import React, { RefObject } from "react";
import { StreamChatChannel } from "shared/messaging/types"
import ChannelListAvatar from "./ChannelListAvatar";

interface Props{
    isCreator: boolean,
    channel: StreamChatChannel,
    displayImage?: string,
    displayTitle?: string,
    renderMessageText?: () => string | React.ReactElement<{children: string}>,
    previewRef: RefObject<HTMLButtonElement>
    unreadClass?: string,
    activeClass?: string,
    onClick: () => void,
}

const ChannelListItem: React.FC<Props> = ({
    isCreator, 
    channel, 
    displayImage,
    displayTitle,
    renderMessageText, 
    previewRef, 
    activeClass='', 
    unreadClass='',
    onClick
}) => {
    return(
        <button
        data-testid="channel-preview-button"
        type="button"
        onClick={onClick}
        ref={previewRef}
        className={`str-chat__channel-preview-messenger ${unreadClass} ${activeClass}`}
        >
            <ChannelListAvatar isCreator={isCreator} channel={channel} image={displayImage} name={displayTitle} />
            <div className="str-chat__channel-preview-messenger--right">
                <div className="str-chat__channel-preview-messenger--name">
                    <span>{displayTitle}</span>
                </div>
                {renderMessageText && 
                    <div className="str-chat__channel-preview-messenger--last-message">
                        {!channel.state.messages[0] ? 'Nothing yet...' :  renderMessageText()}
                    </div>
                }
            </div>
        </button>
    );
}

export default ChannelListItem;
import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';

export default css({
  '&.lightbox-arrow': {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    zIndex: zIndex.zModalContent,
    position: 'absolute',
    top: 0,
    '&__right': {
      right: 0,
    },
    '&__left': {
      left: 0,
    },
  },
  '.lightbox-arrow': {
    '&__btn': {
      color: COLORS.WHITE,
      border: 'none',
      background: 'transparent',
      cursor: 'pointer',
      outline: 'none',
    },
    '&__btn:hover, &__btn:focus': {
      color: COLORS.PRIMARY500,
    },
  },
});

import 'stream-chat-react/dist/css/index.css';

import React, { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import cs from 'classnames';
import { ChannelFilters } from 'stream-chat/dist/types/types';
import {
  Channel,
  ChannelList,
  ChannelSearchProps,
  Chat,
  MessageInput,
  MessageList,
  SearchResultItemProps,
  StreamMessage,
  Thread,
  Window,
} from 'stream-chat-react';
import { ChannelSearchFunctionParams } from 'stream-chat-react/dist/components/ChannelSearch/hooks/useChannelSearch';

import ChannelListPreview from 'features/messages/ChannelListPreview';
import ChannelHeader from 'features/messages/components/molecules/MessageChannelHeader/MessageChannelHeader';
import CustomMessage from 'features/messages/Message';
import NoChannelsPlaceholder from 'features/messages/NoChannelsPlaceholder/NoChannelsPlaceholder';
import SearchCustomResultItem from 'features/messages/SearchCustomResultItem';
import { useMessaging } from 'features/messages/useMessaging';
import { createSearchEmpty } from 'features/messages/util';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import { Text } from 'shared/components/display';
import Box from 'shared/components/display/Box.display';

import { MESSAGE_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useQuery } from 'shared/hooks/useQuery';
import Messaging from 'shared/messaging/Messaging';
import { StreamChatClient, StreamChatTypes } from 'shared/messaging/types';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

const CUSTOM_MESSAGE_ACTIONS = [
  'delete',
  'edit',
  'flag',
  // 'mute',
  'pin',
  'react',
  'reply',
];

interface Query {
  c: string;
}

type RouteParams = {
  organizationId?: string;
};

const MessageCenter = () => {
  const params = useParams<RouteParams>();
  const { activeChannel, setActiveChannel, setUnreadChannels } = useMessaging();
  const { isCreator } = useCurrentUser();
  const { getQuery } = useQuery();
  const { currentUser } = useCurrentUser();

  const [loading, setLoading] = useState(true);
  const [keyboardOpen, setKeyboardOpen] = useState(false);

  const chatClient = Messaging.getClient();
  const query = getQuery<Query>();
  const organizationId = +(params.organizationId || 0);

  const channelFilter = useMemo(() => {
    const filter: ChannelFilters<StreamChatTypes> = {
      members: { $in: [`${currentUser.id}`] },
    };
    if (organizationId) {
      filter.organizationId = organizationId;
    }
    return filter;
  }, [organizationId, currentUser.id]);

  useEffect(() => {
    const setInitialActiveChannel = async (channelId) => {
      const channel = await Messaging.findAccountChannel(channelId);
      setActiveChannel(channel);
      setLoading(false);
    };

    setInitialActiveChannel(query.c);

    return () => {
      setActiveChannel(null);
    };
  }, []);

  // overrides default behavior when new Message is received on Channel that is not being watched
  const onMessageNew = (setChannels, e) => {
    if (e.channel.organizationId === organizationId) {
      setChannels((channels) => {
        return [e.channel, ...channels];
      });
    }
  };

  const activeUnreadHandler = () => {
    setUnreadChannels();
  };

  // Searching for channels has no existing context of the already parsed channel list so need to make
  // sure that we still query for channels the user is a member of or they will have access to
  // channels they are not a part of
  const customSearchFunction = async (
    props: ChannelSearchFunctionParams<StreamChatTypes>,
    event: { target: { value: SetStateAction<string> } },
    client: StreamChatClient,
  ) => {
    const { setResults, setSearching, setQuery } = props;
    const searchString = event.target.value.toString();

    //Inputing an empty string will force the lifecycle of searching so we cannot prevent the search results
    //from appearing. Populate an empty search when the string is empty.
    if (searchString.trim() === '') {
      setQuery(searchString);
      setResults([]);
      return;
    }
    const filter: ChannelFilters<StreamChatTypes> = {
      members: { $in: [`${currentUser.id}`] },
      name: { $autocomplete: searchString },
    };

    if (organizationId) {
      filter.organizationId = organizationId;
    }

    setSearching(true);
    setQuery(searchString);
    let channels = await client.queryChannels(filter);

    //Parse the  channels to not display searches that include organizations and creators searching for their
    //own names
    if (organizationId) {
      channels = channels.filter((channel) => {
        const userChannelName = channel?.data?.name?.split('-')[0].trim().toLowerCase();
        return userChannelName?.includes(searchString.toLowerCase());
      });
    } else {
      channels = channels.filter((channel) => {
        const brandChannelName = channel?.data?.name?.split('-')[1].trim().toLowerCase();
        return brandChannelName?.includes(searchString.toLowerCase());
      });
    }
    setResults(channels);
    setSearching(false);
  };

  // Props used internally by streamchat to be passed to ChannelSearch. Defines the functionality for searching and the
  // components used to render the results.
  const searchProps: Omit<ChannelSearchProps<StreamChatTypes>, 'setChannels'> = {
    popupResults: false,
    clearSearchOnClickOutside: false,
    searchFunction: (params, event) => {
      return customSearchFunction(params, event, chatClient);
    },
    SearchEmpty: () =>
      createSearchEmpty(isCreator ? MESSAGE_COPY.BRAND_SEARCH_EMPTY : MESSAGE_COPY.CREATOR_SEARCH_EMPTY),
    SearchResultItem: (props: SearchResultItemProps<StreamChatTypes>) => <SearchCustomResultItem {...props} />,
  };

  const markAsUnread = (message: StreamMessage) => {
    if (activeChannel) {
      activeChannel.markUnread({ message_id: message.id });
    }
  };

  return loading ? (
    <CenteredSpinnerContainer />
  ) : (
    <Box css={styles}>
      <Chat
        client={chatClient}
        initialNavOpen={false}
        theme={cs('messaging', 'light', {
          'str-chat-no-scroll': keyboardOpen,
          'str-chat-for-brands': !isCreator,
        })}
      >
        <ChannelList
          customActiveChannel={activeChannel?.id}
          Preview={ChannelListPreview}
          filters={channelFilter}
          EmptyStateIndicator={() => (
            <Text variant={TEXT_VARIANTS.SUBHEADING_SM} className="str-chat__no-channels-heading">
              {MESSAGE_COPY.HEADING_NO_CHANNELS}
            </Text>
          )}
          onMessageNew={organizationId ? onMessageNew : undefined} // applies to new messages that appear after refreshing the page, and channelFilter has not changed
          allowNewMessagesFromUnfilteredChannels={false} // applies to new messages that appear after channelFilter changes (ex: switching organizations)
          showChannelSearch
          additionalChannelSearchProps={searchProps}
          LoadingIndicator={React.memo(() => (
            <div></div>
          ))} //Override the default loading channel list to hide the skeletons that have styling conflict with channel search
        />
        <Channel activeUnreadHandler={activeUnreadHandler} EmptyPlaceholder={<NoChannelsPlaceholder />}>
          <Window>
            <ChannelHeader />
            <MessageList
              Message={CustomMessage}
              messageActions={CUSTOM_MESSAGE_ACTIONS}
              customMessageActions={{ 'Mark as unread': markAsUnread }}
            />
            <MessageInput
              additionalTextareaProps={{
                onFocus: () => setKeyboardOpen(true),
                onBlur: () => setKeyboardOpen(false),
                placeholder: activeChannel?.data?.frozen
                  ? MESSAGE_COPY.FROZEN_MESSAGE_INPUT_PLACEHOLDER
                  : MESSAGE_COPY.DEFAULT_MESSAGE_INPUT_PLACEHOLDER,
              }}
              disabled={activeChannel?.data?.frozen}
              // not in MessageInputProps but in MessageInputContext
              // @ts-ignore
              isUploadEnabled={!activeChannel?.data?.frozen}
              {...(activeChannel?.data?.frozen ? { openEmojiPicker: null } : {})} //override emoji picker to not open when frozen
            />
          </Window>
          <Thread />
        </Channel>
      </Chat>
    </Box>
  );
};

export default MessageCenter;

export enum UserStatus {
  INVITED = 2,
  VERIFIED = 1,
  UNVERIFIED = 0,
  DISABLED = -2,
}

export enum VettingStatus {
  NEW = 'NEW',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum VettingAction {
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
}

export enum UserType {
  CREATOR = 2,
  ORG = 1,
}

export const userStatusDisplay = {
  [UserStatus.INVITED]: 'Invited',
  [UserStatus.DISABLED]: 'Disabled',
  [UserStatus.UNVERIFIED]: 'Unverified',
  [UserStatus.VERIFIED]: 'Verified',
};

export enum PhoneStatus {
  ACTIVE = 1,
  INACTIVE = 2,
  BLOCKED = 3,
}

export const phoneStatusDisplay = {
  [PhoneStatus.ACTIVE]: 'On',
  [PhoneStatus.INACTIVE]: 'Off',
  [PhoneStatus.BLOCKED]: 'Off',
};

import { Box } from 'shared/components/display';
import collectionPreviewStyles from 'features/briefs/components/CollectionPreview/collectionPreviewStyles';
import Gallery from 'features/media/components/organisms/Gallery';
import { useFiles } from 'features/media/useFiles';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import Divider from 'shared/components/molecules/Divider';
import { BRIEF_COPY } from 'shared/config/copy';
import { STYLES } from 'shared/styles/styles';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LinkCreator } from 'shared/utilities/linkUtility';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Text } from 'rebass/styled-components';

const { BODY, H5 } = TEXT_VARIANTS;
const { ITALIC } = STYLES;
interface Props {
  collectionId: number;
  accountId: number;
  organizationId: number;
}

const CollectionPreview: React.FC<Props> = ({ collectionId, accountId, organizationId }) => {
  /**
   * Show a single line preview of assets in a collection
   * When clicked on, navigate to the collection
   */
  const { loading, collectionFiles } = useFiles({
    params: { collectionId },
    shouldFetch: true,
  });
  const { formatMessage } = useIntl();
  // only display a small preview of items
  // Filter out marketer uploaded videos from the preview
  const approvedCollectionFiles = collectionFiles.filter((collectionFile) => collectionFile.approvedInAnySubmission);
  const galleryItems = useMemo(() => {
    return approvedCollectionFiles.map((itemObject) => ({
      itemObject,
    }));
  }, [approvedCollectionFiles]);
  const numAssets = approvedCollectionFiles.length;
  const pathToCollection = useMemo(
    () =>
      LinkCreator.createLink({
        routeKey: 'COLLECTION',
        variables: {
          accountId,
          organizationId,
          collectionId,
        },
      }),
    [collectionId, accountId, organizationId],
  );
  return loading ? (
    <CenteredSpinner />
  ) : (
    <Box as="section" css={collectionPreviewStyles} className="collection-preview">
      <Divider
        heading={formatMessage(
          {
            id: 'HEADING_APPROVED_ASSETS',
          },
          { numAssets },
        )}
        variant={H5}
      />
      {numAssets < 1 ? (
        <Text variant={BODY} fontStyle={ITALIC}>
          {BRIEF_COPY.WARNING_NO_APPROVED_ASSETS}
        </Text>
      ) : (
        <Link to={pathToCollection} className="collection-preview__link">
          <div className="collection-preview__gallery-wrapper">
            {/* TODO: see if library can support maxNumRows */}
            <Gallery items={galleryItems && galleryItems.slice(0, 5)} onlyImage numberOfSkeletons={3} />
            <Box className="collection-preview__overlay">
              <Text>{BRIEF_COPY.BUTTON_VIEW_COLLECTION_BRIEF}</Text>
            </Box>
          </div>
        </Link>
      )}
    </Box>
  );
};
export default CollectionPreview;

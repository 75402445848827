import React from 'react';

import RedirectOnError from 'features/auth/components/atoms/RedirectOnError';
import { useAuth } from 'features/auth/useAuth';

import { useQuery } from 'shared/hooks/useQuery';
import { useTimedRedirect } from 'shared/hooks/useTimedRedirect';
import { requiresSignIn } from 'shared/utilities/errorUtility';
import { LinkCreator } from 'shared/utilities/linkUtility';

const ActivateUser = () => {
  const { activateUser, activationErrors } = useAuth();
  const { redirectToPath } = useTimedRedirect();
  const { getQuery } = useQuery();
  const urlParams = getQuery();

  const onError = () => {
    if (requiresSignIn(activationErrors[0])) {
      redirectToPath({
        pathname: LinkCreator.createLink({
          routeKey: 'SIGN_IN',
        }),
      });
    } else {
      redirectToPath({
        pathname: LinkCreator.createLink({
          routeKey: 'TOKEN_EXPIRED',
        }),
        state: { type: 'activation' },
      });
    }
  };

  return (
    <RedirectOnError errors={activationErrors} onError={onError} actionCallback={() => activateUser(urlParams.token)} />
  );
};

export default ActivateUser;

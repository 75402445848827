import React, { useMemo } from 'react';
import { faPercent } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import cs from 'classnames';

import BriefStatus from 'features/briefs/BriefStatus';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Box, Text } from 'shared/components/display';
import BrkfstTable from 'shared/components/organisms/BrkfstTable';
import Pagination from 'shared/components/organisms/Pagination';

import { BRIEF_COPY } from 'shared/config/copy';
import { useQuery } from 'shared/hooks/useQuery';
import COLORS from 'shared/styles/colors';
import { prettyStringDate } from 'shared/utilities/dateUtility';
import { formatCurrency } from 'shared/utilities/stringUtility';

import styles from './styles';
import { Brief } from 'shared/typings/briefs';

const columnHelper = createColumnHelper<any>();

interface Props {
  isElite?: boolean;
  searchParams: any;
  loading: boolean;
  briefs: Brief[];
  totalBriefs: number;
  selectedItem: number | null;
}

const CreatorBriefIndex: React.FC<Props> = ({ briefs, isElite, loading, searchParams, selectedItem, totalBriefs }) => {
  const { setQuery } = useQuery();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor((row) => row, {
        header: BRIEF_COPY.COLUMN_ACCOUNT,
        cell: (info) => {
          const value = info.getValue();
          const selected = value.id === selectedItem;
          return (
            <Box
              className={cs('endWithEllipsis', {
                'brief-index__row--selected': selected,
              })}
            >
              {selected && <Box as="span" className="brief-index__selected" />}
              {value.account?.name}
            </Box>
          );
        },
        meta: {
          addTooltip: true,
        },
      }),
      columnHelper.accessor('name', {
        header: BRIEF_COPY.COLUMN_NAME_BRIEF,
        cell: (info) => {
          const name = info.getValue();
          return <Box className="endWithEllipsis">{name}</Box>;
        },
        meta: {
          addTooltip: true,
        },
      }),
      columnHelper.accessor(
        (row) => ({
          creatorPayout: row.creatorPayout,
          eliteCreatorPayout: row.eliteCreatorPayout,
          creativeOnly: row.creativeOnly,
        }),
        {
          header: BRIEF_COPY.COLUMN_PAYOUT,
          cell: (info) => {
            const { creatorPayout, creativeOnly, eliteCreatorPayout } = info.getValue();

            return (
              <>
                <Text className="brief-index__payout">
                  {formatCurrency(isElite ? eliteCreatorPayout : creatorPayout)}
                </Text>
                {!creativeOnly && (
                  <Tooltip content={BRIEF_COPY.CREATOR_PAYOUT_TIP}>
                    <FontAwesomeIcon
                      size="xs"
                      className="brief-index__percent"
                      border
                      color={COLORS.WHITE}
                      icon={faPercent}
                      fixedWidth
                    />
                  </Tooltip>
                )}
              </>
            );
          },
          meta: {
            width: '100px',
          },
        },
      ),
      columnHelper.accessor((row) => row, {
        header: BRIEF_COPY.COLUMN_STATUS_BRIEF,
        cell: (info) => {
          const value = info.getValue();
          return (
            <Box
              className={cs({
                'brief-index__row--selected': value.id === selectedItem,
              })}
            >
              <BriefStatus
                briefCreatorStatus={value.briefCreatorStatus}
                full={value.full}
                inviteOnly={value.inviteOnly}
              />
            </Box>
          );
        },
        meta: {
          width: '90px',
        },
      }),
      columnHelper.accessor('dateUpdated', {
        header: BRIEF_COPY.COLUMN_DATE_UPDATED,
        cell: (info) => {
          const value = info.getValue();
          return value ? prettyStringDate(value) : '-';
        },
        meta: {
          width: '130px',
        },
      }),
    ];
  }, [selectedItem, isElite]);

  return loading ? (
    <CenteredSpinner />
  ) : (
    <Box className="brief-index" css={styles}>
      <Pagination totalAmount={totalBriefs} searchParams={searchParams}>
        <BrkfstTable
          data={briefs}
          columns={columns}
          onRowClick={(el) =>
            setQuery({
              item: el.id,
              subPage: 1,
              subBriefId: el.id,
              creatorsPage: 1,
              creatorsBriefId: el.id,
            })
          }
          noResultsMessage={BRIEF_COPY.PLACEHOLDER_BRIEFS}
          dataCy="brief-index__table"
        />
      </Pagination>
    </Box>
  );
};

export default CreatorBriefIndex;

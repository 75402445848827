import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRightDrawerData, rightDrawerDataSet, rightDrawerToggled } from 'features/ui/ui.slice';

export const useRightDrawer = (mount = false) => {
  const dispatch = useDispatch();
  const {
    isOpen: isRightDrawerOpen,
    mountDrawer: mountRightDrawer,
    ...rightDrawerProps
  } = useSelector(getRightDrawerData);

  const toggleRightDrawer = (toggle) => {
    dispatch(rightDrawerToggled(toggle));
  };

  const setRightDrawer = ({ key, Content, Header, headerText, onRequestClose }) => {
    let isOpen = true;
    if (isRightDrawerOpen && key === rightDrawerProps.key) {
      isOpen = false;
    }

    dispatch(
      rightDrawerDataSet({
        Content,
        Header,
        headerText,
        onRequestClose,
        isOpen,
        key,
      }),
    );
  };

  useEffect(() => {
    if (mount) {
      dispatch(
        rightDrawerDataSet({
          mountDrawer: true,
        }),
      );
    }
    return () => {
      if (mount) {
        dispatch(
          rightDrawerDataSet({
            mountDrawer: false,
            Content: null,
            key: null,
            Header: null,
            headerText: '',
            isOpen: false,
          }),
        );
      }
    };
  }, []);

  return {
    toggleRightDrawer,
    isRightDrawerOpen,
    setRightDrawer,
    mountRightDrawer,
    ...rightDrawerProps,
  };
};

// * Lib
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as RollbarProvider } from '@rollbar/react';
//* Display/UI
import App from 'app/App';
import { store } from 'app/store';

import CenteredSpinner from 'features/ui/CenteredSpinner';

import { rollbar } from 'shared/errors/Logger';
import TagManager from 'shared/utilities/gtmUtility';

import config from './config';
// * Util
import * as serviceWorker from './serviceWorker';
// * Styles

const persistor = persistStore(store);

const tagManagerArgs = {
  gtmId: config.GTM_ID,
};
TagManager.initialize(tagManagerArgs);

// @ts-ignore
const container: Element | DocumentFragment = document.getElementById('root');
const root = createRoot(container);

const RoutesWrapper = () => (
  <RollbarProvider instance={rollbar}>
    <Provider store={store}>
      <PersistGate loading={<CenteredSpinner />} persistor={persistor}>
        <Outlet />
      </PersistGate>
    </Provider>
  </RollbarProvider>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RoutesWrapper />}>
      <Route path="/*" element={<App />} />
    </Route>,
  ),
);

root.render(<RouterProvider router={router} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

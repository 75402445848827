export const API_FILE_ROUTES = {
  ADD_THUMBNAIL: '/file/add-thumbnail',
  APPROVED_ASSETS: 'approved-assets/:creatorId',
  PINNED_ASSETS_COUNT: 'approved-assets/pinnedCount/:creatorId',
  PIN_FILE: '/file/pin-file/:fileId',
  ASSIGN_FILE: '/file/:collectionId/assign-to-collection',
  COMPOSITION_FILES: 'composition/files',
  COMPOSITION: '/composition',
  DOWNLOAD_FILES: '/file/download-files',
  FILE: '/file/:fileId',
  FILES: '/file',
  GENERATE_SAS_TOKEN: '/file/generate-sas-token',
  REMOVE_FILE: '/file/:collectionId/remove-from-collection/:fileId',
  SLICED_UPLOAD: '/file/sliced-upload',
};

import TrackDirtyState from 'shared/components/atoms/TrackDirtyState';
import FormButton from 'shared/components/FormButton';
import { StepFormProps } from 'shared/components/forms/MultiStepForm/interfaces';
import { GENERIC_COPY } from 'shared/config/copy';
import FormikStripe from 'shared/components/molecules/FormikStripe';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { Form } from 'formik';
import { isEmpty } from 'lodash';
import React from 'react';

const StepStripeForm = <Values extends {}>({
  children,
  disableUntilTouched = false,
  initialValues,
  isActive = false,
  isOptional = false,
  isDeferred = false,
  onSkip,
  onSubmit = async () => {},
  setDirtyState,
  validationSchema,
  validateOnChange = true,
  disableFormButtons = false,
}: StepFormProps<Values>) => {
  return (
    <FormikStripe<Values>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={validateOnChange}
    >
      {(formikProps) => (
        <Form>
          {isActive && <TrackDirtyState setDirtyState={setDirtyState} />}
          {React.Children.map<React.ReactElement, React.ReactElement>(children, (Child: React.ReactElement) => {
            return React.cloneElement(Child, { formikProps });
          })}
          <FormButton
            className="multi-step-form__continue-btn"
            disabled={disableUntilTouched ? isEmpty(formikProps.touched) : false || disableFormButtons}
            loading={formikProps.isSubmitting}
          >
            {GENERIC_COPY.BUTTON_CONTINUE}
          </FormButton>
          {(isOptional || isDeferred) && (
            <FormButton onClick={onSkip} variant={BUTTON_VARIANTS.TEXT} type="button" disabled={disableFormButtons}>
              {isOptional ? 'Skip' : 'Set Up Later'}
            </FormButton>
          )}
        </Form>
      )}
    </FormikStripe>
  );
};

export default StepStripeForm;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '&.trend-ellipsis-menu': {
    borderRadius: '3px',
    border: `1px solid ${COLORS.NEUTRAL300}`,
    width: '30px',
    height: '30px',
    alignItems: 'center',
    cursor: 'pointer',
  },
});

import React, { useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Button } from 'rebass/styled-components';
import cs from 'classnames';
import config from 'config';

import { ACCOUNT_PLATFORM_HEALTH_PROGRESS_COPY } from 'features/accountPlatforms/components/molecules/AccountPlatformHealthProgress/copy';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Box, Flex, Text } from 'shared/components/display';
import { Image } from 'shared/components/display';

import { useQuery } from 'shared/hooks/useQuery';
import brkfstLogoIcon from 'shared/images/logo/brkfst-logo-icon.svg';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';

import styles from './styles';

interface Props {
  completedWithNoScore?: boolean;
  isOnboarding?: boolean;
  accountPlatformId?: number;
  accountId: number;
  // In case an image or gif is used, this will allow to trigger events util it's loaded from the parent component
  LoadingIndicator?: React.FC<{ className: string }>;
}

const FAUX_SCORE = 55;

const AccountPlatformHealthProgress: React.FC<Props> = ({
  completedWithNoScore,
  accountId,
  accountPlatformId,
  isOnboarding = false,
  LoadingIndicator,
}) => {
  const [score, setScore] = useState(0);
  const { stringifyQuery, getQuery } = useQuery();
  const { email } = getQuery();
  const { accountPlatformHealthProgress = 0 } = useAccountPlatforms({ accountId, accountPlatformId });

  const onClick = () => {
    const query = stringifyQuery({ email });
    window.location.assign(config.MARKETING_SITE_URL + query);
  };
  useEffect(() => {
    // Delay setting score, so that the loading animation can trigger a bit after the page loads
    if (completedWithNoScore) setTimeout(() => setScore(FAUX_SCORE), 400);
  }, [completedWithNoScore]);

  return (
    <Flex
      css={styles(accountPlatformHealthProgress)}
      className={cs('account-platform-health-progress', 'mainContainer')}
    >
      {completedWithNoScore ? (
        <>
          <Flex className="account-platform-health-progress__container">
            <CircularProgressbar
              className="account-platform-health-progress__progress-circle"
              value={100}
              circleRatio={score / 100}
              strokeWidth={13}
              text="?"
              background={false}
              styles={{
                path: {
                  stroke: COLORS.NEUTRAL300,
                  strokeLinecap: 'butt',
                  transition: 'stroke-dashoffset 0.5s ease 0s',
                },
                trail: {
                  stroke: COLORS.TRANSPARENT,
                },
              }}
            />
            <Text as="h1" className="account-platform-health-progress__title">
              {ACCOUNT_PLATFORM_HEALTH_PROGRESS_COPY.NO_SCORE_TITLE}
            </Text>

            {isOnboarding ? (
              <>
                <Text as="h2" className="account-platform-health-progress__subtitle">
                  {ACCOUNT_PLATFORM_HEALTH_PROGRESS_COPY.NO_SCORE_SUBTITLE_EXTERNAL}
                </Text>
                <Button onClick={onClick} variant={BUTTON_VARIANTS.PRIMARY} className="account-health__improve-btn">
                  Learn More
                </Button>
              </>
            ) : (
              <Text as="h2" className="account-platform-health-progress__subtitle">
                {ACCOUNT_PLATFORM_HEALTH_PROGRESS_COPY.NO_SCORE_SUBTITLE}
              </Text>
            )}
          </Flex>
        </>
      ) : (
        <>
          <Text as="h1" className="account-platform-health-progress__title">
            {ACCOUNT_PLATFORM_HEALTH_PROGRESS_COPY.IN_PROGRESS_TITLE}
          </Text>
          <Flex className="account-platform-health-progress__container">
            {LoadingIndicator ? (
              <LoadingIndicator className="account-platform-health-progress__img" />
            ) : (
              <CenteredSpinner />
            )}
            <Box className="account-platform-health-progress__progress-bar-wrapper">
              <Box className="account-platform-health-progress__progress-bar" />
              <Image src={brkfstLogoIcon} className="account-platform-health-progress__logo" />
            </Box>
            <Text>{accountPlatformHealthProgress}% Complete</Text>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default AccountPlatformHealthProgress;

import { useEffect, useContext } from 'react';
import { SignalRContext } from 'shared/contexts/signalr';
import { useDispatch } from 'react-redux';
import { useToasts } from 'shared/hooks/useToasts';
import { componentLoadingToggled } from 'features/ui/ui.slice';
import { RESOURCES } from 'shared/config/resourceNames';
import { profilePictureUploaded } from './user.slice';
import { useFiles } from 'features/media/useFiles';
import { FileUploadStatus } from 'shared/typings/file/enums';

export const useAvatarNotification = (): void => {
  const { connection } = useContext(SignalRContext);
  const dispatch = useDispatch();
  const { setSuccessToast } = useToasts();
  const { updateFileUpload } = useFiles();

  useEffect(() => {
    const notificationHandler = (avatar) => {
      dispatch(
        profilePictureUploaded({
          data: {
            profilePic: avatar,
            profilePicId: avatar.id,
          },
        }),
      );
      dispatch(
        componentLoadingToggled({
          component: RESOURCES.PROFILE_PICTURE,
          loading: false,
        }),
      );
      updateFileUpload(avatar.key, FileUploadStatus.UPLOADED);
      setSuccessToast({
        heading: 'Profile',
        message: 'Profile Picture updated',
      });
    };

    if (connection) {
      connection.on('newFile', notificationHandler);
    }

    return function cleanup() {
      connection?.off('newFile', notificationHandler);
    };
  }, [connection]);
};

import React from 'react';
import { Image } from 'rebass/styled-components';
import cs from 'classnames';

import { Box } from 'shared/components/display';

import styles from './styles';

interface Props {
  name: string;
  source: string;
  width?: string;
  height?: string;
  className?: string;
}
const SingleImage: React.FC<Props> = ({ name, source, width, height, className }) => {
  return (
    <Box css={styles(source, width, height)} className={cs('single-image', className)}>
      {height ? <Box className="single-image__img" /> : <Image alt={name} src={source} width={width} />}
    </Box>
  );
};

export default SingleImage;

import { createSlice } from '@reduxjs/toolkit';

import { CreativePayout } from 'shared/typings/creatorPerformance';

interface CreatorPerformanceState {
  data: Array<CreativePayout>;
  total: number;
}

const initialState: CreatorPerformanceState = {
  data: [],
  total: 0,
};

const creatorPerformanceSlice = createSlice({
  name: 'creatorPerformance',
  initialState,
  reducers: {
    creativePayoutsLoaded: (performance, action) => {
      performance.data = action.payload.data.data.map((data, ndx) => {
        data.id = ndx + 1;
        return data;
      });
      performance.total = action.payload.data.total;
    },
  },
});

export const getCreativePayouts = ({ creatorPerformance: { data } }) => data;

export const getCreativePayoutsCount = ({ creatorPerformance: { total } }) => total;

export const { creativePayoutsLoaded } = creatorPerformanceSlice.actions;

export default creatorPerformanceSlice.reducer;

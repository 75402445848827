import React, { useMemo } from 'react';
import DropdownWrapper from 'shared/components/atoms/DropdownWrapper';
import { DRAWERS } from 'shared/config/containers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/pro-solid-svg-icons';
import AttributionForm from 'features/performance/AttributionWindowDropdown/form';
import { AttributionValues } from 'features/performance/AttributionWindowDropdown/config';
import { useContainer } from 'shared/hooks/useContainer';
import { usePerformance } from 'shared/hooks/usePerformance';
import { AttributionWindow } from 'features/performance/enums';

const AttributionWindowDropdown: React.FC = () => {
  const {
    updateAttributionWindow,
    performanceState: { attributionWindow },
  } = usePerformance();
  const { closeContainer } = useContainer(DRAWERS.ATTRIBUTION);

  const setAttributionWindow = (values: AttributionValues) => {
    const attributionWindows: AttributionWindow[] = [];
    if (values.view) {
      attributionWindows.push(values.view);
    }
    if (values.click) {
      attributionWindows.push(values.click);
    }
    updateAttributionWindow(attributionWindows);
    closeContainer();
  };

  const attributionText = useMemo(() => {
    if (attributionWindow.length) {
      return `Attribution: ${attributionWindow
        .map((aw) => `${aw.replace(/\D/g, '')} Day ${aw.split('_').pop()}`)
        .join(' / ')}`;
    }
    return 'Attribution';
  }, [attributionWindow]);

  return (
    <DropdownWrapper
      label={DRAWERS.ATTRIBUTION}
      clickComponent={
        <>
          <FontAwesomeIcon icon={faChartLine} />
          <span>{attributionText}</span>
        </>
      }
    >
      <AttributionForm onSubmit={setAttributionWindow} onCancel={closeContainer} />
    </DropdownWrapper>
  );
};

export default AttributionWindowDropdown;

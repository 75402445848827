import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '.creator-performance': {
    width: '100%',
    overflowY: 'auto',
    padding: '16px',
    height: '100%',

    [mediaquery.md]: {
      height: 'auto',
      overflow: 'visible',
      paddingRight: '16px',
    },

    '&:before': {
      content: '""',
      width: '16px',
      position: 'absolute',
      background: COLORS.NEUTRAL50,
      left: '0',
      top: '0',
      height: '100%',
    },

    '&__pagination': {
      height: '100%',
    },
  },

  '.creator-performance--no-results': {
    overflow: 'hidden',
  },

  '.brkfst-pagination__index': {
    background: 'white',
    padding: '20px 0',
    position: 'sticky',
    bottom: '-16px',
    borderTop: `2px solid ${COLORS.NEUTRAL100}`,
  },
});

import React, { useEffect, useMemo, useState } from 'react';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { useReviewModules } from 'features/reviewModules/useReviewModules';
import CopyLink from 'shared/components/atoms/CopyLink/CopyLink';
import { Box } from 'shared/components/display';
import ActionButton from 'shared/components/molecules/ActionButton';
import { Values } from 'shared/components/molecules/ModuleReviewForm/moduleReviewFormConfig';
import ModuleReviewLightbox from 'shared/components/molecules/ModuleReviewLightbox/ModuleReviewLightbox';

import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { usePermissions } from 'shared/hooks/usePermissions';
import { useQuery } from 'shared/hooks/useQuery';
import { intl } from 'shared/lib/intl';
import theme from 'shared/styles/themes';
import { ApiOnSuccess } from 'shared/typings/api';
import { ModuleSubmissionStatus } from 'shared/typings/submissions';

import { classes, moduleReviewStyles } from './moduleReviewStyles';

interface Props {
  organizationId: number;
  accountId: number;
  briefId: number;
  briefName: string;
  brandName: string;
  submissionId?: number;
  reviewLink: string;
  margin?: string;
  alignItems?: string;
  copyLinkMargin?: string;
}

export type HandleCriticalErrors = (args: {
  data: Values;
  errorCategory: string;
  submissionModuleId: number;
  creatorId: number;
  onSuccess?: ApiOnSuccess;
}) => void;

const ModuleReview: React.FC<Props> = ({
  organizationId,
  accountId,
  briefId,
  briefName,
  brandName,
  submissionId,
  reviewLink,
  margin = theme.space[4],
  alignItems = 'center',
  copyLinkMargin = theme.space[4],
}) => {
  const [currentModule, setCurrentModule] = useState<number>(0);
  const { account } = useAccounts({ accountId: `${accountId}` });
  const { reviewModules, createRevision, loading, updateSubmissionModuleStatus, fetchReviewModules } =
    useReviewModules();
  const { isAuthorized } = usePermissions();
  const { setQuery, getQuery } = useQuery();

  const query = getQuery();
  const canReview = isAuthorized(organizationId, PERMISSIONS_MAP.REVIEW_MODULES.API);

  const [approvalLoading, setApprovalLoading] = useState(false);

  useEffect(() => {
    fetchReviewModules(briefId, submissionId);
  }, [briefId, submissionId]);

  const { filteredModules, openLightbox } = useMemo(() => {
    if (!canReview) return { filteredModules: [], openLightbox: false };

    return {
      filteredModules: reviewModules,
      openLightbox: Boolean(query.openLightbox) && reviewModules.length > 0,
    };
  }, [reviewModules, canReview, query.openLightbox]);

  useEffect(() => {
    if (!loading) {
      if (filteredModules.length === 0) {
        // If there are no more modules to review, close the lightbox
        if (query.openLightbox) toggleLightbox(false);
        // reset the currentModule
        if (currentModule > 0) setCurrentModule(0);

        return;
      }
      // if the last module was approved, but there are still modules to review, change currentModal to still be in range
      if (currentModule >= filteredModules.length) {
        setCurrentModule(filteredModules.length - 1);
      }
    }
  }, [filteredModules.length, loading]);

  const handleCriticalErrors: HandleCriticalErrors = ({
    data,
    errorCategory,
    submissionModuleId,
    creatorId,
    onSuccess,
  }) => {
    const onSuccessWithLoading = (response) => {
      if (onSuccess) onSuccess(response);
      setApprovalLoading(false);
    };

    setApprovalLoading(true);
    createRevision(
      { category: errorCategory, comment: data.comment, submissionModuleId },
      creatorId,
      ModuleSubmissionStatus.REVISION,
      onSuccessWithLoading,
      () => setApprovalLoading(false),
    );
  };

  const toggleLightbox = (newValue?: boolean) => {
    if (newValue === undefined) {
      setQuery({
        openLightbox: !openLightbox,
      });
    } else {
      setQuery({
        openLightbox: newValue,
      });
    }
  };

  const updateCurrentModule = (index: number) => {
    setCurrentModule(index);
  };

  const handleApproveModule = (submissionModuleId: number, hasTodoRevision: boolean) => {
    setApprovalLoading(true);
    updateSubmissionModuleStatus({
      submissionModuleId,
      status: hasTodoRevision ? ModuleSubmissionStatus.REVISION : ModuleSubmissionStatus.APPROVED,
      onSuccess: () => setApprovalLoading(false),
      onError: () => setApprovalLoading(false),
    });
  };

  const styles = useMemo(() => moduleReviewStyles({ margin, alignItems }), [margin, alignItems]);

  return (
    <>
      <Box className={classes.moduleReview} css={styles}>
        <div className={classes.reviewButtons}>
          <ActionButton
            onClick={() => toggleLightbox()}
            disabled={filteredModules.length === 0}
            loading={loading}
            dataCy="module-review__begin-review-btn"
          >
            {intl.formatMessage(
              {
                id: 'BUTTON_MODULES_TO_REVIEW',
              },
              {
                num: loading ? 0 : filteredModules.length,
              },
            )}
          </ActionButton>
          <CopyLink
            link={window.location.origin + reviewLink}
            text={intl.formatMessage({ id: 'COPY_MODULE_LINK' })}
            dataCy="module-review__copy-link-button"
            margin={copyLinkMargin}
          />
        </div>
      </Box>
      <ModuleReviewLightbox
        onChange={updateCurrentModule}
        isOpen={openLightbox}
        onRequestClose={toggleLightbox}
        currentModule={currentModule}
        modules={filteredModules}
        handleApproveModule={handleApproveModule}
        handleCriticalErrors={handleCriticalErrors}
        briefName={briefName}
        brandName={brandName}
        approvalLoading={approvalLoading}
        enableModuleComments={account.enableModuleComments}
      />
    </>
  );
};

export default ModuleReview;

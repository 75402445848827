import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.rich-text': {
    position: 'relative',
    marginTop: theme.space[4],
    '&--read-only': {
      marginTop: 0,
    },
    '&--read-only .rich-text__editor': {
      border: 'none',
    },
  },
  '.rich-text': {
    '&__icon-button': {
      border: 'none',
      background: 'transparent',
      padding: '3px 4px',
      margin: '0 2px',
      borderRadius: '2px',
      cursor: 'pointer',
      userSelect: 'none',
    },
    '&__icon-button--active': {
      background: COLORS.PRIMARY200,
      color: COLORS.PRIMARY500,
    },
    '&__editor': {
      border: `1px solid ${COLORS.NEUTRAL300}`,
      borderBottomRightRadius: '2px',
      borderBottomLeftRadius: '2px',
      padding: `${theme.space[3]} ${theme.space[4]}`,
      overflow: 'visible',
      zIndex: zIndex.zForeground,
      background: 'transparent',
      position: 'relative',
    },
    '&__link': {
      color: COLORS.PRIMARY500,
      textDecoration: 'underline',
    },
    '&__link-wrapper': {
      display: 'inline',
      width: 'auto',
    },
    '&__placeholder': {
      color: COLORS.NEUTRAL700,
      position: 'absolute',
      pointerEvents: 'none',
      cursor: 'text',
    },
  },
  '.rich-image': {
    maxWidth: '100%',
  },
  '&.readable-line-width': {
    maxWidth: '800px',
  },
});

import React, { useEffect, useState } from 'react';
import cs from 'classnames';

import { useFiles } from 'features/media/useFiles';
import OrganizationOnboardingConfirmation from 'features/organizations/components/organisms/OrganizationOnboardingConfirmation';
import {
  brandAdvertisingInitialValues,
  brandAdvertisingSchema,
  BrandAdvertisingStepFields,
  facebookOnboardingInitialValues,
  facebookOnboardingScheme,
  FacebookOnboardingStepFields,
  orgBillingInitialValues,
  orgBillingOnSubmit,
  orgBillingSchema,
  OrgBillingStepFields,
  orgDetailsInitialValues,
  orgDetailsSchema,
  OrgDetailsStepFields,
} from 'features/organizations/components/organisms/OrgOnboardingStepFields';
import {
  BrandAdvertisingValues,
  FacebookOnboardingValues,
  ORGANIZATION_ONBOARDING_STEPS,
  OrgBillingValues,
  OrgDetailsValues,
} from 'features/organizations/components/organisms/OrgOnboardingStepFields/types';
import OrganizationOnboardingLoading from 'features/organizations/components/OrganizationOnboardingLoading';
import { OnboardingPayload } from 'features/organizations/pages/OrganizationOnboarding/types';
import { useOrganizations } from 'features/organizations/useOrganizations';
import { Box } from 'shared/components/display';
import { MultiStepForm, StepForm } from 'shared/components/forms/MultiStepForm';
import { StepOnSubmit } from 'shared/components/forms/MultiStepForm/interfaces';
import StepStripeForm from 'shared/components/forms/MultiStepForm/Steps/StepStripeForm';
import VerticalStepWrapper from 'shared/components/forms/MultiStepForm/Steps/VerticalStepWrapper/VerticalStepWrapper';
import {
  AddressFormFields,
  addressOnSubmit,
  addressSchemaNoPhone,
} from 'shared/components/organisms/AddressFormFields';
import { addressInitialValues } from 'shared/components/organisms/AddressFormFields/constants';
import { AddressValues } from 'shared/components/organisms/AddressFormFields/types';

import { ACCOUNT_COPY, ORGANIZATION_COPY } from 'shared/config/copy';
import { BRKFST_SUPPORTED_IMAGE_FORMATS } from 'shared/config/fileFormats';
import { RESOURCES } from 'shared/config/resourceNames';
import { LOCAL_STORAGE } from 'shared/config/storage';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useFileNotification } from 'shared/hooks/useFileNotification';
import { useGeoLocation } from 'shared/hooks/useGeoLocationHook';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { useQuery } from 'shared/hooks/useQuery';
import { CLASSNAMES } from 'shared/styles/containers';
import { GeneralValidator } from 'shared/utilities/validator';

interface Props {}
const OrganizationOnboarding: React.FC<Props> = () => {
  const [addressError, setAddressError] = useState<string[]>([]);

  const { validateAddress } = useGeoLocation();
  const { initiateSingleFileUpload, getFile, selectedFiles, deleteFile } = useFiles();
  const { onboardOrg, loadingOnboarding } = useOrganizations();
  const { setQuery } = useQuery();

  const {
    value: brandLogoId,
    setVal: setBrandLogoVal,
    removeVal: removeBrandLogoVal,
  } = useLocalStorage<number>(LOCAL_STORAGE.BRAND_LOGO_ID);

  const { loading: fbaLoading } = useComponentLoading(RESOURCES.FACEBOOK_BUSINESS_ASSET, false);
  const { loading: igLoading } = useComponentLoading(RESOURCES.INSTAGRAM, false);

  useFileNotification({ updateCollection: false, onFileUploaded: (file) => setBrandLogoVal(file.id) });

  const [selectedFile] = selectedFiles;

  useEffect(() => {
    if (brandLogoId && !selectedFile) {
      getFile(brandLogoId);
    }
  }, [brandLogoId, selectedFile]);

  const onConfirm = (values, clearDataFromLocalStorage) => {
    const orgDetails: OrgDetailsValues = values[ORGANIZATION_ONBOARDING_STEPS.DETAILS];
    const orgBilling: OrgBillingValues = values[ORGANIZATION_ONBOARDING_STEPS.BILLING];
    const {
      phoneNumber,
      country: { value: country },
      state: { value: state },
      ...billingAddress
    }: AddressValues = values[ORGANIZATION_ONBOARDING_STEPS.ADDRESS];
    const brandDetails: BrandAdvertisingValues = values[ORGANIZATION_ONBOARDING_STEPS.BRAND_ADVERTISING];
    const facebook: FacebookOnboardingValues = values[ORGANIZATION_ONBOARDING_STEPS.FACEBOOK];
    const stateId = state?.id || null; // Null if no state to remove relation if one already exists
    const onboardingPayload: OnboardingPayload = {
      brandName: brandDetails.brandName.trim(),
      billingAddress: {
        ...billingAddress,
        countryId: country?.id,
        stateId,
      },
      brandDomain: brandDetails.brandWebsite,
      brandSlogan: brandDetails.brandSlogan,
      card: orgBilling.creditCard.card,
      organizationName: orgDetails.name,
      organizationDomain: orgDetails.domain,
    };

    if (brandLogoId) {
      onboardingPayload.brandLogoId = brandLogoId;
    }
    for (const asset in facebook) {
      const assetVal = facebook[asset];
      if (asset === 'facebookBusiness') onboardingPayload.businessId = assetVal;
      else onboardingPayload[asset] = assetVal;
    }
    if (!loadingOnboarding) {
      onboardOrg(onboardingPayload, () => {
        clearDataFromLocalStorage();
        removeBrandLogoVal();
      });
    }
  };

  const uploadBrandLogo = (newValue: BrandAdvertisingValues): void => {
    const { brandLogo } = newValue;
    if (brandLogo?.file?.size) {
      initiateSingleFileUpload(
        {
          // @ts-ignore
          file: brandLogo.file,
        },
        new GeneralValidator(BRKFST_SUPPORTED_IMAGE_FORMATS),
      );
    }
  };

  const brandAdvertisingOnSubmit: StepOnSubmit<BrandAdvertisingValues> = async (newValues) => {
    const { brandLogo: newBrandLogo } = newValues;
    const uploadedFileId = selectedFile?.id || brandLogoId;
    const isFileCreated = !uploadedFileId;

    if (isFileCreated) {
      uploadBrandLogo(newValues);
    }
    // isFileDeleted
    else if (newBrandLogo?.file.size < 1 && uploadedFileId) {
      deleteFile({ fileId: uploadedFileId, showToast: false });
      removeBrandLogoVal();
    } else if (selectedFile?.name !== newBrandLogo.file.name && uploadedFileId) {
      uploadBrandLogo(newValues);
      deleteFile({ fileId: uploadedFileId, showToast: false });
      removeBrandLogoVal();
    }

    return { success: true, newValues };
  };

  const onFBSkip = () => {
    setQuery({}, true);
  };

  return (
    <Box className={cs('organization-onboarding', CLASSNAMES.MAIN_CONTAINER)}>
      {loadingOnboarding ? (
        <OrganizationOnboardingLoading />
      ) : (
        <MultiStepForm
          StepWrapper={VerticalStepWrapper}
          onConfirm={onConfirm}
          ConfirmationPage={<OrganizationOnboardingConfirmation loading={loadingOnboarding} />}
          confirmationTitle="Confirm Details"
          localStorageKey={LOCAL_STORAGE.ORGANIZATION_ONBOARDING}
        >
          <StepForm<OrgDetailsValues>
            initialValues={orgDetailsInitialValues}
            validationSchema={orgDetailsSchema}
            title={ORGANIZATION_COPY.FORM_HEADING_CREATE_ORGANIZATION}
            titleInfo={{
              header: ORGANIZATION_COPY.FORM_HEADING_CREATE_ORGANIZATION,
              text: ORGANIZATION_COPY.FORM_INFO_CREATE,
            }}
          >
            <OrgDetailsStepFields />
          </StepForm>
          <StepStripeForm<OrgBillingValues>
            title={ORGANIZATION_COPY.FORM_HEADING_BILLING}
            initialValues={orgBillingInitialValues}
            validationSchema={orgBillingSchema}
            onSubmit={orgBillingOnSubmit}
          >
            <OrgBillingStepFields />
          </StepStripeForm>
          <StepForm
            title={ORGANIZATION_COPY.FORM_SUBTITLE_ADDRESS}
            initialValues={addressInitialValues}
            validationSchema={addressSchemaNoPhone}
            onSubmit={addressOnSubmit(validateAddress, setAddressError)}
          >
            <AddressFormFields error={addressError} hidePhoneField />
          </StepForm>
          <StepForm<BrandAdvertisingValues>
            title={ORGANIZATION_COPY.FORM_HEADING_BRAND_ADVERTISING}
            titleInfo={{
              header: ORGANIZATION_COPY.FORM_HEADING_BRAND_ADVERTISING,
              text: ORGANIZATION_COPY.FORM_INFO_BRAND_ADVERTISING,
            }}
            validationSchema={brandAdvertisingSchema}
            initialValues={brandAdvertisingInitialValues}
            onSubmit={brandAdvertisingOnSubmit}
          >
            <BrandAdvertisingStepFields />
          </StepForm>
          <StepForm<FacebookOnboardingValues>
            title={ACCOUNT_COPY.ONBOARDING_LINK_FACEBOOK_ACCOUNT}
            titleInfo={{
              header: ACCOUNT_COPY.ONBOARDING_LINK_FACEBOOK_ACCOUNT,
              text: ACCOUNT_COPY.ONBOARDING_FB_CONNECTION_INFO_TEXT,
            }}
            initialValues={facebookOnboardingInitialValues}
            validationSchema={facebookOnboardingScheme}
            disableFormButtons={igLoading || fbaLoading}
            onSkip={onFBSkip}
            isDeferred
          >
            <FacebookOnboardingStepFields />
          </StepForm>
        </MultiStepForm>
      )}
    </Box>
  );
};

export default OrganizationOnboarding;

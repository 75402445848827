import { css } from '@emotion/react';

export default css({
  '.add-files-to-collection-form': {
    padding: '1rem',
    '&__collection-select': {
      marginBottom: '10px',
    },
  },
});

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import { startCase } from 'lodash';

import { Box, Flex } from 'shared/components/display';
import { ErrorMessage } from 'shared/components/FieldErrorMessage';
import { StepFieldProps } from 'shared/components/forms/MultiStepForm/interfaces';
import AddressSearch from 'shared/components/inputs/AddressSearch';
import PhoneInput from 'shared/components/inputs/PhoneInput';
import { SelectInput } from 'shared/components/molecules/Select';
import { ADDRESS_FIELDNAMES } from 'shared/components/organisms/AddressFormFields/constants';
import TextInput from 'shared/components/TextInput';

import { apiAction } from 'shared/actions/api';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { GeoLocationValue, useGeoLocation } from 'shared/hooks/useGeoLocationHook';
import { makeLabel } from 'shared/lib/formik';
import { Country, State } from 'shared/typings/address';

import styles from './styles';
import { AddressValues } from './types';
interface Props {
  error?: string[];
  hidePhoneField?: boolean;
  focusAddress?: boolean;
}

const UNITED_STATES = 'United States';
const CANADA = 'Canada';
const COUNTRIES_WITH_STATE = [UNITED_STATES, CANADA];

export const AddressFormFields: React.FC<StepFieldProps & Props> = ({
  error,
  formikProps,
  hidePhoneField = false,
  focusAddress = false,
}) => {
  const [countryDisabled, setCountryDisabled] = useState(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const [states, setStates] = useState<State[]>([]);
  const addressLineOneRef = useRef<any>(null);
  const hasPhoneFormikError = Boolean(formikProps?.errors[ADDRESS_FIELDNAMES.PHONE_NUMBER]);
  const { country, countryDialCode, phoneNumber } = formikProps?.values;
  const countryName = country?.value?.name;
  const countryId = country?.value?.id;
  const { getCountryAlphaCode } = useGeoLocation();

  const isUnitedStates = [UNITED_STATES, ''].includes(countryName);
  const isCanada = countryName === CANADA;
  const hasStateInput = !countryName || isUnitedStates || isCanada;

  const { setFieldValue, setFieldTouched, validateField } = useFormikContext<AddressValues>();
  const { isCreator } = useCurrentUser();
  const dispatch = useDispatch();

  const stateOptions = useMemo(() => {
    return states.map(({ name, ...state }) => ({ value: state, label: name }));
  }, [states]);

  const countryOptions = useMemo(() => {
    return countries.map(({ name, ...country }) => ({
      value: {
        ...country,
        name,
      },
      label: name,
    }));
  }, [countries]);

  const fetchStates = useCallback(
    (countryId: number, onSuccess?: (states: State[]) => void) => {
      dispatch(
        apiAction({
          path: {
            route: API_USER_ROUTES.COUNTRY_STATES,
            variables: { countryId },
          },
          params: { countryCode: 'US' },
          entity: 'state',
          onSuccess: ({ data }) => {
            setStates(data);
            onSuccess?.(data);
          },
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.COUNTRIES,
        },
        entity: 'country',
        onSuccess: ({ data }) => {
          setCountries(data);
        },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (hasStateInput && states.length === 0) {
      if (countryId) {
        fetchStates(countryId);
      } else if (countries.length) {
        const unitedStates = countries.find((country) => country.code === 'US');
        if (unitedStates) fetchStates(unitedStates.id);
      }
    }
  }, [states, fetchStates, countryId, countries, hasStateInput, setFieldTouched]);

  useEffect(() => {
    if (addressLineOneRef.current && focusAddress) {
      addressLineOneRef.current.focus();
    }
  }, [addressLineOneRef, focusAddress]);

  const onAddressChange = async (address: GeoLocationValue) => {
    const res = await getCountryAlphaCode(address.country);
    const countryCode = res?.iso2.toLowerCase() || 'us';
    const dialCode = getCountryCallingCode(countryCode.toUpperCase() as CountryCode);

    if (countryDialCode !== dialCode && phoneNumber === countryDialCode) {
      // Update the phone country dial code if the number is not already filled out
      setFieldValue(ADDRESS_FIELDNAMES.COUNTRY_DIAL_CODE, dialCode);
      const startsWithRegEx = new RegExp(`^(${countryDialCode})`);
      if (phoneNumber) setFieldValue(ADDRESS_FIELDNAMES.PHONE_NUMBER, phoneNumber.replace(startsWithRegEx, dialCode));
    }
    setFieldValue(ADDRESS_FIELDNAMES.COUNTRY_CODE, countryCode);
    if (COUNTRIES_WITH_STATE.includes(address.country)) {
      const countryId = countries.find((country) => country.name === address.country)?.id;
      if (countryId)
        fetchStates(countryId, (states) => {
          setFieldValue(ADDRESS_FIELDNAMES.STATE, {
            value: states.find((state) => state.name === address.state),
            label: address.state,
          });
        });
    }
  };

  const onStateChange = (state) => {
    setFieldValue(ADDRESS_FIELDNAMES.STATE, {
      value: states.find((s) => s.name === state.label),
      label: state.label,
    });
    // Resolves existing Formik issue with setFieldValue: https://github.com/jaredpalmer/formik/issues/2059
    setTimeout(() => setFieldTouched(ADDRESS_FIELDNAMES.STATE, true));
  };

  const onCountryChange = () => {
    // Resolves existing Formik issue with setFieldValue: https://github.com/jaredpalmer/formik/issues/2059
    setTimeout(() => setFieldTouched(ADDRESS_FIELDNAMES.COUNTRY, true));
  };

  return (
    <Box css={styles} className="address-form-fields">
      <Field
        name={ADDRESS_FIELDNAMES.ADDRESS_LINE_1}
        component={AddressSearch}
        countries={countries}
        onAddressChange={onAddressChange}
        setCountryDisabled={setCountryDisabled}
        placeholder={`${startCase(ADDRESS_FIELDNAMES.ADDRESS_LINE_1)}*`}
        label={`${startCase(ADDRESS_FIELDNAMES.ADDRESS_LINE_1)}*`}
        innerRef={addressLineOneRef}
      />
      <Field
        name={ADDRESS_FIELDNAMES.ADDRESS_LINE_2}
        component={TextInput}
        placeholder={startCase(ADDRESS_FIELDNAMES.ADDRESS_LINE_2)}
        label={startCase(ADDRESS_FIELDNAMES.ADDRESS_LINE_2)}
        autoComplete="none"
        dataCy="address-form-fields__address-line-2-input"
      />
      <Field
        name={ADDRESS_FIELDNAMES.COMPANY_NAME}
        component={TextInput}
        placeholder={startCase(ADDRESS_FIELDNAMES.COMPANY_NAME)}
        label={startCase(ADDRESS_FIELDNAMES.COMPANY_NAME)}
        dataCy="address-form-fields__company-input"
      />
      <Flex className="address-form-fields__split-fields">
        <Field
          name={ADDRESS_FIELDNAMES.CITY}
          component={TextInput}
          placeholder={`${startCase(ADDRESS_FIELDNAMES.CITY)}*`}
          label={`${startCase(ADDRESS_FIELDNAMES.CITY)}*`}
          autoComplete="none"
          dataCy="address-form-fields__city-input"
          className="address-form-fields__city-input"
        />
        {hasStateInput && (
          <Field
            name={ADDRESS_FIELDNAMES.STATE}
            className="address-form-fields__state-select"
            component={SelectInput}
            placeholder={`${startCase(ADDRESS_FIELDNAMES.STATE)}*`}
            options={stateOptions}
            isSearchable
            filterByLabel
            validationFromStart
            onChange={onStateChange}
            dataCy="address-form-fields__state-select"
          />
        )}
      </Flex>
      <Flex className="address-form-fields__split-fields">
        <Field
          name={ADDRESS_FIELDNAMES.POSTAL_CODE}
          component={TextInput}
          placeholder={`${startCase(ADDRESS_FIELDNAMES.POSTAL_CODE)}*`}
          label={`${startCase(ADDRESS_FIELDNAMES.POSTAL_CODE)}*`}
          autoComplete="none"
          dataCy="address-form-fields__postal-code-input"
          className="address-form-fields__postal-code-input"
        />
        <Field
          name={ADDRESS_FIELDNAMES.COUNTRY}
          component={SelectInput}
          dataCy="address-form-fields__country-select"
          placeholder={`${startCase(ADDRESS_FIELDNAMES.COUNTRY)}*`}
          options={countryOptions}
          isSearchable
          disabled={countryDisabled}
          filterByLabel
          onChange={onCountryChange}
          validationFromStart
          className="address-form-fields__country-select"
        />
      </Flex>
      {!hidePhoneField && (
        <Field
          name={ADDRESS_FIELDNAMES.PHONE_NUMBER}
          component={PhoneInput}
          placeholder={makeLabel(ADDRESS_FIELDNAMES.PHONE_NUMBER, true)}
          label={makeLabel(ADDRESS_FIELDNAMES.PHONE_NUMBER, true)}
          dialCodeFieldName={ADDRESS_FIELDNAMES.COUNTRY_DIAL_CODE}
        />
      )}

      {!hasPhoneFormikError &&
        error?.map((err) => (
          <ErrorMessage key={err} dataCy="address-form-fields__error-message">
            {err}
          </ErrorMessage>
        ))}
    </Box>
  );
};

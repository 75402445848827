import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import Password from 'shared/components/inputs/PasswordInput/Password';
import StrengthBar from 'shared/components/inputs/PasswordInput/StrengthBar';
import { Input } from 'shared/lib/formik';
import React, { useState } from 'react';
import zxcvbn from 'zxcvbn';

interface Props {
  placeholder: string;
  maxLength: number;
  disabled: boolean;
  withValidation?: boolean;
  label?: string;
  dataCy?: string;
}

interface Value {
  password: string;
  score: number;
  message: string;
}

const PasswordValidationInput: Input<Value, Props> = ({
  field: { value, onBlur, name, onChange },
  form: { touched, values, errors },
  maxLength,
  disabled,
  placeholder,
  label,
  dataCy,
}) => {
  const [strengthScore, setScore] = useState<number>(0);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    const { firstName, lastName } = values;
    const { score, feedback } = zxcvbn(password, ['brkfst', 'BRKFST', firstName, lastName, firstName + lastName]);

    onChange({
      target: {
        name,
        value: {
          score,
          message: feedback.warning || feedback.suggestions.join(', '),
          password,
        },
      },
    });
    setScore(score);
  };

  return (
    <>
      <Password
        value={value.password}
        onChange={handleChange}
        onBlur={onBlur}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
        touched={Boolean(touched[name])}
        hasError={Boolean(errors[name])}
        label={label}
        name={name}
        dataCy={dataCy}
      >
        <StrengthBar score={strengthScore} hasValue={!!value.password} />
      </Password>
      <FieldErrorMessage name={name} dataCy="password-validation-input__error-message" />
    </>
  );
};

export default PasswordValidationInput;

import React, { useEffect } from 'react';

interface Props {
  disabled?: boolean;
}
/**
 * Prompts the user when they try to refresh, but not when navigating to another page. The message is based on the browser and can't be modified. This should work for every browser except safari on ios
 * @param disabled - if true, the refresh prompt will not show
 *
 */
const RefreshPrompt: React.FC<Props> = ({ disabled = false }) => {
  useEffect(() => {
    const unloadCallback = disabled
      ? () => null
      : (event) => {
          event.preventDefault();
          return (event.returnValue = ''); // for legacy & cross browser support
        };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, [disabled]);

  return <></>;
};

export default RefreshPrompt;

export const API_ORG_ROUTES = Object.freeze({
  ACCOUNTS: '/org/:organizationId/accounts',
  CARD: '/org/:organizationId/card/:cardId',
  CARDS: '/org/:organizationId/card',
  INVOICES: '/org/:organizationId/charges',
  ORG_ADDRESS: '/org/:organizationId/address',
  ORG_USERS: '/org/:organizationId/users',
  ORGANIZATION: '/org/:organizationId',
  ORGANIZATIONS: '/org',
  ROLE: '/org/:organizationId/role/:roleId',
  ROLES: '/org/:organizationId/role',
  USER_ACCESS: '/org/:organizationId/user-access',
});

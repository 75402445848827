import { css } from '@emotion/react';
import theme from 'shared/styles/themes';
import COLORS from 'shared/styles/colors';

export default css({
  '.credit-card-input': {
    '&__element': {
      padding: '0.75rem',
      borderRadius: '2px',
      border: `1px solid ${COLORS.NEUTRAL300}`,
    },
    '&__edit-display': {
      alignItems: 'center',
    },
    '&__card-name': {
      margin: theme.space[4],
    },
  },
});

import React, { useMemo } from 'react';
import cs from 'classnames';
import { uniqWith } from 'lodash';
import * as Yup from 'yup';

import Tags from 'shared/components/atoms/Tags';
import { Tag, TagCategory } from 'shared/components/atoms/Tags/templates';
import { EMPTY_TAG_VALUE } from 'shared/components/atoms/Tags/templates/TagCategory';
import { Box } from 'shared/components/display';
import { ArrayErrorMessage } from 'shared/components/FieldErrorMessage';
import tagInputStyles from 'shared/components/molecules/TagInput/tagInputStyles';

import { TAG_COPY } from 'shared/config/copy';
import { CONSTRAINT_LIMITS, MESSAGES } from 'shared/config/validations';
import { Input } from 'shared/lib/formik';
import { BrkfstTag } from 'shared/typings/tag';

// either an array with the value as the entries
// or can be an object with the value as a property
// with extra properties
type TagData = Pick<BrkfstTag, 'value' | 'category'>;

// Pure Function
function parseObjectTags(tags: string): TagData[] {
  return (tags ? JSON.parse(tags) : []).map((tag) => {
    const [category, value]: string[] = tag.value.includes(':') ? tag.value.split(':') : [tag.category, tag.value];
    return {
      ...tag,
      value: value.trim() || EMPTY_TAG_VALUE,
      category: category?.trim(),
    };
  });
}

export const getCategoryTagInputSchema = (categoryRequired: boolean) =>
  Yup.array(
    Yup.mixed().test({
      name: 'tag',
      message: MESSAGES.TAG_LENGTH,
      test(value): boolean | Yup.ValidationError {
        if (categoryRequired && !value.category) {
          return this.createError({
            message: TAG_COPY.VALIDATION_MISSING_TAG_CATEGORY,
          });
        }

        if (value.value === EMPTY_TAG_VALUE) {
          return this.createError({
            message: TAG_COPY.VALIDATION_MISSING_TAG_VALUE,
          });
        }

        return value.value?.length < CONSTRAINT_LIMITS.TAG_MAX_LENGTH;
      },
    }),
  );

type Props = {
  placeholder: string;
  className?: string;
  whitelist?: TagData[];
  duplicates?: boolean;
  categoryRequired?: boolean;
  disabled?: boolean;
};

const CategoryTagInput: Input<TagData[], Props> = ({
  field: { value, name, onChange, onBlur },
  form: { errors, touched },
  placeholder,
  whitelist,
  className,
  duplicates = false,
  categoryRequired = false,
  disabled = false,
}) => {
  const settings = useMemo(() => {
    return {
      templates: {
        tag: categoryRequired ? TagCategory : Tag,
      },
      keepInvalidTags: true,
      duplicates,
    };
  }, [duplicates, categoryRequired]);

  const handleChange = (e) => {
    let parsedTags = parseObjectTags(e.detail?.value);
    if (!duplicates)
      parsedTags = uniqWith(
        parsedTags,
        (tagA, tagB) =>
          tagA.value.toLowerCase() === tagB.value.toLowerCase() &&
          tagA.category?.toUpperCase() === tagB.category?.toUpperCase(),
      );
    let newTag = parsedTags[parsedTags.length - 1];

    // Convert the value to lowercase
    newTag = { value: newTag.value.toLowerCase(), category: newTag.category?.toUpperCase() };
    parsedTags[parsedTags.length - 1] = newTag;
    onChange({ target: { name, value: parsedTags } });
  };

  return (
    <Box data-focus-selector={name}>
      <Box
        data-focus-selector={name}
        css={tagInputStyles}
        className={cs(className, 'tagify-input', {
          'tagify-input--invalid': touched[name] && errors[name],
          'tagify-input--has-value': value?.length !== 0,
        })}
      >
        <Tags
          value={value}
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          settings={settings}
          onBlur={() => {
            onBlur({
              target: {
                name,
              },
            });
          }}
          whitelist={whitelist}
          readOnly={disabled}
        />
      </Box>
      <ArrayErrorMessage name={name} />
    </Box>
  );
};

export default CategoryTagInput;

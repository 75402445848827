import React, { useMemo } from 'react';
import { Button } from 'rebass/styled-components';

import AddFilesToCollection from 'features/media/AddFilesToCollection/AddFilesToCollection';
import VideoCompositionButton from 'features/media/components/molecules/VideoCompositionButton';
import { useFiles } from 'features/media/useFiles';
import { Flex } from 'shared/components/display';

import { COLLECTION_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';

import DownloadFilesButton from '../DownloadFilesButton';

import styles from './styles';

export interface SelectedFileActionsProps {
  selectedFileIds: number[];
  collectionId: number;
  isMarketerCollection?: boolean;
  downloadInfo?: {
    brandName: string;
    orgName: string;
    briefName?: string;
    collectionName: string;
  };
}
const SelectedFileActions: React.FC<SelectedFileActionsProps> = ({
  selectedFileIds,
  collectionId,
  isMarketerCollection = false,
  downloadInfo,
}) => {
  const { selectFiles, selectedFiles } = useFiles();
  const clearSelection = () => selectFiles([]);

  const selectedFileNames = useMemo(() => {
    return selectedFiles.map(({ name }) => name).join(', ');
  }, [selectedFiles]);

  return (
    <Flex css={styles} className="selected-file-actions">
      {isMarketerCollection && downloadInfo && (
        <>
          <DownloadFilesButton
            splitButtonVariant="borderless"
            selectedFiles={selectedFiles}
            downloadInfo={downloadInfo}
          />
          <VideoCompositionButton selectedFileNames={selectedFileNames} selectedFiles={selectedFiles} />
        </>
      )}
      <AddFilesToCollection
        selectedAssetIds={selectedFileIds}
        selectedFileNames={selectedFileNames}
        collectionId={collectionId}
      />
      <Button color={COLORS.NEUTRAL700} variant={BUTTON_VARIANTS.TEXT_ONLY} onClick={clearSelection}>
        {COLLECTION_COPY.BUTTON_CLEAR_FILE_SELECTION}
      </Button>
    </Flex>
  );
};

export default SelectedFileActions;

import React from 'react';
import cs from 'classnames';
import { Button, Flex } from 'shared/components/display';
import UpdateReportModalForm from 'features/accounts/components/organisms/UpdateReportModalForm/UpdateReportModalForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import PromptButton from 'shared/components/molecules/PromptButton';

import { BUTTON_VARIANTS } from 'shared/styles/button';
import { SIZING } from 'shared/config/formatting';
import { REPORT_COPY } from 'shared/config/copy';
import COLORS from 'shared/styles/colors';

import styles from './styles';

interface Props {
  report: any;
  onSelectReport: () => void;
  onDeleteReport: () => void;
  selected?: boolean;
  accountId: number;
  onClick?: () => void;
}
const ReportButton: React.FC<Props> = ({ report, onSelectReport, selected, onDeleteReport, accountId, onClick }) => {
  const handleReportClick = () => {
    onSelectReport();
    onClick && onClick();
  };

  return (
    <Flex css={styles} className="report-button">
      <Button onClick={handleReportClick} variant={BUTTON_VARIANTS.OPTION}>
        {report.name}
      </Button>
      {selected && (
        <Flex className={cs('report-button__btn-wrapper', 'endWithEllipsis')} color={COLORS.PRIMARY500}>
          <UpdateReportModalForm onClick={onClick} reportId={report.id} accountId={accountId} />

          <PromptButton
            modalSize={SIZING.MEDIUM}
            text={REPORT_COPY.MODAL_DELETE_REPORT}
            onConfirm={onDeleteReport}
            ariaLabel="Delete Report"
            variant={BUTTON_VARIANTS.TEXT_ONLY}
            className="report-button__delete-btn"
            onClick={onClick}
          >
            <FontAwesomeIcon key="delete-report-button" icon={faTimes} />
          </PromptButton>
        </Flex>
      )}
    </Flex>
  );
};

export default ReportButton;

import AUTH_ROUTES from 'shared/config/routes/authRoutes';
import ACCOUNT_ROUTES from 'shared/config/routes/brands/accountRoutes';
import BRAND_BRIEF_ROUTES from 'shared/config/routes/brands/briefRoutes';
import GENERAL_BRAND_ROUTES from 'shared/config/routes/brands/generalBrandRoutes';
import ORG_ROUTES from 'shared/config/routes/brands/orgRoutes';
import PLATFORM_ROUTES from 'shared/config/routes/brands/platformRoutes';
import BRAND_USER_ROUTES from 'shared/config/routes/brands/userRoutes';
import GENERAL_CREATOR_ROUTES from 'shared/config/routes/creators/generalCreatorRoutes';
import CREATOR_USER_ROUTES from 'shared/config/routes/creators/userRoutes';

export const GENERAL_ROUTES = {
  NO_ACCESS: '/no-access',

  NOT_FOUND: '/not-found',

  HOME_PAGE: '/',

  ERROR_PAGE: '/error',
};

export const ALL_ROUTES = {
  BRANDS: {
    ...AUTH_ROUTES,
    ...GENERAL_ROUTES,
    ...GENERAL_BRAND_ROUTES,
    ...ACCOUNT_ROUTES,
    ...ORG_ROUTES,
    ...PLATFORM_ROUTES,
    ...BRAND_BRIEF_ROUTES,
    ...BRAND_USER_ROUTES,
  },
  CREATORS: {
    ...AUTH_ROUTES,
    ...GENERAL_ROUTES,
    ...GENERAL_CREATOR_ROUTES,
    ...CREATOR_USER_ROUTES,
  },
};

import React from 'react';
import { useParams } from 'react-router-dom';

import AccountCreationForm from 'features/accountPlatforms/components/molecules/AccountPlatformCreation';
import PlatformSnippet from 'features/accountPlatforms/facebook/components/molecules/PlatformSnippet';
import accountPlatformsStyles from 'features/accountPlatforms/pages/AccountPlatforms/accountPlatformsStyles';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import { Box, Flex, Text } from 'shared/components/display';

import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { usePermissions } from 'shared/hooks/usePermissions';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';

const { BODY } = TEXT_VARIANTS;

const AccountPlatforms = () => {
  const params = useParams<{ organizationId: string; accountId: string }>();

  const organizationId = +params.organizationId;
  const accountId = +params.accountId;
  const { accountPlatforms, platformsLoading } = useAccountPlatforms({ accountId });
  const { displayComponent } = usePermissions();

  const { loading: accountLoading } = useAccounts({ accountId: params.accountId });

  const showPlaceholder = accountPlatforms.length < 1 && !platformsLoading;

  return accountLoading ? (
    <CenteredSpinnerContainer />
  ) : (
    <Flex css={accountPlatformsStyles} className="account-platforms">
      <Flex className="account-platforms__wrapper">
        {accountPlatforms.map(({ id }) => (
          <PlatformSnippet key={id} accountPlatformId={id} accountId={accountId} organizationId={organizationId} />
        ))}
        {/* @ts-ignore */}
        {platformsLoading && <PlatformSnippet key={0} loading={true} />}
      </Flex>

      {showPlaceholder && (
        <Box className={CLASSNAMES.MAIN_CONTAINER}>
          <Text variant={BODY}>{ACCOUNT_PLATFORM_COPY.PLACEHOLDER_PLATFORMS}</Text>
        </Box>
      )}
      <Box className="account-platforms__wrapper">
        {displayComponent(<AccountCreationForm />, organizationId, PERMISSIONS_MAP.ADD_ACCOUNT_PLATFORM.API)}
      </Box>
    </Flex>
  );
};

export default AccountPlatforms;

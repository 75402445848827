import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '.brief-form': {
    '&__hr': {
      marginTop: '1rem',
    },
    '&__header p': {
      fontSize: '24px',
    },
    '&__section-header': {
      marginTop: theme.space[4],
    },
    '&__button-wrapper': {
      marginTop: theme.space[4],
      position: 'sticky',
      bottom: '0',
      justifyContent: 'center',
      zIndex: zIndex.zStickyElements,
      textAlign: 'center',
      paddingTop: '20px',
      [mediaquery.md]: {
        position: 'relative',
        justifyContent: 'left',
        textAlign: 'left',
        paddingTop: '0',
      },
    },
    '&__button-wrapper--open': {
      marginTop: theme.space[4],
      backgroundColor: COLORS.WHITE,
    },
    '&__button-wrapper--open  > *:not(:last-child), &__button-wrapper  > *:not(:last-child)': {
      marginRight: theme.space[0],
      [mediaquery.md]: {
        marginRight: theme.space[5],
      },
    },
    '&__draft-btn': {
      marginRight: theme.space[3],
      marginBottom: theme.space[2],
      backgroundColor: COLORS.WHITE,
    },
    '&__invite-only .check-box__label, &__shipping-required .check-box__label, &__creative-only .check-box__label, &__inshop-required .check-box__label':
      {
        fontSize: '16px',
      },
    '&__invite-only, &__shipping-required, &__creative-only, &__inshop-required': {
      marginLeft: '2px',
    },
  },
});

export const briefDurationStyles = css({
  '&.brief-duration': {
    maxWidth: '800px',
  },
  '.brief-duration': {
    '&__wrapper': {
      marginTop: theme.space[4],
    },
    '&__header': {
      marginTop: theme.space[4],
    },
  },
});

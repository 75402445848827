import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'rebass/styled-components';

import styled from 'styled-components';
import theme from 'shared/styles/themes';
import { TEXT_VARIANTS } from 'shared/styles/text';

const { colors } = theme;

const ActionLink = ({ to, defaultText, actionText, ...styleProps }) => {
    const [text, setText] = useState(defaultText);

    const onMouseEnter = () => setText(actionText);

    const onMouseLeave = () => setText(defaultText);

    return (
        <StyledLink to={to}>
            <StyledText
                variant={TEXT_VARIANTS.SUBHEADING_SM}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                {...styleProps}
            >
                {text}
            </StyledText>
        </StyledLink>
    );
};

const StyledLink = styled(Link)`
    color: ${colors.PRIMARY500};
    height: 100%;
    display: inline-block;
`;

const StyledText = styled(Text)`
    color: ${colors.PRIMARY500};
    display: flex;
    align-items: ${({ alignItems }) => alignItems || 'center'};
    justify-content: ${({ justifyContent }) => justifyContent || 'center'};
    text-align: ${({ textAlign }) => textAlign || 'center'};
    height: 100%;
    width: ${({ width }) => width || '15rem'};
    white-space: ${({ wrapText }) => !wrapText && 'nowrap'};
`;

export default ActionLink;

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cs from 'classnames';

import BrandApprovedBadge from 'features/users/components/atoms/BrandApprovedBadge';
import EliteCreatorBadge from 'features/users/components/atoms/EliteCreatorBadge';
import Tag from 'shared/components/atoms/Tag';
import TruncatedFlex from 'shared/components/atoms/TruncatedFlex';
import { Box, Flex, Text } from 'shared/components/display';

import COLORS from 'shared/styles/colors';
import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

import creatorInfoStyles from './creatorInfoStyles';
import SpotlightCreatorBadge from 'features/users/components/atoms/SpotlightCreatorBadge';

interface Props {
  creator: BrkfstUser;
}
const CreatorInfo: React.FC<Props> = ({ creator }) => {
  const profileLink = LinkCreator.createLink({
    userType: UserType.ORG,
    routeKey: 'USER_PROFILE',
    variables: {
      userId: creator.id,
    },
  });

  const { approvedAssetBadge, isElite, isSpotlighted } = creator;

  const { tags, hasTags } = useMemo(() => {
    return {
      tags: creator.creatorTags,
      hasTags: creator.creatorTags.length > 0,
    };
  }, [creator.creatorTags]);

  return (
    <Link to={profileLink} target="_blank" data-cy={`creator-info__link-${creator.id}`}>
      {' '}
      <Box
        className={cs('creator-info', {
          'creator-info--has-tags': hasTags,
        })}
        css={creatorInfoStyles(creator.profilePic?.url && `"${creator.profilePic?.url}"`)}
        data-cy={`creator-info-${creator.id}`}
      >
        <Box className="creator-info__pic" />
        <Box className="creator-info__content-wrapper">
          <TruncatedFlex
            className="creator-info__tags"
            maxRows={3}
            EllipsisComponent={hasTags ? <Tag theme="compact">... more</Tag> : <></>}
            ellipsisWidth={66}
            key={creator.id}
          >
            {tags.map((tag) => (
              <Tag theme="compact" color={COLORS.PRIMARY800} key={tag.id}>
                {tag.value}
              </Tag>
            ))}
          </TruncatedFlex>

          <Box className="creator-info__name-icons_wrapper">
            <Text as="h5" className={cs('creator-info__name', 'endWithEllipsis')}>
              {creator.fullName}
            </Text>
            <Flex className="creator-info__icons_wrapper">
              {approvedAssetBadge && <BrandApprovedBadge showTooltip showHoverBox />}
              {isElite && <EliteCreatorBadge showTooltip showHoverBox />}
              {isSpotlighted && <SpotlightCreatorBadge showTooltip showHoverBox />}
            </Flex>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default CreatorInfo;

import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { BrkfstAction } from 'shared/typings/redux';
import { Trend } from 'shared/typings/trends';

type TrendsState = {
  data: Trend[];
  total: number;
  pinnedTotal: number;
};

const initialState: TrendsState = {
  data: [],
  total: 0,
  pinnedTotal: 0,
};

type TrendAction<T = Trend> = BrkfstAction<T>;

export const trendsSlice = createSlice<TrendsState, SliceCaseReducers<TrendsState>>({
  name: 'trends',
  initialState,
  reducers: {
    trendsLoaded: (state, action: TrendAction<{ results: []; total: number; pinnedTotal: number }>) => {
      state.data = action.payload.data.results;
      state.total = action.payload.data.total;
      state.pinnedTotal = action.payload.data.pinnedTotal;
    },
    trendAdded: (state, action: TrendAction) => {
      state.data.push(action.payload.data);
    },
    trendRemoved: (state, action: TrendAction) => {
      state.data = state.data.filter((trend) => trend.id !== action.payload.data.id);
      if (action.payload.data.isPinned) {
        state.pinnedTotal -= 1;
      }
    },
    trendUpdated: (state, action: TrendAction) => {
      state.data = state.data.map((trend) => {
        if (trend.id === action.payload.data.id) {
          return {
            ...trend,
            ...action.payload.data,
          };
        }
        return trend;
      });
    },
    trendPinned: (state, action: TrendAction) => {
      state.pinnedTotal += 1;
      state.data = state.data.map((trend) => {
        if (trend.id === action.payload.data.id) {
          return {
            ...trend,
            ...action.payload.data,
            isPinned: true,
          };
        }
        return trend;
      });
    },
    trendUnpinned: (state, action: TrendAction) => {
      state.pinnedTotal -= 1;
      state.data = state.data.map((trend) => {
        if (trend.id === action.payload.data.id) {
          return {
            ...trend,
            ...action.payload.data,
            isPinned: false,
          };
        }
        return trend;
      });
    },
    trendThumbnailUpdated: (
      state,
      action: PayloadAction<{
        fileId: number;
        thumbnailUrl: string;
        thumbnailOffset: number;
      }>,
    ) => {
      state.data = state.data.map((trend) => {
        if (trend.file.id === action.payload.fileId) {
          return {
            ...trend,
            file: {
              ...trend.file,
              thumbnailUrl: action.payload.thumbnailUrl,
              thumbnailOffset: action.payload.thumbnailOffset,
            },
          };
        }
        return trend;
      });
    },
  },
});

export const {
  trendsLoaded,
  trendAdded,
  trendRemoved,
  trendUpdated,
  trendPinned,
  trendUnpinned,
  trendThumbnailUpdated,
} = trendsSlice.actions;

export const getTrends = (state: { trends: TrendsState }): Trend[] => state.trends.data;

export const getTrend =
  (id?: number) =>
  (state: { trends: TrendsState }): Trend | undefined =>
    state.trends.data.find((trend) => trend.id === id);

export const getTrendsTotal = (state: { trends: TrendsState }): number => state.trends.total;

export const getPinnedCount = (state) => {
  return state.trends.pinnedTotal;
};

import { css, keyframes } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

const expand = keyframes({
  from: {
    maxHeight: 0,
  },
  to: {
    maxHeight: '500px',
  },
});

export default css({
  backgroundColor: COLORS.NEUTRAL50,
  position: 'fixed',
  zIndex: zIndex.zDrawer,
  bottom: 0,
  width: '350px',
  padding: '10px',
  borderTop: '1px solid white',
  borderRadius: '10px',
  maxHeight: '0',
  boxShadow: '10px 15px 27px -2px',
  left: '10px',
  [mediaquery.md]: {
    width: '500px',
  },
  '&.survey-drawer--open': {
    animation: `${expand} 2s`,
    animationDelay: '0s',
    animationFillMode: 'forwards',
  },
  '.survey-drawer': {
    '&--open': {
      transform: 'translateY(0)',
    },
    '&__close': {
      position: 'absolute',
      top: '8px',
      right: '8px',
      cursor: 'pointer',
    },
    '&__title': {
      marginBottom: '20px',
      textAlign: 'center',
    },
    '&__form': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    '&__form-container': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
    },
    '&__label': {
      marginBottom: '5px',
    },
    '&__thumbs': {
      display: 'flex',
      gap: '20px',
      fontSize: '24px',
    },
    '&__npsscore': {
      display: 'flex',
      gap: '10px',
      fontSize: '24px',
    },
    '&__thumb': {
      cursor: 'pointer',
      color: COLORS.NEUTRAL500,
      transition: 'color 0.2s',
      '&--selected': {
        color: COLORS.PRIMARY500,
      },
      '&:hover': {
        color: COLORS.PRIMARY300,
      },
    },
    '&__nps': {
      paddingLeft: theme.space[3],
      paddingRight: theme.space[3],
      '&--selected, &:focus, &:focus svg, &:not(:disabled):focus': {
        backgroundColor: `${COLORS.PRIMARY500}`,
        borderRadius: '5px',
        color: `${COLORS.NEUTRAL50}`,
      },
      '&:hover': {
        backgroundColor: `${COLORS.PRIMARY300}`,
        borderRadius: '5px',
        color: `${COLORS.NEUTRAL50}`,
      },
    },
    '&__textarea': {
      padding: '10px',
      border: `1px solid ${COLORS.NEUTRAL500}`,
      borderRadius: '5px',
    },
    '&__button': {
      width: '100%',
      padding: '10px 20px',
      backgroundColor: COLORS.PRIMARY500,
      color: COLORS.NEUTRAL50,
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      marginTop: '10px',
      '&:hover': {
        backgroundColor: COLORS.PRIMARY600,
      },
    },
  },
});

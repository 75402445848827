import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.trend-form': {},
  '.trend-form': {
    '&__subheader': {
      color: COLORS.NEUTRAL600,
      marginBottom: theme.space[2],
    },
    '&__button-wrapper': {
      borderTop: `1px solid ${COLORS.NEUTRAL300}`,
      padding: `${theme.space[4]} 0`,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      marginTop: theme.space[4],
    },
    '&__btn': {
      marginLeft: theme.space[2],
      fontWeight: 500,
      borderRadius: '5px',
    },
    '&__delete-btn': {
      backgroundColor: COLORS.PRIMARY100,
      color: COLORS.PRIMARY500,
    },
    '&__submit-btn': {
      backgroundColor: COLORS.PRIMARY500,
      color: COLORS.NEUTRAL0,
      '&:not(:disabled):hover, &:not(:disabled):focus': {
        color: COLORS.NEUTRAL0,
      },
    },
  },
});

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import SHADOWS from 'shared/styles/shadow';
import theme from 'shared/styles/themes';

export const inlineButtonsStyle = css({
  '&.inline-buttons': {
    display: 'flex',
    position: 'absolute',
    right: 0,
    zIndex: zIndex.zButton,
    '&--textarea': {
      bottom: `-18px`,
    },
  },
  '.inline-buttons': {
    '&__btn': {
      marginTop: '0.25rem',
      marginLeft: 0,
      marginRight: '0.25rem',
      borderRadius: '4px',
      boxShadow: SHADOWS.SHADOW02,
      fontSize: '0.875rem',
      padding: '0.5rem',
      alignContent: 'center',
      display: 'flex',
      border: `1px solid ${COLORS.NEUTRAL200}`,
      backgroundColor: 'white',
      color: COLORS.BLACK,
    },
    '&__btn:hover': {
      backgroundColor: COLORS.NEUTRAL50,
    },
  },
});

export default css({
  '&.inline-input': {
    position: 'relative',
  },
  '.inline-input': {
    '&__label': {
      marginBottom: theme.space[2],
      marginTop: theme.space[4],
      color: COLORS.NEUTRAL500,
    },
    '&__char-remaining': {
      marginBottom: theme.space[2],
      marginTop: theme.space[2],
    },
    '&__char-remaining--hidden': {
      visibility: 'hidden',
    },
    '&__at': {
      color: COLORS.NEUTRAL300,
      position: 'absolute',
      top: '51px',
      left: '10px',
      fontWeight: 'bold',
    },
    '&__at--active': {
      color: COLORS.BLACK,
    },
  },
  '#inline-input__sm-input': {
    paddingLeft: '28px',
  },
  '.inline-input__input': {
    padding: '0.5rem 0.75rem',

    '&:not(:disabled):hover, &:focus': {
      backgroundColor: COLORS.PRIMARY100,
    },
    '&--invalid:not(:disabled):hover, &--invalid:focus': {
      backgroundColor: COLORS.RED50,
    },
    '&::placeholder': {
      color: COLORS.NEUTRAL300,
    },
    '&:disabled': {
      backgroundColor: 'white',
      color: 'initial',
    },
  },
});

import React, { useState } from 'react';
import { Button } from 'rebass/styled-components';
import { faCalendarDay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box, Flex, Text } from 'shared/components/display';
import CalendarDaySelector from 'shared/components/molecules/CalendarDaySelector/CalendarDaySelector';
import Modal from 'shared/components/molecules/Modal';
import dayPickerModalStyles from 'shared/components/organisms/DayPickerModal/dayPickerModalStyles';

import { SIZING } from 'shared/config/formatting';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { prettyDate } from 'shared/utilities/dateUtility';

interface Props {
  title: string;
  onSelect: (day: Date) => void;
  onClick?: () => void;
  initialDate?: Date;
  onlyAllowFutureDateSelection?: boolean;
  disabled?: boolean;
  showDate?: boolean;
  allowFutureDates?: boolean;
  showDropdown?: boolean;
}

const DayPickerModal: React.FC<Props> = ({
  title,
  onSelect,
  onClick,
  initialDate,
  onlyAllowFutureDateSelection,
  disabled = false,
  showDate = true,
  allowFutureDates = false,
  showDropdown = false,
}) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<Date | undefined>(initialDate);

  const toggleOpen = () => setOpen((prev) => !prev);

  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  const handleSelect = (day) => {
    setDate(day);
    onSelect(day);
    toggleOpen();
  };

  return (
    <Flex className="day-picker-modal" css={dayPickerModalStyles} onClick={handleClick}>
      <Button
        className="day-picker-modal__btn"
        disabled={disabled}
        onClick={toggleOpen}
        variant={BUTTON_VARIANTS.TEXT_ONLY}
        type="button"
      >
        <FontAwesomeIcon icon={faCalendarDay} />
      </Button>
      {showDate && <Text as="span">{date ? prettyDate(date) : '-'}</Text>}
      <Modal
        isOpen={open}
        onRequestClose={toggleOpen}
        title={title}
        modalSize={SIZING.SMALL}
        component={
          <Box className="day-picker-modal__modal-content" css={dayPickerModalStyles} onClick={handleClick}>
            <CalendarDaySelector
              onSelect={handleSelect}
              selectedDay={date}
              onlyAllowFutureDateSelection={onlyAllowFutureDateSelection}
              allowFutureDates={allowFutureDates}
              showDropdown={showDropdown}
            />
          </Box>
        }
      />
    </Flex>
  );
};

export default DayPickerModal;

import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import cs from 'classnames';

import { GalleryItem } from 'features/media/interfaces/gallery';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { ProfilePicture } from 'features/users/components/molecules/ProfilePicture';
import { useShowcaseAssets } from 'features/users/hooks/useShowcaseAssets';
import BackButton from 'shared/components/atoms/BackButton';
import { Box, Flex, Text } from 'shared/components/display';
import ToggleGalleryListView from 'shared/components/molecules/ToggleGalleryListView';
import MediaGalleryAndListDisplay from 'shared/components/organisms/MediaGalleryAndListDisplay';
import Pagination from 'shared/components/organisms/Pagination';

import { CREATOR_COPY } from 'shared/config/copy';
import { useQuery } from 'shared/hooks/useQuery';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import { intl } from 'shared/lib/intl';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';

const fileParams = {
  page: 1,
  size: 10,
};

interface Props {
  user: BrkfstUser;
  brandView?: boolean;
}

const CreatorShowcase: React.FC<Props> = ({ user, brandView = false }) => {
  const { userId } = useParams<{ userId: string }>();
  const { getCreatorApprovedAssets, files, totalFiles, loading, clearAssets, toggleLoading, loadPinnedCount } =
    useShowcaseAssets();
  const { getQuery } = useQuery();
  const query = getQuery();
  const searchParams = useSearchParams(query, fileParams);

  const linkToProfile = LinkCreator.createLink({
    userType: brandView ? UserType.ORG : UserType.CREATOR,
    routeKey: 'USER_PROFILE',
    variables: {
      userId: user.id,
    },
  });

  useEffect(() => {
    getCreatorApprovedAssets(+userId, searchParams);
    return () => {
      clearAssets();
      toggleLoading(true);
    };
  }, [searchParams, userId]);

  useEffect(() => {
    loadPinnedCount(+userId);
  }, [userId]);

  const formatGalleryItems = (file): GalleryItem => ({
    itemObject: file,
    captionData: { ...file, name: file.brandName },
  });

  return (
    <Box css={styles} className="creator-showcase">
      <BackButton
        to={linkToProfile}
        text={CREATOR_COPY.BUTTON_BACK_TO_PROFILE}
        className="creator-showcase__back-btn"
      />
      <Flex className="creator-showcase__creator">
        <ProfilePicture user={user} size="sm" customProfileLink={linkToProfile} />
        <Flex className="creator-showcase__page-title">
          <Link to={linkToProfile} className={cs('creator-showcase__page-title-text', 'creator-showcase__name')}>
            {`${user.firstName} ${user.lastName}`}
          </Link>
          <Text variant={TEXT_VARIANTS.H5} className="creator-showcase__page-title-text">
            {intl.formatMessage(
              {
                id: 'HEADING_CREATOR_SHOWCASE',
              },
              {
                creatorName: user.firstName,
              },
            )}
          </Text>
        </Flex>
      </Flex>
      <Box className={cs(CLASSNAMES.PADDING_CONTAINER, 'creator-showcase__content-wrapper')}>
        <Flex className={cs('creator-showcase__header')}>
          <ToggleGalleryListView />
        </Flex>
        {loading ? (
          <CenteredSpinner />
        ) : (
          <Pagination totalAmount={totalFiles} searchParams={searchParams} loading={loading} pageSizeBase={10}>
            <MediaGalleryAndListDisplay
              data={files}
              placeholderText={CREATOR_COPY.PLACEHOLDER_SHOWCASE}
              formatGalleryItems={formatGalleryItems}
              useBrandName
              showPin
            />
          </Pagination>
        )}
      </Box>
    </Box>
  );
};

export default CreatorShowcase;

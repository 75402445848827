import React, { useLayoutEffect, useRef, useState } from 'react';
import cs from 'classnames';
import { Box, Text } from 'shared/components/display';
import styles from './styles';
import ModuleCommentForm from 'features/submissions/components/molecules/ModuleCommentForm';
import { ModuleComment as ModuleCommentType } from 'shared/typings/moduleComments';
import ModuleComment from 'features/submissions/components/molecules/ModuleComment';
import IconWithNotification from 'shared/components/atoms/IconWithNotification';
import { faComment } from '@fortawesome/pro-solid-svg-icons';
import ReviewOverlayModal from 'shared/components/molecules/ReviewOverlayModal';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { intl } from 'shared/lib/intl';
import { SUBMISSION_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { ModuleCommentValues } from 'features/submissions/components/molecules/ModuleCommentForm/config';
import { FormikHelpers } from 'formik';
import { useReviewModules } from 'features/reviewModules/useReviewModules';

interface Props {
  submissionModuleId: number;
  comments?: ModuleCommentType[];
  setOpen: (open: boolean) => void;
  open?: boolean;
  className?: string;
  mediaWidth: number;
  moduleNum: number;
}
const ModuleCommentsModal: React.FC<Props> = ({
  submissionModuleId,
  comments = [],
  setOpen,
  open,
  className,
  mediaWidth,
  moduleNum,
}) => {
  const { updateModuleComment, deleteModuleComment, createModuleComment } = useReviewModules();
  const { currentUser, isSuperadmin } = useCurrentUser();
  const newestComment = useRef<HTMLElement>(null);

  const onCreateComment = (values: ModuleCommentValues, formikHelpers: FormikHelpers<ModuleCommentValues>) => {
    createModuleComment({
      submissionModuleId,
      moduleComment: {
        authorId: currentUser.id,
        comment: values.comment,
      },
      onFinish: () => {
        formikHelpers.resetForm();
      },
    });
  };

  useLayoutEffect(() => {
    newestComment.current?.scrollIntoView({ behavior: 'smooth' });
  }, [comments]);

  return (
    <>
      <IconWithNotification
        icon={faComment}
        size="xl"
        count={comments?.length || 0}
        className={className}
        onClick={() => setOpen(true)}
        dataCy="module-comments-modal__btn"
      />
      <ReviewOverlayModal
        ariaLabel="Module Comments"
        id="module-comments-modal"
        isOpen={open}
        onRequestClose={setOpen}
        mediaWidth={mediaWidth}
      >
        <Box css={styles} className={cs('module-comments-modal')} data-cy="module-comments-modal">
          <Text variant={TEXT_VARIANTS.H4} className="module-comments-modal__header">
            {intl.formatMessage(
              { id: 'CAPTION_REVIEW_COMMENT_HEADER' },
              {
                moduleNum,
              },
            )}
          </Text>
          <Box className="module-comments-modal__comments-wrapper">
            {comments.length < 1 ? (
              <Text className="module-comments-modal__comment-placeholder" variant={TEXT_VARIANTS.BODY_SM}>
                {SUBMISSION_COPY.PLACEHOLDER_REVIEW_COMMENTS}
              </Text>
            ) : (
              comments.map((comment, index) => (
                <ModuleComment
                  key={comment.id}
                  ref={index === comments.length - 1 ? newestComment : undefined}
                  comment={comment}
                  onDelete={(commentId: number, onFinish: () => void) =>
                    deleteModuleComment(commentId, submissionModuleId, onFinish)
                  }
                  onEdit={(data: string, onFinish: () => void) =>
                    updateModuleComment(comment.id, submissionModuleId, data, onFinish)
                  }
                  userIsAuthor={currentUser.id === comment.authorId}
                  isSuperadmin={isSuperadmin}
                />
              ))
            )}
          </Box>
          <ModuleCommentForm onSubmit={onCreateComment} />
        </Box>
      </ReviewOverlayModal>
    </>
  );
};

export default ModuleCommentsModal;

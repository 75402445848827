import React, { useEffect, useLayoutEffect, useRef } from 'react';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import CreatorBriefView from 'features/briefs/components/molecules/CreatorBriefView';
import MobileBriefCreatorButtons from 'features/briefs/components/molecules/MobileBriefCreatorButtons';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import ResponsiveDisplay from 'shared/components/atoms/ResponsiveDisplay';
import { Box, Flex } from 'shared/components/display';
import FileListView, { FileAction } from 'shared/components/molecules/FileListView/FileListView';
import { Tab, TabBar } from 'shared/components/molecules/Tabs';

import { BRIEF_COPY } from 'shared/config/copy';
import { Brief } from 'shared/typings/briefs';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';

import styles from './creatorBriefTabsStyles';

interface Props {
  loading: boolean;
  brief: Brief;
  setHeight?: (number) => void;
}

const CreatorBriefTabs: React.FC<Props> = ({ loading, brief, setHeight }) => {
  const { brandAssets, fetchBrandAssets, loadingBrandAsset } = useAccounts({
    accountId: brief?.accountId.toString(),
  });

  const ref = useRef<HTMLDivElement>(null);
  const isApproved = [UserBriefStatus.ACTIVE, UserBriefStatus.SUBMITTED].includes(brief?.briefCreatorStatus);

  useEffect(() => {
    if (brief?.accountId) fetchBrandAssets(brief?.accountId.toString());
  }, [brief?.accountId]);

  useLayoutEffect(() => {
    if (ref.current && setHeight) {
      setHeight(ref.current.clientHeight);
    }
  }, [ref.current?.clientHeight, setHeight]);

  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <Box css={styles}>
      <TabBar className="brief-tabs">
        <Tab title={BRIEF_COPY.TAB_TITLE_BRIEF}>
          {brief ? <CreatorBriefView currentBrief={brief} loading={loading} /> : <></>}
          <ResponsiveDisplay display="mobile">
            <Flex className="brief-tabs__creator-buttons" ref={ref}>
              <MobileBriefCreatorButtons brief={brief} />
            </Flex>
          </ResponsiveDisplay>
        </Tab>
        <Tab title={BRIEF_COPY.TAB_TITLE_BRIEF_ASSETS} hidden={!brief?.brandAssets.length || !isApproved}>
          <Box className="brief-tabs__assets-wrapper">
            <FileListView
              files={brief?.brandAssets}
              action={FileAction.DOWNLOAD}
              zipFolderName={`${brief?.name} Assets`}
              showDownloadAll
              showFilePreview
            />
          </Box>
        </Tab>
        <Tab
          title={BRIEF_COPY.TAB_TITLE_BRAND_GUIDELINES}
          hidden={loadingBrandAsset || !brandAssets.length || !isApproved}
        >
          <Box className="brief-tabs__assets-wrapper">
            <FileListView
              files={brandAssets}
              action={FileAction.DOWNLOAD}
              zipFolderName={`${brief?.name} Brand Guidelines`}
              showDownloadAll
              showFilePreview
            />
          </Box>
        </Tab>
      </TabBar>
    </Box>
  );
};

export default CreatorBriefTabs;

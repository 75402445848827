import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

// @ts-ignore
export default css({
  flexDirection: 'column',
  overflow: 'hidden',
  '.earnings-mobile-table': {
    '&__header': {
      position: 'relative',
      height: '100px',
      textTransform: 'uppercase',
    },
    '&__total-earnings-label': {
      margin: 0,
    },
    '&__total-earnings': {
      wordBreak: 'break-all',
      lineHeight: '1',
      margin: '2px',
      width: '72%',
    },
    '&__tax-legend': {
      fontSize: '0.54rem',
      fontWeight: 600,
      color: COLORS.NEUTRAL300,
      display: 'block',
    },
    '&__stripe-wrapper': {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
});

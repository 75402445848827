import { isArray } from 'lodash';

import { MEDIA } from './media';

/*
 * Ad Platform Specific Support
 */

// MIME Types and Extensions
export const AD_SUPPORTED_VIDEO_FORMATS: readonly string[] = Object.freeze([
  MEDIA.MIME_TYPES.MP4,
  MEDIA.MIME_TYPES.MOV,
  MEDIA.MIME_TYPES.M4V,
]);
export const AD_SUPPORTED_GIF_FORMATS: string[] = [MEDIA.MIME_TYPES.GIF];
export const AD_SUPPORTED_IMAGE_FORMATS: readonly string[] = Object.freeze([
  MEDIA.MIME_TYPES.PNG,
  MEDIA.EXTENSIONS.JPEG,
  MEDIA.EXTENSIONS.JPG,
]);

// All Platform Supported Formats
// TODO: Split by ad platform when ready
export const ALL_AD_SUPPORTED_FILE_FORMATS: readonly string[] = Object.freeze([
  ...AD_SUPPORTED_VIDEO_FORMATS,
  ...AD_SUPPORTED_GIF_FORMATS,
  ...AD_SUPPORTED_IMAGE_FORMATS,
]);

/*
 * General Brkfst Support
 */

// MIME Types and Extensions
export const BRKFST_SUPPORTED_VIDEO_FORMATS: readonly string[] = Object.freeze([
  MEDIA.MIME_TYPES.MP4,
  MEDIA.MIME_TYPES.MOV,
]);

// Note: GIF is not included in this list
export const BRKFST_SUPPORTED_IMAGE_FORMATS: readonly string[] = Object.freeze([
  MEDIA.MIME_TYPES.JPEG,
  MEDIA.MIME_TYPES.PNG,
  MEDIA.MIME_TYPES.APNG,
  MEDIA.MIME_TYPES.SVG,
  MEDIA.MIME_TYPES.WEBP,
]);

// Miscellaneous formats
export const BRKFST_SUPPORTED_MISC_FORMATS: readonly string[] = Object.freeze([
  MEDIA.MIME_TYPES.PDF,
  ...MEDIA.MIME_TYPES.EPS,
  MEDIA.MIME_TYPES.TTF,
  MEDIA.MIME_TYPES.AI,
  ...MEDIA.MIME_TYPES.PSD,
]);

// All General Supported Formats
export const ALL_BRKFST_SUPPORTED_FILE_FORMATS: readonly string[] = Object.freeze([
  ...BRKFST_SUPPORTED_VIDEO_FORMATS,
  ...BRKFST_SUPPORTED_IMAGE_FORMATS,
  MEDIA.MIME_TYPES.GIF,
  ...BRKFST_SUPPORTED_MISC_FORMATS,
]);

// format mimetype or extension for display
export const formatFileTypes = (fileFormats) => {
  if (isArray(fileFormats)) {
    return fileFormats
      .map((format) => {
        if (format === MEDIA.MIME_TYPES.SVG) return 'svg';
        if (format === MEDIA.MIME_TYPES.M4V) return 'm4v';
        if (format === MEDIA.MIME_TYPES.MOV) return 'mov';
        // remove first part of mime type string (e.g. 'image/' or 'video/')
        if (format.includes('/')) return format.split('/')[1];
        // remove '.' from extension (e.g. '.png' -> 'png')
        if (format.includes('.')) return format.split('.')[1];
        else return format;
      })
      .join(', ');
  } else if (typeof fileFormats === 'string') {
    if (fileFormats.includes('/')) return fileFormats.split('/')[1];
    if (fileFormats.includes('.')) return fileFormats.split('.')[1];
    else return fileFormats;
  }
};

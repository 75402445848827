import { generateConstKeyObj } from 'shared/utilities/objectUtility';

import COLORS from 'shared/styles/colors';
import SHADOWS from 'shared/styles/shadow';

const { NEUTRAL100, NEUTRAL200, WHITE } = COLORS;
const { SHADOW06 } = SHADOWS;

export const CLASSNAMES = {
  MAIN_CONTAINER: 'mainContainer',
  CENTERED_CONTAINER: 'centeredContainer',
  PADDING_CONTAINER: 'paddingContainer',
  MARGIN_CONTAINER: 'marginContainer',
  PUBLIC_CONTAINER: 'publicContainer',
};

const variants = {
  bordered: {
    border: `1px solid ${NEUTRAL200}`,
    bg: WHITE,
  },
  borderedRadius: {
    border: `1px solid ${NEUTRAL100}`,
    borderRadius: [2],
    bg: WHITE,
  },
  circle: {
    borderRadius: '50%',
    width: 'min-content',
  },
  card: {},
  tile: {
    border: `1px solid ${NEUTRAL100}`,
    borderRadius: [2],
    '&:hover': {
      cursor: 'pointer',
      boxShadow: SHADOW06,
    },
  },
  // TO DO STYLES: add styles to default Rebass Link theme
  // UserOrgDisplay
  link: {},
  imageBg: {
    backgroundSize: 'cover',
    objectFit: 'cover',
    backgroundColor: WHITE,
    border: `1px solid ${NEUTRAL100}`,
  },
  overlayBg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  overlayChild: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  spaceOutChildren: {
    flexWrap: ['wrap', 'wrap', 'nowrap'],
    bg: WHITE,
    '& > *:not(:last-child)': {
      mr: [0, 0, 5],
    },
  },
  spaceOutChildrenVertical: {
    '& > *:not(:last-child)': {
      mb: 5,
    },
  },
  flexDefault: {
    width: '100%',
    flexWrap: 'wrap',
  },
  flexStart: {
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
  },
  flexEnd: {
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  flexCenter: {
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  flexBetween: {
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  flexAround: {
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  flexEvenly: {
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  grid: {
    display: 'grid',
  },
};

export const CONTAINER_VARIANTS: { [key: string]: any } = generateConstKeyObj(variants);

export default variants;

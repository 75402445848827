import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import config from 'config';

import { componentLoadingToggled, errorCleared, errorTriggered, getErrors } from 'features/ui/ui.slice';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_STRIPE_ROUTES } from 'shared/config/routes/api/apiStripeRoutes';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';

export const useStripe = (shouldFetchStripe = false) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useComponentLoading(RESOURCES.STRIPE, false);
  const [checkoutError] = useSelector(getErrors(RESOURCES.CHECKOUT));
  const [stripeObject, setStripeObject] = useState<Promise<Stripe | null> | null>(null);

  useEffect(() => {
    const fetchStripeObject = () => {
      const res = loadStripe(config.STRIPE_PUBLIC_KEY);
      setStripeObject(res);
    };
    if (shouldFetchStripe) {
      fetchStripeObject();
    }
  }, []);

  const fetchExpressDashboardLink = async (onSuccess) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.STRIPE_DASHBOARD,
        },
        onSuccess,
      }),
    );
  };

  const clearCheckoutError = () => {
    dispatch(errorCleared({ entity: RESOURCES.CHECKOUT }));
  };

  const onCheckout = async ({
    organizationId,
    invoice,
    checkoutInvoiceHasPromoCode,
    confirmationPageProps,
    successRedirectRoute,
    stripe,
    onCheckoutError,
    onCheckoutSuccess,
    clientSecret,
  }) => {
    dispatch(
      componentLoadingToggled({
        component: RESOURCES.STRIPE,
        loading: true,
      }),
    );
    const pushProps = {
      pathname: successRedirectRoute,
      state: confirmationPageProps,
    };
    const { total, id } = invoice;
    const result = total ? await stripe.confirmCardPayment(clientSecret) : {};
    if (result.error) {
      dispatch(
        errorTriggered({
          error: result.error,
          entity: RESOURCES.CHECKOUT,
        }),
      );
      dispatch(
        componentLoadingToggled({
          component: RESOURCES.STRIPE,
          loading: false,
        }),
      );
      if (onCheckoutError) onCheckoutError();
    } else if (
      !total ||
      result.paymentIntent.status === 'succeeded' ||
      result.paymentIntent.status === 'requires_capture'
    ) {
      // The payment has been processed!

      // Show a success message to your customer
      // There's a risk of the customer closing the window before callback
      // execution. Set up a webhook or plugin to listen for the
      // payment_intent.succeeded event that handles any business critical
      // post-payment actions.

      // create invoice to redeem promo code applied to the payment intent
      if (checkoutInvoiceHasPromoCode) {
        dispatch(
          apiAction({
            path: {
              route: API_STRIPE_ROUTES.PAYMENT_INTENT_INVOICE,
            },
            method: 'POST',
            entity: RESOURCES.STRIPE,
            data: {
              paymentIntentId: id,
              organizationId,
            },
            navigate,
            pushFunction: () => pushProps,
          }),
        );
      } else {
        dispatch(
          componentLoadingToggled({
            component: RESOURCES.STRIPE,
            loading: false,
          }),
        );
      }
      onCheckoutSuccess();
    } else {
      // shouldn't hit this
      console.log({ status: result.paymentIntent.status });
    }
  };

  return {
    fetchExpressDashboardLink,
    loading,
    onCheckout,
    checkoutError,
    clearCheckoutError,
    stripeObject,
  };
};

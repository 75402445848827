import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { streamChatMediaquery } from 'shared/styles/styleFunctions';

export default css({
  '.str-chat': {
    '&__header-hamburger': {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '5px',
      [streamChatMediaquery.md]: {
        display: 'none',
      },
    },
    '&__header-hamburger--line': {
      width: '15px',
      borderTop: `2px solid ${COLORS.BLACK}`,
      padding: '1.5px',
    },
  },
});

import React, { useEffect } from 'react';

import CollectionTileList from 'features/media/components/CollectionTileList';
import CollectionSearch from 'features/media/components/organisms/CollectionSearch';
import creatorAssetsLibraryStyles from 'features/media/pages/CreatorAssetsLibrary/creatorAssetsLibraryStyles';
import { useCollections } from 'features/media/useCollections';
import { Box, Flex, Text } from 'shared/components/display';
import Pagination from 'shared/components/organisms/Pagination';
import { PaginationSortType, SortOptions } from 'shared/components/molecules/PaginationSort';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useQuery } from 'shared/hooks/useQuery';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';

const { H4 } = TEXT_VARIANTS;

const sortOptions: SortOptions = [
  {
    label: 'Name',
    value: 'name',
    sortType: PaginationSortType.ALPHABET,
  },
  {
    label: 'Creation Date',
    value: 'dateCreated',
    sortType: PaginationSortType.TIME,
  },
];

const CreatorAssetsLibrary = () => {
  const { currentUser } = useCurrentUser();

  const { getQuery, setQuery } = useQuery();
  const query = getQuery();

  const searchParams = useSearchParams(query, {
    page: 1,
    size: 10,
    orderBy: 'dateCreated',
    orderAsc: false,
    creatorId: currentUser.id,
  });

  const { collections, totalCollections, createCollection, loading, loadingNewCollection, getFilteredCollections } =
    useCollections();

  useEffect(() => {
    getFilteredCollections({
      ...searchParams,
      ...query,
    });
  }, [searchParams, query]);

  return (
    <Flex className="assets-library" css={creatorAssetsLibraryStyles}>
      <Text className="assets-library__header" variant={H4}>
        Library
      </Text>
      <Box className={CLASSNAMES.PADDING_CONTAINER}>
        <Pagination
          first={
            <CollectionSearch
              onSubmit={({ search: name }) => {
                setQuery({ ...query, page: 1, name });
              }}
              state={query}
            />
          }
          totalAmount={totalCollections}
          loading={loading}
          searchParams={searchParams}
          paginationControlProps={{
            sortOptions,
            sortWidth: '210px',
          }}
        >
          <CollectionTileList
            data={collections}
            loading={loadingNewCollection}
            createCollection={createCollection}
            addLink
            creatorCollection
            showNewCollectionTile
          />
        </Pagination>
      </Box>
    </Flex>
  );
};

export default CreatorAssetsLibrary;

import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import cs from 'classnames';

import { CONSTRAINT_LIMITS } from 'shared/config/validations';
import { decodeHtml } from 'shared/utilities/domUtility';

const TagComponent: React.FC<any> = ({ props: [tagData] }) => {
  const { value, class: className } = tagData;
  const pattern = new RegExp(`^.{1,${CONSTRAINT_LIMITS.TAG_MAX_LENGTH}}$`);
  const decodedValue = decodeHtml(value);
  const tagTooLong = !pattern.test(decodedValue || '');

  return (
    <tag
      title={value}
      contentEditable={false}
      spellCheck={false}
      className={cs(className, 'tagify__tag', {
        'tagify__tag--invalid': tagTooLong,
      })}
      data-cy={`tagify__tag--${decodedValue}`}
    >
      <x aria-label="remove tag" title="remove tag" className="tagify__tag__removeBtn" />
      <span className="tagify__tag-text">{decodedValue}</span>
    </tag>
  );
};

const Tag = (...props) => renderToStaticMarkup(<TagComponent props={props} />);

export default Tag;

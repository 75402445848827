import React from 'react';
import cs from 'classnames';

import { Box, Text } from 'shared/components/display';
import { StepWrapperProps } from 'shared/components/forms/MultiStepForm/interfaces';
import splitPaneStepStyles from 'shared/components/forms/MultiStepForm/Steps/SplitPaneStepWrapper/splitPaneStepStyles';
import Heading from 'shared/components/molecules/Heading';

import { TEXT_VARIANTS } from 'shared/styles/text';

import SplitPaneStepHeader from './SplitPaneStepHeader';

const SplitPaneStepWrapper: React.FC<StepWrapperProps> = ({
  children = null,
  isActive,
  isActiveStepDirty,
  isCompleted,
  isOptional,
  onStepChange,
  stepKey = 0,
  title,
  titleInfo,
  isConfirmationStep,
  totalSteps = 0,
  isLatestStep,
}) => {
  const navigateToCompletedStep = () => {
    // can only navigate to completed steps
    if (onStepChange) onStepChange();
  };
  return (
    <>
      <SplitPaneStepHeader
        isCompleted={isCompleted}
        stepKey={stepKey}
        title={title}
        isActiveStepDirty={isActiveStepDirty}
        isActive={isActive}
        titleInfo={titleInfo}
        onClick={navigateToCompletedStep}
        isOptional={isOptional}
        isConfirmationStep={isConfirmationStep}
        isLatestStep={isLatestStep}
      />

      {isActive && (
        <Box
          css={splitPaneStepStyles}
          className={cs('split-step__body-content', {
            ['split-step__body--confirmation']: isConfirmationStep,
            'split-step__body-content--hidden': !isActive,
          })}
        >
          <Text as="h6" className="split-step__step-key">
            <strong>Step {stepKey}</strong> of {totalSteps + 1} {/* Add 1 to include confirmation step */}
          </Text>
          <Heading
            text={title}
            headerText={titleInfo?.header}
            descriptionText={titleInfo?.text}
            variant={isActive ? TEXT_VARIANTS.H4 : TEXT_VARIANTS.BODY}
            className="split-step__title"
            dataCy="split-step__title"
          />
          <Box>
            {isOptional && (
              <Text variant={TEXT_VARIANTS.LABEL} className="split-step__optional">
                Optional
              </Text>
            )}
          </Box>

          {children}
        </Box>
      )}
    </>
  );
};

export default SplitPaneStepWrapper;

import CollectionFormModal from 'features/media/components/CollectionFormModal/CollectionFormModal';
import { Tile } from 'shared/components/atoms/Tile';
import { COLLECTION_COPY } from 'shared/config/copy';
import React from 'react';

interface Props {
  createCollection: (data: any) => void;
}
const NewCollectionTile: React.FC<Props> = ({ createCollection }) => {
  return (
    <CollectionFormModal
      title={COLLECTION_COPY.FORM_HEADER_NEW_COLLECTION}
      submitText={COLLECTION_COPY.BUTTON_CREATE_NEW_COLLECTION}
      onSubmit={createCollection}
    >
      <Tile newTile label="Create Collection" />
    </CollectionFormModal>
  );
};

export default NewCollectionTile;

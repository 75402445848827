// * Lib
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import Skeleton from 'shared/components/atoms/Skeleton';

//* Display/UI
import { Box, Flex, Image } from 'shared/components/display';

// * Styles
import styles from './styles';
import { BrkfstAccount } from 'shared/typings/account';

type Size = 'xs' | 'sm' | 'md' | 'lg';
interface Props {
  loading?: boolean;
  size?: Size;
  account: BrkfstAccount;
}

const AccountLogo: React.FC<Props> = ({ loading, size = 'xs', account }) => {
  const { name } = account;
  const url = account.logo?.url;

  if (loading) {
    return (
      <Box css={styles}>
        <Skeleton className={`account-logo-skeleton account-logo-skeleton--${size}`} />
      </Box>
    );
  }

  return url ? (
    <Image
      src={url}
      alt={`${name} logo`}
      title={`${name} logo`}
      css={styles}
      className={`account-logo account-logo--${size}`}
    />
  ) : (
    <Flex css={styles} className={`account-logo-placeholder account-logo-placeholder--${size}`}>
      <FontAwesomeIcon
        icon={faBuilding}
        className="account-logo-placeholder__icon"
        title={`Default logo for ${name}`}
      />
    </Flex>
  );
};

export default AccountLogo;

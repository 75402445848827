import { FilterOptions } from 'shared/components/molecules/PaginationFilter';
import { PaginationSortType, SingleOptions, SortOptions } from 'shared/components/molecules/PaginationSort';
import { useQuery } from 'shared/hooks/useQuery';
import { creatorBriefStatusDisplay } from 'shared/typings/userBriefs/enums';
// fieldnames
export const ORDER_BY = 'creatorsOrderBy';
export const ORDER_ASC = 'creatorsOrderAsc';
export const STATUS = 'creatorsStatus';

export const sortOptions: SortOptions = [
  {
    label: 'Applied Date',
    // alias for user_briefs used in creator brief query
    value: 'ub.dateCreated',
    sortType: PaginationSortType.TIME,
  },
  {
    label: 'First Name',
    value: 'firstName',
    sortType: PaginationSortType.ALPHABET,
  },
  {
    label: 'Last Name',
    value: 'lastName',
    sortType: PaginationSortType.ALPHABET,
  },

];

export const singleOptions: SingleOptions = [
  {
    label: 'Relevance',
    value: 'score',
    orderAsc: false,
  },
];

export const filterOptions: FilterOptions = Object.keys(creatorBriefStatusDisplay).map((value) => {
  const label = creatorBriefStatusDisplay[value];
  return {
    value,
    label,
  };
});

import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import { SIDEBAR } from 'shared/config/formatting';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import { STYLES } from 'shared/styles/styles';
import theme from 'shared/styles/themes';

export default css({
  width: '37px',
  zIndex: zIndex.zNav,
  [mediaquery.md]: {
    width: 'auto',
  },
  '&.brand-side-bar': {
    '& .brand-side-bar__toggle-btn, &:hover .brand-side-bar__toggle-btn': {
      display: 'flex',
    },
    '&--expanded .brand-side-bar__toggle-btn': {
      display: 'none',
    },
    '& .brand-side-bar__account-btn': {
      display: 'none',
    },
    '&--expanded .brand-side-bar__account-btn': {
      display: 'flex',
    },
  },
  '.brand-side-bar': {
    '&__toggle-btn': {
      position: 'absolute',
      cursor: 'pointer',
      borderRadius: '50%',
      right: 0,
      border: STYLES.BORDER_NEUTRAL,
      height: '24px',
      width: '24px',
      backgroundColor: COLORS.WHITE,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transform: 'translateX(50%)',
    },
    '&__account-btn': {
      padding: 0,
      display: 'flex',
      alignItems: 'baseline',
      width: `calc(${SIDEBAR.EXPANDED_WIDTH} - ${theme.space[5]} - ${theme.space[5]})`,
      paddingRight: theme.space[1],
      '& .endWithEllipsis': {
        flex: 1,
        textAlign: 'left',
      },
    },
    '&__loading-wrapper': {
      margin: `${theme.space[1]} 0`,
    },
    '&__divider': {
      margin: '1rem 0',
    },
  },
});

import React from 'react';
import { useLocation } from 'react-router-dom';

import ErrorPage from 'features/ui/pages/ErrorPages/ErrorPage';

import { GENERIC_COPY } from 'shared/config/copy/genericCopy';
import { intl } from 'shared/lib/intl';

const NoAccessMsg = () => {
  const location = useLocation<any>();
  const { type } = location.state;
  const errorMessage = intl.formatMessage({ id: 'ERROR_PAGE_403_MESSAGE' }, { type });

  return <ErrorPage errorType={GENERIC_COPY.ERROR_PAGE_403_TYPE} errorMessage={errorMessage}></ErrorPage>;
};

export default NoAccessMsg;

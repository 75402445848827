import { validatePhone } from 'shared/components/inputs/PhoneInput/util';
import { addressInitialValues } from './constants';
import { AddressValues } from './types';

import { MESSAGES } from 'shared/config/validations';
import { Address } from 'shared/typings/address';
import { Phone } from 'shared/typings/user';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';

export const formatAddressQuery = ({
  city,
  state,
  postalCode,
  country,
}: {
  city: string;
  state?: string;
  postalCode: string;
  country: string;
}) => {
  const addresses = [city, postalCode, country];
  if (state) addresses.splice(1, 0, state);
  return addresses.join('+');
};

export const addressPhoneOnSubmit =
  (validateAddress: (address: string) => Promise<any>, setError: (err: string[]) => void) => async (stepData) => {
    const {
      city,
      postalCode,
      phoneNumber,
      countryCode: phoneCountryCode,
      country: { value: country },
      state: { value: state },
    } = stepData;
    const countryName = country.name;
    const address = formatAddressQuery({ city, state: state.name, postalCode, country: countryName });

    const validationAddress = await validateAddress(address);
    const { validity: validationPhone, formattedNumber } = await validatePhone(phoneNumber, phoneCountryCode);
    const errorMessage: string[] = [];
    if (!validationAddress) {
      errorMessage.push(MESSAGES.INVALID_ADDRESS);
    }

    if (!validationPhone) {
      errorMessage.push(MESSAGES.INVALID_PHONE);
    } else {
      stepData.phoneNumber = formattedNumber;
    }
    setError(errorMessage);
    return { success: validationAddress && validationPhone, newValues: stepData };
  };

export const addressOnSubmit =
  (validateAddress: (address: string) => Promise<any>, setError: (err: string[]) => void) =>
  async (stepData, formikProps) => {
    const {
      city,
      postalCode,
      state: { value: state },
      country: { value: country },
    } = stepData;
    const countryName = country.name;
    const address = formatAddressQuery({ city, state: state.name, postalCode, country: countryName });
    const validation = await validateAddress(address);

    const addressData = { ...stepData };
    if (!validation) {
      setError([MESSAGES.INVALID_ADDRESS]);
    }

    formikProps.setSubmitting(false);
    return { success: validation, newValues: addressData };
  };

export const getInitialValues = (address?: Address, phone?: Phone) => {
  const country = address?.country?.name
    ? { label: address.country.name, value: address.country }
    : addressInitialValues.country;

  const state = address?.state?.name ? { label: address.state.name, value: address.state } : addressInitialValues.state;

  const vals: AddressValues = {
    country,
    state,
    addressLine1: address?.addressLine1 || addressInitialValues.addressLine1,
    addressLine2: address?.addressLine2 || addressInitialValues.addressLine2,
    companyName: address?.companyName || addressInitialValues.companyName,
    city: address?.city || addressInitialValues.city,
    postalCode: address?.postalCode || addressInitialValues.postalCode,
    phoneNumber: phone ? phone?.number || addressInitialValues.phoneNumber : undefined,
    countryCode: phone?.countryCode || addressInitialValues.countryCode,
  };
  if (phone?.countryCode) {
    vals.countryDialCode = getCountryCallingCode(phone.countryCode.toUpperCase() as CountryCode);
  }
  return vals;
};

/** @jsxRuntime classic */
// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { jsx } from '@emotion/react';
// The 3 lines above are necessary because we're using the css prop on a native html element
import React from 'react';

// * Display/UI
import Base, { BaseProps } from './Base.display';

interface Props extends BaseProps {
  onClick?: React.EventHandler<React.SyntheticEvent>;
  type?: string;
  name?: string;
  className?: string;
  autofocus?: boolean;
  dataCy?: string;
}

const Box = React.forwardRef<any, Props>((props, ref) => <Base data-display="Box" {...props} ref={ref} />);

export default Box;

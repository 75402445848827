import { Box, Flex, Text } from 'shared/components/display';
import FileIcon from './FileIcon';
import fileListItemStyle from './fileListItemStyle';
import { TEXT_VARIANTS } from 'shared/styles/text';
import cs from 'classnames';
import React from 'react';

interface Props {
  children: any;
  fileName: string;
  mimeType: string;
  onClick?: () => void;
}
const FileListItem: React.FC<Props> = ({ children, fileName, mimeType, onClick }) => {
  const clickable = !!onClick;
  return (
    <Box as="li" css={fileListItemStyle} className="file-list-item">
      <Flex
        onClick={onClick}
        className={cs('file-list-item__name-icon', {
          ['file-list-item__name-icon--clickable']: clickable,
        })}
      >
        <FileIcon mimeType={mimeType} className={'file-list-item__icon'} />
        <Text variant={TEXT_VARIANTS.SUBHEADING_SM} className="endWithEllipsis file-list-item__name">
          {fileName}
        </Text>
      </Flex>
      {children}
    </Box>
  );
};

export default FileListItem;

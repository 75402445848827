import React, { forwardRef, useState } from 'react';
import Tooltip from 'shared/components/atoms/Tooltip';
import { TippyProps } from '@tippyjs/react';

interface Props extends TippyProps {
  as?: string;
}
// https://github.com/atomiks/tippyjs-react#lazy-mounting
// Will only render the `content` or `render` elements if the tippy is mounted to the DOM.
const LazyTooltip: React.FC<Props> = forwardRef((props, ref) => {
  const [mounted, setMounted] = useState(false);

  const lazyPlugin = {
    fn: () => ({
      onMount: () => setMounted(true),
      onHidden: () => setMounted(false),
    }),
  };

  const computedProps = { ...props };

  computedProps.plugins = [lazyPlugin, ...(props.plugins || [])];

  if (props.render) {
    computedProps.render = (...args) =>
      // @ts-ignore
      mounted ? props.render(...args) : '';
  } else {
    computedProps.content = mounted ? props.content : '';
  }

  // @ts-ignore
  return <Tooltip {...computedProps} ref={ref} />;
});

export default LazyTooltip;

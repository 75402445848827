import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { BrkfstTag } from 'shared/typings/tag';

import { BrkfstUser } from 'shared/typings/user';
import { ALL_CREATORS, UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { getAgeFromDate } from 'shared/utilities/dateUtility';

const statusMatches = (state, newStatus) => {
  let statusMatch;
  if (state.status === UserBriefStatus.WORKING) {
    statusMatch = [UserBriefStatus.ACTIVE, UserBriefStatus.DRAFTED, UserBriefStatus.SUBMITTED].includes(newStatus);
  } else {
    statusMatch = [newStatus, ALL_CREATORS].includes(state.status);
  }
  if (!statusMatch) return false;
  return true;
};

interface CreatorsState {
  data: BrkfstUser[];
  resultsCount: number;
  status: null | UserBriefStatus;
  totalCount: number;
}

type CreatorAction<T = BrkfstUser[], P = any> = PayloadAction<{ data: T; params: P }>;

const formatCreator = (creator: BrkfstUser): BrkfstUser => {
  const creatorTags: BrkfstTag[] = (
    creator.height
      ? [
          {
            category: 'height',
            isOther: false,
            isStandard: true,
            type: 'users',
            value: creator.height,
          },
        ]
      : []
  ).concat(creator.creatorTags || []);

  // @ts-ignore
  return {
    ...creator,
    creatorTags,
    age: creator.birthdate
      ? `${getAgeFromDate(creator.birthdate)}`
      : creatorTags.find((tag) => tag.category.toLowerCase() === 'age')?.value || '',
    gender: creator.gender
      ? creator.gender
      : creatorTags.find((tag) => tag.category.toLowerCase() === 'gender')?.value || '',
  };
};

const creatorsSlice = createSlice<CreatorsState, SliceCaseReducers<CreatorsState>>({
  name: 'creators',
  initialState: {
    data: [],
    resultsCount: 0, // count of results from query
    status: null,
    totalCount: 0, // total count of creators where status is not DISABLED
  },
  reducers: {
    creatorsLoaded: (
      creators,
      action: CreatorAction<{ data: BrkfstUser[]; total: number }, { status: UserBriefStatus }>,
    ) => {
      const { data, total } = action.payload.data;
      const { status } = action.payload.params;
      creators.data = data.map(formatCreator);
      creators.resultsCount = total;
      creators.status = status || ALL_CREATORS;
    },
    creatorsAdded: (creators, action: CreatorAction) => {
      const { data } = action.payload;
      if (statusMatches(creators, data[0].creatorBriefStatus)) {
        creators.data = [...creators.data, ...data.map(formatCreator)];
        creators.resultsCount += data.length;
      }
    },
    creatorsTotalCountRefresh: (creators, action: CreatorAction<{ total: number }>) => {
      const { total } = action.payload.data;
      creators.totalCount = total;
    },
    creatorChanged: (creators: CreatorsState, { payload: { data } }: CreatorAction<BrkfstUser>) => {
      const creator = creators.data.find((c) => c.id === data.id);
      if (creator) {
        const index = creators.data.indexOf(creator);
        const updatedCreator = Object.assign(creator, formatCreator(data));
        creators.data.splice(index, 1, updatedCreator);
        // total should be of currently displayed creators, so if status changed then need to change total
        if (!statusMatches(creators, updatedCreator.creatorBriefStatus)) {
          creators.resultsCount -= 1;
        }
      }
    },
  },
});

export const { creatorsLoaded, creatorChanged, creatorsAdded, creatorsTotalCountRefresh } = creatorsSlice.actions;

export default creatorsSlice.reducer;

export const getFilteredCreators = (state) => {
  return state.creators.data.filter(({ creatorBriefStatus }) => {
    if (state.creators.status === UserBriefStatus.WORKING) {
      return [
        UserBriefStatus.ACTIVE,
        UserBriefStatus.DRAFTED,
        UserBriefStatus.SUBMITTED,
        UserBriefStatus.REVISION,
      ].includes(creatorBriefStatus);
    }
    if (state.creators.status && state.creators.status !== ALL_CREATORS)
      return state.creators.status === creatorBriefStatus;
    return true;
  });
};
export const getResultsCount = (state) => state.creators.resultsCount;
export const getTotalCount = (state) => state.creators.totalCount;

import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export default css({
  '.payout-checkout': {
    '&__subheader': {
      marginTop: theme.space[6],
      marginBottom: theme.space[6],
    },
  },
});

import React from 'react';
import { FormikContextType, useFormikContext } from 'formik';

import { BUTTON_VARIANTS } from 'shared/styles/button';

import ActionButton from '../molecules/ActionButton';
import { isEmpty } from 'lodash';

type Props<V> = {
  disabled?: boolean | null;
  onClick?: (formik: FormikContextType<V>) => void;
  className?: string;
  /** Defaults to submit */
  type?: 'button' | 'submit';
  variant?: BUTTON_VARIANTS;
  tabIndex?: number;
  loading?: boolean;
  loaderColor?: string;
  dataCy?: string;
  disableUntilTouched?: boolean;
};

// Note: This will become the new formButton that uses just formik.
// This was made barebones to solve a problem and will be fleshed out.

function FormButton<V>({
  disabled = null,
  children,
  onClick,
  className,
  type = 'submit',
  variant = BUTTON_VARIANTS.PRIMARY,
  tabIndex,
  loading = false,
  loaderColor,
  dataCy,
  disableUntilTouched = false,
}: React.PropsWithChildren<Props<V>>): JSX.Element {
  const formikContext = useFormikContext<V>();
  const isDisabled =
    (disabled !== null ? disabled : formikContext.isSubmitting) ||
    (disableUntilTouched && isEmpty(formikContext.touched));

  return (
    <ActionButton
      type={type}
      loading={loading}
      disabled={isDisabled}
      className={className}
      onClick={() => onClick && onClick(formikContext)}
      variant={variant}
      tabIndex={tabIndex}
      loaderColor={loaderColor}
      dataCy={dataCy}
    >
      {children}
    </ActionButton>
  );
}

export default FormButton;

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { accountPlatformAdded, accountPlatformRemoved } from 'features/accountPlatforms/accountPlatforms.slice';
import { loggedOut } from 'features/auth/auth.slice';
import { organizationAdded } from 'features/organizations/organizations.slice';

import { AccountPricingConfig, BrkfstAccount } from 'shared/typings/account';
import { BrkfstAction } from 'shared/typings/redux';

type AccountState = BrkfstAccount[];

export const accountsSlice = createSlice<AccountState, SliceCaseReducers<AccountState>>({
  name: 'accounts',
  initialState: [],
  reducers: {
    accountsLoaded: (accounts, action: BrkfstAction<{ data: BrkfstAccount[] }>) => action.payload.data.data,
    accountAdded: (accounts, action: BrkfstAction<BrkfstAccount>) => {
      accounts.push(action.payload.data);
    },
    accountRemoved: (accounts, action: BrkfstAction<BrkfstAccount>) =>
      accounts.filter((account) => account.id !== action.payload.data.id),
    accountChanged: (accounts, action: BrkfstAction<BrkfstAccount>) =>
      accounts.map((account) =>
        account.id === action.payload.data.id ? { ...account, ...action.payload.data } : account,
      ),
    accountPricingChanged: (accounts, action: BrkfstAction<AccountPricingConfig & Pick<BrkfstAccount, 'id'>>) => {
      const {
        creativeOnlyCreatorPayout,
        creativeOnlyEliteCreatorPayout,
        creativeOnlyCreatorApprovalPricing,
        creativeOnlyEliteCreatorApprovalPricing,
        performanceCreatorPayout,
        performanceEliteCreatorPayout,
        performanceCreatorApprovalPricing,
        performanceEliteCreatorApprovalPricing,
      } = action.payload.data;
      return accounts.map((account) => {
        return account.id === action.payload.data.id
          ? {
              ...account,
              pricing: {
                creativeOnlyCreatorPayout,
                creativeOnlyEliteCreatorPayout,
                creativeOnlyCreatorApprovalPricing,
                creativeOnlyEliteCreatorApprovalPricing,
                performanceCreatorPayout,
                performanceEliteCreatorPayout,
                performanceCreatorApprovalPricing,
                performanceEliteCreatorApprovalPricing,
              },
            }
          : account;
      });
    },
    accountLogoUpdated: (accounts, action: BrkfstAction<BrkfstAccount>) =>
      accounts.map((account) =>
        account.id === action.payload.data.id
          ? {
              ...account,
              logo: action.payload.data.logo,
              logoId: action.payload.data.logoId,
            }
          : account,
      ),
    accountLogoRemoved: (accounts, action: BrkfstAction<BrkfstAccount>) =>
      accounts.map((account) =>
        account.id === action.payload.data.id ? { ...account, logo: null, logoId: null } : account,
      ),
    accountBannerRemoved: (accounts, action: BrkfstAction<BrkfstAccount>) =>
      accounts.map((account) =>
        account.id === action.payload.data.id ? { ...account, banner: null, bannerId: null } : account,
      ),
  },
  extraReducers: (builder) =>
    builder
      .addCase(organizationAdded, (accounts, action) => {
        const { accounts: orgAccounts } = action.payload.data;
        const orgAccountIds = orgAccounts.map(({ id }) => id);
        const preExistingAccounts = accounts.filter(({ id }) => !orgAccountIds.includes(id));
        return [...preExistingAccounts, ...orgAccounts];
      })
      .addCase(accountPlatformAdded, (accounts, action) => {
        const { id, accountId } = action.payload.data;
        const ownerAccount = accounts.find((a) => a.id === accountId);

        ownerAccount?.accountPlatformIds.push(id);
      })
      .addCase(accountPlatformRemoved, (accounts, action) => {
        // TODO -- put accountId in action.payload so this isn't necessary
        accounts.forEach((account) => {
          if (account.accountPlatformIds.find((a) => a === action.payload.data.id)) {
            const index = account.accountPlatformIds.indexOf(action.payload.data.id);
            account.accountPlatformIds.splice(index, 1);
          }
        });
      })
      .addCase(loggedOut, () => []),
});

export const {
  accountsLoaded,
  accountAdded,
  accountChanged,
  accountRemoved,
  accountLogoUpdated,
  accountLogoRemoved,
  accountBannerRemoved,
  accountPricingChanged,
} = accountsSlice.actions;

export const getAccounts = (state) => state.accounts;

export const getAccount = (accountId) => (state) => state.accounts.find((element) => element.id === +accountId) || {};

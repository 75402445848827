import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

import { TOOLTIP_SIZE } from './NewTooltip';

export default (maxWidth?: TOOLTIP_SIZE | number) =>
  css({
    '&.brkfst-tooltip': {
      maxWidth: typeof maxWidth === 'number' ? `${maxWidth}px` : maxWidth || `calc(100vw - ${theme.space[4]})`,
      backgroundColor: COLORS.NEUTRAL800,
      color: 'white',
      fontSize: '90%',
      padding: '4px 8px',
      borderRadius: '4px',
      zIndex: zIndex.zTooltip,
      width: 'max-content',
    },
  });

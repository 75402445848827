import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import IGPosts from 'features/users/components/molecules/IGPosts';
import ProfilePanel from 'features/users/components/molecules/ProfilePanel';
import ExternalLink from 'shared/components/atoms/ExternalLink/ExternalLink';
import { Box, Flex, Text } from 'shared/components/display';

import { CREATOR_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

import igPostsPanelStyles from './igPostsPanelStyles';

const { OVERLINE } = TEXT_VARIANTS;

const IG_URL = 'https://www.instagram.com/';

interface Props {
  user: BrkfstUser;
  isCurrentUser: boolean;
}

const IGPostsPanel: React.FC<Props> = ({ user, isCurrentUser }) => {
  const { igPosts = [], igUsername } = user.instagramProfile || {};

  const placeholderText = useMemo(() => {
    if (user.instagramProfile && !igPosts.length) {
      return CREATOR_COPY.PLACEHOLDER_NO_IG_POSTS;
    } else {
      return isCurrentUser ? CREATOR_COPY.PLACEHOLDER_CONNECT_INSTAGRAM : CREATOR_COPY.PLACEHOLDER_NO_INSTAGRAM;
    }
  }, [isCurrentUser, user.instagramProfile, igPosts.length]);

  return (
    <Box css={igPostsPanelStyles} className="ig-posts-panel">
      <ProfilePanel
        HeaderComponent={
          <Flex className="ig-posts-panel__header">
            {CREATOR_COPY.HEADER_IG_POSTS}
            {igUsername && (
              <ExternalLink
                href={`${IG_URL}${igUsername}`}
                text={
                  igUsername?.trim()?.length > 0 && (
                    <Flex className="ig-posts-panel__username">
                      <FontAwesomeIcon icon={faInstagram} />
                      {igUsername}
                    </Flex>
                  )
                }
              />
            )}
          </Flex>
        }
        className="ig-posts-panel"
      >
        <IGPosts igPosts={igPosts} placeholderText={placeholderText} creatorId={user.id} />
        <Flex className="ig-posts-panel__link-wrapper">
          {!user.instagramProfile && isCurrentUser && (
            <Link
              to={LinkCreator.createLink({
                userType: UserType.CREATOR,
                routeKey: 'ACCOUNT_SETTINGS',
                variables: {
                  userId: user.id,
                },
              })}
            >
              <Text variant={OVERLINE} className="ig-posts-panel__link-text">
                {CREATOR_COPY.LINK_CONNECT_INSTAGRAM}
              </Text>
            </Link>
          )}
        </Flex>
      </ProfilePanel>
    </Box>
  );
};

export default IGPostsPanel;

// * Lib
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// * Display/UI
import { Box } from 'shared/components/display';
import Pagination from 'shared/components/organisms/Pagination';
import CreatorSearch from 'features/creators/components/molecules/CreatorSearch/CreatorSearch';
import CreatorsTable from 'features/creators/components/organisms/CreatorsTable/CreatorsTable';
import { GetAllUsersQuery } from 'features/creators/components/molecules/CreatorSearch';

// * Util
import { useDeepEffect } from 'shared/hooks/useDeepEffect';
import { useCreators } from 'features/creators/useCreators';
import { useQuery } from 'shared/hooks/useQuery';
import { submissionsCleared } from 'features/submissions/submissions.slice';
// * Styles
import manageCreatorsStyles from './styles';

const ManageCreators = () => {
  const { creators, creatorResultsCount, loading, queryCreators } = useCreators();

  const [search, setSearch] = useState<GetAllUsersQuery>({ recentlyActive: '60 days' });
  const { getQuery } = useQuery();
  const query = getQuery();

  const [pagination, setPagination] = useState({ page: 1, size: 36 });
  const dispatch = useDispatch();
  // query ALL creators to set totalCount in redux store
  useEffect(() => queryCreators({ recentlyActive: '', ...pagination }, true), []);

  useDeepEffect(() => {
    setPagination({
      page: query?.page || 1,
      size: query?.size || 36,
    });
  }, [query]);

  useDeepEffect(() => {
    queryCreators({ ...search, ...pagination });
  }, [pagination, search]);

  useEffect(() => {
    return () => {
      dispatch(submissionsCleared({}));
    };
  }, []);

  const handleSubmit = (searchQuery: GetAllUsersQuery) => {
    setSearch(searchQuery);
    setPagination({ page: 1, size: 36 });
  };

  return (
    <Box css={manageCreatorsStyles}>
      <Box className="manage-creators">
        <CreatorSearch onSubmit={handleSubmit} />

        <Pagination totalAmount={creatorResultsCount} searchParams={pagination} pageSizeBase={36} hidePageSizeSelect>
          <CreatorsTable data={creators} loading={loading} />
        </Pagination>
      </Box>
    </Box>
  );
};

export default ManageCreators;

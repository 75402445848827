import React, { useState } from 'react';

import { useReviewModules } from 'features/reviewModules/useReviewModules';
import { Select } from 'shared/components/molecules/Select';
import ModuleReviewFormModal from 'shared/components/organisms/ModuleReviewFormModal';

import { ModuleSubmission, ModuleSubmissionStatus } from 'shared/typings/submissions';

import { getStatusOptions, makeOption } from './util';

interface Props {
  submissionModule: ModuleSubmission;
  creatorId: number;
  briefId: number;
  setModuleLoading: (id: number | null) => void;
  className?: string;
}
const SubmissionModuleStatusSelect: React.FC<Props> = ({
  submissionModule,
  creatorId,
  briefId,
  setModuleLoading,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { updateSubmissionModuleStatus, createRevision, addReviewModule } = useReviewModules();

  const toggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const changeModuleStatus = (selectedModule: ModuleSubmission, newStatus: ModuleSubmissionStatus) => {
    if (newStatus && selectedModule?.status !== newStatus) {
      setModuleLoading(selectedModule.id);
      updateSubmissionModuleStatus({
        submissionModuleId: selectedModule.id,
        status: newStatus,
        onSuccess: () => setModuleLoading(null),
        onError: () => setModuleLoading(null),
      });
    }
  };

  const handleOptionalFeedback = ({ data, errorCategory, onSuccess }) => {
    setModuleLoading(submissionModule.id);
    function onSuccessCallbacks() {
      onSuccess();
      setModuleLoading(null);
      addReviewModule(submissionModule.id, briefId);
    }
    createRevision(
      { category: errorCategory, comment: data.comment, submissionModuleId: submissionModule.id },
      creatorId,
      ModuleSubmissionStatus.REVIEW,
      onSuccessCallbacks,
      () => setModuleLoading(null),
    );
    toggleModal();
  };

  const setReviewStatusAndCloseModal = () => {
    changeModuleStatus(submissionModule, ModuleSubmissionStatus.REVIEW);
    toggleModal();
  };

  return (
    <>
      <Select
        options={getStatusOptions(submissionModule.status)}
        name="Review Module"
        dataCy={`submission-module-status-select--${submissionModule.moduleNum}`}
        className={className}
        value={makeOption(submissionModule.status)}
        //  @ts-ignore
        onChange={(option: SingleValue<SelectValue<ModuleSubmissionStatus>>) => {
          // open modal when transitioning to REVIEW from any other status
          if (
            submissionModule.status !== ModuleSubmissionStatus.REVIEW &&
            option?.value === ModuleSubmissionStatus.REVIEW
          )
            toggleModal();
          else {
            option?.value && changeModuleStatus(submissionModule, option?.value);
          }
        }}
        width="135px"
        hidePlaceholder
      />
      <ModuleReviewFormModal
        onSubmit={handleOptionalFeedback}
        onClose={toggleModal}
        isOpen={isOpen}
        showSkip
        onSkip={setReviewStatusAndCloseModal}
      />
    </>
  );
};

export default SubmissionModuleStatusSelect;

import React, { Children, PropsWithChildren, ReactNode, useId, useMemo } from 'react';
import { useSwipeable } from 'react-swipeable';
import { HandledEvents } from 'react-swipeable/es/types';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Box, Flex } from 'shared/components/display';
import { noOp } from 'shared/defaults';
import { useQuery } from 'shared/hooks/useQuery';

import styles, { CLASSNAMES, MAIN_CLASS } from './styles';
import { update } from 'lodash';

export enum SwipeableContainerVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
}

export const ElementPosition = {
  left: 'left',
  right: 'right',
  center: 'center',
} as const;

type TypeElementPosition = typeof ElementPosition;
type PaginationPosition = TypeElementPosition[keyof TypeElementPosition];
interface Props {
  variant?: SwipeableContainerVariant;
  paginationPosition?: PaginationPosition;
  pageIndex?: number;
  onSwiped?: (pageIndex?: number) => void;
  loading?: boolean;
  className?: string;
  updatePageIndex: (index: number) => void;
}

//Updated props to SwipeableContainer to expect the children passed in to be the content rendered in the swipeable container
const SwipeableContainer: React.FC<PropsWithChildren<Props>> = ({
  variant = SwipeableContainerVariant.PRIMARY,
  paginationPosition = 'center',
  pageIndex = 0,
  onSwiped = () => noOp(),
  loading = false,
  className = '',
  children,
  updatePageIndex,
}) => {
  const swipeableId = useId();
  const items: ReactNode[] = useMemo(() => {
    return Children.toArray(children);
  }, [children]);
  const numChildren = Children.count(children);
  const currentPage = pageIndex + 1;
  const disablePrevious = currentPage === 1;
  const disableNext = currentPage > numChildren - 1;

  const onPreviousTriggered = (e: HandledEvents) => {
    e.stopPropagation();
    if (!disablePrevious) {
      updatePageIndex(pageIndex ? pageIndex - 1 : 1);
      onSwiped(pageIndex - 1);
    }
  };
  const onNextTriggered = (e: HandledEvents) => {
    e.stopPropagation();
    if (!disableNext) {
      updatePageIndex(pageIndex ? pageIndex + 1 : 1);
      onSwiped(pageIndex);
    }
  };

  const onAltPaginationTriggered = (index: number) => {
    updatePageIndex(index);
    onSwiped(index);
  };

  const handlers = useSwipeable({
    onSwipedLeft: ({ event }) => {
      onNextTriggered(event);
    },
    onSwipedRight: ({ event }) => {
      onPreviousTriggered(event);
    },
    preventScrollOnSwipe: true,
  });

  return (
    <Box css={styles} {...handlers} className={`${MAIN_CLASS} ${className}`}>
      <Flex
        className={`${CLASSNAMES.pagination} ${CLASSNAMES.pagination}--${paginationPosition} ${CLASSNAMES[variant]}`}
      >
        {variant === SwipeableContainerVariant.PRIMARY ? (
          <>
            <button onClick={onPreviousTriggered} disabled={disablePrevious} className={CLASSNAMES.paginationBtn}>
              {'<'}
            </button>
            <p className={CLASSNAMES.pageCount}>
              {currentPage}/{numChildren}
            </p>
            <button onClick={onNextTriggered} disabled={disableNext} className={CLASSNAMES.paginationBtn}>
              {'>'}
            </button>{' '}
          </>
        ) : (
          <>
            {numChildren > 1 &&
              Array.from({ length: numChildren }).map((_, index) => (
                <Box
                  className={`${CLASSNAMES.paginationBtn} ${pageIndex === index ? CLASSNAMES.paginationBtnActive : ''}`}
                  onClick={() => onAltPaginationTriggered(index)}
                  key={`${swipeableId}-${index}`}
                ></Box>
              ))}
          </>
        )}
      </Flex>

      {numChildren > 0 ? loading ? <CenteredSpinner /> : items[pageIndex] : <></>}
    </Box>
  );
};

export default SwipeableContainer;

/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { Brief } from 'shared/typings/briefs';

interface BriefState {
  data: Brief[];
  total: number;
}

interface BriefPayload<T = BriefState> {
  data: T;
}

const initialState = {
  data: [],
  total: 0,
};

const creatorBriefsSlice = createSlice<BriefState, SliceCaseReducers<BriefState>, 'creatorBriefs'>({
  name: 'creatorBriefs',
  initialState,
  reducers: {
    creatorBriefsLoaded: (creatorBriefs, action: PayloadAction<BriefPayload>) => {
      const { data, total } = action.payload.data;
      creatorBriefs.data = data;
      creatorBriefs.total = total;
    },
    creatorBriefChanged: (creatorBriefs, action: PayloadAction<BriefPayload<Brief>>) => {
      creatorBriefs.data = creatorBriefs.data.map((brief) => {
        if (brief.id === action.payload.data.id) {
          return {
            ...brief,
            ...action.payload.data,
          };
        }
        return brief;
      });
    },
    briefsCleared: () => {
      return initialState;
    },
  },
});

export const { creatorBriefsLoaded, creatorBriefChanged, briefsCleared } = creatorBriefsSlice.actions;

export default creatorBriefsSlice.reducer;

interface StoreState {
  creatorBriefs: BriefState;
}

export const getBrief = (id: number) => (state: StoreState) => {
  return state.creatorBriefs.data.find((element) => element.id === id);
};

export const getCreatorBriefs = (state: StoreState) => state.creatorBriefs.data;

export const getTotalCreatorBriefs = (state: StoreState) => state.creatorBriefs.total;

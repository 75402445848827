import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.radio-group': {
    '&__wrapper--vertical': {
      flexDirection: 'column',
    },
    '&__input-label--horizontal': {
      margin: 0,
    },
    '&__input-wrapper': {
      alignItems: 'center',
      margin: `${theme.space[4]}`,
    },
    '&__label': {
      margin: `0 0 0 ${theme.space[4]}`,
    },
    '&__label, &__input': {
      cursor: 'pointer',
    },
  },
});

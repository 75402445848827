import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { BrkfstFile } from 'shared/typings/file';

interface State {
  assets: BrkfstFile[];
  accountId: number;
}

const brandAssetsSlice = createSlice<State, SliceCaseReducers<State>, string>({
  name: 'brandAssets',
  initialState: {
    assets: [],
    accountId: 0,
  },
  reducers: {
    brandAssetsLoaded: (
      brandAssets,
      action: PayloadAction<{
        data: BrkfstFile[];
        params: { accountId: number };
      }>,
    ) => {
      brandAssets.assets = action.payload.data;
      brandAssets.accountId = action.payload.params?.accountId;
    },
    brandAssetRemoved: (brandAssets, action) => {
      brandAssets.assets = brandAssets.assets.filter(({ id }) => action.payload.data.id !== id);
    },
    brandAssetAdded: (brandAssets, action) => {
      brandAssets.assets.unshift(action.payload.data);
    },
    brandAssetUpdated: (brandAssets, action) => {
      const { fileId, thumbnailUrl, thumbnailOffset } = action.payload;
      brandAssets.assets = brandAssets.assets.map((asset) => {
        if (asset.id === fileId) {
          return {
            ...asset,
            thumbnailUrl,
            thumbnailOffset,
          };
        }
        return asset;
      });
    },
  },
});

export const { brandAssetAdded, brandAssetUpdated, brandAssetRemoved, brandAssetsLoaded } = brandAssetsSlice.actions;

export default brandAssetsSlice.reducer;

export const getBrandAssets = (accountId: string) => (state) => {
  if (accountId === state.brandAssets.accountId) return state.brandAssets.assets;
  return [];
};

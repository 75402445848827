import React, { useEffect, useMemo } from 'react';
import { Text } from 'rebass/styled-components';
import { createColumnHelper } from '@tanstack/react-table';
import cs from 'classnames';
import { camelCase, mapKeys } from 'lodash';

import MarketerSubmissionEllipsis from 'features/submissions/components/molecules/MarketerSubmissionEllipsis';
import SubmissionsPayout from 'features/submissions/components/organisms/SubmissionsPayout';
import SubmissionView from 'features/submissions/components/SubmissionView/SubmissionView';
import { useSubmissions } from 'features/submissions/useSubmissions';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import SmallCreatorPreview from 'features/users/components/SmallCreatorPreview/SmallCreatorPreview';
import BackButton from 'shared/components/atoms/BackButton';
import { Box, Flex } from 'shared/components/display';
import BrkfstTable from 'shared/components/organisms/BrkfstTable';
import ModuleReview from 'shared/components/organisms/ModuleReview';
import Pagination from 'shared/components/organisms/Pagination';

import { SUBMISSION_COPY } from 'shared/config/copy/submissionCopy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { usePermissions } from 'shared/hooks/usePermissions';
import { useQuery } from 'shared/hooks/useQuery';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Brief } from 'shared/typings/briefs';
import { SubmissionStatus } from 'shared/typings/submissions';
import { UserType } from 'shared/typings/user/enums';
import { prettyDate } from 'shared/utilities/dateUtility';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';

const columnHelper = createColumnHelper<any>();

const { BODY } = TEXT_VARIANTS;

interface Props {
  currentBrief: Brief;
}

const SubmissionIndex: React.FC<Props> = ({ currentBrief }) => {
  const briefId = currentBrief?.id;
  const { getQuery, setQuery } = useQuery();
  const query = getQuery();
  const { currentUser } = useCurrentUser();

  const { submissions, totalSubmissions, fetchSubmissions, markAsSeen, loading } = useSubmissions();

  const { isAuthorized } = usePermissions();

  const showModuleReview = isAuthorized(currentBrief.organizationId, PERMISSIONS_MAP.REVIEW_MODULES.API);

  const searchParams = useSearchParams(query, {
    subPage: 1,
    subSize: 5,
    subOrderBy: 'dateSubmitted',
    subOrderAsc: false,
    subBriefId: briefId,
    subStatus: SubmissionStatus.SUBMITTED,
    sId: null,
  });

  const displaySubmission = useMemo(() => {
    return submissions.find((submission) => submission.id === searchParams.sId);
  }, [searchParams.sId, submissions]);

  useEffect(() => {
    const formattedQuery = mapKeys(searchParams, (val, key) => {
      return camelCase(key.replace(/^sub/, ''));
    });
    fetchSubmissions(formattedQuery);
  }, [searchParams]);

  const clearSubmission = () => setQuery({ sId: null });

  const reviewLink = LinkCreator.createLink({
    userType: UserType.ORG,
    routeKey: 'VIEW_INDIVIDUAL_BRIEF',
    variables: {
      organizationId: currentBrief.organizationId,
      accountId: currentBrief.accountId,
      briefId: currentBrief.id,
    },
    query: {
      activeTab: 3,
      openLightbox: true,
      sId: searchParams.sId,
    },
  });

  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <Box css={styles} className="submissions">
      {
        // if there are no submissions, don't show the index
        totalSubmissions < 1 ? (
          <Text variant={BODY} className="submissions__placeholder">
            {SUBMISSION_COPY.PLACEHOLDER_SUBMISSIONS}
          </Text>
        ) : displaySubmission ? (
          <>
            <BackButton onClick={clearSubmission} dataCy="back-to-submissions-btn">
              {SUBMISSION_COPY.BUTTON_ALL_SUBMISSIONS}
            </BackButton>
            <hr />
            <SubmissionView submission={displaySubmission} />
          </>
        ) : (
          <>
            {showModuleReview && (
              <ModuleReview
                organizationId={currentBrief.organizationId}
                accountId={currentBrief.accountId}
                briefId={currentBrief.id}
                briefName={currentBrief.name}
                brandName={currentBrief.account.name}
                reviewLink={reviewLink}
              />
            )}
            <Pagination
              searchParams={searchParams}
              sizeParamName="subSize"
              pageParamName="subPage"
              totalAmount={totalSubmissions}
            >
              <BrkfstTable
                data={submissions}
                columns={[
                  columnHelper.accessor((row) => row, {
                    header: SUBMISSION_COPY.COLUMN_CREATOR_SUBMISSION,
                    cell: (info) => {
                      const submission = info.getValue();

                      return (
                        <Box
                          className={cs({
                            'submissions__row--unread': !submission.hasBeenSeen,
                          })}
                          data-cy="submissions__row"
                        >
                          {submission.important && <Box as="span" className="submissions__important" />}
                          {submission.creator && <SmallCreatorPreview user={submission.creator} showFullName />}
                        </Box>
                      );
                    },
                    meta: {
                      width: '150px',
                    },
                  }),
                  columnHelper.accessor(
                    (row) => ({
                      dateSubmitted: row.dateSubmitted,
                      hasBeenSeen: row.hasBeenSeen,
                      dateCreated: row.dateCreated,
                    }),
                    {
                      header: SUBMISSION_COPY.COLUMN_SUBMISSION_DATE,
                      cell: (info) => {
                        const rowSubmission = info.getValue();
                        return (
                          <Box
                            className={cs('submissions__row', {
                              'submissions__row--unread': !rowSubmission.hasBeenSeen,
                            })}
                          >
                            {prettyDate(rowSubmission.dateSubmitted || rowSubmission.dateCreated)}
                          </Box>
                        );
                      },
                      meta: {
                        width: '135px',
                      },
                    },
                  ),
                  columnHelper.accessor((row) => row, {
                    header: SUBMISSION_COPY.COLUMN_NUM_APPROVED,
                    cell: (info) => {
                      const rowSubmission = info.getValue();

                      return (
                        <Flex
                          className={cs('submissions__last-col', 'submissions__row', {
                            'submissions__row--unread': !rowSubmission.hasBeenSeen,
                          })}
                        >
                          {rowSubmission.numApprovedAssets}
                          <Flex className="submissions__extras-wrapper">
                            {currentUser.isSuperadmin && (
                              <SubmissionsPayout submission={rowSubmission} brief={currentBrief} />
                            )}
                            <MarketerSubmissionEllipsis
                              organizationId={currentBrief.organizationId}
                              submission={rowSubmission}
                            />
                          </Flex>
                        </Flex>
                      );
                    },
                    meta: {
                      width: '160px',
                    },
                  }),
                ]}
                onRowClick={(submission) => {
                  if (!submission.hasBeenSeen) {
                    markAsSeen({
                      submissionId: submission.id,
                    });
                  }
                  setQuery({ sId: submission.id });
                }}
              />
            </Pagination>
          </>
        )
      }
    </Box>
  );
};

export default SubmissionIndex;

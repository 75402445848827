import React from 'react';
import cs from 'classnames';
import PromptButton from 'shared/components/molecules/PromptButton';
import { BRIEF_COPY } from 'shared/config/copy';
import { Brief } from 'shared/typings/briefs';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import { Box } from 'shared/components/display';
import styles from './styles';

interface Props {
  brief: Brief;
  variant?: string;
  className?: string;
  onClick?: () => void;
}
const CloseBriefButton: React.FC<Props> = ({ brief, variant, className, onClick }) => {
  const { closeBrief } = useBriefs();

  const onClose = () => {
    const pushFunction = () => {
      return LinkCreator.createLink({
        routeKey: 'COLLECTION',
        variables: {
          accountId: brief.accountId,
          organizationId: brief.organizationId,
          collectionId: brief.collection.id,
        },
      });
    };

    closeBrief(brief.id, pushFunction);
  };

  return (
    <Box css={styles}>
      <PromptButton
        text={brief.numNewSubmissions > 0 ? BRIEF_COPY.MODAL_CLOSE_UNSEEN_SUBMISSIONS : BRIEF_COPY.MODAL_CLOSE}
        variant={variant}
        className={cs('close-brief-button', className)}
        onConfirm={onClose}
        onClick={onClick}
      >
        {BRIEF_COPY.BUTTON_CLOSE}
      </PromptButton>
    </Box>
  );
};

export default CloseBriefButton;

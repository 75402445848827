import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.file-upload': {
    '&__btn': {
      width: 'max-content',
    },
    '&__error': {
      color: COLORS.RED500,
      marginLeft: theme.space[4],
    },
  },
});

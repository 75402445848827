import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.brief-module': {
    '&__duration, &__tags, &__file-type, &__scene-wrapper': {
      marginBottom: theme.space[4],
      marginLeft: theme.space[3],
    },
    '&__duration-label, &__file-type-label': {
      fontWeight: theme.fontWeights.bold,
    },
    '&__scene-wrapper': {
      justifyContent: 'space-around',
      flexWrap: 'wrap',
    },
  },
});

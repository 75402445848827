/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { useState, useMemo } from 'react';
import ReactDropzone from 'react-dropzone';
import { useIntl } from 'react-intl';
import { Text } from 'rebass/styled-components';
import cs from 'classnames';

import ResponsiveDisplay from 'shared/components/atoms/ResponsiveDisplay';
import { Box } from 'shared/components/display';
import styles from 'shared/components/molecules/DragAndDropUpload/styles';

import { FILE_COPY } from 'shared/config/copy';
import { formatFileTypes } from 'shared/config/fileFormats';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { ModuleFileType } from 'shared/typings/briefs';
import { ErrorMessage } from 'shared/components/FieldErrorMessage';

import { MimeType } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowUp, faFilm, faImage } from '@fortawesome/pro-solid-svg-icons';
import { faFile } from '@fortawesome/pro-light-svg-icons';

const { NEUTRAL600 } = COLORS;
const { BODY, BODY_SM } = TEXT_VARIANTS;

export interface FileData {
  file: File;
  mediaObjectURL: string;
}
interface CommonProps {
  noClick?: boolean;
  text?: string;
  className?: string;
  supportedMedia?: ModuleFileType;
  // Accepts mime types only. Mime types are used to determine file extensions for [react-dropzone accept prop](https://react-dropzone.js.org/#section-accepting-specific-file-types)
  acceptedMedia?: readonly MimeType[];
  disabled?: boolean;
  multiple?: boolean;
  maxFiles?: number;
  disabledText?: string;
}

type MultipleFileProps = CommonProps & {
  onChange: (val: FileData[]) => void;
  multiple: true;
};

type SingleFileProps = CommonProps & {
  onChange: (val: FileData) => void;
  multiple?: false;
};

// Using a dscriminated union to define props type separately for multiple and single file upload
type Props = SingleFileProps | MultipleFileProps;

/**
 * This component handles fileInputs
 * Calls the setState hook function with the URL of the file,
 * file name, and media type
 */
const DragAndDropUpload: React.FC<Props> = (props) => {
  // destructuring an object messes up typescript's ability to
  // infer the types from a discriminated union. So the props
  // that are different for multi/single upload are referenced
  // off of props.
  const {
    noClick = false,
    supportedMedia = 'video',
    acceptedMedia = [],
    text = '',
    className = '',
    disabled = false,
    disabledText = text,
  } = props;
  const [dragEnter, setDragEnter] = useState(false);

  const { formatMessage } = useIntl();

  const formatFileData = (file: File): FileData => {
    return {
      file,
      mediaObjectURL: URL.createObjectURL(file),
    };
  };

  const handleFileChange = (acceptedFile: File[]) => {
    setDragEnter(false);
    const fileList: FileData[] = [];
    if (acceptedFile.length > 0) {
      while (acceptedFile.length > 0) {
        const nextFile = acceptedFile.pop();
        if (nextFile) {
          const fileData = formatFileData(nextFile);
          if (props.multiple) {
            fileList.push(fileData);
          } else {
            props.onChange(fileData);
          }
        }
      }

      if (props.multiple) props.onChange(fileList);
    }
  };

  return (
    <Box css={styles} className={cs('drag-drop', { 'drag-drop--disabled': disabled })}>
      <ReactDropzone
        disabled={disabled}
        onDrop={handleFileChange}
        onDragOver={() => {
          setDragEnter(true);
        }}
        onDragLeave={() => {
          setDragEnter(false);
        }}
        noClick={noClick}
      >
        {({ getRootProps, getInputProps, fileRejections }) => (
          <div {...getRootProps()} className={className}>
            {disabled ? (
              <Box className="drag-drop__input-wrapper">
                <MediaIcon supportedMedia={supportedMedia} />
                <Text variant={BODY} data-cy="drag-drop__disabled-message">
                  {disabledText}
                </Text>
              </Box>
            ) : (
              <Box
                data-cy="drag-drop"
                className={cs('drag-drop__input-wrapper', { 'drag-drop__input-wrapper--drag-enter': dragEnter })}
              >
                <input {...getInputProps()} />
                <ResponsiveDisplay display="mobile">
                  <Text variant={BODY}>
                    {text ||
                      formatMessage(
                        { id: 'BODY_UPLOAD_MEDIA' },
                        {
                          supportedMedia,
                        },
                      )}
                  </Text>
                </ResponsiveDisplay>
                <ResponsiveDisplay display="desktop">
                  <MediaIcon supportedMedia={supportedMedia} showUploadIcon={dragEnter} />

                  <Text data-cy="drag-drop__media-text" variant={BODY}>
                    {text || formatMessage({ id: `BODY_DRAG_DROP_${supportedMedia.toUpperCase()}` })}
                  </Text>
                  <Text variant={BODY_SM}>{FILE_COPY.BODY_SUBTITLE_DRAG_DROP}</Text>
                </ResponsiveDisplay>
                <Text variant={BODY_SM} color={NEUTRAL600}>
                  {FILE_COPY[`WARNING${supportedMedia.toUpperCase()}_RESTRICTIONS`]}
                </Text>
                {acceptedMedia?.length && <Text variant={BODY_SM}>({formatFileTypes(acceptedMedia)}) </Text>}
              </Box>
            )}
            {fileRejections.map(({ file, errors }) => (
              <div key={file.name}>
                {errors.map((e) => (
                  <ErrorMessage key={e.code}>
                    {file.name} {e.message}
                  </ErrorMessage>
                ))}
              </div>
            ))}
          </div>
        )}
      </ReactDropzone>
    </Box>
  );
};

const MediaIcon: React.FC<{ supportedMedia?: ModuleFileType; showUploadIcon?: boolean }> = ({
  supportedMedia,
  showUploadIcon,
}) => {
  const icon = useMemo(() => {
    if (supportedMedia === 'video') {
      return faFilm;
    }
    if (supportedMedia === 'image' || supportedMedia === 'gif') {
      return faImage;
    }
    return faFile;
  }, [supportedMedia]);

  return showUploadIcon ? (
    <FontAwesomeIcon className="drag-drop__icon" icon={faCircleArrowUp} size="2xl" />
  ) : (
    <FontAwesomeIcon className="drag-drop__icon" icon={icon} size="2xl" />
  );
};

export default DragAndDropUpload;

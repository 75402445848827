import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'shared/styles/styleFunctions';

interface Props {
  display: 'mobile' | 'desktop';
  children: React.ReactNode;
}
const ResponsiveDisplay: React.FC<Props> = ({ children, display }) => {
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const show = (isMobile && display === 'mobile') || (!isMobile && display === 'desktop');

  return show ? <>{children}</> : <></>;
};

export default ResponsiveDisplay;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export const BUTTON_WIDTH = '110px';

export default css({
  '&.shipping-link-modal': {
    '&__content': {
      padding: theme.space[4],
      paddingTop: 0,
    },
    '&__btn:not(:disabled):hover': {
      backgroundColor: COLORS.NEUTRAL50,
    },
  },
  '.shipping-link-modal': {
    '&__icon': {
      marginRight: theme.space[3],
    },

    '&__action-btn': {
      width: BUTTON_WIDTH,
    },
    '&__buttons': {
      marginTop: theme.space[4],
      justifyContent: 'space-between',
      alignContent: 'center',
    },
    '&__link': {
      display: 'flex',
      alignItems: 'center',
    },
    '&__link--disabled': {
      pointerEvents: 'none',
      color: COLORS.NEUTRAL700,
      '& svg': {
        color: COLORS.NEUTRAL700,
      },
    },
  },
});

import React from 'react';
import { components, ClearIndicatorProps } from 'react-select';
import { GeoLocationSuggestion } from 'shared/hooks/useGeoLocationHook';

const ClearIndicator: React.FC<ClearIndicatorProps<GeoLocationSuggestion, false>> = (props) => {
  return (
    <components.ClearIndicator
      {...props}
      innerProps={{
        ...props.innerProps,
        // @ts-ignore
        'data-cy': 'address-search__clear-indicator',
      }}
    />
  );
};

export default React.memo(ClearIndicator);

import { css } from '@emotion/react';

import COLORS, { STRIPE_COLORS } from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  alignItems: 'center',
  flexWrap: 'wrap',
  '.stripe-connect': {
    '&__link': {
      display: 'inline-flex',
      justifyContent: 'center',
      width: '100%',
      backgroundColor: STRIPE_COLORS.BLUE,
      borderRadius: '5px',
      padding: '8px 12px',
      alignItems: 'center',
      [mediaquery.md]: {
        width: 'auto',
      },
      '&__text': { margin: '0 5px 0 0', color: COLORS.WHITE },
      '&__logo': { paddingTop: '1px' },
    },
    '&__image': {
      height: '40px',
      width: 'auto',
    },
    '&__image:hover': {
      opacity: 0.8,
    },
    '&__image--connected, &__image--connected:hover': {
      cursor: 'default',
      opacity: 0.3,
    },
    '&__connected-wrapper': {
      backgroundColor: COLORS.TRANSPARENT,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '&__disconnect': {
      color: COLORS.NEUTRAL500,
      padding: '0 12px',
      margin: 0,
      '&:hover': {
        fontWeight: 'bold',
      },
    },
    '&__disconnect-wrapper': {
      alignItems: 'baseline',
      margin: '4px 0 0 4px',
      cursor: 'pointer',
    },
    '&__disconnect-icon': {
      fontSize: '10px',
      paddingTop: '1.3px',
    },
    '&__connected-message': {
      color: COLORS.PRIMARY500,
      margin: '0 0 0 4px',
    },
    '&__connected-message--thernary': {
      color: COLORS.PRIMARY500,
      margin: theme.space[4],
    },
    '&__error-message': {
      color: COLORS.RED500,
      margin: theme.space[4],
    },
    '&__secondary-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    '&__connect-button': {
      color: COLORS.NEUTRAL300,
      fontSize: theme.fontSizes[1],
      fontWeight: '600',
      padding: '0 6px',
      textAlign: 'end',
      marginBottom: '-6px',
    },
    '&__stripe-logo': {
      display: 'block',
    },
  },
});

export const ACCOUNT_COPY = {
  // Buttons
  BUTTON_ADD_ACCOUNT: `${String.fromCharCode(
    65291, // This is the html entity of a '+' sign
  )}  Add New Account`,
  BUTTON_EDIT_ACCOUNT_BUDGET_GOAL: 'Update Budget & Goals',
  BUTTON_SET_GOALS: 'Set Goals',
  BUTTON_BACK_TO_BUDGET: 'Back to Edit Budget',
  BUTTON_BACK_TO_CREATION: 'Back to Account Creation',
  BUTTON_UPLOAD_BRAND_ASSET: 'Upload Asset',
  BUTTON_UPLOAD_LOGO: 'Upload Logo',
  BUTTON_UPLOAD_BANNER: 'Upload Banner',
  BUTTON_DELETE_BANNER: 'Remove Banner',
  BUTTON_DELETE_LOGO: 'Remove Logo',
  BUTTON_CREATE_ACCOUNT: 'Create New Account',
  BUTTON_DELETE_ACCOUNT: 'Delete Account',
  BUTTON_EDIT_ACCOUNT: 'Edit Account',

  COLUMN_SEVEN_DAY_SPEND: 'Last Seven Day Spend',
  COLUMN_ACCOUNT_NAME: 'Name',

  // Widget Titles
  WIDGET_BRIEFS: 'Briefs',
  WIDGET_CHANNELS: 'Channels',
  WIDGET_CREATIVES: 'Top Performing Creatives',
  WIDGET_FACEBOOK_NO_DATA: 'No Creatives Yet',
  WIDGET_NO_DATA: 'Nothing to show yet',
  WIDGET_TIKTOK_NO_DATA: 'TikTok Creative Insights Coming Soon',

  // Account Config Headings
  HEADING_ACCOUNT_SETTINGS: 'Settings',
  HEADING_ACCOUNT_LOGO: 'Logo',
  HEADING_BANNER: 'Banner',
  HEADING_ACCOUNT_DETAILS: 'Details',
  HEADING_BRAND_ASSETS: 'Brand Guidelines',

  HEADING_ACCOUNT_BUDGET_ALLOCATION: 'Budget Allocation',
  HEADING_ACCOUNT_BUDGET: 'Account Budget',
  HEADING_SUPERADMIN_SETTINGS: 'Superadmin Settings',

  // Labels
  LABEL_NAME: 'Name',
  LABEL_DOMAIN: 'Domain',
  LABEL_SLOGAN: 'Slogan',

  MODAL_DELETE_ACCOUNT: 'Are you sure you want to delete this account?',

  // Nav links
  NAV_OVERVIEW: 'Overview',
  NAV_PLATFORMS: 'Platforms',
  NAV_ATTRIBUTION: 'Attributions',
  NAV_LIBRARY: 'Library',
  NAV_BRIEFS: 'Briefs',
  NAV_CREATORS: 'Creators',
  NAV_CONFIGURE: 'Settings',

  // SubNav
  SUB_NAV_CONVERSIONS: 'Conversions',
  SUB_NAV_MODEL_METRICS: 'Model Metrics',
  SUB_NAV_ACCOUNT_DETAILS: 'Account Details',
  SUB_NAV_CUSTOM_AUDIENCE: 'Customer List',

  // Alt text
  ONBOARDING_LINK_FACEBOOK_ACCOUNT: 'Link your Facebook business account',
  ONBOARDING_FB_CONNECTION_INFO_TEXT: `brkfst needs permission to access your Facebook business page(s), associated pixels, and Instagram accounts.

    brkfst uses your customer file to create audiences on Facebook. Consider using a group of 1,000 to 10,000 of your best customers based on lifetime value, transaction value, total order size, or engagement.`,
  ONBOARDING_FB_CONNECT_VALIDATION: 'Please link your Facebook account to continue',
  // Placeholders
  PLACEHOLDER_CREATE_ACCOUNT:
    'Please create an account to get started. Your account name on brkfst is the name of your Brand.',
  PLACEHOLDER_NO_ACCOUNTS: "Looks like you don't have access to any accounts yet",
  PLACEHOLDER_CUSTOMER_FILE: 'Upload Customer File',
  PLACEHOLDER_NO_BRAND_ASSETS: 'You have not uploaded any brand assets yet',

  // form
  FORM_HEADING_CREATE: 'Set up your Account',
  FORM_HEADING_USER_ACCESS: 'Account Access',
  FORM_DRAG_DROP_AUDIENCE: 'Drag & Drop CSV',
  FORM_CPA_TITLE: 'Cost per Acquisition',
};

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CollectionFileElliptical from 'features/media/components/molecules/CollectionFileElliptical';
import CreatorFileOverlayIcons from 'features/media/components/molecules/CreatorFileOverlayIcons';
import Collection from 'features/media/components/organisms/Collection';
import { GalleryItem } from 'features/media/interfaces/gallery';
import { useCollections } from 'features/media/useCollections';
import BackButton from 'shared/components/atoms/BackButton';
import ToggleSwitch from 'shared/components/atoms/ToggleSwitch';
import { Box } from 'shared/components/display';

import { COLLECTION_COPY, FILE_COPY } from 'shared/config/copy';
import { RESOURCES } from 'shared/config/resourceNames';
import { COLLECTION_TOASTS } from 'shared/config/toasts/collectionToasts';
import { BrkfstFile } from 'shared/typings/file';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { noAccess } from 'shared/utilities/routerUtility';

import styles from './styles';

const PAGE_SIZE = 10;

type Props = {
  brandView?: boolean;
};

const fileParams = {
  size: PAGE_SIZE,
  page: 1,
  orderBy: 'dateCreated',
  orderAsc: false,
  name: '',
  fileType: 'all',
};

const CreatorCollection: React.FC<Props> = ({ brandView = false }) => {
  const routeParams = useParams<any>();
  const collectionId = +routeParams.collectionId;
  const navigate = useNavigate();

  const { collection, updateCollection, loading } = useCollections({
    collectionId,
  });

  // Creator Collection can only be viewed when the collection is public or owned by current user
  if (brandView && !loading && !collection.publicAccess) {
    noAccess(navigate, `${RESOURCES.COLLECTION} Page`);
  }

  const formatGalleryItems = (itemObject: BrkfstFile): GalleryItem => {
    const options = !brandView ? (
      <CollectionFileElliptical mediaObject={itemObject} collectionId={collection.id} />
    ) : undefined;
    const OverlayLeft = <CreatorFileOverlayIcons file={itemObject} showSubmitted showTags />;
    return {
      itemObject,
      options,
      OverlayLeft,
      captionData: itemObject,
    };
  };

  const toggleCollectionPrivacy = () => {
    updateCollection({
      data: {
        publicAccess: !collection.publicAccess,
      },
      successToast: {
        message: collection.publicAccess
          ? COLLECTION_TOASTS.COLLECTION_MADE_PRIVATE
          : COLLECTION_TOASTS.COLLECTION_MADE_PUBLIC,
      },
    });
  };

  return (
    <Box css={styles} className="creator-collection">
      <BackButton
        to={
          !brandView
            ? LinkCreator.createLink({
                userType: UserType.CREATOR,
                routeKey: 'ASSETS_LIBRARY',
              })
            : LinkCreator.createLink({
                routeKey: 'USER_PROFILE',
                userType: UserType.CREATOR,
                variables: {
                  userId: routeParams.userId,
                },
              })
        }
      >
        {!brandView ? COLLECTION_COPY.BUTTON_BACK_TO_LIBRARY : COLLECTION_COPY.BUTTON_BACK_TO_CREATOR_PROFILE}
      </BackButton>

      <Collection
        CollectionControls={
          !brandView && !collection.isDefault ? (
            <ToggleSwitch
              onClick={toggleCollectionPrivacy}
              fieldName="collection-privacy-toggle"
              label={COLLECTION_COPY.LABEL_COLLECTION_TOGGLE_PRIVACY}
              toggledOn={collection.publicAccess}
              className="creator-collection__toggle"
            />
          ) : (
            <></>
          )
        }
        collectionId={collectionId}
        fileParams={fileParams}
        formatGalleryItems={formatGalleryItems}
        isSearchable
        isSelectable={!brandView}
        placeholderText={
          brandView ? FILE_COPY.PLACEHOLDER_DRAG_N_DROP_BRAND_VIEW : FILE_COPY.PLACEHOLDER_DRAG_N_DROP_GALLERY
        }
        isEditable={!brandView}
        onlyImage={brandView}
        forceViewMode={brandView ? 'Gallery' : ''}
      />
    </Box>
  );
};

export default CreatorCollection;

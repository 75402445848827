import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import { UploadFile } from 'features/ui/ui.slice';
import { ScrollingText } from 'shared/components/atoms/ScrollingText';
import { Box } from 'shared/components/display';
import AbortStatus from 'shared/components/molecules/UploadStatusDrawer/AbortStatus';
import UploadStatusIcon from 'shared/components/molecules/UploadStatusDrawer/UploadStatusIcon';

import COLORS from 'shared/styles/colors';
import { FileUploadStatus } from 'shared/typings/file/enums';

import { uploadDrawerItemStyles } from './styles';

interface Props {
  file: UploadFile;
  filesUploading: UploadFile[];
  newFilesLoading: number;
  updateFileUpload: (id: string, fileUploadStatus: FileUploadStatus, errors?: string | undefined) => void;
  dataCy?: string;
}
const UploadDrawerItem: React.FC<Props> = ({ file, filesUploading, newFilesLoading, updateFileUpload, dataCy }) => {
  const [newUploadsIds, setNewUploadsIds] = useState<Array<string | number>>([]);

  useEffect(() => {
    setNewUploadsIds(() => filesUploading.slice(0, newFilesLoading).map((file) => file.id));
  }, [newFilesLoading, filesUploading]);

  return (
    <Box
      css={uploadDrawerItemStyles}
      className={cs('upload-drawer-item', {
        'upload-drawer-item--animated': newUploadsIds.includes(file.id),
      })}
      data-cy={dataCy}
    >
      {file?.errors?.length === 0 && (
        <li className="upload-drawer-item__row">
          <UploadStatusIcon progress={file?.progress} status={file?.status} />
          <ScrollingText dataCy="upload-drawer-item__text" className="upload-drawer-item__file-name">
            {file?.name}
          </ScrollingText>
          <AbortStatus file={file} updateFileUpload={updateFileUpload} files={filesUploading} />
        </li>
      )}

      {file?.errors?.map((e: string, errorIndex: number) => (
        <li key={`${file.name}-error-${errorIndex}`} className="upload-drawer-item__row">
          <FontAwesomeIcon icon={faTimesCircle} color={COLORS.RED500} />
          <ScrollingText dataCy="upload-drawer-item__text" className="upload-drawer-item__file-name">
            {file?.name}
          </ScrollingText>
          <ScrollingText dataCy="upload-drawer-item__error" className="upload-drawer-item__error">
            {ReactHtmlParser(e)}
          </ScrollingText>
        </li>
      ))}
    </Box>
  );
};

export default UploadDrawerItem;

import React, { Fragment, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useInvoice } from 'features/organizations/useInvoice';
import { Text } from 'shared/components/display';
import CheckoutPayment from 'shared/components/molecules/CheckoutPayment';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import CheckoutPageTemplate from 'shared/components/organisms/CheckoutPageTemplate';

import { apiAction } from 'shared/actions/api';
import { BRIEF_COPY } from 'shared/config/copy';
import { API_BRIEF_ROUTES } from 'shared/config/routes/api/apiBriefRoutes';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import theme from 'shared/styles/themes';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

const BriefApprovalCheckoutPage = () => {
  const dispatch = useDispatch();
  const params = useParams<{ organizationId: string; accountId: string; briefId: string; creatorId: string }>();
  const location = useLocation<{ creatorBriefStatus: UserBriefStatus; creatorName: string; briefName: string }>();
  const navigate = useNavigate();
  const { organizationId, accountId, briefId, creatorId } = params;
  const { briefName, creatorBriefStatus, creatorName } = location.state || {};

  const { createBriefApprovalInvoice, checkoutInvoice } = useInvoice({
    accountId: +accountId,
    organizationId: +organizationId,
  });

  const pathToBrief = useMemo(
    () =>
      LinkCreator.createLink({
        routeKey: 'VIEW_INDIVIDUAL_BRIEF',
        variables: {
          accountId,
          organizationId,
          briefId,
        },
      }),
    [accountId, briefId, organizationId],
  );

  const successRedirectRoute = useMemo(
    () =>
      LinkCreator.createLink({
        routeKey: 'BRIEF_APPROVAL_CHECKOUT_CONFIRMATION',
        variables: {
          accountId,
          organizationId,
          briefId,
        },
      }),
    [accountId, briefId, organizationId],
  );

  const isValidState = useMemo(
    () => [UserBriefStatus.PENDING, UserBriefStatus.REJECTED].includes(creatorBriefStatus),
    [creatorBriefStatus],
  );

  useEffect(() => {
    if (!isValidState) navigate(pathToBrief);
  }, [isValidState, pathToBrief, history]);

  useEffect(() => {
    if (isValidState) createBriefApprovalInvoice({ briefId, creatorId, accountId });
  }, [briefId, creatorId, accountId, isValidState]);

  const confirmationPageProps = {
    body: BRIEF_COPY.CHECKOUT_SUCCESS_BODY_BRIEF_APPROVAL,
    buttonLink: pathToBrief,
    buttonText: BRIEF_COPY.CHECKOUT_SUCCESS_BUTTON_BRIEF,
  };

  const onPaymentDeclined = () => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.APPROVE_CREATOR_INVOICE_DECLINED,
          variables: {
            briefId,
            creatorId,
          },
        },
        method: 'PATCH',
      }),
    );
  };

  return (
    <CheckoutPageTemplate
      backButtonProps={{
        text: BRIEF_COPY.BUTTON_BACK_TO_BRIEF,
        to: pathToBrief,
        state: location.state,
      }}
      CheckoutComponent={
        <Fragment>
          <Text
            variant={TEXT_VARIANTS.H4}
            style={{
              marginBottom: theme.space[4],
            }}
          >
            {BRIEF_COPY.HEADING_APPROVE_CREATOR}
          </Text>
          <Text variant={TEXT_VARIANTS.H4}>{BRIEF_COPY.HEADING_DETAILS}</Text>
          <DefinitionList labelColor={COLORS.BLACK}>
            <ListDetail text="Brief">{briefName}</ListDetail>
            <ListDetail text="Creator">{creatorName}</ListDetail>
          </DefinitionList>
          <CheckoutPayment />
        </Fragment>
      }
      onSubmitText={BRIEF_COPY.BUTTON_CONFIRM_CREATOR_APPROVAL}
      heading={BRIEF_COPY.HEADING_PAYMENT_SUMMARY}
      successRedirectRoute={successRedirectRoute}
      confirmationPageProps={confirmationPageProps}
      onCheckoutError={onPaymentDeclined}
      leaveProcessingPageMessage={BRIEF_COPY.CHECKOUT_CONFIRM_LEAVE_PROCESSING_BRIEF}
    />
  );
};

export default BriefApprovalCheckoutPage;

import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { useUser } from 'features/users/hooks/useUser';
import Tooltip from 'shared/components/atoms/Tooltip';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import StreetAddress from 'shared/components/molecules/StreetAddress';

import { USER_COPY } from 'shared/config/copy';
import { Address } from 'shared/typings/address';

interface Props {
  userId: number;
}

const CreatorAddress: React.FC<Props> = ({ userId }) => {
  const { user, isCurrentUser, loadingAddress, getUserAddress } = useUser(userId);

  const [initLoad, setInitLoad] = useState(true);
  //If the address is not already loading in and does not exist on user, request the address
  useEffect(() => {
    if (user.mailingAddress === undefined && !loadingAddress && !initLoad) {
      getUserAddress(userId);
    }
    if (initLoad) {
      setInitLoad(!initLoad);
    }
  }, [user, loadingAddress, getUserAddress, userId, initLoad]);

  if (isEmpty(user.mailingAddress) && !isCurrentUser) {
    return <></>;
  }

  const AddressFields = (
    <DefinitionList>
      <ListDetail text={USER_COPY.SUBHEADING_MAILING_ADDRESS}>
        <StreetAddress address={user.mailingAddress as Address} />
      </ListDetail>
      <ListDetail text={USER_COPY.LABEL_PHONE_USER}>{user.phone?.number || USER_COPY.PLACEHOLDER_PHONE}</ListDetail>
    </DefinitionList>
  );

  return isCurrentUser ? <Tooltip content={USER_COPY.TOOLTIP_MAILING_ADDRESS}>{AddressFields}</Tooltip> : AddressFields;
};

export default CreatorAddress;

import { useAuth } from 'features/auth/useAuth';
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from 'shared/hooks/useQuery';
import { useToasts } from 'shared/hooks/useToasts';
import { decryptString } from 'shared/utilities/encryptionUtility';

const GoogleOauthLogin = () => {
  const { getUserByToken } = useAuth();
  const { setErrorToast } = useToasts();
  const { getQuery } = useQuery();

  const urlParams = getQuery();

  const googleIt = () => {
    const { redirectURI = '', token, message } = urlParams;
    if (token) {
      const decryptedURI = decryptString(decodeURIComponent(redirectURI));

      getUserByToken(token, decryptedURI);
    } else if (message) {
      setErrorToast({ message });
    }
  };

  useEffect(() => {
    googleIt();
  }, [urlParams]);

  return <Navigate to="/" />;
};

export default GoogleOauthLogin;

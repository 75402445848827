import { css } from '@emotion/react';
import { CSSInterpolation } from '@emotion/serialize';

import COLORS from 'shared/styles/colors';

const sharedStyles: CSSInterpolation = {
  position: 'absolute',
  backgroundColor: COLORS.BLACK,
  boxSizing: 'border-box',
  pointerEvents: 'none',
};

export default css({
  '&.creative-safe-zone': {
    opacity: '0.68',
  },
  '.creative-safe-zone': {
    '&__facebook': {
      ...sharedStyles,
      top: '0',
      left: '0',
      right: '0',
      bottom: '86%',
    },
    '&__facebook2': {
      ...sharedStyles,
      top: '0',
      left: '0',
      right: '94%',
      bottom: '0',
    },
    '&__facebook3': {
      ...sharedStyles,
      top: '0%',
      left: '94%',
      right: '-0.2px',
      bottom: '0',
    },
    '&__facebook4': {
      ...sharedStyles,
      top: '65%',
      left: '0',
      right: '0',
      bottom: '0',
    },
    '&__facebook5': {
      ...sharedStyles,
      top: '60%',
      left: '79%',
      right: '0',
      bottom: '0',
    },
    '&__facebook6': {
      ...sharedStyles,
      top: '13%',
      left: '11%',
      right: '11%',
      bottom: '86%',
    },
    '&__tiktok': {
      ...sharedStyles,
      top: '0',
      left: '0',
      right: '0',
      bottom: '87%',
    },
    '&__tiktok2': {
      ...sharedStyles,
      top: '0',
      left: '0',
      right: '89%',
      bottom: '0',
    },
    '&__tiktok3': {
      ...sharedStyles,
      top: '0',
      left: '89%',
      right: '-0.2px',
      bottom: '0',
    },
    '&__tiktok4': {
      ...sharedStyles,
      top: '67%',
      left: '0',
      right: '0',
      bottom: '0',
    },
    '&__tiktok5': {
      ...sharedStyles,
      top: '19%',
      left: '78%',
      right: '0',
      bottom: '0',
    },
  },
});

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.ad-health-sign-up': {
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.NEUTRAL50,
    alignContent: 'center',
    input: { borderRadius: '6px', borderColor: COLORS.NEUTRAL300 },
    'input::placeholder': {
      color: COLORS.NEUTRAL500,
    },
    overflowY: 'auto',
  },
  '.ad-health-sign-up': {
    '&__form': {
      flexDirection: 'column',
      margin: '25px',
      padding: '48px 36px 48px 36px',
      borderRadius: '16px',
      [mediaquery.md]: {
        maxWidth: '760px',
        flexDirection: 'column',
        margin: 'auto',
        padding: '48px 36px 36px 36px',
        borderRadius: '16px',
      },
    },
    '&__heading': {
      marginBottom: 0,
    },
    '&__subheading': {
      fontSize: theme.fontSizes[5],
      lineHeight: '1.2',
      [mediaquery.md]: { fontSize: theme.fontSizes[6] },
    },
    '&__text-wrapper': {
      flexDirection: 'column',
      justifyContent: 'space-between',
      [mediaquery.md]: {
        paddingRight: '30px',
      },
    },
    '&__pitch': {
      lineHeight: '1.2',
    },
    '&__list-header': {
      [mediaquery.md]: {
        marginBottom: 0,
      },
    },
    '&__list-item': {
      marginBottom: '10px',
      gap: '0.5rem',
      alignItems: 'center',
      [mediaquery.md]: {
        marginBottom: 0,
        gap: '0.5rem',
        alignItems: 'center',
      },
      p: {
        marginBottom: 0,
        lineHeight: '1.2',
        color: COLORS.NEUTRAL900,
        [mediaquery.md]: {
          lineHeight: '1.2',
          color: COLORS.NEUTRAL900,
        },
      },
    },
    '&__line': {
      width: '100%',
      backgroundColor: COLORS.NEUTRAL200,
      height: '1px',
      justifyContent: 'center',
      alignContent: 'center',
      margin: '16px 0',
    },
    '&__body': {
      flexDirection: 'column-reverse',
      [mediaquery.md]: {
        flexDirection: 'row',
        margin: '36px 0 20px 0',
      },
    },
    '&__image': {
      marginBottom: '24px',
      [mediaquery.md]: {
        width: '250px',
        height: 'auto',
        marginBottom: 0,
      },
    },
    '&__numbered-list-header': {
      [mediaquery.md]: {
        marginBottom: 0,
      },
    },
    '&__numbered-list': {
      p: {
        marginBottom: '0.5rem',
      },
    },
    '&__fields-wrapper': {
      [mediaquery.md]: {
        width: '100%',
        gap: '0 16px',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        alignItems: 'start',
      },
    },
    '&__footnote': {
      margin: `${theme.space[3]} 0 ${theme.space[6]} 0`,
    },
    '&__loading-icon': {
      fontSize: '24px',
      margin: '-3px 15px',
    },
    '&__submit-btn': {
      width: '100%',
      [mediaquery.md]: {
        width: '25%',
      },
    },
  },
});

import css from '@emotion/css';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';

export const BASE_CLASS = 'label';

export const MODIFIERS = {
  DISABLED: '--disabled',
  INVALID: '--invalid',
  ACTIVE: '--active',
  HAS_VALUE: '--has-value',
};

export default css({
  [`&.${BASE_CLASS}`]: {
    zIndex: zIndex.zBackdrop,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    background: COLORS.WHITE,
    padding: '0 0.5rem',
    fontSize: '12px',
    color: COLORS.NEUTRAL700,
    pointerEvents: 'none',
    position: 'absolute',
    top: '5px',
    left: '5px',
    margin: 0,
    visibility: 'hidden',

    [`&${MODIFIERS.ACTIVE}`]: {
      color: COLORS.PRIMARY500,
      visibility: 'visible',
    },

    [`&${MODIFIERS.DISABLED}`]: {
      color: COLORS.NEUTRAL500,
    },

    [`&${MODIFIERS.INVALID}`]: {
      color: COLORS.RED500,
      visibility: 'visible',
    },

    [`&${MODIFIERS.HAS_VALUE}`]: {
      visibility: 'visible',
    },
  },
});

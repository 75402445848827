import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.tt-videos': {
    '&__caption': {
      marginTop: theme.space[4],
    },
  },
  '.tt-videos': {
    '&__open-btn': {
      padding: `${theme.space[2]} ${theme.space[5]}`,
      border: 'none',
      outline: 'none',
      background: COLORS.WHITE,
      borderRadius: '2rem',
      cursor: 'pointer',
      userSelect: 'none',
      fontWeight: 600,
      lineHeight: 1.8,
    },
    '&__open-btn:hover *, &__open-btn:focus *': {
      color: COLORS.NEUTRAL500,
    },
    '&__open-btn--disabled *': {
      display: COLORS.BLACK,
    },
  },
});

import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
export default css({
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',

  '.account-health-score-display': {
    '&__score': {
      marginBottom: '4%',
    },
    '&__contributors': {
      flexDirection: 'column',
      width: '100%',
      marginBottom: '40px',
      [mediaquery.md]: {
        width: '80%',
      },
    },
    '&__contributors-grid': {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gridColumnGap: '10px',
    },
    '&__expand-btn': {
      width: 'auto',
      marginLeft: 'auto',
      backgroundColor: COLORS.TRANSPARENT,
      color: COLORS.BLACK,
      outline: 'none',
      cursor: 'pointer',
    },
    '&__improve-btn': {
      width: '80%',
      position: 'sticky',
      zIndex: zIndex.zStickyElements,
      bottom: '10px',
      [mediaquery.md]: {
        width: '31%',
        position: 'relative',
      },
    },
  },
});

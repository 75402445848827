import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '&.calendar-day-selector': {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
});

export const modifiersStyles = {
  selected: {
    backgroundColor: COLORS.PRIMARY200,
    color: COLORS.BLACK,
  },
};

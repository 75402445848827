import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { isEqual } from 'lodash';

import submissionDetailsStyles from 'features/submissions/components/molecules/SubmissionDetails/submissionDetailsStyles';
import SubmissionModules from 'features/submissions/components/SubmissionModules';
import { Box } from 'shared/components/display';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import Richtext, { RICHTEXT_DEFAULT_VALUE } from 'shared/components/Richtext';

import { SUBMISSION_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { breakpoints } from 'shared/styles/styleFunctions';
import { Submission } from 'shared/typings/submissions';

interface Props {
  submission: Submission;
}
const SubmissionDetails: React.FC<Props> = ({ submission }) => {
  const { isCreator } = useCurrentUser();
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  return (
    <Box className="submission-details" css={submissionDetailsStyles}>
      <DefinitionList uppercase={false} className="submission-details__details-wrapper">
        <ListDetail text={SUBMISSION_COPY.LABEL_BRIEF} hide={!isCreator || !isMobile}>
          {submission.brief.name}
        </ListDetail>
        <ListDetail text={SUBMISSION_COPY.LABEL_BRAND} hide={!isCreator || !isMobile}>
          {submission.brief.account.name}
        </ListDetail>
        <ListDetail
          text={SUBMISSION_COPY.LABEL_COMMENT}
          hide={isEqual(JSON.parse(submission.description), RICHTEXT_DEFAULT_VALUE)}
        >
          <Richtext
            value={JSON.parse(submission.description)}
            key={submission.id}
            placeholder={SUBMISSION_COPY.PLACEHOLDER_COMMENT}
            readOnly
          />
        </ListDetail>
      </DefinitionList>
      <SubmissionModules submission={submission} />
    </Box>
  );
};

export default SubmissionDetails;

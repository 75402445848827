import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.edit-audience': {
    flexDirection: 'column',
  },
  '.edit-audience': {
    '&__header': {
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    '&__body': {
      marginTop: '2rem',
      flexWrap: 'wrap',
      paddingLeft: '3rem',
      paddingRight: '3rem',
    },
    '&__tos-agreement-text': {
      marginLeft: '0.1rem',
      marginRight: '0.4rem',
    },
    '&__text': {
      marginTop: theme.space[4],
      width: '100%',
    },
    '&__button': {
      width: 'fit-content',
    },
    '&__form': {
      flexDirection: 'column',
      width: '100%',
    },
    '&__audience-file': {
      paddingTop: theme.space[5],
      paddingBottom: theme.space[5],
      alignItems: 'center',
    },
  },
});

import React from 'react';
import cs from 'classnames';
import { Field } from 'formik';

import { Box, Flex } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import ToggleInput from 'shared/components/ToggleInput';

import { makeArray } from 'shared/lib/formik';

import styles from './styles';

export interface Option {
  label: string;
  value: string;
  disabled?: boolean;
  checked?: boolean;
}

interface Props {
  disabled?: boolean;
  displayVertically?: boolean;
  onChange?: (val, label: string) => void;
}

const CheckboxGroupInput = makeArray<Option, Props>(
  ({ values, name, disabled, onChange, displayVertically = false }) => {
    return (
      <Box className="checkbox-group" css={styles}>
        <Flex
          className={cs('checkbox-group__options', {
            'checkbox-group__options--vertical': displayVertically,
          })}
        >
          {values.map(({ label, disabled: optionDisabled }, index) => (
            <Field
              name={`${name}.[${index}].checked`}
              component={ToggleInput}
              label={label}
              onChange={(val) => {
                onChange?.(val, label);
              }}
              disabled={optionDisabled || disabled}
              className="checkbox-group__option"
              key={label}
            />
          ))}
        </Flex>
        <FieldErrorMessage name={name} />
      </Box>
    );
  },
);

export default CheckboxGroupInput;

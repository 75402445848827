import React, { useMemo } from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';

import {
  ACCOUNT_FIELD_NAMES,
  MAX_NAME_LENGTH,
  MAX_SLOGAN_LENGTH,
  newAccountSchema,
  NewAccountValues,
} from 'features/accounts/components/molecules/NewAccountForm/config';
import newAccountFormStyles from 'features/accounts/components/molecules/NewAccountForm/newAccountFormStyles';
import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { useOrganizations } from 'features/organizations/useOrganizations';
import TagsInput from 'features/users/components/organisms/TagsInput';
import { TAG_CATEGORIES } from 'features/users/components/organisms/TagsInput/types';
import { Box, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import TextInput from 'shared/components/TextInput';

import { ACCOUNT_COPY } from 'shared/config/copy';
import { makeLabel } from 'shared/lib/formik';
import { TEXT_VARIANTS } from 'shared/styles/text';

interface Props {
  organizationId: number;
  defaultValues: NewAccountValues;
}

const NewAccountForm: React.FC<Props> = ({ organizationId, defaultValues }) => {
  const { organization } = useOrganizations(organizationId);
  const { accounts, createAccount } = useAccounts();

  const handleSubmit: (
    values: NewAccountValues,
    formikHelpers: FormikHelpers<NewAccountValues>,
  ) => void | Promise<any> = (formData, { setSubmitting }) => {
    createAccount(
      {
        ...formData,
        name: formData.name.trim(),
        organizationId,
        tags: formData.tags.reduce((acc: { value: string; category: string }[], { category, values }) => {
          values.forEach((tag) => {
            acc.push({ value: tag, category });
          });
          return acc;
        }, []),
      },
      () => {
        setSubmitting(false);
      },
    );
  };

  const initialValues = useMemo(() => {
    if (defaultValues) return defaultValues;
    return {
      // if an org has no account yet, auto-populate with org info for new accounts
      name: !accounts.length ? organization.name : '',
      domain: !accounts.length ? organization.domain : '',
      slogan: '',
      tags: [{ category: TAG_CATEGORIES.INDUSTRY, values: [] }],
    };
  }, [defaultValues]);

  return (
    <Formik<NewAccountValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={newAccountSchema}
      validateOnBlur={false}
    >
      {(formik) => (
        <Box css={newAccountFormStyles} className="new-account-form">
          <Form>
            <Text variant={TEXT_VARIANTS.H6} className="new-account-form__header">
              {ACCOUNT_COPY.FORM_HEADING_CREATE}
            </Text>
            <Text variant={TEXT_VARIANTS.H5}>{ACCOUNT_COPY.HEADING_ACCOUNT_DETAILS}</Text>
            <Field
              name={ACCOUNT_FIELD_NAMES.NAME}
              component={TextInput}
              label={makeLabel(ACCOUNT_FIELD_NAMES.NAME, true)}
              placeholder={makeLabel(ACCOUNT_FIELD_NAMES.NAME, true)}
              showCharactersCounter
              maxLength={MAX_NAME_LENGTH}
            />
            <Field
              name={ACCOUNT_FIELD_NAMES.DOMAIN}
              component={TextInput}
              label={makeLabel(ACCOUNT_FIELD_NAMES.DOMAIN, true)}
              placeholder={makeLabel(ACCOUNT_FIELD_NAMES.DOMAIN, true)}
            />
            <Field
              name={ACCOUNT_FIELD_NAMES.SLOGAN}
              component={TextInput}
              label={makeLabel(ACCOUNT_FIELD_NAMES.SLOGAN)}
              placeholder={makeLabel(ACCOUNT_FIELD_NAMES.SLOGAN)}
              showCharactersCounter
              maxLength={MAX_SLOGAN_LENGTH}
            />
            <Box py="10px">
              <TagsInput
                dataCy="new-account-form__industry-tags"
                name={ACCOUNT_FIELD_NAMES.TAGS}
                allowAddCategory={false}
                placeholder="Industry"
                theme="top-category-plain"
              />
            </Box>
            <FormButton loading={formik.isSubmitting} className="new-account-form__submit-btn">
              {ACCOUNT_COPY.BUTTON_CREATE_ACCOUNT}
            </FormButton>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default NewAccountForm;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Button, Text } from 'rebass/styled-components';

import BriefAcceptInviteButton from 'features/briefs/components/atoms/BriefAcceptInviteButton';
import BriefApplicationButton from 'features/briefs/components/molecules/BriefApplicationButton';
import { useSubmissions } from 'features/submissions/useSubmissions';
import { componentLoadingToggled } from 'features/ui/ui.slice';
import Chip from 'shared/components/atoms/Chip/Chip';
import Tooltip from 'shared/components/atoms/Tooltip';
import ActionLink from 'shared/components/clicks/ActionLink';
import { Flex } from 'shared/components/display';

import { SUBMISSION_COPY } from 'shared/config/copy';
import { BRIEF_COPY } from 'shared/config/copy/briefCopy';
import { RESOURCES } from 'shared/config/resourceNames';
import { breakpoints } from 'shared/styles/styleFunctions';
import { STYLES } from 'shared/styles/styles';
import { TEXT_VARIANTS } from 'shared/styles/text';
import theme from 'shared/styles/themes';
import { Brief } from 'shared/typings/briefs';
import { BriefTypes } from 'shared/typings/briefs/enums';
import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
const { SUBHEADING } = TEXT_VARIANTS;
const { CENTER, NONE } = STYLES;

const {
  colors: { ORANGE100, ORANGE700, GREEN100, GREEN700, PRIMARY400, RED700, RED50 },
} = theme;

interface Props {
  currentBrief: Pick<Brief, 'id' | 'briefCreatorStatus' | 'briefCreatorSubmission' | 'type' | 'full' | 'inviteOnly'>;
  currentUser: Pick<BrkfstUser, 'hasPortfolio' | 'id' | 'stripeAccountId'>;
}

const HeaderCreatorActions: React.FC<Props> = ({ currentBrief, currentUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md - 1 });
  const { createSubmission } = useSubmissions();

  const navigateToSubmissionForm = () => {
    dispatch(
      componentLoadingToggled({
        component: RESOURCES.SUBMISSION,
        loading: true,
      }),
    );
    navigate(
      LinkCreator.createLink({
        userType: UserType.CREATOR,
        routeKey: 'CREATE_UPDATE_SUBMISSION',
        variables: {
          submissionId: currentBrief.briefCreatorSubmission?.id,
        },
      }),
    );
  };

  switch (currentBrief.briefCreatorStatus) {
    case UserBriefStatus.NOT_APPLIED:
      if (currentBrief.inviteOnly) {
        return (
          <Flex alignItems={CENTER} flex={NONE}>
            <Chip label={BRIEF_COPY.LABEL_INVITE_ONLY} color={PRIMARY400} bgColor={NONE} square />
            <Button ml={4} disabled>
              {BRIEF_COPY.BUTTON_APPLY_BRIEF}
            </Button>
          </Flex>
        );
      }
      if (currentBrief.full) {
        return (
          <Flex alignItems={CENTER} flex={NONE}>
            <Chip label={BRIEF_COPY.LABEL_FULL_DISPLAY} color={PRIMARY400} bgColor={NONE} square />
            <Button ml={4} disabled>
              {BRIEF_COPY.BUTTON_APPLY_BRIEF}
            </Button>
          </Flex>
        );
      }
      if (!currentUser.hasPortfolio) {
        return (
          <Flex alignItems={CENTER} flex={NONE}>
            <ActionLink
              to={LinkCreator.createLink({
                userType: UserType.CREATOR,
                routeKey: 'USER_PROFILE',
                variables: {
                  userId: currentUser.id,
                },
              })}
              defaultText={BRIEF_COPY.LINK_PORTFOLIO_NOT_CONNECTED}
              actionText={BRIEF_COPY.LINK_HOVER_PORTFOLIO_NOT_CONNECTED}
              className="brief-header-creator-actions"
            />
            <Button ml={4} disabled>
              {BRIEF_COPY.BUTTON_APPLY_BRIEF}
            </Button>
          </Flex>
        );
      }

      return (
        <BriefApplicationButton
          className="brief-application-button"
          userId={currentUser.id}
          briefId={currentBrief.id}
        />
      );
    case UserBriefStatus.PENDING:
      if (currentBrief.full) {
        return (
          <Flex alignItems={CENTER} flex={NONE}>
            <Chip label={BRIEF_COPY.LABEL_FULL_DISPLAY} color={PRIMARY400} bgColor={NONE} square />
            <Chip
              dataCy="brief-header-creator-actions__brief-label"
              label={BRIEF_COPY.LABEL_APPROVAL_PENDING}
              color={ORANGE700}
              bgColor={ORANGE100}
              square
            />
          </Flex>
        );
      } else {
        return (
          <Chip
            dataCy="brief-header-creator-actions__brief-label"
            label={BRIEF_COPY.LABEL_APPROVAL_PENDING}
            color={ORANGE700}
            bgColor={ORANGE100}
            square
          />
        );
      }
    case UserBriefStatus.DRAFTED:
      return (
        <Button data-cy="brief-header-creator-actions__finish-submission-btn" onClick={navigateToSubmissionForm}>
          {BRIEF_COPY.BUTTON_COMPLETE_BRIEF_SUBMISSION}
        </Button>
      );
    case UserBriefStatus.ACTIVE:
      if (!currentUser.stripeAccountId && currentBrief.type === BriefTypes.BRAND) {
        return (
          <Flex alignItems={CENTER} flex={NONE}>
            <ActionLink
              to={LinkCreator.createLink({
                userType: UserType.CREATOR,
                routeKey: 'ACCOUNT_SETTINGS',
                variables: {
                  userId: currentUser.id,
                },
              })}
              defaultText={BRIEF_COPY.LINK_STRIPE_NOT_CONNECTED}
              actionText={BRIEF_COPY.LINK_HOVER_STRIPE_NOT_CONNECTED}
              className="brief-header-creator-actions"
              width="150px"
            />
            <Button ml={4} disabled>
              {BRIEF_COPY.BUTTON_SUBMIT_BRIEF}
            </Button>
          </Flex>
        );
      }
      return (
        <Flex alignItems={CENTER} flex={NONE} className="brief-header-creator-actions">
          <Text color={PRIMARY400} variant={SUBHEADING}>
            {BRIEF_COPY.LABEL_APPROVED}
          </Text>
          <Button
            onClick={() => createSubmission(currentBrief.id)}
            className="brief-header-creator-actions__submission-btn"
            data-cy="brief-header-creator-actions__submission-btn"
          >
            {BRIEF_COPY.BUTTON_SUBMIT_BRIEF}
          </Button>
        </Flex>
      );
    case UserBriefStatus.REJECTED:
      return <Chip label={BRIEF_COPY.LABEL_NOT_SELECTED} color={RED700} bgColor={RED50} square />;
    case UserBriefStatus.SUBMITTED:
      return (
        <Tooltip
          content={SUBMISSION_COPY.SUBMITTED_TOOLTIP}
          as="span"
          arrow
          placement={isMobile ? 'bottom-end' : 'right'}
          offset={isMobile ? [10, 10] : [5, 20]}
        >
          <Chip
            label={BRIEF_COPY.LABEL_SUBMITTED}
            color={GREEN700}
            bgColor={GREEN100}
            square
            onClick={navigateToSubmissionForm}
          />
        </Tooltip>
      );
    case UserBriefStatus.INVITED:
      if (currentBrief.full) {
        return (
          <Flex alignItems={CENTER} flex={NONE}>
            <Chip label={BRIEF_COPY.LABEL_FULL_DISPLAY} color={PRIMARY400} bgColor={NONE} square />
            <BriefAcceptInviteButton userId={currentUser.id} briefId={currentBrief.id} full={currentBrief.full} />
          </Flex>
        );
      } else {
        return <BriefAcceptInviteButton userId={currentUser.id} briefId={currentBrief.id} />;
      }

    default:
      return null;
  }
};

export default HeaderCreatorActions;

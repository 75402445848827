import { css } from '@emotion/react';

export default (positionProps: { top?: string; bottom?: string; left?: string; right?: string }) =>
  css({
    '&.hover-overlay': {
      position: 'relative',
      '&:hover .hover-overlay__overlay': {
        visibility: 'visible',
      },
    },
    '.hover-overlay': {
      '&__overlay': {
        visibility: 'hidden',
        position: 'absolute',
        ...positionProps,
      },
    },
  });

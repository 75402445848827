import React from 'react';

import TotalEarningsHeader from 'features/users/components/molecules/TotalEarningsHeader/TotalEarningsHeader';
import { Earning, EarningPayout } from 'features/users/pages/Earnings/Earnings';
import { Flex } from 'shared/components/display';
import BrkfstTable from 'shared/components/organisms/BrkfstTable/BrkfstTable';

import { formatCurrency } from 'shared/utilities/stringUtility';

import styles from './styles';

interface Props {
  earnings: Array<Earning | EarningPayout>;
  onEarningRowClick: (el: any) => void;
  columns: Array<any>;
  loading?: boolean;
  noResultsMessage: string;
  totalEarnings: number;
}

const EarningsTable = ({ earnings, onEarningRowClick, columns, loading = false, noResultsMessage }: Props) => {
  const totalEarningsAmount = formatCurrency(
    earnings.reduce((acc, earning) => {
      return acc + +earning.totalAmount;
    }, 0) / 100,
  );

  return (
    <Flex className="earnings-mobile-table" css={styles}>
      <TotalEarningsHeader total={totalEarningsAmount} />

      <BrkfstTable
        data={earnings}
        columns={columns}
        onRowClick={onEarningRowClick}
        loading={loading}
        noResultsMessage={noResultsMessage}
      />
    </Flex>
  );
};

export default EarningsTable;

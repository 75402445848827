import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { currentUser, getRedirectUri, portfolioChecked, redirectURISet } from 'features/auth/auth.slice';

import { apiAction } from 'shared/actions/api';
import { userConfigurationChanged } from 'shared/actions/currentUser';
import { API_CONFIGURATION_ROUTES } from 'shared/config/routes/api/apiConfigurationRoutes';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { BrkfstUser } from 'shared/typings/user';
import { UserType, VettingStatus } from 'shared/typings/user/enums';

const CHECKING_PORTFOLIO = 'CHECKING_PORTFOLIO';

export const useCurrentUser = () => {
  const dispatch = useDispatch();
  const user: BrkfstUser = useSelector(currentUser);
  const redirectURI = useSelector(getRedirectUri);
  const { onboarded, vettingStatus } = user;
  const authenticated = !isEmpty(user);
  const userType = user.type;
  const isMarketer = userType === UserType.ORG;
  const isCreator = userType === UserType.CREATOR;
  const isVetted = vettingStatus === VettingStatus.APPROVED;

  const { loading } = useComponentLoading(CHECKING_PORTFOLIO, false);
  const [lastVisitedOrg, configurationId]: number[] = useMemo(() => {
    if (authenticated && user.userConfiguration) {
      const {
        userConfiguration: {
          value: { lastVisitedOrg: orgId },
          id: configId,
        },
      } = user;
      return [orgId, configId];
    }
    // if the userConfiguration value is empty, just send them to the first organization they have access to
    if (user.roles && user.roles.length) {
      return [user.roles[0].organizationId, null];
    }
    // probably need something better than null
    return [null, null];
  }, [user.userConfiguration]);

  const isSuperadmin = user.isSuperadmin;
  const updateLastVisitedOrg = (organizationId) => {
    const { userConfiguration } = user;
    if (userConfiguration)
      dispatch(
        apiAction({
          path: {
            route: API_CONFIGURATION_ROUTES.CONFIGURATION,
            variables: {
              configurationId,
            },
          },
          method: 'PATCH',
          successAction: userConfigurationChanged,
          data: {
            ...userConfiguration,
            value: {
              ...userConfiguration?.value,
              lastVisitedOrg: organizationId,
            },
          },
        }),
      );
  };

  const updateRedirectURI = (uri = '') => {
    if (uri === null) uri = '';
    dispatch(redirectURISet(uri));
  };

  const checkHasPortfolio = () => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.CHECK_PORTFOLIO,
          variables: {
            userId: user.id,
          },
        },
        entity: CHECKING_PORTFOLIO,
        successAction: portfolioChecked,
      }),
    );
  };

  return {
    currentUser: user,
    lastVisitedOrg,
    authenticated,
    userType,
    isCreator,
    isMarketer,
    updateLastVisitedOrg,
    onboarded,
    isVetted,
    checkHasPortfolio,
    loading,
    isSuperadmin,
    redirectURI,
    updateRedirectURI,
  };
};

export const STRIPE_COPY = {
    // Buttons
    BUTTON_DASHBOARD: 'Access Stripe Account',

    // Alt
    ALT_POWERED_BY_STRIPE: 'Powered By Stripe',
    ALT_LOGO: 'Stripe',

    // HEADING
    PROCESSING: 'Processing',

    // Links
    LINK_EDIT_STRIPE: 'Edit Stripe Account',
    LINK_CONNECT: 'Connect Account',

    // Warnings
    WARNING_NOT_CONNECTED: 'Not Connected',
    CONNECTED: 'Connected',

    // onboarding
    ONBOARDING_CONNECT_VALIDATION:
        'Please link your Stripe account to continue',
};

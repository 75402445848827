import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.address-form': {
    '&__btn-wrapper': {
      width: '100%',
      justifyContent: 'flex-end',
      marginTop: theme.space[4],
      '& > *:not(:last-child)': {
        marginRight: theme.space[5],
      },
    },
    '&__btn-wrapper--right': {
      justifyContent: 'flex-end',
    },
    '&__cancel-btn': {
      width: '100px',
      backgroundColor: COLORS.NEUTRAL100,
      cursor: 'pointer',
      color: COLORS.BLACK,
    },
  },
});

import React, { forwardRef } from 'react';
import { Button } from 'rebass/styled-components';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

import { BUTTON_VARIANTS } from 'shared/styles/button';

interface Props extends Omit<FontAwesomeIconProps, 'onClick'> {
  className?: string;
  svgClassName?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const IconButton = forwardRef<any, Props>(({ icon, className, title, svgClassName, onClick, disabled, color }, ref) => {
  return (
    <Button
      variant={BUTTON_VARIANTS.TEXT_ONLY}
      type="button"
      onClick={(e) => {
        onClick(e);
        e.currentTarget.blur();
      }}
      disabled={disabled}
      className={className}
    >
      <FontAwesomeIcon icon={icon} title={title} className={svgClassName} color={color} ref={ref} />
    </Button>
  );
});

export default IconButton;

import React from 'react';
import { Button } from 'rebass/styled-components';

import { useContainer } from 'shared/hooks/useContainer';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import Tooltip, { TooltipPlacement } from 'shared/components/atoms/Tooltip';

const { TOOL } = BUTTON_VARIANTS;

interface Props {
  placement?: TooltipPlacement;
  className?: string;
  label?: string;
  variant?: string;
  clickComponent: React.ReactElement;
  clickComponentWidth?: string;
  children: React.ReactNode;
}

const DropdownWrapper: React.FC<Props> = ({
  placement = 'bottom-end',
  clickComponent,
  children: content,
  variant = TOOL,
  label = 'dropdown', // Use a unique label when using multiple dropdowns in a page.
  className, // optional: overwrite Tooltip styles with styled-components
  clickComponentWidth = null, // optional: resize the button wrapping the click component
}) => {
  const { containerState: isOpen, closeContainer: close, openContainer: open } = useContainer(label);

  return (
    <Tooltip
      className={className}
      theme="light"
      maxWidth="100%"
      onClickOutside={close}
      interactive
      visible={!!isOpen}
      content={content}
      placement={placement}
    >
      <Button width={clickComponentWidth} type="button" variant={variant} onClick={isOpen ? close : open}>
        {clickComponent}
      </Button>
    </Tooltip>
  );
};

export default DropdownWrapper;

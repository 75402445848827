import { creatorBriefsLoaded, getCreatorBriefs, getTotalCreatorBriefs } from 'features/briefs/creatorBriefs.slice';

import { useDispatch, useSelector } from 'react-redux';
import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_BRIEF_ROUTES } from 'shared/config/routes/api/apiBriefRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { Brief } from 'shared/typings/briefs';

interface CreatorBriefQuery {
  inProgress?: boolean;
  type?: number;
  page?: number;
  size?: number;
}

export const useCreatorBriefs = () => {
  // For briefs that the creator has interacted with
  // either in-progress/completed
  //  useBriefs is for all briefs
  const briefs = useSelector(getCreatorBriefs);
  const total = useSelector(getTotalCreatorBriefs);
  const dispatch = useDispatch();

  const { loading } = useComponentLoading(RESOURCES.CREATOR_BRIEF, true);

  const fetchCreatorBriefs = (userId: number, query: CreatorBriefQuery) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.CREATOR_BRIEFS,
          variables: { userId },
        },
        params: query,
        entity: RESOURCES.CREATOR_BRIEF,
        successAction: creatorBriefsLoaded,
      }),
    );
  };

  return {
    briefs,
    loading,
    fetchCreatorBriefs,
    total,
  };
};

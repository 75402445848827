import React, { useEffect } from 'react';

import AuthPageTemplate from 'features/auth/AuthPageTemplate/AuthPageTemplate';
import SignInForm from 'features/auth/components/organisms/SignInForm';

import { AUTH_COPY } from 'shared/config/copy';
import { useQuery } from 'shared/hooks/useQuery';
import { useQueryToast } from 'shared/hooks/useQueryToast';

const SignInPage = () => {
  const { getQuery } = useQuery();
  const { queryToast } = useQueryToast();

  const urlParams = getQuery();
  const { redirectURI = '', search = '' } = urlParams;
  const redirectTo = redirectURI + search;

  useEffect(() => {
    queryToast(urlParams.error);
  }, [urlParams]);

  return (
    <AuthPageTemplate
      welcomeHeading={AUTH_COPY.HEADING_SIGN_IN_WELCOME}
      welcomeSubheading={AUTH_COPY.SUBHEADING_SIGN_IN_WELCOME}
      googleText={AUTH_COPY.BUTTON_GOOGLE_SIGN_IN}
      facebookText={AUTH_COPY.BUTTON_FACEBOOK_SIGN_IN}
      urlParams={urlParams}
      signIn
    >
      <SignInForm redirectTo={redirectTo} />
    </AuthPageTemplate>
  );
};

export default SignInPage;

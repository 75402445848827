import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  alignItems: 'center',
  '.file-input': {
    '&__filename': {
      flex: 1,
      margin: '.75rem 0.5rem .25rem 0',
      cursor: 'pointer',
      borderRadius: '2px',
      border: `1px solid ${COLORS.NEUTRAL300}`,
      padding: '0.5rem 0.75rem',
    },
    '&__placeholder': {
      color: COLORS.NEUTRAL700,
      userSelect: 'none',
      '&--capitalized': {
        textTransform: 'capitalize',
      },
    },
    '&__btn': {
      marginTop: '5px',
    },
  },
});

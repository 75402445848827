import CollectionForm, { CollectionFormProps } from 'features/media/components/CollectionForm/CollectionForm';
import Modal from 'shared/components/molecules/Modal';
import { SIZING } from 'shared/config/formatting';
import React, { useState } from 'react';

interface Props {
  children: React.ReactElement;
  title: string;
}
const CollectionFormModal: React.FC<Props & CollectionFormProps> = ({
  name,
  onSubmit,
  children,
  submitText,
  title,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <>
      {React.cloneElement(children, {
        onClick: toggleModal,
      })}
      <Modal
        modalSize={SIZING.MEDIUM}
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        title={title}
        component={
          <CollectionForm
            name={name}
            onSubmit={(values) => {
              onSubmit(values);
              toggleModal();
            }}
            submitText={submitText}
          />
        }
      />
    </>
  );
};

export default CollectionFormModal;

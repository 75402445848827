import type { Validator, ValidatorOptions } from './types';

export class MultiValidator implements Validator {
  private validators: Validator[];

  constructor(...validators: Validator[]) {
    this.validators = validators;
  }

  public hasValidatorType(type): boolean {
    return this.validators.some((v) => v instanceof type);
  }

  public async validate(options: ValidatorOptions) {
    const errorMessages: Array<string> = [];
    const promises = this.validators.map((validator) => validator.validate(options));
    const vMessages = await Promise.all(promises);
    for (const messages of vMessages) {
      messages.forEach((error) => {
        errorMessages.push(error);
      });
    }
    return errorMessages;
  }
}

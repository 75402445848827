import React, { useState } from 'react';
import ModuleReviewLightbox from 'shared/components/molecules/ModuleReviewLightbox/ModuleReviewLightbox';
import { useReviewModules } from 'features/reviewModules/useReviewModules';
import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { HandleCriticalErrors } from 'shared/components/organisms/ModuleReview/ModuleReview';
import { ModuleSubmissionStatus } from 'shared/typings/submissions';

interface Props {
  moduleId: number;
  accountId: number;
  briefName: string;
  brandName: string;
  open: boolean;
  toggleLightbox: () => void;
  displayAssetId: number | null;
}
const IndividualModuleReview: React.FC<Props> = ({
  moduleId,
  accountId,
  briefName,
  brandName,
  toggleLightbox,
  open,
  displayAssetId,
}) => {
  const [approvalLoading, setApprovalLoading] = useState(false);

  const { account } = useAccounts({ accountId: `${accountId}` });
  const { reviewModule, createRevision, loading, updateSubmissionModuleStatus } = useReviewModules(moduleId);

  const handleCriticalErrors: HandleCriticalErrors = ({
    data,
    errorCategory,
    submissionModuleId,
    creatorId,
    onSuccess,
  }) => {
    const onSuccessWithLoading = (response) => {
      if (onSuccess) onSuccess(response);
      setApprovalLoading(false);
    };

    setApprovalLoading(true);
    createRevision(
      { category: errorCategory, comment: data.comment, submissionModuleId },
      creatorId,
      ModuleSubmissionStatus.REVISION,
      onSuccessWithLoading,
      () => setApprovalLoading(false),
    );
  };

  const handleApproveModule = (submissionModuleId: number, hasTodoRevision: boolean) => {
    setApprovalLoading(true);
    updateSubmissionModuleStatus({
      submissionModuleId,
      status: hasTodoRevision ? ModuleSubmissionStatus.REVISION : ModuleSubmissionStatus.APPROVED,
      onSuccess: () => setApprovalLoading(false),
      onError: () => setApprovalLoading(false),
    });
  };

  if (loading || !reviewModule) return null;

  return (
    <ModuleReviewLightbox
      isOpen={open}
      onRequestClose={toggleLightbox}
      currentModule={0}
      modules={[reviewModule]}
      handleApproveModule={handleApproveModule}
      handleCriticalErrors={handleCriticalErrors}
      briefName={briefName}
      brandName={brandName}
      approvalLoading={approvalLoading}
      enableModuleComments={account.enableModuleComments}
      displayAssetId={displayAssetId}
    />
  );
};

export default IndividualModuleReview;

import React, { useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { ALL_ROUTES } from 'shared/config/routes/generalRoutes';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { isExistingRoute } from 'shared/utilities/routeUtility';

const RedirectCreator: React.FC = () => {
  const location = useLocation<any>();
  const { redirectURI, updateRedirectURI } = useCurrentUser();

  const shouldRedirect = useMemo(() => {
    const isValidRoute = isExistingRoute(UserType.CREATOR, redirectURI);

    return isValidRoute && redirectURI !== '' && redirectURI !== '/';
  }, [redirectURI]);

  useEffect(() => {
    return () => {
      updateRedirectURI('');
    };
  }, []);

  if (shouldRedirect) {
    return <Navigate to={redirectURI} />;
  }

  return !location.pathname.includes(ALL_ROUTES.CREATORS.DASHBOARD) ? (
    <Navigate
      to={LinkCreator.createLink({
        routeKey: 'DASHBOARD',
        userType: UserType.CREATOR,
      })}
    />
  ) : null;
};

export default RedirectCreator;

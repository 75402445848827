import { RichTextFormats, RichtextValue } from 'shared/components/Richtext/shared/types';
import { ImageElement } from 'shared/typings/slate';
import { Text } from 'slate';

export const EMPTY_TEXT: Text = { text: '' };

export const RICHTEXT_DEFAULT_VALUE: RichtextValue = [
  {
    type: RichTextFormats.PARAGRAPH,
    children: [EMPTY_TEXT],
  },
];

export const EMPTY_ELEMENT: ImageElement = {
  type: RichTextFormats.IMAGE,
  url: '',
  children: [EMPTY_TEXT],
};

import { moduleDefaults } from 'features/briefs/components/BriefForm/components/BriefModulesForm';
import { SelectValue } from 'shared/components/molecules/Select';
import { EMPTY_TEXT, RICHTEXT_DEFAULT_VALUE } from 'shared/components/Richtext';
import { RichTextFormats } from 'shared/components/Richtext/shared/types';

import { FACEBOOK_VIDEO_MAX_DURATION } from 'shared/config/validations';
import { AccountPricingConfig } from 'shared/typings/account';
import { State } from 'shared/typings/address';
import { Brief } from 'shared/typings/briefs';
import { BriefStatus } from 'shared/typings/briefs/enums';

import { FormBrief } from './types';

export const SCENE_FIELD_NAMES = {
  TITLE: 'title',
  DESCRIPTION: 'description',
};

export const MIN_SCENES = 0;
export const MAX_SCENES = 10;

export function briefToBriefFormState(briefIn?: Brief, pricing?: AccountPricingConfig): FormBrief {
  const formattedModules = briefIn?.modules?.map((module) => ({
    ...module,
    content: JSON.parse(module.content),
    tags: module.tags,
    fileType: module.fileType,
    durationRange: [module.minAssetDuration || 0, module.maxAssetDuration || FACEBOOK_VIDEO_MAX_DURATION],
    scenes: module.scenes.map(({ description, ...scene }) => ({
      ...scene,
      description: JSON.parse(description),
    })),
  }));

  const basicFields = {
    name: briefIn?.name || '',
    status: briefIn?.status || BriefStatus.DRAFT,
    freeProduct: briefIn?.freeProduct || '',
    website: briefIn?.website || '',
    description: briefIn?.description ? JSON.parse(briefIn?.description) : RICHTEXT_DEFAULT_VALUE,
    promotionalCode: briefIn?.promotionalCode || '',
    autoResponse: briefIn?.autoResponse || '',
    brandAssets: {
      assets: briefIn?.brandAssets || [],
      newFiles: [],
    },
    inviteOnly: briefIn?.inviteOnly || false,
    shippingRequired: briefIn?.shippingRequired || false,
    inShopRequired: Boolean(briefIn?.states?.length) || false,
    creativeOnly: briefIn?.creativeOnly || false,
    modules: formattedModules || [],
    states:
      briefIn?.states?.map((state): SelectValue<State> => {
        return { label: state.code || '', value: state };
      }) || [],
    internalNote: briefIn?.internalNote || '',
  };

  const pricingFields = {
    creatorPayout: briefIn?.creativeOnly
      ? pricing?.creativeOnlyCreatorPayout || 0
      : pricing?.performanceCreatorPayout || 0,
    eliteCreatorPayout: briefIn?.creativeOnly
      ? pricing?.creativeOnlyEliteCreatorPayout || 0
      : pricing?.performanceEliteCreatorPayout || 0,
    creatorApprovalPricing: briefIn?.creativeOnly
      ? pricing?.creativeOnlyCreatorApprovalPricing || 0
      : pricing?.performanceCreatorApprovalPricing || 0,
    eliteCreatorApprovalPricing: briefIn?.creativeOnly
      ? pricing?.creativeOnlyEliteCreatorApprovalPricing || 0
      : pricing?.performanceEliteCreatorApprovalPricing || 0,
  };

  if (briefIn?.template) {
    return {
      ...basicFields,
      ...pricingFields,
    };
  }

  return {
    ...basicFields,
    creatorPayout: briefIn?.creatorPayout ?? pricingFields.creatorPayout,
    eliteCreatorPayout: briefIn?.eliteCreatorPayout ?? pricingFields.eliteCreatorPayout,
    creatorApprovalPricing: briefIn?.creatorApprovalPricing ?? pricingFields.creatorApprovalPricing,
    eliteCreatorApprovalPricing: briefIn?.eliteCreatorApprovalPricing ?? pricingFields.eliteCreatorApprovalPricing,
  };
}

export function defaultBriefTemplate(pricing?: AccountPricingConfig): FormBrief {
  // We assume that the brief is not creative only at the start since it is unchecked by default, leaving this here for now since we may update the flow in the future to pick if it is creative only or not before starting the brief.
  const creativeOnly = false;
  return {
    modules: [moduleDefaults],
    description: [
      {
        type: RichTextFormats.PARAGRAPH,
        children: [EMPTY_TEXT],
      },
    ],
    name: '',
    freeProduct: '',
    autoResponse: '',
    promotionalCode: '',
    brandAssets: { assets: [], newFiles: [] },
    inviteOnly: false,
    shippingRequired: false,
    creativeOnly: false,
    creatorPayout: creativeOnly ? pricing?.creativeOnlyCreatorPayout || 0 : pricing?.performanceCreatorPayout || 0,
    eliteCreatorPayout: creativeOnly
      ? pricing?.creativeOnlyEliteCreatorPayout || 0
      : pricing?.performanceEliteCreatorPayout || 0,
    creatorApprovalPricing: creativeOnly
      ? pricing?.creativeOnlyCreatorApprovalPricing || 0
      : pricing?.performanceCreatorApprovalPricing || 0,
    eliteCreatorApprovalPricing: creativeOnly
      ? pricing?.creativeOnlyEliteCreatorApprovalPricing || 0
      : pricing?.performanceEliteCreatorApprovalPricing || 0,
  };
}

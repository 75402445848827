import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.tags-input': {},
  '.tags-input': {
    '&__category-group': {
      marginBottom: theme.space[3],
    },
    '&__tag-input.tagify-input ': {
      border: 'solid 2px transparent',
      margin: `${theme.space[2]} 0`,
      borderRadius: '5px',
    },
    '&__tag-input--plain.tagify-input': {
      margin: 0,
    },
    '&__tag-input:has(.tagify--focus)': {
      border: `${COLORS.PRIMARY400} 2px solid`,
      backgroundColor: COLORS.NEUTRAL0,
      borderRadius: '0px',
    },
    '&__add-category-btn': {
      color: COLORS.PRIMARY500,
      textAlign: 'left',
      paddingLeft: 0,
    },
    '&__add-category-btn svg': {
      marginRight: theme.space[2],
    },
  },
});

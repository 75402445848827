import React from 'react';
import cs from 'classnames';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBadgeCheck } from '@fortawesome/pro-solid-svg-icons';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import COLORS from 'shared/styles/colors';
import { Flex } from 'shared/components/display';
import styles from './styles';

interface Props {
  className?: string;
  size?: SizeProp;
  showTooltip?: boolean;
  showHoverBox?: boolean;
}
const BrandApprovedBadge: React.FC<Props> = ({ className, size = 'lg', showTooltip = false, showHoverBox = false }) => {
  return (
    <Tooltip content="Brand Approved" disabled={!showTooltip}>
      <Flex
        css={styles}
        className={cs('brand-approved-badge', {
          'brand-approved-badge--hover': showHoverBox,
        })}
      >
        <FontAwesomeIcon icon={faBadgeCheck} size={size} className={className} color={COLORS.PRIMARY500} />
      </Flex>
    </Tooltip>
  );
};

export default BrandApprovedBadge;

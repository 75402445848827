import { Box, Text } from 'shared/components/display';
import { tabStyles } from 'shared/components/molecules/Tabs/tabsStyles';
import { useQuery } from 'shared/hooks/useQuery';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import cs from 'classnames';
import React, { ReactNode, useEffect } from 'react';
import { Button } from 'rebass/styled-components';

export interface TabProps {
  title: string;
  children: ReactNode;
  activeTab?: number;
  index?: number;
  hidden?: boolean;
  dataCy?: string;
}
const Tab: React.FC<TabProps> = ({ title, activeTab, children, index, dataCy, hidden = false }) => {
  const active = activeTab === index;

  const { setQuery } = useQuery();

  const onTabClick = (): void => {
    setQuery({
      activeTab: index,
    });
  };

  useEffect(() => {
    if (active && hidden) {
      setQuery({
        activeTab: 0,
      });
    }
  }, [active, hidden]);

  return (
    <>
      <Box css={tabStyles}>
        <Button
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          className={cs({
            'tab-bar__tab': true,
            'tab-bar__tab--active': active,
            'tab-bar__tab--hidden': hidden,
          })}
          onClick={onTabClick}
          role="tab"
          id={title}
          aria-selected={active}
          aria-controls={`${title}-content`}
          data-cy={dataCy}
        >
          <Text variant={TEXT_VARIANTS.CATEGORY} className="tab-bar__title">
            {title}
          </Text>
        </Button>
      </Box>
      {active && (
        <Box
          className={cs({
            'tab-bar__content': true,
            'tab-bar__content--hidden': hidden,
          })}
          css={tabStyles}
          aria-labelledby={title}
          id={`${title}-content`}
        >
          {children}
        </Box>
      )}
    </>
  );
};

export default Tab;

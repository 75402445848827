import * as yup from 'yup';

import { BRKFST_SUPPORTED_IMAGE_FORMATS } from 'shared/config/fileFormats';
import { MEDIA } from 'shared/config/media';
import { IMAGE_SIZE_LIMIT, MESSAGES } from 'shared/config/validations';
import { intl } from 'shared/lib/intl';

export const marketerProfilePictureSchema = yup.object({
  profilePic: yup
    .object({
      file: yup.mixed(),
    })
    .nullable()
    .supportedFile({
      message: intl.formatMessage(
        {
          id: 'WARNING_SUPPORTED_MEDIA',
        },
        { supportedMedia: MEDIA.TYPES.IMAGE },
      ),
      supportedFormats: BRKFST_SUPPORTED_IMAGE_FORMATS,
    })
    .fileSize({
      fileSizeMax: IMAGE_SIZE_LIMIT,
      message: MESSAGES.IMAGE_SIZE_LIMIT,
    })
    .fileNameLength({ message: MESSAGES.FILE_NAME_LENGTH })
    .fileNameFormat()
    // @ts-ignore
    .test('required', MESSAGES.REQUIRED_FIELD, (value) => {
      if (!value) return false;
      const values = Object.values(value);
      const empty = values.every((val) => !val || !val.name);
      return !empty;
    }),
});

import React, { useMemo, useState } from 'react';
import { Button } from 'rebass';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { isEqual, omit, pick } from 'lodash';

import { Box, Flex } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import { AddressValues } from 'shared/components/organisms/AddressFormFields/types';

import { GENERIC_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { Address } from 'shared/typings/address';
import { Phone } from 'shared/typings/user';

import {
  AddressFormFields,
  addressSchemaNoPhone,
  addressSchemaWithPhone,
  getInitialValues,
} from '../AddressFormFields';

import styles from './addressFormStyles';

interface Props {
  address?: Address;
  phone?: Phone;
  onSubmit: (
    values: any,
    setAddressError,
    phoneChanged: boolean,
    addressChanged: boolean,
    setSubmitting?: (isSubmitting: boolean) => void,
  ) => void;
  onCancel?: () => void;
  hidePhoneField?: boolean;
  confirmButtonText?: string;
  focusAddress?: boolean;
  buttonPosition?: 'left' | 'right';
  allowReset?: boolean;
  disableUntilTouched?: boolean;
}

const AddressForm: React.FC<Props> = ({
  address,
  onSubmit,
  onCancel,
  phone,
  hidePhoneField = false,
  confirmButtonText,
  focusAddress = false,
  buttonPosition = 'left',
  allowReset = false,
  disableUntilTouched = false,
}) => {
  const initialValues: AddressValues = useMemo(() => {
    return getInitialValues(address, hidePhoneField ? undefined : phone);
  }, [address, phone, hidePhoneField]);

  const [addressError, setAddressError] = useState<string[]>([]);

  const onResetHandler = (setFieldValues) => {
    setFieldValues(initialValues);
  };

  return (
    <Formik<AddressValues>
      validationSchema={hidePhoneField ? addressSchemaNoPhone : addressSchemaWithPhone}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        const phoneChanged = !isEqual(
          pick(initialValues, ['phoneNumber', 'countryCode']),
          pick(values, ['phoneNumber', 'countryCode']),
        );
        const addressChanged = !isEqual(
          omit(initialValues, ['phoneNumber', 'countryCode']),
          omit(values, ['phoneNumber', 'countryCode']),
        );
        onSubmit(values, setAddressError, phoneChanged, addressChanged, setSubmitting);
      }}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <Box css={styles} className="address-form">
            <AddressFormFields
              error={addressError}
              formikProps={formikProps}
              hidePhoneField={hidePhoneField}
              focusAddress={focusAddress}
            />
            <Flex
              className={classNames('address-form__btn-wrapper', {
                'address-form__btn-wrapper--right': buttonPosition === 'right',
              })}
            >
              {onCancel && (
                <Button
                  className="address-form__cancel-btn"
                  type="button"
                  onClick={onCancel}
                  variant={BUTTON_VARIANTS.OPTION}
                >
                  {GENERIC_COPY.BUTTON_CANCEL}
                </Button>
              )}
              {allowReset && (
                <Button
                  className="address-form__cancel-btn"
                  type="button"
                  onClick={() => onResetHandler(formikProps.setValues)}
                  variant={BUTTON_VARIANTS.OPTION}
                >
                  {GENERIC_COPY.BUTTON_CANCEL}
                </Button>
              )}
              <FormButton
                dataCy="address-form__submit"
                disabled={formikProps.isSubmitting && addressError.length === 0}
                disableUntilTouched={disableUntilTouched}
              >
                {confirmButtonText ?? GENERIC_COPY.BUTTON_SUBMIT}
              </FormButton>
            </Flex>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddressForm;

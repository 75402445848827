import cs from 'classnames';
import React from 'react';
import BriefFields from 'features/briefs/components/BriefFields/BriefFields';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import MessageButton from 'shared/components/atoms/MessageButton';
import { Box } from 'shared/components/display';
import { Brief } from 'shared/typings/briefs';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import BannerLogo from 'features/accounts/components/molecules/BannerLogo/BannerLogo';

import styles from './styles';

interface Props {
  currentBrief: Brief;
  children?: React.ReactNode;
  showMessageButton?: boolean;
  loading?: boolean;
  className?: string;
}

const CreatorBriefView: React.FC<Props> = ({
  currentBrief,
  children = null,
  showMessageButton = true,
  loading = false,
  className = '',
}) => {
  // If the creator has been approved to work on the brief then they can submit a response and should see the message button
  const approvedStatuses = [UserBriefStatus.ACTIVE, UserBriefStatus.SUBMITTED, UserBriefStatus.DRAFTED];
  const canSubmitResponse = currentBrief && approvedStatuses.includes(currentBrief?.briefCreatorStatus);

  return (
    <Box css={styles} className={cs('creator-brief-view', className)}>
      {loading ? (
        <CenteredSpinner />
      ) : (
        <Box className="creator-brief-view__content">
          <Box className="creator-brief-view__banner-wrapper">
            <BannerLogo loading={false} account={currentBrief.account} />
          </Box>
          <BriefFields brief={currentBrief} uppercase />
          {canSubmitResponse && showMessageButton && <MessageButton account={currentBrief.account} />}
          {children}
        </Box>
      )}
    </Box>
  );
};

export default CreatorBriefView;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.collection-tiles--stacked': {
    margin: theme.space[4],
    overflow: 'visible',
  },
  '.collection-tiles': {
    '&__link': {
      textDecoration: 'none',
      width: 'min-content',
    },
    '&__no-collections': {
      textAlign: 'center',
    },
    '&__label': {
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
    },
    '&__label-text': {
      marginBottom: 0,
      // make sure there's enough space left for the number of digits in
      // file num display
      maxWidth: 'calc(100% - 40px)',
    },
    '&__label__num': {
      color: COLORS.PRIMARY500,
      marginLeft: theme.space[1],
    },
  },
});

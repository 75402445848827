import { css } from '@emotion/react';

import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.nav-logo--center': {
    position: 'absolute',
    left: 'calc(50% - 12.5px)',
    top: 'calc(50% - 12.5px)',
    height: '25px',
    width: '25px',
    [mediaquery.md]: {
      left: 'calc(50% - 43.055px)',
      width: '86.11px',
    },
  },
  '.nav-logo': {
    '&__logo': {
      height: '25px',
      display: 'none',
      maxWidth: '100px',
      [mediaquery.md]: {
        display: 'block',
      },
    },
    '&__logo-icon': {
      height: '25px',
      display: 'block',
      maxWidth: '30px',
      [mediaquery.md]: {
        display: 'none',
      },
    },
    '&__logo--clickable, &__logo-icon--clickable': {
      cursor: 'pointer',
    },
  },
});

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.icon-with-notification': {
    position: 'relative',
    cursor: 'pointer',
    width: 'fit-content',
  },
  '.icon-with-notification': {
    '&__count': {
      zIndex: zIndex.zButtonOverlay,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: COLORS.BLACK,
      fontSize: theme.fontSizes[0],
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
});

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.profile-panel': {
    backgroundColor: COLORS.NEUTRAL100,
    border: `1px solid ${COLORS.NEUTRAL100}`,
    borderRadius: '10px',
    height: 'min-content',
    margin: '1rem',
    '&--white': {
      backgroundColor: COLORS.WHITE,
    },
  },
  '.profile-panel': {
    '&__header': {
      backgroundColor: COLORS.WHITE,
      borderRadius: '10px 10px 0 0',
      margin: 0,
      overflowWrap: 'break-word',
      padding: `${theme.space[3]} ${theme.space[4]}`,
      textTransform: 'uppercase',
      display: 'flex',
      flexDirection: 'column',
      [mediaquery.md]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    '&__header--centered': {
      justifyContent: 'center',
      textAlign: 'center',
    },
    '&__gender-age': {
      textAlign: 'center',
      marginBottom: theme.space[2],
    },
    '&__header-item': {
      width: '100%',
    },
    '&__item': {
      padding: '0 1rem',
      whiteSpace: 'pre-line',
    },
    '&__modal-form-btn': {
      marginTop: theme.space[5],
    },
    '&__marketer-btn-wrapper': {
      justifyContent: 'start',
      gap: theme.space[4],
      marginBottom: theme.space[4],
    },
    '&__admin-btn-group': {
      borderRadius: '3px',
      border: `1px solid ${COLORS.NEUTRAL300}`,
      width: '30px',
      height: '30px',
      alignContent: 'center',
      cursor: 'pointer',
    },
    '&__tags': {
      padding: theme.space[4],
    },
    '&__elite, &__spotlight': {
      margin: 'auto',
    },
    '&__profile-pic-wrapper': {
      paddingTop: '1rem',
    },
    '&__badge-wrapper': {
      justifyContent: 'center',
      marginBottom: theme.space[4],
    },
  },
});

import React from 'react';
import cs from 'classnames';
import { Flex, Text } from 'shared/components/display';
import styles from './styles';
import { TEXT_VARIANTS } from 'shared/styles/text';

interface Props {
  children: string;
  heading?: string;
}
const Toast: React.FC<Props> = ({ children, heading }) => {
  return (
    <Flex css={styles} className={cs('brkfst-toast')}>
      <Text variant={TEXT_VARIANTS.SUBHEADING_SM} className="brkfst-toast__heading">
        {heading}
      </Text>
      <Text data-cy={`brkfst-toast__body--${children}`} variant={TEXT_VARIANTS.BODY_SM} className="brkfst-toast__body">
        {children}
      </Text>
    </Flex>
  );
};

export default Toast;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';

import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import DataStudioReport from 'features/accounts/components/atoms/DataStudioReport';
import { TopCreativeTable } from 'features/accounts/components/molecules/TopCreativeTable/TopCreativeTable';
import {
  ACCOUNT_LAYOUTS,
  ACCOUNT_LAYOUTS_REPORT,
  ACCOUNT_WIDGET_KEYS,
} from 'features/accounts/components/organisms/AccountWidgets/config';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import { getRequestQuery } from 'features/briefs/searchUtility';
import WidgetWrapper from 'shared/components/atoms/WidgetWrapper';
import { EmptySelectOption, Select } from 'shared/components/molecules/Select';
import { emptySelectOption } from 'shared/components/molecules/Select/SelectInput';
import WidgetPanel from 'shared/components/molecules/WidgetPanel';
import BrkfstTable from 'shared/components/organisms/BrkfstTable';

import { apiAction } from 'shared/actions/api';
import { ACCOUNT_COPY, BRIEF_COPY } from 'shared/config/copy';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_PERFORMANCE_ROUTES } from 'shared/config/routes/api/apiPerformanceRoutes';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import { breakpoints } from 'shared/styles/styleFunctions';
import { PlatformShortNames } from 'shared/typings/accountPlatform/enums';
import { BriefStatus, briefStatusDisplay } from 'shared/typings/briefs/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
interface Props {
  dataStudioReportId?: string;
  accountId: number;
  organizationId: number;
}

interface AccountPlatformValue {
  label: string;
  value: {
    id: number;
    platform: string;
  };
}

const columnHelper = createColumnHelper<any>();

const briefColumns = [
  columnHelper.accessor('name', {
    header: BRIEF_COPY.COLUMN_NAME_BRIEF,
    cell: (info) => info.getValue() || BRIEF_COPY.DEFAULT_BRIEF_NAME,
    meta: {
      addTooltip: true,
      width: '140px',
    },
  }),
  columnHelper.accessor('numSubmissions', {
    header: BRIEF_COPY.COLUMN_NUM_SUBMISSIONS,
    cell: (info) => `${info.getValue() || 0}`,
    meta: {
      width: '140px',
    },
  }),
  columnHelper.accessor((row) => ({ full: row.full, status: row.status }), {
    header: BRIEF_COPY.COLUMN_STATUS_BRIEF,
    cell: (info) => {
      const brief = info.getValue();
      return brief.full ? briefStatusDisplay[BriefStatus.FULL] : briefStatusDisplay[brief.status];
    },
    meta: {
      width: '100px',
    },
  }),
];

const PANEL_Z_INDEX = {
  TOP_CREATIVES: 1,
  BRIEFS: 0,
  REPORT: 0,
};
const AccountWidgets: React.FC<Props> = ({ accountId, organizationId, dataStudioReportId }) => {
  const [loading, setLoading] = useState(true);
  const { briefs, fetchBriefs, loading: briefsLoading } = useBriefs();
  const dispatch = useDispatch();
  const [topCreatives, setTopCreatives] = useState<any[]>([]);
  const { accountPlatforms, platformsLoading } = useAccountPlatforms({
    accountId,
  });

  const accountPlatformOptions = useMemo(() => {
    return [
      emptySelectOption,
      ...accountPlatforms.map(({ id, name, platform }) => ({
        label: name,
        value: {
          id,
          platform: platform.shortenedName,
        },
      })),
    ] as (EmptySelectOption | AccountPlatformValue)[];
  }, [accountPlatforms]);

  const [platformChosen, setPlatformChosen] = useState<AccountPlatformValue | EmptySelectOption>(emptySelectOption);

  const fetchTopCreatives = (id: number) => {
    setLoading(true);
    dispatch(
      apiAction({
        path: {
          route: `${API_PERFORMANCE_ROUTES.TOP_CREATIVE}/${id}`,
        },
        onSuccess: ({ data }) => {
          setTopCreatives(data);
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        },
      }),
    );
  };

  useEffect(() => {
    if (accountPlatformOptions.length > 1) {
      const lastPlatform = accountPlatformOptions[accountPlatformOptions.length - 1];
      if (lastPlatform.value?.id) fetchTopCreatives(lastPlatform.value?.id);
      setPlatformChosen(lastPlatform);
    } else {
      setLoading(false);
      setPlatformChosen(emptySelectOption);
    }
  }, [accountPlatforms]);

  const searchParams = useSearchParams(
    {},
    {
      page: 1,
      size: 3,
      orderBy: 'dateCreated',
      orderAsc: false,
      name: '',
      status: -1,
    },
  );
  const navigate = useNavigate();
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  /**
   * TEMPORARY PLATFORM ID.
   * Briefs are only applicable for Facebook right now.
   */

  const getBriefLink = (briefId?: string) => {
    return LinkCreator.createLink({
      routeKey: isMobile && briefId ? 'VIEW_INDIVIDUAL_BRIEF' : 'BRIEF_INDEX',
      variables: {
        organizationId,
        accountId,
        ...(isMobile && briefId ? { briefId } : {}),
      },
      ...(isMobile && !briefId
        ? {}
        : {
            query: {
              item: briefId,
            },
          }),
    });
  };

  const briefsHomeLink = useMemo(() => {
    if (!platformsLoading) {
      return getBriefLink();
    }
    return '';
  }, [platformsLoading, accountPlatforms]);

  const onBriefClick = (brief) => {
    const { id } = brief;
    const link = getBriefLink(id);
    if (link?.length > 0) {
      navigate(link);
    }
  };

  const displayNoDataText = useMemo(() => {
    switch (platformChosen?.value?.platform) {
      case PlatformShortNames.TIKTOK:
        return ACCOUNT_COPY.WIDGET_TIKTOK_NO_DATA;
      case PlatformShortNames.FACEBOOK:
        return ACCOUNT_COPY.WIDGET_FACEBOOK_NO_DATA;
      default:
        return ACCOUNT_COPY.WIDGET_NO_DATA;
    }
  }, [platformChosen]);

  useEffect(() => {
    const formattedQuery = getRequestQuery(searchParams, accountId, organizationId);

    fetchBriefs(formattedQuery);
  }, [accountId]);

  return (
    <WidgetWrapper
      initialLayouts={dataStudioReportId ? ACCOUNT_LAYOUTS_REPORT : ACCOUNT_LAYOUTS}
      entity="ACCOUNTS"
      entityId={accountId}
    >
      {dataStudioReportId ? (
        <WidgetPanel key={ACCOUNT_WIDGET_KEYS.FIRST} isHidden={!dataStudioReportId} zIndex={PANEL_Z_INDEX.REPORT}>
          <DataStudioReport dataStudioReportId={dataStudioReportId || ''} />
        </WidgetPanel>
      ) : (
        <></>
      )}
      <WidgetPanel
        HeaderButton={
          <Select
            width="200px"
            options={accountPlatformOptions}
            // @ts-ignore
            onChange={(val: AccountPlatformValue) => {
              setPlatformChosen(val);
              fetchTopCreatives(val.value.id);
            }}
            placeholder={RESOURCES.ACCOUNT_PLATFORM}
            name="selectAccountPlatform"
            value={platformChosen}
            menuPortalTarget="body"
            showOptionTooltip
          />
        }
        title={ACCOUNT_COPY.WIDGET_CREATIVES}
        key={ACCOUNT_WIDGET_KEYS.SECOND}
        zIndex={PANEL_Z_INDEX.TOP_CREATIVES}
      >
        <TopCreativeTable loading={loading} noDataText={displayNoDataText} topCreatives={topCreatives} />
      </WidgetPanel>
      <WidgetPanel
        link={briefsHomeLink}
        key={ACCOUNT_WIDGET_KEYS.THIRD}
        title={ACCOUNT_COPY.WIDGET_BRIEFS}
        loading={briefsLoading || platformsLoading} // platformsLoading it used because facebook platform id is needed for briefLink
        zIndex={PANEL_Z_INDEX.BRIEFS}
      >
        <BrkfstTable columns={briefColumns} onRowClick={onBriefClick} data={briefs} />
      </WidgetPanel>
    </WidgetWrapper>
  );
};

export default AccountWidgets;

import React, { useCallback, useMemo } from 'react';
import { Button } from 'rebass/styled-components';

import { BriefPermissions } from 'features/briefs/BriefHeader/HeaderBrandActions/types';
import CloseBriefButton from 'features/briefs/components/atoms/CloseBriefButton';
import DuplicateBriefButton from 'features/briefs/components/atoms/DuplicateBriefButton';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import SplitButton from 'shared/components/molecules/SplitButton';

import { BRIEF_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { Brief } from 'shared/typings/briefs';

interface Props {
  currentBrief: Brief;
  openBriefPermissions: Pick<BriefPermissions, 'createEditDraft' | 'closeReopenBrief' | 'publishEditBrief'>;
}

const OpenBriefActionButtons: React.FC<Props> = ({ currentBrief, openBriefPermissions }) => {
  const { editBrief, pauseBrief } = useBriefs();
  const { createEditDraft, closeReopenBrief, publishEditBrief } = openBriefPermissions;
  const hasSplitButton = createEditDraft || closeReopenBrief || publishEditBrief;
  const onEdit = useCallback(() => {
    editBrief(currentBrief);
  }, [currentBrief]);
  const onPause = useCallback(() => {
    pauseBrief(currentBrief);
  }, [currentBrief]);

  const splitButtonProps = useMemo(
    () => ({
      primaryText: closeReopenBrief ? BRIEF_COPY.BUTTON_PAUSE : BRIEF_COPY.BUTTON_EDIT_BRIEF,
      primaryOnClick: closeReopenBrief ? onPause : onEdit,
    }),
    [closeReopenBrief, onPause, onEdit],
  );

  //Standalone Edit Button
  return hasSplitButton ? (
    <SplitButton
      confirmButtonText="Yes"
      promptTitle={BRIEF_COPY.BUTTON_MARK_FULL_PROMPT_TITLE}
      promptMessage={BRIEF_COPY.BUTTON_MARK_FULL_PROMPT_MESSAGE}
      primaryText={splitButtonProps.primaryText}
      primaryOnClick={splitButtonProps.primaryOnClick}
      width="190px"
    >
      {publishEditBrief && closeReopenBrief && (
        <Button key="Edit Brief" variant={BUTTON_VARIANTS.FLAT_OPTION} onClick={onEdit}>
          {BRIEF_COPY.BUTTON_EDIT_BRIEF}
        </Button>
      )}
      {createEditDraft && <DuplicateBriefButton brief={currentBrief} variant={BUTTON_VARIANTS.FLAT_OPTION} />}
      {closeReopenBrief && <CloseBriefButton brief={currentBrief} variant={BUTTON_VARIANTS.FLAT_OPTION} />}
    </SplitButton>
  ) : (
    <Button key="Edit Brief" variant={BUTTON_VARIANTS.PRIMARY} onClick={onEdit} width="190px">
      {BRIEF_COPY.BUTTON_EDIT_BRIEF}
    </Button>
  );
};

export default OpenBriefActionButtons;

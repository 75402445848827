import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export const MAIN_CLASS = 'checkout-page-template';
export const CLASSNAMES = {
  errorBox: `${MAIN_CLASS}__error-box`,
  content: `${MAIN_CLASS}__content`,
  contentProcessing: `${MAIN_CLASS}__content--processing`,
};

export default css({
  [`&.${MAIN_CLASS}`]: {
    margin: `${theme.space[3]}`,
    maxWidth: '87%',
  },
  [`.${MAIN_CLASS}`]: {
    '&__error-box': {
      border: `1px solid ${COLORS.RED700}`,
      borderRadius: '4px',
      backgroundColor: COLORS.RED50,
      color: COLORS.RED700,
    },
    '&__content': {
      position: 'relative',
      flex: '1 1 0%',
      [mediaquery.md]: {
        marginRight: '1.5rem',
        maxWidth: '80%',
      },
    },
    '&__content--processing': {
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: COLORS.WHITE,
        width: '100%',
        height: '100%',
        opacity: '0.7',
      },
    },
  },
});

export const TRENDS_LIBRARY_COPY = {
  BUTTON_DELETE_TREND: 'Delete Trend',
  BUTTON_CREATE_TREND: 'Create Trend',
  BUTTON_EDIT_TREND: 'Update',
  BUTTON_LAUNCH_BRIEF: 'Launch Brief',

  TOOLTIP_LAUNCH_BUTTON: 'Please pin at least two trends to launch a brief.',
  TOOLTIP_INFO_ICON:
    'Click to launch a brief based on your pinned trends! Your Account Manager will share the drafted brief within 1 business day for your review and approval before you commit.',

  HEADER_LAUNCH_BRIEF: 'Launch a Brief',

  DESCRIPTION_LAUNCH_BRIEF: `<strong>Click confirm to launch a brief based on your pinned trends!</strong>\n\nYour Account Manager will share the drafted brief within 1 business day for your review and approval. If you change your mind, don’t worry—you can always opt out.`,

  TREND_LIBRARY_HEADER: 'Discover What’s <pink>Trending</pink>',
  TREND_FORM_SUB_HEADER: 'Name your trend and upload a reference.',
  TREND_FORM_MODAL_HEADER: '{isNew, select, true {New} other {Edit}} Trend',
  TREND_FORM_MODAL_BUTTON_TEXT: '{isNew, select, true {New} other {Edit}} Trend',

  TOAST_CREATE_TREND_SUCCESS: 'Trend created successfully',
  TOAST_UPDATE_TREND_SUCCESS: 'Trend updated successfully',
  TOAST_DELETE_TREND_SUCCESS: 'Trend deleted successfully',
  TOAST_PIN_TREND_MAX: 'You cannot pin more than {max} trends',
  TOAST_BRIEF_LAUNCH_SUCCESS: 'Brief successfully launched',

  PROMPT_DELETE_TREND: 'Are you sure you want to delete this trend?',

  VALIDATION_TREND_DURATION: 'Above max duration of {max}',
  VALIDATION_TREND_FILE_REQUIRED: 'Please upload a valid file',

  COPY_TEXT: 'Copy Link',
  COPIED_TEXT: 'Copied!',
};

import React, { Key, useEffect, useState } from 'react';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset as offsetFunc,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cs from 'classnames';

import { Box } from 'shared/components/display';

import { BUTTON_VARIANTS } from 'shared/styles/button';

import dropdownStyles from './dropdownStyles';
import { DropdownChildren, DropdownOffset, DropdownOptionProps } from './types';

interface DropdownProps {
  className?: string;
  children: DropdownChildren;
  optionVariant?: string;
  searchable?: boolean;
  placement?: Placement;
  ClickComponent: React.ReactElement;
  offset?: DropdownOffset;
  onClickOutside?: () => void;
  width?: string | number;
  Footer?: React.ReactElement;
  onOpen?: (open: boolean) => void;
  disabled?: boolean;
  allRounded?: boolean;
  setFinalPlacement?: (placement: Placement) => void;
  disablePortal?: boolean;
  portalTarget?: string;
  dataCy?: string;
  debug?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  className,
  children,
  optionVariant = BUTTON_VARIANTS.OPTION,
  searchable = false,
  placement = 'bottom-end',
  ClickComponent,
  offset,
  onClickOutside,
  width = '200px',
  Footer,
  onOpen,
  disabled,
  allRounded,
  setFinalPlacement,
  disablePortal = false,
  portalTarget = 'body',
  dataCy,
  debug,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
    onOpen && onOpen(open);
  };

  const {
    refs,
    floatingStyles,
    context,
    placement: finalPlacement,
  } = useFloating({
    open: isOpen,
    onOpenChange,
    middleware: [
      offsetFunc(offset),
      flip({
        padding: 5,
      }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
    placement,
  });

  const click = useClick(context, { enabled: !disabled });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'menu' });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const onSearchChange = ({ target: { value: text } }) => {
    setSearch(text);
  };

  const compareToSearch = (text: Key | null): boolean => {
    return typeof text === 'string' ? text.toLowerCase().includes(search.toLowerCase()) : false;
  };

  const hideDropdown = () => {
    onOpenChange(false);
  };

  useEffect(() => {
    if (finalPlacement && setFinalPlacement) setFinalPlacement(finalPlacement);
  }, [finalPlacement, setFinalPlacement]);

  /**
   * According to docs https://floating-ui.com/docs/floatingportal
   * The portal component should be conditionally rendered based on the isOpen or mounted state (as seen above), rather than always rendered. This allows React Suspense to work as expected.
   * However, we are using css to hide and show portal because unmounting the portal causes any modals used within the dropdown (as is the case with PromptButtons) to not function because they have been unmounted.
   * Therefore, offering the option to have Dropdown without a portal for where it is not necessary and having issues with Suspense.
   */
  const TopWrapper = disablePortal ? Box : FloatingPortal;

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="dropdown"
    >
      {React.cloneElement(
        ClickComponent,
        getReferenceProps({
          ref: refs.setReference,
          ...ClickComponent.props,
          'data-state': isOpen ? 'open' : 'closed',
          className: ClickComponent.props?.className,
          'data-cy': dataCy,
        }),
      )}

      <TopWrapper id={portalTarget}>
        <Box
          css={dropdownStyles(width)}
          ref={refs.setFloating}
          className={cs(className, 'dropdown__content-wrapper', `dropdown__content-wrapper--${finalPlacement}`, {
            'dropdown__content-wrapper--all-rounded': allRounded,
            'dropdown__content-wrapper--open': isOpen || debug,
          })}
          style={floatingStyles}
          {...getFloatingProps()}
        >
          {searchable && (
            <Box className="dropdown__search-box">
              <input
                type="text"
                className="dropdown__search-bar"
                placeholder="Search"
                onChange={onSearchChange}
                value={search}
              />
            </Box>
          )}
          {React.Children.map(children, (Child: React.ReactElement<DropdownOptionProps>) => {
            if (!Child) return null;
            const extraProps = {
              className: cs('dropdown__option', 'endWithEllipsis', Child.props.className),
              type: 'button',
              variant: Child.props.variant || optionVariant,
              onClick: (e) => {
                e?.persist();
                if (Child.props.onClick) Child.props.onClick(e);
                if (onClickOutside) onClickOutside();
                hideDropdown();
              },
            };
            if (searchable && Child.key) {
              const displayChild = compareToSearch(Child.key);
              return displayChild ? React.cloneElement(Child, extraProps) : <></>;
            }
            return React.cloneElement(Child, extraProps);
          })}
          {Footer && (
            <Box className="dropdown__footer">
              {React.cloneElement(Footer, {
                onClick: (e) => {
                  e?.persist();
                  if (Footer.props.onClick) Footer.props.onClick(e);
                  if (onClickOutside) onClickOutside();
                  hideDropdown();
                },
              })}
            </Box>
          )}
        </Box>
      </TopWrapper>
    </Box>
  );
};

export default Dropdown;

import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';

export const MAIN_CLASS = 'earning-details';
export const CLASSNAMES = {
  fileThumbnail: `${MAIN_CLASS}__file-thumbnail`,
  viewSubmissionBtn: `${MAIN_CLASS}__view-submission-btn`,
  amountHeader: `${MAIN_CLASS}__amount-header`,
  amountBody: `${MAIN_CLASS}__amount-body`,
  brandBriefCarousel: `${MAIN_CLASS}__brand-brief-carousel`,
  brandBriefBody: `${MAIN_CLASS}__brand-brief-body`,
};
// @ts-ignore
export default css({
  height: '100%',
  [`.${MAIN_CLASS}`]: {
    '&__file-thumbnail': {
      maxHeight: '60px',
      maxWidth: '80px',
    },
    '&__view-submission-btn': {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      left: 0,
      right: 0,
      [mediaquery.md]: {
        width: '20%',
        margin: 'auto',
      },
    },
    '&__amount-header': {
      textAlign: 'right',
    },
    '&__amount-body': {
      textAlign: 'right',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: '100%',
    },
    '&__brand-brief-carousel': {
      padding: '0 4px',
    },
    '&__brand-brief-body': {
      height: 'max-content',
    },
  },
});

import React from 'react';
import { Placement } from '@floating-ui/react';
import cs from 'classnames';

import { Box } from 'shared/components/display';
import { DropdownChildren } from 'shared/components/molecules/Dropdown';
import Elliptical from 'shared/components/molecules/Elliptical';

import COLORS from 'shared/styles/colors';

import styles from './styles';

interface Props {
  className?: string;
  placement?: Placement;
  color?: string;
  children: DropdownChildren;
  width?: string | number;
  portalTarget?: string;
  dataCy?: string;
  id?: string;
  disablePortal?: boolean;
  debug?: boolean;
}

const EllipticalMenu: React.FC<Props> = ({
  placement = 'top-end',
  color = COLORS.NEUTRAL300,
  className,
  children,
  width,
  portalTarget,
  dataCy,
  id,
  disablePortal,
  debug,
}) => {
  return (
    <Box css={styles} id={id} className={cs('elliptical-menu', className)}>
      <Elliptical
        color={color}
        placement={placement}
        width={width}
        portalTarget={portalTarget}
        dataCy={dataCy}
        disablePortal={disablePortal}
        debug={debug}
        isVertical
      >
        {children}
      </Elliptical>
    </Box>
  );
};

export default EllipticalMenu;

import { css } from '@emotion/react';

export default css({
  '.download-ready': {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingTop: '100px',
    '&__logo-container': {
      maxHeight: '200px',
      flexDirection: 'column',
      alignItems: 'center',
      '&__logo': {
        maxHeight: '200px',
        width: '100%',
        margin: '20px auto',
      },
      '&__btn': {
        marginTop: '10px',
      },
    },
    '&__text': {
      textAlign: 'center',
    },
    '&__hidden-download': {
      display: 'none',
    },
  },
});

import React from 'react';
import { Box, Flex } from 'shared/components/display';
import softCurtainStyles from 'shared/components/atoms/SoftCurtain/softCurtainStyles';

interface Props {
  Content: React.ReactElement;
  RightContent?: React.ReactElement;
}

/**
 * Used for containers that SHOULD NOT wrap if content
 * is too long. A transparent-to-white gradient (soft curtain)
 * will hide the right end of the container. The content should
 * be scrollable or cut off with an ellipsis for text.
 */
const SoftCurtain: React.FC<Props> = ({ Content, RightContent }) => {
  return (
    <Flex className="soft-curtain" css={softCurtainStyles}>
      {Content}
      <Box className="soft-curtain__right-content">{RightContent}</Box>
    </Flex>
  );
};

export default SoftCurtain;

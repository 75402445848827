import React from 'react';
import { Box } from 'shared/components/display';
import NavBar from 'shared/components/atoms/NavBar';
import NavBarLogo from 'shared/components/atoms/NavBarLogo';
import SignOutButton from 'shared/components/atoms/SignOutButton';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import styles from './styles';

const NewUserNavBar: React.FC = () => {
  return (
    <Box css={styles} className="new-user-nav-bar">
      <NavBar rightAlignItems>
        <NavBarLogo disableNavigate centerLogo />
        <SignOutButton className="new-user-nav-bar__sign-out-btn" variant={BUTTON_VARIANTS.TEXT_ONLY} />
      </NavBar>
    </Box>
  );
};

export default NewUserNavBar;

export const NOTIFICATION_BADGE_MAX_COUNT = 99;

export const NOTIFICATIONS = {
    UNREAD_MESSAGES: 'unreadMessages',
};

// refer to https://getstream.io/chat/docs/notification_events/?language=js
export const MESSAGE_TYPE = {
    NEW: 'message.new',
    NOTIFICATION_NEW: 'notification.message_new',
    READ: 'notification.mark_read',
};

import { useDispatch, useSelector } from 'react-redux';

import {
  configurationAdded,
  configurationsLoaded,
  defaultConfigurationSet,
  getConfigurations,
  getDefaultConfiguration,
} from 'features/accountPlatforms/facebook/configurations.slice';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_CONFIGURATION_ROUTES } from 'shared/config/routes/api/apiConfigurationRoutes';
import { CONFIGURATION_TOASTS } from 'shared/config/toasts/configurationToasts';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';

export const useConfigurations = () => {
  const dispatch = useDispatch();
  const configurations = useSelector(getConfigurations);
  const defaultConfiguration = useSelector(getDefaultConfiguration);
  const { loading } = useComponentLoading(RESOURCES.CONFIGURATION);

  const fetchConfigurations = ({ entityType, entityId }) => {
    dispatch(
      apiAction({
        path: {
          route: API_CONFIGURATION_ROUTES.CONFIGURATIONS,
        },
        params: {
          entityType,
          entityId,
        },
        successAction: configurationsLoaded,
        entity: RESOURCES.CONFIGURATION,
      }),
    );
  };

  const createConfiguration = (configObj) => {
    dispatch(
      apiAction({
        path: {
          route: API_CONFIGURATION_ROUTES.CONFIGURATIONS,
        },
        method: 'POST',
        data: configObj,
        successAction: configurationAdded,
        entity: RESOURCES.CONFIGURATION,
        successToast: {
          message: CONFIGURATION_TOASTS.SAVED_CONFIGURATION_TOASTS,
        },
      }),
    );
  };

  const setDefaultConfiguration = (config) => {
    dispatch(defaultConfigurationSet(config));
  };

  return {
    fetchConfigurations,
    createConfiguration,
    setDefaultConfiguration,
    configurations,
    defaultConfiguration,
    loading,
  };
};

import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export const styles = css({
  marginBottom: theme.space[5],
  '.file-upload': {
    marginTop: '1.2rem',
    fontSize: '0.9rem',
  },
});

export const footerStyles = css({
  justifyContent: 'flex-end',
  width: '100%',
  '.submission-asset-upload': {
    '&__cancel-btn': {
      marginLeft: 'auto',
      marginRight: theme.space[4],
    },
  },
});

import React from 'react';
import { useDispatch } from 'react-redux';

import { userChanged } from 'features/users/hooks/user.slice';
import { apiAction } from 'shared/actions/api';
import { CREATOR_COPY } from 'shared/config/copy';
import { BrkfstUser } from 'shared/typings/user';
import { Button } from 'shared/components/display';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';
import { VettingStatus } from 'shared/typings/user/enums';

interface Props {
  user: BrkfstUser;
  className?: string;
  onClick?: () => void;
}

const SpotlightCreatorButton: React.FC<Props> = ({ user, className, onClick }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    const { isSpotlighted } = user;
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.SPOTLIGHT_CREATOR,
          variables: { userId: user.id },
        },
        method: 'PATCH',
        data: {
          isSpotlighted: !isSpotlighted,
        },
        successToast: {
          message: CREATOR_COPY.SUCCESS_CREATOR_SPOTLIGHT,
        },
        successAction: userChanged,
      }),
    );
    onClick && onClick();
  };

  return (
    <Button
      variant={BUTTON_VARIANTS.OPTION}
      className={className}
      onClick={handleClick}
      disabled={user.vettingStatus === VettingStatus.REJECTED}
      data-cy="spotlight-creator-button"
    >
      {user.isSpotlighted ? CREATOR_COPY.BUTTON_DEACTIVATE_SPOTLIGHT : CREATOR_COPY.BUTTON_ACTIVATE_SPOTLIGHT}
    </Button>
  );
};

export default SpotlightCreatorButton;

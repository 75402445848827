import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import placeholder from 'shared/images/profile-picture-placeholder.png';
import COLORS from 'shared/styles/colors';
import SHADOWS from 'shared/styles/shadow';

export const CARD_WIDTH = '252px';
export const CARD_HEIGHT = '348px';

const PIC_FULL_HEIGHT = '252px';

const TAG_TRANSITION = 'max-height 0.4s';

export default (backgroundSrc: string = placeholder) =>
  css({
    '&.creator-info': {
      backgroundColor: COLORS.WHITE,
      cursor: 'pointer',
      width: CARD_WIDTH,
      height: CARD_HEIGHT,
      border: `1px solid ${COLORS.NEUTRAL200}`,
      borderRadius: '16px',
      overflow: 'hidden',
      position: 'relative',
      '&:hover': {
        boxShadow: SHADOWS.SHADOW04,
      },
      '&--has-tags:hover .creator-info__tags': {
        visibility: 'visible',
        height: 'fit-content',
        marginTop: '16px',
        maxHeight: '104px',
        transition: TAG_TRANSITION,
        opacity: 1,
        zIndex: zIndex.zBackdrop,
        position: 'relative', //so z-index works
      },
    },
    '.creator-info': {
      '&__pic': {
        backgroundImage: `url(${backgroundSrc})`,
        repeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: PIC_FULL_HEIGHT,
        boxShadow: 'inset 0 2px 8px rgba(0, 39, 119, .16)',
      },
      '&__content-wrapper': {
        backgroundColor: COLORS.WHITE,
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: 'fit-content',
      },
      '&__name-icons_wrapper': {
        height: '96px',
        padding: '16px',
        backgroundColor: COLORS.WHITE,
        zIndex: zIndex.zForeground,
        position: 'relative', //so z-index works
      },
      '&__icons_wrapper': {
        alignItems: 'center',
      },
      '&__name': {
        margin: 0,
        marginBottom: '4px',
        fontWeight: 600,
        fontSize: '24px',
        height: '30px',
        width: '100%',
        color: COLORS.NEUTRAL800,
      },

      '&__tags': {
        visibility: 'hidden',
        opacity: 0,
        maxHeight: 0,
        gap: '4px',
        padding: '0 16px',
        transition: TAG_TRANSITION,
      },
    },
  });

import React, { useEffect, useState } from 'react';
import { Flex, Text, Box } from 'shared/components/display';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { SegmentOption } from 'shared/components/molecules/SegmentedButton/types';
import cs from 'classnames';
import getSegmentedButtonStyles, { Slider } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { SUBHEADING_SM } = TEXT_VARIANTS;

function getSelected<V>(options: SegmentOption<V>[], defaultValue?: V): number {
  return defaultValue ? options.findIndex((opt) => opt.value === defaultValue) : 0;
}

interface Props<V = string> {
  optionWidth?: number;
  options: SegmentOption<V>[];
  value?: V;
  onChanged: (option: SegmentOption<V>) => void;
  className?: string;
  disabled?: boolean;
}

export const SegmentedButton = <V extends {}>({
  value = undefined,
  optionWidth = 7,
  options,
  onChanged,
  className,
  disabled = false,
}: Props<V>) => {
  const [selected, setSelected] = useState(getSelected<V>(options, value));

  useEffect(() => {
    onChanged(options[selected]);
  }, [selected]);

  return (
    <Flex css={getSegmentedButtonStyles(optionWidth)} className={cs('segmented-button', className)}>
      <Box className="segmented-button__control">
        <Slider
          // TODO: Fix Types
          // @ts-ignore
          index={selected}
          optionWidth={optionWidth}
        />
        {options.map(({ text, value: inputValue, icon }, i) => (
          <Box key={`${inputValue}`} className="segmented-button__option">
            <label className="segmented-button__label">
              <input
                type="radio"
                value={`${inputValue}`}
                checked={selected === i}
                data-index={i}
                onChange={() => setSelected(i)}
                className="segmented-button__input"
                disabled={disabled}
              />
              <Text as="span" variant={SUBHEADING_SM}>
                {icon ? <FontAwesomeIcon icon={icon} /> : text}
              </Text>
            </label>
          </Box>
        ))}
      </Box>
    </Flex>
  );
};

import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { isEqual } from 'lodash';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Box, Flex } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import Heading from 'shared/components/molecules/Heading';
import NumberInput from 'shared/components/molecules/NumberInput';

import { GENERIC_COPY } from 'shared/config/copy';
import { AccountPricingConfig } from 'shared/typings/account';

import styles from './accountPricingStyles';
import {
  ACCOUNT_PRICING_FIELDNAMES,
  ACCOUNT_PRICING_LABELS,
  AccountPricingValues,
  initialValues,
  MAX_PRICING,
  MIN_PRICING,
  validationSchema,
} from './validation';

const AccountPricing = () => {
  const params = useParams<{
    accountId: string;
  }>();
  const accountId = params.accountId;
  const { updateAccountPricing, getAccountPricing, account, loadingAccountPricing } = useAccounts({ accountId });

  const onSubmit = (values: AccountPricingValues, setSubmitting: (isSubmitting: boolean) => void) => {
    updateAccountPricing(accountId, values, () => setSubmitting(false));
  };

  const accountPricingValues = useMemo(() => account?.pricing || initialValues, [account]);

  useEffect(() => {
    getAccountPricing(accountId);
  }, [accountId]);

  return (
    <Box css={styles} className="account-pricing">
      {loadingAccountPricing ? (
        <CenteredSpinner />
      ) : (
        <Formik<AccountPricingConfig>
          validationSchema={validationSchema}
          initialValues={accountPricingValues}
          onSubmit={(values, { setSubmitting }) => {
            onSubmit(values, setSubmitting);
          }}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <Flex className="account-pricing__form-container">
                <Heading text="Performance Briefs" />
                <Field
                  min={MIN_PRICING}
                  max={MAX_PRICING}
                  name={ACCOUNT_PRICING_FIELDNAMES.PERFORMANCE_CREATOR_PAYOUT}
                  component={NumberInput}
                  label={`${ACCOUNT_PRICING_LABELS.CREATOR_PAYOUT}*`}
                  placeholder={ACCOUNT_PRICING_LABELS.CREATOR_PAYOUT}
                  autoComplete="none"
                  className="account-pricing__field"
                />
                <Field
                  min={MIN_PRICING}
                  max={MAX_PRICING}
                  name={ACCOUNT_PRICING_FIELDNAMES.PERFORMANCE_ELITE_CREATOR_PAYOUT}
                  component={NumberInput}
                  label={`${ACCOUNT_PRICING_LABELS.ELITE_CREATOR_PAYOUT}*`}
                  placeholder={ACCOUNT_PRICING_LABELS.ELITE_CREATOR_PAYOUT}
                  autoComplete="none"
                  className="account-pricing__field"
                />
                <Field
                  min={MIN_PRICING}
                  max={MAX_PRICING}
                  name={ACCOUNT_PRICING_FIELDNAMES.PERFORMANCE_CREATOR_APPROVAL_PRICING}
                  component={NumberInput}
                  label={`${ACCOUNT_PRICING_LABELS.CREATOR_APPROVAL_PRICING}*`}
                  placeholder={ACCOUNT_PRICING_LABELS.CREATOR_APPROVAL_PRICING}
                  autoComplete="none"
                  className="account-pricing__field"
                />
                <Field
                  min={MIN_PRICING}
                  max={MAX_PRICING}
                  name={ACCOUNT_PRICING_FIELDNAMES.PERFORMANCE_ELITE_CREATOR_APPROVAL_PRICING}
                  component={NumberInput}
                  label={`${ACCOUNT_PRICING_LABELS.ELITE_CREATOR_APPROVAL_PRICING}*`}
                  placeholder={ACCOUNT_PRICING_LABELS.ELITE_CREATOR_APPROVAL_PRICING}
                  autoComplete="none"
                  className="account-pricing__field"
                />
                <Heading text="Creative Only Briefs" />
                <Field
                  min={MIN_PRICING}
                  max={MAX_PRICING}
                  name={ACCOUNT_PRICING_FIELDNAMES.CREATIVE_CREATOR_PAYOUT}
                  component={NumberInput}
                  label={`${ACCOUNT_PRICING_LABELS.CREATOR_PAYOUT}*`}
                  placeholder={ACCOUNT_PRICING_LABELS.CREATOR_PAYOUT}
                  autoComplete="none"
                  className="account-pricing__field"
                />
                <Field
                  min={MIN_PRICING}
                  max={MAX_PRICING}
                  name={ACCOUNT_PRICING_FIELDNAMES.CREATIVE_ELITE_CREATOR_PAYOUT}
                  component={NumberInput}
                  label={`${ACCOUNT_PRICING_LABELS.ELITE_CREATOR_PAYOUT}*`}
                  placeholder={ACCOUNT_PRICING_LABELS.ELITE_CREATOR_PAYOUT}
                  autoComplete="none"
                  className="account-pricing__field"
                />
                <Field
                  min={MIN_PRICING}
                  max={MAX_PRICING}
                  name={ACCOUNT_PRICING_FIELDNAMES.CREATIVE_CREATOR_APPROVAL_PRICING}
                  component={NumberInput}
                  label={`${ACCOUNT_PRICING_LABELS.CREATOR_APPROVAL_PRICING}*`}
                  placeholder={ACCOUNT_PRICING_LABELS.CREATOR_APPROVAL_PRICING}
                  autoComplete="none"
                  className="account-pricing__field"
                />
                <Field
                  min={MIN_PRICING}
                  max={MAX_PRICING}
                  name={ACCOUNT_PRICING_FIELDNAMES.CREATIVE_ELITE_CREATOR_APPROVAL_PRICING}
                  component={NumberInput}
                  label={`${ACCOUNT_PRICING_LABELS.ELITE_CREATOR_APPROVAL_PRICING}*`}
                  placeholder={ACCOUNT_PRICING_LABELS.ELITE_CREATOR_APPROVAL_PRICING}
                  autoComplete="none"
                  className="account-pricing__field"
                />
                <FormButton
                  disabled={isEqual(values, accountPricingValues) || isSubmitting}
                  className="account-pricing__submit-btn"
                >
                  {GENERIC_COPY.BUTTON_SUBMIT}
                </FormButton>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default AccountPricing;

import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { AudienceForm } from 'features/accountPlatforms/facebook/components/AudienceForm/AudienceForm';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Flex } from 'shared/components/display';
import Heading from 'shared/components/molecules/Heading';

import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import { GLOBAL_COPY_VARIABLES } from 'shared/config/copy/allCopy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { usePermissions } from 'shared/hooks/usePermissions';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { Status, Type } from 'shared/typings/facebookBusinessAsset/enums';

import TOSAgreement from './agreement';
import ReuploadButton from './button';
import styles from './styles';

const AUDIENCE_TEMPLATE_URL = `${process.env.PUBLIC_URL}/files/audienceTemplate.csv`;

export const DownloadAudienceTemplate = () => (
  <a
    href={AUDIENCE_TEMPLATE_URL}
    download={ACCOUNT_PLATFORM_COPY.AUDIENCE_TEMPLATE_FILE}
    className="account-template-download"
  >
    <DownloadButton variant={BUTTON_VARIANTS.MICRO} type="button">
      <FontAwesomeIcon icon={faDownload} />
      {ACCOUNT_PLATFORM_COPY.AUDIENCE_TEMPLATE_DOWNLOAD}
    </DownloadButton>
  </a>
);

const EditAudience = () => {
  const params = useParams<{ organizationId: string; accountId: string; accountPlatformId: string }>();
  const organizationId = +params.organizationId;
  const accountId = +params.accountId;
  const accountPlatformId = +params.accountPlatformId;

  const { formatMessage } = useIntl();
  const [showForm, setShowForm] = useState(false);

  const { platformsLoading, accountPlatform } = useAccountPlatforms({
    accountId,
    accountPlatformId,
  });

  const { isAuthorized } = usePermissions();
  const authorizedToEdit = isAuthorized(organizationId, PERMISSIONS_MAP.EDIT_ACCOUNT.API);

  const toggleForm = () => setShowForm((prev) => !prev);
  useEffect(() => {
    if (accountPlatform.facebookCustomAudience) {
      setShowForm(false);
    } else {
      setShowForm(true);
    }
  }, [accountPlatform.facebookCustomAudience]);

  const tosAgreement = useMemo(
    () => accountPlatform.facebookBusinessAssets.find(({ type }) => type === Type.AUDIENCE_TOS_AGREEMENT),
    [accountPlatform.facebookBusinessAssets],
  );

  const tosPending = tosAgreement?.status !== Status.ACTIVE;

  return platformsLoading ? (
    <CenteredSpinner />
  ) : (
    <Flex css={styles} className="edit-audience">
      <Flex className="edit-audience__header">
        <Heading
          text={ACCOUNT_PLATFORM_COPY.AUDIENCE_HEADER}
          descriptionText={
            accountPlatform.facebookCustomAudience &&
            `${formatMessage({ id: 'AUDIENCE_DESCRIPTION' }, { appName: GLOBAL_COPY_VARIABLES.APP_NAME })}\n\n${
              ACCOUNT_PLATFORM_COPY.AUDIENCE_TEMPLATE
            }`
          }
        />
        <DownloadAudienceTemplate />
      </Flex>
      <Flex className="edit-audience__body">
        <TOSAgreement
          accessId={accountPlatform.accessId}
          disabled={!accountPlatform?.hasRequiredFacebookAssets}
          tosAgreement={tosAgreement}
        />
        <Flex className="edit-audience__form">
          <ReuploadButton
            facebookAudience={accountPlatform?.facebookCustomAudience}
            show={showForm}
            onClick={toggleForm}
          />
          {showForm && (
            <AudienceForm
              accountId={accountId}
              accountPlatformId={accountPlatformId}
              disabled={!authorizedToEdit || tosPending}
              isReupload={Boolean(accountPlatform?.facebookCustomAudience)}
              onCancel={toggleForm}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const DownloadButton = styled(Button)`
  svg {
    margin-right: 0.25rem;
  }
`;

export default EditAudience;

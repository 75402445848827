import React, { useEffect, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useAuth } from 'features/auth/useAuth';
import { useMessaging } from 'features/messages/useMessaging';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useSessionLogout } from 'shared/hooks/useSessionLogout';
import BrandOnboardingRoutes from 'shared/router/brandRoutes/BrandOnboardingRoutes';
import BrandRoutes from 'shared/router/brandRoutes/BrandRoutes';
import NewUserRoutesComp from 'shared/router/NewUserRoutes';
import { UserType } from 'shared/typings/user/enums';

import CreatorRoutes, { CreatorOnboardingRoutes } from '../creatorRoutes/CreatorRoutes';

import DashboardWrapper from './DashboardWrapper';

const DashboardRoutes = () => {
  const {
    userType,
    lastVisitedOrg,
    onboarded,
    currentUser: { id },
  } = useCurrentUser();
  const { listenToChanges, removeListeners } = useMessaging();
  const { signOut } = useAuth();
  const RoutesByType = useMemo(() => {
    switch (userType) {
      case UserType.CREATOR:
        return onboarded ? CreatorRoutes : CreatorOnboardingRoutes;
      case UserType.ORG:
        return onboarded ? BrandRoutes : BrandOnboardingRoutes;
      default:
        return NewUserRoutesComp;
    }
  }, [userType, onboarded]);

  useEffect(() => {
    listenToChanges();
    return () => {
      removeListeners();
    };
  }, [lastVisitedOrg, userType]);

  useSessionLogout(signOut);

  return (
    <Routes>
      <Route element={<DashboardWrapper userId={id} />}>
        <Route path="*" element={<RoutesByType />} />
      </Route>
    </Routes>
  );
};

export default DashboardRoutes;

import React, { useCallback, useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { faMessage } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import { lowerCase, upperFirst } from 'lodash';

import StarIcon from 'features/briefs/components/atoms/StarIcon';
import BriefCreatorsControls from 'features/briefs/components/molecules/BriefCreatorsControls';
import BriefCreatorInfo from 'features/briefs/components/organisms/BriefCreatorInfo';
import CreatorBriefStatus from 'features/briefs/CreatorBriefStatus';
import { useCreators } from 'features/creators/useCreators';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import SmallCreatorPreview from 'features/users/components/SmallCreatorPreview/SmallCreatorPreview';
import BackButton from 'shared/components/atoms/BackButton';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Box, Flex, Text } from 'shared/components/display';
import { ShippingLinkModal } from 'shared/components/molecules/ShippingLinkModal';
import BrkfstTable from 'shared/components/organisms/BrkfstTable';
import DayPickerModal from 'shared/components/organisms/DayPickerModal';
import Pagination from 'shared/components/organisms/Pagination';
import BrandApprovedBadge from 'features/users/components/atoms/BrandApprovedBadge';
import EliteCreatorBadge from 'features/users/components/atoms/EliteCreatorBadge';
import { CREATOR_COPY } from 'shared/config/copy/creatorCopy';
import { useQuery } from 'shared/hooks/useQuery';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import COLORS from 'shared/styles/colors';
import { breakpoints } from 'shared/styles/styleFunctions';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Brief } from 'shared/typings/briefs';
import { BriefStatus } from 'shared/typings/briefs/enums';
import { BrkfstUser } from 'shared/typings/user';
import { UserStatus } from 'shared/typings/user/enums';
import { ALL_CREATORS, UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { downloadFile } from 'shared/utilities/fileUtility';

import styles from './styles';
import SpotlightCreatorBadge from 'features/users/components/atoms/SpotlightCreatorBadge';

const columnHelper = createColumnHelper<any>();

interface Props {
  currentBrief: Brief;
}

const BriefCreators: React.FC<Props> = ({ currentBrief }) => {
  const briefId = currentBrief.id;
  const accountId = currentBrief.accountId;

  const {
    creators,
    creatorResultsCount,
    loading,
    singleLoading,
    deadlineChangeLoading,
    fetchBriefCreators,
    setCreatorBriefDeadline,
    setShippingLink,
    deleteCreatorBriefDeadline,
    setCreatorBriefStarred,
    starredChangeLoading,
    getBriefCreatorsShippingInfo,
    getCreatorsShippingInfoLoading,
  } = useCreators();
  const { getQuery, setQuery } = useQuery();
  const query = getQuery();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const searchParams = useSearchParams(query, {
    creatorsPage: 1,
    creatorsSize: 10,
    creatorsStatus:
      currentBrief.status === BriefStatus.CLOSED || currentBrief.full
        ? UserBriefStatus.WORKING
        : UserBriefStatus.PENDING,
    creatorsOrderAsc: false,
    // alias for user_briefs used in creator brief query
    creatorsOrderBy: 'score',
    brandApprovedBadge: false,
    selectedId: null,
  });

  const memoizedFetchBriefCreators = useCallback(() => {
    const {
      creatorsStatus,
      creatorsPage: page,
      creatorsSize: size,
      creatorsOrderAsc: orderAsc,
      creatorsOrderBy: orderBy,
      brandApprovedBadge,
    } = searchParams;

    const query = {
      size,
      page,
      orderBy,
      orderAsc,
      accountId,
      status: creatorsStatus !== ALL_CREATORS ? creatorsStatus : undefined,
      brandApprovedBadge,
    };
    fetchBriefCreators(query, briefId);
  }, [searchParams, accountId, briefId]);

  const isMobile = useMediaQuery({ maxWidth: breakpoints.md - 1 });

  useEffect(() => {
    memoizedFetchBriefCreators();
  }, [searchParams, accountId, briefId]);

  const creatorIndexColumns = useMemo(() => {
    const columns = [
      columnHelper.accessor((row) => row, {
        header: '',
        id: 'starIcon',
        cell: (info) => {
          const creator = info.getValue();
          return (
            <Box className="brief-creators__star-icon">
              <StarIcon
                starred={creator.starred}
                onClick={() => setCreatorBriefStarred(creator.id, briefId, !creator.starred)}
                disabled={starredChangeLoading}
                dataCy="brief-creators__star-icon"
                size="lg"
              />
            </Box>
          );
        },
        meta: {
          width: '30px',
        },
      }),
      columnHelper.accessor((row) => row, {
        header: CREATOR_COPY.COLUMN_CREATOR,
        cell: (info) => {
          const creator = info.getValue();
          const creatorUpdatedData = Object.assign({}, creator);
          if (creator.status === UserStatus.INVITED) {
            creatorUpdatedData.firstName = creator.email;
            creatorUpdatedData.lastName = '';
          }
          return <SmallCreatorPreview user={creatorUpdatedData} showFullName showTooltip />;
        },
        meta: { width: '150px' },
      }),
      columnHelper.accessor((row) => row, {
        header: '',
        id: 'badges',
        cell: (info) => {
          const { isElite, brandApprovedBadge, applicationPitch, creatorBriefStatus, isSpotlighted } = info.getValue();
          return (
            <Flex
              className="brief-creators__badge-cell-wrapper"
              dataCy="brief-creators__badge-cell-wrapper"
              data-spotlighted={isSpotlighted}
            >
              {brandApprovedBadge && <BrandApprovedBadge showTooltip />}
              {isElite && <EliteCreatorBadge showTooltip />}
              {isSpotlighted && <SpotlightCreatorBadge showTooltip />}
              {applicationPitch?.length > 0 && creatorBriefStatus === UserBriefStatus.PENDING && (
                <Tooltip
                  content={
                    <Box css={styles}>
                      <Text className="brief-creators__tooltip-text" variant={TEXT_VARIANTS.SUBHEADING}>
                        Creator Message
                      </Text>
                      <Text className="brief-creators__tooltip-text" variant={TEXT_VARIANTS.BODY}>
                        {applicationPitch || ''}
                      </Text>
                    </Box>
                  }
                  maxWidth={300}
                >
                  <FontAwesomeIcon className="brief-creators__badge" icon={faMessage} color={COLORS.ORANGE500} />
                </Tooltip>
              )}
            </Flex>
          );
        },
        meta: {
          width: '55px',
          paddingLeft: '0',
        },
      }),
      columnHelper.accessor((row) => row, {
        header: CREATOR_COPY.COLUMN_CREATOR_BRIEF_STATUS,
        cell: (info) => {
          const { creatorBriefStatus } = info.getValue();
          return (
            <Flex className="brief-creators__cell-wrapper">
              <CreatorBriefStatus creatorBriefStatus={creatorBriefStatus} />
            </Flex>
          );
        },
        meta: {
          width: '100px',
        },
      }),
    ];
    if (currentBrief.shippingRequired) {
      columns.push(
        columnHelper.accessor((row) => row, {
          header: CREATOR_COPY.COLUMN_SHIPPING_LINK,
          id: 'shippingLink',
          cell: (info) => {
            const creator = info.getValue();
            const briefIsClosed = currentBrief.status === BriefStatus.CLOSED;
            const hasActiveOrInProgressStatus =
              creator.creatorBriefStatus === UserBriefStatus.ACTIVE ||
              creator.creatorBriefStatus === UserBriefStatus.DRAFTED;
            const cannotEditShippingLink = !hasActiveOrInProgressStatus || briefIsClosed;

            if (!creator.shippingLink && cannotEditShippingLink) {
              return <></>;
            }

            return (
              <ShippingLinkModal
                shippingLink={creator.shippingLink}
                onSubmit={(shippingLink, onFinish, successToast) =>
                  setShippingLink(creator.id, briefId, shippingLink, onFinish, successToast)
                }
                disabled={cannotEditShippingLink}
              />
            );
          },
          meta: {
            width: '130px',
          },
        }),
      );
    }

    columns.push(
      columnHelper.accessor((row) => row, {
        header: CREATOR_COPY.COLUMN_BRIEF_DEADLINE,
        cell: (info) => {
          const creator = info.getValue();
          const briefIsClosed = currentBrief.status === BriefStatus.CLOSED;
          const hasActiveOrInProgressStatus = [UserBriefStatus.ACTIVE, UserBriefStatus.DRAFTED].includes(
            creator.creatorBriefStatus,
          );
          const cannotEditDeadline = !hasActiveOrInProgressStatus || briefIsClosed;

          if (cannotEditDeadline && !creator.deadline) {
            return <></>;
          }

          return (
            <DayPickerModal
              title="Deadline"
              onSelect={(day) =>
                day
                  ? setCreatorBriefDeadline(creator.id, briefId, day, timezone, memoizedFetchBriefCreators)
                  : deleteCreatorBriefDeadline(creator.id, briefId, memoizedFetchBriefCreators)
              }
              initialDate={creator.deadline ? new Date(creator.deadline) : undefined}
              onlyAllowFutureDateSelection
              disabled={cannotEditDeadline}
            />
          );
        },
        meta: {
          width: '145px',
        },
      }),
    );

    return columns;
  }, [briefId, isMobile]);

  const selectedCreator = useMemo(() => {
    return creators.find((creator) => creator.id === query.selectedId);
  }, [query.selectedId, creators]);

  const clearCreator = () => setQuery({ selectedId: null });

  const generateShippingInfoFile = (
    creatorsShippingInfo: Array<
      Pick<BrkfstUser, 'firstName' | 'lastName' | 'mailingAddress' | 'phone'> & {
        shippingLink?: string;
        name: string;
      }
    >,
  ) => {
    const shippingInformation = creatorsShippingInfo.map((item) => {
      const { country, state, addressLine1, addressLine2, city, companyName, postalCode } = item.mailingAddress ?? {};
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        shippingLink: item.shippingLink,
        addressLine1: `"${addressLine1}"`,
        addressLine2: `"${addressLine2}"`,
        companyName: `"${companyName}"`,
        country: country?.name || '',
        state: state?.name || '',
        city,
        postalCode,
        phone: `${item.phone?.number}` || '',
      };
    });
    const refinedData: Array<any> = [];
    const titleColumns = Object.keys(shippingInformation[0]).map((title) => upperFirst(lowerCase(title)));
    let csvContent = '';

    refinedData.push(titleColumns);
    shippingInformation.forEach((item) => {
      refinedData.push(Object.values(item));
    });

    refinedData.forEach((row) => {
      csvContent += row.join(',') + '\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
    const objUrl = URL.createObjectURL(blob);
    downloadFile({ url: objUrl, name: `${currentBrief.name}-shipping.csv` });
  };

  const onDownloadShippingInfoClick = () => {
    getBriefCreatorsShippingInfo({
      briefId,
      status: searchParams.creatorsStatus,
      onSuccess: (creatorsShippingInfo) => generateShippingInfoFile(creatorsShippingInfo.data),
    });
  };

  return (
    <Box css={styles} className="brief-creators" id="brief-creators">
      {Boolean(selectedCreator) ? (
        <>
          <BackButton onClick={clearCreator}>{CREATOR_COPY.BUTTON_ALL_CREATORS}</BackButton>
          <BriefCreatorInfo
            accountId={accountId}
            briefId={briefId}
            loading={loading || singleLoading}
            creator={selectedCreator}
          />
        </>
      ) : (
        <>
          <Box className="brief-creators__header">
            <BriefCreatorsControls
              searchParams={searchParams}
              brief={currentBrief}
              onDownloadShippingInfoClick={onDownloadShippingInfoClick}
              isDownloadLoading={getCreatorsShippingInfoLoading}
              creatorResultsCount={creatorResultsCount}
              shippingRequired={currentBrief.shippingRequired}
            />
          </Box>
          <Pagination
            totalAmount={creatorResultsCount}
            searchParams={searchParams}
            sizeParamName="creatorsSize"
            pageParamName="creatorsPage"
          >
            {loading || deadlineChangeLoading ? (
              <CenteredSpinner />
            ) : (
              <BrkfstTable
                data={creators}
                columns={creatorIndexColumns}
                onRowClick={(creator) => setQuery({ selectedId: creator.id })}
              />
            )}
          </Pagination>
        </>
      )}
    </Box>
  );
};

export default BriefCreators;

export const SIZING = {
  SMALL: 'small',
  MEDIUM: 'medium',
  MEDIUM_WIDE: 'mediumWide',
  LARGE: 'large',
  FULLSCREEN: 'fullscreen',
};

export const RIGHT_DRAWER_FULL_WIDTH = '20rem';

export const SIDEBAR = {
  EXPANDED_WIDTH: '16rem',
  COLLAPSED_WIDTH: '0rem',
};

import React from 'react';
import * as yup from 'yup';

import { Box, Text } from 'shared/components/display';
import ButtonGroupInput, { Option } from 'shared/components/molecules/ButtonGroupInput/ButtonGroupInput';
import { buttonGroupRequiredSchema } from 'shared/components/molecules/ButtonGroupInput/validation';
import { StepFieldProps } from 'shared/components/forms/MultiStepForm/interfaces';
import { CREATOR_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

export const WorkPreferencesFormFields: React.FC<StepFieldProps> = ({ className }) => {
  return (
    <Box css={styles}>
      <Text variant={TEXT_VARIANTS.LABEL} className={className}>
        {CREATOR_COPY.CAPTION_ONE_OR_BOTH}
      </Text>
      <ButtonGroupInput name={WORK_PREFERENCES_FIELDNAMES.WORK_TYPE} isMultiSelect />
      <Text variant={TEXT_VARIANTS.SUBHEADING} className="work-pref__subheading">
        {CREATOR_COPY.CAPTION_WORK_PREFERENCES_EDITING}
      </Text>
      <ButtonGroupInput name={WORK_PREFERENCES_FIELDNAMES.EDITING} dataCy="editing" />
    </Box>
  );
};

export const WORK_PREFERENCES_FIELDNAMES = {
  WORK_TYPE: 'workType',
  EDITING: 'editing',
};

export const workPreferencesSchema = yup.object({
  workType: buttonGroupRequiredSchema,
  editing: buttonGroupRequiredSchema,
});

export type WorkPreferencesValues = {
  workType: Option[];
  editing: Option[];
};

export const formatInitialWorkPrefValues = (values: WorkPreferencesValues) => {
  return {
    workType: values?.workType,
    editing: values?.editing.map(({ value, selected }) => {
      if (value === 'can edit content')
        return {
          value,
          label: 'I can edit content',
          selected,
        };
      if (value === 'uses an editor')
        return {
          value,
          label: 'I use an editor',
          selected,
        };
      if (value === 'needs to find an editor')
        return {
          value,
          label: 'I need to find an editor',
          selected,
        };
    }),
  };
};

import React from 'react';
import { Outlet } from 'react-router-dom';
import { Flex } from 'shared/components/display';
import { SignalRProvider } from 'shared/contexts/signalr';
import styles from './styles';

interface Props {
  userId: number;
}
const DashboardWrapper: React.FC<Props> = ({ userId }) => {
  return (
    <SignalRProvider userId={userId}>
      <Flex css={styles} className="dashboard-wrapper">
        <Outlet />
      </Flex>
    </SignalRProvider>
  );
};

export default React.memo(DashboardWrapper);

import { css } from '@emotion/react';
import { controlStyles } from 'shared/components/atoms/SelectControl';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';

export const reactSelectStyles = (width) => ({
  container: (base) => ({
    ...base,
    width,
  }),
  menu: (base) => ({
    ...base,
    zIndex: zIndex.zDropdown,
    minWidth: 'fit-content',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: zIndex.zDropdownPortal,
  }),
  valueContainer: (base) => ({
    ...base,
    overflow: 'visible',
  }),
  ...controlStyles,
});

export default css({
  '&.value-container': {
    alignItems: 'center',
    height: '100%',
    position: 'relative',
  },
  '.value-container': {
    '&__placeholder': {
      position: 'absolute',
      left: 0,
      zIndex: zIndex.zBackdrop,
      width: 'max-content',
      margin: 0,
      color: COLORS.NEUTRAL700,
    },
    '&__placeholder--active': {
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
      transformOrigin: 'bottom left',
      transform: 'scale(0.75) translateY(-30px)',
      zIndex: zIndex.zForeground,
      background: COLORS.WHITE,
      color: COLORS.PRIMARY500,
    },
  },
  '.address-search__input': {
    margin: 0,
    padding: 0,
    flexGrow: 1,
    position: 'absolute',
    left: 0,
    top: '-12px',
    zIndex: zIndex.zForeground,
    width: '100%',
    display: 'block',
  },
  '.address-search__input--active input': {
    pointerEvents: 'auto',
  },
  '.address-search__input--inactive input': {
    pointerEvents: 'none',
    '&::placeholder': {
      color: COLORS.WHITE,
    },
  },
});

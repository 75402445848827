import React from 'react';
import { Button } from 'rebass';
import { Form, Formik, FormikHelpers } from 'formik';

import { Box, Flex, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';

import { USER_COPY } from 'shared/config/copy';
import { RESOURCES } from 'shared/config/resourceNames';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';
import { PhoneStatus } from 'shared/typings/user/enums';

import {
  NOTIFICATIONS_FIELDNAMES,
  NotificationsFormFields,
  notificationsSchema,
} from '../../CreatorOnboardingStepsFields';

import styles from './styles';

interface Props {
  user: BrkfstUser;
  onSubmit: (values: any) => void;
}

const TextNotificationsForm: React.FC<Props> = ({ user, onSubmit }) => {
  const initialValues = {
    [NOTIFICATIONS_FIELDNAMES.OPT_IN]: user?.phone?.status === PhoneStatus.ACTIVE || false,
  };

  const handleSubmit = (values, formikHelpers: FormikHelpers<any>) => {
    const phoneValues = {
      notifications: values[NOTIFICATIONS_FIELDNAMES.OPT_IN],
      number: user.phone?.number,
      entity: RESOURCES.USER_NOTIFICATION,
      onSuccess: () => {
        formikHelpers.setSubmitting(false);
      },
    };
    onSubmit(phoneValues);
  };

  const onCancel = (setValues: (values: any) => void) => {
    setValues(initialValues);
  };

  return (
    <Box css={styles} className="text-notifications-form">
      <Text variant={TEXT_VARIANTS.SUBHEADING} className="text-notifications-form__field-title">
        {USER_COPY.SUBHEADING_TEXT_NOTIFICATIONS}
      </Text>
      <Formik initialValues={initialValues} validationSchema={notificationsSchema} onSubmit={handleSubmit}>
        {({ setValues }) => (
          <Form>
            <NotificationsFormFields showUpdateNote={false} />
            <Flex className="text-notifications-form__submit-btn">
              <Button
                variant={BUTTON_VARIANTS.OPTION}
                className="text-notifications-form__cancel-btn"
                onClick={() => {
                  onCancel(setValues);
                }}
                type="button"
              >
                Cancel
              </Button>
              <FormButton dataCy="text-notifications-form__submit-btn" disableUntilTouched>
                Save Changes
              </FormButton>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default TextNotificationsForm;

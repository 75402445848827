import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import text, { fontSizes } from 'shared/styles/text';
export default css({
  '.account-health-score': {
    '&__progress-bar': {
      width: '40vw',
      transform: 'rotate(180deg)',
      minWidth: '150px',
      [mediaquery.md]: {
        width: '10vw',
      },
    },
  },
  '& .CircularProgressbar-text ': {
    fill: COLORS.BLACK,
    transformOrigin: '50px 50px',
    transform: 'rotate(180deg)',
    ...text.h1,
    fontSize: fontSizes[6],
  },
});

import { FULL_COPY } from 'shared/config/copy/allCopy';
import { createIntl, createIntlCache, IntlShape, useIntl as useReactIntl } from 'react-intl';

type IntlObject = Omit<IntlShape, 'formatMessage'> & {
  formatMessage: ({ id }: { id: keyof typeof FULL_COPY }, values?: Record<string, any>) => string;
};

/** Drop-in replacement to react-intl's useIntl which adds id auto-complete and type-checking */
export function useIntl(): IntlObject {
  const { formatMessage, ...rest } = useReactIntl();
  return {
    formatMessage: (id, values) => {
      return formatMessage(id, values);
    },
    ...rest,
  };
}

// https://formatjs.io/docs/react-intl/api/#createintl
const cache = createIntlCache();

// This allows us to use the intl outside of react components or in any place
// within react that would normally throw an error for improper use of hooks.
// E.g. inside yup validation error messages.
const reactIntl = createIntl(
  {
    locale: 'en',
    defaultLocale: 'en',
    // @ts-ignore Typescript is complaining about Record<string, string> vs an object of strings. We can safely ignore this
    messages: FULL_COPY,
  },
  cache,
);

// As with useIntl, this just adds id auto-complete when using intl imperatively
export const intl: IntlObject = {
  ...reactIntl,
  formatMessage: (id, values) => {
    return reactIntl.formatMessage(id, values);
  },
};

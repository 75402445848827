import React, { useState } from 'react';
import cs from 'classnames';
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'shared/styles/styleFunctions';
import { Flex, Box } from 'shared/components/display';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';

import styles from './styles';

interface Props {
  className?: string;
  children: React.ReactElement | React.ReactElement[];
  portalTarget?: string;
  dataCy?: string;
  placement?: Placement;
  width?: string | number;
  disabled?: boolean;
  debug?: boolean;
  menuClassName?: string;
  ButtonContent: React.ReactElement;
  onClick?: () => void;
  allRounded?: boolean;
}
const SubDropdownButton: React.FC<Props> = ({
  className,
  children,
  portalTarget,
  dataCy,
  placement = 'right-start',
  width,
  disabled,
  debug,
  menuClassName,
  ButtonContent,
  onClick,
  allRounded,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const onOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange,
    middleware: [offset(2), flip(), shift()],
    whileElementsMounted: autoUpdate,
    ...(placement ? { placement } : {}),
  });

  const hover = useHover(context, {
    move: true,
    enabled: !disabled && !isMobile,
    handleClose: safePolygon(), // allows mouse to traverse from button to dropdown without closing dropdown
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'menu' });
  const click = useClick(context, { enabled: !disabled && isMobile, event: 'click' });

  const { getReferenceProps, getFloatingProps } = useInteractions([click, hover, focus, dismiss, role]);

  const portalId = typeof portalTarget === 'string' ? portalTarget : undefined;
  const portalRoot = typeof portalTarget !== 'string' ? portalTarget : undefined;

  const hideDropdown = () => {
    onOpenChange(false);
  };

  return (
    <>
      {React.cloneElement(
        <Flex>
          {ButtonContent}
          <FontAwesomeIcon icon={faAngleRight} className="sub-dropdown-button__caret" />
        </Flex>,
        getReferenceProps({
          ref: refs.setReference,
          className: cs('sub-dropdown-button', className),
          //   @ts-ignore
          'data-state': isOpen ? 'open' : 'closed',
          'data-cy': dataCy,
          css: styles(width),
        }),
      )}
      {(isOpen || debug) && (
        <FloatingPortal id={portalId} root={portalRoot}>
          <Box
            css={styles(width)}
            className={cs('sub-dropdown-button__menu', menuClassName, {
              'sub-dropdown-button__menu--all-rounded': allRounded,
              'sub-dropdown-button__menu--open': isOpen,
            })}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {React.Children.map(children, (Child: React.ReactElement) => {
              if (!Child) return null;
              const extraProps = {
                className: cs('sub-dropdown-button__option', 'endWithEllipsis', Child.props.className),
                type: 'button',
                variant: Child.props.variant,
                onClick: (e) => {
                  e?.persist();
                  if (Child.props.onClick) Child.props.onClick(e);
                  if (onClick) onClick();
                  hideDropdown();
                },
              };
              return React.cloneElement(Child, extraProps);
            })}
            {/* {children} */}
          </Box>
        </FloatingPortal>
      )}
    </>
  );
};

export default SubDropdownButton;

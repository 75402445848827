/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Text } from 'rebass/styled-components';
import { createColumnHelper } from '@tanstack/react-table';
import cs from 'classnames';

import BriefStatus from 'features/briefs/BriefStatus';
import CreatorBriefsPaginationControl from 'features/briefs/components/molecules/CreatorBriefsPaginationControl';
import { useCreatorBriefs } from 'features/briefs/hooks/useCreatorBriefs';
import CreatorSubmissionHeader from 'features/submissions/components/CreatorSubmissionHeader/CreatorSubmissionHeader';
import CreatorSubmissionModal from 'features/submissions/components/organisms/CreatorSubmissionModal';
import CreatorSubmissionTabs from 'features/submissions/components/organisms/CreatorSubmissionTabs';
import CreatorBriefsTable from 'features/submissions/CreatorBriefsTable';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Box } from 'shared/components/display';
import SplitPanelScroll from 'shared/components/layout/SplitPanelScroll/SplitPanelScroll';
import BrkfstTable from 'shared/components/organisms/BrkfstTable';
import Pagination from 'shared/components/organisms/Pagination';

import { SUBMISSION_COPY } from 'shared/config/copy/submissionCopy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useQuery } from 'shared/hooks/useQuery';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import { CLASSNAMES } from 'shared/styles/containers';
import { breakpoints } from 'shared/styles/styleFunctions';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BriefTypes } from 'shared/typings/briefs/enums';
import { SubmissionStatus } from 'shared/typings/submissions';
import { prettyDate, prettyStringDate } from 'shared/utilities/dateUtility';

import styles from './styles';

const columnHelper = createColumnHelper<any>();

const { BODY } = TEXT_VARIANTS;

const CreatorBriefs: React.FC = () => {
  // view for creators of their own submissions
  const { briefs, total, fetchCreatorBriefs, loading } = useCreatorBriefs();
  const { currentUser, isVetted } = useCurrentUser();

  const userId = currentUser.id;
  const { getQuery, setQuery } = useQuery();
  const query = getQuery();

  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  /** ********************************************************* */
  /* the selected brief information */
  /** ********************************************************* */
  // default to the first brief
  const briefId = useMemo(() => {
    if (isMobile && !query.item) {
      return null;
    }
    return query.item ? +query.item : briefs.length > 0 ? briefs[0].id : null;
  }, [query, briefs]);
  const currentBrief = useMemo(() => briefs.find((brief) => brief.id === briefId), [briefId, briefs]);
  /** ********************************************************* */

  const searchParams = useSearchParams(query, {
    page: 1,
    size: 10,
    inProgress: true,
  });

  const columns = useMemo(() => {
    const cols = [
      columnHelper.accessor((row) => row, {
        header: SUBMISSION_COPY.COLUMN_BRAND_SUBMISSION,
        cell: (info) => {
          const brief = info.getValue();
          const selected = brief.id === briefId;
          return (
            <Box
              className={cs('endWithEllipsis', {
                'creator-briefs__row--selected': selected,
              })}
            >
              {selected && <Box as="span" className="creator-briefs__selected" />}
              {brief.account?.name}
            </Box>
          );
        },
        meta: {
          addTooltip: true,
        },
      }),
      columnHelper.accessor((row) => row.name, {
        header: SUBMISSION_COPY.COLUMN_BRIEF_SUBMISSION,
        cell: (info) => info.renderValue(),
      }),
    ];

    if (searchParams.inProgress) {
      cols.push(
        columnHelper.accessor(
          (row) => ({
            briefCreatorStatus: row.briefCreatorStatus,
            full: row.full,
            inviteOnly: row.inviteOnly,
            id: row.id,
          }),
          {
            header: SUBMISSION_COPY.COLUMN_BRIEF_STATUS,
            cell: (info) => {
              const value = info.getValue();
              return (
                <Box
                  className={cs({
                    'creator-briefs__row--selected': value.id === briefId,
                  })}
                >
                  <BriefStatus
                    briefCreatorStatus={value.briefCreatorStatus}
                    full={value.full}
                    inviteOnly={value.inviteOnly}
                  />
                </Box>
              );
            },
            meta: {
              width: '110px',
            },
          },
        ),
      );
    }

    cols.push(
      ...[
        columnHelper.accessor((row) => row.creatorDeadline, {
          header: SUBMISSION_COPY.COLUMN_SUBMISSION_DEADLINE,
          cell: (info) => {
            const value = info.getValue();
            return value ? prettyStringDate(value) : '-';
          },
          meta: {
            width: '130px',
          },
        }),
        columnHelper.accessor((row) => row.briefCreatorSubmission?.dateSubmitted, {
          header: SUBMISSION_COPY.COLUMN_SUBMISSION_DATE,
          cell: (info) => {
            const value = info.getValue();
            return value ? prettyDate(value) : '-';
          },
          meta: {
            width: '130px',
          },
        }),
      ],
    );

    return cols;
  }, [briefId, searchParams.inProgress]);

  useEffect(() => {
    fetchCreatorBriefs(userId, {
      ...searchParams,
      // if the user is vetted, then we want to show only brand briefs
      // if not, then we want to show only the system brief
      type: isVetted ? BriefTypes.BRAND : BriefTypes.SYSTEM,
    });
  }, [userId, searchParams]);

  useEffect(() => {
    const briefSelected = !!currentBrief;
    const briefsExist = briefs.length > 0;
    const queryMatchesSelectedBrief = briefSelected && +query.item === currentBrief?.id;
    if (!isMobile) {
      if (!queryMatchesSelectedBrief && briefSelected) {
        /**
         * if the submission is not being set by the query,
         * aka the first submission was selected
         * then replace the item query from the url
         * */
        setQuery({ item: currentBrief?.id }, false, true);
      } else if (!briefSelected && briefsExist) {
        /**
         * If no submission is selected but there are submissions,
         * then it means the query doesn't match an existing submission
         * so we'll set it to the first submission
         */
        setQuery({ item: briefs[0].id }, false, true);
      }
    }
  }, [currentBrief, briefs]);

  const onRequestClose = () => {
    setQuery({ open: !query.open });
  };

  return (
    <>
      {isMobile ? (
        <>
          <CreatorBriefsPaginationControl searchParams={searchParams} isMobile />
          <Pagination totalAmount={total} searchParams={searchParams} pageSizeBase={10}>
            {briefs.length ? (
              <CreatorBriefsTable
                data={briefs}
                onPress={(brief) => setQuery({ item: brief.id, open: true })}
                hideStatus={!searchParams.inProgress}
              />
            ) : (
              <div className={CLASSNAMES.MAIN_CONTAINER}>
                {(loading && <CenteredSpinner />) || (
                  <Text variant={BODY}>{SUBMISSION_COPY.PLACEHOLDER_SUBMISSIONS}</Text>
                )}
              </div>
            )}
          </Pagination>
          <CreatorSubmissionModal onRequestClose={onRequestClose} isOpen={query.open} brief={currentBrief} />
        </>
      ) : (
        <SplitPanelScroll
          leftHeaderComponent={<CreatorBriefsPaginationControl searchParams={searchParams} />}
          leftComponent={
            <Box className="creator-briefs" css={styles}>
              <Pagination searchParams={searchParams} totalAmount={total}>
                <BrkfstTable
                  data={briefs}
                  onRowClick={(brief) => setQuery({ item: brief.id })}
                  loading={loading}
                  noResultsMessage={SUBMISSION_COPY.PLACEHOLDER_SUBMISSIONS}
                  columns={columns}
                />
              </Pagination>
            </Box>
          }
          rightHeaderComponent={
            <CreatorSubmissionHeader
              brief={currentBrief}
              submissionId={currentBrief?.briefCreatorSubmission?.id}
              isDraft={currentBrief?.briefCreatorSubmission?.status === SubmissionStatus.DRAFT}
            />
          }
          rightComponent={<CreatorSubmissionTabs brief={currentBrief} loading={loading} />}
        />
      )}
    </>
  );
};

export default CreatorBriefs;

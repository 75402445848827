import React, { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useOrganizations } from 'features/organizations/useOrganizations';
import CenteredSpinner from 'features/ui/CenteredSpinner';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useQuery } from 'shared/hooks/useQuery';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

const RedirectToTrends = () => {
  const { getQuery } = useQuery();
  const { accountId, orgId, trendId } = getQuery();
  const { lastVisitedOrg } = useCurrentUser();
  const { fetchOrg: fetchLastVisitOrg } = useOrganizations(lastVisitedOrg);
  const { fetchOrg: fetchOrgById } = useOrganizations(orgId);
  // Separate the loading state from `useComponentLoading`.
  // The `get-by-token` function alters the loading state in the ui.slice in redux,
  // making the `initialLoadingState` argument in `useComponentLoading` useless.
  // The get-by-token assigns it to `false` before this component is rendered.
  const [state, setState] = useState({
    loading: true,
    link: LinkCreator.createLink({
      routeKey: 'ORGANIZATION',
      variables: {
        organizationId: lastVisitedOrg,
      },
    }),
  });
  const { loading, link } = state;

  const setTrendState = (orgId: number, accountId: number, trendId?: number) => {
    setState({
      loading: false,
      link: LinkCreator.createLink({
        userType: UserType.ORG,
        routeKey: 'TRENDS_LIBRARY',
        variables: {
          organizationId: orgId,
          accountId: accountId,
        },
        query: trendId ? { trendId } : {},
      }),
    });
  };

  const lastVisitOnSuccess = useCallback(
    ({ data }) => {
      setTrendState(lastVisitedOrg, data?.accounts[0]?.id, trendId);
    },
    [lastVisitedOrg, trendId],
  );

  const onResponse = () => {
    setState((prev) => ({
      ...prev,
      loading: false,
    }));
  };

  useEffect(() => {
    if (lastVisitedOrg && !orgId)
      fetchLastVisitOrg({
        onSuccess: lastVisitOnSuccess,
        onError: onResponse,
      });
  }, [lastVisitedOrg, orgId, lastVisitOnSuccess]);

  useEffect(() => {
    if (orgId)
      fetchOrgById({
        onSuccess: () => {
          setTrendState(orgId, accountId, trendId);
        },
        onError: () => {
          fetchLastVisitOrg({ onSuccess: lastVisitOnSuccess, onError: onResponse });
        },
      });
  }, [lastVisitedOrg, orgId, accountId, trendId, lastVisitOnSuccess]);

  return loading ? <CenteredSpinner /> : <Navigate to={link} />;
};

export default RedirectToTrends;

import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NavigationPrompt from 'shared/components/molecules/NavigationPrompt';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import cs from 'classnames';

import { useInvoice } from 'features/organizations/useInvoice';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import BackButton from 'shared/components/atoms/BackButton';
import { Box, Flex } from 'shared/components/display';
import CheckoutSummary from 'shared/components/organisms/CheckoutSummary';

import { GENERIC_COPY } from 'shared/config/copy';
import { useStripe } from 'shared/hooks/useStripe';
import { CLASSNAMES as GLOBAL_CLASSNAMES } from 'shared/styles/containers';
import { STYLES } from 'shared/styles/styles';
import { LineItem } from 'shared/typings/lineItem';

import styles, { CLASSNAMES, MAIN_CLASS } from './styles';

const { COLUMN_COLUMN_ROW } = STYLES;

const MAX_WIDTH = '63rem';
interface Props {
  stripe: any;
  CheckoutComponent: ReactElement<any>;
  onSubmitText: string;
  onSubmit: (invoice?: any) => void;
  onCheckoutError: () => void;
  onCheckoutSuccess?: () => void;
  submitInProcess: boolean;
  backButtonProps: any;
  confirmationPageProps?: any;
  successRedirectRoute: string;
  heading: string;
  loading: boolean;
  total?: number;
  lineItems?: Array<LineItem>;
  leaveProcessingPageMessage?: string;
}

const CheckoutPageTemplate = ({
  stripe,
  CheckoutComponent,
  onSubmitText,
  onSubmit: handleSubmit,
  onCheckoutError,
  onCheckoutSuccess: onCheckoutComplete,
  submitInProcess,
  backButtonProps,
  confirmationPageProps = {},
  successRedirectRoute,
  heading,
  loading: loadingProp,
  total = 0,
  lineItems = undefined,
  leaveProcessingPageMessage = GENERIC_COPY.PROMPT_NAVIGATE_WARNING,
}: Props) => {
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const params: { organizationId: string; accountId: string } = useParams();
  const organizationId = +params.organizationId;
  const accountId = +params.accountId;

  const {
    checkoutInvoice,
    checkoutInvoiceHasPromoCode,
    loading: loadingInvoice,
    clearCheckoutInvoice,
  } = useInvoice({
    accountId,
  });

  const loading = useMemo(
    () => (loadingProp === undefined ? loadingInvoice : loadingProp),
    [loadingInvoice, loadingProp],
  );

  const { onCheckout, loading: loadingStripe, checkoutError, clearCheckoutError } = useStripe();
  const onCheckoutSuccess = () => {
    setSubmitted(true);
    if (onCheckoutComplete) onCheckoutComplete();
  };

  const onSubmit = () => {
    const successConfirmationPageProps = {
      ...confirmationPageProps,
      invoiceId: checkoutInvoice.id,
      heading: checkoutInvoice.message || confirmationPageProps.heading,
    };
    if (checkoutInvoice.piClientSecret) {
      onCheckout({
        organizationId,
        invoice: checkoutInvoice,
        checkoutInvoiceHasPromoCode,
        clientSecret: checkoutInvoice.piClientSecret,
        successRedirectRoute,
        confirmationPageProps: successConfirmationPageProps,
        stripe,
        onCheckoutError,
        onCheckoutSuccess,
      });
    }
    if (handleSubmit) handleSubmit(checkoutInvoice);
  };

  useEffect(() => {
    if (submitted && successRedirectRoute && !loadingStripe) {
      navigate(
        {
          pathname: successRedirectRoute,
        },
        {
          state: {
            ...confirmationPageProps,
            invoiceId: checkoutInvoice.id,
            heading: checkoutInvoice.message || confirmationPageProps.heading,
          },
        },
      );
    }
  }, [submitted, loadingStripe, checkoutInvoice, confirmationPageProps]);

  useEffect(() => {
    return () => {
      clearCheckoutError();
      clearCheckoutInvoice();
    };
  }, []);
  return loading ? (
    <CenteredSpinnerContainer />
  ) : (
    <Box css={styles} p={3} mx={STYLES.AUTO} maxWidth={MAX_WIDTH} className={MAIN_CLASS}>
      <NavigationPrompt
        when={loadingStripe}
        confirmButtonText={'Confirm'}
        cancelButtonText={'Cancel'}
        promptText={leaveProcessingPageMessage}
      />
      <Box mb={5}>
        <BackButton {...backButtonProps} />
      </Box>
      {checkoutError && checkoutError.message && (
        <Box p={5} mb={5} className={CLASSNAMES.errorBox}>
          {checkoutError.message}
        </Box>
      )}
      <Flex flexDirection={COLUMN_COLUMN_ROW}>
        <Box
          className={cs(GLOBAL_CLASSNAMES.PADDING_CONTAINER, CLASSNAMES.content, {
            [CLASSNAMES.contentProcessing]: loadingStripe,
          })}
          mr={[0, 0, 8]}
          mb={[5, 5, 0]}
        >
          {CheckoutComponent}
        </Box>
        <CheckoutSummary
          onSubmitText={onSubmitText}
          onSubmit={onSubmit}
          submitInProcess={checkoutInvoice.piClientSecret ? loadingStripe : submitInProcess}
          charges={checkoutInvoice.lineItems || lineItems}
          total={checkoutInvoice.total || total}
          heading={heading}
        />
      </Flex>
    </Box>
  );
};

export default (props) => {
  const { stripeObject } = useStripe(true);

  return (
    <Elements stripe={stripeObject}>
      <ElementsConsumer>
        {({ stripe, elements }) => <CheckoutPageTemplate stripe={stripe} elements={elements} {...props} />}
      </ElementsConsumer>
    </Elements>
  );
};

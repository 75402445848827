import React, { useRef } from 'react';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box } from 'shared/components/display';

import theme from 'shared/styles/themes';
import { copyToClipboard } from 'shared/utilities/stringUtility';

import styles, { ANIMATION_DURATION, classes } from './styles';

type Props = {
  text: string;
  link: string;
  size?: 'xs' | 'sm' | 'lg';
  dataCy?: string;
  className?: string;
  margin?: string;
};

const CopyLink: React.FC<Props> = ({ link, text, dataCy, size = 'sm', className, margin = theme.space[4] }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const handleClick = () => {
    copyToClipboard(link);
    setTimeout(() => buttonRef.current?.blur(), ANIMATION_DURATION);
  };

  return (
    <Box css={styles(text, margin)} className={className}>
      <button className={classes.linkButton} onClick={handleClick} ref={buttonRef} data-cy={dataCy}>
        <FontAwesomeIcon icon={faLink} size={size} />
      </button>
    </Box>
  );
};

export default CopyLink;

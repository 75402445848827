import * as Yup from 'yup';

import { getCategoryTagInputSchema } from 'shared/components/molecules/TagInput';
import { richTextSchema } from 'shared/components/Richtext';
import { isRichtextEmpty } from 'shared/components/Richtext/shared/utils';

import { BRIEF_COPY } from 'shared/config/copy';
import {
  CONSTRAINT_LIMITS,
  FACEBOOK_VIDEO_MAX_DURATION,
  FACEBOOK_VIDEO_MIN_DURATION,
  MESSAGES,
} from 'shared/config/validations';
import { intl } from 'shared/lib/intl';
import { isValidDomain } from 'shared/utilities/stringUtility';

const PRICING_MIN = 0;
const PAYOUT_MIN = 0;
const PRICING_PAYOUT_MAX = 10000;
export const INTERNAL_NOTE_MAX_LENGTH = 150;

export const FIELDNAMES = {
  // Fields
  NAME: 'name',
  CREATOR_PAYOUT: 'creatorPayout',
  ELITE_CREATOR_PAYOUT: 'eliteCreatorPayout',
  CREATOR_APPROVAL_PRICING: 'creatorApprovalPricing',
  ELITE_CREATOR_APPROVAL_PRICING: 'eliteCreatorApprovalPricing',
  BRAND_ASSETS: 'brandAssets',
  FREE_PRODUCT: 'freeProduct',
  WEBSITE: 'website',
  PROMO_CODE: 'promotionalCode',
  DESCRIPTION: 'description',
  MODULES: 'modules',
  AUTO_RESPONSE: 'autoResponse',
  INVITE_ONLY: 'inviteOnly',
  SHIPPING_REQUIREMENT: 'shippingRequired',
  INSHOP_REQUIREMENT: 'inShopRequired',
  CREATIVE_ONLY: 'creativeOnly',
  STATES: 'states',
  INTERNAL_NOTE: 'internalNote',
};

const payoutSchema = Yup.string().money({
  min: PAYOUT_MIN,
  max: PRICING_PAYOUT_MAX,
  message: `Payout needs to be between $${PAYOUT_MIN} and $${PRICING_PAYOUT_MAX}`,
});

const pricingSchema = Yup.string().money({
  min: PRICING_MIN,
  max: PRICING_PAYOUT_MAX,
  message: `Pricing needs to be between $${PRICING_MIN} and $${PRICING_PAYOUT_MAX}`,
});

// TODO Transition to using SchemaOf for typescript validation of field names
// Yup.SchemaOf<BriefForm>

export const getSchema = (isSuperadmin) =>
  Yup.object({
    name: Yup.string()
      .customRequiredString()
      .max(CONSTRAINT_LIMITS.MAX_BRIEF_TITLE_LENGTH)
      .required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD)
      .test({
        name: 'name',
        message: BRIEF_COPY.VALIDATION_NAME,
        test(name) {
          if (name) return !name.includes('}');
          return false;
        },
      }),

    ...(isSuperadmin ? { creatorPayout: payoutSchema, eliteCreatorPayout: payoutSchema } : {}),
    creatorApprovalPricing: pricingSchema,
    eliteCreatorApprovalPricing: pricingSchema,
    brandAssets: Yup.object({
      assets: Yup.array().of(
        Yup.object({
          name: Yup.string(),
          mimeType: Yup.string(),
          id: Yup.number(),
        }),
      ),
      newFiles: Yup.array().of(
        Yup.object({
          name: Yup.string(),
          mimeType: Yup.string(),
        }),
      ),
    }),
    freeProduct: Yup.string().customRequiredString(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
    website: Yup.string()
      .nullable()
      .test({
        name: 'domain',
        message: MESSAGES.DOMAIN,
        test(domain) {
          if (domain) return isValidDomain(domain);
          return true;
        },
      }),
    promotionalCode: Yup.string().nullable(),
    description: richTextSchema.required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
    modules: Yup.array().of(
      Yup.object({
        content: richTextSchema,
        tags: getCategoryTagInputSchema(true),
        fileType: Yup.string().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
        durationRange: Yup.array()
          .of(
            Yup.number()
              .integer(BRIEF_COPY.VALIDATION_DURATION_INTEGER)
              .min(
                FACEBOOK_VIDEO_MIN_DURATION,
                intl.formatMessage(
                  { id: 'VALIDATION_MIN_DURATION' },
                  {
                    minDuration: FACEBOOK_VIDEO_MIN_DURATION,
                  },
                ),
              )
              .max(
                FACEBOOK_VIDEO_MAX_DURATION,
                intl.formatMessage(
                  { id: 'VALIDATION_MAX_DURATION' },
                  {
                    maxDuration: FACEBOOK_VIDEO_MAX_DURATION,
                  },
                ),
              ),
          )
          .test({
            name: 'minMax',
            message: BRIEF_COPY.VALIDATION_DURATION,
            test(value) {
              if (value !== undefined) {
                const [minVal, maxVal] = value;
                return minVal !== undefined && maxVal !== undefined && minVal < maxVal;
              }
              return false;
            },
          }),
        scenes: Yup.array().of(
          Yup.object().shape(
            {
              title: Yup.string().when('description', {
                is: (value) => !isRichtextEmpty(value),
                then: Yup.string()
                  .customRequiredString()
                  .max(255, intl.formatMessage({ id: 'ERROR_STRING_LENGTH' }, { numChar: 255, entity: 'title' })),
                otherwise: Yup.string().optional().nullable(),
              }),
              description: Yup.mixed().when('title', {
                is: (value) => value,
                then: richTextSchema.required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
                otherwise: Yup.mixed().nullable(),
              }),
            },
            [['description', 'title']],
          ),
        ),
      }),
    ),
    autoResponse: Yup.string().trim().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
    inviteOnly: Yup.boolean(),
    shippingRequired: Yup.boolean(),
    creativeOnly: Yup.boolean(),
    inShopRequired: Yup.boolean(),
    states: Yup.array().when('inShopRequired', {
      is: (value) => value,
      then: Yup.array(
        Yup.object({
          label: Yup.string().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
          value: Yup.object({
            id: Yup.number().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
            name: Yup.string().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
            code: Yup.string().required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD),
          }),
        }),
      ).test('stateRequirement', BRIEF_COPY.VALIDATION_STATE_REQUIREMENT, (value) => (value?.length || 0) > 0),
      otherwise: Yup.array().nullable(),
    }),
    internalNote: Yup.string().nullable().trim().max(INTERNAL_NOTE_MAX_LENGTH),
  });

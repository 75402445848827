import { useOrganizations } from 'features/organizations/useOrganizations';
import { ProfilePicture } from 'features/users/components/molecules/ProfilePicture';
import smallMarketerPreviewStyles from 'features/users/components/SmallMarketerPreview/smallMarketerPreviewStyles';
import { Flex, Text } from 'shared/components/display';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';
import cs from 'classnames';
import React from 'react';

interface Props {
  user: BrkfstUser;
  showFullName?: boolean;
  showOrg?: boolean;
  hideProfilePicture?: boolean;
  disableNavigate?: boolean;
}
const SmallMarketerPreview: React.FC<Props> = ({
  user,
  showFullName = false,
  showOrg = false,
  hideProfilePicture = false,
  disableNavigate = false,
}) => {
  const { lastVisitedOrg: lastVisitedOrgId } = useCurrentUser();
  const { organization } = useOrganizations(lastVisitedOrgId);

  const nameDisplay = showFullName ? `${user.firstName} ${user.lastName}` : user.firstName;

  return (
    <Flex className="marketer-preview" css={smallMarketerPreviewStyles}>
      {user.profilePic && !hideProfilePicture && (
        <ProfilePicture user={user} size="xs" disableNavigate={disableNavigate} />
      )}
      <Flex className="marketer-preview__text-wrapper">
        {showOrg && (
          <Text className={cs('marketer-preview__org', 'endWithEllipsis')} variant={TEXT_VARIANTS.CATEGORY}>
            {organization.name}
          </Text>
        )}
        <Text
          variant={showOrg ? TEXT_VARIANTS.SUBHEADING : TEXT_VARIANTS.BODY}
          className={cs('endWithEllipsis', 'marketer-preview__name')}
        >
          {user.firstName !== '' ? nameDisplay : '-'}
        </Text>
      </Flex>
    </Flex>
  );
};

export default SmallMarketerPreview;

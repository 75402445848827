import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.creator-portfolio': {
    '&__wrapper': {
      maxHeight: '20rem',
      overflowY: 'auto',
    },
    '&__placeholder': {
      fontStyle: 'italic',
    },
    '&__header-right-wrapper': {
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    '&__header-text-wrapper': {
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    '&__header-text': {
      margin: `0 ${theme.space[2]} 0 0`,
    },
    '&__header-secondary-text': {
      margin: 0,
      color: COLORS.NEUTRAL500,
    },
  },
});

import React, { useState } from 'react';
import ReactTable from 'react-table';
import { Image } from 'rebass';

import styles, { CLASSNAMES, MAIN_CLASS } from 'features/performance/components/organisms/PerformanceTable/styles';
import { Box } from 'shared/components/display';
import { CarouselItem } from 'shared/components/molecules/CarouselItem';
import { Lightbox } from 'shared/components/organisms/Lightbox';

interface Props {
  topCreatives: any[];
  noDataText?: string;
  loading: boolean;
}

export const TopCreativeTable: React.FC<Props> = React.forwardRef(({ topCreatives, noDataText = '', loading }) => {
  const [lightbox, setLightbox] = useState<{ open: boolean; index: number }>({
    open: false,
    index: 0,
  });

  return (
    <>
      <Box className={MAIN_CLASS} css={styles}>
        <ReactTable
          className={CLASSNAMES.table}
          showPagination={false}
          loading={loading}
          noDataText={noDataText}
          getNoDataProps={() => {
            return { style: { height: '100%' } };
          }}
          minRows={0}
          getTrProps={(_, rowInfo) => {
            return {
              onClick: (e) => {
                setLightbox({ index: rowInfo.index, open: true });
              },
            };
          }}
          columns={[
            {
              Header: 'Number',
              minWidth: 10,
              style: {
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
              Cell: (tableProps) => tableProps.index + 1,
              headerStyle: {
                display: 'none',
              },
            },
            {
              Header: 'Thumbnail',
              minWidth: 15,
              Cell: (tableProps) => {
                return (
                  <Image
                    src={tableProps.original.thumbnailUrl}
                    width={80}
                    alt={`thumbnail_${tableProps.original.id}`}
                  />
                );
              },
              headerStyle: {
                display: 'none',
              },
            },
            {
              Header: 'Name',
              accessor: 'name',
              style: {
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
              },
              headerStyle: {
                display: 'none',
              },
            },
          ]}
          data={topCreatives}
        />
      </Box>
      <Lightbox
        ariaLabel="Media Preview"
        isOpen={lightbox.open}
        selectedItemIndex={lightbox.index}
        onRequestClose={() => {
          setLightbox((prev) => ({
            ...prev,
            open: false,
          }));
        }}
      >
        {topCreatives.map((file) => (
          <CarouselItem thumbnailUrl={file.thumbnailUrl} mimeType={'video'} url={file.url} key={file.url} />
        ))}
      </Lightbox>
    </>
  );
});

/* eslint-disable no-nested-ternary */
import React, { Fragment, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Button } from 'rebass/styled-components';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import OrgWidgets from 'features/organizations/components/organisms/OrgWidgets';
import orgHomeStyles from 'features/organizations/pages/OrgHome/orgHomeStyles';
import { useOrganizations } from 'features/organizations/useOrganizations';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import { Box, Text } from 'shared/components/display';

import { ACCOUNT_COPY } from 'shared/config/copy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { usePermissions } from 'shared/hooks/usePermissions';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LinkCreator } from 'shared/utilities/linkUtility';

const OrgHome = () => {
  const params = useParams<{ organizationId: string }>();
  const navigate = useNavigate();

  const organizationId = +params.organizationId;

  const { accounts, requestSnippets, loading: accountLoading } = useAccounts();
  const { isAuthorized } = usePermissions();
  const canAddAccount = isAuthorized(organizationId, PERMISSIONS_MAP.ADD_ACCOUNT.API);

  const { loading: orgLoading } = useOrganizations(organizationId);

  useEffect(() => {
    if (!orgLoading && !accountLoading) {
      accounts.forEach((account) => {
        requestSnippets(account);
      });
    }
  }, [orgLoading, accountLoading]);

  if (orgLoading || accountLoading) {
    return <CenteredSpinnerContainer />;
  }

  if (accounts.length === 1 && accounts[0].organizationId === organizationId) {
    // redirect to account page if the org is loaded & has only one account
    return (
      <Navigate
        to={LinkCreator.createLink({
          routeKey: 'ACCOUNT',
          variables: {
            organizationId,
            accountId: accounts[0].id,
          },
        })}
      />
    );
  }

  if (accounts.length === 0) {
    // show link to new account form if the org is loaded & has no accounts
    return (
      <Box className={`${CLASSNAMES.MAIN_CONTAINER} org-home--no-accounts`} css={orgHomeStyles}>
        {canAddAccount ? (
          <Fragment>
            <Text variant={TEXT_VARIANTS.H5}>{ACCOUNT_COPY.PLACEHOLDER_CREATE_ACCOUNT}</Text>
            <Button
              onClick={() =>
                navigate(
                  LinkCreator.createLink({
                    routeKey: 'CREATE_ACCOUNT',
                    variables: {
                      organizationId,
                    },
                  }),
                )
              }
              className="org-home__create-btn"
            >
              {ACCOUNT_COPY.BUTTON_CREATE_ACCOUNT}
            </Button>
          </Fragment>
        ) : (
          <Text variant={TEXT_VARIANTS.H5}>{ACCOUNT_COPY.PLACEHOLDER_NO_ACCOUNTS}</Text>
        )}
      </Box>
    );
  }
  // show org widgets if the org is loaded & has more than one account
  return <OrgWidgets organizationId={organizationId} />;
};

export default OrgHome;

import React, { useState, useMemo } from 'react';
import Modal from 'shared/components/molecules/Modal';
import ReportForm from 'features/accounts/components/molecules/ReportForm/ReportForm';
import { useReport } from 'features/accounts/reports/useReport';
import { usePerformance } from 'shared/hooks/usePerformance';
import { REPORT_COPY } from 'shared/config/copy';
import { Button } from 'rebass/styled-components';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons';
import { ReportValues } from 'features/accounts/components/molecules/ReportForm/config';
import COLORS from 'shared/styles/colors';

interface Props {
  reportId: number;
  accountId: number;
  onClick?: () => void;
}
const UpdateReportModalForm: React.FC<Props> = ({ reportId, accountId, onClick }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { report, updateReport } = useReport({
    reportId,
  });
  const {
    performanceState: { params },
  } = usePerformance();

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const onSubmit = (data: ReportValues) => {
    const { name, interval, intervalValue } = data;
    updateReport({
      name,
      interval: `${intervalValue} ${interval.value}`,
      params,
      accountId,
    });
    toggleModal();
  };

  const handleClick = () => {
    toggleModal();
    onClick && onClick();
  };

  const initialValues = useMemo(() => {
    const [intervalValue, interval] = report.jobInterval.split(' ');

    return {
      name: report.name,
      interval: { label: interval, value: interval },
      intervalValue,
    };
  }, [report]);

  return (
    <>
      <Button variant={BUTTON_VARIANTS.TEXT_ONLY} onClick={handleClick}>
        <FontAwesomeIcon icon={faPencilAlt} color={COLORS.PRIMARY500} />
      </Button>
      <Modal
        title={REPORT_COPY.FORM_HEADER_UPDATE_REPORT}
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        component={<ReportForm onSubmit={onSubmit} initialValues={initialValues} />}
      />
    </>
  );
};

export default UpdateReportModalForm;

import React, { useMemo } from 'react';
import cs from 'classnames';
import { Field, Form, Formik } from 'formik';
import TextInput from 'shared/components/TextInput';
import { makeLabel } from 'shared/lib/formik';
import FormButton from 'shared/components/FormButton';
import { GENERIC_COPY } from 'shared/config/copy';
import { BrkfstUser } from 'shared/typings/user';
import { useUser } from 'features/users/hooks/useUser';
import { marketerInfoSchema } from 'features/users/components/organisms/MarketerInfoForm/config';
import { Box } from 'shared/components/display';
import marketerInfoFormStyles from 'features/users/components/organisms/MarketerInfoForm/marketerInfoFormStyles';
import { USER_TOASTS } from 'shared/config/toasts/userToasts';

// fieldnames
const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const PRIMARY_EMAIL = 'email';
const SECONDARY_EMAIL = 'secondaryEmail';

interface Props {
  user: BrkfstUser;
  toggleEdit?: () => void;
}
const MarketerInfoForm: React.FC<Props> = ({ user, toggleEdit }) => {
  const { updateUser } = useUser(user.id);

  const onSubmit = (data) => {
    updateUser({
      data,
      successToast: {
        message: USER_TOASTS.UPDATED_USER_FIELD,
      },
    });
    if (toggleEdit) toggleEdit();
  };

  const initialValues = useMemo(() => {
    return {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      email: user.email || '',
      secondaryEmail: user.secondaryEmail || '',
    };
  }, [user]);

  return (
    <Formik initialValues={initialValues} validationSchema={marketerInfoSchema} onSubmit={onSubmit}>
      <Box css={marketerInfoFormStyles} className="marketer-info-form">
        <Form>
          <Field
            name={FIRST_NAME}
            component={TextInput}
            label={makeLabel(FIRST_NAME, true)}
            placeholder={makeLabel(FIRST_NAME, true)}
            dataCy="marketer-info-form__first-name"
          />
          <Field
            name={LAST_NAME}
            component={TextInput}
            label={makeLabel(LAST_NAME, true)}
            placeholder={makeLabel(LAST_NAME, true)}
            dataCy="marketer-info-form__last-name"
          />
          <Field
            name={PRIMARY_EMAIL}
            component={TextInput}
            label={makeLabel(PRIMARY_EMAIL, true)}
            placeholder={makeLabel(PRIMARY_EMAIL, true)}
            dataCy="marketer-info-form__email"
            disabled
          />
          <Field
            name={SECONDARY_EMAIL}
            component={TextInput}
            label={makeLabel(SECONDARY_EMAIL)}
            placeholder={makeLabel(SECONDARY_EMAIL)}
            dataCy="marketer-info-form__secondary-email"
          />
          <FormButton className="marketer-info-form__submit-btn">{GENERIC_COPY.BUTTON_SUBMIT}</FormButton>
        </Form>
      </Box>
    </Formik>
  );
};

export default MarketerInfoForm;

import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '.connect-instagram': {
    '&__buttons': {
      marginTop: theme.space[3],
      flexDirection: 'column',

      [mediaquery.md]: {
        flexDirection: 'row',
      },
    },
    '&__button': {
      marginBottom: theme.space[4],
      width: '120px',
      [mediaquery.md]: {
        marginRight: theme.space[4],
      },
    },
    '&__select-wrapper': {
      alignItems: 'center',
    },
    '&__loader': {
      marginTop: theme.space[4],
    },
  },
});

const HISTORY_PUSHED = 'HISTORY_PUSHED';

export function historyPushed(payload) {
  return {
    type: HISTORY_PUSHED,
    payload,
  };
}

export const routingMiddleware = () => (next) => (action) => {
  next(action);
  if (action.type === HISTORY_PUSHED) {
    const { navigate, pushFunction, data } = action.payload;
    // pushFunction should be a function that takes the api response and returns
    // whatever needs to be passed to history.push
    const newPath = pushFunction(data);

    if (newPath) navigate(newPath.pathname || newPath, { state: newPath?.state });
  }
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import cs from 'classnames';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Flex, Text } from 'shared/components/display';
import widgetPanelStyles, { Panel } from 'shared/components/molecules/WidgetPanel/widgetPanelStyles';

import { TEXT_VARIANTS } from 'shared/styles/text';

interface Props {
  title?: string;
  loading?: boolean;
  link?: string;
  HeaderButton?: React.ReactNode;
  isHidden?: boolean;
  //   provided by WidgetWrapper
  isDraggable?: boolean;
  children: JSX.Element | Array<JSX.Element>;
  overflow?: string;
  zIndex?: number;
}

const WidgetPanel: React.FC<Props> = React.forwardRef<HTMLDivElement, Props>(
  ({ title, link, loading, children, HeaderButton, isHidden, overflow, ...props }, ref) => {
    const navigate = useNavigate();

    const onLinkClick = () => {
      if (link) navigate(link);
    };

    return (
      <Panel
        className="widget-panel"
        {...props}
        ref={ref}
        // TODO: Fix Types
        // @ts-ignore
        isHidden={isHidden}
        overflow={overflow}
      >
        <Flex
          css={widgetPanelStyles}
          className={cs('widget-panel__title', {
            'widget-panel__title--with-link': !!link,
          })}
          onClick={onLinkClick}
        >
          {title ? <Text variant={TEXT_VARIANTS.H5}>{title}</Text> : null}
          {HeaderButton}
        </Flex>
        {loading ? <CenteredSpinner /> : children}
      </Panel>
    );
  },
);

export default WidgetPanel;

export const MEDIA = {
  FILE_TYPES: {
    GIF: 'gif',
    PNG: 'png',
    BLOB: 'blob',
    CSV: 'csv',
    MOV: 'mov',
    MP4: 'mp4',
  },
  TYPES: {
    IMAGE: 'image',
    VIDEO: 'video',
    GIF: 'gif',
    FILE: 'file',
    APPLICATION_FILE: 'application',
  },
  IMAGEMOSH: { MAX_ALLOWED_IMAGES: 7 },
  MIME_TYPES: {
    IMAGE_PREFIX: 'image/',
    JPEG: 'image/jpeg',
    APNG: 'image/apng',
    PNG: 'image/png',
    WEBP: 'image/webp',
    TIFF: 'image/tiff',
    OCTET_STREAM: 'image/octet-stream',
    GIF: 'image/gif',
    MP4: 'video/mp4',
    MOV: 'video/quicktime',
    M4V: 'video/x-m4v',
    CSV: 'text/csv',
    PDF: 'application/pdf',
    EPS: ['application/postscript', 'application/eps', 'application/x-eps', 'image/eps', 'image/x-eps'],
    SVG: 'image/svg+xml',
    TTF: 'font/ttf',
    AI: 'application/postscript',
    BMP: 'image/bmp',
    DIB: 'image/dib',
    HEIC: 'image/heic',
    IFF: 'image/iff',
    JP2: 'image/x-jp2',
    WBMP: 'image/vnd.wap.wbmp',
    XBM: 'image/x-xbitmap',
    PSD: [
      'image/vnd.adobe.photoshop',
      'application/x-photoshop',
      'application/photoshop',
      'application/psd',
      'image/psd',
    ],
  },
  EXTENSIONS: {
    JPG: '.jpg',
    JPEG: '.jpeg',
    CSV: '.csv',
  },

  MAX_PINNED_FILES: 10,
};

export const ASPECT_RATIO_TOLERANCE = 0.01;

import { css } from '@emotion/react';

export default css({
  '&.select-option': {
    '&__content': {
      whiteSpace: 'nowrap',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

import React, { useEffect } from 'react';
import { Button } from 'rebass/styled-components';
import { faArrowAltCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GalleryItem } from 'features/media/interfaces/gallery';
import { useFiles } from 'features/media/useFiles';
import { Box, Flex, Text } from 'shared/components/display';
import DropBox from 'shared/components/DropBox/DropBox';
import MediaUploadButton from 'shared/components/molecules/MediaUploadButton';
import ToggleGalleryListView from 'shared/components/molecules/ToggleGalleryListView';
import MediaGalleryAndListDisplay from 'shared/components/organisms/MediaGalleryAndListDisplay';
import Pagination from 'shared/components/organisms/Pagination';

import { COLLECTION_COPY } from 'shared/config/copy';
import { useQuery } from 'shared/hooks/useQuery';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import { intl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstFile } from 'shared/typings/file';

import styles from './styles';

interface Props {
  uploadNewFiles: Function;
  maxNumFiles?: number;
  fileType: 'video' | 'gif' | 'image' | 'all';
  acceptedMedia?: readonly string[];
  collectionId: number;
  clearFileState?: () => void;
  formatGalleryItems: (files: BrkfstFile) => GalleryItem;
}
const FileExplorer: React.FC<Props> = ({
  uploadNewFiles,
  maxNumFiles = Infinity,
  fileType,
  acceptedMedia = [],
  collectionId,
  clearFileState,
  formatGalleryItems,
}) => {
  const { getQuery, setQuery } = useQuery();
  const query = getQuery();
  const fileParams = useSearchParams(query, {
    size: 10,
    page: 1,
    orderBy: 'dateCreated',
    orderAsc: false,
  });

  const {
    collectionFiles,
    totalFiles,
    selectedFiles,
    selectedCollection,
    selectFiles,
    newFilesLoading,
    changeSelectedCollection,
    getCollectionFilteredFiles,
    loading: filesLoading,
  } = useFiles({ params: fileParams });

  const onBackToCollections = () => {
    changeSelectedCollection(null);
  };

  useEffect(() => {
    if (fileType) {
      getCollectionFilteredFiles(collectionId, {
        ...fileParams,
        fileType: fileType,
      });
    }
  }, [collectionId, fileParams, fileType]);

  useEffect(() => {
    return () => {
      if (clearFileState) clearFileState();
      setQuery({
        page: 1,
        size: fileParams.size,
      });
    };
  }, []);

  return (
    <Box css={styles}>
      <DropBox onFileDrop={(files) => uploadNewFiles(files)} className="file-explorer">
        <Button
          type="button"
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          className="file-explorer__collection-btn"
          onClick={onBackToCollections}
        >
          <FontAwesomeIcon icon={faArrowAltCircleLeft} size="xs" />
          &nbsp;Back
        </Button>
        <Flex className="file-explorer__control-header">
          <Flex className="file-explorer__controls-left">
            <Text variant={TEXT_VARIANTS.H4} as="h4" className="file-explorer__collection-name">
              {selectedCollection?.name}
            </Text>
            <MediaUploadButton
              buttonContent={intl.formatMessage(
                {
                  id: 'BUTTON_UPLOAD_COLLECTION_ASSET',
                },
                {
                  media: fileType,
                },
              )}
              onFileUpload={(files) => uploadNewFiles(files)}
              acceptedMedia={acceptedMedia}
              maxNumFiles={maxNumFiles}
              multipleFiles
              disabled={filesLoading}
            />
          </Flex>
          <ToggleGalleryListView />
        </Flex>

        <Pagination totalAmount={totalFiles} searchParams={fileParams}>
          <MediaGalleryAndListDisplay
            data={collectionFiles}
            placeholderText={COLLECTION_COPY.PLACEHOLDER_ASSETS}
            selectedFileIds={selectedFiles.map(({ id }) => id)}
            updateSelectedItems={selectFiles}
            formatGalleryItems={formatGalleryItems}
            numLoadingItems={newFilesLoading}
            loading={filesLoading}
            isSelectable
          />
        </Pagination>
      </DropBox>
    </Box>
  );
};

export default FileExplorer;

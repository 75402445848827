import React, { useMemo } from 'react';
import cs from 'classnames';
import { useQuery } from 'shared/hooks/useQuery';
import { Select, SelectValue } from 'shared/components/molecules/Select';
import { ORDER_MAP, DELIMINATOR, SortOptions, SingleOptions } from './config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import COLORS from 'shared/styles/colors';
import { faArrowDownWideShort, faArrowUpWideShort } from '@fortawesome/pro-light-svg-icons';

interface Props<S = string> {
  className?: string;
  orderAscParamName?: string;
  orderByParamName?: string;
  pageParamsName?: string;
  searchParams?: any;
  sortOptions?: SortOptions<S>;
  singleOptions?: SingleOptions<S>
  width?: string;
  showSortIcon?: boolean;
  onChange: (option: SelectValue) => void;
}

export const PaginationSort: React.FC<Props> = ({
  className,
  onChange,
  orderAscParamName = 'orderAsc',
  orderByParamName = 'orderBy',
  pageParamsName = 'page',
  searchParams = {},
  showSortIcon = false,
  sortOptions = [],
  singleOptions = [],
  width = '200px',
}) => {
  const { stringifyQuery } = useQuery();

  const formattedSortOptions: (SelectValue & { orderAsc: boolean })[] = useMemo(() => {
    // Create ascending and descending order by sort options
    const newOptions: (SelectValue & { orderAsc: boolean })[] = [];
    sortOptions.forEach(({ sortType, label, value }) => {
      const orderSuffixes = ORDER_MAP[sortType];
      newOptions.push({
        label: `${label}${DELIMINATOR}${orderSuffixes.ascending}`,
        value: stringifyQuery({
          [orderByParamName]: value,
          [orderAscParamName]: true,
          [pageParamsName]: 1,
        }),
        orderAsc: true,
      });
      newOptions.push({
        label: `${label}${DELIMINATOR}${orderSuffixes.descending}`,
        value: stringifyQuery({
          [orderByParamName]: value,
          [orderAscParamName]: false,
          [pageParamsName]: 1,
        }),
        orderAsc: false,
      });
    });

    singleOptions.forEach(({ orderAsc, label, value }) =>{
      newOptions.push({
        label: label,
        value: stringifyQuery({
          [orderByParamName]: value,
          [orderAscParamName]: orderAsc,
          [pageParamsName]: 1,
        }),
        orderAsc: orderAsc,
      });
    });

    return newOptions;
  }, [sortOptions, singleOptions]);

  const selectedSortValue = useMemo(() => {
    const sortValue = stringifyQuery({
      [orderByParamName]: searchParams[orderByParamName],
      [orderAscParamName]: searchParams[orderAscParamName],
      [pageParamsName]: 1,
    });

    return formattedSortOptions.find(({ value }) => value === sortValue) || formattedSortOptions[0];
  }, [searchParams, orderByParamName, orderAscParamName, formattedSortOptions, pageParamsName]);

  return (
    <Select
      name="orderBy"
      options={formattedSortOptions}
      onChange={onChange}
      value={selectedSortValue}
      className={cs('pagination-sort', className)}
      width={width}
      menuPortalTarget="body"
      ControlPrefix={
        showSortIcon ? (
          <FontAwesomeIcon
            icon={selectedSortValue.orderAsc ? faArrowUpWideShort : faArrowDownWideShort}
            color={COLORS.PRIMARY500}
          />
        ) : undefined
      }
      hidePlaceholder
      isSearchable
      filterByLabel
    />
  );
};

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.billing': {
    margin: theme.space[4],
  },
  '.billing': {
    '&__link': {
      color: theme.colors.PRIMARY500,
    },
    '&__heading': {
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: theme.space[7],
    },
    '&__pagination': {
      justifyContent: 'space-around',
      marginTop: theme.space[4],
    },
  },
});

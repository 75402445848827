import { camelCase } from 'lodash';
import cs from 'classnames';
import React from 'react';
import { Button, Text } from 'rebass/styled-components';
import { Flex } from 'shared/components/display';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';

const { TEXT_ONLY } = BUTTON_VARIANTS;
const { H6, BODY } = TEXT_VARIANTS;

const SelectionItem: React.FC<{
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  selected: boolean;
  heading: string;
  subHeading: string;
  type?: string | number;
  icon: JSX.Element;
}> = ({ onClick, selected, heading, subHeading, type, icon }) => (
  <Button
    variant={TEXT_ONLY}
    onClick={onClick}
    value={type}
    id={camelCase(heading)}
    className={cs('selection-button', { 'selection-button--selected': selected })}
  >
    <Flex className={`selection-item ${selected ? 'selection-item--selected' : ''}`}>
      <Flex className={`icon-container ${selected ? 'icon-container--selected' : ''}`}>{icon}</Flex>
      <Flex className="item-content">
        <Text variant={H6}>{heading}</Text>
        <Text variant={BODY}>{subHeading}</Text>
      </Flex>
    </Flex>
  </Button>
);

export default SelectionItem;

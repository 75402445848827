import { differenceInMonths } from 'date-fns';
import { capitalize, unionBy } from 'lodash';

import { formatDateWithSlashes } from 'shared/utilities/dateUtility';
import { CHILD_TAGS, formatInitialModelValues, TEENAGER_TAGS } from 'shared/utilities/tagUtility';

import {
  formatInitialWorkPrefValues,
  LOCATION_FIELDNAMES,
  MODEL_AVAILABILITY_FIELDNAMES,
  WORK_PREFERENCES_FIELDNAMES,
} from '../../CreatorOnboardingStepsFields';

export const FIELDNAMES: Record<string, WorkInfoSections> = {
  WORK_PREFERENCES: 'workPreferences',
  MODELS: 'model',
  LOCATIONS: 'filmingBackdrop',
};

export type WorkInfoSections = 'workPreferences' | 'model' | 'filmingBackdrop';

export const getAge = (birthdate: string) => {
  return Math.floor(differenceInMonths(new Date(), new Date(birthdate)) / 12);
};

export const isChild = (birthdate: string) => {
  const ageInMonths = differenceInMonths(new Date(), new Date(birthdate));
  return ageInMonths < 12 * 13;
};
export const isTeenager = (birthdate: string) => {
  const ageInMonths = differenceInMonths(new Date(), new Date(birthdate));
  return ageInMonths >= 13 * 12 && ageInMonths <= 18 * 12;
};

export const formatDependentDateOfBirth = (birthdate: string) => {
  const [month, , year] = formatDateWithSlashes(birthdate).split('/');
  return `${month}/${year}`;
};

export const getInitialValues = (creatorUser, tags) => {
  const workType = unionBy(
    creatorUser.creatorTags
      ?.filter((tags) => tags.category === 'work preference')
      .map((tag) => ({ label: capitalize(tag.value), value: tag.value, selected: true })),
    tags['work preference'],
    'value',
  );
  const editing = unionBy(
    creatorUser.creatorTags
      ?.filter((tags) => tags.category === 'editing')
      .map((tag) => ({ label: capitalize(tag.value), value: tag.value, selected: true })),
    tags['editing'],
    'value',
  );

  const selectedModels = creatorUser.creatorTags
    ?.filter((tags) => tags.category === 'model')
    .map((tag, ind) => ({ label: capitalize(tag.value), value: tag.value, key: ind }));
  const selectedChildren = creatorUser.dependents?.some((dependent) => isChild(dependent.birthdate));
  const selectedTeenagers = creatorUser.dependents?.some((dependent) => isTeenager(dependent.birthdate));

  const location = unionBy(
    creatorUser.creatorTags
      ?.filter((tags) => tags.category === 'filming backdrop')
      .map((tag) => ({ label: capitalize(tag.value), value: tag.value, selected: true })),
    tags['filming backdrop'],
    'value',
  );

  return {
    ...formatInitialWorkPrefValues({
      workType: workType?.length > 0 ? workType : tags['work preference'],
      editing: editing?.length > 0 ? editing : tags['editing'],
    }),
    [MODEL_AVAILABILITY_FIELDNAMES.MODELS]: formatInitialModelValues([...tags['model']]).models.map((model) => {
      if (CHILD_TAGS.includes(model.value)) {
        return { ...model, selected: selectedChildren };
      } else if (TEENAGER_TAGS.includes(model.value)) {
        return { ...model, selected: selectedTeenagers };
      }
      const selectedModel = selectedModels.find(({ value }) => value === model.value);
      return selectedModel ? { ...model, selected: true } : model;
    }),
    [MODEL_AVAILABILITY_FIELDNAMES.CHILDREN]:
      creatorUser.dependents
        ?.filter((dependent) => isChild(dependent.birthdate))
        .map(({ birthdate }) => ({
          dob: formatDependentDateOfBirth(birthdate),
          age: getAge(birthdate),
        })) || [],
    [MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS]: creatorUser?.dependents
      ?.filter((dependent) => isTeenager(dependent.birthdate))
      ?.map(({ birthdate }) => ({
        dob: formatDependentDateOfBirth(birthdate),
        age: getAge(birthdate),
      })),
    [MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS]: creatorUser?.dependents
      ?.filter((dependent) => !isChild(dependent.birthdate))
      ?.map(({ birthdate }) => ({
        dob: formatDependentDateOfBirth(birthdate),
        age: getAge(birthdate),
      })),
    [LOCATION_FIELDNAMES.LOCATIONS]: location?.length > 0 ? location : [...tags['filming backdrop']],
  };
};

export const getFieldsToReset = (activeForm: WorkInfoSections | null, initialValues) => {
  switch (activeForm) {
    case 'workPreferences':
      return {
        [WORK_PREFERENCES_FIELDNAMES.EDITING]: initialValues[WORK_PREFERENCES_FIELDNAMES.EDITING],
        [WORK_PREFERENCES_FIELDNAMES.WORK_TYPE]: initialValues[WORK_PREFERENCES_FIELDNAMES.WORK_TYPE],
      };

    case 'model':
      return {
        [MODEL_AVAILABILITY_FIELDNAMES.MODELS]: initialValues[MODEL_AVAILABILITY_FIELDNAMES.MODELS],
        [MODEL_AVAILABILITY_FIELDNAMES.CHILDREN]: initialValues[MODEL_AVAILABILITY_FIELDNAMES.CHILDREN],
        [MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS]: initialValues[MODEL_AVAILABILITY_FIELDNAMES.TEENAGERS],
      };

    case 'filmingBackdrop':
      return {
        [LOCATION_FIELDNAMES.LOCATIONS]: initialValues[LOCATION_FIELDNAMES.LOCATIONS],
      };

    default:
      return {};
  }
};

export enum FilterType {
  ALL = 'all',
  PINNED = 'pinned',
}

export const OPTIONS = Object.freeze([
  { text: 'Pinned', value: FilterType.PINNED },
  {
    text: 'All',
    value: FilterType.ALL,
  },
]);

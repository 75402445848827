import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  '&.external-ad-health': {
    height: '100%',
    backgroundColor: COLORS.TRANSPARENT,
    border: 'none',
    padding: '1rem 0.3rem 1rem 0',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '0 auto',
    gridGap: '0',
  },
  '.external-ad-health': {
    '&__content': {
      alignSelf: 'start',
      gridRow: '2',
    },
  },
});

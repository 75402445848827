import { apiAction } from 'shared/actions/api';
import { Box, Flex, Text } from 'shared/components/display';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_FACEBOOK_ROUTES } from 'shared/config/routes/api/apiFacebookRoutes';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { Field, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SingleValue } from 'react-select';
import COLORS from 'shared/styles/colors';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { TEXT_VARIANTS } from 'shared/styles/text';
import ToggleInput from 'shared/components/ToggleInput';
import { SelectInput } from 'shared/components/molecules/Select';
import { Value as SelectValue } from 'shared/components/molecules/Select/SelectInput';
import { MESSAGES } from 'shared/config/validations';
import * as yup from 'yup';
import { ACCOUNT_COPY } from 'shared/config/copy';
import FacebookConnectInput from 'features/accountPlatforms/facebook/FacebookConnect/FacebookConnectInput';
import { startCase } from 'lodash';
import { FacebookOnboardingValues } from 'features/organizations/components/organisms/OrgOnboardingStepFields/types';
import { TailSpin } from 'react-loader-spinner';
import facebookStepStyles from 'features/organizations/components/organisms/OrgOnboardingStepFields/facebookStepStyles';
import cs from 'classnames';

interface State {
  fbPage: { id: string; name: string }[];
  fbPixel: { id: string; name: string }[];
  fbAdAccount: { account_id: string; name: string }[];
  igAccount: { id: string; username: string }[];
  productCatalog: { id: string; name: string }[];
}

const initialState: State = {
  fbPage: [],
  fbPixel: [],
  fbAdAccount: [],
  igAccount: [],
  productCatalog: [],
};

export const FacebookOnboardingStepFields: React.FC = () => {
  const { loading: fbaLoading } = useComponentLoading(RESOURCES.FACEBOOK_BUSINESS_ASSET, false);
  const { loading: igLoading } = useComponentLoading(RESOURCES.INSTAGRAM, false);
  const { values, initialValues, setFieldValue, errors, touched } = useFormikContext<FacebookOnboardingValues>();
  const [state, setState] = useState<State>(initialState);

  const dispatch = useDispatch();

  const isConnectingAdAccount = !values.shouldCreateAdAccount;
  const fbAccessToken = values.connectFacebook.token || initialValues.connectFacebook.token;

  const queryAssets = useCallback(
    (business: SingleValue<SelectValue>) => {
      if (business)
        dispatch(
          apiAction({
            path: {
              route: `${API_FACEBOOK_ROUTES.BUSINESS_ASSETS}/${business.value}`,
            },
            params: {
              token: fbAccessToken,
            },
            entity: RESOURCES.FACEBOOK_BUSINESS_ASSET,
            onSuccess: ({ data }) => {
              setFieldValue(FACEBOOK_FIELDNAMES.FB_AD_ACCOUNT, null);
              setFieldValue(FACEBOOK_FIELDNAMES.FB_PAGE, null);
              setFieldValue(FACEBOOK_FIELDNAMES.FB_PIXEL, null);
              setFieldValue(FACEBOOK_FIELDNAMES.IG_ACCOUNT, null);
              setFieldValue(FACEBOOK_FIELDNAMES.PRODUCT_CATALOG, null);
              setState((prev) => ({
                ...prev,
                fbPage: data.pages,
                fbPixel: data.pixels,
                fbAdAccount: data.adAccounts,
                productCatalog: data.productCatalogs,
              }));
            },
          }),
        );
    },
    [fbAccessToken],
  );

  const queryInstagramAccounts = (page: SingleValue<SelectValue>) => {
    if (page)
      dispatch(
        apiAction({
          path: {
            route: `${API_FACEBOOK_ROUTES.BUSINESS_ASSETS}/page/${page.value}/instagram-accounts`,
          },
          entity: RESOURCES.INSTAGRAM,
          onSuccess: ({ data }) => {
            setFieldValue(FACEBOOK_FIELDNAMES.IG_ACCOUNT, null);
            setState((prev) => ({
              ...prev,
              igAccount: data,
            }));
          },
        }),
      );
  };

  const { facebookBusiness, fbPage } = initialValues;
  // Loads the assets in when a business id is present in the `initialValues`
  useEffect(() => {
    if (facebookBusiness) queryAssets(facebookBusiness);
  }, [facebookBusiness?.value, queryAssets]);

  useEffect(() => {
    if (fbPage) queryInstagramAccounts(fbPage);
  }, [fbPage?.value]);

  const { showPageErrors, showPixelErrors, showAdAccountWarnings } = useMemo(() => {
    const fbBusinessSelected = Boolean(values.facebookBusiness?.value);

    return {
      showPageErrors: fbBusinessSelected && state.fbPage.length < 1 && !fbaLoading,
      showPixelErrors: fbBusinessSelected && state.fbPixel.length < 1 && !fbaLoading,
      showAdAccountWarnings: fbBusinessSelected && state.fbAdAccount.length < 1 && !fbaLoading,
    };
  }, [values, state, fbaLoading]);

  const { businesses } = values.connectFacebook;

  return (
    <Box className="facebook-step" css={facebookStepStyles}>
      <Field
        name={FACEBOOK_FIELDNAMES.CONNECT_FACEBOOK}
        component={FacebookConnectInput}
        redirectURI={LinkCreator.createLink({
          routeKey: 'ORGANIZATION_ONBOARDING',
        })}
      />
      {businesses?.length > 0 && (
        <Flex className={cs('facebook-step__input-wrapper', 'facebook-step__loading-wrapper')}>
          <Field
            name={FACEBOOK_FIELDNAMES.FBBUSINESS}
            component={SelectInput}
            placeholder={`${startCase(FACEBOOK_FIELDNAMES.FBBUSINESS)}`}
            label={`${startCase(FACEBOOK_FIELDNAMES.FBBUSINESS)}`}
            required
            options={businesses.map((business) => ({
              value: business.id,
              label: business.name,
            }))}
            onChange={(value) => {
              queryAssets(value);
            }}
            width="222px"
            isSearchable
            filterByLabel
          />
          <TailSpin
            visible={!!fbaLoading}
            color={COLORS.PRIMARY500}
            height="35px"
            width="35px"
            wrapperClass="facebook-step__loader"
          />
        </Flex>
      )}
      {state.fbPage.length > 0 && !fbaLoading && (
        <Flex className={cs('facebook-step__input-wrapper', 'facebook-step__loading-wrapper')}>
          <Field
            name={FACEBOOK_FIELDNAMES.FB_PAGE}
            component={SelectInput}
            placeholder={'Facebook Page'}
            label={'Facebook Page'}
            required={state.fbPage.length > 0}
            options={state.fbPage.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            onChange={(value) => {
              queryInstagramAccounts(value);
            }}
            width="222px"
            isSearchable
            filterByLabel
          />
          <TailSpin
            visible={!!igLoading}
            color={COLORS.PRIMARY500}
            height="35px"
            width="35px"
            wrapperClass="facebook-step__loader"
          />
        </Flex>
      )}
      {showPageErrors && (
        <Text variant={TEXT_VARIANTS.CAPTION} color={COLORS.RED700} className="facebook-step__error">
          No Facebook Pages are associated with the business.
        </Text>
      )}
      {state.igAccount.length > 0 && !(fbaLoading || igLoading) && (
        <Flex className={cs('facebook-step__input-wrapper')}>
          <Field
            name={FACEBOOK_FIELDNAMES.IG_ACCOUNT}
            component={SelectInput}
            placeholder={'Instagram Account'}
            label={'Instagram Account'}
            required={false}
            options={state.igAccount.map(({ id, username }) => ({
              value: id,
              label: username,
            }))}
            width="222px"
            isSearchable
            filterByLabel
          />
        </Flex>
      )}
      {state.fbPixel.length > 0 && !fbaLoading && (
        <Flex className={cs('facebook-step__input-wrapper')}>
          <Field
            name={FACEBOOK_FIELDNAMES.FB_PIXEL}
            component={SelectInput}
            placeholder="Facebook Pixel"
            label="Facebook Pixel"
            required={state.fbPixel.length > 0}
            options={state.fbPixel.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            width="222px"
            isSearchable
            filterByLabel
          />
        </Flex>
      )}
      {showPixelErrors && (
        <Text variant={TEXT_VARIANTS.CAPTION} color={COLORS.RED700} className="facebook-step__error">
          No Facebook Pixels are associated with the business.
        </Text>
      )}
      {state.productCatalog.length > 0 && !fbaLoading && (
        <Flex className={cs('facebook-step__input-wrapper')}>
          <Field
            name={FACEBOOK_FIELDNAMES.PRODUCT_CATALOG}
            component={SelectInput}
            placeholder="Product Catalog"
            label="Product Catalog"
            required={false}
            options={state.productCatalog.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
            width="222px"
            isSearchable
            filterByLabel
          />
        </Flex>
      )}
      {businesses?.length > 0 && !fbaLoading && (
        <Flex className={cs('facebook-step__checkbox-wrapper')}>
          <Field
            name={FACEBOOK_FIELDNAMES.SHOULD_CREATE_AD_ACCOUNT}
            component={ToggleInput}
            label="Create Facebook Ad Account on my behalf"
            disabled={state.fbAdAccount.length < 1}
          />
        </Flex>
      )}
      {state.fbAdAccount.length > 0 && isConnectingAdAccount && !fbaLoading && (
        <Flex className={cs('facebook-step__input-wrapper')}>
          <Field
            name={FACEBOOK_FIELDNAMES.FB_AD_ACCOUNT}
            component={SelectInput}
            placeholder="Facebook Ad Account"
            label="Facebook Ad Account"
            required={state.fbAdAccount.length > 0}
            options={state.fbAdAccount.map(({ account_id, name }) => ({
              value: account_id,
              label: name,
            }))}
            width="222px"
            isSearchable
            filterByLabel
          />
        </Flex>
      )}
      {showAdAccountWarnings && (
        <Text variant={TEXT_VARIANTS.CAPTION} color={COLORS.ORANGE700} className="facebook-step__error">
          No Facebook Ad Accounts are associated with the business. An Ad Account will be created for your brand.
        </Text>
      )}
    </Box>
  );
};

export const FACEBOOK_FIELDNAMES = {
  CONNECT_FACEBOOK: 'connectFacebook',
  FB_AD_ACCOUNT: 'fbAdAccount',
  FB_PAGE: 'fbPage',
  FB_PIXEL: 'fbPixel',
  FBBUSINESS: 'facebookBusiness',
  IG_ACCOUNT: 'igAccount',
  SHOULD_CREATE_AD_ACCOUNT: 'shouldCreateAdAccount',
  PRODUCT_CATALOG: 'productCatalog',
};

export const facebookOnboardingInitialValues: FacebookOnboardingValues = {
  connectFacebook: {
    businesses: [],
    token: '',
  },
  facebookBusiness: null,
  fbAdAccount: null,
  fbPage: null,
  fbPixel: null,
  igAccount: null,
  shouldCreateAdAccount: false,
};

const selectSchema = yup
  .object({
    value: yup.string(),
    label: yup.string(),
  })
  .nullable();

const optionalSelectSchema = selectSchema.when(FACEBOOK_FIELDNAMES.FBBUSINESS, {
  is: (facebookBusiness) => Boolean(facebookBusiness?.value),
  then: selectSchema.required(MESSAGES.REQUIRED_FIELD),
});

// @ts-ignore
export const facebookOnboardingScheme: yup.SchemaOf<FacebookOnboardingValues> = yup.object().shape({
  connectFacebook: yup
    .object({
      businesses: yup.array().of(
        yup.object({
          id: yup.string(),
          name: yup.string(),
        }),
      ),
      token: yup.string().nullable(),
    })
    .test('fbConnected', ACCOUNT_COPY.ONBOARDING_FB_CONNECT_VALIDATION, ({ businesses }) => {
      return Boolean(businesses?.length);
    }),
  shouldCreateAdAccount: yup.boolean(),
  facebookBusiness: selectSchema.when(FACEBOOK_FIELDNAMES.CONNECT_FACEBOOK, {
    is: ({ businesses }) => Boolean(businesses.length),
    then: selectSchema.required(MESSAGES.REQUIRED_FIELD),
  }),
  fbPixel: optionalSelectSchema,
  fbPage: optionalSelectSchema,
  productCatalog: selectSchema,
  igAccount: selectSchema,
  fbAdAccount: selectSchema.when([FACEBOOK_FIELDNAMES.SHOULD_CREATE_AD_ACCOUNT, FACEBOOK_FIELDNAMES.FBBUSINESS], {
    is: (shouldCreateAdAccount, facebookBusiness) => {
      return shouldCreateAdAccount === false && Boolean(facebookBusiness?.value);
    },
    then: selectSchema.required(MESSAGES.REQUIRED_FIELD),
    otherwise: selectSchema,
  }),
});

import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import SHADOWS from 'shared/styles/shadow';
import theme from 'shared/styles/themes';

interface StyleArgs {
  width: number;
  height?: number;
  selectScale: string;
  selectBgScale: string;
  selectBorder: number;
  margin: number;
}

export default (props: StyleArgs) =>
  css({
    '&.gallery-tile': {
      flexDirection: 'column',
      position: 'relative',
      width: props.width,
      margin: props.margin,
    },
    '.gallery-tile': {
      '&__non-scalable-content': {
        position: 'absolute',
        zIndex: zIndex.zForeground,
        width: props.width,
        height: props.height || '100%',
        alignItems: 'start',
        padding: '0.5rem',
        transform: 'translate3d(0, 0, 1px)',
        justifyContent: 'space-between',
      },
      '&__overlay': {
        position: 'absolute',
        zIndex: zIndex.zBackdrop,
        width: props.width,
        height: props.height || '100%',
        padding: '0.5rem',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
      },
      '&__soft-curtain-content': {
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      '&__name': {
        zIndex: zIndex.zBackdrop,
        margin: `${theme.space[2]} 0`,
        textAlign: 'center',
      },
      '&__name--with-options': {
        zIndex: zIndex.zBackdrop,
        margin: `${theme.space[2]} 0`,
        textAlign: 'left',
      },
      '&__pin-btn': {
        position: 'absolute',
        left: '10px',
      },
    },
    '.gallery-tile__display': {
      position: 'relative',
      borderRadius: '4px',
      overflow: 'hidden',
      backgroundColor: COLORS.PRIMARY100,
      transition: 'width 0.25s ease-in-out, height 0.25s ease-in-out, background-color 0.15s ease-in',
      cursor: 'default',
      width: props.width,
      height: props.height || '100%',
      boxShadow: SHADOWS.SHADOW01,
      '& .gallery-tile__hover-btn': {
        visibility: 'hidden',
      },
      '&--selectable': {
        cursor: 'pointer',
      },
    },
    '.gallery-tile__display--selected': {
      '& .gallery-tile__scalable-content *': {
        transform: props.selectScale,
        '& *': {
          transform: props.selectBgScale,
        },
      },
      '& .gallery-tile__overlay-left': {
        transform: `translate3d(${props.selectBorder}px, -${props.selectBorder}px, 0)`,
      },
      '& .gallery-tile__overlay-right': {
        transform: `translate3d(-${props.selectBorder}px, -${props.selectBorder}px, 0)`,
      },
      '& .gallery-tile__select-btn': {
        opacity: 1,
        borderColor: COLORS.PRIMARY500,
        boxShadow: theme.shadows.SHADOW06,
      },
    },
    '.gallery-tile__display:hover': {
      backgroundColor: `${COLORS.PRIMARY200}ad`,
      '& .gallery-tile__overlay-left, & .gallery-tile__overlay-right': {
        transition: 'transform 0.135s ease-in-out',
        overflow: 'hidden',
      },
      '& .gallery-tile__select-btn svg': {
        color: COLORS.WHITE,
      },
      '& .gallery-tile__hover-btn, & .gallery-tile__select-btn': { visibility: 'visible' },
    },
    '.gallery-tile__display--selectable:hover': {
      '& .gallery-tile__overlay': {
        background: `linear-gradient(${COLORS.BLACK}75,10%, transparent);`,
      },
    },
    '.gallery-tile__display--selected:hover': {
      '& .gallery-tile__overlay': {
        background: 'transparent',
      },
    },
  });

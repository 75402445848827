import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import SHADOWS from 'shared/styles/shadow';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.module-comment:hover': {
    '.module-comment__action-wrapper': {
      visibility: 'visible',
    },
  },
  '.module-comment': {
    '&__text': {
      wordBreak: 'break-word',
    },
    '&__btn:first-of-type': {
      margin: `0 ${theme.space[4]}`,
    },
    '&__header': {
      position: 'relative',
      height: '30px',
      alignItems: 'center',
    },
    '&__action-wrapper': {
      position: 'absolute',
      top: 0,
      right: 0,
      fontWeight: 400,
      fontSize: '11px',
      justifyContent: 'flex-end',
      background: `linear-gradient(to left, ${COLORS.WHITE}, 95%, transparent);`,
      [mediaquery.md]: {
        visibility: 'hidden',
      },
    },
  },
});

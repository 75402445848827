export enum CREATOR_ONBOARDING_STEPS {
  PORTFOLIO = 1,
  ADDRESS = 2,
  DOB = 3,
  ETHNICITY = 4,
  GENDER = 5,
  HEIGHT = 6,
  LANGUAGE = 7,
  WORK_PREFERENCES = 8,
  MODEL = 9,
  LOCATION = 10,
  STRIPE = 11,
  NOTIFICATIONS = 12,
  REFERRAL = 13,
}

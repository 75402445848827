import { createSlice } from '@reduxjs/toolkit';
import type { Campaign } from './types';

const initialState: { data: Campaign[] } = {
  data: [],
};

const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    campaignsLoaded: (campaigns, action) => {
      campaigns.data = action.payload.data;
    },
    campaignsRemoved: (campaigns) => {
      campaigns.data = [];
    },
  },
});

export const {
  campaignsLoaded,
  campaignsRemoved,
} = campaignsSlice.actions;
export default campaignsSlice.reducer;

export const getCampaigns = (state) => state.campaigns.data;

export const getAdsets = (state) =>
  state.campaigns.data.map(({ adsets }) => adsets).flat();

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';
import { zIndex } from 'shared/config';
import SHADOWS from 'shared/styles/shadow';
import { STYLES } from 'shared/styles/styles';

export default css({
  '&.nav-bar': {
    position: 'sticky',
    top: 0,
    zIndex: zIndex.zNav,
    backgroundColor: 'white',
    height: '50px',
    justifyContent: 'space-between',
    padding: `${theme.space[4]} ${theme.space[5]}`,
    borderBottom: STYLES.BORDER_NEUTRAL,
    alignItems: 'center',
    '&--shadow': {
      boxShadow: SHADOWS.SHADOW03,
    },
    '&--right': {
      justifyContent: 'end',
    },
  },
});

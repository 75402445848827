import React from 'react';
import { Field, Form, Formik } from 'formik';

import accountLogoStyles from 'features/accounts/components/organisms/AccountLogoForm/accountLogoStyles';
import { accountLogoSchema, BRAND_LOGO } from 'features/accounts/components/organisms/AccountLogoForm/config';
import { Box } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import ImageFileInput, { DEFAULT_VALUE } from 'shared/components/molecules/ImageFileInput';

import { ACCOUNT_COPY } from 'shared/config/copy';
import { BRKFST_SUPPORTED_IMAGE_FORMATS } from 'shared/config/fileFormats';

interface Props {
  toggleEdit: () => void;
  onSubmit: (values: any) => void;
  formDisabled?: boolean;
}

const AccountLogoForm: React.FC<Props> = ({ toggleEdit, onSubmit, formDisabled = false }) => {
  const submitAndToggleEdit = (data) => {
    onSubmit(data);
    toggleEdit();
  };

  return (
    <Formik
      initialValues={{
        [BRAND_LOGO]: DEFAULT_VALUE,
      }}
      onSubmit={submitAndToggleEdit}
      validationSchema={accountLogoSchema}
      validateOnBlur={false}
    >
      {() => (
        <Form>
          <Box css={accountLogoStyles} className="account-logo-form">
            <Field name={BRAND_LOGO} component={ImageFileInput} acceptedFormats={BRKFST_SUPPORTED_IMAGE_FORMATS} />
            <FormButton className="account-logo-form__submit-btn" disabled={formDisabled}>
              {ACCOUNT_COPY.BUTTON_UPLOAD_LOGO}
            </FormButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AccountLogoForm;

import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import Password from 'shared/components/inputs/PasswordInput/Password';
import { Input } from 'shared/lib/formik';
import React from 'react';

interface Props {
  placeholder: string;
  maxLength: number;
  disabled: boolean;
  label?: string;
  dataCy?: string;
}

const PasswordInput: Input<string, Props> = ({
  field: { value, onBlur, name, onChange },
  form: { touched, errors },
  placeholder,
  maxLength,
  disabled,
  label,
  dataCy,
}) => {
  return (
    <>
      <Password
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
        touched={Boolean(touched[name])}
        hasError={Boolean(errors[name])}
        label={label}
        name={name}
        dataCy={dataCy}
      />
      <FieldErrorMessage name={name} dataCy="password-input__error-message" />
    </>
  );
};

export default PasswordInput;

import collectionFormStyles from 'features/media/components/CollectionForm/collectionFormStyles';
import { Box } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import TextInput from 'shared/components/TextInput';
import { MESSAGES } from 'shared/config/validations';
import { Field, Form, Formik } from 'formik';
import { startCase } from 'lodash';
import React from 'react';
import * as yup from 'yup';

// fieldnames
const NAME = 'name';
const namePlaceholder = `${startCase(NAME)}*`;

const validationSchema = yup.object({
  [NAME]: yup.string().trim().required(MESSAGES.REQUIRED_FIELD),
});

interface Values {
  name: string;
}

export interface CollectionFormProps {
  name?: string;
  onSubmit: (values: Values) => void;
  submitText: string;
}
const CollectionForm: React.FC<CollectionFormProps> = ({ name, onSubmit, submitText }) => {
  const initialValues: Values = {
    [NAME]: name || '',
  };

  return (
    <Box className="collection-form" css={collectionFormStyles}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
      >
        {(formikProps) => (
          <Form>
            <Field name={NAME} placeholder={namePlaceholder} component={TextInput} />
            <FormButton className="collection-form__submit-btn">{submitText}</FormButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CollectionForm;

import React, { useMemo } from 'react';
import cs from 'classnames';
import Duration from 'features/media/components/atoms/Duration';
import FileSelectButton from 'features/media/components/atoms/FileSelectButton';
import FileInformationModal from 'features/media/components/molecules/FileInformationModal';
import SingleImage from 'shared/components/atoms/SingleImage';
import SoftCurtain from 'shared/components/atoms/SoftCurtain';
import Tooltip from 'shared/components/atoms/Tooltip';
import { Box, Flex, Text } from 'shared/components/display';
import { MEDIA } from 'shared/config/media';
import { BrkfstFile } from 'shared/typings/file';
import PinFileControls from 'features/media/components/molecules/PinFileControls';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useShowcaseAssets } from 'features/users/hooks/useShowcaseAssets';

import getStyles from './styles';

// The border size of the colored area around
// the thumbnail when the media is selected
const SELECTED_MEDIA_BORDER = 18;

export interface GalleryTileProps {
  src: string;
  itemObject: BrkfstFile;
  onlyImage?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  selectDisabled?: boolean;
  selectTooltip?: string;
  toggleSelect?: () => void;
  showSelectOption?: boolean;
  OverlayLeft?: React.ReactNode;
  layout: {
    width: number;
    height: number;
    index: number;
  };
  margin: number;
  options?: any;
  onClick?: (index: number) => void;
  infoModalDisabled?: boolean;
  hideAspectRatio?: boolean;
  useBrandName?: boolean;
  showPin?: boolean;
}
const GalleryTile: React.FC<GalleryTileProps> = ({
  src,
  margin,
  options,
  itemObject,
  onlyImage = false,
  isSelectable = false,
  isSelected = false,
  toggleSelect,
  showSelectOption,
  OverlayLeft,
  onClick,
  selectDisabled = false,
  selectTooltip,
  layout,
  infoModalDisabled = false,
  hideAspectRatio = false,
  useBrandName = false,
  showPin = false,
}) => {
  const { pinFile, maxPinLimitReached } = useShowcaseAssets();
  const { isSuperadmin } = useCurrentUser();
  const { name, mimeType, metadata, brandName = '' } = itemObject;
  const duration = metadata?.duration || 0;
  const { width, height } = layout;

  // selectScale is the scale used to shrink selected media thumbnails
  // a different select scale is used for each image so the border size
  // stays the same. This is used for the div encompassing the (background) image
  // and will hide any overflowing image.
  // selectBgScale is used to scale the image to its original aspect ratio
  // if selectScale is not scaling the width and height on at 1:1 ratio
  const selectScaleX = useMemo(() => (width - SELECTED_MEDIA_BORDER * 2) / width, [width]);
  const selectScaleY = useMemo(() => (height - SELECTED_MEDIA_BORDER * 2) / height, [height]);
  const selectScale = useMemo(() => `scale3d(${selectScaleX}, ${selectScaleY}, 1)`, [selectScaleX, selectScaleY]);

  const selectBgScale = useMemo(() => {
    if (selectScaleX >= selectScaleY) {
      const selectBgScaleY = selectScaleX / selectScaleY;
      return `scale3d(1, ${selectBgScaleY}, 1)`;
    }
    const selectBgScaleX = selectScaleY / selectScaleX;
    return `scale3d(${selectBgScaleX}, 1, 1)`;
  }, [selectScaleX, selectScaleY]);

  const selectButtonToggle = (e) => {
    if (toggleSelect) toggleSelect();
    e.stopPropagation();
  };

  const handlePin = (file: BrkfstFile) => () => {
    if (isSuperadmin) pinFile(file, !file.isPinned);
  };

  /**
   * GalleryTile consists of two main sections:
   * -----
   * | A | Display
   * -----
   * | B | Body
   * -----
   *
   * The display section consists of 3 layers.
   * 1. Nonscalable content. This contains the content that will not be
   * scaled down and will remain in the same location when the tile is selected.
   * Example: the select button
   *
   * 2. Overlay. This contains the nonscalable content but will move its
   * location (in relation to the scalable content) when the tile is selected.
   * Overlays can only exist at the bottom left or bottom right of the display area.
   * Example: duration of videos
   *
   * 3. Scalable content. The main content to display goes here. Content here will be
   * scaled down when the tile is selected to a visual cue for users,
   * indicating that there was a state change (unselected => selected).
   *
   * The (optional) body can be used to display additional information about
   * the content of this image/video (name, tags).
   */

  return (
    <Flex
      css={getStyles({
        width,
        height,
        selectScale,
        selectBgScale,
        selectBorder: SELECTED_MEDIA_BORDER,
        margin,
      })}
      className={cs('gallery-tile')}
      dataCy="gallery-tile"
    >
      <Box
        className={cs('gallery-tile__display', {
          'gallery-tile__display--selectable': isSelectable || !!onClick,
          'gallery-tile__display--select-mode': showSelectOption,
          'gallery-tile__display--selected': isSelected,
        })}
        {...(onClick && !showSelectOption
          ? {
              onClick: (e) => {
                e.stopPropagation();
                onClick(layout.index);
              },
            }
          : isSelectable && !selectDisabled && { onClick: selectButtonToggle })}
      >
        <Flex className="gallery-tile__non-scalable-content">
          <Tooltip content={selectTooltip} disabled={!Boolean(selectTooltip)}>
            {isSelectable && (
              <FileSelectButton
                onClick={selectButtonToggle}
                isSelected={isSelected}
                showSelectOption={showSelectOption}
                disabled={selectDisabled}
                className="gallery-tile__select-btn"
              />
            )}
          </Tooltip>
          {showPin && (
            <PinFileControls
              className={cs('gallery-tile__hover-btn', 'gallery-tile__pin-btn')}
              canPin={isSuperadmin}
              maxPinLimitReached={maxPinLimitReached}
              isPinned={itemObject.isPinned}
              onPin={handlePin(itemObject)}
            />
          )}
          {!infoModalDisabled && (
            <FileInformationModal
              className="gallery-tile__hover-btn"
              file={itemObject}
              hideAspectRatio={hideAspectRatio}
            />
          )}
        </Flex>
        <Flex className="gallery-tile__overlay">
          <Flex className="gallery-tile__overlay-left">{OverlayLeft}</Flex>
          <Flex className="gallery-tile__overlay-right">
            {mimeType.includes(MEDIA.TYPES.VIDEO) && <Duration time={duration || '--:--'} />}
          </Flex>
        </Flex>
        <Flex className="gallery-tile__scalable-content">
          <SingleImage
            name={useBrandName ? brandName : name}
            source={src}
            width={`${width}px`}
            height={`${height}px`}
          />
        </Flex>
      </Box>
      {!onlyImage &&
        (Boolean(options) ? (
          <SoftCurtain
            Content={
              <Flex className="gallery-tile__soft-curtain-content">
                <Text
                  title={useBrandName ? brandName : name}
                  className={cs('gallery-tile__name--with-options', 'endWithEllipsis')}
                >
                  {useBrandName ? brandName : name}
                </Text>
              </Flex>
            }
            RightContent={options}
          />
        ) : (
          <Text title={useBrandName ? brandName : name} className={cs('gallery-tile__name', 'endWithEllipsis')}>
            {useBrandName ? brandName : name}
          </Text>
        ))}
    </Flex>
  );
};

export default GalleryTile;

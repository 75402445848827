import React from 'react';
import cs from 'classnames';
import { Field, Form, Formik } from 'formik';
import { Box, Flex } from 'shared/components/display';
import { formStyles } from 'features/performance/AttributionWindowDropdown/styles';
import { Button } from 'rebass/styled-components';
import {
  AttributionValues,
  ATTRIBUTION_INITIAL_VALUES,
  ATTRIBUTION_WINDOW_FIELDNAMES,
  CLICK_ATTRIBUTION_OPTIONS,
  VIEW_ATTRIBUTION_OPTIONS,
} from 'features/performance/AttributionWindowDropdown/config';
import RadioGroupInput from 'shared/components/molecules/RadioGroupInput';
import FormButton from 'shared/components/FormButton';
import { BUTTON_VARIANTS } from 'shared/styles/button';

interface Props {
  onSubmit: (values: AttributionValues) => void;
  onCancel: () => void;
}

const VIEW_ATTRIBUTION_LABEL = 'View Attribution Window';
const CLICK_ATTRIBUTION_LABEL = 'Click Attribution Window';

const AttributionForm: React.FC<Props> = ({ onSubmit, onCancel }) => {
  return (
    <Box className="attribution-window-form" css={formStyles}>
      <Formik<AttributionValues>
        onSubmit={(values, actions) => {
          onSubmit(values);
          actions.setSubmitting(false);
        }}
        initialValues={ATTRIBUTION_INITIAL_VALUES}
      >
        {(formikProps) => {
          const onReset = () => {
            formikProps.setValues(ATTRIBUTION_INITIAL_VALUES);
          };
          const resetField = (fieldName: string) => () =>
            formikProps.setFieldValue(fieldName, ATTRIBUTION_INITIAL_VALUES[fieldName]);

          return (
            <Form>
              <Flex className="attribution-window-form__revert-btn-wrapper">
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.TEXT_ONLY}
                  onClick={onReset}
                  className="attribution-window-form__clear-btn"
                >
                  Revert to default
                </Button>
              </Flex>
              <Flex className="attribution-window-form__field-and-btn">
                <Field
                  name={ATTRIBUTION_WINDOW_FIELDNAMES.VIEW}
                  component={RadioGroupInput}
                  options={VIEW_ATTRIBUTION_OPTIONS}
                  label={VIEW_ATTRIBUTION_LABEL}
                  display="horizontal"
                  showLabel
                />
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.TEXT_ONLY}
                  onClick={resetField(ATTRIBUTION_WINDOW_FIELDNAMES.VIEW)}
                  className="attribution-window-form__clear-btn"
                >
                  Clear
                </Button>
              </Flex>
              <Flex className="attribution-window-form__field-and-btn">
                <Field
                  name={ATTRIBUTION_WINDOW_FIELDNAMES.CLICK}
                  label={CLICK_ATTRIBUTION_LABEL}
                  component={RadioGroupInput}
                  options={CLICK_ATTRIBUTION_OPTIONS}
                  display="horizontal"
                  showLabel
                />
                <Button
                  type="button"
                  variant={BUTTON_VARIANTS.TEXT_ONLY}
                  onClick={resetField(ATTRIBUTION_WINDOW_FIELDNAMES.CLICK)}
                  className="attribution-window-form__clear-btn"
                >
                  Clear
                </Button>
              </Flex>
              <Flex className="attribution-window-form__btn-wrapper">
                <FormButton
                  onClick={onCancel}
                  type="button"
                  variant={BUTTON_VARIANTS.TEXT}
                  className="attribution-window-form__cancel-btn"
                >
                  Cancel
                </FormButton>
                <FormButton variant={BUTTON_VARIANTS.PRIMARY}>Submit</FormButton>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AttributionForm;

import React from 'react';
import cs from 'classnames';
import { Box, Flex, Text } from 'shared/components/display';
import { Revision, RevisionStatus, RevisionStatusDisplay } from 'shared/typings/revisions';
import { REVISION_CATEGORY_DISPLAY } from 'shared/components/organisms/ModuleReviewFormModal/config';
import { TEXT_VARIANTS } from 'shared/styles/text';
import singleRevisionStyles from './singleRevisionStyles';
import AuthorTimeDisplay from 'shared/components/atoms/AuthorTimeDisplay';

interface Props {
  revision: Revision;
  brandName: string;
  isCreator: boolean;
}
const SingleRevision: React.FC<Props> = ({ revision, brandName, isCreator }) => {
  return (
    <Box className="single-revision" css={singleRevisionStyles}>
      <Text as="h5" className="single-revision__header">
        {REVISION_CATEGORY_DISPLAY[revision.category]}
      </Text>
      <Flex className="single-revision__sub-header">
        <AuthorTimeDisplay name={isCreator ? brandName : revision.author?.fullName} dateCreated={revision.dateCreated}>
          <Text
            as="span"
            className={cs('single-revision__status', {
              'single-revision__status--done': revision.status === RevisionStatus.DONE,
            })}
          >
            {RevisionStatusDisplay[revision.status]}
          </Text>
        </AuthorTimeDisplay>
      </Flex>
      <Text data-cy="revision__comment" variant={TEXT_VARIANTS.BODY}>
        {revision.comment}
      </Text>
    </Box>
  );
};

export default SingleRevision;

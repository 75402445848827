import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  earningDetailsLoaded,
  earningsLoaded,
  getAllEarnings,
  getCurrentPayouts,
  getCurrentPayoutsTotal,
  getEarningsPaidAt,
  getEarningsPayPeriod,
  getEarningsTotal,
  getEarningsTotalAmount,
} from 'features/users/hooks/earnings.slice';
import { EarningPayout, EarningType } from 'features/users/pages/Earnings/Earnings';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_PAYOUT_ROUTES } from 'shared/config/routes/api/apiPayoutRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useQuery } from 'shared/hooks/useQuery';
import { getDate } from 'shared/utilities/dateUtility';
import { useSearchParams } from 'shared/hooks/useSearchParams';

export const useEarnings = () => {
  const dispatch = useDispatch();

  const {
    currentUser: { id: creatorId },
  } = useCurrentUser();

  const earnings = useSelector(getAllEarnings);
  const totalEarnings = useSelector(getEarningsTotal);
  const totalEarningsAmount = useSelector(getEarningsTotalAmount);
  const earningsPaidAt = useSelector(getEarningsPaidAt);
  const earningsPayPeriod = useSelector(getEarningsPayPeriod);
  const currentPayouts = useSelector(getCurrentPayouts);
  const currentPayoutsTotal = useSelector(getCurrentPayoutsTotal);
  const { loading } = useComponentLoading(RESOURCES.EARNING);
  const { loading: earningDetailsLoading } = useComponentLoading(RESOURCES.EARNING_DETAILS, false);
  const DEFAULT_PERFORMANCE_FILES_SIZE = 10;
  const DEFAULT_PERFORMANCE_FILES_PAGE = 1;

  const { setQuery, getQuery } = useQuery();
  const query = getQuery();

  const searchParams = useSearchParams(query, {
    // For Earnings page
    page: 1,
    size: 10,
    orderBy: 'billingStartDate',
    groupBy: 'creator',
    orderAsc: false,
    payoutIndex: -1,
  });

  const performanceSearchParams = useSearchParams(query, {
    // For earnings detail pagination (performance search params)
    performanceFilesPage: DEFAULT_PERFORMANCE_FILES_PAGE,
    performanceFilesSize: DEFAULT_PERFORMANCE_FILES_SIZE,
  });

  const getEarnings = useCallback(() => {
    dispatch(
      apiAction({
        path: {
          route: API_PAYOUT_ROUTES.PAYOUTS,
        },
        method: 'GET',
        successAction: earningsLoaded,
        entity: RESOURCES.EARNING,
        params: {
          ...searchParams,
          creatorId,
        },
      }),
    );
  }, [searchParams, creatorId]);

  const onRowClick = (el) => {
    if (searchParams.groupBy === 'creator')
      setQuery({
        groupBy: 'file',
        billingStartDate: getDate(el.billingStartDate),
        billingEndDate: getDate(el.billingEndDate),
        page: 1,
      });
    return null;
  };

  const getEarningDetails = (
    el: EarningPayout,
    paginationParams: {
      page: number;
      size: number;
    } = {
      page: 1,
      size: 10,
    },
  ) => {
    let params;
    if (el.type === EarningType.SUBMISSION) params = { groupBy: 'submission', submissionId: el.submissionId };
    else if (el.type === EarningType.MANUAL)
      params = { groupBy: 'manual', manualTransactionId: el.manualTransactionId };
    else
      params = {
        groupBy: 'file',
        billingStartDate: getDate(el.billingStartDate),
        billingEndDate: getDate(el.billingEndDate),
      };

    dispatch(
      apiAction({
        path: {
          route: API_PAYOUT_ROUTES.PAYOUTS,
        },
        method: 'GET',
        successAction: earningDetailsLoaded,
        entity: RESOURCES.EARNING_DETAILS,
        params: {
          creatorId,
          ...paginationParams,
          ...params,
        },
      }),
    );
  };

  return {
    earnings,
    loading,
    totalEarnings,
    totalEarningsAmount,
    earningsPaidAt,
    earningsPayPeriod,
    currentPayouts,
    searchParams,
    onRowClick,
    getEarningDetails,
    currentPayoutsTotal,
    earningDetailsLoading,
    getEarnings,
    performanceSearchParams,
    DEFAULT_PERFORMANCE_FILES_PAGE,
    DEFAULT_PERFORMANCE_FILES_SIZE,
  };
};

import React from 'react';
import WelcomeHeader from 'shared/components/molecules/WelcomeHeader/WelcomeHeader';
import { CLASSNAMES } from 'shared/styles/containers';
import { STYLES } from 'shared/styles/styles';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { Link } from 'react-router-dom';
import { AUTH_COPY } from 'shared/config/copy';
import ForgotPasswordForm from 'features/auth/components/organisms/ForgotPasswordForm/ForgotPasswordForm';
import { Box, Text } from 'shared/components/display';
import theme from 'shared/styles/themes';

const ForgotPassword = () => {
  return (
    <Box className={CLASSNAMES.PUBLIC_CONTAINER}>
      <WelcomeHeader
        heading={AUTH_COPY.HEADING_FORGOT_PASSWORD}
        subheading={AUTH_COPY.SUBHEADING_FORGOT_PASSWORD}
        textAlign={STYLES.CENTER}
      />
      <ForgotPasswordForm />
      <Text
        style={{
          textAlign: 'center',
          marginTop: theme.space[4],
        }}
      >
        <Link
          to={LinkCreator.createLink({
            routeKey: 'SIGN_IN',
          })}
        >
          {AUTH_COPY.BUTTON_REMEMBERED_PASSWORD}
        </Link>
      </Text>
    </Box>
  );
};

export default ForgotPassword;

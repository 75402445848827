import React from 'react';
import { useParams } from 'react-router-dom';

import FacebookAccountCreationForm from 'features/accountPlatforms/facebook/components/AccountCreationForm';
import { openFbLoginDialog } from 'features/accountPlatforms/facebook/FacebookConnect/FacebookConnect';
import { Box } from 'shared/components/display';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { LinkCreator } from 'shared/utilities/linkUtility';

import AdHealthSignIn from '../AdHealthSignIn/AdHealthSignIn';

import styles from './styles';

const AccountHealthNoPlatforms: React.FC = () => {
  const params = useParams<{ accountId: string; organizationId: string }>();
  const accountId = +params.accountId;
  const organizationId = +params.organizationId;
  const { currentUser } = useCurrentUser();

  const onSubmit = () => {
    const redirectURI = LinkCreator.createLink({
      routeKey: 'ACCOUNT_HEALTH',
      variables: {
        organizationId,
        accountId,
      },
      query: {
        modalOpen: true,
        platform: 'facebook',
      },
    });

    openFbLoginDialog(redirectURI, currentUser.id);
  };

  return (
    <Box css={styles} className="account-health-no-platforms">
      <AdHealthSignIn onSubmit={onSubmit} />
      <FacebookAccountCreationForm />
    </Box>
  );
};

export default AccountHealthNoPlatforms;

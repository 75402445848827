import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { faCheckCircle, faSpinnerThird, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import COLORS from 'shared/styles/colors';
import { FileUploadStatus } from 'shared/typings/file/enums';

interface Props {
  status: FileUploadStatus;
  progress?: number;
}
const UploadStatusIcon: React.FC<Props> = ({ progress = 0, status }) => {
  switch (status) {
    case FileUploadStatus.UPLOADING:
      return (
        <div data-cy="upload-status-drawer__progress-icon" className="upload-status-drawer__progress-icon">
          <CircularProgressbar
            className="upload-status-drawer__progress-bar"
            value={progress}
            styles={buildStyles({
              pathColor: COLORS.GREEN500,
            })}
          />
        </div>
      );

    case FileUploadStatus.UPLOADED:
      return (
        <FontAwesomeIcon
          data-cy="upload-status-drawer__status-icon--uploaded"
          icon={faCheckCircle}
          color={COLORS.GREEN500}
          className="upload-status-drawer__status-icon"
        />
      );

    case FileUploadStatus.UPLOAD_FAILED:
      return (
        <FontAwesomeIcon
          data-cy="upload-status-drawer__status-icon--failed"
          icon={faTimesCircle}
          color={COLORS.NEUTRAL200}
          className="upload-status-drawer__status-icon"
        />
      );

    case FileUploadStatus.UPLOAD_CANCELLED:
      return (
        <FontAwesomeIcon
          data-cy="upload-status-drawer__status-icon--canceled"
          icon={faTimesCircle}
          color={COLORS.NEUTRAL200}
          className="upload-status-drawer__status-icon"
        />
      );
  }
  return <FontAwesomeIcon icon={faSpinnerThird} spin className="upload-status-drawer__status-icon" />;
};

export default UploadStatusIcon;

import React from 'react';
import cs from 'classnames';
import ReactModal from 'react-modal';
import { Box } from 'shared/components/display';
import styles, { reviewOverlayModalCss } from './styles';
import { Button } from 'rebass/styled-components';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { convertWidthNum } from 'shared/utilities/moduleReviewUtility';
import { breakpoints } from 'shared/styles/styleFunctions';
import { useMediaQuery } from 'react-responsive';

interface Props {
  ariaLabel: string;
  id: string;
  isOpen?: boolean;
  onRequestClose: (any) => void;
  children: React.ReactNode;
  mediaWidth: number;
  dataCy?: string;
}
const ReviewOverlayModal: React.FC<Props> = ({
  ariaLabel,
  id,
  isOpen,
  onRequestClose,
  children,
  mediaWidth,
  dataCy,
}) => {
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });
  //Set the width to match the video or img that the modal is overlaid on
  const width = convertWidthNum(mediaWidth, 50);
  reviewOverlayModalCss['overlay']['width'] = isMobile ? 'calc(100% - 75px)' : width;

  return (
    <ReactModal
      aria={{
        label: ariaLabel,
      }}
      className="brkfst-modal"
      id={id}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      role="dialog"
      shouldCloseOnOverlayClick
      style={reviewOverlayModalCss}
    >
      <Box css={styles} className={cs('review-overlay-modal')} data-cy={dataCy}>
        <Box className="review-overlay-modal__header">
          <Button
            variant={BUTTON_VARIANTS.TEXT_ONLY}
            onClick={(e) => {
              e.stopPropagation();
              onRequestClose(false);
            }}
            className="review-overlay-modal__close-btn"
            data-cy="review-overlay-modal__close-btn"
            type="button"
          >
            &#10005;
          </Button>
        </Box>
        {children}
      </Box>
    </ReactModal>
  );
};

export default ReviewOverlayModal;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

// allow room for carousel arrows
const CAROUSEL_CONTENT_WIDTH = 'calc(100vw - 50px)';

const sharedIframeStyles = css({
  width: '450px!important', // @TODO remove under BRKFST-178 after re-styling
  height: '800px',
});

const sharedItemStyles = css({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '100%',
});

export const carouselItemStyles = css({
  '&.carousel-item': {
    ...sharedItemStyles,
  },
  '.carousel-item__iframe': {
    ...sharedIframeStyles,
  },
  '.carousel-item__image-container': {
    position: 'relative',
    '& .safe-zone-buttons': {
      position: 'absolute',
      bottom: '7px',
      right: 0,
      [mediaquery.md]: {
        display: 'none',
      },
    },
    '&:hover .safe-zone-buttons': {
      [mediaquery.md]: {
        display: 'flex',
      },
    },
    '& img.carousel-item__img': {
      maxWidth: CAROUSEL_CONTENT_WIDTH,
      userSelect: 'none',
      height: 'auto',
      maxHeight: ['75vh', '78svh'],
      [mediaquery.md]: {
        maxHeight: '84vh',
      },
    },
  },
  '.carousel-item__video-wrapper': {
    '& .video-player__video': {
      maxWidth: CAROUSEL_CONTENT_WIDTH,
      // svh is unit for the visible portion of the viewport, fallback for older browsers that don't support svh
      maxHeight: ['70vh', '78svh'],
      objectFit: 'cover',
      [mediaquery.md]: {
        maxHeight: '84vh',
      },
    },
    '& .safe-zone-buttons': {
      position: 'absolute',
      bottom: '15%',
      right: 0,
      [mediaquery.md]: {
        bottom: '13%',
        display: 'none',
      },
    },
    '&:hover .safe-zone-buttons': {
      [mediaquery.md]: {
        display: 'flex',
      },
    },
  },
});

export const trendCarouselItemStyles = css({
  '&.trend-carousel-item': {
    ...sharedItemStyles,
  },
  '.trend-carousel-item__iframe': {
    ...sharedIframeStyles,
  },
  '.trend-carousel-item': {
    '&__header': {
      alignItems: 'baseline',
      justifyContent: 'space-between',
      padding: theme.space[5],
    },
    '&__header-text': {
      marginBottom: 0,
      fontWeight: 600,
    },
    '&__hr': {
      marginBottom: 0,
    },
    '&__container': {
      flexDirection: 'column',
      borderRadius: '8px',
      backgroundColor: COLORS.WHITE,
      // svh is unit for the visible portion of the viewport, fallback for older browsers that don't support svh
      maxHeight: ['85vh', '85svh'],
      width: 'fit-content',
      height: 'fit-content',
    },
    '&__video-wrapper, &__image-container': {
      borderRadius: '8px',
    },
    '&__video-wrapper .video-player__video, &__image-container img.trend-carousel-item__img': {
      width: 'auto',
      height: '500px',
      objectFit: 'cover',
      margin: theme.space[6],
      borderRadius: '8px',
      overflow: 'hidden',
    },
  },
});

import React from 'react';

import { EarningPayout, EarningTypeLabel } from 'features/users/pages/Earnings/Earnings';
import { Flex, Text } from 'shared/components/display';

import { EARNING_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles, { CLASSNAMES, MAIN_CLASS } from './styles';

export interface Props {
  payoutDate: string;
  payoutType: EarningPayout['type'];
  payPeriod: string;
  brand?: string;
  brief?: string;
  submissionDate?: string;
  note?: string;
}

const PayoutDetailsInfo: React.FC<Props> = ({
  payoutDate,
  payoutType,
  payPeriod,
  brand = '',
  brief = '',
  submissionDate = '',
  note = '',
}) => {
  return (
    <Flex css={styles} className={MAIN_CLASS}>
      <Flex flexDirection="column">
        <Flex flexDirection="column">
          <Text as="h3" variant={TEXT_VARIANTS.SUBHEADING} className={CLASSNAMES.label}>
            {EARNING_COPY.PAYOUT_DATE}
          </Text>
          <Text as="p" variant={TEXT_VARIANTS.BODY} className={CLASSNAMES.body}>
            {payoutDate}
          </Text>
        </Flex>
        {payPeriod?.length > 0 ? (
          <Flex flexDirection="column">
            <Text as="h3" variant={TEXT_VARIANTS.SUBHEADING} className={CLASSNAMES.label}>
              {EARNING_COPY.PAY_PERIOD}
            </Text>
            <Text as="p" variant={TEXT_VARIANTS.BODY} className={CLASSNAMES.body}>
              {payPeriod}
            </Text>
          </Flex>
        ) : (
          <></>
        )}
        {brand?.length > 0 ? (
          <Flex flexDirection="column">
            <Text as="h3" variant={TEXT_VARIANTS.SUBHEADING} className={CLASSNAMES.label}>
              {EARNING_COPY.BRAND}
            </Text>
            <Text as="p" variant={TEXT_VARIANTS.BODY} className={CLASSNAMES.body}>
              {brand}
            </Text>
          </Flex>
        ) : (
          <></>
        )}
        {brief?.length > 0 ? (
          <Flex flexDirection="column">
            <Text as="h3" variant={TEXT_VARIANTS.SUBHEADING} className={CLASSNAMES.label}>
              {EARNING_COPY.BRIEF}
            </Text>
            <Text as="p" variant={TEXT_VARIANTS.BODY} className={CLASSNAMES.body}>
              {brief}
            </Text>
          </Flex>
        ) : (
          <></>
        )}
        {submissionDate?.length ? (
          <Flex flexDirection="column">
            <Text as="h3" variant={TEXT_VARIANTS.SUBHEADING} className={CLASSNAMES.label}>
              {EARNING_COPY.SUBMISSION_DATE}
            </Text>
            <Text as="p" variant={TEXT_VARIANTS.BODY} className={CLASSNAMES.body}>
              {submissionDate}
            </Text>
          </Flex>
        ) : (
          <></>
        )}
        {note?.length ? (
          <Flex flexDirection="column">
            <Text as="h3" variant={TEXT_VARIANTS.SUBHEADING} className={CLASSNAMES.label}>
              {EARNING_COPY.NOTE}
            </Text>
            <Text as="p" variant={TEXT_VARIANTS.BODY} className={CLASSNAMES.body}>
              {note}
            </Text>
          </Flex>
        ) : (
          <></>
        )}
      </Flex>
      <Flex flexDirection="column">
        <Text as="h3" variant={TEXT_VARIANTS.SUBHEADING} className={CLASSNAMES.label}>
          {EARNING_COPY.PAYOUT_TYPE}
        </Text>
        <Text as="p" variant={TEXT_VARIANTS.BODY} className={CLASSNAMES.body}>
          {EarningTypeLabel[payoutType]}
        </Text>
      </Flex>
    </Flex>
  );
};

export default PayoutDetailsInfo;

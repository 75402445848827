import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export default css({
  flexDirection: 'column',
  '.ad-creation-form': {
    '&__call-to-action-label': {
      marginBottom: theme.space[4],
    },
    '&__heading': {
      marginBottom: theme.space[4],
    },
    '&__form-buttons': {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill,170px)',
      columnGap: '10px',
      rowGap: '10px',
    },
    '&--hidden': {
      display: 'none',
    },
  },
  '.product-row': {
    flexDirection: 'column',
    width: '150px',
    marginRight: theme.space[5],
    marginTop: theme.space[5],
  },
  '.product-image': {
    width: '150px',
    height: '150px',
  },
  '.product-text': {
    textAlign: 'center',
  },
  '.product-caption': {
    marginTop: theme.space[3],
  },
  '.buttons': {
    marginTop: '0.75rem',
  },
});

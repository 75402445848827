import React from 'react';

interface Props {
  original: {
    labels: string[];
  };
  isExpanded?: boolean;
}

const LabelExpander: React.FC<Props> = ({ original, isExpanded = false }) => {
  const { labels = [] } = original;
  if (labels.length) {
    return <div>{isExpanded ? <span>{labels.length} &#x2299;</span> : <span>{labels.length} &#x2295;</span>}</div>;
  }
  return null;
};

export default LabelExpander;

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { Flex } from 'shared/components/display';
import { ToggleEditInformation } from 'shared/components/molecules/ToggleEditInformation';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import React from 'react';
import { Button } from 'rebass/styled-components';
import BannerLogo from 'features/accounts/components/molecules/BannerLogo/BannerLogo';
import BannerLogoForm from 'features/accounts/components/organisms/BannerLogoForm/BannerLogoForm';
import { ACCOUNT_COPY } from 'shared/config/copy';
import styles from './styles';

interface Props {
  accountId: string;
  authorizedToEdit: boolean;
}
const UpdateBannerLogo: React.FC<Props> = ({ accountId, authorizedToEdit }) => {
  const { account, loadingAccountBanner, addAccountBanner, removeAccountBanner } = useAccounts({ accountId });

  const bannerFormProps = {
    onSubmit: (data) => {
      addAccountBanner(data.bannerLogo);
    },
    formDisabled: !authorizedToEdit,
  };

  return (
    <ToggleEditInformation
      editButtonText={ACCOUNT_COPY.BUTTON_UPLOAD_BANNER}
      heading={ACCOUNT_COPY.HEADING_BANNER}
      variant={TEXT_VARIANTS.H6}
      EditingComponent={BannerLogoForm}
      EditingComponentProps={bannerFormProps}
    >
      <Flex css={styles} className="update-banner-logo">
        <BannerLogo account={account} loading={loadingAccountBanner} />
        {!loadingAccountBanner && account.banner?.url && (
          <Button
            variant={BUTTON_VARIANTS.TEXT_ONLY}
            className="update-banner-logo__delete-btn"
            onClick={() => removeAccountBanner(account.bannerId)}
          >
            {ACCOUNT_COPY.BUTTON_DELETE_BANNER}
          </Button>
        )}
      </Flex>
    </ToggleEditInformation>
  );
};

export default UpdateBannerLogo;

import React from 'react';
import cs from 'classnames';
import { Field, Form, Formik } from 'formik';
import TextInput from 'shared/components/TextInput';
import FormButton from 'shared/components/FormButton';
import { GENERIC_COPY } from 'shared/config/copy';
import { accountPlatformFromSchema } from 'features/accountPlatforms/components/molecules/AccountPlatformForm/validations';
import { AccountPlatform } from 'shared/typings/accountPlatform';
import { startCase } from 'lodash';
import { Box } from 'shared/components/display';

import accountPlatformFormStyles from 'features/accountPlatforms/components/molecules/AccountPlatformForm/accountPlatformFormStyles';

const NAME = 'name';

export type AccountPlatformFormValues = Pick<AccountPlatform, 'name'>;

interface Props {
  onSubmit: (values: AccountPlatformFormValues) => void;
  initialValues: AccountPlatformFormValues;
}

const AccountPlatformForm: React.FC<Props> = ({ onSubmit, initialValues }) => {
  return (
    <Formik<AccountPlatformFormValues>
      onSubmit={onSubmit}
      validationSchema={accountPlatformFromSchema}
      validateOnBlur={false}
      initialValues={initialValues}
    >
      <Form>
        <Box className="account-platform-form" css={accountPlatformFormStyles}>
          <Field component={TextInput} name={NAME} label={startCase(NAME)} placeholder={startCase(NAME)} />
          <FormButton className="account-platform-form__submit-btn">{GENERIC_COPY.BUTTON_SUBMIT}</FormButton>
        </Box>
      </Form>
    </Formik>
  );
};

export default AccountPlatformForm;

import Carousel from 'shared/components/organisms/Carousel';
import { Lightbox } from 'shared/components/organisms/Lightbox';
import React, { useState } from 'react';

interface Props {
  children: React.ReactElement[];
  ariaLabel: string;
}

interface State {
  isOpen: boolean;
  currentLightboxIndex: number;
}

const CarouselLightbox: React.FC<Props> = ({ children, ariaLabel }) => {
  const [state, setState] = useState<State>({
    isOpen: false,
    currentLightboxIndex: 0,
  });

  const onRequestClose = () => {
    setState((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  return (
    <>
      <Carousel
        onClickItem={(index) => {
          setState({
            currentLightboxIndex: index,
            isOpen: true,
          });
        }}
        showIndicators
      >
        {children}
      </Carousel>
      <Lightbox
        isOpen={state.isOpen}
        ariaLabel={ariaLabel}
        onRequestClose={onRequestClose}
        selectedItemIndex={state.currentLightboxIndex}
      >
        {children}
      </Lightbox>
    </>
  );
};

export default CarouselLightbox;

import { briefAssetAdded, briefAssetUpdated } from 'features/briefs/briefs.slice';
import { useFiles } from 'features/media/useFiles';
import { componentLoadingToggled } from 'features/ui/ui.slice';
import { RESOURCES } from 'shared/config/resourceNames';
import { SignalRContext } from 'shared/contexts/signalr';
import { FileUploadStatus } from 'shared/typings/file/enums';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

export const useBriefAssetsNotification = (): void => {
  const { connection } = useContext(SignalRContext);
  const dispatch = useDispatch();
  const { updateFileUpload } = useFiles();

  useEffect(() => {
    const notificationHandler = ({ key, isBrandAsset, ...file }) => {
      const { briefId } = file;

      if (isBrandAsset && briefId) {
        dispatch(briefAssetAdded(file));

        dispatch(
          componentLoadingToggled({
            component: RESOURCES.BRIEF_ASSET,
            loading: false,
          }),
        );
        updateFileUpload(key, FileUploadStatus.UPLOADED);
      }
    };

    const thumbnailHandler = ({ fileId, thumbnailUrl, thumbnailOffset }) => {
      dispatch(briefAssetUpdated({ fileId, thumbnailUrl, thumbnailOffset }));
    };

    if (connection) {
      connection.on('newFile', notificationHandler);
      connection.on('newThumbnail', thumbnailHandler);
    }

    return function cleanup() {
      connection?.off('newFile', notificationHandler);
      connection?.off('newThumbnail', thumbnailHandler);
    };
  }, [connection]);
};

import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export default css({
  '&.submission-form': {},
  '.submission-form': {
    '&__buttons': {
      justifyContent: 'flex-start',
      marginTop: theme.space[5],
    },
    '&__button': {
      marginLeft: theme.space[3],
      whiteSpace: 'nowrap',
    },
    '&__label-comment': {
      textTransform: 'uppercase',
    },
  },
});

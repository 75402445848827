import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '.module-comment-form': {
    '&__input-border': {
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'center',
      width: '100%',
      border: `1px solid ${COLORS.NEUTRAL600}`,
      borderRadius: '2px',
      paddingLeft: '3px',
    },
    '&__input:not(:has(textarea:focus))': {
      paddingBottom: '15px',
    },
    '&__input:has(textarea:focus)': {
      paddingBottom: '3px',
    },
    '&__input-border:has(textarea:focus)': {
      outline: `${COLORS.PRIMARY500} auto medium`,
    },
    '&__input': {
      width: '100%',
    },
    '&__input textarea': {
      outline: 'none',
      border: 'none',
    },
  },
});

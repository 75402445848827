import React, { useMemo } from 'react';

import ClosedBriefActionButtons from 'features/briefs/BriefHeader/HeaderBrandActions/ClosedBriefActionButtons';
import FullBriefActionButtons from 'features/briefs/BriefHeader/HeaderBrandActions/FullBriefActionButtons';
import { BriefPermissions } from 'features/briefs/BriefHeader/HeaderBrandActions/types';
import DuplicateBriefButton from 'features/briefs/components/atoms/DuplicateBriefButton';

import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { usePermissions } from 'shared/hooks/usePermissions';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { Brief } from 'shared/typings/briefs';
import { BriefStatus } from 'shared/typings/briefs/enums';

import DraftBriefActionButtons from './DraftBriefActionButtons';
import OpenBriefActionButtons from './OpenBriefActionButtons';

interface Props {
  currentBrief: Brief;
}

const HeaderBrandActions: React.FC<Props> = ({ currentBrief }) => {
  const { isAuthorized, displayComponent } = usePermissions();
  const { isSuperadmin } = useCurrentUser();

  const [isDraft, isOpen, isClosed, isFull] = useMemo(() => {
    return currentBrief
      ? [
          currentBrief.status === BriefStatus.DRAFT,
          currentBrief.status === BriefStatus.OPEN && !currentBrief.full,
          currentBrief.status === BriefStatus.CLOSED,
          currentBrief.full === true,
        ]
      : [false, false, false, false];
  }, [currentBrief]);

  const [createEditDraft, deleteDraft, publishEditBrief, closeReopenBrief] = useMemo(() => {
    return [
      // draft permissions
      isAuthorized(currentBrief.organizationId, PERMISSIONS_MAP.CREATE_EDIT_BRIEF_DRAFT.API),
      isAuthorized(currentBrief.organizationId, PERMISSIONS_MAP.DELETE_BRIEF_DRAFT.API),

      // brief permissions
      isAuthorized(currentBrief.organizationId, PERMISSIONS_MAP.PUBLISH_EDIT_BRIEF.API),
      isAuthorized(currentBrief.organizationId, PERMISSIONS_MAP.CLOSE_REOPEN_BRIEF.API),
    ];
  }, [currentBrief.organizationId]);

  const briefPermissions: BriefPermissions = {
    createEditDraft,
    deleteDraft,
    publishEditBrief,
    closeReopenBrief,
  };

  // if the brief is a draft, user can edit/duplicate or delete it
  if (isDraft && (createEditDraft || deleteDraft))
    return <DraftBriefActionButtons currentBrief={currentBrief} draftBriefPermissions={briefPermissions} />;

  if (isOpen && (publishEditBrief || closeReopenBrief)) {
    return <OpenBriefActionButtons currentBrief={currentBrief} openBriefPermissions={briefPermissions} />;
  }
  // if the brief is closed, user can duplicate or reopen
  if (isClosed && (createEditDraft || closeReopenBrief))
    return <ClosedBriefActionButtons currentBrief={currentBrief} closedBriefPermissions={briefPermissions} />;

  if (isFull && (closeReopenBrief || publishEditBrief)) {
    return <FullBriefActionButtons currentBrief={currentBrief} fullBriefPermissions={briefPermissions} />;
  }

  // if not authorized for any action above, then don't display split button
  // if user can create/edit drafts, then only display Duplicate button
  // otherwise, don't display anything
  if (createEditDraft) {
    return (
      <>
        {displayComponent(
          <DuplicateBriefButton brief={currentBrief} isDraft={isDraft} variant={BUTTON_VARIANTS.PRIMARY} />,
          currentBrief.organizationId,
          PERMISSIONS_MAP.CREATE_EDIT_BRIEF_DRAFT.API,
        )}
      </>
    );
  }
  return null;
};

export default HeaderBrandActions;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.creator-invite-form-modal': {},
  '.creator-invite-form-modal': {
    '&__success-message': {
      padding: `0 ${theme.space[5]}`,
    },
    '&__btn-wrapper': {
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: `${theme.space[5]}`,
    },
    '&__hr': {
      margin: 0,
    },
  },
});

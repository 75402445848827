import React, { useState } from 'react';
import cs from 'classnames';
import { Box, Flex } from 'shared/components/display';
import styles from './styles';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { ModuleCommentValues } from './config';
import TextareaInput from 'shared/components/TextareaInput/TextareaInput';
import { Button } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faSend } from '@fortawesome/pro-light-svg-icons';
import { BUTTON_VARIANTS } from 'shared/styles/button';

interface Props {
  editing?: boolean;
  onSubmit: (values: ModuleCommentValues, formikHelpers: FormikHelpers<ModuleCommentValues>) => void;
  comment?: string;
}
const ModuleCommentForm: React.FC<Props> = ({ editing, onSubmit, comment = '' }) => {
  return (
    <Box css={styles} className={cs('module-comment-form')}>
      <Formik<ModuleCommentValues> onSubmit={onSubmit} initialValues={{ comment }}>
        {(formikHelpers) => (
          <Form>
            <Flex className="module-comment-form__input-border">
              <Field
                name="comment"
                className="module-comment-form__input"
                dataCy="module-comment-form__input"
                component={TextareaInput}
                rows={3}
                maxLength={255}
                softMaxLength={255}
                noMargin
                showCharactersCounter
              />
              <Button
                type="submit"
                variant={BUTTON_VARIANTS.TEXT_ONLY}
                disabled={!formikHelpers.dirty || formikHelpers.isSubmitting}
                className="module-comment-form__send-btn"
                data-cy="module-comment-form__send-btn"
              >
                <FontAwesomeIcon icon={editing ? faPencil : faSend} />
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ModuleCommentForm;

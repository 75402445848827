import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';
import SHADOWS from 'shared/styles/shadow';

export default css({
  '&.platform-snippet': {
    background: 'white',
    margin: theme.space[4],
    padding: theme.space[4],
    boxShadow: SHADOWS.SHADOW04,
    cursor: 'pointer',
    minHeight: '15rem',
    width: '15rem',
    flexWrap: 'nowrap',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& svg': {
      cursor: 'pointer',
    },
    '&:hover': {
      boxShadow: SHADOWS.SHADOW12,
    },
  },
  '.platform-snippet': {},
});

import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import cs from 'classnames';

import Gallery from 'features/media/components/organisms/Gallery';
import { GalleryItem } from 'features/media/interfaces/gallery';
import IGPostCarousel from 'features/users/components/molecules/IGPostCarousel';
import ExternalLink from 'shared/components/atoms/ExternalLink/ExternalLink';
import { Box } from 'shared/components/display';

import { CREATOR_COPY } from 'shared/config/copy';
import { MEDIA } from 'shared/config/media';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { breakpoints } from 'shared/styles/styleFunctions';
import { getVideoElement } from 'shared/utilities/validator/utils';

import igPostsStyles from './igPostsStyles';
interface CaptionProps {
  captionData?: { igUrl: string };
  disabled?: boolean;
}

export const IgPostCaption: React.FC<CaptionProps> = ({ captionData = { igUrl: '' }, disabled }) => {
  const { igUrl } = captionData;
  return (
    <Box css={igPostsStyles} className="ig-posts__caption">
      <ExternalLink
        href={igUrl}
        text={CREATOR_COPY.OPEN_IN_INSTAGRAM}
        className={cs('ig-posts__open-btn', {
          ['ig-posts__open-btn--disabled']: disabled,
        })}
      />
    </Box>
  );
};

interface IGPost {
  id: string;
  mediaType: string;
  thumbnailUrl: string;
  url: string;
  igUrl: string;
  width: number;
  height: number;
}

interface Props {
  creatorId: number;
  igPosts: IGPost[];
  placeholderText: string;
}

const IGPosts: React.FC<Props> = ({ creatorId, igPosts, placeholderText }) => {
  const [galleryItems, setGalleryItems] = useState<GalleryItem[]>([]);

  useEffect(() => {
    let active = true;
    getPostsWithMetadata();
    async function getPostsWithMetadata() {
      const promises = igPosts.slice(0, 5).map(async (post): Promise<GalleryItem> => {
        const metadata: Record<string, any> = {
          width: post.width || 500,
          height: post.height || 500,
        };
        const isVideo = post.mediaType === 'VIDEO';
        if (isVideo) {
          const fileData = await getVideoElement(post.url);
          metadata.width = fileData.videoWidth;
          metadata.height = fileData.videoHeight;
          metadata.duration = fileData.duration;
        }
        return {
          itemObject: {
            id: +post.id,
            thumbnailUrl: isVideo ? post.thumbnailUrl : post.url,
            url: post.url,
            creatorId,
            dateCreated: new Date(),
            name: post.id,
            metadata,
            mimeType: isVideo ? MEDIA.TYPES.VIDEO : MEDIA.TYPES.IMAGE,
          },
          captionData: { igUrl: post.igUrl },
        };
      });
      const posts = await Promise.all(promises);
      if (!active) return;
      setGalleryItems(posts);
    }

    return () => {
      active = false;
    };
  }, [igPosts, creatorId]);

  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const showCarousel = isMobile && igPosts?.length > 0;
  const { isCreator } = useCurrentUser();

  return (
    <Box css={igPostsStyles} className={'ig-posts'}>
      {showCarousel ? (
        <IGPostCarousel igPosts={igPosts.slice(0, 5)} />
      ) : (
        <Gallery
          items={galleryItems}
          numberOfSkeletons={igPosts.length}
          /**
           * Set targetHeight to 0 so photos can freely shrink to fit.
           * This is to optimize the probability of fitting all images in one row.
           * As long as the parent width is not 0, these images will never have a width of 0.
           */
          targetHeight={0}
          LightboxCaption={<IgPostCaption />}
          placeholderText={placeholderText}
          onlyImage
          infoModalDisabled={isCreator}
          hideAspectRatio
        />
      )}
    </Box>
  );
};

export default IGPosts;

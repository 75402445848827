import React from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { Box, Flex, Text } from 'shared/components/display';
import FileListItem from 'shared/components/molecules/FileListView/FileListItem';
import FileListView, { FileAction } from 'shared/components/molecules/FileListView/FileListView';
import MediaUploadButton from 'shared/components/molecules/MediaUploadButton';
import { BRIEF_COPY } from 'shared/config/copy';
import { ALL_BRKFST_SUPPORTED_FILE_FORMATS } from 'shared/config/fileFormats';
import { Input } from 'shared/lib/formik';
import COLORS from 'shared/styles/colors';
import { getFileMimeType } from 'shared/utilities/validator/utils';

import styles from './briefAssetsInputStyles';
import IconButton from 'shared/components/atoms/IconButton';
import { BrkfstFile } from 'shared/typings/file';

interface Props {}

interface NewFile {
  file: File;
  name: string;
  mimeType: string;
}

interface InputValue {
  newFiles: NewFile[];
  assets: BrkfstFile[];
}

const BriefAssetsInput: Input<InputValue, Props> = ({
  field: { value: { assets = [], newFiles = [] } = {}, name, onChange, onBlur },
}) => {
  const setInputValue = (value: InputValue) => {
    const event = { target: { name, value } };
    onChange(event);
    onBlur(event);
  };

  const onFileUpload = (files: FileList) => {
    const fileArray: NewFile[] = newFiles;
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      fileArray.push({
        file,
        name: file.name,
        mimeType: getFileMimeType(file),
      });
    }
    setInputValue({
      assets,
      newFiles: fileArray,
    });
  };

  return (
    <Box css={styles} className="brief-assets">
      <Flex className="brief-assets__title-wrapper">
        <Text as="h4" className="brief-assets__title">
          {BRIEF_COPY.HEADING_BRIEF_ASSETS}
        </Text>
        <MediaUploadButton
          acceptedMedia={ALL_BRKFST_SUPPORTED_FILE_FORMATS}
          multipleFiles
          onFileUpload={onFileUpload}
          buttonContent="Upload Asset"
        />
      </Flex>
      <Box className="brief-assets__files">
        <FileListView
          files={assets}
          action={FileAction.REMOVE}
          onClick={(asset: BrkfstFile) => {
            setInputValue({
              newFiles,
              assets: assets.filter((file: BrkfstFile) => file.id !== asset.id),
            });
          }}
        />
        <Box as="ul" className="brief-assets__new-files">
          {newFiles.map(({ name, mimeType }) => {
            return (
              <FileListItem key={name} fileName={name} mimeType={mimeType}>
                <IconButton
                  icon={faTimes}
                  color={COLORS.RED500}
                  onClick={() => {
                    setInputValue({
                      assets,
                      newFiles: newFiles.filter((file): boolean => name !== file.name),
                    });
                  }}
                />
              </FileListItem>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default BriefAssetsInput;

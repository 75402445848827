import { SelectValue } from 'shared/components/molecules/Select';
import { getSelectInputSchema } from 'shared/components/molecules/Select/SelectInput';
import * as yup from 'yup';

const emptySelectOption: (name: string) => Readonly<SelectValue<any>> = (name) =>
  Object.freeze({
    label: `Select ${name}`,
    value: '',
    isDisabled: true,
  });

export const FIELDNAMES = {
  ORGANIZATION: 'organization',
  ACCOUNT: 'account',
  BRIEF: 'brief',
};

export const initialValues: InviteCreatorValues = {
  organization: emptySelectOption('Organization'),
  account: emptySelectOption('Account'),
  brief: emptySelectOption('Brief'),
};

export type InviteCreatorValues = {
  organization: {
    value: number;
    label: string;
  };
  account: {
    value: number;
    label: string;
  };
  brief: {
    value: number;
    label: string;
  };
};

export const validationSchema = yup.object().shape({
  organization: getSelectInputSchema(false),
  account: getSelectInputSchema(false),
  brief: getSelectInputSchema(true),
});

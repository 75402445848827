import { useEffect, useMemo } from 'react';

import axiosClient from 'shared/axiosClient';
import { Constants } from 'shared/blobstorage';
import { API_FILE_ROUTES } from 'shared/config/routes/api/apiFileRoutes';
import { LOCAL_STORAGE } from 'shared/config/storage';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';

const PERMISSIONS = 'racw'; // read, add, create, write, list --> delete omitted

export const useSasToken = (): string | undefined => {
  const { value: sasToken, setVal } = useLocalStorage<string>(LOCAL_STORAGE.SAS_TOKEN);

  const expiryDate = useMemo(() => {
    if (sasToken) {
      const xpDate = new URLSearchParams(sasToken).get('se');
      if (xpDate) return new Date(xpDate);
    }
    return null;
  }, [sasToken]);

  const hasExpired = expiryDate ? expiryDate < new Date() : false;

  useEffect(() => {
    const createToken = async () => {
      const {
        data: { token },
      } = await axiosClient.httpRequest<{ token: string }>({
        url: API_FILE_ROUTES.GENERATE_SAS_TOKEN,
        method: 'POST',
        data: {
          container: Constants.CONTAINER_NAME,
          permissions: PERMISSIONS,
        },
      });
      setVal(token);
    };

    if (!sasToken || hasExpired) createToken();
  }, [sasToken, hasExpired]);

  return sasToken;
};

import { AttributionWindow } from 'features/performance/enums';

export const ATTRIBUTION_WINDOW_FIELDNAMES = {
  VIEW: 'view',
  CLICK: 'click',
};

export const ATTRIBUTION_INITIAL_VALUES = {
  view: AttributionWindow.DEFAULT,
  click: AttributionWindow.DEFAULT,
};

export const VIEW_ATTRIBUTION_OPTIONS = [
  {
    label: '1 Day',
    value: AttributionWindow.ONE_DAY_VIEW,
  },
];

export const CLICK_ATTRIBUTION_OPTIONS = [
  {
    label: '7 Day',
    value: AttributionWindow.SEVEN_DAY_CLICK,
  },
  {
    label: '1 Day',
    value: AttributionWindow.ONE_DAY_CLICK,
  },
];

export interface AttributionValues {
  view: AttributionWindow;
  click: AttributionWindow;
}

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

const scoreMap = [
  { backgroundColor: COLORS.RED500, width: '20%' },
  { backgroundColor: COLORS.RED500, width: '40%' },
  { backgroundColor: COLORS.ORANGE500, width: '60%' },
  { backgroundColor: COLORS.ORANGE500, width: '80%' },
  { backgroundColor: COLORS.GREEN500, width: '100%' },
];

const addScoreCss = () => {
  return scoreMap.reduce((styles, scoreStyle, score) => {
    styles[`&__strength-bar-${score}`] = scoreStyle;
    return styles;
  }, {});
};

export default css({
  '&.password-input': {
    position: 'relative',
  },
  '.password-input': {
    '&__strength-bar': {
      position: 'absolute',
      left: 0,
      top: '46px',
      zIndex: zIndex.zForeground,
      height: '4px',
      transition: 'width 0.5s',
      backgroundColor: 'transparent',
      width: '0%',
    },
    ...addScoreCss(),
    '&__toggle-btn': {
      position: 'absolute',
      right: '16px',
      top: '2px',
      marginTop: theme.space[4],
    },
  },
});

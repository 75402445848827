import React, { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { TailSpin } from 'react-loader-spinner';
import { faPencilAlt, faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import CollectionFormModal from 'features/media/components/CollectionFormModal/CollectionFormModal';
import editCollectionNameStyles from 'features/media/EditCollectionName/styles';
import { useCollections } from 'features/media/useCollections';
import CopyLink from 'shared/components/atoms/CopyLink/CopyLink';
import { Box } from 'shared/components/display';
import { Text } from 'shared/components/display';
import PromptButton from 'shared/components/molecules/PromptButton';

import { COLLECTION_COPY } from 'shared/config/copy';
import { RESOURCES } from 'shared/config/resourceNames';
import { COLLECTION_TOASTS } from 'shared/config/toasts/collectionToasts';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';

const { NEUTRAL600 } = COLORS;
const { H4 } = TEXT_VARIANTS;

interface Props {
  collectionId: number;
  isEditable?: boolean;
  allowCopy?: boolean;
}

const EditCollectionName: React.FC<Props> = ({ collectionId, isEditable = false, allowCopy = false }) => {
  const { collection, updateCollection, deleteCollection, loadingUpdatedCollection } = useCollections({
    collectionId,
  });

  const { formatMessage } = useIntl();
  const currentUrl = window.location.href;

  const updateName = (collectionObj) => {
    updateCollection({
      data: {
        id: collectionId,
        name: collectionObj.name,
      },
      successToast: {
        message: COLLECTION_TOASTS.COLLECTION_UPDATED,
      },
    });
  };

  return (
    <Box css={editCollectionNameStyles} className="collection-name">
      <Text variant={H4} as="h4" className={cs('endWithEllipsis', 'collection-name__name')}>
        {collection.name}&nbsp;
      </Text>
      {isEditable && (
        <Fragment>
          <CollectionFormModal
            onSubmit={updateName}
            name={collection.name}
            submitText={formatMessage({ id: 'BUTTON_UPDATE' }, { resource: RESOURCES.COLLECTION })}
            title={COLLECTION_COPY.FORM_HEADER_EDIT_COLLECTION}
          >
            <FontAwesomeIcon
              icon={faPencilAlt}
              size="sm"
              color={NEUTRAL600}
              id="edit-name"
              role="button"
              tabIndex={0}
            />
          </CollectionFormModal>
          {!(collection.isBriefCollection || collection.isDefault) ? (
            <PromptButton
              text={COLLECTION_COPY.MODAL_DELETE_COLLECTION}
              onConfirm={deleteCollection}
              confirmButtonText={COLLECTION_COPY.BUTTON_DELETE_COLLECTION}
              ariaLabel="Delete Collection"
              variant={BUTTON_VARIANTS.TEXT_ONLY}
              className="collection-name__delete-btn"
            >
              <FontAwesomeIcon icon={faTrashAlt} size="xs" color={NEUTRAL600} id="delete-collection" />
            </PromptButton>
          ) : (
            <></>
          )}
          {allowCopy && (
            <CopyLink text="Copy Library Collection Link" link={currentUrl} className="collection-name__copy-btn" />
          )}
          <TailSpin visible={!!loadingUpdatedCollection} color={COLORS.NEUTRAL300} height="1rem" width="1rem" />
        </Fragment>
      )}
    </Box>
  );
};

export default EditCollectionName;

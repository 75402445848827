import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '&.account-health': {
    height: '100%',
    backgroundColor: COLORS.TRANSPARENT,
    border: 'none',
    padding: '1rem 0.3rem 1rem 0',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '0 auto',
    gridGap: '0',

    '&--with-select': {
      gridTemplateRows: '40px auto',
      gridGap: '1rem',
    },
  },
  '.account-health': {
    '&__header': {
      width: '80%',
      justifySelf: 'center',
    },
    '&__content': {
      alignSelf: 'start',
      gridRow: '2',
    },
    '&__date-calc': {
      marginLeft: 'auto',
      fontSize: '0.9rem',
      color: COLORS.NEUTRAL500,
    },
    '&__date-calc-icon': {
      marginRight: '0.25rem',
    },
  },
});

import React from 'react';
import { VettingStatus } from 'shared/typings/user/enums';
import Chip from 'shared/components/atoms/Chip/Chip';
import COLORS from 'shared/styles/colors';
import { startCase } from 'lodash';

interface Props {
  vettingStatus: VettingStatus;
}
const CreatorVettingStatus: React.FC<Props> = ({ vettingStatus }) => {
  const label = startCase(vettingStatus.toLowerCase());
  switch (vettingStatus) {
    case VettingStatus.NEW:
      return <Chip label={label} square color={COLORS.SECONDARY500} bgColor={COLORS.SECONDARY50} />;
    case VettingStatus.APPROVED:
      return <Chip label={label} square color={COLORS.GREEN700} bgColor={COLORS.GREEN100} />;
    case VettingStatus.REJECTED:
      return <Chip label={label} square color={COLORS.RED700} bgColor={COLORS.RED50} />;
    case VettingStatus.PENDING:
      return <Chip label={label} square color={COLORS.ORANGE700} bgColor={COLORS.ORANGE100} />;
    default:
      return null;
  }
};

export default CreatorVettingStatus;

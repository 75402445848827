import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '.asset-input': {
    '&__name': {
      margin: 0,
    },
    '&__img': {
      maxHeight: '100px',
      marginRight: theme.space[4],
    },
    '&__input-wrapper': {
      width: '100%',
      alignContent: 'center',
    },
    '&__name-wrapper': {
      alignItems: 'center',
      margin: theme.space[2],
      justifyContent: 'space-between',
    },
    '&__row': {
      marginBottom: theme.space[4],
      alignItems: 'center',
      flexDirection: 'column',

      [mediaquery.md]: {
        flexDirection: 'row',
      },
    },
  },
  'input.underline-input': {
    marginTop: 0,
  },
});

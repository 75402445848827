import React from 'react';
import {
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircle,
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UploadFile } from 'features/ui/ui.slice';
import { Flex, Text } from 'shared/components/display';

import { FILE_COPY } from 'shared/config/copy';
import { intl } from 'shared/lib/intl';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { FileUploadStatus } from 'shared/typings/file/enums';

export const headerStatus = (files: UploadFile[]) => {
  const uploadNotFinished = files?.some(
    (file) =>
      file.status === FileUploadStatus.UPLOAD_FAILED ||
      file.status === FileUploadStatus.UPLOAD_CANCELLED ||
      file.status === FileUploadStatus.UPLOADING ||
      file.status === FileUploadStatus.UPLOAD_ATTEMPTING_CANCEL,
  );

  if (uploadNotFinished) {
    return (
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        color={COLORS.ORANGE500}
        className="upload-status-drawer__header-status-icon"
      />
    );
  }

  return (
    <FontAwesomeIcon
      icon={faCheckCircle}
      color={COLORS.GREEN500}
      className="upload-status-drawer__header-status-icon"
    />
  );
};

export const headerLabel = (uploadComplete: boolean, files: UploadFile[]) => {
  // Still uploading
  if (!uploadComplete) {
    let uploading = 0;
    for (let file of files) {
      if (!file.errors?.length && file.status === FileUploadStatus.UPLOADING) {
        uploading += 1;
      }
    }

    return intl.formatMessage({ id: 'LABEL_UPLOADING_FILES' }, { numFiles: uploading });
  }

  const filesFailed = files.filter(
    (file) => file.status === FileUploadStatus.UPLOAD_FAILED || file.status === FileUploadStatus.UPLOAD_CANCELLED,
  );

  // All failed
  if (filesFailed.length === files.length) {
    return FILE_COPY.LABEL_ALL_UPLOADS_FAILED;
  }

  const uploadedFiles = files.filter((file) => file.status === FileUploadStatus.UPLOADED);

  // Upload finished
  return intl.formatMessage({ id: 'LABEL_FILES_UPLOADED' }, { numFiles: uploadedFiles.length });
};

interface Props {
  isCollapsed: boolean;
  onDrawerArrowClick: (args) => void;
  filesUploading: Array<any>;
  filesUploadComplete: boolean;
  onClear: (args) => void;
}

const UploadStatusDrawerHeader: React.FC<Props> = ({
  isCollapsed,
  onDrawerArrowClick,
  filesUploading,
  filesUploadComplete,
  onClear,
}) => {
  return (
    <Flex className="upload-status-drawer__list-header-wrapper">
      <span className="upload-status-drawer__list-header-open-icon-wrapper fa-layers fa-fw">
        <FontAwesomeIcon
          icon={faCircle}
          color={COLORS.WHITE}
          className="upload-status-drawer__list-header-open-icon-circle"
        />
        <FontAwesomeIcon
          icon={isCollapsed ? faChevronUp : faChevronDown}
          transform="shrink-6"
          onClick={onDrawerArrowClick}
        />
      </span>
      {headerStatus(filesUploading)}
      <Text as="h3" variant={TEXT_VARIANTS.BODY} className="upload-status-drawer__list-header">
        {headerLabel(filesUploadComplete, filesUploading)}
      </Text>
      <FontAwesomeIcon icon={faTimes} className="upload-status-drawer__list-header-close-icon" onClick={onClear} />
    </Flex>
  );
};

export default UploadStatusDrawerHeader;

import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  flexDirection: 'column',
  '.video-thumbnail-picker': {
    '&__preview': {
      width: '100%',
      justifyContent: 'space-evenly',
      textAlign: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      [mediaquery.md]: {
        flexDirection: 'row',
      },
    },
    '&__canvas': {
      marginTop: theme.space[4],
      [mediaquery.md]: {
        margin: 0,
      },
    },
    '&__button': {
      alignSelf: 'center',
      margin: `${theme.space[5]} 0`,
    },
  },
});

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.facebook-nav': {
    '&__item p': {
      fontWeight: 600,
      fontSize: theme.fontSizes[3],
    },
    '&__sub-item p': {
      fontWeight: 600,
      fontSize: theme.fontSizes[3],
    },
    '&__sub-item': {
      marginLeft: theme.space[5],
    },
    '&__tooltip': {
      display: 'inline',
    },
  },
});

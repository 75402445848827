import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import { zIndex } from 'shared/config';
import { STYLES } from 'shared/styles/styles';

export const mainLinkStyles = css({
  '&.nav-links__main': {
    listStyleType: 'none',
    margin: 0,
    padding: '0 20%',
    marginTop: '50px',
    overflowY: 'auto',
    flex: 1,
    [mediaquery.md]: {
      margin: 0,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
    },
    '& li': {
      display: 'list-item',
      [mediaquery.md]: {
        margin: '0 6px',
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
      },
    },
    '& a': {
      display: 'inline-block',
    },
    '& a p': {
      width: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      [mediaquery.md]: {
        fontSize: '14px',
        fontWeight: 400,
        padding: '0 6px',
      },
    },
    '& a.active p': {
      fontWeight: 600,
      color: COLORS.PRIMARY500,
      position: 'relative',
      [mediaquery.md]: {
        fontWeight: 600,
        color: COLORS.PRIMARY500,
        position: 'unset',
      },
    },
    '& a.active p::before': {
      content: "''",
      backgroundColor: COLORS.PRIMARY500,
      width: '4px',
      height: '75%',
      position: 'absolute',
      left: '-24px',
      [mediaquery.md]: {
        width: '100%',
        height: '4px',
        left: 0,
        bottom: 0,
      },
    },
    '& a > *[data-badge]::after': {
      [mediaquery.md]: {
        fontSize: '10px',
        marginLeft: '6px',
      },
    },
  },
});

export const secondaryLinkStyles = css({
  '&.nav-links__secondary': {
    listStyleType: 'none',
    display: 'block',
    margin: 0,
    padding: '0 20%',
    backgroundColor: 'white',
    borderTop: STYLES.BORDER_NEUTRAL,
    flex: 1,
    [mediaquery.md]: {
      display: 'none',
    },
    '& li': {
      display: 'list-item',
      margin: '16px 0',
    },
    '& a': {
      display: 'inline-block',
    },
    '& a > *, & *:not(a)': {
      width: 'fit-content',
    },
  },
  // Styles for when secondary links are not proceeded by main links
  '.nav-links:not(:has(.nav-links__main)) &.nav-links__secondary': {
    marginTop: '50px',
    borderTop: 'none',
  },
});

export default css({
  '&.nav-links': {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: zIndex.zNav,
    overflow: 'hidden',
    backgroundColor: 'white',
    width: '100%',
    height: '100vh',
    maxHeight: 0,
    transition: 'max-height 0.2s ease-in-out',
    [mediaquery.md]: {
      maxHeight: '100%',
      width: 'auto',
      backgroundColor: 'transparent',
      zIndex: 'initial',
      whiteSpace: 'nowrap',
      left: '50%',
      transform: 'translateX(-50%)',
      transition: 'none',
      height: '100%',
    },
    '&--open': {
      maxHeight: ['calc(100 * var(--vh))', '100vh'],
      [mediaquery.md]: {
        maxHeight: '100%',
      },
    },
  },
  '.nav-links': {
    '&__wrapper': {
      height: ['calc(100 * var(--vh))', '100vh'],
      width: '100%',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      [mediaquery.md]: {
        height: '100%',
        position: 'initial',
      },
    },
  },
});

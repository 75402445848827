import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { isEqual } from 'lodash';

import {
  collectionAdded,
  collectionChanged,
  collectionLoaded,
  collectionRemoved,
  collectionsLoaded,
  getCollection,
  getCollectionCount,
  getCollections,
  getDefaultCollection,
  collectionsCleared,
} from 'features/media/collections.slice';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_COLLECTION_ROUTES } from 'shared/config/routes/api/apiCollectionRoutes';
import { COLLECTION_TOASTS } from 'shared/config/toasts/collectionToasts';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { ApiOnSuccess } from 'shared/typings/api';
import { Collection } from 'shared/typings/collections';
import { LinkCreator } from 'shared/utilities/linkUtility';
const newCollectionEntity = 'newCollection';
const updateCollectionEntity = 'updateCollection';

export const useCollections = ({
  collectionId,
  accountId,
  organizationId,
  accountPlatformId,
}: {
  collectionId?: number;
  accountId?: number;
  organizationId?: number;
  accountPlatformId?: number;
} = {}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeParams = useParams<any>();
  const [prevParams, setPrevParams] = useState({});
  const { userType, currentUser } = useCurrentUser();
  collectionId = +(collectionId || routeParams.collectionId);
  organizationId = +(organizationId || routeParams.organizationId);
  accountId = +(accountId || routeParams.accountId);
  accountPlatformId = +(accountPlatformId || routeParams.accountPlatformId);
  const entity = RESOURCES.COLLECTION;
  const { loading } = useComponentLoading(entity, true);
  const { loading: loadingNewCollection } = useComponentLoading(newCollectionEntity, false);
  const { loading: loadingUpdatedCollection } = useComponentLoading(updateCollectionEntity, false);

  const collections: Collection[] = useSelector(getCollections);
  // TODO: Fix Types
  // @ts-ignore
  const defaultCollection: Collection = useSelector(getDefaultCollection);
  const totalCollections: number = useSelector(getCollectionCount);
  // TODO: Fix Types
  // @ts-ignore
  const collection: Collection = useSelector(getCollection(collectionId));

  const getFilteredCollections = (params: any, onSuccess?: ApiOnSuccess) => {
    setPrevParams(params);
    if (!isEqual(params, prevParams)) {
      dispatch(
        apiAction({
          path: {
            route: API_COLLECTION_ROUTES.COLLECTIONS,
          },
          params,
          successAction: collectionsLoaded,
          entity,
          onSuccess,
        }),
      );
    }
  };

  const createCollection = (data: Pick<Collection, 'name'>) => {
    const userSpecificIds = accountId
      ? {
          accountId,
          organizationId,
        }
      : { creatorId: currentUser.id };
    dispatch(
      apiAction({
        path: {
          route: API_COLLECTION_ROUTES.COLLECTIONS,
        },
        method: 'POST',
        data: {
          ...data,
          ...userSpecificIds,
        },
        entity: newCollectionEntity,
        successAction: collectionAdded,
        successToast: {
          message: COLLECTION_TOASTS.COLLECTION_CREATED,
        },
      }),
    );
  };

  const deleteCollection = () => {
    const pathToAssetLibrary = LinkCreator.createLink({
      userType,
      routeKey: 'ASSETS_LIBRARY',
      variables: {
        accountId,
        organizationId,
        accountPlatformId,
      },
    });

    dispatch(
      apiAction({
        path: {
          route: API_COLLECTION_ROUTES.COLLECTION,
          variables: {
            collectionId,
          },
        },
        method: 'DELETE',
        data: { id: collectionId },
        successAction: collectionRemoved,
        entity,
        navigate,
        pushFunction: () => pathToAssetLibrary,
        successToast: {
          message: COLLECTION_TOASTS.COLLECTION_DELETED,
        },
      }),
    );
  };

  const updateCollection = ({ data, successToast }) => {
    dispatch(
      apiAction({
        path: {
          route: API_COLLECTION_ROUTES.COLLECTION,
          variables: {
            collectionId,
          },
        },
        method: 'PATCH',
        data,
        entity: updateCollectionEntity,
        successAction: collectionChanged,
        successToast,
      }),
    );
  };

  const fetchCollection = (collectionId: number) => {
    dispatch(
      apiAction({
        path: {
          route: API_COLLECTION_ROUTES.COLLECTION,
          variables: {
            collectionId,
          },
        },
        entity,
        successAction: collectionLoaded,
      }),
    );
  };

  const clearCollections = () => {
    dispatch(collectionsCleared({}));
  };

  return {
    collection,
    collections,
    totalCollections,
    defaultCollection,
    loading,
    loadingNewCollection,
    loadingUpdatedCollection,
    getFilteredCollections,
    createCollection,
    deleteCollection,
    updateCollection,
    fetchCollection,
    clearCollections,
  };
};

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '&.sm-handle': {
    position: 'relative',
  },
  '.sm-handle': {
    '&__at': {
      position: 'absolute',
      top: '20px',
      left: '10px',
      fontWeight: 'bold',
      color: COLORS.BLACK,
    },
  },
  '#sm-handle__input': {
    paddingLeft: '28px',
  },
});

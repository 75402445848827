// ========================================================================================
/*                                                                                      *
 * ATOM: TEXT                                                                            *
 *                                                                                      */
// ========================================================================================
// * Lib
// * Styles
import styled from '@emotion/styled';
import theme from 'shared/styles/themes';
import React, { ReactNode } from 'react';
import { variant } from 'styled-system';
// * Display/UI
import Base from './Base.display';

interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  className?: string;
  variant?: any;
  as?: string;
  color?: string;
  id?: string;
  css?: any;
  htmlFor?: string;
}

const StyledText = styled(Base)(
  variant({
    scale: 'text',
    variants: theme.text,
  }),
);

const Text = React.forwardRef<any, Props>((props, ref) => (
  <StyledText as="p" data-display="Text" {...props} ref={ref} />
));

export default Text;

import { useEffect } from 'react';

type LogoutFunction = (...args: any) => void;

export function useSessionLogout(signOut: LogoutFunction = () => {}): void {
  useEffect(() => {
    const handleInvalidToken = ({
      key,
      oldValue,
      newValue,
    }: StorageEvent): void => {
      if (key === 'token') {
        if (oldValue && !newValue) {
          // Logged out
          signOut();
        } else if (oldValue !== newValue) {
          // Signed into new account
          window.location.assign('/');
        }
      }
    };

    window.addEventListener('storage', handleInvalidToken);

    return function cleanup(): void {
      window.removeEventListener('storage', handleInvalidToken);
    };
  }, [signOut]);
}

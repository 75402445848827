import React from 'react';
import { components, MultiValueGenericProps } from 'react-select';

import SmallCreatorPreview from 'features/users/components/SmallCreatorPreview/SmallCreatorPreview';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';

import { CreatorSelectData } from './interfaces';

const MultiValueLabel: React.FC<MultiValueGenericProps<CreatorSelectData>> = ({ ...selectProps }) => {
  const { isSuperadmin } = useCurrentUser();
  return (
    <components.MultiValueLabel {...selectProps}>
      <SmallCreatorPreview user={selectProps.data.value} showFullName showEmail={isSuperadmin} fixedHeight />
    </components.MultiValueLabel>
  );
};

export default MultiValueLabel;

import jwtDecode from 'jwt-decode';
import { LOCAL_STORAGE } from 'shared/config/storage';
import { deleteBearerToken } from 'shared/utilities/networkUtility';

export function getExpirationDate(tokenString: string) {
  const decodedJWT = jwtDecode<{ exp: number }>(tokenString);
  return new Date(decodedJWT.exp * 1000);
}

export function isTokenExpired(tokenString: string) {
  const now = new Date();
  const expirationDate = getExpirationDate(tokenString);
  return now > expirationDate;
}

export function storeToken(tokenString: string) {
  localStorage.setItem(LOCAL_STORAGE.TOKEN, tokenString);
}

export function deleteAllTokens() {
  localStorage.removeItem(LOCAL_STORAGE.TOKEN);
  deleteBearerToken();
}

import { css } from '@emotion/react';

import COLORS, { FACEBOOK_COLORS } from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.ad-health-sign-in': {
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.NEUTRAL50,
    alignContent: 'center',
    input: { borderRadius: '6px', borderColor: COLORS.NEUTRAL300 },
    'input::placeholder': {
      color: COLORS.NEUTRAL500,
    },
    overflowY: 'auto',
  },
  '.ad-health-sign-in': {
    '&__form': {
      flexDirection: 'column',
      margin: '25px',
      padding: '48px 36px 48px 36px',
      borderRadius: '16px',
      [mediaquery.md]: {
        maxWidth: '760px',
        flexDirection: 'column',
        margin: 'auto',
        padding: '48px 36px 36px 36px',
        borderRadius: '16px',
      },
    },
    '&__header': {
      fontSize: theme.fontSizes[5],
      lineHeight: '1.2',
      [mediaquery.md]: { fontSize: theme.fontSizes[6] },
    },
    '&__fb-btn': {
      backgroundColor: FACEBOOK_COLORS.BLUE,
      [mediaquery.md]: {
        width: '330px',
      },
    },
    '&__fb-icon': {
      marginRight: '20px',
    },
    '&__text-wrapper': {
      flexDirection: 'column',
      justifyContent: 'space-between',
      [mediaquery.md]: {
        paddingRight: '30px',
      },
    },
    '&__pitch': {
      lineHeight: '1.2',
    },
    '&__list-item': {
      marginBottom: '10px',
      gap: '0.5rem',
      alignItems: 'center',
      [mediaquery.md]: {
        marginBottom: 0,
        gap: '0.5rem',
        alignItems: 'center',
      },
      p: {
        marginBottom: 0,
        lineHeight: '1.2',
        color: COLORS.NEUTRAL900,
        [mediaquery.md]: {
          lineHeight: '1.2',
          color: COLORS.NEUTRAL900,
        },
      },
    },
    '&__line': {
      width: '100%',
      backgroundColor: COLORS.NEUTRAL200,
      height: '1px',
      justifyContent: 'center',
      alignContent: 'center',
      margin: '16px 0',
    },
    '&__body': {
      flexDirection: 'column-reverse',
      [mediaquery.md]: {
        flexDirection: 'row',
      },
    },
    '&__description': {
      lineHeight: '1.5',
    },
    '&__image': {
      marginBottom: '24px',
      [mediaquery.md]: {
        width: '250px',
        height: 'auto',
      },
    },
    '&__footer': {
      marginTop: '24px',
      padding: '10px',
      backgroundColor: COLORS.NEUTRAL50,
      border: `1px solid ${COLORS.NEUTRAL100}`,
      borderRadius: '8px',
      alignItems: 'center',
      gap: '10px',
      '&__message': {
        marginBottom: 0,
        lineHeight: '1.2',
        fontSize: theme.fontSizes[1],
      },
    },
  },
});

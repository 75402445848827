import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '&.spotlight-creator-badge': {
    width: '30px',
    height: '30px',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center',
    '&--hover:hover': {
      backgroundColor: COLORS.NEUTRAL100,
    },
  },
  '.spotlight-creator-badge__icon': {
    height: '20px',
    width: 'auto',
  },
});

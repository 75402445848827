import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  flexDirection: 'column',
  '.brief-modules-form': {
    '&__module-label-wrapper': {
      maxWidth: '800px',
    },
    '&__remove-btn': {
      paddingTop: '0.4rem',
      paddingRight: 0,
      paddingLeft: 0,
      marginLeft: '10px',
    },
    '&__options-wrapper': {
      marginLeft: 'auto',
      padding: 0,
    },
    '&__add-btn': {
      marginTop: '20px',
      [mediaquery.md]: {
        marginRight: 'auto',
      },
    },
    '&__module': {
      flexDirection: 'column',
      marginBottom: '16px',
      '.module-tags': {
        maxWidth: '800px',
      },
    },
    '&__order-icons-wrapper': {
      alignItems: 'center',
    },
    '&__order-icon-up': {
      padding: '10px',
      margin: '-10px 0',
      cursor: 'pointer',
      '&:hover': {
        color: COLORS.PRIMARY500,
      },
    },
    '&__order-icon-down': {
      padding: '10px',
      margin: '-10px 0',
      cursor: 'pointer',
      '&:hover': {
        color: COLORS.RED500,
      },
    },
    '&__order-icon--disabled': {
      cursor: 'default',
      '&:hover': {
        color: COLORS.NEUTRAL200,
      },
    },
  },
});

import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { filesAdded, fileUpdated } from 'features/media/files.slice';
import { useFiles } from 'features/media/useFiles';
import { componentLoadingToggled, updateFileUploadProgress } from 'features/ui/ui.slice';

import { RESOURCES } from 'shared/config/resourceNames';
import { SignalRContext } from 'shared/contexts/signalr';
import { useToasts } from 'shared/hooks/useToasts';
import { BrkfstFile } from 'shared/typings/file';
import { FileUploadStatus } from 'shared/typings/file/enums';

import { useCurrentUser } from './useCurrentUser';

interface Params {
  updateCollection?: boolean;
  selectUploadedFiles?: boolean;
  onFileUploaded?: (file: BrkfstFile) => void;
}

export const SIGNALR_NOTIFICATION_TIME_SPAN = 3500; // milliseconds
const fileUpdateEntity = RESOURCES.FILE_UPDATE;

export const useFileNotification = ({
  updateCollection = true,
  selectUploadedFiles = true,
  onFileUploaded,
}: Params = {}): void => {
  const { connection } = useContext(SignalRContext);
  const dispatch = useDispatch();
  const { updateFileUpload } = useFiles();
  const { setErrorToast } = useToasts();
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const fileHandler = (file) => {
      const {
        key,
        collectionIds: [collectionId],
      } = file;
      dispatch(
        filesAdded({
          data: { ...file, creatorFirstName: currentUser.firstName, creatorLastName: currentUser.lastName },
          params: {
            collectionId,
            updateCollection,
            selectUploadedFiles,
          },
        }),
      );
      updateFileUpload(key, FileUploadStatus.UPLOADED);
      if (onFileUploaded) onFileUploaded(file);
    };

    const fileErrorHandler = ({ error, key }) => {
      updateFileUpload(key, FileUploadStatus.UPLOAD_FAILED, error);
    };

    const videoCompositionErrorHandler = ({ error, key }) => {
      setErrorToast({
        message: error,
      });
      updateFileUpload(key, FileUploadStatus.UPLOAD_FAILED, error);
    };

    const thumbnailHandler = ({ fileId, thumbnailUrl, thumbnailOffset }) => {
      dispatch(
        fileUpdated({
          data: {
            id: fileId,
            thumbnailUrl,
            thumbnailOffset,
          },
        }),
      );
      dispatch(
        componentLoadingToggled({
          component: fileUpdateEntity,
          loading: false,
        }),
      );
    };

    const progressHandler = ({ key, progress }) => {
      dispatch(updateFileUploadProgress({ id: key, progress: +progress }));
    };

    if (connection) {
      connection.on('newFile', fileHandler);
      connection.on('newThumbnail', thumbnailHandler);
      connection.on('fileError', fileErrorHandler);
      connection.on('videoCompositionError', videoCompositionErrorHandler);
      connection.on('fileProgress', progressHandler);
    }

    return function cleanup() {
      connection?.off('newFile', fileHandler);
      connection?.off('newThumbnail', thumbnailHandler);
      connection?.off('fileError', fileErrorHandler);
      connection?.off('videoCompositionError', videoCompositionErrorHandler);
      connection?.off('fileProgress', progressHandler);
    };
  }, [connection, onFileUploaded, currentUser.firstName, currentUser.lastName]);
};

import * as yup from 'yup';

import { Breakdowns, TopBottomFieldnames } from 'features/performance/enums';
import { getSelectInputSchema } from 'shared/components/molecules/Select/SelectInput';

import { intl } from 'shared/lib/intl';

import { TopBottomAnalysisValues } from './types';

const baseSelectSchema = getSelectInputSchema(false, yup.string());
const requiredSelectSchema = getSelectInputSchema(true, yup.string());

const MIN_TOP_BOTTOM_AMOUNT = 1;
const MAX_TOP_BOTTOM_AMOUNT = 20;

export const topBottomSchema: yup.SchemaOf<TopBottomAnalysisValues> = yup.object({
  topBottomAmount: yup
    .number()
    .required()
    .min(
      MIN_TOP_BOTTOM_AMOUNT,
      intl.formatMessage(
        { id: 'MIN' },
        {
          min: MIN_TOP_BOTTOM_AMOUNT,
        },
      ),
    )
    .max(
      MAX_TOP_BOTTOM_AMOUNT,
      intl.formatMessage(
        { id: 'MAX' },
        {
          max: MAX_TOP_BOTTOM_AMOUNT,
        },
      ),
    ),
  rankBy: requiredSelectSchema,
  gender: baseSelectSchema.when([TopBottomFieldnames.BREAKDOWN], {
    is: (breakdownValue) => [Breakdowns.GENDER, Breakdowns.AGE_GENDER].includes(breakdownValue),
    then: requiredSelectSchema,
    otherwise: baseSelectSchema,
  }),
  age: baseSelectSchema.when([TopBottomFieldnames.BREAKDOWN], {
    is: (breakdownValue) => [Breakdowns.AGE, Breakdowns.AGE_GENDER].includes(breakdownValue),
    then: requiredSelectSchema,
    otherwise: baseSelectSchema,
  }),
  publisher_platform: baseSelectSchema.when([TopBottomFieldnames.BREAKDOWN], {
    is: (breakdownValue) => breakdownValue === Breakdowns.PLACEMENT,
    then: requiredSelectSchema,
    otherwise: baseSelectSchema,
  }),
  platform_position: baseSelectSchema.when([TopBottomFieldnames.BREAKDOWN], {
    is: (breakdownValue) => breakdownValue === Breakdowns.PLACEMENT,
    then: requiredSelectSchema,
    otherwise: baseSelectSchema,
  }),
  device_platform: baseSelectSchema.when([TopBottomFieldnames.BREAKDOWN], {
    is: (breakdownValue) => breakdownValue === Breakdowns.PLACEMENT,
    then: requiredSelectSchema,
    otherwise: baseSelectSchema,
  }),
  breakdown: yup.string(),
});

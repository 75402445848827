import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.selected-file-popover': {
    '& .selected-file-actions': {
      flexDirection: 'column',
    },
  },
  '.selected-file-popover': {
    '&__btn': {
      display: 'flex',
      alignItems: 'center',
      padding: '2px',
    },
    '&__btn > *:nth-of-type(1)': {
      marginRight: theme.space[2],
    },
  },
});

export const selectedFilePopoverContentStyles = {
  '&.selected-file-popover__content-wrapper': {
    padding: theme.space[3],
  },
};

import { FILE_COPY } from 'shared/config/copy';
import { GENERAL_ROUTES } from 'shared/config/routes/generalRoutes';

export const STATUS_CODE = Object.freeze({ PAYLOAD_TOO_LARGE: 413 });

// Use to handle error that cannot be caught by our backend node application.
// example: 413 Payload too large sent by Azure web server.
export const ERROR_MESSAGE = Object.freeze({
  [STATUS_CODE.PAYLOAD_TOO_LARGE]: FILE_COPY.WARNING_FILE_SIZE,
});

/*
    Takes in an error and joins all the errors into one String
    Errors will be located in format err.response.data.errors
 */

export function parseError(err) {
  if (err.response) {
    const { data, status } = err.response;
    const exclusiveErrorMessage = ERROR_MESSAGE[+status];
    if (status && exclusiveErrorMessage) {
      return exclusiveErrorMessage;
    }
    if (!data.validations || data.validations.length === 0) return data.message;
    return data.validations.map((validation) => validation.msg).join('\n');
  }
  return '';
}

export function getErrorStatusCode(err) {
  const { response: { data, status } = {} } = err;
  if (status) {
    return +status;
  }
  if (data && data.status) {
    return +data.status;
  }
  return null;
}

export function requiresSignIn(err) {
  const { response: { data } = {} } = err;
  if (data && data.json && 'action' in data.json) {
    return data.json.action && data.json.action === 'redirectToSignIn';
  }
  return false;
}

export function requiresSignUp(err) {
  const {
    response: {
      data: { signUp },
    },
  } = err;
  return !!signUp;
}

export function requiresRedirectToHome(err) {
  const { response: { data } = {} } = err;
  if (data.json && 'action' in data.json) {
    return data.json.action && data.json.action === 'redirectToHome';
  }
  return false;
}

export function isOnErrorPage() {
  const errorPageRoutes = [GENERAL_ROUTES.NO_ACCESS, GENERAL_ROUTES.NOT_FOUND];
  return errorPageRoutes.includes(window.location.pathname);
}

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '.brief-viewing-modal': {
    '&__modal-content': {
      padding: `0 ${theme.space[5]}`,
    },
    '&__btn': {
      marginBottom: '16px',
      textTransform: 'uppercase',
      float: 'right',
      [mediaquery.md]: {
        display: 'none',
      },
    },
  },
});

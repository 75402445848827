import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';

import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';

import { Box } from 'shared/components/display';

import getStyles from './styles';

const TOOL_TIP_DURATION = [200, 200] as [number | null, number | null];

export type TooltipPlacement =
  | 'bottom-end'
  | 'bottom'
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'right'
  | 'left'
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'right-start'
  | 'right-end'
  | 'left-start'
  | 'left-end';

interface Props extends Omit<TippyProps, 'children'> {
  as?: string;
  children: any;
  touch?: boolean | 'hold' | ['hold', number];
  width?: string | number;
  css?: any;
}

/** @deprecated use NewTooltip.tsx instead */
const Tooltip: React.FC<Props> = ({
  children,
  content,
  arrow = false,
  offset = [0, 10],
  className,
  as,
  width,
  css,
  ...settings
}) => {
  return (
    <Tippy arrow={arrow} content={content} {...settings} duration={TOOL_TIP_DURATION} offset={offset}>
      {/* Tippy requires you to forward the ref to the child component.
          A way to get around this, is to wrap the child in a container. */}
      <Box as={as} css={css || getStyles(width)} className={className}>
        {children}
      </Box>
    </Tippy>
  );
};

export default Tooltip;

import React, { useMemo } from 'react';
import { Box } from 'rebass';
import cs from 'classnames';

import FileLightboxFooter from 'features/media/components/molecules/FileLightboxFooter';
import Gallery from 'features/media/components/organisms/Gallery';
import { GalleryItem, GallerySelectableProps } from 'features/media/interfaces/gallery';
import Skeleton from 'shared/components/atoms/Skeleton';
import { useGalleryListView, VIEWS } from 'shared/components/molecules/ToggleGalleryListView';
import MediaListView from 'shared/components/organisms/MediaListView';

import { BrkfstFile } from 'shared/typings/file';

import styles from './styles';

interface Props {
  data: BrkfstFile[];
  formatGalleryItems: (files: BrkfstFile) => GalleryItem;
  placeholderText?: string;
  numLoadingItems?: number; // for new file uploading
  renderLightbox?: boolean;
  loading?: boolean; // for initial loading
  useBrandName?: boolean;
  showPin?: boolean;
  onlyImage?: boolean;
  forceViewMode?: 'Gallery' | 'List' | '';
  onItemClick?: (index: number) => void;
  LightboxCaption?: React.ReactElement<any>;
}

const MediaGalleryAndListDisplay: React.FC<Props & GallerySelectableProps> = ({
  data,
  formatGalleryItems,
  selectedFileIds,
  updateSelectedItems,
  numLoadingItems,
  placeholderText,
  isSelectable = false,
  renderLightbox,
  loading = false,
  useBrandName = false,
  showPin = false,
  onlyImage,
  forceViewMode,
  onItemClick,
  LightboxCaption = <FileLightboxFooter />,
}) => {
  const { viewMode } = useGalleryListView(forceViewMode);

  const galleryItems = useMemo(() => {
    return data?.length ? data.map(formatGalleryItems) : [];
  }, [data]);

  if (viewMode === VIEWS.LIST.value) {
    if (loading) {
      return (
        <Box
          // @ts-ignore
          css={styles}
          className={cs('media-gallery-and-list-display', 'media-gallery-and-list-display__loading-skeleton--list')}
        >
          <Skeleton height={30} width="100%" />
          <Skeleton height={30} width="100%" />
          <Skeleton height={30} width="100%" />
        </Box>
      );
    }
    return (
      <MediaListView
        numLoadingItems={numLoadingItems}
        galleryItems={galleryItems}
        selectedFileIds={selectedFileIds}
        updateSelectedItems={updateSelectedItems}
        isSelectable={isSelectable}
        placeholderText={placeholderText}
        renderLightbox={renderLightbox}
        LightboxCaption={LightboxCaption}
        useBrandName={useBrandName}
        showPin={showPin}
        onItemClick={onItemClick}
      />
    );
  }
  if (viewMode === VIEWS.GALLERY.value) {
    if (loading) {
      return (
        <Box
          // @ts-ignore
          css={styles}
          className={cs('media-gallery-and-list-display', 'media-gallery-and-list-display__loading-skeleton--gallery')}
        >
          <Skeleton height={150} width={150} />
          <Skeleton height={150} width={150} />
          <Skeleton height={150} width={150} />
        </Box>
      );
    }
    return (
      <Gallery
        items={galleryItems}
        isSelectable={isSelectable}
        numLoadingItems={numLoadingItems}
        placeholderText={placeholderText}
        selectedItems={selectedFileIds}
        updateSelectedItems={updateSelectedItems}
        renderLightbox={renderLightbox}
        LightboxCaption={onlyImage ? undefined : LightboxCaption}
        useBrandName={useBrandName}
        showPin={showPin}
        onlyImage={onlyImage}
        onItemClick={onItemClick}
      />
    );
  }

  return <></>;
};

export default MediaGalleryAndListDisplay;

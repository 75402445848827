import React, { useState } from 'react';
import cs from 'classnames';

import { Box } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import numberInputStyles from 'shared/components/molecules/NumberInput/numberInputStyles';

import { Input as InputType } from 'shared/lib/formik';
import Input from 'shared/styles/Input/Input';
import InputLabel from 'shared/styles/InputLabel/InputLabel';

interface Props {
  placeholder?: string;
  disabled?: boolean;
  label: string;
  className?: string;
}
const NumberInput: InputType<number, Props> = ({
  field: { value, name, onChange, onBlur },
  form: { touched, errors },
  label,
  placeholder,
  disabled,
  className = '',
}) => {
  const [active, setActive] = useState(false);
  const error = errors[name];

  const handleFocus = () => {
    setActive(true);
  };

  const handleChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/gi, '');
    onChange({ target: { name, value } });
  };

  return (
    <Box className={cs(className, 'number-input')} css={numberInputStyles}>
      <InputLabel
        htmlFor={name}
        disabled={disabled}
        active={active}
        hasValue={Boolean(value)}
        isInvalid={touched[name] && !!error}
      >
        {label}
      </InputLabel>
      <Input
        placeholder={placeholder}
        value={value}
        type="text"
        disabled={disabled}
        onFocus={handleFocus}
        onBlur={(e) => {
          e.persist();
          setActive(false);
          onBlur(e);
        }}
        name={name}
        data-lpignore={true}
        onChange={handleChange}
      />
      {error && <FieldErrorMessage name={name} />}
    </Box>
  );
};

export default NumberInput;

import React from 'react';
import cs from 'classnames';
import { Field, Form, Formik } from 'formik';

import {
  facebookFilterOptions,
  FIELD,
  filterSchema,
  FilterValues,
  idFilterOptions,
  initialValues,
  nameFilterOptions,
  OPERATOR,
  VALUE,
} from 'features/performance/FilterFormDropdown/config';
import filterFormStyles from 'features/performance/FilterFormDropdown/filterFormStyles';
import { TagNumber } from 'shared/components/atoms/Tags';
import { Box } from 'shared/components/display';
import { ArrayErrorMessage } from 'shared/components/FieldErrorMessage';
import FormButton from 'shared/components/FormButton';
import { SelectInput } from 'shared/components/molecules/Select';
import { TagInput } from 'shared/components/molecules/TagInput';
import TextInput from 'shared/components/TextInput';

import { makeLabel } from 'shared/lib/formik';

interface Props {
  onSubmit: (values: FilterValues) => void;
}

const valueLabel = makeLabel(VALUE, true);
const operatorLabel = makeLabel(OPERATOR, true);

const FacebookFilterForm: React.FC<Props> = ({ onSubmit }) => {
  return (
    <Formik<FilterValues>
      validationSchema={filterSchema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(false);
        onSubmit(values);
      }}
      initialValues={initialValues}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const chosenField = formikProps.values[FIELD];
        const idFieldSelected = chosenField.value.includes('.id');
        const nameFieldSelected = chosenField.value.includes('.name');
        return (
          <Form>
            <Box className="filter-form" css={filterFormStyles}>
              <Field
                menuPortalTarget="filter-form"
                name={FIELD}
                component={SelectInput}
                options={facebookFilterOptions}
                placeholder="Choose Field*"
              />

              <Field
                menuPortalTarget="filter-form"
                component={SelectInput}
                options={idFilterOptions}
                name={`${OPERATOR}Id`}
                label={operatorLabel}
                placeholder={operatorLabel}
                className={cs('filter-form__input', {
                  'filter-form__input--hide': !idFieldSelected,
                })}
              />
              <Field
                menuPortalTarget="filter-form"
                component={SelectInput}
                options={nameFilterOptions}
                name={`${OPERATOR}Name`}
                label={operatorLabel}
                placeholder={operatorLabel}
                className={cs('filter-form__input', {
                  'filter-form__input--hide': !nameFieldSelected,
                })}
              />

              <Box
                className={cs({
                  'filter-form__input--hide': !idFieldSelected,
                })}
              >
                <Field
                  component={TagInput}
                  tagTemplate={TagNumber}
                  placeholder={valueLabel}
                  label={valueLabel}
                  name={`${VALUE}Id`}
                />
                <ArrayErrorMessage name={`${VALUE}Id`} />
              </Box>

              <Field
                component={TextInput}
                name={`${VALUE}Name`}
                placeholder={valueLabel}
                label={valueLabel}
                className={cs({
                  'filter-form__input--hide': !nameFieldSelected,
                })}
              />
              <FormButton className="filter-form__submit-btn">Add</FormButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FacebookFilterForm;

export const hasDuplicates = (array): string | boolean => {
  const valuesSoFar = Object.create(null);
  for (let i = 0; i < array.length; ++i) {
    const value = array[i];
    if (value in valuesSoFar) {
      return value;
    }
    valuesSoFar[value] = true;
  }
  return false;
};

export const objectsArrayToKeys = (object) => {
  const keys = Object.keys(object);
  const objectKeys = [];
  for (let i = 0; i < keys.length; ++i) {
    // @ts-ignore
    objectKeys.push(Object.keys(object[keys[i]]));
  }
  // @ts-ignore
  return objectKeys.reduce((keysArray, item) => keysArray.concat(item));
};

export const sumUpArrayValues = (array) => array.reduce((total: number, value: number): number => total + value, 0);

import React from 'react';
import LinkConfirmationTemplate from 'features/auth/LinkConfirmationTemplate';
import { AUTH_COPY } from 'shared/config/copy';

const SignUpLinkConfirmation = () => {
  return (
    <LinkConfirmationTemplate
      heading={AUTH_COPY.HEADING_SIGN_UP_CONFIRMATION}
      subHeading={AUTH_COPY.SUBHEADING_SIGN_UP_CONFIRMATION}
      linkText={AUTH_COPY.LINK_SIGN_UP_CONFIRMATION}
      type="activation"
    />
  );
};

export default SignUpLinkConfirmation;

import React from 'react';
import { Button } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie } from '@fortawesome/pro-solid-svg-icons';
import Dropdown from 'shared/components/molecules/Dropdown';
import { usePerformance } from 'shared/hooks/usePerformance';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { BREAKDOWN_MAP } from 'shared/config/performance';
import { Breakdowns } from 'features/performance/enums';

const { TOOL } = BUTTON_VARIANTS;

const BreakdownsSelector = () => {
  const {
    updateBreakdown,
    performanceState: { breakdownValue },
  } = usePerformance();

  const onClick = (value: Breakdowns) => () => {
    const breakdownChosen = BREAKDOWN_MAP[value] || value.toLowerCase();
    updateBreakdown(breakdownChosen, value);
  };

  return (
    <Dropdown
      ClickComponent={
        <Button variant={TOOL}>
          <FontAwesomeIcon icon={faChartPie} />
          <span>
            Breakdown
            {breakdownValue !== 'none' && `: ${breakdownValue}`}
          </span>
        </Button>
      }
      width="150px"
    >
      {Object.values(Breakdowns).map((breakdown) => (
        <Button key={breakdown} onClick={onClick(breakdown)}>
          {breakdown}
        </Button>
      ))}
    </Dropdown>
  );
};

export default BreakdownsSelector;

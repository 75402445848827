import React from 'react';
import styles from './styles';
import { Box, Flex } from 'shared/components/display';
import { Scene } from 'shared/typings/briefs';
import Richtext from 'shared/components/Richtext';
import { intl } from 'shared/lib/intl';

interface Props {
  scene: Scene;
  sceneNum: number;
}
const ModuleScene: React.FC<Props> = ({ scene, sceneNum }) => {
  return (
    <Box css={styles} className="module-scene">
      <Box className="module-scene__heading">
        <Box as="h5" className="module-scene__title">
          {scene.title}
        </Box>
        <Box as="h6" className="module-scene__sub-heading">
          {intl.formatMessage(
            { id: 'SUBHEADING_SCENE' },
            {
              num: sceneNum,
            },
          )}
        </Box>
      </Box>
      <Richtext value={JSON.parse(scene.description)} readOnly />
    </Box>
  );
};

export default ModuleScene;

// Breakpoint names are arbitrary but must match in the cols and layouts objects.

import { breakpoints } from 'shared/styles/styleFunctions';

export const COLUMNS = {
  DESKTOP: 12,
  TABLET: 12,
  PHONE: 12,
};

export const BREAKPOINTS = {
  DESKTOP: breakpoints.lg,
  TABLET: breakpoints.md,
  PHONE: breakpoints.sm,
};

export const ROW_HEIGHT = 30;

import { FACEBOOK_FIELDNAMES } from 'features/organizations/components/organisms/OrgOnboardingStepFields';
import * as Yup from 'yup';

const REQUIRED_FIELD = 'This field is required.';
export const validationSchema = Yup.object({
  fbBusiness: Yup.object()
    .shape({
      value: Yup.string().required(REQUIRED_FIELD),
      label: Yup.string().required(REQUIRED_FIELD),
    })
    .nullable()
    .required(REQUIRED_FIELD),
  fbPage: Yup.object()
    .shape({
      value: Yup.string().required(REQUIRED_FIELD),
      label: Yup.string().required(REQUIRED_FIELD),
    })
    .when('isPerformance', {
      is: true,
      then: Yup.object({
        value: Yup.string(),
        label: Yup.string(),
      })
        .nullable()
        .required(REQUIRED_FIELD),
    })
    .nullable(),
  fbPixel: Yup.object()
    .shape({
      value: Yup.string().required(REQUIRED_FIELD),
      label: Yup.string().required(REQUIRED_FIELD),
    })
    .when('isPerformance', {
      is: true,
      then: Yup.object({
        value: Yup.string(),
        label: Yup.string(),
      })
        .nullable()
        .required(REQUIRED_FIELD),
    })
    .nullable(),
  igAccount: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .nullable(),
  productCatalog: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .nullable(),
  fbAdAccount: Yup.object({
    value: Yup.string(),
    label: Yup.string(),
  })
    .when(FACEBOOK_FIELDNAMES.SHOULD_CREATE_AD_ACCOUNT, {
      is: false,
      then: Yup.object({
        value: Yup.string(),
        label: Yup.string(),
      })
        .nullable()
        .required(REQUIRED_FIELD),
    })
    .nullable(),
});

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.submission-header': {
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  '.submission-header': {
    '&__name': {
      flex: 1,
      paddingRight: theme.space[5],
      margin: 0,
    },
    '&__edit-btn': {
      whiteSpace: 'nowrap',
    },
  },
});

import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export default css({
  '.ad-copy': {
    '&__submit': {
      marginTop: theme.space[4],
    },
  },
});

import React, { useState } from 'react';
import cs from 'classnames';

import AccountHealthScore from 'features/accountPlatforms/components/atoms/AccountHealthScore/AccountHealthScore';
import AccountHealthContributor from 'features/accountPlatforms/components/molecules/AccountHealthContributor/AccountHealthContributor';
import { AccountPlatformHealth } from 'features/accountPlatforms/types';
import { Box, Button, Flex } from 'shared/components/display';

import { BUTTON_VARIANTS } from 'shared/styles/button';

import styles from './styles';

interface Props {
  isOnboarding?: boolean;
  onImproveMyScore?: () => Promise<void>;
  accountPlatformHealth: AccountPlatformHealth;
}
const AccountHealthScoreDisplay: React.FC<Props> = ({ isOnboarding, onImproveMyScore, accountPlatformHealth }) => {
  // contributors are collapsed by default
  const [openContributors, setOpenContributors] = useState<boolean[]>(
    Array.from({ length: accountPlatformHealth?.accountPlatformHealthContributors?.length }, () => false),
  );
  // if any contributors aren't expanded then we show option to expand all
  const expandAll = openContributors.some((open) => !open);

  const score = Math.floor(+accountPlatformHealth.score * 100);

  const onExpandClick = () => {
    if (expandAll) {
      setOpenContributors(
        Array.from({ length: accountPlatformHealth?.accountPlatformHealthContributors?.length }, () => true),
      );
    } else {
      // minimize all
      setOpenContributors(
        Array.from({ length: accountPlatformHealth?.accountPlatformHealthContributors?.length }, () => false),
      );
    }
  };

  return (
    <Flex css={styles} className={cs('account-health-score-display')}>
      <Box className="account-health-score-display__score">
        <AccountHealthScore score={score} />
      </Box>

      <Flex className="account-health-score-display__contributors">
        <Button
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          className="account-health-score-display__expand-btn"
          onClick={onExpandClick}
        >
          {expandAll ? 'Expand All' : 'Minimize All'}
        </Button>
        <Box className="account-health-score-display__contributors-grid">
          {accountPlatformHealth?.accountPlatformHealthContributors?.map(
            ({ title, impact, description, cta, assessment, contributorScore, isRatio }, index) => {
              return (
                <AccountHealthContributor
                  title={title}
                  score={contributorScore}
                  description={description}
                  cta={cta}
                  impact={impact}
                  assessment={assessment}
                  isExpanded={openContributors[index]}
                  key={title}
                  isRatio={isRatio}
                  onClick={() =>
                    setOpenContributors((state) => state.map((_, i) => (i === index ? !state[i] : state[i])))
                  }
                />
              );
            },
          )}
        </Box>
      </Flex>

      {isOnboarding && (
        <Button
          onClick={onImproveMyScore}
          variant={BUTTON_VARIANTS.PRIMARY}
          className="account-health-score-display__improve-btn"
        >
          Improve My Score
        </Button>
      )}
    </Flex>
  );
};

export default AccountHealthScoreDisplay;

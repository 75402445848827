import COLORS from 'shared/styles/colors';

const { BLACK } = COLORS;

const SHADOWS = {
    SHADOW01: `0px 0px 3px ${`${BLACK}0A`}, 0px 1px 2px ${`${BLACK}14`}`,
    SHADOW02: `0px 0px 4px ${`${BLACK}0D`}, 0px 1px 4px ${`${BLACK}14`}`,
    SHADOW03: `0px 0px 6px ${`${BLACK}0F`}, 0px 3px 6px ${`${BLACK}14`}`,
    SHADOW04: `0px 0px 8px ${`${BLACK}12`}, 0px 4px 9px ${`${BLACK}17`}`,
    SHADOW06: `0px 0px 12px ${`${BLACK}14`}, 0px 4px 11px ${`${BLACK}17`}`,
    SHADOW08: `0px 0px 16px ${`${BLACK}14`}, 0px 6px 12px ${`${BLACK}1A`}`,
    SHADOW09: `0px 0px 18px ${`${BLACK}17`}, 0px 7px 10px ${`${BLACK}1F`}`,
    SHADOW12: `0px 0px 24px ${`${BLACK}1A`}, 0px 10px 14px ${`${BLACK}24`}`,
    SHADOW16: `0px 0px 32px ${`${BLACK}1F`}, 0px 14px 12px ${`${BLACK}29`}`,
    SHADOW24: `0px 0px 48px ${`${BLACK}21`}, 0px 18px 16px ${`${BLACK}2E`}`,
};

export default SHADOWS;

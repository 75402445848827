import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import config from 'config';

import { useAuth } from 'features/auth/useAuth';
import UserDropdown from 'features/home/components/molecules/UserDropdown/UserDropdown';
import { useUser } from 'features/users/hooks/useUser';
import HamburgerMenu from 'shared/components/atoms/HamburgerMenu';
import NavBar from 'shared/components/atoms/NavBar';
import NavBarLogo from 'shared/components/atoms/NavBarLogo';
import NavItem from 'shared/components/atoms/NavItem';
import ResponsiveDisplay from 'shared/components/atoms/ResponsiveDisplay';
import SignOutButton from 'shared/components/atoms/SignOutButton';
import { Text } from 'shared/components/display';
import PromptButton from 'shared/components/molecules/PromptButton';
import NavLinks from 'shared/components/organisms/NavLinks';

import { USER_COPY } from 'shared/config/copy';
import { NOTIFICATIONS } from 'shared/config/notifications';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useNotifications } from 'shared/hooks/useNotifications';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator, RouteKey } from 'shared/utilities/linkUtility';
interface CreatorLink {
  text: string;
  routeKey: RouteKey;
  exact?: boolean;
  notificationKey?: string;
}

export const CREATOR_LINKS: CreatorLink[] = [
  { text: 'Home', routeKey: 'HOME_PAGE', exact: true },
  { text: 'Home', routeKey: 'DASHBOARD', exact: true },
  { text: 'Briefs Board', routeKey: 'BRIEFS_BOARD' },
  { text: 'Your Briefs', routeKey: 'CREATOR_SUBMISSIONS' },
  { text: 'Library', routeKey: 'ASSETS_LIBRARY' },
  {
    text: 'Messages',
    routeKey: 'MESSAGES',
    exact: true,
    notificationKey: NOTIFICATIONS.UNREAD_MESSAGES,
  },
  { text: 'Performance', routeKey: 'PERFORMANCE', exact: true },
  { text: 'Earnings', routeKey: 'EARNINGS', exact: true },
  { text: 'Creator Onboarding', routeKey: 'CREATOR_ONBOARDING', exact: true },
  { text: 'Brkfst Showcase', routeKey: 'CREATOR_APPROVED_ASSETS', exact: true },
  { text: 'Profile', routeKey: 'USER_PROFILE', exact: true },
];

const CreatorNavBar = () => {
  const { currentUser, isSuperadmin } = useCurrentUser();
  const location = useLocation<{}>();
  const navigate = useNavigate();
  const { getFormattedTotalNotificationCount } = useNotifications();
  const { signOut } = useAuth();
  const { deleteUser } = useUser(currentUser.id);

  const makeLink = (routeKey: RouteKey) =>
    LinkCreator.createLink({
      userType: UserType.CREATOR,
      routeKey,
      variables: {
        userId: currentUser.id,
      },
    });

  const goToFaq = () => {
    window.open(USER_COPY.LINK_FAQ, '_blank');
  };

  const onDeleteUser = () => {
    deleteUser(signOut);
  };

  const notificationCount = getFormattedTotalNotificationCount(NOTIFICATIONS.UNREAD_MESSAGES);

  const currentPageName = useMemo(() => {
    const labeledPaths: (CreatorLink & { path: string })[] = CREATOR_LINKS.map((link) => ({
      ...link,
      path: LinkCreator.createLink({
        routeKey: link.routeKey,
        userType: UserType.CREATOR,
        variables: {
          userId: currentUser.id,
        },
      }),
    }));

    const activePage: (CreatorLink & { path: string }) | undefined = labeledPaths.find(({ exact, path }) =>
      exact ? location.pathname === path : location.pathname.startsWith(path),
    );

    return activePage?.text;
  }, [location.pathname]);

  return (
    <NavBar showShadow>
      <NavBarLogo />
      <ResponsiveDisplay display="mobile">
        <Text style={{ marginBottom: 0 }} variant={TEXT_VARIANTS.SUBHEADING}>
          {currentPageName}
        </Text>
        <HamburgerMenu hasNotification={Boolean(notificationCount)} />
      </ResponsiveDisplay>
      <NavLinks>
        <NavLinks.Main>
          <NavItem text="Home" path={makeLink('DASHBOARD')} exact />
          <NavItem
            text="Briefs Board"
            path={makeLink('BRIEFS_BOARD')}
            linkClassName={location.pathname === '/' ? 'active' : ''}
          />
          <NavItem text="Your Briefs" path={makeLink('CREATOR_SUBMISSIONS')} />
          <NavItem text="Library" path={makeLink('ASSETS_LIBRARY')} />
          <NavItem text="Messages" path={makeLink('MESSAGES')} notificationCount={notificationCount} exact />
          <NavItem text="Performance" path={makeLink('PERFORMANCE')} exact />
          <NavItem text="Earnings" path={makeLink('EARNINGS')} exact />
        </NavLinks.Main>
        <NavLinks.Secondary>
          <NavItem text="Profile" path={makeLink('USER_PROFILE')} variant={TEXT_VARIANTS.BODY} exact />
          <NavItem text="Account Settings" path={makeLink('ACCOUNT_SETTINGS')} variant={TEXT_VARIANTS.BODY} />
          <NavItem text={USER_COPY.BUTTON_SIGN_OUT} variant={TEXT_VARIANTS.BODY} onClick={signOut} />
        </NavLinks.Secondary>
      </NavLinks>
      <ResponsiveDisplay display="desktop">
        <UserDropdown>
          <Button
            onClick={() => {
              navigate(
                LinkCreator.createLink({
                  routeKey: 'USER_PROFILE',
                  userType: UserType.CREATOR,
                  variables: {
                    userId: currentUser.id,
                  },
                }),
              );
            }}
          >
            {USER_COPY.BUTTON_PROFILE}
          </Button>
          <Button
            onClick={() => {
              const link = LinkCreator.createLink({
                routeKey: 'ACCOUNT_SETTINGS',
                userType: UserType.CREATOR,
                variables: {
                  userId: currentUser.id,
                },
              });
              navigate(link);
            }}
          >
            {USER_COPY.BUTTON_ACCOUNT_SETTINGS}
          </Button>
          <Button onClick={goToFaq}>{USER_COPY.BUTTON_FAQ}</Button>

          {config.FEATURE_FLAG_DELETE_USER && (
            <PromptButton onConfirm={onDeleteUser} text={USER_COPY.MODAL_HEADING_DELETE_USER}>
              {USER_COPY.BUTTON_DELETE_USER}
            </PromptButton>
          )}

          <SignOutButton />
        </UserDropdown>
      </ResponsiveDisplay>
    </NavBar>
  );
};

export default CreatorNavBar;

import React from 'react';
import { Button } from 'rebass/styled-components';

import CloseBriefButton from 'features/briefs/components/atoms/CloseBriefButton';
import DuplicateBriefButton from 'features/briefs/components/atoms/DuplicateBriefButton';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import SplitButton from 'shared/components/molecules/SplitButton';

import { BRIEF_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { Brief } from 'shared/typings/briefs';

import { BriefPermissions } from './types';

interface Props {
  currentBrief: Brief;
  fullBriefPermissions: Pick<BriefPermissions, 'closeReopenBrief' | 'publishEditBrief' | 'createEditDraft'>;
}

const FullBriefActionButtons: React.FC<Props> = ({ currentBrief, fullBriefPermissions }) => {
  const { resumeBrief, editBrief } = useBriefs();
  const { closeReopenBrief, publishEditBrief, createEditDraft } = fullBriefPermissions;
  const onResume = () => {
    resumeBrief(currentBrief);
  };
  const onEdit = () => {
    editBrief(currentBrief);
  };

  if (!closeReopenBrief) {
    return (
      <Button key="Edit Brief" variant={BUTTON_VARIANTS.PRIMARY} onClick={onEdit}>
        {BRIEF_COPY.BUTTON_EDIT_BRIEF}
      </Button>
    );
  } else {
    return (
      <SplitButton primaryText={BRIEF_COPY.BUTTON_RESUME} primaryOnClick={onResume} width="190px">
        {publishEditBrief && (
          <Button key="Edit Brief" variant={BUTTON_VARIANTS.FLAT_OPTION} onClick={onEdit}>
            {BRIEF_COPY.BUTTON_EDIT_BRIEF}
          </Button>
        )}
        {createEditDraft && publishEditBrief && (
          <DuplicateBriefButton brief={currentBrief} variant={BUTTON_VARIANTS.FLAT_OPTION} />
        )}
        <CloseBriefButton brief={currentBrief} variant={BUTTON_VARIANTS.FLAT_OPTION} />
      </SplitButton>
    );
  }
};

export default FullBriefActionButtons;

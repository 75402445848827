import React, { useState } from 'react';
import { Button } from 'rebass/styled-components';
import { Box, Text, Flex } from 'shared/components/display';
import { CardElement } from '@stripe/react-stripe-js';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons';
import creditCardInputStyles from 'shared/components/molecules/CreditCardInput/creditCardInputStyles';
import { Input } from 'shared/lib/formik';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { CreditCardValue } from 'shared/components/molecules/CreditCardInput/config';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';

interface Props {
  className?: string;
}

const CreditCardInput: Input<CreditCardValue, Props> = ({ field: { value, name, onChange, onBlur } }) => {
  const [editMode, setEditMode] = useState<boolean>(!value.complete);
  const { card } = value;
  const showInput = editMode || !card?.sourceId;

  const toggleEdit = () => setEditMode((prev) => !prev);

  const handleChange = (event: StripeCardElementChangeEvent) => {
    const { error, complete } = event;

    onChange({
      target: {
        name,
        value: {
          ...value,
          complete,
          error: error?.message,
        },
      },
    });
  };

  const handleBlur = () => onBlur({ target: { name } });

  return (
    <Box css={creditCardInputStyles} className="credit-card-input">
      {showInput ? (
        <>
          <CardElement className="credit-card-input__element" onChange={handleChange} onBlur={handleBlur} />
          <FieldErrorMessage name={name} />
        </>
      ) : (
        <Flex className="credit-card-input__edit-display">
          <FontAwesomeIcon icon={faCreditCard} />
          <Text className="credit-card-input__card-name">{card?.name}</Text>
          <Button
            className="credit-card-input__edit-btn"
            variant={BUTTON_VARIANTS.MICRO}
            onClick={toggleEdit}
            type="button"
          >
            {editMode ? 'Cancel' : 'Edit'}
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default CreditCardInput;

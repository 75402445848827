import React, { useEffect, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Text } from 'rebass/styled-components';
import { Image } from 'rebass/styled-components';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import authenticationPageStyles from 'features/auth/AuthPageTemplate/authenticationPageStyles';
import { useAuth } from 'features/auth/useAuth';
import PrivacyFooter from 'features/home/PrivacyFooter';
import OAuthButton from 'shared/components/atoms/OAuthButton';
import { Box, Flex } from 'shared/components/display';
import WelcomeHeader from 'shared/components/molecules/WelcomeHeader/WelcomeHeader';

import { AUTH_COPY } from 'shared/config/copy/authCopy';
import AUTH_ROUTES from 'shared/config/routes/authRoutes';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { googleIcon } from 'shared/images/google';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { FACEBOOK_COLORS } from 'shared/styles/colors';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LinkCreator } from 'shared/utilities/linkUtility';
const REDIRECT_PATHS = [AUTH_ROUTES.SIGN_IN, AUTH_ROUTES.SIGN_UP];

const { CATEGORY, BODY_SM } = TEXT_VARIANTS;

interface Props {
  welcomeHeading: string;
  welcomeSubheading: string;
  googleText: string;
  facebookText: string;
  signIn?: boolean;
  urlParams: any;
  children: JSX.Element | Array<JSX.Element>;
}

const AuthPageTemplate: React.FC<Props> = ({
  welcomeHeading,
  welcomeSubheading,
  children,
  googleText,
  facebookText,
  signIn = false,
  urlParams = {},
}) => {
  const { authenticated } = useCurrentUser();
  const { googleOauthRedirect, facebookOauthRedirect } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (authenticated && REDIRECT_PATHS.includes(location.pathname)) {
      navigate('/');
    }
  }, [authenticated, history]);

  const content = useMemo(() => {
    return signIn
      ? {
          buttonText: AUTH_COPY.BUTTON_CREATE_ACCOUNT_AUTH,
          linkPath: LinkCreator.createLink({
            routeKey: 'SIGN_UP',
          }),
          question: AUTH_COPY.FOOTNOTE_QUESTION_SIGN_UP,
        }
      : {
          buttonText: AUTH_COPY.BUTTON_SIGN_IN_AUTH,
          linkPath: LinkCreator.createLink({
            routeKey: 'SIGN_IN',
          }),
          question: AUTH_COPY.FOOTNOTE_QUESTION_SIGN_IN,
        };
  }, [signIn]);

  return (
    <Box className={`${CLASSNAMES.PUBLIC_CONTAINER} auth-page`} css={authenticationPageStyles}>
      <WelcomeHeader heading={welcomeHeading} subheading={welcomeSubheading} />
      <Flex className="auth-page__oauth-btn-wrapper">
        <OAuthButton
          clickHandler={() => {
            googleOauthRedirect(urlParams);
          }}
        >
          <Image src={googleIcon} />
          {googleText}
        </OAuthButton>

        <OAuthButton
          clickHandler={() => {
            facebookOauthRedirect(urlParams);
          }}
        >
          <FontAwesomeIcon icon={faFacebook} color={FACEBOOK_COLORS.BLUE} size="2xl" />
          {facebookText}
        </OAuthButton>
      </Flex>

      <Text variant={CATEGORY} className="auth-page__or-separator">
        OR
      </Text>
      {children}
      <Flex className="auth-page__switch-form-wrapper">
        <Text variant={BODY_SM} className="auth-page__question">
          {content.question}
        </Text>
        <Link to={content.linkPath}>
          <Button variant={BUTTON_VARIANTS.OUTLINE} className="auth-page__switch-btn">
            {content.buttonText}
          </Button>
        </Link>
      </Flex>
      <PrivacyFooter />
    </Box>
  );
};

export default AuthPageTemplate;

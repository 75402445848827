import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.module-scene': {
    borderRadius: '10px',
    backgroundColor: COLORS.NEUTRAL50,
    margin: theme.space[4],
    marginLeft: 0,
    width: '300px',
  },
  '.module-scene': {
    '&__heading': {
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      backgroundColor: COLORS.PRIMARY500,
      color: COLORS.WHITE,
    },
    '&__title': {
      textAlign: 'center',
      fontWeight: 600,
      marginBottom: 0,
      paddingTop: theme.space[1],
    },
    '&__sub-heading': {
      textAlign: 'center',
      fontWeight: 300,
      paddingBottom: theme.space[2],
    },
  },
});

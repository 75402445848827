import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.page-index': {
    justifyContent: 'center',
    marginBottom: theme.space[1],
    marginRight: theme.space[2],
    marginTop: theme.space[3],
  },
  '.page-index': {
    '&__page-btn': {
      backgroundColor: COLORS.TRANSPARENT,
      color: COLORS.BLACK,
      marginRight: theme.space[2],
      minWidth: 'unset',
    },
    '&__page-btn--current': {
      backgroundColor: COLORS.WHITE,
      color: COLORS.PRIMARY500,
    },
    '&__page-nav-btn': {
      minWidth: 'unset',
      marginRight: theme.space[2],
    },
    '&__page-nav-btn > svg': {
      margin: `0 ${theme.space[2]}`,
    },
  },
});

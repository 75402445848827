import React, { Fragment, useMemo } from 'react';
import { Text } from 'rebass/styled-components';
import { useFormikContext } from 'formik';

import SubmissionAssetArray from 'features/submissions/components/organisms/SubmissionAssetArray/SubmissionAssetArray';
import { FormSubmissionModule, SubmissionModuleFields } from 'features/submissions/components/SubmissionForm/types';
import { Box } from 'shared/components/display';
import { ErrorMessage } from 'shared/components/FieldErrorMessage';

import { makeArray } from 'shared/lib/formik';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstFile } from 'shared/typings/file';
import { ModuleSubmissionStatus } from 'shared/typings/submissions';

import styles from './styles';

export type FileCollectionProps = {
  userId: number;
};

function useAsyncSetFieldValue() {
  const { setFieldValue } = useFormikContext();

  // Wondering WTF this abomination is? https://github.com/jaredpalmer/formik/issues/2266
  return (field: string, value: any) => {
    setTimeout(() => setFieldValue(field, value));
  };
}

const SubmissionModuleArray = makeArray<FormSubmissionModule, FileCollectionProps>(
  ({ values, name, userId, form: { errors, setFieldValue } }) => {
    const asyncSetFieldValue = useAsyncSetFieldValue();
    const handleFilesChanged = (count: number, index: number) => {
      setFieldValue(`${name}.${index}.${SubmissionModuleFields.MODULE_ASSET_COUNT}`, count);
      if (!values[index].touched) {
        asyncSetFieldValue(`${name}.${index}.${SubmissionModuleFields.TOUCHED}`, true);
      }
    };
    const allSubmittedFiles = useMemo<
      Pick<BrkfstFile, 'id' | 'name' | 'metadata' | 'submissionValidatorFields'>[]
    >(() => {
      return values.reduce((files, value) => {
        files.push(
          ...value.files.map(({ id, name, metadata, submissionValidatorFields }) => ({
            id,
            name,
            metadata,
            submissionValidatorFields,
          })),
        );
        return files;
      }, [] as Pick<BrkfstFile, 'id' | 'name' | 'metadata' | 'submissionValidatorFields'>[]);
    }, [values]);

    return (
      <Box css={styles} className="submission-module-array">
        {values.map((value, index) => (
          <Fragment key={index}>
            <Text className="submission-module-array__label-module" variant={TEXT_VARIANTS.SUBHEADING} as="label">
              {`Module ${index + 1}`}
            </Text>
            <SubmissionAssetArray
              name={`${name}.${index}.${SubmissionModuleFields.FILES}`}
              userId={userId}
              minDuration={value.minDuration}
              maxDuration={value.maxDuration}
              moduleNum={index + 1}
              moduleFileType={value.fileType}
              disabled={value.status === ModuleSubmissionStatus.APPROVED}
              onFilesChanged={(count) => handleFilesChanged(count, index)}
              allSubmittedFiles={allSubmittedFiles}
            />
            <ErrorMessage>
              {values[index].touched && errors?.[name]?.[index]?.[SubmissionModuleFields.MODULE_ASSET_COUNT]}
            </ErrorMessage>
          </Fragment>
        ))}
      </Box>
    );
  },
);

export default SubmissionModuleArray;

import React, { useState } from 'react';
import { Button } from 'rebass/styled-components';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import Tags, { TagCategory } from 'shared/components/atoms/Tags';

import { Box } from 'shared/components/display';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import Modal from 'shared/components/molecules/Modal';

import { FILE_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { BrkfstFile } from 'shared/typings/file';
import { UserType } from 'shared/typings/user/enums';
import { formatAspectRatio, isRotatedSideways } from 'shared/utilities/fileUtility';

import styles from './styles';
interface Props {
  file: BrkfstFile;
  variant?: string;
  textDisplay?: boolean;
  hideAspectRatio?: boolean;
  onClick?: (e: any) => void;
  className?: string;
}

const settings = {
  templates: {
    tag: TagCategory,
  },
  duplicates: true,
};

const FileInformationModal: React.FC<Props> = ({
  file,
  variant = BUTTON_VARIANTS.TEXT_ONLY,
  textDisplay = false,
  hideAspectRatio = false,
  onClick,
  className,
}) => {
  const [open, setOpen] = useState(false);
  const { currentUser, isSuperadmin } = useCurrentUser();

  // to prevent lightbox from opening when clicking on the modal button
  const stopPropagation = (e) => e.stopPropagation();

  const toggleModal = (e) => {
    setOpen((prev) => !prev);

    if (onClick) onClick(e);
  };

  return (
    <Box
      css={styles}
      className={cs('file-information-modal__btns', className)}
      data-cy="file-information-modal__btns"
      onClick={stopPropagation}
    >
      <Button
        className={cs({
          'file-information-modal__info-btn--icon': !textDisplay,
        })}
        onClick={toggleModal}
        variant={variant}
      >
        {textDisplay ? FILE_COPY.BUTTON_GET_FILE_INFO : <FontAwesomeIcon icon={faCircleInfo} size="lg" />}
      </Button>
      <Modal
        isOpen={open}
        title={FILE_COPY.MODAL_HEADER_FILE_INFO}
        onRequestClose={toggleModal}
        component={
          <Box css={styles} className={cs('file-information-modal')}>
            <DefinitionList>
              <ListDetail text={FILE_COPY.MODAL_LABEL_NAME}>{file.name}</ListDetail>
              <ListDetail text={FILE_COPY.MODAL_LABEL_ID} hide={!isSuperadmin}>
                {file.id.toString()}
              </ListDetail>
              <ListDetail text={FILE_COPY.MODAL_LABEL_FB_ASSETS} hide={!file.facebookAssetAPNames}>
                {file.facebookAssetAPNames || ''}
              </ListDetail>
              <ListDetail text={FILE_COPY.MODAL_LABEL_TIKTOK_ASSETS} hide={!file.tiktokAssetAPNames}>
                {file.tiktokAssetAPNames || ''}
              </ListDetail>
              <ListDetail text={FILE_COPY.MODAL_LABEL_SUBMISSION_STATUS} hide={!file.submitted}>
                {FILE_COPY.MODAL_VALUE_SUBMISSION_STATUS}
              </ListDetail>
              <ListDetail text={FILE_COPY.MODAL_LABEL_TAGS} hide={(file.tags?.length || 0) < 1}>
                <Tags value={file.tags || []} settings={settings} readOnly />
              </ListDetail>
              <ListDetail
                text={FILE_COPY.MODAL_AUTHOR_NAME}
                hide={!file.creatorFirstName || !file.creatorLastName || currentUser.type === UserType.CREATOR}
              >{`${file.creatorFirstName} ${file.creatorLastName}`}</ListDetail>
              <ListDetail
                text={FILE_COPY.MODAL_ASPECT_RATIO}
                hide={!(file.mimeType.includes('video') || file.mimeType.includes('image')) || hideAspectRatio}
              >
                {formatAspectRatio({
                  width: file.metadata?.width,
                  height: file.metadata?.height,
                  rotation: file.metadata?.rotation,
                })}
              </ListDetail>
              <ListDetail
                text={FILE_COPY.MODAL_LABEL_DIMENSIONS}
                hide={!file.metadata?.width || !file.metadata?.height || hideAspectRatio}
              >
                {/* @ts-ignore */}
                {isRotatedSideways(file.metadata.rotation)
                  ? `${file.metadata?.height} x ${file.metadata?.width}`
                  : `${file.metadata?.width} x ${file.metadata?.height}`}
              </ListDetail>
              <ListDetail text={FILE_COPY.MODAL_MODULE_NUM} hide={!Boolean(file.moduleNum)}>
                {file.moduleNum || ''}
              </ListDetail>
            </DefinitionList>
          </Box>
        }
      />
    </Box>
  );
};

export default FileInformationModal;

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';

export default (creatorButtonsHeight: number) =>
  css({
    '&.creator-submission-tabs': {
      height: creatorButtonsHeight > 0 ? `calc(100%  - (${creatorButtonsHeight}px + 10px))` : '100%',
    },
    '.creator-submission-tabs': {
      '&__creator-buttons': {
        bottom: '12px',
        position: 'fixed',
        zIndex: zIndex.zButton,
        width: '100%',
        justifyContent: 'center',
      },
    },
  });

import React, { JSXElementConstructor, ReactElement } from 'react';
import cs from 'classnames';

import { Box } from 'shared/components/display';
import definitionListStyles from 'shared/components/molecules/DefinitionList/definitionListStyles';
import { ListDetailProps } from 'shared/components/molecules/DefinitionList/ListDetail';

import COLORS from 'shared/styles/colors';

type ListDetailChild = ReactElement<ListDetailProps, string | JSXElementConstructor<any>>;

interface Props {
  children: ListDetailChild | Array<ListDetailChild> | ListDetailChild | any;
  stickyLabel?: boolean;
  className?: string;
  uppercase?: boolean;
  labelColor?: string;
}
const DefinitionList: React.FC<Props> = ({
  children,
  stickyLabel = false,
  uppercase = false,
  className,
  labelColor = COLORS.NEUTRAL500,
}) => {
  return (
    <Box as="dl" css={definitionListStyles} className={cs('definition-list', className)}>
      {React.Children.map<ReactElement, ReactElement>(children, (child: ReactElement<ListDetailProps>) => {
        return React.cloneElement(child, {
          uppercase,
          color: child.props.color || labelColor,
          stickyLabel,
        });
      })}
    </Box>
  );
};

export default DefinitionList;

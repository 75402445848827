import React from 'react';
import cs from 'classnames';
import { Field, Form, Formik } from 'formik';
import { SelectInput } from 'shared/components/molecules/Select';
import FormButton from 'shared/components/FormButton';
import { TagInput } from 'shared/components/molecules/TagInput';
import {
  tiktokFilterOptions,
  filterSchema,
  FilterValues,
  FIELD,
  VALUE,
  tiktokInitialValues,
} from 'features/performance/FilterFormDropdown/config';
import { Box, Text } from 'shared/components/display';
import filterFormStyles from 'features/performance/FilterFormDropdown/filterFormStyles';
import { makeLabel } from 'shared/lib/formik';
import { TagNumber } from 'shared/components/atoms/Tags';
import { ArrayErrorMessage } from 'shared/components/FieldErrorMessage';

interface Props {
  onSubmit: (values: FilterValues) => void;
}

const valueLabel = makeLabel(VALUE, true);

const TiktokFilterForm: React.FC<Props> = ({ onSubmit }) => {
  return (
    <Formik<FilterValues>
      validationSchema={filterSchema}
      onSubmit={(values, formikActions) => {
        onSubmit(values);
        formikActions.setSubmitting(false);
      }}
      initialValues={tiktokInitialValues}
      validateOnBlur={false}
    >
      {(formikProps) => {
        const fieldSelected = Boolean(formikProps.values[FIELD].value);

        return (
          <Form>
            <Box className="filter-form" css={filterFormStyles}>
              <Field
                menuPortalTarget="filter-form"
                name={FIELD}
                component={SelectInput}
                options={tiktokFilterOptions}
                placeholder="Choose Field*"
              />

              <Text
                className={cs('filter-form__operator', {
                  'filter-form__input--hide': !fieldSelected,
                })}
              >
                IN
              </Text>
              <Box
                className={cs({
                  'filter-form__input--hide': !fieldSelected,
                })}
              >
                <Field
                  component={TagInput}
                  tagTemplate={TagNumber}
                  placeholder={valueLabel}
                  label={valueLabel}
                  name={`${VALUE}Id`}
                />
                <ArrayErrorMessage name={`${VALUE}Id`} />
              </Box>
              <FormButton className="filter-form__submit-btn">Add</FormButton>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TiktokFilterForm;

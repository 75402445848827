import React, { useState } from 'react';
import { Button } from 'rebass/styled-components';

import AccountPlatformForm, {
  AccountPlatformFormValues,
} from 'features/accountPlatforms/components/molecules/AccountPlatformForm/AccountPlatformForm';
import Modal from 'shared/components/molecules/Modal';

import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';

interface Props {
  accountPlatform: AccountPlatformFormValues;
  onSubmit: (values: AccountPlatformFormValues) => void;
  onClick?: () => void;
}

const AccountPlatformFormModal: React.FC<Props> = ({ accountPlatform, onSubmit, onClick }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <>
      <Button
        variant={BUTTON_VARIANTS.OPTION}
        onClick={() => {
          if (onClick) onClick();
          toggleModal();
        }}
      >
        {ACCOUNT_PLATFORM_COPY.BUTTON_EDIT_ACCOUNT_PLATFORM}
      </Button>
      <Modal
        title={ACCOUNT_PLATFORM_COPY.FORM_HEADING_EDIT_PLATFORM}
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        component={
          <AccountPlatformForm
            initialValues={{
              name: accountPlatform.name,
            }}
            onSubmit={(data) => {
              onSubmit(data);
              toggleModal();
            }}
          />
        }
      />
    </>
  );
};

export default AccountPlatformFormModal;

import React, { Fragment, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Image } from 'rebass/styled-components';
import { findKey, startCase } from 'lodash';
import styled from 'styled-components';

import { Box, Text } from 'shared/components/display';

import { AD_NAME, AD_NAME_PREVIEW, ADS, CALL_TO_ACTION, URL_TAGS } from 'shared/config/ads';
import { DISPLAY_URL } from 'shared/config/ads';
import { FACEBOOK_COPY } from 'shared/config/copy';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { formatISODate } from 'shared/utilities/dateUtility';
import { parseDynamicString } from 'shared/utilities/stringUtility';

const { SUBHEADING, BODY } = TEXT_VARIANTS;

interface LabelProps {
  label: string;
  value: any;
}

export const LabelValue: React.FC<LabelProps> = ({ label, value }) => {
  return value ? (
    <Box my={5}>
      <Text variant={SUBHEADING}>{label}</Text>
      <InputValue variant={BODY}>{value}</InputValue>
    </Box>
  ) : null;
};

const FbAdCheckoutSingle = () => {
  const location = useLocation<{ formData: any; formattedAssets: any[]; moduleNum: number }>();
  const { formData, formattedAssets, moduleNum } = location.state;

  const adNamePreview = useMemo(() => {
    const { creatorFirstName: firstName, creatorLastName: lastName } = formattedAssets[0];
    return parseDynamicString(formData.adName, {
      ...formData,
      creatorName: `${lastName}${firstName}`,
      moduleNumber: moduleNum,
      creatorInitials: `${firstName[0]}${lastName[0]}`,
      date: formatISODate(new Date()),
    });
  }, [formData, moduleNum, formattedAssets]);

  return (
    <Fragment>
      <Text variant={SUBHEADING}>{FACEBOOK_COPY.HEADING_ASSET}</Text>
      <AssetsGrid>
        {formattedAssets.map((asset) => (
          <div key={asset.name}>
            <Image src={asset.thumbnailUrl} alt={asset.name} />
            <InputValue variant={BODY} className="endWithEllipsis">
              {asset.name}
            </InputValue>
          </div>
        ))}
      </AssetsGrid>
      {ADS.FACEBOOK.SINGLE_AD_INPUTS.DYNAMIC.map(({ fieldName }, ndx) => (
        <LabelValue key={ndx} label={startCase(fieldName)} value={formData[fieldName]} />
      ))}
      <LabelValue
        label={startCase(CALL_TO_ACTION)}
        value={findKey(ADS.FACEBOOK.CTA, (cta) => cta === formData[CALL_TO_ACTION])}
      />
      <LabelValue label={startCase(DISPLAY_URL)} value={formData[DISPLAY_URL]} />
      <LabelValue label={startCase(AD_NAME)} value={formData[AD_NAME]} />
      <LabelValue label={AD_NAME_PREVIEW} value={adNamePreview} />
      <LabelValue label={startCase(URL_TAGS)} value={formData[URL_TAGS]} />
    </Fragment>
  );
};

export const InputValue = styled(Text)`
  color: ${COLORS.NEUTRAL700};
  word-break: break-word;
`;

const AssetsGrid = styled(Box)`
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 1rem;
  row-gap: 0.75rem;
  margin-top: 0.5rem;

  img {
    float: left;
    margin-right: 0.5rem;
    width: 3rem;
  }
`;

export default FbAdCheckoutSingle;

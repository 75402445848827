import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { useCreators } from 'features/creators/useCreators';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Box, Flex } from 'shared/components/display';

import { CREATOR_COPY } from 'shared/config/copy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { usePermissions } from 'shared/hooks/usePermissions';
import { Brief } from 'shared/typings/briefs';
import { BriefStatus } from 'shared/typings/briefs/enums';
import { BrkfstUser } from 'shared/typings/user';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';
import PromptButton from 'shared/components/molecules/PromptButton';

interface Props {
  brief: Pick<
    Brief,
    | 'id'
    | 'status'
    | 'accountId'
    | 'organizationId'
    | 'full'
    | 'name'
    | 'creatorApprovalPricing'
    | 'eliteCreatorApprovalPricing'
  >;
  creator: Pick<BrkfstUser, 'id' | 'creatorBriefStatus' | 'fullName' | 'isElite'>;
}

const ApprovalButtons: React.FC<Props> = ({ brief, creator }) => {
  const navigate = useNavigate();
  const { isAuthorized } = usePermissions();
  const {
    account: { enableCreditCardCharges, organizationId },
  } = useAccounts({ accountId: brief.accountId.toString() });
  const briefIsClosed = brief.status === BriefStatus.CLOSED;
  const hasActiveCreator =
    creator.creatorBriefStatus === UserBriefStatus.SUBMITTED || creator.creatorBriefStatus === UserBriefStatus.DRAFTED;
  const hideButtons =
    !isAuthorized(brief.organizationId, PERMISSIONS_MAP.APPROVE_CREATORS.API) || briefIsClosed || hasActiveCreator;
  const chargeAmount = creator.isElite ? brief.eliteCreatorApprovalPricing : brief.creatorApprovalPricing;

  const { approveCreator, rejectCreator } = useCreators();

  const redirectToCheckout = () => {
    navigate(
      {
        pathname: LinkCreator.createLink({
          routeKey: 'BRIEF_APPROVAL_CHECKOUT',
          variables: {
            briefId: brief.id,
            creatorId: creator.id,
            accountId: brief.accountId,
            organizationId,
          },
        }),
      },
      {
        state: {
          creatorBriefStatus: creator.creatorBriefStatus,
          creatorName: creator.fullName,
          briefName: brief.name,
        },
      },
    );
  };

  const onApprovalClick = () => {
    if (enableCreditCardCharges && chargeAmount > 0) {
      redirectToCheckout();
    } else approveCreator({ creatorId: creator.id, briefId: brief.id, accountId: brief.accountId });
  };

  const disableApproveButton = useMemo(() => {
    if (brief.full) return true;
    if (creator.creatorBriefStatus)
      return [UserBriefStatus.ACTIVE, UserBriefStatus.DRAFTED].includes(creator.creatorBriefStatus);
    return false;
  }, [brief.full, creator.creatorBriefStatus]);

  return hideButtons ? (
    <></>
  ) : (
    <Flex css={styles} className="approval-buttons">
      <Button
        onClick={() => rejectCreator({ creatorId: creator.id, briefId: brief.id, accountId: brief.accountId })}
        className="approval-buttons__reject-btn"
        disabled={creator.creatorBriefStatus === UserBriefStatus.REJECTED}
      >
        <Flex className="approval-buttons__icon-wrapper">
          <FontAwesomeIcon icon={faTimes} size="sm" role="img" aria-hidden="true" />
          {CREATOR_COPY.BUTTON_REJECT}
        </Flex>
      </Button>
      <Tooltip
        disabled={!brief.full || creator.creatorBriefStatus === UserBriefStatus.ACTIVE}
        content={CREATOR_COPY.TOOLTIP_FULL_BRIEF}
      >
        <PromptButton
          text={CREATOR_COPY.PROMPT_CREATOR_APPROVAL_LEGAL_TEXT}
          title={CREATOR_COPY.PROMPT_TITLE_CREATOR_APPROVAL}
          onConfirm={onApprovalClick}
          className="approval-buttons__approve-btn"
          disabled={disableApproveButton}
        >
          <Flex className="approval-buttons__icon-wrapper">
            <FontAwesomeIcon icon={faCheck} size="sm" role="img" aria-hidden="true" />
            {CREATOR_COPY.BUTTON_APPROVE}
          </Flex>
        </PromptButton>
      </Tooltip>
    </Flex>
  );
};

export default ApprovalButtons;

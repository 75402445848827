import { isEqual } from 'lodash';
import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

const memoizedEqualityCheck = (value: any): any => {
  const ref = useRef();
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
};

/**
 * Like useEffect but can handle object, arrays etc
 * as dependencies
 *
 * @param callback
 * @param dependencies
 */
export const useDeepEffect = (
  callback: EffectCallback,
  dependencies: DependencyList,
): void => {
  useEffect(callback, dependencies.map(memoizedEqualityCheck));
};

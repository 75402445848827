const BASE = '/user/:userId/';

export default {
  USER_PROFILE: BASE,

  COLLECTION: BASE + 'library/:collectionId',

  ACCOUNT_SETTINGS: BASE + 'settings',

  CREATOR_APPROVED_ASSETS: BASE + 'approved-assets',
};

import React from 'react';
import { Box, Text } from 'shared/components/display';
import LinkButton from 'shared/components/clicks/LinkButton';
import { LinkCreator } from 'shared/utilities/linkUtility';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useMediaQuery } from 'react-responsive';

import styles from 'features/messages/NoChannelsPlaceholder/styles';

import { breakpoints } from 'shared/styles/styleFunctions';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { MESSAGE_COPY } from 'shared/config/copy/messageCopy';
import { UserType } from 'shared/typings/user/enums';

const NoChannelsPlaceholder = () => {
  const { isCreator } = useCurrentUser();

  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  return (
    <Box css={styles} className="no-channels-placeholder">
      <Text variant={TEXT_VARIANTS.H4} className="no-channels-placeholder__heading">
        {MESSAGE_COPY.HEADING_NO_CHANNELS}
      </Text>
      <Text className="no-channels-placeholder__description" variant={isMobile ? TEXT_VARIANTS.BODY : TEXT_VARIANTS.H4}>
        {isCreator ? MESSAGE_COPY.HEADING_NO_CHANNELS_CREATOR : MESSAGE_COPY.HEADING_NO_CHANNELS_BRAND}
      </Text>
      {isCreator && (
        <LinkButton
          className="no-channels-placeholder__button"
          buttonText={MESSAGE_COPY.BUTTON_NO_CHANNELS_CREATOR}
          pathname={LinkCreator.createLink({
            userType: UserType.CREATOR,
            routeKey: 'BRIEFS_BOARD',
          })}
          variant={BUTTON_VARIANTS.PRIMARY}
        />
      )}
    </Box>
  );
};

export default NoChannelsPlaceholder;

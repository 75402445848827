import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default (color?: string) =>
  css({
    '&.tag': {
      backgroundColor: COLORS.PRIMARY50,
      color: color || COLORS.PRIMARY500,
      alignContent: 'center',
      justifyContent: 'space-between',
      padding: `${theme.space[2]} ${theme.space[4]}`,
      width: 'fit-content',
      fontWeight: 500,
      height: 'fit-content',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'flex',
      alignItems: 'center',
      '&--default': {
        borderRadius: '18px',
        border: `1px solid ${COLORS.PRIMARY500}`,
      },
      '&--compact': {
        borderRadius: '5px',
        padding: `${theme.space[2]} ${theme.space[3]}`,
      },
    },
    '.tag': {
      '&__remove-btn': {
        marginLeft: theme.space[3],
        color: COLORS.NEUTRAL400,
        width: '7px',
        cursor: 'pointer',
      },
    },
  });

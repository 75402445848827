import React from 'react';
import ReactTable from 'react-table';
import { Button } from 'rebass/styled-components';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Box, Text } from 'shared/components/display';

import { FACEBOOK_COPY } from 'shared/config/copy';
import { FORM_COPY } from 'shared/config/copy/formCopy';
import { TEXT_VARIANTS } from 'shared/styles/text';
import theme from 'shared/styles/themes';

import { AdCountLedger } from '../../Campaigns';

import { AdCreative } from './types';
const AD_COUNT_LIMIT = 50;

interface Props {
  ads: AdCreative[];
  adCountErrors: AdCountLedger[];
  loading: boolean;
  onClick: () => void;
}

export const UploadPrompt: React.FC<Props> = ({ ads, adCountErrors, loading, onClick }) => {
  const hasErrors = Boolean(adCountErrors.length);
  const hideErrorDisplay = !hasErrors;
  const disableUpload = loading || hasErrors;

  return (
    <>
      <ul>
        {FACEBOOK_COPY.PROMPT_AD_UPLOAD_BODY.map((item, ndx) => (
          <li key={ndx}>{item}</li>
        ))}
      </ul>
      {loading && <CenteredSpinner height={40} width={40} py={1} />}
      <Box display={hideErrorDisplay ? 'none' : undefined} m={5}>
        <Text
          style={{
            marginBottom: theme.space[3],
          }}
          variant={TEXT_VARIANTS.ERROR}
        >
          This {ads.length} ad upload will exceed FB's {AD_COUNT_LIMIT}-ad limit for the following ad sets
        </Text>
        <ReactTable
          style={{ width: '75%' }}
          showPagination={false}
          minRows={0}
          columns={[
            {
              Header: 'Campaign Name',
              accessor: 'campaignName',
            },
            {
              Header: 'Adset Name',
              accessor: 'adsetName',
            },
            {
              Header: 'Current Ads',
              accessor: 'currentAds',
            },
            {
              Header: 'Active Ads',
              accessor: 'activeAds',
            },
            {
              Header: 'Paused Ads',
              accessor: 'pausedAds',
            },
            {
              Header: 'Ads to Remove',
              accessor: 'adsToRemove',
            },
          ]}
          data={adCountErrors.map((adCountError) => ({
            campaignName: adCountError?.campaignName,
            adsetName: adCountError?.adsetName,
            currentAds: adCountError?.total,
            activeAds: adCountError?.active.length,
            pausedAds: adCountError?.paused.length,
            adsToRemove: adCountError?.total - (AD_COUNT_LIMIT - ads?.length),
          }))}
        />
        <Text
          style={{
            marginTop: theme.space[3],
          }}
        >
          After removing the sufficient number of ads for each ad set above, close and re-submit.
        </Text>
      </Box>
      <Button m={5} disabled={disableUpload} onClick={onClick}>
        {FORM_COPY.UPLOAD_AD}
      </Button>
    </>
  );
};

import { updateFileUploadStatus } from 'features/ui/ui.slice';
import { SignalRContext } from 'shared/contexts/signalr';
import { FileUploadStatus } from 'shared/typings/file/enums';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fileUpdated } from '../files.slice';
import { useFiles } from '../useFiles';

export const useTikTokAssetNotification = (): void => {
  const { connection } = useContext(SignalRContext);
  const dispatch = useDispatch();
  const { updateFileUpload } = useFiles();

  useEffect(() => {
    const assetHandler = ({ accountPlatformAssets, errors, accountPlatformName }) => {
      accountPlatformAssets.forEach(({ fileId }) => {
        updateFileUpload(fileId, FileUploadStatus.UPLOADED);
        dispatch(
          fileUpdated({
            data: {
              id: fileId,
              tiktokAssetAPNames: accountPlatformName,
            },
          }),
        );
      });
      errors.forEach(({ fileId, message }) => {
        dispatch(
          updateFileUploadStatus({
            id: fileId,
            fileUploadStatus: FileUploadStatus.UPLOAD_FAILED,
            errors: [message],
          }),
        );
      });
    };

    if (connection) {
      connection.on('newTikTokAsset', assetHandler);
    }

    return function cleanup() {
      connection?.off('newTikTokAsset', assetHandler);
    };
  }, [connection]);
};

import { faCloudDownloadAlt, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButton from 'shared/components/molecules/ActionButton';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { downloadFile } from 'shared/utilities/fileUtility';
import React, { useEffect, useState } from 'react';
import { FileAction } from './FileListView';
import { BrkfstFile } from 'shared/typings/file';

interface Props {
  file: BrkfstFile;
  action: FileAction;
  onRemoveHandler: (file: BrkfstFile) => any;
  loading?: boolean;
}

const FileActionIcon: React.FC<Props> = ({ action, file, onRemoveHandler, loading = false }) => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (!loading) setClicked(false);
  }, [loading]);

  const thisFileIsLoading = clicked && loading;

  switch (action) {
    case FileAction.REMOVE:
      return (
        <ActionButton
          loading={thisFileIsLoading}
          loaderColor={COLORS.BLACK}
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          onClick={() => {
            if (onRemoveHandler) {
              onRemoveHandler(file);
              setClicked(true);
            }
          }}
        >
          {thisFileIsLoading ? <></> : <FontAwesomeIcon icon={faTimes} color={COLORS.RED500} />}
        </ActionButton>
      );
    case FileAction.DOWNLOAD:
      return (
        <ActionButton
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          onClick={() => {
            downloadFile(file);
            setClicked(true);
          }}
        >
          {thisFileIsLoading ? <></> : <FontAwesomeIcon icon={faCloudDownloadAlt} color={COLORS.NEUTRAL300} />}
        </ActionButton>
      );
    default:
      return <></>;
  }
};

export default FileActionIcon;

import React from 'react';
import cs from 'classnames';
import { Flex } from 'shared/components/display';
import sidebarStyles from './sidebarStyles';

interface Props {
  children: JSX.Element | JSX.Element[];
  className?: string;
  expanded?: boolean;
}
const Sidebar: React.FC<Props> = ({ children, className, expanded = false }) => {
  return (
    <Flex
      className={cs('sidebar', className, {
        'sidebar--expanded': expanded,
      })}
      as="aside"
      css={sidebarStyles}
    >
      {children}
    </Flex>
  );
};

export default Sidebar;

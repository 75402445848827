import React, { ReactElement, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { ListsEditor, withListsReact } from '@prezly/slate-lists';
import cs from 'classnames';
import { noop } from 'lodash';
import { BaseEditor, createEditor, Editor, Node, Transforms } from 'slate';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  RenderPlaceholderProps,
  Slate,
  withReact,
} from 'slate-react';

import { useSasToken } from 'features/media/useSasToken';
import { Box } from 'shared/components/display';
import { RICHTEXT_DEFAULT_VALUE } from 'shared/components/Richtext/config';
import { RichTextFormats, RichtextRef, RichtextValue } from 'shared/components/Richtext/shared/types';
import richTextStyles from 'shared/components/Richtext/styles';
import { useRichtextImages } from 'shared/components/Richtext/useRichtextImages';

import { canDeleteIndentation, getBlock, getMark, newLineIndentation, toggleBlock, toggleMark } from './shared/utils';
import { isRichtextEmpty } from './shared/utils';
import { AriaProps } from 'shared/typings/a11y/aria';

import RichToolBar from './RichtextToolBar/RichToolBar';
import { withListsPlugin } from './plugins';
import { withImages, withLinks } from './plugins';
import { Element, Leaf } from './RichPieces';

type RichTextA11y = Pick<AriaProps, 'ariaLabelledBy'>;

export type RichtextProps = {
  value: RichtextValue;
  onChange?: (data: RichtextValue, extraProps?: any) => void;
  onBlur?: () => void;
  readOnly?: boolean;
  className?: string;
  placeholder?: string;
  /** Sets maxWith to 800px to for greater accessibility */
  readableWidth?: boolean;
} & Partial<RichTextA11y>;

const Richtext = React.forwardRef<RichtextRef, RichtextProps>(
  (
    {
      className,
      readOnly = false,
      placeholder = '',
      readableWidth,
      ariaLabelledBy,
      value = RICHTEXT_DEFAULT_VALUE,
      onChange = noop,
      onBlur = noop,
    },
    ref,
  ) => {
    const richTextRef = useRef<ReactElement>();
    const sasToken = useSasToken();
    // Create a Slate editor object that won't change across renders.
    const [editor] = useState<BaseEditor & ReactEditor & ListsEditor & { isLink?: boolean }>(() =>
      withListsReact(withListsPlugin(withImages(withLinks(withReact(createEditor())), sasToken || ''))),
    );

    useRichtextImages(editor, readOnly);

    const renderLeaf = useCallback((props: RenderLeafProps) => <Leaf {...props} />, []);
    const renderElement = useCallback(
      (props: RenderElementProps) => <Element {...props} readOnly={readOnly} richTextElem={richTextRef.current} />,
      [readOnly],
    );

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
      const mark = getMark(event);
      if (mark) {
        toggleMark(editor, mark);
      }
      const block = getBlock(event);
      if (block) {
        event.preventDefault();
        toggleBlock(editor, block);
      }

      //Check for special cases with indentation
      //1. Backspacing on the first character of an indented text
      //2. Creating a new line in an indented text area
      if (canDeleteIndentation(event, editor)) {
        //Default backspace will move the indented line to the row above after removing indentation
        event.preventDefault();
        toggleBlock(editor, RichTextFormats.UNINDENT);
      }

      if (newLineIndentation(event, editor)) {
        event.preventDefault();
        //Check if the new line is in the middle of the block or at the end
        const selectedLeaf = Node.descendant(editor, editor.selection!.focus.path);
        if (selectedLeaf.text.length === editor.selection?.focus.offset) {
          Transforms.insertNodes(editor, {
            type: 'paragraph',
            children: [{ text: '', marks: [] }],
          });
        } else {
          Transforms.splitNodes(editor);
          Transforms.setNodes(editor, { type: 'paragraph' });
        }
      }
    };

    useImperativeHandle(ref, () => ({
      focus() {
        //Focusing in rich text does not scroll on default
        ReactEditor.focus(editor);
      },
      scrollIntoView() {
        if (editor.selection) {
          const domPoint = ReactEditor.toDOMPoint(editor, editor.selection.focus);
          const element = domPoint[0].parentElement;
          element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      },
    }));

    //Race condition occurs when switching to an empty rich text field after pasting an image, make sure the selection
    //is set to the starting point in a empty rich text field
    const handleOnFocus = () => {
      if (isRichtextEmpty(value)) {
        setTimeout(() => {
          Transforms.select(editor, {
            anchor: Editor.start(editor, []),
            focus: Editor.start(editor, []),
          });
        });
      }
    };

    const handleChange = (value: RichtextValue) => {
      onChange(value);
      if (editor.isLink) {
        onChange(value, { isLink: true });
        editor.isLink = false;
      }
    };

    return (
      <Box
        aria-labelledby={ariaLabelledBy}
        className={cs(className, 'rich-text', {
          'rich-text--read-only': readOnly,
          'readable-line-width': readableWidth,
        })}
        css={richTextStyles}
        ref={richTextRef}
        tabIndex={0}
      >
        {/* NOTE: Slate's value prop is poorly named, it's actually an initialValue. 
        If value needs to change after mount then give richtext a key so that react knows to re-render

        If value is null then the default value is not used and will cause an error from Slate if a fallback value is not provided here
      */}
        <Slate editor={editor} value={value || RICHTEXT_DEFAULT_VALUE} onChange={handleChange}>
          {!readOnly && <RichToolBar onBlur={onBlur} />}
          <Editable
            placeholder={placeholder}
            className="rich-text__editor"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            onKeyDown={handleKeyDown}
            readOnly={readOnly}
            spellCheck
            onBlur={onBlur}
            onFocus={handleOnFocus}
            tabIndex={0}
            renderPlaceholder={({ children }: RenderPlaceholderProps) => (
              <Box className="rich-text__placeholder" contentEditable={false}>
                {children}
              </Box>
            )}
          />
        </Slate>
      </Box>
    );
  },
);

export default Richtext;

import React from 'react';
import { Field } from 'formik';
import { startCase } from 'lodash';
import * as yup from 'yup';

import { MAX_NAME_LENGTH, MAX_SLOGAN_LENGTH } from 'features/accounts/components/molecules/NewAccountForm/config';
import { BrandAdvertisingValues } from 'features/organizations/components/organisms/OrgOnboardingStepFields/types';
import ImageFileInput, { DEFAULT_VALUE } from 'shared/components/molecules/ImageFileInput';
import TextInput from 'shared/components/TextInput';

import { BRKFST_SUPPORTED_IMAGE_FORMATS } from 'shared/config/fileFormats';
import { MEDIA } from 'shared/config/media';
import { IMAGE_SIZE_LIMIT, MESSAGES } from 'shared/config/validations';
import { intl } from 'shared/lib/intl';
import { YUP_FIELDS } from 'shared/utilities/yupFieldUtility';

export const BrandAdvertisingStepFields: React.FC = () => {
  return (
    <>
      <Field
        name={BRAND_ADVERTISING_FIELD_NAMES.BRAND_NAME}
        component={TextInput}
        label={`${startCase(BRAND_ADVERTISING_FIELD_NAMES.BRAND_NAME)}*`}
        placeholder={`${startCase(BRAND_ADVERTISING_FIELD_NAMES.BRAND_NAME)}*`}
        showCharactersCounter
        maxLength={MAX_NAME_LENGTH}
      />
      <Field
        name={BRAND_ADVERTISING_FIELD_NAMES.BRAND_WEBSITE}
        component={TextInput}
        label={`${startCase(BRAND_ADVERTISING_FIELD_NAMES.BRAND_WEBSITE)}*`}
        placeholder={`${startCase(BRAND_ADVERTISING_FIELD_NAMES.BRAND_WEBSITE)}*`}
      />
      <Field
        name={BRAND_ADVERTISING_FIELD_NAMES.BRAND_LOGO}
        component={ImageFileInput}
        acceptedFormats={BRKFST_SUPPORTED_IMAGE_FORMATS}
        label={startCase(BRAND_ADVERTISING_FIELD_NAMES.BRAND_LOGO)}
        placeholder={startCase(BRAND_ADVERTISING_FIELD_NAMES.BRAND_LOGO)}
      />
      <Field
        name={BRAND_ADVERTISING_FIELD_NAMES.BRAND_SLOGAN}
        component={TextInput}
        label={startCase(BRAND_ADVERTISING_FIELD_NAMES.BRAND_SLOGAN)}
        placeholder={startCase(BRAND_ADVERTISING_FIELD_NAMES.BRAND_SLOGAN)}
        showCharactersCounter
        maxLength={MAX_SLOGAN_LENGTH}
      />
    </>
  );
};

export const BRAND_ADVERTISING_FIELD_NAMES = {
  BRAND_NAME: 'brandName',
  BRAND_WEBSITE: 'brandWebsite',
  BRAND_LOGO: 'brandLogo',
  BRAND_SLOGAN: 'brandSlogan',
};

export const brandAdvertisingInitialValues: BrandAdvertisingValues = {
  brandName: '',
  brandWebsite: '',
  brandLogo: DEFAULT_VALUE,
  brandSlogan: '',
};

// @ts-ignore
export const brandAdvertisingSchema: yup.SchemaOf<BrandAdvertisingValues> = yup.object({
  brandName: yup.string().customRequiredString().max(MAX_NAME_LENGTH),
  brandWebsite: YUP_FIELDS.DOMAIN_REQUIRED,
  brandLogo: yup
    .object({
      file: yup.mixed(),
    })
    .nullable()
    .supportedFile({
      message: intl.formatMessage({ id: 'WARNING_SUPPORTED_MEDIA' }, { supportedMedia: MEDIA.TYPES.IMAGE }),
      supportedFormats: BRKFST_SUPPORTED_IMAGE_FORMATS,
    })
    .fileSize({
      fileSizeMax: IMAGE_SIZE_LIMIT,
      message: MESSAGES.IMAGE_SIZE_LIMIT,
    })
    .fileNameLength({ message: MESSAGES.FILE_NAME_LENGTH }),
  brandSlogan: yup.string().max(MAX_SLOGAN_LENGTH),
});

export const USER_TOASTS = {
  UPDATED_USER_FIELD: 'Successfully updated user information',
  UPDATED_CREATOR_NOTES: 'Successfully updated creator notes',
  PICTURE_REMOVED: 'Profile picture removed',
  PICTURE_ADDED: 'Profile picture successfully added',
  FACEBOOK_CONNECTED: 'Facebook account connected',
  MOBILE_UPDATED: 'Information Updated',
  INSTAGRAM_REMOVED: 'Your Instagram account has been disconnected.',
  NOTIFICATIONS_TURNED_ON: 'You have successfully subscribed to text notifications.',
  NOTIFICATIONS_TURNED_OFF: 'You have successfully unsubscribed from text notifications.',
  PHONE_UPDATED: 'Phone number successfully updated',
  PHONE_UPDATED_WITH_NOTIFICATIONS: 'You have successfully subscribed to text notifications on this new number',
  USER_ADDRESS_UPDATED: 'Successfully updated address',
};

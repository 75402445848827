import React, { useRef } from 'react';
import { Button } from 'rebass/styled-components';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { Flex, Box } from 'shared/components/display';
import styles from './styles';
import { Field, FormikProps } from 'formik';
import TextInput from 'shared/components/TextInput';
import { useFormikContext } from 'formik';
import { FORM_COPY } from 'shared/config/copy';

export interface Variable {
  value: string;
  display?: string;
}

interface Props {
  name: string;
  variables: readonly Variable[] | Variable[];
  disabled?: boolean;
  maxLength?: number;
  label: string;
  placeholder: string;
}

const ParameterizedTextInput: React.FC<Props> = ({
  name,
  variables = [],
  disabled = false,
  maxLength = Infinity,
  label,
  placeholder,
}) => {
  const { values, setFieldValue, setFieldError } = useFormikContext<FormikProps<Props>>();
  const inputRef = useRef<HTMLInputElement>(null);

  const clickHandler = (eventValue) => {
    const existingValue = values[name] || '';
    const newValue = `${existingValue}${eventValue}`;
    if (newValue.length <= maxLength) {
      setFieldValue(name, newValue);
      if (inputRef.current) inputRef.current.focus();
    } else {
      setFieldError(name, FORM_COPY.INPUT_MAX_CHARACTER_VAR);
    }
  };

  return (
    <Box css={styles} className="parameterized-text-input">
      <Flex className="parameterized-text-input__variables">
        {variables.map(({ value, display }) => (
          <Button
            key={display}
            type="button"
            mr={2}
            onClick={() => clickHandler(`{{${value}}}`)}
            variant={BUTTON_VARIANTS.MICRO}
            disabled={disabled}
            className="parameterized-text-input__btn"
          >
            {display}
          </Button>
        ))}
      </Flex>
      <Field
        innerRef={inputRef}
        disabled={disabled}
        maxLength={maxLength}
        name={name}
        component={TextInput}
        label={label}
        placeholder={placeholder}
      />
    </Box>
  );
};

export default ParameterizedTextInput;

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';

export default css({
  '&.creator-briefs': {
    position: 'relative',
    '& thead': {
      height: '48px',
      position: 'sticky',
      top: 0,
    },
    'tr:has(* .creator-briefs__row--selected)': {
      backgroundColor: `${COLORS.PRIMARY100}40`,
    },
    'td:has(* .creator-briefs__row--selected)': {
      position: 'relative',
    },
  },
  '.creator-briefs': {
    '&__selected': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '5px',
      height: '50px',
      backgroundColor: COLORS.PRIMARY500,
      justifyContent: 'flex-start',
      zIndex: zIndex.zForeground,
    },
  },
});

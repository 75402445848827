import { css } from '@emotion/react';

import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.account-settings': {
    margin: '0 auto',
    [mediaquery.md]: {
      width: '50%',
    },
  },
});

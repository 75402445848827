export const LOCAL_STORAGE = Object.freeze({
  BRAND_LOGO_ID: 'brandLogoId',
  BRIEF_ASSET_IDS: 'briefAssetIds',
  MEDIA_DISPLAY_FORMAT: 'mediaDisplayFormat',
  CREATOR_ONBOARDING: 'creatorOnboardingV3',
  FB_BUSINESS_ID: 'fbBusinessId',
  INVITE_TOKEN: 'inviteToken',
  NEW_ORG_STATE: 'newOrgState',
  ONBOARDING_STATE: 'onboardingState',
  ORGANIZATION_ONBOARDING: 'organizationOnboarding',
  REDIRECT_AFTER_LOGIN: 'redirectForGmail',
  SAS_TOKEN: 'sasToken',
  SIDEBAR_EXPANDED: 'sidebarExpanded',
  TOKEN: 'token',
  VIDEO_FILE_ID: 'videoFileId',
  WIDGET_LAYOUT: 'widgetLayout',
  VIDEO_COMPOSITION: 'videoComposition',
});

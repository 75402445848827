import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.richtext-tool-bar': {
    display: 'flex',
    border: `1px solid ${COLORS.NEUTRAL300}`,
    borderBottom: 'none',
    borderTopRightRadius: '2px',
    borderTopLeftRadius: '2px',
    padding: theme.space[3],
  },
  '.richtext-tool-bar': {
    '&__icon': {
      fontSize: '12px',
    },
    '&__icon svg': {
      margin: '4px',
    },
    '&__icon svg:hover, &__icon a:hover svg': {
      color: COLORS.PRIMARY500,
    },
    '&__divider': {
      margin: `0 ${theme.space[1]}`,
    },
    '&__divider::before': {
      content: '"|"',
      color: COLORS.NEUTRAL600,
    },
    '&__subgroup-caret': {
      paddingLeft: '2px',
      fontSize: '11px',
    },
    '&__subgroup': {
      margin: 0,
      padding: '0 4px',
    },
    '&__subgroup:focus': {
      backgroundColor: COLORS.PRIMARY200,
    },
  },
});

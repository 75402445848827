import React, { useMemo } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import cs from 'classnames';
import { FormikErrors } from 'formik';
import { isObject } from 'lodash';
import * as yup from 'yup';

import { Box } from 'shared/components/display';
import { ErrorMessage } from 'shared/components/FieldErrorMessage';
import { SelectInputProps, SelectValue } from 'shared/components/molecules/Select/interfaces';
import Select from 'shared/components/molecules/Select/Select';

import { MESSAGES } from 'shared/config/validations';
import { Input } from 'shared/lib/formik';

export interface Value {
  value: string;
  label: string;
}

export const emptySelectOption: Readonly<SelectValue<any>> = Object.freeze({
  label: 'Select an Option',
  value: '',
  isDisabled: true,
});

export const getSelectInputSchema = (required?: boolean, valueSchema: any = yup.number()) => {
  const baseSchema = yup.object({
    label: yup.string(),
    value: valueSchema,
  });
  if (required) {
    return baseSchema.test({
      name: 'required',
      message: MESSAGES.REQUIRED_FIELD,
      test(value) {
        return Boolean(value?.value);
      },
    });
  }
  return baseSchema;
};

const SelectInput: Input<SingleValue<Value> | MultiValue<Value>, SelectInputProps> = ({
  field: { name, value, onChange: formikOnChange, onBlur },
  form: { errors, touched },
  onChange,
  disabled = false,
  width,
  className,
  validationFromStart = false,
  runBlurOnChange = true,
  dataCy,
  ...props
}) => {
  const error = errors[name] as FormikErrors<any> | undefined;
  const onChangeCallback = (val: SingleValue<Value> | MultiValue<Value>, actionMeta) => {
    const event = { target: { name, value: val } };
    formikOnChange(event);
    if (onChange) onChange(val, actionMeta);
    if (runBlurOnChange) onBlur(event);
  };

  const errorMessage = useMemo(() => {
    const errorIn = Array.isArray(error) ? error[0] : error;
    if (errorIn?.value) {
      if (isObject(errorIn.value)) return Object.values(errorIn.value)[0];
      return errorIn.value;
    } else return errorIn;
  }, [error]);

  const hasErrors = useMemo(() => {
    if (validationFromStart) return Boolean(errorMessage);
    else return Boolean(errorMessage && touched[name]);
  }, [validationFromStart, errorMessage, touched, name]);

  return (
    <Box
      style={{
        width,
      }}
      className={cs(className)}
    >
      <Select
        {...props}
        width={width}
        onChange={onChangeCallback}
        hasErrors={hasErrors}
        name={name}
        value={value}
        disabled={disabled}
        dataCy={dataCy}
      />
      {hasErrors ? <ErrorMessage dataCy={`${dataCy}__error-message`}>{errorMessage}</ErrorMessage> : null}
    </Box>
  );
};

export default SelectInput;

import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import React, { FC, useEffect, useState } from 'react'; // or any spinner component

export interface Props {
  delay?: number;
}

const LazySpinner: FC<Props> = ({ delay = 450 }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  return show ? <CenteredSpinnerContainer /> : null;
};

export default LazySpinner;

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
    display: 'flex',
    flexDirection: 'column',
    margin: theme.space[5],
    '.assets-library': {
        '&__heading': {
            marginBottom: theme.space[5],
        },
    },
});

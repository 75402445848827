import config from 'config';
import CryptoJS from 'crypto-js';

export const encryptString = (string: string) => {
  // encrypted to be url safe
  const b64 = CryptoJS.AES.encrypt(string, config.ENCRYPTION_KEY).toString();
  const e64 = CryptoJS.enc.Base64.parse(b64);
  return e64.toString(CryptoJS.enc.Hex);
};

export const decryptString = (encryptedString: string) => {
  if (encryptedString) {
    const bytes = CryptoJS.enc.Hex.parse(encryptedString).toString(CryptoJS.enc.Base64);
    const decrypt = CryptoJS.AES.decrypt(bytes, config.ENCRYPTION_KEY);
    return decrypt.toString(CryptoJS.enc.Utf8);
  }
  return null;
};

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  '&.drag-drop': {
    width: '100%',
    '&--disabled .drag-drop__input-wrapper': {
      color: COLORS.NEUTRAL600,
      border: `1px dashed ${COLORS.NEUTRAL500}`,
      cursor: 'default',
    },
  },
  '.drag-drop': {
    '&__input-wrapper': {
      border: `1px dashed ${COLORS.PRIMARY500}`,
      borderRadius: `4px`,
      backgroundColor: COLORS.NEUTRAL50,
      padding: `1rem`,
      width: `100%`,
      textAlign: `center`,
      cursor: `pointer`,
    },
    '&__input-wrapper--drag-enter': {
      backgroundColor: COLORS.PRIMARY100,
    },
  },
});

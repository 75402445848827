import React from 'react';
import cs from 'classnames';
import { BRIEF_COPY } from 'shared/config/copy';
import PromptButton from 'shared/components/molecules/PromptButton';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import { Box } from 'shared/components/display';
import styles from './styles';

interface Props {
  briefId: number;
  variant?: string;
  className?: string;
  onClick?: () => void;
}
const DeleteDraftButton: React.FC<Props> = ({ briefId, variant, className, onClick }) => {
  const { deleteBrief } = useBriefs();

  return (
    <Box css={styles}>
      <PromptButton
        text={BRIEF_COPY.MODAL_DELETE_BRIEF_DRAFT}
        onConfirm={() => deleteBrief(briefId)}
        className={cs('delete-draft-button', className)}
        variant={variant}
        onClick={onClick}
      >
        {BRIEF_COPY.BUTTON_DELETE_DRAFT}
      </PromptButton>
    </Box>
  );
};

export default DeleteDraftButton;

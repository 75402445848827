import { createSlice } from '@reduxjs/toolkit';

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState: [],
  reducers: {
    organizationsLoaded: (organizations, action) => {
      return action.payload.data;
    },
    organizationAdded: (organizations, action) => {
      organizations.push(action.payload.data);
    },
    organizationChanged: (organizations, action) => {
      const org = organizations.find((o) => o.id === action.payload.data.id);
      const index = organizations.indexOf(org);
      organizations.splice(index, 1, { ...org, ...action.payload.data });
    },
    organizationPinned: (organizations, action) => {
      const org = organizations.find((o) => o.id === action.payload.data.organizationId);
      const index = organizations.indexOf(org);
      organizations.splice(index, 1, { ...org, pinned: true });
    },
    organizationUnPinned: (organizations, action) => {
      const org = organizations.find((o) => o.id === action.payload.data.organizationId);
      const index = organizations.indexOf(org);
      organizations.splice(index, 1, { ...org, pinned: false });
    },
    addressUpdated: (organizations, action) => {
      const { organizationId, address } = action.payload.data;
      organizations.map((org) => {
        if (organizationId === org.id) {
          org.billingAddress = address;
        }
        return org;
      });
    },
  },
});

export const {
  organizationsLoaded,
  organizationChanged,
  organizationAdded,
  addressUpdated,
  organizationPinned,
  organizationUnPinned,
} = organizationsSlice.actions;

export default organizationsSlice.reducer;

export const getOrganizations = (state) => state.organizations;
export const getOrganization = (organizationId) => (state) =>
  state.organizations.find((element) => +element.id === +organizationId) || {};

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '.back-button': {
    '&__text': {
      whiteSpace: 'nowrap',
      color: COLORS.NEUTRAL600,
      display: 'flex',
      alignItems: 'center',
      marginBottom: 0,
    },
    '&__btn:hover svg': {
      color: COLORS.NEUTRAL600,
    },
  },
});

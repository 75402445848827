// widget keys

import { COLUMNS } from 'shared/components/atoms/WidgetWrapper/config';
import { Layouts } from 'react-grid-layout';

export const ACCOUNT_WIDGET_KEYS = {
  FIRST: 'FIRST',
  SECOND: 'SECOND',
  THIRD: 'THIRD',
  FOURTH: 'FOURTH',
};

const HEIGHTS = {
  DESKTOP: 15,
  TABLET: 10,
  PHONE: 7,
};

export const ACCOUNT_LAYOUTS: Layouts = {
  DESKTOP: [
    { i: ACCOUNT_WIDGET_KEYS.SECOND, w: COLUMNS.DESKTOP/2, h: HEIGHTS.DESKTOP/2, x:0 , y: 0 },
    { i: ACCOUNT_WIDGET_KEYS.THIRD, w: COLUMNS.DESKTOP/2, h: HEIGHTS.DESKTOP/2, x: COLUMNS.DESKTOP/2, y: 0 },
  ],
  TABLET: [
    { i: ACCOUNT_WIDGET_KEYS.SECOND, w: COLUMNS.TABLET/2, h: HEIGHTS.TABLET, x: 0, y: 0},
    { i: ACCOUNT_WIDGET_KEYS.THIRD, w: COLUMNS.TABLET/2, h: HEIGHTS.TABLET, x: COLUMNS.TABLET/2, y: 0},
  ],
  PHONE: [
    { i: ACCOUNT_WIDGET_KEYS.SECOND, w: COLUMNS.PHONE, h: HEIGHTS.PHONE , x: 0, y: 0},
    { i: ACCOUNT_WIDGET_KEYS.THIRD, w: COLUMNS.PHONE, h: HEIGHTS.PHONE , x: 0, y: HEIGHTS.PHONE  },
  ],
};

export const ACCOUNT_LAYOUTS_REPORT: Layouts = {
  DESKTOP: [
    { i: ACCOUNT_WIDGET_KEYS.FIRST, w: COLUMNS.DESKTOP, h: HEIGHTS.DESKTOP, x: 0, y: 0, maxH: 22},
    { i: ACCOUNT_WIDGET_KEYS.SECOND, w: COLUMNS.DESKTOP/2, h: HEIGHTS.DESKTOP/2, x:0 , y: HEIGHTS.DESKTOP/2 },
    { i: ACCOUNT_WIDGET_KEYS.THIRD, w: COLUMNS.DESKTOP/2, h: HEIGHTS.DESKTOP/2, x: COLUMNS.DESKTOP/2, y: HEIGHTS.DESKTOP/2 },
  ],
  TABLET: [
    { i: ACCOUNT_WIDGET_KEYS.FIRST, w: COLUMNS.TABLET, h: HEIGHTS.TABLET, x: 0, y: 0},
    { i: ACCOUNT_WIDGET_KEYS.SECOND, w: COLUMNS.TABLET/2, h: HEIGHTS.TABLET, x: 0, y: HEIGHTS.TABLET },
    { i: ACCOUNT_WIDGET_KEYS.THIRD, w: COLUMNS.TABLET/2, h: HEIGHTS.TABLET, x: COLUMNS.TABLET/2, y: HEIGHTS.TABLET },
  ],
  PHONE: [
    { i: ACCOUNT_WIDGET_KEYS.FIRST, w: COLUMNS.PHONE, h: HEIGHTS.PHONE, x: 0, y: 0},
    { i: ACCOUNT_WIDGET_KEYS.SECOND, w: COLUMNS.PHONE, h: HEIGHTS.PHONE , x: 0, y: HEIGHTS.PHONE },
    { i: ACCOUNT_WIDGET_KEYS.THIRD, w: COLUMNS.PHONE, h: HEIGHTS.PHONE , x: 0, y: HEIGHTS.PHONE * 2 },
  ],
};

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export const formStyles = css({
  '&.attribution-window-form': {
    marginLeft: theme.space[3],
  },
  '.attribution-window-form': {
    '&__revert-btn-wrapper': {
      justifyContent: 'flex-end',
    },
    '&__field-and-btn': {
      alignItems: 'baseline',
      justifyContent: 'space-between',
    },
    '&__cancel-btn': {
      marginRight: theme.space[4],
    },
    '&__clear-btn': {
      color: COLORS.NEUTRAL500,
    },
    '&__btn-wrapper': {
      padding: theme.space[4],
    },
  },
});

import React, { useEffect, useMemo, useState } from 'react';
import cs from 'classnames';
import { Box, Text } from 'shared/components/display';
import { Field } from 'formik';
import { MultiStepForm, StepForm } from 'shared/components/forms/MultiStepForm';

import TextareaInput from 'shared/components/TextareaInput/TextareaInput';
import CheckboxGroupInput from 'shared/components/molecules/CheckboxGroupInput';
import ModalStepWrapper from 'shared/components/forms/MultiStepForm/Steps/ModalStepWrapper';
import { Brief } from 'shared/typings/briefs';
import {
  AddressFormFields,
  addressPhoneOnSubmit,
  addressSchemaWithPhone,
  getInitialValues,
} from 'shared/components/organisms/AddressFormFields';
import { useGeoLocation } from 'shared/hooks/useGeoLocationHook';
import { useUser } from 'features/users/hooks/useUser';
import { PhoneStatus } from 'shared/typings/user/enums';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { FormattedMessage } from 'react-intl';
import { BRIEF_COPY, GENERIC_COPY } from 'shared/config/copy';

import {
  PITCH_FIELDNAMES,
  STATE_FIELDNAMES,
  applicationPitchInitialValues,
  applicationPitchSchema,
  inStoreRequirementSchema,
} from './config';
import styles from './styles';
import { ShippingConfirmationSteps } from './steps/ShippingConfirmationSteps';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';

interface Props {
  onSubmit: (data: { pitch: string; states: { checked: boolean; value: number }[] }) => void;
  brief: Brief;
  userId: number;
  open?: boolean;
  onCancel: () => void;
}

const CreatorBriefStartingSteps: React.FC<Props> = ({ brief, userId, onSubmit, onCancel, open }) => {
  const { shippingRequired, states, briefCreatorAutoApproved, briefCreatorStatus } = brief;

  const [addressError, setAddressError] = useState<string[]>([]);
  const { validateAddress } = useGeoLocation();
  const { user, getUserAddress, updateUserAddress, createOrUpdatePhone } = useUser(userId);
  const { mailingAddress: address, phone } = user;
  const hasStateRequirements = Boolean(states?.length && states.length > 0);

  const creatorIsInvited = briefCreatorStatus === UserBriefStatus.INVITED;

  const initialStateValues = useMemo(
    () => ({
      states: (states || []).map((state) => ({
        label: state.code,
        value: state.briefInStoreRequirementId,
        checked: false,
      })),
    }),
    [states],
  );

  const addressInitialValues = getInitialValues(address, phone);

  useEffect(() => {
    getUserAddress(userId);
  }, [userId]);

  const onConfirm = (values) => {
    const SHIPPING_STEP = 'SHIPPING',
      STATE_STEP = 'STATE',
      PITCH_STEP = 'PITCH';
    const STEPS: string[] = [];
    if (hasStateRequirements) STEPS.push(STATE_STEP);
    if (shippingRequired) STEPS.push(SHIPPING_STEP);
    if (!briefCreatorAutoApproved) STEPS.push(PITCH_STEP);
    const shippingStepStepKey = STEPS.indexOf(SHIPPING_STEP) + 1;
    const stateStepStepKey = STEPS.indexOf(STATE_STEP) + 1;
    const pitchStepStepKey = STEPS.indexOf(PITCH_STEP) + 1;
    const shippingData = values[shippingStepStepKey];
    const stateData = values[stateStepStepKey];
    const pitchData = values[pitchStepStepKey];

    if (shippingData) {
      const {
        phoneNumber,
        countryCode,
        state: { value: state },
        country: { value: country },
      } = shippingData;
      const stateId = state?.id || null; // Null if no state to remove relation if one already exists
      const mailingAddress = { ...shippingData, countryId: country.id, stateId };
      createOrUpdatePhone({
        notifications: phone?.status === PhoneStatus.ACTIVE || false,
        number: phoneNumber,
        countryCode,
        showToast: false,
        onSuccess: () => {
          updateUserAddress({
            mailingAddress,
            showToast: false,
          });
        },
      });
    }

    onSubmit({ pitch: pitchData?.pitch, states: stateData?.states });
  };

  const extraWrapperProps = { open };

  return (
    <Box css={styles} className={cs('creator-brief-starting-steps')}>
      <MultiStepForm StepWrapper={ModalStepWrapper} onConfirm={onConfirm}>
        {hasStateRequirements && (
          <StepForm<{ states: { checked?: boolean; value?: number }[] }>
            title="Store Visit Confirmation - State"
            initialValues={initialStateValues}
            validationSchema={inStoreRequirementSchema}
            extraWrapperProps={extraWrapperProps}
            onCancel={onCancel}
            submitText={GENERIC_COPY.BUTTON_SUBMIT}
            disableUntilTouched
            showCancel
          >
            <Box css={styles} className="creator-brief-starting-steps">
              <Text variant={TEXT_VARIANTS.H5} className="creator-brief-starting-steps__states-subheading">
                {BRIEF_COPY.MODAL_INSTORE_REQUIREMENT}
              </Text>
              <CheckboxGroupInput name={STATE_FIELDNAMES.STATES} />
            </Box>
          </StepForm>
        )}
        {shippingRequired && (
          <StepForm
            title="Shipping Address Confirmation"
            validationSchema={addressSchemaWithPhone}
            initialValues={addressInitialValues}
            extraWrapperProps={{ open, wide: true, showButtonDivider: true }}
            onSubmit={addressPhoneOnSubmit(validateAddress, setAddressError)}
            submitText="Yes, mail to this address"
            onCancel={onCancel}
            dataCy="shipping-address"
            showCancel
          >
            <ShippingConfirmationSteps addressError={addressError} />
          </StepForm>
        )}

        <StepForm
          initialValues={applicationPitchInitialValues}
          validationSchema={applicationPitchSchema}
          extraWrapperProps={{ open, wide: true, showButtonDivider: true }}
          onCancel={onCancel}
          submitText={creatorIsInvited ? 'Confirm Invite' : 'Confirm Application'}
          title={creatorIsInvited ? 'Confirm Invitation' : 'Confirm Application'}
          dataCy="application-pitch"
          showCancel
        >
          <Box css={styles} className="creator-brief-starting-steps">
            <Text variant={TEXT_VARIANTS.H5} className="creator-brief-starting-steps__pitch__subheading">
              <FormattedMessage
                id={briefCreatorAutoApproved ? 'MODAL_CREATOR_BRIEF_LEGAL_AUTO_APPROVAL' : 'MODAL_CREATOR_BRIEF_LEGAL'}
                values={{
                  b: (chunks) => <strong>{chunks}</strong>,
                  ul: (chunks) => <ul>{chunks}</ul>,
                  li: (chunks) => <li className="creator-brief-starting-steps__li">{chunks}</li>,
                }}
              />
            </Text>
            {!briefCreatorAutoApproved && (
              <>
                <hr className="creator-brief-starting-steps__pitch__hr" />
                <Text variant={TEXT_VARIANTS.H5} className="creator-brief-starting-steps__pitch__subheading">
                  {BRIEF_COPY.MODAL_APPLICATION_PITCH_DESCRIPTION}
                </Text>
                <Field
                  name={PITCH_FIELDNAMES.PITCH}
                  className="creator-brief-starting-steps__pitch"
                  component={TextareaInput}
                  rows={7}
                  maxLength={300}
                  noMargin
                  showCharactersCounter
                  softMaxLength={300}
                  dataCy="creator-brief-starting-steps__pitch"
                  placeholder="Write your pitch"
                />
              </>
            )}
          </Box>
        </StepForm>
      </MultiStepForm>
    </Box>
  );
};

export default CreatorBriefStartingSteps;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.org-display': {
    '&__link:hover': {
      textDecoration: 'none',
    },
    '&__initials': {
      borderRadius: '50%',
      paddingTop: '0.5rem',
      backgroundColor: COLORS.BLACK,
      color: COLORS.WHITE,
      width: '2.7rem',
      height: '2.7rem',
      textAlign: 'center',
      margin: '1rem',
    },
    '&__initials:hover': {
      boxShadow: theme.shadows.SHADOW06,
    },
  },
});

import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'rebass/styled-components';
import cs from 'classnames';

import userRoleStyles from 'features/auth/pages/UserRole/userRoleStyles';
import { useUser } from 'features/users/hooks/useUser';
import Icon from 'shared/components/atoms/Icon';
import { Flex, Text } from 'shared/components/display';

import { GENERIC_COPY, USER_COPY } from 'shared/config/copy';
import { GLOBAL_COPY_VARIABLES } from 'shared/config/copy/allCopy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

const { TEXT_ONLY } = BUTTON_VARIANTS;
const { BODY } = TEXT_VARIANTS;

/**
 * Allow new users to choose if they are a org user or a creator.
 */
const UserRole = () => {
  const {
    currentUser: { id },
  } = useCurrentUser();

  const { setUserType, loading, fetchUser } = useUser(id);

  useEffect(() => {
    fetchUser(id);
  }, [id]);

  const { formatMessage } = useIntl();
  const [userType, setState] = useState<UserType | null>(null);

  const updateUserType = (e, newUserType) => {
    setState(newUserType);
    e.currentTarget.blur();
  };

  const onUserTypeSubmit = () => {
    setUserType({
      type: userType,
      id,
      pushFunction: () =>
        userType === UserType.CREATOR
          ? LinkCreator.createLink({
              userType: UserType.CREATOR,
              routeKey: 'CREATOR_ONBOARDING',
            })
          : LinkCreator.createLink({
              routeKey: 'ORGANIZATION_ONBOARDING',
            }),
    });
  };

  return (
    <Flex className="user-role" css={userRoleStyles}>
      <Text as="h3" className="user-role__heading">
        {formatMessage(
          {
            id: 'HEADING_WELCOME',
          },
          { appName: GLOBAL_COPY_VARIABLES.APP_NAME },
        )}
      </Text>
      <Text as="h5" className="user-role__sub-heading">
        {USER_COPY.SUBHEADING_WELCOME}
      </Text>
      <Button variant={TEXT_ONLY} onClick={(e) => updateUserType(e, UserType.ORG)}>
        <Flex
          className={cs('user-role__role', {
            'user-role__role--selected': userType === UserType.ORG,
          })}
        >
          <Flex className="user-role__icon-container">
            <Icon icon="graph-chart" />
          </Flex>
          <Flex className="user-role__heading-wrapper">
            <Text as="h6" className="user-role__role-title">
              {USER_COPY.HEADING_BRAND}
            </Text>
            <Text variant={BODY}>{USER_COPY.SUBHEADING_BRAND}</Text>
          </Flex>
        </Flex>
      </Button>
      <Button variant={TEXT_ONLY} onClick={(e) => updateUserType(e, UserType.CREATOR)}>
        <Flex
          className={cs('user-role__role', {
            'user-role__role--selected': userType === UserType.CREATOR,
          })}
        >
          <Flex className="user-role__icon-container">
            <Icon icon="camera" />
          </Flex>
          <Flex className="user-role__heading-wrapper">
            <Text as="h6" className="user-role__role-title">
              {USER_COPY.HEADING_CREATOR}
            </Text>
            <Text variant={BODY}>{USER_COPY.SUBHEADING_CREATOR}</Text>
          </Flex>
        </Flex>
      </Button>
      <Button onClick={onUserTypeSubmit} disabled={!userType || loading} className="user-role__btn">
        {GENERIC_COPY.BUTTON_SUBMIT}
      </Button>
    </Flex>
  );
};

export default UserRole;

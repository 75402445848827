import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useAccountScoreNotification } from 'features/accountPlatforms/hooks/useAccountScoreNotification';
import OrganizationOnboarding from 'features/organizations/pages/OrganizationOnboarding';

import { LinkCreator } from 'shared/utilities/linkUtility';

import OnboardingWrapper from './OnboardingWrapper';

const BrandOnboardingRoutes: React.FC = () => {
  useAccountScoreNotification();
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/org-onboarding" />} />
      <Route element={<OnboardingWrapper />}>
        <Route path="/org-onboarding" element={<OrganizationOnboarding />} />
        <Route element={<Navigate to={LinkCreator.createLink({ routeKey: 'NOT_FOUND' })} />} />
      </Route>
    </Routes>
  );
};

export default BrandOnboardingRoutes;

import React, { useState } from 'react';
import Modal from 'shared/components/molecules/Modal';
import ReportForm from 'features/accounts/components/molecules/ReportForm/ReportForm';
import { useReport } from 'features/accounts/reports/useReport';
import { usePerformance } from 'shared/hooks/usePerformance';
import { REPORT_COPY } from 'shared/config/copy';
import { Button } from 'rebass/styled-components';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { SIZING } from 'shared/config/formatting';
import { ReportValues } from 'features/accounts/components/molecules/ReportForm/config';

interface Props {
  accountId: number;
  accountPlatformId: number;
  onClick?: () => void;
}
const CreateReportModalForm: React.FC<Props> = ({ accountId, accountPlatformId, onClick }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { createReport } = useReport();
  const {
    performanceState: { params },
  } = usePerformance();

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const onSubmit = (data: ReportValues) => {
    const { name, interval, intervalValue } = data;
    createReport({
      name,
      interval: `${intervalValue} ${interval.value}`,
      accountId,
      accountPlatformId,
      params,
    });
    toggleModal();
  };

  const handleClick = () => {
    toggleModal();
    onClick && onClick();
  };

  return (
    <>
      <Button onClick={handleClick} variant={BUTTON_VARIANTS.DROPDOWN_FOOTER}>
        {REPORT_COPY.BUTTON_CREATE_REPORT}
      </Button>
      <Modal
        title={REPORT_COPY.FORM_HEADER_NEW_REPORT}
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        modalSize={SIZING.MEDIUM}
        component={<ReportForm onSubmit={onSubmit} />}
      />
    </>
  );
};

export default CreateReportModalForm;

import React from 'react';

import { StreamChatChannel } from '../../shared/messaging/types';

export const parseChannelDisplayTitle = (displayTitle: string) => {
  if (displayTitle) {
    const splitTitle = displayTitle.split('-');
    if (splitTitle.length === 2) {
      return splitTitle[1].trim();
    }
    return displayTitle;
  }
  return '';
};

export const getUserImage = (channel: StreamChatChannel, isCreator: boolean): string => {
  if (isCreator) {
    // @ts-ignore
    return channel.data.brandLogo ?? '';
  }
  const creatorId = channel?.id?.replace(new RegExp('u([0-9]+)-a[0-9]+'), '$1');
  const members = Object.values(channel.state.members);
  const creator = members.find((member) => member.user?.id == creatorId);
  return (creator?.user?.image as string) || '';
};

export const createSearchEmpty = (searchEmpty: string) => {
  return (
    <div className="str-chat__channel-search-results-header" aria-live="polite">
      {searchEmpty}
    </div>
  );
};

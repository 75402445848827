// * Lib
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle, faClock, faRectangleList } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ConnectInstagram } from 'features/accountPlatforms/facebook/components/ConnectInstagramForm';
// * Util
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
//* Display/UI
import { Box, Flex } from 'shared/components/display';
import Heading from 'shared/components/molecules/Heading';
import { ToggleEditInformation } from 'shared/components/molecules/ToggleEditInformation';

import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import { GLOBAL_COPY_VARIABLES } from 'shared/config/copy/allCopy';
import { useQuery } from 'shared/hooks/useQuery';
import COLORS from 'shared/styles/colors';
// * Styles
import { TEXT_VARIANTS } from 'shared/styles/text';
import { AccountPlatformStatus } from 'shared/typings/accountPlatform/enums';
import { FacebookBusinessAsset } from 'shared/typings/facebookBusinessAsset';
import { Status, Type } from 'shared/typings/facebookBusinessAsset/enums';

import { ConnectProductCatalog } from '../components/organisms/ConnectProductCatalog';

import styles from './styles';

const LABEL_MAP = Object.freeze({
  PAGE: 'Facebook Page',
  PIXEL: 'Facebook Pixel',
  INSTAGRAM_ACCOUNT: 'Instagram Account',
  ACCOUNT_PLATFORM: 'Facebook Ad Account',
  PRODUCT_CATALOG: 'Product Catalog',
});

const FacebookPermissions: React.FC = () => {
  const { getQuery } = useQuery();
  const params = useParams<any>();
  const accountId = +params.accountId;
  const accountPlatformId = +params.accountPlatformId;
  const organizationId = +params.organizationId;
  const { token } = getQuery();

  const { accountPlatform } = useAccountPlatforms({
    accountId,
    accountPlatformId,
    organizationId,
  });
  const { formatMessage } = useIntl();
  const facebookConnected = AccountPlatformStatus.ACTIVE === +accountPlatform?.status;

  const instagramAsset = accountPlatform?.facebookBusinessAssets?.find(({ type }) => type === Type.INSTAGRAM_ACCOUNT);
  const pageAsset = accountPlatform?.facebookBusinessAssets?.find(({ type }) => type === Type.PAGE);
  const productCatalog = accountPlatform?.facebookBusinessAssets?.find(({ type }) => type === Type.PRODUCT_CATALOG);

  const filteredAssets = useMemo<Pick<FacebookBusinessAsset, 'type' | 'status'>[]>(() => {
    const adAccountAsset: Pick<FacebookBusinessAsset, 'type' | 'status'> = {
      type: Type.ACCOUNT_PLATFORM,
      status: facebookConnected ? Status.ACTIVE : Status.PENDING,
    };
    if (accountPlatform?.facebookBusinessAssets) {
      return accountPlatform.facebookBusinessAssets
        .filter(({ type }) => [Type.INSTAGRAM_ACCOUNT, Type.PIXEL, Type.PAGE, Type.PRODUCT_CATALOG].includes(type))
        .map(({ type, status }) => ({ type, status }))
        .concat(adAccountAsset);
    } else return [adAccountAsset];
  }, [accountPlatform?.facebookBusinessAssets]);

  return (
    <Flex css={styles} className="facebook-permissions">
      <Heading
        text={ACCOUNT_PLATFORM_COPY.PERMISSIONS_HEADER}
        descriptionText={formatMessage(
          {
            id: 'DESCRIPTION_FACEBOOK_PERMISSION',
          },
          { appName: GLOBAL_COPY_VARIABLES.APP_NAME },
        )}
      />
      <Flex className="facebook-permissions__checklist">
        {filteredAssets.map(({ type, status }) => (
          <Flex key={type} className="facebook-permissions__permission">
            <FontAwesomeIcon
              icon={status === Status.ACTIVE ? faCheckCircle : faClock}
              size="xs"
              color={status === Status.ACTIVE ? COLORS.GREEN500 : COLORS.ORANGE400}
            />
            <Text className="facebook-permissions__checklist-item" variant={TEXT_VARIANTS.CAPTION}>
              {LABEL_MAP[type]}
            </Text>
          </Flex>
        ))}
        <Text className="facebook-permissions__text">
          {formatMessage(
            {
              id: 'DESCRIPTION_FACEBOOK_PERMISSION',
            },
            { appName: GLOBAL_COPY_VARIABLES.APP_NAME },
          )}
        </Text>
        <Text className="facebook-permissions__text">{ACCOUNT_PLATFORM_COPY.PERMISSIONS_IG_TEXT}</Text>
        <Box className="facebook-permissions__additional-section">
          <ToggleEditInformation
            disabled={pageAsset?.status !== Status.ACTIVE}
            variant={TEXT_VARIANTS.H6}
            editButtonText={ACCOUNT_PLATFORM_COPY.PERMISSIONS_IG_EDIT_BUTTON_TEXT}
            heading={ACCOUNT_PLATFORM_COPY.PERMISSIONS_IG_EDIT_HEADING}
            EditingComponent={ConnectInstagram}
          >
            {instagramAsset ? (
              <Flex className="facebook-permissions__badge">
                <Box className="facebook-permissions__icon">
                  <FontAwesomeIcon icon={faInstagram} fixedWidth />
                </Box>
                <Text variant={TEXT_VARIANTS.SUBHEADING} className="facebook-permissions__additional-section-name">
                  {instagramAsset.name}
                </Text>
              </Flex>
            ) : (
              <Text>{ACCOUNT_PLATFORM_COPY.PERMISSIONS_IG_NOT_LINKED}</Text>
            )}
          </ToggleEditInformation>
        </Box>
        <Box className="facebook-permissions__additional-section">
          <ToggleEditInformation
            variant={TEXT_VARIANTS.H6}
            disabled={!accountPlatform?.hasRequiredFacebookAssets}
            editButtonText={ACCOUNT_PLATFORM_COPY.PERMISSIONS_PRODUCT_CATALOG_BUTTON_TEXT}
            heading={ACCOUNT_PLATFORM_COPY.PERMISSIONS_PRODUCT_CATALOG_HEADING}
            EditingComponent={ConnectProductCatalog}
            expanded={Boolean(token)}
          >
            {productCatalog ? (
              <Flex className="facebook-permissions__badge">
                <Box className="facebook-permissions__icon">
                  <FontAwesomeIcon icon={faRectangleList} fixedWidth />
                </Box>
                <Text variant={TEXT_VARIANTS.SUBHEADING} className="facebook-permissions__additional-section-name">
                  {productCatalog.name}
                </Text>
              </Flex>
            ) : (
              <Text>{ACCOUNT_PLATFORM_COPY.PERMISSIONS_PRODUCT_CATALOG_NOT_LINKED}</Text>
            )}
          </ToggleEditInformation>
        </Box>
      </Flex>
    </Flex>
  );
};

export default FacebookPermissions;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import { zIndex } from 'shared/config';

export default css({
  '&.hamburger-menu': {
    display: 'block',
    cursor: 'pointer',
    userSelect: 'none',
    margin: 0,
    padding: '12px 4px',
    position: 'relative',
    zIndex: zIndex.zNavItem,
    '&:before': {
      content: '""',
      position: 'absolute',
      zIndex: zIndex.zNav,
      top: 0,
      right: '1px',
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      transition: 'background-color 0.2s ease-out',
      backgroundColor: 'transparent',
    },
    '&--has-notification:before': {
      backgroundColor: COLORS.RED500,
    },
    '&--open:before': {
      background: 'transparent',
    },
  },
  '.hamburger-menu': {
    '&__icon': {
      height: '2px',
      width: '18px',
      backgroundColor: COLORS.BLACK,
      transition: 'background-color 0.2s ease-out',
      display: 'block',
      position: 'relative',
    },
    '&__icon:before, &__icon:after': {
      content: '""',
      position: 'absolute',
      height: '100%',
      width: '100%',
      backgroundColor: COLORS.BLACK,
      transition: 'transform 0.2s ease-out, top 0.2s ease-out',
    },
    '&__icon:before': {
      top: '7px',
    },
    '&__icon:after': {
      top: '-7px',
    },
    '&__icon--open': {
      background: 'transparent',
    },
    '&__icon--open:before, &__icon--open:after': {
      top: 0,
    },
    '&__icon--open:before': {
      transform: 'rotate(-45deg)',
    },
    '&__icon--open:after': {
      transform: 'rotate(45deg)',
    },
  },
});

import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Link, Text } from 'rebass/styled-components';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { orderBy } from 'lodash';

import { useInvoice } from 'features/organizations/useInvoice';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Box, Flex } from 'shared/components/display';
import ActionButton from 'shared/components/molecules/ActionButton';
import CheckoutPromoCodeForm from 'shared/components/molecules/CheckoutPromoCodeForm';

import { STRIPE_COPY } from 'shared/config/copy';
import { useStripe } from 'shared/hooks/useStripe';
import { poweredByStripe } from 'shared/images/stripe';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LineItem } from 'shared/typings/lineItem';
import { LineItemType } from 'shared/typings/lineItem/enums';
import { formatCurrency } from 'shared/utilities/stringUtility';

import styles from './styles';

interface Props {
  heading?: string;
  charges?: Array<LineItem>;
  total: number;
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
  onSubmitText: string;
  submitInProcess?: boolean;
  className?: string;
}

const PAYMENT_PROCESSING_URL = 'https://www.brkfst.io/faq';

const CheckoutSummary: React.FC<Props> = ({
  heading = 'Summary',
  charges = [],
  total,
  onSubmit,
  onSubmitText,
  submitInProcess = false,
  className,
}) => {
  const params = useParams<{ organizationId: string; accountId: string }>();
  const organizationId = +params.organizationId;
  const accountId = +params.accountId;

  const { loading: loadingStripe } = useStripe();
  const { checkoutInvoice, checkoutInvoiceHasPromoCode, loadingPromoCode, removePromoCode } = useInvoice({
    organizationId,
    accountId,
  });

  const renderCharges = () => {
    return (
      <Fragment>
        {orderBy(charges, 'amount', 'desc').map(({ description, type, amount }) => {
          return (
            <li
              key={description}
              className={cs({
                'checkout-summary__charge': type !== LineItemType.DISCOUNT,
                'checkout-summary__discount': type === LineItemType.DISCOUNT,
              })}
            >
              {type === LineItemType.DISCOUNT && (
                <Button
                  onClick={removePromoCode}
                  variant={BUTTON_VARIANTS.TEXT_ONLY}
                  aria-labelledby="remove-promo-code"
                  className="checkout-summary__remove-discount-btn"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
              )}
              <Text variant={TEXT_VARIANTS.BODY} className="checkout-summary__charge-description endWithEllipsis">
                {description}
              </Text>
              <Text variant={TEXT_VARIANTS.BODY}>{formatCurrency(amount)}</Text>
            </li>
          );
        })}
      </Fragment>
    );
  };

  return (
    <Box css={styles} className={className}>
      <Box className="checkout-summary">
        {(loadingStripe || loadingPromoCode) && (
          <Flex className="checkout-summary__loading">
            <CenteredSpinner />
          </Flex>
        )}
        <Text variant={TEXT_VARIANTS.H4}>{heading}</Text>
        <ul className="checkout-summary__charges">{renderCharges()}</ul>
        {!checkoutInvoiceHasPromoCode && checkoutInvoice.id && <CheckoutPromoCodeForm />}
        <hr className="checkout-summary__break" />
        {total >= 0 && (
          <Flex className="checkout-summary__total">
            <Text variant={TEXT_VARIANTS.H6}>Due Today</Text>
            <Text variant={TEXT_VARIANTS.H6}>{formatCurrency(total)} USD</Text>
          </Flex>
        )}
        <Text variant={TEXT_VARIANTS.CAPTION} className="checkout-summary__terms">
          By checking out, you agree to our{' '}
          <Link href={PAYMENT_PROCESSING_URL} target="_blank" className="checkout-summary__terms-link">
            Payment Processing Terms
          </Link>
          .{' '}
        </Text>
        <ActionButton onClick={onSubmit} loading={submitInProcess} className="checkout-summary__submit-btn">
          {onSubmitText}
        </ActionButton>
      </Box>
      <Box className="checkout-summary__powered-by-stripe">
        <img src={poweredByStripe} alt={STRIPE_COPY.ALT_POWERED_BY_STRIPE} title={STRIPE_COPY.ALT_POWERED_BY_STRIPE} />
      </Box>
    </Box>
  );
};

export default CheckoutSummary;

import React, { useMemo, useState } from 'react';
import { Button } from 'rebass/styled-components';
import cs from 'classnames';

import ModuleCommentsModal from 'features/submissions/components/organisms/ModuleCommentsModal';
import { Flex, Text } from 'shared/components/display';
import moduleReviewFooterStyles from 'shared/components/molecules/ModuleReviewFooter/moduleReviewFooterStyles';
import { Values } from 'shared/components/molecules/ModuleReviewForm/moduleReviewFormConfig';
import ModuleRevisionModal from 'shared/components/molecules/ModuleRevisionModal/ModuleRevisionModal';
import SplitButton from 'shared/components/molecules/SplitButton';
import ModuleReviewFormModal from 'shared/components/organisms/ModuleReviewFormModal';

import { SUBMISSION_COPY } from 'shared/config/copy';
import { intl } from 'shared/lib/intl';
import COLORS from 'shared/styles/colors';
import { RevisionStatus } from 'shared/typings/revisions';
import { ReviewModule } from 'shared/typings/submissions';
import { convertWidthNum } from 'shared/utilities/moduleReviewUtility';

interface Props {
  handleApproveModule: () => void;
  handleCriticalErrors: (args: { data: Values; errorCategory: string }) => void;
  numModulesRemaining: number;
  setOpenRevision: (boolean) => void;
  openRevision: boolean;
  setOpenComments: (boolean) => void;
  openComments: boolean;
  mediaWidth: number;
  approvalLoading: boolean;
  brandName: string;
  mod: ReviewModule;
  enableModuleComments?: boolean;
}
const ModuleReviewFooter: React.FC<Props> = ({
  numModulesRemaining,
  handleApproveModule,
  handleCriticalErrors,
  setOpenRevision,
  mediaWidth,
  approvalLoading,
  setOpenComments,
  mod,
  brandName,
  openComments,
  openRevision,
  enableModuleComments,
}) => {
  const mediaStyle = useMemo(() => {
    //Change the margins depending on the video width and make sure footer has a minimum width to fit all buttons
    const width = convertWidthNum(mediaWidth < 380 ? 390 : mediaWidth);
    return {
      widthStyle: {
        width: width,
      },
    };
  }, [mediaWidth]);
  const [isOpen, setIsOpen] = useState(false);

  const toggleCommentsModal = () => {
    setOpenComments((prev) => !prev);
    setOpenRevision(false);
  };

  const toggleRevisionModal = () => {
    setOpenRevision((prev) => !prev);
    setOpenComments(false);
  };

  const toggleModal = () => {
    setOpenRevision(false);
    setOpenComments(false);
    setIsOpen((prev) => !prev);
  };

  const onSubmitCriticalErrors = (args) => {
    handleCriticalErrors(args);
    toggleModal();
  };

  const onApproveModule = () => {
    handleApproveModule();
    toggleModal();
  };

  return (
    <Flex
      className={cs('review-footer')}
      css={moduleReviewFooterStyles}
      onClick={(e) => e.stopPropagation()}
      style={mediaStyle.widthStyle}
    >
      <Flex className={cs('review-footer__caption-wrapper')}>
        <Text className="review-footer__caption-text" data-cy="review-footer__caption-text">
          {intl.formatMessage(
            { id: 'CAPTION_REVIEW_NAME' },
            {
              moduleNum: mod.moduleNum,
            },
          )}
        </Text>

        <ModuleRevisionModal
          revisions={mod.revisions}
          setOpenRevision={toggleRevisionModal}
          moduleNum={mod.moduleNum}
          mediaWidth={mediaWidth}
          brandName={brandName}
          openRevision={openRevision}
          className="review-footer__icon"
        />
        {enableModuleComments && (
          <ModuleCommentsModal
            setOpen={toggleCommentsModal}
            submissionModuleId={mod.id}
            mediaWidth={mediaWidth}
            moduleNum={mod.moduleNum}
            comments={mod.comments}
            open={openComments}
            className="review-footer__icon"
          />
        )}

        <Text className="review-footer__caption-text-modules">
          {intl.formatMessage(
            {
              id: 'CAPTION_REVIEW_MODULES_REMAINING',
            },
            {
              numRemaining: numModulesRemaining,
            },
          )}
        </Text>
      </Flex>
      <Flex className="review-footer__button-wrapper">
        <SplitButton
          primaryOnClick={onApproveModule}
          disableButton={approvalLoading}
          className="review-footer__approve-btn"
          primaryText={
            mod.revisions?.length && mod.revisions.some((rev) => rev.status === RevisionStatus.DOING)
              ? SUBMISSION_COPY.BUTTON_APPROVE_MODULE_WITH_REVISIONS
              : SUBMISSION_COPY.BUTTON_APPROVE_MODULE
          }
          width="230px"
          placement="top-end"
          portalTarget=".review-footer"
          dataCy="review-footer__approve-btn"
        >
          <Button onClick={toggleModal} backgroundColor={COLORS.RED200} className="review-footer__warning-btn">
            {SUBMISSION_COPY.BUTTON_REVIEW_ERRORS}
          </Button>
          {enableModuleComments && (
            <Button onClick={toggleCommentsModal} data-cy="review-footer__module-comments-btn">
              {intl.formatMessage(
                {
                  id: 'BUTTON_REVIEW_COMMENTS',
                },
                {
                  count: mod.comments?.length || 0,
                },
              )}
            </Button>
          )}
        </SplitButton>
        <ModuleReviewFormModal onSubmit={onSubmitCriticalErrors} onClose={toggleModal} isOpen={isOpen} />
      </Flex>
    </Flex>
  );
};

export default ModuleReviewFooter;

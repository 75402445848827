import { INVOICE_COPY } from 'shared/config/copy';
import { Box, Flex } from 'shared/components/display';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useMemo } from 'react';
import { Link, Text } from 'rebass/styled-components';
import Divider from 'shared/components/molecules/Divider';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { prettyDate } from 'shared/utilities/dateUtility';
import { formatCurrency } from 'shared/utilities/stringUtility';
import styles from './styles';

const StripeReceipt = (charge) => {
  const {
    metadata: { lineItems },
    amountInDollars,
    brandName,
    briefName,
    dateCreated,
    receiptNumber,
    receiptUrl,
  } = charge;

  const receiptLineItems = useMemo(() => {
    return lineItems?.length
      ? JSON.parse(lineItems).map((item) => (
          <Flex key={item.description}>
            <Text variant={TEXT_VARIANTS.BODY} className="stripe-receipt__item-description">
              {item.description}
            </Text>
            <Text variant={TEXT_VARIANTS.BODY}>{formatCurrency(item.amount / 100)}</Text>
          </Flex>
        ))
      : null;
  }, [lineItems]);

  return (
    <Flex className="stripe-receipt" css={styles}>
      <Flex>
        <Text variant={TEXT_VARIANTS.OVERLINE} className="stripe-receipt__receipt-number">
          {INVOICE_COPY.HEADING_RECEIPT}
          {receiptNumber && ` #${receiptNumber}`}
          <Link
            href={receiptUrl}
            target="_blank"
            aria-label={INVOICE_COPY.LABEL_RECEIPT_EXTERNAL_LINK}
            className="stripe-receipt__receipt-link"
          >
            <FontAwesomeIcon icon={faExternalLinkAlt} className="stripe-receipt__receipt-link-icon" />
          </Link>
        </Text>
      </Flex>
      <Text variant={TEXT_VARIANTS.H3}>{formatCurrency(amountInDollars)}</Text>
      <Divider
        heading={INVOICE_COPY.HEADING_RECEIPT_SUMMARY}
        variant={TEXT_VARIANTS.CATEGORY}
        className="stripe-receipt__divider"
      />
      <Box className="stripe-receipt__summary-grid">
        {brandName && (
          <Fragment>
            <Text variant={TEXT_VARIANTS.BODY} className="stripe-receipt__summary-label">
              {INVOICE_COPY.COLUMN_BRAND}
            </Text>
            <Text variant={TEXT_VARIANTS.BODY} className="stripe-receipt__summary-value">
              {brandName}
            </Text>
          </Fragment>
        )}
        {briefName && (
          <Fragment>
            <Text variant={TEXT_VARIANTS.BODY} className="stripe-receipt__summary-label">
              {INVOICE_COPY.COLUMN_BRIEF}
            </Text>
            <Text variant={TEXT_VARIANTS.BODY} className="stripe-receipt__summary-value">
              {briefName}
            </Text>
          </Fragment>
        )}
        <Text variant={TEXT_VARIANTS.BODY} className="stripe-receipt__summary-label">
          {INVOICE_COPY.COLUMN_PAY_DATE}
        </Text>
        <Text variant={TEXT_VARIANTS.BODY} className="stripe-receipt__summary-value">
          {prettyDate(dateCreated)}
        </Text>
      </Box>
      {lineItems?.length && (
        <Fragment>
          <Divider
            heading={INVOICE_COPY.HEADING_RECEIPT_LINE_ITEMS}
            variant={TEXT_VARIANTS.CATEGORY}
            className="stripe-receipt__divider"
          />
          <Flex className="stripe-receipt__line-items">{receiptLineItems}</Flex>
        </Fragment>
      )}
    </Flex>
  );
};

export default StripeReceipt;

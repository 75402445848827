import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  '.error-page': {
    '&__logo': {
      marginBottom: '20px',
      width: '194px',
      [mediaquery.md]: {
        width: '100%',
      },
    },
    '&__email': {
      color: theme.colors.PRIMARY500,
    },
    '&__text': {
      textAlign: 'center',
    },
  },
});

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export const filterFormStyles = css({
  '&.filter-form__active-filters': {
    marginTop: '8px',
    display: 'grid',
    gridTemplateColumns: '[label-start] 110px [tags-start] 1fr [clear-btn-start] 90px [end]',
    gridTemplateRows: 'auto',
    gridGap: '5px 5px',
    alignItems: 'start',
  },
  '.filter-form__active-filters': {
    '&__label': {
      gridColumn: 'label-start / tags-start',
      color: COLORS.NEUTRAL500,
      marginBottom: 0,
      padding: `8px 0`,
    },
    '&__tags': {
      gridColumn: 'tags-start / clear-btn-start',
      flexWrap: 'wrap',
      gap: '5px 5px',
    },
    '&__clear-btn': {
      gridColumn: 'clear-btn-start / end',
    },
  },
});

export const dropdownFieldsStyles = (width: string) =>
  css({
    '&.dropdown-field': {
      minWidth: width,
      padding: `${theme.space[4]} ${theme.space[4]} 0 ${theme.space[4]}`,
      '&__click-component': {
        minWidth: width,
        borderBottom: `1px solid ${COLORS.NEUTRAL500}`,
        color: COLORS.NEUTRAL500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '8px 5px',
        height: '34px',
        fontSize: '14px',
      },
      '&__click-component--active': {
        borderBottom: `3px solid ${COLORS.PRIMARY500}`,
        color: COLORS.PRIMARY500,
      },
      '&__click-component[data-state="open"] .dropdown-field__click-icon': {
        transform: 'rotate(180deg)',
        marginTop: '7px',
      },
    },
    '.dropdown-field': {
      position: 'relative',
      '&__filter-name': {
        marginBottom: 0,
      },
      '&__clear-btn': {
        color: COLORS.PRIMARY500,
        padding: 0,
      },
      '&__btn-wrapper': {
        position: 'sticky',
        zIndex: zIndex.zStickyElements,
        bottom: 0,
        right: 0,
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.space[3]} 0`,
        backgroundColor: COLORS.WHITE,
      },
    },
  });

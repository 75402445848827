import { getInfo } from 'react-mediainfo';

import {
  AD_SUPPORTED_GIF_FORMATS,
  AD_SUPPORTED_VIDEO_FORMATS,
  AD_SUPPORTED_IMAGE_FORMATS,
  BRKFST_SUPPORTED_IMAGE_FORMATS,
  BRKFST_SUPPORTED_VIDEO_FORMATS,
} from 'shared/config/fileFormats';
import { MEDIA } from 'shared/config/media';
import {
  FACEBOOK_VIDEO_MAX_DURATION,
  IMAGE_SIZE_LIMIT,
  MESSAGES,
  SUBMISSION_AR_PERCENT_TOLERANCE,
  SUBMISSION_IMAGE_MIN_DIMENSION,
  SUBMISSION_UPLOAD_ASPECT_RATIOS,
  SUBMISSION_VIDEO_MIN_DIMENSION,
  VIDEO_SIZE_LIMIT,
} from 'shared/config/validations';
import { ModuleFileType } from 'shared/typings/briefs';
import { BrkfstFile, FileMetadata, RotationMetadata } from 'shared/typings/file';
import { withinPercentTolerance } from 'shared/utilities/mathUtility';
import { removeDuplicateCharacters, removeExtensionFromFilename } from 'shared/utilities/stringUtility';

import { getImageDimension, getMimeType, isRotatedSideways } from '../fileUtility';

import { FileMetaData, GifMetaData, ImageMetaData, VideoMetaData } from './types';

export const hasFileInCollection = (fileName: string, collectionFiles: BrkfstFile[]) => {
  return collectionFiles.find(({ name }) => name == fileName);
};

// convenience method for checking mime type and extension rather than having to call both methods above
export const hasValidMimeTypeOrExtension = (
  mimeType: string,
  fileName: string,
  supportedFormats: readonly string[] = [],
) => {
  const supportedFormatsString = supportedFormats.join(',');

  const { acceptedMimeTypes, acceptedExtensions } = separateMimeTypesAndExtensions(supportedFormatsString);
  if (acceptedMimeTypes.includes(mimeType)) {
    return true;
  } else {
    if (fileName.includes('.')) {
      const extension = fileName.split('.').pop();
      if (extension) return acceptedExtensions.includes(`.${extension}`);
    }
  }
  return false;
};

const separateMimeTypesAndExtensions = (
  acceptedMedia: string,
): { acceptedMimeTypes: string[]; acceptedExtensions: string[] } => {
  const acceptedMediaParts = acceptedMedia.split(',');
  let acceptedMimeTypes: string[] = [];
  let acceptedExtensions: string[] = [];
  acceptedMediaParts.forEach((media) => {
    if (media.includes('/')) acceptedMimeTypes.push(media);
    else if (media.includes('.')) acceptedExtensions.push(media);
  });
  return { acceptedMimeTypes, acceptedExtensions };
};

export const getPlatformFormatsByFileType = (fileType: ModuleFileType): readonly string[] => {
  switch (fileType) {
    case 'video':
      return AD_SUPPORTED_VIDEO_FORMATS;
    case 'gif':
      return AD_SUPPORTED_GIF_FORMATS;
    case 'image':
      return AD_SUPPORTED_IMAGE_FORMATS;
    default:
      return [];
  }
};

interface AspectRatioInput {
  width?: number;
  height?: number;
  rotation?: RotationMetadata;
}

export const hasValidAspectRatio = ({ width = 0, height = 0, rotation = 0 }: AspectRatioInput) => {
  const aspectRatio = isRotatedSideways(rotation) ? height / width : width / height;
  return SUBMISSION_UPLOAD_ASPECT_RATIOS.some((acceptedRatio) =>
    withinPercentTolerance(aspectRatio, acceptedRatio, SUBMISSION_AR_PERCENT_TOLERANCE),
  );
};

export const is9by16AspectRatio = ({ width, height }: Pick<FileMetadata, 'width' | 'height'>) => {
  if (width && height) return withinPercentTolerance(width / height, 9 / 16, SUBMISSION_AR_PERCENT_TOLERANCE);
  return false;
};
/* General Validations */

export const getInvalidFilenameCharacters = (fileName): string => {
  const name = removeExtensionFromFilename(fileName);
  const specialChars = removeDuplicateCharacters(name.replace(/[a-zA-Z0-9 _.-]/g, ''));
  return specialChars;
};

export const hasValidVideoDimensions = (videoWidth: number, videoHeight: number) => {
  return videoWidth >= SUBMISSION_VIDEO_MIN_DIMENSION && videoHeight >= SUBMISSION_VIDEO_MIN_DIMENSION;
};

export const hasValidImageDimensions = (width: number, height: number) => {
  return width >= SUBMISSION_IMAGE_MIN_DIMENSION && height >= SUBMISSION_IMAGE_MIN_DIMENSION;
};

export const hasValidSize = (mimeType: string, size: number) => {
  return isImage(mimeType) ? size <= IMAGE_SIZE_LIMIT : size <= VIDEO_SIZE_LIMIT;
};

export const hasValidDuration = (videoDuration) => {
  return videoDuration <= FACEBOOK_VIDEO_MAX_DURATION;
};

/* Gif Validations */

export async function getImageMetaData<Metadata>(image: File, fileName: string): Promise<Metadata> {
  try {
    const { width, height } = await getImageDimension(image);
    return {
      name: fileName,
      height,
      width,
      size: image.size,
      mimeType: image.type,
    } as Metadata;
  } catch (err) {
    return { name: fileName, size: image.size, mimeType: image.type, error: err } as Metadata;
  }
}

export const getVideoRotation = async (video: File) => {
  const mediaInfo = await getInfo(video);
  const rotation = mediaInfo.media.track?.length > 1 ? +mediaInfo.media.track[1].Rotation : 0;
  return [0, 90, 180, 270, -90, -180, -270].includes(Math.round(rotation)) ? Math.round(rotation) : 0;
};

export const getVideoMetaData = async (file: File, fileName: string): Promise<VideoMetaData> => {
  let videoElement;
  let mediaError = '';
  try {
    videoElement = await getVideoElement(URL.createObjectURL(file));
  } catch (err) {
    if (videoElement.error?.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) mediaError = MESSAGES.VIDEO_CODEC_ERROR;
  }
  if (videoElement.videoHeight === 0) {
    mediaError = MESSAGES.VIDEO_CODEC_ERROR;
  }

  const rotation = (await getVideoRotation(file)) as RotationMetadata;

  return {
    width: videoElement.videoWidth,
    height: videoElement.videoHeight,
    duration: videoElement.duration,
    mimeType: getFileMimeType(file),
    error: mediaError,
    rotation,
    name: fileName,
    size: file.size,
  };
};

export const getVideoElement = async (path: string): Promise<HTMLVideoElement> => {
  const videoElement = document.createElement('video');
  videoElement.preload = 'metadata';
  videoElement.src = path;
  await new Promise((resolve, reject) => {
    videoElement.onloadedmetadata = resolve;
    videoElement.onerror = reject;
  });
  return videoElement;
};

export const getFileMetadata = (file: File, fileName): FileMetaData => {
  return {
    name: fileName,
    size: file.size,
    mimeType: getFileMimeType(file),
  };
};

export const isVideo = (mimeType: string) => mimeType.includes('video');
// PSD's width and height cannot be parsed easily via client-side JavaScript.
export const isGif = (mimeType: string) => mimeType === MEDIA.MIME_TYPES.GIF;
export const isImage = (mimeType: string) =>
  mimeType.includes('image') && mimeType !== MEDIA.MIME_TYPES.GIF && !MEDIA.MIME_TYPES.PSD.includes(mimeType);

export const isSupportedVideo = (mimeType: string) => BRKFST_SUPPORTED_VIDEO_FORMATS.includes(mimeType);
export const isSupportedImage = (mimeType: string) => BRKFST_SUPPORTED_IMAGE_FORMATS.includes(mimeType);

/* Metadata parsing methods */
export const getMediaMetaData = async (
  file: File,
  fileName?: string,
): Promise<VideoMetaData | GifMetaData | FileMetaData> => {
  const name = fileName ? fileName : file.name;
  const fileType = getFileMimeType(file);
  if (isVideo(fileType)) {
    return getVideoMetaData(file, name);
  } else if (isGif(fileType)) {
    return getImageMetaData<GifMetaData>(file, name);
  } else if (isImage(fileType) && fileType !== MEDIA.MIME_TYPES.TIFF) {
    return getImageMetaData<ImageMetaData>(file, name);
  } else {
    return getFileMetadata(file, name);
  }
};

export const getFileMimeType = (file: File): string => {
  return file.type || getMimeType(file.name);
};

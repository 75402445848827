import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export default css({
  '&.collection-name': {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    marginBottom: '1rem',
    '&:hover #delete-collection': {
      visibility: 'visible',
      cursor: 'pointer',
    },
  },
  '#delete-collection': {
    marginLeft: '0.5rem',
    visibility: 'hidden',
  },
  '#edit-name': {
    paddingLeft: theme.space[2],
    cursor: 'pointer',
  },
  '.collection-name': {
    '&__delete-btn': {
      padding: 0,
      paddingLeft: theme.space[2],
    },
    '&__name': {
      marginRight: theme.space[2],
      maxWidth: '95%',
    },
    '&:hover': {
      '&.collection-name__copy-btn': {
        visibility: 'visible',
      },
    },
  },
});

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.editable-creator-summary': {
    '&__btn-wrapper': {
      marginTop: theme.space[4],
      justifyContent: 'space-between',
    },
    '&__btn': {
      width: '75px',
      minWidth: 'unset',
    },
    '&__text-icon-wrapper': {
      alignItems: 'baseline',
    },
  },
});

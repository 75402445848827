import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { ALL_ROUTES } from 'shared/config/routes/generalRoutes';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

const RedirectToOnboarding: React.FC = () => {
  const location = useLocation<any>();
  return !location.pathname.includes(ALL_ROUTES.CREATORS.CREATOR_ONBOARDING) ? (
    <Navigate
      to={LinkCreator.createLink({
        routeKey: 'CREATOR_ONBOARDING',
        userType: UserType.CREATOR,
      })}
    />
  ) : null;
};

export default RedirectToOnboarding;

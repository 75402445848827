import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'rebass/styled-components';
import { faColumns } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase } from 'lodash';

import { getPerformanceFieldsByLevel } from 'features/performance/performance.slice';
import DropdownWrapper from 'shared/components/atoms/DropdownWrapper';
import { Flex } from 'shared/components/display';
import { Select, SelectOnChange } from 'shared/components/molecules/Select';

import { STATUS } from 'shared/config/performance';
import { useContainer } from 'shared/hooks/useContainer';
import { usePerformance } from 'shared/hooks/usePerformance';
import { BUTTON_VARIANTS } from 'shared/styles/button';

import columnsSelectorDropdownStyles from './columnsSelectorDropdownStyles';

const { TEXT } = BUTTON_VARIANTS;

const ColumnsSelector = () => {
  const [selected, setSelected] = useState<any>([]);

  const {
    performanceState: { columnsChosen, level },
    updateColumnsChosen,
  } = usePerformance();

  const { closeContainer } = useContainer('columns-selector-test');

  const columnOptions = useSelector(getPerformanceFieldsByLevel(level));

  useEffect(() => {
    const options = columnsChosen.map((col) => ({
      value: col,
      label: startCase(col),
      isFixed: col === STATUS || col.includes(level),
    }));
    setSelected(options);
  }, [columnsChosen, level]);

  const onChange: SelectOnChange = (value, meta) => {
    const { action, removedValue } = meta;
    let newValue = value;
    if (action === 'remove-value' || action === 'pop-value' || action === 'deselect-option') {
      if (!removedValue || removedValue.isFixed) return;
    }
    if (action === 'clear') {
      newValue = selected.filter((option) => option.isFixed);
    }
    setSelected(newValue);
  };

  const submitColumns = () => {
    updateColumnsChosen(selected.map(({ value }) => value));
    closeContainer();
  };

  const cancelChanges = () => {
    setSelected(
      columnsChosen.map((col) => ({
        value: col,
        label: startCase(col),
        isFixed: col === STATUS || col.includes(level),
      })),
    );
    closeContainer();
  };

  return (
    <DropdownWrapper
      label="columns-selector-test"
      clickComponent={
        <>
          <FontAwesomeIcon icon={faColumns} />
          <span>Columns</span>
        </>
      }
    >
      <Flex className="columns-selector-dropdown" css={columnsSelectorDropdownStyles}>
        <Select
          menuPortalTarget="column-selector-dropdown"
          isSearchable
          name="columns-selector"
          options={columnOptions}
          value={selected}
          showSelectAll={false}
          onChange={onChange}
          placeholder="Select Columns"
          maxMenuHeight={300}
          isMulti
        />
        <Flex className="columns-selector-dropdown__btn-wrapper">
          <Button onClick={cancelChanges} variant={TEXT}>
            Cancel
          </Button>
          <Button onClick={submitColumns}>Update</Button>
        </Flex>
      </Flex>
    </DropdownWrapper>
  );
};
export default ColumnsSelector;

export const ACCOUNT_PLATFORM_COPY = {
  // Buttons
  BUTTON_ADD_ACCOUNT_PLATFORM: 'Add New Account Platform',
  BUTTON_GET_BID: 'Get Bid',
  BUTTON_BACK_TO_ALL_PLATFORMS: 'Back to All Platforms',
  BUTTON_SUBMIT_AUDIENCE: 'Submit Customer List',
  BUTTON_CREATE_ACCOUNT_PLATFORM: 'Create New Account Platform',
  BUTTON_EDIT_ACCOUNT_PLATFORM: 'Edit Account Platform',
  BUTTON_DELETE_ACCOUNT_PLATFORM: 'Delete Account Platform',
  BUTTON_TOP_BOTTOM_SUBMIT: 'Perform Analysis',

  // Placeholders
  PLACEHOLDER_PLATFORMS: 'There are no platforms to display yet',

  // Body
  BODY_PICK_PLATFORM: 'Which platform are you creating the ad for?',

  MODAL_DELETE_ACCOUNT_PLATFORM: 'Are you sure you want to delete this Account Platform?',

  // Account Config Headings
  HEADING_ACCOUNT_CUSTOM_AUDIENCE: 'Customer List',
  HEADING_FACEBOOK_REQUIREMENTS: 'Facebook Requirements',

  // customer list
  AUDIENCE_DESCRIPTION:
    '{appName} uses your customer file to create audiences on Facebook. Consider using a group of 1,000 to 10,000 of your best customers based on lifetime value, transaction value, total order size, or engagement.',
  AUDIENCE_TEMPLATE:
    'Download the provided customer list template file to input your data. The name and email are required fields. The more data there is, the better the match rate will be.',
  AUDIENCE_TEMPLATE_FILE: 'customer_list_template.csv',
  AUDIENCE_TEMPLATE_DOWNLOAD: 'Download Customer List Template',

  HEADING_FACEBOOK_PROGRESS: 'Progress',
  HEADING_FACEBOOK_PERMISSION: 'Permission',
  DESCRIPTION_FACEBOOK_PERMISSION:
    '{appName} needs permission to access your Facebook ad account, business page(s), associated pixels in order to create ads. Facebook requires the account administrator in order to properly grant permissions. Granting recommended access to your Instragram account will allow BRKFST to fully utilize those ad placements.',
  DESCRIPTION_FACEBOOK_PROGRESS:
    'Your Facebook setup is {percentComplete}% complete. To start launching ads on {appName}, you must complete all the Facebook requirements.',
  DESCRIPTION_FACEBOOK_PROGRESS_COMPLETE: 'You are now set to launch ads on {appName}!',

  // Warnings
  WARNING_CUSTOM_AUDIENCE_REUPLOAD:
    'Re-uploading a customer list will refresh your lookalike audiences and is irreversible. Please consult with BRKFST customer success before attempting to re-upload a customer list.',
  WARNING_INVALID_CUSTOM_AUDIENCE_FILE: 'Please upload a valid csv file.',

  // PRIVACY
  PRIVACY_CUSTOM_AUDIENCE_PII:
    'Your customer list with personally identifiable information is uploaded directly to Facebook and is not stored on our servers.',

  // Form
  FORM_HEADING_EDIT_PLATFORM: 'Edit Account Platform',
  FORM_HEADING_SAVE_CONFIG: 'Save Configuration',

  // Assign Instagram
  ASSIGN_INSTAGRAM: 'Assign Instagram Account',
  ASSIGN_INSTAGRAM_LABEL: 'Linked Instagram Account',
  ASSIGN_INSTAGRAM_TOAST: 'Instagram Account Assigned for Ad-Creation',

  // Requirements Page Link
  REQUIREMENTS_PAGE_LINK_TEXT: 'Fulfill requirements to create ad',
  REQUIREMENTS_PAGE_LINK_TEXT_HOVER: 'View Requirements Page',
  PLATFORMS_PAGE_LINK_TEXT: 'Connect Facebook to create ad',
  PLATFORMS_PAGE_LINK_TEXT_HOVER: 'View Platforms Page',

  AUDIENCE_SUBMIT: 'Submit Customer List',
  AUDIENCE_PROMPT_TITLE: 'Reupload Customer File?',
  AUDIENCE_PROMPT:
    'Refreshing your customer audience and associated lookalike audiences is irreversible and cannot be undone. Please consult with BRKFST customer success before attempting to re-upload a customer list.',
  AUDIENCE_HEADER: 'Audiences',
  AUDIENCE_INFO_TEXT:
    'BRKFST needs your consent on Facebook to create custom audiences tailored to your marketing needs. Brkfst requires the account administrator in order to properly consent to their custom audience agreement and terms of service.',
  AUDIENCE_CONSENT: 'Consent to Audience Agreement',

  PERMISSIONS_HEADER: 'Permissions',
  PERMISSIONS_IG_TEXT:
    'We recommend granting access to your Instagram account in order for BRKFST to fully utilize those ad placements. You must grant access to your Facebook business page in order to connect your Instagram account.',
  PERMISSIONS_IG_EDIT_BUTTON_TEXT: 'Edit Instagram Page',
  PERMISSIONS_IG_EDIT_HEADING: 'Link Instagram Account',
  PERMISSIONS_IG_NOT_LINKED: 'No Instagram account linked',
  PERMISSIONS_PRODUCT_CATALOG_BUTTON_TEXT: 'Edit Product Catalog',
  PERMISSIONS_PRODUCT_CATALOG_HEADING: 'Link Product Catalog',
  PERMISSIONS_PRODUCT_CATALOG_NOT_LINKED: 'No Product Catalog Linked',

  // Ad Health Sign Up
  HEADING_AD_HEALTH_SIGNUP: "Let's Get Started",
  SUBHEADING_AD_HEALTH_SIGNUP: 'Enter Your Details To See Your Ad Health Score',
  NUMBERED_LIST_HEADER_AD_HEALTH_SIGNUP: 'You can generate your score in 3 simple steps:',
  FIRST_LIST_ITEM_AD_HEALTH_SIGNUP: `<strong>1.</strong> Complete the form below.`,
  SECOND_LIST_ITEM_AD_HEALTH_SIGNUP: "<strong>2.</strong> Connect your brand's Meta Ad Account.",
  THIRD_LIST_ITEM_AD_HEALTH_SIGNUP: '<strong>3.</strong> Generate your score.',
  TOS_AD_HEALTH_SIGNUP:
    'By sharing your details, you are agreeing to our <a style="color:#007bff" target="_blank" href="https://www.brkfst.io/privacy-policy-2">Privacy Policy</a>, <a style="color:#007bff" target="_blank" href= "https://www.brkfst.io/legal">Terms of Use</a>, and <a style="color:#007bff" target="_blank" href= "https://www.brkfst.io/brand-master-services-agreement">Master Service Agreement</a>',

  // Ad Health Sign In
  SUBHEADING_AD_HEALTH_SIGNIN: 'Connect Your Meta Ad Account To Get Your Score',
  DESCRIPTION_AD_HEALTH_SIGNIN:
    'After connecting your Meta Ad Account, the tool will generate your score and provide a detailed analysis with actionable insights to optimize your ads and enhance performance.',
  FOOTER_AD_HEALTH_SIGNIN: `Our app leverages read-only access to your Meta Ad Account solely for the purpose of retrieving data to calculate your brand's Ad Health Score. <strong>No credit card required and no strings attached.</strong>`,
  BUTTON_FACEBOOK_SIGN_IN_AD_HEALTH: 'Continue with Facebook',

  // Ad Health Onboarding
  PITCH_AD_HEALTH_ONBOARDING: `<a style="color:#007bff" target="_blank" href= "https://www.brkfst.io/">brkfst.io</a>'s proprietary Ad Health Score helps brands evaluate and improve their Meta Ad Account performance against a comprehensive set of metrics and industry benchmarks.`,
  LIST_HEADER_AD_HEALTH_ONBOARDING: 'Allowing your brand to:',
  FIRST_CHECK_AD_HEALTH_ONBOARDING: 'Keep your ads fresh and effectively manage ad fatigue',
  SECOND_CHECK_AD_HEALTH_ONBOARDING: 'Focus on key optimizations to boost your ad performance',
  THIRD_CHECK_AD_HEALTH_ONBOARDING: 'Compare your ads with market standards',
  NO_RESULTS_AD_HEALTH_ONBOARDING: 'No Facebook Ad Accounts are associated with {businessName}.',
  HEADING_AD_HEALTH_CONNECT: 'Select your account',
  LABEL_CONTRIBUTOR_IMPACT: 'This score has {impact} impact on the overall Ad Health Score.',
};

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import SHADOWS from 'shared/styles/shadow';

export default css({
  '&.widget-wrapper': {
    margin: '1rem',
  },
  '.react-grid-item.react-grid-placeholder': {
    background: COLORS.NEUTRAL100,
    boxShadow: SHADOWS.SHADOW15,
  },
});

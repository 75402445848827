import { Text } from 'shared/components/display';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import React from 'react';

interface Props {
  captionData: {
    caption: string;
  };
}
const LightboxFooter: React.FC<Props> = ({ captionData: { caption } }) => {
  return (
    <Text variant={TEXT_VARIANTS.BODY} color={COLORS.WHITE}>
      {caption}
    </Text>
  );
};

export default LightboxFooter;

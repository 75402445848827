import { MESSAGES } from 'shared/config/validations';
import cs from 'classnames';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import * as yup from 'yup';

const validateEmailTag = (value: string): boolean => {
  try {
    yup.string().trim().email(MESSAGES.EMAIL).validateSync(value);
    return true;
  } catch (err) {
    return false;
  }
};

const TagEmailComponent: React.FC<any> = ({ props: [tagData] }) => {
  const { value, class: className } = tagData;

  const isValidEmail = validateEmailTag(value);

  return (
    <tag
      title={value}
      contentEditable={false}
      spellCheck={false}
      className={cs({
        tagify__tag: true,
        [className]: !!className,
        'tagify__tag--invalid': !isValidEmail,
      })}
    >
      <x aria-label="remove tag" title="remove tag" className="tagify__tag__removeBtn" />
      <span className="tagify__tag-text">{value}</span>
    </tag>
  );
};

export const TagEmail = (...props) => renderToStaticMarkup(<TagEmailComponent props={props} />);

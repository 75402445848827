import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from 'shared/components/atoms/Tooltip';
import { Box } from 'shared/components/display';

import ACCOUNT_ROUTES from 'shared/config/routes/brands/accountRoutes';
import { useVideoComposition } from 'shared/hooks/useVideoComposition';
import { intl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { BrkfstFile } from 'shared/typings/file';

import styles from './styles';
import { validateSelectedFiles } from './util';
interface Props {
  selectedFiles: BrkfstFile[];
  selectedFileNames: string;
}
const VideoCompositionButton: React.FC<Props> = ({ selectedFiles, selectedFileNames }) => {
  const navigate = useNavigate();

  const { updateFileIds } = useVideoComposition();

  const errors = useMemo(() => {
    return validateSelectedFiles(selectedFiles);
  }, [selectedFiles]);

  const navigateToCompositionPage = () => {
    updateFileIds(selectedFiles.map((file) => file.id));
    navigate(ACCOUNT_ROUTES.VIDEO_COMPOSITION, { state: selectedFiles });
  };

  if (selectedFiles.length < 2) {
    return <></>;
  }

  //Convert tooltip errors to a set to prevent duplicate error messages from showing
  return (
    <Box css={styles} className="video-composition-button">
      <Tooltip content={errors.length ? [...new Set(errors)].join(', ') : selectedFileNames}>
        <Box>
          <Button
            onClick={navigateToCompositionPage}
            className="video-composition-button__btn"
            variant={BUTTON_VARIANTS.TEXT_ONLY}
            disabled={Boolean(errors.length)}
          >
            <FontAwesomeIcon icon={faLayerGroup} />
            {intl.formatMessage(
              { id: 'BUTTON_COMPOSE_FILES' },
              {
                numFiles: selectedFiles.length,
              },
            )}
          </Button>
        </Box>
      </Tooltip>
    </Box>
  );
};

export default VideoCompositionButton;

import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import cs from 'classnames';

import collectionTileListStyles from 'features/media/components/CollectionTileList/collectionTileListStyles';
import NewCollectionTile from 'features/media/components/NewCollectionTile/NewCollectionTile';
import CollectionTileOverlay from 'features/users/components/CollectionTileOverlay/CollectionTileOverlay';
import Skeleton from 'shared/components/atoms/Skeleton';
import { Tile } from 'shared/components/atoms/Tile';
import TileContainer from 'shared/components/atoms/TileContainer';
import { Box, Flex, Text } from 'shared/components/display';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { lazyWithPreload, PreloadableComponent } from 'shared/lib/lazyWithRetry';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Collection as CollectionType } from 'shared/typings/collections';
import { LinkCreator } from 'shared/utilities/linkUtility';
const MarketerCollection = lazyWithPreload(() => import('features/media/pages/MarketerCollection'));
const CreatorCollection = lazyWithPreload(() => import('features/media/pages/CreatorCollection/CreatorCollection'));
interface CommonProps {
  data: CollectionType[];
  addLink?: boolean;
  onClick?: (collectionId: number) => void;
  loading?: boolean;
  creatorCollection?: boolean;
  tileSize?: string;
  stacked?: boolean;
  singleLine?: boolean;
  showFileNum?: boolean;
}

type NewCollectionProps =
  | {
      showNewCollectionTile: true;
      createCollection: (data: any) => void;
    }
  | {
      showNewCollectionTile?: false;
      createCollection?: never;
    };

type CollectionTileListProps = CommonProps & NewCollectionProps;

const CollectionTileList: React.FC<CollectionTileListProps> = ({
  addLink = false,
  createCollection,
  creatorCollection = false,
  data,
  loading = false,
  onClick,
  showNewCollectionTile = false,
  tileSize,
  stacked,
  singleLine = false,
  showFileNum = false,
}) => {
  const { currentUser } = useCurrentUser();
  const { formatMessage } = useIntl();

  const preloadCollection = () => {
    const Collection: PreloadableComponent<React.FC<any>> = creatorCollection ? CreatorCollection : MarketerCollection;
    Collection.preload();
  };

  return (
    <Box
      className={cs('collection-tiles', {
        'collection-tiles--stacked': stacked,
      })}
      css={collectionTileListStyles}
    >
      <TileContainer stacked={stacked} singleLine={singleLine}>
        {/* @ts-ignore */}
        {showNewCollectionTile && <NewCollectionTile createCollection={createCollection} />}

        {data.length < 1 ? (
          <Text className="collection-tiles__no-collections" variant={TEXT_VARIANTS.H6}>
            {formatMessage({ id: 'NO_COLLECTIONS_RESULTS' })}
          </Text>
        ) : (
          data.map(
            ({
              isBriefCollection,
              name,
              thumbnailUrl,
              id,
              accountId,
              organizationId,
              publicAccess,
              creatorId,
              numFiles,
            }) => {
              const showOverlay = (creatorCollection && !publicAccess) || isBriefCollection;

              const CollectionTile = (
                <Tile
                  label={
                    <Flex className="collection-tiles__label">
                      <Text className={cs('endWithEllipsis', 'collection-tiles__label-text')}>{name}</Text>
                      {showFileNum && <span className="collection-tiles__label__num">{`(${numFiles})`}</span>}
                    </Flex>
                  }
                  backgroundImage={thumbnailUrl}
                  overlay={
                    showOverlay ? (
                      <CollectionTileOverlay
                        publicAccess={publicAccess}
                        isBriefCollection={isBriefCollection}
                        creatorCollection={creatorCollection}
                      />
                    ) : undefined
                  }
                  key={id}
                  onClick={onClick ? () => onClick(id) : undefined}
                  onMouseEnter={preloadCollection}
                  dataCy={`collection-tile--${name}`}
                  size={tileSize}
                  stacked={stacked}
                />
              );

              if (addLink) {
                return (
                  <Link
                    to={LinkCreator.createLink({
                      routeKey: creatorCollection ? 'CREATOR_COLLECTION' : 'COLLECTION',
                      userType: currentUser.type,
                      variables: {
                        userId: creatorId,
                        collectionId: id,
                        accountId,
                        organizationId,
                      },
                    })}
                    key={id}
                  >
                    {CollectionTile}
                  </Link>
                );
              }
              return CollectionTile;
            },
          )
        )}

        {loading && <Tile label={<Skeleton width={100} />} />}
      </TileContainer>
    </Box>
  );
};

export default CollectionTileList;

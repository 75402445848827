/// <reference types="heremaps" />

import { useDispatch } from 'react-redux';
import lookup from 'country-code-lookup';

import { componentLoadingToggled } from 'features/ui/ui.slice';

import axiosClient from 'shared/axiosClient';
import { RESOURCES } from 'shared/config/resourceNames';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { sumUpArrayValues } from 'shared/utilities/arrayUtility';
export interface GeoLocationValue {
  addressLine1: string;
  city: string;
  country: string;
  postalCode: string;
  state: string;
  countryCode: string;
}

export interface GeoLocationSuggestion {
  value: GeoLocationValue;
  label: string;
}

export const useGeoLocation = () => {
  const dispatch = useDispatch();
  const entity = RESOURCES.ADDRESS_LOOKUP;
  const { loading: addressLoading } = useComponentLoading(entity, false);

  const getAddressSuggestions = async (query: string): Promise<any> => {
    const { data }: H.service.ServiceResult = await axiosClient.httpRequest<any>({
      url: `geo-location/suggestions?query=${query}`,
      method: 'GET',
    });
    return data;
  };

  // https://developer.here.com/cn/documentation/geocoder/topics/scoring.html
  const validateAddress = async (address: string): Promise<any> => {
    dispatch(
      componentLoadingToggled({
        component: entity,
        loading: true,
      }),
    );

    const { data }: H.service.ServiceResult = await axiosClient.httpRequest<any>({
      url: `geo-location/validate?address=${address}`,
      method: 'POST',
    });

    if (data.Response.View.length > 0 && data.Response.View[0].Result[0].Location) {
      const locationResult = data.Response.View[0].Result[0];
      const matchQualityScore = sumUpArrayValues(Object.values(locationResult.MatchQuality));
      const goodMatchLevel =
        locationResult.MatchLevel === 'postalCode' ||
        locationResult.MatchLevel === 'city' ||
        locationResult.MatchLevel === 'district' ||
        locationResult.MatchLevel === 'town';

      dispatch(
        componentLoadingToggled({
          component: entity,
          loading: false,
        }),
      );

      return matchQualityScore >= 2.5 && goodMatchLevel && locationResult.Relevance >= 0.95;
    }

    dispatch(
      componentLoadingToggled({
        component: entity,
        loading: false,
      }),
    );

    return false;
  };

  const mapSuggestions = (results, countryOnly): GeoLocationSuggestion[] => {
    return results.map((suggestion) => {
      const {
        houseNumber = '',
        street = '',
        city = '',
        countryName = '',
        postalCode = '',
        state: stateName = '',
        countryCode = '',
        label,
      } = suggestion.address;
      return {
        value: countryOnly
          ? {
              country: countryName,
              countryCode,
            }
          : {
              addressLine1: `${houseNumber} ${street}`,
              city,
              country: countryName,
              postalCode,
              state: stateName,
              countryCode,
            },
        label: countryOnly ? countryName : label,
      };
    });
  };

  const filterResults = (results, query, countryOnly = false) => {
    const filteredResults = results.items.filter(({ address: { countryName } }) => {
      // Standardize USA country name to United States
      const notAmericaResult = countryName !== 'America';
      if (countryOnly) {
        // make sure query matched country name
        return new RegExp(`^${query}`, 'i').test(countryName) && notAmericaResult;
      }
      return notAmericaResult;
    });

    if (countryOnly) {
      // filter out repeat country results
      return [...new Map(filteredResults.map((suggestion) => [suggestion.address.countryCode, suggestion])).values()];
    }

    return filteredResults;
  };

  const onQueryChange = async (query: string, callback: (results) => void, countryOnly = false) => {
    if (query) {
      const results = await getAddressSuggestions(query);
      if (results?.items?.length && results?.items[0]) {
        const filteredResults = filterResults(results, query, countryOnly);
        callback(mapSuggestions(filteredResults, countryOnly));
      }
    }
    return [];
  };

  const getCountryAlphaCode = async (countryName: string) => {
    return lookup.byCountry(countryName);
  };
  return {
    validateAddress,
    onQueryChange,
    addressLoading,
    getCountryAlphaCode,
  };
};

import React from 'react';
import { Link } from 'react-router-dom';
import { Text } from 'rebass/styled-components';
import cs from 'classnames';

import { ProfilePicSize } from 'features/users/components/molecules/ProfilePicture/interfaces';
import styles from 'features/users/components/molecules/ProfilePicture/styles';
import { Box } from 'shared/components/display';

import { USER_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
interface Props {
  size: ProfilePicSize;
  user: Pick<BrkfstUser, 'firstName' | 'lastName' | 'profilePic'>;
  customProfileLink?: string;
  disableNavigate?: boolean;
  openNewTab?: boolean;
}

const ProfilePicture: React.FC<Props> = ({
  size,
  user: { profilePic, firstName, lastName },
  customProfileLink,
  disableNavigate = false,
  openNewTab = false,
}) => {
  const { url } = profilePic || {};
  const { currentUser, userType } = useCurrentUser();

  const profileLink =
    customProfileLink ||
    (userType === UserType.CREATOR
      ? LinkCreator.createLink({
          routeKey: 'USER_PROFILE',
          userType: UserType.CREATOR,
          variables: {
            userId: currentUser.id,
          },
        })
      : LinkCreator.createLink({
          routeKey: 'USER_SETTINGS',
        }));

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) =>
    disableNavigate && event.preventDefault();

  return (
    <Link target={openNewTab ? '_blank' : '_self'} to={profileLink} onClick={(event) => handleClick(event)}>
      <Box
        css={styles}
        className={cs('profile-picture', {
          'profile-picture--navigation-disabled': disableNavigate,
        })}
      >
        {url ? (
          <img
            src={url}
            alt={USER_COPY.ALT_PROFILE_PICTURE}
            className={cs('profile-picture__img', `profile-picture__img--${size}`)}
          />
        ) : (
          <Box className={cs('profile-picture__placeholder', `profile-picture__placeholder--${size}`)}>
            <Text variant={TEXT_VARIANTS.H6}>
              {firstName && firstName[0]}
              {lastName && lastName[0]}
            </Text>
          </Box>
        )}
      </Box>
    </Link>
  );
};

export default ProfilePicture;

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.module-comments-modal': {
    padding: theme.space[4],
  },
  '.module-comments-modal': {
    '&__comments-wrapper': {
      maxHeight: '400px',
      minHeight: '35px',
      overflow: 'auto',
      marginBottom: theme.space[3],
    },
    '&__comment-placeholder': {
      textAlign: 'center',
    },
  },
});

import React, { useState } from 'react';
import { Placement } from '@floating-ui/react';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import { Flex, Button } from 'shared/components/display';
import ActionButton from 'shared/components/molecules/ActionButton';
import Dropdown, { DropdownChildren, DropdownOffset } from 'shared/components/molecules/Dropdown';
import PromptButton from 'shared/components/molecules/PromptButton';

import { SIZING } from 'shared/config/formatting';
import { BUTTON_VARIANTS } from 'shared/styles/button';

import { getSplitButtonStyles } from './styles';

const { MEDIUM } = SIZING;

const variantMap = {
  default: BUTTON_VARIANTS.PRIMARY,
  mini: BUTTON_VARIANTS.MICRO,
  borderless: BUTTON_VARIANTS.TEXT,
};

interface Props {
  primaryText: string | React.ReactElement;
  primaryOnClick: () => void;
  promptMessage?: string;
  promptTitle?: string;
  promptAriaLabel?: string;
  confirmButtonText?: string;
  children: DropdownChildren;
  placement?: Placement;
  className?: string;
  disableButton?: boolean;
  offset?: DropdownOffset;
  width?: string | number;
  loading?: boolean;
  variant?: 'default' | 'mini' | 'borderless';
  disablePortal?: boolean;
  portalTarget?: string;
  dataCy?: string;
}

const SplitButton: React.FC<Props> = ({
  primaryText,
  primaryOnClick,
  promptMessage,
  promptTitle = '',
  promptAriaLabel,
  placement = 'bottom-end',
  className = '',
  disableButton = false,
  children,
  offset = {
    mainAxis: 0,
    crossAxis: 0,
  },
  width,
  confirmButtonText = '',
  loading,
  variant = 'default',
  disablePortal = false,
  portalTarget = 'body',
  dataCy,
}) => {
  const [open, setIsOpen] = useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [finalPlacement, setFinalPlacement] = useState<Placement>(placement);
  const iconUp = placement.includes('top');

  return (
    <Flex ref={containerRef} css={getSplitButtonStyles(width)} className={cs('split-btn', className)} data-cy={dataCy}>
      {promptMessage ? (
        <PromptButton
          onConfirm={primaryOnClick}
          text={promptMessage}
          title={promptTitle}
          modalSize={MEDIUM}
          disabled={disableButton}
          ariaLabel={promptAriaLabel}
          confirmButtonText={confirmButtonText}
          className={cs('split-btn__button', `split-btn__button--${variant}`)}
          variant={variantMap[variant]}
        >
          {primaryText}
        </PromptButton>
      ) : (
        <ActionButton
          type="button"
          disabled={disableButton}
          loading={loading}
          onClick={primaryOnClick}
          className={cs('split-btn__button', `split-btn__button--${finalPlacement}`, `split-btn__button--${variant}`, {
            'split-btn__button--flat': open,
          })}
          variant={variantMap[variant]}
        >
          {primaryText}
        </ActionButton>
      )}
      <Dropdown
        ClickComponent={
          <Button
            disabled={!children}
            className={cs('split-btn__dropdown-button', `split-btn__dropdown-button--${variant}`)}
            variant={variantMap[variant]}
            dataCy="split-btn__dropdown-button"
          >
            <FontAwesomeIcon icon={iconUp ? faAngleUp : faAngleDown} />
          </Button>
        }
        placement={placement}
        offset={offset}
        onOpen={setIsOpen}
        width={containerRef.current?.offsetWidth || width}
        setFinalPlacement={setFinalPlacement}
        optionVariant={variant === 'default' ? BUTTON_VARIANTS.OPTION : BUTTON_VARIANTS.FLAT_OPTION}
        disablePortal={disablePortal}
        portalTarget={portalTarget}
      >
        {children}
      </Dropdown>
    </Flex>
  );
};

export default SplitButton;

import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'rebass/styled-components';

import { BUTTON_VARIANTS } from 'shared/styles/button';

const { PLAIN } = BUTTON_VARIANTS;

const LinkButton = (props) => {
    const {
        pathname,
        buttonText,
        params,
        variant = PLAIN,
        ...buttonProps
    } = props;

    return (
        <Link to={{ pathname, state: params }}>
            <Button variant={variant} {...buttonProps}>
                {buttonText}
            </Button>
        </Link>
    );
};

export default LinkButton;

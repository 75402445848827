import { startCase } from 'lodash';

import { emptySelectOption } from 'shared/components/molecules/Select/SelectInput';

import { ADS, BODY, CREATOR_INITIALS, DATE, DESCRIPTION, HEADLINE, LANDING_PAGE } from 'shared/config/ads';

export const CALL_TO_ACTION_OPTIONS = [
  emptySelectOption,
  ...Object.keys(ADS.FACEBOOK.CTA).map((label) => ({
    value: ADS.FACEBOOK.CTA[label],
    label,
  })),
];

export const CREATOR_DYNAMIC_VARIABLES = Object.freeze([
  {
    value: 'creatorName',
    display: 'Creator Name',
  },
  {
    value: 'moduleNumber',
    display: 'Module Number',
  },
  {
    value: `${CREATOR_INITIALS}`,
    display: `${startCase(CREATOR_INITIALS)}`,
  },
]);

export const STANDARD_AD_DYNAMIC_VARIABLES = Object.freeze([
  {
    value: `${LANDING_PAGE}Name`,
    display: `${startCase(LANDING_PAGE)} Name`,
  },
  {
    value: `${HEADLINE}Name`,
    display: `${startCase(HEADLINE)} Name`,
  },
  {
    value: `${BODY}Name`,
    display: 'Primary Text Name',
  },
  {
    value: `${DESCRIPTION}Name`,
    display: `${startCase(DESCRIPTION)} Name`,
  },
  {
    value: `${DATE}`,
    display: `${startCase(DATE)}`,
  },
]);

export const CAROUSEL_AD_DYNAMIC_VARIABLES = Object.freeze([
  {
    value: `${BODY}Name`,
    display: 'Primary Text Name',
  },
  {
    value: `${DATE}`,
    display: `${startCase(DATE)}`,
  },
]);

export const FACEBOOK_URL_DYNAMIC_VARIABLES = Object.freeze([
  {
    value: 'ad.id',
    display: 'Ad Id',
  },
  {
    value: 'adset.id',
    display: 'Adset Id',
  },
  {
    value: 'campaign.id',
    display: 'Campaign Id',
  },
  {
    value: 'ad.name',
    display: 'Ad Name',
  },
  {
    value: 'adset.name',
    display: 'Adset Name',
  },
  {
    value: 'campaign.name',
    display: 'Campaign Name',
  },
  {
    value: 'placement',
    display: 'Placement',
  },
  {
    value: 'site_source_name',
    display: 'Site Source Name',
  },
]);

export const FACEBOOK_URL_DYNAMIC_VARIABLE_VALUES = Object.freeze(
  FACEBOOK_URL_DYNAMIC_VARIABLES.map(({ value }) => value),
);

export const BASE_INITIAL_VALUES = {
  adName: '',
  urlTags: '',
  body: '',
  bodyName: '',
  displayUrl: '',
  callToAction: { ...emptySelectOption },
  primaryText: '',
  primaryTextName: '',
};

export const ASSET_INITIAL_VALUES = {
  headline: '',
  headlineName: '',
  description: '',
  descriptionName: '',
  landingPage: '',
  landingPageName: '',
};

export const AD_CREATION_INITIAL_VALUES = {
  ...BASE_INITIAL_VALUES,
  ...ASSET_INITIAL_VALUES,
};

export const CAROUSEL_INITIAL_VALUES = {
  ...BASE_INITIAL_VALUES,
  carouselAssets: [{ ...ASSET_INITIAL_VALUES }, { ...ASSET_INITIAL_VALUES }],
  seeMoreUrl: '',
};

import React from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import AccountWidgets from 'features/accounts/components/organisms/AccountWidgets';
import { useAccounts } from 'features/accounts/hooks/useAccounts';
import accountHomeStyles from 'features/accounts/pages/AccountHome/accountHomeStyles';
import { Flex, Image } from 'shared/components/display';

const AccountHome: React.FC = () => {
  const params = useParams<{ accountId: string; organizationId: string }>();
  const accountId = +params.accountId;
  const organizationId = +params.organizationId;

  const { account } = useAccounts({ accountId: params.accountId });
  return !isEmpty(account) ? (
    <Flex css={accountHomeStyles} className="account-home">
      <AccountWidgets
        accountId={accountId}
        organizationId={organizationId}
        dataStudioReportId={account.dataStudioReportId}
      />
    </Flex>
  ) : null;
};

export default AccountHome;

import React, { useRef } from 'react';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Box, Text } from 'shared/components/display';
import { breakpoints } from 'shared/styles/styleFunctions';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import getStyles from './styles';
import { isEllipsisActive } from './util';

interface Props {
  children: string;
  className?: string;
  pxPerSecondScrollSpeed?: number;
  dataCy?: string;
}

/**
 * ScrollingText is a component that displays text that scrolls upon hover if the text is too long to fit in the container, and displays a tooltip on mobile.
 *
 * If not hovering, the text will be truncated with ellipsis.
 *
 * @param children text to display
 *
 */
const ScrollingText: React.FC<Props> = ({ children, className, pxPerSecondScrollSpeed, dataCy }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const widthDifference = textRef.current ? textRef.current.scrollWidth - textRef.current.offsetWidth : 0;
  const textIsTruncated = isEllipsisActive(textRef.current);

  return (
    <Tooltip disabled={!isMobile || !textIsTruncated} content={children} portalTarget="body">
      <Box css={getStyles(widthDifference, pxPerSecondScrollSpeed)} className={cs('scrolling-text', className)}>
        <Text
          ref={textRef}
          className={cs('scrolling-text__text', {
            'scrolling-text__text--scroll': textIsTruncated && !isMobile,
          })}
          data-cy={dataCy}
        >
          {children}
        </Text>
      </Box>
    </Tooltip>
  );
};

export default ScrollingText;

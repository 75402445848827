import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.columns-selector-dropdown': {
    flexDirection: 'column',
    padding: theme.space[6],
    background: COLORS.WHITE,
    width: '35em', // Style-guide Exception: Tippy placement props conflict with rem units.
    height: '20em', // Style-guide Exception: Tippy placement props conflict with rem units.
  },
  '.columns-selector-dropdown': {
    '&__btn-wrapper': {
      alignSelf: 'flex-end',
      marginTop: 'auto',
      flexWrap: 'wrap',
    },
    '&__btn-wrapper > :not(:last-child)': {
      marginRight: theme.space[3],
    },
  },
});

import { Permission, ResourceName } from 'shared/hooks/usePermissions';

interface PermissionMap {
  [key: string]: {
    API: Permission;
    PRETTY: string;
  };
}

export const PERMISSIONS_MAP: PermissionMap = {
  // Organizations
  EDIT_ORG: {
    API: {
      resource: ResourceName.ORGANIZATION,
      permissions: ['update'],
    },
    PRETTY: 'Edit Organization',
  },

  // Accounts
  ADD_ACCOUNT: {
    API: {
      resource: ResourceName.ACCOUNT,
      permissions: ['create'],
    },
    PRETTY: 'Add Account',
  },
  EDIT_ACCOUNT: {
    API: {
      resource: ResourceName.ACCOUNT,
      permissions: ['update'],
    },
    PRETTY: 'Edit Account',
  },
  DELETE_ACCOUNT: {
    API: {
      resource: ResourceName.ACCOUNT,
      permissions: ['delete'],
    },
    PRETTY: 'Delete Account',
  },

  // Roles
  ADD_ROLE: {
    API: {
      resource: ResourceName.ROLE,
      permissions: ['create'],
    },
    PRETTY: 'Add Role',
  },
  EDIT_ROLES: {
    API: {
      resource: ResourceName.ROLE,
      permissions: ['update'],
    },
    PRETTY: 'Edit Roles',
  },
  DELETE_ROLES: {
    API: {
      resource: ResourceName.ROLE,
      permissions: ['delete'],
    },
    PRETTY: 'Delete Roles',
  },
  REASSIGN_USER_ROLES: {
    API: {
      resource: ResourceName.USER_ROLE,
      permissions: ['update'],
    },
    PRETTY: 'Reassign User Roles',
  },

  // Marketers
  // In order to Invite Marketers, user needs both INVITE_MARKETERS & PARTIALLY_MANAGE_ACCOUNT_ACCESS
  INVITE_MARKETERS: {
    API: {
      resource: ResourceName.MARKETER_ACCOUNT,
      permissions: ['create'],
    },
    PRETTY: 'Invite Users',
  }, // formerly canAddUsers
  PARTIALLY_MANAGE_ACCOUNT_ACCESS: {
    API: {
      resource: ResourceName.MARKETER_ACCOUNT,
      permissions: ['update'],
    },
    PRETTY: 'Partially Manage Account Access',
  }, // formerly canEditUsers
  // TODO: will eventually want a FULL_MANAGE_ACCOUNT_ACCESS permission

  // Creators
  APPROVE_CREATORS: {
    API: {
      resource: ResourceName.USER_BRIEF,
      permissions: ['update'],
    },
    PRETTY: 'Approve or Reject Creators',
  },
  INVITE_CREATORS: {
    API: {
      resource: ResourceName.USER_BRIEF,
      permissions: ['update'],
    },
    PRETTY: 'Invite Creators',
  },

  // Account Platforms
  ADD_ACCOUNT_PLATFORM: {
    API: {
      resource: ResourceName.ACCOUNT_PLATFORM,
      permissions: ['create'],
    },
    PRETTY: 'Add Account Platform',
  },
  EDIT_ACCOUNT_PLATFORM: {
    API: {
      resource: ResourceName.ACCOUNT_PLATFORM,
      permissions: ['update'],
    },
    PRETTY: 'Edit Account Platform',
  },
  DELETE_ACCOUNT_PLATFORM: {
    API: {
      resource: ResourceName.ACCOUNT_PLATFORM,
      permissions: ['delete'],
    },
    PRETTY: 'Delete Account Platform',
  },

  // Brief Drafts
  CREATE_EDIT_BRIEF_DRAFT: {
    API: {
      resource: ResourceName.BRIEF_DRAFT,
      permissions: ['create', 'update'],
    },
    PRETTY: 'Create/Edit Brief Draft',
  },
  DELETE_BRIEF_DRAFT: {
    API: {
      resource: ResourceName.BRIEF_DRAFT,
      permissions: ['delete'],
    },
    PRETTY: 'Delete Brief Draft',
  },

  // Briefs
  PUBLISH_EDIT_BRIEF: {
    API: {
      resource: ResourceName.BRIEF_PUBLISHED,
      permissions: ['create'],
    },
    PRETTY: 'Publish/Edit Brief',
  },
  CLOSE_REOPEN_BRIEF: {
    API: {
      resource: ResourceName.BRIEF_PUBLISHED,
      permissions: ['update'],
    },
    PRETTY: 'Close/Reopen Brief',
  },
  PAUSE_RESUME_BRIEF: {
    API: {
      resource: ResourceName.BRIEF_PUBLISHED,
      permissions: ['update'],
    },
    PRETTY: 'Pause/Resume Brief',
  },

  // Cards
  ADD_CARD: {
    API: {
      resource: ResourceName.CARD,
      permissions: ['create'],
    },
    PRETTY: 'Add Card',
  },
  EDIT_CARD: {
    API: {
      resource: ResourceName.CARD,
      permissions: ['update'],
    },
    PRETTY: 'Edit Card',
  },
  DELETE_CARD: {
    API: {
      resource: ResourceName.CARD,
      permissions: ['delete'],
    },
    PRETTY: 'Delete Card',
  },

  // Submissions
  VIEW_SUBMITTED_SUBMISSION_MODULES: {
    API: {
      resource: ResourceName.SUBMISSION,
      permissions: ['read'],
    },
    PRETTY: 'View Submitted Submission',
  },
  UPDATE_MODULE_STATE: {
    API: {
      resource: ResourceName.SUBMISSION,
      permissions: ['update'],
    },
    PRETTY: 'Update Submission Module State',
  },
  REVIEW_MODULES: {
    API: {
      resource: ResourceName.REVISION,
      permissions: ['create'],
    },
    PRETTY: 'Can Review Modules',
  },
};

import { createSlice } from '@reduxjs/toolkit';

const channelsSlice = createSlice({
    name: 'channels',
    initialState: { channels: [] },
    reducers: {
        channelsLoaded: (channels, action) => {
            channels.channels = action.payload.data.data;
        },
    },
});

export const { channelsLoaded } = channelsSlice.actions;

export default channelsSlice.reducer;

export const getAccountChannels = () => (state) => state.channels.channels;

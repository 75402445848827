import React from 'react';
import { SliderItem } from 'react-compound-slider';

const Tick: React.FC<{
  tick: SliderItem;
  count: number;
  valueDisplayMap?: Record<number, string>;
}> = ({ tick, count, valueDisplayMap }) => (
  <div>
    <div
      className="slider__tick"
      style={{
        left: `${tick.percent}%`,
      }}
    />
    <div
      className="slider__tick-text"
      style={{
        marginLeft: `${-(100 / count) / 2}%`,
        width: `${100 / count}%`,
        left: `${tick.percent}%`,
      }}
    >
      {valueDisplayMap ? valueDisplayMap[tick.value] : tick.value}
    </div>
  </div>
);

export default Tick;

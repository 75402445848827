import { FilterOption, FilterOptions } from 'shared/components/molecules/PaginationFilter';
import { PaginationSortType, SortOptions } from 'shared/components/molecules/PaginationSort';

import { briefStatusDisplay } from 'shared/typings/briefs/enums';
import { briefCreatorStatusDisplay, FULL_BRIEF, UserBriefStatus } from 'shared/typings/userBriefs/enums';

export const ANY_STATUS_VALUE = '-1';
export const marketerSortOptions: SortOptions = [
  {
    label: 'Name',
    value: 'name',
    sortType: PaginationSortType.ALPHABET,
  },
  {
    label: 'Date Updated',
    value: 'dateUpdated',
    sortType: PaginationSortType.TIME,
  },
  {
    label: 'Relevance',
    value: 'relevance',
    sortType: PaginationSortType.RELEVANCE,
  },
];

export const creatorSortOptions: SortOptions = [
  {
    label: 'Name',
    value: 'briefs.name',
    sortType: PaginationSortType.ALPHABET,
  },
  {
    label: 'Relevance',
    value: 'relevance',
    sortType: PaginationSortType.RELEVANCE,
  },
];

export const marketerFilterOptions: FilterOptions<number> = [
  {
    label: 'Any Status',
    value: -1,
  },
  ...Object.keys(briefStatusDisplay).map((value): FilterOption<number> => {
    return {
      value: +value,
      label: briefStatusDisplay[value],
    };
  }),
];

export const creatorFilterOptions: FilterOptions<string> = [
  {
    label: 'Any Status',
    value: ANY_STATUS_VALUE,
  },
  ...Object.keys(briefCreatorStatusDisplay)
    .filter((value) =>
      [UserBriefStatus.PENDING, UserBriefStatus.NOT_APPLIED, UserBriefStatus.REJECTED, FULL_BRIEF].includes(value),
    )
    .map((value): FilterOption<string> => {
      return {
        value: value,
        label: briefCreatorStatusDisplay[value],
      };
    }),
];

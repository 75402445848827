import React from 'react';
import cs from 'classnames';
import { TailSpin } from 'react-loader-spinner';

import { Flex } from 'shared/components/display';

import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';

import { CLASSNAMES, StyledActionButton } from './actionButtonStyles';

/**
 * Button for actions that you want to be sure the user can only trigger once
 */

interface Props {
  onClick: any;
  disabled?: boolean;
  loading?: boolean;
  children: React.ReactNode;
  loaderColor?: string;
  className?: string;
  variant?: string;
  type?: 'button' | 'submit';
  tabIndex?: number;
  dataCy?: string;
}

const ActionButton = React.forwardRef<any, Props>(
  (
    {
      onClick,
      disabled = false,
      loading = false,
      children,
      loaderColor = COLORS.WHITE,
      className,
      variant = BUTTON_VARIANTS.PRIMARY,
      type = 'button',
      tabIndex,
      dataCy,
    },
    ref,
  ) => {
    return (
      <StyledActionButton
        variant={variant}
        ref={ref}
        className={cs(className, 'action-button')}
        onClick={!loading ? onClick : null}
        disabled={disabled || loading}
        type={type}
        {...(!!tabIndex ? { tabIndex } : {})}
        data-cy={dataCy}
      >
        <Flex className={CLASSNAMES.wrapper}>
          <TailSpin
            visible={!!loading}
            color={loaderColor}
            height="1rem"
            width="1rem"
            wrapperClass={CLASSNAMES.loadingIcon}
            data-cy="action-button__loading"
          />
          {children}
        </Flex>
      </StyledActionButton>
    );
  },
);

export default ActionButton;

import React from 'react';
import { useAuth } from 'features/auth/useAuth';
import { Button } from 'rebass/styled-components';
import { USER_COPY } from 'shared/config/copy';
import PromptButton from 'shared/components/molecules/PromptButton';
import { useUser } from 'features/users/hooks/useUser';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';

interface Props {
  localStorageKeys?: string[];
  variant?: string;
  onClick?: () => void;
  className?: string;
}
const SignOutButton: React.FC<Props> = ({ localStorageKeys = [], variant, onClick, className }) => {
  const { signOut } = useAuth();
  const { currentUser } = useCurrentUser();
  const { disconnectInstagram } = useUser(currentUser.id);

  const onConfirm = () => {
    localStorageKeys.forEach((key) => window.localStorage.removeItem(key));
    disconnectInstagram();
    signOut();
  };

  const handleSignOut = () => {
    signOut();
    if (onClick) onClick();
  };

  const isOnboarding = localStorageKeys?.length > 0;

  return isOnboarding ? (
    <PromptButton
      onClick={onClick}
      className={className}
      text={USER_COPY.BODY_ONBOARD_SIGN_OUT}
      onConfirm={onConfirm}
      variant={variant}
    >
      {USER_COPY.BUTTON_SIGN_OUT}
    </PromptButton>
  ) : (
    <Button className={className} variant={variant} onClick={handleSignOut}>
      {USER_COPY.BUTTON_SIGN_OUT}
    </Button>
  );
};

export default SignOutButton;

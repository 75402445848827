import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '&.org-settings-layout': {
    height: '100%',
  },
  '.org-settings-layout': {
    '&__content': {
      flex: 1,
      backgroundColor: COLORS.WHITE,
      overflowY: 'auto',
    },
  },
});

import { MESSAGES } from 'shared/config/validations';
import * as yup from 'yup';

export const SIGN_IN_FORM_NAME = 'signInForm';

export const SIGN_IN_FIELDNAMES = {
  EMAIL: 'email',
  PASSWORD: 'password',
  REMEMBER_ME: 'rememberMe',
};

export const signInSchema = yup.object({
  email: yup.string().trim().email(MESSAGES.EMAIL).required(MESSAGES.REQUIRED_FIELD),
  password: yup.string().required(MESSAGES.REQUIRED_FIELD),
  rememberMe: yup.boolean(),
});

import { useNavigate } from 'react-router-dom';

import { useToasts } from 'shared/hooks/useToasts';

type QueryError = {
  statusCode: number;
  message: string;
};

/**
 * This hook should be kept simple.
 */
export function useQueryToast() {
  const { setErrorToast, setSuccessToast } = useToasts();
  const navigate = useNavigate();
  return {
    /**
     * @param error Likely something like "urlParams.error"
     */
    queryToast(error: string | undefined = undefined) {
      if (error !== undefined) {
        const { statusCode, message } = JSON.parse(error) as QueryError;
        if (statusCode === 200) {
          setSuccessToast({
            heading: 'Success!',
            message,
          });
        } else {
          setErrorToast({
            heading: `Error ${statusCode}`,
            message,
          });
        }
        navigate({ search: '' }, { replace: true });
      }
    },
  };
}

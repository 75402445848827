import React from 'react';

import { BriefPermissions } from 'features/briefs/BriefHeader/HeaderBrandActions/types';
import DuplicateBriefButton from 'features/briefs/components/atoms/DuplicateBriefButton';
import ReopenBriefButton from 'features/briefs/components/atoms/ReopenBriefButton';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import SplitButton from 'shared/components/molecules/SplitButton';

import { BRIEF_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { Brief } from 'shared/typings/briefs';

interface Props {
  currentBrief: Brief;
  closedBriefPermissions: Pick<BriefPermissions, 'createEditDraft' | 'closeReopenBrief'>;
}

const ClosedBriefActionButtons: React.FC<Props> = ({ currentBrief, closedBriefPermissions }) => {
  const { duplicateBrief } = useBriefs();
  const { createEditDraft, closeReopenBrief } = closedBriefPermissions;
  const onDuplicate = () => {
    duplicateBrief(currentBrief);
  };

  if (createEditDraft && closeReopenBrief) {
    return (
      <SplitButton primaryText={BRIEF_COPY.BUTTON_DUPLICATE} primaryOnClick={onDuplicate} width="190px">
        <ReopenBriefButton brief={currentBrief} variant={BUTTON_VARIANTS.FLAT_OPTION} />
      </SplitButton>
    );
  }
  {
    return <DuplicateBriefButton brief={currentBrief} variant={BUTTON_VARIANTS.PRIMARY} />;
  }
};

export default ClosedBriefActionButtons;

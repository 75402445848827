import { Value } from 'features/users/components/organisms/TagsInput';
import * as yup from 'yup';

export interface InternalNoteValues {
  tags: Value[];
  note: string;
  originalNote: string;
  originalTags: { value: string; category?: string }[];
}

export const INTERNAL_NOTE_FIELDS = {
  TAGS: 'tags',
  SUBMIT: 'submit',
  NOTE: 'note',
  ORIGINAL_NOTE: 'originalNote',
  ORIGINAL_TAGS: 'originalTags',
};

export const internalNoteSchema = yup.object({
  [INTERNAL_NOTE_FIELDS.TAGS]: yup.array(
    yup.object().shape({
      category: yup.string(),
      values: yup.array().of(yup.string()),
    }),
  ),
  [INTERNAL_NOTE_FIELDS.NOTE]: yup.string(),
});

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.rich-text-image': {
    width: 'max-content',
    position: 'relative',
    maxWidth: '100%',
    zIndex: zIndex.zBackdrop,
    display: 'inline-block',
    '&--selected .rich-text-image__img': {
      padding: 0,
      border: `3px solid ${COLORS.PRIMARY400}`,
    },
    '&--selected .rich-text-image__remove-btn, &:hover .rich-text-image__remove-btn': {
      userSelect: 'none',
      color: COLORS.WHITE,
      position: 'absolute',
      top: '10px',
      right: '10px',
      display: 'inline',
      zIndex: zIndex.zForeground,
      background: `${COLORS.NEUTRAL900}`,
      borderRadius: '5px',
      border: `1px solid ${COLORS.NEUTRAL900}`,
    },
    '&--selected .rich-text-image__remove-btn--read-only, &:hover .rich-text-image__remove-btn--read-only': {
      display: 'none',
    },
  },
  '.rich-text-image': {
    '&__img': {
      maxWidth: '100%',
      padding: '3px',
    },
    '&__remove-btn, &__remove-btn--read-only': {
      display: 'none',
    },
    '&__btn-icon': {
      marginRight: '5px',
    },
    '&__remove-btn:hover .rich-text-image__btn-icon, &__remove-btn:not(:disabled):hover .rich-text-image__btn-icon, &__remove-btn:not(:disabled) .rich-text-image__btn-icon':
      {
        backgroundColor: 'transparent',
        color: COLORS.WHITE,
      },
    '&__wrapper': {
      display: 'inline-block',
      zIndex: zIndex.zBackdrop,
    },
  },
});

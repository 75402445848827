import React from 'react';
import { withChatContext } from 'stream-chat-react';
import { Box } from 'shared/components/display';
import styles from './styles';
import { parseChannelDisplayTitle } from 'features/messages/util';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';

const MessageChannelHeader = (props) => {
  const {
    channel: {
      data: { name },
    },
    openMobileNav,
  } = props;
  const { isCreator } = useCurrentUser();
  const title = isCreator ? parseChannelDisplayTitle(name) : name;

  return (
    <Box css={styles} className="str-chat__header-livestream">
      <div
        className="str-chat__header-hamburger"
        onClick={openMobileNav}
        onKeyPress={openMobileNav}
        role="button"
        tabIndex={0}
      >
        <Box className="str-chat__header-hamburger--line"></Box>

        <Box className="str-chat__header-hamburger--line"></Box>

        <Box className="str-chat__header-hamburger--line"></Box>
      </div>
      <div className="str-chat__header-livestream-left">
        <p className="str-chat__header-livestream-left--title">{title}</p>
      </div>
    </Box>
  );
};

const ChannelHeader = withChatContext(MessageChannelHeader);

export default ChannelHeader;

import React from 'react';
import { Box as RebassBox } from 'rebass/styled-components';
import styled from 'styled-components';

import { Box } from 'shared/components/display';
import styles from 'shared/components/layout/SplitPanelScroll/styles';

import { CONTAINER_VARIANTS } from 'shared/styles/containers';
import theme from 'shared/styles/themes';

const { colors, space } = theme;

const SplitPanelScroll = ({ leftComponent, leftHeaderComponent, rightComponent, rightHeaderComponent }) => {
  return (
    <Box css={styles} className="split-panel-scroll">
      <Wrapper variant={CONTAINER_VARIANTS.BORDERED_RADIUS} className="split-panel-scroll__grid">
        <Title left className="split-panel-scroll__left-header">
          {leftHeaderComponent}
        </Title>
        <Panel left className="split-panel-scroll__left-panel">
          {leftComponent}
        </Panel>
        <Title right className="split-panel-scroll__right-header">
          {rightHeaderComponent}
        </Title>
        <Panel right className="split-panel-scroll__right-panel">
          {rightComponent}
        </Panel>
      </Wrapper>
    </Box>
  );
};

const Wrapper = styled(RebassBox)`
  position: relative;
  display: grid;
  grid-template-rows: [title] auto [body] 1fr;
  grid-template-columns: [left] minmax(200px, 1fr) [right] minmax(200px, 1fr) [end];
  padding: 0;
  height: 100%;
`;

const Panel = styled.div`
  overflow-y: auto;
  scroll-behavior: smooth;
  border-top: 2px solid ${colors.NEUTRAL100};
  grid-row: body / span 1;

  ${(props) =>
    props.left &&
    `
        grid-column: left / right;
        border-right: 2px solid ${colors.NEUTRAL100};
    `}
  ${(props) =>
    props.right &&
    `
        grid-column: right / end;
    `}
`;

const Title = styled(Box)`
  padding: 0 ${space[4]};

  ${(props) =>
    props.left &&
    `
        grid-column: left / right;
        border-right: 2px solid ${colors.NEUTRAL100};
    `}
  ${(props) =>
    props.right &&
    `
        grid-column: right / end;
    `}
`;

export default SplitPanelScroll;

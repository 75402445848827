import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';

export default css({
  '.definition-list': {
    '&__label--sticky': {
      top: 0,
      position: 'sticky',
      zIndex: zIndex.zStickyElements,
      backgroundColor: COLORS.WHITE,
    },
    '& > a': {
      overflowWrap: 'anywhere',
    },
    '&__label': {
      color: COLORS.NEUTRAL500,
    },
    '&__label > p': {
      marginBottom: 0,
    },
    '&__label--upper': {
      textTransform: 'uppercase',
    },
    '&__detail': {
      overflowWrap: 'break-word',
      marginInlineStart: 0,
      position: 'relative',
    },
  },
});

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import { fontSizes } from 'shared/styles/text';
import theme from 'shared/styles/themes';

export default css({
  padding: '10px 0',
  marginTop: '-20px',
  [mediaquery.md]: {
    marginTop: '0',
    padding: '10px 0 10px 8px',
  },
  '.payment-info-form': {
    '&__stripe-title': {
      margin: '4px',
    },
    '&__connect-stripe-wrapper': {
      marginTop: '25px',
      fontSize: fontSizes[1],
    },
    '&__pricing-structure-title': {
      margin: '0',
      padding: '20px 0 8px 0',
    },
    '&__pricing-structure-description': {
      ...theme.text.body,
      lineHeight: '1.5',
      whiteSpace: 'pre-line',
    },
  },
});

import { Text } from 'shared/components/display';
import React from 'react';
import NavBar from 'shared/components/atoms/NavBar';
import NavBarLogo from 'shared/components/atoms/NavBarLogo';
import { TEXT_VARIANTS } from 'shared/styles/text';
import ResponsiveDisplay from 'shared/components/atoms/ResponsiveDisplay';
import HamburgerMenu from 'shared/components/atoms/HamburgerMenu';
import NavLinks from 'shared/components/organisms/NavLinks';
import UserDropdown from 'features/home/components/molecules/UserDropdown';
import SignOutButton from 'shared/components/atoms/SignOutButton';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import ChangeUserTypeButton from 'shared/components/atoms/ChangeUserTypeButton';

interface Props {
  localStorageKeys: string[];
}

const OnboardingNavBar: React.FC<Props> = ({ localStorageKeys }) => {
  return (
    <NavBar>
      <NavBarLogo disableNavigate />
      <ResponsiveDisplay display="mobile">
        <Text variant={TEXT_VARIANTS.SUBHEADING}>Creator Onboarding</Text>
        <HamburgerMenu />
      </ResponsiveDisplay>
      <NavLinks>
        <NavLinks.Secondary>
          <ChangeUserTypeButton variant={BUTTON_VARIANTS.TEXT_ONLY} localStorageKeys={localStorageKeys} />
          <SignOutButton variant={BUTTON_VARIANTS.TEXT_ONLY} localStorageKeys={localStorageKeys} />
        </NavLinks.Secondary>
      </NavLinks>
      <ResponsiveDisplay display="desktop">
        <UserDropdown>
          <ChangeUserTypeButton localStorageKeys={localStorageKeys} />
          <SignOutButton localStorageKeys={localStorageKeys} />
        </UserDropdown>
      </ResponsiveDisplay>
    </NavBar>
  );
};

export default OnboardingNavBar;

import * as yup from 'yup';
import { checkboxGroupSchema } from 'shared/components/molecules/CheckboxGroupInput';

export const STATE_FIELDNAMES = {
  STATES: 'states',
};

const VALIDATION_STATE_MESSAGE = 'Please select at least one state';
export const inStoreRequirementSchema = yup.object({
  states: checkboxGroupSchema.test({
    name: 'required',
    message: VALIDATION_STATE_MESSAGE,
    test(values) {
      return Boolean(values?.some((state) => state.checked));
    },
  }),
});

export const applicationPitchSchema = yup.object({
  pitch: yup.string(),
});

export const PITCH_FIELDNAMES = {
  PITCH: 'pitch',
};

export const applicationPitchInitialValues = {
  pitch: '',
};

import React from 'react';
import { useField } from 'formik';
import { css } from '@emotion/react';
import { Box } from 'shared/components/display';

import COLORS from '../styles/colors';

const errorStyle = css({
  color: COLORS.RED500,
  fontSize: '0.75rem',
  // Regardless of text, error box will have a height of 1em.
  height: '1em',
  // Center text in div to make margin top and bottom more consistent.
  display: 'flex',
  alignItems: 'center',
  margin: '.75em 0',
});

/** For situations in which touched/error might not align with field name */
export const ErrorMessage = ({ children, className = '', dataCy = '' }) => (
  <Box className={className} css={errorStyle} data-cy={dataCy}>
    {children}
  </Box>
);

export const ArrayErrorMessage: React.FC<{ name: string }> = ({ name }) => {
  const [_, { touched, error }] = useField(name);
  if (touched && Array.isArray(error)) {
    const uniqueErrors = Array.from(new Set(error.filter((e) => e)));
    return (
      <>
        {uniqueErrors.map((e) => {
          return <ErrorMessage key={e}>{e}</ErrorMessage>;
        })}
      </>
    );
  }
  if (touched && error) return <ErrorMessage>{error}</ErrorMessage>;
  return <></>;
};

/** When used inside of a custom formik field, will display error for that field */
const FieldErrorMessage: React.FC<{ name: string; className?: string; dataCy?: string }> = ({
  name,
  className,
  dataCy,
}) => {
  const [_, { touched, error }] = useField(name);
  const message = touched && typeof error === 'string' && error;
  const show = Boolean(message);
  if (!show) return null;
  return (
    <ErrorMessage className={className} dataCy={dataCy}>
      {message}
    </ErrorMessage>
  );
};

export default FieldErrorMessage;

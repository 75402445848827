import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '.collection': {
    '&__header': {
      flexDirection: 'column',
      justifyContent: 'flex-end',
      [mediaquery.md]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
    '&__control-strip': {
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginBottom: theme.space[3],
      height: '40px',
    },
    '&__control-strip--file-selected': {
      justifyContent: 'space-between',
    },
    '&__upload-btn': {
      marginTop: theme.space[4],
      marginBottom: theme.space[4],
      [mediaquery.md]: {
        marginBottom: 0,
      },
    },
  },
});

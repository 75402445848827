import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rebass/styled-components';

import { useSubmissions } from 'features/submissions/useSubmissions';
import EllipticalMenu from 'shared/components/EllipticalMenu/EllipticalMenu';

import { SUBMISSION_COPY } from 'shared/config/copy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';

import { usePermissions } from 'shared/hooks/usePermissions';
import { Submission } from 'shared/typings/submissions';
import { LinkCreator } from 'shared/utilities/linkUtility';
interface Props {
  organizationId: number;
  submission: Submission;
}
const MarketerSubmissionEllipsis: React.FC<Props> = ({ organizationId, submission }) => {
  const navigate = useNavigate();
  const { markAsUnSeen, toggleImportance } = useSubmissions();
  const { isAuthorized } = usePermissions();
  const canMarkUnseen = isAuthorized(organizationId, PERMISSIONS_MAP.CREATE_EDIT_BRIEF_DRAFT.API); // TODO what permission to check for marking submission as unseen
  return (
    <EllipticalMenu>
      {canMarkUnseen && (
        <Button
          onClick={() =>
            markAsUnSeen({
              submissionId: submission.id,
            })
          }
        >
          {SUBMISSION_COPY.BUTTON_MARK_NEW}
        </Button>
      )}
      {canMarkUnseen && (
        <Button onClick={() => toggleImportance(submission.id)}>
          {submission.important ? SUBMISSION_COPY.BUTTON_MARK_NOT_IMPORTANT : SUBMISSION_COPY.BUTTON_MARK_IMPORTANT}
        </Button>
      )}
      <Button
        onClick={() => {
          const path = LinkCreator.createLink({
            routeKey: 'USER_PROFILE',
            variables: {
              userId: submission.creator.id,
            },
          });

          navigate(path);
        }}
      >
        {SUBMISSION_COPY.BUTTON_VIEW_CREATOR}
      </Button>
    </EllipticalMenu>
  );
};

export default MarketerSubmissionEllipsis;

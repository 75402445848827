import { FORM_COPY } from 'shared/config/copy';
import { Flex } from 'shared/components/display';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { verticalStepHeaderStyles } from 'shared/components/forms/MultiStepForm/Steps/VerticalStepWrapper/verticalStepStyles';
import Heading from 'shared/components/molecules/Heading';
import PromptButton from 'shared/components/molecules/PromptButton';
import { TEXT_VARIANTS } from 'shared/styles/text';
import cs from 'classnames';
import React from 'react';
import { BUTTON_VARIANTS } from 'shared/styles/button';
interface Props {
  isActive?: boolean;
  isActiveStepDirty?: boolean;
  isCompleted?: boolean;
  onClick?: () => void;
  onKeyPress?: (event: any) => any;
  stepKey: number;
  title: string;
  titleInfo?: { header?: string; text: string };
}
const StepIconAndTitle: React.FC<Props> = ({
  isActive,
  isCompleted,
  onClick,
  onKeyPress,
  stepKey,
  title,
  titleInfo,
}) => {
  const isInteractive = isCompleted && !isActive;

  const interactiveProps = isInteractive
    ? {
        onClick,
        onKeyPress,
        tabIndex: 0,
      }
    : {};

  return (
    <Flex css={verticalStepHeaderStyles} className={cs('step-nav')} {...interactiveProps}>
      <Flex
        className={cs('step-nav__icon', {
          ['step-nav__icon--active']: isActive,
          ['step-nav__icon--completed']: isCompleted,
          ['step-nav__icon--clickable']: isInteractive,
        })}
      >
        {isInteractive ? (
          <FontAwesomeIcon
            icon={faPen}
            size="xs"
            className="vertical-step__edit-icon"
            role="navigation"
            aria-label={`Step ${stepKey} - ${title}`}
          />
        ) : (
          stepKey
        )}
      </Flex>
      <Heading
        text={title}
        headerText={titleInfo?.header}
        descriptionText={titleInfo?.text}
        variant={isActive ? TEXT_VARIANTS.SUBHEADING : TEXT_VARIANTS.BODY}
        className={cs('step-nav__title', {
          [`step-nav__title--clickable`]: isInteractive,
        })}
      />
    </Flex>
  );
};

const VerticalStepHeader: React.FC<Props> = ({
  isActive,
  isActiveStepDirty,
  isCompleted,
  onClick,
  onKeyPress,
  stepKey,
  title,
  titleInfo,
}) => {
  if (isActiveStepDirty) {
    const isInteractive = isCompleted && !isActive;
    return (
      <PromptButton
        text={FORM_COPY.MODAL_WARNING_DATA_LOSS}
        // @ts-ignore
        onConfirm={onClick}
        modalSize="small"
        confirmButtonText="Yes"
        cancelButtonText="No"
        ariaLabel="Data Loss Warning"
        variant={BUTTON_VARIANTS.TEXT_ONLY}
        className="vertical-step__prompt-btn"
        disabled={!isInteractive}
      >
        <StepIconAndTitle
          isCompleted={isCompleted}
          stepKey={stepKey}
          title={title}
          isActive={isActive}
          titleInfo={titleInfo}
        />
      </PromptButton>
    );
  }
  return (
    <StepIconAndTitle
      isCompleted={isCompleted}
      stepKey={stepKey}
      title={title}
      onClick={onClick}
      onKeyPress={onKeyPress}
      isActive={isActive}
      titleInfo={titleInfo}
    />
  );
};

export default VerticalStepHeader;

import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import BriefStatus from 'features/briefs/BriefStatus';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Box } from 'shared/components/display';
import BrkfstTable from 'shared/components/organisms/BrkfstTable';
import Pagination from 'shared/components/organisms/Pagination';

import { BRIEF_COPY } from 'shared/config/copy';
import { useQuery } from 'shared/hooks/useQuery';
import { prettyDate } from 'shared/utilities/dateUtility';

import styles from './styles';
import { Brief } from 'shared/typings/briefs';

const columnHelper = createColumnHelper<any>();

interface Props {
  searchParams: any;
  loading: boolean;
  briefs: Brief[];
  totalBriefs: number;
  selectedItem: number | null;
}

const MarketerBriefIndex: React.FC<Props> = ({ briefs, loading, searchParams, selectedItem, totalBriefs }) => {
  const { setQuery } = useQuery();

  const columns = useMemo(() => {
    return [
      columnHelper.accessor(
        (row) => ({
          id: row.id,
          name: row.name,
        }),
        {
          header: BRIEF_COPY.COLUMN_NAME_BRIEF,
          cell: (info) => {
            const value = info.getValue();
            const selected = value.id === selectedItem;
            return (
              <>
                {selected && <Box as="span" className="brief-index__selected" />}
                {value.name || BRIEF_COPY.DEFAULT_BRIEF_NAME}
              </>
            );
          },
          meta: {
            addTooltip: true,
          },
        },
      ),
      columnHelper.accessor('numSubmissions', {
        header: BRIEF_COPY.COLUMN_NUM_SUBMISSIONS,
        cell: (info) => `${info.renderValue() || 0}`,
        meta: {
          width: '140px',
        },
      }),
      columnHelper.accessor('dateUpdated', {
        header: BRIEF_COPY.COLUMN_DATE_UPDATED,
        cell: (info) => prettyDate(info.renderValue()),
        meta: {
          width: '130px',
        },
      }),
      columnHelper.accessor(
        (row) => ({
          full: row.full,
          status: row.status,
          inviteOnly: row.inviteOnly,
        }),
        {
          header: BRIEF_COPY.COLUMN_STATUS_BRIEF,
          cell: (info) => {
            const value = info.getValue();
            return <BriefStatus status={value.status} full={value.full} inviteOnly={value.inviteOnly} />;
          },
          meta: {
            width: '90px',
          },
        },
      ),
    ];
  }, [selectedItem]);

  return loading ? (
    <CenteredSpinner />
  ) : (
    <Box className="brief-index" css={styles}>
      <Pagination totalAmount={totalBriefs} searchParams={searchParams}>
        <BrkfstTable
          data={briefs}
          columns={columns}
          onRowClick={(el) =>
            setQuery({
              item: el.id,
              subPage: 1,
              subBriefId: el.id,
              creatorsPage: 1,
              creatorsBriefId: el.id,
            })
          }
          noResultsMessage={BRIEF_COPY.PLACEHOLDER_BRIEFS}
          dataCy="brief-index__table"
        />
      </Pagination>
    </Box>
  );
};

export default MarketerBriefIndex;

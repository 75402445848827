import React from 'react';
import cs from 'classnames';
import { Box, Flex } from 'shared/components/display';
import styles from './styles';

interface Props {
  children: React.ReactNode;
  className?: string;
  id?: string;
}

const RoutesWrapper: React.FC<Props> & {
  ContentWrapper: React.ForwardRefExoticComponent<Props & React.RefAttributes<HTMLElement>>;
} = ({ children, className, id }) => {
  return (
    <Flex id={id} css={styles} className={cs('routes-wrapper', className)}>
      {children}
    </Flex>
  );
};

const ContentWrapper = React.forwardRef<HTMLElement, Props>(({ children, className, id }) => {
  return (
    <Box id={id} css={styles} className={cs('content-wrapper', className)}>
      {children}
    </Box>
  );
});

RoutesWrapper.ContentWrapper = ContentWrapper;

export default RoutesWrapper;

import React, { useEffect, useMemo, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik, FormikHelpers } from 'formik';

import {
  facebookBusinessAssetAdded,
  facebookBusinessAssetUpdated,
} from 'features/accountPlatforms/accountPlatforms.slice';
import FacebookConnect from 'features/accountPlatforms/facebook/FacebookConnect/FacebookConnect';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import { Box, Flex, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import { SelectInput } from 'shared/components/molecules/Select';

import { apiAction } from 'shared/actions/api';
import { API_FACEBOOK_ROUTES } from 'shared/config/routes/api/apiFacebookRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useQuery } from 'shared/hooks/useQuery';
import COLORS from 'shared/styles/colors';
import { breakpoints } from 'shared/styles/styleFunctions';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Type } from 'shared/typings/facebookBusinessAsset/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';
import { FormValues, ProductCatalog } from './types';
import { validationSchema } from './validation';

const ENTITY = 'ProductCatalog';

const PLACE_HOLDERS = Object.freeze({
  PRODUCT_CATALOG: 'Product Catalog',
});

const FIELD_NAMES = Object.freeze({
  PRODUCT_CATALOG: 'productCatalog',
});

const DEFAULT_VALUES = Object.freeze({
  [FIELD_NAMES.PRODUCT_CATALOG]: null,
});

type RouteParams = {
  organizationId: string;
  accountId: string;
  accountPlatformId: string;
};

export const ConnectProductCatalog: React.FC<{}> = ({}) => {
  const dispatch = useDispatch();
  const { getQuery } = useQuery();
  const { token, fbError } = getQuery();
  const params = useParams<RouteParams>();
  const { accountPlatform } = useAccountPlatforms({
    accountPlatformId: +params.accountPlatformId,
  });
  const { loading } = useComponentLoading(ENTITY, false);
  const [productCatalogs, setProductCatalogs] = useState<ProductCatalog[]>([]);
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const business = useMemo(
    () => accountPlatform.facebookBusinessAssets?.find(({ type }) => type === Type.BUSINESS),
    [accountPlatform.facebookBusinessAssets],
  );

  const productCatalog = useMemo(
    () => accountPlatform.facebookBusinessAssets?.find(({ type }) => type === Type.PRODUCT_CATALOG),
    [accountPlatform.facebookBusinessAssets],
  );

  const initialValues = useMemo<FormValues>(() => {
    if (productCatalog) return { productCatalog: { label: productCatalog.name, value: productCatalog.facebookId } };
    return DEFAULT_VALUES;
  }, [productCatalog]);

  useEffect(() => {
    if (business && token)
      dispatch(
        apiAction({
          path: {
            route: `${API_FACEBOOK_ROUTES.BUSINESS_ASSETS}/${business.facebookId}/product-catalog`,
          },
          params: {
            token,
          },
          entity: ENTITY,
          onSuccess: ({ data }) => {
            setProductCatalogs(data.map(({ id, name }) => ({ value: id, label: name })));
          },
        }),
      );
  }, [business, token]);

  const onSubmit = (formData: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { productCatalog: catalogFormData } = formData;
    if (business && catalogFormData)
      dispatch(
        apiAction({
          path: {
            route: API_FACEBOOK_ROUTES.BUSINESS_ASSETS,
          },
          data: {
            accountPlatformId: accountPlatform.id,
            name: catalogFormData.label,
            facebookId: catalogFormData.value,
            type: Type.PRODUCT_CATALOG,
          },
          method: 'POST',
          entity: ENTITY,
          successAction: productCatalog ? facebookBusinessAssetUpdated : facebookBusinessAssetAdded,
          onSuccess: () => {
            setSubmitting(false);
          },
          successToast: {
            message: 'Product Catalog connected for Facebook Ad-Creation',
          },
        }),
      );
  };

  return (
    <Box css={styles}>
      <Text variant={TEXT_VARIANTS.CAPTION}>Retrieve Product Catalogs with Facebook</Text>
      <FacebookConnect
        facebookConnected={false}
        redirectURI={LinkCreator.createLink({
          routeKey: 'PLATFORM_REQUIREMENTS',
          variables: {
            organizationId: params.organizationId,
            accountId: params.accountId,
            accountPlatformId: params.accountPlatformId,
          },
        })}
      />
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {(formikProps) => (
          <Form>
            <Flex className="connect-product-catalog__form">
              <Field
                name={FIELD_NAMES.PRODUCT_CATALOG}
                component={SelectInput}
                width={isMobile ? '200px' : '250px'}
                options={productCatalogs}
                placeholder={PLACE_HOLDERS.PRODUCT_CATALOG}
                isSearchable={true}
              />
              <TailSpin
                wrapperClass="connect-product-catalog__loader"
                color={COLORS.PRIMARY200}
                height="25"
                width="25"
                visible={loading}
              />
            </Flex>
            <Flex className="connect-product-catalog__buttons">
              <FormButton className="connect-product-catalog__button">Submit</FormButton>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

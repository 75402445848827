import React from 'react';
import { Box, Text } from 'shared/components/display';
import { StepFieldProps } from 'shared/components/forms/MultiStepForm/interfaces';
import { AddressFormFields } from 'shared/components/organisms/AddressFormFields';
import { AddressValues } from 'shared/components/organisms/AddressFormFields/types';
import { BRIEF_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';
import styles from '../styles';

export const ShippingConfirmationSteps: React.FC<StepFieldProps<AddressValues> & { addressError: string[] }> = ({
  formikProps,
  addressError,
}) => {
  return (
    <Box css={styles} className="creator-brief-starting-steps">
      <Text variant={TEXT_VARIANTS.H5} className="creator-brief-starting-steps__shipping__subheading">
        {BRIEF_COPY.MODAL_CONFIRM_SHIPPING_SUBHEADING}
      </Text>
      <AddressFormFields error={addressError} formikProps={formikProps} />
    </Box>
  );
};

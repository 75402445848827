import { css } from '@emotion/react';

import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.user-profile': {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [mediaquery.md]: {
      display: 'grid',
      gridTemplateColumns: `25rem auto`,
    },
  },
  '.user-profile': {
    '&__right-container': {
      overflow: 'hidden',
      paddingRight: '15px',
      gridColumnStart: 2,
      gridColumnEnd: 'span 2',
    },
  },
});

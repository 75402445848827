import React from 'react';
import { components, ValueContainerProps } from 'react-select';
import cs from 'classnames';

import { Box, Flex } from 'shared/components/display';

import { GeoLocationSuggestion } from 'shared/hooks/useGeoLocationHook';

import styles from './styles';

interface ExtraProps {
  active: boolean;
  formikValue: string;
  ourPlaceholder: string;
  dataCy?: string;
}

type Props = ExtraProps & ValueContainerProps<GeoLocationSuggestion, false>;

const ValueContainer: React.FC<Props> = ({ children, active, formikValue, ourPlaceholder, dataCy, ...props }) => {
  const showValue = !active;
  const showPlaceholder = !Boolean(formikValue) || active;

  return (
    <components.ValueContainer {...props}>
      <Flex css={styles} className="value-container">
        {showValue && formikValue}
        <Box
          as="p"
          className={cs('value-container__placeholder', {
            'value-container__placeholder--active': active,
          })}
        >
          {showPlaceholder ? ourPlaceholder : ' '}
        </Box>

        <Box data-cy={dataCy}>{children}</Box>
      </Flex>
    </components.ValueContainer>
  );
};

export default ValueContainer;

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.checkbox-group': {
    '&__options': {
      flexWrap: 'wrap',
    },
    '&__options--vertical': {
      flexDirection: 'column',
    },
    '&__option': {
      marginRight: theme.space[4],
    },
  },
});

import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import AccountHealthOnboarding from 'features/accountPlatforms/pages/AccountHealthOnboarding/AccountHealthOnboarding';
import AdHealthSignUp from 'features/accountPlatforms/pages/AdHealthSignUp/AdHealthSignUp';
import ExternalAdHealth from 'features/accountPlatforms/pages/ExternalAdHealth/ExternalAdHealth';
import ActivateUser from 'features/auth/ActivateUser';
import FacebookOauthLogin from 'features/auth/pages/FacebookOauthLogin';
import ForgotPassword from 'features/auth/pages/ForgotPassword';
import GoogleOauthLogin from 'features/auth/pages/GoogleOauthLogin';
import ResetPasswordLinkConfirmation from 'features/auth/pages/ResetPasswordLinkConfirmation';
import SignInPage from 'features/auth/pages/SignInPage';
import SignUpLinkConfirmation from 'features/auth/pages/SignUpLinkConfirmation';
import SignUpPage from 'features/auth/pages/SignUpPage';
import TokenExpired from 'features/auth/pages/TokenExpired';
import InvalidInviteTokenPage from 'features/ui/pages/ErrorPages/InvalidInviteTokenPage';
import ResetPassword from 'features/auth/ResetPassword';
import ResetPasswordConfirmation from 'features/auth/ResetPasswordConfirmation';
import VerifyResetPasswordToken from 'features/auth/VerifyResetPasswordToken';
import DownloadReady from 'features/media/pages/DownloadReady/DownloadReady';
import { NotFoundPage } from 'features/ui/pages/ErrorPages';
import LazySpinner from 'shared/components/molecules/LazySpinner';
import UploadStatusDrawer from 'shared/components/molecules/UploadStatusDrawer';

import { useQuery } from 'shared/hooks/useQuery';
import DashboardRoutes from 'shared/router/DashboardRoutes';

import ProtectedRouteWrapper from './ProtectedRouteWrapper';
import PublicRouteWrapper from './PublicRouteWrapper';
import SignalRRouteWrapper from './SignalRRouteWrapper';

const AuthRoutes = () => {
  const { getQuery } = useQuery();
  const { adAccountId } = getQuery();

  return (
    <>
      <Suspense fallback={<LazySpinner />}>
        <Routes>
          <Route element={<PublicRouteWrapper />}>
            <Route path="/sign-up" element={<SignUpPage />} />
            <Route path="/sign-in" key="/sign-in" element={<SignInPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password-confirmation" element={<ResetPasswordLinkConfirmation />} />
            <Route path="/download-ready" element={<DownloadReady />} />
            <Route path="/ad-health-onboarding">
              <Route index element={<AdHealthSignUp />} />
              <Route path="connect" element={<AccountHealthOnboarding />} />
            </Route>
            <Route element={<SignalRRouteWrapper userId={adAccountId} />}>
              <Route path="/external-ad-health" element={<ExternalAdHealth />} />
            </Route>
            <Route path="/user">
              <Route path="google" element={<GoogleOauthLogin />} />
              <Route path="facebook" element={<FacebookOauthLogin />} />
              <Route path="verify-reset-password" element={<VerifyResetPasswordToken />} />
              <Route path="reset-password" element={<ResetPassword />} />
              <Route path="sign-up-confirmation" element={<SignUpLinkConfirmation />} />
              <Route path="request-new" element={<TokenExpired />} />
              <Route path="reset-password-confirmation" element={<ResetPasswordConfirmation />} />
              <Route path="activate" element={<ActivateUser />} />
            </Route>
            <Route path="/invalid-invite-token" element={<InvalidInviteTokenPage />} />
          </Route>

          <Route element={<ProtectedRouteWrapper />}>
            <Route path="*" element={<DashboardRoutes />} />
            <Route path="/invalid-invite-token" element={<InvalidInviteTokenPage />} />
            <Route path="/not-found" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </Suspense>
      <UploadStatusDrawer />
    </>
  );
};

export default AuthRoutes;

export const API_AUTH_ROUTES = Object.freeze({
  ACTIVATE_USER: 'user/activate',
  GET_USER_BY_TOKEN: 'user/get-by-token',
  GOOGLE_OAUTH_REDIRECT: 'user/auth/google',
  FACEBOOK_OAUTH_REDIRECT: 'user/auth/facebook/callback',
  TIKTOK_OAUTH_REDIRECT: 'user/auth/tiktok/callback',
  INVITE_SIGN_UP: 'user/invite/sign-up',
  LOG_IN: 'user/auth/local',
  REGISTER: 'user',
  REQUEST_RESET_PASSWORD: 'user/request-reset-password',
  RESEND_LINK: 'user/resend-link',
  RESET_PASSWORD: 'user/reset-password',
  SIGN_OUT: 'user/logout',
});

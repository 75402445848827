import { API_FILE_ROUTES } from 'shared/config/routes/api/apiFileRoutes';
import { MESSAGES } from 'shared/config/validations';
import { apiRequest } from 'shared/middleware/api';

import { Validator, ValidatorOptions } from './types';
import { hasFileInCollection } from './utils';
import { intl } from 'shared/lib/intl';
import { BrkfstFile } from 'shared/typings/file';

export class CollectionValidator implements Validator {
  constructor(private collectionId: number, private collectionFiles: BrkfstFile[], private numCollectionFiles: number) {
    this.collectionId = collectionId;
    this.collectionFiles = collectionFiles;
    this.numCollectionFiles = numCollectionFiles;
  }

  public async validate({ fileName: fName, file, metadata }: ValidatorOptions) {
    const errorMessages: Array<string> = [];
    const fileName = fName || file.name;
    let fileExists = false;
    if (this.collectionFiles.length !== this.numCollectionFiles) {
      // Check backend
      const res = await apiRequest({
        path: API_FILE_ROUTES.FILES,
        params: { name: fileName, collectionId: this.collectionId },
      });
      const {
        data: { data },
      } = res;
      if (data.length) fileExists = true;
    }
    if (hasFileInCollection(fileName, this.collectionFiles) || fileExists) {
      errorMessages.push(
        intl.formatMessage(
          {
            id: 'ERROR_FILE_DUPLICATE_ERROR',
          },
          {
            fileName,
          },
        ),
      );
    }
    return errorMessages;
  }
}

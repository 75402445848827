import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';
import SHADOWS from 'shared/styles/shadow';

export default (size: number, numRows?: number) =>
  css({
    '&.grid-gallery': {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fill, minmax(max-content, 1fr))`,
      gridTemplateRows: `repeat(${numRows}, minmax(${size}px, 1fr))`,
      gridAutoFlow: 'column',
      gridAutoColumns: `${size}px`,
      overflowX: 'auto',
      gridColumnGap: '.75rem',
      gridRowGap: '.75rem',
      marginRight: theme.space[4],
      ...(numRows
        ? {
            // always show scrollbar
            overflowX: 'scroll',
            '::-webkit-scrollbar': {
              backgroundColor: COLORS.WHITE,
              borderRadius: '10px',
              width: '100%',
              height: '7px',
            },
            '::-webkit-scrollbar-thumb': {
              backgroundColor: COLORS.NEUTRAL500,
              borderRadius: '10px',
            },
          }
        : {}),
    },
    '.grid-gallery': {
      '&__show-more': {
        width: `${size}px`,
        height: `${size}px`,
        background: COLORS.NEUTRAL50,
        boxShadow: SHADOWS.SHADOW02,
        borderRadius: '4px',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '10px 10px 32px 10px',
        cursor: 'pointer',
      },
      '&__show-more-icon-wrapper': {
        background: COLORS.PRIMARY100,
        margin: '2px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        width: '50px',
        height: '50px',
      },
      '&__show-more-icon': {
        padding: '5px',
        color: COLORS.PRIMARY500,
      },
      '&__show-more-text': {
        width: '30vw',
        lineHeight: '1.15',
        [mediaquery.md]: {
          width: '16vw',
          lineHeight: '1.75',
        },
      },
      '&__placeholder': {
        fontStyle: 'italic',
      },
    },
  });

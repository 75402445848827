import { componentLoadingToggled, errorTriggered, setSuccessMessage } from 'features/ui/ui.slice';

import { userCleared } from 'shared/actions/currentUser';
import { errorToastActivated, infoToastActivated, successToastActivated } from 'shared/actions/toasts';
import axiosClient from 'shared/axiosClient';
import { historyPushed } from 'shared/middleware/routing';
import { getErrorStatusCode, isOnErrorPage, parseError, requiresSignIn } from 'shared/utilities/errorUtility';
import { LinkCreator } from 'shared/utilities/linkUtility';

export const apiRequest = ({ path = '', method = 'GET', data = {}, params = {}, config = {}, headers = {} }) => {
  return axiosClient.httpRequest({
    method,
    url: path,
    data,
    params,
    headers,
    config: {
      // default values (can overwrite)
      addBetterGetParams: true,
      addAuthHeader: true,
      ...config,
    },
  });
};

// middleware
// actions match -- look up

export const apiMiddleware = () => (next) => (action) => {
  // can access dispatch & getState in first paren
  next(action);
  if (action.type === 'API_REQUEST') {
    const { data, meta } = action;
    const {
      path,
      method,
      params,
      headers,
      successAction,
      errorAction = errorTriggered,
      pushFunction,
      navigate,
      config,
      entity,
      successToast,
      errorToast,
      hideError,
      showSuccessToast,
      onSuccess,
      onError,
    } = meta;

    next(componentLoadingToggled({ component: entity, loading: true }));

    // could use getState here to implement caching ??
    apiRequest({
      path: typeof path === 'string' ? path : LinkCreator.createApiLink(path),
      method,
      data,
      headers,
      params,
      config,
    })
      .then((response) => {
        if (successAction) {
          next(successAction({ requestData: data, data: response.data, params }));
        }
        if (onSuccess) {
          onSuccess({ data: response.data, params });
        }
        if (showSuccessToast) {
          const { message, heading } = response.data;

          next(
            successToastActivated({
              message,
              heading,
            }),
          );
        }
        if (response.data.message) {
          const { message } = response.data;
          next(
            setSuccessMessage({
              message,
              entity,
            }),
          );
        }

        next(
          componentLoadingToggled({
            component: entity,
            loading: false,
          }),
        );

        if (pushFunction) {
          next(
            historyPushed({
              navigate,
              pushFunction,
              data: response.data,
            }),
          );
        }
        if (successToast) {
          const { message, heading } = successToast;
          next(
            successToastActivated({
              message: message,
              heading: heading,
            }),
          );
        }
      })
      .catch((error) => {
        // update redux state on complete
        console.error(error);
        next(
          componentLoadingToggled({
            component: entity,
            loading: false,
          }),
        );
        const errorMessage = parseError(error);
        // handle error messaging
        if (requiresSignIn(error)) {
          next(userCleared());
          if (error.response && errorMessage && !hideError) {
            next(
              errorToastActivated({
                message: errorMessage,
                heading: error.response?.data?.title,
              }),
            );
          }
        } else if (errorToast) {
          // display user provided error toast copy
          const { message, heading } = errorToast;
          next(
            errorToastActivated({
              message: message || error.message,
              heading,
            }),
          );
        } else if (error.response && errorMessage && !hideError && !isOnErrorPage()) {
          // display error copy from the backend
          const status = getErrorStatusCode(error);
          const statusGroup = Math.floor(status / 100);

          if (statusGroup === 1 || statusGroup === 3) {
            next(
              infoToastActivated({
                message: errorMessage,
              }),
            );
          } else if (statusGroup === 4 || statusGroup === 5) {
            next(
              errorToastActivated({
                message: errorMessage,
                heading: error.response?.data?.title,
              }),
            );
          }
        }
        if (onError) {
          onError(error);
        }
        next(errorAction({ error, entity }));
      });
  }
};

// call next if state is updated, call dispatch to start again from the beginning

import React from 'react';
import { GetRailProps } from 'react-compound-slider/dist/types/Rail/types';

const SliderRail: React.FC<{ getRailProps: GetRailProps }> = ({
  getRailProps,
}) => (
    <>
        <div className="slider__rail-outer" {...getRailProps()} />
        <div className="slider__rail-inner" />
    </>
);

export default SliderRail;

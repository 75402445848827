import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import { createColumnHelper } from '@tanstack/react-table';
import { isEmpty } from 'lodash';

import StripeReceipt from 'features/organizations/components/molecules/StripeReceipt';
import { useInvoice } from 'features/organizations/useInvoice';
import { useOrganizations } from 'features/organizations/useOrganizations';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { useRightDrawer } from 'features/ui/useRightDrawer';
import ExternalLink from 'shared/components/atoms/ExternalLink/ExternalLink';
import { Box, Flex, Text } from 'shared/components/display';
import BrkfstTable from 'shared/components/organisms/BrkfstTable';

import { INVOICE_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { prettyDate } from 'shared/utilities/dateUtility';
import { formatCurrency } from 'shared/utilities/stringUtility';

import styles from './styles';

const { PLAIN } = BUTTON_VARIANTS;

const { OVERLINE, H4 } = TEXT_VARIANTS;

const columnHelper = createColumnHelper<any>();

const Billing = () => {
  const params = useParams<{ organizationId: string }>();
  const organizationId = +params.organizationId;

  const [page, setPage] = useState(1);
  const { organization } = useOrganizations(organizationId);
  const { invoices, loading, fetchPaginatedInvoices, hasMore } = useInvoice({
    organizationId,
    shouldFetch: true,
  });
  const { setRightDrawer } = useRightDrawer(true);

  const openReceiptRightDrawer = (charge) => {
    setRightDrawer({
      key: charge.id,
      Content: <StripeReceipt {...charge} />,
    });
  };

  const columns = [
    columnHelper.accessor('dateCreated', {
      header: INVOICE_COPY.COLUMN_PAY_DATE,
      cell: (info) => prettyDate(info.getValue()),
      meta: {
        width: '110px',
      },
    }),
    columnHelper.accessor('amountInDollars', {
      header: INVOICE_COPY.COLUMN_AMOUNT,
      cell: (info) => formatCurrency(info.getValue()),
      meta: {
        width: '140px',
      },
    }),
    columnHelper.accessor((row) => row.metadata.brandName, {
      header: INVOICE_COPY.COLUMN_BRAND,
      cell: (info) => info.getValue() || '-',
      meta: {
        addTooltip: true,
        width: '110px',
      },
    }),
    columnHelper.accessor((row) => row.metadata.briefName, {
      header: INVOICE_COPY.COLUMN_BRIEF,
      cell: (info) => info.getValue() || '-',
      meta: {
        addTooltip: true,
        width: '110px',
      },
    }),
    columnHelper.accessor('receiptUrl', {
      header: INVOICE_COPY.COLUMN_RECEIPT,
      cell: (info) => <ExternalLink href={info.getValue()} text={'View on Stripe'} className="billing__link" />,
      meta: {
        width: '110px',
      },
    }),
  ];

  if (isEmpty(organization)) {
    return <></>;
  }

  return (
    <Box css={styles} className="billing">
      <Flex className="billing__heading">
        <Text variant={H4}>{INVOICE_COPY.HEADING_BILLING}</Text>
      </Flex>
      <Text variant={OVERLINE}>{INVOICE_COPY.HEADING_PAYMENT_HISTORY}</Text>
      {loading ? (
        <CenteredSpinner />
      ) : (
        <>
          <BrkfstTable columns={columns} data={invoices} loading={loading} onRowClick={openReceiptRightDrawer} />
          <Flex className="billing__pagination">
            {page > 1 && (
              <Button
                variant={PLAIN}
                onClick={() => {
                  setPage((p) => p - 1);
                  fetchPaginatedInvoices('previous');
                }}
              >
                Previous
              </Button>
            )}
            {hasMore && (
              <Button
                variant={PLAIN}
                onClick={() => {
                  setPage((p) => p + 1);
                  fetchPaginatedInvoices('next');
                }}
              >
                Next
              </Button>
            )}
          </Flex>
        </>
      )}
    </Box>
  );
};

export default Billing;

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  '.underline-input::-webkit-outer-spin-button, .underline-input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '.underline-input': {
    marginTop: '0.75rem',
    marginBottom: '0.25rem',
    borderRadius: '2px',
    boxShadow: 'none',
    border: `1px solid ${COLORS.NEUTRAL300}`,
    padding: '0.5rem 0.75rem',
    fontSize: '1rem',
    color: `${COLORS.BLACK}`,
    '-moz-appearance': 'textfield',
    '&--no-margin': {
      marginTop: '0',
      marginBottom: '0',
    },
    '&--submitted': {
      borderColor: COLORS.RED500,
    },
    '&:not(:disabled):active, &:not(:disabled):focus': {
      borderColor: `${COLORS.PRIMARY500}`,
    },

    '&:disabled': {
      padding: '0.5rem 2rem 0.5rem 0.75rem',
      borderColor: `${COLORS.NEUTRAL300}`,
      background: `${COLORS.NEUTRAL50}`,
      color: `${COLORS.NEUTRAL500}`,
    },
    '&__text-counter': {
      margin: 0,
    },
    '&::placeholder': {
      color: COLORS.NEUTRAL700,
    },
  },
});

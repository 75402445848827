import { Flex } from 'shared/components/display';
import { FILE_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import React, { useEffect, useRef } from 'react';
import { Button } from 'rebass/styled-components';
import styles from './styles';

const { OUTLINE } = BUTTON_VARIANTS;

export type ThumbnailCapture = {
  thumbnailOffset: number;
};

type Props = {
  src: string;
  width?: number;
  onCapture: (capture: ThumbnailCapture) => void;
};

/** Allows a user to select a thumbnail */
const VideoThumbnailPicker: React.FC<Props> = ({ src, width = 200, onCapture }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const setCanvas = () => {
    if (videoRef.current === null) return;
    if (canvasRef.current === null) return;

    const videoElement = videoRef.current;
    const visibleCanvas = canvasRef.current;

    const { videoWidth, videoHeight } = videoElement;
    const scaledHeight = (width / videoWidth) * videoHeight;

    visibleCanvas.width = width;
    visibleCanvas.height = scaledHeight;

    const visibleContext = visibleCanvas.getContext('2d');
    visibleContext?.drawImage(videoElement, 0, 0, width, scaledHeight);
  };

  const handleCapture = () => {
    if (videoRef.current === null) return;
    if (canvasRef.current === null) return;

    setCanvas();
    const { currentTime, duration } = videoRef.current;
    // Seek backwards just a little bit to fix the offset that prevents it from correctly setting the thumbnail when the last frame is selected.
    const offset = currentTime === duration ? currentTime - 0.2 : currentTime;
    onCapture({
      thumbnailOffset: offset,
    });
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0.5;
    }
  }, []);

  return (
    <Flex css={styles} className="video-thumbnail-picker">
      <Flex className="video-thumbnail-picker__preview">
        <video width={width} src={src} ref={videoRef} onLoadedData={setCanvas} controls />
        <canvas ref={canvasRef} className="video-thumbnail-picker__canvas" />
      </Flex>
      <Button type="button" variant={OUTLINE} onClick={handleCapture} className="video-thumbnail-picker__button">
        {FILE_COPY.BUTTON_THUMBNAIL}
      </Button>
    </Flex>
  );
};

export default React.memo(VideoThumbnailPicker);

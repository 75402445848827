import { css } from '@emotion/react';

import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.account-health-no-platforms': {
    '& .ad-health-sign-in__form': {
      [mediaquery.md]: {
        margin: '70px auto',
      },
    },
  },
});

import React from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import styles, { selectedFilePopoverContentStyles } from './styles';
import SelectedFileActions, { SelectedFileActionsProps } from 'features/media/components/molecules/SelectedFileActions';
import Popover from 'shared/components/atoms/Popover';
import { Button } from 'rebass/styled-components';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { intl } from 'shared/lib/intl';

interface Props {}
const SelectedFilePopover: React.FC<Props & SelectedFileActionsProps> = ({
  collectionId,
  selectedFileIds,
  isMarketerCollection,
}) => {
  return (
    <Box css={styles} className={cs('selected-file-popover')}>
      <Popover
        ClickComponent={
          <Button className="selected-file-popover__btn" variant={BUTTON_VARIANTS.TEXT_ONLY}>
            <FontAwesomeIcon icon={faFileCircleCheck} />
            {intl.formatMessage({ id: 'BUTTON_SELECTED_FILES' }, { numFiles: selectedFileIds.length })}
          </Button>
        }
        contentClassName="selected-file-popover__content-wrapper"
        contentStyles={selectedFilePopoverContentStyles}
      >
        <SelectedFileActions
          collectionId={collectionId}
          selectedFileIds={selectedFileIds}
          isMarketerCollection={isMarketerCollection}
        />
      </Popover>
    </Box>
  );
};

export default SelectedFilePopover;

import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { Field } from 'formik';
import { difference } from 'lodash';

import { Box, Button } from 'shared/components/display';
import CategoryTags from 'shared/components/molecules/CategoryTags';
import { Select } from 'shared/components/molecules/Select';
import { TagInput } from 'shared/components/molecules/TagInput';

import axiosClient from 'shared/axiosClient';
import { USER_COPY } from 'shared/config/copy';
import { API_TAG_ROUTES } from 'shared/config/routes/api/apiTagRoutes';
import { makeArray } from 'shared/lib/formik';
import { BUTTON_VARIANTS } from 'shared/styles/button';

import styles from './styles';

export type Value = {
  category: string;
  values: string[];
};

interface State {
  categories: { value: string; label: string }[];
  values: Record<string, string[]>;
  loading: boolean;
}

interface Props {
  dataCy?: string;
  allowAddCategory?: boolean;
  placeholder?: string;
  theme?: 'top-category' | 'top-category-plain' | 'left-category';
}

const TagsInput = makeArray<Value, Props>(
  ({ values, name, push, allowAddCategory = true, placeholder, theme = 'left-category' }) => {
    const [inputState, setInputState] = useState<State>({
      loading: true,
      categories: [],
      values: {},
    });
    const [showCategorySelect, setShowCategorySelect] = useState(false);
    const [active, setActive] = useState(false);

    const updateActiveState = () => {
      setActive((prev) => !prev);
    };

    const existingCategories = values.map(({ category }) => category);

    const onSelectNewCategory = (category: { value: string; label: string }) => {
      setShowCategorySelect(false);
      // remove selected category from values that can be chosen
      setInputState((prev) => ({
        ...prev,
        categories: prev.categories.filter((c) => c.value !== category.value),
      }));
      push({ category: category.value, values: [] });
    };

    useEffect(() => {
      // fetch categories
      axiosClient
        .httpRequest<{ category: string; values: string[] }[]>({
          url: API_TAG_ROUTES.TAG_CATEGORIES,
          method: 'GET',
        })
        .then(({ data }) => {
          const categories = data.map(({ category }) => category);
          // don't show categories that already have been used for the user
          setInputState({
            categories: difference(categories, existingCategories).map((category) => ({
              value: category,
              label: category.toUpperCase(),
            })),
            values: data.reduce((acc, val) => {
              acc[val.category] = val.values;
              return acc;
            }, {}),
            loading: false,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }, []);

    if (inputState.loading) {
      return <Skeleton count={3} height="200px" width="100%" />;
    }

    return (
      <Box css={styles} className={cs('tags-input')} onClick={updateActiveState} onBlur={updateActiveState}>
        {values.map(({ category, values: tags }, index) => (
          <CategoryTags.Wrapper className="tags-input__category-group" theme={theme} key={category}>
            <CategoryTags.Category category={category} active={active} />
            <CategoryTags.Values>
              {category !== 'other' ? (
                <Field
                  className={cs('tags-input__tag-input', {
                    'tags-input__tag-input--plain': theme === 'top-category-plain',
                  })}
                  dataCy={`tags-input__tag-input--${category}`}
                  component={TagInput}
                  name={`${name}[${index}].values`}
                  value={tags}
                  whitelist={inputState.values[category.toLowerCase()]}
                  enforceWhitelist
                  showDropdownOnFocus
                  disableEditingTags
                  placeholder={placeholder}
                />
              ) : (
                // when there is no category, we want the user to be able to remove but not add any tags
                <Field
                  className="tags-input__tag-input"
                  component={TagInput}
                  name={`${name}[${index}].values`}
                  value={tags}
                  disableAddingTags
                  placeholder={placeholder}
                />
              )}
            </CategoryTags.Values>
          </CategoryTags.Wrapper>
        ))}
        {showCategorySelect ? (
          <Select
            name="Add Category"
            placeholder={USER_COPY.PLACEHOLDER_USER_TAGS_INPUT}
            value={null}
            options={inputState.categories}
            onChange={onSelectNewCategory}
            dataCy="tags-input__category-select"
            capitalizePlaceholder={false}
            isSearchable
          />
        ) : allowAddCategory ? (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setShowCategorySelect(true);
            }}
            variant={BUTTON_VARIANTS.TEXT_ONLY}
            className="tags-input__add-category-btn"
            data-cy="tags-input__add-category-btn"
            type="button"
          >
            <FontAwesomeIcon icon={faPlus} />
            Add Category
          </Button>
        ) : (
          <></>
        )}
      </Box>
    );
  },
);

export default TagsInput;

import { useDispatch, useSelector } from 'react-redux';

import {
  creatorChanged,
  creatorsLoaded,
  creatorsTotalCountRefresh,
  getFilteredCreators,
  getResultsCount,
  getTotalCount,
} from 'features/creators/creators.slice';
import { userChanged } from 'features/users/hooks/user.slice';
import { apiAction } from 'shared/actions/api';
import { PLURALS, RESOURCES } from 'shared/config/resourceNames';
import { API_BRIEF_ROUTES } from 'shared/config/routes/api/apiBriefRoutes';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';
import { CREATOR_TOASTS } from 'shared/config/toasts/creatorToasts';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { BrkfstSearchQueryParams } from 'shared/typings/searchQueryParams';
import { VettingAction, VettingStatus } from 'shared/typings/user/enums';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { formatDateWithSlashes, formatISODate } from 'shared/utilities/dateUtility';
import { GetAllUsersQuery } from 'features/creators/components/molecules/CreatorSearch/types';

export const useCreators = () => {
  const dispatch = useDispatch();
  const creators = useSelector(getFilteredCreators);
  const creatorResultsCount = useSelector(getResultsCount);
  const creatorTotalCount = useSelector(getTotalCount);
  const { loading } = useComponentLoading(PLURALS.CREATOR);
  const { loading: singleLoading } = useComponentLoading(RESOURCES.CREATOR, false);
  const { loading: deadlineChangeLoading } = useComponentLoading(RESOURCES.CREATOR_DEADLINE, false);
  const { loading: starredChangeLoading } = useComponentLoading(RESOURCES.CREATOR_STARRED, false);
  const { loading: getCreatorsShippingInfoLoading } = useComponentLoading(RESOURCES.CREATORS_SHIPPING_INFO, false);

  const fetchBriefCreators = (
    query: BrkfstSearchQueryParams & { status?: UserBriefStatus; accountId: number },
    briefId: number,
  ) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.PAGINATED_CREATORS,
          variables: {
            briefId,
          },
        },
        successAction: creatorsLoaded,
        params: query,
        entity: PLURALS.CREATOR,
      }),
    );
  };

  const approveCreator = ({
    creatorId,
    briefId,
    accountId,
  }: {
    creatorId: number;
    briefId: number;
    accountId: number;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.APPROVE_CREATOR,
          variables: {
            briefId,
            creatorId,
          },
        },
        data: {
          accountId,
        },
        method: 'POST',
        successAction: creatorChanged,
        entity: RESOURCES.CREATOR,
        successToast: {
          message: CREATOR_TOASTS.APPROVED,
        },
      }),
    );
  };

  const rejectCreator = ({
    creatorId,
    briefId,
    accountId,
  }: {
    creatorId: number;
    briefId: number;
    accountId: number;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.REJECT_CREATOR,
          variables: {
            briefId,
            creatorId,
          },
        },
        data: {
          accountId,
        },
        method: 'POST',
        successAction: creatorChanged,
        entity: RESOURCES.CREATOR,
        successToast: {
          message: CREATOR_TOASTS.REJECTED,
        },
      }),
    );
  };

  const queryCreators = (query: GetAllUsersQuery = {}, refreshTotalCount = false) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.FIND_CREATORS,
        },
        successAction: refreshTotalCount ? creatorsTotalCountRefresh : creatorsLoaded,
        params: query,
        entity: PLURALS.CREATOR,
      }),
    );
  };

  const setCreatorBriefDeadline = (creatorId: number, briefId: number, deadline: Date, timezone: string, onError) => {
    const deadlineString = formatDateWithSlashes(formatISODate(deadline));
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.BRIEF_DEADLINE,
          variables: {
            userId: creatorId,
          },
        },
        method: 'POST',
        data: { briefId, deadline: deadlineString, timezone },
        successAction: creatorChanged,
        successToast: { message: CREATOR_TOASTS.DEADLINE_UPDATED },
        onError,
        entity: RESOURCES.CREATOR_DEADLINE,
      }),
    );
  };

  const setShippingLink = (
    creatorId: number,
    briefId: number,
    shippingLink: string = '',
    onFinish,
    successToast: string,
  ) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.SHIPPING_LINK,
          variables: {
            userId: creatorId,
          },
        },
        method: 'PATCH',
        data: { briefId, shippingLink },
        successAction: creatorChanged,
        successToast: {
          message: successToast,
        },
        onSuccess: onFinish,
        onError: onFinish,
      }),
    );
  };

  const deleteCreatorBriefDeadline = (creatorId: number, briefId: number, onError) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.BRIEF_DEADLINE,
          variables: {
            userId: creatorId,
          },
        },
        method: 'DELETE',
        data: { briefId },
        successAction: creatorChanged,
        successToast: { message: CREATOR_TOASTS.DEADLINE_DELETED },
        onError,
      }),
    );
  };

  const approveCreatorVetting = (userId: number, vettingStatus: VettingStatus) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.UPDATE_VETTING_STATUS,
          variables: {
            userId,
          },
        },
        data: {
          vettingStatus,
          vettingAction: VettingAction.APPROVE,
        },
        method: 'PATCH',
        successAction: userChanged,
        entity: RESOURCES.CREATOR,
        successToast: {
          message: CREATOR_TOASTS.APPROVED,
        },
      }),
    );
  };

  const rejectCreatorVetting = (userId: number, vettingStatus: VettingStatus) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.UPDATE_VETTING_STATUS,
          variables: {
            userId,
          },
        },
        data: {
          vettingStatus,
          vettingAction: VettingAction.REJECT,
        },
        method: 'PATCH',
        successAction: userChanged,
        entity: RESOURCES.CREATOR,
        successToast: {
          message: CREATOR_TOASTS.REJECTED,
        },
      }),
    );
  };

  const setCreatorBriefStarred = (creatorId: number, briefId: number, starred: boolean) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.CREATOR_BRIEF_STARRED,
          variables: {
            userId: creatorId,
          },
        },
        method: 'PATCH',
        data: { briefId, starred },
        successAction: creatorChanged,
        entity: RESOURCES.CREATOR_STARRED,
      }),
    );
  };

  const getBriefCreatorsShippingInfo = ({
    briefId,
    status,
    onSuccess,
  }: {
    briefId: number;
    status: UserBriefStatus;
    onSuccess: (creatorsShippingInfo) => void;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.GET_BRIEF_CREATORS_SHIPPING_INFO,
          variables: {
            briefId,
          },
        },
        params: { status },
        method: 'GET',
        onSuccess,
        entity: RESOURCES.CREATORS_SHIPPING_INFO,
      }),
    );
  };

  return {
    creators,
    creatorResultsCount,
    creatorTotalCount,
    loading,
    singleLoading,
    deadlineChangeLoading,
    approveCreator,
    rejectCreator,
    fetchBriefCreators,
    queryCreators,
    setCreatorBriefDeadline,
    setShippingLink,
    deleteCreatorBriefDeadline,
    approveCreatorVetting,
    rejectCreatorVetting,
    setCreatorBriefStarred,
    starredChangeLoading,
    getBriefCreatorsShippingInfo,
    getCreatorsShippingInfoLoading,
  };
};

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.btn-input': {
    '&__option, &__option:not(:disabled):focus': {
      border: `1px solid ${COLORS.BLACK}`,
      borderWidth: '1px',
      borderRadius: '4px',
      width: 'fit-content',
      backgroundColor: COLORS.WHITE,
    },
    '&__option--selected, &__option--selected:not(:disabled):focus': {
      backgroundColor: COLORS.PRIMARY500,
      color: COLORS.WHITE,
      border: `1px solid ${COLORS.PRIMARY500}`,
    },
    '&__option--left': {
      textAlign: 'left',
    },
  },
});

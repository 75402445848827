import { passwordConfirmationSchema, passwordValidationSchema } from 'shared/components/inputs/PasswordInput/config';
import { CONSTRAINTS, MESSAGES } from 'shared/config/validations';
import * as yup from 'yup';

export const getSignUpSchema = (invited: boolean) =>
  yup.object({
    firstName: yup
      .string()
      .trim()
      .matches(CONSTRAINTS.NO_SPECIAL_CHARACTERS_CONSTRAINT, {
        message: MESSAGES.NO_SPECIAL_CHARACTERS,
      })
      .required(MESSAGES.REQUIRED_FIELD),
    lastName: yup
      .string()
      .trim()
      .matches(CONSTRAINTS.NO_SPECIAL_CHARACTERS_CONSTRAINT, {
        message: MESSAGES.NO_SPECIAL_CHARACTERS,
      })
      .required(MESSAGES.REQUIRED_FIELD),
    email: invited
      ? yup.string().trim().email(MESSAGES.EMAIL)
      : yup.string().trim().email(MESSAGES.EMAIL).required(MESSAGES.REQUIRED_FIELD),
    password: passwordValidationSchema,
    confirmPassword: passwordConfirmationSchema,
  });

export const SIGN_UP_FIELDNAMES = {
  FIRST_NAME: 'firstName' as const,
  LAST_NAME: 'lastName' as const,
  EMAIL: 'email' as const,
  PASSWORD: 'password' as const,
  CONFIRM_PASSWORD: 'confirmPassword' as const,
  TERMS: 'termsAgreement' as const,
};

export const SIGN_UP_FORM_NAME = 'signUpForm';

export interface SignUpValues {
  [SIGN_UP_FIELDNAMES.EMAIL]: string;
  [SIGN_UP_FIELDNAMES.FIRST_NAME]: string;
  [SIGN_UP_FIELDNAMES.LAST_NAME]: string;
  [SIGN_UP_FIELDNAMES.TERMS]?: boolean;
  [SIGN_UP_FIELDNAMES.CONFIRM_PASSWORD]: string;
  [SIGN_UP_FIELDNAMES.PASSWORD]: {
    password: string;
    score: number;
    message: string;
  };
}

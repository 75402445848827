import React, { forwardRef } from 'react';
import classNames from 'classnames';

import { Box } from 'shared/components/display';

import { Ref } from 'shared/typings/react';

import styles from './styles';

interface Props {
  onFocus?: () => any;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: any;
  disabled?: boolean;
  maxLength?: number;
  width?: string;
  noMargin?: boolean;
  submitted?: boolean;
  showCharactersCounter?: boolean;
  value?: string;
  type: string;
  min?: number;
  max?: number;
  inputMode?: 'numeric';
  className?: string;
  placeholder?: string;
  name?: string;
  dataCy?: string;
  onKeyPress?: (e: any) => void;
}

const UnderlineInput = forwardRef<Ref, Props>((props, ref) => {
  const {
    width,
    className,
    noMargin,
    submitted,
    value = '',
    maxLength = 0,
    showCharactersCounter = false,
    placeholder,
    name,
    dataCy,
  } = props;

  return (
    <Box css={styles}>
      <Box
        {...props}
        ref={ref}
        as="input"
        className={classNames('underline-input', {
          'underline-input--no-margin': noMargin,
          'underline-input--submitted': submitted,
          className,
        })}
        width={`${width || '100%'}`}
        placeholder={placeholder}
        name={name}
        data-cy={dataCy}
      />
      {showCharactersCounter && (
        <p className="underline-input__text-counter">{`${maxLength - value.length} characters remaining`}</p>
      )}
    </Box>
  );
});

export default UnderlineInput;

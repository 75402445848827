import React from 'react';
import { Text } from 'rebass/styled-components';

import BriefsTable from 'features/briefs/BriefsTable';
import briefCardsPaginationStyles from 'features/briefs/components/BriefCardsPagination/briefCardsPaginationStyles';
import NewBriefButton from 'features/briefs/NewBriefButton/NewBriefButton';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Flex } from 'shared/components/display';
import Pagination from 'shared/components/organisms/Pagination';

import { BRIEF_COPY } from 'shared/config/copy';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Brief } from 'shared/typings/briefs';

const { BODY } = TEXT_VARIANTS;

interface Props {
  loading: boolean;
  canCreateBrief?: boolean;
  briefs: Brief[];
  totalBriefs: number;
  searchParams: any;
  organizationId: number;
  accountId: number;
}
const BriefCardsPagination: React.FC<Props> = ({
  loading,
  canCreateBrief = false,
  briefs,
  totalBriefs,
  searchParams,
  organizationId,
  accountId,
}) => {
  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <Flex css={briefCardsPaginationStyles} className="brief-cards">
      <Pagination
        totalAmount={totalBriefs}
        searchParams={searchParams}
        pageSizeBase={10}
        className="brief-cards__pagination"
      >
        {briefs.length ? (
          <BriefsTable data={briefs} />
        ) : (
          <div className={CLASSNAMES.MAIN_CONTAINER}>
            <Text variant={BODY}>{BRIEF_COPY.PLACEHOLDER_NO_BRIEFS}</Text>
          </div>
        )}
      </Pagination>
      {canCreateBrief && (
        <NewBriefButton
          organizationId={organizationId}
          accountId={accountId}
          className="brief-cards__new-brief"
          buttonClassName="brief-cards__new-brief-btn"
        />
      )}
    </Flex>
  );
};

export default BriefCardsPagination;

import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';
import { isEmpty, isEqual, startCase } from 'lodash';

import profileModalStyles from 'features/users/components/ProfileModalForm/profileModalStyles';
import {
  PROFILE_MODAL_FIELDNAMES,
  profileModalSchema,
} from 'features/users/components/ProfileModalForm/profileModalValidations';
import ProfilePictureWithDropdown from 'features/users/components/ProfilePictureWithDropdown/ProfilePictureWithDropdown';
import { useUser } from 'features/users/hooks/useUser';
import { Box, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import Modal from 'shared/components/molecules/Modal';
import SocialMediaHandleInput from 'shared/components/SocialMediaHandleInput/SocialMediaHandleInput';
import TextareaInput from 'shared/components/TextareaInput/TextareaInput';
import TextInput from 'shared/components/TextInput';

import { USER_COPY } from 'shared/config/copy';
import { SIZING } from 'shared/config/formatting';
import { USER_TOASTS } from 'shared/config/toasts/userToasts';
import { intl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
interface Props {
  userId: number;
}

const ProfileModalForm: React.FC<Props> = ({ userId }) => {
  const MAX_SUMMARY_LENGTH = 160;

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const { user, updateUser, saveProfilePicture, removeProfilePicture } = useUser(userId);
  const { formatMessage } = useIntl();

  const defaultValues = useMemo(() => {
    return {
      [PROFILE_MODAL_FIELDNAMES.SUMMARY]: user.summary || '',
      [PROFILE_MODAL_FIELDNAMES.PORTFOLIO_LINK]: user.portfolioLink || '',
      [PROFILE_MODAL_FIELDNAMES.PROFILE_PICTURE]: user.profilePic || {},
      [PROFILE_MODAL_FIELDNAMES.TIKTOK]: user.socialMediaInfo?.tiktok,
    };
  }, [user.summary, user.creatorTags, user.profilePic, user.portfolioLink]);

  const updateUserProfile = ({ summary, portfolioLink, tiktok }) => {
    if (summary !== user.summary || portfolioLink !== user.portfolioLink || tiktok !== user.socialMediaInfo?.tiktok) {
      updateUser({
        data: {
          id: user.id,
          summary,
          portfolioLink,
          tiktok,
        },
        successToast: {
          message: USER_TOASTS.MOBILE_UPDATED,
        },
        onSuccess: toggleModal,
      });
    } else {
      toggleModal();
    }
  };

  const onSubmit = (data) => {
    const { profilePic } = data;
    if (!isEmpty(profilePic)) {
      if (profilePic.removePicture) {
        removeProfilePicture();
      } else if (!isEqual(profilePic, user.profilePic)) {
        saveProfilePicture(profilePic);
      }
    }
    updateUserProfile(data);
  };

  return (
    <Box className="profile-modal" css={profileModalStyles}>
      <Button variant={BUTTON_VARIANTS.MICRO} onClick={toggleModal} className="profile-modal__btn">
        {USER_COPY.BUTTON_EDIT_USER}
      </Button>

      <Modal
        onRequestClose={toggleModal}
        component={
          <Formik onSubmit={onSubmit} initialValues={defaultValues} validationSchema={profileModalSchema}>
            {(formikProps) => (
              <Box className="profile-modal" css={profileModalStyles}>
                <Form>
                  <Field
                    name={PROFILE_MODAL_FIELDNAMES.PROFILE_PICTURE}
                    component={ProfilePictureWithDropdown}
                    userId={userId}
                  />
                  <Text
                    as="label"
                    variant={TEXT_VARIANTS.SUBHEADING}
                    htmlFor={PROFILE_MODAL_FIELDNAMES.PORTFOLIO_LINK}
                    className="profile-modal__label"
                  >
                    {USER_COPY.FORM_LABEL_PORTFOLIO}
                  </Text>
                  <Field
                    name={PROFILE_MODAL_FIELDNAMES.PORTFOLIO_LINK}
                    id={PROFILE_MODAL_FIELDNAMES.PORTFOLIO_LINK}
                    maxLength={512}
                    component={TextInput}
                    placeholder={formatMessage(
                      {
                        id: 'PLACEHOLDER_PROFILE',
                      },
                      { field: USER_COPY.FORM_LABEL_PORTFOLIO },
                    )}
                  />
                  <Text
                    as="label"
                    variant={TEXT_VARIANTS.SUBHEADING}
                    htmlFor={PROFILE_MODAL_FIELDNAMES.SUMMARY}
                    className="profile-modal__label"
                  >
                    {USER_COPY.FORM_LABEL_SUMMARY}
                  </Text>
                  <Field
                    name={PROFILE_MODAL_FIELDNAMES.SUMMARY}
                    id={PROFILE_MODAL_FIELDNAMES.SUMMARY}
                    component={TextareaInput}
                    maxLength={MAX_SUMMARY_LENGTH}
                    showCharactersCounter
                    softMaxLength={MAX_SUMMARY_LENGTH}
                    className="profile-modal__summary-field"
                    placeholder={intl.formatMessage(
                      {
                        id: 'PLACEHOLDER_PROFILE',
                      },
                      {
                        field: startCase(PROFILE_MODAL_FIELDNAMES.SUMMARY),
                      },
                    )}
                  />
                  <Text
                    as="label"
                    variant={TEXT_VARIANTS.SUBHEADING}
                    htmlFor={PROFILE_MODAL_FIELDNAMES.TIKTOK}
                    className="profile-modal__label"
                  >
                    {USER_COPY.FORM_LABEL_TIKTOK}
                  </Text>
                  <Field
                    name={PROFILE_MODAL_FIELDNAMES.TIKTOK}
                    id={PROFILE_MODAL_FIELDNAMES.TIKTOK}
                    component={SocialMediaHandleInput}
                    placeholder="tiktok_handle"
                  />
                  <FormButton className="profile-modal__submit-btn">{USER_COPY.BUTTON_SAVE_CHANGES}</FormButton>
                </Form>
              </Box>
            )}
          </Formik>
        }
        modalSize={SIZING.MEDIUM}
        title={USER_COPY.MODAL_HEADING_PROFILE}
        isOpen={modalOpen}
      />
    </Box>
  );
};

export default ProfileModalForm;

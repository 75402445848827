export const PERFORMANCE_DATE_PRESETS = [
  'Today',
  'Yesterday',
  'Last 7 Days',
  'Last 14 Days',
  'Last 30 Days',
  'This Week',
  'Last Week',
  'This Month',
  'Last Month',
];

import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuth } from 'features/auth/useAuth';
import { useQuery } from 'shared/hooks/useQuery';
import { useToasts } from 'shared/hooks/useToasts';

import { decryptString } from 'shared/utilities/encryptionUtility';

const FacebookOauthLogin = () => {
  const { getUserByToken } = useAuth();
  const { setErrorToast } = useToasts();
  const { getQuery } = useQuery();

  const urlParams = getQuery();

  const facebookLogin = () => {
    const { redirectURI = '', token, message } = urlParams;
    if (token) {
      const decryptedURI = decryptString(decodeURIComponent(redirectURI));
      getUserByToken(token, decryptedURI);
    } else if (message) {
      setErrorToast({ message });
    }
  };

  useEffect(() => {
    facebookLogin();
  }, [urlParams]);

  return <Navigate to="/" />;
};

export default FacebookOauthLogin;

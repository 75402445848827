import React from 'react';
import { components, OptionProps } from 'react-select';
import { GeoLocationSuggestion } from 'shared/hooks/useGeoLocationHook';

const Option: React.FC<OptionProps<GeoLocationSuggestion, false>> = (props) => {
  return (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        // @ts-ignore
        'data-cy': 'address-search__option',
      }}
    />
  );
};

export default React.memo(Option);

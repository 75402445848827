import React from 'react';
import cs from 'classnames';
import { Flex } from 'shared/components/display';
import styles from './styles';
import Tooltip from 'shared/components/atoms/Tooltip';
import { COLLECTION_COPY } from 'shared/config/copy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faTag } from '@fortawesome/pro-solid-svg-icons';
import { useGalleryListView, VIEWS } from 'shared/hooks/useGalleryListView';
import { BrkfstFile } from 'shared/typings/file';

interface Props {
  file: Pick<BrkfstFile, 'facebookAssetAPNames' | 'tiktokAssetAPNames' | 'tags'>;
  showTags?: boolean;
}
const MarketerFileOverlayIcons: React.FC<Props> = ({ file, showTags = false }) => {
  const { viewMode } = useGalleryListView();
  return (
    <Flex
      css={styles}
      className={cs('marketer-file-overlay-icons', {
        'marketer-file-overlay-icons--gallery': viewMode === VIEWS.GALLERY.value,
        'marketer-file-overlay-icons--list': viewMode === VIEWS.LIST.value,
      })}
    >
      {file.facebookAssetAPNames && (
        <Tooltip content={COLLECTION_COPY.LABEL_FB_LIBRARY}>
          <FontAwesomeIcon className="marketer-file-overlay-icons__icon" icon={faFacebook} />
        </Tooltip>
      )}
      {file.tiktokAssetAPNames && (
        <Tooltip content={COLLECTION_COPY.LABEL_TIKTOK_LIBRARY}>
          <FontAwesomeIcon className="marketer-file-overlay-icons__icon" icon={faTiktok} />
        </Tooltip>
      )}

      {Boolean(file.tags?.length) && showTags && (
        <Tooltip content={COLLECTION_COPY.LABEL_TAGS}>
          <FontAwesomeIcon className="marketer-file-overlay-icons__icon" icon={faTag} />
        </Tooltip>
      )}
    </Flex>
  );
};

export default MarketerFileOverlayIcons;

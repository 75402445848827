import { useContext, useEffect } from 'react';

import { SignalRContext } from 'shared/contexts/signalr';
import { BrkfstFile } from 'shared/typings/file';

interface ThumbnailParams {
  onThumbnailUploaded: (file: { id: number; thumbnailUrl: string; thumbnailOffset: number }) => void;
}

export const SIGNALR_NOTIFICATION_TIME_SPAN = 3500; // milliseconds

export const useSubmissionThumbnailNotification = ({ onThumbnailUploaded }: ThumbnailParams): void => {
  const { connection } = useContext(SignalRContext);
  useEffect(() => {
    const thumbnailHandler = ({ fileId: id, thumbnailUrl, thumbnailOffset }) => {
      onThumbnailUploaded({ id, thumbnailUrl, thumbnailOffset });
    };

    if (connection) connection.on('newThumbnail', thumbnailHandler);

    return function cleanup() {
      connection?.off('newThumbnail', thumbnailHandler);
    };
  }, [connection, onThumbnailUploaded]);
};

interface FileParams {
  onFileUploaded: (file: BrkfstFile) => void;
}

export const useSubmissionFileNotification = ({ onFileUploaded }: FileParams): void => {
  const { connection } = useContext(SignalRContext);
  useEffect(() => {
    const fileHandler = (file) => {
      if (onFileUploaded) onFileUploaded(file);
    };

    if (connection) connection.on('newFile', fileHandler);

    return function cleanup() {
      connection?.off('newFile', fileHandler);
    };
  }, [connection, onFileUploaded]);
};

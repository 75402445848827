import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

interface Props<Values> {
  defaultValue: Partial<Values>;
}
const SetActiveDefaults = <Values extends {}>({ defaultValue }: Props<Values>) => {
  // If you want to set values that should be active by default, set them here because initialValues are used for comparison to determine if a field is active and to reset the form etc.
  // kinda hacky :/ but it works
  const formikProps = useFormikContext<Values>();
  useEffect(() => {
    formikProps.setValues({
      ...formikProps.values,
      ...defaultValue,
    });
  }, []);

  return <></>;
};

export default SetActiveDefaults;

import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '.review-form': {
    '&__submit-btn': {
      margin: `${theme.space[4]} 0`,
    },
    '&__textarea textarea': {
      maxHeight: '480px',
      minHeight: '35px',
      [mediaquery.md]: {
        maxHeight: '500px',
      },
    },
  },
});

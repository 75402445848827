import React from 'react';
import * as yup from 'yup';

import { Box, Text } from 'shared/components/display';
import { StepFieldProps } from 'shared/components/forms/MultiStepForm/interfaces';
import ButtonGroupInput, { Option } from 'shared/components/molecules/ButtonGroupInput/ButtonGroupInput';
import { buttonGroupSchema } from 'shared/components/molecules/ButtonGroupInput/validation';

import { CREATOR_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

export const LocationFormFields: React.FC<StepFieldProps> = ({ className }) => {
  return (
    <Box css={styles}>
      <Text variant={TEXT_VARIANTS.BODY} className={className}>
        {CREATOR_COPY.CAPTION_MULTIPLE_OPTIONS}
      </Text>
      <ButtonGroupInput name={LOCATION_FIELDNAMES.LOCATIONS} isMultiSelect />
    </Box>
  );
};

export const LOCATION_FIELDNAMES = {
  LOCATIONS: 'filmingBackdrop',
};

export const locationSchema = yup.object({
  filmingBackdrop: buttonGroupSchema,
});

export type LocationValues = {
  filmingBackdrop: Option[];
};

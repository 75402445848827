import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import CreatorBriefView from 'features/briefs/components/molecules/CreatorBriefView';
import MobileBriefCreatorButtons from 'features/briefs/components/molecules/MobileBriefCreatorButtons';
import SubmissionView from 'features/submissions/components/SubmissionView/SubmissionView';
import { useSubmissions } from 'features/submissions/useSubmissions';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import ResponsiveDisplay from 'shared/components/atoms/ResponsiveDisplay';
import { Box, Flex } from 'shared/components/display';
import { Tab, TabBar } from 'shared/components/molecules/Tabs';

import { SUBMISSION_COPY } from 'shared/config/copy';
import { Brief } from 'shared/typings/briefs';

import styles from './styles';

interface Props {
  brief?: Brief | null;
  loading?: boolean;
}
const CreatorSubmissionTabs: React.FC<Props> = ({ brief, loading }) => {
  const [tabHeight, setTabHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const submissionId = brief?.briefCreatorSubmission?.id;
  const {
    fetchSingleSubmission,
    submission,
    loading: loadingSubmission,
  } = useSubmissions({
    submissionId,
    loadingStartingState: false,
  });

  useEffect(() => {
    if (submissionId) fetchSingleSubmission({ submissionId });
  }, [submissionId]);

  useLayoutEffect(() => {
    if (ref.current && setTabHeight) {
      setTabHeight(ref.current.clientHeight);
    }
  }, [ref.current?.clientHeight, setTabHeight]);

  if (loading || loadingSubmission) {
    return <CenteredSpinner />;
  }

  return (
    <Box css={styles(tabHeight)} className="creator-submission-tabs">
      <TabBar>
        <Tab title={SUBMISSION_COPY.TAB_TITLE_SUBMISSION_BRIEF}>
          {brief ? <CreatorBriefView currentBrief={brief} /> : <></>}
        </Tab>
        <Tab title={SUBMISSION_COPY.TAB_TITLE_SUBMISSION} hidden={!submission}>
          {submission && <SubmissionView submission={submission} />}
        </Tab>
      </TabBar>
      <ResponsiveDisplay display="mobile">
        <Flex className="creator-submission-tabs__creator-buttons" ref={ref}>
          {brief ? <MobileBriefCreatorButtons brief={brief} /> : <></>}
        </Flex>
      </ResponsiveDisplay>
    </Box>
  );
};

export default CreatorSubmissionTabs;

import React, { useEffect, useState } from 'react';
import { Text } from 'shared/components/display';
import { UploadFile } from 'features/ui/ui.slice';
import { FileUploadStatus } from 'shared/typings/file/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle as abortIcon } from '@fortawesome/pro-light-svg-icons';
import { SIGNALR_NOTIFICATION_TIME_SPAN } from 'shared/hooks/useFileNotification';
import { TEXT_VARIANTS } from 'shared/styles/text';
import COLORS from 'shared/styles/colors';
import { FILE_COPY } from 'shared/config/copy';

interface Props {
  file: Pick<UploadFile, 'status'>;
  updateFileUpload: (id: string, fileUploadStatus: FileUploadStatus, errors?: string | undefined) => void;
  files: UploadFile[];
}
const AbortStatus: React.FC<Props> = ({ file, updateFileUpload, files }) => {
  const [timeOutIds, setTimeoutIds] = useState([]);

  const onAbort = async (file) => {
    await file.abortController.abort();
    updateFileUpload(file.id, FileUploadStatus.UPLOAD_ATTEMPTING_CANCEL);
    const timeoutId = setTimeout(async () => {
      const fileToAbort = files.find((fileOp) => fileOp.id === file.id);
      if (fileToAbort?.status !== FileUploadStatus.UPLOADED) {
        updateFileUpload(file.id, FileUploadStatus.UPLOAD_CANCELLED);
      }
    }, SIGNALR_NOTIFICATION_TIME_SPAN);

    // @ts-ignore
    setTimeoutIds((prev) => [...prev, timeoutId]);
  };

  const clearAbortTimeouts = () => {
    timeOutIds.forEach((id) => clearTimeout(id));
  };

  useEffect(() => {
    return () => {
      if (timeOutIds.length) clearAbortTimeouts();
    };
  }, []);

  switch (file.status) {
    case FileUploadStatus.UPLOADING:
      return (
        <FontAwesomeIcon
          icon={abortIcon}
          className="upload-status-drawer__abort-upload-icon"
          onClick={() => onAbort(file)}
        />
      );
    case FileUploadStatus.UPLOAD_ATTEMPTING_CANCEL:
      return (
        <Text
          as="p"
          variant={TEXT_VARIANTS.BODY}
          className="upload-status-drawer__upload-cancelled-label"
          color={COLORS.NEUTRAL600}
        >
          {FILE_COPY.WARNING_UPLOAD_ATTEMPTING_CANCEL}
        </Text>
      );
    case FileUploadStatus.UPLOAD_CANCELLED:
      return (
        <Text
          as="p"
          variant={TEXT_VARIANTS.BODY}
          className="upload-status-drawer__upload-cancelled-label"
          color={COLORS.NEUTRAL600}
        >
          {FILE_COPY.WARNING_UPLOAD_CANCELLED}
        </Text>
      );

    default:
      return null;
  }
};

export default AbortStatus;

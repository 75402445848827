import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.auth-page': {
    '&__switch-form-wrapper': {
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginTop: theme.space[7],
    },
    '&__or-separator': {
      marginTop: `${theme.space[5]}`,
      marginBottom: `${theme.space[3]}`,
      color: COLORS.NEUTRAL300,
      textAlign: 'center',
    },
    '&__switch-btn': {
      padding: `${theme.space[1]} ${theme.space[3]}`,
      fontSize: theme.fontSizes[0],
    },
    '&__question': {
      margin: `${theme.space[2]} ${theme.space[5]} ${theme.space[0]} 0`,
    },
    '&__oauth-btn-wrapper': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
});

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ProfilePanel from 'features/users/components/molecules/ProfilePanel';
import TTVideos from 'features/users/components/molecules/TTVideos';
import ExternalLink from 'shared/components/atoms/ExternalLink/ExternalLink';
import { Box, Flex, Text } from 'shared/components/display';

import { CREATOR_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

import tiktokVideosPanelStyles from './tiktokVideosPanelStyles';

const { OVERLINE } = TEXT_VARIANTS;

const TT_URL = 'https://www.tiktok.com/@';

interface Props {
  user: BrkfstUser;
  isCurrentUser: boolean;
}

const TTVideosPanel: React.FC<Props> = ({ user, isCurrentUser }) => {
  const { tiktokVideos = [], tiktokUsername } = user.tiktokProfile || {};

  const placeholderText = useMemo(() => {
    if (user.tiktokProfile && !tiktokVideos.length) {
      return CREATOR_COPY.PLACEHOLDER_NO_TT_VIDEOS;
    } else {
      return isCurrentUser ? CREATOR_COPY.PLACEHOLDER_CONNECT_TIKTOK : CREATOR_COPY.PLACEHOLDER_NO_TIKTOK;
    }
  }, [isCurrentUser, user.tiktokProfile, tiktokVideos.length]);

  return (
    <Box css={tiktokVideosPanelStyles} className="tt-videos-panel">
      <ProfilePanel
        HeaderComponent={
          <Flex className="tt-videos-panel__header">
            {CREATOR_COPY.HEADER_TT_VIDEOS}
            {tiktokUsername && (
              <ExternalLink
                href={`${TT_URL}${tiktokUsername}`}
                text={
                  tiktokUsername?.trim()?.length > 0 && (
                    <Flex className="tt-videos-panel__username">
                      <FontAwesomeIcon icon={faTiktok} />
                      {tiktokUsername}
                    </Flex>
                  )
                }
              />
            )}
          </Flex>
        }
        className="tt-videos-panel"
      >
        <TTVideos tiktokVideos={tiktokVideos} placeholderText={placeholderText} creatorId={user.id} />
        <Flex className="tt-videos-panel__link-wrapper">
          {!user.tiktokProfile && isCurrentUser && (
            <Link
              to={LinkCreator.createLink({
                userType: UserType.CREATOR,
                routeKey: 'ACCOUNT_SETTINGS',
                variables: {
                  userId: user.id,
                },
              })}
            >
              <Text variant={OVERLINE} className="tt-videos-panel__link-text">
                {CREATOR_COPY.LINK_CONNECT_TIKTOK}
              </Text>
            </Link>
          )}
        </Flex>
      </ProfilePanel>
    </Box>
  );
};

export default TTVideosPanel;

import React, { useState, useEffect } from 'react';
import { Text, Box, Flex } from 'shared/components/display';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { noOp } from 'shared/defaults';
import cs from 'classnames';
import styles from './styles';

const { BODY } = TEXT_VARIANTS;

interface Props {
  isMini?: boolean;
  label: string;
  toggledOn?: boolean;
  onClick?: React.ChangeEventHandler<HTMLInputElement>;
  fieldName?: string;
  labelVariant?: string;
  className?: string;
  width?: string;
  rightAlign?: boolean;
}

const ToggleSwitch: React.FC<Props> = ({
  isMini = false,
  label,
  toggledOn = false,
  onClick = noOp,
  fieldName = '',
  labelVariant = BODY,
  className = '',
  width = '',
  rightAlign = false,
}) => {
  const [toggled, setToggled] = useState(toggledOn);

  useEffect(() => {
    setToggled(toggledOn);
  }, [toggledOn]);

  return (
    <Flex
      css={styles}
      className={cs(className, 'toggle-switch', { 'right-align': rightAlign })}
      style={{ width: width }}
    >
      <Text className={cs('toggle-switch__label', { 'toggle-switch__label--mini': isMini })} variant={labelVariant}>
        {label}
      </Text>
      <Box
        className={cs('toggle-switch__wrapper', {
          'toggle-switch__wrapper--mini': isMini,
        })}
      >
        <input
          type="checkbox"
          className={cs('toggle-switch__input', { 'toggle-switch__input--mini': isMini })}
          name={fieldName}
          checked={toggled}
          data-cy={`toggle-switch__input--${fieldName}`}
          onChange={(e) => {
            onClick(e);
            e.currentTarget.blur();
          }}
        />
        <Box
          as="span"
          className={cs('toggle-switch__slider', {
            'toggle-switch__slider--mini': isMini,
          })}
        />
      </Box>
    </Flex>
  );
};

export default ToggleSwitch;

import * as yup from 'yup';

import { TRENDS_LIBRARY_COPY } from 'features/trends/pages/TrendsLibrary/copy';

export const DEFAULT_VALUE = {
  name: '',
  size: 0,
  id: 0,
};

export const trendFileInputSchema = yup
  .object({
    name: yup.string(),
  })
  .test({
    name: 'required',
    message: TRENDS_LIBRARY_COPY.VALIDATION_TREND_FILE_REQUIRED,
    test(val) {
      return Boolean(val?.name);
    },
  });

import { useDispatch, useSelector } from 'react-redux';

import {
  filesCleared,
  filesLoaded,
  getFiles,
  getPinnedCount,
  getTotalNumFiles,
  pinnedCountLoaded,
  fileUpdated as fileUpdatedAction,
  pinnedCountUpdated,
} from 'features/media/files.slice';
import { componentLoadingToggled } from 'features/ui/ui.slice';

import { apiAction } from 'shared/actions/api';
import { API_FILE_ROUTES } from 'shared/config/routes/api/apiFileRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useMemo } from 'react';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { MEDIA } from 'shared/config/media';
import { BrkfstFile } from 'shared/typings/file';

const ENTITY = 'showcaseAssets';
const PINNED_ENTITY = 'pinnedAssets';

export const useShowcaseAssets = () => {
  const dispatch = useDispatch();
  const files = useSelector(getFiles);
  const totalFiles = useSelector(getTotalNumFiles);
  const pinnedCount = useSelector(getPinnedCount);

  const { loading } = useComponentLoading(ENTITY, true);
  const { loading: pinnedLoading } = useComponentLoading(PINNED_ENTITY, true);

  const toggleLoading = (loading: boolean) => {
    dispatch(componentLoadingToggled({ component: ENTITY, loading }));
  };

  const getCreatorApprovedAssets = (
    creatorId: number,
    filter: {
      page?: number;
      size?: number;
      name?: string;
    },
  ) => {
    dispatch(
      apiAction({
        path: {
          route: API_FILE_ROUTES.APPROVED_ASSETS,
          variables: {
            creatorId,
          },
        },
        params: filter,
        successAction: filesLoaded,
        entity: ENTITY,
      }),
    );
  };

  const loadPinnedCount = (creatorId: number) => {
    dispatch(
      apiAction({
        path: {
          route: API_FILE_ROUTES.PINNED_ASSETS_COUNT,
          variables: {
            creatorId,
          },
        },
        successAction: pinnedCountLoaded,
        entity: PINNED_ENTITY,
      }),
    );
  };

  const clearAssets = () => {
    dispatch(filesCleared({}));
  };

  const onPinSuccess = (file: Pick<BrkfstFile, 'id' | 'isPinned'>) => {
    dispatch(
      fileUpdatedAction({
        data: {
          id: file.id,
          isPinned: !file.isPinned,
        },
      }),
    );

    dispatch(pinnedCountUpdated(file.isPinned ? -1 : 1));
  };

  const pinFile = (file: Pick<BrkfstFile, 'id' | 'isPinned'>, value: boolean) => {
    dispatch(
      apiAction({
        path: {
          route: API_FILE_ROUTES.PIN_FILE,
          variables: {
            fileId: file.id,
          },
        },
        method: 'POST',
        data: {
          pinned: value,
        },
        onSuccess: () => {
          onPinSuccess(file);
        },
      }),
    );
  };

  return {
    clearAssets,
    files,
    getCreatorApprovedAssets,
    loading,
    loadPinnedCount,
    maxPinLimitReached: pinnedCount >= MEDIA.MAX_PINNED_FILES,
    pinFile,
    pinnedLoading,
    toggleLoading,
    totalFiles,
  };
};

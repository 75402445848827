import { faPencilAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AudienceFile from 'features/accountPlatforms/facebook/AudienceFile';
import React from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'rebass/styled-components';
import { GLOBAL_COPY_VARIABLES } from 'shared/config/copy/allCopy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { Flex, Text } from 'shared/components/display';
import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';

const ReuploadButton = ({ facebookAudience, show, onClick }) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Text className="edit-audience__text">
        {`${formatMessage({ id: 'AUDIENCE_DESCRIPTION' }, { appName: GLOBAL_COPY_VARIABLES.APP_NAME })}`}
      </Text>
      <Text className="edit-audience__text">{ACCOUNT_PLATFORM_COPY.AUDIENCE_TEMPLATE}</Text>
      {facebookAudience && (
        <Flex className="edit-audience__audience-file">
          <AudienceFile filename={facebookAudience.name} date={facebookAudience.dateUpdated} />
          {!show && (
            <Button onClick={onClick} variant={BUTTON_VARIANTS.TEXT_ONLY}>
              <FontAwesomeIcon icon={faPencilAlt} color={COLORS.NEUTRAL600} />
            </Button>
          )}
        </Flex>
      )}
    </>
  );
};

export default ReuploadButton;

import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { BrkfstRole } from 'shared/typings/role';

interface RolePayload<T = RoleState> {
  data: T;
  requestData: T;
}

type RoleState = BrkfstRole[];

const rolesSlice = createSlice<RoleState, SliceCaseReducers<RoleState>, 'roles'>({
  name: 'roles',
  initialState: [],
  reducers: {
    rolesLoaded: (roles, action: PayloadAction<RolePayload<{ data: RoleState }>>) => action.payload.data.data,

    roleAdded: (roles, action: PayloadAction<RolePayload<BrkfstRole>>) => {
      roles.push(action.payload.data);
    },
    roleRemoved: (roles, action: PayloadAction<RolePayload<Pick<BrkfstRole, 'id'>>>) =>
      roles.filter((role) => role.id !== action.payload.data.id),

    roleChanged: (roles, action: PayloadAction<RolePayload<BrkfstRole>>) =>
      roles.map((role) => (role.id === action.payload.data.id ? { ...role, ...action.payload.data } : role)),
  },
});

export const { roleAdded, rolesLoaded, roleRemoved, roleChanged } = rolesSlice.actions;

interface StoreState {
  roles: RoleState;
}

export const getRoles = (state: StoreState) => state.roles;
export const getRole = (id: number) => (state: StoreState) => {
  return state.roles.find((role) => role.id === id);
};
export const getRoleCount = (state: StoreState) => state.roles.length;

export default rolesSlice.reducer;

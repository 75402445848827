import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import { STYLES } from 'shared/styles/styles';
import theme from 'shared/styles/themes';

export default (creatorButtonsHeight: number) =>
  css({
    height: '100%',
    '.individual-brief': {
      height: '100%',
      flexDirection: 'column',
      padding: theme.space[5],
      '&__top-bar': {
        backgroundColor: 'white',
        alignItems: 'center',
        border: STYLES.BORDER_NEUTRAL,
        padding: theme.space[3],
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        [mediaquery.md]: {
          flexWrap: 'unset',
        },
      },
      '&__top-bar .brief-header__name': {
        textAlign: 'center',
        fontSize: theme.space[5],
        paddingBottom: theme.space[6],
        paddingTop: theme.space[4],
        [mediaquery.md]: {
          padding: `0 ${theme.space[7]}`,
          fontSize: theme.space[6],
        },
      },

      '&__body': {
        // 100px to cover height of tabs
        height: `calc(100%  - (${creatorButtonsHeight}px + 100px))`,
        [mediaquery.md]: {
          height: '90%',
        },
      },
    },
    '.brief-view__content': {
      backgroundColor: COLORS.WHITE,
      [mediaquery.md]: {
        margin: `${theme.space[6]} auto`,
        boxShadow: theme.shadows.SHADOW06,
        maxWidth: '90%',
      },
    },
    '.individual-brief .submissions, .individual-brief .brief-creators': {
      [mediaquery.md]: {
        margin: `${theme.space[6]} auto`,
        boxShadow: theme.shadows.SHADOW06,
        maxWidth: '90%',
      },
    },
    '.tab-bar': {
      overflowY: 'auto',
      border: STYLES.BORDER_NEUTRAL,
      borderTop: 'none',
    },
    '.tab-bar__content, .tab-bar__content .brief-view': {
      [mediaquery.md]: {
        backgroundColor: COLORS.NEUTRAL100,
      },
    },
  });

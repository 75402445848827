import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Text } from 'rebass/styled-components';

import { Box } from 'shared/components/display';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import { SelectValue } from 'shared/components/molecules/Select';
import CheckoutPageTemplate from 'shared/components/organisms/CheckoutPageTemplate';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_PAYOUT_ROUTES } from 'shared/config/routes/api/apiPayoutRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LineItemType } from 'shared/typings/lineItem/enums';
import { BrkfstUser } from 'shared/typings/user';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { formatCurrency } from 'shared/utilities/stringUtility';

import { COPY } from './config';
import styles from './styles';

interface State {
  creator: BrkfstUser | null;
  amount: string;
  note: string;
  account: SelectValue<string> | null;
}

const PayoutCheckout = () => {
  const [state, setState] = useState<State>({
    creator: null,
    amount: '0',
    note: '',
    account: null,
  });
  const dispatch = useDispatch();
  const { loading } = useComponentLoading(RESOURCES.PAYOUT, false);
  const location = useLocation<State>();
  const navigate = useNavigate();

  const profileLink = useMemo(
    () =>
      state.creator
        ? LinkCreator.createLink({
            routeKey: 'USER_PROFILE',
            variables: {
              userId: state.creator.id,
            },
          })
        : '',
    [state.creator],
  );

  useEffect(() => {
    if (!location.state) {
      profileLink ? navigate(profileLink) : navigate(-1);
    } else {
      setState((prev) => ({
        ...prev,
        amount: location.state.amount,
        note: location.state.note,
        creator: location.state.creator,
        account: location.state.account,
      }));
    }
  }, [location.state, profileLink, history]);

  const lineItems = useMemo(() => {
    if (state.amount) {
      const currentDate = new Date();
      return [
        {
          amount: state.amount,
          quantity: 1,
          description: 'Creator Payout',
          startDate: currentDate,
          endDate: currentDate,
          type: LineItemType.CHARGE,
        },
      ];
    }
    return undefined;
  }, [state.amount]);

  const onSubmit = () => {
    const path = LinkCreator.createLink({
      routeKey: 'PAYOUT_CHECKOUT',
      variables: {
        userId: state.creator?.id,
      },
    });

    dispatch(
      apiAction({
        path: {
          route: API_PAYOUT_ROUTES.PAYOUTS,
        },
        method: 'POST',
        entity: RESOURCES.PAYOUT,
        data: {
          amount: Math.round(parseFloat(state.amount) * 100), // convert to cents
          accountId: state.account?.value,
          creatorId: state.creator?.id,
          note: state.note,
        },
        navigate,
        successToast: {
          message: COPY.SUCCESS_TOAST,
        },
        pushFunction: () => path,
      }),
    );
  };

  const creatorFullName = `${state.creator?.firstName || ''} ${state.creator?.lastName || ''}`;

  return (
    <CheckoutPageTemplate
      total={state.amount}
      lineItems={lineItems}
      backButtonProps={{
        text: COPY.BACK_BUTTON,
        to: profileLink,
        state: location.state,
      }}
      loading={loading}
      CheckoutComponent={
        <Box css={styles} className="payout-checkout">
          <Text variant={TEXT_VARIANTS.H3}>{COPY.HEADER}</Text>
          <Text className="payout-checkout__subheader" variant={TEXT_VARIANTS.H4}>
            {COPY.SUBHEADER}
          </Text>
          <DefinitionList labelColor={COLORS.BLACK}>
            <ListDetail text="Creator">{creatorFullName}</ListDetail>
            <ListDetail text="Brand">{state.account?.label || ''}</ListDetail>
            <ListDetail text="Payout">{formatCurrency(state.amount || 0)}</ListDetail>
            <ListDetail text="Note">{state.note || ''}</ListDetail>
          </DefinitionList>
        </Box>
      }
      onSubmit={onSubmit}
      onSubmitText={COPY.SUBMIT}
      leaveProcessingPageMessage={COPY.EXIT_PROMPT}
    />
  );
};

export default PayoutCheckout;

// * Lib
import React, { useEffect } from 'react';
import cs from 'classnames';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import CreativePerformanceTable from 'features/users/components/CreativePerformanceTable/CreativePerformanceTable';
// * Util
import { useCreatorPerformance } from 'features/users/hooks/useCreatorPerformance';
import { useQuery } from 'shared/hooks/useQuery';
//* Display/UI
import { Box } from 'shared/components/display';
import Pagination from 'shared/components/organisms/Pagination';

// * Styles
import styles from './styles';

const CreatorPerformance: React.FC = () => {
  const { creativePayouts, creativePayoutsTotal, searchParams, loading, getPayouts } = useCreatorPerformance();
  const { setQuery } = useQuery();

  const updateBriefIndex = (index: number) => {
    setQuery({ briefIndex: index });
  };

  useEffect(() => {
    getPayouts();
  }, [getPayouts]);

  return (
    <Box css={styles}>
      {loading ? (
        <CenteredSpinner />
      ) : (
        <Box
          className={cs({
            'creator-performance': true,
            'creator-performance--no-results': creativePayouts?.length === 0,
          })}
        >
          <Pagination
            totalAmount={creativePayoutsTotal}
            pageSizeBase={10}
            searchParams={searchParams}
            className="creator-performance__pagination"
          >
            <CreativePerformanceTable data={creativePayouts} updateBriefIndex={updateBriefIndex} />
          </Pagination>
        </Box>
      )}
    </Box>
  );
};

export default CreatorPerformance;

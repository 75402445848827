import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.switch-organizations': {
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.space[6],
  },
  '.switch-organizations': {
    '&__content-wrapper': {
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      width: '80%',
      marginBottom: theme.space[6],
    },
    '&__org-pin': {
      alignSelf: 'flex-start',
      transform: 'rotate(25deg)',
      position: 'absolute',
    },
    '&__org-wrapper': {
      margin: theme.space[3],
      paddingTop: theme.space[3],
      cursor: 'pointer',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '15px',
      backgroundColor: COLORS.TRANSPARENT,
      width: '100px',
      [mediaquery.md]: {
        width: '130px',
      },
    },
    '&__org-wrapper:hover': {
      backgroundColor: COLORS.NEUTRAL100,
    },
    '&__org-wrapper--current': {
      backgroundColor: COLORS.PRIMARY200,
    },
    '&__org-wrapper--current:hover': {
      backgroundColor: COLORS.PRIMARY200,
    },
    '&__badge-wrapper': {
      position: 'relative',
    },
    '&__org-name': {
      textAlign: 'center',
      marginBottom: theme.space[2],
    },
    '&__button-wrapper': {
      justifyContent: 'center',
      position: 'sticky',
      left: '10px',
      bottom: '10px',
      backgroundImage: [
        'radial-gradient(rgba(255, 255, 255, 1), rgba(255,255,255, 0.9), rgba(255,255,255, 0.8), rgba(255,255,255,0.7), rgba(255,255,255,0.1), rgba(255, 255, 255, 0))',
        '-moz-radial-gradien(rgba(255, 255, 255, 1), rgba(255,255,255, 0.9), rgba(255,255,255, 0.8), rgba(255,255,255,0.7), rgba(255,255,255,0.1), rgba(255, 255, 255, 0))',
        '-ms-radial-gradient(rgba(255, 255, 255, 1), rgba(255,255,255, 0.9), rgba(255,255,255, 0.8), rgba(255,255,255,0.7), rgba(255,255,255,0.1), rgba(255, 255, 255, 0))',
        '-o-radial-gradient(rgba(255, 255, 255, 1), rgba(255,255,255, 0.9), rgba(255,255,255, 0.8), rgba(255,255,255,0.7), rgba(255,255,255,0.1), rgba(255, 255, 255, 0))',
        '-webkit-radial-gradient(rgba(255, 255, 255, 1), rgba(255,255,255, 0.9), rgba(255,255,255, 0.8), rgba(255,255,255,0.7), rgba(255,255,255,0.1), rgba(255, 255, 255, 0))',
      ],
    },
    '&__btn': {
      margin: `${theme.space[4]} 0`,
    },
  },
});

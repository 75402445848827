import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { Button } from 'rebass/styled-components';
import cs from 'classnames';

import { Flex } from 'shared/components/display';
import WelcomeHeader from 'shared/components/molecules/WelcomeHeader/WelcomeHeader';

import { AUTH_COPY, FILE_COPY } from 'shared/config/copy';
import { GLOBAL_COPY_VARIABLES } from 'shared/config/copy/allCopy';
import { useQuery } from 'shared/hooks/useQuery';
import brkfstLogoIcon from 'shared/images/logo/brkfst-logo-icon.svg';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { CLASSNAMES } from 'shared/styles/containers';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';

const navigateToHomePage = (): void => {
  const path = LinkCreator.createLink({
    routeKey: 'HOME_PAGE',
  });
  window.location.assign(path);
};

const DownloadReady = () => {
  const { getQuery } = useQuery();
  const query = getQuery();
  const downloadLinkRef = useRef<HTMLAnchorElement>(null);
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (query.fileUrl && query.expDate && downloadLinkRef.current) {
      const now = new Date();
      const expDate = new Date(decodeURIComponent(query.expDate));
      if (expDate < now) {
        setIsExpired(true);
      } else {
        // delay download to allow favicon to load
        setTimeout(() => {
          if (downloadLinkRef.current) {
            downloadLinkRef.current.click();
          }
        }, 1000);
      }
    }
  }, [query.fileUrl, query.expDate]);

  return (
    <Flex css={styles}>
      <Flex className={cs(CLASSNAMES.PUBLIC_CONTAINER, 'download-ready')} css={styles}>
        <WelcomeHeader
          heading={isExpired ? FILE_COPY.HEADING_DOWNLOAD_EXPIRED : FILE_COPY.HEADING_DOWNLOAD_READY}
          subheading={isExpired ? FILE_COPY.SUBHEADING_DOWNLOAD_EXPIRED : FILE_COPY.SUBHEADING_DOWNLOAD_READY}
          textAlign="center"
        />
        <Flex className="download-ready__logo-container">
          <img
            src={brkfstLogoIcon}
            alt={GLOBAL_COPY_VARIABLES.APP_NAME}
            className="download-ready__logo-container__logo"
          />
          <Button
            variant={BUTTON_VARIANTS.PRIMARY}
            className="download-ready__logo-container__btn"
            onClick={isExpired ? navigateToHomePage : () => downloadLinkRef.current?.click()}
          >
            {isExpired ? AUTH_COPY.BUTTON_SIGN_IN_AUTH : FILE_COPY.BUTTON_DOWNLOAD_NOW}
          </Button>
        </Flex>
        <a id="downloadLink" ref={downloadLinkRef} href={query.fileUrl} className="download-ready__hidden-download">
          Download File
        </a>
      </Flex>
    </Flex>
  );
};
export default DownloadReady;

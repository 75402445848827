import React from 'react';

import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import SingleFileUploadNameAndButton from 'shared/components/molecules/SingleFileUploadNameAndButton';

import { MEDIA } from 'shared/config/media';
import { Input } from 'shared/lib/formik';
import { FileFormats } from 'shared/typings/file/enums';
import { readUploadedFileAsDataURL } from 'shared/utilities/fileUtility';

interface Props {
  name: string;
  placeholder?: string;
  shouldStoreFile?: boolean;
  disabled?: boolean;
}

interface Value {
  file: File;
  name: string;
}

const CsvFileInput: Input<Value, Props> = ({
  field: { name, value, onChange, onBlur },
  placeholder = '',
  shouldStoreFile = false,
  disabled,
}) => {
  const handleChange = async (csvFile) => {
    if (csvFile) {
      const value: any = {
        file: csvFile,
        name: csvFile.name,
      };
      if (shouldStoreFile) {
        const dataUrl = await readUploadedFileAsDataURL(csvFile);
        value.dataUrl = dataUrl;
      }
      onChange({ target: { name, value } });
    } else {
      onChange({ target: { name, value: null } });
    }
  };
  return (
    <>
      <SingleFileUploadNameAndButton
        filename={value?.name}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={onBlur}
        fileFormat={FileFormats.CSV}
        accept={[MEDIA.EXTENSIONS.CSV, MEDIA.MIME_TYPES.CSV]}
        disabled={disabled}
      />
      <FieldErrorMessage name={name} />
    </>
  );
};

export default CsvFileInput;

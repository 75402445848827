import { ALL_AD_SUPPORTED_FILE_FORMATS, formatFileTypes } from 'shared/config/fileFormats';
import { MESSAGES, SUBMISSION_AR_PERCENT_TOLERANCE, SUBMISSION_UPLOAD_ASPECT_RATIOS } from 'shared/config/validations';
import { intl } from 'shared/lib/intl';
import { ModuleFileType } from 'shared/typings/briefs';

import { withinPercentTolerance } from '../mathUtility';

import type { GifMetaData, ImageMetaData, MetadataType, Validator, ValidatorOptions, VideoMetaData } from './types';
import {
  getFileMimeType,
  getInvalidFilenameCharacters,
  hasValidDuration,
  hasValidImageDimensions,
  hasValidMimeTypeOrExtension,
  hasValidSize,
  hasValidVideoDimensions,
  isGif,
  isImage,
  isSupportedImage,
  isSupportedVideo,
} from './utils';

export class PlatformValidator implements Validator {
  constructor(private moduleFileType?: ModuleFileType) {
    this.moduleFileType = moduleFileType;
  }

  public validate({ file, fileName, metadata }: ValidatorOptions) {
    const errors = this.runMediaValidations({ file, fileName, metadata });
    return errors;
  }

  private runMediaValidations(options: ValidatorOptions) {
    let errorMessages: Array<string> = [];
    const {
      file: { type, name },
      fileName,
    } = options;

    const filename = fileName || name;

    // don't run media validation if the file type doesn't match
    if (this.moduleFileType && !type.includes(this.moduleFileType)) {
      return errorMessages;
    }

    if (isSupportedVideo(type)) errorMessages = this.runVideoValidations(options as ValidatorOptions<VideoMetaData>);
    else if (isGif(type)) {
      errorMessages = this.runGifValidations(options as ValidatorOptions<GifMetaData>);
    } else if (isSupportedImage(type)) {
      errorMessages = this.runImageValidations(options as ValidatorOptions<ImageMetaData>);
    } else {
      if (!hasValidMimeTypeOrExtension(type, filename, ALL_AD_SUPPORTED_FILE_FORMATS)) {
        const extension = filename.split('.').pop();
        errorMessages.push(
          intl.formatMessage({ id: 'ERROR_UNSUPPORTED_FILE' }, { extension: formatFileTypes(extension) }),
        );
      }
    }
    return errorMessages;
  }

  private runImageValidations({ file, fileName, metadata }: ValidatorOptions<ImageMetaData>) {
    const errorMessages: Array<string> = [];
    const name = fileName ? fileName : file.name;
    if (!hasValidImageDimensions(metadata.width, metadata.height)) errorMessages.push(MESSAGES.IMAGE_DIMENSIONS_ERROR);
    const fileType = getFileMimeType(file);
    const generalValidations = this.runCommonFileValidations(name, fileType, metadata);
    return [...errorMessages, ...generalValidations];
  }

  private runGifValidations({ file, fileName, metadata }: ValidatorOptions<GifMetaData>) {
    const errorMessages: Array<string> = [];
    const name = fileName ? fileName : file.name;
    const fileType = getFileMimeType(file);
    const generalValidations = this.runCommonFileValidations(name, fileType, metadata);
    if (!hasValidVideoDimensions(metadata.width, metadata.height)) errorMessages.push(MESSAGES.VIDEO_DIMENSIONS_ERROR);
    return [...errorMessages, ...generalValidations];
  }

  private runVideoValidations({ file: video, fileName, metadata }: ValidatorOptions<VideoMetaData>): Array<string> {
    const errorMessages: Array<string> = [];
    const name = fileName ? fileName : video.name;
    const generalValidations = this.runCommonFileValidations(name, video.type, metadata);
    if (!hasValidVideoDimensions(metadata.width, metadata.height)) errorMessages.push(MESSAGES.VIDEO_DIMENSIONS_ERROR);
    if (!hasValidDuration(metadata.duration)) errorMessages.push(MESSAGES.VIDEO_DURATION_ERROR);
    return [...errorMessages, ...generalValidations];
  }

  private runCommonFileValidations(fileName: string, mimeType: string, metadata: MetadataType) {
    const errorMessages: Array<string> = [];
    const isImageFileType = isImage(mimeType);
    const specialChars = getInvalidFilenameCharacters(fileName);
    if (specialChars?.length > 0) errorMessages.push(`${MESSAGES.FILE_NAME_ERROR} ${specialChars}`);
    if (!hasValidMimeTypeOrExtension(mimeType, fileName, ALL_AD_SUPPORTED_FILE_FORMATS)) {
      const extension = fileName.split('.').pop();
      errorMessages.push(
        intl.formatMessage({ id: 'ERROR_UNSUPPORTED_FILE' }, { extension: formatFileTypes(extension) }),
      );
    }
    if (!hasValidSize(metadata.mimeType, metadata.size))
      errorMessages.push(isImageFileType ? MESSAGES.IMAGE_SIZE_LIMIT : MESSAGES.VIDEO_SIZE_LIMIT);
    if (metadata.width && metadata.height) {
      const aspectRatio = metadata.width / metadata.height;
      const isValidAspectRatio = SUBMISSION_UPLOAD_ASPECT_RATIOS.some((acceptedRatio) =>
        withinPercentTolerance(aspectRatio, acceptedRatio, SUBMISSION_AR_PERCENT_TOLERANCE),
      );
      if (!isValidAspectRatio) {
        const dimensions = `${metadata.width}x${metadata.height}`;
        errorMessages.push(`${dimensions} is not ${MESSAGES.VALID_FILE_ASPECT_RATIOS}`);
      }
    } else errorMessages.push('Missing width or height');
    return errorMessages;
  }
}

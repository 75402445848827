import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  '&.banner-logo': {
    objectFit: 'cover',
  },

  '&.banner-logo-placeholder': {
    color: COLORS.BLACK,
    backgroundColor: COLORS.ORANGE300,
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.banner-logo-placeholder': {
    '&__text': {
      marginBottom: '0',
    },
  },

  '&.banner-logo-foreground': {
    borderRadius: '4px',
    maxWidth: '468px',
    maxHeight: '80px',
    overflow: 'overlay',
  },
  '&.banner-logo, &.banner-logo-placeholder, .banner-logo-skeleton': {
    height: '80px',
    borderRadius: '4px',
  },
  '.banner-logo-placeholder__icon': {
    width: '50%',
    height: '50%',
  },
});

import { useDispatch, useSelector } from 'react-redux';
import { apiAction } from 'shared/actions/api';
import {
  getReport,
  getReports,
  getReportIds,
  getReportDisplayNames,
  getReportDisplayName,
  reportAdded,
  reportRemoved,
  reportEdited,
  reportsLoaded,
} from 'features/accounts/reports/reports.slice';
import { useEffect } from 'react';
import { API_REPORT_ROUTES } from 'shared/config/routes/api/apiReportRoutes';
import { REPORT_TOASTS } from 'shared/config/toasts/reportToasts';

interface Config {
  reportId?: number;
  accountPlatformId?: number;
}

export const useReport = ({ reportId, accountPlatformId }: Config = {}) => {
  const report = useSelector(getReport(reportId));
  const reports = useSelector(getReports);
  const reportIds = useSelector(getReportIds);
  const reportDisplayNames = useSelector(getReportDisplayNames);
  const reportDisplayName = useSelector(getReportDisplayName(reportId));

  const dispatch = useDispatch();

  useEffect(() => {
    if (accountPlatformId) {
      dispatch(
        apiAction({
          path: {
            route: API_REPORT_ROUTES.REPORTS,
          },
          method: 'GET',
          params: { accountPlatformId },
          successAction: reportsLoaded,
        }),
      );
    }
  }, [accountPlatformId]);

  const createReport = (data) => {
    dispatch(
      apiAction({
        path: {
          route: API_REPORT_ROUTES.REPORTS,
        },
        method: 'POST',
        data,
        successAction: reportAdded,
        successToast: {
          message: REPORT_TOASTS.REPORT_CREATED,
        },
      }),
    );
  };

  const updateReport = ({ name, interval, params, accountId }) => {
    dispatch(
      apiAction({
        path: {
          route: API_REPORT_ROUTES.REPORT,
          variables: {
            reportId: report.id,
          },
        },
        method: 'PATCH',
        data: {
          name,
          interval,
          accountId,
          id: report.id,
          jobId: report.jobId,
          params,
        },
        successAction: reportEdited,
        successToast: {
          message: REPORT_TOASTS.REPORT_UPDATED,
        },
      }),
    );
  };

  const deleteReport = () => {
    if (reportId) {
      dispatch(
        apiAction({
          path: {
            route: API_REPORT_ROUTES.REPORT,
            variables: {
              reportId: report.id,
            },
          },
          method: 'DELETE',
          data: { id: reportId, accountId: report.accountId },
          successAction: reportRemoved,
          successToast: {
            message: REPORT_TOASTS.REPORT_DELETED,
          },
        }),
      );
    }
  };

  return {
    report,
    reports,
    reportIds,
    reportDisplayName,
    reportDisplayNames,
    createReport,
    updateReport,
    deleteReport,
  };
};

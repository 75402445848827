import React from 'react';
import { components, OptionProps } from 'react-select';

import SmallCreatorPreview from 'features/users/components/SmallCreatorPreview/SmallCreatorPreview';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';

import { CreatorSelectData } from './interfaces';

const Option: React.FC<OptionProps<CreatorSelectData>> = ({ ...selectProps }) => {
  const { isSuperadmin } = useCurrentUser();
  if (selectProps.data.value === 'all') {
    return <components.Option {...selectProps} />;
  }
  return (
    <components.Option {...selectProps}>
      <SmallCreatorPreview
        user={selectProps.data.value}
        showFullName
        showEmail={isSuperadmin}
        fixedHeight
        showTooltip
      />
    </components.Option>
  );
};

export default Option;

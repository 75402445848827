import { SelectValue } from 'shared/components/molecules/Select';
import { ModuleSubmissionStatus } from 'shared/typings/submissions';
import { capitalize } from 'lodash';

type ModuleAssetSelectValue = SelectValue<ModuleSubmissionStatus>;

export const makeOption = (status: ModuleSubmissionStatus): ModuleAssetSelectValue => ({
  label: capitalize(status.toLowerCase()),
  value: status,
});

export const getStatusOptions = (moduleStatus: ModuleSubmissionStatus): SelectValue<ModuleSubmissionStatus>[] => {
  const displayStatus = Object.keys(ModuleSubmissionStatus).map((status: string) => {
    return makeOption(status as ModuleSubmissionStatus);
  });
  if (moduleStatus !== ModuleSubmissionStatus.REVISION) {
    return displayStatus.filter((option) => option.value !== ModuleSubmissionStatus.REVISION);
  }
  return displayStatus;
};

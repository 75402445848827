import { range } from 'lodash';
import { GalleryItem } from 'features/media/interfaces/gallery';

import { ASPECT_RATIO_TOLERANCE } from 'shared/config/media';
import { RotationMetadata } from 'shared/typings/file';
import { isRotatedSideways } from 'shared/utilities/fileUtility';
import { withinPercentTolerance } from 'shared/utilities/mathUtility';

import { MAX_HEIGHT, MAX_ROW_ITEMS } from './config';
import { CustomPhoto } from './types';

const SKELETON_TILE_WIDTH = 300;
const SKELETON_TILE_HEIGHT = 150;
export const MAX_NUM_ITEMS = 4;
export const MAX_SIZE = 300;
export const MIN_SIZE = 200;

export const getRenderDimension = (options: {
  width: number;
  height: number;
  maxHeight: number;
  withShowMore: boolean;
  numItems: number;
}): {
  renderWidth: number;
  renderHeight: number;
} => {
  let renderWidth = options.width;
  let renderHeight = options.height;
  // A single row of fewer items will grow too big in
  // order to fill in the row so we implement a max
  // height

  if (renderHeight > options.maxHeight && options.numItems <= MAX_ROW_ITEMS) {
    renderWidth = (options.maxHeight * renderWidth) / renderHeight;
    renderHeight = options.maxHeight;
  }
  // image rotation check and height & width swap already happens in convertToPhoto
  // so don't do again here
  if (!options.withShowMore) {
    return { renderWidth, renderHeight };
  }
  renderWidth = options.numItems > MAX_NUM_ITEMS ? renderWidth * MAX_NUM_ITEMS : renderWidth;
  renderHeight = options.numItems > MAX_NUM_ITEMS ? renderHeight * MAX_NUM_ITEMS : renderHeight;

  let tileWidth = renderWidth > MAX_SIZE ? MAX_SIZE : renderWidth;
  let tileHeight = renderHeight > MAX_SIZE ? MAX_SIZE : renderHeight;

  const isNineBySixteen = withinPercentTolerance(renderHeight / renderWidth, 9 / 16, ASPECT_RATIO_TOLERANCE);

  // Display 9:16 images correctly
  if (isNineBySixteen && renderWidth > MAX_SIZE) {
    tileWidth = MAX_SIZE / 1.7;
  }

  // Add 50px to prevent the tiles from being too small specially on mobile
  if (tileWidth < MIN_SIZE && tileHeight < MIN_SIZE) {
    tileHeight = tileHeight + 50;
    tileWidth = tileWidth + 50;
  }
  return { renderWidth: tileWidth, renderHeight: tileHeight };
};

const dummyItemObject = {
  id: 0,
  name: '',
  thumbnailUrl: '',
  url: '',
  metadata: { width: 0, height: 0 },
  mimeType: '',
  creatorId: 0,
  dateCreated: new Date(),
};

export function getPhotosAndSkeletons(
  items: GalleryItem[],
  numLoadingItems: number,
  withShowMore: boolean,
): CustomPhoto[] {
  const skellies = generateSkeletonObj(numLoadingItems);
  const photos = items.map(convertToPhoto);
  const avgHeight = items[0]?.itemObject?.metadata?.height || MAX_HEIGHT;
  const showMoreItem = {
    src: `show more`, // the library uses the src as a key
    width: avgHeight,
    height: avgHeight,
    isShowMore: true,
    itemObject: dummyItemObject,
  };
  const assetsList = [...skellies, ...photos];
  if (withShowMore) {
    assetsList.push(showMoreItem);
  }

  return assetsList;
}

export const generateSkeletonObj = (numOfSkeletons: number): CustomPhoto[] => {
  return range(numOfSkeletons).map((count) => ({
    src: `${count}`, // the library uses the src as a key
    width: SKELETON_TILE_WIDTH,
    height: SKELETON_TILE_HEIGHT,
    isSkeleton: true,
    itemObject: dummyItemObject,
  }));
};

const deriveDisplayDimensions = (
  width: number,
  height: number,
  rotation: RotationMetadata,
): { width: number; height: number } => {
  if (isRotatedSideways(rotation)) {
    return { width: height, height: width };
  }
  return { width, height };
};

export const convertToPhoto = (item: GalleryItem): CustomPhoto => {
  const { id, thumbnailUrl, metadata } = item.itemObject;
  const { rotation = 0 } = metadata;
  let width = metadata.width;
  let height = metadata.height;
  if (width && height) {
    const { width: derivedWidth, height: derivedHeight } = deriveDisplayDimensions(width, height, rotation);
    width = derivedWidth;
    height = derivedHeight;
  }

  return {
    ...item,
    key: id.toString(),
    src: thumbnailUrl,
    width: width || 500,
    height: height || 300,
  };
};

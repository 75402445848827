import SingleRevision from 'features/submissions/components/atoms/SingleRevision';
import { Box, Text } from 'shared/components/display';
import { Revision } from 'shared/typings/revisions';
import moduleRevisionCardStyles from 'shared/components/molecules/ModuleRevisionCard/moduleRevisionCardStyles';
import { intl } from 'shared/lib/intl';
import React from 'react';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { TEXT_VARIANTS } from 'shared/styles/text';

interface Props {
  revision: Revision;
  moduleNum: number;
  brandName: string;
}

const ModuleRevisionCard: React.FC<Props> = ({ revision, moduleNum, brandName }) => {
  const { isCreator } = useCurrentUser();
  return (
    <Box css={moduleRevisionCardStyles}>
      <Text className="revision-card__header" variant={TEXT_VARIANTS.H4}>
        {intl.formatMessage({ id: 'CAPTION_REVIEW_REVISION_HEADER' }, { moduleNum: moduleNum })}
      </Text>
      <Box className="revision-card__body">
        <SingleRevision revision={revision} brandName={brandName} isCreator={isCreator} />
      </Box>
    </Box>
  );
};

export default ModuleRevisionCard;

import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import SHADOWS from 'shared/styles/shadow';
import { mediaquery } from 'shared/styles/styleFunctions';
import { STYLES } from 'shared/styles/styles';
import theme from 'shared/styles/themes';

export default (options: { backgroundImage?: string; size?: string }) =>
  css({
    '&.tile': {
      position: 'relative',
      // leave space for label
      height: `calc(${options.size} + 18px)`,
      width: options.size,
      flexDirection: 'column',
      [mediaquery.md]: {
        width: '195px',
      },
      '&--clickable': {
        cursor: 'pointer',
      },
      '&--stacked': {
        marginTop: theme.space[4],
        overflow: 'visible',
      },
      '&--stacked .tile__background': {
        boxShadow: SHADOWS.SHADOW03,
      },
    },
    '.tile': {
      '&__stack, &__stack::after': {
        content: '" "',
        height: options.size,
        width: options.size,
        backgroundColor: COLORS.WHITE,
        border: `1px solid ${COLORS.NEUTRAL100}`,
        boxShadow: SHADOWS.SHADOW03,
        borderRadius: '5px',
        zIndex: zIndex.zBackdrop,
        position: 'absolute',
      },
      '&__stack': { top: '-18px', right: '-10px' },
      '&__stack::after': { top: '7px', right: '7px' },
      '&__background': {
        backgroundImage: `url("${options.backgroundImage}")`,
        backgroundColor: COLORS.NEUTRAL200,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        paddingBottom: '75%',
        borderRadius: '5px',
        position: 'relative',
        zIndex: zIndex.zForeground,
        ...(options.size ? { width: options.size, height: options.size } : { width: '100%' }),
      },

      '&__background::after': {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: '2rem',
        color: COLORS.PRIMARY500,
      },
      '&__background--new-tile': {
        backgroundColor: COLORS.WHITE,
        border: STYLES.BORDER_NEUTRAL,
        cursor: 'pointer',
      },
      '&__background--new-tile::after': {
        content: '"\\002B"',
      },
      '&__label': {
        zIndex: zIndex.zForeground,
        textTransform: 'capitalize',
        color: 'initial',
        textAlign: 'center',
        marginBottom: 0,
      },
      '&__overlay': {
        position: 'absolute',
        bottom: 0,
        marginLeft: theme.space[3],
        marginBottom: theme.space[3],
      },
      '&__overlay-text': {
        margin: `${theme.space[1]} ${theme.space[3]}`,
        zIndex: zIndex.zButtonOverlay,
        color: 'white',
      },
      '&__overlay-background': {
        backgroundColor: COLORS.NEUTRAL600,
        mixBlendMode: 'multiply',
        borderRadius: '3px',
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: '0.5',
      },
    },
  });

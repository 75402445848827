import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import cs from 'classnames';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';

import { onToggleInshop } from 'features/briefs/components/BriefTemplateForm/util';
import BriefAssetsInput from 'features/briefs/components/molecules/BriefAssetsInput/BriefAssetsInput';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import { getStates, statesLoaded } from 'features/users/hooks/states.slice';
import { Box } from 'shared/components/display';
import FormikErrorFocus from 'shared/components/forms/FormikErrorFocus';
import Heading from 'shared/components/molecules/Heading';
import NavigationPrompt from 'shared/components/molecules/NavigationPrompt';
import { SelectInput } from 'shared/components/molecules/Select';
import RichtextInput from 'shared/components/Richtext/RichtextInput';
import TextareaInput from 'shared/components/TextareaInput/TextareaInput';
import TextInput from 'shared/components/TextInput';
import ToggleInput from 'shared/components/ToggleInput';

import { apiAction } from 'shared/actions/api';
import { BRIEF_COPY } from 'shared/config/copy';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';
import { TRACKING } from 'shared/config/tracking';
import { CONSTRAINT_LIMITS } from 'shared/config/validations';
import { makeLabel } from 'shared/lib/formik';
import { intl } from 'shared/lib/intl';
import { breakpoints } from 'shared/styles/styleFunctions';
import { trackEvent } from 'shared/utilities/trackingUtility';

import { BriefModulesForm } from './components/BriefModulesForm';
import FormButtons from './components/FormButtons';
import briefFormStyles from './briefFormStyles';
import { FormBrief } from './types';
import { FIELDNAMES, getSchema, INTERNAL_NOTE_MAX_LENGTH } from './validation';

const MAX_FREE_PRODUCT_LENGTH = 15;
export const SELECT_ALL = 'ALL';
export const SELECT_ALL_LABEL = 'Select All';
export const SELECT_ALL_OPTION = {
  value: {
    id: 0,
    name: SELECT_ALL,
  },
  label: SELECT_ALL_LABEL,
};

interface Props {
  defaultValues: FormBrief;
  isPublished: boolean;
  showPublishButton: boolean;
  onCancel: () => void;
  onPublish: (values: FormBrief) => void;
  onSaveDraft: (values: FormBrief) => void;
  isSuperadmin?: boolean;
  onPromptConfirm: (values: any) => void;
  loadingCheckout?: boolean;
  loadingSaveDraft?: boolean;
  promptText: string;
  formCompleted: boolean;
  onToggleCreativeOnly: (value: React.ChangeEvent<HTMLInputElement>, formikProps: FormikProps<FormBrief>) => void;
  loading?: boolean;
}

const BriefForm: React.FC<Props> = ({
  onPublish = () => null,
  onCancel = () => null,
  onSaveDraft = () => null,
  defaultValues,
  isPublished = false,
  showPublishButton = false,
  isSuperadmin = false,
  loadingSaveDraft = false,
  loadingCheckout = false,
  onPromptConfirm,
  promptText,
  formCompleted,
  onToggleCreativeOnly,
  loading = false,
}) => {
  const states = useSelector(getStates);
  const dispatch = useDispatch();
  const [showSelectAll, setSelectAll] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md - 1 });

  useEffect(() => {
    if (!states.length)
      dispatch(
        apiAction({
          path: {
            route: API_USER_ROUTES.STATES,
          },
          params: {
            countryCode: 'US',
          },
          entity: 'state',
          successAction: statesLoaded,
        }),
      );
  }, [dispatch, states]);

  const validationSchema = getSchema(isSuperadmin);
  const handleSubmit = (values: FormBrief, actions: FormikHelpers<FormBrief>) => {
    if (!isPublished) {
      trackEvent(TRACKING.BRIEF_PUBLISH);
      onPublish(values);
    }
    // Because we've got multiple "ways to submit" we're going to
    // reset submitting state in case we cancel submitting
    actions.setSubmitting(false);
  };

  const stateOptions = useMemo(() => {
    const stateOptions = states.map((state) => ({ value: state, label: state.code || '' }));
    if (showSelectAll) return [SELECT_ALL_OPTION].concat(stateOptions);
    else return stateOptions;
  }, [states, showSelectAll]);

  return loading ? (
    <CenteredSpinnerContainer />
  ) : (
    <Box className="brief-form" css={briefFormStyles}>
      <Formik<FormBrief>
        initialValues={defaultValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange
        enableReinitialize
      >
        {(formikProps) => (
          <Form>
            <Heading text={BRIEF_COPY.FORM_NAME_HEADING} className="brief-form__header" />
            <Field
              name={FIELDNAMES.NAME}
              component={TextInput}
              maxLength={CONSTRAINT_LIMITS.MAX_BRIEF_TITLE_LENGTH}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_NAME}
              label={BRIEF_COPY.LABEL_NAME_BRIEF + '*'}
            />

            <Heading
              text={intl.formatMessage(
                {
                  id: 'FORM_CREATOR_PAYOUT_HEADING',
                },
                {
                  isSuperadmin,
                },
              )}
              className="brief-form__header"
            />
            <Field
              name={FIELDNAMES.CREATIVE_ONLY}
              component={ToggleInput}
              label="Creative Only"
              className="brief-form__creative-only"
              disabled={isPublished}
              onChange={(e) => onToggleCreativeOnly(e, formikProps)}
            />
            {isSuperadmin && (
              <>
                <Field
                  name={FIELDNAMES.CREATOR_PAYOUT}
                  component={TextInput}
                  label={makeLabel(FIELDNAMES.CREATOR_PAYOUT, true)}
                  placeholder={BRIEF_COPY.FORM_PLACEHOLDER_PAYOUT}
                  disabled={isPublished}
                />
                <Field
                  name={FIELDNAMES.ELITE_CREATOR_PAYOUT}
                  component={TextInput}
                  label={makeLabel(FIELDNAMES.ELITE_CREATOR_PAYOUT, true)}
                  placeholder={makeLabel(FIELDNAMES.ELITE_CREATOR_PAYOUT, true)}
                  disabled={isPublished}
                />
              </>
            )}
            <Field
              name={FIELDNAMES.CREATOR_APPROVAL_PRICING}
              component={TextInput}
              label={makeLabel(FIELDNAMES.CREATOR_APPROVAL_PRICING, true)}
              placeholder={makeLabel(FIELDNAMES.CREATOR_APPROVAL_PRICING, true)}
              disabled={isPublished || !isSuperadmin}
            />
            <Field
              name={FIELDNAMES.ELITE_CREATOR_APPROVAL_PRICING}
              component={TextInput}
              label={makeLabel(FIELDNAMES.ELITE_CREATOR_APPROVAL_PRICING, true)}
              placeholder={makeLabel(FIELDNAMES.ELITE_CREATOR_APPROVAL_PRICING, true)}
              disabled={isPublished || !isSuperadmin}
            />
            <Field name={FIELDNAMES.BRAND_ASSETS} component={BriefAssetsInput} />
            <Heading text={BRIEF_COPY.FORM_PRODUCT_HEADING} className="brief-form__header" />
            <Field
              name={FIELDNAMES.FREE_PRODUCT}
              component={TextInput}
              maxLength={MAX_FREE_PRODUCT_LENGTH}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_FREE_PRODUCT}
              label={BRIEF_COPY.LABEL_FREE_PRODUCT + '*'}
              showCharactersCounter
            />
            <Field
              component={TextInput}
              name={FIELDNAMES.WEBSITE}
              label={BRIEF_COPY.LABEL_WEBSITE}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_WEBSITE}
            />
            <Field
              component={TextInput}
              name={FIELDNAMES.PROMO_CODE}
              label={BRIEF_COPY.LABEL_PROMO_CODE}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_PROMO_CODE}
            />
            <Heading
              text={BRIEF_COPY.FORM_HEADING_DESCRIPTION}
              descriptionText={BRIEF_COPY.FORM_TOOLTIP_DESCRIPTION}
              className="brief-form__header"
            />
            <Field
              name={FIELDNAMES.DESCRIPTION}
              component={RichtextInput}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_MODULE_DESCRIPTION}
              readableWidth
            />
            <Heading
              text={BRIEF_COPY.FORM_HEADING_MODULE}
              descriptionText={BRIEF_COPY.FORM_TOOLTIP_MODULE}
              className={cs('brief-form__section-header', 'brief-form__header')}
            />
            <BriefModulesForm name="modules" isDraftState={!isPublished} />
            <Heading
              text={BRIEF_COPY.FORM_HEADING_AUTO_RESPONSE}
              descriptionText={BRIEF_COPY.FORM_TOOLTIP_AUTO_RESPONSE}
              className={cs('brief-form__section-header', 'brief-form__header')}
            />
            <Field
              name={FIELDNAMES.AUTO_RESPONSE}
              component={TextareaInput}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_AUTO_RESPONSE}
              allowVerticalResize
            />
            <Field
              name={FIELDNAMES.INVITE_ONLY}
              component={ToggleInput}
              label="Invite Only"
              className="brief-form__invite-only"
            />
            <Field
              name={FIELDNAMES.SHIPPING_REQUIREMENT}
              component={ToggleInput}
              label="Shipping Required"
              className="brief-form__shipping-required"
            />
            <Field
              name={FIELDNAMES.INSHOP_REQUIREMENT}
              component={ToggleInput}
              label="In-Shop Visit Required"
              className="brief-form__inshop-required"
              onChange={() => onToggleInshop(formikProps)}
            />
            {formikProps.values[FIELDNAMES.INSHOP_REQUIREMENT] && (
              <Field
                name={FIELDNAMES.STATES}
                component={SelectInput}
                isSearchable
                isMulti
                isOptionSelected={({ value: { id } }) =>
                  formikProps.values.states?.some(({ value }) => value.id === id)
                }
                onChange={(values) => {
                  const allChosen = values.length === states.length;
                  const hasSelectAll = values.some(({ value }) => value.name === SELECT_ALL);
                  if (!allChosen && hasSelectAll) {
                    const allStates = states.map((state) => ({ value: state, label: state.code }));
                    formikProps.setFieldValue('states', allStates, true);
                  }
                  if (hasSelectAll || allChosen || values.length === states.length - 1) {
                    setSelectAll(false);
                  } else if (!showSelectAll) {
                    setSelectAll(true);
                  }
                }}
                options={stateOptions}
                placeholder={'Store States'}
                width={isMobile ? '100%' : '400px'}
                runBlurOnChange={false}
                filterByLabel
              />
            )}
            {isSuperadmin && (
              <>
                <hr className="brief-form__hr" />
                <Heading text={BRIEF_COPY.FORM_HEADING_INTERNAL_NOTE} className="brief-form__header" />
                <Field
                  name={FIELDNAMES.INTERNAL_NOTE}
                  component={TextareaInput}
                  maxLength={INTERNAL_NOTE_MAX_LENGTH}
                  softMaxLength={INTERNAL_NOTE_MAX_LENGTH}
                  placeholder={BRIEF_COPY.FORM_PLACEHOLDER_INTERNAL_NOTE}
                  dataCy="brief-form__internal-note"
                />
              </>
            )}
            <FormikErrorFocus offset={-30} align="top" ease="linear" duration={200} formik={formikProps} />
            <NavigationPrompt
              onConfirm={() => onPromptConfirm(formikProps.values)}
              when={({ currentLocation, nextLocation }) => {
                return !formCompleted && formikProps.dirty && currentLocation.pathname !== nextLocation.pathname;
              }}
              promptText={promptText}
              proceedOnCancel
            />
            <FormButtons
              isPublished={isPublished}
              showPublishButton={showPublishButton}
              onPublish={onPublish}
              onSaveDraft={onSaveDraft}
              onCancel={onCancel}
              loadingPublish={loadingCheckout}
              loadingSaveDraft={loadingSaveDraft}
            />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default BriefForm;

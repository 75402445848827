import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Text } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SelectionItem from 'features/briefs/BriefSelection/SelectionItem';
import styles from 'features/briefs/BriefSelection/styles';
import { Flex } from 'shared/components/display';

import { BRIEF_COPY } from 'shared/config/copy';
import { OrganizationRouteParams } from 'shared/config/routes/brands/matchTypes';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Brief } from 'shared/typings/briefs';
import { LinkCreator } from 'shared/utilities/linkUtility';

import { useBriefs } from '../hooks/useBriefs';

const { H3 } = TEXT_VARIANTS;

const BriefTemplateSelectionEdit: React.FC = () => {
  const [selected, setSelected] = useState<number | null>(null);
  const { briefTemplates, fetchBriefTemplates, loadingBriefTemplates } = useBriefs();
  const params = useParams<OrganizationRouteParams>();
  const navigate = useNavigate();
  const organizationId = +params.organizationId;
  const updateSelected = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    const {
      currentTarget: { value },
    } = event;
    setSelected(parseInt(value));
    event.currentTarget.blur();
  };

  useEffect(() => {
    fetchBriefTemplates();
  }, []);

  const onTemplateSelect = (): void => {
    const path = LinkCreator.createLink({
      routeKey: 'EDIT_BRIEF_TEMPLATE',
      variables: {
        organizationId,
        briefTemplateId: selected,
      },
    });
    navigate(path);
  };

  if (loadingBriefTemplates) return null;

  return (
    <Flex className="brief-selection" css={styles}>
      <Text variant={H3}>{BRIEF_COPY.HEADING_BRIEF_EDIT}</Text>
      {briefTemplates.map((briefTemplate: Brief) => {
        return (
          <SelectionItem
            onClick={updateSelected}
            selected={selected === briefTemplate.id}
            heading={briefTemplate.name}
            subHeading={briefTemplate.subheading}
            icon={
              <FontAwesomeIcon
                className={`icon ${selected === briefTemplate.id ? 'icon--selected' : ''}`}
                icon={['fal', briefTemplate.icon]}
              />
            }
            type={briefTemplate.id}
            key={briefTemplate.name}
          />
        );
      })}
      <Button onClick={onTemplateSelect} disabled={!selected} className="brief-selection__submit-button">
        {BRIEF_COPY.BUTTON_SELECT_TEMPLATE}
      </Button>
    </Flex>
  );
};

export default BriefTemplateSelectionEdit;

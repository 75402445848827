import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  creativePayoutsLoaded,
  getCreativePayouts,
  getCreativePayoutsCount,
} from 'features/users/hooks/creatorPerformance.slice';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_PAYOUT_ROUTES } from 'shared/config/routes/api/apiPayoutRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useQuery } from 'shared/hooks/useQuery';
import { useSearchParams } from 'shared/hooks/useSearchParams';

interface PaginationQueryParams {
  page: number;
  size: number;
  orderBy: string;
  groupBy: string;
  orderAsc: boolean;
}

interface BriefPerformanceParams {
  briefIndex: number;
}

export const useCreatorPerformance = () => {
  const dispatch = useDispatch();

  const {
    currentUser: { id: creatorId },
  } = useCurrentUser();

  const { loading } = useComponentLoading(RESOURCES.CREATOR_PERFORMANCE);

  const { getQuery } = useQuery();
  const query = getQuery();

  const creativePayouts = useSelector(getCreativePayouts);
  const creativePayoutsTotal = useSelector(getCreativePayoutsCount);

  const searchParams: PaginationQueryParams = useSearchParams(query, {
    size: 10,
    groupBy: 'file_lifetime',
    page: 1,
    orderAsc: true,
    orderBy: 'fileName',
  });

  const briefPerformanceSearchParams: BriefPerformanceParams = useSearchParams(query, {
    briefIndex: -1,
  });

  const getPayouts = useCallback(() => {
    dispatch(
      apiAction({
        path: {
          route: API_PAYOUT_ROUTES.PAYOUTS,
        },
        method: 'GET',
        successAction: creativePayoutsLoaded,
        entity: RESOURCES.CREATOR_PERFORMANCE,
        params: {
          ...searchParams,
          creatorId,
        },
      }),
    );
  }, [searchParams, creatorId]);

  return {
    loading,
    creativePayouts,
    creativePayoutsTotal,
    searchParams,
    briefPerformanceSearchParams,
    getPayouts,
  };
};

import React from 'react';
import cs from 'classnames';

import BackButton from 'shared/components/atoms/BackButton';
import { Box, Flex } from 'shared/components/display';
import ConfirmationSummary from 'shared/components/molecules/ConfirmationSummary';

import { CLASSNAMES as GLOBAL_CLASSNAMES } from 'shared/styles/containers';
import { STYLES } from 'shared/styles/styles';

import styles, { CLASSNAMES, MAIN_CLASS } from './styles';

const { COLUMN_COLUMN_ROW } = STYLES;

interface Props {
  children: React.ReactNode;
  onSubmitText: string;
  onSubmit: () => void;
  backButtonProps: any;
  submitInProcess?: boolean;
}

const ConfirmationPanel = ({ children, onSubmitText, onSubmit, backButtonProps, submitInProcess = false }: Props) => {
  return (
    <Box css={styles} className={MAIN_CLASS}>
      <Box mr={[0, 0, 8]} mb={[5, 5, 0]}>
        <BackButton {...backButtonProps} />
      </Box>
      <Flex flexDirection={COLUMN_COLUMN_ROW}>
        <Box className={cs(GLOBAL_CLASSNAMES.PADDING_CONTAINER, CLASSNAMES.content)} mr={[0, 0, 8]} mb={[5, 5, 0]}>
          {children}
        </Box>
        <ConfirmationSummary onSubmitText={onSubmitText} onSubmit={onSubmit} submitInProcess={submitInProcess} />
      </Flex>
    </Box>
  );
};

export default ConfirmationPanel;

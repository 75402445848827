import { css } from '@emotion/react';
import theme from 'shared/styles/themes';
import COLORS from 'shared/styles/colors';

const MAX_TABS = 5;

export const tabBarStyles = css({
  '&.tab-bar': {
    display: 'grid',
    gridTemplateRows: '[bar-start] min-content [content-start] auto',
    gridTemplateColumns: `[start] repeat(${MAX_TABS - 1}, max-content) 1fr [end]`,
    backgroundColor: COLORS.WHITE,
    height: '100%',
  },
});

export const tabStyles = css({
  '.tab-bar__title': {
    padding: 0,
    margin: `0 6px 0 6px`,
  },
  '.tab-bar__tab': {
    alignItems: 'center',
    gridRow: 'bar-start / span 1',
    gridColumn: 'span 1',
    color: COLORS.NEUTRAL600,
    cursor: 'pointer',
    padding: theme.space[4],
    borderRadius: '0',
    margin: 0,
  },
  '.tab-bar__tab--active': {
    cursor: 'default',
    borderBottom: `3px solid ${COLORS.PRIMARY500}`,
    color: COLORS.PRIMARY500,
  },
  '.tab-bar__tab--hidden': {
    display: 'none',
  },
  '&.tab-bar__content': {
    gridRow: 'content-start / span 1',
    gridColumn: 'start / end',
    borderTop: `1px solid ${COLORS.NEUTRAL200}`,
    width: '100%',
    overflowY: 'auto',
  },
  '&.tab-bar__content--hidden': {
    display: 'none',
  },
});

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { accountPlatformHealthSlice } from 'features/accountPlatforms/accountPlatformHealth.slice';
import { accountPlatformsSlice } from 'features/accountPlatforms/accountPlatforms.slice';
import campaigns from 'features/accountPlatforms/facebook/Campaigns/campaigns.slice';
import configurations from 'features/accountPlatforms/facebook/configurations.slice';
import { accountsSlice } from 'features/accounts/accounts.slice';
import brandAssets from 'features/accounts/brandAssets.slice';
import channels from 'features/accounts/channels.slice';
import reports from 'features/accounts/reports/reports.slice';
import { authSlice } from 'features/auth/auth.slice';
import briefs from 'features/briefs/briefs.slice';
import creators from 'features/creators/creators.slice';
import collections from 'features/media/collections.slice';
import files from 'features/media/files.slice';
import cards from 'features/organizations/cards.slice';
import invoices from 'features/organizations/invoices.slice';
import organizations from 'features/organizations/organizations.slice';
import { orgUsersSlice } from 'features/organizations/orgUsers.slice';
import roles from 'features/organizations/roles.slice';
import performance from 'features/performance/performance.slice';
import { reviewModulesSlice } from 'features/reviewModules/reviewModules.slice';
import { submissionsSlice } from 'features/submissions/submissions.slice';
import { uiSlice } from 'features/ui/ui.slice';
import creatorPerformance from 'features/users/hooks/creatorPerformance.slice';
import earnings from 'features/users/hooks/earnings.slice';
import states from 'features/users/hooks/states.slice';
import user from 'features/users/hooks/user.slice';
import forms from 'shared/components/forms/forms.slice';
import creatorBriefs from 'features/briefs/creatorBriefs.slice';
import { USER_ACTION_TYPES } from 'shared/actions/currentUser';
import { apiMiddleware } from 'shared/middleware/api';
import { currentUserMiddleware } from 'shared/middleware/currentUser';
import { downloadFileMiddleware } from 'shared/middleware/downloadFile';
import { organizationMiddleware } from 'shared/middleware/organizations';
import { routingMiddleware } from 'shared/middleware/routing';
import { toastMiddleware } from 'shared/middleware/toasts';
import { trendsSlice } from 'features/trends/trends.slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['organizations'],
};

const appReducer = combineReducers({
  accountPlatforms: accountPlatformsSlice.reducer,
  accountPlatformHealth: accountPlatformHealthSlice.reducer,
  accounts: accountsSlice.reducer,
  brandAssets,
  briefs,
  campaigns,
  cards,
  channels,
  collections,
  configurations,
  creatorBriefs,
  creatorPerformance,
  creators,
  currentUser: authSlice.reducer,
  earnings,
  files,
  forms,
  invoices,
  organizations,
  orgUsers: orgUsersSlice.reducer,
  performance,
  reports,
  reviewModules: reviewModulesSlice.reducer,
  roles,
  submissions: submissionsSlice.reducer,
  ui: uiSlice.reducer,
  user,
  states,
  trends: trendsSlice.reducer,
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer = (state, action) => {
  if (action.type === USER_ACTION_TYPES.USER_CLEARED) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    apiMiddleware,
    currentUserMiddleware,
    organizationMiddleware,
    toastMiddleware,
    downloadFileMiddleware,
    routingMiddleware,
  ],
});

import React from 'react';
import styled from 'styled-components';
import { Text } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/pro-solid-svg-icons';
import { prettyDate } from 'shared/utilities/dateUtility';

import theme from 'shared/styles/themes';
import { TEXT_VARIANTS } from 'shared/styles/text';
import COLORS from 'shared/styles/colors';

const { SUBHEADING_SM, CAPTION } = TEXT_VARIANTS;
const { GREEN500, NEUTRAL50, NEUTRAL600 } = COLORS;

const AudienceFile = ({ filename, date }) => {
  return (
    <File>
      <FontAwesomeIcon icon={faFileCsv} size="2x" color={filename ? GREEN500 : NEUTRAL600} />
      <div>
        <Text variant={SUBHEADING_SM}>{filename}</Text>
        {date && <Text variant={CAPTION}>{`Uploaded ${prettyDate(date)}`}</Text>}
      </div>
    </File>
  );
};

const File = styled.div`
  background-color: ${NEUTRAL50};
  padding: 1rem;
  border-radius: 4px;
  width: fit-content;
  white-space: nowrap;

  svg {
    float: left;
    margin-right: ${theme.space[3]};
  }
  & > div {
    display: flex;
    flex-direction: column;
  }
`;

export default AudienceFile;

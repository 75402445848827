import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.adset-select': {
    marginTop: theme.space[4],
    width: '100%',
    alignContent: 'center',
  },
  '.adset-select': {
    '&__box': {
      flex: 4,
      [mediaquery.md]: {
        flex: 0,
      },
    },
    '&__btn': {
      marginTop: theme.space[4],
    },
  },
});

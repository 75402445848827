import React from 'react';
import { useNavigate } from 'react-router-dom';

import CreatorProfile from 'features/users/pages/CreatorProfile/CreatorProfile.tsx';

import { RESOURCES } from 'shared/config/resourceNames';
import { useCurrentUser } from 'shared/hooks/useCurrentUser.ts';
import { noAccess } from 'shared/utilities/routerUtility';
const CurrentUserProfile = () => {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const { creatorTags } = useUser();

  if (!currentUser) {
    noAccess(navigate, `${RESOURCES.USER} Page`);
  }
  return <CreatorProfile isCurrentUser user={currentUser} creatorTags={creatorTags} />;
};

export default CurrentUserProfile;

import { MESSAGES } from 'shared/config/validations';
import { hasHttpPrefix, isValidDomain, charactersPresentBeforeHttp } from 'shared/utilities/stringUtility';
import { tldExists } from 'tldjs';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  shippingLink: yup
    .string()
    .customRequiredString()
    .test({
      message: MESSAGES.DOMAIN,
      name: 'url-validation',
      test(link) {
        if (link) {
          if (!isValidDomain(link) || !hasHttpPrefix(link)) {
            return false;
          }
          if (charactersPresentBeforeHttp(link)) {
            return false;
          }
          if (!tldExists(link)) {
            return this.createError({
              message: MESSAGES.TLD_ERROR,
            });
          }
        }
        return true;
      },
    }),
});

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.top-bottom-form-dropdown': {
    flexDirection: 'column',
    padding: theme.space[6],
    paddingBottom: theme.space[3],
    backgroundColor: COLORS.WHITE,
    width: '20em', // Style-guide Exception: Tippy placement props conflict with rem units.
  },
  '.top-bottom-form-dropdown': {
    '&__title': {
      display: 'inline-flex',
      alignContent: 'center',
    },
    '&__question-icon': {
      paddingTop: theme.space[2],
      paddingLeft: theme.space[3],
    },
  },
});

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.sign-in': {
    '&__link-wrapper': {
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: theme.space[4],
    },
    '&__link': {
      textAlign: 'right',
    },
    '&__email-input input': {
      color: COLORS.BLACK,
    },
    '&__footnote': { fontWeight: 300, lineHeight: 1.2, margin: `${theme.space[5]} 0` },
    '&__footnote--bold': {
      fontWeight: 600,
    },
  },
});

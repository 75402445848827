import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { loggedOut } from 'features/auth/auth.slice';

import { AccountPlatform } from 'shared/typings/accountPlatform';
import { Platforms } from 'shared/typings/accountPlatform/enums';
import { FacebookBusinessAsset } from 'shared/typings/facebookBusinessAsset';

interface AccountPlatformState {
  platforms: AccountPlatform[];
}

type AccountPlatformAction<T = AccountPlatform> = PayloadAction<{ data: T }>;

export const initialAccountPlatformsState: AccountPlatformState = Object.freeze({
  platforms: [],
});

export const accountPlatformsSlice = createSlice<AccountPlatformState, SliceCaseReducers<AccountPlatformState>>({
  name: 'accountPlatforms',
  initialState: initialAccountPlatformsState,
  reducers: {
    accountPlatformsReset: () => {
      return initialAccountPlatformsState;
    },
    accountPlatformsLoaded: (accountPlatforms, action: AccountPlatformAction<AccountPlatform[]>) => {
      accountPlatforms.platforms = action.payload.data;
    },
    accountPlatformAdded: (accountPlatforms, action: AccountPlatformAction) => {
      accountPlatforms.platforms.unshift(action.payload.data);
    },
    accountPlatformRemoved: (accountPlatforms, action: AccountPlatformAction) => {
      accountPlatforms.platforms = accountPlatforms.platforms.filter(
        (accountPlatform) => accountPlatform.id !== action.payload.data.id,
      );
    },
    accountPlatformChanged: (accountPlatforms, action: AccountPlatformAction) => {
      accountPlatforms.platforms = accountPlatforms.platforms.map((accountPlatform) =>
        accountPlatform.id === action.payload.data.id
          ? { ...accountPlatform, ...action.payload.data }
          : accountPlatform,
      );
    },
    facebookBusinessAssetUpdated: (accountPlatforms, action: AccountPlatformAction<FacebookBusinessAsset>) => {
      accountPlatforms.platforms = accountPlatforms.platforms.map((accountPlatform) =>
        accountPlatform.id === action.payload.data.accountPlatformId
          ? {
              ...accountPlatform,
              facebookBusinessAssets: accountPlatform.facebookBusinessAssets.map((asset) =>
                asset.id === action.payload.data.id
                  ? {
                      ...asset,
                      ...action.payload.data,
                    }
                  : asset,
              ),
            }
          : accountPlatform,
      );
    },
    facebookBusinessAssetAdded: (accountPlatforms, action: AccountPlatformAction<FacebookBusinessAsset>) => {
      accountPlatforms.platforms = accountPlatforms.platforms.map((accountPlatform) =>
        accountPlatform.id === action.payload.data.accountPlatformId
          ? {
              ...accountPlatform,
              facebookBusinessAssets: [...accountPlatform.facebookBusinessAssets, action.payload.data],
            }
          : accountPlatform,
      );
    },
    facebookBusinessAssetRemoved: (accountPlatforms, action: AccountPlatformAction<FacebookBusinessAsset>) => {
      accountPlatforms.platforms = accountPlatforms.platforms.map((accountPlatform) =>
        accountPlatform.id === action.payload.data.accountPlatformId
          ? {
              ...accountPlatform,
              facebookBusinessAssets: accountPlatform.facebookBusinessAssets.filter(
                ({ id }) => id !== action.payload.data.id,
              ),
            }
          : accountPlatform,
      );
    },
  },
  extraReducers: (builder) => builder.addCase(loggedOut, () => initialAccountPlatformsState),
});

export const {
  accountPlatformsLoaded,
  accountPlatformsReset,
  activeAccountPlatformSet,
  accountPlatformAdded,
  accountPlatformChanged,
  accountPlatformRemoved,
  facebookBusinessAssetAdded,
  facebookBusinessAssetRemoved,
  facebookBusinessAssetUpdated,
} = accountPlatformsSlice.actions;

interface State {
  accountPlatforms: AccountPlatformState;
}

export const getAccountPlatforms = (state: State) => state.accountPlatforms.platforms;

export const getAccountPlatform =
  (accountPlatformId) =>
  (state: State): AccountPlatform | {} => {
    return state.accountPlatforms.platforms.find((element) => element.id === accountPlatformId) || {};
  };

export const getFacebookAccountPlatforms = (state: State) =>
  state.accountPlatforms.platforms.filter((ap) => ap.platform.name === Platforms.FACEBOOK);

export const hasFacebookAccountPlatforms = (state: State) => {
  const platforms = getFacebookAccountPlatforms(state);
  return !!platforms.length;
};

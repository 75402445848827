import React from 'react';
import cs from 'classnames';
import { renderToStaticMarkup } from 'react-dom/server';
import { CONSTRAINT_LIMITS } from 'shared/config/validations';
import { decodeHtml } from 'shared/utilities/domUtility';

const TagNumberComponent: React.FC<any> = ({ props: [tagData] }) => {
  const { value, class: className } = tagData;
  const pattern = new RegExp(`^[0-9]{1,${CONSTRAINT_LIMITS.TAG_MAX_LENGTH}}$`);
  const tagTooLong = !pattern.test(decodeHtml(value) || '');

  return (
    <tag
      title={value}
      contentEditable={false}
      spellCheck={false}
      className={cs({
        tagify__tag: true,
        [className]: !!className,
        'tagify__tag--invalid': tagTooLong,
      })}
    >
      <x aria-label="remove tag" title="remove tag" className="tagify__tag__removeBtn" />
      <span className="tagify__tag-text">{value}</span>
    </tag>
  );
};

const TagNumber = (...props) => renderToStaticMarkup(<TagNumberComponent props={props} />);

export default TagNumber;

import * as yup from 'yup';

import { YUP_FIELDS } from 'shared/utilities/yupFieldUtility';

export const PROFILE_MODAL_FIELDNAMES = {
  // fieldnames
  SUMMARY: 'summary',
  PROFILE_PICTURE: 'profilePic',
  PORTFOLIO_LINK: 'portfolioLink',
  TIKTOK: 'tiktok',
};

export const profileModalSchema = yup.object({
  [PROFILE_MODAL_FIELDNAMES.SUMMARY]: yup.string(),
  [PROFILE_MODAL_FIELDNAMES.PORTFOLIO_LINK]: YUP_FIELDS.PORTFOLIO_LINK_OPTIONAL,
  [PROFILE_MODAL_FIELDNAMES.TIKTOK]: yup.string().nullable().tiktokHandle(),
});

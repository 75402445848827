import React from 'react';
import { Element } from 'slate';
import { RenderElementProps } from 'slate-react';

import { Text } from 'shared/components/display';

import { addHttp } from 'shared/utilities/stringUtility';

interface Props {
  className?: string;
  element: Pick<Element, 'url'>;
  variant?: string;
  attributes?: any;
  onClick?: (e) => void;
  disabled?: boolean;
}

const RichTextLink: React.FC<Omit<RenderElementProps, 'element' | 'attributes'> & Props> = ({
  attributes,
  children,
  element,
  className,
  variant,
  onClick,
  disabled,
}) => {
  return (
    <Text
      {...attributes}
      as="a"
      className={className}
      variant={variant}
      // @ts-ignore
      target="_blank"
      rel="noopener noreferrer"
      href={addHttp(element.url)}
      tabIndex={0}
      onClick={(e) => {
        if (!disabled && onClick) onClick(e);
      }}
    >
      {children}
    </Text>
  );
};

export default RichTextLink;

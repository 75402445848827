import * as yup from 'yup';

export const PAYOUT_FORM_FIELD = Object.freeze({
  NOTE: 'note',
  ACCOUNT: 'account',
  AMOUNT: 'amount',
  PRESET: 'preset',
});

export const schema = yup.object({
  [PAYOUT_FORM_FIELD.ACCOUNT]: yup
    .object()
    .shape({
      value: yup.string().customRequiredString().nullable(),
    })
    .nullable()
    .required('This field is required.'),
  [PAYOUT_FORM_FIELD.AMOUNT]: yup
    .string()
    .money({
      max: 5000,
      min: 1,
      message: 'Amount must be between $1 and $5,000',
    })
    .nullable()
    .required(),
  [PAYOUT_FORM_FIELD.NOTE]: yup.string().required('This field is required.'),
});

export const COPY = Object.freeze({
  SUBMIT: 'Confirm Creator Payment',
  PAY: 'Pay Creator',
  MODAL_TITLE: 'Manual Payout',
  TOOLTIP_CONTENT: 'No creator Stripe account linked to receive payment',
});

export const PLACEHOLDER = Object.freeze({
  AMOUNT: 'Amount',
  PRESET: 'Preset',
  BRAND: 'Brand',
});

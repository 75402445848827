import React, { useEffect } from 'react';
import { Button } from 'rebass/styled-components';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from 'config';
import CryptoJS from 'crypto-js';

import { Flex, Text } from 'shared/components/display';

import { useQuery } from 'shared/hooks/useQuery';
import { useToasts } from 'shared/hooks/useToasts';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

const TIKTOK_CONNECT_LABEL = 'Connect with TikTok';
const TIKTOK_CONNECTED_LABEL = 'Connected';

function generateRandomString(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function initiateTiktokAuth(redirectURI: string, isBusiness: boolean, userId: number | undefined): void {
  const state = JSON.stringify({
    redirectURI: encodeURIComponent(redirectURI),
    userId,
    // @TODO set up csrfState token under BRKFST-178; will need to save token somewhere so might be best to move this redirect to backend
    // https://developers.tiktok.com/doc/login-kit-web/
    // token: Math.random().toString(36).substring(2),
  });
  const codeChallenge = CryptoJS.SHA256(generateRandomString(64)).toString(CryptoJS.enc.Hex);
  // @TODO: Potentially replace URL with environment variable due to the nature of TikTok Redirect URIs
  const url = isBusiness
    ? `https://ads.tiktok.com/marketing_api/auth?app_id=${config.TIKTOK_APP_ID}&state=${state}&redirect_uri=${config.TIKTOK_REDIRECT_URI}&rid=${config.TIKTOK_RID}`
    : `https://www.tiktok.com/v2/auth/authorize/?client_key=${config.TIKTOK_CLIENT_KEY}&response_type=code&scope=${config.TIKTOK_USER_SCOPES}&redirect_uri=${config.API_URL}${config.TIKTOK_CALLBACK_URL}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
  window.location.assign(url);
}

const TiktokConnect: React.FC<{
  tiktokConnected?: boolean;
  redirectURI: string;
  isBusinessLogin?: boolean;
  userId?: number;
}> = ({ tiktokConnected = false, redirectURI, isBusinessLogin = true, userId }) => {
  const { getQuery } = useQuery();
  const { tiktokError, adAccounts } = getQuery();
  const { setErrorToast } = useToasts();

  useEffect(() => {
    if (tiktokError) setErrorToast({ heading: 'Tiktok', message: tiktokError });
  }, [tiktokError]);

  return (
    <Flex css={styles} className="tiktok-connect">
      <Button
        onClick={() => initiateTiktokAuth(redirectURI, isBusinessLogin, userId)}
        disabled={tiktokConnected || adAccounts?.length}
        className="tiktok-connect__button"
        type="button"
      >
        <FontAwesomeIcon icon={faTiktok} className="tiktok-connect__tiktok-icon" fixedWidth />
        {TIKTOK_CONNECT_LABEL}
      </Button>
      {tiktokConnected && (
        <Text as="h3" variant={TEXT_VARIANTS.SUBHEADING} className="tiktok-connect__connected">
          {TIKTOK_CONNECTED_LABEL}
        </Text>
      )}
    </Flex>
  );
};

export default TiktokConnect;

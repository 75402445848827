import React, { useMemo, useState } from 'react';

import DownloadFilesButton from 'features/media/components/molecules/DownloadFilesButton';
import { useOrganizations } from 'features/organizations/useOrganizations';
import ModuleHeaderIcons from 'features/submissions/components/molecules/ModuleHeaderIcons';
import RevisionsList from 'features/submissions/components/molecules/RevisionsList';
import SubmissionModuleStatusSelect from 'features/submissions/components/molecules/SubmissionModuleStatusSelect';
import ModuleAssets from 'features/submissions/components/organisms/ModuleAssets';
import { useSubmissions } from 'features/submissions/useSubmissions';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { useRightDrawer } from 'features/ui/useRightDrawer';
import { Box, Flex } from 'shared/components/display';
import Divider from 'shared/components/molecules/Divider';
import ToggleGalleryListView from 'shared/components/molecules/ToggleGalleryListView';
import ModuleReview from 'shared/components/organisms/ModuleReview';

import { COLLECTION_COPY } from 'shared/config/copy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { usePermissions } from 'shared/hooks/usePermissions';
import { intl } from 'shared/lib/intl';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstFile } from 'shared/typings/file';
import { Submission, SubmissionStatus } from 'shared/typings/submissions';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import theme from 'shared/styles/themes';

import styles from './styles';

const MODULE_COLLECTION_NAME = 'Module Files';

interface Props {
  submission: Submission;
}

const SubmissionModules: React.FC<Props> = ({ submission }) => {
  const { isAuthorized } = usePermissions();
  const [selectedFiles, setSelectedFiles] = useState<BrkfstFile[]>([]);
  const { loading } = useSubmissions();
  const { organizations } = useOrganizations();
  const { setRightDrawer } = useRightDrawer(true);

  const [moduleLoading, setModuleLoading] = useState<number | null>(null);
  const {
    brief: {
      id: briefId,
      organizationId,
      accountId,
      name: briefName,
      account: { name: brandName },
    },
    creatorId,
    moduleSubmissions,
  } = submission;
  const downloadInfo = useMemo(
    () => ({
      brandName,
      briefName,
      collectionName: MODULE_COLLECTION_NAME,
      orgName: organizations.find(({ id }) => id === organizationId)?.name || brandName,
    }),
    [brandName, briefName, organizations, organizationId],
  );
  const isDraft = submission.status === SubmissionStatus.DRAFT;

  const canUpdateModuleState = isAuthorized(organizationId, PERMISSIONS_MAP.UPDATE_MODULE_STATE.API);

  const onOpenFeedbackDrawer = (revisions, moduleNum) => {
    if (revisions?.length) {
      return setRightDrawer({
        key: moduleNum,
        Content: <RevisionsList revisions={revisions} brandName={brandName} />,
        headerText: intl.formatMessage(
          {
            id: 'DRAWER_TITLE_REVISIONS',
          },
          {
            briefName,
            moduleNum,
          },
        ),
      });
    }
  };

  if (loading) {
    return <CenteredSpinner />;
  }

  if (moduleSubmissions.length === 0) {
    return <></>;
  }

  const reviewLink = LinkCreator.createLink({
    userType: UserType.ORG,
    routeKey: 'VIEW_INDIVIDUAL_BRIEF',
    variables: {
      organizationId: organizationId,
      accountId: accountId,
      briefId: briefId,
    },
    query: {
      activeTab: 3,
      openLightbox: true,
      sId: submission.id,
    },
  });

  const showModuleReview = isAuthorized(organizationId, PERMISSIONS_MAP.REVIEW_MODULES.API);

  return (
    <Box className="submission-modules" css={styles}>
      <Flex className="submission-modules__control-wrapper">
        {showModuleReview && (
          <ModuleReview
            margin={'0 auto 0 0'}
            copyLinkMargin={`0.2rem ${theme.space[4]}`}
            alignItems="start"
            organizationId={organizationId}
            accountId={accountId}
            briefId={briefId}
            briefName={briefName}
            brandName={brandName}
            submissionId={submission.id}
            reviewLink={reviewLink}
          />
        )}
        <ToggleGalleryListView />
      </Flex>
      <Flex>
        {Boolean(selectedFiles.length) && (
          <DownloadFilesButton
            selectedFiles={selectedFiles}
            downloadInfo={downloadInfo}
            tooltip={COLLECTION_COPY.TOOLTIP_DOWNLOAD_SELECTED_FILES}
            promptTitle={COLLECTION_COPY.MODAL_DOWNLOAD_FILES}
          />
        )}
      </Flex>
      {moduleSubmissions.map((submissionModule) => {
        return (
          <Box key={submissionModule.moduleNum} dataCy={`submission-modules__mod-${submissionModule.moduleNum}`}>
            <Divider
              heading={`Module ${submissionModule.moduleNum}`}
              Component={
                isDraft ? undefined : (
                  <Flex className="submission-modules__header">
                    <ModuleHeaderIcons
                      status={submissionModule.status}
                      isModuleLoading={moduleLoading === submissionModule.id}
                      revisions={submissionModule.revisions}
                      onClick={() => onOpenFeedbackDrawer(submissionModule.revisions, submissionModule.moduleNum)}
                      moduleNum={submissionModule.moduleNum}
                    />
                    {canUpdateModuleState && (
                      <SubmissionModuleStatusSelect
                        className="submission-modules__status-select"
                        creatorId={creatorId}
                        setModuleLoading={setModuleLoading}
                        submissionModule={submissionModule}
                        briefId={briefId}
                      />
                    )}
                  </Flex>
                )
              }
              variant={TEXT_VARIANTS.H6}
            />
            <ModuleAssets
              assets={submissionModule.files}
              status={submissionModule.status}
              organizationId={organizationId}
              setSelectedFiles={setSelectedFiles}
              selectedFiles={selectedFiles}
              moduleNum={submissionModule.moduleNum}
              accountId={accountId}
              moduleId={submissionModule.id}
              briefName={briefName}
              brandName={brandName}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default SubmissionModules;

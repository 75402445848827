import { faFileImage, faFilePdf, faFileVideo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MEDIA } from 'shared/config/media';
import COLORS from 'shared/styles/colors';
import React from 'react';

interface Props {
  mimeType: string;
  className?: string;
}
const FileIcon: React.FC<Props> = ({ mimeType, className = '' }) => {
  const [fileType] = mimeType.split('/');
  switch (fileType) {
    case MEDIA.TYPES.APPLICATION_FILE:
      return (
        <FontAwesomeIcon
          icon={faFilePdf}
          color={'#B30B00'} // Adobe Acrobat's red color
          className={className}
        />
      );
    case MEDIA.TYPES.VIDEO:
      return <FontAwesomeIcon icon={faFileVideo} color={COLORS.PRIMARY500} className={className} />;
    case MEDIA.TYPES.IMAGE:
    default:
      return <FontAwesomeIcon icon={faFileImage} color={COLORS.GREEN500} className={className} />;
  }
};

export default FileIcon;

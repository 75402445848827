import { MEDIA } from 'shared/config/media';
import { intl } from 'shared/lib/intl';
import { calculateBufferedDurationMax, calculateBufferedDurationMin } from 'shared/lib/validationTests';
import { ModuleFileType } from 'shared/typings/briefs';
import { BrkfstFile } from 'shared/typings/file';

import { formatAspectRatio } from '../fileUtility';

import type { Validator, ValidatorOptions } from './types';

export class SubmissionValidator implements Validator {
  constructor(
    private minAssetDuration: number,
    private maxAssetDuration: number,
    private moduleNum: number,
    private moduleFileType: ModuleFileType,
    private allSubmittedFiles?: Pick<BrkfstFile, 'id' | 'name' | 'metadata' | 'submissionValidatorFields'>[],
  ) {
    // Usually not necessary to do this with constructor shorthand, but Jest wasn't playing nicely.
    this.maxAssetDuration = maxAssetDuration;
    this.minAssetDuration = minAssetDuration;
    this.moduleNum = moduleNum;
    this.moduleFileType = moduleFileType;
    this.allSubmittedFiles = allSubmittedFiles;
  }

  public validate({ fileName, metadata }: ValidatorOptions) {
    const bufferedMinAssetDuration = calculateBufferedDurationMin(this.minAssetDuration);
    const bufferedMaxAssetDuration = calculateBufferedDurationMax(this.maxAssetDuration);
    const roundedFileDuration = Math.round(metadata?.duration || 0);

    const moduleNum = this.moduleNum;
    const errorMessages: Array<string> = [];

    if (this.moduleFileType !== 'image' && metadata?.duration) {
      if (roundedFileDuration < bufferedMinAssetDuration) {
        errorMessages.push(
          intl.formatMessage({ id: 'VALIDATION_MODULE_DURATION_BELOW' }, { min: this.minAssetDuration, moduleNum }),
        );
      } else if (roundedFileDuration > bufferedMaxAssetDuration) {
        errorMessages.push(
          intl.formatMessage({ id: 'VALIDATION_MODULE_DURATION_EXCEEDS' }, { max: this.maxAssetDuration, moduleNum }),
        );
      }
    }
    this.validateFileType(errorMessages, metadata.mimeType);
    if (fileName) this.validateFileIsNotSubmitted(errorMessages, fileName, metadata);
    return errorMessages;
  }

  private validateFileType(errors: string[], mimeType: string): void {
    const isGif = mimeType === MEDIA.MIME_TYPES.GIF;
    if ((isGif && this.moduleFileType === 'gif') || (!isGif && mimeType.includes(this.moduleFileType))) return;
    else {
      errors.push(
        intl.formatMessage(
          { id: 'VALIDATION_MODULE_FILETYPE_MISMATCH' },
          { moduleNum: this.moduleNum, fileType: this.moduleFileType },
        ),
      );
    }
  }

  private validateFileIsNotSubmitted(errors: string[], fileName: string, metadata: BrkfstFile['metadata']): void {
    const fileNameWithoutExtension = fileName.split('.')[0];
    const alreadySubmitted =
      this.allSubmittedFiles && fileName
        ? Boolean(this.allSubmittedFiles.find(({ name }) => name.split('.')[0] === fileNameWithoutExtension))
        : false;
    const aspectRatio = formatAspectRatio(metadata);
    const mimeType = metadata.mimeType;
    let fileType = mimeType?.split('/')[0] || '';
    if (mimeType === MEDIA.MIME_TYPES.GIF) fileType = 'gif';
    if (alreadySubmitted) {
      errors.push(
        `Another ${fileType} file with the ${aspectRatio} aspect ratio has already been submitted to this module`,
      );
    }
  }
}

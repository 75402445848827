import { useCallback, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { accountPlatformHealthLoaded } from 'features/accountPlatforms/accountPlatformHealth.slice';
import { accountProgressUpdated } from 'features/ui/ui.slice';

import { SignalRContext } from 'shared/contexts/signalr';
import { useToasts } from 'shared/hooks/useToasts';

export interface AccountPlatformHealth {
  id?: number;
  dateCreated: Date;
  score: number;
  accountPlatformId: number;
}

export interface AccountPlatformHealthContributor {
  id?: number;
  dateUpdated: Date;
  accountPlatformHealthId: number;
  contributorId: number;
  value: number;
}

export interface AccountScorePayload {
  accountPlatformHealth: AccountPlatformHealth;
  accountPlatformHealthContributors: AccountPlatformHealthContributor[];
}

export interface AccountScoreProgressPayload {
  progress: number;
  userId: number;
  accountPlatformId: number;
}

export interface AccountScoreErrorPayload {
  error: string;
  userId: number;
  accountPlatformId: number;
}

export const useAccountScoreNotification = () => {
  const dispatch = useDispatch();
  const { connection } = useContext(SignalRContext);
  const { setErrorToast } = useToasts();
  const accountScoreHandler = useCallback(
    (payload: AccountScorePayload) => {
      dispatch(accountPlatformHealthLoaded({ data: payload }));
    },
    [dispatch],
  );

  const accountProgressHandler = useCallback(
    (payload: AccountScoreProgressPayload) => {
      dispatch(accountProgressUpdated(payload));
    },
    [dispatch],
  );

  const accountScoreErrorHandler = (payload: AccountScoreErrorPayload) => {
    setErrorToast({
      message: payload.error,
    });
  };

  useEffect(() => {
    if (connection) {
      connection.on('accountScore', accountScoreHandler);
      connection.on('accountScoreProgress', accountProgressHandler);
      connection.on('accountScoreError', accountScoreErrorHandler);
    }

    return function cleanup() {
      connection?.off('accountScore', accountScoreHandler);
      connection?.off('accountScoreProgress', accountProgressHandler);
      connection?.off('accountScoreError', accountScoreErrorHandler);
    };
  }, [connection]);
};

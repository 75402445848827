import 'tippy.js/themes/light-border.css';

import React, { useRef } from 'react';
import { faImage } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSlateStatic } from 'slate-react';

import { useSasToken } from 'features/media/useSasToken';
import HiddenFileInput from 'shared/components/atoms/HiddenFileInput';
import { uploadRichtextImage } from 'shared/components/Richtext/RichtextPicture/utils';

import { BRKFST_SUPPORTED_IMAGE_FORMATS } from 'shared/config/fileFormats';
import { MEDIA } from 'shared/config/media';
import { useToasts } from 'shared/hooks/useToasts';

const RICHTEXT_ACCEPTED_MEDIA = [...BRKFST_SUPPORTED_IMAGE_FORMATS, MEDIA.MIME_TYPES.GIF];

const RichtextPictureIcon: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { setErrorToast } = useToasts();
  const sasToken = useSasToken();
  const editor = useSlateStatic();

  const setError = (error: string) => setErrorToast({ message: error });

  const handleClick = () => {
    inputRef.current?.click();
  };

  const onChange = (files) => {
    if (sasToken) uploadRichtextImage(editor, sasToken, files);
  };

  return (
    <>
      <FontAwesomeIcon icon={faImage} className="richtext-tool-bar__icon" onClick={handleClick} />
      <HiddenFileInput
        name="hidden_input"
        onChange={onChange}
        onError={setError}
        acceptedMedia={RICHTEXT_ACCEPTED_MEDIA}
        ref={inputRef}
        validateFiles
      />
    </>
  );
};

export default RichtextPictureIcon;

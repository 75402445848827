export const SUBMISSION_COPY = {
  // Buttons
  BUTTON_MARK_NEW: 'Mark as new',
  BUTTON_MARK_IMPORTANT: 'Mark as Important',
  BUTTON_MARK_NOT_IMPORTANT: 'Mark as not important',
  BUTTON_VIEW_CREATOR: 'View Creator Profile',
  BUTTON_RETURN_TO_BRIEF: 'Return to Brief',
  BUTTON_VIEW_BRIEF: 'Brief',
  BUTTON_EDIT_SUBMISSION: 'Edit Submission',
  BUTTON_EDIT_SUBMISSION_DRAFT: 'Finish Submission',
  BUTTON_REVIEW_ERRORS: 'Submit Critical Error',
  BUTTON_REVIEW_SUBMIT: 'Submit Feedback',
  BUTTON_APPROVE_MODULE: 'Approve',
  BUTTON_APPROVE_MODULE_WITH_REVISIONS: 'Approve w/ Revisions',
  BUTTON_ALL_SUBMISSIONS: 'View All Submissions',
  BUTTON_REVIEW_COMMENTS: 'Review Comments ({count})',
  BUTTON_SAVE_SUBMISSION_DRAFT: 'Save Draft',
  BUTTON_SUBMIT_SUBMISSION: 'Submit',
  BUTTON_MODULES_TO_REVIEW: '{num} {num, plural, =1 {Module} other {Modules}} to Review',
  BUTTON_ADD_TO_TREND: 'Add to Trends Hub',

  // Labels
  LABEL_APPROVED_ASSET_SUBMISSION: 'Approved',
  LABEL_BRIEF: 'Brief',
  LABEL_BRAND: 'Brand',
  LABEL_SUBMISSION_DATE: 'Date Submitted',
  LABEL_DESCRIPTION: 'Description',
  LABEL_COMMENT: 'Comments',

  // Columns
  COLUMN_SUBMISSION_DATE: 'Date Submitted',
  COLUMN_SUBMISSION_DEADLINE: 'Deadline',
  COLUMN_BRIEF_SUBMISSION: 'Brief',
  COLUMN_BRIEF_STATUS: 'Status',
  COLUMN_CREATOR_SUBMISSION: 'Creator',
  COLUMN_NUM_APPROVED: '# Approved',
  COLUMN_BRAND_SUBMISSION: 'Brand',

  // placeholders
  PLACEHOLDER_DESCRIPTION: 'No Description',
  PLACEHOLDER_COMMENT: 'No Comments',
  PLACEHOLDER_SUBMISSIONS: 'No submissions to display',
  PLACEHOLDER_NONE_SELECTED: 'No Submission Selected',
  PLACEHOLDER_SUBMITTED_MODULES: 'File(s) not ready for review',
  PLACEHOLDER_REVIEW_COMMENTS: 'No comments yet',

  // Prompt
  PROMPT_DELETE_REVIEW_COMMENT: 'Are you sure you want to delete this comment?',
  PROMPT_SUBMIT_SUBMISSION: 'Post this submission? Once posted, brands will be able to review it.',

  // Headings
  HEADING_SUBMISSION_CREATION: 'Your Submission',
  HEADING_SUBMISSION_CREATION_BRIEF_NAME: 'Submission to: {name}',

  // Captions
  CAPTION_REVIEW_NAME: 'Module {moduleNum}',
  CAPTION_REVIEW_MODULES_REMAINING: '{numRemaining} module(s) remaining',
  CAPTION_REVIEW_REVISION_HEADER: 'Module {moduleNum} Revisions',
  CAPTION_REVIEW_COMMENT_HEADER: 'Module {moduleNum} Comments',

  // Modal
  MODAL_TITLE_REVIEW: 'Critical Error: {errorCategory}',
  DRAWER_TITLE_REVISIONS: '{briefName} Module {moduleNum} Revisions',

  // Tooltip
  MODULE_REVISION_TOOLTIP: 'Module requires resubmission',
  MODULE_APPROVED_TOOLTIP: 'Module is ready for ad creation',
  MODULE_SUBMITTED_TOOLTIP: 'Module will be approved for review shortly',
  MODULE_REVIEW_TOOLTIP: 'Module is under review by the brand',
  MODULE_REVIEW_ICON_TOOLTIP: 'Click to show/hide feedback',
  COPY_MODULE_LINK: 'Copy Module Review Link',
  SUBMITTED_TOOLTIP: 'Click to view your submission',

  // Checkout
  CHECKOUT_SUBMIT_TEXT: 'Pay Creator for Submission',
  CHECKOUT_HEADER: 'Creator Payout',
  CHECKOUT_SUBHEADER: 'Submission Overview',
  CHECKOUT_BACK_BUTTON_TEXT: 'Back to Submission',
  CHECKOUT_LINE_ITEM_DESCRIPTION: 'Submission Payout',
  CHECKOUT_PAYOUT_CONFIRM_LEAVE_PROCESSING:
    'Are you sure you want to leave this page while we are processing your payout?',

  // Paid
  SUBMISSION_PAID: 'Paid',
  SUBMISSION_UNPAID: 'Pay',

  // Validation Errors
  VALIDATION_MODULE_DURATION_BELOW: "Below module {moduleNum}'s {min}s min",
  VALIDATION_MODULE_DURATION_EXCEEDS: "Above module {moduleNum}'s {max}s max",
  VALIDATION_MODULE_FILETYPE_MISMATCH: "Module {moduleNum}'s file type is {fileType}",
  VALIDATION_MODULE_DUPLICATE: 'Another module is using this file',
  VALIDATION_MODULE_MAX_FILES:
    'The maximum number of uploads per module is two. Please try again with fewer uploads, or remove existing uploads from the module.',

  SUBMISSION_ASSET_DISABLED_COPY: `You have reached the maximum limit of two uploads per module.\nPlease remove one of your existing uploads before attempting to upload a new one.`,

  TOOLTIP_NO_STRIPE_ACCOUNT: "Creator hasn't connected Stripe yet",
  TOOLTIP_SUBMISSION_PAYOUT: "Brief's submission payout is ${creatorPayout}",
  TOOLTIP_REJECTED_FROM_BRIEF: 'You are no longer approved to submit to this brief',

  // Tabs
  TAB_TITLE_SUBMISSION: 'Submission',
  TAB_TITLE_SUBMISSION_BRIEF: 'Brief',

  MODAL_TITLE_ADD_TO_TREND: 'Add New Trend',
  MODAL_FORM_DESCRIPTION_TREND: 'Create a name for this new trend.',
};

import React from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import getStyles from './styles';

interface Props {
  src: string;
  width?: string | number;
  height?: string | number;
  className?: string;
}
/**
 *
 * @description Displays an image scaled to parent container's height while maintaining image's aspect ratio
 *
 */
const ScaledImage: React.FC<Props> = ({ src, width = '', height = '', className }) => {
  return <Box css={getStyles(src, width, height)} className={cs('scaled-image', className)} />;
};

export default ScaledImage;

import {
  errorToastActivated,
  infoToastActivated,
  successToastActivated,
  warningToastActivated,
} from 'shared/actions/toasts';
import { Toast } from 'shared/typings/toasts';
import { useDispatch } from 'react-redux';

export const useToasts = () => {
  const dispatch = useDispatch();

  const setErrorToast = ({ message, heading }: Toast) => {
    dispatch(errorToastActivated({ message, heading }));
  };

  const setSuccessToast = ({ message, heading }: Toast) => {
    dispatch(successToastActivated({ message, heading }));
  };

  const setWarningToast = ({ message, heading }: Toast) => {
    dispatch(warningToastActivated({ message, heading }));
  };

  const setInfoToast = ({ message, heading }: Toast) => {
    dispatch(infoToastActivated({ message, heading }));
  };

  return {
    setErrorToast,
    setSuccessToast,
    setWarningToast,
    setInfoToast,
  };
};

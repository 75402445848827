import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from 'rebass/styled-components';

import { briefToBriefFormState, defaultBriefTemplate } from 'features/briefs/components/BriefForm/utils';
import BriefTemplateForm from 'features/briefs/components/BriefTemplateForm/';
import { FormBriefTemplate } from 'features/briefs/components/BriefTemplateForm/types';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import { useFiles } from 'features/media/useFiles';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import { componentLoadingToggled } from 'features/ui/ui.slice';

import { ALL_BRKFST_SUPPORTED_FILE_FORMATS } from 'shared/config/fileFormats';
import { RESOURCES } from 'shared/config/resourceNames';
import { useFileNotification } from 'shared/hooks/useFileNotification';
import { CLASSNAMES } from 'shared/styles/containers';
import { BriefStatus } from 'shared/typings/briefs/enums';
import { UploadOperation } from 'shared/typings/file';
import { TagType } from 'shared/typings/tag/enums';
import { GeneralValidator } from 'shared/utilities/validator';

const EditBriefTemplate: React.FC = () => {
  const params = useParams<{ briefTemplateId: string }>();
  const briefTemplateId = +params.briefTemplateId;
  const { editBriefTemplate, fetchBriefTemplate, briefTemplate, loadingBriefTemplates } = useBriefs(briefTemplateId);
  const { formatMessage } = useIntl();
  useFileNotification();
  const dispatch = useDispatch();

  const { initiateMultipleFilesUpload } = useFiles();
  const uploadBriefAssets = async (briefId: number, files: { file: File }[]) => {
    dispatch(
      componentLoadingToggled({
        component: RESOURCES.BRIEF_ASSET,
        loading: true,
      }),
    );
    const formattedFiles: UploadOperation[] = files.map(({ file }) => ({
      file,
      metadata: {
        isBrandAsset: true,
        briefId,
      },
    }));

    await initiateMultipleFilesUpload(formattedFiles, new GeneralValidator(ALL_BRKFST_SUPPORTED_FILE_FORMATS));
  };

  const defaultValues = useMemo(() => {
    if (briefTemplateId && briefTemplateId > 0) {
      return briefToBriefFormState(briefTemplate);
    } else {
      return defaultBriefTemplate();
    }
  }, [briefTemplateId, briefTemplate]);

  useEffect(() => {
    if (briefTemplateId) {
      fetchBriefTemplate(briefTemplateId);
    }
  }, [briefTemplateId]);

  const handleEditBrief = (values: FormBriefTemplate) => {
    const { creatorPayout, brandAssets: { newFiles = [], assets = [] } = {}, description, modules, states } = values;
    const updatedBriefTemplate = {
      ...values,
      name: values.name.trim(),
      description: JSON.stringify(description), // richText
      modules: modules.map(({ durationRange, content, scenes, ...mod }, idx) => {
        const [minAssetDuration, maxAssetDuration] = durationRange;
        return {
          ...mod,
          tags: mod.tags.map((tag) => ({ ...tag, type: TagType.MODULES })),
          content: JSON.stringify(content),
          name: formatMessage({ id: 'FORM_MODULE_DISPLAY' }, { num: idx + 1 }),
          minAssetDuration,
          maxAssetDuration,
          scenes: scenes.map(({ description, title, id }, idx) => ({
            id,
            title,
            order: idx + 1,
            description: JSON.stringify(description),
          })),
        };
      }), // richText
      brandAssets: assets.map(({ id }) => id),
      status: BriefStatus.DRAFT,
      creatorPayout: +creatorPayout * 100, //Convert to cents from dollar
      states: states?.map(({ value }) => value),
      id: briefTemplateId,
    };
    editBriefTemplate({
      data: updatedBriefTemplate,
      onSuccess: ({ data }) => {
        if (newFiles.length) uploadBriefAssets(data.id, newFiles);
      },
    });
  };

  return loadingBriefTemplates ? (
    <CenteredSpinnerContainer />
  ) : (
    <Box className={CLASSNAMES.MAIN_CONTAINER}>
      <BriefTemplateForm defaultValues={defaultValues} onSaveTemplate={handleEditBrief} />
    </Box>
  );
};

export default EditBriefTemplate;

import { useMessaging } from 'features/messages/useMessaging';
import { MESSAGE_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { BrkfstAccount } from 'shared/typings/account';
import { BrkfstUser } from 'shared/typings/user';
import React from 'react';
import { Button } from 'shared/components/display';

import styles from './styles';

interface Props {
  user?: BrkfstUser;
  account: BrkfstAccount;
  variant?: string;
}

const MessageButton: React.FC<Props> = ({ user, account, variant = BUTTON_VARIANTS.PLAIN }) => {
  const { currentUser } = useCurrentUser();
  const { openAndRedirectToChannel } = useMessaging();
  const messageUser = user || currentUser;

  return (
    <Button
      css={styles}
      variant={variant}
      onClick={() => openAndRedirectToChannel(messageUser, account)}
      className="message-button"
    >
      {MESSAGE_COPY.BUTTON_MESSAGE}
    </Button>
  );
};

export default MessageButton;

import { useContext, useEffect } from 'react';
import { Editor } from 'slate';

import { removeImage, updateImage } from 'shared/components/Richtext/RichtextPicture/utils';

import { SignalRContext } from 'shared/contexts/signalr';
import { useToasts } from 'shared/hooks/useToasts';

export const useRichtextImages = (editor: Editor, readOnly: boolean = false) => {
  const { connection } = useContext(SignalRContext);
  const { setErrorToast } = useToasts();

  const onInvalidFile = ({ key, error }: { key: string; error: string }) => {
    setErrorToast({ message: error });
    removeImage(editor, key);
  };

  const onImageUpload = (file) => {
    updateImage(editor, file);
  };

  useEffect(() => {
    if (connection && !readOnly) {
      connection.on('newRichtextFile', onImageUpload);
      connection.on('richtextUploadError', onInvalidFile);
    }

    return function cleanup() {
      if (connection && !readOnly) {
        connection?.off('newRichtextFile', onImageUpload);
        connection?.off('richtextUploadError', onInvalidFile);
      }
    };
  }, [connection]);
};

import { USER_COPY } from 'shared/config/copy';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import React from 'react';

const UserInfo = ({ user }) => {
  return (
    <DefinitionList>
      <ListDetail text={USER_COPY.LABEL_FIRST_NAME}>{user.firstName}</ListDetail>
      <ListDetail text={USER_COPY.LABEL_LAST_NAME}>{user.lastName}</ListDetail>
      <ListDetail text={USER_COPY.LABEL_EMAIL}>{user.email}</ListDetail>
      <ListDetail text={USER_COPY.LABEL_SECONDARY_EMAIL} hide={!user.secondaryEmail}>
        {user.secondaryEmail}
      </ListDetail>
    </DefinitionList>
  );
};

export default UserInfo;

import 'tippy.js/themes/light-border.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { Editor, Element as SlateElement, NodeEntry, Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';

import IconButton from 'shared/components/atoms/IconButton';
import Tooltip from 'shared/components/atoms/Tooltip';
import { Box } from 'shared/components/display';
import { RichTextLink, RichTextLinkMenu } from 'shared/components/Richtext/RichTextLink';

import { ELEMENTS } from 'shared/styles/styles';

import styles from './richTextLinkWithMenuStyles';
interface Props {
  element: SlateElement;
  richTextElem?: React.ReactElement;
  children: any;
}

const RichTextLinkWithMenu: React.FC<Props> = (props) => {
  const [isEditMode, setEditMode] = useState(false);
  const [node, setNode] = useState<NodeEntry | any[]>([]);
  const editor = useSlateStatic();
  const [showMenu, setShowMenu] = useState(false);
  // Prevent the button being clicked multiple times repeatedly
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const menuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (editor.selection) {
      const linkNode = Editor.parent(editor, editor.selection.anchor.path);
      setNode(linkNode);
    }
  }, [editor.selection]);

  const hideMenu = useCallback(() => {
    setEditMode(false);
    setShowMenu(false);
    ReactEditor.focus(editor);
    ReactEditor.blur(editor);
  }, [editor]);

  const selectLinkNode = (event?: Event) => {
    // This will ensure the editLink method will always have the right node
    event?.preventDefault();
    try {
      const path = ReactEditor.findPath(editor, props.element);

      const offset = 1;
      const point = {
        path,
        offset,
      };
      const selection = {
        anchor: point,
        focus: point,
      };

      Transforms.select(editor, selection);
    } catch (error) {
      return;
    }
  };

  const handleMenuBlur = (e) => {
    const target = e?.relatedTarget || e.target;
    if (!target.className.includes('rich-text-link-menu') && !target.className.includes('rich-text__editor')) {
      setShowMenu(false);
      setEditMode(false);
    }
  };

  const handleLinkClick = (e) => {
    if (!isButtonDisabled) {
      setButtonDisabled(true);
      selectLinkNode(e);
      setShowMenu(true);
      setTimeout(() => setButtonDisabled(false), 1000); // Re-enable after 1 second
    }
  };
  useEffect(() => {
    if (showMenu) {
      menuRef.current?.focus();
    }
  }, [showMenu]);

  return (
    <Box className="rich-text-link-with-menu" css={styles}>
      <RichTextLink className="rich-text__link" {...props} onClick={handleLinkClick} />
      {showMenu ? (
        <Box className="rich-text-link-with-menu__menu">
          <RichTextLinkMenu
            element={props.element}
            setEditMode={setEditMode}
            isEditMode={isEditMode}
            onHide={hideMenu}
            editor={editor}
            node={node}
            onBlur={(e) => {
              handleMenuBlur(e);
            }}
            disabled={isButtonDisabled}
            ref={menuRef}
          />
          <Tooltip
            content="Close Link Menu"
            as={ELEMENTS.SPAN}
            arrow
            placement="bottom"
            offset={[0, 15]}
            className="rich-text-link-with-menu__tooltip"
          >
            <IconButton icon={faCircleXmark} onClick={hideMenu} className="rich-text-link-with-menu__close-btn" />
          </Tooltip>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default RichTextLinkWithMenu;

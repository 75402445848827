import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Image, Text } from 'rebass/styled-components';

import ModuleHeaderIcons from 'features/submissions/components/molecules/ModuleHeaderIcons';
import { useSubmissions } from 'features/submissions/useSubmissions';
import { Box, Flex } from 'shared/components/display';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import CheckoutPageTemplate from 'shared/components/organisms/CheckoutPageTemplate';

import { SUBMISSION_COPY } from 'shared/config/copy';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LineItemType } from 'shared/typings/lineItem/enums';
import { prettyDate } from 'shared/utilities/dateUtility';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { formatCurrency } from 'shared/utilities/stringUtility';
import { useRightDrawer } from 'features/ui/useRightDrawer';

import styles from './styles';
import { Location, Params, State } from './types';
import RevisionsList from 'features/submissions/components/molecules/RevisionsList';
import { intl } from 'shared/lib/intl';

const SubmissionCheckout = () => {
  const [state, setState] = useState<State>({
    submission: null,
    brief: null,
  });
  const { loading, createSubmissionPayout } = useSubmissions({ loadingStartingState: false });
  const { setRightDrawer } = useRightDrawer(true);
  const params = useParams<Params>();
  const organizationId = +params.organizationId;
  const accountId = +params.accountId;
  const location = useLocation<Location>();
  const navigate = useNavigate();

  const { creatorPayout, eliteCreatorPayout } = state.brief || {};
  const { creator } = state.submission || {};
  const payoutAmount = creator?.isElite ? eliteCreatorPayout : creatorPayout;

  const onOpenFeedbackDrawer = (revisions, moduleNum) => {
    if (revisions?.length) {
      return setRightDrawer({
        key: moduleNum,
        Content: <RevisionsList revisions={revisions} brandName={state.brief?.account.name || ''} />,
        headerText: intl.formatMessage(
          {
            id: 'DRAWER_TITLE_REVISIONS',
          },
          {
            briefName: state.brief?.name || '',
            moduleNum,
          },
        ),
      });
    }
  };

  const submissionLink = useMemo(
    () =>
      LinkCreator.createLink({
        routeKey: 'VIEW_SUBMISSION',
        variables: {
          organizationId,
          accountId,
          briefId: state.brief?.id,
          submissionId: state.submission?.id,
        },
      }),
    [accountId, organizationId, state],
  );

  useEffect(() => {
    if (!location.state) {
      navigate(
        LinkCreator.createLink({
          routeKey: 'BRIEF_INDEX',
          variables: {
            organizationId,
            accountId,
          },
        }),
      );
    } else {
      setState((prev) => ({
        ...prev,
        submission: location.state.submission,
        brief: location.state.brief,
      }));
    }
  }, [location.state]);

  const lineItems = useMemo(() => {
    if (payoutAmount) {
      const currentDate = new Date();
      return [
        {
          amount: payoutAmount,
          quantity: 1,
          description: SUBMISSION_COPY.CHECKOUT_LINE_ITEM_DESCRIPTION,
          startDate: currentDate,
          endDate: currentDate,
          type: LineItemType.CHARGE,
        },
      ];
    }
    return undefined;
  }, [state.brief]);

  const onSubmit = () => {
    if (state.submission && state.brief)
      createSubmissionPayout(state.submission.id, state.submission.creatorId, submissionLink, state.brief.id);
  };

  return (
    <CheckoutPageTemplate
      total={payoutAmount}
      lineItems={lineItems}
      backButtonProps={{
        text: SUBMISSION_COPY.CHECKOUT_BACK_BUTTON_TEXT,
        to: submissionLink,
        state: location.state,
      }}
      loading={loading}
      CheckoutComponent={
        <Box css={styles} className="submission-checkout">
          <Text variant={TEXT_VARIANTS.H3}>{SUBMISSION_COPY.CHECKOUT_HEADER}</Text>
          <Text className="submission-checkout__subheader" variant={TEXT_VARIANTS.H4}>
            {SUBMISSION_COPY.CHECKOUT_SUBHEADER}
          </Text>
          <DefinitionList labelColor={COLORS.BLACK}>
            <ListDetail text="Brief">{state.brief?.name || ''}</ListDetail>
            <ListDetail text="Payout">{formatCurrency(payoutAmount || 0)}</ListDetail>
            <ListDetail text="Creator">{state.submission?.creator?.fullName || ''}</ListDetail>
            <ListDetail text="Date Submitted">
              {state.submission?.dateCreated ? prettyDate(state.submission.dateCreated) : ''}
            </ListDetail>
            <>
              {state.submission?.moduleSubmissions?.map((modSub) => (
                <ListDetail
                  color={COLORS.BLACK}
                  key={modSub.id}
                  text={
                    <Flex>
                      <Text
                        variant={TEXT_VARIANTS.SUBHEADING}
                        className="submission-checkout__module-subheader"
                      >{`Module ${modSub.moduleNum}`}</Text>
                      <ModuleHeaderIcons
                        status={modSub.status}
                        isModuleLoading={false}
                        revisions={modSub.revisions}
                        onClick={() => onOpenFeedbackDrawer(modSub.revisions, modSub.moduleNum)}
                        moduleNum={modSub.moduleNum}
                      />
                    </Flex>
                  }
                >
                  {modSub.files?.map((file) => (
                    <Flex key={file.id}>
                      <Image className="submission-checkout__image" src={file.thumbnailUrl} alt={file.name} />
                      <Text variant={TEXT_VARIANTS.LABEL}>{file.name}</Text>
                    </Flex>
                  ))}
                </ListDetail>
              ))}
            </>
          </DefinitionList>
        </Box>
      }
      onSubmit={onSubmit}
      onSubmitText={SUBMISSION_COPY.CHECKOUT_SUBMIT_TEXT}
      leaveProcessingPageMessage={SUBMISSION_COPY.CHECKOUT_PAYOUT_CONFIRM_LEAVE_PROCESSING}
    />
  );
};

export default SubmissionCheckout;

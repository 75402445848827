import * as Yup from 'yup';

export const FIELD_NAMES = {
  // Fields
  NAME: 'name',
  MEDIA_PREVIEW: 'mediaPreview',
};

// TODO Transition to using SchemaOf for typescript validation of field names
export const editFileSchema = Yup.object({
  name: Yup.string().filename().required(),
});

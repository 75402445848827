import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import cs from 'classnames';
import config from 'config';

import AccountPlatformHealthProgress from 'features/accountPlatforms/components/molecules/AccountPlatformHealthProgress';
import AccountHealthScoreDisplay from 'features/accountPlatforms/components/organisms/AccountHealthScoreDisplay';
import SurveyDrawer from 'features/accountPlatforms/components/organisms/SurveyDrawer/SurveyDrawer';
import { useAccountScoreNotification } from 'features/accountPlatforms/hooks/useAccountScoreNotification';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import { Box, Image } from 'shared/components/display';

import { TRACKING } from 'shared/config/tracking';
import { useQuery } from 'shared/hooks/useQuery';
import LoadingAnimation from 'shared/images/loading/loading-001.gif';
import { UserType } from 'shared/typings/user/enums';
import { sha256Hash, trackEvent } from 'shared/utilities/trackingUtility';

import styles from './externalAdHealthStyles';

const ExternalAdHealth = () => {
  const [loadingGifLoaded, setLoadingGifLoaded] = useState(false);
  const params = useParams<{ accountId: string }>();
  const accountId = +params.accountId;
  const { getQuery, stringifyQuery } = useQuery();
  const {
    token: fbAccessToken,
    adAccountId,
    adAccountName,
    email,
    firstName,
    lastName,
    experiment_id,
    experiment_name,
  } = getQuery();

  const { calculateAccountHealth, accountPlatformHealth, resetAccountPlatformHealth, resetAdScoreProgress } =
    useAccountPlatforms();
  useAccountScoreNotification();

  useEffect(() => {
    resetAccountPlatformHealth();
    resetAdScoreProgress();
    if (loadingGifLoaded) {
      calculateAccountHealth({
        fbAccessToken,
        adAccountId,
        userId: adAccountId,
        email,
        firstName,
        lastName,
      });
    }
  }, [accountId, loadingGifLoaded]);

  const LoadingIndicator = () => (
    <Image
      onLoad={() => {
        setLoadingGifLoaded(true);
      }}
      src={LoadingAnimation}
      alt="loading"
      title="loading"
    />
  );

  const scoreInProgress = !accountPlatformHealth?.score && accountPlatformHealth?.score !== 0;
  const completedWithNoScore = Boolean(+accountPlatformHealth?.score === 0);

  const onImproveMyScore = async () => {
    const emailHashed = await sha256Hash(email);
    trackEvent(TRACKING.ADSCORE_IMPROVE, { email, type: UserType.ORG, userId: emailHashed });
    const query = stringifyQuery({ email, firstName, lastName, experiment_id, experiment_name });
    window.location.assign(config.MARKETING_SITE_URL + query);
  };

  return (
    <Box css={styles} className={cs('external-ad-health', {})}>
      <Box className="account-health__content">
        {scoreInProgress || completedWithNoScore ? (
          <AccountPlatformHealthProgress
            accountId={accountId}
            completedWithNoScore={completedWithNoScore}
            isOnboarding
            LoadingIndicator={LoadingIndicator}
          />
        ) : (
          <AccountHealthScoreDisplay
            onImproveMyScore={onImproveMyScore}
            isOnboarding
            accountPlatformHealth={accountPlatformHealth}
          />
        )}
      </Box>
      <SurveyDrawer adAccountId={adAccountId} adAccountName={adAccountName} accountId={accountId} />
    </Box>
  );
};

export default ExternalAdHealth;

import { useCallback, useContext, useMemo } from 'react';
import ContainerContext from 'shared/contexts/container/ContainerContext';

export const useContainer = (containerName) => {
  const { state, dispatch } = useContext(ContainerContext);
  // TO DO: [context]
  // TO DO: [callback]
  const getContainerState = useCallback(
    (fieldName) => {
      return state[fieldName];
    },
    [state],
  );
  // TO DO: [callback]
  const setContainerState = useCallback(
    (fieldName, value) => {
      dispatch({
        type: 'SET',
        fieldName,
        value,
      });
    },
    [state, dispatch],
  );

  const containerState = useMemo(() => state[containerName], [state, containerName]);
  // TO DO: [callback]
  const toggleContainer = useCallback(() => {
    if (containerName) {
      setContainerState(containerName, !state[containerName]);
    }
  }, [containerName, setContainerState, state[containerName]]);

  const closeContainer = useCallback(() => {
    if (containerName) {
      setContainerState(containerName, false);
    }
  });

  const openContainer = useCallback(() => {
    if (containerName) {
      setContainerState(containerName, true);
    }
  });

  return {
    containerState,
    openContainer,
    closeContainer,
    toggleContainer,
    getContainerState,
    setContainerState,
  };
};

import React, { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { BlockerFunction } from '@remix-run/router';

import Modal from 'shared/components/molecules/Modal';
import { Button, Flex, Text } from 'shared/components/display';

import styles from './styles';
import { BUTTON_VARIANTS } from 'shared/styles/button';

interface Props {
  when: boolean | BlockerFunction;
  promptText: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  title?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  proceedOnCancel?: boolean;
}
const NavigationPrompt: React.FC<Props> = ({
  when,
  promptText,
  onConfirm: onConfirmProp,
  onCancel: onCancelProp,
  title = 'Confirm Changes',
  confirmButtonText = 'Yes',
  cancelButtonText = 'No',
  proceedOnCancel = false,
}) => {
  const blocker = useBlocker(when);

  const onConfirm = () => {
    blocker.proceed?.();
    onConfirmProp?.();
  };

  const onClose = () => {
    blocker.reset?.();
  };
  const onCancel = () => {
    if (proceedOnCancel) {
      blocker.proceed?.();
    } else {
      blocker.reset?.();
    }

    onCancelProp?.();
  };

  useEffect(() => {
    // Make sure navigation isn't blocked once the component unmounts.
    return () => {
      if (blocker.state === 'blocked') {
        blocker.reset?.();
      }
    };
  }, []);

  return (
    <Modal
      component={
        <Flex css={styles} className="navigation-prompt">
          <Flex className="navigation-prompt__text">
            <Text className="navigation-prompt__inner-text">{promptText}</Text>
          </Flex>
          <Flex className="navigation-prompt__button-wrapper">
            <Button
              type="button"
              variant={BUTTON_VARIANTS.TEXT}
              className="navigation-prompt__button"
              onClick={onCancel}
            >
              {cancelButtonText}
            </Button>
            <Button
              type="button"
              variant={BUTTON_VARIANTS.PRIMARY}
              className="navigation-prompt__button"
              onClick={onConfirm}
            >
              {confirmButtonText}
            </Button>
          </Flex>
        </Flex>
      }
      onRequestClose={onClose}
      title={title}
      isOpen={blocker.state === 'blocked'}
      ariaRole="alertdialog"
      ariaLabel="Navigate Warning"
    />
  );
};

export default NavigationPrompt;

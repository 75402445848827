import { css } from '@emotion/react';

export default css({
  '.approved-creatives': {
    maxWidth: '100%',
    '&__header': {
      justifyContent: 'space-between',
      gridColumn: '1 / span 2',
    },
    '&__header a': {
      textAlign: 'right',
    },
  },
  '.profile-panel__content-wrapper': {
    // We need to override the padding of the child component from here
    padding: '0.5rem 0 0.5rem 1rem!important',
  },
});

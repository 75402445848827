import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { CollectionState } from 'features/media/collections.slice';
import { submissionAdded, submissionChanged } from 'features/submissions/submissions.slice';

import { BrkfstFile } from 'shared/typings/file';

interface FileState {
  data: BrkfstFile[];
  selectedFiles: BrkfstFile[];
  total: number;
  fileUpdated: boolean;
  pinnedCount: number;
}

interface FilePayload<T = BrkfstFile> {
  data: T;
  params?: { selectUploadedFiles?: boolean };
}

/**
 * the state contains an array of files, and a set of fileIDs.
 * both need to updated on loading new files or deleting files.
 *
 * @param newFiles {(Object|Object[])} - new files to add to the current file state
 * newFiles can either be in the format of:
 * - search result object {data: [...], total: N }
 * - single file object {...}
 * - array of file objects [{...}]
 */

export const addToFiles = (filesState: FileState, newFiles: BrkfstFile[] | BrkfstFile, selectNewFiles?: boolean) => {
  let newFilesArray = newFiles;

  if (
    !Array.isArray(newFiles) &&
    Object.prototype.hasOwnProperty.call(newFiles, 'data') &&
    Object.prototype.hasOwnProperty.call(newFiles, 'total')
  ) {
    // @ts-ignore
    newFilesArray = newFiles.data;
  }

  if (!Array.isArray(newFilesArray)) {
    newFilesArray = [newFilesArray];
  }

  newFilesArray.forEach((newFile) => {
    const idSet = new Set(filesState.data.map((fileData) => fileData.id));
    if (!idSet.has(newFile.id)) {
      // Adding what's called a cache buster so it always fetches the new thumbnail by adding a unique param
      const cacheBuster = newFile?.thumbnailUrl?.includes('?t=') ? '' : `?t=${Math.random()}`;
      const file = { ...newFile, thumbnailUrl: `${newFile.thumbnailUrl}${cacheBuster}` };
      filesState.data.unshift(file);
      if (selectNewFiles) filesState.selectedFiles.push(file);
      filesState.total++;
    }
  });
};

const updateSubmissionAssets = (files, action) => {
  const { assets } = action.payload.data;
  if (assets)
    assets.forEach((asset) => {
      const index = files.data.findIndex((file) => file.id === asset.id);
      if (index >= 0) {
        files.data[index] = asset;
      }
    });
  return files;
};

const formatAssetAPNames = (file, updatedFile) => ({
  tiktokAssetAPNames:
    file.tiktokAssetAPNames && updatedFile.tiktokAssetAPNames
      ? [file.tiktokAssetAPNames, updatedFile.tiktokAssetAPNames].join(', ')
      : file.tiktokAssetAPNames || updatedFile.tiktokAssetAPNames,
  facebookAssetAPNames:
    file.facebookAssetAPNames && updatedFile.facebookAssetAPNames
      ? [file.facebookAssetAPNames, updatedFile.facebookAssetAPNames].join(', ')
      : file.facebookAssetAPNames || updatedFile.facebookAssetAPNames,
});

const updateFiles = (files, updatedFile) => {
  return files.map((prevFile) => {
    const { id } = updatedFile;
    if (id === prevFile.id) {
      const updatedPayload = {
        ...prevFile,
        ...updatedFile,
        ...formatAssetAPNames(prevFile, updatedFile),
      };
      // Adding what's called a cache buster so it always fetches the new thumbnail by adding a unique param
      if (updatedFile.thumbnailUrl) {
        updatedPayload.thumbnailUrl = `${updatedFile.thumbnailUrl}?t=${updatedFile.thumbnailOffset}`;
      }
      return updatedPayload;
    }
    return prevFile;
  });
};

const initialState = { data: [], selectedFiles: [], total: 0, fileUpdated: false, pinnedCount: 0 };

const filesSlice = createSlice<FileState, SliceCaseReducers<FileState>, 'files'>({
  name: 'files',
  initialState,
  reducers: {
    filesLoaded: (files, action: PayloadAction<FilePayload<{ data: BrkfstFile[]; total: number }>>) => {
      const { data, total } = action.payload.data;
      files.data = data;
      files.total = total;
    },
    filesAdded: (files, action: PayloadAction<FilePayload<BrkfstFile[] | BrkfstFile>>) => {
      const { data, params = {} } = action.payload;
      const { selectUploadedFiles } = params;
      addToFiles(files, data, selectUploadedFiles);
    },
    fileUpdated: (files, action: PayloadAction<FilePayload>) => {
      // selected files
      return {
        ...files,
        data: updateFiles(files.data, action.payload.data),
        fileUpdated: true,
        total: files.total,
        selectedFiles: updateFiles(files.selectedFiles, action.payload.data),
      };
    },
    filesChanged: (files, action: PayloadAction<FilePayload<BrkfstFile[]>>) => {
      const currentIds = files.data.map((f) => f.id);
      const updatedFilesData = action.payload.data.filter((file) => currentIds.includes(file.id));
      const updatedFilesIDs = new Set(updatedFilesData.map((file) => file.id));
      files.data = files.data.filter((file) => {
        return !updatedFilesIDs.has(file.id);
      });
      addToFiles(files, updatedFilesData);
    },
    fileRemoved: (files, action: PayloadAction<FilePayload<Pick<BrkfstFile, 'id'>>>) => {
      const deletedFileId = action.payload.data.id;
      files.data = files.data.filter((file) => file.id !== deletedFileId);
      files.selectedFiles = files.selectedFiles.filter((file) => file.id !== deletedFileId);
    },
    filesCleared: (files) => ({ ...initialState, pinnedCount: files.pinnedCount }),
    filesAddedToCollection: (
      files,
      action: PayloadAction<FilePayload<{ fileIds: number[]; collectionId: number }>>,
    ) => {
      const { fileIds, collectionId } = action.payload.data;
      files.data = files.data.map((file) => {
        if (fileIds.includes(file.id)) {
          file.collectionIds?.push(collectionId);
        }
        return file;
      });
    },
    resetFileUpdated: (files) => {
      files.fileUpdated = false;
    },
    setSelectedFiles: (files, action: PayloadAction<number[]>) => {
      const selectedIds = action.payload;
      // @ts-ignore
      files.selectedFiles = selectedIds
        .map((id) => {
          const alreadySelected = files.selectedFiles.find((file) => file.id === id);
          if (alreadySelected) return alreadySelected;
          const newlySelected = files.data.find((file) => file.id === id);
          if (newlySelected) return newlySelected;
        })
        .filter((file) => file !== undefined);
    },
    pinnedCountLoaded: (files, action: PayloadAction<{ data: number }>) => {
      files.pinnedCount = action.payload.data;
    },
    pinnedCountUpdated: (files, action: PayloadAction<number>) => {
      files.pinnedCount += action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addCase(submissionAdded, updateSubmissionAssets).addCase(submissionChanged, updateSubmissionAssets),
});

export const {
  filesLoaded,
  filesChanged,
  filesAdded,
  fileRemoved,
  fileUpdated,
  filesCleared,
  filesAddedToCollection,
  resetFileUpdated,
  setSelectedFiles,
  pinnedCountLoaded,
  pinnedCountUpdated,
} = filesSlice.actions;

export default filesSlice.reducer;

interface State {
  files: FileState;
  collections: CollectionState;
}

export const getFile = (id: number) => (state: State) => {
  const file = state.files.data.find((element) => element.id === id);
  return file;
};

export const getTotalNumFiles = (state: State) => state.files.total;

export const getFiles = (state: State) => state.files.data;

export const getCollectionFiles = (state: State) => {
  if (state.collections.selectedCollectionId) {
    return state.files.data.filter((file) => {
      // @ts-ignore
      return file.collectionIds?.includes(state.collections.selectedCollectionId);
    });
  }
  return null;
};
export const getSelectedCollectionId = (state: State) => state.collections.selectedCollectionId;

export const getFileCount = (state: State) => state.files.data.length;

export const getFileUpdated = (state: State) => state.files.fileUpdated;

export const getSelectedFiles = (state) => {
  return state.files.selectedFiles;
};

export const getPinnedCount = (state) => {
  return state.files.pinnedCount;
};

export type ProductCatalog = { label: string; value: string };
import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.connect-product-catalog': {
    '&__form': {
      marginTop: theme.space[3],
      alignItems: 'center',
    },
    '&__buttons': {
      marginTop: theme.space[3],
    },
    '&__button': {
      marginRight: theme.space[4],
    },
    '&__loader': {
      marginTop: theme.space[4],
    },
  },
});

import config from 'config';
import { ChannelState, StreamChat, TokenOrProvider } from 'stream-chat';

import { RollbarError } from 'shared/errors/RollbarError';
import { StreamChatChannel, StreamChatClient, StreamChatTypes } from 'shared/messaging/types';
import { PhoneStatus } from 'shared/typings/user/enums';

const AUTHENTICATION_ERROR_CODE = 5;

interface User {
  id: number;
  name: string;
  profilePic: {
    url: string;
  };
  email: string;
  phone?: {
    number: string;
    status: number;
  };
  streamToken: TokenOrProvider;
  type: number;
}
class Messaging {
  private client: StreamChatClient;

  private loggedIn: boolean;

  constructor() {
    // default timeout is 3000ms
    this.client = StreamChat.getInstance<StreamChatTypes>(config.STREAM_CHAT_KEY, {
      timeout: config.STREAM_CHAT_TIMEOUT,
    });
    this.loggedIn = false;
  }

  public getClient(): StreamChatClient {
    return this.client;
  }

  public async off() {
    await this.client.disconnectUser();
    this.loggedIn = false;
  }

  public async refreshUser(user: User) {
    await this.off();
    await this.setUser(user);
  }

  public async setUser(user: User) {
    if (!this.loggedIn) {
      try {
        await this.client.connectUser(
          {
            id: user.id.toString(),
            name: user.name,
            image: user.profilePic?.url,
            email: user.email,
            type: user.type,
          },
          user.streamToken,
        );
      } catch (e: any) {
        if (e.code === AUTHENTICATION_ERROR_CODE) {
          throw new RollbarError(e.message, false);
        }
        throw e;
      }

      this.loggedIn = true;
    }
  }

  public async updateUserPhone(user: User) {
    await this.client.connectUser(
      {
        id: user.id.toString(),
        name: user.name,
        image: user.profilePic?.url,
        email: user.email,
        phoneNumber: user.phone && user.phone.status == PhoneStatus.ACTIVE ? user.phone.number : '',
        type: user.type,
      },
      user.streamToken,
    );
  }

  public getChannelId(user, account): string {
    return `u${user.id}-a${account.id}`;
  }

  public async findAccountChannel(channelId: string): Promise<StreamChatChannel | null> {
    if (!channelId) return null;
    const channels = await this.client.queryChannels({ id: channelId });
    if (channels.length < 1) return null;
    const channel = channels[0];
    await channel.watch();
    return channel;
  }

  public async leaveAccount(userId: number, accountId: number): Promise<void> {
    const memberId = userId.toString();
    const channels = await this.client.queryChannels({ accountId });
    const promises = channels.map((c) => c.removeMembers([memberId]));
    await Promise.all(promises);
  }

  public async joinAccount(userId: number, accountId: number): Promise<void> {
    const memberId = userId.toString();
    const channels = await this.client.queryChannels({ accountId });
    const promises = channels.map((c) => c.addMembers([memberId]));
    await Promise.all(promises);
  }

  public async getNotificationCounts(user: User): Promise<Object> {
    const orgUnreadCounts = {};
    const filter = { members: { $in: [`${user.id}`] } };
    const channels = await this.client.queryChannels(filter, {
      has_unread: -1,
    });
    channels.forEach(({ data, state }) => {
      const orgId = data?.organizationId?.toString();
      if (orgId) {
        const userUnreadMessages = (state.read[user.id] as any).unread_messages;
        const count = orgUnreadCounts[orgId] || 0;
        const shouldNotIncrementCount = this.skipNotificationForMarketer(state, user.id);
        if (userUnreadMessages > 0 && !shouldNotIncrementCount) orgUnreadCounts[orgId] = count + 1;
      }
    });
    return orgUnreadCounts;
  }

  /**
   * Checks if vettedCreatorId is on last message in channel, indicating that it is a brief auto response and should not notify marketer
   *
   * @param  {ChannelState} state
   * @returns boolean
   */
  private skipNotificationForMarketer(state: ChannelState<StreamChatTypes>, userId: number): boolean {
    const lastMessage = state.messages[state.messages.length - 1];
    return lastMessage?.vettedCreatorId !== undefined && lastMessage?.vettedCreatorId !== userId;
  }
}

export default new Messaging();

import React from 'react';
import cs from 'classnames';
import { isEmpty } from 'lodash';

import { Box, Text } from 'shared/components/display';
import streetAddressStyles from 'shared/components/molecules/StreetAddress/streetAddressStyles';

import { GENERIC_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Address } from 'shared/typings/address';

const { BODY } = TEXT_VARIANTS;

interface Props {
  address: Address;
  displayInline?: boolean;
}

const StreetAddress: React.FC<Props> = ({ address, displayInline = false }) => {
  const { addressLine1, addressLine2, companyName, city, state, postalCode, country } = address || {};
  return isEmpty(address) ? (
    <Box css={streetAddressStyles}>
      <Text variant={BODY} className="address__line">
        {GENERIC_COPY.PLACEHOLDER_NO_DATA}
      </Text>
    </Box>
  ) : (
    <Box css={streetAddressStyles} className="address">
      <Text
        variant={BODY}
        className={cs('address__line', {
          ['address__line--inline']: displayInline,
          ['address--hide']: !addressLine1,
        })}
      >
        {addressLine1}
      </Text>
      <Text
        variant={BODY}
        className={cs('address__line', {
          ['address__line--inline']: displayInline,
          ['address--hide']: !addressLine2,
        })}
      >
        {addressLine2}
      </Text>
      <Text
        variant={BODY}
        className={cs('address__line', {
          ['address__line--inline']: displayInline,
          ['address--hide']: !companyName,
        })}
      >
        {companyName}
      </Text>
      <Text
        variant={BODY}
        className={cs('address__line', {
          ['address__line--inline']: displayInline,
        })}
      >{`${city}, ${state?.name ? `${state.name}, ` : ''}${postalCode}`}</Text>
      <Text
        variant={BODY}
        className={cs('address__line', {
          ['address__line--inline']: displayInline,
          ['address--hide']: !country,
        })}
      >
        {country?.name}
      </Text>
    </Box>
  );
};

export default StreetAddress;

import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

type GTMUser = Pick<BrkfstUser, 'email' | 'type' | 'isSuperadmin'> & { id: string | number };

const USER_TYPE = Object.freeze({
  [UserType.CREATOR]: 'CREATOR',
  [UserType.ORG]: 'BRAND',
});

export interface UTMParams {
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
}

export interface ABTestingParams {
  experiment_id?: string;
  experiment_name?: string;
}

export const pushGtmSignUpEvent = (email: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'SignUp',
    email,
  });
};
export const pushGtmOnboardEvent = (type: string) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'OnboardingComplete',
    type,
  });
};

export const trackPageView = (path: string, { id: userId, email, type, isSuperadmin }: GTMUser) => {
  window.dataLayer = window.dataLayer || [];
  const isInternal = email?.indexOf('@brkfst.io') > -1 || email?.indexOf('@within.co') > -1;
  window.dataLayer.push({
    event: 'PageView',
    pageUrl: window.location.href,
    pageTitle: path.substring(1), // Remove first slash
    userId,
    email,
    type: USER_TYPE[type],
    isSuperadmin,
    isInternal,
  });
};

export const trackEvent = ({ category, action, label }, data = {}) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: `${category}${action}`,
    label,
    ...data,
  });
};

export async function sha256Hash(str: string): Promise<string> {
  const buffer = new TextEncoder().encode(str);
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export function getUTMParams(queryParams: Record<string, any>): UTMParams {
  return Object.keys(queryParams).reduce((acc, key) => {
    if (key.includes('utm')) acc[key] = queryParams[key];
    return acc;
  }, {});
}

export function getABParams(queryParams: Record<string, any>): ABTestingParams {
  return Object.keys(queryParams).reduce((acc, key) => {
    if (key.includes('experiment')) acc[key] = queryParams[key];
    return acc;
  }, {});
}

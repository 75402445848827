import { Box } from 'shared/components/display';
import { TabProps } from 'shared/components/molecules/Tabs/Tab';
import { tabBarStyles } from 'shared/components/molecules/Tabs/tabsStyles';
import { useQuery } from 'shared/hooks/useQuery';
import cs from 'classnames';
import React, { ReactElement } from 'react';

interface Props {
  children: ReactElement<TabProps> | Array<ReactElement<TabProps>>;
  className?: string;
}
const TabBar: React.FC<Props> = ({ children, className }) => {
  const { getQuery } = useQuery();

  const { activeTab } = getQuery();

  return (
    <Box
      css={tabBarStyles}
      className={cs({
        'tab-bar': true,
        className: !!className,
      })}
    >
      {React.Children.map(children, (child: ReactElement<TabProps>, index) => {
        return React.cloneElement(child, {
          activeTab: activeTab || 0,
          index,
        });
      })}
    </Box>
  );
};

export default TabBar;

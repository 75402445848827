/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { reviewModuleStatusUpdated, revisionAdded } from 'features/reviewModules/reviewModules.slice';
import { ModuleSubmission, ModuleSubmissionStatus, Submission } from 'shared/typings/submissions';

interface SubmissionState {
  data: Submission[];
  total: number;
}

const initialState = {
  data: [],
  total: 0,
};

type SubmissionAction<T = SubmissionState> = PayloadAction<{ data: T }>;

const submissionUpdated = (submissions, action: SubmissionAction<Submission>) => {
  const submission = submissions.data.find((submission) => submission.id === action.payload.data.id);
  const updatedSubmission = { ...(submission || {}), ...action.payload.data };
  const index = submissions.data.indexOf(submission);
  submissions.data.splice(index, 1, updatedSubmission);
};

const updateNumApprovedAssets = (
  submission: Pick<Submission, 'numApprovedAssets' | 'moduleSubmissions'>,
  newSubmissionModule: ModuleSubmission,
): number => {
  if (newSubmissionModule.status === ModuleSubmissionStatus.APPROVED) {
    return submission.numApprovedAssets + newSubmissionModule.files.length;
  }

  const prevSubmissionModule = submission.moduleSubmissions.find(({ id }) => id === newSubmissionModule.id);

  if (prevSubmissionModule?.status === ModuleSubmissionStatus.APPROVED) {
    return submission.numApprovedAssets - newSubmissionModule.files.length;
  }

  return submission.numApprovedAssets;
};

export const submissionsSlice = createSlice<SubmissionState, SliceCaseReducers<SubmissionState>>({
  name: 'submissions',
  initialState,
  reducers: {
    submissionsLoaded: (submissions, action: SubmissionAction) => {
      const { data, total } = action.payload.data;
      submissions.data = data;
      submissions.total = total;
    },
    systemSubmissionLoaded: (submissions, action: SubmissionAction<Submission>) => {
      submissions.data = [action.payload.data];
      submissions.total = 1;
    },
    submissionsCleared: () => {
      return initialState;
    },
    submissionAdded: (submissions, action: SubmissionAction<Submission>) => {
      // add to the beginning to maintain created by order
      submissions.data.unshift(action.payload.data);
      submissions.total += 1;
    },
    submissionChanged: submissionUpdated,
    // payload should be submissionId of deleted submission
    submissionRemoved: (submissions, action: SubmissionAction<Pick<Submission, 'id'>>) => {
      submissions.data = submissions.data.filter((submission) => submission.id !== action.payload.data.id);
      submissions.total -= 1;
    },
    hasBeenSeenToggled: submissionUpdated,
  },
  extraReducers: (builder) =>
    builder
      .addCase(reviewModuleStatusUpdated, (submissions, action) => {
        const submissionModule = action.payload.data;
        submissions.data = submissions.data.map((submission) => {
          if (submission.id === submissionModule.submissionId) {
            return {
              ...submission,
              numApprovedAssets: updateNumApprovedAssets(submission, submissionModule),
              moduleSubmissions: submission.moduleSubmissions.map((moduleSubmission) => {
                if (moduleSubmission.id === submissionModule.id) {
                  return submissionModule;
                }
                return moduleSubmission;
              }),
            };
          }
          return submission;
        });
      })
      .addCase(revisionAdded, (submissions, action) => {
        const {
          data: { submissionModuleId },
          requestData: { moduleStatus },
        } = action.payload;

        submissions.data = submissions.data.map((sub) => ({
          ...sub,
          moduleSubmissions: sub.moduleSubmissions.map((subMod) => {
            if (subMod.id === submissionModuleId) {
              return {
                ...subMod,
                revisions: [action.payload.data, ...(subMod.revisions || [])],
                status: moduleStatus,
              };
            }
            return subMod;
          }),
        }));
      }),
});

export const {
  submissionsLoaded,
  systemSubmissionLoaded,
  submissionsCleared,
  submissionAdded,
  submissionChanged,
  submissionRemoved,
  hasBeenSeenToggled,
} = submissionsSlice.actions;

export const getSubmission = (id) => (state) => {
  return state.submissions.data.find((submission) => submission.id === id);
};
export const getSubmissions = (state) => state.submissions.data;
export const getSubmissionCount = (state) => state.submissions.total;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { TikTokColors } from 'shared/styles/colors';

interface Props {
  className?: string;
}
const TiktokIcon: React.FC<Props> = ({ className }) => {
  return <FontAwesomeIcon icon={faTiktok} color={TikTokColors.BLACK} className={className} />;
};

export default TiktokIcon;

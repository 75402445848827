import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '.manage-creators': {
    padding: theme.space[4],
    width: '100%',
    [mediaquery.md]: {
      width: '95%',
    },
  },
});

import React from 'react';
import { Field, Form, Formik } from 'formik';

import { useUser } from 'features/users/hooks/useUser';
import { marketerProfilePictureSchema } from 'features/users/pages/BrandUserAccountSettings/config';
import FormButton from 'shared/components/FormButton';
import ImageFileInput from 'shared/components/molecules/ImageFileInput';

import { GENERIC_COPY, USER_COPY } from 'shared/config/copy';
import { BRKFST_SUPPORTED_IMAGE_FORMATS } from 'shared/config/fileFormats';
import { BrkfstUser } from 'shared/typings/user';
import { Box, Flex } from 'shared/components/display';
import styles from 'features/users/components/molecules/MarketerProfilePictureForm/styles';

// fieldnames
const PROFILE_PICTURE = 'profilePic';

const INITIAL_VALUES = {
  profilePic: {
    file: {
      name: '',
      type: '',
      size: 0,
    },
  },
};

interface Props {
  user: BrkfstUser;
  toggleEdit?: () => void;
}

const MarketerProfilePictureForm: React.FC<Props> = ({ user, toggleEdit }) => {
  const { saveProfilePicture } = useUser(user.id);

  const onSubmit = (data) => {
    saveProfilePicture(data[PROFILE_PICTURE]);
    if (toggleEdit) toggleEdit();
  };

  return (
    <Formik onSubmit={onSubmit} validationSchema={marketerProfilePictureSchema} initialValues={INITIAL_VALUES}>
      <Box css={styles} >
        <Form>
          <Field
            name={PROFILE_PICTURE}
            component={ImageFileInput}
            acceptedFormats={BRKFST_SUPPORTED_IMAGE_FORMATS}
            placeholder={USER_COPY.HEADING_PROFILE_PICTURE}
            label={USER_COPY.HEADING_PROFILE_PICTURE}
          />
          <Flex className='marketer-profile-picture__submit-button'>
            <FormButton>{GENERIC_COPY.BUTTON_SUBMIT}</FormButton>
          </Flex>
        </Form>
      </Box>
    </Formik>
  );
};

export default MarketerProfilePictureForm;

import React from 'react';
import * as Yup from 'yup';

import { Box } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';

import { isRichtextEmpty } from './shared/utils';
import { MESSAGES } from 'shared/config/validations';

import Richtext from './Richtext';

export const richTextSchema = Yup.mixed().test({
  name: 'richtext-not-empty',
  message: MESSAGES.REQUIRED_FIELD,
  test: (value) => !isRichtextEmpty(value),
});

const RichtextInput = ({ field: { value, name, onChange }, form: { handleBlur }, ...props }) => {
  return (
    <Box data-focus-selector={name} tabIndex={0}>
      <Richtext
        {...props}
        value={value}
        onChange={(data, extraProps: { isLink?: boolean }) => {
          // Debounce the onChange method to allow a much smoother transition when typing
          // Not needed when inserting links as the user has to stop to click the add link btn
          if (!extraProps?.isLink) {
            setTimeout(() => {
              onChange({ target: { name, value: data } });
            }, 300);
          } else {
            onChange({ target: { name, value: data } });
          }
        }}
        onBlur={() => handleBlur({ target: { name } })}
        ref={props.innerRef}
      />
      <FieldErrorMessage name={name} />
    </Box>
  );
};

export default RichtextInput;

import { css } from '@emotion/react';

export default (width: string | undefined) =>
  css({
    '&.chip': {
      display: 'flex',
      position: 'relative',
      textAlign: 'center',
      userSelect: 'none',
      height: '1.5rem',
      width: width || 'max-content',
      padding: '0.125rem 0.5rem',
      margin: '0.125rem 0.25rem 0.125rem 0',
      justifyContent: 'center',
      borderRadius: '24px',
      '&--square': {
        borderRadius: '3px',
      },
      '&--with-icon': {
        height: '2rem',
        padding: '0.5rem 1rem 0.5rem 2.5rem',
        margin: '0.5rem',
      },
      '&--clickable': {
        cursor: 'pointer',
      },
      '&__icon-wrapper': {
        position: 'absolute',
        top: '0',
        left: '0%',
        width: '2rem',
        height: '100%',
        borderRadius: '10px 0 0 10px',
        padding: '0.5rem',
        '& > *': {
          alignSelf: 'center',
        },
      },
    },
  });

import { css, keyframes } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

const DRAWER_DESKTOP_WIDTH = '500px';

const expand = keyframes({
  from: {
    maxHeight: 0,
  },
  to: {
    maxHeight: '500px',
  },
});

const slideIn = keyframes({
  from: {
    left: DRAWER_DESKTOP_WIDTH,
  },
  to: {
    left: 0,
  },
});

const LABEL_BACKGROUND =
  'linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.42) 0%,rgba(255,255,255,1) 8%)';

const styles = css({
  '&.upload-status-drawer': {
    position: 'fixed',
    bottom: 0,
    right: 0,
    left: 0,
    margin: 'auto',
    flexDirection: 'column',
    background: COLORS.WHITE,
    zIndex: zIndex.zDropdownPortal,
    border: `1px solid ${COLORS.NEUTRAL100}`,
    width: '100%',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    [mediaquery.md]: {
      right: '5px',
      left: 'auto',
      width: DRAWER_DESKTOP_WIDTH,
    },
  },
  '.upload-status-drawer': {
    '&__list-header': {
      margin: 0,
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    '&__list-header-wrapper': {
      height: '50px',
      boxShadow: `0px -1px 9px 1px ${COLORS.NEUTRAL300}`,
      alignItems: 'center',
      justifyContent: 'center',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    '&__list-header-open-icon-wrapper': {
      position: 'absolute',
      top: '-8px',
    },
    '&__list-header-open-icon-circle': {
      boxShadow: `-1px 3px 8px 1px ${COLORS.NEUTRAL100}`,
      borderRadius: '10px',
    },
    '&__list-header-close-icon': {
      position: 'absolute',
      right: '20px',
      top: '18px',
      cursor: 'pointer',
    },
    '&__list': {
      width: 'auto',
      maxHeight: '0',
      transition: '0.5s all ease',
      listStyleType: 'none',
      padding: '0',
      margin: '0',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    '&__list--open': {
      animation: `${expand} 2s`,
      animationDelay: '0s',
      animationFillMode: 'forwards',
    },
    '&__header-status-icon': {
      marginRight: '15.6px',
    },

    '&__progress-icon svg': {
      width: 16,
      height: 16,
      position: 'relative',
    },
    '&__progress-bar': {
      marginBottom: '6px',
    },
    '&__abort-upload-icon': {
      width: '35px',
      cursor: 'pointer',
      background: LABEL_BACKGROUND,
      right: 0,
      position: 'absolute',
    },
    '&__upload-cancelled-label': {
      cursor: 'pointer',
      background: LABEL_BACKGROUND,
      marginBottom: 0,
      whiteSpace: 'nowrap',
    },
  },
});

export const uploadDrawerItemStyles = css({
  '&.upload-drawer-item': {
    position: 'relative',
    padding: '0 5px',
    '&--animated': {
      animation: `${slideIn} 1s`,
      animationDelay: '0s',
      animationFillMode: 'forwards',
    },
  },
  '.upload-drawer-item': {
    '&__row': {
      borderBottom: `1px solid ${COLORS.NEUTRAL100}`,
      padding: '15px 10px',
      overflow: 'hidden',
      alignItems: 'center',
      display: 'flex',
    },
    '&__file-name': {
      margin: `0 15.6px`,
      width: 'max-content',
      height: '27px',
      overflow: 'hidden',
      whiteSpace: 'pre',
      textOverflow: 'ellipsis',
      flexGrow: 2,
    },
    '&__error': {
      margin: 0,
      textAlign: 'right',
      color: COLORS.RED500,
      flexGrow: 1.5,
    },
    '&__status-icon': {
      position: 'relative',
      top: '2px',
    },
  },
});

export default styles;

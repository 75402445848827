import { VideoCompositionPayload } from 'features/media/pages/VideoComposition/types';
import { newFilesLoadingToggled, updateFileUploadStatus } from 'features/ui/ui.slice';
import { useDispatch } from 'react-redux';
import { apiAction } from 'shared/actions/api';
import { API_FILE_ROUTES } from 'shared/config/routes/api/apiFileRoutes';
import { LOCAL_STORAGE } from 'shared/config/storage';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import { ApiOnSuccess } from 'shared/typings/api';
import { FileUploadStatus } from 'shared/typings/file/enums';
import { parseError } from 'shared/utilities/errorUtility';

export const useVideoComposition = () => {
  const dispatch = useDispatch();

  const { value: fileIds = [], setVal: setFileIds } = useLocalStorage<number[]>(LOCAL_STORAGE.VIDEO_COMPOSITION);

  const updateFileIds = (ids: number[], addFiles: boolean = false) => {
    if (addFiles) setFileIds([...fileIds, ...ids]);
    else {
      setFileIds(ids);
    }
  };

  const getFiles = (fileIds: number[], onSuccess: ApiOnSuccess) => {
    dispatch(
      apiAction({
        path: {
          route: API_FILE_ROUTES.COMPOSITION_FILES,
        },
        params: {
          fileIds: JSON.stringify(fileIds),
        },
        method: 'GET',
        onSuccess,
      }),
    );
  };

  const createVideoCompositions = async (data: VideoCompositionPayload[]) => {
    dispatch(
      newFilesLoadingToggled({
        numNewFiles: data.length,
        filesUploading: data.map(({ compositionName, key = '' }: VideoCompositionPayload) => {
          return {
            name: compositionName,
            status: FileUploadStatus.UPLOADING,
            errors: [],
            id: key,
          };
        }),
      }),
    );
    data.forEach((payload) => {
      dispatch(
        apiAction({
          path: {
            route: API_FILE_ROUTES.COMPOSITION,
          },
          method: 'POST',
          data: payload,
          hideError: true,
          onError: (error) => {
            // Error message is set as key:message
            const parsedError: string = parseError(error);
            const [key, message] = parsedError.split(':');
            dispatch(
              updateFileUploadStatus({
                id: key,
                fileUploadStatus: FileUploadStatus.UPLOAD_CANCELLED,
                errors: [message],
              }),
            );
          },
        }),
      );
    });
  };

  return {
    updateFileIds,
    createVideoCompositions,
    fileIds,
    getFiles,
  };
};

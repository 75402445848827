import React from 'react';
import { Button } from 'rebass/styled-components';
import cs from 'classnames';

import { Flex, Text } from 'shared/components/display';

import { BUTTON_VARIANTS } from 'shared/styles/button';

import styles from './styles';

interface Props {
  cancelButtonText?: string;
  cancelCallback?: React.MouseEventHandler<HTMLButtonElement>;
  confirmButtonText?: string;
  confirmCallback: React.MouseEventHandler<HTMLButtonElement>;
  onRequestClose: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  showCancelButton?: boolean;
}
const Prompt: React.FC<Props> = ({
  text,
  confirmCallback,
  cancelCallback,
  onRequestClose,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  showCancelButton = true,
}) => {
  const onCancel: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.persist();
    if (cancelCallback) cancelCallback(e);
    onRequestClose(e);
  };

  const onConfirm: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    confirmCallback(e);
    onRequestClose(e);
  };

  return (
    <Flex css={styles} className={cs('prompt')}>
      <Flex className="prompt__text">
        <Text className="prompt__inner-text">{text}</Text>
      </Flex>
      <Flex className="prompt__button-wrapper">
        {showCancelButton && (
          <Button variant={BUTTON_VARIANTS.TEXT} className="prompt__button" onClick={onCancel}>
            {cancelButtonText}
          </Button>
        )}
        <Button variant={BUTTON_VARIANTS.PRIMARY} className="prompt__button" onClick={onConfirm}>
          {confirmButtonText}
        </Button>
      </Flex>
    </Flex>
  );
};

export default Prompt;

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import theme from 'shared/styles/themes';

export default css({
  '&.brief-cards': {
    flexDirection: 'column',
  },
  '.brief-cards': {
    '&__pagination': {
      marginBottom: '18px',
    },
    '&__new-brief': {
      position: 'sticky',
      zIndex: zIndex.zStickyElements,
      bottom: theme.space[6],
      width: '93.52%',
      padding: '0 12px 0 16px',
      alignSelf: 'center',
    },
    '&__new-brief-btn': {
      width: '100%',
      boxShadow: theme.shadows.SHADOW04,
    },
  },
});

import config from 'config';

export const COLLECTION_TOASTS = {
  SHARED: "Collection '#{collectionName}' shared with #{email}",
  COLLECTION_MADE_PRIVATE: 'The collection is now private.',
  COLLECTION_MADE_PUBLIC: 'The collection is now public.',
  COLLECTION_CREATED: 'Collection successfully created',
  COLLECTION_DELETED: 'Collection successfully deleted',
  SINGLE_AD_SAME_MEDIA_TYPE_ERROR_OCCURRED:
    'Cannot mix video/gif/image formats. Please update your selection and try again.',
  NO_FILES_CHOSEN: 'No files chosen',
  FB_ASSET_UPLOAD_ENOUGH_ASSETS: 'Select at least 1 file to add to Facebook library',
  TIKTOK_ASSET_UPLOAD_ENOUGH_ASSETS: 'Select at least 1 file to add to TikTok library',
  AD_NOT_ENOUGH_ASSETS: 'Select at least 1 file to create a single ad.',
  AD_NOT_ENOUGH_ASSETS_FOR_CAROUSEL: 'Select at least 2 files to create carousel ad.',
  AD_NOT_ENOUGH_ASSETS_SINGLE_COLLECTION: 'Select at least 1 file to create a {type} ad.',
  AD_ASSETS_LESS_THAN_MIN_DURATION:
    'Ad placements require that any videos be at least 1 second long. Please update your selection and try again',
  ADD_TO_COLLECTION_NOT_ENOUGH_ASSETS: 'Please select an asset to add to a collection.',
  COLLECTION_INVALID_FILENAME: `The file name should be less than ${config.FILE_NAME_LENGTH} characters and should not contain whitespaces, or special characters`,
  AD_NOT_THE_SAME_CREATOR: 'You must select assets from the same creator',
  COLLECTION_UPDATED: 'Collection successfully updated',
  AD_UNSUPPORTED_ASPECT_RATIO_SELECTED: 'Selection contains file that is not a supported aspect ratio (1:1, 4:5, 9:16)',
  AD_NONUNIQUE_RATIOS_SELECTED: 'Selection can only contain 1 of each supported aspect ratio (1:1, 4:5, 9:16)',
  ERROR_DOWNLOAD_FILES: 'The selected files were not submitted to a creative only brief and cannot be downloaded',
  SUCCESS_DOWNLOAD_FILES:
    'The selected files are being prepared for download, you will receive an email once they are ready',
};

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';
import SHADOWS from 'shared/styles/shadow';

export default css({
  '&.user-role': {
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
    padding: `${theme.space[4]} 0`,
    width: '100%',
    [mediaquery.md]: {
      padding: `${theme.space[8]} 0`,
    },
  },
  '.user-role': {
    '&__heading': {
      textAlign: 'center',
      fontWeight: 600,
      marginTop: theme.space[4],
    },
    '&__sub-heading': {
      textAlign: 'center',
      fontWeight: 300,
      paddingLeft: theme.space[5],
      paddingRight: theme.space[5],
    },
    '&__role-title': {
      fontWeight: 600,
    },
    '&__role': {
      backgroundColor: 'white',
      border: '2px solid white',
      boxShadow: SHADOWS.SHADOW06,
      borderRadius: '8px',
      maxWidth: '30rem',
      alignItems: 'center',
      userSelect: 'none',
      textAlign: 'left',
      transition: 'all ease-in-out 0.1s',
      marginBottom: theme.space[3],
      padding: theme.space[4],
      color: 'black',
      [mediaquery.md]: {
        marginBottom: theme.space[5],
        padding: theme.space[6],
      },
    },
    '&__role:hover': {
      [mediaquery.md]: {
        backgroundColor: COLORS.PRIMARY100,
        border: `2px solid ${COLORS.PRIMARY100}`,
        boxShadow: SHADOWS.SHADOW16,
      },
    },
    '&__role:active': {
      transform: 'scale(0.98)',
      boxShadow: SHADOWS.SHADOW04,
    },
    '&__role--selected': {
      backgroundColor: COLORS.PRIMARY100,
      border: `2px solid ${COLORS.PRIMARY500}`,
    },
    '&__role--selected:hover': {
      border: `2px solid ${COLORS.PRIMARY500}`,
    },
    '&__role--selected > div:first-of-type': {
      backgroundColor: COLORS.PRIMARY500,
    },
    '&__role svg': {
      fill: COLORS.PRIMARY500,
      verticalAlign: '-0.125em',
    },
    '&__role--selected svg': {
      fill: COLORS.WHITE,
      verticalAlign: '-0.125em',
    },
    '&__icon-container': {
      fontSize: theme.fontSizes[7],
      border: `1px solid ${COLORS.PRIMARY500}`,
      borderRadius: '4px',
      transition: 'background-color 0.2s ease-in-out',
      marginRight: theme.space[5],
      padding: theme.space[3],
      [mediaquery.md]: {
        padding: theme.space[5],
      },
    },
    '&__heading-wrapper': {
      flexDirection: 'column',
      flex: 1,
    },
    '&__btn': {
      margin: `${theme.space[5]} 0`,
      [mediaquery.md]: {
        margin: `${theme.space[7]} 0`,
      },
    },
  },
});

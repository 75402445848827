import * as Yup from 'yup';

const REQUIRED_FIELD = 'This field is required.';
export const validationSchema = Yup.object({
  tiktokAdAccount: Yup.object()
    .shape({
      value: Yup.string().required(REQUIRED_FIELD),
      label: Yup.string().required(REQUIRED_FIELD),
    })
    .nullable()
    .required(REQUIRED_FIELD),
});

import * as yup from 'yup';

export const buttonGroupSchema = yup.array().of(
  yup.object({
    label: yup.string(),
    selected: yup.boolean().default(false),
    value: yup.string(),
    disabled: yup.boolean(),
  }),
);

export const buttonGroupRequiredSchema = yup
  .array(
    yup.object({
      label: yup.string(),
      selected: yup.boolean().default(false),
      value: yup.string(),
      disabled: yup.boolean(),
    }),
  )
  .test('at-least-one-selected', 'At least one option must be selected', (options) =>
    (options || []).some(({ selected }) => selected),
  );

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModuleSubmissionStatus } from 'shared/typings/submissions';
import { Revision, RevisionStatus } from 'shared/typings/revisions';
import { faNote } from '@fortawesome/pro-solid-svg-icons';
import COLORS from 'shared/styles/colors';
import Tooltip from 'shared/components/atoms/Tooltip';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons';
import Chip from 'shared/components/atoms/Chip/Chip';
import { SUBMISSION_COPY } from 'shared/config/copy';
import { capitalize } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'shared/styles/styleFunctions';
import { Flex } from 'shared/components/display';
import moduleHeaderIconsStyles from 'features/submissions/components/molecules/ModuleHeaderIcons/moduleHeaderIconsStyles';

interface Props {
  status: ModuleSubmissionStatus;
  isModuleLoading: boolean;
  revisions: Revision[] | undefined;
  onClick: () => void;
  moduleNum: number;
}

const ModuleHeaderIcons: React.FC<Props> = ({ status, isModuleLoading, revisions, onClick, moduleNum }) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md - 1 });

  const showRevisionsIcon =
    [ModuleSubmissionStatus.REVISION, ModuleSubmissionStatus.REVIEW].includes(status) &&
    revisions &&
    revisions?.length > 0;

  return (
    <Flex className="module-header-icons" css={moduleHeaderIconsStyles}>
      <Tooltip
        content={SUBMISSION_COPY[`MODULE_${status}_TOOLTIP`]}
        as="span"
        arrow
        placement={isMobile ? 'bottom-end' : 'right'}
        offset={isMobile ? [10, 10] : [5, 20]}
      >
        <Chip
          label={capitalize(status.toLowerCase())}
          color={COLORS.PRIMARY400}
          bgColor={COLORS.PRIMARY100}
          dataCy={`module-header-icons__status-${moduleNum}`}
          square
        />
      </Tooltip>
      {isModuleLoading ? (
        <FontAwesomeIcon
          icon={faCircleNotch}
          color={COLORS.PRIMARY500}
          data-cy={`module-header-icons__loading-${moduleNum}`}
        />
      ) : (
        <>
          {showRevisionsIcon && (
            <Tooltip
              content={SUBMISSION_COPY.MODULE_REVIEW_ICON_TOOLTIP}
              as="span"
              arrow
              placement={isMobile ? 'bottom-end' : 'right'}
              offset={isMobile ? [10, 10] : [5, 20]}
            >
              <FontAwesomeIcon
                className="module-header-icons__note"
                icon={faNote}
                color={
                  revisions?.length && revisions.some((rev) => rev.status === RevisionStatus.DOING)
                    ? COLORS.RED200
                    : COLORS.GREEN500
                }
                onClick={onClick}
              />
            </Tooltip>
          )}
        </>
      )}
    </Flex>
  );
};
export default ModuleHeaderIcons;

import React from 'react';
import RedirectOnError from 'features/auth/components/atoms/RedirectOnError';
import { useQuery } from 'shared/hooks/useQuery';
import { useAuth } from 'features/auth/useAuth';
import { getErrorStatusCode, requiresRedirectToHome } from 'shared/utilities/errorUtility';
import { useTimedRedirect } from 'shared/hooks/useTimedRedirect';
import { LinkCreator } from 'shared/utilities/linkUtility';

const VerifyResetPasswordToken = () => {
  const { verifyResetPassword, resetPasswordErrors } = useAuth();
  const { redirectToPath } = useTimedRedirect();

  const { getQuery } = useQuery();
  const urlParams = getQuery();

  const onError = () => {
    if (requiresRedirectToHome(resetPasswordErrors[0])) {
      redirectToPath({ pathname: '/' });
    }
    const tokenExpired = resetPasswordErrors.some((err) => getErrorStatusCode(err) === 401);
    if (tokenExpired) {
      redirectToPath({
        pathname: LinkCreator.createLink({
          routeKey: 'TOKEN_EXPIRED',
        }),
        state: { type: 'reset-password' },
      });
    }
  };
  return (
    <RedirectOnError
      errors={resetPasswordErrors}
      onError={onError}
      actionCallback={() => verifyResetPassword(urlParams.token)}
    />
  );
};

export default VerifyResetPasswordToken;

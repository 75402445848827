import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { getRoles, roleAdded, roleChanged, roleRemoved, rolesLoaded } from 'features/organizations/roles.slice';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_ORG_ROUTES } from 'shared/config/routes/api/apiOrgRoutes';
import { ROLE_TOASTS } from 'shared/config/toasts/roleToasts';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';

const { ROLE } = RESOURCES;
const ADDING_ROLE = `adding_${ROLE}`;
const DEFAULT_BASIC_ROLE_NAME = 'Marketer';

export const useRoles = ({ organizationId }: { organizationId?: number } = {}) => {
  const roles = useSelector(getRoles);
  const { currentUser } = useCurrentUser();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { loading: loadingNewRole } = useComponentLoading(ADDING_ROLE, false);
  const { loading } = useComponentLoading(ROLE);

  const transformPermissionsToArray = (permissions) => Object.keys(permissions).filter((perm) => permissions[perm]);

  const transformPermissionsToObject = (permissions: { value: string }[] = []) => {
    // change permissions from array to object
    // used for when sending role to backend
    return permissions.reduce((returnObj, permission) => {
      // eslint-disable-next-line no-param-reassign
      returnObj[permission.value] = true;
      return returnObj;
    }, {});
  };

  const getCurrentUserRole = (orgId: number) => {
    return currentUser.roles?.find((role) => role.organizationId === orgId);
  };

  const getDefaultBasicRole = useCallback(
    (orgId) => {
      return roles.find((role) => role.name === DEFAULT_BASIC_ROLE_NAME && role.organizationId === orgId);
    },
    [roles],
  );

  const createRole = (role, orgId) => {
    dispatch(
      apiAction({
        path: {
          route: API_ORG_ROUTES.ROLES,
          variables: {
            organizationId: orgId,
          },
        },
        method: 'POST',
        data: role,
        successAction: roleAdded,
        entity: ADDING_ROLE,
        successToast: {
          message: formatMessage(
            {
              id: 'CREATED_ROLE_TOASTS',
            },
            {
              name: role.name,
            },
          ),
        },
      }),
    );
  };

  const deleteRole = (id, orgId) => {
    dispatch(
      apiAction({
        path: {
          route: API_ORG_ROUTES.ROLE,
          variables: {
            organizationId: orgId,
            roleId: id,
          },
        },
        method: 'DELETE',
        data: { id, default: true },
        successAction: roleRemoved,
        successToast: {
          message: ROLE_TOASTS.ROLE_DELETED,
        },
      }),
    );
  };

  const editRole = (role, orgId) => {
    dispatch(
      apiAction({
        path: {
          route: API_ORG_ROUTES.ROLE,
          variables: {
            organizationId: orgId,
            roleId: role.id,
          },
        },
        method: 'PATCH',
        data: role,
        successAction: roleChanged,
        successToast: {
          message: ROLE_TOASTS.ROLE_UPDATED,
        },
      }),
    );
  };

  const [shouldFetch, setShouldFetch] = useState(true);
  useEffect(() => {
    const isOrgDefined = organizationId !== undefined && organizationId !== null;
    if (isOrgDefined && shouldFetch && roles.length === 0) {
      dispatch(
        apiAction({
          path: {
            route: API_ORG_ROUTES.ROLES,
            variables: {
              organizationId,
            },
          },
          successAction: rolesLoaded,
          entity: ROLE,
        }),
      );
      setShouldFetch(false);
    }
  }, [dispatch, roles, organizationId, shouldFetch]);

  return {
    roles,
    loading,
    loadingNewRole,
    getCurrentUserRole,
    getDefaultBasicRole,
    createRole,
    deleteRole,
    editRole,
    transformPermissionsToArray,
    transformPermissionsToObject,
  };
};

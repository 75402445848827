import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

export const MAIN_CLASS = 'total-earnings-header';
export const CLASSNAMES = {
  totalAmountLabel: `${MAIN_CLASS}__total-amount-label`,
  total: `${MAIN_CLASS}__total`,
  taxLegend: `${MAIN_CLASS}__tax-legend`,
  stripeWrapper: `${MAIN_CLASS}__stripe-wrapper`,
  mainWrapper: `${MAIN_CLASS}__main-wrapper`,
};
// @ts-ignore
export default css({
  position: 'relative',
  height: '65px',
  textTransform: 'uppercase',
  [mediaquery.md]: {
    padding: '0 10px',
  },
  [`.${MAIN_CLASS}`]: {
    '&__main-wrapper': {
      flexDirection: 'column',
      overflow: 'hidden',
    },
    '&__total-amount-label': {
      margin: 0,
      [mediaquery.md]: {
        paddingBottom: '5px',
      },
    },
    '&__total': {
      wordBreak: 'break-all',
      lineHeight: '0.6',
      marginBottom: '5px',
      width: '72%',
    },
    '&__tax-legend': {
      fontSize: '0.54rem',
      fontWeight: 600,
      color: COLORS.NEUTRAL300,
      display: 'block',
    },
    '&__stripe-wrapper': {
      position: 'absolute',
      top: '-1px',
      right: 0,
      [mediaquery.md]: {
        right: '5px',
      },
    },
  },
});

import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import cs from 'classnames';

import { Flex } from 'shared/components/display';

import COLORS from 'shared/styles/colors';
import { CLASSNAMES } from 'shared/styles/containers';

import styles from './centeredSpinnerStyles';

const { PRIMARY200 } = COLORS;

interface Props {
  className?: string;
  height?: string | number;
  width?: string | number;
  color?: string;
  ariaLabel?: string;
  wrapperStyle?: {
    [key: string]: string;
  };
  wrapperClass?: string;
  visible?: boolean;
  radius?: string | number;
  py?: string | number;
}

const CenteredSpinner: React.FC<Props> = ({ visible = true, className = '', ...props }) => {
  const py = props.py || 10;
  return (
    <Flex py={py} className={cs(className, 'centered-spinner')} css={styles}>
      <TailSpin visible={visible} color={PRIMARY200} {...props} />
    </Flex>
  );
};

export const CenteredSpinnerContainer = (props) => {
  return (
    <div style={{ display: props.visible === false ? 'none' : undefined }} className={CLASSNAMES.MAIN_CONTAINER}>
      <CenteredSpinner {...props} />
    </div>
  );
};

export default CenteredSpinner;

export const GENERIC_COPY = {
  // Button text
  BUTTON_CANCEL: 'Cancel',
  BUTTON_SUBMIT: 'Submit',
  BUTTON_SAVE: 'Save #{resource}',
  BUTTON_DELETE: 'Delete #{resource}',
  BUTTON_EDIT: 'Edit #{resource}',
  BUTTON_ADD: 'Add New {resource}',
  BUTTON_CONFIRM: 'Confirm',
  BUTTON_UPDATE: 'Update {resource}',
  BUTTON_SIGN_IN: 'Sign In',
  BUTTON_SIGN_UP: 'Sign Up',
  BUTTON_CONTINUE: 'Continue',
  BUTTON_APPLY: 'Apply',
  BUTTON_ADD_PROMOTION_CODE: 'Add Promo Code',
  BUTTON_LINK_COPIED: 'Link Copied!',
  BUTTON_PLAIN_DELETE: 'Delete',

  // Modals
  MODAL_DELETE: 'Are you sure you want to delete this #{lowerCase}?',

  // Placeholders
  PLACEHOLDER_NO_DATA: 'Nothing to show yet',
  PLACEHOLDER_SEARCH: 'Search #{plural}...',
  PLACEHOLDER_PROMOTION_CODE: 'Promo Code',

  // Branding
  APP_NAME: '#{appName}',

  // Prompt
  PROMPT_NAVIGATE_WARNING: 'Navigate away from page? Any entered information may be lost',

  //Error Page
  ERROR_PAGE_HEADER: 'Sorry, something broke...',
  ERROR_PAGE_BODY: 'Try refreshing, returning to the home page, or contacting {email}',
  ERROR_PAGE_BUTTON: 'Go Home',
  ERROR_PAGE_403_TYPE: '403 Forbidden',
  ERROR_PAGE_403_MESSAGE: 'You do not have access to this {type}',
  ERROR_PAGE_404_TYPE: '404 Page Not Found',
  ERROR_PAGE_404_MESSAGE: 'Try returning to the home page or contacting {email}',
  ERROR_PAGE_INVALID_TOKEN_TYPE: 'This invitation has expired.',
  ERROR_PAGE_INVALID_TOKEN_MESSAGE: 'Please email {email} to see if the opportunity is still available.',

  // Validation
  ERROR_STRING_LENGTH: 'Please enter a {entity} less than {numChar} characters',
  MIN: 'Value must be equal or greater than {min}',
  MAX: 'Value must be less or equal to {max}',
  // Pagination
  PAGINATION_DISPLAY: '{rowMin} - {rowMax} out of {total} items',

  // Date
  TIME_AGO: '{date} ago',
};

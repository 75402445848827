import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { STYLES } from 'shared/styles/styles';

export const SIDEBAR = {
  EXPANDED_WIDTH: '16rem',
  COLLAPSED_WIDTH: '0rem',
};

export default css({
  '&.sidebar': {
    position: 'relative',
    backgroundColor: COLORS.WHITE,
    borderRight: STYLES.BORDER_NEUTRAL,
    boxShadow: `1px 0 5px -2px ${COLORS.NEUTRAL500}`,
    height: '100%',
    padding: theme.space[5],
    width: SIDEBAR.COLLAPSED_WIDTH,
    transition: 'width 0.2s ease-in-out',
    marginRight: theme.space[5],
    flexDirection: 'column',
    '&--expanded': {
      width: SIDEBAR.EXPANDED_WIDTH,
    },
  },
  '.sidebar': {
    '& .nav-item': {
      width: `calc(${SIDEBAR.EXPANDED_WIDTH} - ${theme.space[5]} - ${theme.space[5]})`,
    },
  },
});

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import SHADOWS from 'shared/styles/shadow';
import theme from 'shared/styles/themes';
import styled from 'styled-components';

/* 
Use styled component so that brkfst styles
play nicely with the styles from react-grid-layout
that are automatically added to grid items.
*/
export const Panel = styled.div`
  padding: ${theme.space[4]};
  display: ${({
    // TODO: Fix Types
    // @ts-ignore
    isHidden,
  }) => (isHidden ? 'none' : 'flex')};
  flex-direction: column;
  background: white;
  box-shadow: ${SHADOWS.SHADOW04};
  /* user can expand widget to see overflow */
  overflow: ${({
    // TODO: Transform to emotion
    // @ts-ignore
    overflow,
  }) => overflow || 'hidden'};
  z-index: ${({
    // TODO: Transform to emotion
    // @ts-ignore
    zIndex,
  }) => zIndex};
`;

export default css({
  '&.widget-panel': {
    '&__title': {
      padding: theme.space[5],
      background: COLORS.WHITE,
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    '&__title:hover': {
      background: COLORS.WHITE,
    },
    '&__title--with-link:hover': {
      cursor: 'pointer',
      background: COLORS.NEUTRAL50,
    },
  },
});

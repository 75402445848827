import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { fontWeights } from 'shared/styles/text';
import theme from 'shared/styles/themes';

export default css({
  '&.submissions': {
    backgroundColor: COLORS.WHITE,
    'td:has(* .submissions__important)': {
      position: 'relative',
    },
  },
  '.submissions': {
    '&__placeholder': {
      fontStyle: 'italic',
      margin: theme.space[4],
      textAlign: 'center',
    },
    '&__last-col': {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '&__row': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
    '&__row--unread, &__row--unread .creator-preview__name': {
      fontWeight: fontWeights.bold,
    },
    '&__important': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '5px',
      height: '54px',
      backgroundColor: COLORS.ORANGE500,
      justifyContent: 'flex-start',
    },
    '&__extras-wrapper': {
      alignItems: 'center',
    },
  },
});

import { css } from '@emotion/react';

import COLORS, { INSTAGRAM_COLORS } from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

// Instagram's Brand Guidelines: https://en.instagram-brand.com/

export default css({
  flexWrap: 'wrap',
  alignItems: 'center',
  borderRadius: '6px',
  cursor: 'pointer',
  width: '100%',
  [mediaquery.md]: {
    justifyContent: 'start',
  },
  '.new-instagram-connect': {
    '&__ig-wrapper': {
      backgroundColor: COLORS.NEUTRAL50,
      alignItems: 'center',
      paddingRight: '6px',
      borderRadius: '5px',
      width: '100%',
      '&:hover': {
        fontWeight: 'bold',
      },
      [mediaquery.md]: {
        width: 'auto',
      },
    },
    '&__ig-icon': {
      height: '20px',
      marginRight: '10px',
      background: [
        INSTAGRAM_COLORS.PURPLE,
        `linear-gradient(0,${INSTAGRAM_COLORS.YELLOW},${INSTAGRAM_COLORS.RED},${INSTAGRAM_COLORS.PURPLE} 90%)`,
      ],
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20px',
      padding: '10px',
      borderRadius: '5px',
      '&:not(:disabled):hover': {
        background: `linear-gradient(225deg,${INSTAGRAM_COLORS.YELLOW},${INSTAGRAM_COLORS.RED},${INSTAGRAM_COLORS.PURPLE})`,
      },
      [mediaquery.md]: {
        width: 'auto',
      },
    },
    '&__ig-text': {
      textAlign: 'center',
      width: '100%',
      justifyContent: 'center',
    },
    '&__error-message': {
      color: COLORS.RED500,
      margin: 0,
      lineHeight: '1.5',
      [mediaquery.md]: {
        whiteSpace: 'nowrap',
      },
    },
    '&__connect-message': {
      fontWeight: '400',
      marginBottom: 0,
    },
    '&__connected-wrapper': {
      backgroundColor: COLORS.TRANSPARENT,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    '&__connected': {
      color: COLORS.PRIMARY500,
      padding: '0 0 8px 0',
      '&:hover': {
        fontWeight: 'bold',
      },
    },
    '&__disconnect': {
      color: COLORS.NEUTRAL500,
      padding: '0 12px',
      margin: 0,
      '&:hover': {
        fontWeight: 'bold',
      },
    },
    '&__disconnect-wrapper': {
      alignItems: 'center',
      '&--disabled': {
        cursor: 'not-allowed',
      },
    },
    '&__disconnect-icon': {
      fontSize: '10px',
    },
    '&__disconnecting-spinner': {
      width: '16px',
    },
  },
});

import React, { useMemo } from 'react';
import { isEqual } from 'lodash';

import { PerformanceLevels } from 'features/performance/enums';
import { Flex, Text } from 'shared/components/display';
import { SegmentedButton, SegmentOption } from 'shared/components/molecules/SegmentedButton';

import { usePerformance } from 'shared/hooks/usePerformance';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { PlatformShortNames } from 'shared/typings/accountPlatform/enums';
import LevelUtility from 'shared/utilities/Performance/levelUtility';

import levelSelectorStyles from './levelSelectorStyles';

const { OVERLINE } = TEXT_VARIANTS;

const LEVELS: SegmentOption[] = [
  { text: 'Account', value: PerformanceLevels.ACCOUNT },
  {
    text: 'Campaign',
    value: PerformanceLevels.CAMPAIGN,
  },
];

const AD_LEVEL: SegmentOption = { text: 'Ad', value: PerformanceLevels.AD };
const ADSET_LEVEL: SegmentOption = { text: 'AdSet', value: PerformanceLevels.ADSET };
const ADGROUP_LEVEL: SegmentOption = { text: 'Ad Group', value: PerformanceLevels.ADSET };

interface Props {
  platformName: string;
  showAdLevel?: boolean;
}

const LevelSelector: React.FC<Props> = ({ platformName, showAdLevel = false }) => {
  const {
    performanceState: { columnsChosen, level },
    updateLevelAndFetchData,
  } = usePerformance();

  const levelOptions = useMemo(() => {
    let options = [...LEVELS];
    if (platformName === PlatformShortNames.FACEBOOK) options.push(ADSET_LEVEL);
    else if (platformName === PlatformShortNames.TIKTOK) options.push(ADGROUP_LEVEL);
    if (showAdLevel) return options.concat(AD_LEVEL);
    return options;
  }, [showAdLevel, platformName]);

  const setLevelCallback = (levelSelected) => {
    const columns = [...columnsChosen];
    const columnsFilteredByLevel = LevelUtility.formatColumns({
      columns,
      level: levelSelected.value,
    });
    if (!isEqual(columnsChosen, columnsFilteredByLevel)) {
      updateLevelAndFetchData(levelSelected.value, columnsFilteredByLevel);
    } else {
      updateLevelAndFetchData(levelSelected.value);
    }
  };

  return (
    <Flex className="level-selector" css={levelSelectorStyles}>
      <Text variant={OVERLINE} className="level-selector__label">
        Level
      </Text>
      <SegmentedButton value={level || 'campaign'} options={levelOptions} onChanged={setLevelCallback} />
    </Flex>
  );
};

export default LevelSelector;

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';

import { Box, Flex, Text } from 'shared/components/display';

import { USER_COPY } from 'shared/config/copy';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';

import {
  STRIPE_FIELDNAMES,
  StripeFormFields,
  stripeInitialValues,
  stripeSchema,
} from '../../CreatorOnboardingStepsFields';

import styles from './styles';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { UserType } from 'shared/typings/user/enums';
import NotificationBanner from 'shared/components/atoms/NotificationBanner';

interface Props {
  user: BrkfstUser;
  onSubmit: (values: any) => void;
}

const PaymentInfoForm: React.FC<Props> = ({ user, onSubmit }) => {
  const initialValues = {
    [STRIPE_FIELDNAMES.CONNECTED]: user.stripeAccountId || stripeInitialValues[STRIPE_FIELDNAMES.CONNECTED],
  };

  const stripeRedirectUri = LinkCreator.createLink({
    userType: UserType.CREATOR,
    routeKey: 'ACCOUNT_SETTINGS',
    variables: {
      userId: user.id,
    },
    query: {
      tab: 'Payment',
    },
  });

  return (
    <Box css={styles} className="payment-info-form">
      <Formik initialValues={initialValues} validationSchema={stripeSchema} onSubmit={onSubmit}>
        {() => (
          <Form>
            <Text variant={TEXT_VARIANTS.SUBHEADING} className="payment-info-form__stripe-title">
              Stripe
            </Text>
            <StripeFormFields user={user} redirectURI={stripeRedirectUri} />
            {!user.stripeAccountId && (
              <NotificationBanner
                className="payment-info-form__connect-stripe-wrapper"
                theme="warning"
                message={ReactHtmlParser(USER_COPY.DESCRIPTION_STRIPE_CONNECT)}
                extraStyles={{ padding: '10px' }}
              />
            )}
            <Text variant={TEXT_VARIANTS.SUBHEADING} className="payment-info-form__pricing-structure-title">
              Pricing Structure
            </Text>
            <Box color={COLORS.NEUTRAL500} className="payment-info-form__pricing-structure-description">
              {ReactHtmlParser(USER_COPY.DESCRIPTION_PAYMENT)}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PaymentInfoForm;

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { useOrganizations } from 'features/organizations/useOrganizations';
import CenteredSpinner from 'features/ui/CenteredSpinner';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { LinkCreator } from 'shared/utilities/linkUtility';

const RedirectToAccountHealth = () => {
  const { lastVisitedOrg } = useCurrentUser();
  const { fetchOrg } = useOrganizations(lastVisitedOrg);
  const { accounts } = useAccounts();
  // Separate the loading state from `useComponentLoading`.
  // The `get-by-token` function alters the loading state in the ui.slice in redux,
  // making the `initialLoadingState` argument in `useComponentLoading` useless.
  // The get-by-token assigns it to `false` before this component is rendered.
  const [loading, setLoading] = useState(true);
  const onResponse = () => setLoading(false);
  useEffect(() => {
    if (lastVisitedOrg) fetchOrg({ onSuccess: onResponse, onError: onResponse });
  }, [lastVisitedOrg]);

  if (loading) return <CenteredSpinner />;

  return accounts?.length ? (
    <Navigate
      to={LinkCreator.createLink({
        routeKey: 'ACCOUNT_HEALTH',
        variables: {
          organizationId: lastVisitedOrg,
          accountId: accounts[0].id,
        },
      })}
    />
  ) : (
    <Navigate
      to={LinkCreator.createLink({
        routeKey: 'ORGANIZATION',
        variables: {
          organizationId: lastVisitedOrg,
        },
      })}
    />
  );
};

export default RedirectToAccountHealth;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '.token-expired': {
    '&__form': {
      maxWidth: '850px',
    },
    '&__submit-btn': {
      marginTop: theme.space[4],
    },
  },
});

export const CHILD_TAGS = ['child', 'children'];
export const TEENAGER_TAGS = ['teenager', 'teenagers'];
const COUPLE_TAG = 'couple';

export const formatInitialModelValues = (models) => {
  return {
    models: models
      ?.filter(({ value }) => ![...CHILD_TAGS, ...TEENAGER_TAGS, COUPLE_TAG].includes(value))
      .concat(...formattedModelOptions),
  };
};

export const formattedModelOptions = [
  {
    label: 'Couple (shoot with a partner)',
    value: 'couple',
  },
  {
    label: 'Children (less than 13)',
    value: 'children',
  },
  {
    label: 'Teenagers (13 to 18)',
    value: 'teenagers',
  },
];

import React from 'react';
import { Button } from 'rebass/styled-components';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ProfilePicture } from 'features/users/components/molecules/ProfilePicture';
import SmallCreatorPreview from 'features/users/components/SmallCreatorPreview/SmallCreatorPreview';
import SmallMarketerPreview from 'features/users/components/SmallMarketerPreview/SmallMarketerPreview';
import { Flex } from 'shared/components/display';
import Dropdown, { DropdownChildren } from 'shared/components/molecules/Dropdown';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { BUTTON_VARIANTS } from 'shared/styles/button';

import styles from './styles';

const { TEXT_ONLY } = BUTTON_VARIANTS;
interface Props {
  children: DropdownChildren;
}

const UserDropdown: React.FC<Props> = ({ children }) => {
  const { currentUser, isCreator } = useCurrentUser();

  const yOffset = isCreator ? 15 : 5;

  return (
    <Flex css={styles} className="user-dropdown">
      <ProfilePicture size="xs" user={currentUser} disableNavigate={!currentUser.onboarded} />
      <Dropdown
        ClickComponent={
          <Flex className="user-dropdown__name-arrow-wrapper">
            {isCreator ? (
              <SmallCreatorPreview user={currentUser} hideProfilePicture />
            ) : (
              <SmallMarketerPreview user={currentUser} showOrg hideProfilePicture />
            )}
            &nbsp;&nbsp;
            <Button variant={TEXT_ONLY} className="user-dropdown__arrow-btn">
              <FontAwesomeIcon icon={faAngleDown} />
            </Button>
          </Flex>
        }
        offset={{
          crossAxis: 0,
          mainAxis: yOffset,
        }}
        allRounded
        width="180px"
        disablePortal
      >
        {children}
      </Dropdown>
    </Flex>
  );
};

export default UserDropdown;

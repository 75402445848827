import React, { useMemo } from 'react';
import cs from 'classnames';
import { Field, Form, Formik } from 'formik';
import { startCase } from 'lodash';

import { Breakdowns, TopBottomFieldnames } from 'features/performance/enums';
import { Box } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import NumberInput from 'shared/components/molecules/NumberInput';
import { SelectInput } from 'shared/components/molecules/Select';
import { emptySelectOption } from 'shared/components/molecules/Select/SelectInput';

import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import { makeLabel } from 'shared/lib/formik';
import { arrayIncludesString } from 'shared/utilities/stringUtility';

import {
  ageOptions,
  BLACK_LIST,
  devicePlatformOptions,
  genderOptions,
  platformPositionOptions,
  publisherPlatformOptions,
} from './config';
import styles from './styles';
import { TopBottomAnalysisValues } from './types';
import { topBottomSchema } from './validation';

interface Props {
  onSubmit: (values: TopBottomAnalysisValues) => void;
  performanceColumns: string[];
  breakdownValue: Breakdowns;
  values: TopBottomAnalysisValues;
}
const TopBottomForm: React.FC<Props> = ({ onSubmit, performanceColumns, breakdownValue, values }) => {
  const { showAgeSelect, showGenderSelect, showPlacementSelects } = useMemo(() => {
    return {
      showAgeSelect: [Breakdowns.AGE, Breakdowns.AGE_GENDER].includes(breakdownValue),
      showGenderSelect: [Breakdowns.GENDER, Breakdowns.AGE_GENDER].includes(breakdownValue),
      showPlacementSelects: breakdownValue === Breakdowns.PLACEMENT,
    };
  }, [breakdownValue]);

  const rankByOptions = useMemo(() => {
    const options = performanceColumns
      .filter((field) => !arrayIncludesString(BLACK_LIST, field))
      .map((value) => ({ value, label: startCase(value) }));
    options.unshift(emptySelectOption);
    return options;
  }, [performanceColumns]);

  const initialValuesWithBreakdown = useMemo(() => {
    return {
      ...values,
      breakdown: breakdownValue,
    };
  }, [breakdownValue, values]);

  return (
    <Formik<TopBottomAnalysisValues>
      onSubmit={onSubmit}
      initialValues={initialValuesWithBreakdown}
      validationSchema={topBottomSchema}
      validateOnBlur={false}
      enableReinitialize
    >
      <Box css={styles} className="top-bottom-tool-form">
        <Form>
          <Field
            name={TopBottomFieldnames.RANK_BY}
            component={SelectInput}
            menuPortalTarget={'top-bottom-form-dropdown'}
            options={rankByOptions}
            placeholder={makeLabel(TopBottomFieldnames.RANK_BY, true)}
          />
          <Field
            component={NumberInput}
            name={TopBottomFieldnames.TOP_BOTTOM_AMOUNT}
            label={makeLabel(TopBottomFieldnames.TOP_BOTTOM_AMOUNT, true)}
            placeholder={makeLabel(TopBottomFieldnames.TOP_BOTTOM_AMOUNT, true)}
          />
          <Field
            name={TopBottomFieldnames.AGE}
            component={SelectInput}
            menuPortalTarget={'top-bottom-form-dropdown'}
            options={ageOptions}
            placeholder={makeLabel(TopBottomFieldnames.AGE, true)}
            className={cs('top-bottom-tool-form__select-input', {
              'top-bottom-tool-form__select-input--hidden': !showAgeSelect,
            })}
          />
          <Field
            name={TopBottomFieldnames.GENDER}
            component={SelectInput}
            menuPortalTarget={'top-bottom-form-dropdown'}
            options={genderOptions}
            placeholder={makeLabel(TopBottomFieldnames.GENDER, true)}
            className={cs('top-bottom-tool-form__select-input', {
              'top-bottom-tool-form__select-input--hidden': !showGenderSelect,
            })}
          />
          <Field
            name={TopBottomFieldnames.PUBLISHER_PLATFORM}
            component={SelectInput}
            menuPortalTarget={'top-bottom-form-dropdown'}
            options={publisherPlatformOptions}
            placeholder={makeLabel(TopBottomFieldnames.PUBLISHER_PLATFORM, true)}
            className={cs('top-bottom-tool-form__select-input', {
              'top-bottom-tool-form__select-input--hidden': !showPlacementSelects,
            })}
          />
          <Field
            name={TopBottomFieldnames.PLATFORM_POSITION}
            component={SelectInput}
            menuPortalTarget={'top-bottom-form-dropdown'}
            options={platformPositionOptions}
            placeholder={makeLabel(TopBottomFieldnames.PLATFORM_POSITION, true)}
            className={cs('top-bottom-tool-form__select-input', {
              'top-bottom-tool-form__select-input--hidden': !showPlacementSelects,
            })}
          />
          <Field
            name={TopBottomFieldnames.DEVICE_PLATFORM}
            component={SelectInput}
            menuPortalTarget={'top-bottom-form-dropdown'}
            options={devicePlatformOptions}
            placeholder={makeLabel(TopBottomFieldnames.DEVICE_PLATFORM, true)}
            className={cs('top-bottom-tool-form__select-input', {
              'top-bottom-tool-form__select-input--hidden': !showPlacementSelects,
            })}
          />
          <FormButton className="top-bottom-tool-form__submit-btn">
            {ACCOUNT_PLATFORM_COPY.BUTTON_TOP_BOTTOM_SUBMIT}
          </FormButton>
        </Form>
      </Box>
    </Formik>
  );
};

export default TopBottomForm;

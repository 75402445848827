export default {
  ACTIVATE: '/user/activate',

  FORGOT_PASSWORD: '/forgot-password',

  FORGOT_PASSWORD_CONFIRMATION: '/forgot-password-confirmation',

  TOKEN_EXPIRED: '/user/request-new',

  RESET_PASSWORD: '/user/reset-password',

  RESET_PASSWORD_CONFIRMATION: '/user/reset-password-confirmation',

  VERIFY_RESET_PASSWORD: '/user/verify-reset-password',

  SIGN_IN: '/sign-in',

  SIGN_UP: '/sign-up',

  SIGN_UP_CONFIRMATION: '/user/sign-up-confirmation',

  DOWNLOAD_READY: '/download-ready',

  GOOGLE_OAUTH: '/user/google',

  FACEBOOK_OAUTH: '/user/facebook',
};

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.confirmation-summary': {
    padding: '16px',
    position: 'relative',
    border: `1px solid ${COLORS.NEUTRAL100}`,
    borderRadius: '2px',
    backgroundColor: 'white',
    maxHeight: '75px',
    [mediaquery.md]: {
      width: '300px',
    },
  },
  '.confirmation-summary': {
    '&__submit-btn': {
      padding: '10px',
      width: '100%',
      whiteSpace: 'nowrap',
    },
  },
});

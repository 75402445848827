import React from 'react';
import { Field, Form, Formik } from 'formik';

import { Box } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import {
  adminCommentMaxLength,
  COMMENT,
  commentLabel,
  commentMaxLength,
  moduleReviewInitialValues,
  moduleReviewSchema,
  Values,
} from 'shared/components/molecules/ModuleReviewForm/moduleReviewFormConfig';
import moduleReviewFormStyles from 'shared/components/molecules/ModuleReviewForm/moduleReviewFormStyles';
import TextareaInput from 'shared/components/TextareaInput/TextareaInput';

import { SUBMISSION_COPY } from 'shared/config/copy';

interface Props {
  onSubmit: (data: Values) => void;
  isSuperadmin: boolean | undefined;
}

const ModuleReviewForm: React.FC<Props> = ({ onSubmit, isSuperadmin }) => {
  return (
    <Formik validationSchema={moduleReviewSchema} onSubmit={onSubmit} initialValues={moduleReviewInitialValues}>
      {() => (
        <Box className="review-form" css={moduleReviewFormStyles}>
          <Form>
            <Field
              component={TextareaInput}
              maxLength={isSuperadmin ? adminCommentMaxLength : commentMaxLength}
              softMaxLength={isSuperadmin ? adminCommentMaxLength : commentMaxLength}
              showCharactersCounter
              name={COMMENT}
              label={commentLabel}
              placeholder={commentLabel}
              rows={5}
              allowVerticalResize
              className="review-form__textarea"
              dataCy="module-review-form__textarea"
            />
            <FormButton className="review-form__submit-btn" dataCy="review-form__submit-btn">
              {SUBMISSION_COPY.BUTTON_REVIEW_SUBMIT}
            </FormButton>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default ModuleReviewForm;

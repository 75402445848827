import React, { useState } from 'react';
import { Button } from 'rebass/styled-components';

import { Flex } from 'shared/components/display';
import Modal from 'shared/components/molecules/Modal';
import ModuleReviewForm from 'shared/components/molecules/ModuleReviewForm';
import { Values } from 'shared/components/molecules/ModuleReviewForm/moduleReviewFormConfig';
import { REVISION_CATEGORY, REVISION_CATEGORY_DISPLAY } from 'shared/components/organisms/ModuleReviewFormModal/config';
import moduleReviewFormModalStyles from 'shared/components/organisms/ModuleReviewFormModal/moduleReviewFormModalStyles';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { intl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { ApiOnSuccess } from 'shared/typings/api';

interface Props {
  onSubmit: (args: { data: Values; errorCategory: string; onSuccess: ApiOnSuccess }) => void;
  onClose: (any) => void;
  isOpen?: boolean;
  showSkip?: boolean;
  onSkip?: () => void;
}

const ModuleReviewFormModal: React.FC<Props> = ({ onSubmit, onClose, isOpen = false, showSkip = false, onSkip }) => {
  const [category, setCategory] = useState<string>('');
  const { isSuperadmin } = useCurrentUser();

  const clearCategory = () => setCategory('');

  return (
    <Modal
      title={
        category
          ? intl.formatMessage(
              {
                id: 'MODAL_TITLE_REVIEW',
              },
              {
                errorCategory: REVISION_CATEGORY_DISPLAY[category],
              },
            )
          : ''
      }
      isOpen={isOpen}
      onRequestClose={category ? clearCategory : onClose}
      ariaLabel="Pick Error Category"
      component={
        category ? (
          <ModuleReviewForm
            onSubmit={(data) => {
              onSubmit({ data, errorCategory: category, onSuccess: clearCategory });
            }}
            isSuperadmin={isSuperadmin}
          />
        ) : (
          <Flex css={moduleReviewFormModalStyles} className="review-modal">
            {Object.values(REVISION_CATEGORY).map((category) => {
              return (
                <Button
                  variant={BUTTON_VARIANTS.SECONDARY}
                  onClick={() => setCategory(category)}
                  className="review-modal__btn"
                  data-cy={`review-modal__btn--${REVISION_CATEGORY_DISPLAY[category]}`}
                  key={category}
                >
                  {REVISION_CATEGORY_DISPLAY[category]}
                </Button>
              );
            })}
            {showSkip && (
              <Button
                variant={BUTTON_VARIANTS.TEXT_ONLY}
                onClick={onSkip}
                data-cy="review-modal__skip-btn"
                className="review-modal__skip-btn"
              >
                Skip
              </Button>
            )}
          </Flex>
        )
      }
      transparentBackground={!category}
    />
  );
};

export default ModuleReviewFormModal;

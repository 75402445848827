import { css } from '@emotion/react';
import { zIndex } from 'shared/config';

export default css({
  overflowY: 'auto',
  flex: 1,
  display: 'flex',
  zIndex: zIndex.zForeground,
  '.route-wrapper': {
    overflowY: 'auto',
    flex: '1',
  },
});

import { Box, Flex, Text } from 'shared/components/display';
import { StepWrapperProps } from 'shared/components/forms/MultiStepForm/interfaces';
import VerticalStepHeader from 'shared/components/forms/MultiStepForm/Steps/VerticalStepWrapper/VerticalStepHeader';
import verticalStepStyles from 'shared/components/forms/MultiStepForm/Steps/VerticalStepWrapper/verticalStepStyles';
import { Constants } from 'shared/config';
import { TEXT_VARIANTS } from 'shared/styles/text';
import cs from 'classnames';
import React from 'react';

const VerticalStepWrapper: React.FC<StepWrapperProps> = ({
  children,
  isActive,
  isActiveStepDirty,
  isCompleted,
  isOptional,
  onStepChange,
  stepKey = 0,
  title,
  titleInfo,
  isConfirmationStep,
}) => {
  const handleKeypress = (keypressEvent) => {
    if (keypressEvent.charCode === Constants.KEY_CODES.ENTER_CHAR_CODE && onStepChange) {
      onStepChange();
    }
  };

  const navigateToCompletedStep = () => {
    // can only navigate to completed steps
    if (onStepChange) onStepChange();
  };

  return (
    <Box
      css={verticalStepStyles}
      className={cs('vertical-step', {
        'vertical-step--active': isActive,
        'vertical-step--completed': isCompleted,
      })}
    >
      <VerticalStepHeader
        isCompleted={isCompleted}
        stepKey={stepKey}
        title={title}
        onClick={navigateToCompletedStep}
        onKeyPress={handleKeypress}
        isActiveStepDirty={isActiveStepDirty}
        isActive={isActive}
        titleInfo={titleInfo}
      />
      {isOptional && (
        <Text as="h3" variant={TEXT_VARIANTS.CAPTION} className="vertical-step__subtitle">
          Optional
        </Text>
      )}
      <Flex className="vertical-step__content-wrapper">
        {isActive && (
          <Flex
            className={cs('vertical-step__body', {
              ['vertical-step__body--confirmation']: isConfirmationStep,
            })}
          >
            {children}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

export default VerticalStepWrapper;

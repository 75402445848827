import React from 'react';
import { Field } from 'formik';
import TextInput from 'shared/components/TextInput';
import CreditCardInput, {
  creditCardInitialValue,
  getCreditCardSchema,
} from 'shared/components/molecules/CreditCardInput';
import * as yup from 'yup';
import { MESSAGES } from 'shared/config/validations';
import { CardElement } from '@stripe/react-stripe-js';
import { StepOnSubmit } from 'shared/components/forms/MultiStepForm/interfaces';
import { intl } from 'shared/lib/intl';
import { OrgBillingValues } from 'features/organizations/components/organisms/OrgOnboardingStepFields/types';
import { makeLabel } from 'shared/lib/formik';

export const OrgBillingStepFields: React.FC = () => {
  return (
    <>
      <Field
        name={ORG_BILLING_FIELDNAMES.CREDIT_CARD_OWNER}
        label={makeLabel(ORG_BILLING_FIELDNAMES.CREDIT_CARD_OWNER, true)}
        placeholder={makeLabel(ORG_BILLING_FIELDNAMES.CREDIT_CARD_OWNER, true)}
        component={TextInput}
      />
      <Field
        name={ORG_BILLING_FIELDNAMES.CREDIT_CARD}
        label={makeLabel(ORG_BILLING_FIELDNAMES.CREDIT_CARD, true)}
        placeholder={makeLabel(ORG_BILLING_FIELDNAMES.CREDIT_CARD, true)}
        component={CreditCardInput}
      />
    </>
  );
};

const ORG_BILLING_FIELDNAMES = {
  CREDIT_CARD_OWNER: 'creditCardOwner',
  CREDIT_CARD: 'creditCard',
};

export const orgBillingInitialValues: OrgBillingValues = {
  creditCardOwner: '',
  creditCard: creditCardInitialValue,
};

// @ts-ignore
export const orgBillingSchema: yup.SchemaOf<OrgBillingValues> = yup.object({
  creditCardOwner: yup.string().required(MESSAGES.REQUIRED_FIELD),
  creditCard: getCreditCardSchema(true),
});

export const orgBillingOnSubmit: StepOnSubmit<OrgBillingValues> = async (cardData, formikHelpers, stripe, elements) => {
  /**
   * try to see if the stripe input is mounted and if so get new
   * payment info from stripe.
   * If it's not, stripe will throw an error and we check if there
   * is a previously added card to use instead (user can edit payment info)
   */
  try {
    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements?.getElement(CardElement);

    if (cardElement) {
      const res = await stripe?.createSource(cardElement, {
        type: 'card',
        owner: {
          name: cardData.creditCardOwner,
        },
      });
      if (res?.error) {
        return {
          newValues: {
            ...cardData,
            creditCard: {
              ...cardData.creditCard,
              error: res.error.message,
            },
          },
          success: false,
        };
      }

      return res?.source
        ? {
            newValues: {
              creditCardOwner: cardData.creditCardOwner,
              creditCard: {
                complete: true,
                card: {
                  sourceId: res.source.id,
                  expiration: `${res.source.card?.exp_month}/${res.source.card?.exp_year}`,
                  default: true,
                  name: intl.formatMessage(
                    {
                      id: 'DEFAULT_CARD_NAME',
                    },
                    {
                      brand: res.source.card?.brand,
                      last4: res.source.card?.last4,
                    },
                  ),
                },
              },
            },
            success: true,
          }
        : {
            success: false,
          };
    } else {
      // if the step has already been filled out and not changing the data
      return {
        newValues: cardData,
        success: true,
      };
    }
  } catch (error) {
    if (cardData.creditCard?.card?.sourceId) {
      return {
        success: true,
        newValues: cardData,
      };
    }
    return {
      success: false,
    };
  }
};

import React, { forwardRef, Fragment } from 'react';
import { faPencil, faUnlink } from '@fortawesome/pro-regular-svg-icons';
import { noop } from 'lodash';
import { BaseSelection, Editor, Element as SlateElement, NodeEntry } from 'slate';
import { ReactEditor } from 'slate-react';

import IconButton from 'shared/components/atoms/IconButton';
import Tooltip from 'shared/components/atoms/Tooltip';
import { Flex } from 'shared/components/display';
import { EditRichTextLinkForm, RichTextLink } from 'shared/components/Richtext/RichTextLink';

import { ELEMENTS } from 'shared/styles/styles';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { addHttp } from 'shared/utilities/stringUtility';

import { unwrapLink } from '../../plugins';

import richTextLinkMenuStyles from './styles';

interface Props {
  editor: Editor;
  selection?: BaseSelection;
  element?: SlateElement;
  isEditMode: boolean;
  node?: NodeEntry | any[];
  setEditMode?: (editMode: boolean) => void;
  onHide?: () => void;
  onClickOption?: () => void;
  onBlur?: (e) => void;
  disabled?: boolean;
}

const RichTextLinkMenu = forwardRef<HTMLDivElement, Props>(
  (
    { editor, selection, element, isEditMode, node = [], setEditMode = () => {}, onHide = noop, disabled, onBlur },
    ref,
  ) => {
    const navigateToLink = (url) => {
      window.open(url, '_blank', 'rel=noopener noreferrer');
    };
    return (
      <Flex css={richTextLinkMenuStyles} className="rich-text-link-menu" tabIndex={0} onBlur={onBlur} ref={ref}>
        {isEditMode ? (
          <EditRichTextLinkForm editor={editor} selection={selection} url={element?.url} onHide={onHide} node={node} />
        ) : (
          <Fragment>
            <RichTextLink
              element={{ url: element?.url }}
              variant={TEXT_VARIANTS.BODY}
              className="rich-text-link-menu__link endWithEllipsis"
              onClick={() => navigateToLink(addHttp(element?.url))}
              disabled={disabled}
            >
              {element?.url}
            </RichTextLink>
            <Tooltip
              content="Edit Link"
              as={ELEMENTS.SPAN}
              arrow
              placement="bottom"
              offset={[0, 15]}
              className="rich-text-link-menu__tooltip"
            >
              <IconButton
                icon={faPencil}
                onClick={() => {
                  setEditMode(true);
                  ReactEditor.focus(editor);
                }}
                className="rich-text-link-menu__btn"
              />
            </Tooltip>
            <Tooltip
              content="Remove Link"
              as={ELEMENTS.SPAN}
              arrow
              placement="bottom"
              offset={[0, 15]}
              className="rich-text-link-menu__tooltip"
            >
              {/* TODO: Fix Types */}
              {/* @ts-ignore */}
              <IconButton
                icon={faUnlink}
                onClick={() => {
                  onHide();
                  ReactEditor.focus(editor);
                  unwrapLink(editor);
                }}
                className="rich-text-link-menu__btn"
              />
            </Tooltip>
          </Fragment>
        )}
      </Flex>
    );
  },
);

export default RichTextLinkMenu;

import React, { RefObject, useMemo } from 'react';
import cs from 'classnames';
import { Text } from 'rebass/styled-components';

import { Input } from 'shared/lib/formik';
import { TEXT_VARIANTS } from 'shared/styles/text';

import { Box } from '../display';
import FieldErrorMessage from '../FieldErrorMessage';
import Textarea from 'shared/styles/Textarea/Textarea';
import { useIntl } from 'react-intl';

import textareaStyles from './styles';
import { FormikErrors } from 'formik';

type Props = {
  maxLength?: number;
  softMaxLength?: number;
  disabled?: boolean;
  placeholder?: string;
  rows?: number;
  allowVerticalResize?: boolean;
  className?: string;
  onInput?: React.FormEventHandler<HTMLTextAreaElement>;
  softValidationMessage: string;
  showCharactersCounter?: boolean;
  innerRef?: RefObject<HTMLTextAreaElement>;
  noMargin?: boolean;
  dataCy?: string;
};

const TextareaInput: Input<string, Props> = ({
  field: { onChange, onBlur, value = '', name },
  form: { errors, touched },
  maxLength,
  softMaxLength,
  disabled,
  placeholder,
  rows,
  allowVerticalResize,
  className,
  onInput,
  softValidationMessage,
  showCharactersCounter,
  innerRef,
  noMargin,
  dataCy,
}) => {
  const { formatMessage } = useIntl();

  const hasErrors = useMemo(() => {
    const error = errors[name] as FormikErrors<any> | undefined;
    const errorIn = Array.isArray(error) ? error[0] : error;
    return Boolean(errorIn && touched[name]);
  }, [errors, touched, name]);

  return (
    <Box
      css={textareaStyles}
      className={cs('textarea-input', className, {
        'textarea-input--error': hasErrors,
      })}
    >
      <Textarea
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        onInput={onInput}
        maxLength={maxLength}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        rows={rows}
        allowVerticalResize={allowVerticalResize}
        ref={innerRef}
        noMargin={noMargin}
        data-cy={dataCy}
      />
      <Text className="characters-remaining" variant={TEXT_VARIANTS.CAPTION} mb={3} mt={2}>
        {showCharactersCounter &&
          softMaxLength &&
          formatMessage({ id: 'INPUT_CHARACTERS_REMAINING' }, { numChar: softMaxLength - (value?.length || 0) })}
        {softValidationMessage}
      </Text>
      <FieldErrorMessage name={name} />
    </Box>
  );
};

export default TextareaInput;

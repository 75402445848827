import React from 'react';
import { Flex } from 'rebass/styled-components';
import { Placement } from '@floating-ui/react';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import Dropdown, { DropdownChildren } from 'shared/components/molecules/Dropdown';

import COLORS from 'shared/styles/colors';

const { NEUTRAL300 } = COLORS;

interface Props {
  color?: string;
  placement?: Placement;
  isVertical?: boolean;
  children: DropdownChildren;
  width?: string | number;
  className?: string;
  portalTarget?: string;
  dataCy?: string;
  disablePortal?: boolean;
  debug?: boolean;
}

const Elliptical: React.FC<Props> = ({
  color = NEUTRAL300,
  placement,
  isVertical,
  children,
  width,
  className,
  portalTarget,
  dataCy,
  disablePortal,
  debug,
}) => {
  return (
    <Dropdown
      className={className}
      ClickComponent={
        <Flex alignItems="center" p={2}>
          <FontAwesomeIcon
            icon={faEllipsisH}
            color={color}
            className={cs('fa-fw', {
              ['fa-rotate-90']: isVertical,
            })}
          />
        </Flex>
      }
      placement={placement}
      width={width}
      portalTarget={portalTarget}
      dataCy={dataCy}
      disablePortal={disablePortal}
      debug={debug}
      allRounded
    >
      {children}
    </Dropdown>
  );
};

export default Elliptical;

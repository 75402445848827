import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.brkfst-toast': {
    flexDirection: 'column',
    flex: 1,
    paddingLeft: theme.space[2],
  },
  '.brkfst-toast': {
    '&__heading, &__body': {
      overflowWrap: 'anywhere',
      marginBottom: theme.space[1],
    },
  },
});

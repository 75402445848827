/* eslint-disable no-param-reassign */
import { isEmpty } from 'lodash';
import qs from 'qs';

import { ALL_ROUTES } from 'shared/config/routes/generalRoutes';
import { UserType } from 'shared/typings/user/enums';
import { LinkUtilityConfiguration, RouteKey } from 'shared/utilities/linkUtility/types';

export class LinkCreator {
  private route: string;

  private variables: any;

  private userType: UserType;

  private routeKey?: RouteKey;

  private query: any;

  constructor(configuration: LinkUtilityConfiguration) {
    this.variables = configuration.variables || {};
    this.userType = configuration.userType || UserType.ORG;
    this.routeKey = configuration.routeKey;
    this.query = configuration.query || {};
    this.route = configuration.route || '';
  }

  static createLink({
    userType = UserType.ORG,
    routeKey,
    variables = {},
    query = {},
  }: LinkUtilityConfiguration): string {
    return new LinkCreator({
      userType,
      routeKey,
      variables,
      query,
    })._createLink();
  }

  static createApiLink({ route, variables = {}, query = {}, path = '' }): string {
    // for development purposes, the path can be provided to be used before routes have been built out (will be removed later)
    return (
      path ||
      new LinkCreator({
        route,
        variables,
        query,
      })._createApiLink()
    );
  }

  _createApiLink(): string {
    const link = LinkCreator._makeLinkFromRoute(this.route, this.variables);
    LinkCreator._checkForMissingVariables(link);
    return this._addQuery(link);
  }

  _createLink(): string {
    this._setRoute();

    const link = LinkCreator._makeLinkFromRoute(this.route, this.variables);
    LinkCreator._checkForMissingVariables(link);
    return this._addQuery(link);
  }

  _addQuery(link: string): string {
    if (!isEmpty(this.query)) {
      const queryString = qs.stringify(this.query);
      link += queryString.replace(/(^&)*/, '?');
    }
    return link;
  }

  _setRoute() {
    const type: keyof typeof ALL_ROUTES = this.userType === UserType.CREATOR ? 'CREATORS' : 'BRANDS';
    this.route = ALL_ROUTES[type][this.routeKey || ''];
    if (!this.route) throw new Error(`${type} route '${this.routeKey}' not found`);
  }

  static _makeLinkFromRoute(route: string, variables: any = {}) {
    /**
     * variables is an object with the key being the name of the param:
     * Ex: { accountId: 1 }
     *
     * route is a string path
     */

    const variableNames = Object.keys(variables);

    return variableNames.reduce((link, varName) => {
      // get the value of the current variable
      const varValue = variables[varName];

      // pattern to match variable to param
      const paramRE = new RegExp(`:${varName}`);

      // replace all instances of it in the route
      const newLink = link.replace(paramRE, varValue);

      return newLink;
    }, route);
  }

  static _checkForMissingVariables(link: string) {
    // find any params still present in the link
    const paramRE = /:([A-Za-z]+)(\/|$|\s)/g;
    const matches = link.match(paramRE);
    if (matches && matches.length > 0) {
      const missingVariables = matches.map((match) => match.replace(/[:/]/g, '')).join(', ');
      throw new Error(`Missing: ${missingVariables}`);
    }
  }
}
/**
 * 
// Usage:
const link = LinkCreator.createLink({ userType: USER_TYPES.CREATOR, routeKey: 'SIGN_IN', variables: {
    organizationId,
    accountId,
}});


*/

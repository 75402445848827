import React from 'react';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';

import TrackDirtyState from 'shared/components/atoms/TrackDirtyState';
import { Box } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import { StepFormProps } from 'shared/components/forms/MultiStepForm/interfaces';

import { GENERIC_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';

const StepForm = <Values extends {}>({
  children,
  disableUntilTouched = false,
  hideUntilTouched = false,
  initialValues,
  isActive = false,
  isOptional = false,
  isDeferred = false,
  onSkip,
  onSubmit = async () => {},
  setDirtyState,
  validationSchema,
  validateOnChange = true,
  disableFormButtons = false,
  onCancel,
  showCancel = false,
  submitText = '',
  dataCy = 'step-form',
}: StepFormProps<Values>) => {
  return (
    <Formik<Values>
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange={validateOnChange}
    >
      {(formikProps) => (
        <Form>
          {isActive && <TrackDirtyState setDirtyState={setDirtyState} />}
          {React.Children.map<React.ReactElement, React.ReactElement>(children, (Child: React.ReactElement) => {
            return React.cloneElement(Child, { formikProps });
          })}
          <Box className="multi-step-form__btn-wrapper">
            {((hideUntilTouched && !isEmpty(formikProps.touched)) || !hideUntilTouched) && (
              <FormButton
                className="multi-step-form__continue-btn"
                disabled={disableUntilTouched ? isEmpty(formikProps.touched) : false || disableFormButtons}
                loading={formikProps.isSubmitting}
                dataCy={`${dataCy}__continue-btn`}
              >
                {submitText || GENERIC_COPY.BUTTON_CONTINUE}
              </FormButton>
            )}
            {(isOptional || isDeferred) && (
              <FormButton
                className="multi-step-form__optional-btn"
                onClick={onSkip}
                variant={BUTTON_VARIANTS.TEXT}
                type="button"
                disabled={disableFormButtons}
                dataCy={`${dataCy}__skip-btn`}
              >
                {isOptional ? 'Skip' : 'Set Up Later'}
              </FormButton>
            )}
            {showCancel && (
              <FormButton
                type="button"
                className="multi-step-form__cancel-btn"
                dataCy={`${dataCy}__cancel-btn`}
                onClick={onCancel}
                variant={BUTTON_VARIANTS.TEXT}
              >
                {GENERIC_COPY.BUTTON_CANCEL}
              </FormButton>
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default StepForm;

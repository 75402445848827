import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '.single-revision': {
    '&__header': {
      fontWeight: 'lighter',
    },
    '&__sub-header': {
      fontWeight: 400,
      fontSize: '11px',
    },
    '&__status': {
      borderRadius: '2px',
      backgroundColor: COLORS.RED200,
      color: COLORS.BLACK,
      padding: `0 ${theme.space[2]}`,
    },
    '&__status--done': {
      backgroundColor: COLORS.GREEN300,
    },
  },
});

import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import cs from 'classnames';

import BriefFields from 'features/briefs/components/BriefFields/BriefFields';
import BriefViewingModal from 'features/submissions/components/molecules/BriefViewingModal';
import SubmissionForm from 'features/submissions/components/SubmissionForm/SubmissionForm';
import { SubmitValues } from 'features/submissions/components/SubmissionForm/types';
import { useSubmissions } from 'features/submissions/useSubmissions';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import { Box, Flex, Text } from 'shared/components/display';
import SplitPanelScroll from 'shared/components/layout/SplitPanelScroll/SplitPanelScroll';

import { RESOURCES } from 'shared/config/resourceNames';
import { GENERAL_ROUTES } from 'shared/config/routes/generalRoutes';
import { SUBMISSION_TOASTS } from 'shared/config/toasts/submissionToasts';
import { useDeepEffect } from 'shared/hooks/useDeepEffect';
import { useFileNotification } from 'shared/hooks/useFileNotification';
import { intl } from 'shared/lib/intl';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { SubmissionStatus } from 'shared/typings/submissions';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';

const CreateUpdateSubmission = () => {
  const { submissionId } = useParams<{ submissionId: string }>();
  const {
    editSubmission,
    fetchSingleSubmission,
    loading,
    loadingCreateSubmission,
    loadingUpdateDraftSubmission,
    loadingUpdatePublishSubmission,
    publishSubmission,
    submission,
  } = useSubmissions({ submissionId: +submissionId });
  useFileNotification();

  useDeepEffect(() => {
    fetchSingleSubmission({ submissionId, isRefetch: Boolean(submission) });
  }, [submissionId, submission]);

  const pathToViewSubmission = LinkCreator.createLink({
    userType: UserType.CREATOR,
    routeKey: 'CREATOR_SUBMISSIONS',
    query: {
      item: submissionId,
    },
  });

  const onSubmit = (submissionData: SubmitValues) => {
    const isSubmitted = submission?.status === SubmissionStatus.SUBMITTED;
    if (isSubmitted) {
      editSubmission({
        submissionData: {
          ...submissionData,
          id: submissionId,
        },
        successToast: {
          message: SUBMISSION_TOASTS.SUBMISSION_UPDATED,
        },
        pushFunction: () => pathToViewSubmission,
      });
    } else {
      publishSubmission({
        submissionData: {
          ...submissionData,
          id: submissionId,
        },
        pushFunction: () => pathToViewSubmission,
      });
    }
  };

  const onDraftSubmit = (submissionData) => {
    editSubmission({
      submissionData: {
        ...submissionData,
        id: submissionId,
      },
      successToast: {
        message: SUBMISSION_TOASTS.SUBMISSION_UPDATED,
      },
      pushFunction: () => pathToViewSubmission,
      isDraft: true,
    });
  };

  if ((loading || loadingCreateSubmission) && !submission) return <CenteredSpinnerContainer />;

  if (!submission) return <Navigate to={GENERAL_ROUTES.NO_ACCESS} state={{ type: `${RESOURCES.SUBMISSION} page` }} />;

  return (
    <Flex css={styles} className={cs('create-update-submission')}>
      <BriefViewingModal brief={submission.brief} />
      <SplitPanelScroll
        leftHeaderComponent={
          <Text variant={TEXT_VARIANTS.H3} className="endWithEllipsis">
            {intl.formatMessage(
              {
                id: 'HEADING_SUBMISSION_CREATION_BRIEF',
              },
              { name: submission.brief.name },
            )}
          </Text>
        }
        leftComponent={
          <Box className="create-update-submission__wrapper">
            <BriefFields stickyLabel brief={submission.brief} uppercase />
          </Box>
        }
        rightHeaderComponent={
          <>
            <Text variant={TEXT_VARIANTS.H3} className="create-update-submission__heading-submission">
              {intl.formatMessage({
                id: 'HEADING_SUBMISSION_CREATION',
              })}
            </Text>
          </>
        }
        rightComponent={
          <Box className="create-update-submission__wrapper">
            <SubmissionForm
              brief={submission.brief}
              onSubmit={onSubmit}
              onDraftSubmit={onDraftSubmit}
              submission={submission}
              loadingUpdatePublishSubmission={loadingUpdatePublishSubmission}
              loadingUpdateDraftSubmission={loadingUpdateDraftSubmission}
            />
          </Box>
        }
      />
    </Flex>
  );
};

export default CreateUpdateSubmission;

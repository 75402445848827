import React, { useEffect } from 'react';
import { Text, Box } from 'shared/components/display';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { noOp } from 'shared/defaults';
import { AUTH_COPY } from 'shared/config/copy';
import redirectOnErrorStyles from 'features/auth/components/atoms/RedirectOnError/redirectOnErrorStyles';
import cs from 'classnames';

const { CATEGORY } = TEXT_VARIANTS;

const RedirectOnError = ({ errors = [], onError = noOp, actionCallback = noOp }) => {
  useEffect(() => {
    if (errors && errors.length) {
      onError();
    }
  }, [errors]);

  useEffect(() => {
    actionCallback();
  }, []);

  return (
    <Box css={redirectOnErrorStyles} className={cs(CLASSNAMES.PUBLIC_CONTAINER, 'redirect-on-error')}>
      <CenteredSpinner />
      <Text variant={CATEGORY} className="redirect-on-error__text">
        {AUTH_COPY.TEXT_REDIRECT}
      </Text>
    </Box>
  );
};

export default RedirectOnError;

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.submission-checkout': {
    '&__subheader': {
      marginTop: theme.space[6],
      marginBottom: theme.space[6],
    },
    '&__module-subheader': {
      marginRight: theme.space[2],
    },
    '&__image': {
      marginRight: theme.space[3],
      width: '10%',
    },
  },
});

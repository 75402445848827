import { css } from '@emotion/react';

export default (backgroundImage: string, width: string | number, height: string | number) =>
  css({
    '&.scaled-image': {
      backgroundColor: 'transparent',
      backgroundImage: `url("${backgroundImage}")`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      width,
      height,
      maxWidth: '100%',
      maxHeight: '100%',
    },
  });

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.vetting-approval-buttons': {
    '&__btn': {
      backgroundColor: COLORS.WHITE,
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
    },
  },
});

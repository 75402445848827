import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.file-information-modal': {
    padding: theme.space[4],
  },
  '.file-information-modal': {
    '&__info-btn--icon svg': {
      color: COLORS.WHITE,
    },
    '&__info-btn--icon': {
      padding: 0,
    },
  },
});

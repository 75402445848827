import { API } from 'shared/typings/api';

export function apiAction({
  path,
  method,
  data,
  params,
  headers,
  successAction,
  errorAction,
  pushFunction,
  navigate,
  config,
  entity,
  successToast,
  errorToast,
  hideError = false,
  showSuccessToast,
  onSuccess,
  onError,
}: API) {
  return {
    type: 'API_REQUEST',
    data,
    meta: {
      path,
      method,
      params,
      headers,
      successAction,
      errorAction,
      pushFunction,
      navigate,
      config,
      entity,
      successToast,
      errorToast,
      hideError,
      showSuccessToast,
      onSuccess,
      onError,
    },
  };
}

import { brandAssetAdded, brandAssetUpdated } from 'features/accounts/brandAssets.slice';
import { useFiles } from 'features/media/useFiles';
import { componentLoadingToggled } from 'features/ui/ui.slice';
import { RESOURCES } from 'shared/config/resourceNames';
import { SignalRContext } from 'shared/contexts/signalr';
import { useToasts } from 'shared/hooks/useToasts';
import { FileUploadStatus } from 'shared/typings/file/enums';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { accountChanged } from '../accounts.slice';
import { BrkfstFile } from 'shared/typings/file';

type Payload = BrkfstFile & {
  accountId: number;
  key: string;
  isBrandAsset: boolean;
  isBannerLogo: boolean;
};

export const useBrandFilesNotification = (): void => {
  const { connection } = useContext(SignalRContext);
  const dispatch = useDispatch();
  const { setSuccessToast } = useToasts();
  const { updateFileUpload } = useFiles();

  const handleBannerLogo = (accountId: number, file: BrkfstFile) => {
    dispatch(
      accountChanged({
        data: {
          banner: file,
          id: accountId,
          bannerId: file.id,
        },
      }),
    );
    dispatch(
      componentLoadingToggled({
        component: RESOURCES.ACCOUNT_BANNER,
        loading: false,
      }),
    );
    setSuccessToast({
      message: 'Banner updated',
    });
  };

  const handleBrandAsset = (accountId: number, key: string, file: BrkfstFile) => {
    dispatch(
      brandAssetAdded({
        data: file,
      }),
    );

    dispatch(
      componentLoadingToggled({
        component: RESOURCES.BRAND_ASSET,
        loading: false,
      }),
    );
    updateFileUpload(key, FileUploadStatus.UPLOADED);

    setSuccessToast({
      message: 'Brand asset uploaded',
    });
  };

  const handleAccountLogo = (accountId: number, file: BrkfstFile) => {
    dispatch(
      accountChanged({
        data: {
          logo: file,
          id: accountId,
          logoId: file.id,
        },
      }),
    );
    dispatch(
      componentLoadingToggled({
        component: RESOURCES.ACCOUNT_LOGO,
        loading: false,
      }),
    );
    setSuccessToast({
      message: 'Logo updated',
    });
  };

  useEffect(() => {
    const notificationHandler = ({ key, isBrandAsset, isBannerLogo, ...file }: Payload) => {
      const { accountId } = file;
      if (isBannerLogo) {
        handleBannerLogo(accountId, file);
      } else if (isBrandAsset && accountId) {
        handleBrandAsset(accountId, key, file);
      } else if (accountId) {
        handleAccountLogo(accountId, file);
      }
    };

    const thumbnailHandler = ({ fileId, thumbnailUrl, thumbnailOffset }) => {
      dispatch(brandAssetUpdated({ fileId, thumbnailUrl, thumbnailOffset }));
    };

    if (connection) {
      connection.on('newFile', notificationHandler);
      connection.on('newThumbnail', thumbnailHandler);
    }

    return function cleanup() {
      connection?.off('newFile', notificationHandler);
      connection?.off('newThumbnail', thumbnailHandler);
    };
  }, [connection]);
};

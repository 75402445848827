import React from 'react';
import { useNavigate } from 'react-router-dom';
import cs from 'classnames';

import AccountDetailsForm from 'features/accounts/components/molecules/AccountDetailsForm/AccountDetailsForm';
import UpdateAccountLogo from 'features/accounts/components/molecules/UpdateAccountLogo';
import UpdateBannerLogo from 'features/accounts/components/organisms/UpdateBannerLogo/UpdateBannerLogo';
import UpdateBrandAssets from 'features/accounts/components/UpdateBrandAssets/UpdateBrandAssets';
import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { Value } from 'features/users/components/organisms/TagsInput';
import { TAG_CATEGORIES } from 'features/users/components/organisms/TagsInput/types';
import ExternalLink from 'shared/components/atoms/ExternalLink/ExternalLink';
import { Box, Text } from 'shared/components/display';
import CategoryTags from 'shared/components/molecules/CategoryTags';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import PromptButton from 'shared/components/molecules/PromptButton';
import { ToggleEditInformation } from 'shared/components/molecules/ToggleEditInformation';

import { ACCOUNT_COPY } from 'shared/config/copy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { ACCOUNT_TOASTS } from 'shared/config/toasts';
import { usePermissions } from 'shared/hooks/usePermissions';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';

interface Props {
  accountId: string;
  organizationId: number;
}
const BasicAccountSettings: React.FC<Props> = ({ accountId, organizationId }) => {
  const { account, deleteAccount, editAccount, loadingAccountSettings } = useAccounts({
    accountId,
  });
  const { displayComponent, isAuthorized } = usePermissions();
  const authorizedToEdit: boolean = isAuthorized(organizationId, PERMISSIONS_MAP.EDIT_ACCOUNT.API);
  const navigate = useNavigate();
  const formattedTags: Value[] = Object.values(
    (account.tags || []).reduce((acc, tag) => {
      const category = tag.category;
      if (!acc[category]) {
        acc[category] = {
          values: [tag.value],
          category,
        };
      } else {
        acc[category].values.push(tag.value);
      }

      return acc;
    }, []),
  );

  const accountDetailsFormProps = {
    onSubmit: (accountData) => {
      editAccount({
        data: {
          ...account,
          ...accountData,
          name: accountData.name.trim(),
          tags: accountData.tags.reduce((acc, { category, values }) => {
            values.forEach((tag) => {
              acc.push({ value: tag, category });
            });
            return acc;
          }, []),
        },
        successToast: {
          message: ACCOUNT_TOASTS.ACCOUNT_UPDATED,
        },
      });
    },
    initialValues: {
      name: account.name,
      domain: account.domain,
      slogan: account?.slogan || '',
      tags: formattedTags.length > 0 ? formattedTags : [{ category: TAG_CATEGORIES.INDUSTRY, values: [] }],
    },
    formDisabled: !authorizedToEdit,
  };

  const confirmDeleteAccount = () => {
    deleteAccount({
      id: accountId,
      organizationId,
      pushFunction: () =>
        navigate({
          pathname: LinkCreator.createLink({
            routeKey: 'ORGANIZATION',
            variables: {
              organizationId,
            },
          }),
        }),
    });
  };

  const categoryTagValues = formattedTags[0]?.values.map((value) => ({ value })) || [];

  return (
    <Box css={styles} className={cs('basic-account-settings', CLASSNAMES.CENTERED_CONTAINER)}>
      <Text variant={TEXT_VARIANTS.H4} as="h4">
        {ACCOUNT_COPY.HEADING_ACCOUNT_SETTINGS}
      </Text>
      <UpdateAccountLogo accountId={accountId} authorizedToEdit={authorizedToEdit} />
      <UpdateBannerLogo accountId={accountId} authorizedToEdit={authorizedToEdit} />
      <ToggleEditInformation
        loading={loadingAccountSettings}
        editButtonText={ACCOUNT_COPY.BUTTON_EDIT_ACCOUNT}
        heading={ACCOUNT_COPY.HEADING_ACCOUNT_DETAILS}
        variant={TEXT_VARIANTS.H6}
        EditingComponent={AccountDetailsForm}
        EditingComponentProps={accountDetailsFormProps}
      >
        <DefinitionList>
          <ListDetail text={ACCOUNT_COPY.LABEL_NAME}>{account.name}</ListDetail>
          <ListDetail text={ACCOUNT_COPY.LABEL_DOMAIN}>
            <ExternalLink href={account.domain} />
          </ListDetail>
          <ListDetail text={ACCOUNT_COPY.LABEL_SLOGAN}>{account?.slogan || ''}</ListDetail>
        </DefinitionList>
        <CategoryTags
          category="industry"
          theme="definition-list-category"
          className="confirmation__tags"
          values={categoryTagValues}
        />
      </ToggleEditInformation>
      <UpdateBrandAssets authorizedToEdit={authorizedToEdit} accountId={accountId} />
      {displayComponent(
        <PromptButton
          onConfirm={confirmDeleteAccount}
          text={ACCOUNT_COPY.MODAL_DELETE_ACCOUNT}
          confirmButtonText={ACCOUNT_COPY.BUTTON_DELETE_ACCOUNT}
          ariaLabel={ACCOUNT_COPY.BUTTON_DELETE_ACCOUNT}
          className="basic-account-settings__delete-btn"
          variant={BUTTON_VARIANTS.TEXT_ONLY}
        >
          {ACCOUNT_COPY.BUTTON_DELETE_ACCOUNT}
        </PromptButton>,
        organizationId,
        PERMISSIONS_MAP.DELETE_ACCOUNT.API,
      )}
    </Box>
  );
};

export default BasicAccountSettings;

import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { NewAccountValues } from 'features/accounts/components/molecules/NewAccountForm/config';
import NewAccountForm from 'features/accounts/components/molecules/NewAccountForm/NewAccountForm';
import { Box } from 'shared/components/display';

import { CLASSNAMES } from 'shared/styles/containers';

const NewAccount = () => {
  const params = useParams<{ organizationId: string }>();
  const location = useLocation<any>();
  const organizationId = +params.organizationId;

  return (
    <Box className={CLASSNAMES.MAIN_CONTAINER}>
      <NewAccountForm
        organizationId={organizationId}
        // when user clicks back from checkout
        defaultValues={location.state as NewAccountValues}
      />
    </Box>
  );
};

export default NewAccount;

import React, { useReducer, useMemo, createContext } from 'react';
import { DEFAULT_PARAMS, DEFAULT_COLUMNS_CHOSEN } from 'shared/config/performance';

import { ACTIONS } from 'shared/config/actions';
import { PerformanceState } from 'shared/contexts/performance/types';
import { BreakdownFields, Breakdowns, PerformanceLevels } from 'features/performance/enums';
import { initialValues as topBottomInitialValues } from 'features/performance/TopBottomFormDropdown/config';

const { PERFORMANCE: PERF_ACTION } = ACTIONS;

const initialState: PerformanceState = {
  params: { ...DEFAULT_PARAMS },
  committedParams: { ...DEFAULT_PARAMS },
  attributionWindow: [],
  level: PerformanceLevels.CAMPAIGN,
  columnsChosen: DEFAULT_COLUMNS_CHOSEN,
  filters: [],
  breakdown: BreakdownFields.NONE,
  breakdownValue: Breakdowns.NONE,
  dateRange: {
    from: new Date(),
    to: new Date(),
  },
  dateRanges: null,
  datePreset: 'Today',
  page: 0, // Needs to be removed when transitioning to redux.
  pageSize: 10,
  topBottom: topBottomInitialValues,
};

const reducer = (state: PerformanceState, action): PerformanceState => {
  switch (action.type) {
    case PERF_ACTION.SET_FIELD:
      return {
        ...state,
        [action.field]: action.value,
      };
    case PERF_ACTION.SET_FIELDS:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
};

export const PerformanceContext = createContext<{ state: PerformanceState; dispatch: any }>({
  state: initialState,
  dispatch: null,
});

const PerformanceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return <PerformanceContext.Provider value={contextValue}>{children}</PerformanceContext.Provider>;
};

export default PerformanceProvider;

import { snakeCase, toUpper } from 'lodash';

/**
 * Given an object, create a new object with the following as its key:value pair
 * uppercase(snakecase(key)): key
 * If there is an underscore followed by a number in the key key,
 * the underscore is removed
 */
export const convertToConstProperty = (key) => toUpper(snakeCase(key)).replace(/_(\d)/g, '$1');

export const generateConstKeyObj = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const upperSnakeKey = convertToConstProperty(key);
    acc[upperSnakeKey] = key;
    return acc;
  }, {});
};

import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export default css({
  '&.creator-preview': {
    alignItems: 'center',
    textAlign: 'left',
    '&--fixed-height': {
      height: '32px',
    },
  },
  '.creator-preview': {
    '&__name': {
      margin: `0 0 0 ${theme.space[3]}`,
      lineHeight: 1.1,
      whiteSpace: 'nowrap',
      wordBreak: 'break-word',
      overflowY: 'visible',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

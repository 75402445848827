import React from 'react';

import Chip from 'shared/components/atoms/Chip/Chip';

import theme from 'shared/styles/themes';
import { BriefStatus as BriefStatusEnum, briefStatusDisplay } from 'shared/typings/briefs/enums';
import { briefCreatorStatusDisplay, FULL_BRIEF, INVITE_ONLY, UserBriefStatus } from 'shared/typings/userBriefs/enums';

const {
  colors: {
    ORANGE100,
    ORANGE700,
    GREEN100,
    GREEN700,
    RED50,
    RED200,
    RED700,
    PRIMARY100,
    PRIMARY400,
    NEUTRAL100,
    NEUTRAL800,
    TRANSPARENT,
  },
} = theme;

interface Props {
  status?: BriefStatusEnum;
  briefCreatorStatus?: UserBriefStatus;
  full?: boolean;
  inviteOnly: boolean;
}

const BriefStatus: React.FC<Props> = ({ status, briefCreatorStatus, full, inviteOnly }) => {
  switch (status) {
    case BriefStatusEnum.DRAFT:
      return <Chip label={briefStatusDisplay[status]} color={PRIMARY400} bgColor={PRIMARY100} square width="65px" />;
    case BriefStatusEnum.CLOSED:
      return <Chip label={briefStatusDisplay[status]} bgColor={TRANSPARENT} square width="65px" />;
    case BriefStatusEnum.OPEN:
      if (full) {
        return (
          <Chip label={briefStatusDisplay[BriefStatusEnum.FULL]} color={RED200} bgColor={RED50} square width="65px" />
        );
      } else {
        return <Chip label={briefStatusDisplay[status]} color={GREEN700} bgColor={GREEN100} square width="65px" />;
      }
    case BriefStatusEnum.PENDING:
      return <Chip label={briefStatusDisplay[status]} square width="65px" />;
    default:
      break;
  }
  switch (briefCreatorStatus) {
    case UserBriefStatus.PENDING:
      return (
        <Chip
          label={briefCreatorStatusDisplay[briefCreatorStatus]}
          color={ORANGE700}
          bgColor={ORANGE100}
          square
          width="78px"
        />
      );
    case UserBriefStatus.NOT_APPLIED:
      if (full) {
        return (
          <Chip label={briefCreatorStatusDisplay[FULL_BRIEF]} color={RED700} bgColor={RED50} square width="78px" />
        );
      }
      if (inviteOnly) {
        return (
          <Chip label={briefCreatorStatusDisplay[INVITE_ONLY]} color={RED700} bgColor={RED50} square width="78px" />
        );
      }
      return <Chip label={briefCreatorStatusDisplay[briefCreatorStatus]} bgColor={TRANSPARENT} square width="78px" />;

    case UserBriefStatus.ACTIVE:
      return (
        <Chip
          label={briefCreatorStatusDisplay[briefCreatorStatus]}
          color={PRIMARY400}
          bgColor={PRIMARY100}
          square
          width="78px"
        />
      );
    case UserBriefStatus.REJECTED:
      return (
        <Chip
          label={briefCreatorStatusDisplay[briefCreatorStatus]}
          color={RED700}
          bgColor={RED50}
          square
          width="78px"
        />
      );
    case UserBriefStatus.SUBMITTED:
      return (
        <Chip
          label={briefCreatorStatusDisplay[briefCreatorStatus]}
          color={GREEN700}
          bgColor={GREEN100}
          square
          width="78px"
        />
      );
    case UserBriefStatus.DRAFTED:
      return (
        <Chip
          label={briefCreatorStatusDisplay[briefCreatorStatus]}
          color={NEUTRAL800}
          bgColor={NEUTRAL100}
          square
          width="78px"
        />
      );
    case UserBriefStatus.INVITED:
      if (full) {
        return (
          <Chip label={briefCreatorStatusDisplay[FULL_BRIEF]} color={RED700} bgColor={RED50} square width="78px" />
        );
      }
      return (
        <Chip
          label={briefCreatorStatusDisplay[briefCreatorStatus]}
          color={NEUTRAL800}
          bgColor={NEUTRAL100}
          square
          width="78px"
        />
      );
    case UserBriefStatus.REVISION:
      return (
        <Chip
          label={briefCreatorStatusDisplay[briefCreatorStatus]}
          color={RED700}
          bgColor={RED50}
          square
          width="78px"
        />
      );
    default:
      return null;
  }
};

export default BriefStatus;

import React from 'react';
import { components } from 'react-select';

/**
 * Needed for correct placeholder & label styling to work
 * otherwise placeholder component styles do not receive
 * updated values in the state
 */
export const SelectValueContainer = ({ children, ...valueProps }) => {
  const {
    selectProps: { placeholder, hidePlaceholder, dataCy },
  } = valueProps;

  return (
    <>
      {/* @ts-ignore */}
      <components.ValueContainer
        {...valueProps}
        innerProps={{
          ...valueProps.innerProps,
          'data-testid': 'select__value-container',
          'data-cy': dataCy,
        }}
      >
        {React.Children.map(children, (Child) => {
          if (Child === null) {
            return;
          }
          if (Child.key === 'placeholder')
            return React.cloneElement(Child, {
              innerProps: {
                ...Child.innerProps,
                style: {
                  display: 'none',
                },
              },
            });
          return Child;
        })}
      </components.ValueContainer>
      {/* @ts-ignore */}
      <components.Placeholder {...valueProps}>{!hidePlaceholder && placeholder}</components.Placeholder>
    </>
  );
};

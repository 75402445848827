import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export default css({
  '&.performance-dashboard': {
    margin: theme.space[5],
  },
  '.performance-dashboard': {
    '&__row > :not(:first-of-type)': {
      marginLeft: theme.space[4],
    },
    '&__first-row': {
      marginTop: theme.space[5],
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    '&__second-row, &__second-row-duplicate': {
      marginTop: theme.space[3],
    },
    '&__second-row-duplicate > :nth-of-type(4)': {
      marginLeft: 'auto',
    },
    '&__second-row > :nth-of-type(3)': {
      marginLeft: 'auto',
    },
    '&__third-row': {
      margin: `${theme.space[4]} 0`,
    },
  },
});

import { VIDEO_COMPOSITION_COPY } from 'features/media/pages/VideoComposition/copy';

import { BrkfstFile } from 'shared/typings/file';
import { UserType } from 'shared/typings/user/enums';
import { hasValidAspectRatio } from 'shared/utilities/validator/utils';

export const validateSelectedFiles = (files: BrkfstFile[]) => {
  const errors: string[] = [];
  if (files.some((file) => !file.mimeType.includes('video'))) {
    errors.push(VIDEO_COMPOSITION_COPY.SELECTION_ERROR_NON_VIDEO_FILE);
  }

  if (
    files.some(
      (file) =>
        !hasValidAspectRatio({
          width: file.metadata.width,
          height: file.metadata.height,
          rotation: file.metadata.rotation,
        }),
    )
  ) {
    errors.push(VIDEO_COMPOSITION_COPY.SELECTION_ERROR_UNSUPPORTED_ASPECT_RATIO);
  }

  const creatorAuthorIds = Array.from(
    new Set(files.filter((file) => file.creator?.type === UserType.CREATOR).map((file) => file.creatorId)),
  );

  if (creatorAuthorIds.length > 1) {
    errors.push(VIDEO_COMPOSITION_COPY.SELECTION_ERROR_MULTIPLE_CREATORS);
  }
  return errors;
};

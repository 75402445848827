import { emptySelectOption } from 'shared/components/molecules/Select/SelectInput';

export const addressInitialValues = {
  email: '',
  addressLine1: '',
  addressLine2: '',
  companyName: '',
  city: '',
  state: emptySelectOption,
  postalCode: '',
  country: emptySelectOption,
  phoneNumber: '1',
  countryCode: 'US',
  countryDialCode: '1',
};

export const ADDRESS_FIELDNAMES = {
  EMAIL: 'email',
  ADDRESS_LINE_1: 'addressLine1',
  ADDRESS_LINE_2: 'addressLine2',
  COMPANY_NAME: 'companyName',
  CITY: 'city',
  STATE: 'state',
  POSTAL_CODE: 'postalCode',
  COUNTRY: 'country',
  PHONE_NUMBER: 'phoneNumber',
  COUNTRY_CODE: 'countryCode',
  COUNTRY_DIAL_CODE: 'countryDialCode',
};

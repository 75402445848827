import React, { useMemo } from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import styles from './styles';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import COLORS from 'shared/styles/colors';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  onClick: () => void;
  icon: FontAwesomeIconProps['icon'];
  count: number;
  size?: SizeProp;
  maxCount?: number;
  className?: string;
  dataCy?: string;
}
const IconWithNotification: React.FC<Props> = ({ onClick, icon, count, size, maxCount = 99, className, dataCy }) => {
  const displayCount = useMemo(() => {
    if (count >= maxCount) {
      return `${maxCount}+`;
    }
    return count;
  }, [count, maxCount]);

  return (
    <Box css={styles} className={cs('icon-with-notification', className)} onClick={onClick} data-cy={dataCy}>
      <FontAwesomeIcon className="icon-with-notification__icon" icon={icon} color={COLORS.WHITE} size={size} />
      <Box className="icon-with-notification__count">{displayCount}</Box>
    </Box>
  );
};

export default IconWithNotification;

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.divider': {
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '.divider': {
    '&__heading': {
      marginBottom: 0,
    },
    '&__btn': {
      marginBottom: theme.space[3],
    },
  },
});

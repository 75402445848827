import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Channel } from 'stream-chat';

import { currentUserChanged } from 'features/auth/auth.slice';
import { briefRefreshed } from 'features/briefs/briefs.slice';
import { channelOpened, getActiveChannel, setNotificationCount } from 'features/ui/ui.slice';

import { MESSAGE_TYPE, NOTIFICATIONS } from 'shared/config/notifications';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import Messaging from 'shared/messaging/Messaging';
import { StreamChatChannel, StreamChatTypes } from 'shared/messaging/types';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

enum MessageAction {
  REFRESH_BRIEF = 'REFRESH_BRIEF',
}

export const useMessaging = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeChannel: StreamChatChannel = useSelector(getActiveChannel);
  const activeChannelRef = useRef(activeChannel);

  const { currentUser, lastVisitedOrg, isMarketer } = useCurrentUser();
  const client = Messaging.getClient();

  const getActiveClientChannel = (channelId: string): StreamChatChannel =>
    client.activeChannels[`messaging:${channelId}`];

  const getUnreadChannels = (): StreamChatChannel[] =>
    Object.keys(client.activeChannels)
      .filter((cid) => client.activeChannels[cid].state.unreadCount > 0)
      .map((cid): StreamChatChannel => client.activeChannels[cid]);

  const getUnreadChannelCounts = (): Record<string, number> =>
    // @ts-ignore
    getUnreadChannels().reduce((unreadCounts, { state: { unreadCount, messages }, data: { organizationId } }) => {
      // should not increment unread count for marketer if the last message was a brief auto response
      let latestMessage = messages[messages.length - 1];
      if (latestMessage.isSystemMessage && messages.length >= 2) latestMessage = messages[messages.length - 2];
      const isSystemMessage = latestMessage.isSystemMessage;
      const shouldNotify = currentUser.type !== UserType.ORG || !isSystemMessage;
      const vettedCreatorId = latestMessage.vettedCreatorId;
      const shouldIncrementCount = vettedCreatorId === currentUser.id || !vettedCreatorId;
      if (unreadCount > 0 && shouldIncrementCount && shouldNotify) {
        const count: number = unreadCounts[organizationId] ? unreadCounts[organizationId] : 0;
        unreadCounts[organizationId] = count + 1;
      }
      return unreadCounts;
    }, {} as Record<string, number>);

  const getChannel = async (channelId: string) => {
    const channel = getActiveClientChannel(channelId);
    if (channel) return channel;
    return Messaging.findAccountChannel(channelId);
  };

  const setUnreadChannels = (channel?: StreamChatChannel | null) => {
    const count = getUnreadChannelCounts();
    if (channel?.id) {
      const activeClientChannel = getActiveClientChannel(channel.id);
      const hasActiveUnread = activeClientChannel?.state.unreadCount > 0;
      // @ts-ignore
      const orgId = channel.organizationId || channel.data?.organizationId;
      if (orgId && count[orgId]) {
        const newOrgCount = count[orgId] - 1;
        if (newOrgCount <= 0) delete count[orgId];
        else count[orgId] = newOrgCount;
      } else if (orgId && (hasActiveUnread || !activeClientChannel)) {
        // Has unread active channels
        //  OR if no active client channel is present,
        //     then a new message was sent to the channel.
        count[orgId] = 1;
      }
    }
    dispatch(
      setNotificationCount({
        entity: NOTIFICATIONS.UNREAD_MESSAGES,
        count,
      }),
    );
  };

  useEffect(() => {
    activeChannelRef.current = activeChannel;
  }, [activeChannel]);

  const getMessagePath = (channel: StreamChatChannel, userType = currentUser.type) => {
    const messageCenterPath = LinkCreator.createLink({
      routeKey: 'MESSAGES',
      userType,
      variables: {
        organizationId: channel?.data?.organizationId,
        channelId: channel.id,
      },
      query: {
        c: channel.id,
      },
    });
    return messageCenterPath;
  };

  const openAndRedirectToChannel = async (user, account) => {
    const channelId = Messaging.getChannelId(user, account);
    const channel = await Messaging.findAccountChannel(channelId);

    if (channel) {
      const messageCenterPath = getMessagePath(channel);
      dispatch(channelOpened({ data: channel }));
      navigate(messageCenterPath);
    }
  };

  const onNewMessage = async (event) => {
    let shouldSetToast = true;
    /**
     * when there is an active channel (user is viewing chat),
     * the channel is immediately marked as read.
     * to prevent the notification badge from flashing,
     * disable the update if there is a new message from the active channel
     */
    if (
      // client is watching channel (once message page has been visited)
      event.type === MESSAGE_TYPE.NEW ||
      // client is not watching channel (when you log in and haven't visited message center yet)
      event.type === MESSAGE_TYPE.NOTIFICATION_NEW
    ) {
      // data is pulled from different places depending on event type
      const channelId = event.channel_id;
      const channel = event.channel ? event.channel : await getChannel(channelId);

      const eventUser = event.user ? event.user : event.message.user;
      // creators won't have an org and should just see all notifications
      const belongsToCurrentOrg = lastVisitedOrg ? channel?.data?.organizationId === lastVisitedOrg : true;
      // creator messages sent to marketer during off hours will hit this function twice
      // 1. For the Creators custom message
      // 2. For the System Auto Response
      // The boolean below prevents the notification number from being toggled incorrectly.
      const showSystemMessage = !isMarketer || !event.message.isSystemMessage;
      shouldSetToast = belongsToCurrentOrg && showSystemMessage;

      // update if the message is not from the current user
      if (+eventUser.id === currentUser.id) {
        shouldSetToast = false;
      }
      if (activeChannelRef.current) {
        const isNotCurrentChannel = channelId !== activeChannelRef.current.id;
        shouldSetToast = isNotCurrentChannel && belongsToCurrentOrg;
      }

      if (event.message.vettedCreatorId === currentUser.id) {
        dispatch(currentUserChanged({ vettingStatus: event.message.vettingStatus }));
        dispatch(briefRefreshed({}));
      }

      if (event.message.creatorId === currentUser.id && event.message.action === MessageAction.REFRESH_BRIEF) {
        dispatch(briefRefreshed({}));
      }
    }
    if (shouldSetToast && event.unread_channels !== undefined) {
      const currentChannel = event.type === MESSAGE_TYPE.NOTIFICATION_NEW ? event.channel : null;
      setUnreadChannels(currentChannel);
    }
  };

  const listenToChanges = () => {
    client.on(onNewMessage);
  };

  const removeListeners = () => {
    client.off(onNewMessage);
  };

  const setActiveChannel = (channel: StreamChatChannel | null) => {
    dispatch(channelOpened({ data: channel }));
    setUnreadChannels(channel);
  };

  return {
    openAndRedirectToChannel,
    setActiveChannel,
    activeChannel,
    getUnreadChannels,
    setUnreadChannels,
    listenToChanges,
    removeListeners,
  };
};

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { FACEBOOK_COLORS } from 'shared/styles/colors';

interface Props {
  className?: string;
}
const FacebookIcon: React.FC<Props> = ({ className }) => {
  return <FontAwesomeIcon icon={faFacebookSquare} color={FACEBOOK_COLORS.BLUE} className={className} />;
};

export default FacebookIcon;

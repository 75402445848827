import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.creator-tags': {
    '&--left-category': {
      flexDirection: 'column',
    },
    '&--top-category': {
      flexWrap: 'wrap',
    },
  },
  '.creator-tags': {
    '&__category-group': {
      marginBottom: theme.space[3],
      marginRight: theme.space[3],
    },
  },
});

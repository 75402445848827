import React from 'react';
import cs from 'classnames';

import { Box, Flex, Text } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import radioGroupStyles from 'shared/components/molecules/RadioGroupInput/radioGroupStyles';

import { Input } from 'shared/lib/formik';

interface Props {
  options: Option[];
  className?: string;
  showLabel?: boolean;
  label?: string;
  display?: 'horizontal' | 'vertical';
  disabled?: boolean;
}

interface Option {
  value: string;
  label: string;
}

const RadioGroupInput: Input<string, Props> = ({
  className,
  field,
  options,
  showLabel,
  label,
  display = 'vertical',
  disabled = false,
}) => {
  return (
    <Box css={radioGroupStyles} className={cs(className, 'radio-group')}>
      {showLabel && (
        <Text as="label" htmlFor={field.name} className={`radio-group__input-label--${display}`}>
          {label}
        </Text>
      )}
      <Flex className={`radio-group__wrapper--${display}`}>
        {options.map(({ value, label }) => (
          <Flex className="radio-group__input-wrapper" key={label}>
            <input
              type="radio"
              name={field.name}
              id={`${field.name}-${value}`}
              value={value}
              checked={value === field.value}
              className="radio-group__input"
              onChange={field.onChange}
              onBlur={field.onBlur}
              disabled={disabled}
            />
            <label key={value} htmlFor={`${field.name}-${value}`} className="radio-group__label">
              {label}
            </label>
          </Flex>
        ))}
      </Flex>
      <FieldErrorMessage dataCy="radio-group__error-message" name={field.name} className="radio-group__error-message" />
    </Box>
  );
};

export default RadioGroupInput;

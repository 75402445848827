import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.author-time-display': {
    fontWeight: 400,
    fontSize: '11px',
    width: 'max-content',
  },
  '.author-time-display': {
    '&__author-name::after, &__time-ago--has-sibling::after': {
      content: '"●"',
      color: COLORS.NEUTRAL500,
      fontSize: '12px',
      margin: `auto ${theme.space[2]}`,
    },
    '&__time-ago': {
      color: COLORS.NEUTRAL700,
    },
  },
});

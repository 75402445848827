import React, { useMemo, useState } from 'react';
import cs from 'classnames';
import { Box, Flex, Button } from 'shared/components/display';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import Modal from 'shared/components/molecules/Modal';
import { SIZING } from 'shared/config/formatting';
import styles, { BUTTON_WIDTH } from './styles';
import { Field, Form, Formik } from 'formik';
import FormButton from 'shared/components/FormButton';
import { validationSchema } from './config';
import TextInput from 'shared/components/TextInput';
import { faArrowUpRightFromSquare, faPlus } from '@fortawesome/pro-light-svg-icons';
import Skeleton from 'react-loading-skeleton';
import ExternalLink from 'shared/components/atoms/ExternalLink/ExternalLink';
import SplitButton from 'shared/components/molecules/SplitButton';
import { faPencil, faShippingFast, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CREATOR_TOASTS } from 'shared/config/toasts/creatorToasts';

interface Props {
  disabled?: boolean;
  shippingLink?: string;
  onSubmit: (shippingLink: string, onFinish: any, successToast: string) => void;
}
const ShippingLinkModal: React.FC<Props> = ({ disabled, shippingLink, onSubmit }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleOpen = () => setOpen((prev) => !prev);

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const handleSubmit = (values) => {
    const successToast = Boolean(shippingLink)
      ? CREATOR_TOASTS.SHIPPING_LINK_UPDATED
      : CREATOR_TOASTS.SHIPPING_LINK_ADDED;
    setLoading(true);
    toggleOpen();
    onSubmit(values.shippingLink, () => setLoading(false), successToast);
  };

  const onRemove = () => {
    setLoading(true);
    onSubmit('', () => setLoading(false), CREATOR_TOASTS.SHIPPING_LINK_DELETED);
  };

  const initialValues = useMemo(() => {
    return {
      shippingLink: shippingLink || '',
    };
  }, [shippingLink]);

  const goToLink = () => {
    window.open(shippingLink, '_blank');
  };

  if (loading) {
    return <Skeleton height="20px" width={BUTTON_WIDTH} />;
  }

  return (
    <Box css={styles} className={cs('shipping-link-modal')} onClick={stopPropagation}>
      {/* Adding a link */}
      {!shippingLink && (
        <Button variant={BUTTON_VARIANTS.MICRO} onClick={toggleOpen} className="shipping-link-modal__action-btn">
          <FontAwesomeIcon className="shipping-link-modal__icon" icon={faPlus} />
          Add Link
        </Button>
      )}
      {/* Can View and edit existing link */}
      {shippingLink && !disabled && (
        <SplitButton
          primaryText={
            <>
              <FontAwesomeIcon icon={faShippingFast} />
              Track
            </>
          }
          primaryOnClick={goToLink}
          width={BUTTON_WIDTH}
          variant="mini"
        >
          {/* Need to add css here since children of SplitButton are portaled and so styles in parent components won't apply here */}

          <Button css={styles} className="shipping-link-modal__btn" onClick={toggleOpen}>
            <FontAwesomeIcon className="shipping-link-modal__icon" icon={faPencil} />
            Edit
          </Button>
          <Button css={styles} className="shipping-link-modal__btn" onClick={onRemove}>
            <FontAwesomeIcon className="shipping-link-modal__icon" icon={faTrash} />
            Remove
          </Button>
        </SplitButton>
      )}
      {/* Can view link only */}
      {shippingLink && disabled && (
        <Button variant={BUTTON_VARIANTS.MICRO} onClick={goToLink} className="shipping-link-modal__action-btn">
          <FontAwesomeIcon className="shipping-link-modal__icon" icon={faShippingFast} />
          Track
        </Button>
      )}

      <Modal
        isOpen={open}
        title="Tracking Link"
        onRequestClose={toggleOpen}
        modalSize={SIZING.SMALL}
        component={
          <Box css={styles} className="shipping-link-modal__content" onClick={stopPropagation}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              validateOnChange
              validateOnBlur={false}
            >
              {(formikProps) => (
                <Form>
                  <Field name="shippingLink" placeholder="Tracking Link" component={TextInput} />
                  <Flex className="shipping-link-modal__buttons">
                    <ExternalLink
                      href={formikProps.values.shippingLink}
                      className={cs('shipping-link-modal__link', {
                        'shipping-link-modal__link--disabled':
                          Boolean(formikProps.errors.shippingLink) || !Boolean(formikProps.values.shippingLink),
                      })}
                      text={
                        <>
                          <FontAwesomeIcon className="shipping-link-modal__icon" icon={faArrowUpRightFromSquare} />
                          Visit link
                        </>
                      }
                    />
                    <FormButton>Submit</FormButton>
                  </Flex>
                </Form>
              )}
            </Formik>
          </Box>
        }
      />
    </Box>
  );
};

export default ShippingLinkModal;

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';

export default css({
  '&.elliptical-menu': {
    position: 'relative',
    height: 'min-content',
    zIndex: zIndex.zDrawer,
    '& svg': {
      transform: 'color 0.135s ease-in-out',
    },
    ':& > div': {
      padding: 0,
    },
    ':&:hover::before': {
      opacity: 1,
    },
    ':&::before': {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      content: "''",
      display: 'block',
      backgroundColor: `${COLORS.NEUTRAL700}14`,
      borderRadius: '50%',
      width: '100%',
      opacity: 0,
      transition: 'opacity 0.15s linear',
    },
  },
});

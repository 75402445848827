import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '.checkout-promo-code': {
    '&__add-btn': {
      padding: 0,
      color: COLORS.PRIMARY500,
      textDecoration: 'underline',
    },
    '&__submit-btn': {
      margin: '8px 0',
      '& button': {
        paddingRight: 0,
      },
    },
  },
  '&.checkout-promo-code': {
    '& form': {
      display: 'flex',
      alignItems: 'baseline',
    },
  },
});

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.trends-library': {
    width: '100%',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  '.trends-library': {
    '&__header': {
      textAlign: 'center',
      margin: `${theme.space[5]} 0`,
    },
    '&__control-bar': {
      justifyContent: 'space-between',
      paddingRight: theme.space[4],
      marginBottom: theme.space[4],
      alignItems: 'center',
      '&__launch-button': {
        marginRight: theme.space[3],
      },
      '&__info-icon': {
        alignSelf: 'center',
      },
    },
    '&__text--pink': {
      color: COLORS.SECONDARY300,
    },
  },
});

import { css } from '@emotion/react';

export default css({
  '.submission-module-array': {
    '&__label-module': {
      textTransform: 'uppercase',
      marginTop: '32px',
      marginBottom: '16px',
    },
  },
});

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

const block = 'dropbox';

export const classNames = {
  block,
  indicatorElement: `${block}__indicator`,
};

export default css`
  position: relative;
  .${classNames.indicatorElement} {
    display: initial;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    user-select: none;

    box-shadow: inset 0px 0px 20px 10px ${COLORS.primary}64; // 64 = 0.4 opacity
  }
`;

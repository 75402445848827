import { css } from '@emotion/react';

export default css({
  '.new-brief-button': {
    '&__btn': {
      marginTop: '0.5rem',
      marginBottom: '0.25rem',
    },
  },
});

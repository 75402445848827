import { FacebookConnectValue } from 'features/accountPlatforms/facebook/FacebookConnect/FacebookConnectInput';
import { CreditCardValue } from 'shared/components/molecules/CreditCardInput';
import { ImageFileInputValue } from 'shared/components/molecules/ImageFileInput';
import { Value as SelectValue } from 'shared/components/molecules/Select/SelectInput';

export interface OrgBillingValues {
  creditCard: CreditCardValue;
  creditCardOwner: string;
}

export interface OrgDetailsValues {
  name: string;
  domain: string;
}

export interface BrandAdvertisingValues {
  brandName: string;
  brandWebsite: string;
  brandLogo: ImageFileInputValue;
  brandSlogan: string;
}

export interface FacebookOnboardingValues {
  connectFacebook: FacebookConnectValue;
  facebookBusiness: SelectValue | null;
  fbPage?: SelectValue | null;
  fbPixel?: SelectValue | null;
  fbAdAccount?: SelectValue | null;
  igAccount?: SelectValue | null;
  productCatalog?: SelectValue | null;
  shouldCreateAdAccount?: boolean;
}

export enum ORGANIZATION_ONBOARDING_STEPS {
  DETAILS = 1,
  BILLING = 2,
  ADDRESS = 3,
  BRAND_ADVERTISING = 4,
  FACEBOOK = 5,
}

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.profile-panel': {
    backgroundColor: COLORS.WHITE,
    border: `1px solid ${COLORS.NEUTRAL100}`,
    borderRadius: '10px',
    height: 'min-content',
    margin: '1rem',
  },
  '.profile-panel': {
    '&__header': {
      backgroundColor: COLORS.PRIMARY50,
      borderRadius: '5px',
      margin: theme.space[3],
      height: '55px',
      overflowWrap: 'break-word',
      padding: `${theme.space[3]} ${theme.space[4]}`,

      display: 'grid',
      gridTemplateColumns: '150px auto',
      alignItems: 'center',

      [mediaquery.md]: {
        gridTemplateColumns: '200px auto',
      },
    },
    '&__header a': {
      fontWeight: 'normal',
      color: COLORS.PRIMARY500,
    },
    '&__header > *': {
      width: '100%',
    },
    '&__content-wrapper': {
      padding: '0.5rem 1rem',
    },
  },
});

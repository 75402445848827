/* eslint-disable no-param-reassign */
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { isValid } from 'date-fns';
import { merge, startCase } from 'lodash';

import { PerformanceLevels } from 'features/performance/enums';

import { PERFORMANCE_DATA_TYPES } from 'shared/config/performance';

const { DEFAULT, COMPARISON, TOP_BOTTOM_ANALYSIS } = PERFORMANCE_DATA_TYPES;

interface PerformanceState {
  fields: any[];
  data: any;
  adCopyIds: string[];
  accountSnippets: any;
  platformSnippets: any;
}

const performanceSlice = createSlice<PerformanceState, SliceCaseReducers<PerformanceState>>({
  name: 'performance',
  initialState: {
    fields: [],
    adCopyIds: [],
    data: {},
    accountSnippets: {},
    platformSnippets: {},
  },
  reducers: {
    performanceFieldsLoaded: (performance, action) => {
      performance.fields = action.payload.data;
    },

    performanceDataLoaded: (performance, action) => {
      const { data, params } = action.payload;
      const type = params.dateRanges ? COMPARISON : DEFAULT;
      data.type = type;

      performance.data = data;
    },

    performanceDataReset: (performance) => {
      performance.data = {};
      performance.adCopyIds = [];
    },

    performanceDataFailed: (performance) => {
      performance.data.metrics = [];
      performance.data.summary = {};
      performance.data.pages = 1;
    },

    performanceAdCopyAdded: (performance, action) => {
      const { id } = action.payload;
      if (!performance.adCopyIds.includes(id)) {
        performance.adCopyIds.push(id);
      }
    },

    performanceAdCopyRemoved: (performance, action) => {
      const { id } = action.payload;
      performance.adCopyIds = performance.adCopyIds.filter((adCopyId) => adCopyId !== id);
    },

    snippetDataAdded: (performance, action) => {
      const { data, params } = action.payload;
      const { accountId } = params;

      const perAccess = {};
      const perAccount: any[] = [];

      data.platforms.forEach((platform) => {
        const byAccessId = data.metrics[platform];
        merge(perAccess, byAccessId);
        if (byAccessId) {
          // If metrics are not returned or do no exist for the platform.
          Object.values(byAccessId).forEach((val) => perAccount.push(val));
        }
      });

      performance.platformSnippets = perAccess;
      performance.accountSnippets[accountId] = perAccount;
    },

    topBottomLoaded: (performance, action) => {
      const { top, bottom } = action.payload.data;
      const metricsArr = [
        ...top.metrics.map((metric) => ({ ...metric, top: true })),
        ...bottom.metrics.map((metric) => ({
          ...metric,
          bottom: true,
        })),
      ];

      performance.data = {
        metrics: metricsArr,
        type: TOP_BOTTOM_ANALYSIS,
        summary: {
          top: top.summary,
          bottom: bottom.summary,
        },
      };
    },
  },
});

export const {
  performanceFieldsLoaded,
  performanceDataLoaded,
  performanceDataReset,
  performanceDataFailed,
  topBottomLoaded,
  snippetDataAdded,
  performanceAdCopyAdded,
  performanceAdCopyRemoved,
} = performanceSlice.actions;

export const getPerformance = (state) => state.performance;
export const getPerformanceData = (state) => state.performance.data;
export const getPerformanceFieldsByLevel = (level) => (state) =>
  state.performance.fields
    .filter((field) => {
      let filteredPrefixes: string[] = [];
      if (level === PerformanceLevels.ACCOUNT) {
        filteredPrefixes = [
          `${PerformanceLevels.CAMPAIGN}_`,
          `${PerformanceLevels.ADSET}_`,
          `${PerformanceLevels.AD}_`,
        ];
      }
      if (level === PerformanceLevels.CAMPAIGN) {
        filteredPrefixes = [`${PerformanceLevels.ADSET}_`, `${PerformanceLevels.AD}_`];
      }
      if (level === PerformanceLevels.ADSET) {
        filteredPrefixes = [`${PerformanceLevels.AD}_`];
      }
      return filteredPrefixes.every((prefix) => !field.includes(prefix));
    })
    .map((value) => ({
      value,
      label: startCase(value),
    }));

const formatSnippet = (platformSnippet, metric = 'Spend') => {
  const snippet = {};
  let total = 0;

  if (platformSnippet && platformSnippet.summary && platformSnippet.summary[metric]) {
    const values: any[] = [];

    Object.entries(platformSnippet.summary[metric]).forEach(([range, value]) => {
      const [day] = range.split(',');
      if (isValid(new Date(day))) {
        snippet[day] = value;
        values.push(value);
      }
    });
    total = values.reduce((acc, cur) => acc + cur, 0);
  }

  const dailyArray = Object.entries(snippet).map(([key, value]) => ({
    date: new Date(key),
    value,
  }));

  return { snippet, total, byDay: dailyArray };
};

const aggregateSnippets = (platformSnippets: any[] = []) => {
  let totally = 0;
  const dailyTotals = {};

  platformSnippets.forEach((ps) => {
    const { snippet, total } = formatSnippet(ps);
    const dates = Object.keys(snippet);
    if (dates.length) {
      totally += total;
      dates.forEach((date) => {
        if (!dailyTotals[date]) {
          dailyTotals[date] = snippet[date];
        } else {
          dailyTotals[date] += snippet[date];
        }
      });
    }
  });

  const dailyArray = Object.entries(dailyTotals).map(([key, value]) => ({
    date: new Date(key),
    value,
  }));

  return { total: totally, byDay: dailyArray };
};

// to do: cross-slice selector

export const getPlatformSnippet = (accountPlatformId) => (state) => {
  const accountPlatform = state.accountPlatforms.platforms.find((ap) => ap.id === accountPlatformId);

  if (accountPlatform) {
    const { accessId } = accountPlatform;

    const platformSnippet = state.performance.platformSnippets[accessId] || null;

    return formatSnippet(platformSnippet);
  }
};

export const getAccountsWithSnippet = (state) => {
  const { accounts } = state;
  const { accountSnippets } = state.performance;

  return accounts.map((account) => {
    const { total } = aggregateSnippets(accountSnippets[account.id]);
    return { metricTotal: total, ...account };
  });
};

export const getAdCopyIds = ({ performance: { adCopyIds } }) => adCopyIds;

export default performanceSlice.reducer;

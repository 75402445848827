import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.slider': {
    padding: '1rem 0',
  },
  '.slider': {
    '&__container': {
      position: 'relative',
      width: '100%',
      touchAction: 'none',
    },
    '&__rail-outer, &__rail-inner': {
      position: 'absolute',
      width: '100%',
      borderRadius: 7,
      zIndex: zIndex.zBackdrop,
    },
    '&__rail-outer': {
      cursor: 'pointer',
    },
    '&__rail-inner': {
      pointerEvents: 'none',
    },
    '&__track': {
      position: 'absolute',
      height: 14,
      zIndex: zIndex.zForeground,
      borderRadius: 7,
      cursor: 'pointer',
      backgroundColor: COLORS.PRIMARY300,
    },
    '&__track--disabled': {
      backgroundColor: COLORS.NEUTRAL200,
    },
    '&__handle-slider, &__handle-pointer': {
      position: 'absolute',
    },
    '&__handle-pointer': {
      zIndex: zIndex.zButton,
      borderRadius: '50%',
      boxShadow: '1px 1px 1px 1px rgba(0, 0, 0, 0.3)',
      backgroundColor: COLORS.PRIMARY500,
    },
    '&__handle-pointer--disabled': {
      backgroundColor: COLORS.NEUTRAL500,
    },
    '&__handle-value': {
      textAlign: 'center',
      width: 'fit-content',
    },
  },
  '&.slider--regular': {
    '& .slider__tick': {
      position: 'absolute',
      marginTop: 15,
      width: 1,
      height: 5,
      backgroundColor: 'rgb(200,200,200)',
    },
    '& .slider__tick-text': {
      position: 'absolute',
      marginTop: 22,
      fontSize: 10,
      textAlign: 'center',
    },
    '& .slider__container': {
      margin: '1rem 1rem 2rem 1rem',
    },
    '& .slider__rail-outer': {
      height: 42,
    },
    '& .slider__rail-inner': {
      height: 14,
      backgroundColor: 'rgb(155,155,155)',
    },
    '& .slider__handle-pointer': {
      width: 24,
      height: 24,
      transform: 'translate(-45%, -25%)',
    },
    '& .slider__handle-value': {
      margin: '-25px auto 0 auto',
    },
  },
  '&.slider--mini': {
    padding: theme.space[3],
    paddingBottom: theme.space[5],
    borderRadius: '10px',
    backgroundColor: COLORS.NEUTRAL50,
    '& .slider__container': {
      height: '55px',
      display: 'flex',
      alignItems: 'end',
      margin: 0,
    },
    '& .slider__handle-value': {
      position: 'absolute',
      top: 0,
    },
    '& .slider__handle-value--left': {
      left: 0,
    },
    '& .slider__handle-value--right': {
      right: 0,
    },
    '& .slider__handle-pointer': {
      border: `3px solid ${COLORS.WHITE}`,
      backgroundColor: COLORS.PRIMARY500,
      width: 20,
      height: 20,
      transform: 'translate(-45%, 25%)',
    },
    '& .slider__track, & .slider__rail-outer, & .slider__rail-inner': {
      height: 8,
    },
    '& .slider__rail-inner': {
      backgroundColor: COLORS.NEUTRAL100,
      border: `1px solid ${COLORS.NEUTRAL200}`,
    },
    '& .slider__track': {
      backgroundColor: COLORS.PRIMARY500,
    },
    '& .slider__track--disabled': {
      backgroundColor: COLORS.NEUTRAL100,
    },
  },
});

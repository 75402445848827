import { css } from '@emotion/react';

import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.creator-onboarding': {
    [mediaquery.md]: {
      margin: '1rem 12rem',
    },
  },
  '.creator-onboarding': {
    '&__stripe': {
      marginTop: theme.space[4],
    },
  },
});

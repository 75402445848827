import { MESSAGES } from 'shared/config/validations';
import * as yup from 'yup';

const PASSWORD_THRESHOLD = 3;
// examples:
// score 0: apple
// score 1: apple1
// score 2: Applejack20
// score 3: Applejack201@  <-- current setting
// score 4: Applejack201@@!

export const passwordValidationSchema = yup
  .object({
    message: yup.string(),
    score: yup.number(),
    password: yup.string(),
  })
  .test('required', MESSAGES.REQUIRED_FIELD, function ({ message: passwordMessage, score = 0, password }) {
    const containsWhitespace = /\s/.test(password);
    if (!password) return false;
    if (containsWhitespace) {
      return this.createError({ message: MESSAGES.WHITESPACE });
    }
    if (score < PASSWORD_THRESHOLD) {
      return this.createError({
        message: passwordMessage,
      });
    }
    return true;
  });

export const passwordConfirmationSchema = yup
  .string()
  .required(MESSAGES.REQUIRED_FIELD)
  .test({
    name: 'password-match',
    message: MESSAGES.PASSWORD_MATCH,
    test(value) {
      return value === this.parent.password.password;
    },
  });

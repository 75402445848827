import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
    '.brand-user-settings': {
        '&__picture': {
            flexDirection: 'column',
            alignItems: 'start',
            [mediaquery.md]: {
                width: '50%',
                marginRight: 'auto',
            },
        },
        '&__delete-picture-btn': {
            padding: 0,
            margin: '16px 0',
        },
    },
});

import React from 'react';
import ErrorPage from 'features/ui/pages/ErrorPages/ErrorPage';
import { GENERIC_COPY } from 'shared/config/copy/genericCopy';
import { intl } from 'shared/lib/intl';
import config from 'config';

const NotFoundPage = () => {
  const errorMessage = intl.formatMessage(
    { id: 'ERROR_PAGE_404_MESSAGE' },
    {
      email: (
        <a className="error-page__email" href={`mailto:${config.GMAIL_USER}`} target="_blank">
          {config.GMAIL_USER}
        </a>
      ),
    },
  );

  return <ErrorPage errorType={GENERIC_COPY.ERROR_PAGE_404_TYPE} errorMessage={errorMessage}></ErrorPage>;
};

export default NotFoundPage;

export const MESSAGE_COPY = {
    // Buttons
    BUTTON_MESSAGE: 'Message',
    BUTTON_NO_CHANNELS_CREATOR: 'Apply to a Brief',

    // Heading
    HEADING_NO_CHANNELS: `You have no messages.`,
    HEADING_NO_CHANNELS_CREATOR: `You will be able to message brands once you've been approved.`,
    HEADING_NO_CHANNELS_BRAND: `You will be able to message creators once you've approved them to work on your brief.`,

    // Frozen Channels
    DEFAULT_MESSAGE_INPUT_PLACEHOLDER: `Type your message`,
    FROZEN_MESSAGE_INPUT_PLACEHOLDER: `This channel is no longer active`,

    //Search Channels
    CREATOR_SEARCH_EMPTY: "No channels matched your creator name search",
    BRAND_SEARCH_EMPTY: "No channels matched your brand name search",
};

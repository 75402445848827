import { css } from '@emotion/react';

export default css({
  '&.ds-report': {
    flexGrow: 1,
  },
  '.ds-report': {
    '&__frame': {
      height: '100%',
    },
  },
});

import { css } from '@emotion/react';

export default css({
  '&.module-header-icons': {
    alignItems: 'center',
  },
  '.module-header-icons': {
    '&__note': {
      cursor: 'pointer',
    },
  },
});

import 'stream-chat-react/dist/css/index.css';
import React, { useEffect } from 'react';
import { ChannelPreviewUIComponentProps } from 'stream-chat-react';
import { useMessaging } from 'features/messages/useMessaging';
import { getUserImage, parseChannelDisplayTitle } from 'features/messages/util';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { StreamChatTypes } from 'shared/messaging/types';
import ChannelListItem from './ChannelListItem';

const ChannelListPreview: React.FC<ChannelPreviewUIComponentProps<StreamChatTypes>> = ({
  channel,
  setActiveChannel,
  latestMessage,
  unread = 0,
  active,
  watchers,
  displayTitle,
}) => {
  const channelPreviewButton = React.createRef<HTMLButtonElement>();
  const { activeChannel, setActiveChannel: updateActiveChannel } = useMessaging();

  useEffect(() => {
    if ((active && !activeChannel) || (active && channel.id !== activeChannel.id)) {
      updateActiveChannel(channel);
    }
  }, [active]);

  const { isCreator } = useCurrentUser();
  if (displayTitle && isCreator) {
    displayTitle = parseChannelDisplayTitle(displayTitle);
  }
  const displayImage = getUserImage(channel, isCreator);
  const unreadClass = unread >= 1 ? 'str-chat__channel-preview-messenger--unread' : '';
  const activeClass = active ? 'str-chat__channel-preview-messenger--active' : '';

  const onSelectChannel = () => {
    if (setActiveChannel) setActiveChannel(channel, watchers);
    channelPreviewButton.current?.blur();
  };

  const renderMessageText = () => {
    const lastMessageText = channel.state.messages[channel.state.messages.length - 1].text || '';
    const previewText =
      lastMessageText.length < 10 ? lastMessageText : `${lastMessageText.trimStart().split('\n')[0]}...`;
    if (React.isValidElement<{ children: string }>(latestMessage))
      return React.cloneElement(latestMessage, { children: previewText });
    return previewText;
  };

  if (!channel.state.messages.length) return null;

  return (
    <ChannelListItem
      isCreator={isCreator}
      displayImage={displayImage}
      displayTitle={displayTitle}
      channel={channel}
      renderMessageText={renderMessageText}
      unreadClass={unreadClass}
      activeClass={activeClass}
      previewRef={channelPreviewButton}
      onClick={onSelectChannel}
    />
  );
};

export default ChannelListPreview;

import { css } from '@emotion/react';

export default css({
  '.revision-card': {
    '&__header': {
      marginBottom: '1.5rem',
      marginLeft: '.5rem',
      marginRight: '.5rem',
    },
    '&__body': {
      marginLeft: '1.5rem',
      marginRight: '1.5rem',
      wordBreak: 'break-all',
    },
  },
});

import { LOCAL_STORAGE } from 'shared/config/storage';
import { faList, faTh } from '@fortawesome/pro-solid-svg-icons';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useDispatch, useSelector } from 'react-redux';
import { getGalleryListView, toggleGalleryListView } from 'features/ui/ui.slice';

export const VIEWS = Object.freeze({
  GALLERY: { text: 'Gallery', icon: faTh, value: 'Gallery' },
  LIST: { text: 'List', icon: faList, value: 'List' },
});

export const useGalleryListView = (forceViewMode: 'Gallery' | 'List' | '' = '') => {
  const dispatch = useDispatch();
  const { currentUser, isCreator } = useCurrentUser();

  const storageKey = `${LOCAL_STORAGE.MEDIA_DISPLAY_FORMAT}_${currentUser.id}`;

  const storedValue: string = useSelector(getGalleryListView);
  const storageValue = JSON.parse(window.localStorage.getItem(storageKey) || '""');
  const initialValue = isCreator ? VIEWS.LIST.value : VIEWS.GALLERY.value;

  const setViewMode = (mode: string) => {
    window.localStorage.setItem(storageKey, JSON.stringify(mode));
    dispatch(toggleGalleryListView(mode));
  };
  const viewMode = forceViewMode || storageValue || storedValue || initialValue;

  return {
    setViewMode,
    viewMode,
  };
};

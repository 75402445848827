import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export default css({
  '.heading': {
    '&__text': {
      paddingRight: theme.space[2],
      margin: 0,
    },
    '&__icon': {
      cursor: 'pointer',
      marginTop: theme.space[3],
    },
  },
});

import React from 'react';

interface Props {
  score?: number;
  hasValue?: boolean;
}

const StrengthBar: React.FC<Props> = ({ score = 0, hasValue = false }) => {
  return <span className={`password-input__strength-bar ${hasValue ? `password-input__strength-bar-${score}` : ''}`} />;
};

export default StrengthBar;

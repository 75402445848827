import { css } from '@emotion/react';

import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.submission-modules': {
    margin: '0 1rem 20vh 1rem',
    [mediaquery.md]: {
      margin: '0 1rem',
    },
  },
  '.download-files-button': {
    marginRight: 'auto',
  },
  '.download-files-button__btn': {
    paddingLeft: 0,
  },
  '.submission-modules': {
    '&__control-wrapper': {
      justifyContent: 'end',
    },
    '&__header': {
      alignItems: 'center',
      flexGrow: 1,
      justifyContent: 'space-between',
      paddingLeft: theme.space[3],
      margin: `${theme.space[4]} 0`,
    },
    '&__status-select > div': {
      marginTop: 0,
    },
  },
  '.divider': {
    height: '100%',
    [mediaquery.md]: {
      height: '60px',
    },
  },
});

import { Box } from 'shared/components/display';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lightboxArrowStyles from 'shared/components/organisms/Lightbox/lightboxArrowStyles';
import cs from 'classnames';
import React from 'react';

interface Props {
  clickHandler: () => void;
  hasNext: boolean;
}
const LightboxRightArrow: React.FC<Props> = ({ clickHandler, hasNext }) => {
  return hasNext ? (
    <Box css={lightboxArrowStyles} className={cs('lightbox-arrow', 'lightbox-arrow__right')}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          clickHandler();
        }}
        className={'lightbox-arrow__btn'}
        data-cy="lightbox-arrow__right"
      >
        <FontAwesomeIcon icon={faChevronRight} size="lg" />
      </button>
    </Box>
  ) : (
    <></>
  );
};

export default LightboxRightArrow;

/* eslint-disable @typescript-eslint/no-shadow */
import { useCallback, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import config from 'config';
import { v4 as uuidv4 } from 'uuid';

import { getCollection, selectedCollectionChanged } from 'features/media/collections.slice';
import {
  fileRemoved,
  filesAdded,
  filesAddedToCollection,
  filesCleared,
  filesLoaded,
  fileUpdated as fileUpdatedAction,
  getCollectionFiles,
  getFiles,
  getFileUpdated,
  getSelectedCollectionId,
  getSelectedFiles,
  getTotalNumFiles,
  pinnedCountUpdated,
  resetFileUpdated,
  setSelectedFiles,
} from 'features/media/files.slice';
import {
  componentLoadingToggled,
  getFilesUploadComplete,
  getFilesUploading,
  getLoadingNewFiles,
  getShowUploadDrawer,
  newFilesLoadingToggled,
  updateFileUploadProgress,
  updateFileUploadStatus,
} from 'features/ui/ui.slice';

import { apiAction } from 'shared/actions/api';
import { FileUploadOperation, Metadata, Options, uploadFile, uploadFiles } from 'shared/blobstorage';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_FILE_ROUTES } from 'shared/config/routes/api/apiFileRoutes';
import { FILE_TOASTS } from 'shared/config/toasts/fileToasts';
import { noOp } from 'shared/defaults';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { Collection } from 'shared/typings/collections';
import { AzureReadyOperation, BrkfstFile, UploadOperation } from 'shared/typings/file';
import { FileUploadStatus } from 'shared/typings/file/enums';
import { BrkfstSearchQueryParams } from 'shared/typings/searchQueryParams';
import { MultiValidator, SubmissionValidator, Validator } from 'shared/utilities/validator';
import { getMediaMetaData } from 'shared/utilities/validator/utils';

import { useSasToken } from './useSasToken';

type UseFilesProps = {
  params?: BrkfstSearchQueryParams;
  shouldFetch?: boolean;
};

export const useFiles = ({ params = {}, shouldFetch = false }: UseFilesProps = {}) => {
  const {
    currentUser: { id: userId },
  } = useCurrentUser();
  const sasToken = useSasToken();

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const entity = RESOURCES.FILE;
  const fileUpdateEntity = RESOURCES.FILE_UPDATE;
  const { loading } = useComponentLoading(entity);
  const { loading: fileUpdating } = useComponentLoading(fileUpdateEntity, false);
  const routeParams = useParams<any>();
  const collectionId: number = +params.collectionId || +routeParams.collectionId;
  const files = useSelector(getFiles);
  const totalFiles = useSelector(getTotalNumFiles);

  const collectionFiles: BrkfstFile[] = useSelector(getCollectionFiles) || [];
  const selectedCollectionId = useSelector(getSelectedCollectionId);
  // TODO: Fix Types
  // @ts-ignore
  const selectedCollection = useSelector<any, Collection | null>(getCollection(selectedCollectionId));
  const selectedFiles: BrkfstFile[] = useSelector(getSelectedFiles);
  const newFilesLoading: number = useSelector(getLoadingNewFiles);
  const filesUploading = useSelector(getFilesUploading);
  const showUploadDrawer = useSelector(getShowUploadDrawer);
  const filesUploadComplete = useSelector(getFilesUploadComplete);
  const fileUpdated = useSelector(getFileUpdated);
  const currentPageRef = useRef(1);

  const toggleFilesLoading = (loading: boolean) => {
    dispatch(componentLoadingToggled({ component: entity, loading }));
  };

  const getCollectionFilteredFiles = (newCollectionId = collectionId, query = params) => {
    const filter = Object.assign({ collectionId: newCollectionId }, query);
    dispatch(
      apiAction({
        path: {
          route: API_FILE_ROUTES.FILES,
        },
        params: filter,
        successAction: filesLoaded,
        entity,
      }),
    );
  };

  const changeSelectedCollection = (colId) => {
    dispatch(selectedCollectionChanged(colId ? +colId : null));
    if (!colId) {
      dispatch(filesCleared({}));
      currentPageRef.current = 1;
    }
  };

  useEffect(() => {
    if (shouldFetch) {
      changeSelectedCollection(collectionId);
      getCollectionFilteredFiles(collectionId);
    }
  }, [collectionId]);

  const validateFile = async (file: File, fileName: string, validator?: Validator): Promise<string[]> => {
    const fileData = await getMediaMetaData(file, fileName);
    if (fileData.error) return [fileData.error]; // ran into an error while trying to retrieve metadata

    if (validator && fileData) {
      const errors = await validator.validate({
        file,
        fileName,
        metadata: fileData,
      });
      return errors;
    }
    return [];
  };

  const initiateSingleFileUpload = async (
    {
      file,
      fileName: fName,
      tagNames,
      collectionId,
      submissionId,
      metadata = {},
      dispatchLoader = true,
      fileDirectory = config.BLOB_FILE_DIRECTORY,
      key = uuidv4(),
    }: UploadOperation,
    validator: Validator,
    onError?: (errors: string[]) => void,
  ) => {
    const fileName = fName || file.name;

    const errors = await validateFile(file, fileName, validator);

    // Required Metadata
    const uploadMetadata: Metadata = {
      userId: userId.toString(),
      key,
    };
    // Optional Metadata
    Object.keys(metadata).forEach((label) => {
      if (metadata[label]) uploadMetadata[label] = metadata[label].toString();
    });
    if (tagNames) uploadMetadata.tags = tagNames.toString();
    if (collectionId) uploadMetadata.collectionId = collectionId.toString();
    if (submissionId) uploadMetadata.submissionId = submissionId.toString();

    const abortController = new AbortController();
    if (dispatchLoader) {
      dispatch(
        newFilesLoadingToggled({
          numNewFiles: errors.length < 1 ? 1 : 0,
          filesUploading: [
            {
              id: key,
              errors,
              abortController,
              name: fileName,
              status: errors.length < 1 ? FileUploadStatus.UPLOADING : FileUploadStatus.UPLOAD_FAILED,
            },
          ],
        }),
      );
    }
    if (!sasToken) errors.push('Unauthorized File Upload Access.');

    if (errors.length < 1 && sasToken) {
      await uploadFile(sasToken, file, {
        fileName,
        metadata: uploadMetadata,
        abortSignal: abortController.signal,
        blobDirectory: fileDirectory,
        onProgress: ({ loadedBytes }) =>
          dispatch(updateFileUploadProgress({ id: key, progress: (loadedBytes / file.size) * 100 })),
      });
    } else if (onError) {
      onError(errors);
    }
  };

  const validateUniqueFileNames = useCallback(
    (uploadOperations: UploadOperation[], isSubmissionValidation) => {
      const duplicateErrorCopy = isSubmissionValidation ? 'Duplicate Aspect Ratio' : 'Duplicate File Name';
      const filesUploadingNames = filesUploading
        .filter(({ status }) => ![FileUploadStatus.UPLOAD_FAILED, FileUploadStatus.UPLOADED].includes(status))
        .map(({ name }) => name);
      const fileNames = new Set<string>(filesUploadingNames);
      const uniqueOperations: UploadOperation[] = [];
      const invalidOperations: AzureReadyOperation[] = [];
      uploadOperations.forEach((operation) => {
        const { file, fileName } = operation;
        const name = fileName || file.name;
        if (fileNames.has(name)) {
          invalidOperations.push({
            ...operation,
            errors: [duplicateErrorCopy],
            id: uuidv4(),
          });
        } else {
          fileNames.add(name);
          uniqueOperations.push(operation);
        }
      });
      return { validOperations: uniqueOperations, invalidOperations };
    },
    [filesUploading],
  );

  const initiateMultipleFilesUpload = useCallback(
    async (uploadOperations: UploadOperation[], validator: Validator) => {
      const isSubmissionValidation =
        validator instanceof MultiValidator && validator.hasValidatorType(SubmissionValidator);

      const { validOperations: uniqueOperations, invalidOperations } = validateUniqueFileNames(
        uploadOperations,
        isSubmissionValidation,
      );
      const promises = uniqueOperations.map(async (operation): Promise<AzureReadyOperation> => {
        const { file, fileName: fName } = operation;
        const fileName = fName || file.name;
        const errors = await validateFile(file, fileName, validator);
        const abortController = new AbortController();
        return {
          ...operation,
          fileName,
          errors,
          id: uuidv4(),
          abortController: abortController,
          abortSignal: abortController.signal,
        };
      });
      const validatedOperations = await Promise.all(promises);
      const azureReadyOperations = validatedOperations.concat(invalidOperations);
      const validOperations = azureReadyOperations.filter(
        (operation: AzureReadyOperation) => operation.errors?.length === 0,
      );

      dispatch(
        newFilesLoadingToggled({
          numNewFiles: validOperations.length,
          filesUploading: azureReadyOperations.map((operation: AzureReadyOperation) => {
            const errors = operation?.errors || [];
            if (!sasToken) errors.push('Unauthorized File Upload Access.');
            return {
              name: operation.fileName || operation.file.name,
              status: errors.length === 0 ? FileUploadStatus.UPLOADING : FileUploadStatus.UPLOAD_FAILED,
              errors: errors,
              abortController: operation?.abortController,
              id: operation.id,
            };
          }),
        }),
      );

      if (sasToken) {
        await uploadFiles(
          sasToken,
          validOperations.map(
            ({
              file,
              collectionId,
              submissionId,
              tagNames,
              metadata = {},
              abortSignal,
              id,
              fileName,
            }): FileUploadOperation => {
              // Required Metadata
              const uploadMetadata: Metadata = {
                userId: userId.toString(),
                key: id.toString(),
              };
              // Optional Metadata
              Object.keys(metadata).forEach((label) => {
                if (metadata[label]) uploadMetadata[label] = metadata[label].toString();
              });
              if (tagNames) uploadMetadata.tags = tagNames.toString();
              if (submissionId) uploadMetadata.submissionId = submissionId.toString();
              if (collectionId) uploadMetadata.collectionId = collectionId.toString();
              const fileSize = file.size;
              const options: Options = {
                metadata: uploadMetadata,
                abortSignal: abortSignal,
                onProgress: ({ loadedBytes }) =>
                  dispatch(updateFileUploadProgress({ id, progress: (loadedBytes / fileSize) * 100 })),
              };
              if (fileName) options.fileName = fileName;
              return {
                file: file,
                options,
              };
            },
          ),
        );
      }
    },
    [validateUniqueFileNames, dispatch, sasToken, userId],
  );

  const updateFileUpload = (id: string, fileUploadStatus: FileUploadStatus, errors?: string) => {
    dispatch(updateFileUploadStatus({ id, fileUploadStatus, errors: errors ? [errors] : null }));
  };

  const getFile = (fileId) => {
    dispatch(
      apiAction({
        path: {
          route: API_FILE_ROUTES.FILE,
          variables: {
            fileId,
          },
        },
        params: {
          selectUploadedFiles: true,
          // dont update numFiles since files aren't being newly uploaded
          updateCollection: false,
        },
        method: 'GET',
        successAction: filesAdded,
        entity,
      }),
    );
  };

  const updateFile = (collectionId: number, fileObject: BrkfstFile, updateThumbnail: boolean = false) => {
    dispatch(resetFileUpdated(false));
    dispatch(
      apiAction({
        path: {
          route: API_FILE_ROUTES.FILE,
          variables: {
            fileId: fileObject.id,
          },
        },
        data: {
          ...fileObject,
          collectionId,
        },
        method: 'PATCH',
        entity: RESOURCES.FILE_UPDATE,
        successAction: fileUpdatedAction,
        successToast: {
          message: FILE_TOASTS.FILE_UPDATED,
        },
      }),
    );
  };

  const deleteFile = ({
    fileId,
    collectionId: cId,
    showToast = true,
  }: {
    fileId: number;
    collectionId?: number;
    showToast?: boolean;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_FILE_ROUTES.FILE,
          variables: {
            fileId,
          },
        },
        method: 'DELETE',
        params: { collectionId: cId },
        successAction: fileRemoved,
        entity,
        successToast: showToast
          ? {
              message: FILE_TOASTS.FILE_DELETED,
            }
          : false,
      }),
    );
  };

  const removeFromCollection = ({ fileId, collectionId: cId, showToast = true }) => {
    dispatch(
      apiAction({
        path: {
          route: API_FILE_ROUTES.REMOVE_FILE,
          variables: {
            fileId,
            collectionId: cId,
          },
        },
        method: 'POST',
        // for access in collections slice
        params: { collectionId: cId },
        successAction: fileRemoved,
        entity,
        successToast: showToast
          ? {
              message: FILE_TOASTS.FILE_DELETED,
            }
          : false,
      }),
    );
  };

  const assignToCollection = ({
    fileIds,
    id,
    collectionName,
    onError = noOp,
    onSuccess = noOp,
  }: {
    fileIds: Array<number>;
    id?: number;
    collectionName?: string;
    onError?: (args?: any) => void;
    onSuccess?: (args?: any) => void;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_FILE_ROUTES.ASSIGN_FILE,
          variables: {
            collectionId: id,
          },
        },
        method: 'POST',
        data: {
          fileIds,
        },
        successAction: filesAddedToCollection,
        successToast: {
          message: formatMessage(
            {
              id: 'FILES_MOVED',
            },
            {
              collectionName,
            },
          ),
        },
        onError,
        onSuccess,
      }),
    );
  };

  const downloadCreatives = ({
    fileIds,
    withWatermark = false,
    downloadInfo,
    onSuccess = noOp,
  }: {
    fileIds: Array<number>;
    downloadInfo: {
      brandName: string;
      orgName: string;
      collectionName: string;
      briefName?: string;
    };
    onSuccess?: (args?: any) => void;
    withWatermark?: boolean;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_FILE_ROUTES.DOWNLOAD_FILES,
        },
        method: 'POST',
        data: {
          fileIds,
          downloadInfo,
          withWatermark,
        },
        successAction: pinnedCountUpdated,
        onSuccess,
      }),
    );
  };

  const selectFiles = (newSelectedFiles: number[]) => {
    dispatch(setSelectedFiles(newSelectedFiles));
  };

  return {
    assignToCollection,
    changeSelectedCollection,
    collectionFiles,
    deleteFile,
    files,
    filesUploadComplete,
    filesUploading,
    fileUpdated,
    fileUpdating,
    getCollectionFilteredFiles,
    getFile,
    initiateMultipleFilesUpload,
    initiateSingleFileUpload,
    loading,
    newFilesLoading,
    removeFromCollection,
    downloadCreatives,
    selectedCollection,
    selectedCollectionId,
    selectedFiles,
    selectFiles,
    showUploadDrawer,
    totalFiles,
    updateFile,
    updateFileUpload,
    toggleFilesLoading,
  };
};

import { useQuery } from 'shared/hooks/useQuery';
import { useToasts } from 'shared/hooks/useToasts';
import { useEffect } from 'react';

export const useInvitationTokenError = () => {
  const { getQuery } = useQuery();
  const { tokenError } = getQuery();
  const { setWarningToast } = useToasts();

  useEffect(() => {
    if (tokenError) setWarningToast({ message: tokenError });
  }, [tokenError]);
};

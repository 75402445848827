import React, { useMemo } from 'react';
import { Text } from 'rebass/styled-components';
import { Field, Form, Formik, FormikContextType, FormikHelpers } from 'formik';

import SubmissionModuleArray from 'features/submissions/components/organisms/SubmissionModuleArray';
import { Box, Flex } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import FormPromptButton from 'shared/components/molecules/FormPromptButton/FormPromptButton';
import { RichtextInput } from 'shared/components/Richtext';

import { SUBMISSION_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { OnSubmit } from 'shared/lib/formik';
import { useIntl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Brief } from 'shared/typings/briefs';
import { Submission, SubmissionStatus } from 'shared/typings/submissions';

import styles from './styles';
import { SubmissionFormFields, SubmissionFormState, SubmissionModuleFields, SubmitValues } from './types';
import { formatInitialValues, formatSubmitValues } from './utils';
import { submissionSchema } from './validation';

interface Props {
  brief: Brief;
  onSubmit: (values: SubmitValues) => void;
  onDraftSubmit: (values: SubmitValues) => void;
  submission: Submission;
  isEdit?: boolean;
  loadingUpdatePublishSubmission?: boolean;
  loadingUpdateDraftSubmission?: boolean;
}

const SubmissionForm: React.FC<Props> = ({
  brief,
  onSubmit,
  onDraftSubmit,
  submission,
  loadingUpdatePublishSubmission = false,
  loadingUpdateDraftSubmission = false,
}) => {
  const { currentUser } = useCurrentUser();
  const initialValues = useMemo(() => formatInitialValues(submission, brief), [submission, brief]);
  const { formatMessage } = useIntl();
  const isSubmitted = submission.status === SubmissionStatus.SUBMITTED;

  const handleSubmit: OnSubmit<SubmissionFormState> = (_, actions: FormikHelpers<SubmissionFormState>) => {
    // Because we've got multiple "ways to submit" we're going to
    // reset submitting state in case we cancel submitting
    actions.setSubmitting(false);
  };

  const postSubmission: OnSubmit<SubmissionFormState> = (state, helpers) => {
    onSubmit(formatSubmitValues(submission, state));
  };

  const touchAllFields = ({ values, setFieldValue }: FormikContextType<SubmissionFormState>) => {
    const field = SubmissionFormFields.MODULE_ASSETS;
    const touched = SubmissionModuleFields.TOUCHED;
    values.moduleAssets.forEach((_, index) => {
      setFieldValue(`${field}[${index}].${touched}`, true);
    });
  };

  return (
    <Formik<SubmissionFormState>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={submissionSchema}
      validateOnChange
      enableReinitialize
    >
      {(helpers) => (
        <Box css={styles} className="submission-form">
          <Form>
            <Text
              variant={TEXT_VARIANTS.SUBHEADING}
              className="submission-form__label-comment"
              id={SubmissionFormFields.DESCRIPTION}
              as="label"
            >
              {formatMessage({ id: 'LABEL_COMMENT' })}
            </Text>
            <Field
              name={SubmissionFormFields.DESCRIPTION}
              component={RichtextInput}
              placeholder={formatMessage({ id: 'LABEL_COMMENT' })}
              ariaLabelledBy={SubmissionFormFields.DESCRIPTION}
            />
            <SubmissionModuleArray name={SubmissionFormFields.MODULE_ASSETS} userId={currentUser.id} />

            <Flex className="submission-form__buttons">
              {!isSubmitted && (
                <FormButton<SubmissionFormState>
                  type="button"
                  variant={BUTTON_VARIANTS.OUTLINE}
                  className="submission-form__button"
                  onClick={({ values }) => onDraftSubmit(formatSubmitValues(submission, values))}
                  loading={loadingUpdateDraftSubmission}
                  disabled={loadingUpdatePublishSubmission}
                  loaderColor={COLORS.PRIMARY700}
                  dataCy="submission-form__save-draft-btn"
                >
                  {SUBMISSION_COPY.BUTTON_SAVE_SUBMISSION_DRAFT}
                </FormButton>
              )}
              <FormPromptButton<SubmissionFormState>
                className="submission-form__button"
                onConfirm={(values) => postSubmission(values, helpers)}
                onClick={() => touchAllFields(helpers)}
                loading={loadingUpdatePublishSubmission}
                disabled={loadingUpdateDraftSubmission}
                buttonText={
                  isSubmitted ? SUBMISSION_COPY.BUTTON_EDIT_SUBMISSION : SUBMISSION_COPY.BUTTON_SUBMIT_SUBMISSION
                }
                text={SUBMISSION_COPY.PROMPT_SUBMIT_SUBMISSION}
                validate
              />
            </Flex>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default SubmissionForm;

import { componentLoadingToggled } from 'features/ui/ui.slice';
import { saveAs } from 'file-saver';
import { RESOURCES } from 'shared/config/resourceNames';
import { getBlobFromUrl } from 'shared/utilities/fileUtility';
import PerformanceExportUtility from 'shared/utilities/Performance/exportUtility';

const DOWNLOAD_FILE_ACTION = 'FILE_DOWNLOAD_REQUESTED';
export function downloadFile(payload) {
    return {
        type: DOWNLOAD_FILE_ACTION,
        payload,
    };
}

const DOWNLOAD_PERFORMANCE_EXPORT_ACTION = 'DOWNLOAD_PERFORMANCE_EXPORT';
const PERFORMANCE_EXPORT_FILE_NAME = 'performance_export.csv';
export function downloadPerformanceExport(payload) {
    return {
        type: DOWNLOAD_PERFORMANCE_EXPORT_ACTION,
        payload,
    };
}

export const downloadFileMiddleware = () => (next) => (action) => {
    const { FILE_DOWNLOAD } = RESOURCES;
    next(action);
    if (action.type === DOWNLOAD_FILE_ACTION) {
        const { name, url } = action.payload;
        getBlobFromUrl(url).then((blob) => {
            saveAs(blob, name);
            next(
                componentLoadingToggled({
                    component: FILE_DOWNLOAD,
                    loading: false,
                }),
            );
        });
    }
    if (action.type === DOWNLOAD_PERFORMANCE_EXPORT_ACTION) {
        const {
            data,
            params: { type, breakdowns, dateRanges, fields },
        } = action.payload;
        const headers = fields.split(',');
        const fileData = PerformanceExportUtility.createExport(
            type,
            data,
            headers,
            { breakdowns, dateRanges },
        );
        saveAs(fileData, PERFORMANCE_EXPORT_FILE_NAME);
    }
};

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.creator-brief-view': {
    backgroundColor: COLORS.WHITE,
    height: '100%',
    flex: 1,
    overflowY: 'scroll',
  },
  '.creator-brief-view': {
    '&__content': {
      padding: theme.space[4],
    },
    '&__banner-wrapper': {
      position: 'relative',
    },
  },
});

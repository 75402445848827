import React from 'react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faStar as faStarLight } from '@fortawesome/pro-light-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import COLORS from 'shared/styles/colors';

interface Props {
  onClick: () => void;
  starred: boolean;
  size: SizeProp;
  disabled: boolean;
  dataCy?: string;
}
const StarIcon: React.FC<Props> = ({ onClick, disabled, starred, size, dataCy }) => {
  const icon = starred ? faStar : faStarLight;
  return (
    <FontAwesomeIcon
      icon={icon}
      color={COLORS.ORANGE300}
      onClick={(e) => {
        if (!disabled) {
          e.stopPropagation();
          onClick();
        }
      }}
      size={size}
      data-cy={dataCy}
      data-starred={starred}
    />
  );
};

export default StarIcon;

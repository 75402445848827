import React, { useState } from 'react';
import { Box, Flex } from 'shared/components/display';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import { Field, Form, Formik } from 'formik';
import { Button } from 'rebass/styled-components';
import { ACCOUNT_COPY, ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import FormButton from 'shared/components/FormButton';
import Modal from 'shared/components/molecules/Modal';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { ReuploadPrompt } from './prompt';
import { schema } from './validation';
import CsvFileInput from 'shared/components/inputs/CsvFileInput';
import { audienceFormStyles } from 'features/accountPlatforms/facebook/components/AudienceForm/styles';

const AUDIENCE = 'audience';

const INITIAL_VALUE = Object.freeze({ [AUDIENCE]: null });

export const AudienceForm = ({ accountId, accountPlatformId, isReupload = false, disabled = false, onCancel }) => {
  const { editAudience } = useAccountPlatforms({
    accountId,
    accountPlatformId,
  });
  const [promptOpen, setPromptOpen] = useState(false);

  const submitHandler = (formData) => {
    const { audience } = formData;
    editAudience(audience);
  };

  const onSubmit = (formData) => {
    if (isReupload) {
      setPromptOpen(true);
    } else {
      submitHandler(formData);
    }
  };

  return (
    <Formik initialValues={INITIAL_VALUE} onSubmit={onSubmit} validationSchema={schema} validateOnBlur={false}>
      {(formikProps) => (
        <Box css={audienceFormStyles} className="audience-form">
          <Form>
            <Field
              name={AUDIENCE}
              component={CsvFileInput}
              placeholder={ACCOUNT_COPY.PLACEHOLDER_CUSTOMER_FILE}
              disabled={disabled}
            />
            <Flex className='audience-form__submit-button'>
              <FormButton disabled={disabled}>{ACCOUNT_PLATFORM_COPY.AUDIENCE_SUBMIT}</FormButton>
            </Flex>
            {isReupload && (
              <Button variant={BUTTON_VARIANTS.TEXT_ONLY} onClick={onCancel}>
                Cancel
              </Button>
            )}
            <Modal
              isOpen={promptOpen}
              title={ACCOUNT_PLATFORM_COPY.AUDIENCE_PROMPT_TITLE}
              component={
                <ReuploadPrompt
                  onSubmit={() => {
                    submitHandler(formikProps.values);
                    setPromptOpen(false);
                  }}
                />
              }
              onRequestClose={() => {
                setPromptOpen(false);
              }}
            />
          </Form>
        </Box>
      )}
    </Formik>
  );
};

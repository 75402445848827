import React from 'react';
import cs from 'classnames';
import { Box, Button, Flex, Text } from 'shared/components/display';
import { BrkfstFile } from 'shared/typings/file';

import { TEXT_VARIANTS } from 'shared/styles/text';
import FileInformationModal from 'features/media/components/molecules/FileInformationModal';
import { SUBMISSION_COPY } from 'shared/config/copy';
import { is9by16AspectRatio } from 'shared/utilities/validator/utils';

import styles from './styles';

interface Props {
  captionData?: BrkfstFile;
  onClick?: (fileId?: number) => void;
}
const AssetToTrendLightboxFooter: React.FC<Props> = ({ captionData, onClick = () => {} }) => {
  const handleClick = () => onClick(captionData?.id);

  const is9By16 = captionData ? is9by16AspectRatio(captionData?.metadata) : false;

  return (
    <Box css={styles} className={cs('asset-to-trend-lightbox-footer')}>
      <Flex className="asset-to-trend-lightbox-footer__caption-wrapper">
        <Text variant={TEXT_VARIANTS.BODY} className="asset-to-trend-lightbox-footer__caption">
          {captionData?.name}
        </Text>
        {captionData && <FileInformationModal file={captionData} />}
      </Flex>
      {is9By16 && (
        <Button onClick={handleClick} dataCy="asset-to-trend-lightbox-footer__btn">
          {SUBMISSION_COPY.BUTTON_ADD_TO_TREND}
        </Button>
      )}
    </Box>
  );
};

export default AssetToTrendLightboxFooter;

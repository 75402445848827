import React, { useMemo } from 'react';
import { Select, SelectOnChange, SelectValue } from 'shared/components/molecules/Select';

const sizeArr = [1, 2, 3, 4];

interface Props {
  pageSizeBase?: number;
  pageSizeOptions?: number[];
  value: number;
  onChange: SelectOnChange;
  width?: string;
}
const PageSizeSelect: React.FC<Props> = ({ pageSizeBase = 5, value, onChange, pageSizeOptions, width = '78px' }) => {
  const options = useMemo(() => {
    if (pageSizeOptions)
      return pageSizeOptions.map((value): SelectValue<number> => ({ value, label: value.toString() }));

    const sizeSelectValues: SelectValue[] = [];
    for (const size of sizeArr) {
      const sizeSelectValue: SelectValue<number> = {
        label: (size * pageSizeBase).toString(),
        value: size * pageSizeBase,
      };
      sizeSelectValues.push(sizeSelectValue);
    }
    return sizeSelectValues;
  }, [pageSizeBase, pageSizeOptions]);

  const getSizeOption = (): SelectValue<number> | null => {
    const option = options.find((option) => option.value === value);
    return option || null;
  };

  return (
    <Select
      options={options}
      className="page-size-select"
      key="pagination"
      name="pageSize"
      value={getSizeOption()}
      onChange={onChange}
      width={width}
      hidePlaceholder
      dataCy="page-size-select"
    />
  );
};

export default PageSizeSelect;

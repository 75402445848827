import React, { useMemo } from 'react';
import type { GroupBase } from 'react-select';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { FormikErrors } from 'formik';
import { isEmpty } from 'lodash';

import { controlStyles, SelectControl } from 'shared/components/atoms/SelectControl';
import { multiValueStyles } from 'shared/components/atoms/SelectMultiValueContainer';
import { SelectValueContainer, valueContainerStyles } from 'shared/components/atoms/SelectValueContainer';
import { MODAL_PORTAL_TARGET_SELECTOR } from 'shared/components/molecules/Modal';

import { Input } from 'shared/lib/formik';
import { BrkfstUser } from 'shared/typings/user';

import { defaultOption } from './config';
import { CreatorSelectData, FetchCreatorOptions, Value } from './interfaces';
import MultiValueLabel from './MultiValueLabel';
import Option from './Option';

interface Props {
  placeholder?: string;
  defaultOptions: any[];
  fetchCreatorOptions: FetchCreatorOptions;
  isMulti?: boolean;
  width?: string;
  onChange?: (value: Value) => void;
  showDefaultOption?: boolean;
  displayProperty?: keyof BrkfstUser;
  hidePlaceholder?: boolean;
  ControlPrefix?: React.ReactNode;
}

const components = {
  MultiValueLabel,
  Option,
  ValueContainer: SelectValueContainer,
  Control: SelectControl,
};

const styles = {
  ...valueContainerStyles,
  ...multiValueStyles,
  ...controlStyles,
};

const AsyncCreatorSelect: Input<Value, Props> = ({
  field: { value, name, onChange, onBlur },
  form: { errors, touched },
  placeholder,
  defaultOptions = [],
  fetchCreatorOptions,
  isMulti = false,
  width,
  onChange: userProvidedOnChange,
  showDefaultOption = false,
  displayProperty = 'name',
  hidePlaceholder,
  ControlPrefix,
}) => {
  const error = errors[name] as FormikErrors<any> | undefined;
  const hasErrors = error && Boolean(touched[name]);

  const loadOptions: LoadOptions<CreatorSelectData, GroupBase<CreatorSelectData>, { page: number }> = async (
    search,
    loadedOptions,
    additional,
  ) => {
    const page = additional?.page || 1;
    const data = await fetchCreatorOptions(search, page);
    const newOptions = data.data.map(
      (creator): CreatorSelectData => ({
        value: creator,
        label: creator[displayProperty],
      }),
    );

    return {
      options: newOptions,
      hasMore: loadedOptions.length + newOptions.length < data.total,
      additional: {
        page: page + 1,
      },
    };
  };

  const setInputValue = (value: Value) => {
    const event = {
      target: { name, value },
    };
    onChange(event);
    onBlur(event);
    if (userProvidedOnChange) {
      userProvidedOnChange(value);
    }
  };

  const initialOptions = useMemo(() => {
    if (showDefaultOption) {
      return [defaultOption, ...defaultOptions];
    }
    return defaultOptions;
  }, [showDefaultOption, defaultOptions]);

  return (
    <AsyncPaginate
      closeMenuOnSelect={!isMulti}
      components={components}
      debounceTimeout={300}
      defaultOptions={initialOptions}
      isMulti={isMulti}
      loadOptions={loadOptions}
      menuPlacement="auto"
      // Whether the select menu should use a portal, and where it should attach.
      menuPortalTarget={document.querySelector(MODAL_PORTAL_TARGET_SELECTOR)}
      name={name}
      onChange={setInputValue}
      placeholder={placeholder}
      value={value}
      // @ts-ignore
      width={width}
      hasErrors={hasErrors}
      hasValue={!isEmpty(value)}
      styles={styles}
      hidePlaceholder={hidePlaceholder}
      ControlPrefix={ControlPrefix}
    />
  );
};
export default AsyncCreatorSelect;

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';

import styles from 'features/accountPlatforms/facebook/components/AccountCreationForm/styles';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Flex } from 'shared/components/display';
import { Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import Modal from 'shared/components/molecules/Modal';
import { SelectInput, SelectValue } from 'shared/components/molecules/Select';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_ACCOUNT_ROUTES } from 'shared/config/routes/api/apiAccountRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useQuery } from 'shared/hooks/useQuery';
import { TEXT_VARIANTS } from 'shared/styles/text';

import { validationSchema } from './validations';

const CREATE_LABEL = 'Create';

interface FormValues {
  tiktokAdAccount: SelectValue | null;
}

const initialValues: FormValues = {
  tiktokAdAccount: null,
};

const TiktokAccountCreationForm = ({}) => {
  const { createAccountPlatform } = useAccountPlatforms();
  const dispatch = useDispatch();
  const [tiktokAccounts, setTikTokAccounts] = useState<any[]>([]);
  const { getQuery, setQuery } = useQuery();
  const { modalOpen, platform, token } = getQuery();
  const { loading } = useComponentLoading(RESOURCES.TIK_TOK);

  const [isOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (token && platform === 'tiktok')
      dispatch(
        apiAction({
          path: {
            route: API_ACCOUNT_ROUTES.TIKTOK_AD_ACCOUNTS,
          },
          params: {
            token,
          },
          entity: RESOURCES.TIK_TOK,
          onSuccess: ({ data }) => {
            setTikTokAccounts(data);
          },
        }),
      );
  }, [token, platform]);

  useEffect(() => {
    if (modalOpen && platform === 'tiktok') {
      setModalOpen(true);
    }
  }, [modalOpen, platform]);

  const onClose = () => {
    setModalOpen(false);
    setQuery({}, true);
  };

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        createAccountPlatform({ ...values, accessToken: token, platform: 'tiktok' });
        actions.setSubmitting(false);
        setModalOpen(false);
        setQuery({}, true);
      }}
    >
      {(formikProps) => (
        <Modal
          isOpen={isOpen}
          title={'Connect TikTok'}
          component={
            <Form>
              <Flex css={styles} className="account-creation-form">
                {loading && <CenteredSpinner />}
                {tiktokAccounts?.length >= 1 && (
                  <Field
                    width="275px"
                    name={'tiktokAdAccount'}
                    component={SelectInput}
                    placeholder={'Tiktok Ad Account'}
                    options={tiktokAccounts.map(({ advertiser_id, advertiser_name }) => ({
                      value: advertiser_id,
                      label: advertiser_name,
                    }))}
                  />
                )}
                {tiktokAccounts?.length < 1 && !loading && (
                  <Text variant={TEXT_VARIANTS.BODY} className="account-creation-form__message">
                    No Ad Accounts are associated with the TikTok User Account.
                  </Text>
                )}
                <Flex className={'account-creation-form__submit-button'}>
                  <FormButton type="submit">{CREATE_LABEL}</FormButton>
                </Flex>
              </Flex>
            </Form>
          }
          onRequestClose={onClose}
        />
      )}
    </Formik>
  );
};

export default TiktokAccountCreationForm;

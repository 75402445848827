import { css } from '@emotion/react';

export default css({
  '&.file-list-view': {
    overflowX: 'hidden',
  },
  '.file-list-view': {
    '&__download-all-btn': {
      padding: 0,
      marginBottom: '4px',
      display: 'block',
      marginLeft: 'auto',
    },
    '&__files': {
      listStyleType: 'none',
      padding: 0,
      margin: '4px 0',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gridColumnGap: '16px',
    },
  },
});

import React, { useEffect } from 'react';
import { Button } from 'rebass/styled-components';
import cs from 'classnames';

import MarketerProfilePictureForm from 'features/users/components/molecules/MarketerProfilePictureForm';
import { ProfilePicture } from 'features/users/components/molecules/ProfilePicture';
import MarketerInfoForm from 'features/users/components/organisms/MarketerInfoForm/MarketerInfoForm';
import { useAvatarNotification } from 'features/users/hooks/useAvatarNotification';
import { useUser } from 'features/users/hooks/useUser';
import { Box, Flex } from 'shared/components/display';
import { ToggleEditInformation } from 'shared/components/molecules/ToggleEditInformation';

import { USER_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useFileNotification } from 'shared/hooks/useFileNotification';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';
import UserInfo from './UserInfo';

const BrandUserAccountSettings: React.FC = () => {
  useAvatarNotification();
  const { currentUser } = useCurrentUser();
  const { loadingProfilePicture, loadingUpdateUser, removeProfilePicture } = useUser();
  const { fetchUser } = useUser();
  useEffect(() => {
    fetchUser(currentUser.id);
  }, []);
  useFileNotification();

  return (
    <Box
      css={styles}
      className={cs({
        'brand-user-settings': true,
        [CLASSNAMES.MAIN_CONTAINER]: true,
      })}
    >
      {/* Edit User Profile Picture */}
      <ToggleEditInformation
        loading={loadingProfilePicture}
        editButtonText={USER_COPY.BUTTON_EDIT_PROFILE_PICTURE}
        heading={USER_COPY.HEADING_PROFILE_PICTURE}
        variant={TEXT_VARIANTS.H6}
        EditingComponent={MarketerProfilePictureForm}
        EditingComponentProps={{ user: currentUser }}
      >
        <Flex className="brand-user-settings__picture">
          <ProfilePicture size="md" user={currentUser} />
          {!loadingProfilePicture && currentUser.profilePic?.url && (
            <Button
              variant={BUTTON_VARIANTS.TEXT_ONLY}
              className="brand-user-settings__delete-picture-btn"
              onClick={removeProfilePicture}
            >
              {USER_COPY.BUTTON_DELETE_PROFILE_PICTURE}
            </Button>
          )}
        </Flex>
      </ToggleEditInformation>
      {/* Edit User Info */}
      <ToggleEditInformation
        loading={loadingUpdateUser}
        variant={TEXT_VARIANTS.H4}
        editButtonText={USER_COPY.BUTTON_EDIT_USER}
        heading={USER_COPY.HEADING_USER_INFO}
        EditingComponent={MarketerInfoForm}
        EditingComponentProps={{ user: currentUser }}
      >
        <UserInfo user={currentUser} />
      </ToggleEditInformation>
    </Box>
  );
};

export default BrandUserAccountSettings;

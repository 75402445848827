import { getImageInputSchema } from 'shared/components/molecules/ImageFileInput';
import { MEDIA } from 'shared/config/media';
import { intl } from 'shared/lib/intl';
import * as yup from 'yup';

export const BRAND_LOGO = 'brandLogo';

export const accountLogoSchema = yup.object({
  [BRAND_LOGO]: getImageInputSchema(
    true,
    intl.formatMessage({ id: 'WARNING_SUPPORTED_MEDIA' }, { supportedMedia: MEDIA.TYPES.IMAGE }),
  ),
});

import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useMatch, useNavigate } from 'react-router-dom';

import { useBriefs } from 'features/briefs/hooks/useBriefs';
import CreatorIndividualBriefPage from 'features/briefs/pages/CreatorIndividualBriefPage';
import CenteredSpinner from 'features/ui/CenteredSpinner';

import { BRIEF_COPY } from 'shared/config/copy/briefCopy';
import { RESOURCES } from 'shared/config/resourceNames';
import { ALL_ROUTES } from 'shared/config/routes/generalRoutes';
import { BRIEF_TOASTS } from 'shared/config/toasts';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useQuery } from 'shared/hooks/useQuery';
import { useToasts } from 'shared/hooks/useToasts';
import { BriefStatus, BriefTypes } from 'shared/typings/briefs/enums';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

const CreatorBriefRoutes: React.FC = () => {
  const { isVetted } = useCurrentUser();
  const { getQuery } = useQuery();
  const { setWarningToast } = useToasts();
  const navigate = useNavigate();
  const match = useMatch(ALL_ROUTES.CREATORS.VIEW_INDIVIDUAL_BRIEF);
  // path: /briefs/:briefId
  // @ts-ignore
  const briefId = +match?.params.briefId;
  const { brief: storedBrief, fetchBrief, loading } = useBriefs(briefId);

  const hasIndividualBriefAccess: boolean = isVetted || storedBrief?.type === BriefTypes.SYSTEM;
  const urlParams = getQuery();

  useEffect(() => {
    if (!storedBrief) {
      fetchBrief(briefId);
    }
    if (urlParams.briefIsUnavailable && storedBrief) {
      if (storedBrief.full) {
        setWarningToast({ message: BRIEF_TOASTS.CREATOR_INVITED_FULL });
      } else if (storedBrief.status === BriefStatus.CLOSED) {
        setWarningToast({ message: BRIEF_TOASTS.CREATOR_INVITED_CLOSED });
      }
    }
  }, [urlParams, storedBrief, briefId]);

  useEffect(() => {
    if (storedBrief) {
      if (storedBrief.status !== BriefStatus.OPEN) {
        const query: any = {};
        if (storedBrief.status === BriefStatus.CLOSED) {
          query.error = BRIEF_COPY.WARNING_BRIEF_CLOSED;
        } else if (storedBrief.full === true) {
          query.error = BRIEF_COPY.LABEL_FULL_DISPLAY;
        }
        navigate(
          LinkCreator.createLink({
            userType: UserType.CREATOR,
            routeKey: 'BRIEFS_BOARD',
            query,
          }),
        );
      }
    }
  }, [storedBrief]);

  return (
    <>
      <Routes>
        {hasIndividualBriefAccess && <Route index element={<CreatorIndividualBriefPage briefId={briefId} />} />}
        <Route
          path="*"
          element={
            loading ? (
              <CenteredSpinner />
            ) : (
              <Navigate
                to="/no-access"
                state={{
                  type: `${RESOURCES.BRIEF} page`,
                }}
              />
            )
          }
        />
      </Routes>
    </>
  );
};

export default CreatorBriefRoutes;

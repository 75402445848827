import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

const width = '225px';

export default css({
  '&.trend-gallery': {
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, minmax(${width}, 1fr))`,
    gridTemplateRows: `repeat(auto, minmax(max-content, 1fr))`,
    gridAutoRows: 'max-content',
    gridColumnGap: '1rem',
    gridRowGap: '1rem',
    marginRight: theme.space[4],
    '&--loading': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  },
  '.trend-gallery': {
    '&__tile': {
      width,
    },
    '&__tile-content': {
      width,
      height: 'auto',
      borderRadius: '15px',
      overflow: 'hidden',
      position: 'relative',
      cursor: 'pointer',
    },
    '&__trend-name': {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    '&__tile-overlay': {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '&__brkfst-asset-label': {
      visibility: 'visible',
      backgroundColor: COLORS.WHITE,
      borderRadius: '5px',
      padding: `${theme.space[2]} 0`,
      alignItems: 'center',
      justifyContent: 'space-around',
      height: '30px',
    },
    '&__label-logo': {
      height: '28px',
      display: 'block',
      maxWidth: '100px',
    },
  },
});

import axiosClient from 'shared/axiosClient';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';

export const validatePhone = async (
  phoneNumber: string,
  countryCode: string,
): Promise<{ validity: boolean; formattedNumber: string }> => {
  return axiosClient
    .httpRequest<{ validity: boolean; formattedNumber: string }>({
      url: API_USER_ROUTES.VALIDATE_PHONE,
      method: 'POST',
      data: { phoneNumber, countryCode },
    })
    .then(({ data }) => {
      return data;
    });
};

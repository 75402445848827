import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '&.file-select-button': {
    visibility: 'hidden',
    border: '2px solid white',
    borderRadius: '50%',
    display: 'flex',
    opacity: '0.8',
    width: '24px',
    height: '24px',
    alignItems: 'center',
    '&--show': {
      visibility: 'visible',
    },
    '&--selected': {
      background: COLORS.PRIMARY500,
    },
    '&:not(:disabled):hover, &:not(:disabled):hover svg': {
      color: COLORS.WHITE,
    },
    '&--selected:not(:disabled):hover, &--selected:not(:disabled):hover svg': {
      background: COLORS.PRIMARY500,
    },
  },
  '.file-select-button': {
    '&__btn': {
      fontSize: '0.75rem',
      padding: '0.25rem',
    },
  },
});

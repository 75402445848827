import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import LinkSentMessage from 'features/auth/LinkSentMessage';
import { useAuth } from 'features/auth/useAuth';
import { Box, Text } from 'shared/components/display';
import WelcomeHeader from 'shared/components/molecules/WelcomeHeader/WelcomeHeader';

import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';

const { SUCCESS, ERROR } = TEXT_VARIANTS;

interface Props {
  heading: string;
  subHeading: string;
  body?: string;
  linkText: string;
  type: string;
}

const LinkConfirmationTemplate: React.FC<Props> = ({ heading, subHeading, body, linkText, type }) => {
  const { sendLinkAgain, confirmationLinkErrors, confirmationLinkSuccessMessage, clearConfirmationLinkMessages } =
    useAuth();
  const location = useLocation<{ email: string }>();

  const { email } = location?.state;

  const resendLink = () => {
    sendLinkAgain(email, type);
  };

  useEffect(() => {
    return () => {
      clearConfirmationLinkMessages();
    };
  }, []);

  return (
    <Box className={CLASSNAMES.PUBLIC_CONTAINER}>
      <WelcomeHeader heading={heading} subheading={subHeading} />

      {confirmationLinkSuccessMessage && <Text variant={SUCCESS}>{confirmationLinkSuccessMessage}</Text>}
      {confirmationLinkErrors.length ? <Text variant={ERROR}>{confirmationLinkErrors[0].message}</Text> : null}

      <LinkSentMessage email={email} body={body} linkText={linkText} linkHandler={resendLink} />
    </Box>
  );
};

export default LinkConfirmationTemplate;

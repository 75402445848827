import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { faFacebookF, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import platformSnippetStyles from 'features/accountPlatforms/facebook/components/molecules/PlatformSnippet/platformSnippetStyles';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import Skeleton from 'shared/components/atoms/Skeleton';
import { Box, Flex, Text } from 'shared/components/display';

import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import COLORS from 'shared/styles/colors';
import { CONTAINER_VARIANTS } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Platforms } from 'shared/typings/accountPlatform/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { formatCurrency } from 'shared/utilities/stringUtility';
const PlatformSnippet = ({ organizationId, accountPlatformId, accountId, loading = false }) => {
  const { accountPlatform, platformSnippet } = useAccountPlatforms({
    accountPlatformId,
    accountId,
  });

  const { loading: snippetLoading } = useComponentLoading(`SNIPPET-${accountId}`, false);

  const navigate = useNavigate();

  const onClick = () => {
    if (organizationId && accountId && accountPlatformId) {
      const linkToPlatform = LinkCreator.createLink({
        routeKey: 'PLATFORM',
        variables: {
          organizationId,
          accountId,
          accountPlatformId,
        },
      });
      navigate(linkToPlatform);
    }
  };

  const platformIcon = useMemo(() => {
    switch (accountPlatform?.platform?.name) {
      case Platforms.FACEBOOK:
        return faFacebookF;
      case Platforms.TIKTOK:
        return faTiktok;
      default:
        return null;
    }
  }, [accountPlatform?.platform?.name]);

  return (
    <Flex css={platformSnippetStyles} className="platform-snippet" onClick={onClick}>
      <Box>
        <Text variant={TEXT_VARIANTS.H4} className="endWithEllipsis endMultipleRows">
          {accountPlatform?.name}
        </Text>
      </Box>
      <Text variant={TEXT_VARIANTS.SUBHEADING_SM}>
        {snippetLoading || loading ? <Skeleton width="50px" /> : formatCurrency(platformSnippet?.total)}
      </Text>
      <Flex variant={CONTAINER_VARIANTS.FLEX_END}>
        {platformIcon && <FontAwesomeIcon color={COLORS.PRIMARY700} icon={platformIcon} />}
      </Flex>
    </Flex>
  );
};

export default PlatformSnippet;

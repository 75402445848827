import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.oauth-button': {
    fontFamily: `'Roboto', sans-serif`,
    width: '100%',
    border: `1px solid ${COLORS.NEUTRAL300}`,
    height: '50px',
    background: COLORS.WHITE,
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    boxShadow: '0px 1px 0px 1px rgba(0, 0, 0, 0.05)',
    transitionProperty: 'background-color, box-shadow',
    transitionDuration: '150ms',
    transitionTimingFunction: 'ease-in-out',
    padding: ' 8px',
    cursor: 'pointer',
    marginBottom: theme.space[4],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:focus, &:hover': {
      boxShadow: '1px 4px 5px 1px rgba(0, 0, 0, 0.1)',
    },
    '&:active': {
      backgroundColor: COLORS.NEUTRAL100,
      boxShadow: 'none',
      transitionDuration: '10ms',
    },
  },
  '.oauth-button': {
    '&__wrapper': {
      display: 'flex',
      alignItems: 'center',
      width: '210px',
    },
    '&__wrapper *:first-of-type': {
      width: '25px',
      marginRight: theme.space[6],
    },
  },
});

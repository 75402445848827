import React, { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'rebass/styled-components';
import cs from 'classnames';
import { debounce } from 'lodash';

import SelectedFileActions from 'features/media/components/molecules/SelectedFileActions';
import { SelectedFilePopover } from 'features/media/components/molecules/SelectedFilePopover';
import collectionStyles from 'features/media/components/organisms/Collection/collectionStyles';
import LoadingCollectionSkeleton from 'features/media/components/organisms/Collection/LoadingCollectionSkeleton';
import FileSearch, { FileSearchState } from 'features/media/components/organisms/FileSearch';
import EditCollectionName from 'features/media/EditCollectionName/EditCollectionName';
import { GalleryItem } from 'features/media/interfaces/gallery';
import { useCollections } from 'features/media/useCollections';
import { useFiles } from 'features/media/useFiles';
import ResponsiveDisplay from 'shared/components/atoms/ResponsiveDisplay';
import { Box, Flex } from 'shared/components/display';
import DropBox from 'shared/components/DropBox/DropBox';
import ToggleGalleryListView from 'shared/components/molecules/ToggleGalleryListView';
import MediaGalleryAndListDisplay from 'shared/components/organisms/MediaGalleryAndListDisplay';
import Pagination from 'shared/components/organisms/Pagination';

import { useFileNotification } from 'shared/hooks/useFileNotification';
import { useQuery } from 'shared/hooks/useQuery';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import { CLASSNAMES } from 'shared/styles/containers';
import { BrkfstFile, UploadOperation } from 'shared/typings/file';
import { BrkfstSearchQueryParams } from 'shared/typings/searchQueryParams';
import { CollectionValidator, MultiValidator, PlatformValidator } from 'shared/utilities/validator';

interface Props {
  CollectionControls: React.ReactElement;
  collectionId: number;
  fileParams: BrkfstSearchQueryParams;
  formatGalleryItems: (files: BrkfstFile) => GalleryItem;
  isSearchable?: boolean;
  isSelectable?: boolean;
  placeholderText?: string;
  isEditable?: boolean;
  isMarketerCollection?: boolean;
  downloadInfo?: {
    brandName: string;
    orgName: string;
    briefName?: string;
    collectionName: string;
  };
  isBriefCollection?: boolean;
  onlyImage?: boolean;
  forceViewMode?: 'Gallery' | 'List' | '';
}

const Collection: React.FC<Props> = ({
  CollectionControls = <></>,
  collectionId,
  fileParams,
  formatGalleryItems,
  isSearchable = false,
  isSelectable = false,
  placeholderText,
  isEditable,
  isMarketerCollection = false,
  downloadInfo,
  isBriefCollection = false,
  onlyImage,
  forceViewMode,
}) => {
  const { getQuery, setQuery } = useQuery();
  const query = getQuery();

  const { collection, loading, fetchCollection } = useCollections({
    collectionId,
  });
  useFileNotification();

  const {
    newFilesLoading,
    loading: loadingFiles,
    collectionFiles,
    totalFiles,
    changeSelectedCollection,
    getCollectionFilteredFiles,
    initiateMultipleFilesUpload,
    selectFiles,
    selectedFiles,
  } = useFiles();
  const dropboxRef = useRef<HTMLInputElement>(null);

  const { formatMessage } = useIntl();
  const searchPlaceholderText = formatMessage({ id: 'NO_FILES_RESULTS' });

  const selectedFileIds = useMemo(() => {
    return selectedFiles.map(({ id }) => id);
  }, [selectedFiles]);

  const showSelectedFileActions = isEditable && selectedFiles?.length > 0;

  const searchParams = useSearchParams(query, fileParams);

  const clearSelection = () => selectFiles([]);

  useEffect(() => {
    changeSelectedCollection(collectionId);
    getCollectionFilteredFiles(collectionId, searchParams);
  }, [searchParams, collectionId]);

  useEffect(() => {
    fetchCollection(collectionId);
    return clearSelection;
  }, [collectionId]);

  const onSearchChange = debounce((search: string, submitForm: (() => Promise<void>) & (() => Promise<any>)) => {
    submitForm();
  }, 500);

  const onSubmit = (values: FileSearchState) => {
    const orderByValues = JSON.parse(values.orderBy.value);
    setQuery({
      fileType: values.fileType.value,
      creatorId: values.creator.value?.id,
      author: values.creator.value?.fullName || 'all',
      name: values.search,
      page: 1,
      ...orderByValues,
    });
  };

  const handleUpload = async (files: File[]) => {
    if (files?.length > 0) {
      const assets: UploadOperation[] = files.map((file) => ({
        file,
        collectionId,
        metadata: {
          addWatermark: true,
        },
      }));

      await initiateMultipleFilesUpload(
        assets,
        new MultiValidator(
          new PlatformValidator(),
          new CollectionValidator(collectionId, collectionFiles, collection.numFiles),
        ),
      );
    }
  };

  if (loading) {
    return <LoadingCollectionSkeleton />;
  }

  return (
    <Box className="collection" css={collectionStyles}>
      <EditCollectionName
        collectionId={collectionId}
        isEditable={isEditable}
        allowCopy={isMarketerCollection && isBriefCollection}
      />

      <DropBox
        disabled={!isEditable}
        onFileDrop={handleUpload}
        className={CLASSNAMES.PADDING_CONTAINER}
        ref={dropboxRef}
      >
        <Flex className="collection__header">
          {isEditable && (
            <Button onClick={() => dropboxRef.current?.click()} className="collection__upload-btn">
              {formatMessage({ id: 'BUTTON_UPLOAD_FILES' })}
            </Button>
          )}
          {CollectionControls}
        </Flex>
        <FileSearch
          onSearchChange={onSearchChange}
          isSearchable={isSearchable}
          onSubmit={onSubmit}
          state={query}
          loading={loadingFiles}
          isBriefCollection={collection.isBriefCollection}
          collectionId={collection.id}
          isMarketerCollection={isMarketerCollection}
          searchParams={searchParams}
        />
        <Flex
          className={cs('collection__control-strip', {
            'collection__control-strip--file-selected': selectedFiles?.length > 0,
          })}
        >
          {showSelectedFileActions ? (
            <>
              <ResponsiveDisplay display="desktop">
                <SelectedFileActions
                  collectionId={collectionId}
                  selectedFileIds={selectedFileIds}
                  isMarketerCollection={isMarketerCollection}
                  downloadInfo={downloadInfo}
                />
              </ResponsiveDisplay>
              <ResponsiveDisplay display="mobile">
                <SelectedFilePopover
                  collectionId={collectionId}
                  selectedFileIds={selectedFileIds}
                  isMarketerCollection={isMarketerCollection}
                />
              </ResponsiveDisplay>
            </>
          ) : (
            <></>
          )}

          {!forceViewMode && <ToggleGalleryListView />}
        </Flex>
        <Pagination totalAmount={totalFiles} searchParams={searchParams} loading={loadingFiles}>
          <MediaGalleryAndListDisplay
            data={collectionFiles}
            isSelectable={Boolean(isSelectable && isEditable)}
            numLoadingItems={newFilesLoading}
            placeholderText={searchParams.name ? searchPlaceholderText : placeholderText}
            selectedFileIds={selectedFileIds}
            updateSelectedItems={selectFiles}
            formatGalleryItems={formatGalleryItems}
            onlyImage={onlyImage}
            forceViewMode={forceViewMode}
          />
        </Pagination>
      </DropBox>
    </Box>
  );
};

export default Collection;

import axios, { AxiosInstance, AxiosPromise } from 'axios';
import config from 'config';
import * as qs from 'qs';

import { APIConfig } from 'shared/axiosClient/interfaces';
import { LOCAL_STORAGE } from 'shared/config/storage';

class AxiosClient {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: config.API_URL,
    });

    this.axiosInstance.interceptors.request.use((config) => {
      const { addAuthHeader, addBetterGetParams } = config;
      const token = localStorage.getItem(LOCAL_STORAGE.TOKEN);

      if (token && addAuthHeader) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      if (addBetterGetParams) {
        config.paramsSerializer = (params) => {
          return qs.stringify(params);
        };
      }

      return config;
    });
  }

  public httpRequest<Data>({
    url,
    method = 'GET',
    data = {},
    params = {},
    headers = {},
    config = {
      addBetterGetParams: true,
      addAuthHeader: true,
    },
  }: {
    url: string;
    method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
    data?: any;
    params?: any;
    headers?: any;
    config?: APIConfig;
  }): AxiosPromise<Data> {
    const { addAuthHeader, addBetterGetParams } = config;
    return this.axiosInstance({
      method,
      url,
      data,
      headers,
      params,
      addAuthHeader,
      addBetterGetParams,
    });
  }
}

export default new AxiosClient();

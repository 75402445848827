import '@fortawesome/fontawesome-pro/css/solid.css';

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import themes from 'shared/styles/themes';

export default css({
  '.media-list-view': {
    '&__file': {
      position: 'relative',
      padding: '6px 4px',
      columnGap: '8px',
      alignItems: 'center',
      '&:hover': {
        cursor: 'default',
      },
    },
    '&__skeleton': {
      width: '100%',
      height: '40px',
    },
    '&__file:not(:last-child)': {
      borderBottom: `1px solid ${COLORS.NEUTRAL100}`,
    },
    '&__file:last-child': {
      marginBottom: '16px',
    },
    '&__file:hover .collection-file-elliptical': {
      visibility: 'visible',
      cursor: 'pointer',
    },
    '&__clickable-area': {
      flexGrow: 1,
      marginBottom: 0,
    },
    '&__file-thumbnail': {
      width: '40px',
      objectFit: 'contain',
    },
    '&__file-name': {
      wordBreak: 'break-all',
      flex: 1,
      marginRight: themes.space[4],
    },
    '&__placeholder': {
      textAlign: 'center',
      color: COLORS.NEUTRAL600,
      marginBottom: '16px',
    },
    '&__tags': {
      fontSize: '12px',
      maxHeight: '35px',
      wordBreak: 'break-word',
      overflow: 'hidden',
      position: 'relative',
      maxWidth: '95%',
    },
    '&__elliptical': {
      position: 'absolute',
      right: 0,
    },
    '&__no-items': {
      textAlign: 'center',
      marginBottom: '.5rem',
    },
  },
});

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import theme from 'shared/styles/themes';

export default css({
  height: '100%',
  '.brief-tabs': {
    text: 'black',
    '&__assets-wrapper': {
      margin: theme.space[5],
    },
    '&__creator-buttons': {
      bottom: '12px',
      position: 'fixed',
      zIndex: zIndex.zButton,
      width: '100%',
      justifyContent: 'center',
    },
  },
});

export const ADDRESS_COPY = {
    // Labels
    LABEL_LINE_ONE: 'Address Line 1',
    LABEL_LINE_TWO: 'Address Line 2',
    LABEL_COMPANY: 'Company Name',
    LABEL_CITY: 'City',
    LABEL_STATE: 'State',
    LABEL_POSTAL_CODE: 'Postal Code',
    LABEL_COUNTRY: 'Country',
    LABEL_PHONE: 'Phone Number',
};

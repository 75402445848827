import React, { createContext, useEffect, useState } from 'react';
import config from 'config';

import { Connection } from 'shared/contexts/signalr';
import { SignalRClient } from 'shared/signalr';

const initialState: Connection = {
  connection: null,
};
export const SignalRContext = createContext<Connection>(initialState);

interface Props {
  children: JSX.Element | Array<JSX.Element>;
  userId?: number | string;
}

const SignalRProvider: React.FC<Props> = ({ children, userId }) => {
  const [connectionState, setState] = useState<Connection>({
    connection: null,
  });

  useEffect(() => {
    if (userId) {
      const connection = SignalRClient.initialize(config.SIGNALR_CONNECTION_HUB, userId);
      setState({
        connection,
      });
    }
  }, [userId]);

  return <SignalRContext.Provider value={connectionState}>{children}</SignalRContext.Provider>;
};

export default SignalRProvider;

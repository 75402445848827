import { css } from '@emotion/react';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.creator-showcase': {
    margin: theme.space[5],
  },
  '.creator-showcase': {
    '&__back-btn': {
      marginBottom: theme.space[5],
    },
    '&__page-title': {
      marginLeft: theme.space[4],
      flexDirection: 'column',
      alignItems: 'center',
      [mediaquery.md]: {
        alignItems: 'start',
      },
    },
    '&__name': {
      fontSize: '1.5rem',
      fontWeight: 600,
      letterSpacing: 0,
    },
    '&__page-title-text': {
      marginBottom: 0,
      lineHeight: '1.2',
    },
    '&__header': {
      marginBottom: theme.space[6],
      justifyContent: 'flex-end',
      [mediaquery.md]: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: theme.space[6],
      },
    },
    '&__creator': {
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '1rem',
      [mediaquery.md]: {
        flexDirection: 'row',
      },
    },
  },
});

import React, { useState } from 'react';
import { DateRange, DayClickEventHandler, DayPicker, Matcher, SelectRangeEventHandler } from 'react-day-picker';
import { formatComparisonDateRanges, formatDateFromISO, isDayInRange } from 'shared/utilities/dateUtility';
import { isAfter, isSameDay } from 'date-fns';
import 'react-day-picker/dist/style.css';
import { modifiersStyles } from 'shared/components/molecules/CalendarDateRangeSelector/dayPickerStyles';

const today = new Date();
interface Props {
  dateRange: DateRange;
  setDateRange: (range: DateRange) => void;
  compareDateRange?: boolean;
  numberOfMonths?: number;
  defaultMonth?: Date;
  minDays?: number;
  maxDays?: number;
  allowFutureDates?: boolean;
}
const CalendarDateRangeSelector: React.FC<Props> = ({
  dateRange,
  compareDateRange,
  numberOfMonths = 1,
  defaultMonth = today,
  setDateRange,
  minDays = 1,
  maxDays = Infinity,
  allowFutureDates = false,
}) => {
  const disabledModifier: Matcher = (day) => {
    if (allowFutureDates) {
      return false;
    }
    return isAfter(day, today);
  };

  const compareModifier: Matcher = (day) => {
    if (compareDateRange) {
      const [comparedDateRange] = formatComparisonDateRanges(dateRange);
      const cFrom = formatDateFromISO(comparedDateRange.startDate);
      const cTo = formatDateFromISO(comparedDateRange.endDate);
      const isInRange = isDayInRange(day, {
        from: cFrom,
        to: cTo,
      });
      return isInRange;
    }
    return false;
  };

  const compareStart = (day) => {
    if (compareDateRange) {
      const [comparedDateRange] = formatComparisonDateRanges(dateRange);
      const cFrom = formatDateFromISO(comparedDateRange.startDate);
      return isSameDay(cFrom, day);
    }
    return false;
  };

  const compareEnd = (day) => {
    if (compareDateRange) {
      const [comparedDateRange] = formatComparisonDateRanges(dateRange);
      const cTo = formatDateFromISO(comparedDateRange.endDate);
      return isSameDay(cTo, day);
    }
    return false;
  };

  const onDayClick: DayClickEventHandler = (day) => {
    /**
     * Custom to allow both range and single days to be selected
     */
    const noDateSelected = !dateRange.from && !dateRange.to;
    const singleDaySelected = dateRange.from && dateRange.to && isSameDay(dateRange.from, dateRange.to);
    const rangeSelected = dateRange.from && dateRange.to && !singleDaySelected;

    if (noDateSelected || rangeSelected) {
      // start/restart new range
      setDateRange({ from: day, to: day });
    } else if (singleDaySelected) {
      // set range
      if (dateRange.from && isAfter(dateRange.from, day)) {
        setDateRange({ from: day, to: dateRange.to });
      } else {
        setDateRange({ from: dateRange.from, to: day });
      }
    }
  };

  return (
    <DayPicker
      mode="range"
      min={minDays}
      max={maxDays}
      selected={dateRange}
      onDayClick={onDayClick}
      numberOfMonths={numberOfMonths}
      defaultMonth={defaultMonth}
      toMonth={allowFutureDates ? undefined : today}
      modifiersStyles={modifiersStyles}
      modifiers={{
        // @ts-ignore
        start: dateRange.from,
        // @ts-ignore
        end: dateRange.to,
        compare: compareModifier,
        compareStart,
        compareEnd,
        disabled: disabledModifier,
      }}
      pagedNavigation
    />
  );
};

export default CalendarDateRangeSelector;

import { Flex, Text } from 'shared/components/display';
import dividerStyle from 'shared/components/molecules/Divider/dividerStyle';
import { noOp } from 'shared/defaults';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import React from 'react';
import { Button } from 'rebass/styled-components';
import cs from 'classnames';

const { H3 } = TEXT_VARIANTS;

interface Props {
  heading?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  buttonText?: string;
  Component?: React.ReactElement;
  variant?: string;
  disabled?: boolean;
  className?: string;
}

const Divider: React.FC<Props> = ({
  heading = '',
  onClick = noOp,
  buttonText = '',
  Component,
  variant = H3,
  disabled = false,
  className,
}) => {
  return (
    <>
      <Flex className={cs('divider', className)} css={dividerStyle}>
        <Text variant={variant} className="divider__heading">
          {heading}
        </Text>

        {buttonText && (
          <Button className="divider__btn" disabled={disabled} onClick={onClick} variant={BUTTON_VARIANTS.MICRO}>
            {buttonText}
          </Button>
        )}
        {Component && Component}
      </Flex>
      <hr />
    </>
  );
};

export default Divider;

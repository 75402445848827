import React, { useEffect, useMemo } from 'react';
import { useConfigurations } from 'features/accountPlatforms/facebook/useConfigurations';
import { Select } from 'shared/components/molecules/Select';
import { FACEBOOK_COPY } from 'shared/config/copy';
import { emptySelectOption } from 'shared/components/molecules/Select/SelectInput';
import Skeleton from 'shared/components/atoms/Skeleton';
interface Props {
  isMobile: boolean;
}

const LoadConfigurationBar: React.FC<Props> = ({ isMobile }) => {
  const { configurations, setDefaultConfiguration, defaultConfiguration, loading } = useConfigurations();

  const onRequestLoadConfig = (selection) => {
    const configName = selection.value;
    const configValue = configurations.find((configObj) => {
      return configObj.name === configName;
    });

    setDefaultConfiguration(configValue);
  };

  const options = useMemo(() => {
    return [
      emptySelectOption,
      ...configurations.map((configObj) => ({
        label: configObj.name,
        value: configObj.name,
      })),
    ];
  }, [configurations]);

  const value = useMemo(() => {
    if (!defaultConfiguration?.name) return emptySelectOption;
    return {
      label: defaultConfiguration.name,
      value: defaultConfiguration.name,
    };
  }, [defaultConfiguration]);

  useEffect(() => {
    return () => {
      setDefaultConfiguration(null);
    };
  }, []);

  return loading ? (
    <Skeleton height="40px" />
  ) : (
    <Select
      value={value}
      name="Pick Configuration"
      options={options}
      placeholder={FACEBOOK_COPY.PLACEHOLDER_CONFIG}
      onChange={onRequestLoadConfig}
      width={isMobile ? '100%' : '400px'}
    />
  );
};

export default LoadConfigurationBar;

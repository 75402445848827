import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  checkoutInvoiceAdded,
  checkoutInvoiceReset,
  getCheckoutInvoice,
  getFirstInvoiceId,
  getInvoiceHasMore,
  getLastInvoiceId,
  getPastInvoices,
  getUpcomingInvoiceAmountDue,
  getUpcomingInvoicePaymentDate,
  getUpcomingInvoicePayPeriod,
  invoicesLoaded,
} from 'features/organizations/invoices.slice';
import { componentLoadingToggled, errorCleared, getErrors } from 'features/ui/ui.slice';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_ACCOUNT_ROUTES } from 'shared/config/routes/api/apiAccountRoutes';
import { API_BRIEF_ROUTES } from 'shared/config/routes/api/apiBriefRoutes';
import { API_ORG_ROUTES } from 'shared/config/routes/api/apiOrgRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { LineItemType } from 'shared/typings/lineItem/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

interface Params {
  organizationId?: number;
  accountId?: number;
  shouldFetch?: boolean;
}

export const useInvoice = ({ organizationId, accountId, shouldFetch = false }: Params) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const invoices = useSelector(getPastInvoices);
  const hasMore = useSelector(getInvoiceHasMore);
  const upcomingAmountDue = useSelector(getUpcomingInvoiceAmountDue);
  const upcomingPaymentDate = useSelector(getUpcomingInvoicePaymentDate);
  const upcomingPayPeriod = useSelector(getUpcomingInvoicePayPeriod);
  const firstInvoiceId = useSelector(getFirstInvoiceId);
  const lastInvoiceId = useSelector(getLastInvoiceId);
  const checkoutInvoice = useSelector(getCheckoutInvoice);
  const [promoCodeError] = useSelector(getErrors(RESOURCES.PROMOTION_CODE));
  const { loading: loadingPromoCode } = useComponentLoading(RESOURCES.PROMOTION_CODE, false);
  const { loading } = useComponentLoading(RESOURCES.INVOICE);

  const checkoutInvoiceHasPromoCode = useMemo(() => {
    if (checkoutInvoice.lineItems) {
      return checkoutInvoice.lineItems.some(({ type }) => type === LineItemType.DISCOUNT);
    }
    return false;
  }, [checkoutInvoice.lineItems]);

  useEffect(() => {
    if (shouldFetch && organizationId) {
      dispatch(
        apiAction({
          path: {
            route: API_ORG_ROUTES.INVOICES,
            variables: {
              organizationId,
            },
          },
          method: 'GET',
          successAction: invoicesLoaded,
          entity: RESOURCES.INVOICE,
          params: { limit: 10, status: 'paid' },
        }),
      );
    }
  }, [shouldFetch, organizationId]);

  const fetchPaginatedInvoices = (type) => {
    const stripeParams: any = { limit: 10, status: 'paid' };
    if (type === 'next') {
      stripeParams.starting_after = lastInvoiceId;
    } else {
      stripeParams.ending_before = firstInvoiceId;
    }
    dispatch(
      apiAction({
        path: {
          route: API_ORG_ROUTES.INVOICES,
          variables: {
            ...(organizationId !== undefined ? { organizationId } : {}),
          },
        },
        method: 'GET',
        entity: RESOURCES.INVOICE,
        successAction: invoicesLoaded,
        params: stripeParams,
      }),
    );
  };

  const redirectToBrief = (briefId) => {
    navigate(
      LinkCreator.createLink({
        routeKey: 'VIEW_INDIVIDUAL_BRIEF',
        variables: {
          accountId,
          organizationId,
          briefId,
        },
      }),
    );
  };

  const createBriefApprovalInvoice = ({ accountId, briefId, creatorId }) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.APPROVE_CREATOR_INVOICE,
          variables: {
            briefId,
            creatorId,
          },
        },
        data: { accountId },
        method: 'POST',
        successAction: checkoutInvoiceAdded,
        entity: RESOURCES.INVOICE,
        onError: () => {
          navigate(
            LinkCreator.createLink({
              routeKey: 'VIEW_INDIVIDUAL_BRIEF',
              variables: {
                accountId,
                organizationId,
                briefId,
              },
            }),
          );
          redirectToBrief(briefId);
        },
      }),
    );
  };

  const createBriefInviteApprovalInvoice = ({ accountId, briefId, creatorEmails }) => {
    dispatch(
      apiAction({
        path: {
          route: API_BRIEF_ROUTES.APPROVE_CREATOR_INVOICE_INVITE,
          variables: {
            briefId,
          },
        },
        data: { accountId, creatorEmails },
        method: 'POST',
        successAction: checkoutInvoiceAdded,
        entity: RESOURCES.INVOICE,
        onError: () => {
          redirectToBrief(briefId);
        },
      }),
    );
  };

  const setInvoiceLoading = (newState) => {
    dispatch(
      componentLoadingToggled({
        component: RESOURCES.INVOICE,
        loading: newState,
      }),
    );
  };

  const clearCheckoutInvoice = () => {
    dispatch(checkoutInvoiceReset());
  };

  const clearPromoCodeError = () => {
    dispatch(errorCleared({ entity: RESOURCES.PROMOTION_CODE }));
  };

  const applyPromotionCode = ({ code, onSuccess, onError }) => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.PROMOTION_CODE,
          variables: {
            accountId,
          },
        },
        method: 'POST',
        entity: RESOURCES.PROMOTION_CODE,
        successAction: checkoutInvoiceAdded,
        data: {
          code,
          paymentIntentId: checkoutInvoice.id,
          ...(organizationId !== undefined ? { organizationId } : {}),
        },
        hideError: true,
        onSuccess,
        onError,
      }),
    );
  };

  const removePromoCode = () => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.PROMOTION_CODE,
          variables: {
            accountId,
          },
        },
        method: 'DELETE',
        entity: RESOURCES.PROMOTION_CODE,
        successAction: checkoutInvoiceAdded,
        data: {
          paymentIntentId: checkoutInvoice.id,
        },
      }),
    );
  };

  return {
    upcomingAmountDue,
    upcomingPaymentDate,
    upcomingPayPeriod,
    hasMore,
    invoices,
    fetchPaginatedInvoices,
    loading,
    checkoutInvoice,
    createBriefApprovalInvoice,
    createBriefInviteApprovalInvoice,
    setInvoiceLoading,
    clearCheckoutInvoice,
    loadingPromoCode,
    promoCodeError,
    applyPromotionCode,
    removePromoCode,
    checkoutInvoiceHasPromoCode,
    clearPromoCodeError,
  };
};

import React from 'react';

import { Box } from 'shared/components/display';
import ActionButton from 'shared/components/molecules/ActionButton';

import styles from './styles';

interface Props {
  onSubmit: React.MouseEventHandler<HTMLButtonElement>;
  onSubmitText: string;
  submitInProcess: boolean;
  className?: string;
}

const ConfirmationSummary: React.FC<Props> = ({ onSubmit, onSubmitText, submitInProcess }) => {
  return (
    <Box css={styles} className="confirmation-summary">
      <ActionButton onClick={onSubmit} loading={submitInProcess} className="confirmation-summary__submit-btn">
        {onSubmitText}
      </ActionButton>
    </Box>
  );
};

export default ConfirmationSummary;

import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { AccountPlatformHealth } from './types';

interface AccountPlatformHealthState {
  platformHealth: AccountPlatformHealth;
}

type AccountPlatformHealthAction<T = AccountPlatformHealth> = PayloadAction<{ data: T }>;

export const initialAccountPlatformHealthState = Object.freeze({
  platformHealth: {} as AccountPlatformHealth,
});

export const accountPlatformHealthSlice = createSlice<
  AccountPlatformHealthState,
  SliceCaseReducers<AccountPlatformHealthState>
>({
  name: 'accountPlatformHealth',
  initialState: initialAccountPlatformHealthState,
  reducers: {
    accountPlatformHealthReset: () => {
      return initialAccountPlatformHealthState;
    },
    accountPlatformHealthLoaded: (state, action: AccountPlatformHealthAction<AccountPlatformHealth>) => {
      state.platformHealth = action.payload.data;
    },
  },
});

export const { accountPlatformHealthLoaded, accountPlatformHealthReset } = accountPlatformHealthSlice.actions;

interface State {
  accountPlatformHealth: AccountPlatformHealthState;
}

export const getAccountPlatformHealthData = (state: State) => state.accountPlatformHealth.platformHealth;

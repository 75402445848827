import React from 'react';
import { Button } from 'rebass/styled-components';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import { BRIEF_COPY } from 'shared/config/copy';
import { Brief } from 'shared/typings/briefs';

interface Props {
  isDraft?: boolean;
  brief: Brief;
  variant?: string;
  onClick?: () => void;
}
const DuplicateBriefButton: React.FC<Props> = ({ isDraft, brief, variant, onClick }) => {
  const { duplicateBrief } = useBriefs();

  const handleClick = () => {
    duplicateBrief(brief);
    if (onClick) onClick();
  };

  return (
    <Button onClick={handleClick} variant={variant}>
      {isDraft ? BRIEF_COPY.BUTTON_DUPLICATE_DRAFT : BRIEF_COPY.BUTTON_DUPLICATE}
    </Button>
  );
};

export default DuplicateBriefButton;

import React, { Fragment, useMemo } from 'react';
import { Box, Text } from 'shared/components/display';
import { get, has } from 'lodash';
import { arrayIncludesString } from 'shared/utilities/stringUtility';
import COLORS from 'shared/styles/colors';
import { formatColumnObject } from 'shared/utilities/Performance/dashboardUtility';
import { PERFORMANCE_DATA_TYPES } from 'shared/config/performance';
import { TEXT_VARIANTS } from 'shared/styles/text';
import DashboardColumn from 'shared/models/DashboardColumnModel';

const { TOP_BOTTOM_ANALYSIS, COMPARISON } = PERFORMANCE_DATA_TYPES;
const { SUBHEADING_SM, CAPTION } = TEXT_VARIANTS;
const { GREEN300, RED200, NEUTRAL500 } = COLORS;
const { CHANGE_FIELDS, OMITTED_SUMMARY_FIELDS } = DashboardColumn;

const PerformanceTableFooter = ({ type = 'Default', column, summary }) => {
  if (type === TOP_BOTTOM_ANALYSIS) {
    if (!has(summary.top, column) && !has(summary.bottom, column)) return null;
  } else if (!has(summary, column) || OMITTED_SUMMARY_FIELDS.includes(column)) return null;

  const highlightColor = useMemo(() => {
    if (type === COMPARISON && arrayIncludesString(CHANGE_FIELDS, column)) {
      const changeValueField = DashboardColumn.getChangeValueField(column);
      if (parseFloat(get(summary, changeValueField)) < 0) {
        return RED200;
      }
      return GREEN300;
    }
  }, [type, column, summary]);

  return (
    <Box>
      {type === TOP_BOTTOM_ANALYSIS ? (
        <Fragment>
          <Text variant={SUBHEADING_SM}>Top: {formatColumnObject(column, summary.top)}</Text>
          <Text variant={SUBHEADING_SM}>
            Bottom:
            {formatColumnObject(column, summary.bottom)}
          </Text>
        </Fragment>
      ) : (
        <Text color={highlightColor} variant={SUBHEADING_SM}>
          {formatColumnObject(column, summary)}
        </Text>
      )}

      <Text color={NEUTRAL500} variant={CAPTION}>
        Total
      </Text>
    </Box>
  );
};
export default PerformanceTableFooter;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.filter-form-dropdown': {
    border: `1px solid ${COLORS.NEUTRAL200}`,
    background: COLORS.WHITE,
    width: '100%',
  },
  '.filter-form-dropdown': {
    '&__clear-btn': {
      color: COLORS.NEUTRAL600,
      width: '10rem',
      marginLeft: 'auto',
      display: 'inherit',
    },
    '&__clear-btn--hide': {
      display: 'none',
    },
    '&__form-wrapper': {
      padding: theme.space[6],
      width: '24rem',
    },
    '&__active-filter': {
      color: COLORS.BLACK,
      marginLeft: theme.space[4],
      border: `1px solid ${COLORS.NEUTRAL100}`,
      // borderRadius: [2],
      bg: COLORS.WHITE,
      alignItems: 'baseline',
      padding: `0 ${theme.space[2]}`,
    },
    '&__active-filter__value': {
      marginLeft: theme.space[1],
    },
    '&__active-filter__icon': {
      marginLeft: theme.space[4],
    },
    '&__placeholder': {
      marginLeft: theme.space[2],
    },
  },
});

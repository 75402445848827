import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hamburgerMenuIsOpen, toggleHamburgerMenu } from 'features/ui/ui.slice';
import cs from 'classnames';
import styles from './styles';
import { Box } from 'shared/components/display';

interface Props {
  hasNotification?: boolean;
}

const HamburgerMenu: React.FC<Props> = ({ hasNotification = false }) => {
  const isOpen = useSelector(hamburgerMenuIsOpen);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(toggleHamburgerMenu());
  };

  return (
    <Box
      css={styles}
      onClick={toggleMenu}
      className={cs('hamburger-menu', {
        'hamburger-menu--has-notification': hasNotification,
        'hamburger-menu--open': isOpen,
      })}
    >
      <Box as="span" className={cs('hamburger-menu__icon', { 'hamburger-menu__icon--open': isOpen })} />
    </Box>
  );
};

export default HamburgerMenu;

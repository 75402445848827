// * Lib
import React from 'react';
import { Button } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import cs from 'classnames';
import ReactModal from 'react-modal';
import { useMediaQuery } from 'react-responsive';

// * Util
import { useRightDrawer } from 'features/ui/useRightDrawer';

// * Display/UI
import { Flex, Box, Text } from 'shared/components/display';

// * Styles
import styles from './styles';
import { breakpoints } from 'shared/styles/styleFunctions';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';

const RightDrawer: React.FC = () => {
  ReactModal.setAppElement('#root');

  const {
    isRightDrawerOpen: isOpen,
    toggleRightDrawer,
    Content,
    Header,
    headerText,
    onRequestClose,
    mountRightDrawer,
  } = useRightDrawer();

  const isMobile = useMediaQuery({ maxWidth: breakpoints.md - 1 });

  const onClose = () => {
    toggleRightDrawer();
    if (onRequestClose) onRequestClose();
  };

  const renderDrawer = () => {
    return (
      <Flex css={styles}>
        <aside
          className={cs({
            'right-drawer': true,
            'right-drawer--open': isOpen,
            'right-drawer--closed': !isOpen,
          })}
        >
          <Flex
            className={cs({
              'right-drawer__header': true,
              'right-drawer__header--open': isOpen,
              'right-drawer__header--closed': !isOpen,
            })}
          >
            {headerText ? (
              <Text as="h2" variant={TEXT_VARIANTS.H4} className="right-drawer__header-text">
                {headerText}
              </Text>
            ) : (
              Header
            )}
            <Button
              className="right-drawer__close-btn"
              variant={BUTTON_VARIANTS.TEXT_ONLY}
              onClick={onClose}
              aria-labelledby="close-right-drawer"
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </Flex>
          <Box
            className={cs({
              'right-drawer__content': true,
              'right-drawer__content--open': isOpen,
              'right-drawer__content--closed': !isOpen,
            })}
          >
            {Content}
          </Box>
        </aside>
        <Box
          className={cs({
            'right-drawer__overlay': isOpen,
          })}
          onClick={onClose}
        />
      </Flex>
    );
  };

  return mountRightDrawer ? (
    isMobile ? (
      <ReactModal
        isOpen={isOpen}
        id="right-drawer-root"
        className="right-drawer"
        overlayClassName="right-drawer__overlay"
      >
        {renderDrawer()}
      </ReactModal>
    ) : (
      renderDrawer()
    )
  ) : null;
};

export default RightDrawer;

import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

import { Box } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import ImageFileInput, { getImageInputSchema } from 'shared/components/molecules/ImageFileInput';

import { ACCOUNT_COPY } from 'shared/config/copy';
import { formatFileTypes } from 'shared/config/fileFormats';
import { MEDIA } from 'shared/config/media';
import { intl } from 'shared/lib/intl';

import styles from './styles';

interface Props {
  toggleEdit: () => void;
  onSubmit: (values: any) => void;
  formDisabled?: boolean;
}
// restricted by Brevo limitations, see https://help.brevo.com/hc/en-us/articles/11098615382802-Add-an-attachment-to-an-email-campaign#:~:text=Allowed%20extensions%20for%20attachment%20files,%2C%20ppt%2C%20pptx%2C%20eps.
export const ACCEPTABLE_BANNER_FILE_TYPES = [MEDIA.MIME_TYPES.PNG, MEDIA.MIME_TYPES.JPEG, MEDIA.MIME_TYPES.GIF];

const BannerLogoForm: React.FC<Props> = ({ toggleEdit, onSubmit, formDisabled = false }) => {
  const submitAndToggleEdit = (data) => {
    onSubmit(data);
    toggleEdit();
  };

  return (
    <Formik
      initialValues={{
        bannerLogo: {
          file: {
            name: '',
            type: '',
            size: 0,
          },
        },
      }}
      onSubmit={submitAndToggleEdit}
      validationSchema={yup.object({
        bannerLogo: getImageInputSchema(
          true,
          intl.formatMessage(
            { id: 'WARNING_SUPPORTED_MEDIA' },
            { supportedMedia: formatFileTypes(ACCEPTABLE_BANNER_FILE_TYPES) },
          ),
          ACCEPTABLE_BANNER_FILE_TYPES,
        ),
      })}
      validateOnBlur={false}
    >
      {() => (
        <Form>
          <Box css={styles} className="banner-logo-form">
            <Field
              name={'bannerLogo'}
              component={ImageFileInput}
              placeholder={`Upload Image (${formatFileTypes(ACCEPTABLE_BANNER_FILE_TYPES)})`}
              acceptedFormats={ACCEPTABLE_BANNER_FILE_TYPES}
            />
            <FormButton className="banner-logo-form__submit-btn" disabled={formDisabled}>
              {ACCOUNT_COPY.BUTTON_UPLOAD_BANNER}
            </FormButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default BannerLogoForm;

import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import cs from 'classnames';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { startCase } from 'lodash';

import {
  getSignUpSchema,
  SIGN_UP_FIELDNAMES,
  SIGN_UP_FORM_NAME,
  SignUpValues,
} from 'features/auth/components/organisms/SignUpForm/config';
import signUpFormStyles from 'features/auth/components/organisms/SignUpForm/signUpFormStyles';
import { useAuth } from 'features/auth/useAuth';
import { Box, Flex, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import { PasswordInput, PasswordValidationInput } from 'shared/components/inputs/PasswordInput';
import TextInput from 'shared/components/TextInput';

import { AUTH_COPY } from 'shared/config/copy';
import e2eFlows from 'shared/config/e2eFlows';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { UserType } from 'shared/typings/user/enums';
interface Props {
  token?: string;
  userType?: UserType;
  invitationEmail?: string;
}

const SignUpForm: React.FC<Props> = ({ token = '', userType, invitationEmail }) => {
  const { signUpInvitedUser, register, loading } = useAuth();
  // If there's a token then user was invited
  const invited = Boolean(invitationEmail);
  const hasToken = Boolean(token);
  const onSubmit = (formData: SignUpValues, formikHelpers: FormikHelpers<SignUpValues>) => {
    const data: any = {
      ...formData,
      email: formData.email.trim(),
      password: formData.password.password,
    };

    const onError = () => {
      formikHelpers.setSubmitting(false);
    };
    if (userType) {
      data.type = userType;
    }
    if (hasToken) {
      signUpInvitedUser(token, data, onError);
    } else {
      register(data, onError);
    }
  };

  const initialValues: SignUpValues = useMemo(() => {
    return {
      [SIGN_UP_FIELDNAMES.EMAIL]: invitationEmail || '',
      [SIGN_UP_FIELDNAMES.FIRST_NAME]: '',
      [SIGN_UP_FIELDNAMES.LAST_NAME]: '',
      [SIGN_UP_FIELDNAMES.TERMS]: true,
      [SIGN_UP_FIELDNAMES.CONFIRM_PASSWORD]: '',
      [SIGN_UP_FIELDNAMES.PASSWORD]: {
        password: '',
        score: 0,
        message: '',
      },
    };
  }, [invitationEmail]);

  return (
    <Formik<SignUpValues>
      onSubmit={onSubmit}
      validationSchema={getSignUpSchema(invited)}
      validateOnBlur={false}
      initialValues={initialValues}
      enableReinitialize
    >
      {(formikProps) => (
        <Box className="sign-up" css={signUpFormStyles}>
          <Form data-cy={`${e2eFlows.SIGN_UP}${SIGN_UP_FORM_NAME}`}>
            <Flex className="sign-up__name-wrapper">
              <Field
                name={SIGN_UP_FIELDNAMES.FIRST_NAME}
                component={TextInput}
                placeholder={`${startCase(SIGN_UP_FIELDNAMES.FIRST_NAME)}*`}
                label={`${startCase(SIGN_UP_FIELDNAMES.FIRST_NAME)}*`}
                className={cs('sign-up__name-input', 'sign-up__first-name')}
                dataCy={`${e2eFlows.SIGN_UP}${SIGN_UP_FIELDNAMES.FIRST_NAME}`}
              />
              <Field
                name={SIGN_UP_FIELDNAMES.LAST_NAME}
                component={TextInput}
                placeholder={`${startCase(SIGN_UP_FIELDNAMES.LAST_NAME)}*`}
                label={`${startCase(SIGN_UP_FIELDNAMES.LAST_NAME)}*`}
                className="sign-up__name-input"
                dataCy={`${e2eFlows.SIGN_UP}${SIGN_UP_FIELDNAMES.LAST_NAME}`}
              />
            </Flex>
            <Field
              name={SIGN_UP_FIELDNAMES.EMAIL}
              component={TextInput}
              placeholder={`${startCase(SIGN_UP_FIELDNAMES.EMAIL)}*`}
              label={`${startCase(SIGN_UP_FIELDNAMES.EMAIL)}*`}
              disabled={invited}
              dataCy={`${e2eFlows.SIGN_UP}${SIGN_UP_FIELDNAMES.EMAIL}`}
              className="sign-up__email-input"
            />
            <Field
              name={SIGN_UP_FIELDNAMES.PASSWORD}
              component={PasswordValidationInput}
              placeholder={`${startCase(SIGN_UP_FIELDNAMES.PASSWORD)}*`}
              label={`${startCase(SIGN_UP_FIELDNAMES.PASSWORD)}*`}
              dataCy={`${e2eFlows.SIGN_UP}${SIGN_UP_FIELDNAMES.PASSWORD}`}
            />
            <Field
              name={SIGN_UP_FIELDNAMES.CONFIRM_PASSWORD}
              component={PasswordInput}
              placeholder={`${startCase(SIGN_UP_FIELDNAMES.CONFIRM_PASSWORD)}*`}
              label={`${startCase(SIGN_UP_FIELDNAMES.CONFIRM_PASSWORD)}*`}
              dataCy={`${e2eFlows.SIGN_UP}${SIGN_UP_FIELDNAMES.CONFIRM_PASSWORD}`}
            />
            <Text variant={TEXT_VARIANTS.BODY_SM} className="sign-up__footnote">
              <FormattedMessage
                id="FOOTNOTE_SIGN_UP"
                values={{
                  bold: (chunks) => <span className="sign-up__footnote--bold">{chunks}</span>,
                }}
              />
            </Text>
            <FormButton loading={loading} loaderColor={COLORS.PRIMARY100} dataCy={`${e2eFlows.SIGN_UP}signUpSubmitBtn`}>
              {AUTH_COPY.BUTTON_SUBMIT_AUTH}
            </FormButton>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default SignUpForm;

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.address': {
    marginRight: theme.space[4],
    wordBreak: 'break-word',
  },
  '.address': {
    '&__line': {
      marginBottom: 0,
    },
    '&__line--inline': {
      display: 'inline',
    },
    '&__line--inline:after': {
      content: '", "',
    },
    '&__line--inline:last-child:after': {
      content: '""',
    },
    '&--hide': {
      display: 'none',
    },
  },
});

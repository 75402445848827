import React from 'react';
import { Outlet } from 'react-router-dom';

import { SignalRProvider } from 'shared/contexts/signalr';

interface Props {
  children?: any;
  userId: number;
}

const SignalRRouteWrapper: React.FC<Props> = ({ children = <></>, userId }) => {
  return (
    <SignalRProvider userId={userId}>
      {children}
      <Outlet />
    </SignalRProvider>
  );
};

export default SignalRRouteWrapper;

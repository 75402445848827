import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Text } from 'shared/components/display';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import cs from 'classnames';
import React from 'react';
import getStyles from './styles';

const { NEUTRAL100, NEUTRAL200, BLACK } = COLORS;
const { LABEL } = TEXT_VARIANTS;

interface Props {
  label?: string;
  color?: string;
  bgColor?: string;
  iconColor?: string;
  outline?: boolean;
  onClick?: () => void;
  icon?: any;
  square?: boolean;
  className?: string;
  width?: string;
  dataCy?: string;
}

const Chip: React.FC<Props> = ({
  label = '',
  color = BLACK,
  bgColor = NEUTRAL100,
  iconColor = NEUTRAL200,
  outline = false,
  onClick,
  icon = null,
  square = false,
  className = '',
  width,
  dataCy,
}) => {
  return (
    <Box
      css={getStyles(width)}
      color={color}
      background={bgColor}
      onClick={onClick}
      border={outline ? `2px solid ${bgColor}` : 'initial'}
      className={cs({
        [className]: true,
        ['chip']: true,
        'chip--with-icon': !!icon,
        'chip--clickable': !!onClick,
        'chip--square': square,
      })}
    >
      {icon && (
        <Box
          background={iconColor}
          className={cs({ 'chip__icon-wrapper': true, 'chip__icon-wrapper--with-icon': !!icon })}
        >
          {/* @ts-ignore */}
          <FontAwesomeIcon icon={icon} />
        </Box>
      )}
      <Text as="div" variant={LABEL} data-cy={dataCy}>
        {label}
      </Text>
    </Box>
  );
};

export default Chip;

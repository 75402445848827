import React from 'react';
import cs from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { Box } from 'shared/components/display';
import { closeHamburgerMenu, hamburgerMenuIsOpen } from 'features/ui/ui.slice';
import { breakpoints } from 'shared/styles/styleFunctions';
import styles, { mainLinkStyles, secondaryLinkStyles } from './styles';

interface Props {
  children?:
    | React.ReactElement<NavLinksSubComponentProps, NavLinksSubComponent>
    | React.ReactElement<NavLinksSubComponentProps, NavLinksSubComponent>[];
}

interface NavLinksSubComponentProps {
  children: React.ReactElement<any> | React.ReactElement<any>[];
  closeMenu?: () => void;
}

type NavLinksSubComponent = React.FC<NavLinksSubComponentProps>;

const NavLinks: React.FC<Props> & { Main: NavLinksSubComponent; Secondary: NavLinksSubComponent } = ({ children }) => {
  const menuOpen = useSelector(hamburgerMenuIsOpen);
  const dispatch = useDispatch();

  const closeMenu = () => {
    dispatch(closeHamburgerMenu());
  };

  // close menu whenever screen width goes from sm to md to prevent
  // the menu from being automatically opened the next time they go back to smaller width
  useMediaQuery({ minWidth: breakpoints.md }, undefined, (isLargerWidth) => {
    if (isLargerWidth) {
      closeMenu();
    }
  });

  return (
    <Box
      css={styles}
      className={cs('nav-links', {
        'nav-links--open': menuOpen,
      })}
      role="navigation"
    >
      <Box className="nav-links__wrapper">
        {React.Children.map(children, (child) => {
          return React.cloneElement(child as React.ReactElement, {
            closeMenu,
          });
        })}
      </Box>
    </Box>
  );
};

const MainLinks: NavLinksSubComponent = ({ children, closeMenu }) => {
  return (
    <Box css={mainLinkStyles} className="nav-links__main">
      {React.Children.map(children, (Child: React.ReactElement<any>) => {
        return React.cloneElement(Child, {
          onClick: (...args) => {
            if (Child.props.onClick) Child.props.onClick(...args);
            if (closeMenu) closeMenu();
          },
        });
      })}
    </Box>
  );
};

const SecondaryLinks: NavLinksSubComponent = ({ children, closeMenu }) => {
  return (
    <Box css={secondaryLinkStyles} className="nav-links__secondary">
      {React.Children.map(children, (Child: React.ReactElement<any>) => {
        return React.cloneElement(Child, {
          onClick: (...args) => {
            if (Child.props.onClick) Child.props.onClick(...args);
            if (closeMenu) closeMenu();
          },
        });
      })}
    </Box>
  );
};

NavLinks.Main = MainLinks;
NavLinks.Secondary = SecondaryLinks;

export default NavLinks;

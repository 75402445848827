import { SelectValueContainer, valueContainerStyles } from 'shared/components/atoms/SelectValueContainer';
import { SelectControl, controlStyles } from 'shared/components/atoms/SelectControl';
import { multiValueStyles } from 'shared/components/atoms/SelectMultiValueContainer';
import { CollectionSelectData } from './types';
import * as yup from 'yup';
import { MESSAGES } from 'shared/config/validations';

export const defaultOption: CollectionSelectData = {
  label: 'Select a collection',
  value: {
    id: 0,
    name: '',
  },
  isDisabled: true,
};

export const components = {
  ValueContainer: SelectValueContainer,
  Control: SelectControl,
};

export const styles = {
  ...valueContainerStyles,
  ...multiValueStyles,
  ...controlStyles,
};

export const getCollectionSelectInputSchema = (required?: boolean) => {
  const baseSchema = yup.object({
    label: yup.string(),
    value: yup.object({
      id: yup.number(),
      name: yup.string(),
    }),
  });
  if (required) {
    return baseSchema.test({
      name: 'required',
      message: MESSAGES.REQUIRED_FIELD,
      test(value) {
        return Boolean(value?.value?.id);
      },
    });
  }
  return baseSchema;
};

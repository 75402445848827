import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.brief-creators': {
    backgroundColor: 'white',
  },
  '.brief-creators': {
    '&__header': {
      borderBottom: `1px solid ${COLORS.NEUTRAL100}`,
    },
    '&__creator-info': {
      padding: theme.space[4],
    },
    '&__cell-wrapper': {
      alignItems: 'center',
      height: '100%',
    },
    '&__badge-cell-wrapper': {
      alignItems: 'center',
      height: '100%',
      justifyContent: 'end',
    },
    '&__badge': {
      paddingLeft: theme.space[2],
    },
    '&__star-icon': {
      paddingTop: '.15rem',
    },
    '&__tooltip-text': {
      marginBottom: theme.space[2],
    },
  },
});

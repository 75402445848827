// * Lib
import React from 'react';
import { useParams } from 'react-router-dom';
import { Text } from 'rebass/styled-components';

import EditAudience from 'features/accountPlatforms/facebook/EditAudience/EditAudience';
import FacebookPermissions from 'features/accountPlatforms/facebook/Permissions/Permissions';
import { useAudienceFileNotification } from 'features/accountPlatforms/facebook/useAudienceFileNotification';
// * Util
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
//* Display/UI
import { Flex } from 'shared/components/display';

import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { AccountPlatformStatus } from 'shared/typings/accountPlatform/enums';

// * Styles
import styles from './styles';

const FACEBOOK_PRIVACY_URL = 'https://www.facebook.com/business/help/112061095610075?id=2469097953376494';

const FacebookRequirements: React.FC = () => {
  const params = useParams<any>();
  const accountId = +params.accountId;
  const accountPlatformId = +params.accountPlatformId;
  const organizationId = +params.organizationId;
  useAudienceFileNotification();

  const { platformsLoading, accountPlatform } = useAccountPlatforms({
    accountId,
    accountPlatformId,
    organizationId,
  });

  const facebookConnected = AccountPlatformStatus.ACTIVE === +accountPlatform?.status;

  return platformsLoading ? (
    <CenteredSpinnerContainer />
  ) : (
    <Flex css={styles} className="facebook-requirements">
      <Text variant={TEXT_VARIANTS.H4}>{ACCOUNT_PLATFORM_COPY.HEADING_FACEBOOK_REQUIREMENTS}</Text>
      <Flex className={`facebook-requirements__section ${CLASSNAMES.PADDING_CONTAINER}`}>
        <FacebookPermissions />
      </Flex>
      {facebookConnected && (
        <Flex className={`facebook-requirements__section ${CLASSNAMES.PADDING_CONTAINER}`}>
          <EditAudience />
        </Flex>
      )}
    </Flex>
  );
};

export default FacebookRequirements;

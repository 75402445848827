export const ROLE_COPY = {
    // Columns
    COLUMN_NAME: 'Name',
    COLUMN_DESCRIPTION: 'Description',

    // Buttons
    BUTTON_CREATE_ROLE: 'Create New Role',
    BUTTON_EDIT_ROLE: 'Edit Role',
    BUTTON_DELETE_ROLE: 'Delete Role',

    // Headings
    HEADING_USER_ROLES: `User Roles`,

    FORM_HEADING_NEW_ROLE: 'New Role',
    FORM_HEADING_EDIT_ROLE: 'Edit Role',
    FORM_HEADING_REASSIGN_ROLE: 'Reassign Roles',
    FORM_LABEL_ROLE_NAME: 'Name',
    FORM_LABEL_ROLE_DESCRIPTION: 'Description',
    FORM_LABEL_ROLE_PERMISSIONS: 'Permissions',

    MODAL_DELETE_ROLE: `Are you sure you want to delete this role?`,
};

import React from 'react';
import { useDispatch } from 'react-redux';

import { userChanged } from 'features/users/hooks/user.slice';
import { apiAction } from 'shared/actions/api';
import { CREATOR_COPY } from 'shared/config/copy';
import { BrkfstUser } from 'shared/typings/user';
import { Button } from 'shared/components/display';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';

interface Props {
  user: BrkfstUser;
  className?: string;
  onClick?: () => void;
}

const EliteCreatorButton: React.FC<Props> = ({ user, className, onClick }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    const { isElite } = user;
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.ELITE_USER,
          variables: { userId: user.id },
        },
        method: 'PATCH',
        data: {
          isElite: !isElite,
        },
        successToast: {
          message: CREATOR_COPY.SUCCESS_ELITE_STATUS,
        },
        successAction: userChanged,
      }),
    );
    onClick && onClick();
  };

  return (
    <Button variant={BUTTON_VARIANTS.OPTION} className={className} onClick={handleClick} data-cy="elite-creator-button">
      {user.isElite ? CREATOR_COPY.BUTTON_DEACTIVATE_ELITE : CREATOR_COPY.BUTTON_ACTIVATE_ELITE}
    </Button>
  );
};

export default EliteCreatorButton;

import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import AccountPlatformFormModal from 'features/accountPlatforms/components/molecules/AccountPlatformFormModal/AccountPlatformFormModal';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import { useReport } from 'features/accounts/reports/useReport';
import ExportCsvButton from 'features/performance/ExportCsvButton';
import PerformanceDashboard from 'features/performance/PerformanceDashboard';
import { Box, Flex, Text } from 'shared/components/display';
import Elliptical from 'shared/components/molecules/Elliptical';
import PromptButton from 'shared/components/molecules/PromptButton';

import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { ACCOUNT_PLATFORM_TOASTS } from 'shared/config/toasts/accountPlatformToasts';
import ContainerProvider from 'shared/contexts/container/ContainerProvider';
import PerformanceProvider from 'shared/contexts/performance/PerformanceProvider';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { usePermissions } from 'shared/hooks/usePermissions';
import { useToasts } from 'shared/hooks/useToasts';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

const { H4 } = TEXT_VARIANTS;

const AccountPlatformHome = () => {
  const params = useParams<{
    accountPlatformId: string;
    organizationId: string;
    accountId: string;
    reportId: string;
  }>();
  const accountPlatformId = +params.accountPlatformId;
  const organizationId = +params.organizationId;
  const accountId = +params.accountId;
  const { isSuperadmin } = useCurrentUser();
  const { setErrorToast } = useToasts();

  const { accountPlatforms, accountPlatform, editAccountPlatform, deleteAccountPlatform } = useAccountPlatforms({
    accountId,
    accountPlatformId,
    organizationId,
  });
  const { isAuthorized } = usePermissions();
  const hasEditPermission = isAuthorized(organizationId, PERMISSIONS_MAP.EDIT_ACCOUNT_PLATFORM.API);

  useReport({ accountPlatformId }); // used the fetch reports at top-level.

  const reportId = +params.reportId;

  const onEditAccountPlatformSubmit = useCallback(
    (formData) => {
      const isDuplicate = accountPlatforms.findIndex((platform) => {
        return platform.name === formData.name && platform.id !== accountPlatformId;
      });

      if (isDuplicate === -1) {
        editAccountPlatform(formData);
      } else {
        setErrorToast({
          message: ACCOUNT_PLATFORM_TOASTS.DUPLICATE_PLATFORM_NAME_ERROR,
        });
      }
    },
    [accountPlatforms, accountPlatformId],
  );

  return (
    <ContainerProvider>
      <PerformanceProvider>
        <Box css={styles} className="account-platform-home">
          <Flex className="account-platform-home__header">
            <Flex className="account-platform-home__title-wrapper">
              <Text className="account-platform-home__title" variant={H4}>
                {accountPlatform.name}
              </Text>
              {(isSuperadmin || hasEditPermission) && (
                <Box className="account-platform-home__elliptical">
                  <Elliptical>
                    {hasEditPermission && (
                      <AccountPlatformFormModal
                        accountPlatform={accountPlatform}
                        onSubmit={onEditAccountPlatformSubmit}
                      />
                    )}
                    {isSuperadmin && (
                      <PromptButton
                        onConfirm={deleteAccountPlatform}
                        text={ACCOUNT_PLATFORM_COPY.MODAL_DELETE_ACCOUNT_PLATFORM}
                      >
                        {ACCOUNT_PLATFORM_COPY.BUTTON_DELETE_ACCOUNT_PLATFORM}
                      </PromptButton>
                    )}
                  </Elliptical>
                </Box>
              )}
            </Flex>
            <ExportCsvButton adAccountId={accountPlatform.accessId} />
          </Flex>
          {accountPlatform.platform && (
            <PerformanceDashboard reportId={reportId} accountId={accountId} accountPlatform={accountPlatform} />
          )}
        </Box>
      </PerformanceProvider>
    </ContainerProvider>
  );
};

export default AccountPlatformHome;

import { css } from '@emotion/react';

import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.brief-index-header': {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: '0 0 0 -.3rem',
    '&__creator': {
      margin: '0 0 .2rem -.3rem',
      width: '100%',
      [mediaquery.md]: {
        height: '100%',
        width: 'unset',
        margin: '-.2rem 0 .4rem -.2rem',
      },
      '& > .pagination-control': {
        margin: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0 1rem',
        [mediaquery.md]: {
          width: 'unset',
        },
        '& > *:not(:last-child)': {
          marginRight: 0,
          [mediaquery.md]: {
            marginRight: theme.space[3],
          },
        },
      },
    },
  },
});

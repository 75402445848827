import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useMediaQuery } from 'react-responsive';
import { Text } from 'rebass/styled-components';
import { GalleryItem, GallerySelectableProps } from 'features/media/interfaces/gallery';
import Checkbox from 'shared/components/atoms/Checkbox';
import LightboxFooter from 'shared/components/atoms/LightboxFooter/LightboxFooter';
import Tags, { TagCategory } from 'shared/components/atoms/Tags';
import Tooltip from 'shared/components/atoms/Tooltip';
import { Box, Flex, Image } from 'shared/components/display';
import { CarouselItem } from 'shared/components/molecules/CarouselItem';
import { Lightbox } from 'shared/components/organisms/Lightbox';
import mediaListViewStyles from 'shared/components/organisms/MediaListView/mediaListViewStyles';

import { FILE_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { breakpoints } from 'shared/styles/styleFunctions';
import { TEXT_VARIANTS } from 'shared/styles/text';
import PinFileControls from 'features/media/components/molecules/PinFileControls';
import { useShowcaseAssets } from 'features/users/hooks/useShowcaseAssets';
interface Props {
  numLoadingItems?: number;
  galleryItems: GalleryItem[];
  placeholderText?: string;
  renderLightbox?: boolean;
  LightboxCaption?: React.ReactElement<any>;
  useBrandName?: boolean;
  showPin?: boolean;
  onItemClick?: (index: number) => void;
}

const tagSettings = {
  templates: {
    tag: TagCategory,
  },
  duplicates: true,
};

const MediaListView: React.FC<Props & GallerySelectableProps> = ({
  galleryItems,
  numLoadingItems = 0,
  selectedFileIds = [],
  updateSelectedItems = () => {},
  isSelectable = false,
  placeholderText,
  renderLightbox = true,
  LightboxCaption,
  useBrandName = false,
  showPin = false,
  onItemClick: onItemClickProp,
}) => {
  const { pinFile, maxPinLimitReached } = useShowcaseAssets();
  const { isSuperadmin } = useCurrentUser();
  const [lightbox, setLightbox] = useState<{ open: boolean; index: number }>({
    open: false,
    index: 0,
  });

  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const onItemClick = (index) => {
    if (renderLightbox) setLightbox({ open: true, index });
    if (onItemClickProp) onItemClickProp(index);
  };

  const onRequestClose = () => {
    setLightbox((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const onSelect = (fileId: number) => {
    if (selectedFileIds?.includes(fileId)) {
      updateSelectedItems(selectedFileIds.filter((id) => id !== fileId));
    } else {
      updateSelectedItems([...selectedFileIds, fileId]);
    }
  };

  const handlePin = (file) => () => {
    if (isSuperadmin) pinFile(file, !file.isPinned);
  };

  return (
    <Box css={mediaListViewStyles} className="media-list-view">
      {Array.from({ length: numLoadingItems }).map((it, ndx) => (
        <Box key={`skeleton_${ndx}`} className="media-list-view__file" style={{ width: '100%', display: 'flex' }}>
          <Skeleton containerClassName="media-list-view__skeleton" height={40} />
        </Box>
      ))}

      {galleryItems.map(({ itemObject: file, options, OverlayLeft, selectDisabled, selectTooltip }, index) => {
        const { brandName = '', name, thumbnailUrl, tags, id } = file;
        return (
          <Flex className="media-list-view__file" key={file.id}>
            {isSelectable && (
              <Tooltip content={selectTooltip} disabled={!Boolean(selectTooltip)}>
                <Checkbox
                  dataCy={`file-explorer__checkbox-${name}`}
                  name={name}
                  checked={selectedFileIds?.includes(id)}
                  onChange={() => onSelect(id)}
                  disabled={selectDisabled}
                />
              </Tooltip>
            )}
            {showPin ? (
              <PinFileControls
                canPin={isSuperadmin}
                maxPinLimitReached={maxPinLimitReached}
                isPinned={file.isPinned}
                onPin={handlePin(file)}
              />
            ) : (
              <></>
            )}
            <Image
              alt={name}
              src={thumbnailUrl}
              className="media-list-view__file-thumbnail"
              css={{ '&:hover': { cursor: 'pointer' } }}
              onClick={() => {
                onItemClick(index);
              }}
            />
            <label htmlFor={useBrandName ? brandName : name} className="media-list-view__clickable-area">
              <Flex>
                <Tooltip content={useBrandName ? brandName : name} arrow delay={1000}>
                  <Text variant={TEXT_VARIANTS.BODY} className={'media-list-view__file-name'}>
                    {useBrandName ? brandName : name}
                  </Text>
                </Tooltip>
                {!isMobile &&
                  OverlayLeft &&
                  React.cloneElement(OverlayLeft, {
                    showTags: false,
                  })}
              </Flex>
              {tags?.length ? (
                <Tooltip content={file.tagNames?.join(', ')} arrow delay={1000}>
                  <Tags className="media-list-view__tags" value={tags} settings={tagSettings} readOnly />
                </Tooltip>
              ) : (
                <></>
              )}
            </label>

            <Box className="media-list-view__elliptical">
              {options &&
                React.cloneElement(options, {
                  showInfoOption: true,
                })}
            </Box>
          </Flex>
        );
      })}
      {galleryItems.length === 0 && numLoadingItems === 0 && (
        <Text variant={TEXT_VARIANTS.H4} className="media-list-view__placeholder">
          {placeholderText || FILE_COPY.PLACEHOLDER_GALLERY}
        </Text>
      )}

      <Lightbox
        ariaLabel="Preview File"
        isOpen={lightbox.open}
        selectedItemIndex={lightbox.index}
        onRequestClose={onRequestClose}
      >
        {galleryItems.map(({ itemObject: file, captionData }) => (
          <CarouselItem
            url={file.url}
            key={file.id}
            metadata={file.metadata}
            thumbnailUrl={file.thumbnailUrl}
            mimeType={file.mimeType}
            Footer={
              LightboxCaption ? (
                React.cloneElement(LightboxCaption, {
                  captionData,
                })
              ) : (
                <LightboxFooter captionData={captionData} />
              )
            }
          />
        ))}
      </Lightbox>
    </Box>
  );
};

export default MediaListView;

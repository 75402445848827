import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { faSortAmountDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { BreakdownFields } from 'features/performance/enums';
import { performanceDataFailed, topBottomLoaded } from 'features/performance/performance.slice';
import TopBottomForm from 'features/performance/TopBottomFormDropdown/form';
import toolFormStyles from 'features/performance/TopBottomFormDropdown/topBottomFormDropdownStyles';
import DropdownWrapper from 'shared/components/atoms/DropdownWrapper';
import Tooltip from 'shared/components/atoms/Tooltip';
import { Flex, Text } from 'shared/components/display';

import { apiAction } from 'shared/actions/api';
import { DRAWERS } from 'shared/config/containers';
import { PERFORMANCE_DATA_TYPES } from 'shared/config/performance';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_PERFORMANCE_ROUTES } from 'shared/config/routes/api/apiPerformanceRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useContainer } from 'shared/hooks/useContainer';
import { usePerformance } from 'shared/hooks/usePerformance';
import { TEXT_VARIANTS } from 'shared/styles/text';
import Theme from 'shared/styles/themes';
import { formatISODate } from 'shared/utilities/dateUtility';
import { TopBottomAnalysisValues } from './types';

const { TOP_BOTTOM_ANALYSIS } = PERFORMANCE_DATA_TYPES;
const { TOOL } = DRAWERS;
const { NEUTRAL300 } = Theme.colors;

const TopBottomFormDropdown = () => {
  const {
    performanceState: {
      committedParams,
      breakdown,
      dateRange: { from, to },
      dateRanges,
      breakdownValue,
      topBottom,
    },
    committedFields,
    updateTopBottom,
  } = usePerformance();

  const { loading } = useComponentLoading(RESOURCES.PERFORMANCE);

  const dispatch = useDispatch();

  const { closeContainer } = useContainer(TOOL);

  const getTopBottomPerformers = (
    numWanted: number,
    rankBy: string,
    params: any,
    tbBreakdowns: any[] | null = null,
  ) => {
    const data = { numWanted, rankBy, tbBreakdowns, ...params };

    dispatch(
      apiAction({
        path: {
          route: API_PERFORMANCE_ROUTES.TOP_BOTTOM_ANALYSIS,
        },
        method: 'POST',
        successAction: topBottomLoaded,
        errorAction: performanceDataFailed,
        data,
        entity: RESOURCES.PERFORMANCE,
      }),
    );
  };

  const onSubmit = (data: TopBottomAnalysisValues) => {
    // TODO: Temporary the reason for this is to prevent Top Bottom Analysis while a network
    // request is buffering, need to find a better way to display that to the user instead of
    // using a conditional with no response while loading.
    if (!loading) {
      closeContainer();
      updateTopBottom(data);
      const {
        rankBy: { value: rankBy },
        topBottomAmount,
        ...breakdownValues
      } = data;
      const breakdowns =
        breakdown === BreakdownFields.NONE
          ? null
          : Object.values(breakdownValues).filter((value: any) => Boolean(value.value));

      const rankByField = dateRanges ? `${rankBy}[${formatISODate(from)},${formatISODate(to)}]` : rankBy;

      getTopBottomPerformers(topBottomAmount, rankByField, committedParams, breakdowns);
    }
  };

  return (
    <DropdownWrapper
      label={TOOL}
      clickComponent={
        <>
          <FontAwesomeIcon icon={faSortAmountDown} />
          <span>{TOP_BOTTOM_ANALYSIS}</span>
        </>
      }
    >
      <Flex css={toolFormStyles} className="top-bottom-form-dropdown">
        <Flex className="top-bottom-form-dropdown__title">
          <Text variant={TEXT_VARIANTS.SUBHEADING}>Top-Bottom Analysis</Text>
          <Tooltip
            content="Retrieve the highest/lowest performing entities"
            className="top-bottom-form-dropdown__question-icon"
          >
            <FontAwesomeIcon icon={faQuestionCircle} color={NEUTRAL300} />
          </Tooltip>
        </Flex>
        {!loading && (
          <TopBottomForm
            values={topBottom}
            performanceColumns={committedFields}
            breakdownValue={breakdownValue}
            onSubmit={onSubmit}
          />
        )}
      </Flex>
    </DropdownWrapper>
  );
};

export default TopBottomFormDropdown;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  flexDirection: 'column',
  margin: '16px',
  '.facebook-requirements': {
    '&__progress-bar-label': {
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      color: COLORS.NEUTRAL300,
    },
    '&__progress-bar': {
      height: '12px',
      position: 'relative',
      backgroundColor: COLORS.NEUTRAL100,
      borderRadius: '12px',
      overflow: 'hidden',
      marginTop: '4px',
      marginBottom: '16px',
    },
    '&__progress': {
      position: 'absolute',
      height: '100%',
      backgroundColor: COLORS.GREEN500,
    },
    '&__section': {
      flexDirection: 'column',
      margin: '8px 0',
    },
    '&__disclaimer': {
      textAlign: 'center',
      color: COLORS.NEUTRAL700,
      marginTop: '8px',
    },
  },
});

export const TAG_CATEGORIES = {
  INDUSTRY: 'industry',
  NICHE: 'niche',
  ETHNICITY: 'ethnicity',
  FILMING_BACKDROP: 'filming backdrop',
  LANGUAGE: 'language',
  MODEL: 'model',
  EDITING: 'editing',
  UGC_TREND: 'ugc trend',
  WORK_PREFERENCE: 'work preference',
};

import React, { useReducer, useMemo } from 'react';
import ContainerContext from 'shared/contexts/container/ContainerContext';

const SET = 'SET';

const containerReducer = (state, action) => {
    if (action.type === SET) {
        return { ...state, [action.fieldName]: action.value };
    }
    return state;
};

const ContainerProvider = ({ children }) => {
    const [state, dispatch] = useReducer(containerReducer, {});

    const contextValue = useMemo(() => {
        return { state, dispatch };
    }, [state, dispatch]);

    return (
        <ContainerContext.Provider value={contextValue}>
            {children}
        </ContainerContext.Provider>
    );
};

export default ContainerProvider;

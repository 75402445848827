import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.brief-index': {
    '& thead': {
      height: '48px',
    },
    'tr:has(* .brief-index__selected)': {
      backgroundColor: `${COLORS.PRIMARY100}40`,
    },
    'td:has(* .brief-index__selected)': {
      position: 'relative',
    },
  },
  '.brief-index': {
    '&__percent': {
      borderRadius: '50%',
      backgroundColor: COLORS.ORANGE500,
      width: theme.space[4],
      verticalAlign: 'sub',
      marginTop: '0.2rem',
      float: 'right',
      marginRight: theme.space[3],
    },
    '&__selected': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '5px',
      height: '50px',
      backgroundColor: COLORS.PRIMARY500,
    },
    '&__payout': {
      display: 'inline',
      float: 'left',
      marginBottom: '0',
      width: '60px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
});

import COLORS from 'shared/styles/colors';

const { PRIMARY200, NEUTRAL200, NEUTRAL300 } = COLORS;

export const STYLES = {
  ABSOLUTE: 'absolute',
  AUTO: 'auto',
  BASELINE: 'baseline',
  BLOCK: 'block',
  BOLD: 'bold',
  BORDER_PRIMARY: `1px solid ${PRIMARY200}`,
  BORDER_NEUTRAL: `1px solid ${NEUTRAL200}`,
  BORDER_NEUTRAL_DASHED: `1px dashed ${NEUTRAL300}`,
  BREAK_WORD: 'break-word',
  CAPITALIZE: 'capitalize',
  CENTER: 'center',
  CENTER_CENTER_LEFT: ['center', 'center', 'left'],
  CENTER_CENTER_NORMAL: ['center', 'center', 'normal'],
  COLUMN: 'column',
  COLUMN_COLUMN_ROW: ['column', 'column', 'row'],
  CONTENTS: 'contents',
  END: 'end',
  FIT_CONTENT: 'fit-content',
  FLEX: 'flex',
  FLEX_END: 'flex-end',
  FLEX_START: 'flex-start',
  HIDDEN: 'hidden',
  INLINE_BLOCK: 'inline-block',
  ITALIC: 'italic',
  LEFT: 'left',
  MAX_CONTENT: 'max-content',
  NONE: 'none',
  NORMAL: 'normal',
  NOWRAP: 'nowrap',
  POINTER: 'pointer',
  PRE_LINE: 'pre-line',
  RELATIVE: 'relative',
  RIGHT: 'right',
  ROW: 'row',
  SPACE_AROUND: 'space-around',
  SPACE_BETWEEN: 'space-between',
  SPACE_EVENLY: 'space-evenly',
  START: 'start',
  STICKY: 'sticky',
  SUBMIT: 'submit',
  UNDERLINE: 'underline',
  UNSET: 'unset',
  UPPERCASE: 'uppercase',
  VISIBLE: 'visible',
  WRAP: 'wrap',
};

export const ELEMENTS = {
  LABEL: 'label',
  PRE: 'pre',
  STRONG: 'strong',
  SPAN: 'span',
  BUTTON: 'button',
  LINK: 'a',
};

export const TOOLTIP = {
  BOTTOM_END: 'bottom-end',
  BOTTOM: 'bottom',
};

export const DROPDOWN = {
  DEFAULT: 'default',
  CONDENSE: 'condense',
};

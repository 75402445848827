import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.creator-briefs-pagination-control': {
    width: '100%',
    [mediaquery.md]: {
      height: '100%',
      width: 'unset',
      margin: '-.2rem 0 .4rem -.2rem',
    },
    '& > .pagination-control': {
      margin: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '0 1rem',
      [mediaquery.md]: {
        width: 'unset',
      },
    },
  },
});

import { useFiles } from 'features/media/useFiles';
import { SignalRContext } from 'shared/contexts/signalr';
import { FileUploadStatus } from 'shared/typings/file/enums';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { accountPlatformChanged } from 'features/accountPlatforms/accountPlatforms.slice';

export const useAudienceFileNotification = (): void => {
  const dispatch = useDispatch();
  const { connection } = useContext(SignalRContext);
  const { updateFileUpload } = useFiles();

  useEffect(() => {
    const successHandler = ({ key, result: [facebookCustomAudience] }) => {
      updateFileUpload(key, FileUploadStatus.UPLOADED);
      dispatch(
        accountPlatformChanged({
          data: {
            id: facebookCustomAudience.accountPlatformId,
            facebookCustomAudience,
          },
        }),
      );
    };

    const errorHandler = ({ key, error }) => {
      updateFileUpload(key, FileUploadStatus.UPLOAD_FAILED, error);
    };

    if (connection) {
      connection.on('newAudienceFile', successHandler);
      connection.on('audienceFileError', errorHandler);
    }

    return function cleanup() {
      connection?.off('newAudienceFile', successHandler);
      connection?.off('audienceFileError', errorHandler);
    };
  }, [connection]);
};

// * Lib
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import BriefCard from 'features/briefs/BriefCard/index';
//* Display/UI
import { Flex } from 'shared/components/display';

import { BRIEF_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { Brief } from 'shared/typings/briefs';
// * Util
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

// * Styles
import styles from './styles';
interface Props {
  data: Array<Brief>;
}

const BriefsTable: React.FC<Props> = (props) => {
  const { data } = props;
  const navigate = useNavigate();
  const location = useLocation<any>();
  const {
    isCreator,
    currentUser: { isElite },
  } = useCurrentUser();

  const onBriefPress = (brief: Brief) => {
    const pathToBrief = LinkCreator.createLink(
      isCreator
        ? {
            routeKey: 'VIEW_INDIVIDUAL_BRIEF',
            userType: UserType.CREATOR,
            variables: {
              briefId: brief.id,
            },
          }
        : {
            routeKey: 'VIEW_INDIVIDUAL_BRIEF',
            variables: {
              accountId: brief.accountId,
              organizationId: brief.organizationId,
              briefId: brief.id,
            },
          },
    );
    navigate(
      {
        pathname: pathToBrief,
      },
      {
        state: {
          ...location.state,
        },
      },
    );
  };

  return (
    <Flex className="briefs-table" css={styles}>
      {data.length
        ? data.map((brief: Brief) => (
            <BriefCard
              key={brief.id}
              brandName={brief.account?.name}
              account={brief.account}
              creativeOnly={brief.creativeOnly}
              title={brief.name || BRIEF_COPY.DEFAULT_BRIEF_NAME}
              freeProduct={brief.freeProduct}
              briefCreatorStatus={brief.briefCreatorStatus}
              onPress={() => onBriefPress(brief)}
              briefStatus={!isCreator ? brief.status : undefined}
              creatorPayout={isCreator ? (isElite ? brief.eliteCreatorPayout : brief.creatorPayout) : undefined}
              full={brief.full}
              inviteOnly={brief.inviteOnly}
            />
          ))
        : BRIEF_COPY.PLACEHOLDER_BRIEFS}
    </Flex>
  );
};

export default BriefsTable;

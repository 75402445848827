import React from 'react';
import cs from 'classnames';
import { Flex } from 'shared/components/display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-solid-svg-icons';

import styles from './styles';

interface Props {
  children: string;
  className?: string;
  onDelete?: () => void;
  theme?: 'default' | 'compact';
  color?: string;
}
const Tag: React.FC<Props> = ({ children, className, onDelete, theme = 'default', color }) => {
  const isDeletable = Boolean(onDelete);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onDelete && onDelete();
    }
  };

  return (
    <Flex css={styles(color)} className={cs('tag', className, `tag--${theme}`)}>
      {children}
      {isDeletable && (
        <FontAwesomeIcon
          aria-label="remove tag"
          role="button"
          title="remove tag"
          className="tag__remove-btn"
          tabIndex={0}
          onKeyDown={onKeyDown}
          onClick={onDelete}
          icon={faX}
          size="xs"
        />
      )}
    </Flex>
  );
};
export default React.memo(Tag);

import { ASPECT_RATIO_TOLERANCE } from 'shared/config/media';
import { withinPercentTolerance } from 'shared/utilities/mathUtility';
import { FACEBOOK_URL_DYNAMIC_VARIABLE_VALUES } from './config';

export const deriveFacebookAspectRatio = (width, height) => {
  const aspectRatioVal = width / height;
  if (withinPercentTolerance(aspectRatioVal, 1 / 1, ASPECT_RATIO_TOLERANCE)) {
    return '1:1';
  } else if (withinPercentTolerance(aspectRatioVal, 4 / 5, ASPECT_RATIO_TOLERANCE)) {
    return '4:5';
  } else if (withinPercentTolerance(aspectRatioVal, 9 / 16, ASPECT_RATIO_TOLERANCE)) {
    return '9:16';
  } else {
    throw new Error(`Aspect Ratio not accepted: ${width}/${height}`);
  }
};

/**
 * Interpolate object into url string of handlebar-style variables.
 * Also ignores interpolation of the dynamic URL parameters available in Facebook.
 *
 * @param {string} dynamicStr - String with handlebar variables. e.g: {{variable}}_str
 * @param {object} varMap - Variable to interpolate. e.g: { variable: 'dynamic' }
 */
export const parseFacebookDynamicUrlString = (dynamicStr: string, varMap: Record<string, any> = {}) => {
  // Replace varMap values.
  const partiallyReplacedStr = Object.keys(varMap)
    .map((val) => ({
      regex: new RegExp(`{{${val}}}`),
      value: varMap[val],
    }))
    .reduce((str, { regex, value }): string => str.replace(regex, value), dynamicStr);
  // Check for leftover bracket variables.
  const leftoverVariables = partiallyReplacedStr.match(/\{\{(.*?)\}\}/g);
  // Return immediately if none are found.
  if (!leftoverVariables) return partiallyReplacedStr;
  // Filter any bracket variables that don't match the ones in the FACEBOOK_URL_DYNAMIC_VARIABLE_VALUES array.
  // The array matches the values found here: https://www.facebook.com/business/help/2360940870872492
  const invalidVariables = leftoverVariables.filter(
    (found: string) => !FACEBOOK_URL_DYNAMIC_VARIABLE_VALUES.includes(found.replace(/\{|\}/g, '')),
  );
  // Replace any left over bracket variables, they will be truncated in the output string.
  return invalidVariables.reduce((str, invalidBlock) => str.replace(invalidBlock, ''), partiallyReplacedStr);
};

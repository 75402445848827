export const TRACKING = {
  BRIEF_CREATE: {
    category: 'Brief',
    action: 'Create',
    label: 'Create Brief',
  },
  BRIEF_PUBLISH: {
    category: 'Brief',
    action: 'Publish',
    label: 'Publish Brief',
  },
  BRIEF_APPLY: {
    category: 'Brief',
    action: 'Apply',
    label: 'Apply to Brief',
  },
  BRIEF_SUBMIT: {
    category: 'Brief',
    action: 'Submit',
    label: 'Submit to Brief',
  },
  INVITED_TO_APPLY: {
    category: 'Brief',
    action: 'InvitedToApply',
    label: 'Invited to Apply',
  },
  BRIEF_LAUNCH_REQUESTED: {
    category: 'Brief',
    action: 'LaunchRequested',
    label: 'Brief Launch Requested',
  },
  ADSCORE_DETAIL: {
    category: 'AdScore',
    action: 'Detail',
    label: 'Submit details for AdScore',
  },
  ADSCORE_FACEBOOK: {
    category: 'AdScore',
    action: 'Facebook',
    label: 'Submit Facebook AdScore',
  },
  ADSCORE_CALCULATE: {
    category: 'AdScore',
    action: 'Calculate',
    label: 'Calculate AdScore',
  },
  ADSCORE_IMPROVE: {
    category: 'AdScore',
    action: 'Improve',
    label: 'Improve AdScore',
  },
  ADSCORE_SURVEY_OPENED: {
    category: 'AdScore',
    action: 'SurveyOpened',
    label: 'AdScore Survey Opened',
  },
  ADSCORE_SURVEY_COMPLETED: {
    category: 'AdScore',
    action: 'SurveyCompleted',
    label: 'AdScore Survey Completed',
  },
  TREND_PINNED: {
    category: 'Trend',
    action: 'Pinned',
    label: 'Pin Trend',
  },
  TREND_CLICKED: {
    category: 'Trend',
    action: 'Clicked',
    label: 'Click Trend',
  },
};

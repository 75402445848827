import React from 'react';
import cs from 'classnames';
import { Flex } from 'shared/components/display';
import styles from './styles';
import Tooltip from 'shared/components/atoms/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCheck, faTag } from '@fortawesome/pro-solid-svg-icons';
import { COLLECTION_COPY } from 'shared/config/copy';
import { useGalleryListView, VIEWS } from 'shared/hooks/useGalleryListView';
import { BrkfstFile } from 'shared/typings/file';

interface Props {
  file: Pick<BrkfstFile, 'submitted' | 'tags'>;
  showSubmitted?: boolean;
  showTags?: boolean;
}
const CreatorFileOverlayIcons: React.FC<Props> = ({ file, showSubmitted = false, showTags = false }) => {
  const { viewMode } = useGalleryListView();

  return (
    <Flex
      css={styles}
      className={cs('creator-file-overlay-icons', {
        'creator-file-overlay-icons--gallery': viewMode === VIEWS.GALLERY.value,
        'creator-file-overlay-icons--list': viewMode === VIEWS.LIST.value,
      })}
    >
      {file.submitted && showSubmitted && (
        <Tooltip content={COLLECTION_COPY.LABEL_SUBMITTED_ASSET}>
          <FontAwesomeIcon className="creator-file-overlay-icons__icon" icon={faFileCheck} />
        </Tooltip>
      )}
      {Boolean(file.tags?.length) && showTags && (
        <Tooltip content={COLLECTION_COPY.LABEL_TAGS}>
          <FontAwesomeIcon className="creator-file-overlay-icons__icon" icon={faTag} />
        </Tooltip>
      )}
    </Flex>
  );
};

export default CreatorFileOverlayIcons;

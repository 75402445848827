export enum Type {
  BUSINESS = 'BUSINESS',
  PAGE = 'PAGE',
  PIXEL = 'PIXEL',
  INSTAGRAM_ACCOUNT = 'INSTAGRAM_ACCOUNT',
  AUDIENCE_TOS_AGREEMENT = 'AUDIENCE_TOS_AGREEMENT',
  ACCOUNT_PLATFORM = 'ACCOUNT_PLATFORM',
  PRODUCT_CATALOG = 'PRODUCT_CATALOG',
}

export enum Status {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  // Specific to type AUDIENCE_TOS_AGREEMENT
  PENDING_TOS = 'PENDING_TOS',
}

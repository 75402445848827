import React from 'react';
import * as yup from 'yup';

import { Box, Text } from 'shared/components/display';
import ButtonGroupInput, { Option } from 'shared/components/molecules/ButtonGroupInput/ButtonGroupInput';
import { buttonGroupSchema } from 'shared/components/molecules/ButtonGroupInput/validation';

import { CREATOR_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { StepFieldProps } from 'shared/components/forms/MultiStepForm/interfaces';

import styles from './styles';

export const LanguageFormFields: React.FC<StepFieldProps> = ({ className }) => {
  return (
    <Box css={styles}>
      <Text variant={TEXT_VARIANTS.BODY} className={className}>
        {CREATOR_COPY.CAPTION_MULTIPLE_OPTIONS}
      </Text>
      <ButtonGroupInput
        name={LANGUAGE_FIELDNAMES.LANGUAGE}
        otherOptionFieldName={LANGUAGE_FIELDNAMES.LANGUAGE_OTHER}
        otherOptionValue={languageOtherOption.value}
        isMultiSelect
      />
    </Box>
  );
};

export const LANGUAGE_FIELDNAMES = {
  LANGUAGE: 'language',
  LANGUAGE_OTHER: 'languageOther',
};

export const languageOtherOption = {
  label: 'Other',
  value: 'language:other',
};

export const languageSchema = yup.object({
  language: buttonGroupSchema,
  languageOther: yup.string().when('language', {
    is: (value: Option[]) => {
      const languageOption = value.find((op) => op.value === languageOtherOption.value);
      return languageOption?.selected;
    },
    then: yup.string().customRequiredString().onlyLettersAndSpaces(),
  }),
});

export type LanguageValues = {
  language: Option[];
  languageOther: string;
};

import React from 'react';
import { Formik, FormikConfig, FormikHelpers } from 'formik';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';

import { useStripe } from 'shared/hooks/useStripe';

export type OnSubmitWithStripe<Values = any> = (
  values: Values,
  formikHelpers: FormikHelpers<Values>,
  stripe: Stripe,
  elements: StripeElements,
) => void | Promise<any>;

type FSProps<Values> = Omit<FormikConfig<Values>, 'onSubmit'> & {
  onSubmit: OnSubmitWithStripe<Values>;
};

/**
 * A drop-in replacement wrapper to \<Formik\/\> that implements stripe. FormikStripe implements
 * a custom onSubmit wrapper that has access to stripe & elements as second and third parameters.
 *
 * NB: If you don't want stripe, don't use this component.
 */
const FormikStripe = <Values extends {}>({ onSubmit, children, ...props }: FSProps<Values>) => {
  const { stripeObject } = useStripe(true);

  return (
    <Elements stripe={stripeObject}>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <Formik<Values>
            {...props}
            onSubmit={(values, helpers) => {
              if (stripe && elements) {
                onSubmit(values, helpers, stripe, elements);
              } else {
                console.warn("Stripe or elements is undefined. This shouldn't happen");
              }
            }}
          >
            {children}
          </Formik>
        )}
      </ElementsConsumer>
    </Elements>
  );
};

export default FormikStripe;

// disable eslint to allow for control characters in regex
/* eslint-disable */
import config from 'config';
import { USER_COPY } from './copy';

export const CONSTRAINT_LIMITS = {
  TAG_MAX_LENGTH: 45,
  GOOGLE_ADS_MAX: 5,
  DEFAULT_MONEY_MIN: 0.01,
  MAX_BRIEF_TITLE_LENGTH: 256,
};

// General
export const RECOMMENDED_SLIDER_MAX = 30;
export const RECOMMENDED_SLIDER_MIN = 0;
export const IMAGE_SIZE_LIMIT = 31457280; // 30MB in bytes (30 * 2^20)
export const VIDEO_SIZE_LIMIT = 4294967296; // 4GB in bytes (4 * 2^30)
export const VIDEO_PREFETCH_SIZE_LIMIT = 5300000;

// FB
export const FACEBOOK_IMAGE_DIMENSION_MINIMUM = 600;
export const FACEBOOK_VIDEO_MAX_DURATION = 300; // 5 minutes in seconds
export const FACEBOOK_VIDEO_MIN_DURATION = 0;
export const FB_AD_MIN_DURATION = 1; // 1 second minimum
export const DURATION_BUFFER = 5;

// Submission
export const SUBMISSION_UPLOAD_ASPECT_RATIOS = Object.freeze([1 / 1, 2 / 3, 4 / 5, 9 / 16, 16 / 9]);
export const SUBMISSION_AR_PERCENT_TOLERANCE = 0.01; // 1 percent
export const SUBMISSION_VIDEO_MIN_DIMENSION = 640;
export const SUBMISSION_IMAGE_MIN_DIMENSION = 600;
export const SUBMISSION_MAX_FILES_PER_MODULE = 2;

export const CONSTRAINTS = Object.freeze({
  PHONE_CONSTRAINT: /(\+[0-9]{1})*[0-9]{10}/,
  DOMAIN_CONSTRAINT: /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
  // just for usa for now....
  POSTAL_CONSTRAINT: /^[0-9]{5}([- /]?[0-9]{4})?$/,
  POSITIVE_NUMBER_CONSTRAINT: /^[+]?([.]\d+|\d+([.]\d+)?)$/,

  NO_SPACES: /^\S*$/,
  // sorry, elon musk & grimes' baby
  NO_SPECIAL_CHARACTERS_CONSTRAINT: /^([a-z0-9]+[.]?([ ][a-z0-9])?|[a-z0-9]+['-]?)+$/i, // allows for .'- and spaces but cannot end with space
  NO_DOLLAR_SIGN_CONSTRAINT: /^[^\$]+$/,
  FILE_CHARACTERS_CONSTRAINT: /[a-zA-Z0-9 .-]/g,
  DOMAIN_CHARACTERS_CONSTRAINT: /[^a-zA-Z0-9\.\-:/]/i,
  SPECIAL_CHARS_WHITESPACE: /[^\w\-\. ]+/,
  // domains
  FB_CONSTRAINT: 'facebook.com',
  TIK_TOK_CONSTRAINT: 'tiktok.com',
  VIMEO_LINK_CONSTRAINT: 'vimeo.com',
  YOUTUBE_CONSTRAINT: 'youtube.com',
});

export const MESSAGES = {
  ALREADY_INVITED: 'The user has already been invited to the organization',
  ASPECT_RATIO: 'Please upload a file with a standard aspect ratio of 1:1, 4:5, 9:16, 16:9',
  FB_AD_DOMAIN: 'Please enter a valid url without http(s)://, subdomains(www.) and trailing slash',
  DOMAIN: 'Please enter a valid url starting with https://',
  EMAIL: 'Please enter a valid email address',
  ENDS_IN_PERIOD: 'Username cannot end in a .',
  ENDS_IN_SLASH: 'Domain cannot end with a `/`.',
  DOMAIN_ENDS_IN_PERIOD: 'Domain cannot end in a .',
  TLD_ERROR: 'Top Level Domain Does Not Exist.',
  FILENAME: `Valid characters are A-Z a-z 0-9 . _ - space`,
  FILE_NAME_LENGTH: `The file name should not be longer than ${config.FILE_NAME_LENGTH} characters`,
  FILE_NAME_ERROR: 'Unsupported character(s)',
  INVALID_FILE: 'Please enter a valid file',
  INCOMPLETE_GOOGLE_AD: `Please complete all required fields.`,
  INVALID_CSV_FILE: `Please upload a valid csv file.`,
  LESS_THAN_TWO_CHARACTERS: 'Username must have 2+ characters',
  MINIMUM_DIMENSION: 'Minimum dimension for width and height is 120px',
  NAME_VALUE_PAIRS_MISSING_NAME: 'Please assign a name to this text.',
  NAME_VALUE_PAIRS_NAMING_NOTHING: 'You cannot assign a name to an empty text.',
  NO_DOLLAR_SIGN: 'Please enter a positive number without a dollar sign ($)',
  NO_SPECIAL_CHARACTERS: 'Do not include any special characters',
  NUMBER: 'Please enter a positive number',
  ONLY_NUMBERS: "Username can't contain numbers only",
  PASSWORD_CONFIRMATION: 'Please confirm your password',
  PASSWORD_MATCH: `The password doesn't match. Try again?`,
  PHONE: 'Please enter a valid phone number',
  POSTAL: 'Please enter a valid postal code',
  REQUIRED_FIELD: 'This field is required.',
  REQUIRED_CREATOR_OR_EMAIL: "Please select a creator or enter an email of the creator you'd like to invite.",
  TAG_LENGTH: `Tags must be ${CONSTRAINT_LIMITS.TAG_MAX_LENGTH} characters or less`,
  TOO_MANY_CHARACTERS: "Username can't exceed 24 characters",
  UNSUPPORTED_FILE: `Unsupported file type`, // general error message, for specific message use ERROR_UNSUPPORTED_FILE
  WHITESPACE: "Please don't use whitespaces",
  INVALID_LINK: 'Please enter a valid link for ',
  INVALID_ADDRESS: 'Please enter a valid address.',
  INVALID_PHONE: 'Please enter a valid phone number.',
  INVALID_PHONE_OPTIONAL: 'Please enter a valid phone number or leave field empty.',
  INVALID_CHARACTERS: 'Valid characters are A-Z a-z 0-9 _ .',
  INVALID_DOMAIN: 'Valid characters are A-Z a-z 0-9 - .',
  INVALID_SUBDOMAIN: 'www is a subdomain and is not allowed',
  URL: 'Please enter a valid url starting with https://',

  RANGE_MIN_MAX: 'Please enter a max duration greater than the specified minimum',
  // Platform File Validations

  // Image Validation
  IMAGE_FORMAT_ERROR: `Unsupported image format`, // general error message, for specific message use ERROR_UNSUPPORTED_FILE
  IMAGE_SIZE_LIMIT: `Exceeds image ${IMAGE_SIZE_LIMIT / 2 ** 20}MB max`, // Divide bytes by 2^20 to get MB
  IMAGE_DIMENSIONS_ERROR: 'Min image dimension 600px',

  // Video/GIF Validation
  VIDEO_FORMAT_ERROR: `Unsupported video format`, // general error message, for specific message use ERROR_UNSUPPORTED_FILE
  VIDEO_SIZE_LIMIT: `Exceeds video ${VIDEO_SIZE_LIMIT / 2 ** 30}GB max`, // Divide bytes by 2^30 to get GB
  VIDEO_DIMENSIONS_ERROR: 'Min video dimension 640px',
  VIDEO_DURATION_ERROR: `Exceeds ${FACEBOOK_VIDEO_MAX_DURATION}s max`,
  VIDEO_CODEC_ERROR: `<a href="${USER_COPY.LINK_FAQ}" style="color:#007bff" target="_blank">Unsupported video codec</a>`,

  // Gif Files Validations
  GIF_STATIC_ERROR: 'Static gifs are not supported',

  // Common File Validation
  VALID_FILE_ASPECT_RATIOS: '1:1 | 2:3 | 4:5 | 9:16 | 16:9',
};

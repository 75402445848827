import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export default css({
  marginTop: theme.space[2],
  '.payout-form': {
    '&__submit': {
      margin: `${theme.space[4]} 0`,
    },
  },
});

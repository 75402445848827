import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.safe-zone-buttons': {
    flexDirection: 'column',
    boxSizing: 'border-box',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    backgroundColor: `${COLORS.NEUTRAL800}90`,
    padding: theme.space[2],
    fontWeight: 'bold',
    fontSize: '0.75rem',
    color: 'white',
  },
  '.safe-zone-buttons': {
    '&__header': {
      color: 'white',
      flexDirection: 'column',
      alignItems: 'center',
      whiteSpace: 'pre-line',
      margin: 0,
    },
    '&__btn': {
      backgroundColor: 'transparent',
      borderRadius: '50%',
      padding: theme.space[2],
      margin: '2px',
      width: '35px',
      height: '35px',
      border: 'none',
      cursor: 'pointer',
      fontWeight: 'normal',
      boxShadow: 'none',
      outline: 'none',
    },
    '&__social-media-icon': {
      color: COLORS.WHITE,
      fontSize: '1.25rem',
      margin: theme.space[2],
    },
    '&__social-media-icon--active, &__btn:hover .safe-zone-buttons__social-media-icon': {
      color: COLORS.NEUTRAL900,
    },
    '&__btn:has(.safe-zone-buttons__social-media-icon--active), &__btn:hover': {
      backgroundColor: COLORS.WHITE,
    },
  },
});

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  addressUpdated,
  getOrganization,
  getOrganizations,
  organizationAdded,
  organizationChanged,
  organizationPinned,
  organizationUnPinned,
} from 'features/organizations/organizations.slice';
import { componentLoadingToggled } from 'features/ui/ui.slice';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_ORG_ROUTES } from 'shared/config/routes/api/apiOrgRoutes';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';
import { ORGANIZATION_TOASTS } from 'shared/config/toasts/organizationToasts';
import { GTM_USER_TYPES } from 'shared/config/types';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { organizationSpliced } from 'shared/middleware/organizations';
import { ApiOnSuccess } from 'shared/typings/api';
import { Organization } from 'shared/typings/organizations';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { pushGtmOnboardEvent } from 'shared/utilities/trackingUtility';
const ONBOARDING_MARKETER = 'onboardingMarketer';

export const useOrganizations = (organizationId?: number) => {
  const dispatch = useDispatch();
  const organizations: Organization[] = useSelector(getOrganizations);
  const organization: Organization = useSelector(getOrganization(organizationId));
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();

  const { loading } = useComponentLoading(RESOURCES.ORGANIZATION);
  const { loading: loadingAccess } = useComponentLoading(RESOURCES.ORGANIZATION_ACCESS);

  const { loading: loadingOnboarding } = useComponentLoading(ONBOARDING_MARKETER, false);

  const fetchOrg = ({ onSuccess, onError }: { onSuccess?: ApiOnSuccess; onError?: (error: any) => void } = {}) => {
    dispatch(
      apiAction({
        path: {
          route: API_ORG_ROUTES.ORGANIZATION,
          variables: {
            organizationId,
          },
        },
        successAction: organizationSpliced,
        onSuccess,
        onError,
        entity: [
          RESOURCES.ORGANIZATION,
          RESOURCES.ACCOUNT,
          RESOURCES.ACCOUNT_LOGO,
          RESOURCES.CARD,
          RESOURCES.ROLE,
          RESOURCES.ORGANIZATION_ACCESS,
        ],
      }),
    );
  };

  const onboardOrg = (data, clearStorage) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.ONBOARD_MARKETER,
          variables: {
            userId: currentUser.id,
          },
        },
        entity: ONBOARDING_MARKETER,
        method: 'POST',
        data,
        navigate,
        pushFunction: (newOrg) =>
          LinkCreator.createLink({
            routeKey: 'ONBOARD_COMPLETE',
            userType: UserType.ORG,
            variables: {
              organizationId: newOrg?.id,
              accountId: newOrg?.accounts[0].id,
            },
          }),
        successAction: organizationAdded,
        onSuccess: () => {
          clearStorage();
          pushGtmOnboardEvent(GTM_USER_TYPES.BRAND);
        },
      }),
    );
  };

  const updateOrgAddress = (address, onSuccess?: () => void) => {
    dispatch(
      apiAction({
        path: {
          route: API_ORG_ROUTES.ORG_ADDRESS,
          variables: {
            organizationId,
          },
        },
        method: 'PATCH',
        data: address,
        successAction: addressUpdated,
        successToast: {
          message: ORGANIZATION_TOASTS.ADDRESS_UPDATED,
        },
        ...(onSuccess ? { onSuccess } : {}),
      }),
    );
  };

  const updateOrg = (orgData) => {
    dispatch(
      apiAction({
        path: {
          route: API_ORG_ROUTES.ORGANIZATION,
          variables: {
            organizationId,
          },
        },
        method: 'PATCH',
        data: orgData,
        successAction: organizationChanged,
        successToast: {
          message: ORGANIZATION_TOASTS.ORGANIZATION_UPDATED,
        },
      }),
    );
  };

  const pinOrg = (orgId) => {
    dispatch(
      apiAction({
        path: {
          route: '/org/pinned/:orgId',
          variables: {
            orgId,
          },
        },
        method: 'POST',
        entity: RESOURCES.PIN_ORGANIZATION,
        successAction: organizationPinned,
        successToast: {
          message: ORGANIZATION_TOASTS.ORGANIZATION_UPDATED,
        },
      }),
    );
  };

  const unpinOrg = (orgId) => {
    dispatch(
      apiAction({
        path: {
          route: '/org/pinned/:orgId',
          variables: {
            orgId,
          },
        },
        method: 'DELETE',
        entity: RESOURCES.PIN_ORGANIZATION,
        successAction: organizationUnPinned,
        successToast: {
          message: ORGANIZATION_TOASTS.ORGANIZATION_UPDATED,
        },
      }),
    );
  };

  const toggleOrgLoading = (newLoadingVal = false) => {
    dispatch(
      componentLoadingToggled({
        component: [
          RESOURCES.ORGANIZATION,
          RESOURCES.ACCOUNT,
          RESOURCES.ACCOUNT_LOGO,
          RESOURCES.ACCOUNT_PLATFORM,
          RESOURCES.CARD,
          RESOURCES.ROLE,
        ],
        loading: newLoadingVal,
      }),
    );
  };

  return {
    organizations,
    organization,
    loading,
    loadingAccess,
    updateOrgAddress,
    updateOrg,
    onboardOrg,
    loadingOnboarding,
    fetchOrg,
    toggleOrgLoading,
    pinOrg,
    unpinOrg,
  };
};

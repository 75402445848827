import * as yup from 'yup';

import { BRKFST_SUPPORTED_IMAGE_FORMATS } from 'shared/config/fileFormats';
import { MEDIA } from 'shared/config/media';
import { IMAGE_SIZE_LIMIT, MESSAGES } from 'shared/config/validations';

export type ImageFileInputValue =
  | {
      file: File;
    }
  | typeof DEFAULT_VALUE;

export const DEFAULT_VALUE = {
  file: {
    name: '',
    type: '',
    size: 0,
  },
};

export const getImageInputSchema = (
  required: boolean,
  validationMessage?: string,
  supportedFormats: string[] = [...BRKFST_SUPPORTED_IMAGE_FORMATS, MEDIA.MIME_TYPES.GIF],
) => {
  const baseSchema = yup
    .object({
      file: yup.mixed(),
    })
    .nullable()
    .supportedFile({
      message: validationMessage || MESSAGES.IMAGE_FORMAT_ERROR,
      supportedFormats,
    })
    .fileSize({
      fileSizeMax: IMAGE_SIZE_LIMIT,
      message: MESSAGES.IMAGE_SIZE_LIMIT,
    })
    .fileNameLength({ message: MESSAGES.FILE_NAME_LENGTH })
    .fileNameFormat();
  if (required) {
    return baseSchema.test({
      name: 'required',
      message: MESSAGES.REQUIRED_FIELD,
      test(val) {
        return Boolean(val?.file?.name);
      },
    });
  }
  return baseSchema;
};

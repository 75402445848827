import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  flexDirection: 'column',
  height: '100%',
  overflowY: 'auto',
  '.landing-template': {
    '&__header': {
      borderTop: `4px solid ${COLORS.PRIMARY500}`,
      borderBottom: `1px solid ${COLORS.PRIMARY100}`,
      boxShadow: 'none',
    },
    '&__action-text': {
      width: 'max-content',
    },
  },
});

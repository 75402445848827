import { PerformanceFilters } from 'features/performance/enums';
import { emptySelectOption, getSelectInputSchema } from 'shared/components/molecules/Select/SelectInput';
import { MESSAGES } from 'shared/config/validations';
import * as yup from 'yup';

export const OPERATOR = 'operator';
export const VALUE = 'value';
export const FIELD = 'field';

export const facebookFilterOptions = [
  emptySelectOption,
  {
    label: 'Ad Id',
    value: PerformanceFilters.AD_ID,
  },
  {
    label: 'Campaign Id',
    value: PerformanceFilters.CAMPAIGN_ID,
  },
  {
    label: 'Adset Id',
    value: PerformanceFilters.ADSET_ID,
  },
  {
    label: 'Ad Name',
    value: PerformanceFilters.AD_NAME,
  },
  {
    label: 'Campaign Name',
    value: PerformanceFilters.CAMPAIGN_NAME,
  },
  {
    label: 'Adset Name',
    value: PerformanceFilters.ADSET_NAME,
  },
];

export const tiktokFilterOptions = [
  emptySelectOption,
  {
    label: 'Ad Id',
    value: PerformanceFilters.AD_ID,
  },
  {
    label: 'Campaign Id',
    value: PerformanceFilters.CAMPAIGN_ID,
  },
  {
    label: 'Adset Id',
    value: PerformanceFilters.ADSET_ID,
  },
];

export const nameFilterOptions = [
  emptySelectOption,
  {
    label: 'contain',
    value: 'CONTAIN',
  },
  {
    label: "doesn't contain",
    value: 'NOT_CONTAIN',
  },
];

const inOperatorOption = {
  label: 'in',
  value: 'IN',
};

export const idFilterOptions = [
  emptySelectOption,
  inOperatorOption,
  {
    label: 'not in',
    value: 'NOT_IN',
  },
];

export interface FilterValues {
  field: {
    label: string;
    value: string;
  };
  operatorId: {
    label: string;
    value: string;
  };
  operatorName?: {
    label: string;
    value: string;
  };
  valueName?: string;
  valueId: string[];
}

const TagSchema = yup.array(
  yup.string().test({
    name: 'number',
    message: 'Must be a number',
    test(value: string) {
      if (!value) return true;
      return new RegExp('[0-9]+').test(value);
    },
  }),
);

export const filterSchema = yup.object({
  field: getSelectInputSchema(true, yup.string()),
  operatorName: yup.mixed().when([FIELD], {
    is: (field) => (field.value ? field.value.includes('.name') : false),
    then: getSelectInputSchema(true, yup.string()),
    otherwise: getSelectInputSchema(false, yup.string()),
  }),
  operatorId: yup.mixed().when([FIELD], {
    is: (field) => (field.value ? field.value.includes('.id') : false),
    then: getSelectInputSchema(true, yup.string()),
    otherwise: getSelectInputSchema(false, yup.string()),
  }),
  valueName: yup.string().when([FIELD], {
    is: (field) => (field.value ? field.value.includes('.name') : false),
    then: yup.string().customRequiredString(),
    otherwise: yup.string(),
  }),
  valueId: TagSchema.when([FIELD], {
    is: (field) => (field.value ? field.value.includes('.id') : false),
    then: TagSchema.min(1, MESSAGES.REQUIRED_FIELD),
    otherwise: TagSchema,
  }),
});

// For formik touch logic, the initial values must not be the same object, hence making copies of emptySelectOption
export const initialValues = {
  field: { ...emptySelectOption },
  operatorId: { ...emptySelectOption },
  operatorName: { ...emptySelectOption },
  valueName: '',
  valueId: [],
};

export const tiktokInitialValues = {
  field: { ...emptySelectOption },
  operatorId: { ...inOperatorOption },
  valueId: [],
};

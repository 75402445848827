import React, { useState } from 'react';
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box, Flex, Text } from 'shared/components/display';

import { makeLabel } from 'shared/lib/formik';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';

import FormContainer from '../FormContainer/FormContainer';
import SideNav from '../SideNav/SideNav';

const MobileView = ({ sections, activeForm, onTabClick, user, userId, onSubmit, tags, createOrUpdatePhone }) => {
  const [showSideNav, setShowSideNav] = useState(true);

  return showSideNav ? (
    <SideNav
      sections={sections}
      activeForm={activeForm}
      onTabClick={(tabName) => {
        onTabClick(tabName);
        setShowSideNav(false);
      }}
      isMobile
    />
  ) : (
    <Box className="creator-account-settings__form-container">
      {!['Profile Info', 'Work Info'].includes(activeForm) ? (
        <Flex className="creator-account-settings__form-header-wrapper">
          <FontAwesomeIcon
            icon={faAngleLeft}
            className="creator-account-settings__back-btn"
            fixedWidth
            color={COLORS.NEUTRAL600}
            onClick={() => setShowSideNav(true)}
          />
          <Text className="creator-account-settings__mobile-form-header" variant={TEXT_VARIANTS.SUBHEADING}>
            {makeLabel(`${activeForm}`)}
          </Text>
        </Flex>
      ) : (
        <></>
      )}

      <FormContainer
        activeForm={activeForm}
        user={user}
        userId={userId}
        onSubmit={onSubmit}
        tags={tags}
        createOrUpdatePhone={createOrUpdatePhone}
        onNavigateBack={() => setShowSideNav(true)}
      />
    </Box>
  );
};

export default MobileView;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '.delete-draft-button': {
    color: COLORS.WHITE,
    backgroundColor: COLORS.RED200,
    '&:hover': {
      backgroundColor: COLORS.RED500,
      color: COLORS.WHITE,
    },
  },
});

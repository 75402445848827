import React from 'react';
import cs from 'classnames';
import { Box, Button } from 'shared/components/display';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { faThumbtack as faThumbtackSolid } from '@fortawesome/pro-solid-svg-icons';
import { faThumbTack } from '@fortawesome/pro-regular-svg-icons';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FILE_COPY } from 'shared/config/copy';

import styles from './styles';

interface Props {
  isPinned?: boolean;
  loading?: boolean;
  className?: string;
  // has permission to pin files
  canPin?: boolean;
  maxPinLimitReached?: boolean;
  onPin: () => void;
}
const PinFileControls: React.FC<Props> = ({ isPinned, loading, className, canPin, onPin, maxPinLimitReached }) => {
  // to prevent lightbox from opening when clicking on the pin button
  const stopPropagation = (e) => e.stopPropagation();

  return (
    <Tooltip
      disabled={canPin || isPinned}
      onClick={stopPropagation}
      content={
        <Box css={styles} className="pin-file-controls__pin-tooltip">
          {FILE_COPY.TOOLTIP_PINNED_FILES_LIMIT_REACHED}
        </Box>
      }
    >
      <Box css={styles} className={cs('pin-file-controls', className)} onClick={stopPropagation}>
        <Button
          onClick={maxPinLimitReached && !isPinned ? undefined : onPin}
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          className={cs('pin-file-controls__pin-btn', {
            'pin-file-controls__pin-btn--pinned': isPinned,
            'pin-file-controls__pin-btn--full': canPin && maxPinLimitReached && !isPinned,
            'pin-file-controls__pin-btn--disabled': !canPin && isPinned,
            'pin-file-controls__pin-btn--hidden': !canPin && !isPinned,
          })}
          data-cy={`pin-file-controls__pin-btn${isPinned ? '--pinned' : ''}`}
          disabled={loading}
        >
          <FontAwesomeIcon icon={isPinned ? faThumbtackSolid : faThumbTack} size="sm" />
        </Button>
      </Box>
    </Tooltip>
  );
};

export default PinFileControls;

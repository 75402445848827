import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';

export const valueContainerStyles = {
  container: (base, state) => ({
    ...base,
    width: state.selectProps.width,
  }),
  menu: (base) => ({
    ...base,
    zIndex: zIndex.zDropdown,
    width: '100%',
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: zIndex.zDropdownPortal,
  }),
  input: (base) => ({
    ...base,
    padding: '0.26rem 0',
  }),
  placeholder: (base, state) => ({
    ...base,
    visibility: state.selectProps.hidePlaceholder && 'hidden',
    position: 'absolute',
    color:
      (state.isDisabled && COLORS.NEUTRAL300) ||
      ((state.isFocused || state.selectProps.menuIsOpen) && COLORS.PRIMARY500) ||
      (state.selectProps.hasValue && COLORS.NEUTRAL700) ||
      COLORS.NEUTRAL700,
    backgroundColor: COLORS.WHITE,
    padding: '0 0.5rem',
    transform:
      (state.isFocused || state.selectProps.hasValue || state.selectProps.menuIsOpen) &&
      'scale(0.75) translateY(-34px)',
    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1)',
    transformOrigin: 'bottom left',
    width: 'auto',
    textTransform: state.selectProps.capitalizePlaceolder ? 'capitalize' : 'none',
    whiteSpace: 'nowrap',
    top: '8px',
    left: '0px',
    zIndex: zIndex.zButton,
  }),
  valueContainer: (base, state) => ({
    ...base,
    paddingTop: state.isMulti && '2px',
  }),
};

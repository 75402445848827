import { Box } from 'shared/components/display';
import tileContainerStyles from 'shared/components/atoms/TileContainer/tileContainerStyles';
import cs from 'classnames';
import React from 'react';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  stacked?: boolean;
  singleLine?: boolean;
}
const TileContainer: React.FC<Props> = ({ children, stacked, singleLine }) => {
  return (
    <Box
      className={cs('tile-container', {
        'tile-container--stacked': stacked,
        'tile-container--single-line': singleLine,
      })}
      css={tileContainerStyles}
    >
      {children}
    </Box>
  );
};

export default TileContainer;

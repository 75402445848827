const COLORS = {
  // Main Colors, default Rebass values
  primary: '#2D6BFF',
  secondary: '',

  PRIMARY25: '#F4F6F9',
  PRIMARY50: '#E7EDF9',
  PRIMARY100: '#D1DEF9',
  PRIMARY200: '#A5BFF7',
  PRIMARY300: '#79A1F7',
  PRIMARY400: '#4A81F5',
  PRIMARY500: '#2971F9',
  PRIMARY600: '#0045CE',
  PRIMARY700: '#0034A7',
  PRIMARY800: '#002777',
  PRIMARY900: '#001944',

  // Semantic Colors - Errors & Dangers
  RED25: '#FCF2F3',
  RED50: '#FBE4E4',
  RED100: '#F9C7C7',
  RED200: '#F49B9C',
  RED300: '#EC6C6E',
  RED400: '#DF4648',
  RED500: '#C7282B',
  RED600: '#AE151A',
  RED700: '#93070F',
  RED800: '#720208',
  RED900: '#480204',

  // Semantic Colors - Warnings
  ORANGE25: '#FCF8F2',
  ORANGE50: '#FCF4E6',
  ORANGE100: '#FBEACA',
  ORANGE200: '#FBDCA4',
  ORANGE300: '#F9CA7B',
  ORANGE400: '#F2B553',
  ORANGE500: '#E79F35',
  ORANGE600: '#C88324',
  ORANGE700: '#9C651B',
  ORANGE800: '#724A13',
  ORANGE900: '#49310C',

  // Semantic Colors - Success
  GREEN25: '#F2F9F5',
  GREEN50: '#E4FAEB',
  GREEN100: '#C3F2D1',
  GREEN200: '#9EE4B2',
  GREEN300: '#7CD595',
  GREEN400: '#58C075',
  GREEN500: '#3EAC5C',
  GREEN600: '#299545',
  GREEN700: '#1B7D33',
  GREEN800: '#116123',
  GREEN900: '#0A4219',

  // NEUTRALs
  WHITE: '#FFFFFF',
  NEUTRAL0: '#FFFFFF',
  NEUTRAL25: '#FDFDFE',
  NEUTRAL50: '#F5F8FA',
  NEUTRAL100: '#E5EAED',
  NEUTRAL200: '#D3DADF',
  NEUTRAL300: '#C1CAD1',
  NEUTRAL400: '#A2ADB7',
  NEUTRAL500: '#778793',
  NEUTRAL600: '#495D67',
  NEUTRAL700: '#2E4048',
  NEUTRAL800: '#1D2730',
  NEUTRAL900: '#090F11',
  BLACK: '#111213',

  TRANSPARENT: 'rgb(255,255,255,0)',

  // Brand Colors
  SECONDARY25: '#FBF5F8',
  SECONDARY50: '#FCE8F3',
  SECONDARY100: '#FAD1E9',
  SECONDARY200: '#FAA8D7',
  SECONDARY300: '#FF86CF',
  SECONDARY400: '#F258AF',
  SECONDARY500: '#DE3594',
  SECONDARY600: '#C31C78',
  SECONDARY700: '#A1105E',
  SECONDARY800: '#7B0748',
  SECONDARY900: '#47042B',

  // TODO: update once values are updated in figma
  // TERTIARY25: '#F2F6FB',
  // TERTIARY50: '#E1EEFB',
  // TERTIARY100: '#CEE3FA',
  // TERTIARY200: '#A3CDFA',
  // TERTIARY300: '#7AB6F9',
  // TERTIARY400: '#519FF7',
  // TERTIARY500: '#298AF7',
  // TERTIARY600: '#006BD6',
  // TERTIARY700: '#004CA6',
  // TERTIARY800: '#003979',
  // TERTIARY900: '#002E5C',
};

export const INSTAGRAM_COLORS = {
  YELLOW: '#F7D440',
  RED: '#F50000',
  PURPLE: '#B900B4',
};

export const FACEBOOK_COLORS = {
  BLUE: '#1877F2',
};

export const STRIPE_COLORS = {
  BLUE: '#635bff',
};

export enum TikTokColors {
  BLACK = '#010101',
  LIGHT_AQUA = '#69C9D0',
  RED = '#EE1D52',
}

export default COLORS;

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import { startCase } from 'lodash';

import adHeathSignUpFormStyles from 'features/accountPlatforms/pages/AdHealthSignUp/adHealthSignUpStyles';
import { Box, Flex, Image, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import TextInput from 'shared/components/TextInput';

import { apiAction } from 'shared/actions/api';
import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import E2EFLOWS from 'shared/config/e2eFlows';
import { RESOURCES } from 'shared/config/resourceNames';
import { TRACKING } from 'shared/config/tracking';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useQuery } from 'shared/hooks/useQuery';
import ahsExample from 'shared/images/account-health/ahs-example-v2.png';
import COLORS from 'shared/styles/colors';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { getABParams, getUTMParams, sha256Hash, trackEvent } from 'shared/utilities/trackingUtility';

import {
  AD_HEALTH_SIGN_UP_FIELDNAMES,
  AD_HEALTH_SIGN_UP_FORM_NAME,
  adHealthSignUpSchema,
  initialValues,
} from './config';

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
}

const AdHealthSignUp = () => {
  const { getQuery } = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useComponentLoading(RESOURCES.MARKETO_LEAD, false);

  const onSubmit = async (formData: FormData) => {
    const queryParams = getQuery();
    const utmParams = getUTMParams(queryParams);
    const abParams = getABParams(queryParams);
    const emailHash = await sha256Hash(formData.email);
    trackEvent(TRACKING.ADSCORE_DETAIL, { email: formData.email, type: UserType.ORG, userId: emailHash });
    // Send data to Marketo
    dispatch(
      apiAction({
        path: {
          route: 'marketo/lead',
        },
        method: 'POST',
        data: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          company: formData.company,
        },
        navigate,
        pushFunction: () => {
          return {
            pathname: LinkCreator.createLink({
              userType: UserType.ORG,
              routeKey: 'AD_HEALTH_CONNECT',
              query: {
                email: formData.email,
                firstName: formData.firstName,
                lastName: formData.lastName,
                ...abParams,
                ...utmParams,
              },
            }),
            state: { hasSignedUp: true },
          };
        },
        entity: RESOURCES.MARKETO_LEAD,
      }),
    );
  };

  return (
    <Box className="ad-health-sign-up" css={adHeathSignUpFormStyles}>
      <Box className={classNames(CLASSNAMES.MAIN_CONTAINER, 'ad-health-sign-up__form')}>
        <Text color={COLORS.SECONDARY500} variant={TEXT_VARIANTS.H6} as="h6" className="ad-health-sign-up__heading">
          {ACCOUNT_PLATFORM_COPY.HEADING_AD_HEALTH_SIGNUP}
        </Text>
        <Text variant={TEXT_VARIANTS.H3} className="ad-health-sign-up__subheading">
          {ACCOUNT_PLATFORM_COPY.SUBHEADING_AD_HEALTH_SIGNUP}
        </Text>
        <Text variant={TEXT_VARIANTS.BODY} color={COLORS.NEUTRAL600} className="ad-health-sign-up__pitch">
          {ReactHtmlParser(ACCOUNT_PLATFORM_COPY.PITCH_AD_HEALTH_ONBOARDING)}
        </Text>
        <Flex className="ad-health-sign-up__body">
          <Flex className="ad-health-sign-up__text-wrapper">
            <Text className="ad-health-sign-up__list-header">
              <strong>{ACCOUNT_PLATFORM_COPY.LIST_HEADER_AD_HEALTH_ONBOARDING}</strong>
            </Text>
            <Flex className="ad-health-sign-up__list-item">
              <FontAwesomeIcon icon={faCheck} color={COLORS.SECONDARY500} />
              <Text variant={TEXT_VARIANTS.BODY}>{ACCOUNT_PLATFORM_COPY.FIRST_CHECK_AD_HEALTH_ONBOARDING}</Text>
            </Flex>
            <Flex className="ad-health-sign-up__list-item">
              <FontAwesomeIcon icon={faCheck} color={COLORS.SECONDARY500} />
              <Text variant={TEXT_VARIANTS.BODY}>{ACCOUNT_PLATFORM_COPY.SECOND_CHECK_AD_HEALTH_ONBOARDING}</Text>
            </Flex>
            <Flex className="ad-health-sign-up__list-item">
              <FontAwesomeIcon icon={faCheck} color={COLORS.SECONDARY500} />
              <Text variant={TEXT_VARIANTS.BODY}>{ACCOUNT_PLATFORM_COPY.THIRD_CHECK_AD_HEALTH_ONBOARDING}</Text>
            </Flex>
            <span className="ad-health-sign-up__line" />
            <Text color={COLORS.NEUTRAL700} className="ad-health-sign-up__numbered-list-header">
              <strong>{ACCOUNT_PLATFORM_COPY.NUMBERED_LIST_HEADER_AD_HEALTH_SIGNUP}</strong>
            </Text>
            <Box className="ad-health-sign-up__numbered-list">
              <Text color={COLORS.NEUTRAL600}>
                {ReactHtmlParser(ACCOUNT_PLATFORM_COPY.FIRST_LIST_ITEM_AD_HEALTH_SIGNUP)}
              </Text>
              <Text color={COLORS.NEUTRAL600}>
                {ReactHtmlParser(ACCOUNT_PLATFORM_COPY.SECOND_LIST_ITEM_AD_HEALTH_SIGNUP)}
              </Text>
              <Text color={COLORS.NEUTRAL600}>
                {ReactHtmlParser(ACCOUNT_PLATFORM_COPY.THIRD_LIST_ITEM_AD_HEALTH_SIGNUP)}
              </Text>
            </Box>
          </Flex>
          <Image src={ahsExample} alt="loading" title="loading" className="ad-health-sign-up__image" />
        </Flex>
        <Formik
          onSubmit={onSubmit}
          validationSchema={adHealthSignUpSchema}
          validateOnBlur={false}
          initialValues={initialValues}
          enableReinitialize
        >
          {() => (
            <Box>
              <Form data-cy={`${E2EFLOWS.AD_HEALTH_ONBOARDING_FLOW}${AD_HEALTH_SIGN_UP_FORM_NAME}`}>
                <Box className="ad-health-sign-up__fields-wrapper">
                  <Field
                    name={AD_HEALTH_SIGN_UP_FIELDNAMES.FIRST_NAME}
                    component={TextInput}
                    placeholder={`${startCase(AD_HEALTH_SIGN_UP_FIELDNAMES.FIRST_NAME)}*`}
                    label={`${startCase(AD_HEALTH_SIGN_UP_FIELDNAMES.FIRST_NAME)}*`}
                    dataCy={`${E2EFLOWS.AD_HEALTH_ONBOARDING_FLOW}${AD_HEALTH_SIGN_UP_FIELDNAMES.FIRST_NAME}`}
                  />
                  <Field
                    name={AD_HEALTH_SIGN_UP_FIELDNAMES.LAST_NAME}
                    component={TextInput}
                    placeholder={`${startCase(AD_HEALTH_SIGN_UP_FIELDNAMES.LAST_NAME)}*`}
                    label={`${startCase(AD_HEALTH_SIGN_UP_FIELDNAMES.LAST_NAME)}*`}
                    dataCy={`${E2EFLOWS.AD_HEALTH_ONBOARDING_FLOW}${AD_HEALTH_SIGN_UP_FIELDNAMES.LAST_NAME}`}
                  />
                  <Field
                    name={AD_HEALTH_SIGN_UP_FIELDNAMES.EMAIL}
                    component={TextInput}
                    placeholder={`${startCase(AD_HEALTH_SIGN_UP_FIELDNAMES.EMAIL)}*`}
                    label={`${startCase(AD_HEALTH_SIGN_UP_FIELDNAMES.EMAIL)}*`}
                    dataCy={`${E2EFLOWS.AD_HEALTH_ONBOARDING_FLOW}${AD_HEALTH_SIGN_UP_FIELDNAMES.EMAIL}`}
                  />
                  <Field
                    name={AD_HEALTH_SIGN_UP_FIELDNAMES.COMPANY}
                    component={TextInput}
                    placeholder={`${startCase(AD_HEALTH_SIGN_UP_FIELDNAMES.COMPANY)}*`}
                    label={`${startCase(AD_HEALTH_SIGN_UP_FIELDNAMES.COMPANY)}*`}
                    dataCy={`${E2EFLOWS.AD_HEALTH_ONBOARDING_FLOW}${AD_HEALTH_SIGN_UP_FIELDNAMES.COMPANY}`}
                  />
                </Box>

                <Text variant={TEXT_VARIANTS.CAPTION} color={COLORS.NEUTRAL500} className="ad-health-sign-up__footnote">
                  {ReactHtmlParser(ACCOUNT_PLATFORM_COPY.TOS_AD_HEALTH_SIGNUP)}
                </Text>
                <FormButton
                  className="ad-health-sign-up__submit-btn"
                  loaderColor={COLORS.PRIMARY100}
                  dataCy={`${E2EFLOWS.AD_HEALTH_ONBOARDING_FLOW}signUpSubmitBtn`}
                  loading={loading}
                >
                  Submit
                </FormButton>
              </Form>
            </Box>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AdHealthSignUp;

import { VIDEO_COMPOSITION_COPY } from 'features/media/pages/VideoComposition/copy';
import { TRENDS_LIBRARY_COPY } from 'features/trends/pages/TrendsLibrary/copy';
import { ACCOUNT_COPY } from './accountCopy';
import { ACCOUNT_PLATFORM_COPY } from './accountPlatformCopy';
import { ADDRESS_COPY } from './addressCopy';
import { AUTH_COPY } from './authCopy';
import { BRIEF_COPY } from './briefCopy';
import { CARD_COPY } from './cardCopy';
import { COLLECTION_COPY } from './collectionCopy';
import { CONFIGURATION_COPY } from './configurationCopy';
import { CREATOR_COPY } from './creatorCopy';
import { EARNING_COPY } from './earningCopy';
import { FACEBOOK_COPY } from './facebookCopy';
import { FILE_COPY } from './fileCopy';
import { FORM_COPY } from './formCopy';
import FORM_ERRORS_COPY from './formErrorsCopy';
import { GENERIC_COPY } from './genericCopy';
import { INVOICE_COPY } from './invoiceCopy';
import { MESSAGE_COPY } from './messageCopy';
import { ORGANIZATION_COPY } from './organizationCopy';
import { ORGANIZATION_USER_COPY } from './organizationUserCopy';
import { REPORT_COPY } from './reportCopy';
import { ROLE_COPY } from './roleCopy';
import { STRIPE_COPY } from './stripeCopy';
import { SUBMISSION_COPY } from './submissionCopy';
import { TAG_COPY } from './tagCopy';
import { TASK_COPY } from './taskCopy';
import { USER_COPY } from './userCopy';

export {
  ACCOUNT_COPY,
  ACCOUNT_PLATFORM_COPY,
  ADDRESS_COPY,
  AUTH_COPY,
  BRIEF_COPY,
  CARD_COPY,
  COLLECTION_COPY,
  CONFIGURATION_COPY,
  CREATOR_COPY,
  EARNING_COPY,
  FILE_COPY,
  FACEBOOK_COPY,
  FORM_COPY,
  GENERIC_COPY,
  INVOICE_COPY,
  MESSAGE_COPY,
  ORGANIZATION_COPY,
  ORGANIZATION_USER_COPY,
  REPORT_COPY,
  ROLE_COPY,
  STRIPE_COPY,
  SUBMISSION_COPY,
  TASK_COPY,
  TAG_COPY,
  USER_COPY,
};

export const BRKFST_COPY = {
  ...ACCOUNT_COPY,
  ...ACCOUNT_PLATFORM_COPY,
  ...ADDRESS_COPY,
  ...AUTH_COPY,
  ...BRIEF_COPY,
  ...CARD_COPY,
  ...COLLECTION_COPY,
  ...CONFIGURATION_COPY,
  ...CREATOR_COPY,
  ...EARNING_COPY,
  ...FILE_COPY,
  ...FACEBOOK_COPY,
  ...FORM_COPY,
  ...GENERIC_COPY,
  ...INVOICE_COPY,
  ...MESSAGE_COPY,
  ...ORGANIZATION_COPY,
  ...ORGANIZATION_USER_COPY,
  ...REPORT_COPY,
  ...ROLE_COPY,
  ...STRIPE_COPY,
  ...SUBMISSION_COPY,
  ...TASK_COPY,
  ...TAG_COPY,
  ...USER_COPY,
  ...FORM_ERRORS_COPY,
  ...VIDEO_COMPOSITION_COPY,
  ...TRENDS_LIBRARY_COPY,
};

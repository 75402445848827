import { IgPostCaption } from 'features/users/components/molecules/IGPosts';
import { CarouselItem } from 'shared/components/molecules/CarouselItem';
import CarouselLightbox from 'shared/components/organisms/CarouselLightbox/CarouselLightbox';
import { MEDIA } from 'shared/config/media';
import { IGPost } from 'shared/typings/user';
import React from 'react';

interface Props {
  igPosts: IGPost[];
}

const IGPostCarousel: React.FC<Props> = ({ igPosts }) => {
  return (
    <CarouselLightbox ariaLabel="Instagram posts">
      {igPosts.map((post) => (
        <CarouselItem
          thumbnailUrl={post.mediaType === 'VIDEO' ? post.thumbnailUrl : post.url}
          name={post.id}
          mimeType={post.mediaType === 'VIDEO' ? MEDIA.TYPES.VIDEO : MEDIA.TYPES.IMAGE}
          url={post.url}
          key={post.id}
          Footer={<IgPostCaption captionData={{ igUrl: post.igUrl }} disabled={false} />}
        />
      ))}
    </CarouselLightbox>
  );
};

export default IGPostCarousel;

import React from 'react';
import { Field, Form, Formik } from 'formik';

import {
  ACCOUNT_DETAILS_FIELD_NAMES,
  accountDetailsSchema,
  AccountDetailsValues,
} from 'features/accounts/components/molecules/AccountDetailsForm/config';
import TagsInput from 'features/users/components/organisms/TagsInput';
import { Box } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import TextInput from 'shared/components/TextInput';

import { ACCOUNT_COPY } from 'shared/config/copy';
import { makeLabel } from 'shared/lib/formik';

import { MAX_NAME_LENGTH, MAX_SLOGAN_LENGTH } from '../NewAccountForm/config';

import AccountDetailsFormStyles from './accountDetailsFormStyles';

interface Props {
  initialValues: AccountDetailsValues;
  onSubmit: (values: AccountDetailsValues) => void;
  formDisabled?: boolean;
  toggleEdit?: () => void;
}

const AccountDetailsForm: React.FC<Props> = ({ initialValues, onSubmit, formDisabled, toggleEdit }) => {
  const onSubmitToggle = (data) => {
    onSubmit(data);
    if (toggleEdit) toggleEdit();
  };
  return (
    <Formik<AccountDetailsValues>
      initialValues={initialValues}
      onSubmit={onSubmitToggle}
      validationSchema={accountDetailsSchema}
    >
      <Form>
        <Box css={AccountDetailsFormStyles} className="account-details-form">
          <Field
            name={ACCOUNT_DETAILS_FIELD_NAMES.NAME}
            component={TextInput}
            label={makeLabel(ACCOUNT_DETAILS_FIELD_NAMES.NAME, true)}
            placeholder={makeLabel(ACCOUNT_DETAILS_FIELD_NAMES.NAME, true)}
            showCharactersCounter
            maxLength={MAX_NAME_LENGTH}
          />
          <Field
            name={ACCOUNT_DETAILS_FIELD_NAMES.DOMAIN}
            component={TextInput}
            label={makeLabel(ACCOUNT_DETAILS_FIELD_NAMES.DOMAIN, true)}
            placeholder={makeLabel(ACCOUNT_DETAILS_FIELD_NAMES.DOMAIN, true)}
          />
          <Field
            name={ACCOUNT_DETAILS_FIELD_NAMES.SLOGAN}
            component={TextInput}
            label={makeLabel(ACCOUNT_DETAILS_FIELD_NAMES.SLOGAN)}
            placeholder={makeLabel(ACCOUNT_DETAILS_FIELD_NAMES.SLOGAN)}
            showCharactersCounter
            maxLength={MAX_SLOGAN_LENGTH}
          />
          <Box py="10px">
            <TagsInput
              dataCy="basic-account-settings__industry-tags"
              name={ACCOUNT_DETAILS_FIELD_NAMES.TAGS}
              allowAddCategory={false}
              placeholder="Industry"
              theme="top-category-plain"
            />
          </Box>

          <FormButton className="account-details-form__submit-btn" disabled={formDisabled}>
            {ACCOUNT_COPY.BUTTON_EDIT_ACCOUNT}
          </FormButton>
        </Box>
      </Form>
    </Formik>
  );
};

export default AccountDetailsForm;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.date-range-setter': {
    padding: theme.space[5],
  },
  '.date-range-setter': {
    '&__grid': {
      display: 'grid',
      height: 'fit-content',
      gridTemplateRows: 'repeat(10, 1fr)',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gridGap: '5px 5px',
    },
    '&__comparison-display': {
      gridRow: '1 / span 2',
      gridColumn: '1 / span 2',
    },
    '&__comparison-toggle': {
      gridRow: '1 / span 1',
      gridColumn: '4 / span 1',
    },
    '&__calendar': {
      gridRow: '2 / span 8',
      gridColumn: '1 / span 3',
    },
    '&__date-presets': {
      gridRow: '2 / span 7',
      gridColumn: '4 / span 1',
    },
    '&__apply-btn': {
      gridRow: '10 / span 1',
      gridColumn: '4 / span 1',
    },
    '&__cancel-btn': {
      gridRow: '10 / span 1',
      gridColumn: '3 / span 1',
    },
    '&__date-preset-btn': {
      textAlign: 'left',
      paddingLeft: 0,
      display: 'block',
      color: COLORS.NEUTRAL700,
    },
    '&__date-preset-btn--active': {
      color: COLORS.PRIMARY500,
    },
    '&__comparison-list': {
      listStyle: 'none',
    },
    '&__list-item': {
      color: COLORS.BLACK,
    },
    '&__list-item::before': {
      content: '"■ "',
      color: COLORS.NEUTRAL100,
      fontSize: '1.5rem',
      lineHeight: '1rem',
    },
    '&__list-item--selected::before': {
      content: '"■ "',
      color: COLORS.PRIMARY200,
      fontSize: '1.5rem',
      lineHeight: '1rem',
    },
  },
});

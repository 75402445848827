import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'rebass/styled-components';
import { faCheckCircle, faClock, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { facebookBusinessAssetUpdated } from 'features/accountPlatforms/accountPlatforms.slice';
import { Flex, Text } from 'shared/components/display';

import { apiAction } from 'shared/actions/api';
import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import { API_FACEBOOK_ROUTES } from 'shared/config/routes/api/apiFacebookRoutes';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { FacebookBusinessAsset } from 'shared/typings/facebookBusinessAsset';
import { Status } from 'shared/typings/facebookBusinessAsset/enums';
const AUDIENCE_TOS_AGREEMENT_LINK = 'https://www.facebook.com/customaudiences/app/tos?act=';

const LABEL_MAP = Object.freeze({
  PAGE: 'Page Access',
  PIXEL: 'Pixel',
  INSTAGRAM_ACCOUNT: 'Instagram Account',
  AUDIENCE_TOS_AGREEMENT: 'Audience Agreement',
});

interface Props {
  accessId: string;
  tosAgreement?: FacebookBusinessAsset;
  disabled?: boolean;
}

const TOSAgreement: React.FC<Props> = ({ tosAgreement, accessId, disabled = false }) => {
  const tosPending = tosAgreement?.status !== Status.ACTIVE;
  const dispatch = useDispatch();

  const openTosAgreement = () => {
    dispatch(
      apiAction({
        path: {
          route: `${API_FACEBOOK_ROUTES.BUSINESS_ASSETS}/${tosAgreement?.id}`,
        },
        data: {
          status: Status.PENDING_TOS,
        },
        method: 'PATCH',
        successAction: facebookBusinessAssetUpdated,
        onSuccess: () => {
          window.open(`${AUDIENCE_TOS_AGREEMENT_LINK}${accessId}`, '_blank')?.focus();
        },
      }),
    );
  };

  const tosIcon = useMemo(() => {
    switch (tosAgreement?.status) {
      case Status.ACTIVE:
        return faCheckCircle;
      case Status.PENDING_TOS:
        return faClock;
      default:
        return faExclamationCircle;
    }
  }, [tosAgreement]);

  const tosColor = useMemo(() => {
    switch (tosAgreement?.status) {
      case Status.ACTIVE:
        return COLORS.GREEN500;
      case Status.PENDING_TOS:
        return COLORS.ORANGE400;
      default:
        return COLORS.PRIMARY500;
    }
  }, [tosAgreement]);

  return (
    <>
      {tosAgreement && (
        <Flex key={tosAgreement.type} className="facebook-permissions__permission facebook-permissions__tos-agreement">
          <FontAwesomeIcon
            className="collection-actions__requirements-icon"
            icon={tosIcon}
            size="xs"
            color={tosColor}
          />
          <Text variant={TEXT_VARIANTS.CAPTION} className="edit-audience__tos-agreement-text">
            {LABEL_MAP[tosAgreement.type]}
          </Text>
        </Flex>
      )}
      <Text className="edit-audience__text">{ACCOUNT_PLATFORM_COPY.AUDIENCE_INFO_TEXT}</Text>
      {tosPending && (
        <Button disabled={disabled} className="edit-audience__button" onClick={openTosAgreement} target="_blank">
          {ACCOUNT_PLATFORM_COPY.AUDIENCE_CONSENT}
        </Button>
      )}
    </>
  );
};

export default TOSAgreement;

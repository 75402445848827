export enum AccountPlatformStatus {
  PENDING = 3,
  /** @deprecated */
  RUNNING = 2,
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum Platforms {
  FACEBOOK = 'Facebook',
  TIKTOK = 'TikTok',
}

export enum PlatformShortNames {
  FACEBOOK = 'facebook',
  TIKTOK = 'tiktok',
}

export enum AccountPlatformType {
  SYSTEM_GENERATED = 0,
  USER_GENERATED = 1,
}

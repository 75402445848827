/* eslint-disable no-param-reassign */
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnFixed } from 'react-table-hoc-fixed-columns';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { startCase } from 'lodash';

import { getAdCopyIds, performanceAdCopyAdded, performanceAdCopyRemoved } from 'features/performance/performance.slice';
import PerformanceTableFooter from 'features/performance/TableComponents/TableFooter';
import Checkbox from 'shared/components/atoms/Checkbox';
import { Flex, Text } from 'shared/components/display';

import { DUPLICATE, NON_NUMERIC_COLUMNS, PERFORMANCE_DATA_TYPES, STATUS } from 'shared/config/performance';
import DashboardColumn from 'shared/models/DashboardColumnModel';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { formatDateWithSlashes } from 'shared/utilities/dateUtility';

const { BLACK } = COLORS;
const { DEFAULT } = PERFORMANCE_DATA_TYPES;

const NO_COLUMN_HEADER = [DUPLICATE];

export const usePerformanceTableColumns = (
  columns: string[],
  type: string,
  summary: {
    campaign_name?: string;
    total?: number;
  },
  dateRanges: null | { startDate: string; endDate: string }[],
): ColumnFixed[] => {
  const dispatch = useDispatch();
  const adCopyIds = useSelector(getAdCopyIds);

  const createColumn = (columnName): ColumnFixed => {
    const columnHeader = NO_COLUMN_HEADER.includes(columnName) ? '' : startCase(columnName);
    const dashboardFooter = <PerformanceTableFooter type={type} summary={summary} column={columnName} />;

    if (type !== DEFAULT && !NON_NUMERIC_COLUMNS.includes(columnName) && dateRanges !== null) {
      const dateRangeColumns = dateRanges.map(({ startDate, endDate }) => {
        const dateRangeColumnName = `${columnName}[${startDate},${endDate}]`;
        const dateRangeColumnDisplayName = `${formatDateWithSlashes(startDate)} - ${formatDateWithSlashes(endDate)}`;
        return DashboardColumn.createColumn(
          dateRangeColumnName,
          dateRangeColumnDisplayName,
          <PerformanceTableFooter type={type} summary={summary} column={dateRangeColumnName} />,
        );
      });
      const changeValueColumn = DashboardColumn.createColumn(
        `${columnName}[changeValue]`,
        'Change',
        <PerformanceTableFooter type={type} summary={summary} column={`${columnName}[changeValue]`} />,
      );
      const percentChangeColumn = DashboardColumn.createColumn(
        `${columnName}[changePercent]`,
        'Change(%)',
        <PerformanceTableFooter type={type} summary={summary} column={`${columnName}[changePercent]`} />,
      );
      dateRangeColumns.push(changeValueColumn, percentChangeColumn);
      return DashboardColumn.createColumnWithNestedColumns(columnName, columnHeader, dashboardFooter, dateRangeColumns);
    }
    const column = DashboardColumn.createColumn(columnName, columnHeader, dashboardFooter);
    if (columnName === STATUS) {
      // @ts-ignore
      column.Cell = ({ value }) => (
        <Flex
          style={{
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: '0.5rem',
              paddingRight: theme.space[3],
            }}
          >
            <FontAwesomeIcon icon={faCircle} />
          </Text>

          <Text color={BLACK}>{value}</Text>
        </Flex>
      );
    } else if (columnName === DUPLICATE) {
      column.Cell = ({
        row: {
          _original: { id },
        },
      }) => {
        return (
          <Flex
            style={{
              marginTop: '0.25rem',
              width: '30px',
              alignItems: 'center',
            }}
          >
            <Checkbox
              name="adCopy"
              checked={adCopyIds.includes(id)}
              onChange={(event: any) => {
                const checked = Boolean(event.target.checked);
                if (checked) dispatch(performanceAdCopyAdded({ id }));
                else dispatch(performanceAdCopyRemoved({ id }));
              }}
            />
          </Flex>
        );
      };
    }
    return column;
  };

  return useMemo(() => [{ expander: true, show: false }, ...columns.map(createColumn)], [type, summary, adCopyIds]);
};

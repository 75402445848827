import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.asset-to-trend-form-modal': {},
  '.asset-to-trend-form-modal': {
    '&__btn-wrapper': {
      gap: theme.space[2],
      padding: `${theme.space[4]} 0`,
      justifyContent: 'end',
    },
    '&__name-input': {
      margin: `0  0 ${theme.space[4]} 0`,
    },
    '&__text': {
      marginBottom: theme.space[2],
      color: COLORS.NEUTRAL600,
    },
  },
});

import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  height: '100%',
  backgroundColor: 'white',
  overflow: 'hidden',
  '.str-chat': {
    height: '100%',
    '&-channel-list': {
      '.str-chat__channel-list-messenger': {
        height: 'calc(100% - 30px)',
        [mediaquery.md]: {
          height: '100%',
        },
      },
      '.str-chat__channel-list-messenger__main': {
        padding: '10px',
      },
      '&:not(.str-chat-channel-list--open)': {
        left: '-100vw',
      },
      minHeight: '80vh',
      width: '300px',
    },
    '&-for-brands': {
      '.str-chat__message--received:not(.str-chat__message--me) .str-chat__avatar': {
        cursor: 'pointer',
      },
    },
    '.str-chat__reaction-list': {
      zIndex: zIndex.zBackdrop,
    },
    '&.str-chat-no-scroll': {
      '.str-chat__list': {
        overflowX: 'hidden',
      },
    },
    '&.messaging': {
      height: '100%',
      backgroundColor: '#fafafa', // stream chat's color
      top: '50px',
      '.str-chat__main-panel': {
        padding: 0,
      },
      '.str-chat__header-livestream': {
        borderRadius: 0,
      },
      '.str-chat__thread': {
        margin: 0,
        marginLeft: '5px',
        borderRadius: 0,
        top: '50px',
        height: 'calc(100% - 50px)',
      },
    },
    '&__input-footer': {
      display: 'none',
    },
    '&__modal': {
      '.str-chat__edit-message-form': {
        '@media screen and (max-width: 960px)': {
          width: '300px',
        },
      },
    },
    '&__no-channels-heading': {
      marginTop: '24px',
      textAlign: 'center',
    },
    '&__loading-channels': {
      width: '200vw',
      height: 'unset',
    },
    '&__loading-channels-item': {
      maxWidth: '300px',
      '@media screen and (max-width: 960px)': {
        marginLeft: '100vw',
      },
    },
    '&__channel-list-messenger': {
      height: '100%',
    },
    '&__channel-list-messenger__main': {
      height: '100%',
    },
    '&__message--me li': {
      textAlign: 'left',
    },
    '.image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image': {
      height: '100%',
    },
    '&__channel-search-results-header': {
      textAlign: 'center',
    },
    '&__channel-search-container': {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    '&__message-reaction-emoji': {
      paddingTop: '5px',
      marginLeft: '1px',
      marginRight: '1px',
    },
    '&__channel-search:has( .str-chat__channel-search-results-header)': {
      height: '100%',
      overflowY: 'auto',
    },
  },
});

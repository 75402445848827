import React, { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import { Box } from 'shared/components/display';
import styles from './styles';

interface Props {
  name: string;
}
const GroupInputSelectedCount = <Values extends {}>({ name }: Props) => {
  const { values } = useFormikContext<Values>();

  const selectedCount = useMemo(() => {
    return values[name].filter((value) => value.selected).length;
  }, [values[name]]);

  return (
    <Box css={styles} className="group-input-selected-count">
      <DefinitionList className="group-input-selected-count__dl" uppercase>
        <ListDetail text="Selected" detailsClassName="group-input-selected-count__dd">
          {selectedCount}
        </ListDetail>
      </DefinitionList>
    </Box>
  );
};

export default GroupInputSelectedCount;

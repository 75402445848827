export const FILE_COPY = {
  // Buttons
  BUTTON_BROWSE: 'Browse',
  BUTTON_CROP_SELECTION: 'Crop Selection',
  BUTTON_CROP: 'Crop Selection',
  BUTTON_DELETE_FILE: 'Delete File',
  BUTTON_EDIT_FILE: 'Edit File',
  BUTTON_EXPORT: 'Export',
  BUTTON_GENERATE_PERMUTATIONS: 'Generate More Permutations',
  BUTTON_REMOVE: 'Remove',
  BUTTON_SAVE_FILE: 'Save File',
  BUTTON_SELECT_ALL_FILE: 'Select All',
  BUTTON_THUMBNAIL: 'Capture Thumbnail',
  BUTTON_UPLOAD_FILE: 'Upload',
  BUTTON_UPLOAD_IMAGE: 'Upload New Image',
  BUTTON_UPLOAD_VIDEO_FILE: 'Upload New Video',
  BUTTON_DOWNLOAD_ALL: 'Download All Files',
  BUTTON_GET_FILE_INFO: 'Get Info',
  BUTTON_DOWNLOAD_NOW: 'Download Now',
  BUTTON_SELECTED_FILES: '{numFiles, plural, one {# File} other {# Files}} selected',

  // Tooltips
  TOOLTIP_BOLD: 'Bold',
  TOOLTIP_ITALIC: 'Italic',
  TOOLTIP_UNDERLINE: 'Underline',
  TOOLTIP_DELETE_COPY: 'Delete Copy',
  TOOLTIP_REVERT_SIZE: 'Revert to Original Image Size',
  TOOLTIP_CROP: 'Crop Image',
  TOOLTIP_DUPLICATE: 'Duplicate Frame',
  TOOLTIP_ADD_COPY: 'Add Copy',
  TOOLTIP_DELETE_FRAME: 'Delete Frame',
  TOOLTIP_EDIT_DISABLED_APPROVED: 'You cannot edit an approved file.',
  TOOLTIP_FILE_ALREADY_SUBMITTED: 'File already used in submission',
  TOOLTIP_FILE_ALREADY_IN_ANOTHER_SUBMISSION: 'File used in another submission',
  TOOLTIP_FILE_ASPECT_RATIO_ALREADY_USED: 'Aspect ratio already used',
  TOOLTIP_FILE_DELETE_APPROVED_SUBMISSION: 'You cannot delete an approved submission file',
  TOOLTIP_FILE_DELETE_DISABLED_PUBLISHED: 'You cannot delete a file pushed to FB/TikTok',
  TOOLTIP_PINNED_FILES_LIMIT_REACHED:
    'You have reached your maximum of 10 pinned videos or images. To pin this you must unpin another video or image first.',

  // Labels
  LABEL_ASPECT_RATIO: 'Fix Aspect Ratio',
  LABEL_UPLOADING_FILES: '{numFiles, plural, one {# File} other {# Files}} Uploading',
  LABEL_FILES_UPLOADED: '{numFiles, plural, one {# File} other {# Files}} Uploaded',
  LABEL_ALL_UPLOADS_FAILED: 'All Uploads Failed',
  LABEL_FILES_FILTER: '{fileType} Files {author, select, All {} other {by {author}}}',

  MODAL_DELETE_FILE: 'Are you sure you want to delete this file?',

  // Placeholder
  PLACEHOLDER_COPY: 'Enter Copy Here',
  PLACEHOLDER_GALLERY: 'No files to display',
  PLACEHOLDER_DRAG_N_DROP_BRAND_VIEW: 'No uploads yet',
  PLACEHOLDER_DRAG_N_DROP_GALLERY: 'Drag & Drop to upload a file',
  PLACEHOLDER_LABELS: 'labels',
  PLACEHOLDER_FILE_PREVIEW: 'No Preview Available',
  PLACEHOLDER_FILE_BEING_PROCESSED: 'We are still processing this file. Please try viewing it later.',
  PLACEHOLDER_TAGS: 'Tags',
  PLACEHOLDER_NAME: 'Name',

  // Image Alts
  ALT_PREVIEW: 'Video Preview',

  // Warnings
  WARNING_NO_PERMUTATIONS: 'No more permutations',
  WARNING_FILE_MISSING: 'Please select a file',
  WARNING_START_TIME_NEGATIVE: "The video can't start before the 0th second!",
  WARNING_END_TIME_BEFORE_START: "The video can't end before it has begun!",
  WARNING_LABEL_MISSING: 'Please enter at least one label',
  WARNING_MAX_FILES: 'Please upload up to {maxNumFiles} files',
  WARNING_ONE_FILE: 'Please upload 1 file',
  WARNING_SUPPORTED_MEDIA_DEFAULT: 'Please upload a valid file.',
  WARNING_SUPPORTED_MEDIA: 'Please upload a valid {supportedMedia} file.',
  WARNING_VIDEO_RESTRICTIONS: 'Supports #{supportedVideoFormats} files, up to 500MB each',
  WARNING_IMAGE_RESTRICTIONS: 'Supports #{supportedImageFormats} files, up to 500MB each',
  WARNING_FILE_RESTRICTIONS: 'Supports files up to 500MB each',
  WARNING_CSV_RESTRICTIONS: 'Supports CSV files up to 500MB each',
  WARNING_FILENAME: "Please enter a filename that doesn't use whitespace or special characters",
  WARNING_FILE_SIZE: 'Please upload files less than 150MB each',
  WARNING_UPLOAD_ATTEMPTING_CANCEL: 'Attempting Cancel',
  WARNING_UPLOAD_CANCELLED: 'Upload Cancelled',

  // Errors
  ERROR_NO_FILE_SELECTED: 'Please select at least one video to submit',
  ERROR_FILE_DUPLICATE_ERROR: 'Collection already contains file {fileName}',

  // Body
  BODY_DRAG_DROP_VIDEO: 'Drag & Drop a video',
  BODY_DRAG_DROP_IMAGE: 'Drag & Drop an image',
  BODY_DRAG_DROP_GIF: 'Drag & Drop a GIF',
  BODY_SUBTITLE_DRAG_DROP: 'or click to browse',
  BODY_UPLOAD_MEDIA: 'Upload {supportedMedia}',

  // Toasts
  TOAST_UNSUPPORTED_MEDIA: 'Cannot upload {fileName}. Unsupported file type.',

  FORM_HEADER_NEW_VIDEO: 'New Video',

  MODAL_HEADER_FILE_INFO: 'File Information',
  MODAL_LABEL_SUBMISSION_STATUS: 'Submission Status',
  MODAL_LABEL_NAME: 'Name',
  MODAL_LABEL_ID: 'ID',
  MODAL_LABEL_FB_ASSETS: 'Account Platforms with Facebook Assets',
  MODAL_LABEL_TIKTOK_ASSETS: 'Account Platforms with Tiktok Assets',
  MODAL_LABEL_TAGS: 'Tags',
  MODAL_VALUE_SUBMISSION_STATUS: 'This file has been included in a submission',
  MODAL_AUTHOR_NAME: 'Author',
  MODAL_ASPECT_RATIO: 'Aspect Ratio',
  MODAL_MODULE_NUM: 'Module',
  MODAL_LABEL_DIMENSIONS: 'Dimensions',

  HEADING_DOWNLOAD_READY: 'Your download will start shortly',
  SUBHEADING_DOWNLOAD_READY: "If it doesn't, click below",
  HEADING_DOWNLOAD_EXPIRED: 'Sorry, your download link has expired',
  SUBHEADING_DOWNLOAD_EXPIRED: 'Please sign in and request your files again',

  // Upload Drawer Validation
  ERROR_UNSUPPORTED_FILE: '{extension} is not supported',
};

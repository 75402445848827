import COLORS from 'shared/styles/colors';

const { NEUTRAL50, NEUTRAL800, RED500, ORANGE500, GREEN500 } = COLORS;

export const fonts = {
  body: `'Source Sans Pro', Arial, Helvetica Neue, Helvetica, sans-serif`,
  heading: `'Source Sans Pro', Arial, Helvetica Neue, Helvetica, sans-serif`,
  monospace: `'Lucida Console', Monaco, monospace`,
};

export const fontSizes = [
  '0.75rem', // 12px
  '0.875rem', // 14px
  '1rem', // 16px
  '1.125rem', // 18px
  '1.25rem', // 20px
  '1.5rem', // 24px
  '1.875rem', // 30px
  '2.25rem', // 36px
  '3rem', // 48px
];

export const fontWeights = {
  light: 300,
  body: 400,
  normal: 400,
  heading: 600,
  bold: 600,
};

const text = {
  h1: {
    fontSize: [7, 7, 8],
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 1.5,
  },
  h2: {
    fontSize: [7],
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 1.5,
  },
  h3: {
    fontSize: [6],
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 1.6,
  },
  h4: {
    fontSize: [5],
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 1.5,
  },
  h5: {
    fontSize: [4],
    fontWeight: 300,
    letterSpacing: 0,
    lineHeight: 1.6,
  },
  h6: {
    fontSize: [3],
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 1.8,
  },
  subheading: {
    fontSize: [2],
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: [1.8, 1.75],
  },
  category: {
    fontSize: [1],
    fontWeight: 600,
    letterSpacing: '0.05rem',
    lineHeight: [1.8, 1.5],
    textTransform: 'uppercase',
  },
  subheadingSM: {
    fontSize: [1],
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: [1.8, 1.5],
  },
  body: {
    fontSize: [2],
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: [1.6, 1.8],
    whiteSpace: 'pre-line',
  },
  bodySM: {
    fontSize: [1],
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: [1.8, 1.8],
  },
  overline: {
    fontSize: [0],
    fontWeight: 600,
    letterSpacing: '0.1rem',
    lineHeight: [2, 1.75],
    textTransform: 'uppercase',
  },
  caption: {
    fontSize: [0],
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 1,
  },
  label: {
    alignSelf: 'center',
    textTransform: 'capitalize',
    fontSize: [1],
    whiteSpace: 'nowrap',
  },
  code: {
    fontSize: [0],
    fontFamily: 'monospace',
    p: [5],
    color: NEUTRAL800,
    bg: NEUTRAL50,
    my: [3],
  },
  error: {
    color: RED500,
    fontSize: [2],
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: [1.8, 1.75],
  },
  warning: {
    color: ORANGE500,
    fontSize: [2],
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: [1.8, 1.75],
  },
  success: {
    color: GREEN500,
    fontSize: [2],
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: [1.8, 1.75],
  },
};

export enum TEXT_VARIANTS {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  SUBHEADING = 'subheading',
  SUBHEADING_SM = 'subheadingSM',
  BODY = 'body',
  CATEGORY = 'category',
  BODY_SM = 'bodySM',
  OVERLINE = 'overline',
  CAPTION = 'caption',
  LABEL = 'label',
  CODE = 'code',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success',
}

export default text;

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button } from 'rebass';
import { Field, Form, Formik } from 'formik';

import { Box, Flex, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import SocialMediaHandleInput from 'shared/components/SocialMediaHandleInput/SocialMediaHandleInput';
import TextInput from 'shared/components/TextInput';
import NotificationBanner from 'shared/components/atoms/NotificationBanner';
import { USER_COPY } from 'shared/config/copy';
import { makeLabel } from 'shared/lib/formik';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

import { getPortfolioSchema } from '../../CreatorOnboardingStepsFields';
import NewInstagramConnect from '../../molecules/NewInstagramConnect/NewInstagramConnect';

import styles from './styles';

interface Props {
  user: BrkfstUser;
  onSubmit: (values: any) => void;
}

const PORTFOLIO_FIELDNAMES = {
  PORTFOLIO_LINK: 'portfolioLink',
  TIKTOK_HANDLE: 'tiktokHandle',
};

const PortfolioForm: React.FC<Props> = ({ user, onSubmit }) => {
  const initialValues = {
    [PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK]: user.portfolioLink || '',
    [PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE]: user.socialMediaInfo?.tiktok || '',
  };

  const onCancel = (setValues: (values: any) => void) => {
    setValues(initialValues);
  };

  const hasInstagram = Boolean(user.instagramProfile);

  return (
    <Box css={styles} className="portfolio-form">
      <Flex className="portfolio-form__ig-header">
        <Text className="portfolio-form__ig-label" variant={TEXT_VARIANTS.H6}>
          Instagram
        </Text>
        <Text className="portfolio-form__optional" variant={TEXT_VARIANTS.CAPTION}>
          Optional
        </Text>
      </Flex>
      <Flex className="portfolio-form__ig-wrapper">
        <NewInstagramConnect
          userId={user.id}
          hasSocialProfile={hasInstagram}
          userHandle={user.instagramProfile?.igUsername}
          redirectURI={LinkCreator.createLink({
            userType: UserType.CREATOR,
            routeKey: 'ACCOUNT_SETTINGS',
            variables: {
              userId: user.id,
            },
          })}
        />
      </Flex>
      {!hasInstagram && (
        <NotificationBanner
          className="portfolio-form__connect-ig-wrapper"
          theme="warning"
          message={ReactHtmlParser(USER_COPY.DESCRIPTION_INSTAGRAM_CONNECT)}
          extraStyles={{ padding: '10px', alignItems: 'flex-start' }}
        />
      )}
      <Text variant={TEXT_VARIANTS.BODY} className="portfolio-form__connect-ig-benefits-text" color={COLORS.NEUTRAL500}>
        {ReactHtmlParser(USER_COPY.DESCRIPTION_IG_CONNECT_BENEFITS)}
      </Text>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={getPortfolioSchema(!!user.instagramProfile)}
      >
        {({ setValues }) => (
          <Form>
            <Text className="portfolio-form__label" variant={TEXT_VARIANTS.H6}>
              {makeLabel(PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK)}
            </Text>
            <Field
              name={PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK}
              component={TextInput}
              label={makeLabel(PORTFOLIO_FIELDNAMES.PORTFOLIO_LINK)}
              placeholder="Portfolio Link"
            />
            <Text className="portfolio-form__label" variant={TEXT_VARIANTS.H6}>
              TikTok
            </Text>
            <Field
              name={PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE}
              label={makeLabel(PORTFOLIO_FIELDNAMES.TIKTOK_HANDLE)}
              placeholder="tiktok123"
              component={SocialMediaHandleInput}
            />
            <Flex className="portfolio-form__btns-wrapper">
              <Button
                className="portfolio-form__cancel-btn"
                variant={BUTTON_VARIANTS.OPTION}
                type="button"
                onClick={() => onCancel(setValues)}
              >
                Cancel
              </Button>
              <FormButton type="submit" disableUntilTouched>
                Save Changes
              </FormButton>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PortfolioForm;

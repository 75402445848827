import React from 'react';
import { Route, Routes } from 'react-router-dom';
import UserRole from 'features/auth/pages/UserRole';
import NewUserWrapper from 'shared/router/NewUserRoutes/NewUserWrapper';

// Private Routes for new users who has not declared their type (brand or creator)
const NewUserRoutesComp = () => {
  return (
    <Routes>
      <Route element={<NewUserWrapper />}>
        <Route index element={<UserRole />} />
      </Route>
    </Routes>
  );
};

export default NewUserRoutesComp;

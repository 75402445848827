import { css } from '@emotion/react';

import { intl } from 'shared/lib/intl';
import theme from 'shared/styles/themes';

export const classes = {
  linkButton: 'link-button',
};

const LINK_COPIED = intl.formatMessage({ id: 'BUTTON_LINK_COPIED' });

export const ANIMATION_DURATION = 2000;

const styles = (text: string, margin: string = theme.space[4]) => css`
  & {
    .${classes.linkButton} {
      border: 0;
      padding: 0;
      background: none;
      outline: none;
      margin: ${margin};
      cursor: pointer;
    }
    .${classes.linkButton}::after {
      margin-left: ${theme.space[3]};
      color: ${theme.colors.NEUTRAL600};
      content: '';
    }
    .${classes.linkButton}:hover::after {
      content: '${text}';
    }
    .${classes.linkButton}:focus::after {
      animation: fadeOut ${ANIMATION_DURATION}ms ease backwards;
    }
    svg {
      color: ${theme.colors.NEUTRAL600};
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 0;
      content: '${LINK_COPIED}';
    }
    30%,
    80% {
      opacity: 1;
    }
    99% {
      opacity: 0;
      content: '${LINK_COPIED}';
    }
    100% {
      content: '';
    }
  }
`;

export default styles;

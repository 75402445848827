import { css } from '@emotion/react';

export default css({
  '&.selected-file-actions': {
    alignItems: 'start',
    flexWrap: 'wrap',
    '& button': {
      padding: `8px 10px 8px 4px`,
    },
  },
});

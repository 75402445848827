import { SelectValue } from 'shared/components/molecules/Select/interfaces';

import { MEDIA } from 'shared/config/media';

export const defaultOption = {
  label: 'All',
  value: 'all',
};

export const fileTypeOptions: SelectValue[] = [
  { ...defaultOption },
  {
    label: 'Images',
    value: MEDIA.TYPES.IMAGE,
  },
  {
    label: 'Videos',
    value: MEDIA.TYPES.VIDEO,
  },
  {
    label: 'Gifs',
    value: MEDIA.TYPES.GIF,
  },
];

export const defaultBriefOrderByOption = {
  label: 'Module: Lowest',
  value: JSON.stringify({
    orderBy: 'modNum',
    orderAsc: true,
  }),
};

export const defaultOrderByOption = {
  label: 'Date Uploaded: Latest',
  value: JSON.stringify({
    orderBy: 'dateCreated',
    orderAsc: false,
  }),
};

export const briefOrderByOptions: SelectValue[] = [
  { ...defaultBriefOrderByOption },
  {
    label: 'Module: Highest',
    value: JSON.stringify({
      orderBy: 'modNum',
      orderAsc: false,
    }),
  },
  {
    label: 'Date Uploaded: Earliest',
    value: JSON.stringify({
      orderBy: 'dateCreated',
      orderAsc: true,
    }),
  },
  {
    label: 'Date Uploaded: Latest',
    value: JSON.stringify({
      orderBy: 'dateCreated',
      orderAsc: false,
    }),
  },
];

export const orderByOptions: SelectValue[] = [
  {
    label: 'Date Uploaded: Earliest',
    value: JSON.stringify({
      orderBy: 'dateCreated',
      orderAsc: true,
    }),
  },
  { ...defaultOrderByOption },
];

export const FileSearchNames = {
  SEARCH: 'search',
  FILE_TYPE: 'fileType',
  CREATOR: 'creator',
  ORDER_BY: 'orderBy',
};

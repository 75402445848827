import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'rebass/styled-components';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';

import {
  campaignsLoaded,
  campaignsRemoved,
  getCampaigns,
} from 'features/accountPlatforms/facebook/Campaigns/campaigns.slice';
import { Box, Flex } from 'shared/components/display';
import { SelectInput } from 'shared/components/molecules/Select';

import { apiAction } from 'shared/actions/api';
import { API_FACEBOOK_ROUTES } from 'shared/config/routes/api/apiFacebookRoutes';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { breakpoints } from 'shared/styles/styleFunctions';

import styles from './styles';

interface Props {
  name: string;
  placeholder: string;
  accountPlatformId: number;
}

const AdsetSelect: React.FC<Props> = ({ name, placeholder, accountPlatformId }) => {
  const campaigns = useSelector(getCampaigns);
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  useEffect(() => {
    dispatch(
      apiAction({
        path: {
          route: API_FACEBOOK_ROUTES.CAMPAIGNS,
        },
        params: {
          accountPlatformId,
        },
        successAction: campaignsLoaded,
      }),
    );

    return function cleanup() {
      dispatch(campaignsRemoved());
    };
  }, [accountPlatformId]);

  const options = useMemo(
    () =>
      campaigns.map(({ name, platformCampaignId, adsets }) => ({
        label: `Campaign: ${name} (${platformCampaignId})`,
        options: adsets.map(({ name, platformAdsetId }) => ({
          value: platformAdsetId,
          label: `Adset: ${name} (${platformAdsetId})`,
          tooltipContent: `Adset: ${name} (${platformAdsetId})`,
        })),
      })),
    [campaigns],
  );

  const onClick = (e) => {
    e.preventDefault();
    dispatch(
      apiAction({
        path: {
          route: API_FACEBOOK_ROUTES.CAMPAIGNS_REFRESH,
        },
        params: {
          accountPlatformId,
        },
        successAction: campaignsLoaded,
      }),
    );
  };

  return (
    <Flex css={styles} className="adset-select">
      <Box data-focus-selector={name} className="adset-select__box">
        <Field
          name={name}
          component={SelectInput}
          isSearchable
          isMulti
          options={options}
          placeholder={placeholder}
          width={isMobile ? '100%' : '400px'}
        />
      </Box>
      <Button className="adset-select__btn" onClick={onClick} variant={BUTTON_VARIANTS.TEXT_ONLY}>
        <FontAwesomeIcon icon={faSync} color={COLORS.GREEN500} />
      </Button>
    </Flex>
  );
};

export default AdsetSelect;

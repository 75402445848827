import React from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import FormButton from 'shared/components/FormButton';
import { AUTH_COPY } from 'shared/config/copy';
import { Box } from 'shared/components/display';
import { PasswordInput, PasswordValidationInput } from 'shared/components/inputs/PasswordInput';
import {
  ResetPasswordFormValues,
  resetPasswordInitialValues,
  resetPasswordSchema,
  RESET_PASSWORD_FIELDNAMES,
} from 'features/auth/components/organisms/ResetPasswordForm/config';
import { startCase } from 'lodash';
import resetPasswordFormStyles from 'features/auth/components/organisms/ResetPasswordForm/resetPasswordFormStyles';

interface Props {
  onSubmit: (values: ResetPasswordFormValues, formikHelpers: FormikHelpers<ResetPasswordFormValues>) => void;
}
const ResetPasswordForm: React.FC<Props> = ({ onSubmit }) => {
  return (
    <Box className="reset-password-form" css={resetPasswordFormStyles}>
      <Formik<ResetPasswordFormValues>
        onSubmit={onSubmit}
        validationSchema={resetPasswordSchema}
        initialValues={resetPasswordInitialValues}
      >
        {() => (
          <Form>
            <Field
              name={RESET_PASSWORD_FIELDNAMES.PASSWORD}
              component={PasswordValidationInput}
              placeholder={`${startCase(RESET_PASSWORD_FIELDNAMES.PASSWORD)}*`}
              label={`${startCase(RESET_PASSWORD_FIELDNAMES.PASSWORD)}*`}
            />
            <Field
              name={RESET_PASSWORD_FIELDNAMES.CONFIRM_PASSWORD}
              component={PasswordInput}
              placeholder={`${startCase(RESET_PASSWORD_FIELDNAMES.CONFIRM_PASSWORD)}*`}
              label={`${startCase(RESET_PASSWORD_FIELDNAMES.CONFIRM_PASSWORD)}*`}
            />
            <FormButton className="reset-password-form__submit-btn">{AUTH_COPY.BUTTON_RESET_PASSWORD}</FormButton>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ResetPasswordForm;

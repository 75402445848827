import { Revision } from 'shared/typings/revisions';
import React, { useState } from 'react';
import SwipeableContainer from 'shared/components/molecules/SwipableContainer/SwipeableContainer';
import ModuleRevisionCard from 'shared/components/molecules/ModuleRevisionCard/ModuleRevisionCard';
import IconWithNotification from 'shared/components/atoms/IconWithNotification';
import { faNoteSticky } from '@fortawesome/pro-solid-svg-icons';
import ReviewOverlayModal from 'shared/components/molecules/ReviewOverlayModal';

interface Props {
  revisions?: Revision[];
  openRevision: boolean;
  setOpenRevision: (open: boolean | Function) => void;
  moduleNum: number;
  mediaWidth: number;
  brandName: string;
  className?: string;
}
const ModuleRevisionModal: React.FC<Props> = ({
  revisions = [],
  openRevision,
  setOpenRevision,
  moduleNum,
  mediaWidth,
  brandName,
  className,
}) => {
  const [index, setIndex] = useState(0);

  return (
    <>
      {revisions.length > 0 && (
        <IconWithNotification
          icon={faNoteSticky}
          onClick={() => setOpenRevision((prev) => !prev)}
          count={revisions?.length || 0}
          size="xl"
          className={className}
          dataCy="module-revision-modal__btn"
        />
      )}
      <ReviewOverlayModal
        ariaLabel="Revision Cards"
        id="modal-revision"
        isOpen={openRevision}
        onRequestClose={setOpenRevision}
        mediaWidth={mediaWidth}
        dataCy="module-revision-modal"
      >
        <SwipeableContainer pageIndex={index} updatePageIndex={setIndex}>
          {revisions.map((revision) => {
            return (
              <ModuleRevisionCard key={revision.id} revision={revision} moduleNum={moduleNum} brandName={brandName} />
            );
          })}
        </SwipeableContainer>
      </ReviewOverlayModal>
    </>
  );
};

export default ModuleRevisionModal;

import React from 'react';
import * as yup from 'yup';

import { Box, Text } from 'shared/components/display';
import { StepFieldProps } from 'shared/components/forms/MultiStepForm/interfaces';
import ButtonGroupInput from 'shared/components/molecules/ButtonGroupInput/ButtonGroupInput';
import { Option } from 'shared/components/molecules/ButtonGroupInput/ButtonGroupInput';
import { buttonGroupSchema } from 'shared/components/molecules/ButtonGroupInput/validation';

import { CREATOR_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

export const EthnicityFormFields: React.FC<StepFieldProps<EthnicityValues>> = ({ className }) => {
  return (
    <Box css={styles}>
      <Text variant={TEXT_VARIANTS.BODY} className={className}>
        {CREATOR_COPY.CAPTION_MULTIPLE_OPTIONS}
      </Text>
      <ButtonGroupInput
        name={ETHNICITY_FIELDNAMES.ETHNICITY}
        otherOptionFieldName={ETHNICITY_FIELDNAMES.ETHNICITY_OTHER}
        otherOptionValue={ethnicityOtherOption.value}
        isMultiSelect
      />
    </Box>
  );
};

export const ethnicityOtherOption = {
  label: 'Other',
  value: 'ethnicity:other',
};

export const ETHNICITY_FIELDNAMES = {
  ETHNICITY: 'ethnicity',
  ETHNICITY_OTHER: 'ethnicityOther',
};

export const ethnicitySchema: yup.SchemaOf<EthnicityValues> = yup.object({
  ethnicity: buttonGroupSchema,
  ethnicityOther: yup.string().when('ethnicity', {
    is: (value: Option[]) => {
      const ethnicityOption = value.find((op) => op.value === ethnicityOtherOption.value);
      return ethnicityOption?.selected;
    },
    then: yup.string().customRequiredString().onlyLettersAndSpaces(),
  }),
});

export type EthnicityValues = {
  ethnicity: Option[];
  ethnicityOther: string;
};

import React, { useState } from 'react';
import { Text } from 'rebass/styled-components';
import cs from 'classnames';

import { Box } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';

import { Input as InputType } from 'shared/lib/formik';
import Input from 'shared/styles/Input/Input';
import InputLabel from 'shared/styles/InputLabel/InputLabel';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

type Props = {
  innerRef: React.RefObject<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
  label: string;
  maxLength?: number;
  softMaxLength?: number;
  placeholder?: string;
  showCharactersCounter?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onInput?: React.FormEventHandler<HTMLInputElement>;
  softValidationMessage: string;
  autoComplete?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  dataCy?: string;
  id?: string;
  autoFocus?: boolean;
};

const TextInput: InputType<string, Props> = ({
  innerRef,
  field: { name, value = '', onChange: formikOnChange },
  form: { touched, errors, handleBlur },
  disabled = false,
  label,
  maxLength,
  softMaxLength = maxLength,
  placeholder,
  showCharactersCounter,
  className = '',
  onFocus,
  onInput,
  softValidationMessage,
  autoComplete,
  onChange,
  dataCy,
  id,
  autoFocus = false,
}) => {
  const [active, setActive] = useState(false);

  const handleFocus = (e) => {
    setActive(true);
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleChange = (e) => {
    e.persist();
    if (onChange) onChange(e);
    formikOnChange(e);
  };

  return (
    <Box className={cs(className, 'text-input')} css={styles}>
      <InputLabel
        htmlFor={name}
        disabled={disabled}
        active={active}
        hasValue={Boolean(value)}
        isInvalid={touched[name] && !!errors[name]}
      >
        {label}
      </InputLabel>

      <Input
        ref={innerRef}
        placeholder={placeholder}
        value={value}
        type="text"
        maxLength={maxLength}
        disabled={disabled}
        onChange={handleChange}
        onFocus={handleFocus}
        onInput={onInput}
        onBlur={(e) => {
          e.persist();
          setActive(false);
          handleBlur(e);
        }}
        name={name}
        data-lpignore={true}
        autoComplete={autoComplete}
        data-cy={dataCy}
        id={id}
        autoFocus={autoFocus}
        isInvalid={touched[name] && !!errors[name]}
      />
      <Text
        className={cs('text-input__characters-remaining', {
          'text-input__characters-remaining--active': active,
          'text-input__characters-remaining--max': maxLength == value?.length,
        })}
        variant={TEXT_VARIANTS.CAPTION}
      >
        {showCharactersCounter &&
          softMaxLength &&
          (softMaxLength - value?.length < 0
            ? '0 characters remaining'
            : `${softMaxLength - value?.length} characters remaining`)}
        {softValidationMessage}
      </Text>
      {Object.keys(errors)?.length > 0 ? (
        <FieldErrorMessage className="text-input__error-message" dataCy={`${dataCy}__error-message`} name={name} />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default TextInput;

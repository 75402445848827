import React from 'react';
import cs from 'classnames';
import { Flex } from 'shared/components/display';
import { PaginationSort, SortOptions } from 'shared/components/molecules/PaginationSort';
import paginationControlStyles from './paginationControlStyles';
import { FilterOptions, PaginationFilter } from 'shared/components/molecules/PaginationFilter';
import { useQuery } from 'shared/hooks/useQuery';

export interface PaginationControlProps<S = string, F = string> {
  sortOptions?: SortOptions<S>;
  filterOptions?: FilterOptions<F>;
  searchParams?: any;
  orderByParamName?: string;
  orderAscParamName?: string;
  pageParamsName?: string;
  filterParamsName?: string;
  className?: string;
  sortWidth?: string;
  filterWidth?: string;
}

const PaginationControl = <S, F>({
  filterOptions = [],
  searchParams = {},
  sortOptions = [],
  orderByParamName = 'orderBy',
  orderAscParamName = 'orderAsc',
  pageParamsName = 'page',
  filterParamsName = '',
  className,
  sortWidth = '200px',
  filterWidth = '200px',
}: PaginationControlProps<S, F>) => {
  const { setQuery, parseQuery } = useQuery();

  const updateQuery = (value) => {
    const query = parseQuery(value);
    setQuery({
      ...searchParams,
      ...query,
    });
  };

  const onChange = (option) => {
    updateQuery(option.value);
  };

  return (
    <Flex css={paginationControlStyles} className={cs('pagination-control', className)}>
      <PaginationSort
        onChange={onChange}
        orderAscParamName={orderAscParamName}
        orderByParamName={orderByParamName}
        pageParamsName={pageParamsName}
        searchParams={searchParams}
        // @ts-ignore
        sortOptions={sortOptions}
        className={cs({
          'pagination-control__hidden-input': !Boolean(sortOptions?.length),
        })}
        width={sortWidth}
      />
      <PaginationFilter
        // @ts-ignore
        filterOptions={filterOptions}
        pageParamsName={pageParamsName}
        filterParamsName={filterParamsName}
        searchParams={searchParams}
        onChange={onChange}
        className={cs({
          'pagination-control__hidden-input': !Boolean(filterOptions?.length),
        })}
        width={filterWidth}
      />
    </Flex>
  );
};

export default PaginationControl;

import { startCase } from 'lodash';
import * as yup from 'yup';

import { MESSAGES } from 'shared/config/validations';

export const commentMaxLength = 255;
export const adminCommentMaxLength = 1000;

export const COMMENT = 'comment';
export const commentLabel = startCase(COMMENT);

export interface Values {
  comment: string;
}

export const moduleReviewSchema = yup.object({
  [COMMENT]: yup.string().trim().required(MESSAGES.REQUIRED_FIELD),
});

export const moduleReviewInitialValues = {
  [COMMENT]: '',
};

import React from 'react';
import cs from 'classnames';
import ReactModal from 'react-modal';
import { Box, Button, Flex, Text } from 'shared/components/display';
import { StepWrapperProps } from 'shared/components/forms/MultiStepForm/interfaces';
import { TEXT_VARIANTS } from 'shared/styles/text';
import COLORS from 'shared/styles/colors';
import styles, { modalStyles } from './styles';
import { BUTTON_VARIANTS } from 'shared/styles/button';

const ModalStepWrapper: React.FC<StepWrapperProps> = ({
  children,
  isActive,
  stepKey = 0,
  totalSteps = 1,
  title,
  onCancel,
  extraWrapperProps = {},
}) => {
  const { open, wide, showButtonDivider } = extraWrapperProps;
  const showModal = Boolean(isActive) && open;
  return (
    <ReactModal
      isOpen={showModal}
      style={modalStyles}
      className="modal-step-wrapper__steps-modal"
      id="modal-root" // used by Select and MultiSelect to portal the menu element in order to make it visible outside of the modal. Open issue with library: https://github.com/JedWatson/react-select/issues/4088
    >
      <Box
        css={styles}
        className={cs('modal-step-wrapper', {
          'modal-step-wrapper--show-button-divider': showButtonDivider,
        })}
      >
        {totalSteps > 1 && (
          <Text variant={TEXT_VARIANTS.H5} color={COLORS.WHITE} className="modal-step-wrapper__steps-modal-text">
            Step {stepKey} of {totalSteps}
          </Text>
        )}
        <Flex
          className={cs('modal-step-wrapper__content', {
            'modal-step-wrapper__content--wide': wide,
          })}
        >
          <Box className="modal-step-wrapper__header">
            <Text as="h4" className={cs('modal-step-wrapper__header-text', 'endWithEllipsis')} id="modal-title">
              {title}
            </Text>
            <Button
              variant={BUTTON_VARIANTS.TEXT_ONLY}
              onClick={onCancel}
              className="modal-step-wrapper__header-btn"
              type="button"
            >
              &#10005;
            </Button>
          </Box>
          {children}
        </Flex>
      </Box>
    </ReactModal>
  );
};

export default ModalStepWrapper;

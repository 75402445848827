import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.check-box': {
    '&__wrapper': {
      marginTop: theme.space[3],
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
    '&__label': {
      color: COLORS.BLACK,
      margin: `${theme.space[2]} ${theme.space[3]}`,
    },
    '&__label--disabled': {
      color: COLORS.NEUTRAL500,
    },
    '&__input': {
      transform: 'scale(1.5)',
    },
  },
});

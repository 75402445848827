import { css } from '@emotion/react';
import theme from 'shared/styles/themes';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  overflow: 'auto',

  [mediaquery.md]: {
    overflow: 'hidden',
    height: '100%',
  },

  '.creative-performance': {
    '&__table': {
      borderCollapse: 'separate',
      borderSpacing: '0',
      border: `1px solid ${COLORS.NEUTRAL100}`,
      borderBottom: 'none',
      width: '100%',
      height: '100%',
    },

    '&__table--no-results': {
      height: '80vh',
    },

    '&__no-results-text': {
      fontStyle: 'italic',
      padding: '0 21%',
      position: 'absolute',
      top: '10%',
      left: '0',
      right: '0',

      [mediaquery.md]: {
        fontSize: theme.fontSizes[3],
      },
    },

    '&__table-row': {
      background: 'white',
    },

    '&__table-cell, &__table-header': {
      margin: '0',
      whiteSpace: 'nowrap',
      borderTopWidth: '0px',
      height: '45px',
      backgroundColor: COLORS.WHITE,
      padding: '0 21px',
    },

    '&__head-col-header': {
      fontFamily: theme.fonts.heading,
      fontSize: theme.fontSizes[1],
      color: COLORS.NEUTRAL700,
      borderBottom: `2px solid ${COLORS.NEUTRAL100}`,
      display: 'flex',
      alignItems: 'center',
      position: 'sticky',
      height: '45px',
      left: '0',
      top: 'auto',
      backgroundColor: COLORS.WHITE,
      margin: '0',
      whiteSpace: 'nowrap',
      borderTopWidth: '0px',

      justifyContent: 'left',
      paddingLeft: '24px',
    },

    '&__table-header': {
      fontFamily: theme.fonts.heading,
      fontSize: theme.fontSizes[1],
      color: COLORS.NEUTRAL700,
      borderBottom: `2px solid ${COLORS.NEUTRAL100}`,
      '&--centered': {
        paddingLeft: '32px',
      },
    },

    '&__table-cell': {
      maxHeight: '50px',
    },

    '&__table-cell--no-results': {
      textAlign: 'center',
      position: 'relative',
    },

    '&__head-col': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'sticky',
      height: '100%',
      left: '0',
      top: 'auto',
      backgroundColor: COLORS.WHITE,
      margin: '0',
      whiteSpace: 'nowrap',
      borderTopWidth: '0px',
      paddingLeft: '21px',

      [mediaquery.md]: {
        justifyContent: 'left',
        height: '100%',
      },
    },

    '&__creative-thumbnail': {
      width: '36px',
      height: '36px',
      borderRadius: '10px',
      marginRight: '11px',
    },

    '&__creative-status': {
      fontSize: theme.fontSizes[0],
      textTransform: 'uppercase',
      padding: '3px 9.22px',
      '&--earning': {
        background: COLORS.GREEN100,
        color: COLORS.GREEN900,
        borderRadius: '1.5px',
      },
    },
    '&__swipeable-container': {
      paddingLeft: '0px',
      paddingTop: '10px',
    },
  },
});

import { useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, isNil, isNumber, omitBy, uniqWith } from 'lodash';

import { PerformanceLevels } from 'features/performance/enums';
import {
  getAdCopyIds,
  getPerformanceData,
  performanceDataFailed,
  performanceDataLoaded,
  performanceDataReset,
} from 'features/performance/performance.slice';

import { apiAction } from 'shared/actions/api';
import { ACTIONS } from 'shared/config/actions';
import { DEFAULT_PARAMS, STATUS } from 'shared/config/performance';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_PERFORMANCE_ROUTES } from 'shared/config/routes/api/apiPerformanceRoutes';
import { PerformanceContext } from 'shared/contexts/performance/PerformanceProvider';
import { PerformanceDateRange, PerformanceParams, PerformanceState } from 'shared/contexts/performance/types';
import { downloadPerformanceExport } from 'shared/middleware/downloadFile';
import { formatComparisonDateRanges, formatISODate } from 'shared/utilities/dateUtility';
import { formatBreakdownParams } from 'shared/utilities/Performance/breakdownUtility';
import { formatReportDateRange, formatRequestParams } from 'shared/utilities/Performance/dashboardUtility';
import { TopBottomAnalysisValues } from 'features/performance/TopBottomFormDropdown/types';
import { initialValues as topBottomInitialValues } from 'features/performance/TopBottomFormDropdown/config';

const { PERFORMANCE: PERF_ACTIONS } = ACTIONS;

export const usePerformance = () => {
  const { state: performanceState, dispatch: performanceDispatch } = useContext(PerformanceContext);
  const performanceData = useSelector(getPerformanceData);
  const adCopyIds = useSelector(getAdCopyIds);
  // TO DO: [context]

  const dispatch = useDispatch();

  const committedFields = useMemo(() => {
    const {
      committedParams: { fields },
    } = performanceState;
    return fields ? fields.split(',') : [];
  }, [performanceState.committedParams.fields]);

  const committedDateRanges: PerformanceDateRange = useMemo(() => {
    const {
      committedParams: { dateRanges },
    } = performanceState;
    return dateRanges ? JSON.parse(dateRanges) : null;
  }, [performanceState.committedParams.dateRanges]);

  // TO DO: [callback]
  const updateField = useCallback(
    (field, value) => {
      performanceDispatch({
        type: PERF_ACTIONS.SET_FIELD,
        field,
        value,
      });
    },
    [performanceDispatch],
  );
  // TO DO: [callback]
  const updateFields = useCallback(
    (value) => {
      performanceDispatch({
        type: PERF_ACTIONS.SET_FIELDS,
        value,
      });
    },
    [performanceDispatch],
  );
  // TO DO: [callback]
  const updatePage = useCallback(
    (page = 0) => {
      if (isNumber(page)) updateField('page', page);
    },
    [updateField],
  );

  const updatePageSize = useCallback(
    (pageSize = 10) => {
      if (isNumber(pageSize)) updateField('pageSize', pageSize);
    },
    [updateField],
  );

  // TO DO: [callback]
  const updateColumnsChosen = useCallback(
    (values) => {
      const columnsChosen = [...values];
      const statusNdx = columnsChosen.indexOf(STATUS);
      if (statusNdx > 0) {
        columnsChosen.unshift(columnsChosen.splice(statusNdx, 1)[0]);
      }
      updateFields({
        columnsChosen,
        topBottom: topBottomInitialValues,
        params: {
          ...performanceState.params,
          fields: columnsChosen.toString(),
        },
      });
    },
    [updateFields, performanceState],
  );

  // TO DO: [callback]
  const updateAttributionWindow = useCallback(
    (attributionWindow) => {
      updateFields({
        attributionWindow,
        topBottom: topBottomInitialValues,
        params: {
          ...performanceState.params,
          attributionWindow: JSON.stringify(attributionWindow),
        },
      });
    },
    [updateFields, performanceState.params],
  );
  // TO DO: [callback]
  const addFilter = useCallback(
    (filter) => {
      const concatenatedFilters = [...performanceState.filters, filter];
      const filters = uniqWith(concatenatedFilters, isEqual);
      updateFields({
        filters,
        params: {
          ...performanceState.params,
          filtering: JSON.stringify(filters),
        },
      });
    },
    [updateFields, performanceState],
  );
  // TO DO: [callback]
  const removeFilter = useCallback(
    (filterToRemove) => {
      const filters = performanceState.filters.filter((filter) => !isEqual(filterToRemove, filter));
      updateFields({
        filters,
        params: {
          ...performanceState.params,
          filtering: JSON.stringify(filters),
        },
      });
    },
    [updateFields, performanceState],
  );

  const clearFilters = () => {
    updateFields({
      filters: [],
      params: {
        ...performanceState.params,
        filtering: DEFAULT_PARAMS.filtering,
      },
    });
  };

  const updateBreakdown = useCallback(
    (breakdown, breakdownValue) => {
      updateFields({
        breakdown,
        breakdownValue,
        topBottom: topBottomInitialValues,
        params: {
          ...performanceState.params,
          breakdowns: formatBreakdownParams(breakdown),
        },
      });
    },
    [updateFields, performanceState],
  );

  const updateTopBottom = useCallback(
    (topBottomValues: TopBottomAnalysisValues) => {
      updateFields({
        topBottom: topBottomValues,
      });
    },
    [updateFields, performanceState],
  );

  const updateDateRange = useCallback(
    (dateRange, datePreset, toggleComparison) => {
      let dateRanges: PerformanceDateRange = null;
      if (toggleComparison) {
        dateRanges = formatComparisonDateRanges(dateRange);
      }
      updateFields({
        dateRange,
        dateRanges,
        datePreset,
        params: {
          ...performanceState.params,
          datePreset,
          startDate: formatISODate(dateRange.from),
          endDate: formatISODate(dateRange.to),
          dateRanges: dateRanges && JSON.stringify(dateRanges),
        },
      });
    },
    [updateFields, performanceState],
  );

  // TO DO: [callback]
  const updateCommittedParams = useCallback(
    (params) => {
      updateFields({
        params,
        committedParams: params,
      });
    },
    [updateFields],
  );

  const fetchPerformanceData = (requestParams) => {
    if (requestParams.client) {
      dispatch(
        apiAction({
          method: 'GET',
          path: {
            route: API_PERFORMANCE_ROUTES.FETCH_DATA,
            variables: {
              client: requestParams.client,
            },
          },
          successAction: performanceDataLoaded,
          errorAction: performanceDataFailed,
          params: requestParams,
          entity: RESOURCES.PERFORMANCE,
          config: { addBetterGetParams: false },
        }),
      );
    }
  };

  const updateLevelAndFetchData = (level: PerformanceLevels, columns: any[] | null = null) => {
    const newFields: Partial<PerformanceState> = {
      level,
      page: 0,
      params: {
        ...performanceState.params,
        page: 0,
        level,
      },
      committedParams: {
        ...performanceState.committedParams,
        page: 0,
        level,
      },
    };
    if (columns) {
      newFields.columnsChosen = columns;
      // @ts-ignore
      newFields.params.fields = columns.toString();
      // @ts-ignore
      newFields.committedParams.fields = columns.toString();
    }
    fetchPerformanceData(newFields.params);
    updateFields(newFields);
  };

  const downloadPerformanceExportData = (client) => {
    if (client) {
      dispatch(
        apiAction({
          method: 'GET',
          path: {
            route: API_PERFORMANCE_ROUTES.EXPORT_DATA,
            variables: {
              client,
            },
          },
          successAction: downloadPerformanceExport,
          params: {
            type: performanceData.type,
            ...omitBy(performanceState.committedParams, isNil),
          },
          entity: RESOURCES.PERFORMANCE,
        }),
      );
    }
  };

  const downloadTopBottomPerformanceExportData = () => {
    const { type, ...data } = performanceData;
    dispatch(
      downloadPerformanceExport({
        data,
        params: {
          type,
          ...performanceState.committedParams,
        },
      }),
    );
  };

  const resetPerformanceData = () => {
    dispatch(performanceDataReset({}));
  };

  const commitAndFetchPerformanceData = (params: PerformanceParams) => {
    fetchPerformanceData(params);
    updateCommittedParams(params);
  };

  // TODO: Get rid for this, deferring to the Performance Dashboard Redux Refactor
  const setDashboardFieldsFromReport = useCallback(
    ({ params }) => {
      const { accountPlatformId, client, platforms } = performanceState.params;

      const {
        startDate,
        endDate,
        datePreset,
        dateRanges: reportDateRanges,
        attributionWindow,
        filtering: filters,
        fields: columnsChosen,
        breakdowns: breakdown,
        level,
      } = params;
      const dateRange = formatReportDateRange(datePreset, startDate, endDate);
      const dateRanges = reportDateRanges && formatComparisonDateRanges(dateRange);
      const requestParams = formatRequestParams(params);

      updateFields({
        attributionWindow,
        breakdown,
        columnsChosen,
        datePreset,
        dateRange,
        dateRanges,
        filters,
        level,
        params: {
          accountPlatformId,
          client,
          platforms,
          ...requestParams,
        },
      });
    },
    [updateFields, performanceState.params],
  );

  return {
    performanceData,
    performanceState,
    addFilter,
    removeFilter,
    clearFilters,
    committedFields,
    committedDateRanges,
    updatePage,
    updatePageSize,
    updateLevelAndFetchData,
    updateFields,
    updateDateRange,
    updateBreakdown,
    updateTopBottom,
    downloadPerformanceExportData,
    downloadTopBottomPerformanceExportData,
    commitAndFetchPerformanceData,
    setDashboardFieldsFromReport,
    updateColumnsChosen,
    updateCommittedParams,
    updateAttributionWindow,
    performanceDispatch,
    resetPerformanceData,
    adCopyIds,
  };
};

import React, { useState, useEffect, useMemo } from 'react';
import { Button } from 'rebass/styled-components';
import { Text, Box } from 'shared/components/display';
import CalendarDateRangeSelector from 'shared/components/molecules/CalendarDateRangeSelector';
import ToggleSwitch from 'shared/components/atoms/ToggleSwitch';
import DropdownWrapper from 'shared/components/atoms/DropdownWrapper';
import { formatComparisonDateRanges, parseDatePresets, prettyDate } from 'shared/utilities/dateUtility';
import { usePerformance } from 'shared/hooks/usePerformance';
import { useContainer } from 'shared/hooks/useContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

import { PERFORMANCE_DATA_TYPES } from 'shared/config/performance';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import dateRangeSetterStyles from './dateRangeSetterStyles';
import cs from 'classnames';
import { PERFORMANCE_DATE_PRESETS } from 'features/performance/DateRangeSetter/config';

const { COMPARISON } = PERFORMANCE_DATA_TYPES;
const { PRIMARY, TEXT, TEXT_ONLY } = BUTTON_VARIANTS;
const { SUBHEADING } = TEXT_VARIANTS;

// fieldnames
const TOGGLE_COMPARISON = 'toggleComparison';

const CONTAINER_NAME = 'date-range-setter';

interface State {
  inputPreset?: string;
  inputRange: {
    from: Date;
    to: Date;
  };
  toggleComparison: boolean;
}

interface Props {
  future?: boolean;
}

const DateRangeSetter: React.FC<Props> = ({ future = false }) => {
  const {
    performanceState: { dateRange, datePreset, dateRanges },
    updateDateRange,
  } = usePerformance();

  const { closeContainer } = useContainer(CONTAINER_NAME);

  const [state, setState] = useState<State>({
    inputRange: dateRange,
    inputPreset: datePreset,
    toggleComparison: false,
  });

  const { inputRange, inputPreset, toggleComparison } = state;

  const formattedDateRange = useMemo(() => {
    const { from, to } = inputRange;
    return `${prettyDate(from)} - ${prettyDate(to)}`;
  }, [inputRange]);

  const formattedComparisonDateRange = useMemo(() => {
    if (toggleComparison) {
      const [comparedDateRange] = formatComparisonDateRanges(inputRange);
      const { startDate, endDate } = comparedDateRange;
      return `${prettyDate(startDate)} - ${prettyDate(endDate)}`;
    }
    return null;
  }, [inputRange, toggleComparison]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      toggleComparison: Boolean(dateRanges),
    }));
  }, [dateRanges]);

  // to pass to another component
  const setInputRange = (newRange) => {
    setState((prev) => ({
      ...prev,
      inputPreset: undefined,
      inputRange: newRange,
    }));
  };

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      inputRange: dateRange,
    }));
  }, [dateRange]);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      inputPreset: datePreset,
    }));
  }, [datePreset]);

  const setDatePreset = (e) => {
    const preset = e.target.innerText;
    const parsedPreset = parseDatePresets(preset);

    setState((prev) => ({
      ...prev,
      inputPreset: preset,
      inputRange: parsedPreset,
    }));
  };

  // changing the actual date range only when the user clicks on apply button
  // TO DO: [callback]
  const setDateRange = () => {
    updateDateRange(inputRange, inputPreset, toggleComparison);
    closeContainer();
  };

  const setToggleComparisonCallback = () =>
    setState((prev) => ({
      ...prev,
      toggleComparison: !prev.toggleComparison,
    }));

  return (
    <DropdownWrapper
      label={CONTAINER_NAME}
      clickComponent={
        <>
          <span>
            {datePreset && `${datePreset}: `}
            {formattedDateRange}
          </span>
          <FontAwesomeIcon icon={faChevronDown} size="xs" />
        </>
      }
    >
      <Box className="date-range-setter" css={dateRangeSetterStyles}>
        <Box className="date-range-setter__grid">
          <Box className="date-range-setter__comparison-display">
            {formattedComparisonDateRange && (
              <Box as="ul" className="date-range-setter__comparison-list">
                <Box as="li" className="date-range-setter__list-item">
                  {formattedComparisonDateRange}
                </Box>
                <Box as="li" className={cs('date-range-setter__list-item--selected', 'date-range-setter__list-item')}>
                  {formattedDateRange}
                </Box>
              </Box>
            )}
          </Box>
          <Box className="date-range-setter__comparison-toggle">
            <ToggleSwitch
              fieldName={TOGGLE_COMPARISON}
              toggledOn={toggleComparison}
              onClick={setToggleComparisonCallback}
              label={COMPARISON}
            />
          </Box>
          <Box className="date-range-setter__calendar">
            <CalendarDateRangeSelector
              compareDateRange={toggleComparison}
              dateRange={inputRange}
              setDateRange={setInputRange}
              allowFutureDates={future}
            />
          </Box>
          <Box className="date-range-setter__date-presets">
            <Text variant={SUBHEADING}>Date Presets</Text>
            {PERFORMANCE_DATE_PRESETS.map((preset) => (
              <Button
                key={preset}
                onClick={setDatePreset}
                variant={TEXT_ONLY}
                className={cs('date-range-setter__date-preset-btn', {
                  'date-range-setter__date-preset-btn--active': preset === inputPreset,
                })}
              >
                {preset}
              </Button>
            ))}
          </Box>
          <Button type="button" variant={PRIMARY} onClick={setDateRange} className="date-range-setter__apply-btn">
            Update
          </Button>
          <Button type="button" variant={TEXT} onClick={closeContainer} className="date-range-setter__cancel-btn">
            Cancel
          </Button>
        </Box>
      </Box>
    </DropdownWrapper>
  );
};

export default DateRangeSetter;

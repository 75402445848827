import { Box } from 'shared/components/display';
import React from 'react';

interface Props {
  onStartClick: () => void;
  onEndClick: () => void;
  onPreviousClick: () => void;
  onNextClick: () => void;
  disablePrevious: boolean;
  disableNext: boolean;
  pageCount: number;
  pageIndex: number;
}

const PaginationControls = ({
  onStartClick,
  onEndClick,
  onPreviousClick,
  onNextClick,
  disablePrevious,
  disableNext,
  pageCount,
  pageIndex,
}: Props) => {
  return (
    <Box>
      <button onClick={onStartClick} disabled={disablePrevious}>
        {'<<'}
      </button>
      <button onClick={onPreviousClick} disabled={disablePrevious}>
        {'<'}
      </button>
      <button onClick={onNextClick} disabled={disableNext}>
        {'>'}
      </button>
      <button onClick={onEndClick} disabled={disableNext}>
        {'>>'}
      </button>
      <span>
        <div>Page</div>
        <strong>
          {pageIndex} of {pageCount}
        </strong>
      </span>
    </Box>
  );
};

export default PaginationControls;

export const INVOICE_COPY = {
    // Placeholder
    PLACEHOLDER_NO_UPCOMING: 'No Active Subscription',

    // Headings
    HEADING_BILLING: `Billing Overview`,
    HEADING_PAYMENT_HISTORY: 'Payment History',
    HEADING_RECEIPT: 'Receipt',
    HEADING_RECEIPT_SUMMARY: 'Summary',
    HEADING_RECEIPT_LINE_ITEMS: 'Items',

    // Columns
    COLUMN_PAY_DATE: 'Pay Date',
    COLUMN_PAY_PERIOD: 'Pay Period',
    COLUMN_AMOUNT: 'Amount',
    COLUMN_NEXT_BILLING: 'Next Billing',
    COLUMN_BILLING_DATE: 'Billed On',
    COLUMN_BILL_PERIOD: 'Bill Period',
    COLUMN_BRAND: 'Brand',
    COLUMN_BRIEF: 'Brief',
    COLUMN_RECEIPT: 'Receipt',

    // Label
    LABEL_RECEIPT_EXTERNAL_LINK: 'View receipt in a new tab.',

    // Footnotes
    FOOTNOTE_NO_TAX: '(excluding tax)',

    // Links
    LINK_PDF: 'PDF',
    LINK_ONLINE: 'Online',
};

import { useSelector } from 'react-redux';
import { getNotifications } from 'features/ui/ui.slice';

import { formatNotificationCount } from 'shared/utilities/notificationsUtility';

export const useNotifications = () => {
  const notifications = useSelector(getNotifications);

  const getTotalNotificationCount = (notificationKey) => {
    return Object.keys(notifications[notificationKey] || {}).length;
  };

  const getOrgNotificationCount = (notificationKey, orgId) => {
    return (notifications[notificationKey] || {})[orgId] || 0;
  };

  const getFormattedTotalNotificationCount = (notificationKey) => {
    const count = getTotalNotificationCount(notificationKey);
    return formatNotificationCount(count);
  };

  const getFormattedOrgNotificationCount = (notificationKey, orgId) => {
    const count = getOrgNotificationCount(notificationKey, orgId);
    return formatNotificationCount(count);
  };

  return {
    notifications,
    getTotalNotificationCount,
    getFormattedTotalNotificationCount,
    getOrgNotificationCount,
    getFormattedOrgNotificationCount,
  };
};

import { USER_COPY } from 'shared/config/copy';
import { ProfilePicSize } from 'features/users/components/molecules/ProfilePicture/interfaces';
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons';
import IconButton from 'shared/components/atoms/IconButton';
import { Box } from 'shared/components/display';
import cs from 'classnames';
import React from 'react';
import styles from './styles';

interface Props {
  size: ProfilePicSize;
  src: string;
  onDelete: React.MouseEventHandler<HTMLButtonElement>;
}

const ProfilePictureWithDeleteButton: React.FC<Props> = ({ size, onDelete, src }) => {
  return (
    <Box css={styles} className="profile-picture">
      <IconButton
        onClick={onDelete}
        icon={faTrashAlt}
        title="Delete Image"
        color="white"
        className="profile-picture__delete-button"
        svgClassName="profile-picture__delete-button-svg"
      />
      <img
        src={src}
        alt={USER_COPY.ALT_PROFILE_PICTURE}
        className={cs('profile-picture__img', `profile-picture__img--${size}`, 'profile-picture__img--editable')}
      />
    </Box>
  );
};

export default ProfilePictureWithDeleteButton;

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';

export const multiValueStyles = {
  multiValue: (base, state) => {
    return {
      ...base,
      ...(state.data.isFixed && { backgroundColor: COLORS.NEUTRAL700 }),
      zIndex: zIndex.zButtonOverlay,
    };
  },
  multiValueLabel: (base, state) => {
    return {
      ...base,
      ...(state.data.isFixed && { fontWeight: 'bold', color: 'white', paddingRight: 6 }),
      ...(state.data.isDisabled && {
        color: COLORS.NEUTRAL700,
        cursor: 'not-allowed',
      }),
    };
  },
  multiValueRemove: (base, state) => {
    return state.data.isFixed ? { ...base, display: 'none' } : base;
  },
};

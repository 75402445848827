import React from 'react';
import { ControlProps, GroupBase, components } from 'react-select';

export const SelectControl: React.FC<ControlProps<any, boolean, GroupBase<any>>> = ({ children, ...controlProps }) => {
  const {
    // @ts-ignore
    selectProps: { ControlPrefix },
  } = controlProps;

  return (
    <components.Control {...controlProps}>
      {ControlPrefix}
      {children}
    </components.Control>
  );
};

import { Box } from 'shared/components/display';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import lightboxArrowStyles from 'shared/components/organisms/Lightbox/lightboxArrowStyles';
import cs from 'classnames';
import React from 'react';

interface Props {
  clickHandler: () => void;
  hasPrev: boolean;
}

const LightboxLeftArrow: React.FC<Props> = ({ hasPrev, clickHandler }) => {
  return hasPrev ? (
    <Box css={lightboxArrowStyles} className={cs('lightbox-arrow', 'lightbox-arrow__left')}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          clickHandler();
        }}
        className={'lightbox-arrow__btn'}
        data-cy="lightbox-arrow__left"
      >
        <FontAwesomeIcon icon={faChevronLeft} size="lg" />
      </button>
    </Box>
  ) : (
    <></>
  );
};

export default LightboxLeftArrow;

import React from 'react';
import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import { promptStyles } from './styles';
import { Flex } from 'shared/components/display';
import { Text, Button } from 'rebass/styled-components';

interface Props {
  onSubmit: () => void;
}

export const ReuploadPrompt: React.FC<Props> = ({ onSubmit }) => {
  return (
    <Flex css={promptStyles} className="audience-form-prompt">
      <Text>{ACCOUNT_PLATFORM_COPY.AUDIENCE_PROMPT}</Text>
      <Button className="audience-form-prompt__modal-button" onClick={onSubmit}>
        Reupload
      </Button>
    </Flex>
  );
};

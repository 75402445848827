/* eslint-disable no-useless-escape */

import { ColumnFixed } from 'react-table-hoc-fixed-columns';
import { get } from 'lodash';

import LabelExpander from 'features/performance/TableComponents/LabelExpander';

import { DUPLICATE, NON_NUMERIC_COLUMNS, STATUS } from 'shared/config/performance';
import COLORS from 'shared/styles/colors';
import { STYLES } from 'shared/styles/styles';
import { columnSortMethod, formatColumnValue } from 'shared/utilities/Performance/dashboardUtility';
import { arrayIncludesString, formatStringPercent, humanize, isNumeric } from 'shared/utilities/stringUtility';

const { GREEN300, RED200 } = COLORS;
const { BORDER_NEUTRAL, UNSET } = STYLES;

type fixed = 'left' | 'right' | true;

export default class DashboardColumn {
  // @ts-ignore
  static #STRING_COLUMNS = ['campaign_name', 'adset_name', 'ad_name'];

  // @ts-ignore
  static #EMPTY_STRING_VALUE = '-';

  // @ts-ignore
  static #EMPTY_NUMBER_VALUE = 0;

  // @ts-ignore
  static #DELTA_START = '[';

  // @ts-ignore
  static #DELTA_END = ']';

  static CHANGE_VALUE = 'changeValue';

  static CHANGE_PERCENT = 'changePercent';

  static CHANGE_FIELDS = [DashboardColumn.CHANGE_VALUE, DashboardColumn.CHANGE_PERCENT];

  static FIXED_FIELDS = [DUPLICATE, STATUS, 'campaign_name', 'adset_name', 'ad_name'];

  static HUMANIZE_FIELDS = [STATUS, 'bid_strategy'];

  static OMITTED_SUMMARY_FIELDS = ['date_start', 'date_stop'];

  #columnName;

  #className;

  #Footer;

  #Header;

  constructor(columnName, Header, Footer) {
    this.#columnName = columnName;
    this.#Footer = Footer;
    this.#Header = Header;
    this.#className = this._getClassName();
  }

  static createColumn(columnName, Header, Footer) {
    return new DashboardColumn(columnName, Header, Footer).formatColumn();
  }

  static createColumnWithNestedColumns(columnName, Header, Footer, columns) {
    const column = new DashboardColumn(columnName, Header, Footer).formatColumn();
    return {
      ...column,
      headerClassName: 'centerAlign',
      columns,
    };
  }

  formatColumnWithNestedColumn(headerClassName, columns) {
    return {
      ...this.formatColumn(),
      columns,
      headerClassName,
    };
  }

  formatColumn(): ColumnFixed {
    return {
      accessor: this._accessor,
      id: this.#columnName,
      className: this.#className,
      headerClassName: this.#className,
      minWidth: this._getMinWidth(),
      minResizeWidth: this._getMinWidth(),
      //   @ts-ignore
      sortMethod: columnSortMethod,
      Footer: this.#Footer,
      Header: this.#Header,
      fixed: this._isFixed(),
      ...this._appendLabelColumns(),
      ...this._appendStatusColumns(),
      ...this._appendChangeColumns(),
    };
  }

  // Variable syntax instead of binding the method to the class in the contructor.
  _accessor = (row) => {
    const value = get(row, this.#columnName);
    if (!value) {
      return DashboardColumn.#STRING_COLUMNS.includes(this.#columnName)
        ? DashboardColumn.#EMPTY_STRING_VALUE
        : DashboardColumn.#EMPTY_NUMBER_VALUE;
    }
    if (this.#columnName.includes(DashboardColumn.CHANGE_PERCENT)) {
      return formatStringPercent(value);
    }
    if (DashboardColumn.HUMANIZE_FIELDS.includes(this.#columnName)) {
      return humanize(value);
    }
    if (isNumeric(value) && !this.#columnName.includes('id')) {
      return formatColumnValue(String(value));
    }
    return value;
  };

  _getClassName() {
    return NON_NUMERIC_COLUMNS.includes(this.#columnName) ? 'leftAlign' : 'rightAlign';
  }

  _getMinWidth() {
    if (this.#columnName.includes(DUPLICATE)) return 20;
    return this.#columnName.includes('name') ? 150 : 100;
  }

  _isFixed(): fixed | undefined {
    return DashboardColumn.FIXED_FIELDS.includes(this.#columnName) ? 'left' : undefined;
  }

  _appendLabelColumns() {
    if (this.#columnName !== 'labels') return null;
    return {
      width: 65,
      sortMethod: null,
      expander: true,
      Expander: LabelExpander,
    };
  }

  _appendStatusColumns() {
    if (this.#columnName !== STATUS) return null;
    return {
      getProps: (_, rowInfo) => ({
        style: {
          color: DashboardColumn.getStatusColor(rowInfo),
        },
      }),
    };
  }

  _appendChangeColumns() {
    if (!arrayIncludesString(DashboardColumn.CHANGE_FIELDS, this.#columnName)) return null;
    return {
      getProps: (_, rowInfo) => {
        return {
          style: {
            color: this._getChangeColumnColor(rowInfo),
            borderRight: this._getChangeColumnBorder(),
          },
        };
      },
    };
  }

  _getChangeColumnColor(rowInfo) {
    if (!rowInfo || !rowInfo.row[this.#columnName]) return GREEN300;
    const changeValueFieldName = DashboardColumn.getChangeValueField(this.#columnName);
    const unformattedNumber = rowInfo.row[changeValueFieldName].replace(/[^0-9\.-]+/g, '');
    const parsedNumber = parseFloat(unformattedNumber);
    return parsedNumber < 0 ? RED200 : GREEN300;
  }

  _getChangeColumnBorder() {
    return this.#columnName.includes(DashboardColumn.CHANGE_PERCENT) ? BORDER_NEUTRAL : UNSET;
  }

  static getChangeValueField(columnName) {
    return `${DashboardColumn.getFieldName(columnName)}${DashboardColumn.getChangeValueFieldName()}`;
  }

  static getStatusColor(rowInfo) {
    return rowInfo && rowInfo.row.status && rowInfo.row.status.toUpperCase() === 'ACTIVE' ? GREEN300 : RED200;
  }

  static getFieldName(value) {
    return value.split(DashboardColumn.#DELTA_START)[0];
  }

  static getChangeValueFieldName() {
    return `${DashboardColumn.#DELTA_START}${DashboardColumn.CHANGE_VALUE}${DashboardColumn.#DELTA_END}`;
  }
}

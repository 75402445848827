import { BreakdownFields, Breakdowns } from 'features/performance/enums';
import { emptySelectOption } from 'shared/components/molecules/Select/SelectInput';

import { TopBottomAnalysisValues } from './types';

// For formik touch logic, the initial values must not be the same object, hence making copies of emptySelectOption
export const initialValues: TopBottomAnalysisValues = {
  rankBy: { ...emptySelectOption },
  topBottomAmount: 1,
  age: { ...emptySelectOption },
  gender: { ...emptySelectOption },
  platform_position: { ...emptySelectOption },
  publisher_platform: { ...emptySelectOption },
  device_platform: { ...emptySelectOption },
  breakdown: Breakdowns.NONE,
};

export const BLACK_LIST = [
  '_name',
  '_id',
  'status',
  'labels',
  'account_currency',
  'bid_strategy',
  'buying_type',
  'date_start',
  'date_stop',
  'gender_targeting',
  'location',
];

export const ageOptions = [
  emptySelectOption,
  { value: '18-24', label: '18-24', breakdown: BreakdownFields.AGE },
  { value: '25-34', label: '25-34', breakdown: BreakdownFields.AGE },
  { value: '35-44', label: '35-44', breakdown: BreakdownFields.AGE },
  { value: '45-54', label: '45-54', breakdown: BreakdownFields.AGE },
  { value: '55-64', label: '55-64', breakdown: BreakdownFields.AGE },
  { value: '65+', label: '65+', breakdown: BreakdownFields.AGE },
];

export const genderOptions = [
  emptySelectOption,
  { value: 'unknown', label: 'unknown', breakdown: BreakdownFields.GENDER },
  { value: 'male', label: 'male', breakdown: BreakdownFields.GENDER },
  { value: 'female', label: 'female', breakdown: BreakdownFields.GENDER },
];

export const publisherPlatformOptions = [
  emptySelectOption,
  { value: 'audience_network', label: 'audience_network', breakdown: BreakdownFields.PUBLISHER_PLATFORM },
  { value: 'facebook', label: 'facebook', breakdown: BreakdownFields.PUBLISHER_PLATFORM },
  { value: 'instagram', label: 'instagram', breakdown: BreakdownFields.PUBLISHER_PLATFORM },
  { value: 'messenger', label: 'messenger', breakdown: BreakdownFields.PUBLISHER_PLATFORM },
];

export const platformPositionOptions = [
  emptySelectOption,
  { value: 'an_classic', label: 'an_classic', breakdown: BreakdownFields.PLATFORM_POSITION },
  { value: 'facebook_stories', label: 'facebook_stories', breakdown: BreakdownFields.PLATFORM_POSITION },
  { value: 'feed', label: 'feed', breakdown: BreakdownFields.PLATFORM_POSITION },
  { value: 'instagram_explore', label: 'instagram_explore', breakdown: BreakdownFields.PLATFORM_POSITION },
  { value: 'instagram_stories', label: 'instagram_stories', breakdown: BreakdownFields.PLATFORM_POSITION },
  { value: 'instant_article', label: 'instant_article', breakdown: BreakdownFields.PLATFORM_POSITION },
  { value: 'instream_video', label: 'instream_video', breakdown: BreakdownFields.PLATFORM_POSITION },
  { value: 'marketplace', label: 'marketplace', breakdown: BreakdownFields.PLATFORM_POSITION },
  { value: 'messenger_stories', label: 'messenger_stories', breakdown: BreakdownFields.PLATFORM_POSITION },
  { value: 'rewarded_video', label: 'rewarded_video', breakdown: BreakdownFields.PLATFORM_POSITION },
  { value: 'right_hand_column', label: 'right_hand_column', breakdown: BreakdownFields.PLATFORM_POSITION },
  { value: 'search', label: 'search', breakdown: BreakdownFields.PLATFORM_POSITION },
  { value: 'video_feeds', label: 'video_feeds', breakdown: BreakdownFields.PLATFORM_POSITION },
];

export const devicePlatformOptions = [
  emptySelectOption,
  { value: 'desktop', label: 'desktop', breakdown: BreakdownFields.DEVICE_PLATFORM },
  { value: 'mobile_app', label: 'mobile_app', breakdown: BreakdownFields.DEVICE_PLATFORM },
  { value: 'mobile_web', label: 'mobile_web', breakdown: BreakdownFields.DEVICE_PLATFORM },
];

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '.shipping-link': {
    '&__icon': {
      marginRight: theme.space[4],
    },
    '&__link': {
      color: COLORS.WHITE,
      backgroundColor: COLORS.PRIMARY500,
      borderRadius: '10px',
      padding: theme.space[4],
      display: 'inline-block',
    },
    '&__link:not(:disabled) svg': {
      color: COLORS.WHITE,
    },
    '&__link:not(:disabled):hover': {
      backgroundColor: COLORS.PRIMARY700,
    },
  },
});

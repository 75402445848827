import { css } from '@emotion/react';

import { SIDEBAR } from 'features/home/components/atoms/Sidebar/sidebarStyles';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

const width = `calc(${SIDEBAR.EXPANDED_WIDTH} - ${theme.space[5]} - ${theme.space[5]})`;

export default css({
  '&.platform-sidebar-nav': {
    flex: 1,
    position: 'relative',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  '.platform-sidebar-nav': {
    '&__heading': {
      marginBottom: theme.space[5],
      position: 'sticky',
      top: 0,
      backgroundColor: theme.colors.WHITE,
    },
    '&__link': {
      width,
      margin: `${theme.space[3]} 0`,
    },
    '&__link .active': {
      color: COLORS.PRIMARY500,
    },
    '&__loading': {
      width,
      padding: '17px 0',
    },
    '&__loading & span': {
      margin: `${theme.space[3]} 0`,
    },
    '&__back-btn': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&__back-btn & svg': {
      marginRight: theme.space[4],
    },
  },
});

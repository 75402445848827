import React from 'react';
import { SliderItem } from 'react-compound-slider';
import { GetTrackProps } from 'react-compound-slider/dist/types/Tracks/types';

const Track: React.FC<{
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  disabled: boolean;
}> = ({ source, target, getTrackProps, disabled = false }) => (
    <div
        className={`slider__track  ${
          disabled ? 'slider__track--disabled' : ''
        }`}
        style={{
          left: `${source.percent}%`,
          width: `${target.percent - source.percent}%`,
        }}
        {...getTrackProps()}
    />
);

export default Track;

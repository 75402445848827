import { intl } from 'shared/lib/intl';

import type { Validator, ValidatorOptions } from './types';
import { withinPercentTolerance } from 'shared/utilities/mathUtility';

export class TrendValidator implements Validator {
  private VALID_FILE_ASPECT_RATIOS: readonly number[] = [9 / 16];
  private PERCENT_TOLERANCE = 0.01;
  private MAX_DURATION = 35;

  public async validate({ metadata }: ValidatorOptions) {
    const errorMessages: Array<string> = [];
    const roundedFileDuration = Math.round(metadata?.duration || 0);

    if (roundedFileDuration > this.MAX_DURATION) {
      errorMessages.push(intl.formatMessage({ id: 'VALIDATION_TREND_DURATION' }, { max: this.MAX_DURATION }));
    }

    if (metadata.width && metadata.height) {
      const aspectRatio = metadata.width / metadata.height;
      const isValidAspectRatio = this.VALID_FILE_ASPECT_RATIOS.some((acceptedRatio) =>
        withinPercentTolerance(aspectRatio, acceptedRatio, this.PERCENT_TOLERANCE),
      );
      if (!isValidAspectRatio) {
        const dimensions = `${metadata.width}x${metadata.height}`;
        errorMessages.push(`${dimensions} is not 9x16`);
      }
    } else errorMessages.push('Missing width or height');

    return errorMessages;
  }
}

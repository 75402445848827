import React from 'react';
import { Field, Form, Formik } from 'formik';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Box } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import MediaPreviewInput, { MediaPreviewValue } from 'shared/components/organisms/MediaPreviewInput';
import TextInput from 'shared/components/TextInput';

import { FILE_COPY, GENERIC_COPY } from 'shared/config/copy';

import styles from './styles';
import { editFileSchema, FIELD_NAMES } from './validation';
import { BrkfstFile } from 'shared/typings/file';

interface Values {
  name: string;
  mediaPreview?: MediaPreviewValue;
}
interface Props {
  onSubmit: (args: Values) => void;
  loading: boolean;
  mediaObject: BrkfstFile;
}

const EditFileForm: React.FC<Props> = ({ onSubmit, loading = false, mediaObject }) => {
  const editFileFormValues: Values = {
    name: mediaObject.name,
    mediaPreview: {
      offset: 0.5,
      updateThumbnail: false,
    },
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={editFileFormValues} validationSchema={editFileSchema} validateOnChange>
      <Box css={styles}>
        <Form className="collection-assets-form">
          {loading ? (
            <CenteredSpinner />
          ) : (
            <Field
              name={FIELD_NAMES.MEDIA_PREVIEW}
              component={MediaPreviewInput}
              placeholder={FIELD_NAMES.MEDIA_PREVIEW}
              label={FIELD_NAMES.MEDIA_PREVIEW}
              file={{
                ...mediaObject,
                type: mediaObject.mimeType,
              }}
              mediaObjectURL={mediaObject.url}
            />
          )}

          <Field
            name={FIELD_NAMES.NAME}
            component={TextInput}
            placeholder={FILE_COPY.PLACEHOLDER_NAME}
            label={FIELD_NAMES.NAME + '*'}
          />
          <FormButton className="collection-assets-form__submit-btn" disabled={loading}>
            {GENERIC_COPY.BUTTON_SUBMIT}
          </FormButton>
        </Form>
      </Box>
    </Formik>
  );
};

export default EditFileForm;

import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'rebass/styled-components';
import { BaseSelection, Editor, NodeEntry } from 'slate';

import { Flex } from 'shared/components/display';
import UnderlineInput from 'shared/components/inputs/UnderlineInput/UnderlineInput';

import { Constants } from 'shared/config';
import { GENERIC_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';

import { editLink } from '../../plugins';

interface Props {
  url?: string;
  node: NodeEntry | any[];
  editor: Editor;
  selection?: BaseSelection;
  onHide: () => void;
}

const EditRichTextLinkForm: React.FC<Props> = ({ editor, selection, url, onHide, node }) => {
  const [newUrl, setUrl] = useState(url || '');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(0, -1);
    }
  }, []);

  const submitNewLink = () => {
    if (newUrl) {
      editLink(editor, newUrl, node, selection);
      onHide();
    }
  };

  return (
    <Flex
      className="rich-text-link-menu__edit-form"
      //   @ts-ignore
      onClick={(e) => {
        e.stopPropagation();
      }}
      tabIndex={0}
    >
      <UnderlineInput
        //   @ts-ignore
        ref={inputRef}
        value={newUrl}
        onChange={(e) => {
          setUrl(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.charCode === Constants.KEY_CODES.ENTER_CHAR_CODE) {
            e.preventDefault();
            submitNewLink();
          }
        }}
        className="rich-text-link-menu__text-input"
        placeholder="Link"
        // This prevents this issue ---> https://github.com/ianstormtaylor/slate/issues/3421
        contentEditable={false}
      />
      <Button
        onClick={submitNewLink}
        type="button"
        className="rich-text-link-menu__submit-btn"
        variant={BUTTON_VARIANTS.TEXT}
        disabled={!newUrl}
        tabIndex={0}
      >
        {GENERIC_COPY.BUTTON_APPLY}
      </Button>
    </Flex>
  );
};

export default EditRichTextLinkForm;

import { getOrgUsers, orgUserChanged, orgUsersAdded, orgUsersLoaded } from 'features/organizations/orgUsers.slice';
import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_ORG_ROUTES } from 'shared/config/routes/api/apiOrgRoutes';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';
import { ORGANIZATION_USER_TOASTS } from 'shared/config/toasts/orgUserToasts';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { intl } from 'shared/lib/intl';
import { BrkfstRole } from 'shared/typings/role';
import { BrkfstUser } from 'shared/typings/user';
import { useDispatch, useSelector } from 'react-redux';

const { ORGANIZATION_USER } = RESOURCES;
const INVITING_USER = `inviting_${ORGANIZATION_USER}`;

export const useOrgUsers = (organizationId: number) => {
  const orgUsers: BrkfstUser[] = useSelector(getOrgUsers);
  const dispatch = useDispatch();
  const { loading } = useComponentLoading(ORGANIZATION_USER);
  const { loading: loadingNewUser } = useComponentLoading(INVITING_USER, false);

  const updateUserRole = (userId: number, newRole: BrkfstRole) => {
    const user = orgUsers.find((u) => u.id === userId);

    if (!user) return false;

    const oldRole = user.roles?.find((role) => role.organizationId === newRole.organizationId);

    if (!oldRole) return false;

    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.UPDATE_ROLE,
          variables: {
            userId: user.id,
          },
        },
        data: {
          organizationId,
          roleId: newRole.id,
          oldRoleId: oldRole.id,
        },
        method: 'POST',
        successAction: orgUserChanged,
        successToast: {
          message: intl.formatMessage(
            {
              id: 'ORG_USER_ROLE_UPDATED',
            },
            { name: newRole.name },
          ),
        },
      }),
    );
    return true;
  };

  const inviteMarketers = (data: {
    accountIds: number[];
    emails: string[];
    organizationId: number;
    roleId: number;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_USER_ROUTES.INVITE_USER,
        },
        method: 'POST',
        data,
        successAction: orgUsersAdded,
        entity: INVITING_USER,
        successToast: {
          message: intl.formatMessage(
            {
              id: 'INVITED',
            },
            {
              emails: data.emails.join(', '),
              numEmails: data.emails.length,
            },
          ),
        },
      }),
    );
  };

  const updateAccessToAccounts = ({ accountIdsToRemove, accountIdsToAdd, user }) => {
    const params = {
      accountIdsToAdd,
      accountIdsToRemove,
      userId: user.id,
      roleId: user.roles[0].id,
    };

    dispatch(
      apiAction({
        path: {
          route: API_ORG_ROUTES.USER_ACCESS,
          variables: {
            organizationId,
          },
        },
        data: params,
        method: 'POST',
        successAction: orgUserChanged,
        successToast: { message: ORGANIZATION_USER_TOASTS.ACCESS_UPDATED },
      }),
    );
  };

  const fetchOrgUsers = () => {
    dispatch(
      apiAction({
        path: {
          route: API_ORG_ROUTES.ORG_USERS,
          variables: {
            organizationId,
          },
        },
        successAction: orgUsersLoaded,
        entity: ORGANIZATION_USER,
      }),
    );
  };

  return {
    orgUsers,
    updateUserRole,
    inviteMarketers,
    updateAccessToAccounts,
    fetchOrgUsers,
    loading,
    loadingNewUser,
  };
};

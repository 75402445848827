import React from 'react';
import cs from 'classnames';
import { SliderItem } from 'react-compound-slider';
import { GetHandleProps } from 'react-compound-slider/dist/types/Handles/types';

const Handle: React.FC<{
  min: number;
  max: number;
  handle: SliderItem;
  disabled: boolean;
  getHandleProps: GetHandleProps;
  position?: 'left' | 'right';
  valueDisplayMap?: Record<number, string>;
}> = ({ min, max, handle: { id, value, percent }, disabled = false, getHandleProps, position, valueDisplayMap }) => (
  <>
    <div
      role="slider"
      className={cs('slider__handle-pointer', {
        'slider__handle-pointer--disabled': disabled,
      })}
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        left: `${percent}%`,
      }}
      {...getHandleProps(id)}
    />

    <div className={cs('slider__handle-value', `slider__handle-value--${position}`)}>
      {valueDisplayMap ? valueDisplayMap[value] : value}
    </div>
  </>
);

export default Handle;

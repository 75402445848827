import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.file-upload': {
    '&__btn': {
      marginRight: theme.space[5],
    },
    '&__error': {
      marginBottom: theme.space[2],
    },
  },
  '.file-explorer': {
    height: ['100vh', 'calc(100 * var(--vh))'],
    padding: `0 ${theme.space[4]}`,
    '&__collection-btn': {
      alignSelf: 'start',
      color: COLORS.NEUTRAL600,
      padding: 0,
    },
    '&__control-header': {
      marginBottom: theme.space[3],
      justifyContent: 'space-between',
    },
    '&__controls-left': {
      alignItems: 'center',
    },
    '&__collection-name': {
      marginRight: theme.space[5],
    },
    '&__error': {
      marginBottom: theme.space[2],
    },
  },
});

import { MEDIA } from 'shared/config/media';
import { MESSAGES } from 'shared/config/validations';
import * as Yup from 'yup';

export const schema = Yup.object({
  audience: Yup.mixed()
    .required(MESSAGES.REQUIRED_FIELD)
    .nullable()
    .test('fileType', 'Only CSV files accepted', (value) => value?.file?.type === MEDIA.MIME_TYPES.CSV),
});

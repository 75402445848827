import { css } from '@emotion/react';

import COLORS, { TikTokColors } from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

// TikTok's Brand Guidelines:
// https://developers.tiktok.com/doc/getting-started-design-guidelines

export default css({
  '.tiktok-connect': {
    '&__button': {
      background: TikTokColors.BLACK,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      [mediaquery.md]: {
        width: 'auto',
      },
      '&:not(:disabled):hover': {
        backgroundColor: TikTokColors.BLACK,
      },
    },
    '&__tiktok-icon': {
      height: '20px',
      marginRight: '10px',
      color: COLORS.WHITE,
      filter: 'drop-shadow(2px 0px 0px #FD3E3E) drop-shadow(-1px -1px 0px #4DE8F4)',
    },
    '&__connected': {
      color: COLORS.GREEN500,
      padding: '8px',
    },
    '&__error-message': {
      color: COLORS.RED500,
      margin: theme.space[4],
    },
  },
});

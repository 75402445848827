import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import config from 'config';
import { unionWith } from 'lodash';

import { accountAdded } from 'features/accounts/accounts.slice';
import { roleChanged } from 'features/organizations/roles.slice';

import { BrkfstUser } from 'shared/typings/user';

type OrgUserState = BrkfstUser[];

interface OrgUserPayload<T = BrkfstUser> {
  data: T;
}

export const orgUsersSlice = createSlice<OrgUserState, SliceCaseReducers<OrgUserState>>({
  name: 'orgUsers',
  initialState: [],
  reducers: {
    orgUsersLoaded: (orgUsers, action: PayloadAction<OrgUserPayload<OrgUserState>>) => action.payload.data,

    orgUsersAdded: (orgUsers, action: PayloadAction<OrgUserPayload<BrkfstUser[]>>) => {
      return unionWith(orgUsers, action.payload.data, (a, b) => {
        return a.id === b.id;
      });
    },
    orgUserRemoved: (orgUsers, action: PayloadAction<OrgUserPayload<Pick<BrkfstUser, 'id'>>>) =>
      orgUsers.filter((orgUser) => orgUser.id !== action.payload.data.id),
    orgUserChanged: (orgUsers, action) =>
      orgUsers.map((orgUser) =>
        orgUser.id === action.payload.data.id ? { ...orgUser, ...action.payload.data } : orgUser,
      ),
  },
  extraReducers: (builder) =>
    builder
      .addCase(roleChanged, (orgUsers, action) => {
        const role = action.payload.data;
        orgUsers = orgUsers.map((orgUser) => {
          orgUser.roles = orgUser.roles?.map((userRole) => {
            return userRole.id === role.id ? { ...userRole, ...role } : userRole;
          });
          return orgUser;
        });
      })
      .addCase(accountAdded, (orgUsers, action) => {
        const adminEmails: string[] = config.BRKFST_ACCOUNT_MANAGER_USER.split(',');
        // @ts-ignore
        orgUsers = orgUsers.map((user) => {
          if (adminEmails.includes(user.email)) {
            return {
              ...user,
              accounts: [...(user.accounts || []), action.payload.data],
            };
          }
          return user;
        });
      }),
});

export const { orgUsersAdded, orgUsersLoaded, orgUserRemoved, orgUserChanged } = orgUsersSlice.actions;

export const getOrgUsers = (state) => state.orgUsers;
export const getOrgUser = (id) => (state) => {
  return state.orgUsers.find((orgUser) => orgUser.id === id);
};
export const getOrgUserCount = (state) => state.orgUsers.length;

export const getAccountUsers = (accountId) => (state) =>
  state.orgUsers.filter((user) => user.accounts.some((account) => account.id === accountId));

import React from 'react';
import cs from 'classnames';

import EllipticalMenu from 'shared/components/EllipticalMenu/EllipticalMenu';
import FileInformationModal from 'features/media/components/molecules/FileInformationModal';
import { BrkfstFile } from 'shared/typings/file';

interface Props {
  file: BrkfstFile;
  showInfoOption?: boolean;
}
const ModuleAssetsEllipsis: React.FC<Props> = ({ file, showInfoOption = false }) => {
  if (!showInfoOption) return <></>;
  return (
    <EllipticalMenu width="120px" className={cs('module-assets-ellipsis')}>
      <FileInformationModal file={file} textDisplay />
    </EllipticalMenu>
  );
};

export default ModuleAssetsEllipsis;

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

const HEADER_HEIGHT = '5px';

export const lightboxModalCss = {
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    background: 'rgba(0, 0, 0, 0.7)',
    zIndex: zIndex.zOverlay,
  },
  content: {
    overflow: 'visible',
  },
};

export default css({
  '&.lightbox': {
    display: 'flex',
    flexDirection: 'column',
    height: 'fill-available',
    [mediaquery.md]: {
      height: '100vh',
    },
  },
  '.lightbox': {
    '&__header': {
      position: 'relative',
      width: '100%',
      height: HEADER_HEIGHT,
    },
    '&__close-btn': {
      opacity: '0.8',
      color: COLORS.WHITE,
      height: '44px',
      width: '44px',
      fontWeight: 1000,
      position: 'absolute',
      right: '10px',
      top: '10px',
    },
    '&__close-btn:hover': {
      opacity: '1',
    },
    '&__content': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      // svh is unit for the visible portion of the viewport, fallback for older browsers that don't support svh
      height: [`calc(100vh - ${HEADER_HEIGHT})`, `calc(100svh - ${HEADER_HEIGHT})`],
    },
  },
});

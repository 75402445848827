// * Lib
import React from 'react';
//* Display/UI
import { Button, Text } from 'rebass/styled-components';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from 'config';

import { Flex } from 'shared/components/display';

// * Hooks
import { useQuery } from 'shared/hooks/useQuery';
// * Styles
import { TEXT_VARIANTS } from 'shared/styles/text';
import { OAuthState } from 'shared/typings/user';

import styles from './styles';

interface Props {
  userId: number;
  hasSocialProfile: boolean;
  redirectURI: string;
  disabled?: boolean;
}

export function getIgLogin({ userId, redirectURI }: OAuthState) {
  const jsonState: any = {
    userId,
    redirectURI,
  };

  const state = JSON.stringify(jsonState);
  const url = `https://api.instagram.com/oauth/authorize?client_id=${config.IG_APP_ID}&redirect_uri=${config.API_URL}${config.IG_CALLBACK_URL}&state=${state}&scope=${config.IG_SCOPE}&response_type=code`;
  window.location.assign(url);
}

const InstagramConnect: React.FC<Props> = ({ userId, hasSocialProfile, redirectURI, disabled }) => {
  const { getQuery } = useQuery();
  const { igError } = getQuery();
  return (
    <Flex className="instagram-connect" css={styles}>
      <Button
        onClick={() => getIgLogin({ userId, redirectURI })}
        disabled={hasSocialProfile || disabled}
        className="instagram-connect__button"
        type="button"
      >
        <FontAwesomeIcon icon={faInstagram} className="instagram-connect__ig-icon" fixedWidth />
        Connect To Instagram
      </Button>
      {hasSocialProfile && (
        <Text as="h3" variant={TEXT_VARIANTS.SUBHEADING} className="instagram-connect__connected">
          Connected
        </Text>
      )}
      {igError ? (
        <Text variant={TEXT_VARIANTS.BODY} className="instagram-connect__error-message">
          {igError}
        </Text>
      ) : null}
    </Flex>
  );
};

export default InstagramConnect;

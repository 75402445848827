import { css } from '@emotion/react';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.media-preview-input': {
    flexDirection: 'column',
    '&:has(.media-preview-input__img)': {
      maxHeight: '400px',
      [mediaquery.md]: {
        maxHeight: '450px',
      },
    },
  },
  '.media-preview-input': {
    '&__text': {
      textAlign: 'center',
      margin: `${theme.space[2]} 0`,
    },
  },
});

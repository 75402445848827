import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.account-details-form': {
    '&__submit-btn': {
      margin: `${theme.space[4]} 0`,
    },
  },
});

import React from 'react';

interface Props {
  name: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  dataCy?: string;
}
const Checkbox = React.forwardRef<HTMLInputElement, Props>(
  ({ name, checked = false, disabled = false, onChange, className, dataCy }, ref) => {
    return (
      <input
        name={name}
        ref={ref}
        type="checkbox"
        id={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        className={className}
        data-cy={dataCy}
      />
    );
  },
);

export default Checkbox;

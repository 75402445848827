import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '&.carousel-header': {
    '&__spacing': {
      justifyContent: 'space-between',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '-10px',
    },
  },
  '.carousel-header': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&__brief-title': {
      color: COLORS.WHITE,
      fontWeight: 'bold',
      alignSelf: 'start',
      textOverflow: 'ellipsis',
    },
    '&__creator-name': {
      alignSelf: 'end',
      color: COLORS.WHITE,
    },
  },
});

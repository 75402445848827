import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  padding: '4px',
  minWidth: '200px',
  maxWidth: '320px',
  '.rich-text-link-menu': {
    '&__tooltip': {
      display: 'inline',
      width: 'auto',
    },
    '&__btn': {
      padding: '4px 6px',
      margin: '0 2px',
      '&:hover, &:focus': {
        background: COLORS.PRIMARY100,
        borderRadius: '2px',
      },
    },
    '&__link': {
      color: COLORS.PRIMARY500,
      marginLeft: '4px',
      flex: 1,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
    },
    '&__edit-form': {
      alignItems: 'center',
    },
    '&__submit-btn': {
      minWidth: 'unset',
      marginLeft: '4px',
    },
    '&__text-input.underline-input': {
      margin: 0,
    },
  },
});

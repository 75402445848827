import React from 'react';
import { createGlobalStyle } from 'styled-components';
import theme from 'shared/styles/themes';
import { media } from 'shared/styles/styleFunctions';
import COLORS from 'shared/styles/colors';

const { PRIMARY500, BLACK, NEUTRAL200 } = COLORS;

const GlobalStyles = createGlobalStyle`
a {
  color: ${BLACK};
  &:hover,
  &:focus {
    color: ${PRIMARY500};
    text-decoration: none;
  }
}

hr {
  border-top: 1px solid ${NEUTRAL200};
  margin-top: 0px;
  width: 100%;
}

/** a one-liner of text that ends in ellipsis '...' when overflowed
  *  must set max-width/width when using this
  */
.endWithEllipsis {
  white-space: nowrap;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

  /** Add this class if text should wrap around multiple lines before ending in ellipsis.
    * -webkit-line-clamp: X rows
    */
  &.endMultipleRows {
    white-space: unset;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.hideScrollbar {
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.displayNone {
  display: none;
}

/** basic container with bg color, padding and margin set.
  * should be the default class for most pages.
  */
.mainContainer {
  background-color: white;
  border: 1px solid ${COLORS.NEUTRAL100};
  border-radius: 2px;
  padding: ${theme.space[4]};
  margin: ${theme.space[1]};

  ${media.md`
    margin: ${theme.space[5]};
    padding: ${theme.space[5]};
  `}
}
/** behaves like .mainContainer but centers the container horizontally.
  * best used when container has a set width
 */
.centeredContainer {
  background-color: white;
  border: 1px solid ${COLORS.NEUTRAL100};
  border-radius: 2px;
  padding: ${theme.space[4]};
  margin: ${theme.space[5]} auto;

  ${media.md`
    padding: ${theme.space[5]};
  `}
}

/* padding and bg */
.paddingContainer {
  background-color: white;
  border: 1px solid ${COLORS.NEUTRAL100};
  border-radius: 2px;
  padding: ${theme.space[4]};
  overflow: none;

  ${media.md`
    padding: ${theme.space[5]};
  `}
}

/* margin and bg */
.marginContainer {
  background-color: white;
  border: 1px solid ${COLORS.NEUTRAL100};
  border-radius: 2px;
  margin: ${theme.space[5]};
}

/* for public routes */
.publicContainer {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-top: ${theme.space[0]};
  padding: 0 ${theme.space[3]};
  ${media.md`
    width: 75%;
    margin-top: ${theme.space[10]};
  `}
  ${media.lg`
    width: 45%;
    margin-top: 5vh;
    margin-top: calc(5 * var(--vh));
  `}
}

  /** Tagify dropdown styles */

  .tagify__dropdown__wrapper {
    border-radius: 5px;
    border-radius: 5px;
    border: none;
  }

  .tagify__dropdown {
    border-radius: 5px;
    border-radius: 5px;
    transform: translateY(6px);
    border: 1px solid ${COLORS.NEUTRAL400};
  }

  .tagify__dropdown__item {
    width: fit-content;
    background-color: ${COLORS.PRIMARY50};
    color: ${COLORS.PRIMARY800};
    border-radius: 5px;
    margin: ${theme.space[2]}
  }
`;

export default React.memo(GlobalStyles);

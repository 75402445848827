import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import cs from 'classnames';
import config from 'config';

import AccountPlatformHealthProgress from 'features/accountPlatforms/components/molecules/AccountPlatformHealthProgress';
import AccountHealthScoreDisplay from 'features/accountPlatforms/components/organisms/AccountHealthScoreDisplay';
import SurveyDrawer from 'features/accountPlatforms/components/organisms/SurveyDrawer/SurveyDrawer';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';
import { Box, Image, Text, Flex } from 'shared/components/display';
import { Select } from 'shared/components/molecules/Select';

import { TRACKING } from 'shared/config/tracking';
import { useQuery } from 'shared/hooks/useQuery';
import LoadingAnimation from 'shared/images/loading/loading-001.gif';
import { Platforms } from 'shared/typings/accountPlatform/enums';
import { UserType } from 'shared/typings/user/enums';
import { sha256Hash, trackEvent } from 'shared/utilities/trackingUtility';

import styles from './accountHealthStyles';
import { useDispatch } from 'react-redux';
import { adHealthSurveyOpened } from 'features/ui/ui.slice';
import { formatDateWithSlashes, prettyDate } from 'shared/utilities/dateUtility';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons';

const AccountHealth = () => {
  const params = useParams<{ accountId: string }>();
  const accountId = +params.accountId;
  const { getQuery, stringifyQuery } = useQuery();
  const { email, firstName, lastName } = getQuery();
  const dispatch = useDispatch();

  const {
    getAccountPlatformHealth,
    accountPlatformHealth,
    facebookAccountPlatforms: accountPlatforms,
    resetAccountPlatformHealth,
    accountPlatform: adHealthAccountPlatforn,
  } = useAccountPlatforms({ accountId });

  // map of loading states for each account platform
  const [loading, setLoading] = useState<Record<number, boolean>>({});
  // To get on this page, there will always be at least one account platform
  const [accountPlatform, setAccountPlatform] = useState<{ value: number; label: string }>({
    value: accountPlatforms[0].id,
    label: accountPlatforms[0].name,
  });

  const adAccountId = useMemo(() => {
    return accountPlatforms.find((platform) => platform.id === accountPlatform.value)?.accessId;
  }, [accountPlatform]);

  const toggleLoading = (accountPlatformId: number) => () => {
    setLoading((prev) => ({
      ...prev,
      [accountPlatformId]: prev[accountPlatformId] ? false : true,
    }));
  };

  useEffect(() => {
    // Clear redux so when we switch between account platforms, we don't see the previous account platform's data
    resetAccountPlatformHealth();
    toggleLoading(accountPlatform?.value)();

    if (accountId && accountPlatform?.value)
      getAccountPlatformHealth(accountId, accountPlatform?.value, toggleLoading(accountPlatform?.value));
  }, [accountId, accountPlatform?.value]);

  useEffect(() => {
    if (accountPlatformHealth?.score > 0) {
      dispatch(adHealthSurveyOpened());
    }
  }, [accountPlatformHealth]);

  const options = useMemo(
    () =>
      accountPlatforms
        .filter(({ platform }) => platform?.name === Platforms.FACEBOOK)
        .map((accountPlatform) => ({
          value: accountPlatform.id,
          label: accountPlatform.name,
        })),
    [accountPlatforms],
  );

  const showSelect = accountPlatforms.length > 1;
  const showLoading = loading[accountPlatform.value] || !accountPlatformHealth;
  const completedWithNoScore = Boolean(+accountPlatformHealth?.score === 0);

  const scoreInProgress =
    !loading[accountPlatform.value] && !accountPlatformHealth?.score && accountPlatformHealth?.score !== 0;

  const onImproveMyScore = async () => {
    const emailHashed = await sha256Hash(email);
    trackEvent(TRACKING.ADSCORE_IMPROVE, { email, type: UserType.ORG, userId: emailHashed });
    const query = stringifyQuery({ email, firstName, lastName });
    window.location.assign(config.MARKETING_SITE_URL + query);
  };

  const LoadingIndicator = () => <Image src={LoadingAnimation} alt="loading" title="loading" />;

  return showLoading ? (
    <Box
      css={styles}
      className={cs('account-health', {
        'account-health--with-select': showSelect,
      })}
    >
      {showSelect && <Skeleton height="40px" width="300px" />}
      <Box className="account-health__content">
        <CenteredSpinnerContainer />
      </Box>
    </Box>
  ) : (
    <Box
      css={styles}
      className={cs('account-health', {
        'account-health--with-select': showSelect,
      })}
    >
      {showSelect && (
        <Flex className="account-health__header">
          <Select
            options={options}
            name="accountPlatform"
            value={accountPlatform}
            onChange={setAccountPlatform}
            placeholder="Select Platform"
            width="300px"
            showOptionTooltip
          />
          {accountPlatformHealth?.score && (
            <Text className="account-health__date-calc">
              <FontAwesomeIcon icon={faCalendar} className="account-health__date-calc-icon" /> Date Run:{' '}
              {prettyDate(accountPlatformHealth.dateCreated.toString())}
            </Text>
          )}
        </Flex>
      )}

      <Box className="account-health__content">
        {scoreInProgress || completedWithNoScore ? (
          <AccountPlatformHealthProgress
            accountId={accountId}
            accountPlatformId={accountPlatform?.value}
            completedWithNoScore={completedWithNoScore}
            LoadingIndicator={LoadingIndicator}
          />
        ) : (
          <>
            <AccountHealthScoreDisplay
              onImproveMyScore={onImproveMyScore}
              accountPlatformHealth={accountPlatformHealth}
            />
            <SurveyDrawer
              adAccountId={`${adAccountId}`}
              adAccountName={accountPlatform.label}
              accountPlatformId={accountPlatform.value}
              accountId={accountId}
              internal
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default AccountHealth;

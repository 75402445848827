import { css } from '@emotion/react';

export default css({
  '&.user-dropdown': {
    alignItems: 'center',
  },
  '.user-dropdown': {
    '&__name-arrow-wrapper': {
      alignItems: 'center',
    },
    '&__arrow-btn': {
      padding: 0,
      display: 'flex',
      maxHeight: '100%',
      margin: 'auto',
    },
  },
});

import React from 'react';
import { FieldArray, FieldArrayRenderProps, FieldProps, Formik, FormikHelpers } from 'formik';
import { get, startCase } from 'lodash';

type MakeForm<Values = any> = (
  Component: React.FC<any>,
  validationSchema?: any,
  defaults?: Values,
) => React.FC<
  any & {
    initialValues?: Values;
    onSubmit?: (values: Values) => void;
  }
>;

// MakeForm's deletion is debatable as its not very useful but the fact that it
// works means that the SubForm type isn't changing the formik API in negative ways.

/** Make a working form out of a component using the SubForm interface */
export const makeForm: MakeForm =
  (Component, validationSchema = null, defaults = null) =>
  ({ initialValues = defaults, onSubmit = () => {}, ...props }) =>
    (
      <Formik {...props} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {(formikProps) => <Component {...formikProps} isSubForm={false} />}
      </Formik>
    );

type FieldArrayComponent<ListItem> = FieldArrayRenderProps & {
  values: ListItem;
};

type FieldArrayProps = { name: string; validateOnChange?: boolean };

/** makeArray provides the same API as FieldArray with the exception of an additional values prop */
export function makeArray<ListItem, ExtraProps = {}>(
  Component: React.FC<FieldArrayComponent<ListItem[]> & ExtraProps>,
) {
  return (props: FieldArrayProps & ExtraProps) => (
    <FieldArray name={props.name} validateOnChange={props.validateOnChange}>
      {(arrayProps) => <Component {...props} {...arrayProps} values={get(arrayProps.form.values, props.name)} />}
    </FieldArray>
  );
}

export type Input<Values, ExtraProps = {}> = React.FC<FieldProps<Values> & ExtraProps>;

export type useInputHelpers<Values> = {
  /** Helper that calls both setFieldValue & setFieldTouched for you without having name on hand */
  setInputValue: (values: Values) => void;
};

export type OnSubmit<Values> = (state: Values, formikHelpers: FormikHelpers<Values>) => void;

export const makeLabel = (name: string, required?: boolean) => `${startCase(name)}${required ? '*' : ''}`;

export const makeFormikOnSubmit =
  <Values,>(onSubmit: OnSubmit<Values>) =>
  (state: Values, formikHelpers: FormikHelpers<Values>) => {
    onSubmit(state, formikHelpers);
    formikHelpers.setSubmitting(false);
  };

import React from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExternalLink from 'shared/components/atoms/ExternalLink/ExternalLink';
import { getShippingIcon } from './util';
import styles from './styles';

interface Props {
  link?: string;
}
const ShippingLink: React.FC<Props> = ({ link }) => {
  return (
    <Box css={styles} className={cs('shipping-link')}>
      <ExternalLink
        href={link || ''}
        className="shipping-link__link"
        text={
          <>
            <FontAwesomeIcon icon={getShippingIcon(link)} size="lg" className="shipping-link__icon" />
            Track your package
          </>
        }
      />
    </Box>
  );
};

export default ShippingLink;

import React from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import sidebarNavBarStyles from './sidebarNavBarStyles';
import { NavItemProps } from 'shared/components/atoms/NavItem';

interface Props {
  className?: string;
  children: React.ReactElement<NavItemProps> | React.ReactElement<NavItemProps>[];
}
const SidebarNavBar: React.FC<Props> = ({ className, children }) => {
  return (
    <Box css={sidebarNavBarStyles} className={cs(className, 'sidebar-nav-bar')} as="ul">
      {children}
    </Box>
  );
};

export default SidebarNavBar;

import React from 'react';
import * as yup from 'yup';

import { Text } from 'shared/components/display';
import ButtonGroupInput, { Option } from 'shared/components/molecules/ButtonGroupInput/ButtonGroupInput';
import { buttonGroupSchema } from 'shared/components/molecules/ButtonGroupInput/validation';

import { CREATOR_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';

const referralOtherOption = { value: 'other', label: 'Other' };

const options = [
  { value: 'Colleague/friend', label: 'Colleague/friend' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Search engine (Google, Bing, etc)', label: 'Search engine (Google, Bing, etc)' },
  { value: 'TikTok', label: 'TikTok' },
  { value: 'Blog/publication', label: 'Blog/publication' },
  referralOtherOption,
];

export const ReferralOnboardingStepFields: React.FC = () => {
  return (
    <>
      <Text variant={TEXT_VARIANTS.BODY} className="onboarding-steps__select-multiple">
        {CREATOR_COPY.CAPTION_MULTIPLE_OPTIONS}
      </Text>
      <ButtonGroupInput
        name={REFERRAL_FIELDNAMES.REFERRAL}
        isMultiSelect
        otherOptionFieldName={REFERRAL_FIELDNAMES.REFERRAL_OTHER}
        otherOptionValue={referralOtherOption.value}
      />
    </>
  );
};

export const REFERRAL_FIELDNAMES = {
  REFERRAL: 'referral',
  REFERRAL_OTHER: 'referralOther',
};

export const referralInitialValues: ReferralValues = {
  referral: options,
  referralOther: '',
};

export const referralSchema = yup.object({
  referral: buttonGroupSchema,
  referralOther: yup.string().when('referral', {
    is: (value: Option[]) => {
      const referralOption = value.find((op) => op.value === referralOtherOption.value);
      return referralOption?.selected;
    },
    then: yup.string().customRequiredString(),
  }),
});

export type ReferralValues = {
  referral: Option[];
  referralOther: string;
};

import React from 'react';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';
import * as yup from 'yup';

import { Box, Text } from 'shared/components/display';
import ToggleInput from 'shared/components/ToggleInput';

import { CREATOR_COPY } from 'shared/config/copy';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

interface Props {
  showUpdateNote?: boolean;
}

export const NotificationsFormFields: React.FC<Props> = ({ showUpdateNote = true }) => {
  return (
    <Box css={styles}>
      <Text className="notifications__caption" variant={TEXT_VARIANTS.BODY}>
        Opt in to receive text notifications and ensure you never miss a beat. Get instant updates on new opportunities,
        direct messages, and important platform announcements.
      </Text>
      <Field
        name={NOTIFICATIONS_FIELDNAMES.OPT_IN}
        component={ToggleInput}
        label={CREATOR_COPY.LABEL_PHONE_NOTIFICATIONS}
        className="notifications__toggle"
        dataCy="notifications__toggle"
      />
      {showUpdateNote ? (
        <Box className="notifications__note">
          <FontAwesomeIcon
            icon={faCircleExclamation}
            size="lg"
            color={COLORS.ORANGE500}
            className="notifications__note-icon"
          />
          <Text variant={TEXT_VARIANTS.BODY} className="notifications__note-text">
            You can adjust your notification preferences later in your settings.
          </Text>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export const NOTIFICATIONS_FIELDNAMES = {
  OPT_IN: 'optIn',
};

export const notificationsSchema = yup.object({
  [NOTIFICATIONS_FIELDNAMES.OPT_IN]: yup.boolean(),
});

export const notificationInitialValues = {
  [NOTIFICATIONS_FIELDNAMES.OPT_IN]: false,
};

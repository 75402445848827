import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  marginTop: '12px',
  '.parameterized-text-input': {
    '&__variables': {
      marginBottom: '4px',
      [mediaquery.sm]: {
        display: 'inline',
      },
      [mediaquery.xl]: {
        display: 'flex',
      },
    },
    '&__input': {
      position: 'relative',
    },
    '&__btn': {
      marginTop: theme.space[2],
    },
  },
});

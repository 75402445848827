import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  '&.phone-input': {
    marginTop: '0.75rem',
    marginBottom: '0.25rem',
    '& .special-label': {
      transform: 'scale(0.80) translateX(-35px)',
      color: COLORS.NEUTRAL700,
    },
    '&--error .phone-input__input:last-of-type': {
      border: `1px solid ${COLORS.RED500}`,
    },
    '&--error .special-label': {
      color: COLORS.RED500,
    },
  },
  '.phone-input': {
    '&__container': {
      margin: '0.65rem 0 0.25rem',
    },
    '&__input:last-of-type': {
      boxShadow: 'none',
      fontSize: '1rem',
      color: COLORS.NEUTRAL700,
      borderRadius: '2px',
      width: '100%',
      height: '44px',
      border: `1px solid ${COLORS.NEUTRAL300}`,
      '&:disabled': {
        padding: '0.5rem 2rem 0.5rem 0.75rem',
        borderColor: `${COLORS.NEUTRAL200}`,
        background: `${COLORS.WHITE}`,
        color: `${COLORS.NEUTRAL300}`,
      },
      '&:disabled .special-label': {
        color: COLORS.NEUTRAL300,
      },
      '&:focus': {
        outline: `5px auto ${COLORS.PRIMARY600}`,
      },
    },
    '&__error': {
      position: 'relative',
      left: '50px',
    },
  },
});

// ========================================================================================
/*                                                                                      *
 * ATOM: Image                                                                            *
 *                                                                                      */
// ========================================================================================
// * Lib
// * Styles
import React from 'react';
import styled from '@emotion/styled';
import { variant } from 'styled-system';

// * Display/UI
import Base from './Base.display';

interface Props {
  src?: String;
  className?: String;
  alt?: String;
  title?: String;
  css?: any;
  onClick?: React.MouseEventHandler;
  onLoad?: React.ReactEventHandler<HTMLImageElement>;
}

const StyledImage = styled(Base)(variant({ scale: 'image' }));

const Image: React.FC<Props> = React.forwardRef((props, ref) => (
  // @ts-ignore
  <StyledImage as="img" data-display="image" {...props} ref={ref} />
));

export default Image;

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  paddingBottom: '1rem',
  '.collection-assets-form': {
    '&__submit-btn': {
      marginTop: theme.space[5],
    },
  },
});

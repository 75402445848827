import addFilesToCollectionStyles from 'features/media/AddFilesToCollection/addFilesToCollectionStyles';
import { faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from 'shared/components/display';
import Modal from 'shared/components/molecules/Modal';
import { COLLECTION_COPY } from 'shared/config/copy';
import { SIZING } from 'shared/config/formatting';
import { COLLECTION_TOASTS } from 'shared/config/toasts/collectionToasts';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import React, { useState } from 'react';
import { Button } from 'rebass/styled-components';
import MoveFilesToCollectionForm from 'features/media/components/organisms/MoveFilesToCollectionForm';
import { useToasts } from 'shared/hooks/useToasts';
import { intl } from 'shared/lib/intl';
import Tooltip from 'shared/components/atoms/Tooltip';

interface Props {
  selectedAssetIds: number[];
  collectionId: number;
  selectedFileNames: string;
}

const AddFilesToCollection: React.FC<Props> = ({ selectedAssetIds = [], collectionId, selectedFileNames }) => {
  const [open, setOpen] = useState(false);
  const { setErrorToast } = useToasts();

  const toggleModal = () => {
    setOpen((prev) => !prev);
  };

  const setError = (err) => {
    setErrorToast({ message: err });
  };

  return (
    <>
      <Box className="add-files-to-collection" css={addFilesToCollectionStyles}>
        <Tooltip content={selectedFileNames}>
          <Button
            variant={BUTTON_VARIANTS.TEXT_ONLY}
            className="add-files-to-collection__btn"
            onClick={
              !selectedAssetIds.length
                ? () => setError(COLLECTION_TOASTS.ADD_TO_COLLECTION_NOT_ENOUGH_ASSETS)
                : toggleModal
            }
          >
            <FontAwesomeIcon icon={faPlusSquare} />
            {intl.formatMessage(
              { id: 'BUTTON_MOVE_FILES' },
              {
                numFiles: selectedAssetIds.length,
              },
            )}
          </Button>
        </Tooltip>
      </Box>
      <Modal
        title={COLLECTION_COPY.DRAWER_ADD_FILES}
        isOpen={open}
        onRequestClose={toggleModal}
        modalSize={SIZING.MEDIUM}
        component={
          <Box className="add-files-to-collection" css={addFilesToCollectionStyles}>
            <MoveFilesToCollectionForm
              selectedAssetIds={selectedAssetIds}
              collectionId={collectionId}
              onSubmit={toggleModal}
            />
          </Box>
        }
      />
    </>
  );
};

export default AddFilesToCollection;

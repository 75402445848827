import styles from 'features/users/components/atoms/ProfilePictureSkeleton/styles';
import { ProfilePicSize } from 'features/users/components/molecules/ProfilePicture';
import { Box } from 'shared/components/display';
import React, { useMemo } from 'react';
import Skeleton from 'shared/components/atoms/Skeleton';

interface Props {
  size: ProfilePicSize;
}

const ProfilePictureSkeleton: React.FC<Props> = ({ size }) => {
  const loadingDimensions = useMemo(() => {
    switch (size) {
      case 'xs':
        return 30;
      case 'sm':
        return 150;
      case 'md':
        return 200;
      case 'lg':
      default:
        return 230;
    }
  }, [size]);

  return (
    <Box css={styles} className="profile-picture-skeleton">
      <Skeleton circle height={loadingDimensions} width={loadingDimensions} />
    </Box>
  );
};

export default ProfilePictureSkeleton;

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.btn-group': {
    display: 'flex',
    flexDirection: 'column',
  },
  '.btn-group': {
    '&__options': {
      flexWrap: 'wrap',
      gap: theme.space[3],
    },
    '&__other': {
      width: '100%',
    },
    '&__option': {
      border: `1px solid ${COLORS.BLACK}`,
      borderWidth: '1px',
      borderRadius: '4px',
      margin: `${theme.space[3]} ${theme.space[3]}  ${theme.space[3]} 0`,
      width: 'fit-content',
      '&:hover': {
        backgroundColor: COLORS.PRIMARY500,
        color: COLORS.WHITE,
      },
    },
    '&__option--selected': {
      backgroundColor: COLORS.PRIMARY500,
      color: COLORS.WHITE,
      '&:hover': {
        backgroundColor: COLORS.WHITE,
        color: COLORS.BLACK,
      },
    },
  },
});

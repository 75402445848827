import { css } from '@emotion/react';

import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.brief-header': {
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    [mediaquery.md]: {
      height: '100%',
      flexWrap: 'wrap',
      margin: '0.15rem 0',
    },
  },
  [mediaquery.md]: {
    order: 'unset',
  },
  '.brief-header': {
    '&__name': {
      flex: 1,
      paddingRight: theme.space[5],
      margin: 0,
    },
  },
  '.brief-header-creator-actions': {
    height: '42px',
  },
});

export const RESOURCES = {
  ACCOUNT_BANNER: 'Account Banner',
  ACCOUNT_LOGO: 'Account Logo',
  ACCOUNT_PLATFORM: 'Account Platform',
  ACCOUNT_PLATFORM_HEALTH: 'Account Platform Health',
  ACCOUNT_SETTINGS: 'Account Settings',
  ACCOUNT_PRICING: 'Account Pricing',
  ACCOUNT: 'Account',
  ADDRESS_LOOKUP: 'Address Lookup',
  ADDRESS: 'Address',
  ASSETS_LIBRARY: 'Assets Library',
  ATTRIBUTION: 'Attribution',
  AUTH: 'auth',
  BIDDING_TEST: 'Bidding Model',
  BRAND_ASSET: 'Brand Asset',
  BRIEF_ASSET: 'Brief Asset',
  BRIEF_PUBLISHING: 'Brief Publishing',
  BRIEF_SAVING_DRAFT: 'Brief Saving Draft',
  BRIEF: 'Brief',
  BRIEF_TEMPLATES: 'Brief Templates',
  CARD: 'Card',
  CHANNEL: 'Channel',
  CHECKOUT: 'checkout',
  COLLECTION: 'Collection',
  CONFIGURATION: 'Configuration',
  CONVERSION: 'Conversion',
  CREATOR_BRIEF: 'Creator Brief',
  CREATOR_DEADLINE: 'Creator Deadline',
  CREATOR_PERFORMANCE: 'Creator Performance',
  CREATOR_STARRED: 'Creator Starred',
  CREATORS_SHIPPING_INFO: 'Creator Shipping Info',
  CREATOR: 'Creator',
  DISCONNECTING_IG: 'Disconnecting Instagram',
  DISCONNECTING_TIKTOK: 'Disconnecting TikTok',
  EARNING: 'Earning',
  EARNING_DETAILS: 'Earning Details',
  FACEBOOK_BUSINESS_ASSET: 'Facebook Business Asset',
  FACEBOOK: 'Facebook',
  FILE_UPDATE: 'File Update',
  FILE: 'File',
  FORM: 'Form',
  GOOGLE_ADS: 'Google Ads',
  HOME: 'Home',
  INSTAGRAM: 'Instagram',
  INVOICE: 'Invoice',
  MARKETING_TEMPLATE: 'Marketing Template',
  MARKETO_LEAD: 'Marketo Lead',
  MESSAGE: 'Message',
  MODULE_COMMENT: 'Module Comment',
  ONBOARDING_USER: 'Onboarding User',
  ORGANIZATION_ACCESS: 'Organization Access',
  ORGANIZATION_USER: 'Organization User',
  ORGANIZATION: 'Organization',
  PAYOUT: 'payout',
  PERFORMANCE_INDICATOR: 'Performance Indicator',
  PERFORMANCE_SNIPPET: 'Performance-Snippet',
  PERFORMANCE: 'Performance',
  PIN_ORGANIZATION: 'Pin Organization',
  PROFILE_PICTURE: 'Profile Picture',
  PROMOTION_CODE: 'promo-code',
  REPORT: 'Report',
  REVIEW_MODULE: 'Review Module',
  REVISIONS: 'Revision',
  ROLE: 'Role',
  SETTINGS: 'Settings',
  STRIPE: 'stripe',
  SUBMISSION_MODULE: 'Submission Module',
  SUBMISSION: 'Submission',
  TAG: 'Tag',
  TASK: 'Task',
  TIK_TOK: 'TikTok',
  TREND: 'Trend',
  UPDATE_USER: 'Update User',
  USER: 'User',
  USER_PHONE: 'User Phone',
  USER_NOTIFICATION: 'User Notification',
  VIDEO_COMPOSITION: 'Video Composition',
};

const makePlurals = <T extends { [key in keyof typeof RESOURCES]: any }>(): T => {
  const plurals = {};
  Object.entries(RESOURCES).forEach(([key, resourceName]) => {
    plurals[key] = `${resourceName}s`;
  });
  // @ts-ignore
  return plurals;
};

export const PLURALS: { [key in keyof typeof RESOURCES]: any } = makePlurals();

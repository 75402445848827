const MAX_DISPLAY_PAGES = 5;
export const SEPARATOR_TEXT = '...';

export const setPages = (totalPages: number, currentPage: number): (number | { separator: number })[] => {
  const pageNumbers: (number | { separator: number })[] = [];
  if (totalPages <= MAX_DISPLAY_PAGES) {
    for (let page = 1; page <= totalPages; ++page) {
      pageNumbers.push(page);
    }
  } else {
    const distFromLeft = currentPage - 1;
    const distFromRight = totalPages - currentPage;
    const maxDistance = MAX_DISPLAY_PAGES - 1;

    if (distFromLeft < maxDistance) {
      for (let page = 1; page <= maxDistance; ++page) {
        pageNumbers.push(page);
      }
      if (maxDistance === currentPage) pageNumbers.push(currentPage + 1);
      pageNumbers.push({ separator: currentPage + 1 }, totalPages);
    } else if (distFromRight < maxDistance) {
      pageNumbers.push(1, { separator: 1 });
      for (let page = totalPages - maxDistance; page <= totalPages; ++page) {
        pageNumbers.push(page);
      }
    } else {
      const difference = Math.floor((MAX_DISPLAY_PAGES - 2) / 2);
      pageNumbers.push(1, { separator: 1 });
      for (let page = currentPage - difference; page <= currentPage + difference; ++page) {
        pageNumbers.push(page);
      }
      pageNumbers.push({ separator: currentPage + difference }, totalPages);
    }
  }
  return pageNumbers;
};

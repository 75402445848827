import { Button } from 'shared/components/display';
import { VettingStatus } from 'shared/typings/user/enums';
import React from 'react';

import { BrkfstUser } from 'shared/typings/user';
import { useCreators } from 'features/creators/useCreators';
import SubDropdownButton from 'shared/components/molecules/SubDropdownButton';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import CreatorVettingStatus from 'features/creators/components/atoms/CreatorVettingStatus';

import vettingApprovalButtonsStyles from './vettingApprovalButtonsStyles';

interface Props {
  creator: BrkfstUser;
  onClick?: () => void;
  portalTarget?: string;
}

const VettingApprovalButtons: React.FC<Props> = ({ creator, onClick, portalTarget }) => {
  const { approveCreatorVetting, rejectCreatorVetting } = useCreators();

  const disableRejected = [VettingStatus.NEW, VettingStatus.REJECTED].includes(creator.vettingStatus);

  const disableApproved = [VettingStatus.NEW, VettingStatus.APPROVED].includes(creator.vettingStatus);

  return (
    <SubDropdownButton
      ButtonContent={
        <>
          Vet Creator
          <CreatorVettingStatus vettingStatus={creator.vettingStatus} />
        </>
      }
      onClick={onClick}
      portalTarget={portalTarget}
      dataCy="vetting-approval-buttons"
      width="225px"
      allRounded
    >
      <Button
        disabled={disableApproved}
        className="vetting-approval-buttons__btn"
        css={vettingApprovalButtonsStyles}
        onClick={() => approveCreatorVetting(creator.id, creator.vettingStatus)}
        variant={BUTTON_VARIANTS.OPTION}
        dataCy="vetting-approval-buttons__approve"
      >
        Approve
      </Button>
      <Button
        disabled={disableRejected}
        className="vetting-approval-buttons__btn"
        css={vettingApprovalButtonsStyles}
        onClick={() => rejectCreatorVetting(creator.id, creator.vettingStatus)}
        variant={BUTTON_VARIANTS.OPTION}
        dataCy="vetting-approval-buttons__reject"
      >
        Reject
      </Button>
    </SubDropdownButton>
  );
};

export default VettingApprovalButtons;

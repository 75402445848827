import { Box } from 'shared/components/display';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import passwordStyles from 'shared/components/inputs/PasswordInput/passwordStyles';
import UnderlineInput from 'shared/components/inputs/UnderlineInput/UnderlineInput';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import InputLabel from 'shared/styles/InputLabel/InputLabel';
import React, { useState } from 'react';
import { Button } from 'rebass/styled-components';

library.add(faEye, faEyeSlash);

interface Props {
  disabled: boolean;
  hasError?: boolean;
  label?: string;
  maxLength: number;
  name: string;
  onBlur: (e: React.FocusEvent) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  touched?: boolean;
  value: string;
  children?: JSX.Element | Array<JSX.Element>;
  dataCy?: string;
}

const Password: React.FC<Props> = ({
  onChange,
  onBlur,
  children,
  maxLength,
  disabled = false,
  value,
  placeholder,
  touched = false,
  hasError = false,
  label,
  name,
  dataCy,
}) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow((prev) => !prev);
  const [active, setActive] = useState(false);

  return (
    <Box css={passwordStyles} className="password-input">
      <InputLabel
        htmlFor={name}
        disabled={disabled}
        active={active}
        hasValue={value?.length > 0}
        isInvalid={touched && hasError}
      >
        {label}
      </InputLabel>
      <UnderlineInput
        type={show ? 'text' : 'password'}
        onChange={onChange}
        onFocus={() => setActive(true)}
        onBlur={(e) => {
          setActive(false);
          onBlur(e);
        }}
        maxLength={maxLength}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        name={name}
        dataCy={dataCy}
      />
      <Button
        variant={BUTTON_VARIANTS.TEXT_ONLY}
        onClick={toggleShow}
        type="button"
        tabIndex={-1}
        className="password-input__toggle-btn"
      >
        <FontAwesomeIcon icon={show ? 'eye-slash' : 'eye'} />
      </Button>
      {children}
    </Box>
  );
};

export default Password;

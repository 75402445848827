import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.account-creation-form': {
    marginTop: theme.space[4],
    height: '500px',
    flexDirection: 'column',
  },
  '.account-creation-form': {
    '&__message': {
      marginLeft: theme.space[2],
      marginTop: theme.space[3],
      color: COLORS.RED700,
    },
    '&__input': {
      flexDirection: 'column',
      marginLeft: theme.space[2],
    },
    '&__input-loader': {
      flexDirection: 'row',
      alignItems: 'center',
      marginLeft: theme.space[2],
    },
    '&__submit-button': {
      flexGrow: 1,
      alignItems: 'self-end',
      marginBottom: theme.space[3],
      marginLeft: theme.space[2],
      marginTop: theme.space[3],
    },
    '&__loader': {
      marginLeft: theme.space[2],
      marginTop: theme.space[4],
    },
  },
});

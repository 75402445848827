import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    default: {
        value: {},
        name: '',
    },
};

const configurationsSlice = createSlice({
    name: 'configurations',
    initialState,
    reducers: {
        configurationsLoaded: (configurations, action) => {
            configurations.data = action.payload.data.data;
        },
        configurationAdded: (configurations, action) => {
            configurations.data.unshift(action.payload.data);
            configurations.default = action.payload.data;
        },
        defaultConfigurationSet: (configurations, action) => {
            configurations.default = action.payload
                ? action.payload
                : {
                      value: {},
                      name: '',
                  };
        },
    },
});

export const {
    configurationAdded,
    configurationsLoaded,
    defaultConfigurationSet,
} = configurationsSlice.actions;
export default configurationsSlice.reducer;

export const getConfigurations = (state) => state.configurations.data;
export const getDefaultConfiguration = (state) => state.configurations.default;

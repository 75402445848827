import React, { useMemo, useState } from 'react';
import { Button, Text } from 'rebass/styled-components';
import { partition } from 'lodash';

import { Box } from 'shared/components/display';
import { CarouselItem } from 'shared/components/molecules/CarouselItem';
import FileActionIcon from 'shared/components/molecules/FileListView/FileActionIcon';
import FileListItem from 'shared/components/molecules/FileListView/FileListItem';
import { Lightbox } from 'shared/components/organisms/Lightbox';

import { FILE_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstFile } from 'shared/typings/file';
import { createZip, downloadFile } from 'shared/utilities/fileUtility';

import styles from './fileListViewStyles';

export enum FileAction {
  REMOVE,
  DOWNLOAD,
  NONE,
}

interface Props {
  action?: FileAction;
  showDownloadAll?: boolean;
  zipFolderName?: string;
  files: BrkfstFile[];
  onClick?: (file: BrkfstFile) => any;
  loading?: boolean;
  placeholder?: string;
  showFilePreview?: boolean;
}

const FileListView: React.FC<Props> = ({
  files = [],
  action = FileAction.DOWNLOAD,
  onClick = downloadFile,
  showDownloadAll = false,
  zipFolderName = 'AllFiles',
  loading = false,
  placeholder = '',
  showFilePreview = false,
}) => {
  const [lightbox, setLightbox] = useState<{ open: boolean; index: number }>({
    open: false,
    index: 0,
  });

  const [lightBoxItems, nonPreviewFiles] = useMemo(() => {
    // tiff images can't be easily displayed like other images so they will not be displayed in the lightbox
    const lightboxFormatRegexp = new RegExp('(video|image)/(?!tiff)');
    return partition(files, (file) => lightboxFormatRegexp.test(file.mimeType));
  }, [files]);

  return (
    <Box css={styles} className="file-list-view">
      {showDownloadAll ? (
        <Button
          onClick={() => createZip(files, zipFolderName)}
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          className="file-list-view__download-all-btn"
        >
          <Text variant={TEXT_VARIANTS.SUBHEADING_SM}>{FILE_COPY.BUTTON_DOWNLOAD_ALL}</Text>
        </Button>
      ) : (
        <></>
      )}
      <ul className="file-list-view__files">
        {files.length === 0 && placeholder && <Text as="p">{placeholder}</Text>}
        {lightBoxItems.map((file, index) => {
          return (
            <FileListItem
              key={file.name}
              fileName={file.name}
              mimeType={file.mimeType}
              onClick={
                showFilePreview
                  ? () => {
                      setLightbox({ index, open: true });
                    }
                  : undefined
              }
            >
              <FileActionIcon file={file} action={action} onRemoveHandler={onClick} loading={loading} />
            </FileListItem>
          );
        })}
        {nonPreviewFiles.map((file) => {
          return (
            <FileListItem
              key={file.name}
              fileName={file.name}
              mimeType={file.mimeType}
              onClick={
                showFilePreview
                  ? () => {
                      window.open(file.url, '_blank');
                    }
                  : undefined
              }
            >
              <FileActionIcon file={file} action={action} onRemoveHandler={onClick} loading={loading} />
            </FileListItem>
          );
        })}
      </ul>
      {showFilePreview ? (
        <Lightbox
          ariaLabel="Media Preview"
          isOpen={lightbox.open}
          selectedItemIndex={lightbox.index}
          onRequestClose={() => {
            setLightbox((prev) => ({
              ...prev,
              open: false,
            }));
          }}
        >
          {lightBoxItems.map((file) => (
            <CarouselItem
              metadata={file.metadata}
              thumbnailUrl={file.thumbnailUrl}
              mimeType={file.mimeType}
              url={file.url}
              key={file.id}
            />
          ))}
        </Lightbox>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default FileListView;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '.report-form': {
    '&__label': {
      marginTop: theme.space[4],
      marginBottom: '-0.8rem',
    },
    '&__interval-wrapper': {
      alignContent: 'center',
    },
    '&__interval': {
      marginLeft: theme.space[3],
    },
    '&__submit-btn': {
      margin: `${theme.space[4]} 0`,
    },
  },
});

import { FormSubmissionModule, SubmissionFormState, SubmitValues } from './types';
import { isEqual } from 'lodash';
import { Brief } from 'shared/typings/briefs';
import { BrkfstFile } from 'shared/typings/file';
import { Submission } from 'shared/typings/submissions';
import { RICHTEXT_DEFAULT_VALUE } from 'shared/components/Richtext';

const submissionModuleEdited = (submission: Submission, files: BrkfstFile[], index: number) => {
  const prevFiles = submission.moduleSubmissions[index]?.files || [];
  const somethingChanged = submissionFilesChanged(files, prevFiles);
  return somethingChanged;
};

const submissionFilesChanged = (files: BrkfstFile[], prevFiles: BrkfstFile[]) => {
  if (files.length !== prevFiles.length) return true;
  return files.some((file, i) => {
    const prevFile = prevFiles[i];
    return !isEqual(file.tags, prevFile.tags) || file.name !== prevFile.name || file.id !== prevFile.id;
  });
};

const getUpdatedModuleIds = (submission: Submission, submissionFormData: SubmissionFormState): number[] => {
  return submissionFormData.moduleAssets
    .filter(({ files }, index) => submissionModuleEdited(submission, files, index))
    .map(({ id }) => id)
    .filter((id) => Boolean(id)) as number[];
};

export const formatSubmitValues = (submission: Submission, state: SubmissionFormState): SubmitValues => ({
  moduleAssets: state.moduleAssets,
  description: JSON.stringify(state.description),
  updatedModuleIds: getUpdatedModuleIds(submission, state),
});

export const formatInitialValues = (submission: Partial<Submission>, brief: Brief): SubmissionFormState => {
  const description = submission.description ? JSON.parse(submission.description) : RICHTEXT_DEFAULT_VALUE;
  const moduleAssets: FormSubmissionModule[] = [];
  for (const { id, minAssetDuration, maxAssetDuration, fileType, moduleNum } of brief.modules) {
    const submissionModule = submission?.moduleSubmissions?.find(({ moduleId }) => moduleId === id);
    const files = submissionModule?.files.map((file) => ({
      ...file,
      moduleSubmissionId: submissionModule.id,
      minDuration: minAssetDuration,
      maxDuration: maxAssetDuration,
    }));
    moduleAssets.push({
      files: files || [],
      moduleAssetCount: files?.length || 0,
      touched: false,
      moduleId: id,
      id: submissionModule?.id,
      status: submissionModule?.status,
      fileType: fileType,
      minDuration: minAssetDuration,
      maxDuration: maxAssetDuration,
      moduleNum,
    });
  }
  return { description, moduleAssets };
};

import { css } from '@emotion/react';

import { fontSizes } from 'shared/styles/text';
import theme from 'shared/styles/themes';

export default css({
  '.submission-view': {
    '&__edit-button': {
      marginLeft: theme.space[5],
      marginTop: theme.space[5],
    },
    '&__creator-name': {
      fontWeight: 700,
      fontSize: fontSizes[5],
      paddingLeft: theme.space[5],
    },
    '&__creator-info': {
      marginLeft: theme.space[4],
    },
  },
  '.profile-picture__placeholder--xs': {
    height: '2.5rem',
    width: '2.5rem',
  },
  '.profile-picture__img--xs': {
    height: '2.5rem',
    width: '2.5rem',
  },
});

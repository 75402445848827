import { Box, Flex, Text } from 'shared/components/display';
import Checkbox from 'shared/components/atoms/Checkbox';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import toggleInputStyles from 'shared/components/ToggleInput/toggleInputStyles';
import { Input } from 'shared/lib/formik';
import { ELEMENTS } from 'shared/styles/styles';
import { TEXT_VARIANTS } from 'shared/styles/text';
import cs from 'classnames';
import React from 'react';

const { BODY_SM } = TEXT_VARIANTS;
const { LABEL } = ELEMENTS;

interface Props {
  disabled?: boolean;
  label: string;
  onChange?: (value: React.SyntheticEvent) => void;
  className?: string;
  dataCy?: string;
}

const ToggleInput: Input<boolean, Props> = ({
  field: { name, value, onChange: formikOnChange, onBlur },
  label,
  disabled = false,
  onChange,
  className,
  dataCy,
}) => {
  const checked = value || false;

  const onChangeCallback = (event) => {
    event.persist();
    if (onChange) onChange(event);
    formikOnChange({ target: { value: event.target.checked, name } });
    onBlur(event);
  };

  return (
    <Box css={toggleInputStyles} className={cs('check-box', className)}>
      <Flex className="check-box__wrapper">
        <Checkbox
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onChangeCallback}
          className={'check-box__input'}
          dataCy={dataCy}
        />

        <Text
          variant={BODY_SM}
          as={LABEL}
          htmlFor={name}
          className={`check-box__label ${disabled && 'check-box__label--disabled'}`}
        >
          {label}
        </Text>
      </Flex>
      <FieldErrorMessage name={name} />
    </Box>
  );
};

export default ToggleInput;

import React from 'react';
import { Box } from 'shared/components/display';
import SidebarNavBar from 'shared/components/atoms/SidebarNavBar';
import { AccountPlatform } from 'shared/typings/accountPlatform';
import Tooltip from 'shared/components/atoms/Tooltip';
import NavItem from 'shared/components/atoms/NavItem';
import TiktokIcon from 'shared/components/atoms/TiktokIcon';
import { LinkCreator, RouteKey } from 'shared/utilities/linkUtility';
import { UserType } from 'shared/typings/user/enums';
import tiktokNavStyles from 'features/home/components/molecules/TikTokNav/tiktokNavStyles';

const tooltipDelay: [number, number] = [500, 0];

interface Props {
  tiktokPlatform: AccountPlatform;
  onIconClick?: () => void;
  isActive?: boolean;
  organizationId: number;
  accountId: number;
  expanded?: boolean;
}
const TikTokNav: React.FC<Props> = ({ accountId, organizationId, tiktokPlatform, onIconClick, isActive, expanded }) => {
  const makeLink = (routeKey: RouteKey) =>
    LinkCreator.createLink({
      userType: UserType.ORG,
      routeKey,
      variables: {
        organizationId,
        accountId,
        accountPlatformId: tiktokPlatform?.id,
      },
    });

  return (
    <Box className="tiktok-nav" css={tiktokNavStyles}>
      <SidebarNavBar>
        <Tooltip
          content={tiktokPlatform.name}
          touch={['hold', 100]}
          delay={tooltipDelay}
          className="tiktok-nav__tooltip"
        >
          <NavItem
            text={tiktokPlatform.name}
            Icon={<TiktokIcon />}
            onClick={onIconClick}
            className="tiktok-nav__item"
            tabIndex={expanded ? 0 : -1}
          />
        </Tooltip>
        <NavItem
          text="Dashboard"
          path={makeLink('PLATFORM')}
          exact
          hide={!isActive}
          className="tiktok-nav__sub-item"
          tabIndex={expanded ? 0 : -1}
        />
      </SidebarNavBar>
    </Box>
  );
};

export default TikTokNav;

import * as yup from 'yup';

import { CONSTRAINTS, MESSAGES } from 'shared/config/validations';

export const adHealthSignUpSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .matches(CONSTRAINTS.NO_SPECIAL_CHARACTERS_CONSTRAINT, {
      message: MESSAGES.NO_SPECIAL_CHARACTERS,
    })
    .required(MESSAGES.REQUIRED_FIELD),
  lastName: yup
    .string()
    .trim()
    .matches(CONSTRAINTS.NO_SPECIAL_CHARACTERS_CONSTRAINT, {
      message: MESSAGES.NO_SPECIAL_CHARACTERS,
    })
    .required(MESSAGES.REQUIRED_FIELD),
  email: yup.string().trim().email(MESSAGES.EMAIL).required(MESSAGES.REQUIRED_FIELD),
  company: yup.string().trim().required(MESSAGES.REQUIRED_FIELD),
});

export const AD_HEALTH_SIGN_UP_FIELDNAMES = {
  FIRST_NAME: 'firstName' as const,
  LAST_NAME: 'lastName' as const,
  EMAIL: 'email' as const,
  COMPANY: 'company' as const,
};

export const AD_HEALTH_SIGN_UP_FORM_NAME = 'adHealthSignUpForm';

export interface AdHealthSignUpValues {
  [AD_HEALTH_SIGN_UP_FIELDNAMES.EMAIL]: string;
  [AD_HEALTH_SIGN_UP_FIELDNAMES.FIRST_NAME]: string;
  [AD_HEALTH_SIGN_UP_FIELDNAMES.LAST_NAME]: string;
  [AD_HEALTH_SIGN_UP_FIELDNAMES.COMPANY]: string;
}

export const initialValues: AdHealthSignUpValues = {
  [AD_HEALTH_SIGN_UP_FIELDNAMES.EMAIL]: '',
  [AD_HEALTH_SIGN_UP_FIELDNAMES.FIRST_NAME]: '',
  [AD_HEALTH_SIGN_UP_FIELDNAMES.LAST_NAME]: '',
  [AD_HEALTH_SIGN_UP_FIELDNAMES.COMPANY]: '',
};

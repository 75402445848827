import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.media-gallery-and-list-display': {
    marginBottom: theme.space[4],
    '&__loading-skeleton--list > *': {
      margin: `${theme.space[4]} 0`,
    },
    '&__loading-skeleton--gallery > *': {
      margin: `0 ${theme.space[2]}`,
    },
  },
});

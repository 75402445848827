import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import Tooltip from 'shared/components/atoms/Tooltip';
import { Box } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import Modal from 'shared/components/molecules/Modal';
import { Select, SelectInput } from 'shared/components/molecules/Select';
import { emptySelectOption } from 'shared/components/molecules/Select/SelectInput';
import TextareaInput from 'shared/components/TextareaInput/TextareaInput';
import TextInput from 'shared/components/TextInput';

import { BUTTON_VARIANTS } from 'shared/styles/button';
import { BrkfstUser } from 'shared/typings/user';
import { LinkCreator } from 'shared/utilities/linkUtility';

import { COPY, PAYOUT_FORM_FIELD, PLACEHOLDER, schema } from './config';
import styles from './styles';
import { PAYOUT_PRESET } from './types';

const NOTE_LENGTH = 100;

interface Props {
  user: BrkfstUser;
  onClick?: () => void;
}

const PayoutForm: React.FC<Props> = ({ user, onClick }) => {
  const navigate = useNavigate();
  const [showPayoutForm, setShowPayoutForm] = useState(false);
  const [option, setOption] = useState({
    label: PLACEHOLDER.PRESET,
    value: '',
    disabled: true,
  });
  const { accounts, getAllAccounts } = useAccounts();

  useEffect(() => {
    getAllAccounts();
  }, []);

  const onSubmit = ({ amount, note, account }) => {
    const pathname = LinkCreator.createLink({
      routeKey: 'PAYOUT_CHECKOUT',
      variables: {
        userId: user.id,
      },
    });
    navigate(
      {
        pathname,
      },
      {
        state: {
          creator: user,
          amount,
          note,
          account,
        },
      },
    );
  };

  return (
    <Box>
      <Tooltip disabled={Boolean(user.stripeAccountId)} content={COPY.TOOLTIP_CONTENT}>
        <Button
          className="payout-button"
          onClick={() => {
            onClick && onClick();
            setShowPayoutForm(true);
          }}
          disabled={!user.stripeAccountId}
          variant={BUTTON_VARIANTS.OPTION}
        >
          {COPY.PAY}
        </Button>
      </Tooltip>

      <Modal
        isOpen={showPayoutForm}
        title={COPY.MODAL_TITLE}
        onRequestClose={() => {
          setShowPayoutForm(false);
        }}
        component={
          <Formik
            onSubmit={onSubmit}
            validationSchema={schema}
            initialValues={{
              amount: '',
              note: '',
              account: emptySelectOption,
            }}
            validateOnBlur={false}
          >
            {(formikProps) => (
              <Box className="payout-form" css={styles}>
                <Form>
                  <Field
                    name={PAYOUT_FORM_FIELD.ACCOUNT}
                    component={SelectInput}
                    isSearchable
                    filterByLabel
                    placeholder={PLACEHOLDER.BRAND}
                    options={accounts.map(({ name, id }) => ({
                      label: name,
                      value: id,
                    }))}
                  />
                  <Field
                    className="payout-form__amount"
                    key={PAYOUT_FORM_FIELD.AMOUNT}
                    name={PAYOUT_FORM_FIELD.AMOUNT}
                    component={TextInput}
                    label={PLACEHOLDER.AMOUNT}
                    placeholder={PLACEHOLDER.AMOUNT}
                  />
                  <Select
                    placeholder={PLACEHOLDER.PRESET}
                    value={option}
                    options={[
                      { label: PLACEHOLDER.PRESET, value: '', isDisabled: true },
                      { label: PAYOUT_PRESET.ADDITIONAL_BRIEF_PAYMENT, value: PAYOUT_PRESET.ADDITIONAL_BRIEF_PAYMENT },
                      {
                        label: PAYOUT_PRESET.EXPEDITED_SUBMISSION_PAYMENT,
                        value: PAYOUT_PRESET.EXPEDITED_SUBMISSION_PAYMENT,
                      },
                      { label: PAYOUT_PRESET.META_EARNINGS, value: PAYOUT_PRESET.META_EARNINGS },
                      { label: PAYOUT_PRESET.TIKTOK_EARNINGS, value: PAYOUT_PRESET.TIKTOK_EARNINGS },
                      { label: PAYOUT_PRESET.YOUTUBE_EARNINGS, value: PAYOUT_PRESET.YOUTUBE_EARNINGS },
                      { label: PAYOUT_PRESET.PRODUCT_PASSTHROUGH, value: PAYOUT_PRESET.PRODUCT_PASSTHROUGH },
                    ]}
                    name={PAYOUT_FORM_FIELD.PRESET}
                    onChange={(option: any) => {
                      formikProps.setFieldValue(PAYOUT_FORM_FIELD.NOTE, option?.value);
                      setOption(option);
                    }}
                  />
                  <Field
                    showCharactersCounter
                    softMaxLength={NOTE_LENGTH}
                    maxLength={NOTE_LENGTH}
                    component={TextareaInput}
                    name={PAYOUT_FORM_FIELD.NOTE}
                    placeholder=""
                  />
                  <FormButton className="payout-form__submit">{COPY.SUBMIT}</FormButton>
                </Form>
              </Box>
            )}
          </Formik>
        }
      />
    </Box>
  );
};

export default PayoutForm;

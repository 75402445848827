export enum FileUploadStatus {
  UPLOADING = 1,
  UPLOADED = 2,
  UPLOAD_FAILED = 3,
  UPLOAD_CANCELLED = 4,
  UPLOAD_ATTEMPTING_CANCEL = 5,
}

export enum FileFormats {
  IMAGE = 'image',
  VIDEO = 'video',
  FILE = 'file',
  APPLICATION = 'application',
  CSV = 'CSV',
}

export enum MimeTypes {
  CSV = 'text/csv',
  EPS = 'application/postscript, application/eps, application/x-eps, image/eps, image/x-eps',
  GIF = 'image/gif',
  JPEG = 'image/jpeg',
  MOV = 'video/quicktime',
  MP4 = 'video/mp4',
  OCTET_STREAM = 'image/octet-stream',
  PDF = 'application/pdf',
  PNG = 'image/png',
  SVG = 'image/svg+xml',
  TIFF = 'image/tiff',
  WEBP = 'image/webp',
}

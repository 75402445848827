export const FACEBOOK_COPY = {
  // Buttons
  BUTTON_UPLOAD: 'Upload',
  BUTTON_SAVE_CONFIG: 'Save Configuration',
  BUTTON_CHECKOUT: 'Confirm & Launch Ad',

  // Alts
  ALT_ASSET: 'Asset Preview',

  // Warnings
  WARNING_EMPTY_FIELDS: `Please fill the following fields: #{fields}`,
  WARNING_CAROUSEL_CARD: `Card #{cardNum}: #{fields}`,
  WARNING_CREATION_FORM: `All fields must be filled out to upload ad(s)`,

  // Headings
  HEADING_LOADING: 'Loading all pages',
  HEADING_FORM: 'Create Ads',
  HEADING_FORM_LANDING_PAGE: 'Landing Page',
  HEADING_FORM_HEADLINE: 'Headline',
  HEADING_FORM_DESCRIPTION: 'Description',
  HEADING_FORM_PRIMARY_TEXT: 'Primary Text',
  HEADING_FORM_DYNAMIC_VARIABLES: 'Dynamic Variables',

  // Checkout
  HEADING_ASSET: 'Asset',
  HEADING_LAUNCH_FB_AD: 'Launch Facebook Ad',
  BUTTON_BACK_TO_CREATE_AD: 'Edit Facebook Ad',
  HEADING_AD_DETAIL: 'Ad Details',
  HEADING_CHECKOUT_FACEBOOK: 'Payment Summary',
  HEADING_TERMS_AND_CONDITIONS: 'BRKFST FACEBOOK AD LAUNCH TERMS AND CONDITIONS',
  CHECKOUT_SUCCESS_BODY: 'You will be notified when your Ads have successfully been created in Meta.',
  CHECKOUT_SUCCESS_BUTTON: 'View Dashboard',
  CHECKOUT_CONFIRM_LEAVE_PROCESSING: 'Are you sure you want to leave this page while we are processing your payment?',

  // Placeholders
  PLACEHOLDER_CONFIG: 'Load Configuration',

  // Labels
  LABEL_ADSETS: 'Adset(s): ',
  LABEL_UPLOAD_ADS: 'Upload ads to the following ad sets: ',
  LABEL_OPTIMIZE: 'Show Best Performing Card First',
  LABEL_ADD_END_CARD: 'Add End Card',
  LABEL_AD_PUBLISHED: 'Your Ads Will Be Published Soon',

  // Prompts
  PROMPT_AD_UPLOAD_TITLE: 'Ready to upload?',
  PROMPT_AD_UPLOAD_BODY: [
    `Upon checkout, your uploaded ads cannot be edited.`,
    `All configured ads will be created in each specified ad set(s).`,
    `The credit card on file will be charged when the account's ad spend balance goes under 5% of your allocated media spend.`,
  ],

  // Form
  FORM_DESCRIPTION_DYNAMIC_VARIABLES: `Dynamic variables can be used to name your ads and create URL parameters for the landing pages.

If you are creating multiple ads and want to follow a common ad naming convention that contains the names of the elements of the ad such as the name of the creative, landing page or headline, you can use dynamic variables to create multiple ad names using just one input.

For example, the ad name: "MyBrandName_{{creativeName}}_{{landingPageName}}_{{headlineName}}"

Similarly, if you are creating URL tags for Google Analytics tracking, you can use dynamic variables to set up the values for the parameters. 

For example, you can use the creative name dynamic variable to create the value for the parameter utm_content: "utm_source=facebook&utm_content={{creativeName}}`,
};

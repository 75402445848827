import { CustomPhoto } from 'features/media/components/organisms/Gallery/types';
import React from 'react';
import { RenderRowContainer } from 'react-photo-album';

export const CustomRowContainer: RenderRowContainer<CustomPhoto> = ({ rowContainerProps, children }) => {
  return (
    <div
      {...rowContainerProps}
      style={{
        ...rowContainerProps?.style,
        justifyContent: 'flex-start',
      }}
    >
      {children}
    </div>
  );
};

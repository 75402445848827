import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

interface Props {
  setDirtyState?: (isDirty: boolean) => void;
}

/**
 * To be used inside formik forms to help track when the
 * the form has been touched
 */
const TrackDirtyState: React.FC<Props> = ({ setDirtyState }) => {
  const formik = useFormikContext();
  useEffect(() => {
    if (setDirtyState) setDirtyState(formik.dirty);
  }, [formik.dirty]);
  return <></>;
};

export default TrackDirtyState;

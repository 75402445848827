import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SubmitValues } from 'features/submissions/components/SubmissionForm/types';
import {
  getSubmission,
  getSubmissionCount,
  getSubmissions,
  hasBeenSeenToggled,
  submissionAdded,
  submissionChanged,
  submissionsCleared,
  submissionsLoaded,
  systemSubmissionLoaded,
} from 'features/submissions/submissions.slice';
import { RICHTEXT_DEFAULT_VALUE } from 'shared/components/Richtext';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_REVISION_ROUTES } from 'shared/config/routes/api/apiRevisionRoutes';
import { API_SUBMISSION_ROUTES } from 'shared/config/routes/api/apiSubmissionRoutes';
import { SUBMISSION_TOASTS } from 'shared/config/toasts/submissionToasts';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { intl } from 'shared/lib/intl';
import { PushFunction } from 'shared/typings/api';
import { Submission } from 'shared/typings/submissions';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

const { SUBMISSION } = RESOURCES;
const UPDATE_PUBLISH_SUBMISSION = 'updatePublishSubmission';
const CREATE_SUBMISSION = 'createSubmission';
const UPDATE_DRAFT_SUBMISSION = 'updateDraftSubmission';
interface Params<T extends number | undefined> {
  submissionId?: T;
  loadingStartingState?: boolean;
}
// submissionId should be a number, using this notation to get conditional type for submission
export const useSubmissions = <T extends number | undefined>(
  { submissionId, loadingStartingState }: Params<T> = {
    loadingStartingState: true,
  },
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submissions: Submission[] = useSelector(getSubmissions);
  const totalSubmissions: number = useSelector(getSubmissionCount);
  const submission: T extends number ? Submission : null = useSelector(getSubmission(submissionId));
  const { loading } = useComponentLoading(SUBMISSION, loadingStartingState);
  const { loading: loadingUpdatePublishSubmission } = useComponentLoading(UPDATE_PUBLISH_SUBMISSION, false);
  const { loading: loadingCreateSubmission } = useComponentLoading(CREATE_SUBMISSION, true);
  const { loading: loadingUpdateDraftSubmission } = useComponentLoading(UPDATE_DRAFT_SUBMISSION, false);

  const fetchSubmissions = (query: any) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_ROUTES.SUBMISSIONS,
        },
        params: query,
        successAction: submissionsLoaded,
        entity: SUBMISSION,
      }),
    );
  };

  const editSubmission = ({
    submissionData,
    successToast,
    pushFunction,
    isDraft,
  }: {
    submissionData: SubmitValues & { id: string };
    successToast?: any;
    pushFunction?: PushFunction;
    isDraft?: boolean;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_ROUTES.SUBMISSION,
          variables: {
            submissionId: submissionData.id,
          },
        },
        method: 'PATCH',
        data: submissionData,
        successAction: submissionChanged,
        successToast,
        pushFunction,
        navigate,
        entity: isDraft ? UPDATE_DRAFT_SUBMISSION : UPDATE_PUBLISH_SUBMISSION,
      }),
    );
  };

  const toggleImportance = async (id: number) => {
    const currentSubmission = submissions.find((s) => s.id === id);
    if (currentSubmission) {
      dispatch(
        apiAction({
          path: {
            route: API_SUBMISSION_ROUTES.SUBMISSION,
            variables: {
              submissionId: id,
            },
          },
          method: 'PATCH',
          data: {
            id: currentSubmission.id,
            important: !currentSubmission.important,
          },
          successAction: submissionChanged,
          successToast: {
            message: intl.formatMessage(
              {
                id: 'TOGGLED_IMPORTANT',
              },
              {
                importance: currentSubmission.important ? 'not important' : 'important',
              },
            ),
          },
        }),
      );
    }
  };

  const markAsSeen = ({ submissionId: id }: { submissionId: number }) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_ROUTES.SUBMISSION,
          variables: {
            submissionId: id,
          },
        },
        method: 'PATCH',
        data: {
          id,
          hasBeenSeen: true,
        },
        successAction: hasBeenSeenToggled,
      }),
    );
  };

  const markAsUnSeen = ({ submissionId: id }: { submissionId: number }) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_ROUTES.SUBMISSION,
          variables: {
            submissionId: id,
          },
        },
        method: 'PATCH',
        data: {
          id,
          hasBeenSeen: false,
        },
        successAction: hasBeenSeenToggled,
        entity: SUBMISSION,
        successToast: {
          message: SUBMISSION_TOASTS.MARKED_UNSEEN,
        },
      }),
    );
  };

  const fetchSingleSubmissionAndMarkAsSeen = ({ submissionId: id }: { submissionId: number }) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_ROUTES.SUBMISSION,
          variables: {
            submissionId: id,
          },
        },
        method: 'PATCH',
        data: {
          id,
          hasBeenSeen: true,
        },
        entity: SUBMISSION,
        successAction: hasBeenSeenToggled,
      }),
    );
  };

  const fetchSingleSubmission = ({
    submissionId: id,
    isRefetch,
  }: {
    submissionId: string | number;
    isRefetch?: boolean;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_ROUTES.SUBMISSION,
          variables: {
            submissionId: id,
          },
        },
        entity: SUBMISSION,
        successAction: isRefetch ? submissionChanged : submissionAdded,
      }),
    );
  };

  const createSubmission = (briefId: number) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_ROUTES.SUBMISSIONS,
        },
        method: 'POST',
        data: { briefId, description: JSON.stringify(RICHTEXT_DEFAULT_VALUE) },
        successAction: submissionAdded,
        entity: CREATE_SUBMISSION,
        pushFunction: (data) =>
          LinkCreator.createLink({
            userType: UserType.CREATOR,
            routeKey: 'CREATE_UPDATE_SUBMISSION',
            variables: {
              submissionId: data.id,
            },
          }),
        navigate,
      }),
    );
  };

  const publishSubmission = ({
    submissionData,
    pushFunction,
  }: {
    submissionData: SubmitValues & { id: string };
    pushFunction?: PushFunction;
  }) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_ROUTES.PUBLISH_SUBMISSION,
          variables: {
            submissionId: submissionData.id,
          },
        },
        method: 'PATCH',
        data: submissionData,
        successAction: submissionChanged,
        entity: UPDATE_PUBLISH_SUBMISSION,
        pushFunction,
        navigate,
        successToast: {
          message: SUBMISSION_TOASTS.SUBMISSION_PUBLISHED,
        },
      }),
    );
  };

  const updateRevisionStatus = (revisionId: number) => {
    dispatch(
      apiAction({
        path: {
          route: API_REVISION_ROUTES.REVISION,
          variables: {
            revisionId,
          },
        },
        method: 'PATCH',
      }),
    );
  };

  const createSubmissionPayout = (
    id: number | string,
    creatorId: number | string,
    redirect: string,
    briefId: number,
  ) => {
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_ROUTES.CREATOR_PAYOUT,
          variables: {
            submissionId: id,
            creatorId: creatorId,
          },
        },
        data: { briefId },
        method: 'POST',
        entity: RESOURCES.SUBMISSION,
        navigate,
        successAction: submissionChanged,
        onSuccess: () => {
          navigate('.', { replace: true });
        },
        pushFunction: () => redirect,
        successToast: {
          message: SUBMISSION_TOASTS.SUBMISSION_CREATOR_PAID_OUT,
        },
      }),
    );
  };

  const fetchSystemBriefSubmission = (creatorId: number, onSuccess: () => void) => {
    dispatch(submissionsCleared({}));
    dispatch(
      apiAction({
        path: {
          route: API_SUBMISSION_ROUTES.CREATOR_SYSTEM_SUBMISSION,
          variables: {
            creatorId,
          },
        },
        method: 'GET',
        entity: RESOURCES.SUBMISSION,
        onSuccess,
        successAction: systemSubmissionLoaded,
      }),
    );
  };

  return {
    createSubmission,
    createSubmissionPayout,
    editSubmission,
    fetchSingleSubmission,
    fetchSingleSubmissionAndMarkAsSeen,
    fetchSubmissions,
    fetchSystemBriefSubmission,
    loading,
    loadingUpdatePublishSubmission,
    loadingUpdateDraftSubmission,
    loadingCreateSubmission,
    markAsSeen,
    markAsUnSeen,
    publishSubmission,
    submission,
    submissions,
    toggleImportance,
    totalSubmissions,
    updateRevisionStatus,
  };
};

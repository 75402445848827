import * as yup from 'yup';

import { MESSAGES } from 'shared/config/validations';

export const MAX_PRICING = 10000;
export const MIN_PRICING = 1;
const MAX_PRICING_ERROR = `Payout must not be greater than $${MAX_PRICING}`;
const MIN_PRICING_ERROR = `Payout must not be less than $${MIN_PRICING}`;

export const ACCOUNT_PRICING_FIELDNAMES = {
  PERFORMANCE_CREATOR_PAYOUT: 'performanceCreatorPayout',
  PERFORMANCE_ELITE_CREATOR_PAYOUT: 'performanceEliteCreatorPayout',
  PERFORMANCE_CREATOR_APPROVAL_PRICING: 'performanceCreatorApprovalPricing',
  PERFORMANCE_ELITE_CREATOR_APPROVAL_PRICING: 'performanceEliteCreatorApprovalPricing',
  CREATIVE_CREATOR_PAYOUT: 'creativeOnlyCreatorPayout',
  CREATIVE_ELITE_CREATOR_PAYOUT: 'creativeOnlyEliteCreatorPayout',
  CREATIVE_CREATOR_APPROVAL_PRICING: 'creativeOnlyCreatorApprovalPricing',
  CREATIVE_ELITE_CREATOR_APPROVAL_PRICING: 'creativeOnlyEliteCreatorApprovalPricing',
};

export const ACCOUNT_PRICING_LABELS = {
  CREATOR_PAYOUT: 'Creator Payout',
  ELITE_CREATOR_PAYOUT: 'Elite Creator Payout',
  CREATOR_APPROVAL_PRICING: 'Creator Approval Pricing',
  ELITE_CREATOR_APPROVAL_PRICING: 'Elite Creator Approval Pricing',
};

export interface AccountPricingValues {
  performanceCreatorPayout: number;
  performanceEliteCreatorPayout: number;
  performanceCreatorApprovalPricing: number;
  performanceEliteCreatorApprovalPricing: number;
  creativeOnlyCreatorPayout: number;
  creativeOnlyEliteCreatorPayout: number;
  creativeOnlyCreatorApprovalPricing: number;
  creativeOnlyEliteCreatorApprovalPricing: number;
}

export const initialValues = {
  performanceCreatorPayout: 200,
  performanceEliteCreatorPayout: 350,
  performanceCreatorApprovalPricing: 200,
  performanceEliteCreatorApprovalPricing: 350,
  creativeOnlyCreatorPayout: 850,
  creativeOnlyEliteCreatorPayout: 1000,
  creativeOnlyCreatorApprovalPricing: 2500,
  creativeOnlyEliteCreatorApprovalPricing: 3000,
};

export const validationSchema = yup.object({
  performanceCreatorPayout: yup
    .number()
    .max(MAX_PRICING, MAX_PRICING_ERROR)
    .min(MIN_PRICING, MIN_PRICING_ERROR)
    .required(MESSAGES.REQUIRED_FIELD),
  performanceEliteCreatorPayout: yup
    .number()
    .max(MAX_PRICING, MAX_PRICING_ERROR)
    .min(MIN_PRICING, MIN_PRICING_ERROR)
    .required(MESSAGES.REQUIRED_FIELD),
  performanceCreatorApprovalPricing: yup
    .number()
    .max(MAX_PRICING, MAX_PRICING_ERROR)
    .min(MIN_PRICING, MIN_PRICING_ERROR)
    .required(MESSAGES.REQUIRED_FIELD),
  performanceEliteCreatorApprovalPricing: yup
    .number()
    .max(MAX_PRICING, MAX_PRICING_ERROR)
    .min(MIN_PRICING, MIN_PRICING_ERROR)
    .required(MESSAGES.REQUIRED_FIELD),
  creativeOnlyCreatorPayout: yup
    .number()
    .max(MAX_PRICING, MAX_PRICING_ERROR)
    .min(MIN_PRICING, MIN_PRICING_ERROR)
    .required(MESSAGES.REQUIRED_FIELD),
  creativeOnlyEliteCreatorPayout: yup
    .number()
    .max(MAX_PRICING, MAX_PRICING_ERROR)
    .min(MIN_PRICING, MIN_PRICING_ERROR)
    .required(MESSAGES.REQUIRED_FIELD),
  creativeOnlyCreatorApprovalPricing: yup
    .number()
    .max(MAX_PRICING, MAX_PRICING_ERROR)
    .min(MIN_PRICING, MIN_PRICING_ERROR)
    .required(MESSAGES.REQUIRED_FIELD),
  creativeOnlyEliteCreatorApprovalPricing: yup
    .number()
    .max(MAX_PRICING, MAX_PRICING_ERROR)
    .min(MIN_PRICING, MIN_PRICING_ERROR)
    .required(MESSAGES.REQUIRED_FIELD),
});

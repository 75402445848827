import React from 'react';
import { components, MultiValueProps } from 'react-select';
import { SelectValue } from 'shared/components/molecules/Select/interfaces';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import Tippy from '@tippyjs/react';
import { Box } from 'shared/components/display';

export const SelectMultiValue: React.FC<MultiValueProps<SelectValue>> = (multiValueProps) => {
  const { tooltipContent } = multiValueProps.data;

  if (tooltipContent) {
    const { children, ...props } = multiValueProps;
    return (
      <components.MultiValue {...props}>
        <Tippy role="tooltip" content={tooltipContent} duration={[200, 200]} offset={[0, 10]} delay={[1000, 0]}>
          <Box className="endWithEllipsis">{children}</Box>
        </Tippy>
      </components.MultiValue>
    );
  }

  return <components.MultiValue {...multiValueProps} />;
};

export const VIDEO_COMPOSITION_COPY = {
  BUTTON_COMPOSE: 'Generate Composition(s)',

  SELECTION_ERROR_MULTIPLE_CREATORS: 'All submission files must come from the same creator',
  SELECTION_ERROR_NON_VIDEO_FILE: 'Remove images/gifs from selection to compose',
  SELECTION_ERROR_UNSUPPORTED_ASPECT_RATIO: 'Remove file(s) with unsupported aspect ratio from selection to compose',
  SELECTION_ERROR_INCOMPATIBLE_DIMENSIONS: 'All files with the same aspect ratio must have the same width & height',
  ERROR_SOURCE_FILE_ALREADY_ADDED: 'File has already been added',
  ERROR_UNSUPPORTED_ASPECT_RATIO: 'Cannot compose file with unsupported aspect ratio',
  ERROR_INCOMPATIBLE_DIMENSIONS: 'Cannot compose file with incompatible dimensions',

  FORM_ERROR_NO_BLOCK: 'Please designate a block for each file',
  FORM_ERROR_NO_FILES: 'At least 2 files are required to compose',
  FORM_ERROR_DUPLICATE_ASPECT_RATIO: 'Block {blockNum} cannot contain duplicate aspect ratios',
  FORM_ERROR_DIFFERENT_DURATION: 'Block {blockNum} cannot contain different durations',
  FORM_ERROR_SAME_ASPECT_RATIO: 'All blocks must contain the same aspect ratios',
  FORM_ERROR_ONE_BLOCK: 'Cannot generate a single block composition',
  FORM_ERROR_COMPOSITION_EXISTS: 'Composition already exists as {compositionName} in the {collectionName} collection',
  FORM_ERROR_MIXED_USER_TYPE: 'Block {blockNum} cannot mix creator/author files',
};

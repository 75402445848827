import COLORS from 'shared/styles/colors';

export const modifiersStyles = {
  compare: {
    backgroundColor: COLORS.NEUTRAL50,
    borderRadius: 0,
  },
  compareEnd: {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
  compareStart: {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  selected: {
    backgroundColor: COLORS.PRIMARY200,
    color: COLORS.BLACK,
  },
};

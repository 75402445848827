import { ANY_STATUS_VALUE } from 'features/briefs/BriefIndexHeader/config';

import { BriefStatus } from 'shared/typings/briefs/enums';
import { FULL_BRIEF, INVITE_ONLY, UserBriefStatus } from 'shared/typings/userBriefs/enums';

export const getRequestQuery = (searchParams, accountId, organizationId) => {
  const formattedQuery = {
    page: searchParams.page,
    size: searchParams.size,
    orderBy: searchParams.orderBy,
    orderAsc: searchParams.orderAsc,
  };
  if (searchParams.name) formattedQuery.$ilike = { name: `%${searchParams.name}%` };
  if (searchParams.status >= 0) formattedQuery.status = searchParams.status;
  if (searchParams.full != undefined) formattedQuery.full = searchParams.full;
  if (searchParams.status === BriefStatus.FULL)
    (formattedQuery.status = BriefStatus.OPEN), (formattedQuery.full = true);
  if (searchParams.type >= 0) formattedQuery.type = searchParams.type;
  if (searchParams.status === BriefStatus.OPEN && !searchParams.type) formattedQuery.full = false;
  if (accountId) formattedQuery.accountId = accountId;
  if (!organizationId) {
    // only show public briefs that are open
    formattedQuery.status = BriefStatus.OPEN;
  }
  if (searchParams.briefCreatorStatus) {
    filterBriefCreatorStatus(searchParams.briefCreatorStatus, formattedQuery);
  }

  return formattedQuery;
};

const filterBriefCreatorStatus = (briefCreatorStatus, formattedQuery) => {
  if (briefCreatorStatus === ANY_STATUS_VALUE) {
    formattedQuery.briefCreatorStatus = [
      UserBriefStatus.PENDING,
      UserBriefStatus.NOT_APPLIED,
      UserBriefStatus.REJECTED,
      UserBriefStatus.INVITED,
    ];
  } else if (briefCreatorStatus === UserBriefStatus.NOT_APPLIED) {
    formattedQuery.full = false;
    formattedQuery.briefCreatorStatus = [UserBriefStatus.NOT_APPLIED, UserBriefStatus.INVITED];
  } else if (briefCreatorStatus === FULL_BRIEF) {
    formattedQuery.full = true;
    formattedQuery.briefCreatorStatus = [
      UserBriefStatus.PENDING,
      UserBriefStatus.NOT_APPLIED,
      UserBriefStatus.REJECTED,
      UserBriefStatus.INVITED,
    ];
  } else if (briefCreatorStatus === INVITE_ONLY) {
    formattedQuery.inviteOnly = true;
    formattedQuery.briefCreatorStatus = [
      UserBriefStatus.PENDING,
      UserBriefStatus.NOT_APPLIED,
      UserBriefStatus.REJECTED,
      UserBriefStatus.INVITED,
    ];
  } else {
    formattedQuery.briefCreatorStatus = briefCreatorStatus;
  }
};

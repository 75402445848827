// use for roles, settings

import { accountsLoaded } from 'features/accounts/accounts.slice';
import { cardsLoaded } from 'features/organizations/cards.slice';
import { organizationChanged } from 'features/organizations/organizations.slice';

const ORG_SPLICED = 'ORG_SPLICED';

export function organizationSpliced(payload) {
  return {
    type: ORG_SPLICED,
    payload,
  };
}

export const organizationMiddleware = () => (next) => (action) => {
  next(action);
  if (action.type === ORG_SPLICED) {
    const { cards, accounts, ...organization } = action.payload.data;

    next(organizationChanged({ data: organization }));
    next(accountsLoaded({ data: { data: accounts } }));
    next(cardsLoaded({ data: cards }));
  }
  // else if
};

import React, { useEffect, useState } from 'react';

import { Button, Flex } from 'shared/components/display';

import { BUTTON_VARIANTS } from 'shared/styles/button';

import styles from './styles';

const Counter = ({ onChange, initialCount, max }) => {
  const [count, setCount] = useState(initialCount || 1);

  // Trigger the onChange function when the count changes
  useEffect(() => {
    onChange(count);
  }, [count]);

  // Update the count if the initialCount prop changes (e.g. when the user resets the form)
  useEffect(() => {
    if (count !== initialCount && initialCount > 0) {
      setCount(initialCount);
    }
  }, [initialCount]);

  const increment = () => setCount((prev) => prev + 1);
  const decrement = () => setCount((prev) => prev - 1);

  return (
    <Flex css={styles} className="counter">
      <Button
        variant={BUTTON_VARIANTS.TEXT}
        onClick={decrement}
        disabled={count === 1}
        className="counter__button"
        type="button"
      >
        -
      </Button>
      {count}
      <Button
        variant={BUTTON_VARIANTS.TEXT}
        onClick={increment}
        disabled={count === max}
        className="counter__button"
        type="button"
      >
        +
      </Button>
    </Flex>
  );
};

export default Counter;

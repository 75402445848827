import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.assets-library': {
    margin: theme.space[5],
    flexDirection: 'column',
  },
  '.assets-library': {
    '&__header': {
      marginBottom: theme.space[5],
    },
  },
});

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'rebass/styled-components';
import { usePerformance } from 'shared/hooks/usePerformance';

import { PERFORMANCE_DATA_TYPES } from 'shared/config/performance';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { ELEMENTS } from 'shared/styles/styles';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { RESOURCES } from 'shared/config/resourceNames';

const { MICRO } = BUTTON_VARIANTS;
const { BUTTON } = ELEMENTS;
const { TOP_BOTTOM_ANALYSIS, DEFAULT } = PERFORMANCE_DATA_TYPES;

const ExportCsvButton = ({ adAccountId }) => {
    const {
        downloadPerformanceExportData,
        downloadTopBottomPerformanceExportData,
        performanceData: { type = DEFAULT },
    } = usePerformance();

    const { loading } = useComponentLoading(RESOURCES.PERFORMANCE);

    const handleClick = async () => {
        if (type === TOP_BOTTOM_ANALYSIS) {
            downloadTopBottomPerformanceExportData();
        } else {
            downloadPerformanceExportData(adAccountId);
        }
    };

    return (
        <Button
            type={BUTTON}
            variant={MICRO}
            onClick={handleClick}
            disabled={loading}
        >
            <FontAwesomeIcon icon={faDownload} /> Export
        </Button>
    );
};

export default ExportCsvButton;

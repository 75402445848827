import React from 'react';
import cs from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAwardSimple } from '@fortawesome/pro-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import spotlightIcon from 'shared/images/icons/spotlight-creator-badge.svg';
import COLORS from 'shared/styles/colors';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Flex } from 'shared/components/display';
import styles from './styles';

interface Props {
  className?: string;
  size?: SizeProp;
  showTooltip?: boolean;
  showHoverBox?: boolean;
}
const SpotlightCreatorBadge: React.FC<Props> = ({
  className,
  size = 'lg',
  showTooltip = false,
  showHoverBox = false,
}) => {
  return (
    <Tooltip content="Spotlighted Creator: A rising star new to brkfst.io" disabled={!showTooltip}>
      <Flex
        css={styles}
        className={cs('spotlight-creator-badge', {
          'spotlight-creator-badge--hover': showHoverBox,
        })}
        dataCy="spotlight-creator-badge"
      >
        <img src={spotlightIcon} className="spotlight-creator-badge__icon" />
      </Flex>
    </Tooltip>
  );
};

export default SpotlightCreatorBadge;

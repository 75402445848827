import { startCase } from 'lodash';

export const makeInitials = (orgName: string, maxLength: number) => {
  /**
   * takes an organizations name and returns the uppercase initials, up to the max number of characters allowed
   *
   * @param {string} orgName -example: 'test org'
   * @param {number} maxLength - the max number of initials to return
   *
   * @returns {string} - example: 'TO'
   * */
  return startCase(orgName)
    .split(' ')
    .reduce((initials, word) => {
      return initials + word[0];
    }, '')
    .substring(0, maxLength);
};

import React from 'react';
import { NavLink } from 'react-router-dom';
import cs from 'classnames';

import { Box, Flex, Text } from 'shared/components/display';

import { TEXT_VARIANTS } from 'shared/styles/text';

import navItemStyles from './navItemStyles';
import Chip from 'shared/components/atoms/Chip/Chip';
import COLORS from 'shared/styles/colors';

export interface NavItemProps {
  exact?: boolean;
  text: string;
  path?: string;
  hide?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  Icon?: React.ReactElement;
  variant?: string;
  className?: string;
  linkClassName?: string;
  notificationCount?: number;
  tabIndex?: number;
  isNew?: boolean;
  dataCy?: string;
}
const NavItem: React.FC<NavItemProps> = ({
  text,
  exact,
  path,
  hide = false,
  onClick,
  Icon,
  variant = TEXT_VARIANTS.H6,
  className,
  linkClassName,
  notificationCount,
  tabIndex,
  isNew = false,
  dataCy,
}) => {
  return (
    <Box
      as="li"
      css={navItemStyles}
      className={cs('nav-item', className, {
        'nav-item--hide': hide,
      })}
    >
      {path ? (
        <NavLink
          to={path}
          end={exact}
          onClick={onClick}
          className={linkClassName}
          tabIndex={hide ? -1 : tabIndex}
          data-cy={dataCy}
        >
          <Flex className="nav-item__text-wrapper">
            <Text variant={variant} className={cs('nav-item__text', 'endWithEllipsis')} data-badge={notificationCount}>
              {Icon}
              {text}
            </Text>
            {isNew && (
              <Chip
                className="nav-item__new"
                color={COLORS.SECONDARY600}
                bgColor={COLORS.SECONDARY50}
                label="New"
                square
              />
            )}
          </Flex>
        </NavLink>
      ) : (
        <Flex className="nav-item__text-wrapper">
          <Text
            variant={variant}
            onClick={onClick}
            data-badge={notificationCount}
            className={cs('nav-item__text', 'endWithEllipsis', {
              ['nav-item__text--clickable']: Boolean(onClick),
            })}
          >
            {Icon}
            {text}
          </Text>
          {isNew && (
            <Chip
              className="nav-item__new"
              color={COLORS.SECONDARY600}
              bgColor={COLORS.SECONDARY50}
              label="New"
              square
            />
          )}
        </Flex>
      )}
    </Box>
  );
};

export default NavItem;

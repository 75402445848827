import React, { useState, useEffect, ChangeEvent } from 'react';

import { Flex } from 'shared/components/display';
import styles from 'shared/components/inputs/MinMaxInput/styles';
import UnderlineInput from 'shared/components/inputs/UnderlineInput/UnderlineInput';

type InputChanged = ChangeEvent<HTMLInputElement>;

export type RangeProps = {
  min: number;
  max: number;
  values?: number[];
  onChange: (range: number[]) => void;
};

const Range: React.FC<RangeProps> = ({ min, max, values, onChange }) => {
  const [minValue, setMinValue] = useState(values?.[0] || 0);
  const [maxValue, setMaxValue] = useState(values?.[1] || 1);

  useEffect(() => {
    onChange([minValue, maxValue]);
  }, [minValue, maxValue]);

  const handleChange = (setState: (value: number) => void) => (e: InputChanged) => {
    setState(+e.target.value);
  };

  return (
    <Flex css={styles}>
      <UnderlineInput
        className="min-max-input"
        dataCy="min-max-input__min"
        value={minValue.toString()}
        type="number"
        inputMode="numeric"
        onChange={handleChange(setMinValue)}
        max={maxValue - 1}
        min={min}
      />
      <UnderlineInput
        className="min-max-input"
        dataCy="min-max-input__max"
        value={maxValue.toString()}
        type="number"
        inputMode="numeric"
        onChange={handleChange(setMaxValue)}
        max={max}
        min={minValue + 1}
      />
    </Flex>
  );
};

export default Range;

import React, { SyntheticEvent } from 'react';
import cs from 'classnames';
import { Flex, Text } from 'shared/components/display';
import { faFacebookSquare, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles';

export interface SafeZoneButtonsProps {
  showFbSafezone?: boolean;
  showTtSafezone?: boolean;
  onFbClick?: (e: SyntheticEvent) => void;
  onTtClick?: (e: SyntheticEvent) => void;
  className?: string;
}
const SafeZoneButtons: React.FC<SafeZoneButtonsProps> = ({
  showFbSafezone,
  showTtSafezone,
  onFbClick,
  onTtClick,
  className,
}) => {
  return (
    <Flex css={styles} className={cs('safe-zone-buttons', className)}>
      <Text className="safe-zone-buttons__header">{`Safe\nZone`}</Text>
      <button aria-label="Facebook Safe Zone" onClick={onFbClick} className="safe-zone-buttons__btn">
        <FontAwesomeIcon
          className={cs('safe-zone-buttons__social-media-icon', {
            'safe-zone-buttons__social-media-icon--active': showFbSafezone,
          })}
          icon={faFacebookSquare}
        />
      </button>
      <button aria-label="TikTok Safe Zone" onClick={onTtClick} className="safe-zone-buttons__btn">
        <FontAwesomeIcon
          className={cs('safe-zone-buttons__social-media-icon', {
            'safe-zone-buttons__social-media-icon--active': showTtSafezone,
          })}
          icon={faTiktok}
        />
      </button>
    </Flex>
  );
};

export default SafeZoneButtons;

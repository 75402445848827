import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface RedirectPath {
  pathname: string;
  state?: any;
}

export const useTimedRedirect = () => {
  const navigate = useNavigate();

  const [timeOutIds, setTimeoutIds] = useState<NodeJS.Timeout[]>([]);

  const redirectToPath = (path: RedirectPath, timer: number = 3000) => {
    const timeoutId = setTimeout(() => {
      navigate(path.pathname, { state: path.state });
    }, timer);

    setTimeoutIds((prev) => [...prev, timeoutId]);
  };

  const clearTimeoutRedirects = () => {
    timeOutIds.forEach((id) => clearTimeout(id));
  };

  // on unmount, clear timed out redirects
  useEffect(() => {
    return () => {
      if (timeOutIds.length) clearTimeoutRedirects();
    };
  }, []);

  return {
    redirectToPath,
  };
};

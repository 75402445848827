import React, { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import { createColumnHelper } from '@tanstack/react-table';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import BriefAndBrandCellWrapper from 'features/users/components/molecules/BriefAndBrandCellWrapper/BriefAndBrandCellWrapper';
import PayoutDetailsInfo from 'features/users/components/molecules/PayoutDetailsInfo';
import TotalEarningsHeader from 'features/users/components/molecules/TotalEarningsHeader/TotalEarningsHeader';
import { useEarnings } from 'features/users/hooks/useEarnings';
import { EarningPayout, EarningType } from 'features/users/pages/Earnings/Earnings';
import { Box, Flex } from 'shared/components/display';
import BrkfstTable from 'shared/components/organisms/BrkfstTable';
import Pagination from 'shared/components/organisms/Pagination';

import { EARNING_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { breakpoints } from 'shared/styles/styleFunctions';
import { UserType } from 'shared/typings/user/enums';
import { prettyDate, splitISODate } from 'shared/utilities/dateUtility';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { formatCurrency } from 'shared/utilities/stringUtility';

import styles, { CLASSNAMES, MAIN_CLASS } from './styles';
interface Props {
  earningItem: EarningPayout;
  updateDetailsIndex: (index: number) => void;
}
const EarningDetails: React.FC<Props> = ({ earningItem, updateDetailsIndex }) => {
  const navigate = useNavigate();
  // This data is shared across the payouts array so it's the same for each
  const {
    currentPayouts,
    getEarningDetails,
    loading,
    earningDetailsLoading,
    currentPayoutsTotal,
    performanceSearchParams,
  } = useEarnings();

  const { stripePayoutDate = '', billingEndDate = '', billingStartDate = '', type } = earningItem;

  useEffect(() => {
    getEarningDetails(earningItem, {
      page: performanceSearchParams.performanceFilesPage,
      size: performanceSearchParams.performanceFilesSize,
    });
  }, [earningItem, performanceSearchParams]);

  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const subTotal = formatCurrency(
    currentPayouts.reduce((acc, payout) => {
      return acc + +payout.totalAmount;
    }, 0) / 100,
  );

  const payoutDate = prettyDate(splitISODate(stripePayoutDate));
  const payPeriod =
    type === EarningType.FILE
      ? `${prettyDate(splitISODate(billingStartDate))} - ${prettyDate(splitISODate(billingEndDate))}`
      : '';

  const submissionDate =
    type === EarningType.SUBMISSION && currentPayouts[0]?.type === EarningType.SUBMISSION
      ? prettyDate(splitISODate(currentPayouts[0].submissionDateCreated))
      : '';

  const performanceColumnHelper = useMemo(() => createColumnHelper<any>(), []);

  const performanceColumns = useMemo(() => {
    return type === EarningType.FILE
      ? [
          performanceColumnHelper.accessor(({ thumbnailUrl }) => thumbnailUrl, {
            id: 'thumbnailUrl',
            cell: (info) => <img src={info.getValue()} className={`${CLASSNAMES.fileThumbnail}`} />,
            header: () => EARNING_COPY.FILE,
            meta: {
              width: isMobile ? '25%' : '40%',
            },
          }),
          performanceColumnHelper.accessor(
            ({ briefNames, accountName }) => {
              return { briefNames, accountName };
            },
            {
              id: 'brandBrief',
              cell: (info) => {
                // Files can no longer be in multiple briefs, so we can just grab the first one
                const { briefNames, accountName } = info.getValue();
                const briefName = briefNames ? briefNames[0] : '';
                return <BriefAndBrandCellWrapper key={briefName} briefName={briefName} brandName={accountName} />;
              },
              header: () => EARNING_COPY.COLUMN_BRAND_NAME + ' / ' + EARNING_COPY.COLUMN_BRIEF_NAME,
            },
          ),
          performanceColumnHelper.accessor(({ totalAmount }) => formatCurrency(totalAmount / 100), {
            id: 'amount',
            cell: (info) => <Box className={CLASSNAMES.amountBody}>{info.getValue()}</Box>,
            header: () => EARNING_COPY.COLUMN_AMOUNT_EARNING,
            meta: {
              addTooltip: true,
              width: isMobile ? '20%' : '10%',
              headerClassName: CLASSNAMES.amountHeader,
            },
          }),
        ]
      : [];
  }, [isMobile, performanceColumnHelper, type]);

  const pathToViewSubmission =
    type === EarningType.SUBMISSION
      ? LinkCreator.createLink({
          userType: UserType.CREATOR,
          routeKey: 'CREATOR_SUBMISSIONS',
          query: {
            item: currentPayouts[0]?.submissionId,
          },
        })
      : '';

  const goToSubmission = () => navigate(pathToViewSubmission);

  const renderPayoutDetails = () => {
    const { type, briefName = '', accountName: brand = '', note } = currentPayouts[0];

    return (
      <PayoutDetailsInfo
        payoutDate={payoutDate}
        payoutType={type}
        payPeriod={payPeriod}
        brief={briefName}
        note={note}
        submissionDate={submissionDate}
        brand={type !== EarningType.FILE ? brand : ''}
      />
    );
  };

  return (
    <Flex flexDirection="column" css={styles} className={MAIN_CLASS}>
      {loading || earningDetailsLoading ? (
        <CenteredSpinner />
      ) : (
        <>
          <TotalEarningsHeader total={subTotal} isTotal={false} />
          {currentPayouts && currentPayouts.length > 0 ? renderPayoutDetails() : <></>}
          {type === EarningType.FILE ? (
            <Pagination
              sizeParamName="performanceFilesSize"
              pageParamName="performanceFilesPage"
              totalAmount={currentPayoutsTotal}
              searchParams={performanceSearchParams}
            >
              <BrkfstTable data={currentPayouts} columns={performanceColumns} />
            </Pagination>
          ) : (
            <></>
          )}
          {type === EarningType.SUBMISSION ? (
            <Button variant={BUTTON_VARIANTS.PLAIN} onClick={goToSubmission} className={CLASSNAMES.viewSubmissionBtn}>
              {EARNING_COPY.BUTTON_VIEW_SUBMISSION}
            </Button>
          ) : (
            <></>
          )}
        </>
      )}
    </Flex>
  );
};

export default EarningDetails;

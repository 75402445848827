import React from 'react';
import { toast } from 'react-toastify';

import { toastPopped } from 'features/ui/ui.slice';
import Toast from 'shared/components/atoms/Toast';

import { TOAST_ACTIVATED } from 'shared/actions/toasts';
import { TOAST_VARIANTS } from 'shared/config/toasts/allToasts';
import COLORS from 'shared/styles/colors';

export const toastMiddleware = () => (next) => (action) => {
  next(action);
  if (action.type === TOAST_ACTIVATED) {
    const { message, variant, heading } = action.payload;
    switch (variant) {
      case TOAST_VARIANTS.SUCCESS:
        toast.success(<Toast heading={heading}>{message}</Toast>, {
          style: {
            backgroundColor: COLORS.GREEN500,
          },
        });
        break;
      case TOAST_VARIANTS.WARNING:
        toast.warn(<Toast heading={heading}>{message}</Toast>, {
          style: {
            backgroundColor: COLORS.ORANGE500,
          },
        });
        break;
      case TOAST_VARIANTS.ERROR:
        toast.error(<Toast heading={heading}>{message}</Toast>, {
          style: {
            backgroundColor: COLORS.RED500,
          },
        });
        break;
      case TOAST_VARIANTS.INFO:
        toast.info(<Toast heading={heading}>{message}</Toast>);
        break;
    }
    next(toastPopped(action.payload));
  }
};

export const EARNING_COPY = {
  // Columns
  COLUMN_AMOUNT_EARNING: 'Amount',
  COLUMN_BRAND_EARNING: 'Brand Name',
  COLUMN_BRIEF_EARNING: 'Brief Names',
  COLUMN_PAY_PERIOD_EARNING: 'Pay Period',
  COLUMN_PAY_DATE_EARNING: 'Pay Date',
  COLUMN_THUMBNAIL: 'Thumbnail',
  COLUMN_FILE: 'File Name',
  COLUMN_TOTAL: 'Total Earnings',
  GRAND_TOTAL: 'Grand Total',
  SUBTOTAL: 'Subtotal',
  COLUMN_PAYMENTS_CREATOR: 'Most Recent Pay Period',
  COLUMN_PAYMENTS_BRAND: 'Pay Period',
  COLUMN_DATE_PAID: 'Paid On',
  COLUMN_TYPE: 'Type',
  COLUMN_DATE: 'Date',
  COLUMN_BRAND_NAME: 'Brand',
  COLUMN_BRIEF_NAME: 'Brief',
  NOTE: 'Note',
  PAYOUT_DATE: 'Payout Date',
  PAYOUT_TYPE: 'Payout Type',
  PAY_PERIOD: 'Pay Period',
  SUBMISSION_DATE: 'Submission Date',
  BRIEF: 'Brief',
  BRAND: 'Brand',
  FILE: 'File',

  // Footnotes
  FOOTNOTE_NO_TAX_EARNING: '(excluding tax)',

  // Placeholder
  PLACEHOLDER_NO_UPCOMING_EARNING: 'No Upcoming Earnings',
  PLACEHOLDER_NO_EARNINGS_YET: 'No Earnings Yet',

  // Heading
  HEADING_PAYMENT_HISTORY_EARNING: 'Payment History',
  HEADING_FILE_PAYMENTS: 'File Payments',

  //Button
  BUTTON_VIEW_SUBMISSION: 'View Submission',
};

import { createSlice } from '@reduxjs/toolkit';

const formsSlice = createSlice({
    name: 'forms',
    initialState: {},
    reducers: {
        saveData: (form, action) => action.payload,
        clearData: () => ({}),
    },
});

export const { saveData, clearData } = formsSlice.actions;

export default formsSlice.reducer;

export const getData = (state) => state.forms;

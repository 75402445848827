import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  '.submissions-payout': {
    '&__text': {
      marginLeft: '0.25rem',
      marginBottom: 0,
    },
    '&__text-wrapper': {
      alignItems: 'center',
    },
    '&__text-wrapper--paid': {
      color: COLORS.GREEN500,
    },
    '&__text-wrapper--unpaid': {
      '&:not(:disabled) svg': {
        color: 'blue',
      },
      color: 'blue',
    },
  },
});

const BASE = '/user/';

export default {
  USER_ROUTES: BASE,

  USER_SETTINGS: BASE + 'settings',

  USER_PROFILE: BASE + ':userId',

  CREATOR_APPROVED_ASSETS: BASE + ':userId/approved-assets',

  CREATOR_COLLECTION: BASE + ':userId/library/:collectionId',

  PAYOUT_CHECKOUT: BASE + ':userId/payout',
};

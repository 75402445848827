import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

// only one card can be the default
const removeDefaults = (newCard, cards) => {
    let oldDefault;
    if (newCard.default) {
        oldDefault = cards.find((card) => card.default);
    }

    const newCards = oldDefault
        ? cloneDeep(cards).map((card) =>
              card.id === oldDefault.id ? { ...card, default: false } : card,
          )
        : cloneDeep(cards);

    return newCards;
};

const cardsSlice = createSlice({
    name: 'cards',
    initialState: {
        cards: [],
        selected: null,
    },
    reducers: {
        cardsLoaded: (state, action) => {
            const defaultCard = action.payload.data.find(
                (card) => card.default,
            );
            state.selected = defaultCard ? defaultCard.id : null;
            state.cards = action.payload.data;
        },
        cardAdded: (state, action) => {
            const newCard = action.payload.data;
            const newCards = removeDefaults(newCard, state.cards);
            newCards.push(newCard);
            state.cards = newCards;
        },
        cardAddedAndSelected: (state, action) => {
            const newCard = action.payload.data;
            const newCards = removeDefaults(newCard, state.cards);
            newCards.push(newCard);
            state.cards = newCards;
            state.selected = newCard.id;
        },
        cardRemoved: (state, action) => {
            state.cards = state.cards.filter(
                (card) => card.id !== action.payload.data.id,
            );
            if (state.selected === action.payload.data.id) {
                const defaultCard = state.cards.find((card) => card.default);
                state.selected = defaultCard ? defaultCard.id : null;
            }
        },
        cardChanged: (state, action) => {
            const newCard = action.payload.data;
            const newCards = removeDefaults(newCard, state.cards);

            state.cards = newCards.map((card) =>
                card.id === action.payload.data.id
                    ? { ...card, ...action.payload.data }
                    : card,
            );
        },
        selectedChanged: (state, action) => {
            state.selected = action.payload;
        },
    },
});

export const {
    cardAdded,
    cardsLoaded,
    cardRemoved,
    cardChanged,
    selectedChanged,
    cardAddedAndSelected,
} = cardsSlice.actions;

export const getCards = (state) => state.cards.cards;
export const getSelectedCard = (state) =>
    state.cards.cards.find((card) => card.id === state.cards.selected) || {};

export default cardsSlice.reducer;

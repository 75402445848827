import React from 'react';

import { Box } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import SingleFileUploadNameAndButton from 'shared/components/molecules/SingleFileUploadNameAndButton';
import { BRKFST_SUPPORTED_IMAGE_FORMATS } from 'shared/config/fileFormats';
import { MEDIA } from 'shared/config/media';
import { Input } from 'shared/lib/formik';
import { FileFormats } from 'shared/typings/file/enums';
import { DEFAULT_VALUE, ImageFileInputValue } from './validation';

interface Props {
  placeholder?: string;
  acceptedFormats?: readonly string[];
}

const ImageFileInput: Input<ImageFileInputValue, Props> = ({
  field: { name, value, onChange, onBlur },
  placeholder = '',
  acceptedFormats = [...BRKFST_SUPPORTED_IMAGE_FORMATS, MEDIA.MIME_TYPES.GIF],
}) => {
  const fileOnChange = (file: File | null) => {
    if (file) {
      onChange({ target: { value: { file }, name } });
    } else {
      onChange({ target: { value: { DEFAULT_VALUE }, name } });
    }
  };

  return (
    <Box>
      <SingleFileUploadNameAndButton
        filename={value?.file?.name || ''}
        name={name}
        onChange={fileOnChange}
        onBlur={onBlur}
        placeholder={placeholder}
        fileFormat={FileFormats.IMAGE}
        accept={acceptedFormats}
      />
      <FieldErrorMessage name={name} dataCy="image-file-input__error-message" />
    </Box>
  );
};

export default ImageFileInput;

import { useEffect } from 'react';
import { connect } from 'formik';
import scrollToElement from 'scroll-to-element';
import { getFirstErrorFieldname } from 'shared/utilities/formikUtility';

const ErrorFocus = ({ offset = 0, align = 'top', ease = 'linear', duration = 1000, formik }) => {
  useEffect(() => {
    const { isSubmitting, isValidating, errors } = formik;
    let errorFieldname = getFirstErrorFieldname(errors);
    if (errorFieldname && isSubmitting && !isValidating) {
      // first check if element has special attributes for inputs that can't focused using name attribute (richtext, tag)
      const specialSelector = `[data-focus-selector="${errorFieldname}"]`;
      const specialErrorElement: HTMLElement | null = document.querySelector(specialSelector);
      if (specialErrorElement) {
        specialErrorElement.scrollIntoView();
      } else {
        // select inputs
        const selector = `[name="${errorFieldname}"]`;
        let errorElement: HTMLElement | null = document.querySelector(selector);
        //Check if the error is coming from a scene but it is not expanded
        if(errorElement === null && (errorFieldname.includes("scenes") && 
        (errorFieldname.includes("title") || errorFieldname.includes("description")))){
          //Focus on the scene label since the form with the error is not expanded
          errorFieldname = errorFieldname.substring(0, errorFieldname.lastIndexOf("."));
          errorElement = document.getElementById(errorFieldname);
        }
        if (errorElement) {
          scrollToElement(errorElement, {
            offset,
            ease,
            duration,
            align,
          });

          errorElement.focus();
        }
      }
    }
  }, [formik.isSubmitting, formik.isValidating, formik.errors]);

  return null;
};

export default connect(ErrorFocus);

import React from 'react';
import RedirectOnError from 'features/auth/components/atoms/RedirectOnError';
import { useTimedRedirect } from 'shared/hooks/useTimedRedirect';
import { GENERAL_ROUTES } from 'shared/config/routes/generalRoutes';

const ResetPasswordConfirmation = () => {
  const { redirectToPath } = useTimedRedirect();

  return <RedirectOnError actionCallback={() => redirectToPath({ pathname: GENERAL_ROUTES.HOME_PAGE })} />;
};

export default ResetPasswordConfirmation;

import { useContext, useEffect } from 'react';

import { SignalRContext } from 'shared/contexts/signalr';
import { useToasts } from 'shared/hooks/useToasts';
import { Toast } from 'shared/typings/toasts';

interface AdPayload {
  id: string;
  name: string;
  preview: string; // Stringified HTML IFrame of Ad Preview
}

interface SuccessPayload {
  ads: AdPayload[];
}

interface FacebookError extends Error {
  message: string;
  error_user_title: string;
  error_user_msg: string;
}

interface AdErrorPayload {
  errors: FacebookError[];
}

interface CreativeErrorPayload {
  error: FacebookError;
}

const formatErrorToastPayload = (error: FacebookError): Toast => {
  const { message, error_user_msg, error_user_title } = error;
  let title;
  if (error_user_title) title = `Title: ${error_user_title}\n`;
  let detailedMessage = '';
  if (error_user_msg) detailedMessage = `Detailed Message: ${error_user_msg}`;
  return {
    message: `Message: ${message}\n${detailedMessage}`,
    heading: title,
  };
};

export const useFacebookAdNotification = (): void => {
  const { connection } = useContext(SignalRContext);
  const { setErrorToast, setSuccessToast } = useToasts();
  useEffect(() => {
    const adHandler = ({ ads }: SuccessPayload) => {
      setSuccessToast({
        message: `The following Facebook Ads have been created: [${ads.map(({ name }) => name).join(', ')}]`,
      });
    };

    const adErrorHandler = ({ errors }: AdErrorPayload) => {
      errors.forEach((error) => {
        setErrorToast(formatErrorToastPayload(error));
      });
    };

    const creativeErrorHandler = ({ error }: CreativeErrorPayload) => {
      setErrorToast(formatErrorToastPayload(error));
    };

    const adCopyErrorHandler = ({ errors }) => {
      errors.forEach((error) => {
        setErrorToast({ message: error });
      });
    };

    if (connection) {
      connection.on('newFacebookAd', adHandler);
      connection.on('errorFacebookAd', adErrorHandler);
      connection.on('errorFacebookCreative', creativeErrorHandler);
      connection.on('errorFacebookCopy', adCopyErrorHandler);
    }

    return function cleanup() {
      connection?.off('newFacebookAd', adHandler);
      connection?.off('errorFacebookAd', adErrorHandler);
      connection?.off('errorFacebookCreative', creativeErrorHandler);
      connection?.off('errorFacebookCopy', adCopyErrorHandler);
    };
  }, [connection]);
};

// * Lib
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface Props {
  contentRef: React.RefObject<HTMLDivElement>;
}

const ScrollToTop: React.FC<Props> = ({ contentRef }) => {
  const { pathname } = useLocation<any>();

  useEffect(() => {
    if (contentRef?.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass/styled-components';

import { useCards } from 'features/organizations/useCards';
import { useOrganizations } from 'features/organizations/useOrganizations';
import LinkButton from 'shared/components/clicks/LinkButton';
import StreetAddress from 'shared/components/molecules/StreetAddress';

import { CARD_COPY, ORGANIZATION_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { STYLES } from 'shared/styles/styles';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LinkCreator } from 'shared/utilities/linkUtility';

type RouteParams = {
  organizationId: string;
};

const CheckoutPayment = () => {
  const params = useParams<RouteParams>();
  const organizationId = +params.organizationId;
  const { selectedCard } = useCards({
    organizationId,
    shouldFetch: true,
  });
  const { organization } = useOrganizations(organizationId);

  const editLink = useMemo(
    () =>
      LinkCreator.createLink({
        routeKey: 'CARD_SETTINGS',
        variables: {
          organizationId,
        },
      }),
    [organizationId],
  );

  return (
    <Box my={4}>
      <Flex alignItems={STYLES.CENTER}>
        <Text variant={TEXT_VARIANTS.H4}>{CARD_COPY.HEADING_CHECKOUT}</Text>
        <LinkButton
          pathname={editLink}
          buttonText={CARD_COPY.LINK_CARD_SETTINGS}
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          color={COLORS.PRIMARY500}
        />
      </Flex>
      <Text>{selectedCard.name}</Text>
      <Text mb={4}>{`EXP ${selectedCard.expiration}`}</Text>
      <Flex alignItems={STYLES.CENTER} mt={4}>
        <Text variant={TEXT_VARIANTS.H4}>{ORGANIZATION_COPY.HEADING_BILLING_ADDRESS}</Text>
        <LinkButton
          pathname={editLink}
          buttonText={CARD_COPY.LINK_CARD_SETTINGS}
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          color={COLORS.PRIMARY500}
        />
      </Flex>
      <StreetAddress address={organization.billingAddress} />
    </Box>
  );
};

export default CheckoutPayment;

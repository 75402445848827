import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export const MAIN_CLASS = 'mobile-table';
export const CLASSNAMES = {
  table: `${MAIN_CLASS}__table`,
  tableHeader: `${MAIN_CLASS}__header`,
  tableBody: `${MAIN_CLASS}__body`,
  tableRow: `${MAIN_CLASS}__row`,
  tableRowClickable: `${MAIN_CLASS}__row--clickable`,
  tableCell: `${MAIN_CLASS}__cell`,
  cellContent: `${MAIN_CLASS}__cell-content`,
  noResultsMessage: `${MAIN_CLASS}__no-results-message`,
  tooltipContentWrapper: `${MAIN_CLASS}__tooltip-content-wrapper`,
};
// @ts-ignore
export default css({
  fontSize: theme.fontSizes[1],
  width: '99%',
  margin: '0 auto',
  overflowX: 'auto',
  overflowY: 'hidden',
  '& thead': {
    borderBottom: `1px solid ${COLORS.NEUTRAL300}`,
  },
  [`.${MAIN_CLASS}`]: {
    '&__table': {
      height: 'min-content',
      width: '100%',
      tableLayout: 'fixed',
      overflow: 'hidden',
    },
    '&__header': {
      color: COLORS.NEUTRAL600,
      ...theme.text.category,
    },
    '&__header::before': {
      content: "''",
      paddingRight: '8px',
    },
    '&__header:last-of-type::after': {
      width: '100%',
    },
    '&__body': {
      ...theme.text.caption,
      fontSize: theme.fontSizes[2],
    },
    '&__row': {
      borderBottom: `2px solid ${COLORS.NEUTRAL100}`,
      [mediaquery.md]: {
        '&--clickable:hover': {
          cursor: 'pointer',
          background: COLORS.PRIMARY100,
        },
      },
    },
    '&__cell': {
      padding: '10px 0 10px 8px',
    },
    '&__cell-content': {
      margin: 0,
      whiteSpace: 'nowrap',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: '100%',
      lineHeight: '150%',
      userSelect: 'none',
      [mediaquery.md]: {
        userSelect: 'auto',
      },
    },
    '&__no-results-message': {
      textAlign: 'center',
      padding: '10px',
    },
    '&__tooltip-content-wrapper': {
      maxWidth: '50vw',
      wordBreak: 'break-all',
    },
  },
  '.tippy': {
    whiteSpace: 'nowrap',
    wordBreak: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

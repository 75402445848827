import React from 'react';
import { useIntl } from 'react-intl';

import { FILE_COPY } from 'shared/config/copy';
import { MEDIA } from 'shared/config/media';
import { noOp } from 'shared/defaults';
import { getFileMimeType, hasValidMimeTypeOrExtension } from 'shared/utilities/validator/utils';

interface HiddenInputCommonProps {
  multipleFiles?: boolean;
  onChange: (files: FileList | null) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  maxNumFiles?: number;
  name: string;
  acceptedMedia?: readonly string[];
  disabled?: boolean;
}

type ValidateProps =
  | {
      validateFiles?: false;
      onError?: never;
    }
  | {
      validateFiles: true;
      onError: (err: string) => void;
    };

type HiddenInputProps = HiddenInputCommonProps & ValidateProps;

const HiddenFileInput = React.forwardRef<HTMLInputElement, HiddenInputProps>(
  (
    {
      acceptedMedia = [],
      multipleFiles = false,
      onChange,
      onBlur,
      onError = noOp,
      maxNumFiles = Infinity,
      name,
      validateFiles = false,
      disabled = false,
    },
    ref,
  ) => {
    const { formatMessage } = useIntl();

    const checkCompatibility = (files: FileList | null) => {
      if (files) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const fileType = getFileMimeType(file);
          const compatible = hasValidMimeTypeOrExtension(fileType, file.name, acceptedMedia);
          if (!compatible) return false;
        }
      }
      return true;
    };

    const getFilesFromInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      e.persist();
      // note: when uploading a file using the native file input here we have to get the object url
      const { files } = e.target;
      const numFiles = files ? files.length : 0;
      if (validateFiles && numFiles > maxNumFiles) {
        onError(
          formatMessage(
            { id: 'WARNING_MAX_FILES' },
            {
              maxNumFiles,
            },
          ),
        );
      } else if (validateFiles && !checkCompatibility(files)) {
        onError(FILE_COPY.WARNING_SUPPORTED_MEDIA_DEFAULT);
      } else {
        onChange(files);
      }
    };

    return (
      <input
        type={MEDIA.TYPES.FILE}
        name={name}
        style={{ display: 'none' }}
        onChange={getFilesFromInput}
        onBlur={onBlur}
        //@ts-ignore
        accept={Array.isArray(acceptedMedia) ? acceptedMedia.join(',') : acceptedMedia}
        multiple={multipleFiles}
        ref={ref}
        disabled={disabled}
      />
    );
  },
);

export default HiddenFileInput;

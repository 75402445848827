import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.review-modal': {
    flexDirection: 'column',
  },
  '.review-modal': {
    '&__btn': {
      marginBottom: theme.space[4],
    },
    '&__skip-btn': {
      color: COLORS.WHITE,
      textDecoration: 'underline',
      fontWeight: 'bold',
    },
  },
});

/* Based off of:
https://www.smashingmagazine.com/2021/02/css-z-index-large-projects/

When adding a new zIndex layer, place it in the order it should fall in the existing layers and rearrange the index following it so that it builds off of the new one you just added.

*/
const base = 0;
const above = 1;

export const zBackdrop = base;
export const zForeground = base + above;
export const zButton = zForeground + above;
export const zButtonOverlay = zButton + above;
export const zStickyElements = zButtonOverlay + above;
export const zNav = zStickyElements + above;
export const zNavItem = zNav + above;
export const zDrawer = zNavItem + above;
export const zDrawerItem = zDrawer + above;
export const zOverlay = zDrawerItem + above;
export const zModal = zOverlay + above;
export const zModalContent = zModal + above;
export const zDropdown = zModalContent + above;
export const zDropdownPortal = zDropdown + above;
export const zTooltip = zDropdownPortal + above;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.report-button': {
    '&__btn-wrapper': {
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingRight: '1rem',
      color: COLORS.PRIMARY500,
    },
    '&__delete-btn': {
      padding: 0,
      paddingLeft: theme.space[2],
    },
  },
});

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.marketer-preview': {
    alignItems: 'center',
    textAlign: 'left',
  },
  '.marketer-preview': {
    '&__text-wrapper': {
      flexDirection: 'column',
      marginLeft: theme.space[3],
    },
    '&__org': {
      fontWeight: 400,
      fontSize: '0.6rem',
    },
    '&__name, &__org': {
      margin: 0,
      whiteSpace: 'nowrap',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
});

import { ListType, withLists } from '@prezly/slate-lists';
import { Element } from 'slate';

import { RichTextFormats } from 'shared/components/Richtext/shared/types';

export const withListsPlugin = withLists({
  isConvertibleToListTextNode(node: Node) {
    // prevent a normal text node to be converted to a list when hitting tab
    return false;
  },
  isDefaultTextNode(node: Node) {
    return Element.isElementType(node, RichTextFormats.PARAGRAPH);
  },
  isListNode(node: Node, type?: ListType) {
    if (type) {
      return Element.isElementType(node, type);
    }
    return (
      Element.isElementType(node, RichTextFormats.ORDERED_LIST) ||
      Element.isElementType(node, RichTextFormats.UNORDERED_LIST)
    );
  },
  isListItemNode(node: Node) {
    return Element.isElementType(node, RichTextFormats.LIST_ITEM);
  },
  isListItemTextNode(node: Node) {
    return Element.isElementType(node, RichTextFormats.LIST_ITEM_TEXT);
  },
  createDefaultTextNode(props = {}) {
    return { children: [{ text: '' }], ...props, type: RichTextFormats.PARAGRAPH };
  },
  createListNode(type: ListType = ListType.UNORDERED, props = {}) {
    const nodeType = type === ListType.ORDERED ? RichTextFormats.ORDERED_LIST : RichTextFormats.UNORDERED_LIST;
    return { children: [{ text: '' }], ...props, type: nodeType };
  },
  createListItemNode(props = {}) {
    return { children: [{ text: '' }], ...props, type: RichTextFormats.LIST_ITEM };
  },
  createListItemTextNode(props = {}) {
    return { children: [{ text: '' }], ...props, type: RichTextFormats.LIST_ITEM_TEXT };
  },
});

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.tile-container': {
    display: 'grid',
    gridColumnGap: '1rem',
    gridRowGap: '1rem',
    alignItems: 'baseline',
    gridTemplateRows: '200px',
    gridTemplateColumns: 'repeat(auto-fill, 163px)',
    '&--stacked': {
      gridTemplateRows: '230px',
      alignItems: 'center',
      gridColumnGap: '1.5rem',
      gridRowGap: '1.5rem',
    },
    '&--single-line': {
      gridAutoFlow: 'column',
      gridAutoColumns: '163px',
      // always show scrollbar
      overflowX: 'scroll',
      '::-webkit-scrollbar': {
        backgroundColor: COLORS.WHITE,
        borderRadius: '10px',
        width: '100%',
        height: '7px',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: COLORS.NEUTRAL500,
        borderRadius: '10px',
      },
      [mediaquery.md]: {
        gridAutoColumns: '195px',
      },
    },
    [mediaquery.md]: {
      gridTemplateColumns: 'repeat(auto-fill, 195px)',
    },
  },
});

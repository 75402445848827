import React, { useEffect, useMemo, useState } from 'react';
import { Navigate, Routes, useNavigate, useParams } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { useAccountScoreNotification } from 'features/accountPlatforms/hooks/useAccountScoreNotification';
import AccountHealth from 'features/accountPlatforms/pages/AccountHealth/AccountHealth';
import AccountHealthNoPlatforms from 'features/accountPlatforms/pages/AccountHealthNoPlatforms';
import AccountPlatforms from 'features/accountPlatforms/pages/AccountPlatforms';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import { useAccounts } from 'features/accounts/hooks/useAccounts';
import AccountHome from 'features/accounts/pages/AccountHome';
import { AccountPricing } from 'features/accounts/pages/AccountPricing';
import AccountSettings from 'features/accounts/pages/AccountSettings/AccountSettings';
import ManageCreators from 'features/creators/pages/ManageCreators/ManageCreators';
import { useFacebookAdNotification } from 'features/media/hooks/useFacebookAdNotification';
import { useFacebookAssetNotification } from 'features/media/hooks/useFacebookAssetNotification';
import { useTikTokAssetNotification } from 'features/media/hooks/useTikTokAssetNotification';
import AssetsLibrary from 'features/media/pages/AssetsLibrary/AssetsLibrary';
import TrendsLibrary from 'features/trends/pages/TrendsLibrary';

import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { RESOURCES } from 'shared/config/resourceNames';
import { AccountRouteParams } from 'shared/config/routes/brands/matchTypes';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useDeepEffect } from 'shared/hooks/useDeepEffect';
import { usePermissions } from 'shared/hooks/usePermissions';
import lazyWithRetry from 'shared/lib/lazyWithRetry';
import AccountHealthWrapper from 'shared/router/brandRoutes/AccountRoutes/AccountHealthLayout';
import BrandBriefRoutes from 'shared/router/brandRoutes/BrandBriefRoutes';
import PlatformRoutes from 'shared/router/brandRoutes/PlatformRoutes';
import { LinkCreator } from 'shared/utilities/linkUtility';

import AccountRoutesLayout from './AccountRoutesLayout';

const MarketerCollection = lazyWithRetry(() => import('features/media/pages/MarketerCollection'));
const VideoComposition = lazyWithRetry(() => import('features/media/pages/VideoComposition/VideoComposition'));
const OnboardCompletePage = lazyWithRetry(() => import('features/organizations/pages/OnboardComplete/OnboardComplete'));

export const AccountRoutes = () => {
  const navigate = useNavigate();
  const params = useParams<AccountRouteParams>();
  const organizationId = +params.organizationId;
  const accountId = +params.accountId;

  const { displayComponent } = usePermissions();
  const { account, accounts, requestSnippets, loading } = useAccounts({
    accountId: params.accountId,
  });
  const { isSuperadmin } = useCurrentUser();

  const { fetchAccountPlatforms, facebookAccountPlatforms, platformsLoading } = useAccountPlatforms({
    accountId,
  });

  useAccountScoreNotification();
  useFacebookAssetNotification();
  useTikTokAssetNotification();
  useFacebookAdNotification();

  const [accountsQuantity, setAccountsQuantity] = useState(0);

  useEffect(() => {
    setAccountsQuantity(accounts.length);
  }, [accounts]);

  useDeepEffect(() => {
    if (!loading) {
      const canAccessAccount = account.id !== undefined && account.id !== null && accounts.length > 0;
      if (canAccessAccount) {
        requestSnippets(account);
        fetchAccountPlatforms(accountId);
      } else {
        if (accounts.length > 0 && accountsQuantity === accounts.length) {
          navigate(
            { pathname: LinkCreator.createLink({ routeKey: 'NO_ACCESS' }) },
            { state: { type: `${RESOURCES.ACCOUNT}` } },
          );
        }
      }
    }
  }, [loading, accountId, account.accountPlatformIds, accountsQuantity]);

  if (accounts.length === 0 && !loading) {
    navigate({
      pathname: LinkCreator.createLink({
        routeKey: 'ORGANIZATION',
        variables: {
          organizationId,
        },
      }),
    });
  }

  const showAccountHealth = account.enableAccountHealth || isSuperadmin;

  return (
    <Routes>
      <Route
        element={useMemo(
          () => (
            <AccountRoutesLayout loading={loading} />
          ),
          [loading],
        )}
      >
        <Route index element={<AccountHome />} />
        {showAccountHealth && (
          <Route path="account-health" element={<AccountHealthWrapper loading={platformsLoading} />}>
            <Route
              index
              element={
                !platformsLoading && facebookAccountPlatforms.length > 0 ? (
                  <AccountHealth />
                ) : (
                  <AccountHealthNoPlatforms />
                )
              }
            />
          </Route>
        )}
        <Route path="platform">
          <Route index element={<AccountPlatforms />} />
          <Route path={':accountPlatformId/*'} element={<PlatformRoutes />} />
        </Route>

        <Route path="library">
          <Route index element={<AssetsLibrary />} />
          <Route path=":collectionId" element={<MarketerCollection />} />
          <Route path=":collectionId/compose-videos" element={<VideoComposition />} />
        </Route>
        <Route path="briefs/*" element={<BrandBriefRoutes />} />
        {displayComponent(<Route path="edit/details" element={<AccountSettings />} />, organizationId, [
          PERMISSIONS_MAP.EDIT_ACCOUNT.API,
          PERMISSIONS_MAP.DELETE_ACCOUNT.API,
        ])}
        <Route path="creator" element={<ManageCreators />} />
        <Route path="onboard-complete" element={<OnboardCompletePage />} />
        {isSuperadmin && <Route path="pricing" element={<AccountPricing />} />}
        <Route path="trends" element={<TrendsLibrary />} />
        <Route path="*" element={<Navigate to="/not-found" />} />
      </Route>
    </Routes>
  );
};

import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageSimple, useChannelStateContext, useMessageContext } from 'stream-chat-react';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
const Message = () => {
  const { message } = useMessageContext();
  const { channel } = useChannelStateContext();

  const navigate = useNavigate();
  const { currentUser } = useCurrentUser();

  const senderId = message?.user?.id || 0;

  const messageSentByCreator = useMemo(() => {
    if (message?.user?.type === UserType.CREATOR) {
      return true;
    }
    const creatorId = (channel?.cid || channel?.id || '').replace(new RegExp('.*u([0-9]+)-a[0-9]+'), '$1');

    return +senderId === +creatorId;
  }, [message, channel]);
  const isCurrentUser = +senderId === currentUser.id;

  const navigateToProfile = () => {
    if (messageSentByCreator && !isCurrentUser) {
      navigate(
        LinkCreator.createLink({
          routeKey: 'USER_PROFILE',
          variables: {
            userId: senderId,
          },
        }),
      );
    }
  };

  return <MessageSimple onUserClick={navigateToProfile} />;
};

export default Message;

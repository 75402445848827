export const USER_COPY = {
  // Buttons
  BUTTON_ACCOUNT_SETTINGS: 'Account Settings',
  BUTTON_ADD_PICTURE: 'Drag & Drop a Profile Picture',
  BUTTON_CREATE_NEW_BRIEF: 'Create Brief',
  BUTTON_DELETE_PROFILE_PICTURE: 'Delete Profile Picture',
  BUTTON_EDIT_ADDRESS: 'Edit Address',
  BUTTON_EDIT_NOTIFICATIONS: 'Edit Notifications',
  BUTTON_EDIT_PROFILE_PICTURE: 'Change Profile Picture',
  BUTTON_EDIT_USER: 'Edit Profile',
  BUTTON_GO_TO_PROFILE: 'View Profile',
  BUTTON_CREATOR_GET_STARTED: "Let's Get Started!",
  BUTTON_INSTAGRAM_CONNECT_REQUEST: 'Connect your Instagram account',
  BUTTON_INSTAGRAM_CONNECTED: 'Instagram account connected',
  BUTTON_INSTAGRAM_DISCONNECT: 'Disconnect Instagram',
  BUTTON_TIKTOK_DISCONNECT: 'Disconnect TikTok',
  BUTTON_LOG_IN: 'Log In',
  BUTTON_PROFILE: 'Profile',
  BUTTON_SAVE_CHANGES: 'Save Changes',
  BUTTON_SAVE_SUMMARY: 'Save',
  BUTTON_SIGN_IN_USER: 'Sign In',
  BUTTON_SIGN_OUT: 'Sign Out',
  BUTTON_EDIT_INTERNAL_NOTES: 'Edit Creator Tags',
  // BUTTON_SIGN_UP: 'Sign Up, this isn't used anywhere. The landing template uses generic copy instead
  BUTTON_USER_SETTINGS: 'User Settings',
  BUTTON_DELETE_USER: 'Delete User',
  BUTTON_FAQ: 'FAQ',
  BUTTON_CONFIRM_SIGN_OUT: 'Yes, sign out',
  BUTTON_RESET_USER_TYPE: 'Change User Type',

  // Placeholders
  PLACEHOLDER_NOTIFICATIONS: 'N/A',
  PLACEHOLDER_OTHER_COLLECTIONS: "This user hasn't created any public collections yet",
  PLACEHOLDER_OWN_COLLECTIONS: "You don't have any collections to display",
  PLACEHOLDER_EMPTY_SEARCH: 'No collections matching that search',
  PLACEHOLDER_PROFILE: 'Add your {field}',
  PLACEHOLDER_SEARCH_ACCOUNTS: 'Search Accounts',
  PLACEHOLDER_SEARCH_PORTFOLIO: 'Search Collections',
  PLACEHOLDER_PHONE: 'No phone number provided',
  PLACEHOLDER_USER_TAGS_INPUT: 'Enter or select the category name',

  // Tooltips

  // Labels
  LABEL_EMAIL: 'Email',
  LABEL_FIRST_NAME: 'First Name',
  LABEL_LAST_NAME: 'Last Name',
  LABEL_NOTIFICATIONS: 'Notifications',
  LABEL_PHONE_USER: 'Phone Number',
  LABEL_SECONDARY_EMAIL: 'Secondary Email',
  LABEL_MUTUAL_ORGANIZATIONS: 'Mutual Organizations',
  LABEL_INVITED_ORGANIZATIONS: 'Invited Organizations',
  LABEL_ACTIVE_BRIEFS: 'Active Briefs',
  // Headings
  HEADING_ACCOUNTS: 'Accounts (#{numAccounts})',
  HEADING_ADDRESS: 'Address Info',
  HEADING_BRAND: "I'm a Marketer",
  HEADING_CREATOR: "I'm a Creator",
  HEADING_NOTIFICATIONS: 'Text Notifications',
  HEADING_ONBOARD_COMPLETE_CREATORS: 'Thanks For Applying To Brkfst!',
  HEADING_ONBOARD_COMPLETE_ORG: 'Congratulations!',
  HEADING_ORGANIZATIONS: 'Organizations',
  HEADING_PORTFOLIO: 'Libraries',
  HEADING_PROFILE_PICTURE: 'Profile Picture',
  HEADING_SETTINGS: 'Account Settings',
  HEADING_SUMMARY: 'About Me',
  HEADING_USER_INFO: 'User Information',
  HEADING_WELCOME: 'Welcome to {appName}',
  HEADING_CREATOR_SHOWCASE: 'Brkfst Showcase',
  HEADING_CREATOR_ACCOUNT_SETTINGS: "Let's make sure we've got your details right.",

  // Subheadings
  SUBHEADING_WELCOME: 'Let’s get you set up. Which one best describes you?',
  SUBHEADING_BRAND: 'I manage ad campaigns, report on performance and analyze marketing data for my brand.',
  SUBHEADING_CREATOR: 'I make video content for ads and work on creative briefs.',
  SUBHEADING_MAILING_ADDRESS: 'Mailing Address',
  SUBHEADING_INSTAGRAM: 'Instagram',
  SUBHEADING_PAYMENT: 'Payment',
  SUBHEADING_EMAIL_CHANGE: `<p>If you want to change your email please contact <a href='https://www.brkfst.io/contact-us/' target='_blank' style="color:#2971F9">brkfst.io</a>.

  You will not be able to change your email if you registered using Google/Facebook.</p>`,
  SUBHEADING_TEXT_NOTIFICATIONS: 'Do you want to receive text notifications?',
  // SUBHEADING_EMAIL_CHANGE: `<a style="color:#007bff" target="_blank" href= "https://www.brkfst.io/">brkfst.io</a>'s propietary Ad Health Score helps brands evaluate and improve their Meta Ad Account performance`,
  // Body
  BODY_ONBOARD_COMPLETE_CREATORS:
    "<p>Your first task is to complete our Getting Started brief.</p> <p>This brief gives you a chance to tell us about yourself, show some of your skills, and gives you an opportunity to get used to how we structure our content.</p> <p>Please watch this <a style='color:#007bff; text-decoration:underline' href='https://www.loom.com/share/bfbc928d1b424611ab9ff6e881c6f9a2'>quick video</a> which will explain next steps and inlcudes a link to Brkfst U! We will review your work and get back to you soon! Then you'll be able to work with our brands!</p>",
  BODY_ONBOARD_COMPLETE_ORG:
    'You’re one step away from generating creative content for your ads. Start your creative brief today!',
  BODY_ONBOARD_COMPLETE_ORG_ALT: 'You’re one step away from generating creative content for your ads.',
  BODY_ONBOARD_SIGN_OUT: 'Onboarding progress will not be saved upon signing out',

  // Dividers
  DIVIDER_ORGANIZATION: '#{name} (#{numAccounts})',

  // Links
  LINK_ALL_ORGANIZATIONS: 'See All',
  LINK_EDIT_DETAILS: 'Edit Details',
  LINK_FAQ: 'https://www.brkfst.io/faq/',

  // Navbar
  NAVBAR_HEADER_CREATORS: 'for Creators',

  // Tooltips
  TOOLTIP_MAILING_ADDRESS: 'This is visible to all brands that have approved you',

  // Descriptions
  DESCRIPTION_PAYMENT: `<p style="margin:0">The pricing structure is based on a pay-for-performance model. When your creative is used in an ad on Facebook and its properties, including Instagram, you are paid a percentage of the ad spend that the brand pays Facebook for showing that ad to an audience.</p><p style="margin-top:-10px">
	Facebook's optimization algorithm automatically puts more spend behind ads that are performing well for the brand. Thus, the creatives that perform best have the most spend and consequently a much larger Creator compensation.</p>`,

  DESCRIPTION_STRIPE_CONNECT: `Please connect your Stripe account to receive payments. <a style="color:#007bff" target="_blank" href= "https://stripe.com/">Stripe</a> is a <a style="color:#007bff" target="_blank" href= "https://stripe.com/docs/security/stripe">secure</a> third party payment platform. You can also create a Stripe account if you don't currently have one.`,

  DESCRIPTION_INSTAGRAM_CONNECT: `Connect your Instagram account to enhance your profile and help brands learn more about your work.
	
	If you don't have an account yet, <a style="color:#007bff" target="_blank" href= "https://www.instagram.com/accounts/emailsignup/">sign up</a> first. Then connect your IG account.`,

  DESCRIPTION_IG_CONNECT_BENEFITS: `When you connect your IG account, <a href='https://www.brkfst.io/' target='_blank' style="color:#2971F9">brkfst.io</a> will display your latest 5 posts on your creator profile page.

They are refreshed every 24 hours so any immediate changes to your latest post will take some time to update.`,

  DESCRIPTION_ACCOUNT_SETTINGS: `Please take a moment to review and confirm your account information. This ensures that all communications and matching opportunities are able to reach you.`,
  // Widgets
  WIDGET_SUBMISSION: 'In Progress Briefs',
  WIDGET_EARNINGS: 'Your Earnings',
  WIDGET_OPEN_BRIEFS: 'Open Briefs',

  // Modal
  MODAL_HEADING_PROFILE: 'Edit Profile',
  MODAL_HEADING_NOTES: 'Creator Notes',
  MODAL_HEADING_ONBOARDING_SIGN_OUT: 'Sign Out?',
  MODAL_HEADING_DELETE_USER: 'Confirm That You Want To Delete This User',

  // Form
  FORM_LABEL_SUMMARY: 'Summary',
  FORM_LABEL_NOTE: 'Note',
  FORM_LABEL_TIKTOK: 'TikTok Handle',
  FORM_LABEL_PORTFOLIO: 'Portfolio Link',
  FORM_LABEL_TAGS: 'Tags',
  ALT_PROFILE_PICTURE: 'Profile Picture',

  // Prompts
  PROMPT_CONFIRM_CHANGE_USER_TYPE:
    'You will lose all onboarding progress upon navigation to the user type selection page. Are you sure you want to reset your onboarding progress and discard all inputs?',
  PROMPT_TITLE_CONFIRM_CHANGE_USER_TYPE: 'Confirm User Type Change',
};

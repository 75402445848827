import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.redirect-on-error': {
    textAlign: 'center',
  },
  '.redirect-on-error': {
    '&__text': {
      textAlign: 'center',
      color: COLORS.NEUTRAL300,
      margin: `${theme.space[5]} 0`,
    },
  },
});

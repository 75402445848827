import { DateRange } from 'react-day-picker';
import {
  addDays,
  differenceInCalendarDays,
  eachDayOfInterval,
  endOfMonth,
  format,
  formatDistance,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  startOfMonth,
  startOfWeek,
  startOfYesterday,
  subDays,
  subMonths,
} from 'date-fns';

const ISO_DATE_FORMAT = 'yyyy-MM-dd';
const PRETTY_DATE_FORMAT = 'MMM dd, yyyy';
const DATE_WITH_SLASHES = 'MM/dd/yyyy';

export const formatDateFromISO = (isoDate: string): Date => {
  return new Date(parseISO(isoDate));
};

export const formatISODate = (dt: number | Date) => {
  return format(dt, ISO_DATE_FORMAT);
};

export const prettyDate = (dt: string | Date) => {
  const date = typeof dt === 'string' ? parseISO(dt) : dt;
  return format(date, PRETTY_DATE_FORMAT);
};

export const formatDateWithSlashes = (dt: string) => {
  return format(parseISO(dt), DATE_WITH_SLASHES);
};

export const prettyStringDate = (dt: string) => {
  let date;
  if (dt.includes('/')) date = new Date(dt);
  else date = parseISO(dt);
  return format(date, PRETTY_DATE_FORMAT);
};

export const LAST_7_DAYS_PRESET = 'Last 7 Days';

export const parseDatePresets = (preset: string) => {
  let from;
  let to;
  switch (preset) {
    case 'Today':
      from = new Date();
      to = new Date();
      break;
    case 'Yesterday':
      from = startOfYesterday();
      to = startOfYesterday();
      break;
    case LAST_7_DAYS_PRESET:
      from = subDays(startOfYesterday(), 6);
      to = startOfYesterday();
      break;
    case 'Last 14 Days':
      from = subDays(startOfYesterday(), 13);
      to = startOfYesterday();
      break;
    case 'Last 30 Days':
      from = subDays(startOfYesterday(), 29);
      to = startOfYesterday();
      break;
    case 'This Week':
      from = startOfWeek(new Date());
      to = new Date();
      break;
    case 'Last Week':
      from = subDays(startOfWeek(new Date()), 7);
      to = addDays(from, 6);
      break;
    case 'This Month':
      from = startOfMonth(new Date());
      to = new Date();
      break;
    case 'Last Month':
      from = startOfMonth(subMonths(new Date(), 1));
      to = endOfMonth(subMonths(new Date(), 1));
      break;
    default:
  }
  return { from, to };
};

export const getComparisonDateRange = ({ from, to }: DateRange): DateRange => {
  if (to && from) {
    const differenceInDays = differenceInCalendarDays(to, from) + 1;
    return {
      from: subDays(from, differenceInDays),
      to: subDays(to, differenceInDays),
    };
  }
  return {
    from: undefined,
    to: undefined,
  };
};

export const formatComparisonDateRanges = (dateRange: DateRange): { startDate: string; endDate: string }[] => {
  const { from: comparisonStartDate, to: comparisonEndDate } = getComparisonDateRange(dateRange);
  if (comparisonEndDate && comparisonStartDate && dateRange.from && dateRange.to) {
    return [
      {
        startDate: formatISODate(comparisonStartDate),
        endDate: formatISODate(comparisonEndDate),
      },
      {
        startDate: formatISODate(dateRange.from),
        endDate: formatISODate(dateRange.to),
      },
    ];
  }
  return [];
};

export const getIncrements = (datePreset, increment = 1) => {
  // increment is num days

  const { from, to } = parseDatePresets(datePreset);

  const incremented = eachDayOfInterval({ start: from, end: to }, { step: increment }).map((date) =>
    formatISODate(date),
  );

  return incremented;
};

/* to avoid system-timezone conversions */
export const getDate = (date) => {
  return date.split('T')[0];
};

// The assumption made when using this method:
//   - Back-end delivers an ISO formatted date-time. e.g: "2020-10-08T00:00:000Z"
export const splitISODate = (date) => date.split('T')[0];

export const isDayInRange = (date, range: { from: Date; to: Date }) => {
  return (
    (isAfter(date, range.from) && isBefore(date, range.to)) || isSameDay(date, range.from) || isSameDay(date, range.to)
  );
};

export const formatTimeAgo = (date: string): string => {
  return formatDistance(parseISO(date), new Date());
};

export const getAgeFromDate = (date: string) => {
  const today = new Date();
  const birthDate = new Date(date);

  let age = today.getFullYear() - birthDate.getFullYear();

  const monthDifference = today.getMonth() - birthDate.getMonth();
  const dayDifference = today.getDate() - birthDate.getDate();

  if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
    age--;
  }

  return age;
};

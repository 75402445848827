import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.navigation-prompt': {
    flexDirection: 'column',
    width: '100%',
  },
  '.navigation-prompt': {
    '&__text': {
      padding: `0 ${theme.space[5]}`,
      minHeight: '5rem',
      overflowY: 'auto',
    },
    '&__inner-text': {
      whiteSpace: 'pre-line',
    },
    '&__button-wrapper': {
      borderTop: `1px solid ${COLORS.NEUTRAL200}`,
      flexWrap: 'nowrap',
      padding: `${theme.space[3]} ${theme.space[5]}`,
      width: '100%',
      justifyContent: 'flex-end',
    },
    '&__button': {
      whiteSpace: 'nowrap',
      marginLeft: theme.space[5],
    },
  },
});

import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '.checkout-summary': {
    padding: '16px',
    margin: '0 auto',
    position: 'relative',
    border: `1px solid ${COLORS.NEUTRAL100}`,
    borderRadius: '2px',
    backgroundColor: 'white',
    [mediaquery.md]: {
      width: '300px',
    },
    '&__loading': {
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      width: '100%',
      height: '100%',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: zIndex.zOverlay,
    },
    '&__charges': {
      listStyleType: 'none',
      padding: 0,
      marginBottom: '8px',
    },
    '&__charge': {
      marginTop: '8px',
      color: COLORS.NEUTRAL700,
      display: 'flex',
      textTransform: 'capitalize',
    },
    '&__charge-description': {
      flex: 1,
    },
    '&__discount': {
      marginTop: '8px',
      color: COLORS.GREEN500,
      display: 'flex',
      alignItems: 'baseline',
    },
    '&__discount-description': {
      flex: 'unset',
    },
    '&__remove-discount-btn': {
      padding: '0 4px',
      fontSize: '14px',
      color: COLORS.NEUTRAL600,
      marginRight: '4px',
    },
    '&__total': {
      justifyContent: 'space-between',
      marginBottom: '16px',
    },
    '&__terms': {
      color: COLORS.NEUTRAL700,
      marginBottom: '16px',
    },
    '&__terms-link': {
      color: COLORS.PRIMARY500,
    },
    '&__submit-btn': {
      width: '100%',
      whiteSpace: 'nowrap',
    },
    '&__powered-by-stripe': {
      marginTop: '16px',
      marginBottom: '16px',
      textAlign: 'center',
    },
    '&__break': {
      marginTop: '16px',
    },
  },
});

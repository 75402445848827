/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import { initialRefresh, loading } from 'features/ui/ui.slice';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { LinkCreator } from 'shared/utilities/linkUtility';

const ProtectedRouteWrapper = () => {
  const { authenticated, userType } = useCurrentUser();
  const isLoading = useSelector(loading);
  const location = useLocation();
  const firstRefresh = useSelector(initialRefresh);

  const params = new URLSearchParams(window.location.search);
  const isLogout = Boolean(params.get('logout'));
  const hasRedirect = location.pathname === '/' || isLogout;
  const hasSearch = location.search === '' || isLogout;

  const link = LinkCreator.createLink({
    userType,
    routeKey: 'SIGN_IN',
    query: {
      redirectURI: hasRedirect ? undefined : location.pathname,
      search: hasSearch ? undefined : location.search,
    },
  });

  if (authenticated) {
    return <Outlet />;
  }

  return isLoading || firstRefresh ? <CenteredSpinner /> : <Navigate to={link} />;
};

export default ProtectedRouteWrapper;

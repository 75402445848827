import React from 'react';
import { Outlet } from 'react-router';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';

interface Props {
  loading?: boolean;
}
const AccountHealthWrapper: React.FC<Props> = ({ loading }) => {
  return <>{loading ? <CenteredSpinnerContainer /> : <Outlet />}</>;
};

export default AccountHealthWrapper;

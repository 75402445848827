import React from 'react';

import BriefCard from 'features/briefs/BriefCard';
import { Flex } from 'shared/components/display';

import { Brief } from 'shared/typings/briefs';

// * Styles
import styles from './styles';

interface Props {
  data: Array<Brief>;
  onPress: (brief: Brief) => void;
  hideStatus?: boolean;
}

const CreatorBriefsTable: React.FC<Props> = ({ data, onPress, hideStatus = false }) => {
  return (
    <Flex className="creator-briefs-table" css={styles}>
      {data.map((brief) => {
        return (
          <BriefCard
            key={`${brief.id}`}
            brandName={brief.account?.name}
            account={brief.account}
            creativeOnly={brief.creativeOnly}
            title={brief.name}
            briefCreatorStatus={brief.briefCreatorStatus}
            full={brief.full}
            onPress={() => onPress(brief)}
            inviteOnly={brief.inviteOnly}
            hideStatus={hideStatus}
          />
        );
      })}
    </Flex>
  );
};

export default CreatorBriefsTable;

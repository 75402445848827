import { useMemo, useRef } from 'react';
import { isEqual, pick } from 'lodash';

export const useSearchParams = (query, defaultParams) => {
  const params = useRef({});
  const searchParams = useMemo(() => {
    const searchKeys = Object.keys(defaultParams);
    const paramsFromQuery = pick(query, searchKeys);
    const newParams = {
      ...defaultParams,
      ...paramsFromQuery,
    };
    if (isEqual(params.current, newParams)) {
      return params.current;
    }
    params.current = newParams;

    return newParams;
  }, [defaultParams, query]);

  return searchParams;
};

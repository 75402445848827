import React, { PropsWithChildren, useEffect, useState } from 'react';
import ReactDropzone from 'react-dropzone';
import cs from 'classnames';

import { Box } from 'shared/components/display';

import styles, { classNames } from './style';

type Props = {
  /** Callback with files being dropped */
  onFileDrop: (files: File[]) => void;
  /** Callback with state of hover */
  onEnter?: (entered: boolean) => void;
  /** Defaults to true. If false, hides the drop indicator */
  showIndicator?: boolean;
  className?: string;
  disabled?: boolean;
};

/** Intended to be used as a wrapper to any element that you want to make a dropzone */
const DropBox = React.forwardRef<HTMLInputElement, PropsWithChildren<Props>>(
  ({ children, onFileDrop, onEnter, className, showIndicator = true, disabled = false }, ref) => {
    const [dragEnter, setDragEnter] = useState(false);

    useEffect(() => {
      if (onEnter) onEnter(dragEnter);
    }, [dragEnter]);

    const handleFileChange = (files: File[]) => {
      onFileDrop(files);
      setDragEnter(false);
    };

    return (
      <ReactDropzone
        disabled={disabled}
        onDrop={handleFileChange}
        onDragOver={() => setDragEnter(true)}
        onDragLeave={() => setDragEnter(false)}
        noClick={true}
      >
        {({ getRootProps, getInputProps }) => (
          // @ts-ignore getRootProps passes some elements that divs use but not box
          <Box {...getRootProps()} css={styles} className={cs(classNames.block, className)}>
            <input {...getInputProps()} ref={ref} />
            {children}
            {showIndicator && dragEnter && <div className={classNames.indicatorElement} />}
          </Box>
        )}
      </ReactDropzone>
    );
  },
);

export default DropBox;
export const DROPBOX_CLASS = classNames.block;

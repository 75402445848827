import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '.name-text-pairs': {
    flexDirection: 'column',
    alignItems: 'unset',
    [mediaquery.md]: {
      flexDirection: 'row',
      alignItems: 'baseline',
    },
    '&__item': {
      width: '100%',
    },
    '&__header':{
      marginBottom: '0px',
      marginTop: '.5em',
    }
  },
  '.name-text-pairs > div:not(:last-child)': {
    [mediaquery.md]: {
      marginRight: '16px',
    },
    width: '100%',
  },
});

import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import COLORS from 'shared/styles/colors';
import { Collection } from 'shared/typings/collections';
import React from 'react';

type Props = Pick<Collection, 'isBriefCollection' | 'publicAccess'> & { creatorCollection?: boolean };

const CollectionTileOverlay: React.FC<Props> = ({ isBriefCollection, publicAccess, creatorCollection = false }) => {
  if (!publicAccess && creatorCollection) {
    return <FontAwesomeIcon color={COLORS.WHITE} icon={faLock} />;
  }
  if (isBriefCollection) {
    return <>Brief</>;
  }
  return <></>;
};

export default CollectionTileOverlay;

import React from 'react';

import FacebookIcon from 'shared/components/atoms/FacebookIcon';
import NavItem from 'shared/components/atoms/NavItem';
import SidebarNavBar from 'shared/components/atoms/SidebarNavBar';
import Tooltip from 'shared/components/atoms/Tooltip';
import { Box } from 'shared/components/display';

import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { usePermissions } from 'shared/hooks/usePermissions';
import { AccountPlatform } from 'shared/typings/accountPlatform';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator, RouteKey } from 'shared/utilities/linkUtility';

import facebookNavStyles from './facebookNavStyles';

const tooltipDelay: [number, number] = [500, 0];

interface Props {
  organizationId: number;
  accountId: number;
  facebookPlatform: AccountPlatform;
  onIconClick?: () => void;
  isActive?: boolean;
  expanded?: boolean;
}
const FacebookNav: React.FC<Props> = ({
  accountId,
  organizationId,
  facebookPlatform,
  onIconClick,
  isActive = false,
  expanded,
}) => {
  const { isAuthorized } = usePermissions();
  const { hasRequiredFacebookAssets } = facebookPlatform;
  const isFacebook = facebookPlatform?.platform.shortenedName === 'facebook';
  const hideDashboard = isFacebook && !hasRequiredFacebookAssets;

  const makeLink = (routeKey: RouteKey) =>
    LinkCreator.createLink({
      userType: UserType.ORG,
      routeKey,
      variables: {
        organizationId,
        accountId,
        accountPlatformId: facebookPlatform?.id,
      },
    });

  return (
    <Box className="facebook-nav" css={facebookNavStyles}>
      <SidebarNavBar>
        <Tooltip
          content={facebookPlatform.name}
          delay={tooltipDelay}
          touch={['hold', 100]}
          className="facebook-nav__tooltip"
        >
          <NavItem
            text={facebookPlatform.name}
            Icon={<FacebookIcon />}
            onClick={onIconClick}
            className="facebook-nav__item"
            tabIndex={expanded ? 0 : -1}
          />
        </Tooltip>
        <NavItem
          text="Dashboard"
          path={makeLink('PLATFORM')}
          exact
          hide={hideDashboard || !isActive}
          className="facebook-nav__sub-item"
          tabIndex={expanded ? 0 : -1}
        />
        <NavItem
          text="Requirements"
          path={makeLink('PLATFORM_REQUIREMENTS')}
          hide={
            !isAuthorized(organizationId, [PERMISSIONS_MAP.EDIT_ACCOUNT.API, PERMISSIONS_MAP.DELETE_ACCOUNT.API]) ||
            !isActive
          }
          className="facebook-nav__sub-item"
          tabIndex={expanded ? 0 : -1}
        />
      </SidebarNavBar>
    </Box>
  );
};

export default FacebookNav;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.file-lightbox-footer': {
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.file-lightbox-footer': {
    '&__caption': {
      color: COLORS.WHITE,
      marginBottom: 0,
      marginRight: theme.space[2],
    },
  },
});

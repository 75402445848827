import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import text from 'shared/styles/text';
import theme from 'shared/styles/themes';

export default css({
  '&.category-tags': {
    borderRadius: '5px',
  },
  '.category-tags': {
    '&__category': {
      color: COLORS.NEUTRAL700,
      fontWeight: 500,
      width: '100%',
    },
    '&__category--uppercase': {
      textTransform: 'uppercase',
    },
    '&__tag': {
      margin: theme.space[2],
    },
  },
  '&.category-tags--top-category': {
    flexDirection: 'column',
    width: 'fit-content',
    '& .category-tags__category': {
      backgroundColor: COLORS.NEUTRAL50,
      textTransform: 'uppercase',
      color: COLORS.NEUTRAL500,
      borderRadius: '5px',
      padding: theme.space[2],
      width: '100%',
      marginBottom: theme.space[2],
      marginLeft: theme.space[2],
    },
    '& .category-tags__category--uppercase': {
      textTransform: 'uppercase',
    },
    '& .category-tags__values': {
      width: 'fit-content',
    },
  },
  '&.category-tags--top-category-plain': {
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    '& .category-tags__category': {
      zIndex: zIndex.zBackdrop,
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      background: COLORS.WHITE,
      padding: '0 0.5rem',
      fontSize: '12px',
      color: COLORS.NEUTRAL700,
      pointerEvents: 'none',
      position: 'absolute',
      top: '2px',
      left: '5px',
      margin: 0,
      width: 'auto',
    },
    '& .category-tags__category--uppercase': {
      textTransform: 'uppercase',
    },
    '& .category-tags__category--active': {
      color: COLORS.PRIMARY400,
    },
    '& .category-tags__values': {
      width: '100%',
      border: `0.7px solid ${COLORS.NEUTRAL300}`,
      margin: '.75rem 0 .25rem',
      borderRadius: '2px',
    },
  },

  '&.category-tags--left-category': {
    backgroundColor: COLORS.NEUTRAL50,
    '& .category-tags__category': {
      width: '100px',
      wrap: 'wrap',
      whiteSpace: 'break-spaces',
      padding: theme.space[3], //0.25rem
      margin: 0,
      textTransform: 'uppercase',
    },
    '& .category-tags__category--uppercase': {
      textTransform: 'uppercase',
    },
    '& .category-tags__values': {
      width: `calc(100% - (100px + 0.5rem)) `,
    },
  },

  '&.category-tags--definition-list-category': {
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    '& .category-tags__category': {
      ...text.subheading,
      textTransform: 'capitalize',
      color: COLORS.NEUTRAL400,
      marginBottom: '5px',
    },
    '& .category-tags__category--uppercase': {
      textTransform: 'uppercase',
      fontSize: '0.875rem',
    },
    '& .category-tags__values': {
      width: '100%',
      '& tag': {
        margin: '0 3px 3px 0',
      },
    },
  },
});

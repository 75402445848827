import React, { useRef, useState } from 'react';
import { Button } from 'rebass/styled-components';

import HiddenFileInput from 'shared/components/atoms/HiddenFileInput';
import { Flex, Text } from 'shared/components/display';

import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

interface MediaUploadButtonProps {
  onFileUpload: (files: FileList) => any;
  onClick?: React.ReactEventHandler<Element>;
  buttonContent: any;
  buttonVariant?: string;
  multipleFiles?: boolean;
  acceptedMedia?: readonly string[];
  maxNumFiles?: number;
  hiddenFileInputRef?: React.MutableRefObject<HTMLInputElement>;
  disabled?: boolean;
}

const MediaUploadButton: React.FC<MediaUploadButtonProps> = ({
  onFileUpload,
  onClick,
  buttonContent,
  buttonVariant = BUTTON_VARIANTS.MICRO,
  multipleFiles = false,
  acceptedMedia = [],
  hiddenFileInputRef = null,
  maxNumFiles = Infinity,
  disabled = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>('');

  const onChange = (files) => {
    onFileUpload(files);
    setError('');
  };

  const handleClick = (e) => {
    e.persist();
    if (hiddenFileInputRef) hiddenFileInputRef.current.click();
    else if (inputRef.current !== null) inputRef.current.click();
    if (onClick) onClick(e);
  };

  return (
    <Flex css={styles} className="file-upload">
      <Button
        type="button"
        className="file-upload__btn"
        onClick={handleClick}
        variant={buttonVariant}
        disabled={disabled}
      >
        {buttonContent}
      </Button>
      {/* for cases where the button will be unmounted while the input is still in use i.e. in dropdown menus */}
      {!hiddenFileInputRef && (
        <HiddenFileInput
          name="hidden_input"
          onChange={onChange}
          onError={setError}
          acceptedMedia={acceptedMedia}
          multipleFiles={multipleFiles}
          maxNumFiles={maxNumFiles}
          ref={inputRef}
          validateFiles
        />
      )}
      <Text className="file-upload__error" variant={TEXT_VARIANTS.ERROR}>
        {error}
      </Text>
    </Flex>
  );
};

export default MediaUploadButton;

import { css } from '@emotion/react';

export const carouselStyles = css({
  '&.carousel': {
    maxWidth: '16rem',
    maxHeight: '16rem',
    margin: '0 auto',
    '& li': {
      maxHeight: '16rem',
    },
  },
});

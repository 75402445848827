import { css } from '@emotion/react';

export default css({
  '.brief-assets': {
    '&__title-wrapper': {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '&__title': {
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: 1.5,
    },
    '&__new-files': {
      listStyleType: 'none',
      padding: 0,
      margin: '4px 0',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gridColumnGap: '16px',
    },
  },
});

import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import { media } from 'shared/styles/styleFunctions';
import SHADOWS from 'shared/styles/shadow';
import COLORS from 'shared/styles/colors';

const { NEUTRAL800 } = COLORS;
const { SHADOW12 } = SHADOWS;

const ToastWrapper = () => {
  return <StyledToast transition={Slide} newestOnTop position="bottom-left" autoClose={5000} theme="colored" />;
};

const StyledToast = styled(ToastContainer)`
  &.Toastify__toast-container {
    ${media.md`
            width: 340px;
        `}
  }
  & .Toastify__toast {
    font-family: inherit;
    text-align: left;
    box-shadow: ${SHADOW12};
    border-radius: 6px;
    min-height: 50px;
    width: 95%;

    ${media.md`
            width: 100%;
        `}
    ${media.lg`
            margin: 0 auto 6px;
        `}

    &.Toastify__toast--warning {
      color: ${NEUTRAL800};
      & .Toastify__close-button {
        color: ${NEUTRAL800};
      }
    }
    &.Toastify__toast--info {
      background: ${NEUTRAL800};
    }
  }
`;

export default ToastWrapper;

import React from 'react';
import { Revision } from 'shared/typings/revisions';
import { Box } from 'shared/components/display';
import SingleRevision from 'features/submissions/components/atoms/SingleRevision';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';

interface Props {
  revisions?: Revision[];
  brandName: string;
}
const RevisionsList: React.FC<Props> = ({ revisions, brandName }) => {
  const { isCreator } = useCurrentUser();
  return (
    <Box>
      {revisions?.map((revision) => (
        <SingleRevision key={revision.id} revision={revision} brandName={brandName} isCreator={isCreator} />
      ))}
    </Box>
  );
};

export default RevisionsList;

import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Text } from 'rebass/styled-components';

import NavBar from 'shared/components/atoms/NavBar';
import NavBarLogo from 'shared/components/atoms/NavBarLogo';
import { Flex } from 'shared/components/display';

import { GENERIC_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';

interface Props {
  children: JSX.Element | Array<JSX.Element>;
}

const LandingTemplate: React.FC<Props> = ({ children }) => {
  const location = useLocation<any>();

  const { link: authLink, text: authText } = useMemo((): { link: string; text: string } => {
    const signInPath = LinkCreator.createLink({
      routeKey: 'SIGN_IN',
    });
    const signIn = {
      text: GENERIC_COPY.BUTTON_SIGN_IN,
      link: signInPath,
    };
    const signUp = {
      text: GENERIC_COPY.BUTTON_SIGN_UP,
      link: LinkCreator.createLink({ routeKey: 'SIGN_UP' }),
    };
    return location.pathname !== signInPath ? signIn : signUp;
  }, [location.pathname]);

  return (
    <Flex css={styles} className="landing-template">
      <NavBar className="landing-template__header">
        <NavBarLogo disableNavigate />
        <Link to={authLink}>
          <Text className="landing-template__action-text" variant={TEXT_VARIANTS.BODY_SM}>
            {authText}
          </Text>
        </Link>
      </NavBar>
      {children}
    </Flex>
  );
};

export default LandingTemplate;

// * Lib
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Text } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

// * Util
import { useInvoice } from 'features/organizations/useInvoice';
//* Display/UI
import { Box } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import TextInput from 'shared/components/TextInput';

import { GENERIC_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
// * Styles
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

const FIELDNAMES = Object.freeze({
  PROMOTION_CODE: 'code',
  SUBMIT: 'submit',
});

const schema = yup.object({
  [FIELDNAMES.PROMOTION_CODE]: yup.string().customRequiredString(),
});

const initialValues = Object.freeze({
  [FIELDNAMES.PROMOTION_CODE]: '',
});

const CheckoutPromoCodeForm = () => {
  const params = useParams<{ organizationId: string; accountId: string }>();
  const organizationId = +params.organizationId;
  const accountId = +params.accountId;
  const { promoCodeError, clearPromoCodeError, applyPromotionCode } = useInvoice({
    accountId,
    organizationId,
  });
  const [showPromoCodeForm, togglePromoCodeForm] = useState(false);

  const onSubmit = (data, formikHelpers) => {
    const { code } = data;
    clearPromoCodeError();

    applyPromotionCode({
      code,
      onSuccess: () => {
        formikHelpers.setSubmitting(false);
      },
      onError: () => {
        formikHelpers.setSubmitting(false);
      },
    });
  };

  const onChange = () => {
    if (promoCodeError) clearPromoCodeError();
  };

  return (
    <Box css={styles} className="checkout-promo-code">
      {showPromoCodeForm ? (
        <>
          <Formik onSubmit={onSubmit} validationSchema={schema} initialValues={initialValues}>
            <Form>
              <Field
                name={FIELDNAMES.PROMOTION_CODE}
                component={TextInput}
                placeholder={GENERIC_COPY.PLACEHOLDER_PROMOTION_CODE}
                onChange={onChange}
              />
              <FormButton variant={BUTTON_VARIANTS.TEXT_ONLY} className="checkout-promo-code__submit-btn">
                {GENERIC_COPY.BUTTON_APPLY}
              </FormButton>
            </Form>
          </Formik>
          {promoCodeError && <Text variant={TEXT_VARIANTS.ERROR}>{promoCodeError.response.data.message}</Text>}
        </>
      ) : (
        <Button
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          className="checkout-promo-code__add-btn"
          onClick={() => {
            togglePromoCodeForm(true);
          }}
        >
          {GENERIC_COPY.BUTTON_ADD_PROMOTION_CODE}
        </Button>
      )}
    </Box>
  );
};

export default CheckoutPromoCodeForm;

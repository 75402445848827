import React, { useRef } from 'react';
import {
  faBold,
  faCode,
  faEllipsis,
  faH1,
  faH2,
  faH3,
  faH4,
  faH5,
  faH6,
  faHeading,
  faIndent,
  faItalic,
  faList,
  faListOl,
  faUnderline,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Instance } from 'tippy.js';

import { Box } from 'shared/components/display';
import { RichTextLinkIcon } from 'shared/components/Richtext/RichTextLink';
import RichtextPictureIcon from 'shared/components/Richtext/RichtextPicture/RichtextPictureIcon';
import ToolbarDivider from 'shared/components/Richtext/RichtextToolBar/ToolbarDivider';
import ToolbarPopOutMenu from 'shared/components/Richtext/RichtextToolBar/ToolbarPopOutMenu';
import { RichTextFormats } from 'shared/components/Richtext/shared/types';

import { toggleLink } from '../plugins';
import { BlockButton, MarkButton } from '../RichPieces';

import styles from './styles';

interface Props {
  onBlur: React.FocusEventHandler<HTMLDivElement>;
}

const RichToolBar: React.FC<Props> = ({ onBlur }) => {
  const toolTipRef = useRef<Instance | undefined>();

  return (
    <Box css={styles} className="richtext-tool-bar" onBlur={onBlur} tabIndex={0}>
      <MarkButton format={RichTextFormats.BOLD} tooltip={'Bold (Ctrl+B)'}>
        <FontAwesomeIcon icon={faBold} className="richtext-tool-bar__icon" />
      </MarkButton>
      <MarkButton format={RichTextFormats.ITALIC} tooltip="Italic (Ctrl+I)">
        <FontAwesomeIcon icon={faItalic} className="richtext-tool-bar__icon" />
      </MarkButton>
      <MarkButton format={RichTextFormats.UNDERLINED} tooltip="Underline (Ctrl+U)">
        <FontAwesomeIcon icon={faUnderline} className="richtext-tool-bar__icon" />
      </MarkButton>
      <MarkButton tooltip="Code (Ctrl+/)" format={RichTextFormats.CODE}>
        <FontAwesomeIcon icon={faCode} className="richtext-tool-bar__icon" />
      </MarkButton>
      <ToolbarDivider />
      <MarkButton
        tooltip="Link"
        format={RichTextFormats.LINK}
        onClick={(editorInstance) => toggleLink(editorInstance, toolTipRef.current)}
      >
        <RichTextLinkIcon
          onCreate={(instance) => (toolTipRef.current = instance)}
          onHide={() => toolTipRef.current?.hide()}
        />
      </MarkButton>
      <MarkButton tooltip="Image" format={RichTextFormats.IMAGE}>
        <RichtextPictureIcon />
      </MarkButton>
      <ToolbarDivider />
      <ToolbarPopOutMenu icon={faHeading} iconAlias="Heading" showDropdownIndicator>
        <BlockButton tooltip="Heading 1" format={RichTextFormats.HEADING_ONE}>
          <FontAwesomeIcon icon={faH1} className="richtext-tool-bar__icon" />
        </BlockButton>
        <BlockButton tooltip="Heading 2" format={RichTextFormats.HEADING_TWO}>
          <FontAwesomeIcon icon={faH2} className="richtext-tool-bar__icon" />
        </BlockButton>
        <BlockButton tooltip="Heading 3" format={RichTextFormats.HEADING_THREE}>
          <FontAwesomeIcon icon={faH3} className="richtext-tool-bar__icon" />
        </BlockButton>
        <BlockButton tooltip="Heading 4" format={RichTextFormats.HEADING_FOUR}>
          <FontAwesomeIcon icon={faH4} className="richtext-tool-bar__icon" />
        </BlockButton>
        <BlockButton tooltip="Heading 5" format={RichTextFormats.HEADING_FIVE}>
          <FontAwesomeIcon icon={faH5} className="richtext-tool-bar__icon" />
        </BlockButton>
        <BlockButton tooltip="Heading 6" format={RichTextFormats.HEADING_SIX}>
          <FontAwesomeIcon icon={faH6} className="richtext-tool-bar__icon" />
        </BlockButton>
      </ToolbarPopOutMenu>
      <ToolbarDivider />
      <ToolbarPopOutMenu icon={faEllipsis} iconAlias="More">
        <BlockButton tooltip="Bulleted List" format={RichTextFormats.UNORDERED_LIST}>
          <FontAwesomeIcon icon={faList} className="richtext-tool-bar__icon" />
        </BlockButton>
        <BlockButton tooltip="Numbered List" format={RichTextFormats.ORDERED_LIST}>
          <FontAwesomeIcon icon={faListOl} className="richtext-tool-bar__icon" />
        </BlockButton>
        <ToolbarDivider />
        <BlockButton tooltip="Indent (Ctrl + [)" format={RichTextFormats.INDENT}>
          <FontAwesomeIcon icon={faIndent} className="richtext-tool-bar__icon" />
        </BlockButton>
        <BlockButton tooltip="Unindent (Ctrl + ])" format={RichTextFormats.UNINDENT}>
          <FontAwesomeIcon icon={faIndent} className="richtext-tool-bar__icon fa-rotate-180" />
        </BlockButton>
      </ToolbarPopOutMenu>
    </Box>
  );
};

export default RichToolBar;

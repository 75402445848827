import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  PaginationState,
  Row,
  useReactTable,
} from '@tanstack/react-table';
import cs from 'classnames';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import Skeleton from 'shared/components/atoms/Skeleton';
import Tooltip from 'shared/components/atoms/Tooltip';
import { Box } from 'shared/components/display';

import { GENERIC_COPY } from 'shared/config/copy';
import { noOp } from 'shared/defaults';
import { useQuery } from 'shared/hooks/useQuery';

import PaginationControls from './PaginationControls';
import { CLASSNAMES, MAIN_CLASS } from './styles';
import styles from './styles';
export interface BrkfstTableProps<T> {
  data: T[];
  columns: ColumnDef<T, any>[];
  pagination?: boolean;
  onRowClick?: (el?: any) => void;
  noResultsMessage?: string;
  loading?: boolean;
  loadingNewRow?: boolean;
  dataCy?: string;
}

const BrkfstTable = <T extends {}>({
  data,
  columns,
  pagination = false,
  onRowClick,
  noResultsMessage = GENERIC_COPY.PLACEHOLDER_NO_DATA,
  loading = false,
  loadingNewRow = false,
  dataCy,
}: BrkfstTableProps<T>) => {
  const { getQuery, setQuery } = useQuery(false);
  const query = getQuery();
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: query.pageIndex > 0 ? query.pageIndex : 1,
    pageSize: query.pageSize | 20,
  });

  const paginationData = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  useEffect(() => {
    if (pagination) setQuery({ ...paginationData, pageIndex: paginationData.pageIndex + 1 });
  }, [pageIndex, pageSize]);

  const table = useReactTable({
    data,
    columns,
    ...(pagination
      ? {
          initialState: {
            pagination: {
              pageIndex: query.pageIndex,
              pageSize: getQuery().pageSize,
            },
          },
          state: {
            pagination: paginationData,
          },
          onPaginationChange: setPagination,
          getFilteredRowModel: getFilteredRowModel(),
          getPaginationRowModel: getPaginationRowModel(),
        }
      : {}),
    getCoreRowModel: getCoreRowModel(),
  });
  const onStartClick = () => table.setPageIndex(0);
  const onEndClick = () => table.setPageIndex(table.getPageCount() - 1);
  const onPreviousClick = () => table.previousPage();
  const onNextClick = () => table.nextPage();
  const handleRowClick = (row: Row<T>, rowIndex) => (onRowClick ? onRowClick({ ...row.original, rowIndex }) : noOp);

  return (
    <Box className={MAIN_CLASS} css={styles}>
      <table className={CLASSNAMES.table} data-cy={dataCy}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  // @ts-ignore
                  className={cs(CLASSNAMES.tableHeader, header?.column?.columnDef?.meta?.headerClassName)}
                  key={header.id}
                  style={{
                    // @ts-ignore
                    width: header?.column?.columnDef?.meta?.width,
                    // @ts-ignore
                    paddingLeft: header?.column?.columnDef?.meta?.paddingLeft,
                  }}
                >
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className={CLASSNAMES.tableBody}>
          {loading || data?.length <= 0 ? (
            <tr>
              <td className={CLASSNAMES.noResultsMessage} colSpan={table.getAllColumns().length}>
                {loading ? <CenteredSpinner /> : noResultsMessage}
              </td>
            </tr>
          ) : (
            <>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  // @ts-ignore
                  height="35px"
                  key={row.id}
                  className={cs(CLASSNAMES.tableRow, {
                    [CLASSNAMES.tableRowClickable]: !!onRowClick,
                  })}
                  onClick={() => handleRowClick(row, index)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      // @ts-ignore
                      className={cs(CLASSNAMES.tableCell, cell.column.meta?.cellClassName)}
                      key={cell.id}
                      style={{
                        // @ts-ignore
                        width: cell.column.columnDef.meta?.width,
                        // @ts-ignore
                        paddingLeft: cell.column.columnDef.meta?.paddingLeft,
                      }}
                    >
                      {/* @ts-ignore */}
                      {cell.column.columnDef?.meta?.addTooltip ? (
                        <Tooltip
                          content={
                            <Box className={CLASSNAMES.tooltipContentWrapper}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </Box>
                          }
                          as="div"
                          arrow
                          placement={'left'}
                          touch={['hold', 100]}
                          className={CLASSNAMES.cellContent}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Tooltip>
                      ) : (
                        <Box className={CLASSNAMES.cellContent}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Box>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
              {loadingNewRow && (
                // @ts-ignore
                <tr height="35px" key="loading-row" className={cs(CLASSNAMES.tableRow)}>
                  <td colSpan={table.getAllColumns().length}>
                    <Skeleton />
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
      {pagination ? (
        <PaginationControls
          pageIndex={table.getState().pagination.pageIndex + 1}
          pageCount={table.getPageCount()}
          onStartClick={onStartClick}
          onEndClick={onEndClick}
          onPreviousClick={onPreviousClick}
          onNextClick={onNextClick}
          disableNext={!table.getCanNextPage()}
          disablePrevious={!table.getCanPreviousPage()}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default BrkfstTable;

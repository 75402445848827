import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  backgroundColor: COLORS.WHITE,
  border: `1px solid ${COLORS.NEUTRAL100}`,
  borderRadius: '2px',
  padding: '1rem 1rem 2rem 1rem',
  width: 'auto',
  height: 'auto',
  margin: '1rem 1rem 1rem 0.5rem',
  '.account-pricing': {
    '&__form-container': {
      flexDirection: 'column',
      height: '100%',
    },
    '&__submit-btn': {
      width: '100px',
      marginTop: '.3rem',
    },
    '&__field': {
      marginBottom: '.3rem',
    },
  },
  form: {
    height: '100%',
  },
});

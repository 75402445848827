import { Box } from 'shared/components/display';
import cs from 'classnames';
import React from 'react';
import profilePanelStyles from './profilePanelStyles';

interface Props {
  children: React.ReactNode;
  HeaderComponent: React.ReactNode;
  className?: string;
}
const ProfilePanel: React.FC<Props> = ({ children, HeaderComponent, className }) => {
  return (
    <Box as="section" css={profilePanelStyles} className={cs('profile-panel', className)}>
      <Box className="profile-panel__header">{HeaderComponent}</Box>
      <Box className="profile-panel__content-wrapper">{children}</Box>
    </Box>
  );
};

export default ProfilePanel;

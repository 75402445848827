import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { startCase } from 'lodash';

import {
  SIGN_IN_FIELDNAMES,
  SIGN_IN_FORM_NAME,
  signInSchema,
} from 'features/auth/components/organisms/SignInForm/config';
import signInFormStyles from 'features/auth/components/organisms/SignInForm/signInFormStyles';
import { useAuth } from 'features/auth/useAuth';
import { Box, Flex, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import { PasswordInput } from 'shared/components/inputs/PasswordInput';
import TextInput from 'shared/components/TextInput';
import ToggleInput from 'shared/components/ToggleInput';

import { AUTH_COPY } from 'shared/config/copy';
import e2eFlows from 'shared/config/e2eFlows';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LinkCreator } from 'shared/utilities/linkUtility';
interface Props {
  redirectTo?: string;
}

const SignInForm: React.FC<Props> = ({ redirectTo = '/' }) => {
  const { logIn, loading } = useAuth();

  const onSubmit = (user, formikHelpers: FormikHelpers<any>) => {
    logIn(user, redirectTo, () => {
      formikHelpers.setSubmitting(false);
    });
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{
        [SIGN_IN_FIELDNAMES.EMAIL]: '',
        [SIGN_IN_FIELDNAMES.PASSWORD]: '',
        [SIGN_IN_FIELDNAMES.REMEMBER_ME]: false,
      }}
      validationSchema={signInSchema}
      validateOnBlur={false}
    >
      {() => (
        <Box css={signInFormStyles} className="sign-in">
          <Form data-cy={`${e2eFlows.SIGN_IN}${SIGN_IN_FORM_NAME}`}>
            <Field
              name={SIGN_IN_FIELDNAMES.EMAIL}
              component={TextInput}
              placeholder={`${startCase(SIGN_IN_FIELDNAMES.EMAIL)}*`}
              label={`${startCase(SIGN_IN_FIELDNAMES.EMAIL)}*`}
              dataCy={`${e2eFlows.SIGN_IN}${SIGN_IN_FIELDNAMES.EMAIL}`}
              className="sign-in__email-input"
            />
            <Field
              name={SIGN_IN_FIELDNAMES.PASSWORD}
              component={PasswordInput}
              label={`${startCase(SIGN_IN_FIELDNAMES.PASSWORD)}*`}
              placeholder={`${startCase(SIGN_IN_FIELDNAMES.PASSWORD)}*`}
              dataCy={`${e2eFlows.SIGN_IN}${SIGN_IN_FIELDNAMES.PASSWORD}`}
            />
            <Flex className="sign-in__link-wrapper">
              <Field
                name={SIGN_IN_FIELDNAMES.REMEMBER_ME}
                component={ToggleInput}
                label={AUTH_COPY.LABEL_REMEMBER_ME}
              />
              <Link
                to={LinkCreator.createLink({
                  routeKey: 'FORGOT_PASSWORD',
                })}
                className="sign-in__link"
              >
                {AUTH_COPY.LINK_FORGOT_PASSWORD}
              </Link>
            </Flex>
            <FormButton loading={loading} loaderColor={COLORS.PRIMARY100}>
              {AUTH_COPY.BUTTON_SIGN_IN_AUTH}
            </FormButton>
            <Text variant={TEXT_VARIANTS.BODY_SM} className="sign-in__footnote">
              <FormattedMessage
                id="FOOTNOTE_SIGN_IN"
                values={{
                  bold: (chunks) => <span className="sign-in__footnote--bold">{chunks}</span>,
                  br: <br />,
                }}
              />
            </Text>
          </Form>
        </Box>
      )}
    </Formik>
  );
};

export default SignInForm;

import React, { useMemo } from 'react';
import cs from 'classnames';

import Tags, { BackspaceSetting, Tag, TagTemplate } from 'shared/components/atoms/Tags';
import { Box } from 'shared/components/display';

import { Input } from 'shared/lib/formik';
import tagInputStyles from './tagInputStyles';
import { parseTags } from './config';

type Props = {
  placeholder: string;
  className?: string;
  whitelist?: string[];
  disabled?: boolean;
  tagTemplate?: TagTemplate;
  backspace?: BackspaceSetting;
  enforceWhitelist?: boolean;
  showDropdownOnFocus?: boolean;
  disableAddingTags?: boolean; // users can still delete tags
  dataCy?: string;
  closeOnSelect?: boolean;
  disableEditingTags?: boolean;
};

const TagInput: Input<string[], Props> = ({
  field: { value, name, onChange, onBlur },
  form: { errors, touched },
  placeholder,
  tagTemplate = Tag,
  whitelist,
  className,
  disabled = false,
  backspace = false,
  enforceWhitelist = false,
  showDropdownOnFocus = false,
  disableAddingTags = false,
  disableEditingTags = false,
  closeOnSelect = true,
  dataCy,
}) => {
  const settings = useMemo(() => {
    return {
      templates: {
        tag: tagTemplate,
        keepInvalidTags: true,
      },
      enforceWhitelist,
      editTags: !disableAddingTags,
      dropdown: {
        // this is a numeral value that tells Tagify when to show the suggestions dropdown, when a minimum of N characters were typed.
        enabled: showDropdownOnFocus ? 0 : undefined,
        maxItems: 20,
        closeOnSelect,
      },
    };
  }, [tagTemplate, enforceWhitelist, showDropdownOnFocus, disableAddingTags, closeOnSelect]);

  const handleChange = (e) => {
    onChange({ target: { name, value: parseTags(e.detail?.value) } });
  };

  const tagifyValue = useMemo(() => {
    return disableEditingTags ? value.map((tag) => ({ value: tag, editable: false })) : value;
  }, [value, disableEditingTags]);

  return (
    <Box
      css={tagInputStyles}
      className={cs(className, 'tagify-input', {
        'tagify-input--invalid': touched[name] && errors[name],
        'tagify-input--has-value': value?.length !== 0,
        'tagify-input--editing-disabled': disableAddingTags,
      })}
    >
      <Tags
        value={tagifyValue}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        settings={settings}
        onBlur={() => {
          onBlur({
            target: {
              name,
            },
          });
        }}
        whitelist={whitelist}
        readOnly={disabled}
        backspace={backspace}
        dataCy={dataCy}
      />
    </Box>
  );
};

export default TagInput;

import { NOTIFICATION_BADGE_MAX_COUNT } from 'shared/config/notifications';

export const formatNotificationCount = (notificationCount) => {
    if (notificationCount > NOTIFICATION_BADGE_MAX_COUNT) {
        return `${NOTIFICATION_BADGE_MAX_COUNT}+`;
    }
    if (notificationCount) {
        return notificationCount.toString();
    }
    return null;
};

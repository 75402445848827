import React from 'react';
import cs from 'classnames';
import { RenderElementProps, useFocused, useSelected, useSlateStatic } from 'slate-react';
import { Box } from 'shared/components/display';
import styles from './styles';
import { Button } from 'rebass/styled-components';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImageElement } from 'shared/typings/slate';
import { removeImage } from 'shared/components/Richtext/RichtextPicture/utils';
import Skeleton from 'shared/components/atoms/Skeleton';
import COLORS from 'shared/styles/colors';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { GENERIC_COPY } from 'shared/config/copy';

interface Props {
  element: ImageElement;
  children?: any;
  readOnly?: boolean;
}
const RichtextPicture: React.FC<Omit<RenderElementProps, 'element'> & Props> = ({
  attributes,
  children,
  element,
  readOnly = false,
}) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  const { url, id: key } = element;

  const onDelete: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    if (key) removeImage(editor, key);
  };

  return (
    <Box
      className={cs('rich-text-image', {
        'rich-text-image--selected': selected && focused && url,
      })}
      css={styles}
      as="span"
      {...attributes}
    >
      {children}
      <Box as="span" contentEditable={false} className="rich-text-image__wrapper">
        {url ? <img src={url} className="rich-text-image__img" /> : <Skeleton width="300px" height="300px" />}
        <Button
          type="button"
          onClick={onDelete}
          variant={BUTTON_VARIANTS.MICRO}
          className={cs({
            'rich-text-image__remove-btn': !readOnly,
            'rich-text-image__remove-btn--read-only': readOnly,
          })}
        >
          <FontAwesomeIcon color={COLORS.WHITE} className={'rich-text-image__btn-icon'} icon={faTrashCan} />
          {GENERIC_COPY.BUTTON_PLAIN_DELETE}
        </Button>
      </Box>
    </Box>
  );
};

export default RichtextPicture;

import { Descendant } from 'slate';

export enum RichTextFormats {
  BOLD = 'bold',
  CODE = 'code',
  HEADING_ONE = 'heading-one',
  HEADING_TWO = 'heading-two',
  HEADING_THREE = 'heading-three',
  HEADING_FOUR = 'heading-four',
  HEADING_FIVE = 'heading-five',
  HEADING_SIX = 'heading-six',
  ITALIC = 'italic',
  LINK = 'link',
  LIST_ITEM = 'list-item',
  LIST_ITEM_TEXT = 'list-item-text',
  ORDERED_LIST = 'ordered-list',
  PARAGRAPH = 'paragraph',
  UNDERLINED = 'underlined',
  UNORDERED_LIST = 'unordered-list',
  IMAGE = 'image',
  INDENT = 'indent',
  UNINDENT = 'unindent',
}

export type EmptyText = {
  text: string;
};

export type RichtextValue = Descendant[];

export type RichtextRef = {
  focus: () => void;
  scrollIntoView: (modifiers: { string: string }) => void;
} | null;

export enum UserBriefStatus {
  // faux status used when there is no user brief relation
  NOT_APPLIED = 'NOT_APPLIED',
  // faux status that indicates a group of statuses
  WORKING = 'WORKING_ON_BRIEF',
  // ** In Progress/ "Working" statuses **
  SUBMITTED = 'SUBMITTED',
  DRAFTED = 'DRAFTED',
  ACTIVE = 'ACTIVE',
  REVISION = 'REVISION',
  // ****
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  INVITED = 'INVITED',
}

// For creatorBriefStatus on creators
export const ALL_CREATORS = 'ALL';

// On creator on userBriefs for marketers
export const creatorBriefStatusDisplay = {
  [ALL_CREATORS]: 'All',
  [UserBriefStatus.WORKING]: 'Working On Brief',
  [UserBriefStatus.INVITED]: 'Invited',
  [UserBriefStatus.PENDING]: 'Pending',
  [UserBriefStatus.ACTIVE]: 'Approved',
  [UserBriefStatus.DRAFTED]: 'In Progress',
  [UserBriefStatus.SUBMITTED]: 'Submitted',
  [UserBriefStatus.REJECTED]: 'Rejected',
  [UserBriefStatus.REVISION]: 'Revision',
};

export const FULL_BRIEF = 'FULL_BRIEF';
export const INVITE_ONLY = 'INVITE_ONLY';

// For briefCreatorStatus on briefs for creators
export const briefCreatorStatusDisplay = {
  [UserBriefStatus.ACTIVE]: 'New',
  [UserBriefStatus.DRAFTED]: 'Draft',
  [UserBriefStatus.INVITED]: 'Invited',
  [UserBriefStatus.PENDING]: 'Pending',
  [UserBriefStatus.NOT_APPLIED]: 'Open',
  [UserBriefStatus.REJECTED]: 'Not Selected',
  [UserBriefStatus.SUBMITTED]: 'Submitted',
  [UserBriefStatus.REVISION]: 'Revision',

  [FULL_BRIEF]: 'Full',
  [INVITE_ONLY]: 'Invite Only',
};

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import { fontSizes } from 'shared/styles/text';
import theme from 'shared/styles/themes';

export const MAIN_CLASS = 'swipeable-container';
export const CLASSNAMES = {
  primary: `${MAIN_CLASS}--primary`,
  secondary: `${MAIN_CLASS}--secondary`,
  pagination: `${MAIN_CLASS}__pagination`,
  paginationCenter: `${MAIN_CLASS}__pagination--center`,
  paginationLeft: `${MAIN_CLASS}__pagination--left`,
  paginationRight: `${MAIN_CLASS}__pagination--right`,
  previousBtn: `${MAIN_CLASS}__previousBtn`,
  pageCount: `${MAIN_CLASS}__page-count`,
  nextBtn: `${MAIN_CLASS}__nextBtn`,
  paginationBtn: `${MAIN_CLASS}__pagination-btn`,
  paginationBtnActive: `${MAIN_CLASS}__pagination-btn--active`,
};
// @ts-ignore
export default css({
  position: 'relative',
  padding: '5px',
  height: '100%',
  [`.${MAIN_CLASS}`]: {
    '&__pagination': {
      justifyContent: 'center',
    },
    '&--primary': {
      [`.${CLASSNAMES.paginationBtn}`]: {
        border: 'none',
        background: 'none',
        color: COLORS.NEUTRAL300,
        fontSize: fontSizes[0],
      },
      [`.${CLASSNAMES.pageCount}`]: {
        margin: 0,
        color: COLORS.NEUTRAL500,
        fontSize: fontSizes[0],
      },
    },
    '&--secondary': {
      [`&.${CLASSNAMES.pagination}`]: {
        position: 'absolute',
        bottom: '-3px',
      },
      [`&.${CLASSNAMES.paginationCenter}`]: {
        left: 0,
        right: 0,
      },
      [`&.${CLASSNAMES.paginationLeft}`]: {
        left: '5px',
      },
      [`&.${CLASSNAMES.paginationRight}`]: {
        right: 0,
      },
      [`.${CLASSNAMES.paginationBtn}`]: {
        border: `2px solid ${COLORS.NEUTRAL200}`,
        background: COLORS.NEUTRAL200,
        borderRadius: '100%',
        width: '5px',
        height: '5px',
        margin: '0 1px',
        [mediaquery.md]: {
          width: '8px',
          height: '8px',
          marginRight: '4px',
          cursor: 'pointer',
        },
      },
      [`.${CLASSNAMES.paginationBtnActive}`]: {
        borderColor: COLORS.NEUTRAL500,
        background: COLORS.NEUTRAL500,
      },
      [`.${CLASSNAMES.pageCount}`]: {
        margin: 0,
        color: COLORS.NEUTRAL500,
        fontSize: fontSizes[0],
      },
    },
  },
});

import React, { useState } from 'react';
import config from 'config';
import { Box, Button, Flex, Text } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import { BRKFST_SUPPORTED_IMAGE_FORMATS, BRKFST_SUPPORTED_VIDEO_FORMATS } from 'shared/config/fileFormats';
import { MEDIA } from 'shared/config/media';
import { Input } from 'shared/lib/formik';
import MediaUploadButton from 'shared/components/molecules/MediaUploadButton';
import { useFiles } from 'features/media/useFiles';
import { GeneralValidator, MultiValidator } from 'shared/utilities/validator';
import { TrendValidator } from 'shared/utilities/validator/trendFile';
import { useTrendFileNotifications } from 'features/trends/hooks/useTrendFileNotifications';
import { BrkfstFile } from 'shared/typings/file';
import Skeleton from 'react-loading-skeleton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-light-svg-icons';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { DEFAULT_VALUE } from './config';
import styles from './styles';

interface Props {
  acceptedFormats?: readonly string[];
}

const TrendFileInput: Input<BrkfstFile, Props> = ({
  field: { name, value, onChange },
  acceptedFormats = [...BRKFST_SUPPORTED_IMAGE_FORMATS, ...BRKFST_SUPPORTED_VIDEO_FORMATS, MEDIA.MIME_TYPES.GIF],
}) => {
  const [loading, setLoading] = useState(false);
  const { initiateSingleFileUpload } = useFiles();

  const onFileUploaded = (file: BrkfstFile) => {
    onChange({ target: { value: file, name } });
    setLoading(false);
  };

  const onFileError = () => {
    setLoading(false);
  };

  useTrendFileNotifications(onFileUploaded, onFileError);

  const fileOnChange = async (files: FileList) => {
    setLoading(true);
    if (files.length) {
      const onValidationError = () => {
        setLoading(false);
      };
      await initiateSingleFileUpload(
        {
          file: files[0],
          fileDirectory: config.TREND_FILE_DIRECTORY,
        },
        new MultiValidator(new GeneralValidator(acceptedFormats), new TrendValidator()),
        onValidationError,
      );
    }
  };

  const onRemove = () => {
    onChange({ target: { value: DEFAULT_VALUE, name } });
  };

  return (
    <Box css={styles} className="trend-file-input">
      {loading ? (
        <Skeleton width="100%" height={40} />
      ) : value.name ? (
        <Flex className="trend-file-input__file-btn">
          <Flex className="trend-file-input__file">
            <FontAwesomeIcon icon={faFile} />
            <Text className="trend-file-input__file-name">{value?.name}</Text>
          </Flex>
          <Button className="trend-file-input__remove-btn" variant={BUTTON_VARIANTS.PLAIN} onClick={onRemove}>
            Remove
          </Button>
        </Flex>
      ) : (
        <MediaUploadButton
          acceptedMedia={acceptedFormats}
          onFileUpload={fileOnChange}
          buttonContent="Upload"
          buttonVariant={BUTTON_VARIANTS.PRIMARY}
        />
      )}
      <FieldErrorMessage name={name} />
    </Box>
  );
};

export default TrendFileInput;

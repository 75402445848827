import cs from 'classnames';
import React from 'react';
import BriefFields from 'features/briefs/components/BriefFields/BriefFields';
import CollectionPreview from 'features/briefs/components/CollectionPreview/CollectionPreview';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import MessageButton from 'shared/components/atoms/MessageButton';
import { Box } from 'shared/components/display';
import { Brief } from 'shared/typings/briefs';
import { BriefStatus } from 'shared/typings/briefs/enums';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import BannerLogo from 'features/accounts/components/molecules/BannerLogo/BannerLogo';
import Chip from 'shared/components/atoms/Chip/Chip';
import COLORS from 'shared/styles/colors';

import styles from './styles';

interface Props {
  currentBrief: Brief;
  loading?: boolean;
  className?: string;
}

const BrandBriefView: React.FC<Props> = ({ currentBrief, loading = false, className = '' }) => {
  // brand users should see the preview of the brief collection and index of submissions if viewing a brief that is not a draft
  const isDraft = currentBrief.status === BriefStatus.DRAFT;
  const isPending = currentBrief.status === BriefStatus.PENDING;

  return (
    <Box css={styles} className={cs('brief-view', className)}>
      {loading ? (
        <CenteredSpinner />
      ) : (
        <Box className="brief-view__content">
          <Box className="brief-view__banner-wrapper">
            <BannerLogo loading={false} account={currentBrief.account} />
            {currentBrief.inviteOnly && (
              <Box className="brief-view__invite-only">
                <Chip label="Invite Only" bgColor={COLORS.PRIMARY400} color={COLORS.WHITE} />
              </Box>
            )}
          </Box>
          <BriefFields brief={currentBrief} uppercase />
          {currentBrief && !isDraft && !isPending && (
            <CollectionPreview
              organizationId={currentBrief.organizationId}
              accountId={currentBrief.accountId}
              collectionId={currentBrief.collection?.id}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default BrandBriefView;

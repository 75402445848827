import { css } from '@emotion/react';

import { mediaquery } from 'shared/styles/styleFunctions';

export const MAIN_CLASS = 'brief-brand-cell-wrapper';
export const CLASSNAMES = {
  text: `${MAIN_CLASS}__text`,
  tooltip: `${MAIN_CLASS}__tooltip`,
};

export default css({
  [`.${MAIN_CLASS}`]: {
    '&__text': {
      whiteSpace: 'nowrap',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      height: '25px',
      margin: 0,
      [mediaquery.md]: {
        height: '24px',
        lineHeight: '0.8',
      },
    },
  },
});

export const tooltipStyles = css({
  [`&.${MAIN_CLASS}__tooltip`]: {
    flexDirection: 'column',
    width: 'max-content',
  },
});

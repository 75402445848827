import React from 'react';
import { Box, Text } from 'rebass/styled-components';

import { TEXT_VARIANTS } from 'shared/styles/text';

const { H2, H3 } = TEXT_VARIANTS;

const WelcomeHeader = ({ heading = '', subheading = '', textAlign = 'left' }) => {
  return (
    <Box my={5} textAlign={textAlign}>
      <Text variant={H2}>{heading}</Text>
      <Text variant={H3}>{subheading}</Text>
    </Box>
  );
};

export default WelcomeHeader;

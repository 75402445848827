export const CARD_COPY = {
  // Modals
  MODAL_DEFAULT: 'Set as the default credit card?',
  // Button
  BUTTON_DEFAULT: 'Set As Default',
  BUTTON_ADD_SUBMIT: 'Add',
  BUTTON_DELETE_CARD: 'Delete Card',
  BUTTON_ADD_CARD: 'Add New Card',

  MODAL_DELETE_CARD: 'Are you sure you want to delete this card?',

  // Column
  COLUMN_NAME_CARD: 'Card',
  COLUMN_EXPIRATION: 'Expiration Date',
  COLUMN_DEFAULT: ' ',

  // errors
  ERROR_INCOMPLETE: 'Your credit card information is incomplete.',

  // Headings
  HEADING_MANAGE: 'Cards',
  HEADING_CHECKOUT: 'Payment Method',

  // Form
  FORM_LABEL_DEFAULT: 'Make default payment method',
  FORM_HEADING_NEW_CARD: 'New Card',

  // Link
  LINK_CARD_SETTINGS: 'Edit',
};

import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export default css({
  '.account-platform-home': {
    '&__header': {
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.space[5],
    },
    '&__title-wrapper': {
      alignContent: 'center',
    },
    '&__title': {
      marginBottom: 0,
    },
    '&__elliptical': {
      cursor: 'pointer',
    },
  },
});

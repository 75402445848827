import axiosClient from 'shared/axiosClient';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';
import { MESSAGES } from 'shared/config/validations';
import { intl } from 'shared/lib/intl';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import * as yup from 'yup';

export const FIELDNAMES = Object.freeze({
  EXISTING_CREATORS: 'creators',
  EMAILS: 'emails',
  AUTO_APPROVE: 'autoApprove',
  SUBMIT: 'submit',
});

export const initialValues = Object.freeze({
  [FIELDNAMES.EXISTING_CREATORS]: [],
  [FIELDNAMES.EMAILS]: [],
  [FIELDNAMES.AUTO_APPROVE]: false,
});

/**
 *
 * @param emails array of emails from the form
 * @param existingCreatorEmails array of emails of creators with existing relationship to the brief
 * @returns array of arrays, the first array containing already invited emails and the second emails from the emails param that were not already invited
 */
const creatorCannotBeInvited = (existingCreatorEmails: string[], emails?: string[]): string[][] => {
  const aggValue: string[][] = [[], []];
  return (
    emails?.reduce((agg, email) => {
      if (existingCreatorEmails.includes(email)) {
        return [[...agg[0], email], agg[1]];
      }
      return [agg[0], [...agg[1], email]];
    }, aggValue) || aggValue
  );
};

const checkForMarketerEmails = async (emails: string[]) => {
  if (!emails.length) return [];
  return axiosClient
    .httpRequest<string[]>({
      url: LinkCreator.createApiLink({
        route: API_USER_ROUTES.CHECK_EMAILS_TYPE,
      }),
      method: 'POST',
      data: {
        emails,
        type: UserType.ORG,
      },
    })
    .then(({ data }) => {
      return data;
    })
    .catch((e) => {
      return [];
    });
};

export const getInviteCreatorSchema = (creatorEmails: string[]) =>
  yup.object({
    [FIELDNAMES.EXISTING_CREATORS]: yup
      .array(
        yup.object({
          firstName: yup.string(),
          lastName: yup.string(),
          email: yup.string(),
        }),
      )
      .test({
        name: 'atLeastOneCreator',
        // error message will come from FIELDNAMES.EMAILS' yup test with the same name
        // because this error refers to both inputs (at least one value between both inputs).
        message: ' ',
        test(value) {
          return value?.length || this.parent[FIELDNAMES.EMAILS]?.length;
        },
      }),
    [FIELDNAMES.EMAILS]: yup
      .array()
      .of(yup.string().trim().email(MESSAGES.EMAIL))
      .test({
        name: 'atLeastOneCreator',
        message: MESSAGES.REQUIRED_CREATOR_OR_EMAIL,
        test(value) {
          return value?.length || this.parent[FIELDNAMES.EXISTING_CREATORS]?.length;
        },
      })
      .test({
        name: 'invalid-invitees',
        message: intl.formatMessage(
          {
            id: 'VALIDATION_INVALID_CREATOR_INVITEES',
          },
          {
            emailCount: 0,
          },
        ),
        async test(value?: string[]) {
          const [alreadyInvitedEmails, otherEmails] = creatorCannotBeInvited(creatorEmails, value);

          if (alreadyInvitedEmails?.length) {
            return this.createError({
              message: intl.formatMessage(
                {
                  id: 'VALIDATION_INVALID_CREATOR_INVITEES',
                },
                {
                  emailCount: alreadyInvitedEmails.length,
                  emails: alreadyInvitedEmails.join(', '),
                },
              ),
            });
          }
          const marketerEmails = await checkForMarketerEmails(otherEmails);

          if (marketerEmails.length) {
            return this.createError({
              message: intl.formatMessage(
                {
                  id: 'VALIDATION_INVALID_MARKETER_INVITEES',
                },
                {
                  emailCount: marketerEmails.length,
                  emails: marketerEmails.join(', '),
                },
              ),
            });
          }
          return true;
        },
      }),
    [FIELDNAMES.AUTO_APPROVE]: yup.boolean(),
  });

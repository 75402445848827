import React from 'react';
import { Route, Routes } from 'react-router-dom';

import BrandUserAccountSettings from 'features/users/pages/BrandUserAccountSettings/BrandUserAccountSettings';

import CreatorRoutes from 'shared/router/brandRoutes/CreatorRoutes';

const BrandUserRoutes = () => {
  return (
    <Routes>
      <Route path="settings" element={<BrandUserAccountSettings />} />
      <Route path=":userId/*" element={<CreatorRoutes />} />
    </Routes>
  );
};

export default BrandUserRoutes;

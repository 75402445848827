import React from 'react';
import cs from 'classnames';
import { Flex } from 'shared/components/display';
import styles from './styles';
import { Button } from 'rebass/styled-components';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import COLORS from 'shared/styles/colors';

interface Props {
  showSelectOption?: boolean;
  isSelected?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
}
const FileSelectButton: React.FC<Props> = ({ showSelectOption, isSelected, onClick, disabled, className }) => {
  return (
    <Flex
      css={styles}
      className={cs('file-select-button', className, {
        'file-select-button--selected': isSelected,
        'file-select-button--show': showSelectOption,
      })}
    >
      <Button
        onClick={(e) => {
          e.persist();
          onClick(e);
          e.currentTarget.blur();
        }}
        variant={BUTTON_VARIANTS.TEXT_ONLY}
        type="button"
        disabled={disabled}
        className="file-select-button__btn"
      >
        <FontAwesomeIcon icon={faCheck} color={showSelectOption && !isSelected ? COLORS.TRANSPARENT : COLORS.WHITE} />
      </Button>
    </Flex>
  );
};

export default FileSelectButton;

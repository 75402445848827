import React, { useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { startCase } from 'lodash';
import * as yup from 'yup';

import { Box } from 'shared/components/display';
import { InlineSocialMediaHandleInput, InlineTextInput } from 'shared/components/InlineInputs';
import { useIntl } from 'shared/lib/intl';
import { YUP_FIELDS } from 'shared/utilities/yupFieldUtility';
import { BrkfstUser } from 'shared/typings/user';

// fieldnames
const PORTFOLIO_LINK = 'portfolioLink';
const PORTFOLIO_LINK_PLACEHOLDER = 'portfolio link';
const TIKTOK = 'tiktok';

interface Props {
  user: Pick<BrkfstUser, 'portfolioLink' | 'socialMediaInfo'>;
  onSubmit: (values: Values) => void;
}

interface Values {
  portfolioLink?: string;
  tiktok?: string;
}

const validationSchema: yup.SchemaOf<Values> = yup.object({
  [PORTFOLIO_LINK]: YUP_FIELDS.PORTFOLIO_LINK_OPTIONAL,
  [TIKTOK]: yup.string().nullable().tiktokHandle(),
});

const EditableCreatorInfo: React.FC<Props> = ({ user, onSubmit }) => {
  const { formatMessage } = useIntl();

  const initialValues: Values = useMemo(() => {
    return {
      [PORTFOLIO_LINK]: user?.portfolioLink || '',
      [TIKTOK]: user.socialMediaInfo?.tiktok,
    };
  }, [user]);

  return (
    <Box className="editable-creator-info">
      <Formik<Values> validationSchema={validationSchema} onSubmit={onSubmit} initialValues={initialValues}>
        {() => (
          <Form>
            <Field
              name={PORTFOLIO_LINK}
              maxLength={512}
              component={InlineTextInput}
              placeholder={formatMessage(
                {
                  id: 'PLACEHOLDER_PROFILE',
                },
                { field: PORTFOLIO_LINK_PLACEHOLDER },
              )}
              label={startCase(PORTFOLIO_LINK)}
            />
            <Field name={TIKTOK} label="TikTok" component={InlineSocialMediaHandleInput} placeholder="tiktok_handle" />
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditableCreatorInfo;

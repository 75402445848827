export const TASK_COPY = {
    // Headings
    HEADING_EDIT: 'Edit Task',
    HEADING_CREATE: 'New Task',

    // Buttons
    BUTTON_ADD_STEP: 'Add Step',

    // Placeholders
    PLACEHOLDER_SELECT_STEP: 'Select a Step',

    // Tooltips
    TOOLTIP_DISABLED_STATUS:
        'Disabled because it has failed too many times (reason: "#{failReason}")',
    TOOLTIP_FAILED_STATUS:
        'Failed #{failCount} times (reason: "#{failReason}")',
    TOOLTIP_STATUS: 'Last run was #{lastFinishedAt}',
};

import React, { useEffect, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import cs from 'classnames';

import EarningDetails from 'features/users/components/organisms/EarningDetails/EarningDetails';
import EarningsTable from 'features/users/components/organisms/EarningsTable/EarningsTable';
import { useEarnings } from 'features/users/hooks/useEarnings';
import BackButton from 'shared/components/atoms/BackButton';
import { Box } from 'shared/components/display';
import SwipeableContainer from 'shared/components/molecules/SwipableContainer';
import Pagination from 'shared/components/organisms/Pagination';

import { CREATOR_COPY, EARNING_COPY } from 'shared/config/copy';
import { useQuery } from 'shared/hooks/useQuery';
import { CLASSNAMES as GLOBAL_CLASSNAMES } from 'shared/styles/containers';
import { UserType } from 'shared/typings/user/enums';
import { prettyDate, splitISODate } from 'shared/utilities/dateUtility';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { formatCurrency } from 'shared/utilities/stringUtility';

import styles, { MAIN_CLASS } from './styles';

export enum EarningType {
  FILE = 'FILE',
  MANUAL = 'MANUAL',
  SUBMISSION = 'SUBMISSION',
}

export const EarningTypeLabel = Object.freeze({
  FILE: 'Performance',
  MANUAL: 'Manual',
  SUBMISSION: 'Submission',
});

export type Earning = {
  billingEndDate: string;
  billingStartDate: string;
  creatorId: number;
  id: number;
  stripePayoutDate: string;
  totalAmount: number;
  type: EarningType;
};

export interface EarningPayout extends Required<Omit<Earning, 'id'>> {
  creatorId: number;
  briefNames: Array<string>;
  fileId?: number;
  fileName?: string;
  fileUrl?: string;
  thumbnailUrl?: string;
  accountName: string;
  briefName?: string;
  submissionId?: string;
  submissionDateCreated?: string;
  note?: string;
  manualTransactionId?: number;
}

const Earnings = () => {
  const {
    earnings,
    currentPayouts,
    loading,
    totalEarnings,
    searchParams,
    getEarnings,
    DEFAULT_PERFORMANCE_FILES_PAGE,
    DEFAULT_PERFORMANCE_FILES_SIZE,
  } = useEarnings();

  const { setQuery } = useQuery();

  const columnHelper = createColumnHelper<Earning>();

  useEffect(() => {
    getEarnings();
  }, [getEarnings]);

  const columns = useMemo(() => {
    return earnings?.length > 0
      ? [
          // @ts-ignore
          columnHelper.accessor(
            // @ts-ignore
            ({ stripePayoutDate }) => prettyDate(splitISODate(stripePayoutDate)),
            // @ts-ignore
            {
              id: 'date',
              cell: (info) => info.getValue(),
              header: () => EARNING_COPY.COLUMN_DATE,
            },
          ),
          columnHelper.accessor(({ type }) => EarningTypeLabel[type], {
            id: 'type',
            cell: (info) => info.getValue(),
            header: () => EARNING_COPY.COLUMN_TYPE,
          }),
          columnHelper.accessor(({ totalAmount }) => (totalAmount ? formatCurrency(totalAmount / 100) : ''), {
            id: 'amount',
            cell: (info) => info.getValue(),
            header: () => EARNING_COPY.COLUMN_AMOUNT_EARNING,
            meta: {
              addTooltip: true,
            },
          }),
        ]
      : [
          // @ts-ignore
          columnHelper.accessor(
            // @ts-ignore
            null,
            {
              id: 'date',
              header: () => EARNING_COPY.COLUMN_DATE,
            },
          ),
          columnHelper.accessor(
            // @ts-ignore
            null,
            {
              id: 'type',
              header: () => EARNING_COPY.COLUMN_TYPE,
            },
          ),
          columnHelper.accessor(
            // @ts-ignore
            null,
            {
              id: 'amount',
              header: () => EARNING_COPY.COLUMN_AMOUNT_EARNING,
            },
          ),
        ];
  }, [earnings]);

  const handleEarningRowClick = (earning: Earning & { rowIndex: number }) => {
    updatePayoutIndex(earning.rowIndex);
  };

  const updatePayoutIndex = (index: number) => {
    setQuery({
      payoutIndex: index,
      performanceFilesPage: DEFAULT_PERFORMANCE_FILES_PAGE,
      performanceFilesSize: DEFAULT_PERFORMANCE_FILES_SIZE,
    });
  };

  const updateDetailsIndex = (index: number) => {
    setQuery({ detailsIndex: index });
  };

  const linkToEarnings = LinkCreator.createLink({
    routeKey: 'EARNINGS',
    userType: UserType.CREATOR,
  });

  return (
    <Box
      css={styles}
      className={cs(MAIN_CLASS, GLOBAL_CLASSNAMES.MAIN_CONTAINER, {
        [`${MAIN_CLASS}--performance`]: currentPayouts?.length > 0 && currentPayouts[0].type === EarningType.FILE,
      })}
    >
      {searchParams.payoutIndex >= 0 ? (
        <BackButton
          to={linkToEarnings}
          text={CREATOR_COPY.BUTTON_BACK_TO_EARNINGS}
          className={`${MAIN_CLASS}__back-btn`}
        />
      ) : (
        <></>
      )}
      {searchParams.payoutIndex >= 0 ? (
        <SwipeableContainer updatePageIndex={updatePayoutIndex} pageIndex={searchParams.payoutIndex}>
          {earnings.map((earning) => (
            <EarningDetails key={earning.id} earningItem={earning} updateDetailsIndex={updateDetailsIndex} />
          ))}
        </SwipeableContainer>
      ) : (
        <Pagination totalAmount={totalEarnings} searchParams={searchParams}>
          <EarningsTable
            earnings={earnings}
            onEarningRowClick={handleEarningRowClick}
            columns={columns}
            loading={loading}
            noResultsMessage={EARNING_COPY.PLACEHOLDER_NO_EARNINGS_YET}
            totalEarnings={totalEarnings}
          />
        </Pagination>
      )}
    </Box>
  );
};

export default Earnings;

import React from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import styles from './styles';

interface Props {
  OverlayComponent: React.ReactElement;
  children: React.ReactElement;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}
const HoverOverlay: React.FC<Props> = ({ OverlayComponent, children, ...styleProps }) => {
  const stopPropagation = (e) => e.stopPropagation();
  return (
    <Box css={styles(styleProps)} className={cs('hover-overlay')}>
      {children}
      <Box className="hover-overlay__overlay" onClick={stopPropagation}>
        {OverlayComponent}
      </Box>
    </Box>
  );
};

export default HoverOverlay;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '&.pin-file-controls': {
    '.pin-file-controls__pin-tooltip': {
      width: '300px',
    },
  },
  '&.pin-file-controls__pin-tooltip': {
    width: '300px',
  },
  '.pin-file-controls': {
    '&__pin-btn': {
      padding: 0,
      color: COLORS.SECONDARY500,
      backgroundColor: COLORS.WHITE,
      borderRadius: '50%',
      width: '30px',
      height: '30px',
      cursor: 'pointer',
      transform: 'rotate(25deg)',
      '&:not(:disabled):hover, &:not(:disabled):focus': {
        backgroundColor: COLORS.WHITE,
      },
      '&:not(:disabled):hover svg, &:not(:disabled):focus svg': {
        color: COLORS.SECONDARY600,
      },
    },
    '&__pin-btn--pinned': {
      visibility: 'visible',
      color: COLORS.SECONDARY500,
      '&:not(:disabled):hover': {
        color: COLORS.SECONDARY600,
      },
    },
    '&__pin-btn--disabled': {
      color: COLORS.SECONDARY500,
      cursor: 'auto',
      '&:not(:disabled):hover': {
        color: COLORS.SECONDARY600,
      },
    },
    '&__pin-btn--hidden': {
      display: 'none',
    },
    '&__pin-btn--full': {
      color: COLORS.SECONDARY500,
      opacity: 0.6,
      cursor: 'not-allowed',
      '&:not(:disabled):hover': {
        color: COLORS.SECONDARY500,
      },
    },
  },
});

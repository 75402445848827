import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.sign-up': {
    '&__name-wrapper': {
      width: '100%',
      flexWrap: ['wrap', 'wrap', 'nowrap'],
      alignItems: 'start',
      justifyContent: 'space-between',
    },
    '&__name-input': {
      flexGrow: 1,
    },
    '&__first-name': {
      marginRight: '0.75rem',
    },
    '&__name-input input': {
      color: COLORS.BLACK,
    },
    '&__email-input input': {
      color: COLORS.BLACK,
    },
    '&__name-input input::placeholder': {
      color: COLORS.NEUTRAL700,
    },
    '&__email-input input::placeholder': {
      color: COLORS.NEUTRAL700,
    },
    '&__name-wrapper > *:not(:last-child)': {
      marginRight: [0, 0, 5],
    },
    '&__footnote': {
      fontWeight: 300,
      lineHeight: 1.2,
      margin: `${theme.space[3]} 0 ${theme.space[4]} 0`,
    },
    '&__footnote--bold': {
      fontWeight: 600,
    },
    '&__loading-icon': {
      fontSize: '24px',
      margin: '-3px 15px',
    },
  },
});

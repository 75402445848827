import { css } from '@emotion/react';

import { mediaquery } from 'shared/styles/styleFunctions';

export const MAIN_CLASS = 'mobile-earnings';
export const CLASSNAMES = {
  fileThumbnail: `${MAIN_CLASS}__file-thumbnail`,
};
// @ts-ignore
export default css({
  [`&.${MAIN_CLASS}`]: {
    height: '100%',
    [mediaquery.md]: {
      height: 'auto',
    },
    '&--performance': {
      height: 'auto',
    },
    '& td': {
      fontStyle: 'italic',
    },
  },
  [`.${MAIN_CLASS}`]: {
    '&__file-thumbnail': {
      maxHeight: '60px',
      maxWidth: '100px',
    },
    '&__back-btn': {
      padding: '0 15px',
    },
  },
});

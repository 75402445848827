import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.collection-search': {
    width: '100%',
    marginTop: theme.space[4],
    marginBottom: theme.space[5],
    marginRight: theme.space[5],
    alignItems: 'center',
    flexDirection: 'column',
    [mediaquery.md]: {
      flexDirection: 'row',
    },
  },
  '.collection-search': {
    '&__form': {
      width: '100%',
    },
    '&__search-bar': {
      position: 'relative',
      alignItems: 'center',
      flexGrow: 1,
      width: '100%',
      marginBottom: theme.space[4],
      [mediaquery.md]: {
        marginBottom: 0,
      },
    },
    '&__icon': {
      position: 'absolute',
      left: '10px',
      zIndex: zIndex.zForeground,
      top: '10px',
    },
    '&__spinner': {
      position: 'absolute',
      right: '10px',
      zIndex: zIndex.zForeground,
      top: '12px',
      color: COLORS.NEUTRAL700,
    },
    '&__search-input': {
      width: '100%',
      margin: 0,
      flexGrow: 1,
      '& input': {
        paddingLeft: '40px',
        margin: 0,
      },
    },
    '&__search-btn': {
      margin: 0,
    },
    '&__filter-wrapper': {
      width: '100%',
      flexDirection: 'column',
      [mediaquery.md]: {
        flexDirection: 'row',
        marginLeft: theme.space[2],
        width: 'auto',
      },
    },
    '&__filter-wrapper *:not(:last-child)': {
      marginRight: theme.space[2],
      [mediaquery.md]: {
        marginRight: theme.space[2],
      },
    },
  },
});

import React from 'react';
import { Box } from 'shared/components/display';
import WelcomeHeader from 'shared/components/molecules/WelcomeHeader/WelcomeHeader';

import { useLocation } from 'react-router-dom';
import { useAuth } from 'features/auth/useAuth';

import { MESSAGES } from 'shared/config/validations';
import { STYLES } from 'shared/styles/styles';
import { AUTH_COPY } from 'shared/config/copy/authCopy';
import { CLASSNAMES } from 'shared/styles/containers';
import { intl } from 'shared/lib/intl';
import { Field, Form, Formik } from 'formik';
import { makeLabel } from 'shared/lib/formik';
import FormButton from 'shared/components/FormButton';
import * as yup from 'yup';
import TextInput from 'shared/components/TextInput';
import tokenExpiredStyles from 'features/auth/pages/TokenExpired/tokenExpiredStyles';
import cs from 'classnames';

const { CENTER } = STYLES;

// fieldnames
const EMAIL = 'email';

const schema = yup.object({
  email: yup.string().trim().email(MESSAGES.EMAIL).customRequiredString(),
});

const initialValues = {
  email: '',
};

const TokenExpired = () => {
  const location = useLocation<{ type: string }>();

  const { sendLinkAgain } = useAuth();

  const { type } = location.state;

  const linkType = type === 'reset-password' ? 'password' : 'activation';

  const onSubmit = (formData) => {
    sendLinkAgain(formData.email, type);
  };

  return (
    <Box css={tokenExpiredStyles} className={cs('token-expired', CLASSNAMES.PUBLIC_CONTAINER)}>
      <WelcomeHeader
        heading={AUTH_COPY.HEADING_TOKEN_EXPIRED}
        subheading={intl.formatMessage(
          {
            id: 'SUBHEADING_TOKEN_EXPIRED',
          },
          {
            linkType,
          },
        )}
        textAlign={CENTER}
      />
      <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={initialValues}>
        <Form className="token-expired__form">
          <Field
            name={EMAIL}
            label={makeLabel(EMAIL, true)}
            placeholder={makeLabel(EMAIL, true)}
            component={TextInput}
          />
          <FormButton className="token-expired__submit-btn">{AUTH_COPY.BUTTON_RESEND}</FormButton>
        </Form>
      </Formik>
    </Box>
  );
};

export default TokenExpired;

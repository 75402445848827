import { trendThumbnailUpdated } from 'features/trends/trends.slice';
import { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { SignalRContext } from 'shared/contexts/signalr';
import { useToasts } from 'shared/hooks/useToasts';
import { BrkfstFile } from 'shared/typings/file';

export const useTrendFileNotifications = (onFileUpload?: (file: BrkfstFile) => void, onFileError?: () => void) => {
  const { connection } = useContext(SignalRContext);
  const { setErrorToast } = useToasts();
  const dispatch = useDispatch();

  const onInvalidFile = ({ key, error }: { key: string; error: string }) => {
    setErrorToast({ message: error });
    if (onFileError) onFileError();
  };

  const onNewTrendFile = (file: BrkfstFile) => {
    if (onFileUpload) onFileUpload(file);
  };

  const onNewThumbnail = ({ fileId, thumbnailUrl, thumbnailOffset }) => {
    dispatch(trendThumbnailUpdated({ fileId, thumbnailUrl, thumbnailOffset }));
  };

  useEffect(() => {
    if (connection) {
      connection.on('newTrendFile', onNewTrendFile);
      connection.on('fileError', onInvalidFile);
      connection.on('newThumbnail', onNewThumbnail);
    }

    return function cleanup() {
      if (connection) {
        connection?.off('newTrendFile', onNewTrendFile);
        connection?.off('fileError', onInvalidFile);
        connection.on('newThumbnail', onNewThumbnail);
      }
    };
  }, [connection]);
};

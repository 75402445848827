import React from 'react';
import { useGalleryListView, VIEWS } from 'shared/hooks/useGalleryListView';
import { SegmentedButton } from 'shared/components/molecules/SegmentedButton';

const viewOptions = Object.values(VIEWS);

interface Props {
  className?: string;
}

const ToggleGalleryListView: React.FC<Props> = ({ className }) => {
  const { setViewMode, viewMode } = useGalleryListView();

  const setValue = (value) => setViewMode(value.value);

  return (
    <SegmentedButton
      value={viewMode}
      options={viewOptions}
      optionWidth={2.5}
      onChanged={setValue}
      className={className}
    />
  );
};

export default ToggleGalleryListView;

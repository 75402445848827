import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';
import { STYLES } from 'shared/styles/styles';

export default css({
  '&.org-settings-sidebar': {
    borderRight: STYLES.BORDER_NEUTRAL,
    overflowY: 'auto',
    padding: `${theme.space[5]} 0`,
  },
  '.org-settings-sidebar': {
    '&__nav-group': {
      marginBottom: theme.space[7],
    },
    '&__nav-item': {
      position: 'relative',
    },
    '&__nav-item p': {
      width: '100%',
      padding: `${theme.space[3]} ${theme.space[7]} ${theme.space[3]} ${theme.space[5]}`,
    },
    '&__nav-item a.active p': {
      width: '100%',
      fontWeight: 600,
      color: COLORS.PRIMARY500,
      backgroundColor: `${COLORS.PRIMARY200}80`,
    },
    '&__nav-header': {
      color: COLORS.NEUTRAL500,
      paddingLeft: theme.space[5],
      paddingRight: theme.space[7],
      marginBottom: theme.space[3],
    },
  },
});

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { FacebookCheckoutCarousel, FacebookCheckoutSingle } from 'features/accountPlatforms/facebook';
import { Text } from 'shared/components/display';
import ConfirmationPanel from 'shared/components/organisms/ConfirmationPanel';

import { apiAction } from 'shared/actions/api';
import { ADS } from 'shared/config/ads';
import { FACEBOOK_COPY } from 'shared/config/copy';
import { API_ACCOUNT_ROUTES } from 'shared/config/routes/api/apiAccountRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useFormData } from 'shared/hooks/useFormData';
import { TEXT_VARIANTS } from 'shared/styles/text';
import theme from 'shared/styles/themes';
import { LinkCreator } from 'shared/utilities/linkUtility';

const FacebookCheckout = () => {
  const params = useParams<{ organizationId: string; accountId: string; accountPlatformId: string }>();
  const location = useLocation<{ type: any; formData: any; ads: any; adsetIds: number[]; productSetId?: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { organizationId, accountId, accountPlatformId } = params;
  const { type, formData, ads, adsetIds, productSetId } = location.state || {};
  const { saveFormData } = useFormData();
  const [adRequestId, setAdRequestId] = useState<string>('');
  const CHECKOUT_ENTITY = 'facebookCheckout';
  const { loading } = useComponentLoading(CHECKOUT_ENTITY, false);
  const isCarousel = type === ADS.FACEBOOK.TYPES.CAROUSEL;

  useEffect(() => {
    if (!location.state) {
      navigate(
        LinkCreator.createLink({
          routeKey: 'PLATFORM',
          variables: {
            accountId,
            organizationId,
            accountPlatformId,
          },
        }),
      );
    }
  }, [accountId, organizationId, accountPlatformId]);

  const onSubmit = () => {
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.AD_PROCESSING,
          variables: {
            accountId,
          },
        },
        method: 'POST',
        data: adRequestId,
        navigate,
        entity: CHECKOUT_ENTITY,
        pushFunction: () => ({
          pathname: LinkCreator.createLink({
            routeKey: 'FB_CHECKOUT_CONFIRMATION',
            variables: {
              accountId,
              organizationId,
              accountPlatformId,
            },
          }),
          state: {
            heading: FACEBOOK_COPY.LABEL_AD_PUBLISHED,
            body: FACEBOOK_COPY.CHECKOUT_SUCCESS_BODY,
            buttonLink: LinkCreator.createLink({
              routeKey: 'PLATFORM',
              variables: {
                accountId,
                organizationId,
                accountPlatformId,
              },
            }),
            buttonText: FACEBOOK_COPY.CHECKOUT_SUCCESS_BUTTON,
          },
        }),
      }),
    );
  };

  useEffect(() => {
    const data: Record<string, any> = { type, ads, adsetIds };
    if (productSetId) data.productSetId = productSetId;
    dispatch(
      apiAction({
        path: {
          route: API_ACCOUNT_ROUTES.AD_LAUNCH,
          variables: {
            accountId,
          },
        },
        params: {
          accountPlatformId,
        },
        data,
        method: 'POST',
        onSuccess: (response) => {
          setAdRequestId(response.data);
        },
      }),
    );
  }, [type, ads, adsetIds, productSetId]);

  const pathToFbCreation = LinkCreator.createLink({
    routeKey: 'AD_CREATION_PLATFORM',
    variables: {
      accountId,
      organizationId,
      accountPlatformId,
    },
  });

  return (
    <ConfirmationPanel
      backButtonProps={{
        text: FACEBOOK_COPY.BUTTON_BACK_TO_CREATE_AD,
        to: pathToFbCreation,
        state: location.state,
        onClick: () => saveFormData(formData),
      }}
      onSubmit={onSubmit}
      onSubmitText={FACEBOOK_COPY.BUTTON_CHECKOUT}
      submitInProcess={loading}
    >
      <Text
        variant={TEXT_VARIANTS.H4}
        style={{
          marginBottom: theme.space[4],
        }}
      >
        {FACEBOOK_COPY.HEADING_LAUNCH_FB_AD}
      </Text>
      <Text variant={TEXT_VARIANTS.H4}>{FACEBOOK_COPY.HEADING_AD_DETAIL}</Text>
      {isCarousel ? <FacebookCheckoutCarousel /> : <FacebookCheckoutSingle />}
    </ConfirmationPanel>
  );
};

export default FacebookCheckout;

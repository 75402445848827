import React from 'react';
import { Text } from 'rebass/styled-components';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { UserBriefStatus, creatorBriefStatusDisplay } from 'shared/typings/userBriefs/enums';
import Chip from 'shared/components/atoms/Chip/Chip';

interface Props {
  creatorBriefStatus: UserBriefStatus;
}

const CreatorBriefStatus: React.FC<Props> = ({ creatorBriefStatus }) => {
  switch (creatorBriefStatus) {
    case UserBriefStatus.SUBMITTED:
      return (
        <Text variant={TEXT_VARIANTS.CATEGORY} color={COLORS.PRIMARY500}>
          {creatorBriefStatusDisplay[creatorBriefStatus]}
        </Text>
      );
    case UserBriefStatus.REJECTED:
      return (
        <Text variant={TEXT_VARIANTS.CATEGORY} color={COLORS.RED700}>
          {creatorBriefStatusDisplay[creatorBriefStatus]}
        </Text>
      );
    case UserBriefStatus.ACTIVE:
      return (
        <Chip
          label={creatorBriefStatusDisplay[creatorBriefStatus]}
          color={COLORS.GREEN700}
          bgColor={COLORS.GREEN100}
          square
          width="83px"
        />
      );
    case UserBriefStatus.PENDING:
      return (
        <Chip
          label={creatorBriefStatusDisplay[creatorBriefStatus]}
          color={COLORS.ORANGE700}
          bgColor={COLORS.ORANGE100}
          square
          width="83px"
        />
      );
    case UserBriefStatus.INVITED:
      return (
        <Chip
          label={creatorBriefStatusDisplay[creatorBriefStatus]}
          color={COLORS.NEUTRAL800}
          bgColor={COLORS.NEUTRAL100}
          square
          width="83px"
        />
      );
    case UserBriefStatus.DRAFTED:
      return (
        <Chip
          label={creatorBriefStatusDisplay[creatorBriefStatus]}
          color={COLORS.NEUTRAL800}
          bgColor={COLORS.NEUTRAL100}
          square
          width="83px"
        />
      );
    case UserBriefStatus.REVISION:
      return (
        <Chip
          label={creatorBriefStatusDisplay[creatorBriefStatus]}
          color={COLORS.RED700}
          bgColor={COLORS.RED50}
          square
          width="83px"
        />
      );
    default:
      return <></>;
  }
};

export default CreatorBriefStatus;

import React from 'react';
import { Field, Form, Formik } from 'formik';
import TextInput from 'shared/components/TextInput';
import FormButton from 'shared/components/FormButton';
import { AUTH_COPY } from 'shared/config/copy';
import { Box } from 'shared/components/display';
import forgotPasswordFormStyles from 'features/auth/components/organisms/ForgotPasswordForm/forgotPasswordFormStyles';
import { useAuth } from 'features/auth/useAuth';
import * as yup from 'yup';
import { MESSAGES } from 'shared/config/validations';
import { makeLabel } from 'shared/lib/formik';

const EMAIL = 'email';

interface Props {}

const schema = yup.object({
  email: yup.string().trim().email(MESSAGES.EMAIL).customRequiredString(),
});

const initialValues = Object.freeze({
  email: '',
});

const ForgotPasswordForm: React.FC<Props> = () => {
  const { requestReset } = useAuth();
  return (
    <Formik validationSchema={schema} onSubmit={requestReset} initialValues={initialValues}>
      <Box css={forgotPasswordFormStyles} className="forgot-password-form">
        <Form>
          <Field
            name={EMAIL}
            placeholder={makeLabel(EMAIL, true)}
            label={makeLabel(EMAIL, true)}
            component={TextInput}
          />
          <FormButton className="forgot-password-form__submit-btn">{AUTH_COPY.BUTTON_SEND_RESET}</FormButton>
        </Form>
      </Box>
    </Formik>
  );
};

export default ForgotPasswordForm;

import React, { ReactElement } from 'react';
import cs from 'classnames';

import { Box, Text } from 'shared/components/display';

import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';

export interface ListDetailProps {
  variant?: string;
  uppercase?: boolean;
  text: ReactElement | Array<ReactElement> | string;
  children: ReactElement | Array<ReactElement | string> | string | String;
  detailsClassName?: string;
  labelClassName?: string;
  hideLabel?: boolean;
  color?: string;
  hide?: boolean;
  stickyLabel?: boolean;
  dataCy?: string;
  className?: string;
}

const ListDetail: React.FC<ListDetailProps> = ({
  text,
  variant = TEXT_VARIANTS.SUBHEADING,
  uppercase = false,
  children,
  detailsClassName,
  labelClassName,
  hideLabel = false,
  color = COLORS.NEUTRAL500,
  hide = false,
  stickyLabel = false,
  dataCy,
  className,
}) => {
  if (hide) return <></>;
  return (
    <Box className={className}>
      {hideLabel ? (
        <></>
      ) : (
        <Box
          as="dt"
          className={cs('definition-list__label', labelClassName, {
            ['definition-list__label--sticky']: stickyLabel,
            ['definition-list__label--upper']: uppercase,
          })}
          data-cy={`${dataCy}__dt`}
        >
          <Text variant={variant} color={color}>
            {text}
          </Text>
        </Box>
      )}
      <Box data-cy={`${dataCy}__dd`} as="dd" className={cs('definition-list__detail', detailsClassName)}>
        {children}
      </Box>
    </Box>
  );
};

export default ListDetail;

export enum Breakdowns {
  AGE = 'Age',
  GENDER = 'Gender',
  AGE_GENDER = 'Age-Gender',
  PLACEMENT = 'Placement',
  NONE = 'none',
}

export enum BreakdownFields {
  AGE = 'age',
  GENDER = 'gender',
  PUBLISHER_PLATFORM = 'publisher_platform',
  PLATFORM_POSITION = 'platform_position',
  DEVICE_PLATFORM = 'device_platform',
  NONE = 'none',
}

export enum PerformanceLevels {
  ACCOUNT = 'account',
  CAMPAIGN = 'campaign',
  ADSET = 'adset',
  AD = 'ad',
}

export enum PerformanceFilters {
  AD_ID = 'ad.id',
  CAMPAIGN_ID = 'campaign.id',
  ADSET_ID = 'adset.id',
  AD_NAME = 'ad.name',
  CAMPAIGN_NAME = 'campaign.name',
  ADSET_NAME = 'adset.name',
}

export enum AttributionWindow {
  DEFAULT = '',
  ONE_DAY_CLICK = '1d_click',
  SEVEN_DAY_CLICK = '7d_click',
  ONE_DAY_VIEW = '1d_view',
}

export enum TopBottomFieldnames {
  RANK_BY = 'rankBy',
  TOP_BOTTOM_AMOUNT = 'topBottomAmount',
  AGE = 'age',
  GENDER = 'gender',
  PUBLISHER_PLATFORM = 'publisher_platform',
  PLATFORM_POSITION = 'platform_position',
  DEVICE_PLATFORM = 'device_platform',
  BREAKDOWN = 'breakdown',
}

import { partition } from 'lodash';

import { UploadFile } from 'features/ui/ui.slice';

import { apiRequest } from 'shared/middleware/api';
import { BrkfstFile, UploadOperation } from 'shared/typings/file';
import { FileUploadStatus } from 'shared/typings/file/enums';
import { formatAspectRatio } from 'shared/utilities/fileUtility';
import { getMediaMetaData } from 'shared/utilities/validator/utils';

interface FormatUploadOperationsParams {
  files: File[];
  submissionId?: number;
  collectionId?: number;
  validatorFields: {
    minAssetDuration: number;
    maxAssetDuration: number;
    moduleNum: number;
    fileType: string;
  };
  fileName?: string;
}

export const formatUploadOperations = ({
  files,
  submissionId,
  collectionId,
  validatorFields,
  fileName,
}: FormatUploadOperationsParams): Promise<UploadOperation[]> => {
  return Promise.all(
    Array.from(files).map(async (file: any) => {
      let uploadingFile = file;
      if (uploadingFile.file) {
        // if the file's coming from fileExplorer, it's a list of files,
        // if it's coming directly from dragAndDrop, it's a list of objects as {file, mediaObjectURL}
        uploadingFile = uploadingFile.file;
      }

      const uploadOperation: UploadOperation = {
        file: uploadingFile,
        metadata: {
          addWatermark: true,
          thumbnailOffset: 0.5,
          submissionValidatorFields: JSON.stringify(validatorFields),
        },
      };
      if (collectionId) uploadOperation.collectionId = collectionId;
      if (submissionId) uploadOperation.submissionId = submissionId;
      if (fileName) {
        const metadata = await getMediaMetaData(uploadingFile);
        const aspectRatio = formatAspectRatio(metadata);
        const fileExtension = uploadingFile.name.split('.').pop();
        const fileNameWithAspectRatio = formatSubmissionFileName(
          fileName,
          aspectRatio,
          validatorFields.fileType,
          fileExtension,
        );
        uploadOperation.fileName = fileNameWithAspectRatio;
      }
      return uploadOperation;
    }),
  );
};

/**
 * Copies the files to the same collection using the submission file naming convention.
 * @param files
 * @param baseFileName
 * @param fileType
 */
export interface FileCopyParams {
  files: BrkfstFile[];
  baseFileName: string;
  fileType: string;
}
export async function copyFiles({ files, baseFileName, fileType }: FileCopyParams): Promise<BrkfstFile[]> {
  const promises = files.map((file) =>
    apiRequest({
      path: `/file/${file.id}/copy`,
      method: 'POST',
      data: {
        name: formatSubmissionFileName(baseFileName, formatAspectRatio(file.metadata), fileType, file.extension),
        sourceId: file.id,
      },
    }),
  );
  const response = await Promise.all(promises);
  return response.map((res) => res.data);
}

/**
 * Formats the Submission file names according to the naming convention <BaseName>_<AspectRatio>_<FileType>.<FileExtension>
 *
 * <BaseName> - <CreatorInitials><CreatorId>_B<briefId>_M<ModuleNumber>
 *
 * @param baseName
 * @param aspectRatio
 * @param fileType
 * @returns
 */
export function formatSubmissionFileName(
  baseName: string,
  aspectRatio: string,
  fileType: string,
  fileExtension?: string,
) {
  return `${baseName}_${aspectRatio.replace(':', 'x')}_${fileType}.${fileExtension}`;
}

export interface ValidateSelectedFilesParams {
  filesUploading: UploadFile[];
  filesSubmitted: Pick<BrkfstFile, 'id' | 'name' | 'metadata' | 'submissionValidatorFields'>[];
  filesCopied: BrkfstFile[];
}

/**
 * Validates the files that are copied to the collection.
 * @param filesUploading
 * @param filesSubmitted
 * @param filesCopied
 * @returns
 */
export function validateCopiedFiles({ filesUploading, filesSubmitted, filesCopied }: ValidateSelectedFilesParams) {
  const uploadingFileNames = filesUploading
    .filter(({ status }) => status === FileUploadStatus.UPLOADING)
    .map(({ name }) => name);
  const submittedFileNames = filesSubmitted.map(({ name }) => name);
  const allFileNames = new Set([...uploadingFileNames, ...submittedFileNames]);
  const [validFiles, invalidFiles] = partition(filesCopied, (file) => !allFileNames.has(file.name));
  return { validFiles, invalidFiles };
}

interface AspectRatioRecord {
  count: number;
  files: BrkfstFile[];
}

/**
 * Validates the files before copying the assert all files are unique
 *
 * @param files
 * @returns
 */
export function validateSelectedFiles(files: BrkfstFile[]) {
  const validFiles: BrkfstFile[] = [];
  const invalidFiles: BrkfstFile[] = [];
  const aspectRatioCount = new Map<string, AspectRatioRecord>();
  for (const file of files) {
    const aspectRatio = formatAspectRatio(file.metadata);
    const count = aspectRatioCount.get(aspectRatio)?.count || 0;
    const files = aspectRatioCount.get(aspectRatio)?.files || [];
    aspectRatioCount.set(aspectRatio, { count: count + 1, files: files.concat(file) });
  }
  const arRecords: AspectRatioRecord[] = Array.from(aspectRatioCount.values());
  for (const record of arRecords) {
    if (record.count > 1) {
      invalidFiles.push(...record.files);
    } else {
      validFiles.push(...record.files);
    }
  }
  return { validFiles, invalidFiles };
}

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default (top: string, right: string) =>
  css({
    '&.notification-badge': {
      position: 'absolute',
      right,
      top,
      fontSize: '10px',
      color: COLORS.WHITE,
      padding: '0.125rem 0.25rem',
      borderRadius: '1rem',
      lineHeight: '0.75rem',
      background: COLORS.RED500,
      fontWeight: 600,
      minWidth: '1rem',
      textAlign: 'center',
    },
  });

import { createSlice } from '@reduxjs/toolkit';

const reportsSlice = createSlice({
    name: 'reports',
    initialState: [],
    reducers: {
        reportsLoaded: (_, action) => action.payload.data,
        reportAdded: (reports, action) => {
            reports.push(action.payload.data);
        },
        reportRemoved: (reports, action) =>
            reports.filter((report) => report.id !== action.payload.data.id),

        reportEdited: (reports, action) =>
            reports.map((report) =>
                report.id === action.payload.data.id
                    ? { ...report, ...action.payload.data }
                    : report,
            ),
    },
});

export const {
    reportAdded,
    reportsLoaded,
    reportRemoved,
    reportEdited,
} = reportsSlice.actions;

export const getReports = (state) => state.reports;
export const getReport = (reportId) => (state) =>
    state.reports.find((report) => report.id === reportId) || null;
export const getReportDisplayNames = (state) =>
    state.reports.map(({ name, id }) => `Name: ${name} (Id: ${id})`);
export const getReportDisplayName = (reportId) => (state) => {
    const report = state.reports.find(({ id: rId }) => rId === reportId);
    return report && `Name: ${report.name} (Id: ${report.id})`;
};
export const getReportIds = (state) => state.reports.map(({ id }) => id);

export default reportsSlice.reducer;

import React from 'react';
import styled from 'styled-components';
import { Flex } from 'shared/components/display';
import { Box, Text, Button } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { lowerCase, startCase } from 'lodash';
import DropdownWrapper from 'shared/components/atoms/DropdownWrapper';
import { usePerformance } from 'shared/hooks/usePerformance';
import { useContainer } from 'shared/hooks/useContainer';
import { DRAWERS } from 'shared/config/containers';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import filterFormStyles from 'features/performance/FilterFormDropdown/filterFormDropdownStyles';
import cs from 'classnames';
import FacebookFilterForm from 'features/performance/FilterFormDropdown/FacebookFilterForm';
import { FilterValues } from 'features/performance/FilterFormDropdown/config';
import { PlatformShortNames } from 'shared/typings/accountPlatform/enums';
import TiktokFilterForm from 'features/performance/FilterFormDropdown/TiktokFilterForm';

const { FILTER } = DRAWERS;
const { NEUTRAL600, NEUTRAL200 } = COLORS;
const { TEXT_ONLY } = BUTTON_VARIANTS;
const { BODY_SM, SUBHEADING_SM } = TEXT_VARIANTS;

interface Props {
  name: string;
}

const FilterFormDropdown: React.FC<Props> = ({ name }) => {
  const {
    addFilter,
    removeFilter,
    clearFilters,
    performanceState: { filters },
  } = usePerformance();

  const { closeContainer } = useContainer(FILTER);
  const setFiltersCallback = (data: FilterValues) => {
    const idFieldSelected = data.field.value.includes('.id');
    const filter = {
      field: data.field.value,
      operator: idFieldSelected ? data.operatorId.value : data.operatorName?.value,
      value: idFieldSelected ? data.valueId : data.valueName,
    };
    addFilter(filter);
    closeContainer();
  };

  return (
    <Flex css={filterFormStyles} className="filter-form-dropdown">
      <FilterDropdown
        variant={TEXT_ONLY}
        label={FILTER}
        placement="bottom-start"
        clickComponentWidth="100%"
        clickComponent={
          <Flex color={NEUTRAL600}>
            <Text>
              <FontAwesomeIcon icon={faSearch} />
            </Text>
            {filters.map((filter) => (
              <ActiveFilter
                key={filter.field}
                filter={filter}
                onClick={(e) => {
                  e.stopPropagation();
                  removeFilter(filter);
                }}
              />
            ))}
            <Text className="filter-form-dropdown__placeholder">Filter</Text>
          </Flex>
        }
      >
        <Box className="filter-form-dropdown__form-wrapper">
          {name === PlatformShortNames.FACEBOOK ? (
            <FacebookFilterForm onSubmit={setFiltersCallback} />
          ) : (
            <TiktokFilterForm onSubmit={setFiltersCallback} />
          )}
        </Box>
      </FilterDropdown>
      <Button
        className={cs('filter-form-dropdown__clear-btn', {
          'filter-form-dropdown__clear-btn--hide': !filters.length,
        })}
        type="button"
        variant={TEXT_ONLY}
        onClick={clearFilters}
      >
        Clear All Filters
      </Button>
    </Flex>
  );
};

const ActiveFilter = ({ onClick, filter: { field, operator, value } }) => {
  return (
    <Flex className="filter-form-dropdown__active-filter" onClick={onClick} key={field + value}>
      <Text variant={BODY_SM}>{`${startCase(field)} ${lowerCase(operator)}`}</Text>
      <Text variant={SUBHEADING_SM} className="filter-form-dropdown__active-filter__value">
        {Array.isArray(value) ? value.join(', ') : value}
      </Text>
      <Text variant={SUBHEADING_SM} className="filter-form-dropdown__active-filter__icon">
        <FontAwesomeIcon icon={faTimes} size="sm" color={NEUTRAL200} />
      </Text>
    </Flex>
  );
};

const FilterDropdown = styled(DropdownWrapper)`
  width: 100%;
`;

export default FilterFormDropdown;

import { TOAST_VARIANTS } from 'shared/config/toasts/allToasts';

export const TOAST_ACTIVATED = 'TOAST_ACTIVATED';

export const successToastActivated = ({ message, heading = '' }) => {
  return {
    type: TOAST_ACTIVATED,
    payload: {
      message,
      heading,
      variant: TOAST_VARIANTS.SUCCESS,
    },
  };
};

export const errorToastActivated = ({ message, heading = '' }) => {
  return {
    type: TOAST_ACTIVATED,
    payload: {
      message,
      heading,
      variant: TOAST_VARIANTS.ERROR,
    },
  };
};

export const warningToastActivated = ({ message, heading = '' }) => {
  return {
    type: TOAST_ACTIVATED,
    payload: {
      message,
      heading,
      variant: TOAST_VARIANTS.WARNING,
    },
  };
};

export const infoToastActivated = ({ message, heading = '' }) => {
  return {
    type: TOAST_ACTIVATED,
    payload: {
      message,
      heading,
      variant: TOAST_VARIANTS.INFO,
    },
  };
};

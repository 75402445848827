import { css, keyframes } from '@emotion/react';

const scroll = (offset: number) =>
  keyframes({
    from: {
      transform: 'translateX(0%)',
    },
    to: {
      transform: `translateX(-${offset}px)`,
    },
  });

export default (offset: number, pxPerSecondScrollSpeed: number = 100) =>
  css({
    '&.scrolling-text': {
      position: 'relative',
      width: 'auto',
      textOverflow: 'clip',
      overflow: 'hidden',
      '&:hover .scrolling-text__text--scroll': {
        textOverflow: 'unset',
        overflow: 'visible',
        animation: `
          ${scroll(offset)} ${offset / pxPerSecondScrollSpeed}s linear 1
        `,
        animationFillMode: 'forwards',
      },
    },
    '.scrolling-text': {
      '&__text': {
        marginBottom: 0,
        whiteSpace: 'nowrap',
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  });

import React, { useState } from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import styles from './styles';
import { Button } from 'rebass/styled-components';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-solid-svg-icons';
import { SUBMISSION_COPY } from 'shared/config/copy';
import Modal from 'shared/components/molecules/Modal';
import CreatorBriefView from 'features/briefs/components/molecules/CreatorBriefView';
import { Brief } from 'shared/typings/briefs';
import { SIZING } from 'shared/config/formatting';

interface Props {
  brief: Brief;
}
const BriefViewingModal: React.FC<Props> = ({ brief }) => {
  const [open, setOpen] = useState(false);

  const toggleModal = () => setOpen((prev) => !prev);

  return (
    <Box css={styles} className={cs('brief-viewing-modal')}>
      <Button variant={BUTTON_VARIANTS.MICRO} onClick={toggleModal} className="brief-viewing-modal__btn">
        <FontAwesomeIcon icon={faFile} className="fa-fw" />
        {SUBMISSION_COPY.BUTTON_VIEW_BRIEF}
      </Button>
      <Modal
        modalSize={SIZING.LARGE}
        isOpen={open}
        onRequestClose={toggleModal}
        title={brief.name}
        component={
          <CreatorBriefView
            className="brief-viewing-modal__modal-content"
            currentBrief={brief}
            showMessageButton={false}
          />
        }
      />
    </Box>
  );
};

export default BriefViewingModal;

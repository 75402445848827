/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useParams } from 'react-router-dom';

import BriefHeader from 'features/briefs/BriefHeader/BriefHeader';
import BriefIndexHeader from 'features/briefs/BriefIndexHeader/BriefIndexHeader';
import BriefCardsPagination from 'features/briefs/components/BriefCardsPagination/BriefCardsPagination';
import BrandBriefTabs from 'features/briefs/components/organisms/BrandBriefTabs';
import MarketerBriefIndex from 'features/briefs/components/organisms/MarketerBriefIndex';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import { BriefSearchQueryParams } from 'features/briefs/interfaces';
import { getRequestQuery } from 'features/briefs/searchUtility';
import SplitPanelScroll from 'shared/components/layout/SplitPanelScroll/SplitPanelScroll';

import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { useFileNotification } from 'shared/hooks/useFileNotification';
import { usePermissions } from 'shared/hooks/usePermissions';
import { useQuery } from 'shared/hooks/useQuery';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import { useToasts } from 'shared/hooks/useToasts';
import { breakpoints } from 'shared/styles/styleFunctions';
import { Brief } from 'shared/typings/briefs';

type BriefsHomeParams = {
  accountId: string;
  organizationId: string;
};

const defaultParams: BriefSearchQueryParams = {
  page: 1,
  size: 10,
  orderAsc: false,
  orderBy: 'dateUpdated',
  name: '',
  status: -1,
};

const MarketerBriefsHome: React.FC = () => {
  const params = useParams<BriefsHomeParams>();
  const accountId = +params.accountId;
  const organizationId = +params.organizationId;

  const { getQuery, setQuery } = useQuery();
  const query = getQuery();
  useFileNotification();

  const { briefs, loading, totalBriefs, fetchBriefs, shouldRefreshBriefs } = useBriefs();

  const { setWarningToast } = useToasts();

  const { isAuthorized } = usePermissions();

  const authorizedToCreate = isAuthorized(organizationId, PERMISSIONS_MAP.CREATE_EDIT_BRIEF_DRAFT.API);
  const canCreateBrief = authorizedToCreate;

  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });
  /** ********************************************************* */
  /* the selected brief information */
  /** ********************************************************* */
  // default to the first brief
  const briefId: number | null = useMemo(
    () => (query.item ? +query.item : briefs.length > 0 ? briefs[0].id : null),
    [query, briefs],
  );
  // TODO: Fix Types
  // @ts-ignore
  const currentBrief: Brief = useMemo(() => briefs.find((brief) => brief.id === briefId), [briefId, briefs]);

  /** ********************************************************* */

  const searchParams = useSearchParams(query, defaultParams);

  useEffect(() => {
    const briefSelected: boolean = !!currentBrief;
    const briefsExist = briefs.length > 0;
    const queryMatchesSelectedBrief = briefSelected && +query.item === currentBrief.id;
    const { error, ...rest } = query;
    if (!queryMatchesSelectedBrief && briefSelected) {
      /**
       * if the brief is not being set by the query,
       * aka the first brief was selected
       * then replace the item query from the url
       * */
      setQuery({ item: currentBrief.id, ...rest }, true, true);
    } else if (!briefSelected && briefsExist) {
      /**
       * If no brief is selected but there are briefs,
       * then it means the query doesn't match an existing brief
       * so we'll set it to the first brief
       */
      const briefId = briefs[0].id;
      setQuery({ ...rest, item: briefId, subBriefId: briefId, creatorsBriefId: briefId }, true, true);
    }
    if (briefs.length && error) setWarningToast({ message: query.error });
  }, [currentBrief, briefs, query]);

  const refreshBriefs = useCallback(
    (queryParams = {}) => {
      const params = { ...searchParams, ...queryParams };
      const formattedQuery = getRequestQuery(params, accountId, organizationId);
      fetchBriefs(formattedQuery);
    },
    [searchParams, accountId, organizationId],
  );

  useEffect(() => {
    refreshBriefs();
  }, [refreshBriefs]);

  useEffect(() => {
    if (shouldRefreshBriefs) {
      if (totalBriefs && briefs.length === 0) {
        setQuery({ ...query, page: query.page - 1 }, true, true);
      } else if (totalBriefs && briefs.length !== searchParams.size) {
        refreshBriefs();
      }
    }
  }, [shouldRefreshBriefs, refreshBriefs]);

  return (
    <>
      {isMobile ? (
        <BriefCardsPagination
          briefs={briefs}
          totalBriefs={totalBriefs}
          searchParams={searchParams}
          loading={loading}
          canCreateBrief={canCreateBrief}
          organizationId={organizationId}
          accountId={accountId}
        />
      ) : (
        <SplitPanelScroll
          leftHeaderComponent={
            <BriefIndexHeader organizationId={organizationId} accountId={accountId} searchParams={searchParams} />
          }
          leftComponent={
            <MarketerBriefIndex
              loading={loading}
              selectedItem={briefId}
              searchParams={searchParams}
              briefs={briefs}
              totalBriefs={totalBriefs}
            />
          }
          rightHeaderComponent={<BriefHeader currentBrief={currentBrief} titleIsClickable={Boolean(currentBrief)} />}
          rightComponent={<BrandBriefTabs brief={currentBrief} loading={loading} />}
        />
      )}
    </>
  );
};

export default MarketerBriefsHome;

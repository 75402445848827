import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.brief-creators-controls': {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '12px',
    [mediaquery.md]: {
      flexDirection: 'row',
    },
  },
  '.brief-creators-controls': {
    '&__left-wrapper': {
      alignItems: 'center',
    },
    '&__invite-creators, &__display-toggle-btn': {
      width: 'calc(100% - 24px)',
      [mediaquery.md]: {
        width: 'unset',
      },
    },
    '&__display-toggle-btn': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: theme.space[3],
      marginRight: theme.space[2],
      [mediaquery.md]: {
        marginBottom: 0,
      },
    },
    '&__display-toggle-btn svg': {
      marginRight: theme.space[3],
    },
    '&__shipping-download-btn': {
      width: 'auto',
      height: 'auto',
      transform: 'scale(0.85)',
      cursor: 'pointer',
      marginLeft: theme.space[4],
    },
    '&__shipping-download-btn:hover': {
      '.brief-creators-controls__download-icon': {
        '--fa-secondary-color': COLORS.PRIMARY500,
      },
    },
    '&__file-icon': {
      '--fa-stack-z-index': 1,
    },
    '&__download-icon': {
      '--fa-stack-z-index': 2,
      '--fa-primary-color': COLORS.WHITE,
      '--fa-secondary-color': COLORS.NEUTRAL500,
      '--fa-secondary-opacity': '1',
    },
    '&__csv-icon-text': {
      fontSize: '9px',
      color: COLORS.BLACK,
      background: COLORS.WHITE,
      left: 0,
      top: '16px',
    },
  },
});

export const briefCreatorsControlsContentStyles = {
  '&.brief-creators-controls__popover-content': {
    padding: theme.space[4],
    minHeight: '300px',
  },
};

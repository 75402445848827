import isUrl from 'is-url';
import { BaseSelection, Editor, Element, Node, NodeEntry, Range, Transforms } from 'slate';
import { Instance } from 'tippy.js';

import { RICHTEXT_DEFAULT_VALUE } from 'shared/components/Richtext/config';
import { RichTextFormats } from 'shared/components/Richtext/shared/types';
import { checkNodeType, isBlockActive } from 'shared/components/Richtext/shared/utils';

import { LinkElement } from 'shared/typings/slate';

export const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => checkNodeType(n, RichTextFormats.LINK),
  });
};

export const toggleLink = (editor: Editor, tooltipMenu?: Instance) => {
  const isActive = isBlockActive(editor, RichTextFormats.LINK);

  if (!editor.selection) {
    return;
  }

  if (isActive) {
    unwrapLink(editor);
  } else {
    tooltipMenu?.show();
  }
};

const wrapLink = (editor: Editor, url: string) => {
  const isActive = isBlockActive(editor, RichTextFormats.LINK);
  if (isActive) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);

  const link: LinkElement = {
    type: RichTextFormats.LINK,
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    //   @ts-ignore
    Transforms.insertNodes(editor, link, { at: editor.selection });
  } else {
    Transforms.wrapNodes(editor, link, {
      //   @ts-ignore
      at: editor.selection,
      split: true,
    });
    Transforms.collapse(editor, { edge: 'end' });
  }
};

const insertLink = (editor: Editor, url: string) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

export const editLink = (editor: Editor, newLink: string, node: NodeEntry | any[], selection?: BaseSelection) => {
  const [, path] = node;
  // Set properties of nodes at the specified location. If no location is specified, use the selection
  // Try to find the parent node children if it doesn't exist (there is no link) then handle the error which means a link insertion
  try {
    const [parentNode, parentPath] = Editor.parent(editor, path);

    if (parentNode && Array.isArray(parentNode.children)) {
      const linkIndex = parentNode.children.findIndex((child) => checkNodeType(child, RichTextFormats.LINK));
      if (linkIndex !== -1) {
        const linkPath = [...parentPath, linkIndex];
        Transforms.setNodes(
          editor,
          { url: newLink },
          {
            at: linkPath,
          },
        );
      }
    }
  } catch (error) {
    if (!editor.selection && selection) {
      Transforms.select(editor, selection);
    }
    insertLink(editor, newLink);
  }
};

export const withLinks = (editor: Editor & { isLink?: boolean }) => {
  const { apply, insertData, insertText, isInline, normalizeNode } = editor;

  editor.isInline = (element: Element): boolean => {
    return element.type === RichTextFormats.LINK ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.apply = (operation) => {
    if (
      (operation.type === 'insert_node' && operation.node.type === RichTextFormats.LINK) ||
      operation.type === 'set_node'
    ) {
      editor.isLink = true;
    }
    apply(operation);
  };

  editor.insertData = (data) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  editor.normalizeNode = (entry: NodeEntry<Node>) => {
    // https://stackoverflow.com/questions/68511348/unable-to-delete-a-link-clearly-in-slate-js-editor-using-the-official-example
    const [node, path] = entry;
    if (Element.isElement(node) && node.type === 'paragraph') {
      const children = Array.from(Node.children(editor, path));
      for (const [child, childPath] of children) {
        // remove link nodes whose text value is empty string.
        // empty text links happen when you move from link to next line or delete link line.
        if (Element.isElement(child) && child.type === 'link' && child.children[0].text === '') {
          if (children.length === 1) {
            Transforms.removeNodes(editor, { at: path });
            Transforms.insertNodes(editor, RICHTEXT_DEFAULT_VALUE);
          } else {
            Transforms.removeNodes(editor, { at: childPath });
          }
          return;
        }
      }
    }
    normalizeNode(entry);
  };

  return editor;
};

import tileStyles from 'shared/components/atoms/Tile/tileStyles';
import { Box, Flex, Text } from 'shared/components/display';
import { TEXT_VARIANTS } from 'shared/styles/text';
import cs from 'classnames';
import React, { useMemo } from 'react';

interface Props {
  onClick?: (arg?: any) => void;
  backgroundImage?: string;
  label?: string | React.ReactElement;
  overlay?: string | React.ReactElement;
  newTile?: boolean;
  onMouseEnter?: (args: any) => void;
  dataCy?: string;
  size?: string;
  stacked?: boolean;
}

const Tile: React.FC<Props> = ({
  onClick,
  backgroundImage,
  label,
  overlay,
  newTile = false,
  onMouseEnter,
  dataCy,
  size,
  stacked,
}) => {
  const styles = useMemo(() => {
    return tileStyles({ backgroundImage, size });
  }, [backgroundImage, size]);

  return (
    <Flex
      className={cs('tile', {
        'tile--clickable': !!onClick,
        'tile--stacked': stacked,
      })}
      css={styles}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      data-cy={dataCy}
    >
      <Box
        className={cs('tile__background', {
          'tile__background--new-tile': newTile,
        })}
      >
        {overlay ? (
          <Flex className="tile__overlay">
            <Text className="tile__overlay-text" variant={TEXT_VARIANTS.OVERLINE}>
              {overlay}
            </Text>
            <div className="tile__overlay-background" />
          </Flex>
        ) : (
          <></>
        )}
      </Box>
      {stacked && <Box as="span" className="tile__stack" />}
      <Text className={cs('tile__label', 'endWithEllipsis', 'endMultipleRows')}>{label}</Text>
    </Flex>
  );
};

export default Tile;

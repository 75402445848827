import { isString, get } from 'lodash';
import {
  formatISODate,
  parseDatePresets,
  formatComparisonDateRanges,
  formatDateWithSlashes,
} from 'shared/utilities/dateUtility';
import {
  isNumeric,
  humanize,
  formatStringPercent,
  formatCurrency,
  addThousandsSeparators,
} from 'shared/utilities/stringUtility';
import { formatBreakdownParams } from 'shared/utilities/Performance/breakdownUtility';
import DashboardColumn from 'shared/models/DashboardColumnModel';

export const columnSortMethod = (a, b) => {
  if (a === b) return 0;
  const floatA = parseFloat(a);
  const floatB = parseFloat(b);
  if (isNumeric(floatA) || isNumeric(floatB)) {
    return floatA - floatB;
  }
  if (isString(a)) {
    return a.localeCompare(b);
  }
  return a - b;
};

export const formatReportDateRange = (datePreset, startDate, endDate) => {
  if (datePreset) {
    return parseDatePresets(datePreset);
  }
  return {
    from: new Date(formatDateWithSlashes(startDate)), // ISO-dates will be interpreted as UTC, MM/DD/YYYY will be intepreted as local time.
    to: new Date(formatDateWithSlashes(endDate)),
  };
};

export const formatRequestParams = ({
  startDate,
  endDate,
  dateRanges: reportDateRanges,
  datePreset,
  filtering,
  attributionWindow,
  breakdowns,
  fields,
  level,
}) => {
  const dateRange = formatReportDateRange(datePreset, startDate, endDate);
  const dateRanges = reportDateRanges && formatComparisonDateRanges(dateRange);
  return {
    level,
    datePreset,
    startDate: formatISODate(dateRange.from),
    endDate: formatISODate(dateRange.to),
    filtering: JSON.stringify(filtering),
    attributionWindow: JSON.stringify(attributionWindow),
    breakdowns: formatBreakdownParams(breakdowns),
    fields: fields.toString(),
    ...(dateRanges && { dateRanges: JSON.stringify(dateRanges) }),
  };
};

export const formatColumnValue = (value) => {
  if (value.includes('.')) {
    return formatCurrency(value);
  }
  return addThousandsSeparators(value);
};

export const formatColumnObject = (column, summary) => {
  const valueString = get(summary, column) || '0';
  if (column.includes(DashboardColumn.CHANGE_PERCENT)) return formatStringPercent(valueString);

  return formatColumnValue(valueString.toString());
};

export const formatSubComponentValue = (field, metric) => {
  if (metric) {
    if (DashboardColumn.HUMANIZE_FIELDS.includes(field)) {
      return humanize(metric);
    }
    if (isNumeric(field) && !field.includes('id')) {
      return formatColumnValue(String(metric));
    }
  }
  return null;
};

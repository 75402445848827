import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.module-scenes-form': {
    marginTop: theme.space[4],
    maxWidth: '800px',
  },
  '.module-scenes-form': {
    '&__section-header': {
      marginBottom: theme.space[6],
    },
    '&__header': {
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: COLORS.NEUTRAL100,
      borderBottom: `1px solid ${COLORS.NEUTRAL300}`,
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      padding: `0 ${theme.space[5]}`,
    },
    '&__body': {
      padding: theme.space[5],
    },
    '&__label': {
      marginBottom: 0,
    },
    '&__input': {
      maxWidth: '800px',
    },
    '&__description-input': {
      backgroundColor: COLORS.WHITE,
    },
    '&__add-btn': {
      width: '100%',
    },
    '&__delete-btn': {
      color: COLORS.NEUTRAL500,
      paddingRight: '0',
    },
    '&__scene-title-wrapper': {
      alignItems: 'center',
    },
    '&__scene-wrapper': {
      borderRadius: '5px',
      border: `1px solid ${COLORS.NEUTRAL300}`,
      backgroundColor: COLORS.NEUTRAL50,
      marginBottom: theme.space[6],
    },
    '&__scene-error': {
      position: 'relative',
      color: COLORS.RED500,
      fontSize: theme.fontSizes[1],
      top: '2px',
    },
  },
});

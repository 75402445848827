export const AUTH_COPY = {
  // Headings
  HEADING_INVITED_SIGN_UP_WELCOME: "You've been invited!",
  HEADING_SIGN_UP_WELCOME: "Let's Get You Started",
  HEADING_SIGN_IN_WELCOME: 'Sign In',
  HEADING_FORGOT_PASSWORD: 'Uh oh, forgot your password?',
  HEADING_RESET_PASSWORD: "Let's reset your password",
  HEADING_TOKEN_EXPIRED: 'Need a new link?',
  HEADING_SIGN_UP_CONFIRMATION: 'Check your inbox',
  HEADING_RESET_PASSWORD_LINK_CONFIRMATION: 'Reset Link Sent!',

  // Subheadings
  SUBHEADING_INVITED_SIGN_UP_WELCOME: 'Sign up to accept your invitation.',
  SUBHEADING_SIGN_UP_WELCOME: 'Sign up to get access to {appName}.',
  SUBHEADING_SIGN_IN_WELCOME: 'Great to see you again!',
  SUBHEADING_FORGOT_PASSWORD: 'No worries, we can help you reset it.',
  SUBHEADING_TOKEN_EXPIRED: 'Enter your email to resend your {linkType} link',
  SUBHEADING_SIGN_UP_CONFIRMATION: 'Check your email to finish signing up. We sent a confirmation link to:',
  SUBHEADING_RESET_PASSWORD_LINK_CONFIRMATION: 'Instructions for resetting your password have been sent to:',

  // Buttons
  BUTTON_SIGN_IN_AUTH: 'SIGN IN',
  BUTTON_SUBMIT_AUTH: 'SUBMIT',
  BUTTON_CREATE_ACCOUNT_AUTH: 'CREATE ONE',
  BUTTON_GOOGLE_SIGN_IN: 'Sign in with Google',
  BUTTON_FACEBOOK_SIGN_IN: 'Sign in with Facebook',
  BUTTON_GOOGLE_SIGN_UP: 'Continue with Google',
  BUTTON_FACEBOOK_SIGN_UP: 'Continue with Facebook',
  BUTTON_SEND_RESET: 'Send Reset Link',
  BUTTON_REMEMBERED_PASSWORD: 'I remembered my password',
  BUTTON_RESET_PASSWORD: 'Reset Password',

  // Footnotes
  FOOTNOTE_QUESTION_SIGN_UP: `Don't have an account?`,
  FOOTNOTE_QUESTION_SIGN_IN: 'Already have an account?',
  FOOTNOTE_SIGN_IN: `<bold>We’ve updated our master agreement as of July 1, 2024.</bold>{br}By logging in you agree you have read and consented to the master agreement.`,
  FOOTNOTE_SIGN_UP:
    '<bold>We’ve updated our master agreement as of July 1, 2024.</bold> By creating an account you agree you have read and consented to our Privacy Policy, Terms of Use, and Master Service Agreement.',

  // Labels
  LABEL_REMEMBER_ME: 'Remember Me',

  // Links
  LINK_FORGOT_PASSWORD: 'Forgot Password?',
  LINK_SIGN_UP_CONFIRMATION: 'Resend confirmation link.',
  LINK_RESET_PASSWORD_LINK_CONFIRMATION: 'Resend password reset link.',

  // Text
  TEXT_REDIRECT: 'Redirecting...',

  // Buttons
  BUTTON_RESEND: 'Resend Link',

  // Body
  BODY_RESET_PASSWORD_LINK_CONFIRMATION: `If the email address you entered is associated with an account in our system, you'll receive this email within 5 minutes. Be sure to check your spam folder too.`,
};

import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';

export const MAIN_CLASS = 'payout-details-info';
export const CLASSNAMES = {
  label: `${MAIN_CLASS}__label`,
  body: `${MAIN_CLASS}__body`,
};
// @ts-ignore
export default css({
  justifyContent: 'space-between',
  [mediaquery.md]: {
    padding: '0 10px',
  },
  [`.${MAIN_CLASS}`]: {
    '&__label': {
      margin: 0,
    },
    '&__body': {
      marginBottom: '5px',
    },
  },
});

import React from 'react';

import { Box } from 'shared/components/display';

import styles from './styles';

interface Props {
  showFbSafezone?: boolean;
  showTtSafezone?: boolean;
}

const CreativeSafeZone: React.FC<Props> = ({ showFbSafezone, showTtSafezone }) => {
  return (
    <Box css={styles} className="creative-safe-zone">
      {showFbSafezone && (
        <>
          <div className="creative-safe-zone__facebook"></div>
          <div className="creative-safe-zone__facebook2"></div>
          <div className="creative-safe-zone__facebook3"></div>
          <div className="creative-safe-zone__facebook4"></div>
          <div className="creative-safe-zone__facebook5"></div>
        </>
      )}
      {showTtSafezone && (
        <>
          <div className="creative-safe-zone__tiktok"></div>
          <div className="creative-safe-zone__tiktok2"></div>
          <div className="creative-safe-zone__tiktok3"></div>
          <div className="creative-safe-zone__tiktok4"></div>
          <div className="creative-safe-zone__tiktok5"></div>
        </>
      )}
    </Box>
  );
};

export default CreativeSafeZone;

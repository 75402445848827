import React, { useMemo } from 'react';
import cs from 'classnames';
import { Flex } from 'shared/components/display';
import styles from './styles';
import PageSizeSelect from 'shared/components/organisms/Pagination/PageSizeSelect';
import { getSafePage, getVisiblePages } from 'features/performance/components/molecules/PerformancePagination/utils';
import { Button } from 'rebass/styled-components';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faChevronsLeft, faChevronsRight } from '@fortawesome/pro-solid-svg-icons';
import { intl } from 'shared/lib/intl';

interface Props {
  page: number;
  pages: number;
  data: any[];
  total: number;
  onPageChange: (newPage: number) => void;
  showPageSizeOptions?: boolean;
  pageSizeOptions: number[];
  pageSize: number;
  canPrevious?: boolean;
  canNext?: boolean;
  onPageSizeChange: (pageSize: number) => void;
}
const PerformancePagination: React.FC<Props> = ({
  page,
  pages,
  pageSizeOptions,
  pageSize,
  onPageSizeChange,
  data,
  canNext,
  canPrevious,
  onPageChange,
  total,
}) => {
  const { rowMin, rowMax } = useMemo(() => {
    if (data.length > 0) {
      const rowMin = page * pageSize + 1;
      return {
        rowMin,
        rowMax: Math.min(rowMin + data.length - 1, rowMin + pageSize - 1),
      };
    }
    return {
      rowMin: 0,
      rowMax: 0,
    };
  }, [page, pageSize, data.length]);

  const visiblePages = useMemo(() => {
    return getVisiblePages(pages, page);
  }, [page, pages]);

  const changePage = (unsafePage) => {
    const newPage = getSafePage(unsafePage, page, pages);
    if (page !== newPage) {
      onPageChange(newPage);
    }
  };

  const onNextPage = () => {
    if (!canNext) return;
    changePage(page + 1);
  };

  const onPreviousPage = () => {
    if (!canPrevious) return;
    changePage(page - 1);
  };

  const onLastPage = () => {
    if (!canNext) return;
    changePage(pages - 1);
  };

  const onFirstPage = () => {
    if (!canPrevious) return;
    changePage(0);
  };

  //   -pagination class is from ReactTable
  return (
    <Flex css={styles} className={cs('performance-pagination', '-pagination')}>
      <Flex className="performance-pagination__navigation">
        <Button variant={BUTTON_VARIANTS.TEXT_ONLY} disabled={!canPrevious} onClick={onFirstPage}>
          <FontAwesomeIcon icon={faChevronsLeft} size="xs" />
        </Button>
        <Button variant={BUTTON_VARIANTS.TEXT_ONLY} onClick={onPreviousPage} disabled={!canPrevious}>
          <FontAwesomeIcon icon={faChevronLeft} size="xs" />
        </Button>
        {visiblePages.map((visiblePage) => (
          <Button
            key={visiblePage}
            className={cs('performance-pagination__page-button', {
              'performance-pagination__page-button--current': visiblePage === page + 1,
            })}
            variant={BUTTON_VARIANTS.TEXT_ONLY}
            onClick={() => {
              if (visiblePage === page + 1) return;
              changePage(visiblePage - 1);
            }}
            // if the current page is active, disable the button
            disabled={visiblePage === page + 1}
          >
            {visiblePage}
          </Button>
        ))}
        <Button variant={BUTTON_VARIANTS.TEXT_ONLY} onClick={onNextPage} disabled={!canNext}>
          <FontAwesomeIcon icon={faChevronRight} size="xs" />
        </Button>
        <Button variant={BUTTON_VARIANTS.TEXT_ONLY} disabled={!canNext} onClick={onLastPage}>
          <FontAwesomeIcon icon={faChevronsRight} size="xs" />
        </Button>
      </Flex>
      <Flex className="performance-pagination__page-size">
        View{' '}
        <PageSizeSelect
          pageSizeOptions={pageSizeOptions}
          value={pageSize}
          onChange={(option) => onPageSizeChange(option ? option?.value : undefined)}
          width="90px"
        />{' '}
        items per page
      </Flex>
      <Flex className="performance-pagination__item-totals">
        {intl.formatMessage(
          {
            id: 'PAGINATION_DISPLAY',
          },
          {
            rowMax,
            rowMin,
            total,
          },
        )}
      </Flex>
    </Flex>
  );
};

export default PerformancePagination;

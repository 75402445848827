import React, { useEffect, useMemo, useState } from 'react';
import { Image } from 'rebass/styled-components';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Field } from 'formik';
import { FacebookProduct, FacebookProductSet } from './types';
import { SelectInput } from 'shared/components/molecules/Select';
import { lowerCase } from 'lodash';
import { Box, Flex, Text } from 'shared/components/display';
import { useDispatch } from 'react-redux';
import { apiAction } from 'shared/actions/api';
import { API_FACEBOOK_ROUTES } from 'shared/config/routes/api/apiFacebookRoutes';
import { FacebookBusinessAsset } from 'shared/typings/facebookBusinessAsset';

interface Props {
  productCatalog?: FacebookBusinessAsset;
}

export const ProductSetInput: React.FC<Props> = ({ productCatalog }) => {
  const dispatch = useDispatch();

  const [productSets, setProductSets] = useState<FacebookProductSet[]>([]);
  const [products, setProducts] = useState<FacebookProduct[]>([]);

  useEffect(() => {
    if (productCatalog) {
      dispatch(
        apiAction({
          path: {
            route: `${API_FACEBOOK_ROUTES.BUSINESS_ASSETS}/product-catalog/${productCatalog.facebookId}`,
          },
          entity: 'PRODUCT_CATALOG',
          onSuccess: ({ data }) => {
            setProductSets(data);
          },
        }),
      );
    }
    // fetch product sets
  }, [productCatalog]);

  const productSetOptions = useMemo(() => {
    if (productCatalog && productSets)
      return productSets.map(({ name, id, products: prods }) => ({
        label: `${productCatalog.name}: ${name} ${prods ? '' : '(0 products)'}`,
        value: id,
      }));
    return [];
  }, [productCatalog, productSets]);

  const onProductSetChosen = ({ value }) => {
    const productSet = productSets.find(({ id }) => id === value);
    if (productSet && productSet.products) setProducts(productSet.products.data);
  };

  return (
    <Box>
      <Text className="form-container__call-to-action-label" variant={TEXT_VARIANTS.H5}>
        Product Set
      </Text>
      <Field
        name={'productSet'}
        component={SelectInput}
        options={productSetOptions}
        onChange={onProductSetChosen}
        placeholder={'Product Set'}
        width="400px"
      />
      {Boolean(products.length) && (
        <Text className="product-caption" variant={TEXT_VARIANTS.CAPTION}>
          Only shows the first 5 products in the set.
        </Text>
      )}
      <Flex>
        {products.map(({ name, image_url }) => (
          <Flex className="product-row">
            <Image className="product-image" src={image_url} alt={name} />
            <Text variant={TEXT_VARIANTS.BODY} title={name} className="product-text endWithEllipsis">
              {lowerCase(name)}
            </Text>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

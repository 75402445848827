import React from 'react';

import CollectionTileList from 'features/media/components/CollectionTileList';
import { useCollections } from 'features/media/useCollections';
import { useFiles } from 'features/media/useFiles';
import { Box } from 'shared/components/display';
import Pagination from 'shared/components/organisms/Pagination';
import { PaginationSortType, SortOptions } from 'shared/components/molecules/PaginationSort';

import { BrkfstSearchQueryParams } from 'shared/typings/searchQueryParams';

import styles from './styles';

const sortOptions: SortOptions = [
  {
    label: 'Name',
    value: 'name',
    sortType: PaginationSortType.ALPHABET,
  },
  {
    label: 'Creation Date',
    value: 'dateCreated',
    sortType: PaginationSortType.TIME,
  },
];

interface Props {
  collectionParams: BrkfstSearchQueryParams & {
    accountId?: string;
    organizationId?: string;
    userCreated?: string;
    type?: string;
  };
  isCreatorCollection?: boolean;
  header?: JSX.Element | JSX.Element[];
}

const CollectionExplorer: React.FC<Props> = ({ collectionParams, isCreatorCollection = false, header }) => {
  const { collections, totalCollections, createCollection } = useCollections();
  const { changeSelectedCollection, toggleFilesLoading } = useFiles();

  const onSelectCollection = (collectionId) => {
    if (collectionId) {
      toggleFilesLoading(true);
      changeSelectedCollection(collectionId);
    }
  };

  return (
    <Box css={styles} className="collection-explorer">
      <Pagination
        last={header}
        totalAmount={totalCollections}
        searchParams={collectionParams}
        paginationControlProps={{
          sortOptions,
          sortWidth: '210px',
        }}
        sizeParamName="collectionSize"
        pageParamName="collectionPage"
      >
        <CollectionTileList
          data={collections}
          createCollection={createCollection}
          onClick={onSelectCollection}
          creatorCollection={isCreatorCollection}
          showNewCollectionTile
        />
      </Pagination>
    </Box>
  );
};

export default CollectionExplorer;

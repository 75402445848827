import React from 'react';
import { differenceInMonths } from 'date-fns';
import { Field } from 'formik';
import * as yup from 'yup';
import { StepFieldProps } from 'shared/components/forms/MultiStepForm/interfaces';
import { Box, Text } from 'shared/components/display';
import DateInput from 'shared/components/organisms/DateInput';

import { CREATOR_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

interface Props {
  mask?: string;
  placeholder?: string;
}

export const AgeFormFields: React.FC<StepFieldProps & Props> = ({
  mask = '99/99/9999',
  placeholder = 'MM/DD/YYYY',
  className,
}) => {
  return (
    <Box css={styles}>
      <Text className={className} variant={TEXT_VARIANTS.LABEL}>
        {CREATOR_COPY.CAPTION_AGE}
      </Text>
      <Field
        name={AGE_FIELDNAMES.DOB}
        component={DateInput}
        label="Date of birth*"
        dataCy="creator-onboarding__dob"
        mask={mask}
        placeholder={placeholder}
      />
    </Box>
  );
};

export const AGE_FIELDNAMES = {
  DOB: 'dob',
};

export const ageInitialValues = {
  [AGE_FIELDNAMES.DOB]: '',
};

export const ageSchema = yup.object({
  [AGE_FIELDNAMES.DOB]: yup
    .string()
    .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/, 'Date of birth must be in MM/DD/YYYY format')
    .required('Date of birth is required')
    .test({
      name: 'at-least-18',
      test: (dob) => (dob ? differenceInMonths(new Date(), new Date(dob)) >= 18 * 12 : true),
      message: 'Must be at least 18 years old',
    }),
});

export type AgeValues = {
  dob: string;
};

import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export const multiStepFormCss = (numSteps: number) =>
  css({
    '&.multi-step-form': {
      [mediaquery.md]: {
        display: 'grid',
        gridTemplateColumns: '[headers-start] 1fr [content-start] 3fr [content-end]',
        // + 1 to cover confirmation step
        gridTemplateRows: `[start] repeat(${numSteps + 1}, 65px) [end]`,
      },
    },
    '.multi-step-form': {
      '&__btn-wrapper': {
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'row-reverse',
        gap: '16px',
      },
      '&__continue-btn': {
        marginTop: theme.space[4],
      },
      '&__optional-btn': {
        marginTop: theme.space[4],
        backgroundColor: COLORS.PRIMARY100,
        color: COLORS.PRIMARY500,
        padding: '0.5rem .75rem',
        minWidth: '4rem',
      },
    },
  });

export const splitPaneStepHeaderStyles = css({
  '&.step-nav': {
    justifyContent: 'start',
    paddingBottom: '16px',
    flexDirection: 'column',
    display: 'flex',
    gridRow: 'span 1',
    gridColumn: 'headers-start / content-start',
    position: 'relative',
  },
  '.step-nav': {
    '&__icon': {
      alignItems: 'center',
      justifyContent: 'center',
      width: '24px',
      height: '24px',
      backgroundColor: COLORS.NEUTRAL200,
      color: 'white',
      borderRadius: '50%',
      zIndex: zIndex.zForeground,
    },
    '&__icon--active': {
      backgroundColor: COLORS.PRIMARY500,
    },
    '&__icon--completed': {
      backgroundColor: COLORS.PRIMARY500,
    },
    '&__left-border': {
      position: 'absolute',
      width: '1px',
      backgroundColor: COLORS.NEUTRAL500,
      height: '60px',
      left: '11px',
      top: '22px',
      zIndex: zIndex.zBackdrop,
    },
    '&__left-border--completed': {
      backgroundColor: COLORS.PRIMARY500,
    },
    '&__title': {
      marginLeft: '5px',
    },
    '&__title .heading__text': {
      whiteSpace: 'nowrap',
    },
    '&__subtitle': {
      marginLeft: '30px',
      marginBottom: '0',
    },
    '&__title--clickable, &__icon--clickable': {
      cursor: 'pointer',
    },
    '&__prompt-btn': {
      padding: 0,
      textAlign: 'left',
    },
    '&__prompt-btn:disabled': {
      opacity: 1,
    },
    '&__prompt-btn:hover .split-step__edit-icon': {
      color: COLORS.WHITE,
    },
    '&__prompt-btn:hover': {
      color: COLORS.BLACK,
    },
  },
});

export default css({
  '&.split-step': {
    '&__body-content': {
      borderLeft: `1px solid ${COLORS.NEUTRAL200}`,
      marginLeft: '10px',
      marginBottom: '0',
      paddingLeft: '25px',
      gridColumn: 'content-start / content-end',
      gridRow: 'start / end',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      padding: '30px',
    },
    '&__body-content--hidden': {
      display: 'none',
    },
  },
  '.split-step': {
    '&__header': {
      overflowY: 'auto',
      marginBottom: theme.space[4],
    },
    '&__content-wrapper': {
      flexDirection: 'column',
      width: '90%',
      minHeight: '20px',
      marginLeft: '10px',
      paddingLeft: '10px',
      paddingTop: theme.space[2],
      [mediaquery.md]: {
        width: '100%',
      },
    },
    '&__title': {
      overflowY: 'auto',
    },
    '&__optional': {
      color: COLORS.NEUTRAL500,
      textTransform: 'none',
      marginBottom: 0,
    },
    '&__body': {
      flexDirection: 'column',
      height: 'min-content',
      flexWrap: 'nowrap',
      width: '100%',
      marginBottom: theme.space[6],
      [mediaquery.md]: {
        maxWidth: '800px',
      },
    },
    '&__body--confirmation': {
      [mediaquery.md]: {
        maxWidth: 'unset',
      },
    },
  },
});

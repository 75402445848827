import { formatFileTypes } from 'shared/config/fileFormats';
import { MESSAGES } from 'shared/config/validations';
import { intl } from 'shared/lib/intl';

import type { Validator, ValidatorOptions } from './types';
import { getInvalidFilenameCharacters, hasValidMimeTypeOrExtension, hasValidSize, isImage } from './utils';

export class GeneralValidator implements Validator {
  constructor(private supportedFormats: readonly string[]) {
    this.supportedFormats = supportedFormats;
  }

  public async validate({ metadata }: ValidatorOptions) {
    const errorMessages: Array<string> = [];
    const formats = this.supportedFormats;

    const specialChars = getInvalidFilenameCharacters(metadata.name);
    if (specialChars?.length > 0) errorMessages.push(`${MESSAGES.FILE_NAME_ERROR} ${specialChars}`);
    if (!hasValidMimeTypeOrExtension(metadata.mimeType, metadata.name, formats)) {
      const extension = metadata.name.split('.').pop();
      errorMessages.push(
        intl.formatMessage({ id: 'ERROR_UNSUPPORTED_FILE' }, { extension: formatFileTypes(extension) }),
      );
    }
    if (!hasValidSize(metadata.mimeType, metadata.size))
      errorMessages.push(isImage(metadata.mimeType) ? MESSAGES.IMAGE_SIZE_LIMIT : MESSAGES.VIDEO_SIZE_LIMIT);

    return errorMessages;
  }
}

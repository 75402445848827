import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';

import { Flex } from 'shared/components/display';

import COLORS from 'shared/styles/colors';

import styles from './accountHealthScoreStyles';

interface Props {
  score: number;
}

const AccountHealthScore = ({ score }: Props) => {
  const [state, setState] = useState({
    score: score,
    barLength: 0,
  });

  const scoreColor = useMemo(() => {
    if (score < 51) return COLORS.RED500;
    if (score < 57) return COLORS.ORANGE500;
    if (score < 100) return COLORS.GREEN500;
  }, [score]);
  // This is a logic allows to trigger the animation and be able to make the bar only
  // grow the percentage (which is calculated from the score value) needed by swapping
  //  the circleRatio prop with the value one.
  useEffect(() => {
    setState({ ...state, score, barLength: 100 });
  }, [score]);

  return (
    <Flex css={styles}>
      <CircularProgressbar
        className="account-health-score__progress-bar"
        // Swapping value and circleRatio to achieve the desired look
        value={state.barLength}
        circleRatio={state.score / 100}
        strokeWidth={13}
        text={`${state.score}`}
        background={false}
        styles={{
          path: {
            stroke: scoreColor,
            strokeLinecap: 'butt',
            transition: 'stroke-dashoffset 0.5s ease 0s',
          },
          trail: {
            stroke: COLORS.TRANSPARENT,
          },
        }}
      />
    </Flex>
  );
};

export default AccountHealthScore;

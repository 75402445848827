import React from 'react';
import LinkConfirmationTemplate from 'features/auth/LinkConfirmationTemplate';
import { AUTH_COPY } from 'shared/config/copy';

const ResetPasswordLinkConfirmation = () => {
  return (
    <LinkConfirmationTemplate
      heading={AUTH_COPY.HEADING_RESET_PASSWORD_LINK_CONFIRMATION}
      subHeading={AUTH_COPY.SUBHEADING_RESET_PASSWORD_LINK_CONFIRMATION}
      body={AUTH_COPY.BODY_RESET_PASSWORD_LINK_CONFIRMATION}
      linkText={AUTH_COPY.LINK_RESET_PASSWORD_LINK_CONFIRMATION}
      type="reset-password"
    />
  );
};

export default ResetPasswordLinkConfirmation;

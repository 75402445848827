import { faFedex, faUps, faUsps } from '@fortawesome/free-brands-svg-icons';
import { faBoxTaped, IconDefinition } from '@fortawesome/pro-light-svg-icons';

export const getShippingIcon = (shippingLink: string = ''): IconDefinition => {
  if (!shippingLink) {
    return faBoxTaped;
  }
  if (shippingLink.includes('fedex')) {
    return faFedex;
  }
  if (shippingLink.includes('ups')) {
    return faUps;
  }
  if (shippingLink.includes('usps')) {
    return faUsps;
  }
  return faBoxTaped;
};

import React from 'react';
import PromptButton from 'shared/components/molecules/PromptButton';
import { USER_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useUser } from 'features/users/hooks/useUser';
import { UserStatus } from 'shared/typings/user/enums';

interface Props {
  onClick?: () => void;
  variant?: string;
  localStorageKeys?: string[];
}
const ChangeUserTypeButton: React.FC<Props> = ({ onClick, variant, localStorageKeys = [] }) => {
  const { currentUser } = useCurrentUser();
  const { setUserType } = useUser(currentUser.id);

  const resetUserType = () => {
    localStorageKeys.forEach((key) => window.localStorage.removeItem(key));
    setUserType({ type: null, id: currentUser.id, pushFunction: () => '/' });
  };

  if (currentUser.status === UserStatus.INVITED) {
    return <></>;
  }

  return (
    <PromptButton
      variant={variant}
      title={USER_COPY.PROMPT_TITLE_CONFIRM_CHANGE_USER_TYPE}
      text={USER_COPY.PROMPT_CONFIRM_CHANGE_USER_TYPE}
      onConfirm={resetUserType}
      onClick={onClick}
    >
      {USER_COPY.BUTTON_RESET_USER_TYPE}
    </PromptButton>
  );
};

export default ChangeUserTypeButton;

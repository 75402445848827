import React, { useMemo } from 'react';
import cs from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Flex, Text } from 'shared/components/display';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { SelectInput, SelectValue } from 'shared/components/molecules/Select';
import TextInput from 'shared/components/TextInput';
import { COLLECTION_COPY } from 'shared/config/copy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { breakpoints } from 'shared/styles/styleFunctions';
import AsyncCreatorSelect from 'shared/components/molecules/CreatorsSelect/AsyncCreatorSelect';
import { CreatorSelectValue, FetchCreatorOptions } from 'shared/components/molecules/CreatorsSelect/interfaces';
import axiosClient from 'shared/axiosClient';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { API_COLLECTION_ROUTES } from 'shared/config/routes/api/apiCollectionRoutes';
import Popover from 'shared/components/atoms/Popover';
import { Button } from 'rebass/styled-components';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { faArrowDownWideShort, faArrowUpWideShort, faFilter, faFilterList } from '@fortawesome/pro-light-svg-icons';
import { intl } from 'shared/lib/intl';
import { BrkfstSearchQueryParams } from 'shared/typings/searchQueryParams';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import { startCase } from 'lodash';
import COLORS from 'shared/styles/colors';
import styles, { contentStyles } from './styles';
import {
  defaultOption,
  fileTypeOptions,
  FileSearchNames,
  orderByOptions,
  defaultOrderByOption,
  briefOrderByOptions,
  defaultBriefOrderByOption,
} from './config';

export type FileSearchState = {
  search: string;
  fileType: SelectValue;
  creator: SelectValue;
  orderBy: SelectValue;
};

interface Props {
  onSubmit: (values: FileSearchState) => void;
  isSearchable?: boolean;
  onSearchChange?: (value: string, submitForm: (() => Promise<void>) & (() => Promise<any>)) => void;
  loading?: boolean;
  state: Omit<FileSearchState, 'fileType'> & { fileType: string };
  isBriefCollection?: boolean;
  collectionId: number;
  isMarketerCollection?: boolean;
  searchParams: BrkfstSearchQueryParams;
}
const FileSearch: React.FC<Props> = ({
  onSubmit,
  onSearchChange,
  isSearchable = false,
  loading = false,
  isBriefCollection = false,
  state,
  collectionId,
  isMarketerCollection = false,
  searchParams,
}) => {
  const initialValues: FileSearchState = useMemo(() => {
    return {
      fileType: fileTypeOptions.find((option) => option.value === state.fileType) || defaultOption,
      search: state.search || '',
      creator: defaultOption,
      orderBy: isBriefCollection
        ? briefOrderByOptions.find((option) => option.value === state.orderBy) || defaultBriefOrderByOption
        : orderByOptions.find((option) => option.value === state.orderBy) || defaultOrderByOption,
    };
  }, [state]);

  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const handleSubmit = (values, helpers: FormikHelpers<FileSearchState>) => {
    onSubmit(values);
    helpers.setSubmitting(false);
  };

  const onChangeText = (
    event: React.ChangeEvent<HTMLInputElement>,
    submitForm: (() => Promise<void>) & (() => Promise<any>),
  ) => {
    if (onSearchChange) onSearchChange(event.target.value, submitForm);
  };

  const fetchCreatorOptions: FetchCreatorOptions = async (search: string, page: number) => {
    const { data } = await axiosClient.httpRequest<{ data: CreatorSelectValue[]; total: number }>({
      url: LinkCreator.createApiLink({
        route: API_COLLECTION_ROUTES.COLLECTION_CREATORS,
        variables: {
          collectionId,
        },
      }),
      params: {
        search,
        page,
        size: 9,
      },
      method: 'GET',
    });
    return data;
  };

  return (
    <Formik<FileSearchState> initialValues={initialValues} onSubmit={handleSubmit} validateOnBlur={false}>
      {({ submitForm }) => (
        <Form>
          <Flex css={styles} className="file-search">
            {isSearchable && (
              <Flex className="file-search__search-bar">
                <Text className="file-search__icon">
                  <FontAwesomeIcon icon={faSearch} />
                </Text>
                <Field
                  name={FileSearchNames.SEARCH}
                  className="file-search__search-input"
                  component={TextInput}
                  placeholder={COLLECTION_COPY.PLACEHOLDER_SEARCH_ASSETS}
                  onChange={(e) => onChangeText(e, submitForm)}
                />
                {loading && <FontAwesomeIcon icon={faSpinner} className={cs('file-search__spinner', 'fa-spin')} />}
              </Flex>
            )}
            <Popover
              className="file-search__display-toggle-btn"
              ClickComponent={
                <Button className="file-search__display-toggle-btn" variant={BUTTON_VARIANTS.TOOL}>
                  <FontAwesomeIcon icon={faFilterList} />
                  {intl.formatMessage(
                    { id: 'LABEL_FILES_FILTER' },
                    {
                      fileType: startCase(searchParams.fileType),
                      author: startCase(searchParams.author) || 'All',
                    },
                  )}
                </Button>
              }
              allRounded
              contentClassName={cs('file-search__popover-content-wrapper', {
                'file-search__popover-content-wrapper--marketer': isMarketerCollection,
              })}
              contentStyles={contentStyles}
            >
              <DefinitionList>
                <ListDetail text="Sort By">
                  <Field
                    name={FileSearchNames.ORDER_BY}
                    className="file-search__order-by"
                    component={SelectInput}
                    ControlPrefix={
                      <FontAwesomeIcon
                        icon={searchParams.orderAsc ? faArrowUpWideShort : faArrowDownWideShort}
                        color={COLORS.PRIMARY500}
                      />
                    }
                    hidePlaceholder
                    menuPortalTarget="body"
                    onChange={submitForm}
                    options={isBriefCollection ? briefOrderByOptions : orderByOptions}
                    placeholder={'Order By'}
                    width={isMobile ? '100%' : '250px'}
                  />
                </ListDetail>
                <ListDetail text="Filter By">
                  {isMarketerCollection ? (
                    <Field
                      name={FileSearchNames.CREATOR}
                      className="file-search__creator-select"
                      component={AsyncCreatorSelect}
                      ControlPrefix={<FontAwesomeIcon icon={faFilter} color={COLORS.PRIMARY500} />}
                      displayProperty="fullName"
                      fetchCreatorOptions={fetchCreatorOptions}
                      menuPortalTarget="body"
                      onChange={submitForm}
                      placeholder={'Author'}
                      showDefaultOption
                      width={isMobile ? '100%' : '250px'}
                    />
                  ) : (
                    <></>
                  )}
                  <Field
                    name={FileSearchNames.FILE_TYPE}
                    className="file-search__file-type"
                    component={SelectInput}
                    ControlPrefix={<FontAwesomeIcon icon={faFilter} color={COLORS.PRIMARY500} />}
                    menuPortalTarget="body"
                    onChange={submitForm}
                    options={fileTypeOptions}
                    placeholder={'File Type'}
                    width={isMobile ? '100%' : '250px'}
                  />
                </ListDetail>
              </DefinitionList>
            </Popover>

            {/* So that enter key will submit form */}
            <input
              type="submit"
              style={{
                display: 'none',
              }}
            />
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default FileSearch;

import * as Yup from 'yup';

import { getCategoryTagInputSchema } from 'shared/components/molecules/TagInput';
import { richTextSchema } from 'shared/components/Richtext';

import { BRIEF_COPY } from 'shared/config/copy';
import {
  CONSTRAINT_LIMITS,
  FACEBOOK_VIDEO_MAX_DURATION,
  FACEBOOK_VIDEO_MIN_DURATION,
  MESSAGES,
} from 'shared/config/validations';
import { intl } from 'shared/lib/intl';
import { isValidDomain } from 'shared/utilities/stringUtility';

export const BRIEF_TEMPLATE_FIELDNAMES = {
  // Fields
  NAME: 'name',
  BRAND_ASSETS: 'brandAssets',
  FREE_PRODUCT: 'freeProduct',
  WEBSITE: 'website',
  PROMO_CODE: 'promotionalCode',
  DESCRIPTION: 'description',
  MODULES: 'modules',
  AUTO_RESPONSE: 'autoResponse',
  INVITE_ONLY: 'inviteOnly',
  SUBHEADING: 'subHeading',
  ICON: 'icon',
  SHIPPING_REQUIREMENT: 'shippingRequired',
  INSHOP_REQUIREMENT: 'inShopRequired',
  CREATIVE_ONLY: 'creativeOnly',
};

export const schema = Yup.object({
  name: Yup.string()
    .customRequiredString()
    .max(CONSTRAINT_LIMITS.MAX_BRIEF_TITLE_LENGTH)
    .required(BRIEF_COPY.VALIDATION_INCOMPLETE_FIELD)
    .test({
      name: 'name',
      message: BRIEF_COPY.VALIDATION_NAME,
      test(name) {
        if (name) return !name.includes('}');
        return false;
      },
    }),
  brandAssets: Yup.object({
    assets: Yup.array().of(
      Yup.object({
        name: Yup.string(),
        mimeType: Yup.string(),
        id: Yup.number(),
      }),
    ),
    newFiles: Yup.array().of(
      Yup.object({
        name: Yup.string(),
        mimeType: Yup.string(),
      }),
    ),
  }),
  freeProduct: Yup.string(),
  website: Yup.string()
    .nullable()
    .test({
      name: 'domain',
      message: MESSAGES.DOMAIN,
      test(domain) {
        if (domain) return isValidDomain(domain);
        return true;
      },
    }),
  promotionalCode: Yup.string().nullable(),
  description: richTextSchema,
  modules: Yup.array().of(
    Yup.object({
      content: richTextSchema,
      tags: getCategoryTagInputSchema(true),
      fileType: Yup.string(),
      durationRange: Yup.array()
        .of(
          Yup.number()
            .integer(BRIEF_COPY.VALIDATION_DURATION_INTEGER)
            .min(
              FACEBOOK_VIDEO_MIN_DURATION,
              intl.formatMessage(
                { id: 'VALIDATION_MIN_DURATION' },
                {
                  minDuration: FACEBOOK_VIDEO_MIN_DURATION,
                },
              ),
            )
            .max(
              FACEBOOK_VIDEO_MAX_DURATION,
              intl.formatMessage(
                { id: 'VALIDATION_MAX_DURATION' },
                {
                  maxDuration: FACEBOOK_VIDEO_MAX_DURATION,
                },
              ),
            ),
        )
        .test({
          name: 'minMax',
          message: BRIEF_COPY.VALIDATION_DURATION,
          test(value) {
            if (value !== undefined) {
              const [minVal, maxVal] = value;
              return minVal !== undefined && maxVal !== undefined && minVal < maxVal;
            }
            return false;
          },
        }),
      scenes: Yup.array().of(
        Yup.object({
          title: Yup.string(),
          description: richTextSchema,
        }),
      ),
    }),
  ),
  autoResponse: Yup.string().trim(),
  inviteOnly: Yup.boolean(),
  subheading: Yup.string(),
  icon: Yup.string(),
  shippingRequired: Yup.boolean(),
  inShopRequired: Yup.boolean(),
  creativeOnly: Yup.boolean(),
});

import { DEFAULT_VALUE, trendFileInputSchema } from 'features/trends/components/molecules/TrendFileInput/config';
import * as yup from 'yup';

export const initialValues: TrendFormValues = {
  name: '',
  file: DEFAULT_VALUE,
};

export const getValidationSchema = (trendNames: string[]) =>
  yup.object({
    name: yup
      .string()
      .customRequiredString()
      .test({
        name: 'unique',
        message: 'Trend name must be unique',
        test(val) {
          return !trendNames.includes(val);
        },
      }),
    file: trendFileInputSchema,
  });

export interface TrendFormValues {
  name: string;
  file: {
    id: number;
    name: string;
  };
}

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.brief-view': {
    backgroundColor: COLORS.WHITE,
    height: '100%',
    flex: 1,
    overflowY: 'scroll',
  },
  '.brief-view': {
    '&__content': {
      padding: theme.space[4],
    },
    '&__banner-wrapper': {
      position: 'relative',
    },
    '&__invite-only': {
      position: 'absolute',
      top: '5px',
      right: '5px',
    },
    '&__invite-only .chip': {
      margin: theme.space[1],
    },
  },
});

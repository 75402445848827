/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
// The 3 lines above are necessary because we're using the css prop on a native html element
import cs from 'classnames';
import React from 'react';
import textareaStyles, { BASE_CLASS, MODIFIERS } from './styles';

type StyleProps = {
  noMargin?: boolean;
  noBorder?: boolean;
  allowVerticalResize?: boolean;
};

type TextareaProps = JSX.IntrinsicElements['textarea'];

const Textarea = React.forwardRef<HTMLTextAreaElement, StyleProps & TextareaProps>(
  ({ className, noBorder, noMargin, allowVerticalResize, ...props }, ref) => (
    <textarea
      {...props}
      ref={ref}
      // @ts-ignore
      css={textareaStyles}
      className={cs(className, {
        [BASE_CLASS]: true,
        [BASE_CLASS.concat(MODIFIERS.NO_BORDER)]: noBorder,
        [BASE_CLASS.concat(MODIFIERS.NO_MARGIN)]: noMargin,
        [BASE_CLASS.concat(MODIFIERS.V_RESIZE)]: allowVerticalResize,
      })}
    />
  ),
);

export default Textarea;

import React from 'react';
import { useParams } from 'react-router-dom';

import BasicAccountSettings from 'features/accounts/components/organisms/BasicAccountSettings';
import SuperadminAccountSettings from 'features/accounts/components/organisms/SuperadminAccountSettings';
import { useBrandFilesNotification } from 'features/accounts/hooks/useBrandFilesNotification';
import { Box } from 'shared/components/display';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';

import styles from './styles';

const AccountSettings: React.FC = () => {
  const params = useParams<any>();
  const organizationId = +params.organizationId;
  const { isSuperadmin } = useCurrentUser();

  useBrandFilesNotification();

  return (
    <Box css={styles} className="account-settings">
      <BasicAccountSettings accountId={params.accountId} organizationId={organizationId} />
      {isSuperadmin && <SuperadminAccountSettings accountId={params.accountId} />}
    </Box>
  );
};

export default AccountSettings;

import React from 'react';
import * as yup from 'yup';
import RangeSlider, { RangeTheme } from 'shared/components/organisms/RangeSlider';
import { Input } from 'shared/lib/formik';
import { MESSAGES } from 'shared/config/validations';

interface Props {
  min: number;
  max: number;
  hideTicks?: boolean;
  theme?: RangeTheme;
  valueDisplayMap?: Record<number, string>;
}
const RangeInput: Input<number[], Props> = ({
  field: { name, value, onChange: formikOnChange, onBlur: formikOnBlur },
  min,
  max,
  hideTicks,
  theme,
  valueDisplayMap,
}) => {
  const handleChange = (value: number[]) => {
    formikOnChange({ target: { name, value } });
    formikOnBlur({ target: { name } });
  };

  return (
    <RangeSlider
      onChange={handleChange}
      values={value}
      min={min}
      max={max}
      hideTicks={hideTicks}
      theme={theme}
      valueDisplayMap={valueDisplayMap}
    />
  );
};

export const rangeValidationSchema = (min: number, max: number) =>
  yup
    .array(
      yup
        .number()
        .min(min, `Please enter a duration greater than ${min}`)
        .max(max, `Please enter a duration less than ${max}`),
    )
    .test({
      name: 'minMax',
      message: MESSAGES.RANGE_MIN_MAX,
      test(value) {
        if (value !== undefined) {
          const [minVal, maxVal] = value;
          return minVal !== undefined && maxVal !== undefined && minVal < maxVal;
        }
        return false;
      },
    });

export default RangeInput;

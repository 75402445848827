import { VIDEO_COMPOSITION_COPY } from 'features/media/pages/VideoComposition/copy';
import { TRENDS_LIBRARY_COPY } from 'features/trends/pages/TrendsLibrary/copy';
import { ACCOUNT_COPY } from 'shared/config/copy/accountCopy';
import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy/accountPlatformCopy';
import { ADDRESS_COPY } from 'shared/config/copy/addressCopy';
import { AUTH_COPY } from 'shared/config/copy/authCopy';
import { BRIEF_COPY } from 'shared/config/copy/briefCopy';
import { CARD_COPY } from 'shared/config/copy/cardCopy';
import { COLLECTION_COPY } from 'shared/config/copy/collectionCopy';
import { CONFIGURATION_COPY } from 'shared/config/copy/configurationCopy';
import { CREATOR_COPY } from 'shared/config/copy/creatorCopy';
import { EARNING_COPY } from 'shared/config/copy/earningCopy';
import { FACEBOOK_COPY } from 'shared/config/copy/facebookCopy';
import { FILE_COPY } from 'shared/config/copy/fileCopy';
import { FORM_COPY } from 'shared/config/copy/formCopy';
import { GENERIC_COPY } from 'shared/config/copy/genericCopy';
import { INVOICE_COPY } from 'shared/config/copy/invoiceCopy';
import { MESSAGE_COPY } from 'shared/config/copy/messageCopy';
import { ORGANIZATION_COPY } from 'shared/config/copy/organizationCopy';
import { ORGANIZATION_USER_COPY } from 'shared/config/copy/organizationUserCopy';
import { REPORT_COPY } from 'shared/config/copy/reportCopy';
import { ROLE_COPY } from 'shared/config/copy/roleCopy';
import { STRIPE_COPY } from 'shared/config/copy/stripeCopy';
import { SUBMISSION_COPY } from 'shared/config/copy/submissionCopy';
import { TAG_COPY } from 'shared/config/copy/tagCopy';
import { TASK_COPY } from 'shared/config/copy/taskCopy';
import { USER_COPY } from 'shared/config/copy/userCopy';

import { BRKFST_TOASTS_COPY } from '../toasts/allToasts';

import { BRKFST_COPY } from '.';

export { GENERIC_COPY } from 'shared/config/copy/genericCopy';

export const GLOBAL_COPY_VARIABLES = {
  // Branding
  APP_NAME: 'BRKFST',

  // Files
  MAX_PERMS_LIMIT: 15,
  NEW_LINE: '<br>',
};

export const ALL_COPY = {
  ACCOUNT_COPY,
  ACCOUNT_PLATFORM_COPY,
  ADDRESS_COPY,
  AUTH_COPY,
  BRIEF_COPY,
  CARD_COPY,
  COLLECTION_COPY,
  CONFIGURATION_COPY,
  CREATOR_COPY,
  EARNING_COPY,
  FILE_COPY,
  FACEBOOK_COPY,
  FORM_COPY,
  GENERIC_COPY,
  INVOICE_COPY,
  MESSAGE_COPY,
  ORGANIZATION_COPY,
  ORGANIZATION_USER_COPY,
  REPORT_COPY,
  ROLE_COPY,
  STRIPE_COPY,
  SUBMISSION_COPY,
  TAG_COPY,
  TASK_COPY,
  USER_COPY,
  VIDEO_COMPOSITION_COPY,
  TRENDS_LIBRARY_COPY,
};

export const FULL_COPY = {
  ...BRKFST_COPY,
  ...BRKFST_TOASTS_COPY,
};

import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import BrandSideBar from 'features/home/components/organisms/BrandSideBar';

import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';

const AccountWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const ContentWrapper = styled.div`
  overflow-y: auto;
  flex: 1;
`;

interface Props {
  loading: boolean;
}

const AccountRoutesLayout: React.FC<Props> = ({ loading }) => {
  return (
    <AccountWrapper>
      <BrandSideBar />
      <ContentWrapper id="account-content">{loading ? <CenteredSpinnerContainer /> : <Outlet />}</ContentWrapper>
    </AccountWrapper>
  );
};

export default AccountRoutesLayout;

import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import FileLightboxFooter from 'features/media/components/molecules/FileLightboxFooter';
import { GalleryItem } from 'features/media/interfaces/gallery';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import ProfilePanel from 'features/users/components/molecules/ProfilePanel';
import { useShowcaseAssets } from 'features/users/hooks/useShowcaseAssets';
import { Box, Flex } from 'shared/components/display';

import { CREATOR_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { LinkCreator } from 'shared/utilities/linkUtility';
import GridGallery from 'features/media/components/organisms/GridGallery';

import styles from './styles';

interface Props {
  userId: number;
}
const ApprovedCreatives: React.FC<Props> = ({ userId }) => {
  const { currentUser } = useCurrentUser();
  const {
    getCreatorApprovedAssets,
    files,
    clearAssets,
    loading,
    pinnedLoading,
    toggleLoading,
    loadPinnedCount,
    totalFiles,
  } = useShowcaseAssets();
  const showcaseLoading = loading || pinnedLoading;
  const navigate = useNavigate();
  useEffect(() => {
    if (userId) {
      getCreatorApprovedAssets(userId, {
        page: 1,
        size: 15,
      });
    }
    loadPinnedCount(userId);
    return () => {
      clearAssets();
      toggleLoading(true);
    };
  }, [userId]);

  const galleryItems: GalleryItem[] = useMemo(() => {
    return files.map(
      (file): GalleryItem => ({
        itemObject: file,
        captionData: { ...file, name: file.brandName },
      }),
    );
  }, [files]);

  const linkToApprovedAssets = LinkCreator.createLink({
    userType: currentUser.type,
    routeKey: 'CREATOR_APPROVED_ASSETS',
    variables: { userId },
  });

  const onShowMore = () => {
    navigate(linkToApprovedAssets);
  };

  return (
    <Box css={styles} className="approved-creatives">
      <ProfilePanel
        HeaderComponent={
          <Flex className="approved-creatives__header">
            {CREATOR_COPY.HEADER_APPROVED_ASSETS}
            <Link
              to={LinkCreator.createLink({
                userType: currentUser.type,
                routeKey: 'CREATOR_APPROVED_ASSETS',
                variables: { userId },
              })}
            >
              {CREATOR_COPY.LINK_APPROVED_ASSETS}
            </Link>
          </Flex>
        }
        className="approved-creatives__profile-panel"
      >
        <>
          {showcaseLoading ? (
            <CenteredSpinner />
          ) : (
            <GridGallery
              items={galleryItems}
              LightboxCaption={FileLightboxFooter}
              withShowMore={totalFiles > galleryItems.length}
              onShowMore={onShowMore}
              showMoreCopy={CREATOR_COPY.LINK_APPROVED_ASSETS}
              numRows={galleryItems.length > 7 ? 2 : 1}
              useBrandName
              showPin
              renderLightbox
            />
          )}
        </>
      </ProfilePanel>
    </Box>
  );
};

export default ApprovedCreatives;

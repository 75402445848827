import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { zIndex } from 'shared/config';
import SHADOWS from 'shared/styles/shadow';

export default (width?: string | number, maxHeight?: string | number, additionalStyles?: any) =>
  css({
    '&.brkfst-popover__content-wrapper': {
      width,
      zIndex: zIndex.zDropdownPortal,
      backgroundColor: COLORS.WHITE,
      boxShadow: SHADOWS.SHADOW04,
      overflowY: 'auto',
      maxHeight,
      whiteSpace: 'normal',
      '&--all-rounded': {
        borderRadius: '5px',
      },
      '&--bottom-end, &--bottom-start': {
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
      },
      '&--top-end, &--top-start': {
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
      },
    },
    ...additionalStyles,
  });

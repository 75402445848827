// * Lib
import React from 'react';
import { TailSpin } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
//* Display/UI
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import config from 'config';

import { useUser } from 'features/users/hooks/useUser';
import { Flex, Text } from 'shared/components/display';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
// * Hooks
import { useQuery } from 'shared/hooks/useQuery';
import COLORS from 'shared/styles/colors';
// * Styles
import { TEXT_VARIANTS } from 'shared/styles/text';
import { OAuthState } from 'shared/typings/user';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';

interface Props {
  userId: number;
  hasSocialProfile: boolean;
  redirectURI: string;
  disabled?: boolean;
  userHandle?: string;
}

export function getIgLogin({ userId, redirectURI }: OAuthState) {
  const jsonState: any = {
    userId,
    redirectURI,
  };

  const state = JSON.stringify(jsonState);
  const url = `https://api.instagram.com/oauth/authorize?client_id=${config.IG_APP_ID}&redirect_uri=${config.API_URL}${config.IG_CALLBACK_URL}&state=${state}&scope=${config.IG_SCOPE}&response_type=code`;
  window.location.assign(url);
}

const NewInstagramConnect: React.FC<Props> = ({ userId, hasSocialProfile, redirectURI, userHandle }) => {
  const { getQuery } = useQuery();
  const { igError } = getQuery();
  const { disconnectInstagram, disconnectingInstagram } = useUser(userId);
  const { userType } = useCurrentUser();

  const linkToUser = LinkCreator.createLink({
    routeKey: 'USER_PROFILE',
    variables: {
      userId,
    },
    userType,
  });

  return (
    <Flex css={styles} className="new-instagram-connect">
      {!hasSocialProfile ? (
        <Flex className="new-instagram-connect__ig-wrapper" onClick={() => getIgLogin({ userId, redirectURI })}>
          <FontAwesomeIcon
            icon={faInstagram}
            className="new-instagram-connect__ig-icon"
            fixedWidth
            color={COLORS.WHITE}
          />

          {igError ? (
            <Text variant={TEXT_VARIANTS.BODY} className="new-instagram-connect__error-message">
              {igError}
            </Text>
          ) : (
            <Flex className="new-instagram-connect__ig-text">
              <Text variant={TEXT_VARIANTS.H6} className="new-instagram-connect__connect-message">
                Connect to Instagram
              </Text>
            </Flex>
          )}
        </Flex>
      ) : (
        <Flex className="new-instagram-connect__connected-wrapper">
          <Link className="new-instagram-connect__connected" to={linkToUser}>{`@${userHandle}`}</Link>
          <Flex
            className={classNames('new-instagram-connect__disconnect-wrapper', {
              'new-instagram-connect__disconnect-wrapper--disabled': disconnectingInstagram,
            })}
            onClick={() => {
              !disconnectingInstagram ? disconnectInstagram() : null;
            }}
          >
            <FontAwesomeIcon className="new-instagram-connect__disconnect-icon" icon={faX} color={COLORS.NEUTRAL400} />
            <Text
              color={COLORS.NEUTRAL300}
              variant={TEXT_VARIANTS.BODY_SM}
              className="new-instagram-connect__disconnect"
            >
              Disconnect
            </Text>
            <TailSpin
              visible={disconnectingInstagram}
              color={COLORS.PRIMARY300}
              height="1rem"
              width="1rem"
              wrapperClass="new-instagram-connect__disconnecting-spinner"
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default NewInstagramConnect;

import { ACCOUNT_PLATFORM_TOASTS } from 'shared/config/toasts/accountPlatformToasts';
import { ACCOUNT_TOASTS } from 'shared/config/toasts/accountToasts';
import { AUTH_TOASTS } from 'shared/config/toasts/authToasts';
import { BRIEF_TOASTS } from 'shared/config/toasts/briefToasts';
import { CARD_TOASTS } from 'shared/config/toasts/cardToasts';
import { COLLECTION_TOASTS } from 'shared/config/toasts/collectionToasts';
import { CONFIGURATION_TOASTS } from 'shared/config/toasts/configurationToasts';
import { CREATOR_TOASTS } from 'shared/config/toasts/creatorToasts';
import { FACEBOOK_TOASTS } from 'shared/config/toasts/facebookToasts';
import { FILE_TOASTS } from 'shared/config/toasts/fileToasts';
import { GENERIC_TOASTS } from 'shared/config/toasts/genericToasts';
import { MESSAGE_TOASTS } from 'shared/config/toasts/messageToasts';
import { ORGANIZATION_TOASTS } from 'shared/config/toasts/organizationToasts';
import { ORGANIZATION_USER_TOASTS } from 'shared/config/toasts/orgUserToasts';
import { REPORT_TOASTS } from 'shared/config/toasts/reportToasts';
import { ROLE_TOASTS } from 'shared/config/toasts/roleToasts';
import { SUBMISSION_TOASTS } from 'shared/config/toasts/submissionToasts';
import { USER_TOASTS } from 'shared/config/toasts/userToasts';

export { GENERIC_TOASTS } from 'shared/config/toasts/genericToasts';

export const TOAST_VARIANTS = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
};

export const ALL_TOASTS = {
  ACCOUNT_PLATFORM_TOASTS,
  ACCOUNT_TOASTS,
  AUTH_TOASTS,
  BRIEF_TOASTS,
  CARD_TOASTS,
  COLLECTION_TOASTS,
  CONFIGURATION_TOASTS,
  CREATOR_TOASTS,
  FACEBOOK_TOASTS,
  FILE_TOASTS,
  GENERIC_TOASTS,
  MESSAGE_TOASTS,
  ORGANIZATION_TOASTS,
  ORGANIZATION_USER_TOASTS,
  REPORT_TOASTS,
  ROLE_TOASTS,
  SUBMISSION_TOASTS,
  USER_TOASTS,
};

export const BRKFST_TOASTS_COPY = {
  ...ACCOUNT_PLATFORM_TOASTS,
  ...ACCOUNT_TOASTS,
  ...AUTH_TOASTS,
  ...BRIEF_TOASTS,
  ...CARD_TOASTS,
  ...COLLECTION_TOASTS,
  ...CONFIGURATION_TOASTS,
  ...CREATOR_TOASTS,
  ...FACEBOOK_TOASTS,
  ...FILE_TOASTS,
  ...GENERIC_TOASTS,
  ...MESSAGE_TOASTS,
  ...ORGANIZATION_TOASTS,
  ...ORGANIZATION_USER_TOASTS,
  ...ROLE_TOASTS,
  ...SUBMISSION_TOASTS,
  ...USER_TOASTS,
};

import React, { Fragment, SyntheticEvent, useMemo, useState } from 'react';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Flex, Text } from 'shared/components/display';
import { CarouselItem } from 'shared/components/molecules/CarouselItem';
import ModuleReviewFooter from 'shared/components/molecules/ModuleReviewFooter';
import { Lightbox } from 'shared/components/organisms/Lightbox';
import { HandleCriticalErrors } from 'shared/components/organisms/ModuleReview/ModuleReview';
import { moduleReviewContentStyles } from 'shared/components/organisms/ModuleReview/moduleReviewStyles';
import { getFileToDisplay } from 'shared/components/organisms/ModuleReview/util';

import { RevisionStatus } from 'shared/typings/revisions';
import { ReviewModule } from 'shared/typings/submissions';
import { convertWidthNum } from 'shared/utilities/moduleReviewUtility';

import moduleReviewLightboxStyles from './styles';

interface Props {
  onChange?: (index: number) => void;
  isOpen: boolean;
  onRequestClose: () => void;
  currentModule: number;
  modules: ReviewModule[];
  handleApproveModule: (id: number, hasTodoRevision: boolean) => void;
  handleCriticalErrors: HandleCriticalErrors;
  briefName: string;
  brandName: string;
  approvalLoading: boolean;
  enableModuleComments?: boolean;
  // to override logic of which file to display
  displayAssetId?: number | null;
}
const ModuleReviewLightbox: React.FC<Props> = ({
  onChange,
  isOpen,
  onRequestClose,
  currentModule,
  modules,
  handleApproveModule,
  handleCriticalErrors,
  briefName,
  brandName,
  approvalLoading,
  enableModuleComments,
  displayAssetId,
}) => {
  const [openRevision, setOpenRevision] = useState<boolean>(false);
  const [openComments, setOpenComments] = useState<boolean>(false);
  const [mediaWidth, setMediaWidth] = useState<number>(0);

  const closeLightbox = () => {
    setOpenRevision(false);
    setOpenComments(false);
    onRequestClose();
  };

  const onClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setOpenRevision(false);
    setOpenComments(false);
  };

  const widthStyle = useMemo(() => {
    //Set the width to match the video or img that the modal is overlayed on
    const width = convertWidthNum(mediaWidth, 10);
    return { width: width };
  }, [mediaWidth]);

  const onLightboxChange = (index: number) => {
    setOpenRevision(false);
    setOpenComments(false);
    if (onChange) onChange(index);
  };

  return (
    <Lightbox
      ariaLabel={'Module Review For Brief: ' + briefName}
      onChange={onLightboxChange}
      selectedItemIndex={currentModule}
      isOpen={isOpen}
      onRequestClose={closeLightbox}
      contentStyles={moduleReviewContentStyles}
      dataCy="module-review-lightbox"
    >
      {modules.map((mod, index) => {
        const { files, id, moduleNum, creatorName, submissionId, revisions, creatorId } = mod;
        if (files.length === 0) {
          return <Fragment key="no-files" />;
        }
        if (approvalLoading) {
          return <CenteredSpinner key="loading" />;
        }
        const file = getFileToDisplay(files, displayAssetId);
        const hasTodoRevision = Boolean(revisions?.some((rev) => rev.status === RevisionStatus.DOING));
        return index === currentModule ? (
          <CarouselItem
            name={`Module ${moduleNum}`}
            mimeType={file.mimeType}
            metadata={file.metadata}
            thumbnailUrl={file.thumbnailUrl}
            url={file.url}
            key={file.id}
            className="lightbox__content__item"
            setMediaWidth={setMediaWidth}
            onClick={onClick}
            Header={
              <Flex css={moduleReviewLightboxStyles} style={widthStyle} className="carousel-header__spacing">
                <Text className="carousel-header__brief-title">{briefName}</Text>
                <Text className="carousel-header__creator-name" data-cy="carousel-header__creator-name">
                  {creatorName}
                </Text>
              </Flex>
            }
            Footer={
              <ModuleReviewFooter
                numModulesRemaining={modules.length}
                handleApproveModule={() => handleApproveModule(id, hasTodoRevision)}
                handleCriticalErrors={(args) => handleCriticalErrors({ ...args, submissionModuleId: id, creatorId })}
                approvalLoading={approvalLoading}
                brandName={brandName}
                mediaWidth={mediaWidth}
                mod={mod}
                openComments={openComments}
                openRevision={openRevision}
                setOpenComments={setOpenComments}
                setOpenRevision={setOpenRevision}
                enableModuleComments={enableModuleComments}
              />
            }
          />
        ) : (
          <Fragment key={submissionId.toString()}></Fragment>
        );
      })}
    </Lightbox>
  );
};

export default ModuleReviewLightbox;

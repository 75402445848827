import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CreateReportModalForm from 'features/accounts/components/organisms/CreateReportModalForm/CreateReportModalForm';
import { useReport } from 'features/accounts/reports/useReport';
import Dropdown from 'shared/components/molecules/Dropdown';

import { usePerformance } from 'shared/hooks/usePerformance';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { STYLES } from 'shared/styles/styles';
import { LinkCreator } from 'shared/utilities/linkUtility';

import ReportButton from 'features/accounts/components/atoms/ReportButton';

const { MAX_CONTENT } = STYLES;
const { TOOL } = BUTTON_VARIANTS;

interface Props {
  reportId?: number;
  accountId: number;
  accountPlatformId: number;
}

const ReportPanel: React.FC<Props> = ({ reportId, accountId, accountPlatformId }) => {
  const navigate = useNavigate();
  const { organizationId } = useParams<{ organizationId: string }>();
  const { deleteReport, reports, report } = useReport({
    reportId,
  });
  const { setDashboardFieldsFromReport } = usePerformance();

  const onClickReport = (clickedReport) => {
    setDashboardFieldsFromReport(clickedReport);
    const path = LinkCreator.createLink({
      routeKey: 'REPORT',
      variables: {
        reportId: clickedReport.id,
        organizationId,
        accountId,
        accountPlatformId,
      },
    });
    navigate({ pathname: path }, { replace: true });
  };

  return (
    <Dropdown
      ClickComponent={
        <Button type="button" variant={TOOL} width={MAX_CONTENT}>
          <FontAwesomeIcon icon={faLayerGroup} />
          <span>
            Reports
            {report && `: ${report.name}`}
          </span>
        </Button>
      }
      Footer={<CreateReportModalForm accountId={accountId} accountPlatformId={accountPlatformId} />}
      placement="bottom-start"
      width="200px"
      offset={{
        mainAxis: 12,
        crossAxis: 0,
      }}
      allRounded
    >
      {reports.map((report) => (
        <ReportButton
          key={report.id}
          onSelectReport={() => onClickReport(report)}
          report={report}
          accountId={accountId}
          onDeleteReport={deleteReport}
          selected={reportId === report.id}
        />
      ))}
    </Dropdown>
  );
};

export default ReportPanel;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import cs from 'classnames';
import { Field, Form, Formik, FormikHelpers } from 'formik';

import { SELECT_ALL, SELECT_ALL_OPTION } from 'features/briefs/components/BriefForm/BriefForm';
import briefFormStyles from 'features/briefs/components/BriefForm/briefFormStyles';
import { BriefModulesForm } from 'features/briefs/components/BriefForm/components/BriefModulesForm/BriefModulesForm';
import BriefAssetsInput from 'features/briefs/components/molecules/BriefAssetsInput/BriefAssetsInput';
import { getStates, statesLoaded } from 'features/users/hooks/states.slice';
import { Box, Flex } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import FormikErrorFocus from 'shared/components/forms/FormikErrorFocus';
import Heading from 'shared/components/molecules/Heading';
import { SelectInput } from 'shared/components/molecules/Select';
import RichtextInput from 'shared/components/Richtext/RichtextInput';
import TextareaInput from 'shared/components/TextareaInput/TextareaInput';
import TextInput from 'shared/components/TextInput';
import ToggleInput from 'shared/components/ToggleInput';

import { apiAction } from 'shared/actions/api';
import { BRIEF_COPY } from 'shared/config/copy';
import { API_USER_ROUTES } from 'shared/config/routes/api/apiUserRoutes';
import { CONSTRAINT_LIMITS } from 'shared/config/validations';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { breakpoints } from 'shared/styles/styleFunctions';

import { FormBriefTemplate } from './types';
import { onToggleInshop } from './util';
import { BRIEF_TEMPLATE_FIELDNAMES, schema } from './validation';

const MAX_FREE_PRODUCT_LENGTH = 15;

interface Props {
  defaultValues: FormBriefTemplate;
  onSaveTemplate: (values: FormBriefTemplate) => void;
}

const BriefTemplateForm: React.FC<Props> = ({ onSaveTemplate = () => null, defaultValues }) => {
  const states = useSelector(getStates);
  const [showSelectAll, setSelectAll] = useState(true);
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md - 1 });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!states.length)
      dispatch(
        apiAction({
          path: {
            route: API_USER_ROUTES.STATES,
          },
          params: {
            countryCode: 'US',
          },
          entity: 'state',
          successAction: statesLoaded,
        }),
      );
  }, [dispatch, states]);

  const handleSubmit = (values, actions: FormikHelpers<FormBriefTemplate>) => {
    onSaveTemplate(values);
    actions.setSubmitting(false);
  };

  const stateOptions = useMemo(() => {
    const stateOptions = states.map((state) => ({ value: state, label: state.code || '' }));
    if (showSelectAll) return [SELECT_ALL_OPTION].concat(stateOptions);
    else return stateOptions;
  }, [states, showSelectAll]);

  return (
    <Box className="brief-form" css={briefFormStyles}>
      <Formik<FormBriefTemplate>
        initialValues={defaultValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        validateOnChange={true}
      >
        {(formikProps) => (
          <Form>
            <Heading text={BRIEF_COPY.FORM_NAME_HEADING} className="brief-form__header" />
            <Field
              name={BRIEF_TEMPLATE_FIELDNAMES.NAME}
              component={TextInput}
              maxLength={CONSTRAINT_LIMITS.MAX_BRIEF_TITLE_LENGTH}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_NAME}
              label={BRIEF_COPY.LABEL_NAME_BRIEF + '*'}
            />
            <Field name={BRIEF_TEMPLATE_FIELDNAMES.BRAND_ASSETS} component={BriefAssetsInput} />
            <Heading text={BRIEF_COPY.FORM_PRODUCT_HEADING} className="brief-form__header" />
            <Field
              name={BRIEF_TEMPLATE_FIELDNAMES.FREE_PRODUCT}
              component={TextInput}
              maxLength={MAX_FREE_PRODUCT_LENGTH}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_FREE_PRODUCT}
              label={BRIEF_COPY.LABEL_FREE_PRODUCT + '*'}
              showCharactersCounter
            />
            <Field
              component={TextInput}
              name={BRIEF_TEMPLATE_FIELDNAMES.WEBSITE}
              label={BRIEF_COPY.LABEL_WEBSITE}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_WEBSITE}
            />
            <Field
              component={TextInput}
              name={BRIEF_TEMPLATE_FIELDNAMES.PROMO_CODE}
              label={BRIEF_COPY.LABEL_PROMO_CODE}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_PROMO_CODE}
            />
            <Heading
              text={BRIEF_COPY.FORM_HEADING_DESCRIPTION}
              descriptionText={BRIEF_COPY.FORM_TOOLTIP_DESCRIPTION}
              className="brief-form__header"
            />
            <Field
              name={BRIEF_TEMPLATE_FIELDNAMES.DESCRIPTION}
              component={RichtextInput}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_MODULE_DESCRIPTION}
              readableWidth
            />
            <Heading
              text={BRIEF_COPY.FORM_HEADING_MODULE}
              descriptionText={BRIEF_COPY.FORM_TOOLTIP_MODULE}
              className={cs('brief-form__section-header', 'brief-form__header')}
            />
            <BriefModulesForm name="modules" isDraftState={true} />
            <Heading
              text={BRIEF_COPY.FORM_HEADING_AUTO_RESPONSE}
              descriptionText={BRIEF_COPY.FORM_TOOLTIP_AUTO_RESPONSE}
              className={cs('brief-form__section-header', 'brief-form__header')}
            />
            <Field
              name={BRIEF_TEMPLATE_FIELDNAMES.AUTO_RESPONSE}
              component={TextareaInput}
              placeholder={BRIEF_COPY.FORM_PLACEHOLDER_AUTO_RESPONSE}
            />
            <Field
              name={BRIEF_TEMPLATE_FIELDNAMES.INVITE_ONLY}
              component={ToggleInput}
              label="Invite Only"
              className="brief-form__invite-only"
            />
            <Field
              name={BRIEF_TEMPLATE_FIELDNAMES.CREATIVE_ONLY}
              component={ToggleInput}
              label="Creative Only"
              className="brief-form__creative-only"
            />
            <Field
              name={BRIEF_TEMPLATE_FIELDNAMES.SHIPPING_REQUIREMENT}
              component={ToggleInput}
              label="Shipping Required"
              className="brief-form__shipping-required"
            />
            <Field
              name={BRIEF_TEMPLATE_FIELDNAMES.INSHOP_REQUIREMENT}
              component={ToggleInput}
              label="In-Shop Visit Required"
              className="brief-form__inshop-required"
              onChange={() => onToggleInshop(formikProps)}
            />
            {formikProps.values[BRIEF_TEMPLATE_FIELDNAMES.INSHOP_REQUIREMENT] && (
              <Field
                name={'states'}
                component={SelectInput}
                isSearchable
                isMulti
                isOptionSelected={({ value: { id } }) =>
                  formikProps.values.states?.some(({ value }) => value.id === id)
                }
                onChange={(values) => {
                  const allChosen = values.length === states.length;
                  const hasSelectAll = values.some(({ value }) => value.name === SELECT_ALL);
                  if (!allChosen && hasSelectAll) {
                    const allStates = states.map((state) => ({ value: state, label: state.code }));
                    formikProps.setFieldValue('states', allStates, true);
                  }
                  if (hasSelectAll || allChosen || values.length === states.length - 1) {
                    setSelectAll(false);
                  } else if (!showSelectAll) {
                    setSelectAll(true);
                  }
                }}
                options={stateOptions}
                placeholder={'Store States'}
                width={isMobile ? '100%' : '400px'}
                runBlurOnChange={false}
              />
            )}
            <FormikErrorFocus offset={-30} align="top" ease="linear" duration={200} formik={formikProps} />
            <Flex className="brief-form__button-wrapper--open">
              <FormButton<FormBriefTemplate>
                type="submit"
                variant={BUTTON_VARIANTS.OUTLINE}
                className="brief-form__draft-btn"
                loaderColor={COLORS.PRIMARY600}
              >
                {BRIEF_COPY.BUTTON_EDIT_BRIEF_TEMPLATE}
              </FormButton>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default BriefTemplateForm;

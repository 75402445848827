import { createSlice } from '@reduxjs/toolkit';

import { State } from 'shared/typings/address';

const statesSlice = createSlice({
  name: 'states',
  initialState: [],
  reducers: {
    statesLoaded: (states, action) => action.payload.data,
  },
});

export const { statesLoaded } = statesSlice.actions;

export default statesSlice.reducer;

export const getStates = ({ states }): State[] => states;

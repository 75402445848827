import React, { useState } from 'react';
import { Button } from 'rebass/styled-components';

import InviteCreatorsForm from 'features/briefs/components/organisms/InviteCreators';
import Modal from 'shared/components/molecules/Modal';

import { CREATOR_COPY } from 'shared/config/copy';
import { SIZING } from 'shared/config/formatting';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { usePermissions } from 'shared/hooks/usePermissions';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { ELEMENTS } from 'shared/styles/styles';
import { Brief } from 'shared/typings/briefs';

interface Props {
  brief: Pick<Brief, 'id' | 'name' | 'accountId' | 'organizationId' | 'full'>;
  className?: string;
  full?: boolean;
}

const InviteCreators: React.FC<Props> = ({ brief, className }) => {
  const { organizationId, full } = brief;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { displayComponent } = usePermissions();

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  return (
    <>
      {displayComponent(
        <Button
          type={ELEMENTS.BUTTON}
          variant={BUTTON_VARIANTS.TOOL}
          className={className}
          onClick={toggleModal}
          disabled={full}
        >
          {CREATOR_COPY.FORM_HEADING_INVITE_CREATORS}
        </Button>,
        organizationId,
        PERMISSIONS_MAP.INVITE_CREATORS.API,
      )}

      <Modal
        title={CREATOR_COPY.FORM_HEADING_INVITE_CREATORS}
        onRequestClose={toggleModal}
        isOpen={isModalOpen}
        modalSize={SIZING.MEDIUM_WIDE}
        component={<InviteCreatorsForm brief={brief} toggleModal={toggleModal} />}
      />
    </>
  );
};

export default InviteCreators;

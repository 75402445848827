import React from 'react';
import { Field } from 'formik';
import TextInput from 'shared/components/TextInput';
import * as yup from 'yup';
import { MESSAGES } from 'shared/config/validations';
import { OrgDetailsValues } from 'features/organizations/components/organisms/OrgOnboardingStepFields/types';
import { YUP_FIELDS } from 'shared/utilities/yupFieldUtility';

export const OrgDetailsStepFields: React.FC = () => {
  return (
    <>
      <Field name={ORG_DETAILS_FIELDNAMES.NAME} component={TextInput} label={LABELS.NAME} placeholder={LABELS.NAME} />
      <Field
        name={ORG_DETAILS_FIELDNAMES.DOMAIN}
        component={TextInput}
        label={LABELS.DOMAIN}
        placeholder={LABELS.DOMAIN}
      />
    </>
  );
};

export const ORG_DETAILS_FIELDNAMES = {
  NAME: 'name',
  DOMAIN: 'domain',
};

const LABELS = {
  NAME: 'Name*',
  DOMAIN: 'Website*',
};

export const orgDetailsSchema = yup.object({
  name: yup.string().trim().max(60).required(MESSAGES.REQUIRED_FIELD),
  domain: YUP_FIELDS.DOMAIN_REQUIRED,
});

export const orgDetailsInitialValues: OrgDetailsValues = {
  name: '',
  domain: '',
};

import { Flex } from 'shared/components/display';
import { PaginationSearchParams } from 'shared/components/layout/interfaces/pagination';
import PaginationControl from 'shared/components/organisms/PaginationControl';
import { FilterOptions } from 'shared/components/molecules/PaginationFilter';

import React from 'react';
import styles from './styles';

const filterOptions: FilterOptions<boolean> = [
  {
    label: 'In Progress Briefs',
    value: true,
  },
  {
    label: 'Completed Briefs',
    value: false,
  },
];

interface Props {
  searchParams: PaginationSearchParams & { [key: string]: string };
  isMobile?: boolean;
}

const CreatorBriefsPaginationControl: React.FC<Props> = ({ searchParams, isMobile }) => {
  return (
    <Flex css={styles} className="creator-briefs-pagination-control">
      <PaginationControl
        searchParams={searchParams}
        filterOptions={filterOptions}
        filterParamsName="inProgress"
        filterWidth={isMobile ? '100%' : '200px'}
      />
    </Flex>
  );
};

export default CreatorBriefsPaginationControl;

import React, { useEffect } from 'react';
import { Input } from 'shared/lib/formik';
import FacebookConnect from 'features/accountPlatforms/facebook/FacebookConnect/FacebookConnect';
import { FacebookConnectProps } from 'features/accountPlatforms/facebook/FacebookConnect/types';
import { useQuery } from 'shared/hooks/useQuery';
import { Box } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';

interface FBBusiness {
  id: string;
  name: string;
}

export interface FacebookConnectValue {
  businesses: FBBusiness[];
  token: string;
}

const FacebookConnectInput: Input<FacebookConnectValue, Omit<FacebookConnectProps, 'facebookConnected'>> = ({
  accountPlatformId,
  redirectURI,
  text,
  field: { name, value, onChange },
  form: { handleBlur },
}) => {
  const { getQuery } = useQuery();
  const { fbBusinesses, token } = getQuery();

  useEffect(() => {
    if (fbBusinesses) {
      const parsedBusinesses: FBBusiness[] = fbBusinesses ? JSON.parse(fbBusinesses) : [];
      onChange({
        target: {
          name,
          value: {
            businesses: parsedBusinesses,
            token,
          },
        },
      });
      handleBlur({ target: { name } });
    }
  }, [fbBusinesses]);

  return (
    <Box>
      <FacebookConnect
        facebookConnected={Boolean(value.businesses?.length)}
        accountPlatformId={accountPlatformId}
        redirectURI={redirectURI}
        text={text}
      />
      <FieldErrorMessage name={name} />
    </Box>
  );
};

export default FacebookConnectInput;

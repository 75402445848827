import * as yup from 'yup';

export const checkboxGroupSchema = yup.array(
  yup.object({
    label: yup.string(),
    checked: yup.boolean(),
    value: yup.string(),
    disabled: yup.boolean(),
  }),
);

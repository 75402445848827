import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.add-files-to-collection': {
    '&__btn': {
      whiteSpace: 'nowrap',
      '& svg': {
        marginRight: '0.5rem',
      },
    },
    '&__submit-btn': {
      margin: `${theme.space[4]} 0`,
    },
  },
});

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.review-footer': {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  '.review-footer': {
    '&__caption-wrapper': {
      color: COLORS.WHITE,
      justifyContent: 'start',
      alignItems: 'center',
      margin: `${theme.space[2]} 0`,
    },
    '&__button-wrapper': {
      justifyContent: 'space-around',
      marginBottom: theme.space[2],
    },
    '&__caption-text': {
      whiteSpace: 'break-spaces',
      marginBottom: 0,
      width: 'fit-content',
      [mediaquery.md]: {
        width: 'fit-content',
      },
    },
    '&__caption-text-modules': {
      whiteSpace: 'break-spaces',
      marginBottom: 0,
      marginLeft: 'auto',
      width: 'fit-content',
      [mediaquery.md]: {
        width: 'fit-content',
      },
    },
    '&__caption-text:first-of-type': {
      textAlign: 'left',
    },
    '&__caption-text:last-of-type': {
      textAlign: 'right',
    },
    '&__icon': {
      marginLeft: theme.space[6],
      [mediaquery.md]: {
        marginLeft: theme.space[4],
      },
    },
    '&__approve-btn': {
      marginTop: theme.space[4],
      [mediaquery.md]: {
        marginTop: theme.space[1],
      },
    },
    '&__warning-btn': {
      color: COLORS.WHITE,
      backgroundColor: COLORS.RED200,
      '&:hover': {
        backgroundColor: COLORS.RED500,
        color: COLORS.WHITE,
      },
    },
  },
});

import React, { useEffect, useState } from 'react';

import { ProfilePictureSkeleton } from 'features/users/components/atoms/ProfilePictureSkeleton';
import {
  ProfilePicSize,
  ProfilePicture,
  ProfilePictureWithDeleteButton,
} from 'features/users/components/molecules/ProfilePicture';
import { useUser } from 'features/users/hooks/useUser';
import DragAndDropUpload, { FileData } from 'shared/components/molecules/DragAndDropUpload/DragAndDropUpload';

import { USER_COPY } from 'shared/config/copy';
import { BRKFST_SUPPORTED_IMAGE_FORMATS } from 'shared/config/fileFormats';

interface Props {
  userId: number;
  isCurrentUser?: boolean;
  size?: ProfilePicSize;
}

const ProfilePictureWithDragAndDrop: React.FC<Props> = ({ userId, isCurrentUser = false, size = 'lg' }) => {
  const { user, saveProfilePicture, removeProfilePicture, loadingProfilePicture: loading } = useUser(userId);
  const { profilePic } = user;
  const { url } = profilePic || {};

  const [state, setState] = useState({
    imageObject: { name: '', url: '' },
  });

  const setImage = async () => {
    if (state.imageObject.url) {
      saveProfilePicture(state.imageObject);
    }
  };

  useEffect(() => {
    setImage();
  }, [state.imageObject]);

  if (isCurrentUser && loading) {
    return <ProfilePictureSkeleton size={size} />;
  }

  if (isCurrentUser) {
    return url ? (
      <ProfilePictureWithDeleteButton size={size} onDelete={removeProfilePicture} src={url} />
    ) : (
      <DragAndDropUpload
        onChange={(newImage) => {
          setState((prev) => ({
            ...prev,
            imageObject: {
              url: newImage.mediaObjectURL,
              name: newImage.file.name,
              file: newImage.file,
            },
          }));
        }}
        text={USER_COPY.BUTTON_ADD_PICTURE}
        supportedMedia={'image'}
        acceptedMedia={BRKFST_SUPPORTED_IMAGE_FORMATS}
      />
    );
  }

  return <ProfilePicture user={user} size={size} />;
};

export default ProfilePictureWithDragAndDrop;

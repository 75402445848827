import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';

import { useFiles } from 'features/media/useFiles';
import Box from 'shared/components/display/Box.display';
import FormButton from 'shared/components/FormButton';
import AsyncCollectionSelect from 'shared/components/molecules/AsyncCollectionSelect';
import {
  defaultOption,
  getCollectionSelectInputSchema,
} from 'shared/components/molecules/AsyncCollectionSelect/config';
import { SelectValue } from 'shared/components/molecules/Select/interfaces';

import { COLLECTION_COPY } from 'shared/config/copy';
import { noOp } from 'shared/defaults';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';

import styles from './styles';
interface FormValues {
  collection: SelectValue | null;
}

const validationSchema = yup.object({
  collection: getCollectionSelectInputSchema(true),
});

const initialValues: FormValues = {
  collection: { ...defaultOption },
};

interface Props {
  selectedAssetIds: Array<number>;
  collectionId: number;
  className?: string;
  onSubmit?: (args?: any) => void;
}
const MoveFilesToCollectionForm: React.FC<Props> = ({
  selectedAssetIds,
  collectionId,
  className = '',
  onSubmit = noOp,
}) => {
  const { assignToCollection, loading, removeFromCollection } = useFiles();
  const { currentUser, isCreator } = useCurrentUser();
  const { accountId, organizationId } = useParams<{ organizationId?: string; accountId?: string }>();

  const removeFilesFromCollection = (fileIds: Array<number>) =>
    fileIds.forEach((fileId) =>
      removeFromCollection({
        fileId,
        collectionId,
        showToast: false,
      }),
    );

  const moveFilesToCollection = ({ collection }): void => {
    assignToCollection({
      fileIds: selectedAssetIds,
      id: collection.value?.id,
      collectionName: collection.value?.name,
      onSuccess: (response) => removeFilesFromCollection(response.data.fileIds),
    });
    onSubmit();
  };

  const extraParams = useMemo(() => {
    if (isCreator) {
      return {
        creatorId: currentUser.id,
      };
    }
    return {
      accountId,
      organizationId,
    };
  }, [currentUser.id, isCreator, accountId, organizationId]);

  return (
    <Formik
      validateOnBlur={false}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={moveFilesToCollection}
      className={className}
    >
      <Box css={styles}>
        <Form className="add-files-to-collection-form">
          <Field
            name="collection"
            component={AsyncCollectionSelect}
            className="add-files-to-collection-form__collection-select"
            extraParams={extraParams}
            placeholder={COLLECTION_COPY.LABEL_DESTINATION_COLLECTION}
            currentCollectionId={collectionId}
          />
          <FormButton type="submit" disabled={loading}>
            {COLLECTION_COPY.BUTTON_MOVE_FILES_TO_COLLECTION}
          </FormButton>
        </Form>
      </Box>
    </Formik>
  );
};

export default MoveFilesToCollectionForm;

import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CollectionTileList from 'features/media/components/CollectionTileList';
import CollectionSearch from 'features/media/components/organisms/CollectionSearch';
import assetsLibraryStyles from 'features/media/pages/AssetsLibrary/assetsLibraryStyles';
import { useCollections } from 'features/media/useCollections';
import { Box, Text } from 'shared/components/display';
import { FilterOptions } from 'shared/components/molecules/PaginationFilter';
import { PaginationSortType, SortOptions } from 'shared/components/molecules/PaginationSort';
import Pagination from 'shared/components/organisms/Pagination';

import { useQuery } from 'shared/hooks/useQuery';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';

const { H4 } = TEXT_VARIANTS;

const sortOptions: SortOptions = [
  {
    label: 'Name',
    value: 'name',
    sortType: PaginationSortType.ALPHABET,
  },
  {
    label: 'Creation Date',
    value: 'dateCreated',
    sortType: PaginationSortType.TIME,
  },
];

const filterOptions: FilterOptions = [
  {
    label: 'All',
    value: '',
  },
  {
    label: 'Brief',
    value: 'brief',
  },
  {
    label: 'Collection',
    value: 'nonBrief',
  },
];

type AssetLibraryParams = {
  accountId: string;
  organizationId: string;
};

const AssetsLibrary = () => {
  const params = useParams<AssetLibraryParams>();
  const { accountId } = params;

  const { getQuery, setQuery } = useQuery();
  const query = getQuery();

  const searchParams = useSearchParams(query, {
    page: 1,
    size: 10,
    orderBy: 'dateCreated',
    orderAsc: false,
    type: '',
    accountId,
    name: '',
  });

  const { collections, totalCollections, createCollection, loading, loadingNewCollection, getFilteredCollections } =
    useCollections();

  useEffect(() => {
    getFilteredCollections({
      ...searchParams,
    });
  }, [searchParams]);

  return (
    <Box css={assetsLibraryStyles} className="assets-library">
      <Text variant={H4} className="assets-library__heading">
        Library
      </Text>
      <div className={CLASSNAMES.PADDING_CONTAINER}>
        <Pagination
          first={
            <CollectionSearch
              onSubmit={({ search: name }) => {
                setQuery({ ...query, page: 1, name });
              }}
              state={query}
            />
          }
          totalAmount={totalCollections}
          loading={loading}
          searchParams={searchParams}
          paginationControlProps={{ sortOptions, filterOptions, filterParamsName: 'type' }}
        >
          <CollectionTileList
            data={collections}
            createCollection={createCollection}
            creatorCollection={false}
            loading={loadingNewCollection}
            addLink
            showNewCollectionTile
          />
        </Pagination>
      </div>
    </Box>
  );
};

export default AssetsLibrary;

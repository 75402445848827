import React from 'react';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { Link } from 'react-router-dom';
import { Avatar } from 'stream-chat-react';
import { StreamChatChannel } from 'shared/messaging/types';

interface Props {
  channel: StreamChatChannel;
  isCreator: boolean;
  image?: string;
  name?: string;
}

const ChannelListAvatar: React.FC<Props> = ({ channel, isCreator, image, name }) => {
  if (!isCreator) {
    /**
     * The structure of a channel ID is u##-a##
     * where the first number is the user (creator) id
     * and the second is the account id.
     * The Regexp below matches that pattern and
     * replaces the whole id with the first number
     */
    const creatorId = channel?.id?.replace(new RegExp('u([0-9]+)-a[0-9]+'), '$1');
    return (
      <div className="str-chat__channel-preview-messenger--left">
        <Link
          to={LinkCreator.createLink({
            routeKey: 'USER_PROFILE',
            variables: {
              userId: creatorId,
            },
          })}
        >
          <Avatar image={image} name={name} size={40} />
        </Link>
      </div>
    );
  }
  return (
    <div className="str-chat__channel-preview-messenger--left">
      <Avatar image={image} name={name} size={40} />
    </div>
  );
};

export default ChannelListAvatar;

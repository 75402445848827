import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.top-bottom-tool-form': {
    '&__submit-btn': {
      margin: `${theme.space[4]} 0`,
    },
    '&__select-input': {
      marginTop: theme.space[5],
    },
    '&__select-input--hidden': {
      display: 'none',
    },
  },
});

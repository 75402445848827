import { css } from '@emotion/react';
import theme from 'shared/styles/themes';
import COLORS, { INSTAGRAM_COLORS } from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

// Instagram's Brand Guidelines: https://en.instagram-brand.com/

export default css({
  flexWrap: 'wrap',
  justifyContent: 'center',
  [mediaquery.md]: {
    justifyContent: 'start',
  },
  '.instagram-connect': {
    '&__button': {
      background: [
        INSTAGRAM_COLORS.PURPLE,
        `linear-gradient(45deg,${INSTAGRAM_COLORS.YELLOW},${INSTAGRAM_COLORS.RED},${INSTAGRAM_COLORS.PURPLE})`,
      ],
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      '&:not(:disabled):hover': {
        background: `linear-gradient(225deg,${INSTAGRAM_COLORS.YELLOW},${INSTAGRAM_COLORS.RED},${INSTAGRAM_COLORS.PURPLE})`,
      },
      [mediaquery.md]: {
        width: 'auto',
      },
      marginRight: '8px',
    },
    '&__ig-icon': {
      height: '20px',
      marginRight: '10px',
    },
    '&__connected': {
      color: COLORS.GREEN500,
      padding: '8px',
    },
    '&__error-message': {
      color: COLORS.RED500,
      margin: theme.space[4],
      marginLeft: 0,
    },
  },
});

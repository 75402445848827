import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export default css({
  '.profile-modal': {
    '&__btn': {
      marginBottom: theme.space[4],
      padding: `${theme.space[3]}`,
    },
    '&__label': {
      margin: 0,
      textAlign: 'center',
    },
    '&__submit-btn': {
      margin: `${theme.space[4]} 0`,
    },
    '&__summary-field': {
      '.textarea': {
        height: '118px',
      },
    },
  },
});

import { FILE_COPY } from 'shared/config/copy';
import { MEDIA } from 'shared/config/media';
import { Input } from 'shared/lib/formik';
import React, { useMemo } from 'react';

import VideoThumbnailPicker, { ThumbnailCapture } from 'shared/components/molecules/VideoThumbnailPicker';
import { Flex, Text } from 'shared/components/display';
import styles from './styles';
import ScaledImage from 'shared/components/atoms/ScaledImage';
import { BrkfstFile } from 'shared/typings/file';

const noPreviewAvailable = [MEDIA.MIME_TYPES.TIFF];

export type MediaPreviewValue = {
  offset: number;
  updateThumbnail: boolean;
};

type Props = {
  mediaObjectURL: string;
  file: BrkfstFile;
  name: string;
};

const MediaPreviewInput: Input<MediaPreviewValue, Props> = ({
  file,
  mediaObjectURL,
  field: { name, onChange, onBlur },
}) => {
  const mediaType = useMemo(() => file?.type?.split('/')[0], []);

  if (!mediaObjectURL) {
    return null;
  }

  const handleCapture = (capture: ThumbnailCapture) => {
    const event = {
      target: {
        name,
        value: {
          offset: capture.thumbnailOffset,
          updateThumbnail: true,
        },
      },
    };
    onChange(event);
    onBlur(event);
  };

  const renderFileTypeSwitch = () => {
    if (noPreviewAvailable.includes(file?.type || ''))
      return <Text className="media-preview-input__text">{FILE_COPY.PLACEHOLDER_FILE_PREVIEW}</Text>;
    if (mediaType === MEDIA.TYPES.VIDEO) return <VideoThumbnailPicker src={mediaObjectURL} onCapture={handleCapture} />;
    return (
      <ScaledImage
        className="media-preview-input__img"
        src={mediaObjectURL}
        width={file.metadata.width}
        height={file.metadata.height}
      />
    );
  };

  return (
    <Flex css={styles} className="media-preview-input">
      {renderFileTypeSwitch()}
    </Flex>
  );
};

export default MediaPreviewInput;

import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'rebass/styled-components';
import { faStripe } from '@fortawesome/free-brands-svg-icons';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import config from 'config';

import ExternalLink from 'shared/components/atoms/ExternalLink/ExternalLink';
import { Box, Flex, Text } from 'shared/components/display';

import { STRIPE_COPY } from 'shared/config/copy';
import { useQuery } from 'shared/hooks/useQuery';
import { useStripe } from 'shared/hooks/useStripe';
import { stripeLogo } from 'shared/images/stripe';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';

import styles from './styles';

export enum StripeConnectVariant {
  PRIMARY = 'primary',
  SECONDARY = 'seconday',
}
interface Props {
  user: Pick<BrkfstUser, 'id' | 'email' | 'stripeAccountId'>;
  redirectURI: string;
  className?: string;
  variant?: StripeConnectVariant;
}

interface StripeState {
  userId: number;
  redirectURI: string;
}

const StripeConnect: React.FC<Props> = ({ user, redirectURI, className, variant = StripeConnectVariant.PRIMARY }) => {
  const { getQuery } = useQuery();
  const { fetchExpressDashboardLink } = useStripe();
  const [stripeDashboardUrl, setStripeDashboardUrl] = useState('');
  const { id: userId, email, stripeAccountId } = user;

  const state = useMemo(() => {
    const data: StripeState = { userId, redirectURI };
    return JSON.stringify(data);
  }, [userId, redirectURI]);

  useEffect(() => {
    const setDashboardUrl = ({ data }) => {
      const { url } = data;
      setStripeDashboardUrl(url);
    };

    if (stripeAccountId) fetchExpressDashboardLink(setDashboardUrl);
  }, [stripeAccountId]);

  const stripeOauthURL = `https://connect.stripe.com/express/oauth/authorize?response_type=code&scope=read_write&redirect_uri=${config.API_URL}/user/stripe/connect/callback&client_id=${config.STRIPE_CLIENT_ID}&state=${state}&stripe_user[email]=${email}`;

  const { stripeError } = getQuery();

  return (
    <Flex className={cs('stripe-connect', className)} css={styles}>
      {stripeAccountId ? (
        <>
          {variant === StripeConnectVariant.PRIMARY ? (
            <Box className="stripe-connect__connected-wrapper">
              <Text variant={TEXT_VARIANTS.CATEGORY} className="stripe-connect__connected-message">
                Connected
              </Text>
              <Flex className="stripe-connect__disconnect-wrapper">
                <FontAwesomeIcon className="stripe-connect__disconnect-icon" icon={faX} color={COLORS.NEUTRAL400} />
                <ExternalLink
                  className="stripe-connect__disconnect"
                  text="Disconnect"
                  href="https://dashboard.stripe.com/settings/apps"
                />
              </Flex>
            </Box>
          ) : (
            <a className="stripe-connect__secondary-wrapper" href={stripeDashboardUrl} tabIndex={0} target="_blank">
              <Button variant={BUTTON_VARIANTS.TEXT} className="stripe-connect__connect-button">
                Access
              </Button>
              <img src={stripeLogo} alt={STRIPE_COPY.ALT_LOGO} height="25rem" className="stripe-connect__stripe-logo" />
            </a>
          )}
        </>
      ) : variant === StripeConnectVariant.PRIMARY ? (
        <a className="stripe-connect__link" href={stripeOauthURL} tabIndex={0}>
          <Text variant={TEXT_VARIANTS.H6} className="stripe-connect__link__text">
            Connect with
          </Text>
          <FontAwesomeIcon icon={faStripe} className="stripe-connect__link__logo" size="2x" color={COLORS.WHITE} />
        </a>
      ) : (
        <a className="stripe-connect__secondary-wrapper" href={stripeOauthURL} tabIndex={0}>
          <Button variant={BUTTON_VARIANTS.TEXT} className="stripe-connect__connect-button">
            Connect
          </Button>
          <img src={stripeLogo} alt={STRIPE_COPY.ALT_LOGO} height="25rem" className="stripe-connect__stripe-logo" />
        </a>
      )}
      {stripeError ? (
        <Text variant={TEXT_VARIANTS.BODY} className="stripe-connect__error-message">
          {stripeError}
        </Text>
      ) : null}
    </Flex>
  );
};

export default StripeConnect;

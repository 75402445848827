import React from 'react';
import { useNavigate } from 'react-router-dom';
import cs from 'classnames';

import navBarLogoStyles from 'shared/components/atoms/NavBarLogo/navBarLogoStyles';
import { Box } from 'shared/components/display';

import { GLOBAL_COPY_VARIABLES } from 'shared/config/copy/allCopy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import brkfstLogo from 'shared/images/logo/brkfst-logo.svg';
import brkfstLogoIcon from 'shared/images/logo/brkfst-logo-icon.svg';
import { LinkCreator } from 'shared/utilities/linkUtility';

interface Props {
  disableNavigate?: boolean;
  centerLogo?: boolean;
}

const NavBarLogo: React.FC<Props> = ({ disableNavigate = false, centerLogo = false }) => {
  const navigate = useNavigate();
  const { lastVisitedOrg } = useCurrentUser();

  const navigateToHomePage = (): void => {
    const path = lastVisitedOrg
      ? LinkCreator.createLink({
          routeKey: 'ORGANIZATION',
          variables: {
            organizationId: lastVisitedOrg,
          },
        })
      : LinkCreator.createLink({
          routeKey: 'HOME_PAGE',
        });
    navigate(path);
  };

  return (
    <Box
      css={navBarLogoStyles}
      className={cs('nav-logo', {
        'nav-logo--center': centerLogo,
      })}
    >
      <img
        onClick={disableNavigate ? undefined : navigateToHomePage}
        src={brkfstLogo}
        alt={GLOBAL_COPY_VARIABLES.APP_NAME}
        className={cs('nav-logo__logo', {
          'nav-logo__logo--clickable': !disableNavigate,
        })}
      />
      <img
        onClick={disableNavigate ? undefined : navigateToHomePage}
        src={brkfstLogoIcon}
        alt={GLOBAL_COPY_VARIABLES.APP_NAME}
        className={cs('nav-logo__logo-icon', {
          'nav-logo__logo-icon--clickable': !disableNavigate,
        })}
      />
    </Box>
  );
};

export default NavBarLogo;

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';

export default css({
  '&.routes-wrapper': {
    overflowY: 'auto',
    flex: 1,
    zIndex: zIndex.zForeground,
  },
  '&.content-wrapper': {
    overflowY: 'auto',
    flex: 1,
  },
});

import { Editor, Element } from 'slate';

import { uploadRichtextImage } from 'shared/components/Richtext/RichtextPicture/utils';
import { RichTextFormats } from 'shared/components/Richtext/shared/types';

export const withImages = (editor: Editor, sasToken: string) => {
  const { isVoid, isInline, insertData } = editor;

  // Any non-text element including images are considered void nodes
  editor.isVoid = (element: Element) => {
    return element.type === RichTextFormats.IMAGE ? true : isVoid(element);
  };

  editor.isInline = (element: Element) => {
    return element.type === RichTextFormats.IMAGE ? true : isInline(element);
  };

  editor.insertData = (data) => {
    // Support for pasting & drag and dropping images
    const { files } = data;
    // Paste Files
    if (files && files.length > 0) {
      uploadRichtextImage(editor, sasToken, files);
      // Paste Text
    } else {
      insertData(data);
    }
  };

  return editor;
};

import React, { useEffect } from 'react';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { useFiles } from 'features/media/useFiles';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Box, Flex } from 'shared/components/display';
import Divider from 'shared/components/molecules/Divider';
import FileListView, { FileAction } from 'shared/components/molecules/FileListView/FileListView';
import MediaUploadButton from 'shared/components/molecules/MediaUploadButton';

import { ACCOUNT_COPY } from 'shared/config/copy';
import { ALL_BRKFST_SUPPORTED_FILE_FORMATS } from 'shared/config/fileFormats';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstFile } from 'shared/typings/file';

interface Props {
  accountId: string;
  authorizedToEdit: boolean;
}
const UpdateBrandAssets: React.FC<Props> = ({ accountId, authorizedToEdit }) => {
  const {
    brandAssets,
    fetchBrandAssets,
    deleteBrandAsset,
    uploadBrandAsset,
    loadingBrandAsset,
    deleteBrandAssetInProgress,
  } = useAccounts({ accountId });

  const { filesUploadComplete } = useFiles();
  useEffect(() => {
    fetchBrandAssets(accountId);
  }, [accountId]);

  if (!authorizedToEdit) return <></>;

  return (
    <Box>
      <Divider
        heading={ACCOUNT_COPY.HEADING_BRAND_ASSETS}
        variant={TEXT_VARIANTS.H6}
        Component={
          <MediaUploadButton
            acceptedMedia={ALL_BRKFST_SUPPORTED_FILE_FORMATS}
            multipleFiles
            buttonContent={'Upload'}
            onFileUpload={(files) => uploadBrandAsset(files, accountId)}
          />
        }
      />
      <Flex className="account-settings__brand-assets">
        {loadingBrandAsset && !filesUploadComplete ? (
          <CenteredSpinner />
        ) : (
          <FileListView
            files={brandAssets}
            action={FileAction.REMOVE}
            onClick={(file: BrkfstFile) => deleteBrandAsset(file.id, accountId)}
            loading={deleteBrandAssetInProgress}
            placeholder={ACCOUNT_COPY.PLACEHOLDER_NO_BRAND_ASSETS}
            showFilePreview
          />
        )}
      </Flex>
    </Box>
  );
};

export default UpdateBrandAssets;

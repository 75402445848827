import 'react-phone-input-2/lib/material.css';

import React, { useMemo } from 'react';
import Input, { CountryData } from 'react-phone-input-2';
import cs from 'classnames';
import { FormikErrors } from 'formik';

import { Box } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import phoneInputStyles from 'shared/components/inputs/PhoneInput/phoneInputStyles';

import { Input as InputType } from 'shared/lib/formik';

interface Props {
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  maxLength?: number;
  country?: string;
  dialCodeFieldName: string;
}

const PhoneInput: InputType<string, Props> = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched },
  disabled = false,
  placeholder,
  label,
  dialCodeFieldName,
}) => {
  const handleChange = (value, data) => {
    onChange({ target: { value, name } });
    onChange({ target: { value: data.countryCode, name: 'countryCode' } });
    onChange({ target: { value: data.dialCode, name: dialCodeFieldName } });
  };

  const handleBlur = (_, data: {} | CountryData) => {
    onBlur({ target: { value: data, name } });
  };

  const hasErrors = useMemo(() => {
    const error = errors[name] as FormikErrors<any> | undefined;
    const errorIn = Array.isArray(error) ? error[0] : error;
    return Boolean(errorIn && touched[name]);
  }, [errors, touched, name]);

  return (
    <Box
      css={phoneInputStyles}
      className={cs('phone-input', {
        'phone-input--error': hasErrors,
        'phone-input--has-value': Boolean(value),
      })}
    >
      <Input
        specialLabel={label}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        data-lpignore={true}
        inputClass="phone-input__input"
        containerClass="phone-input__container"
        dropdownClass="phone-input__dropdown"
        country={'us'} // initial country when none provided
      />

      <FieldErrorMessage name={name} dataCy="phone-input__error-message" />
    </Box>
  );
};

export default PhoneInput;

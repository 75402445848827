import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { Box, Button, Flex, Image, Text } from 'shared/components/display';

import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import ahsExample from 'shared/images/account-health/ahs-example-v2.png';
import COLORS from 'shared/styles/colors';
import { CLASSNAMES } from 'shared/styles/containers';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './adHealthSignInStyles';

export const AdHealthSignIn = ({ onSubmit }) => {
  return (
    <Box className="ad-health-sign-in" css={styles}>
      <Flex className={classNames(CLASSNAMES.MAIN_CONTAINER, 'ad-health-sign-in__form')}>
        <Text
          variant={TEXT_VARIANTS.H3}
          className="ad-health-sign-in__header"
          data-cy="ad-health-sign-in__signInHeader"
        >
          {ACCOUNT_PLATFORM_COPY.SUBHEADING_AD_HEALTH_SIGNIN}
        </Text>
        <Text variant={TEXT_VARIANTS.BODY} color={COLORS.NEUTRAL600} className="ad-health-sign-in__pitch">
          {ReactHtmlParser(ACCOUNT_PLATFORM_COPY.PITCH_AD_HEALTH_ONBOARDING)}
        </Text>
        <Flex className="ad-health-sign-in__body">
          <Flex className="ad-health-sign-in__text-wrapper">
            <Text>
              <strong>{ACCOUNT_PLATFORM_COPY.LIST_HEADER_AD_HEALTH_ONBOARDING}</strong>
            </Text>
            <Flex className="ad-health-sign-in__list-item">
              <FontAwesomeIcon icon={faCheck} color={COLORS.SECONDARY500} />
              <Text variant={TEXT_VARIANTS.BODY}>{ACCOUNT_PLATFORM_COPY.FIRST_CHECK_AD_HEALTH_ONBOARDING}</Text>
            </Flex>
            <Flex className="ad-health-sign-in__list-item">
              <FontAwesomeIcon icon={faCheck} color={COLORS.SECONDARY500} />
              <Text variant={TEXT_VARIANTS.BODY}>{ACCOUNT_PLATFORM_COPY.SECOND_CHECK_AD_HEALTH_ONBOARDING}</Text>
            </Flex>
            <Flex className="ad-health-sign-in__list-item">
              <FontAwesomeIcon icon={faCheck} color={COLORS.SECONDARY500} />
              <Text variant={TEXT_VARIANTS.BODY}>{ACCOUNT_PLATFORM_COPY.THIRD_CHECK_AD_HEALTH_ONBOARDING}</Text>
            </Flex>
            <span className="ad-health-sign-in__line" />
            <Text variant={TEXT_VARIANTS.BODY} color={COLORS.NEUTRAL600} className="ad-health-sign-in__description">
              {ACCOUNT_PLATFORM_COPY.DESCRIPTION_AD_HEALTH_SIGNIN}
            </Text>
            <Button onClick={onSubmit} className="ad-health-sign-in__fb-btn">
              <FontAwesomeIcon
                icon={faFacebook}
                color={COLORS.WHITE}
                size="2xl"
                className="ad-health-sign-in__fb-icon"
              />
              {ACCOUNT_PLATFORM_COPY.BUTTON_FACEBOOK_SIGN_IN_AD_HEALTH}
            </Button>
          </Flex>
          <Image src={ahsExample} alt="loading" title="loading" className="ad-health-sign-in__image" />
        </Flex>
        <Flex className="ad-health-sign-in__footer">
          <FontAwesomeIcon icon={faInfoCircle} color={COLORS.NEUTRAL600} />
          <Text variant={TEXT_VARIANTS.BODY} color={COLORS.NEUTRAL600} className="ad-health-sign-in__footer__message">
            {ReactHtmlParser(ACCOUNT_PLATFORM_COPY.FOOTER_AD_HEALTH_SIGNIN)}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default AdHealthSignIn;

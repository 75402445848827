import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.account-platforms': {
    flexDirection: 'column',
    marginLeft: theme.space[4],
  },
  '.account-platforms': {
    '&__wrapper': {
      margin: theme.space[5],
      flexWrap: 'wrap',
    },
  },
});

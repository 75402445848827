import { css } from '@emotion/react';

export default css({
  '.video-composition-button': {
    '&__btn': {
      whiteSpace: 'nowrap',
      '& svg': {
        marginRight: '0.5rem',
      },
    },
  },
});

import { css } from '@emotion/react';

export default css({
    textAlign: 'center',
    '.organization-onboarding-loading': {
        '&__heading': {
            margin: '16px 0 32px',
        },
        '&__img': {
            width: '100%',
            maxWidth: '400px',
        },
    },
});

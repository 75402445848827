import React from 'react';
import { useMediaQuery } from 'react-responsive';
import cs from 'classnames';
import { isEmpty } from 'lodash';

import CenteredSpinner from 'features/ui/CenteredSpinner';
import { Box, Flex } from 'shared/components/display';
import PageIndex from 'shared/components/molecules/PageIndex';
import PageSizeSelect from 'shared/components/organisms/Pagination/PageSizeSelect';
import PaginationControl from 'shared/components/organisms/PaginationControl';
import { PaginationControlProps } from 'shared/components/organisms/PaginationControl/PaginationControl';

import { useQuery } from 'shared/hooks/useQuery';
import { breakpoints } from 'shared/styles/styleFunctions';

import styles from './styles';

interface Props<S, F> {
  className?: string;
  searchParams?: any;
  totalAmount: number;
  pageSizeBase?: number;
  loading?: boolean;
  children: React.ReactNode;
  first?: React.ReactNode;
  last?: React.ReactNode;
  sizeParamName?: string;
  pageParamName?: string;
  delegateParentQuery?: (any) => void;
  paginationControlProps?: Omit<PaginationControlProps<S, F>, 'searchParams' | 'pageParamName'>;
  hidePageSizeSelect?: boolean;
}
const Pagination: React.FC<Props<any, any>> = ({
  className,
  searchParams,
  totalAmount,
  pageSizeBase = 5,
  loading,
  children,
  first,
  last,
  sizeParamName = 'size',
  pageParamName = 'page',
  paginationControlProps,
  delegateParentQuery,
  hidePageSizeSelect = false,
}) => {
  const { setQuery } = useQuery();
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const onChangeSize = (option) => {
    delegateParentQuery
      ? delegateParentQuery({ [pageParamName]: 1, [sizeParamName]: option.value })
      : setQuery({
          ...searchParams,
          [pageParamName]: 1,
          [sizeParamName]: option.value,
        });
  };

  return (
    <Box css={styles} className={cs('brkfst-pagination', className)}>
      <Flex className="brkfst-pagination__controls">
        {first}
        {!isEmpty(paginationControlProps) && (
          <PaginationControl {...paginationControlProps} pageParamsName={pageParamName} searchParams={searchParams} />
        )}
        {last}
      </Flex>

      <>{loading ? <CenteredSpinner /> : children}</>
      <Box className="brkfst-pagination__footer">
        <PageIndex
          total={totalAmount}
          perPage={+searchParams[sizeParamName]}
          pageNumber={+searchParams[pageParamName]}
          onPageChange={(page) => {
            delegateParentQuery
              ? delegateParentQuery({ [pageParamName]: page })
              : setQuery({
                  ...searchParams,
                  [pageParamName]: page,
                });
          }}
        />
        {!hidePageSizeSelect && !isMobile && totalAmount > 5 && (
          <PageSizeSelect pageSizeBase={pageSizeBase} value={searchParams[sizeParamName]} onChange={onChangeSize} />
        )}
      </Box>
    </Box>
  );
};

export default Pagination;

import { RenderContainer } from 'react-photo-album';
import React from 'react';
import { Box } from 'shared/components/display';

export const CustomContainer: RenderContainer = (props) => {
  return (
    <Box {...props.containerProps} ref={props.containerRef} width="calc(100% - 15px)">
      {props.children}
    </Box>
  );
};

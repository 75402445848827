import React, { useState } from 'react';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import ActionButton from 'shared/components/molecules/ActionButton';
import PromptButton from 'shared/components/molecules/PromptButton';
import { BRIEF_COPY } from 'shared/config/copy';
import { TRACKING } from 'shared/config/tracking';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { trackEvent } from 'shared/utilities/trackingUtility';
import CreatorBriefStartingSteps from 'features/briefs/components/organisms/CreatorBriefStartingSteps';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { CreatorUser } from 'shared/typings/user';
import { noOp } from 'shared/defaults';

const { BRIEF_APPLY } = TRACKING;
const ACTIVE_BRIEF_LIMIT = 5;

interface Props {
  userId: number;
  briefId: number;
  className?: string;
  disabled?: boolean;
}

const BriefApplicationButton: React.FC<Props> = ({ userId, briefId, className, disabled }) => {
  const { brief, applyToBrief, applyToBriefActionInProgress } = useBriefs(briefId);
  const { currentUser } = useCurrentUser();
  const [open, setOpen] = useState(false);

  const { briefCreatorStatus } = brief;

  const onCancel = () => {
    setOpen(false);
  };

  const clickHandler = () => {
    if (briefCreatorStatus === UserBriefStatus.NOT_APPLIED) {
      trackEvent(BRIEF_APPLY);
      setOpen(true);
    }
  };

  const onSubmit = (values: { pitch: string; states: { checked: boolean; value: number }[] }) => {
    const statesChosen = (values.states || []).filter(({ checked }) => checked).map(({ value }) => value);
    applyToBrief(briefId, values.pitch, statesChosen);
  };

  const showPromptButton = ((currentUser as CreatorUser)?.inProgressBriefsCount || 0) >= ACTIVE_BRIEF_LIMIT;

  return (
    <>
      {showPromptButton ? (
        <PromptButton
          confirmButtonText="Okay"
          onConfirm={noOp}
          showCancelButton={false}
          className={className}
          disabled={brief.full || disabled}
          loading={applyToBriefActionInProgress}
          dataCy="brief-application-button"
          text={BRIEF_COPY.PROMPT_ACTIVE_BRIEF_LIMIT}
        >
          {BRIEF_COPY.BUTTON_APPLY_BRIEF}
        </PromptButton>
      ) : (
        <ActionButton
          onClick={clickHandler}
          loading={applyToBriefActionInProgress}
          className={className}
          disabled={brief.full || disabled}
          dataCy="brief-application-button"
        >
          {BRIEF_COPY.BUTTON_APPLY_BRIEF}
        </ActionButton>
      )}
      <CreatorBriefStartingSteps brief={brief} userId={userId} onSubmit={onSubmit} onCancel={onCancel} open={open} />
    </>
  );
};

export default BriefApplicationButton;

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';
import COLORS, { FACEBOOK_COLORS } from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

// Facebook's Brand Guidelines:
// https://developers.facebook.com/docs/facebook-login/userexperience/
// https://en.facebookbrand.com/facebookapp/

export default css({
  '.facebook-connect': {
    '&__button': {
      background: FACEBOOK_COLORS.BLUE,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      [mediaquery.md]: {
        width: 'auto',
      },
      '&:not(:disabled):hover': {
        backgroundColor: FACEBOOK_COLORS.BLUE,
      },
    },
    '&__fb-icon': {
      height: '20px',
      marginRight: '10px',
    },
    '&__connected': {
      color: COLORS.GREEN500,
      padding: '8px',
    },
    '&__error-message': {
      color: COLORS.RED500,
      margin: theme.space[4],
    },
  },
});

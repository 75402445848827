import { useDispatch, useSelector } from 'react-redux';

import { clearData, getData, saveData } from 'shared/components/forms/forms.slice';

export const useFormData = () => {
  const dispatch = useDispatch();
  const savedData = useSelector(getData);

  const saveFormData = (data) => {
    dispatch(saveData(data));
  };

  const clearFormData = () => {
    dispatch(clearData());
  };

  return {
    savedData,
    saveFormData,
    clearFormData,
  };
};

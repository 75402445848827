// * Lib
import React, { useMemo } from 'react';

import AccountLogo from 'features/accounts/AccountLogo/AccountLogo';
import BriefStatus from 'features/briefs/BriefStatus';
import Chip from 'shared/components/atoms/Chip/Chip';
import Tooltip from 'shared/components/atoms/Tooltip';
//* Display/UI
import { Box, Flex, Text } from 'shared/components/display';

import { BRIEF_COPY } from 'shared/config/copy/briefCopy';
// * Styles
import { TEXT_VARIANTS } from 'shared/styles/text';
import theme from 'shared/styles/themes';
import { BriefStatus as BriefStatusEnum } from 'shared/typings/briefs/enums';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
// * Util
import { formatCurrency } from 'shared/utilities/stringUtility';

import styles from './styles';
import { intl } from 'shared/lib/intl';

interface Props {
  brandName?: String;
  title?: String;
  onPress?: any;
  briefCreatorStatus?: UserBriefStatus;
  briefStatus?: BriefStatusEnum;
  account: any;
  freeProduct?: string;
  creatorPayout?: number;
  full?: boolean;
  creativeOnly?: boolean;
  inviteOnly: boolean;
  hideStatus?: boolean;
}

const BriefCard: React.FC<Props> = (props) => {
  const {
    brandName,
    title,
    onPress,
    briefCreatorStatus,
    briefStatus,
    account,
    freeProduct,
    creatorPayout = -1,
    full,
    creativeOnly,
    inviteOnly,
    hideStatus = false,
  } = props;
  const payout = useMemo(
    () =>
      intl.formatMessage(
        {
          id: 'DETAIL_PERFORMANCE_PAYOUT',
        },
        {
          payout: formatCurrency(creatorPayout),
          creativeOnly: creativeOnly,
        },
      ),
    [creatorPayout, creativeOnly],
  );
  return (
    <Flex className="brief-card" css={styles} onClick={onPress}>
      <Flex className="brief-card__body-wrapper">
        <Box className="brief-card__picture-wrapper">
          <AccountLogo account={account} />
        </Box>
        <Box className="brief-card__texts-wrapper">
          <Text variant={TEXT_VARIANTS.BODY_SM} className="brief-card__brand-name">
            {brandName}
          </Text>
          <Tooltip content={title} touch={['hold', 100]}>
            <Text variant={TEXT_VARIANTS.BODY} className="brief-card__title">
              {title}
            </Text>
          </Tooltip>
        </Box>
        {!hideStatus && (
          <Flex className="brief-card__status">
            <BriefStatus
              briefCreatorStatus={briefCreatorStatus}
              status={briefStatus}
              full={full}
              inviteOnly={inviteOnly}
            />
          </Flex>
        )}
      </Flex>

      <Box className="brief-card__tags-wrapper">
        {creatorPayout > -1 && (
          <Chip label={payout} bgColor={theme.colors.NEUTRAL100} square className="brief-card__tag" />
        )}

        {freeProduct && (
          <Chip label={freeProduct} bgColor={theme.colors.NEUTRAL100} square className="brief-card__tag" />
        )}
      </Box>
    </Flex>
  );
};

export default BriefCard;

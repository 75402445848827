import { css } from '@emotion/react';
import { Button } from 'rebass/styled-components';
import styled from 'styled-components';
export const MAIN_CLASS = 'action-button';
export const CLASSNAMES = {
  loadingIcon: `${MAIN_CLASS}__loading-icon`,
  wrapper: `${MAIN_CLASS}__wrapper`,
};

export const StyledActionButton = styled(Button)`
  & .${CLASSNAMES.wrapper} {
    justify-content: space-evenly;
    align-items: center;
  }
  & .${CLASSNAMES.loadingIcon} {
    margin-right: 5px;
    height: fit-content;
  }
`;

import React from 'react';
import WelcomeHeader from 'shared/components/molecules/WelcomeHeader/WelcomeHeader';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { STYLES } from 'shared/styles/styles';

import { useAuth } from 'features/auth/useAuth';
import { useQuery } from 'shared/hooks/useQuery';
import { CLASSNAMES } from 'shared/styles/containers';
import { parseError } from 'shared/utilities/errorUtility';
import { AUTH_COPY } from 'shared/config/copy';
import ResetPasswordForm from 'features/auth/components/organisms/ResetPasswordForm';
import { ResetPasswordFormValues } from 'features/auth/components/organisms/ResetPasswordForm/config';
import { Box, Text } from 'shared/components/display';

const { ERROR } = TEXT_VARIANTS;
const { CENTER } = STYLES;

const ResetPassword = () => {
  const { getQuery } = useQuery();
  const urlParams = getQuery();

  const { passwordReset, resetPasswordErrors } = useAuth();

  const resetPassword = (formData: ResetPasswordFormValues, { setSubmitting }) => {
    const data = {
      password: formData.password.password,
      token: urlParams.token,
      confirmPassword: formData.confirmPassword,
    };

    const onError = () => {
      setSubmitting(false);
    };

    passwordReset(data, onError);
  };

  return (
    <Box className={CLASSNAMES.PUBLIC_CONTAINER}>
      <WelcomeHeader heading={AUTH_COPY.HEADING_RESET_PASSWORD} subheading="" textAlign={CENTER} />

      {resetPasswordErrors && <Text variant={ERROR}>{parseError(resetPasswordErrors)}</Text>}
      <ResetPasswordForm onSubmit={resetPassword} />
    </Box>
  );
};

export default ResetPassword;

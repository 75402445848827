import React from 'react';
import { ListsEditor } from '@prezly/slate-lists';
import { isKeyHotkey } from 'is-hotkey';
import { Editor, Element, Node, Transforms } from 'slate';

import { RichTextFormats } from 'shared/components/Richtext/shared/types';

const LIST_TYPES = [RichTextFormats.UNORDERED_LIST, RichTextFormats.ORDERED_LIST];

export const checkNodeType = (node: Node, format: RichTextFormats): boolean => {
  if (!Editor.isEditor(node) && Element.isElement(node)) {
    return node.type === format;
  }
  return false;
};

export const isBlockActive = (editor: Editor, format: RichTextFormats) => {
  const [match] = Editor.nodes(editor, {
    match: (node) => checkNodeType(node, format),
  });

  return !!match;
};

export const isMarkActive = (editor: Editor, format: RichTextFormats): boolean => {
  // Prevent non-leaf node error due to the structure needed for links
  try {
    return Editor.marks(editor)?.[format] || false;
  } catch (e) {
    return false;
  }
};

export const toggleBlock = (editor: Editor, format: RichTextFormats) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  let type = format;
  //If we are indenting in a list instead of indenting increase/decrease the depth of the list and add list item to new list
  const selectedNode = editor.children[editor.selection!.anchor.path[0]];
  if (type === RichTextFormats.INDENT && LIST_TYPES.includes(selectedNode.type)) {
    type = RichTextFormats.LIST_ITEM;
    if (ListsEditor.isListsEditor(editor)) {
      ListsEditor.increaseDepth(editor);
      return;
    }
  } else if (type === RichTextFormats.UNINDENT && LIST_TYPES.includes(selectedNode.type)) {
    type = RichTextFormats.LIST_ITEM;
    if (ListsEditor.isListsEditor(editor)) {
      ListsEditor.decreaseDepth(editor);
      return;
    }
  }

  Transforms.unwrapNodes(editor, {
    match: (node: Node) => Element.isElement(node) && LIST_TYPES.includes(node.type as RichTextFormats),
    split: true,
  });

  // switch to default if the mark is active, add a list item if it's a list,
  // otherwise, use given format

  if (isActive || format === RichTextFormats.UNINDENT) {
    type = RichTextFormats.PARAGRAPH;
  } else if (isList) {
    type = RichTextFormats.LIST_ITEM;
  }

  Transforms.setNodes(editor, { type });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const toggleMark = (editor: Editor, format: RichTextFormats) => {
  if (isMarkActive(editor, format)) {
    editor.removeMark(format);
  } else {
    editor.addMark(format, true);
  }
};

type KeyMarkTuple = [(e: React.KeyboardEvent) => boolean, RichTextFormats];

const hotKeys: KeyMarkTuple[] = [
  [isKeyHotkey('mod+b'), RichTextFormats.BOLD],
  [isKeyHotkey('mod+i'), RichTextFormats.ITALIC],
  [isKeyHotkey('mod+u'), RichTextFormats.UNDERLINED],
  [isKeyHotkey('mod+/'), RichTextFormats.CODE],
];

const blockHotKeys: KeyMarkTuple[] = [
  [isKeyHotkey('mod+['), RichTextFormats.INDENT],
  [isKeyHotkey('mod+]'), RichTextFormats.UNINDENT],
];

export function getMark(event: React.KeyboardEvent): RichTextFormats | undefined {
  for (const [checkIsHotkey, mark] of hotKeys) {
    if (checkIsHotkey(event)) {
      return mark;
    }
  }
}
export function getBlock(event: React.KeyboardEvent): RichTextFormats | undefined {
  for (const [checkIsHotkey, block] of blockHotKeys) {
    if (checkIsHotkey(event)) {
      return block;
    }
  }
}

export function canDeleteIndentation(event: React.KeyboardEvent, editor: Editor): boolean {
  const selectedNode = editor.children[editor.selection!.anchor.path[0]];
  if (
    selectedNode.type === RichTextFormats.INDENT &&
    isKeyHotkey('backspace', event) &&
    isCursorFirstPosition(editor)
  ) {
    return true;
  }
  return false;
}

const isCursorFirstPosition = (editor: Editor): boolean => {
  if (editor.selection?.anchor.offset === 0 && editor.selection?.focus.offset === 0) {
    return true;
  }
  return false;
};

export const newLineIndentation = (event: React.KeyboardEvent, editor: Editor): boolean => {
  const selectedNode = editor.children[editor.selection!.anchor.path[0]];
  if (selectedNode.type === RichTextFormats.INDENT && isKeyHotkey('enter', event)) {
    return true;
  }
  return false;
};

type SlatePart = {
  type: string;
  children: Array<{
    text: string;
    children: Array<object>;
  }>;
};

export function isRichtextEmpty(descendants?: SlatePart[]) {
  if (descendants) {
    // Iterate over every child.text to see if theres any text in the editor
    for (const part of descendants) {
      if (part.type) {
        for (const child of part.children) {
          if (child?.text?.trim() || child?.children) {
            return false;
          }
        }
      }
    }
  }
  // value should never be null or undefined, but if it is the editor is empty.
  return true;
}

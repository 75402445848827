import React, { useMemo } from 'react';

import { useRoles } from 'features/organizations/useRoles';

import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';

export enum ResourceName {
  ACCOUNT = 'account',
  ACCOUNT_PLATFORM = 'accountPlatform',
  BRIEF_PUBLISHED = 'briefPublished',
  BRIEF_DRAFT = 'briefDraft',
  CARD = 'card',
  MARKETER_ACCOUNT = 'marketerAccount',
  ORGANIZATION = 'organization',
  REVISION = 'revision',
  ROLE = 'role',
  SUBMISSION = 'submission',
  USER_BRIEF = 'userBrief',
  USER_ROLE = 'userRole',
}

export type Action = 'create' | 'read' | 'update' | 'delete';

export interface Permission {
  resource: ResourceName;
  permissions: Action[];
}

export const usePermissions = () => {
  const { isSuperadmin } = useCurrentUser();
  const { getCurrentUserRole } = useRoles();

  const allPermissions = useMemo(() => {
    /**
     * get all permissions available as an array
     * Format:
     * [{ api: 'canEditAccount', pretty: 'Edit Account' }]
     */
    const permissions: {
      API: Permission;
      PRETTY: string;
      disabled?: boolean;
    }[] = [];

    Object.keys(PERMISSIONS_MAP).forEach((actionName) => {
      const permission = PERMISSIONS_MAP[actionName];

      permissions.push(permission);
    });

    return permissions;
  }, []);

  const getCurrentUserPermissions = (organizationId: number): string[] => {
    /**
     * returns an array of the permissions apis they have
     * Format:
     * ['canEditAccount']
     */
    const role = getCurrentUserRole(organizationId);
    return isSuperadmin ? Object.keys(allPermissions) : Object.keys(role?.permissions);
  };

  const isAuthorized = (
    organizationId: number,
    permission?: Permission | Permission[],
    trueForAll: boolean = false, // set to true if authorization check requires all permissions to be true
  ): boolean => {
    if (!permission) {
      // If no permission is provided, we assume that
      // no permission is needed
      return true;
    }
    const role = getCurrentUserRole(organizationId);
    if (isSuperadmin) return true;
    // creators will not have a role
    if (!role) return false;

    if (!Array.isArray(permission)) {
      const resourcePermission = role.permissions.find((perm) => perm.resource.name === permission.resource);
      if (!resourcePermission) return false;
      const isAuthorized = permission.permissions.every((action) => resourcePermission[action]);
      return isAuthorized;
    }
    const resourceNames = permission.map((perm) => perm.resource);
    const resourcePermissions = role.permissions.filter((perm) => resourceNames.includes(perm.resource.name));
    // if permission is an array and we want to check that user has ALL permissions
    if (trueForAll)
      return permission.every((perm) => {
        const resourcePermission = resourcePermissions.find(
          (resourcePerm) => resourcePerm.resource.name === perm.resource,
        );
        if (!resourcePermission) return false;
        const isAuthorized = perm.permissions.every((action) => resourcePermission[action]);
        return isAuthorized;
      });
    // if we just want to check if the user has ANY permission in the array
    return permission.some((perm) => {
      const resourcePermission = resourcePermissions.find(
        (resourcePerm) => resourcePerm.resource.name === perm.resource,
      );
      if (!resourcePermission) return false;
      const isAuthorized = perm.permissions.every((action) => resourcePermission[action]);
      return isAuthorized;
    });
  };

  const displayComponent = (
    component: React.ReactNode,
    organizationId: number,
    permission?: Permission | Permission[],
    trueForAll: boolean = false,
  ): React.ReactNode => {
    const authorized = isAuthorized(organizationId, permission, trueForAll);
    if (authorized) {
      return component;
    }
    return null;
  };

  return {
    allPermissions,
    getCurrentUserPermissions,
    isAuthorized,
    displayComponent,
  };
};

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.pagination-control': {
    marginBottom: theme.space[4],
    flexDirection: 'column',
    [mediaquery.md]: {
      flexDirection: 'row',
    },
    '&  > *:not(:last-child)': {
      marginRight: theme.space[3],
    },
  },
  '.pagination-control': {
    '&__hidden-input': {
      display: 'none',
    },
  },
});

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import searchBg from 'shared/images/search-bg.png';

export default (width: string) =>
  css({
    '&.search-bar': {},
    '.search-bar': {
      '&__input': {
        color: COLORS.BLACK,
        fontSize: '1rem',
        padding: '0.25rem 0.5rem 0.25rem 2rem',
        border: `1px solid ${COLORS.NEUTRAL200}`,
        width,
        borderRadius: '10px',
        background: `${COLORS.WHITE} url(${searchBg}) 8px 7px no-repeat`,
        backgroundSize: '18px 18px',
        margin: 0,
        [mediaquery.md]: {
          color: COLORS.BLACK,
          padding: ' 0.4rem 1rem 0.4rem 2.5rem',
          background: `${COLORS.WHITE} url(${searchBg}) 10px 10px no-repeat`,
          backgroundSize: '18px 18px',
        },
      },
      '&__input:focus': {
        borderColor: COLORS.NEUTRAL300,
      },
      '&__input::placeholder': {
        color: COLORS.NEUTRAL300,
      },
    },
  });

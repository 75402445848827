/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';

import CollectionTileList from 'features/media/components/CollectionTileList';
import { useCollections } from 'features/media/useCollections';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import ProfilePanel from 'features/users/components/molecules/ProfilePanel';
import { Box, Flex, Text } from 'shared/components/display';
import SearchBar from 'shared/components/atoms/SearchBar';

import { USER_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Collection } from 'shared/typings/collections';
import { BrkfstUser } from 'shared/typings/user';

import creatorPortfolioStyles from './creatorPortfolioStyles';

const { BODY } = TEXT_VARIANTS;

const { PLACEHOLDER_EMPTY_SEARCH, PLACEHOLDER_OWN_COLLECTIONS, PLACEHOLDER_OTHER_COLLECTIONS } = USER_COPY;

interface Props {
  user: BrkfstUser;
  isCurrentUser: boolean;
}

const CreatorPortfolio: React.FC<Props> = ({ user, isCurrentUser }) => {
  const { collections, loading, totalCollections, getFilteredCollections, clearCollections } = useCollections();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const placeholder = useMemo(() => {
    if (searchTerm) return PLACEHOLDER_EMPTY_SEARCH;
    return isCurrentUser ? PLACEHOLDER_OWN_COLLECTIONS : PLACEHOLDER_OTHER_COLLECTIONS;
  }, [searchTerm, isCurrentUser]);

  const [filteredCollections, setFilteredCollections] = useState<Collection[]>([]);

  useEffect(() => {
    if (user.id) {
      getFilteredCollections({
        creatorId: user.id,
        publicAccess: true,
        onSuccess: setFilteredCollections,
      });
    }
    return () => {
      clearCollections();
    };
  }, [user.id]);

  return (
    <Box css={creatorPortfolioStyles} className="creator-portfolio">
      <ProfilePanel
        HeaderComponent={
          <>
            <Flex className="creator-portfolio__header-text-wrapper">
              <Text className="creator-portfolio__header-text">{USER_COPY.HEADING_PORTFOLIO}</Text>
              <Text className="creator-portfolio__header-secondary-text">{` (${totalCollections})`}</Text>
            </Flex>
            <Flex className="creator-portfolio__header-right-wrapper">
              <SearchBar
                placeholder={USER_COPY.PLACEHOLDER_SEARCH_PORTFOLIO}
                arrayToSearch={collections}
                propertyToSearch="name"
                parentStateSetter={setFilteredCollections}
                onSearchChanged={(searchTerm: string) => {
                  setSearchTerm(searchTerm);
                }}
                width="300px"
              />
            </Flex>
          </>
        }
      >
        <Box>
          {loading ? (
            <CenteredSpinner />
          ) : filteredCollections.length > 0 ? (
            <Box className="creator-portfolio__wrapper">
              <CollectionTileList
                data={filteredCollections}
                tileSize="187px"
                creatorCollection
                addLink
                stacked
                singleLine
                showFileNum
              />
            </Box>
          ) : (
            <Flex justifyContent="center" alignItems="center">
              <Text variant={BODY} className="creator-portfolio__placeholder">
                {placeholder}
              </Text>
            </Flex>
          )}
        </Box>
      </ProfilePanel>
    </Box>
  );
};

export default CreatorPortfolio;

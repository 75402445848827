import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '&.marketer-file-overlay-icons': {
    borderRadius: '3px',
    padding: '0 0 0 5px',
    alignItems: 'center',
    '&--gallery:has(svg)': {
      backgroundColor: `${COLORS.BLACK}75`,
    },
    '&--gallery .marketer-file-overlay-icons__icon': {
      color: COLORS.WHITE,
    },
    '&--list .marketer-file-overlay-icons__icon': {
      color: `${COLORS.BLACK}A0`,
    },
  },
  '.marketer-file-overlay-icons': {
    '&__icon': {
      paddingRight: '5px',
    },
  },
});

import React, { useState } from 'react';
import { Button } from 'rebass/styled-components';
import cs from 'classnames';

import ActionButton from 'shared/components/molecules/ActionButton';
import Modal from 'shared/components/molecules/Modal';
import Prompt from 'shared/components/molecules/Prompt';

import { SIZING } from 'shared/config/formatting';

interface Props {
  cancelButtonText?: string;
  confirmButtonText?: string;
  disabled?: boolean;
  modalSize?: string;
  onConfirm: () => void;
  text: string;
  title?: string;
  loading?: boolean;
  className?: string;
  children?: string | React.ReactElement | (string | React.ReactElement)[];
  variant?: string;
  ariaLabel?: string;
  onClick?: (e: any) => void;
  showCancelButton?: boolean;
  dataCy?: string;
}

const PromptButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      cancelButtonText = 'Cancel',
      children,
      className,
      confirmButtonText = 'Confirm',
      disabled,
      loading,
      modalSize = SIZING.SMALL,
      onConfirm,
      text,
      title = '',
      variant,
      ariaLabel,
      onClick,
      showCancelButton = true,
      dataCy,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = (e) => {
      setIsOpen((prev) => !prev);
      if (onClick) {
        onClick(e);
      }
    };

    return (
      <>
        {loading !== undefined ? (
          <ActionButton
            ref={ref}
            variant={variant}
            type="button"
            onClick={toggleModal}
            loading={loading}
            disabled={disabled}
            className={cs(className)}
            dataCy={dataCy}
          >
            {children}
          </ActionButton>
        ) : (
          <Button
            ref={ref}
            variant={variant}
            type="button"
            onClick={toggleModal}
            disabled={disabled}
            className={cs(className)}
          >
            {children}
          </Button>
        )}
        <Modal
          isOpen={isOpen}
          modalSize={modalSize}
          title={title}
          onRequestClose={toggleModal}
          component={
            <Prompt
              onRequestClose={toggleModal}
              confirmButtonText={confirmButtonText}
              cancelButtonText={cancelButtonText}
              confirmCallback={onConfirm}
              text={text}
              showCancelButton={showCancelButton}
            />
          }
          ariaRole="alertdialog"
          ariaLabel={ariaLabel}
        />
      </>
    );
  },
);

export default PromptButton;

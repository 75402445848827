import 'react-table/react-table.css';
import 'react-table-hoc-fixed-columns/lib/styles.css';

import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { Button } from 'rebass/styled-components';
import cs from 'classnames';
import { debounce, get, orderBy } from 'lodash';

import PerformancePagination from 'features/performance/components/molecules/PerformancePagination';
import BreakdownSubComponent from 'features/performance/TableComponents/BreakdownSubComponent';
import { Box, Flex } from 'shared/components/display';

import { PERFORMANCE_DATA_TYPES } from 'shared/config/performance';
import { RESOURCES } from 'shared/config/resourceNames';
import { usePerformanceTableColumns } from 'shared/hooks/Performance/performanceHooks';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { usePerformance } from 'shared/hooks/usePerformance';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';

import styles, { CLASSNAMES, MAIN_CLASS } from './styles';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const { TEXT_ONLY } = BUTTON_VARIANTS;
const { TOP_BOTTOM_ANALYSIS } = PERFORMANCE_DATA_TYPES;
const { GREEN100, RED50, WHITE } = COLORS;

interface Props {
  hasConfigChanged?: boolean;
  submitDataRequest?: () => void;
  activeAccountPlatform?: boolean;
}

interface State {
  tableType: string;
  tableData: any;
  tableSummary: any;
  currentPages: number;
  resized: [];
}
/** ******* Performance Table ********* */
const PerformanceTable = ({
  hasConfigChanged = false,
  submitDataRequest = () => {},
  activeAccountPlatform = true,
}: Props) => {
  const {
    updatePage,
    updatePageSize,
    committedFields,
    committedDateRanges,
    commitAndFetchPerformanceData,
    performanceData,
    performanceState: { page: currentPage, committedParams, level, pageSize: currentPageSize },
  } = usePerformance();

  const { loading } = useComponentLoading(RESOURCES.PERFORMANCE, activeAccountPlatform);

  const [state, setState] = useState<State>({
    tableType: 'Default',
    tableData: [],
    tableSummary: {},
    currentPages: -1,
    resized: [],
  });

  const { resized, tableType, tableData, tableSummary, currentPages } = state;

  useEffect(() => {
    const { type, metrics, pages, pageSize, summary } = performanceData;
    if (metrics) {
      if (type === TOP_BOTTOM_ANALYSIS) {
        setState(({ resized: prevResized }) => ({
          resized: prevResized,
          tableType: type,
          tableData: metrics,
          tableSummary: summary,
          currentPages: 1,
        }));
        updatePageSize(metrics.length);
      } else {
        setState(({ resized: prevResized }) => ({
          resized: prevResized,
          tableType: type,
          tableData: metrics,
          tableSummary: summary,
          currentPages: pages,
        }));
        updatePageSize(pageSize);
      }
    }
  }, [performanceData]);

  const fetchSortedPerformanceData = (page, sorted, pageSize = 10) => {
    if (!loading) {
      // Multi-sort is not implemented at the moment so sorted variable will always contain 1 element
      const { id: field, desc } = sorted[0];
      if (performanceData.type === TOP_BOTTOM_ANALYSIS) {
        const sortedData = orderBy(tableData, (obj) => parseFloat(get(obj, field)) || get(obj, field), [
          desc ? 'desc' : 'asc',
        ]);
        setState((prev) => ({
          ...prev,
          tableData: sortedData,
        }));
      } else {
        commitAndFetchPerformanceData({
          ...committedParams,
          page,
          limit: pageSize,
          // @ts-ignore
          sort: JSON.stringify({
            field,
            order: desc ? 'descending' : 'ascending',
          }),
        });
      }
    }
  };

  const onFetchData = ({ page, pageSize, sorted }) => {
    if (sorted.length) {
      fetchSortedPerformanceData(page, sorted, pageSize);
    } else if (currentPageSize !== pageSize || currentPage !== page) {
      commitAndFetchPerformanceData({
        ...committedParams,
        limit: pageSize,
        page,
      });
    }
  };

  const onPageSizeChange = (newPageSize) => {
    updatePage();
    updatePageSize(newPageSize);
  };

  const highlightRows = (_, rowInfo) => {
    const style = {
      background: WHITE,
    };
    if (rowInfo) {
      const {
        original: { top, bottom },
      } = rowInfo;
      if (top) {
        style.background = GREEN100;
      } else if (bottom) {
        style.background = RED50;
      }
    }
    return { style };
  };

  const ptColumns = usePerformanceTableColumns(committedFields, tableType, tableSummary, committedDateRanges);

  /**
   * useCallback is used to keep a reference of the same debounced function
   * throughout the lifecycle of the component. Debouncing the onResizedChange
   * to delay invoking the function until 100ms have elapsed, this prevents
   * updating the state more often than needed.
   */
  const onResizedChange = debounce((newResized) => {
    setState((prev) => ({
      ...prev,
      resized: newResized,
    }));
  }, 500);

  const expanded = tableData.map(() => true); // Used to toggle expanded rows on at all times, it will render normally when breakdowns aren't present.

  return (
    <Flex className={MAIN_CLASS} css={styles}>
      <ReactTableFixedColumns
        className={cs({ [CLASSNAMES.table]: true, [CLASSNAMES.tableLong]: tableData.length >= 5 })}
        page={currentPage}
        pages={currentPages}
        data={tableData}
        columns={ptColumns}
        pageSize={currentPageSize}
        showPageSizeOptions
        showPageJump
        noDataText="No rows found" // Reminder to use errors in ui-slice when implemented.
        loading={loading}
        manual
        expanded={expanded}
        minRows={1}
        onPageChange={updatePage}
        onPageSizeChange={onPageSizeChange}
        // Always return to first page when a column is clicked to sort
        // @ts-ignore
        onSortedChange={updatePage}
        onFetchData={onFetchData}
        onResizedChange={onResizedChange}
        getTrProps={highlightRows}
        SubComponent={(props) => (
          <BreakdownSubComponent
            {...props}
            level={level}
            resizedColumns={resized}
            columns={committedFields}
            dateRanges={committedDateRanges}
          />
        )}
        PaginationComponent={(props) => {
          return <PerformancePagination {...props} total={tableSummary.total || 0} />;
        }}
      >
        {/* 
                    Functional Rendering to access internal components for positioning 
                    Overlay over the div rendered by react-table. 
                    see: https://github.com/tannerlinsley/react-table/tree/v6#functional-rendering     
                */}
        {(_, makeTable) => (
          <>
            {makeTable()}
            <Box className={cs({ [CLASSNAMES.overlay]: true, [CLASSNAMES.overlayDisplay]: hasConfigChanged })}>
              <Button
                variant={TEXT_ONLY}
                onClick={() => {
                  // @ts-ignore
                  submitDataRequest({
                    limit: state.tableType === TOP_BOTTOM_ANALYSIS ? 10 : currentPageSize,
                  });
                }}
                disabled={loading}
                className={CLASSNAMES.overlayContentBtn}
              >
                Click Here to Apply Updates & Refresh Table
              </Button>
            </Box>
          </>
        )}
      </ReactTableFixedColumns>
    </Flex>
  );
};

export default PerformanceTable;

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.day-picker-modal': {
    alignItems: 'center',
    '&__modal-content': {
      paddingLeft: '20px',
    },
  },
  '.day-picker-modal': {
    '&__btn': {
      padding: theme.space[2],
      paddingRight: theme.space[3],
    },
    '&__btn svg': {
      color: theme.colors.NEUTRAL900,
    },
  },
});

import React, { useRef } from 'react';
import { Box, Button, Flex, Text } from 'shared/components/display';
import Tag from 'shared/components/atoms/Tag';
import { Form, Formik, FormikConfig } from 'formik';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import SetActiveDefaults from './SetActiveDefaults';
import DropdownField from './DropdownField';
import { filterFormStyles } from './styles';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { isEqual } from 'lodash';

type DisplayAccessor<Values = any> = (val: Values) => string | string[];
type ResetValue<Values = any> = (display: string, initialValue: Values, value: Values) => any;

export type ValueAccessorMethods<Values = any> = {
  getDisplay: DisplayAccessor<Values>;
  resetValue: ResetValue<Values>;
};
export type ValueAccessorMap<Values = any> = Record<string, ValueAccessorMethods<Values>>;

interface Props {
  children: (React.ReactElement | false)[] | React.ReactElement;
  // used to map the values to a displayable string
  // only include the fields that should be displayed in the active filters
  // and how to reset a field to its initial value
  valueAccessorMap: ValueAccessorMap;
}
const FilterForm = <Values extends {}>({
  children,
  valueAccessorMap,
  ...formikProps
}: Props & Omit<FormikConfig<Values>, 'children'>) => {
  // save initialValues because formik updates them when resetForm is called
  const initialValues = useRef(formikProps.initialValues);

  return (
    <Formik<Values> {...formikProps}>
      {({ resetForm, values, submitForm }) => (
        <Form>
          {children}
          <Box css={filterFormStyles} className="filter-form__active-filters">
            <Text variant={TEXT_VARIANTS.CATEGORY} className="filter-form__active-filters__label">
              Active Filters:
            </Text>
            <Flex className="filter-form__active-filters__tags">
              {Object.keys(values).map((filter) => {
                const valueIsSet = !isEqual(values[filter], initialValues.current[filter]);
                const display = valueIsSet ? valueAccessorMap[filter]?.getDisplay(values[filter]) : '';
                if (display && typeof display === 'string') {
                  return (
                    <Tag
                      onDelete={() => {
                        resetForm({ values: { ...values, [filter]: initialValues.current[filter] } });
                        submitForm();
                      }}
                      className="filter-form__active-filters__tag"
                      key={display}
                    >
                      {display}
                    </Tag>
                  );
                } else if (display && Array.isArray(display)) {
                  return display.map((item) => {
                    const initialVal = valueAccessorMap[filter]?.resetValue(
                      item,
                      initialValues.current[filter],
                      values[filter],
                    );

                    return (
                      <Tag
                        onDelete={() => {
                          resetForm({
                            values: {
                              ...values,
                              [filter]: initialVal,
                            },
                          });
                          submitForm();
                        }}
                        className="filter-form__active-filters__tag"
                        key={item}
                      >
                        {item}
                      </Tag>
                    );
                  });
                } else {
                  return null;
                }
              })}
            </Flex>
            <Button
              onClick={() => {
                resetForm({ values: initialValues.current });
                submitForm();
              }}
              variant={BUTTON_VARIANTS.TEXT_ONLY}
              disabled={isEqual(values, initialValues.current)}
              className="filter-form__active-filters__clear-btn"
              data-cy="filter-form__clear-all-btn"
              type="button"
            >
              Clear all
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

FilterForm.DropdownField = DropdownField;
FilterForm.SetActiveDefaults = SetActiveDefaults;

export default FilterForm;

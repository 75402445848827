export const ACCOUNT_TOASTS = {
  LOGO_REMOVED: 'Logo removed.',
  LOGO_ADDED: 'Logo updated.',
  ACCOUNT_CREATED: 'Account successfully created',
  ACCOUNT_UPDATED: 'Account successfully updated',
  ACCOUNT_PRICING_UPDATED: 'Account pricing successfully updated',
  EDIT_ACCOUNT_DENIED: 'You do not have permission to edit this account',
  ACCOUNT_DELETED: 'Account successfully deleted',
  BRAND_ASSET_DELETED: 'Brand asset successfully deleted',
  BANNER_REMOVED: 'Banner removed',
  COMMENTS_ENABLED: 'Comments enabled',
  COMMENTS_DISABLED: 'Comments disabled',
  EMAIL_NOTIFICATIONS_ENABLED: 'Email Notifications enabled',
  EMAIL_NOTIFICATIONS_DISABLED: 'Email Notifications disabled',
  CREDIT_CARD_CHARGES_ENABLED: 'Credit Card Charges enabled',
  CREDIT_CARD_CHARGES_DISABLED: 'Credit Card Charges disabled',
  ACCOUNT_HEALTH_CHARGES_ENABLED: 'Ad Health Score enabled',
  ACCOUNT_HEALTH_CHARGES_DISABLED: 'Ad Health Score disabled',
  AD_HEALTH_MACHINE_LEARNING_ENABLED: 'Ad Health Machine Learning enabled',
  AD_HEALTH_MACHINE_LEARNING_DISABLED: 'Ad Health Machine Learning disabled',
};

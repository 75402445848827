import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.mobile-brief-creator-buttons': {
    // These styles should only apply when there is a wrapper and not just a single button
    marginTop: theme.space[3],
    alignItems: 'center',
    flexDirection: 'column',
    '&__btn': {
      marginTop: theme.space[3],
      width: 'calc(100% - 32px)',
      boxShadow: theme.shadows.SHADOW04,
      [mediaquery.md]: {
        marginTop: 0,
        boxShadow: 'none',
        width: 'unset',
      },
    },
    '&__btn--white': {
      backgroundColor: COLORS.WHITE,
    },
  },
  '.mobile-brief-creator-buttons': {
    '&__text': {
      color: COLORS.PRIMARY400,
    },
    '&__btn': {
      width: 'calc(100% - 32px)',
      boxShadow: theme.shadows.SHADOW04,
      [mediaquery.md]: {
        marginTop: 0,
        boxShadow: 'none',
        width: 'unset',
      },
    },
    '&__btn--white': {
      backgroundColor: COLORS.WHITE,
    },
  },
});

import React, { useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { useOrganizations } from 'features/organizations/useOrganizations';
import { CenteredSpinnerContainer } from 'features/ui/CenteredSpinner';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { isExistingRoute } from 'shared/utilities/routeUtility';

const RedirectMarketer = () => {
  const { lastVisitedOrg, redirectURI = '', updateRedirectURI } = useCurrentUser();
  const { loading: orgLoading, organizations } = useOrganizations();
  const { accounts } = useAccounts();

  const [firstOrg] = organizations;
  const redirectOrganizationId = lastVisitedOrg || firstOrg.id;

  const shouldRedirect = useMemo(() => {
    const isValidRoute = isExistingRoute(UserType.ORG, redirectURI);

    return isValidRoute && redirectURI !== '' && redirectURI !== '/';
  }, [redirectURI]);

  useEffect(() => {
    return () => {
      updateRedirectURI('');
    };
  }, []);

  if (orgLoading) {
    return <CenteredSpinnerContainer />;
  }
  if (shouldRedirect) {
    return <Navigate to={redirectURI} />;
  }
  if (accounts.length === 1) {
    return (
      <Navigate
        to={LinkCreator.createLink({
          routeKey: 'ACCOUNT',
          variables: {
            organizationId: redirectOrganizationId,
            accountId: accounts[0].id,
          },
        })}
      />
    );
  }
  return (
    <Navigate
      to={LinkCreator.createLink({
        routeKey: 'ORGANIZATION',
        variables: {
          organizationId: redirectOrganizationId,
        },
      })}
    />
  );
};

export default RedirectMarketer;

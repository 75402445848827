import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { SingleValue } from 'react-select';
import { Field, Form, Formik, FormikProps } from 'formik';
import { startCase } from 'lodash';

import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import { FACEBOOK_FIELDNAMES } from 'features/organizations/components/organisms/OrgOnboardingStepFields';
import { Box, Flex, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import Modal from 'shared/components/molecules/Modal';
import { SelectInput, SelectValue } from 'shared/components/molecules/Select';
import ToggleInput from 'shared/components/ToggleInput';

import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_FACEBOOK_ROUTES } from 'shared/config/routes/api/apiFacebookRoutes';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useQuery } from 'shared/hooks/useQuery';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import theme from 'shared/styles/themes';

import styles from './styles';
import { validationSchema } from './validations';

interface FormValues {
  shouldCreateAdAccount: boolean;
  fbPage: SelectValue | null;
  fbPixel: SelectValue | null;
  fbAdAccount: SelectValue | null;
  fbBusiness: SelectValue | null;
  igAccount: SelectValue | null;
  productCatalog: SelectValue | null;
}

const initialValues: FormValues = {
  shouldCreateAdAccount: false,
  fbPage: null,
  fbPixel: null,
  fbAdAccount: null,
  fbBusiness: null,
  igAccount: null,
  productCatalog: null,
};

const initialState = {
  pages: [],
  pixels: [],
  adAccounts: [],
  instagramAccounts: [],
  productCatalogs: [],
};

const FacebookAccountCreationForm = () => {
  const { createAccountPlatform } = useAccountPlatforms();

  const dispatch = useDispatch();
  const { getQuery, setQuery } = useQuery();
  const { fbBusinesses, token: fbAccessToken, modalOpen, platform } = getQuery();
  const parsedBusinesses = useMemo(() => (fbBusinesses ? JSON.parse(fbBusinesses) : []), [fbBusinesses]);
  const { loading } = useComponentLoading(RESOURCES.FACEBOOK_BUSINESS_ASSET, false);
  const { loading: igLoading } = useComponentLoading(RESOURCES.INSTAGRAM, false);

  const [isOpen, setModalOpen] = useState(false);
  const [shouldCreateAdAccount, setCreateAdAccount] = useState(false);
  const [isPerformance, setIsPerformance] = useState(false);
  const [state, setState] = useState(initialState);
  const { pages, pixels, adAccounts, instagramAccounts, productCatalogs } = state;

  useEffect(() => {
    if (modalOpen && platform === 'facebook') {
      setModalOpen(true);
    }
  }, [modalOpen]);

  const onClose = () => {
    setModalOpen(false);
    setQuery({}, true);
  };

  const queryAssets = useCallback(
    (business: SingleValue<SelectValue>, formikProps: FormikProps<FormValues>) => {
      dispatch(
        apiAction({
          path: {
            route: `${API_FACEBOOK_ROUTES.BUSINESS_ASSETS}/${business?.value}`,
          },
          params: {
            token: fbAccessToken,
          },
          entity: RESOURCES.FACEBOOK_BUSINESS_ASSET,
          onSuccess: ({ data }) => {
            if (data.adAccounts.length < 1) {
              formikProps.setFieldValue(FACEBOOK_FIELDNAMES.SHOULD_CREATE_AD_ACCOUNT, true);
            } else {
              formikProps.setFieldValue(FACEBOOK_FIELDNAMES.SHOULD_CREATE_AD_ACCOUNT, false);
            }
            setState((prev) => ({
              ...prev,
              pages: data.pages,
              pixels: data.pixels,
              productCatalogs: data.productCatalogs,
              adAccounts: data.adAccounts,
            }));
          },
        }),
      );
    },
    [fbAccessToken],
  );

  const queryInstagramAccounts = (page: SingleValue<SelectValue>) => {
    if (page)
      dispatch(
        apiAction({
          path: {
            route: `${API_FACEBOOK_ROUTES.BUSINESS_ASSETS}/page/${page.value}/instagram-accounts`,
          },
          entity: RESOURCES.INSTAGRAM,
          onSuccess: ({ data }) => {
            setState((prev) => ({
              ...prev,
              instagramAccounts: data,
            }));
          },
        }),
      );
  };

  const showPages = isPerformance && pages.length >= 1 && !loading;
  const showPageErrors = isPerformance && pages.length < 1 && !loading;
  const showPixels = isPerformance && pixels.length >= 1 && !loading;
  const showPixelErrors = isPerformance && pixels.length < 1 && !loading;
  const showInstagram = isPerformance && instagramAccounts.length >= 1 && !loading;
  const showProductCatalogs = isPerformance && productCatalogs.length >= 1 && !loading;
  const showAdAccounts = adAccounts.length >= 1 && !loading && !shouldCreateAdAccount;
  const showAdAccountWarnings = adAccounts.length < 1 && !loading;
  const showCreateError = (pixels.length < 1 || pages.length < 1) && !loading;

  return (
    <Formik
      validateOnBlur={false}
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        createAccountPlatform({ ...values, fbAccessToken, platform: 'facebook' });
        actions.setSubmitting(false);
        setModalOpen(false);
        setQuery({}, true);
      }}
    >
      {(formikProps) => (
        <Modal
          isOpen={isOpen}
          title={'Connect Facebook'}
          component={
            <Form>
              <Flex css={styles} className="account-creation-form">
                {parsedBusinesses?.length >= 1 && (
                  <>
                    <Flex className={'account-creation-form__input-loader'}>
                      <Field
                        width="275px"
                        name={'fbBusiness'}
                        component={SelectInput}
                        onChange={(event) => {
                          queryAssets(event, formikProps);
                          formikProps.setFieldValue(FACEBOOK_FIELDNAMES.FB_AD_ACCOUNT, null);
                          formikProps.setFieldValue(FACEBOOK_FIELDNAMES.FB_PAGE, null);
                          formikProps.setFieldValue(FACEBOOK_FIELDNAMES.FB_PIXEL, null);
                          formikProps.setFieldValue(FACEBOOK_FIELDNAMES.IG_ACCOUNT, null);
                          formikProps.setFieldValue(FACEBOOK_FIELDNAMES.PRODUCT_CATALOG, null);
                          setState(initialState);
                        }}
                        isSearchable
                        filterByLabel
                        placeholder={`${startCase(FACEBOOK_FIELDNAMES.FBBUSINESS)}`}
                        options={parsedBusinesses.map(({ id, name }) => ({ value: id, label: name }))}
                      />
                      <TailSpin
                        color={COLORS.PRIMARY200}
                        height="25"
                        width="25"
                        visible={loading}
                        wrapperClass="account-creation-form__loader"
                      />
                    </Flex>
                    <Box className={'account-creation-form__input'}>
                      {showAdAccounts && (
                        <Field
                          width="275px"
                          name={FACEBOOK_FIELDNAMES.FB_AD_ACCOUNT}
                          component={SelectInput}
                          isSearchable
                          filterByLabel
                          placeholder="Facebook Ad Account"
                          options={adAccounts.map(({ account_id, name }) => ({ value: account_id, label: name }))}
                        />
                      )}
                    </Box>
                    {showAdAccounts && (
                      <Field
                        onChange={(event) => {
                          setIsPerformance(event.target.checked);
                          setCreateAdAccount(false);
                          formikProps.setFieldValue(FACEBOOK_FIELDNAMES.FB_PAGE, null);
                          formikProps.setFieldValue(FACEBOOK_FIELDNAMES.FB_PIXEL, null);
                          formikProps.setFieldValue(FACEBOOK_FIELDNAMES.IG_ACCOUNT, null);
                          formikProps.setFieldValue(FACEBOOK_FIELDNAMES.PRODUCT_CATALOG, null);
                        }}
                        name={'isPerformance'}
                        component={ToggleInput}
                        label="I want to create ads"
                        disabled={adAccounts.length < 1}
                      />
                    )}
                  </>
                )}
                {showPages && (
                  <Flex className={'account-creation-form__input-loader'}>
                    <Field
                      width="275px"
                      onChange={queryInstagramAccounts}
                      name={FACEBOOK_FIELDNAMES.FB_PAGE}
                      component={SelectInput}
                      isSearchable
                      filterByLabel
                      placeholder="Facebook Page"
                      options={pages.map(({ id, name }) => ({ value: id, label: name }))}
                    />
                    <TailSpin
                      color={COLORS.PRIMARY200}
                      height="25"
                      width="25"
                      visible={igLoading}
                      wrapperClass="account-creation-form__loader"
                    />
                  </Flex>
                )}
                {showPageErrors && formikProps.values.fbBusiness && (
                  <Text variant={TEXT_VARIANTS.CAPTION} className="account-creation-form__message">
                    No Facebook Pages are associated with {formikProps.values.fbBusiness?.label || 'the business'}.
                  </Text>
                )}
                {showInstagram && (
                  <Box className={'account-creation-form__input'}>
                    <Field
                      width="275px"
                      name={FACEBOOK_FIELDNAMES.IG_ACCOUNT}
                      component={SelectInput}
                      isSearchable
                      filterByLabel
                      placeholder="Instagram Account"
                      options={instagramAccounts.map(({ id, username }) => ({ value: id, label: username }))}
                    />
                  </Box>
                )}
                {showPixels && (
                  <Box className={'account-creation-form__input'}>
                    <Field
                      width="275px"
                      name={FACEBOOK_FIELDNAMES.FB_PIXEL}
                      isSearchable
                      filterByLabel
                      component={SelectInput}
                      placeholder="Facebook Pixel"
                      options={pixels.map(({ id, name }) => ({ value: id, label: name }))}
                    />
                  </Box>
                )}
                {showPixelErrors && formikProps.values.fbBusiness && (
                  <Text variant={TEXT_VARIANTS.CAPTION} className="account-creation-form__message">
                    No Facebook Pixels are associated with {formikProps.values.fbBusiness?.label || 'the business'}.
                  </Text>
                )}
                {showProductCatalogs && (
                  <Box className={'account-creation-form__input'}>
                    <Field
                      width="275px"
                      name={FACEBOOK_FIELDNAMES.PRODUCT_CATALOG}
                      component={SelectInput}
                      placeholder="Product Catalog"
                      options={productCatalogs.map(({ id, name }) => ({ value: id, label: name }))}
                      isSearchable
                      filterByLabel
                    />
                  </Box>
                )}
                {showAdAccountWarnings && formikProps.values.fbBusiness && (
                  <Text
                    variant={TEXT_VARIANTS.CAPTION}
                    color={COLORS.ORANGE700}
                    className={'account-creation-form__input'}
                  >
                    No Facebook Ad Accounts are associated with {formikProps.values.fbBusiness?.label || 'the business'}
                    .
                  </Text>
                )}
                <Flex className={'account-creation-form__submit-button'}>
                  <FormButton type="submit" disabled={loading || pages.length < 1 || pixels.length < 1}>
                    Create
                  </FormButton>
                  {formikProps.values.fbBusiness && showCreateError && (
                    <Text
                      style={{
                        marginLeft: theme.space[10],
                        marginBottom: theme.space[5],
                        color: COLORS.RED700,
                      }}
                      variant={TEXT_VARIANTS.CAPTION}
                    >
                      Missing prerequisites
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Form>
          }
          onRequestClose={onClose}
        />
      )}
    </Formik>
  );
};

export default FacebookAccountCreationForm;

import React from 'react';
import { Flex, Text } from 'shared/components/display';
import { UserBriefStatus } from 'shared/typings/userBriefs/enums';
import { Button } from 'rebass/styled-components';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { componentLoadingToggled } from 'features/ui/ui.slice';
import { RESOURCES } from 'shared/config/resourceNames';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { UserType } from 'shared/typings/user/enums';
import { BRIEF_COPY, SUBMISSION_COPY } from 'shared/config/copy';
import { BriefStatus, BriefTypes } from 'shared/typings/briefs/enums';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import ActionLink from 'shared/components/clicks/ActionLink';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { useSubmissions } from 'features/submissions/useSubmissions';
import { Brief } from 'shared/typings/briefs';
import styles from './styles';
import theme from 'shared/styles/themes';

interface Props {
  brief: Pick<Brief, 'id' | 'type' | 'status' | 'briefCreatorStatus'>;
  submissionId?: number;
  isDraft?: boolean;
}
const CreatorSubmissionActions: React.FC<Props> = ({ submissionId, isDraft, brief }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();
  const { createSubmission } = useSubmissions();

  const isEditable = brief.status !== BriefStatus.CLOSED;

  const navigateToSubmissionForm = () => {
    dispatch(
      componentLoadingToggled({
        component: RESOURCES.SUBMISSION,
        loading: true,
      }),
    );
    navigate(
      LinkCreator.createLink({
        userType: UserType.CREATOR,
        routeKey: 'CREATE_UPDATE_SUBMISSION',
        variables: {
          submissionId,
        },
      }),
    );
  };

  switch (brief.briefCreatorStatus) {
    case UserBriefStatus.ACTIVE:
      if (!currentUser.stripeAccountId && brief.type === BriefTypes.BRAND) {
        return (
          <Flex css={styles} className="creator-submission-actions">
            <ActionLink
              to={LinkCreator.createLink({
                userType: UserType.CREATOR,
                routeKey: 'ACCOUNT_SETTINGS',
                variables: {
                  userId: currentUser.id,
                },
              })}
              defaultText={BRIEF_COPY.LINK_STRIPE_NOT_CONNECTED}
              actionText={BRIEF_COPY.LINK_HOVER_STRIPE_NOT_CONNECTED}
              width="150px"
            />
            <Button ml={4} disabled>
              {BRIEF_COPY.BUTTON_SUBMIT_BRIEF}
            </Button>
          </Flex>
        );
      }
      return (
        // TODO: fix styles, for some reason className styles are not applying
        // so adding them directly for now
        <Flex
          css={styles}
          className="creator-submission-actions"
          style={{
            alignItems: 'center',
          }}
        >
          <Text
            className="creator-submission-actions__label"
            color={COLORS.PRIMARY400}
            variant={TEXT_VARIANTS.SUBHEADING}
            style={{
              marginBottom: 0,
              marginRight: theme.space[3],
            }}
          >
            {BRIEF_COPY.LABEL_APPROVED}
          </Text>
          <Button
            onClick={() => createSubmission(brief.id)}
            className="creator-submission-actions__submission-btn"
            data-cy="creator-submission-actions__submission-btn"
            disabled={brief.status === BriefStatus.CLOSED}
          >
            {BRIEF_COPY.BUTTON_SUBMIT_BRIEF}
          </Button>
        </Flex>
      );
    case UserBriefStatus.DRAFTED:
      return (
        <Button data-cy="creator-submission-actions__finish-submission-btn" onClick={navigateToSubmissionForm}>
          {BRIEF_COPY.BUTTON_COMPLETE_BRIEF_SUBMISSION}
        </Button>
      );
    case UserBriefStatus.SUBMITTED:
    case UserBriefStatus.REVISION:
      if (!isEditable) {
        return null;
      }
      return (
        <Button
          variant={isDraft ? BUTTON_VARIANTS.PRIMARY : BUTTON_VARIANTS.PLAIN}
          onClick={navigateToSubmissionForm}
          className="submission-header__edit-btn"
          data-cy="submission-header__edit-btn"
        >
          {isDraft ? SUBMISSION_COPY.BUTTON_EDIT_SUBMISSION_DRAFT : SUBMISSION_COPY.BUTTON_EDIT_SUBMISSION}
        </Button>
      );
    default:
      return null;
  }
};

export default CreatorSubmissionActions;

import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import FacebookNav from 'features/home/components/molecules/FacebookNav';
import TikTokNav from 'features/home/components/molecules/TikTokNav/TikTokNav';
import platformSidebarNavStyles from 'features/home/components/organisms/PlatformSidebarNav/platformSidebarNavStyles';
import NavItem from 'shared/components/atoms/NavItem';
import SidebarNavBar from 'shared/components/atoms/SidebarNavBar';
import Skeleton from 'shared/components/atoms/Skeleton';
import { Box } from 'shared/components/display';

import { PlatformShortNames } from 'shared/typings/accountPlatform/enums';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

interface Props {
  expanded?: boolean;
}

const PlatformSidebarNav: React.FC<Props> = ({ expanded }) => {
  const params = useParams<{ organizationId: string; accountId: string; accountPlatformId }>();
  const navigate = useNavigate();

  const organizationId = +params.organizationId;
  const accountId = +params.accountId;
  const accountPlatformId = +params.accountPlatformId;

  const { accountPlatforms, platformsLoading, newPlatformLoading } = useAccountPlatforms({
    accountId,
  });

  const singleAccountPlatform = accountPlatforms.length === 1;

  const goToPlatform = (id) => () =>
    navigate(
      LinkCreator.createLink({
        userType: UserType.ORG,
        routeKey: 'PLATFORM',
        variables: {
          organizationId,
          accountId,
          accountPlatformId: id,
        },
      }),
    );

  return (
    <Box className="platform-sidebar-nav" css={platformSidebarNavStyles}>
      {platformsLoading ? (
        <Box className="platform-sidebar-nav__loading">
          <Skeleton height={24} />
        </Box>
      ) : (
        <SidebarNavBar className="platform-sidebar-nav__heading">
          <NavItem
            text="Platforms"
            path={LinkCreator.createLink({
              userType: UserType.ORG,
              routeKey: 'PLATFORMS',
              variables: {
                organizationId,
                accountId,
              },
            })}
            tabIndex={expanded ? 0 : -1}
            exact
          />
        </SidebarNavBar>
      )}
      <Box className="platform-sidebar-nav__scrollable-area">
        {accountPlatforms.map((platform) => {
          if (platform.platform.shortenedName === PlatformShortNames.FACEBOOK) {
            return (
              <FacebookNav
                key={platform.name}
                organizationId={organizationId}
                accountId={accountId}
                facebookPlatform={platform}
                onIconClick={accountPlatforms.length > 0 ? goToPlatform(platform?.id) : undefined}
                isActive={platform.id === accountPlatformId || singleAccountPlatform}
                expanded={expanded}
              />
            );
          }
          if (platform.platform.shortenedName === PlatformShortNames.TIKTOK) {
            return (
              <TikTokNav
                key={platform.name}
                organizationId={organizationId}
                accountId={accountId}
                tiktokPlatform={platform}
                isActive={platform.id === accountPlatformId || singleAccountPlatform}
                onIconClick={accountPlatforms.length > 0 ? goToPlatform(platform?.id) : undefined}
                expanded={expanded}
              />
            );
          }
          return <></>;
        })}

        {newPlatformLoading && (
          <Box className="platform-sidebar-nav__loading">
            <Skeleton height={24} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PlatformSidebarNav;

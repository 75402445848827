import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { userChanged, profilePictureUploaded, profilePictureRemoved } from 'features/users/hooks/user.slice';
import { orgUserChanged } from 'features/organizations/orgUsers.slice';
import { organizationAdded } from 'features/organizations/organizations.slice';
import { BrkfstUser } from 'shared/typings/user';
import { Organization } from 'shared/typings/organizations';
import { BrkfstRole } from 'shared/typings/role';

interface AuthState {
  user: BrkfstUser;
  inviteEmail?: string | null;
  redirectURI?: string;
}

type AuthAction<T = BrkfstUser> = PayloadAction<{ data: T }>;

export const authSlice = createSlice<AuthState, SliceCaseReducers<AuthState>, 'currentUser'>({
  name: 'currentUser',
  initialState: { user: <BrkfstUser>{}, inviteEmail: null, redirectURI: '' },
  reducers: {
    loggedIn: (currentUser, action: PayloadAction<BrkfstUser>) => {
      currentUser.user = action.payload;
    },
    loggedOut: () => ({
      user: <BrkfstUser>{},
    }),
    currentUserChanged: (currentUser, action: PayloadAction<BrkfstUser>) => {
      // @ts-ignore
      currentUser.user = {
        ...currentUser.user,
        ...action.payload,
      };
    },
    portfolioChecked: (currentUser, action: AuthAction<boolean>) => {
      // @ts-ignore
      currentUser.user = {
        ...currentUser.user,
        hasPortfolio: action.payload.data,
      };
    },
    redirectURISet: (currentUser, action: PayloadAction<string>) => {
      currentUser.redirectURI = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(userChanged, (currentUser, action) => {
        if (currentUser.user.id === action.payload.data.id) {
          // @ts-ignore
          currentUser.user = {
            ...currentUser.user,
            ...action.payload.data,
          };
        }
      })
      .addCase(profilePictureUploaded, (currentUser, action) => {
        currentUser.user.profilePic = action.payload.data.profilePic;
        currentUser.user.profilePicId = action.payload.data.profilePicId;
      })
      .addCase(profilePictureRemoved, (currentUser) => {
        currentUser.user.profilePic = null;
        currentUser.user.profilePicId = null;
      })
      .addCase(orgUserChanged, (currentUser, action) => {
        if (currentUser.user.id === action.payload.data.id) {
          // @ts-ignore
          currentUser.user = {
            ...currentUser.user,
            ...action.payload.data,
          };
        }
      })
      .addCase(organizationAdded, (currentUser, action: AuthAction<Organization>) => {
        currentUser.user.onboarded = true;
        if (!currentUser.user.roles) {
          // @ts-ignore
          currentUser.user.roles = [<BrkfstRole>action.payload.data.currentRole];
        } else {
          currentUser.user.roles.push(<BrkfstRole>action.payload.data.currentRole);
        }
      }),
});

export const { loggedIn, loggedOut, currentUserChanged, portfolioChecked, redirectURISet } = authSlice.actions;

// selectors
export const currentUser = (state) => {
  return state.currentUser.user;
};

// get user's role in current org
export const getCurrentUserRole = (organizationId) => (state) => {
  const role = state.currentUser.user.roles.find((ro) => ro.organizationId === organizationId);

  return role || null;
};

export const getRedirectUri = (state): string => {
  return state.currentUser.redirectURI;
};

// TO DO: implement isAuthorized, isOwner, isVerified

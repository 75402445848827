import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.trend-file-input': {
    marginTop: theme.space[3],
  },
  '.trend-file-input': {
    '&__file': {
      alignItems: 'center',
    },
    '&__file-btn': {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '&__file-name': {
      marginBottom: 0,
      marginLeft: theme.space[2],
    },
    '&__remove-btn': {
      borderRadius: '5px',
    },
  },
});

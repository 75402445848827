import React from 'react';

import orgSettingsSidebarStyles from 'features/organizations/components/molecules/OrgSettingsSidebar/orgSettingsSidebarStyles';
import NavItem from 'shared/components/atoms/NavItem';
import SidebarNavBar from 'shared/components/atoms/SidebarNavBar';
import { Box } from 'shared/components/display';

import { ORGANIZATION_COPY } from 'shared/config/copy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { usePermissions } from 'shared/hooks/usePermissions';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LinkCreator, RouteKey } from 'shared/utilities/linkUtility';

interface Props {
  organizationId: number;
}
const OrgSettingsSidebar: React.FC<Props> = ({ organizationId }) => {
  const { isAuthorized } = usePermissions();
  const { isSuperadmin } = useCurrentUser();

  const makeLink = (routeKey: RouteKey) =>
    LinkCreator.createLink({
      routeKey,
      variables: { organizationId },
    });

  return (
    <Box className="org-settings-sidebar" css={orgSettingsSidebarStyles}>
      <SidebarNavBar className="org-settings-sidebar__nav-group">
        <NavItem
          variant={TEXT_VARIANTS.OVERLINE}
          text={ORGANIZATION_COPY.SETTINGS_NAV_BILLING}
          className="org-settings-sidebar__nav-header"
        />
        <NavItem
          variant={TEXT_VARIANTS.BODY_SM}
          text={ORGANIZATION_COPY.SETTINGS_NAV_OVERVIEW}
          path={makeLink('ORG_SETTINGS')}
          className="org-settings-sidebar__nav-item"
          exact
        />
        <NavItem
          variant={TEXT_VARIANTS.BODY_SM}
          text={ORGANIZATION_COPY.SETTINGS_NAV_PAYMENT}
          path={makeLink('CARD_SETTINGS')}
          className="org-settings-sidebar__nav-item"
        />
      </SidebarNavBar>
      <SidebarNavBar className="org-settings-sidebar__nav-group">
        <NavItem
          variant={TEXT_VARIANTS.OVERLINE}
          text={ORGANIZATION_COPY.SETTINGS_NAV_TEAM_MANAGEMENT}
          className="org-settings-sidebar__nav-header"
        />
        <NavItem
          variant={TEXT_VARIANTS.BODY_SM}
          text={ORGANIZATION_COPY.SETTINGS_NAV_USERS}
          path={makeLink('ORG_SETTINGS_USERS')}
          className="org-settings-sidebar__nav-item"
          exact
        />
        <NavItem
          variant={TEXT_VARIANTS.BODY_SM}
          text={ORGANIZATION_COPY.SETTINGS_NAV_ROLES}
          path={makeLink('ROLE_SETTINGS')}
          className="org-settings-sidebar__nav-item"
          exact
          hide={!isSuperadmin}
        />
      </SidebarNavBar>
      <SidebarNavBar className="org-settings-sidebar__nav-group">
        <NavItem
          variant={TEXT_VARIANTS.OVERLINE}
          text={ORGANIZATION_COPY.SETTINGS_NAV_ORGANIZATION}
          hide={!isAuthorized(organizationId, PERMISSIONS_MAP.EDIT_ORG.API)}
          className="org-settings-sidebar__nav-header"
        />
        <NavItem
          variant={TEXT_VARIANTS.BODY_SM}
          text={ORGANIZATION_COPY.SETTINGS_NAV_EDIT_DETAILS}
          path={makeLink('EDIT_ORG_DETAILS')}
          hide={!isAuthorized(organizationId, PERMISSIONS_MAP.EDIT_ORG.API)}
          className="org-settings-sidebar__nav-item"
          exact
        />
      </SidebarNavBar>
    </Box>
  );
};

export default OrgSettingsSidebar;

import { css } from '@emotion/react';

export default (width: string = '1em', height: string = '1em') =>
  css({
    '&.icon': {
      overflow: 'visible',
      display: 'inline-block',
      fontSize: 'inherit',
      height,
      width,
    },
  });

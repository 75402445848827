import React, { useState } from 'react';
import cs from 'classnames';
import { Box, Flex, Text } from 'shared/components/display';
import styles from './styles';
import { ModuleComment as ModuleCommentType } from 'shared/typings/moduleComments';
import AuthorTimeDisplay from 'shared/components/atoms/AuthorTimeDisplay';
import { TEXT_VARIANTS } from 'shared/styles/text';
import ModuleCommentForm from 'features/submissions/components/molecules/ModuleCommentForm';
import { ModuleCommentValues } from 'features/submissions/components/molecules/ModuleCommentForm/config';
import { SUBMISSION_COPY } from 'shared/config/copy';
import Skeleton from 'shared/components/atoms/Skeleton';
import DeleteButton from 'shared/components/atoms/DeleteButton';
import EditButton from 'shared/components/atoms/EditButton';

interface Props {
  comment: ModuleCommentType;
  onEdit: (comment: string, onFinish: () => void) => void;
  onDelete: (commentId: number, onFinish: () => void) => void;
  userIsAuthor?: boolean;
  isSuperadmin?: boolean;
}
const ModuleComment = React.forwardRef<HTMLElement, Props>(
  ({ comment, onEdit, onDelete, userIsAuthor = false, isSuperadmin = false }, ref) => {
    const [showEditForm, setShowEditForm] = useState(false);
    const [actionInProgress, setActionInProgress] = useState(false);

    const onDeleteComment = () => {
      setActionInProgress(true);
      onDelete(comment.id, () => {
        setActionInProgress(false);
      });
    };

    const onEditComment = (values: ModuleCommentValues) => {
      setActionInProgress(true);
      onEdit(values.comment, () => {
        setShowEditForm(false);
        setActionInProgress(false);
      });
    };

    if (actionInProgress) {
      return (
        <Box>
          <Skeleton height="14px" width="70%" />
          <Skeleton height="14px" count={3} />
        </Box>
      );
    }

    return (
      <Box ref={ref} css={styles} className={cs('module-comment')}>
        <Flex className="module-comment__header">
          <AuthorTimeDisplay
            name={comment.author.fullName}
            dateCreated={comment.dateCreated}
            dateUpdated={comment.dateUpdated}
          />
          {(userIsAuthor || isSuperadmin) && (
            <Flex className="module-comment__action-wrapper">
              <EditButton
                onClick={() => setShowEditForm((prev) => !prev)}
                editing={showEditForm}
                className="module-comment__btn"
              />
              <DeleteButton
                onClick={onDeleteComment}
                deleteConfirmationText={SUBMISSION_COPY.PROMPT_DELETE_REVIEW_COMMENT}
                className="module-comment__btn"
              />
            </Flex>
          )}
        </Flex>
        {showEditForm ? (
          <ModuleCommentForm onSubmit={onEditComment} comment={comment.comment} />
        ) : (
          <Text variant={TEXT_VARIANTS.BODY} className="module-comment__text" data-cy="module-comment__text">
            {comment.comment}
          </Text>
        )}
      </Box>
    );
  },
);

export default ModuleComment;

import * as yup from 'yup';

import { Value } from 'features/users/components/organisms/TagsInput';

import { YUP_FIELDS } from 'shared/utilities/yupFieldUtility';

export const MAX_SLOGAN_LENGTH = 255;
export const MAX_NAME_LENGTH = 60;

export const ACCOUNT_FIELD_NAMES = {
  NAME: 'name',
  DOMAIN: 'domain',
  SLOGAN: 'slogan',
  TAGS: 'tags',
};

export interface NewAccountValues {
  name: string;
  domain: string;
  slogan: string;
  tags: Value[];
}

export const newAccountSchema = yup.object({
  name: yup.string().customRequiredString().max(MAX_NAME_LENGTH),
  domain: YUP_FIELDS.DOMAIN_REQUIRED,
  slogan: yup.string().max(MAX_SLOGAN_LENGTH),
  tags: yup.array(
    yup.object().shape({
      category: yup.string(),
      values: yup.array().of(yup.string()),
    }),
  ),
});

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  height: '100%',
  '.brief-tabs': {
    '&__assets-wrapper': {
      margin: theme.space[5],
    },
  },
});

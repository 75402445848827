import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.filter-form': {
    '&__input': {
      marginTop: theme.space[5],
    },
    '&__input--hide': {
      display: 'none',
    },
    '&__submit-btn': {
      marginTop: theme.space[4],
    },
    '&__operator': {
      margin: `${theme.space[4]} ${theme.space[2]}`,
    },
  },
});

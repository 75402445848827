/* eslint-disable no-nested-ternary */
import { Box } from 'shared/components/display';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import Divider from 'shared/components/molecules/Divider';
import toggleEditInformationStyles from 'shared/components/molecules/ToggleEditInformation/toggleEditInformationStyles';
import React, { useEffect, useState } from 'react';

/**
 *
 * Component to display information and provide an edit button to
 * toggle to an edit component
 */

interface Props {
  cancelButtonText?: string;
  editButtonText?: string;
  EditingComponent: React.FC<any>;
  EditingComponentProps?: any;
  heading?: string;
  loading?: boolean;
  variant: string;
  children?: JSX.Element | Array<JSX.Element>;
  disabled?: boolean;
  expanded?: boolean;
}

const ToggleEditInformation: React.FC<Props> = ({
  heading = '',
  variant,
  loading,
  children,
  disabled = false,
  expanded = false,
  editButtonText = 'Edit',
  cancelButtonText = 'Cancel',
  EditingComponent,
  EditingComponentProps = {},
}) => {
  const [editingMode, setEditingMode] = useState<boolean>(expanded);

  useEffect(() => {
    setEditingMode(expanded);
  }, [expanded]);

  const toggleEdit = () => {
    setEditingMode((prev) => !prev);
  };

  const renderContents = () => {
    if (loading) {
      return <CenteredSpinner />;
    }
    return editingMode ? <EditingComponent {...EditingComponentProps} toggleEdit={toggleEdit} /> : children;
  };

  return (
    <Box css={toggleEditInformationStyles} className="toggle-edit">
      <Divider
        heading={heading}
        variant={variant}
        buttonText={editingMode ? cancelButtonText : editButtonText}
        onClick={toggleEdit}
        disabled={disabled}
      />
      {renderContents()}
    </Box>
  );
};

export default ToggleEditInformation;

const BASE = '/org/:organizationId/account/:accountId/platform/:accountPlatformId/';

export default {
  PLATFORM: BASE,

  REPORT: BASE + 'report/:reportId',

  REPORTS: BASE + 'report',

  PLATFORM_REQUIREMENTS: BASE + 'requirements',

  AD_CREATION_PLATFORM: BASE + 'create-ads',

  CONNECT_INSTAGRAM_PLATFORM: BASE + 'connect-instagram',

  FB_AD_LAUNCH: BASE + 'launch-ad',

  FB_CHECKOUT_CONFIRMATION: BASE + 'launch-ad/confirmation',
};

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { zIndex } from 'shared/config';
import SHADOWS from 'shared/styles/shadow';

export default (width) =>
  css({
    '&.sub-dropdown-button': {
      width,
      padding: `${theme.space[3]} ${theme.space[5]}`,
      justifyContent: 'space-between',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: COLORS.NEUTRAL50,
      },
      '&__menu': {
        width,
        zIndex: zIndex.zDropdownPortal,
        backgroundColor: COLORS.WHITE,
        boxShadow: SHADOWS.SHADOW04,
        overflowY: 'auto',
        maxHeight: '14rem',
        whiteSpace: 'normal',
        display: 'none',
      },
      '&__menu--open': {
        display: 'block',
      },
      '&__menu--all-rounded': {
        borderRadius: '5px',
      },
      '&__menu--bottom-end, &__menu--bottom-start': {
        borderBottomLeftRadius: '5px',
        borderBottomRightRadius: '5px',
      },
      '&__menu--top-end, &__menu--top-start': {
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
      },
    },
    '.sub-dropdown-button': {
      '&__caret': {
        marginLeft: theme.space[2],
      },
      '&__option': {
        height: 'fit-content',
      },
      '&__option:hover': {
        backgroundColor: COLORS.NEUTRAL100,
      },
    },
  });

import React, { useLayoutEffect, useRef, useState } from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import styles from './styles';

interface Props {
  children: React.ReactElement[];
  EllipsisComponent?: React.ReactElement;
  maxRows: number;
  className?: string;
  ellipsisWidth?: number;
}
/**
 *  truncates children that wrap to rows beyond maxRows
 */
const TruncatedFlex: React.FC<Props> = ({
  children,
  EllipsisComponent = null,
  maxRows,
  className,
  ellipsisWidth = 0,
}) => {
  const [state, setState] = useState<{
    lastChild: number | undefined;
    showEllipsis: boolean;
  }>({
    lastChild: undefined,
    showEllipsis: false,
  });
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const items: any[] = Array.from(ref.current?.children || []);
      let [item] = items;
      // distance from top of parent container
      let baseOffsetTop = item?.offsetTop;
      let row = 1;
      // left padding is accounted for in the offsetLeft
      const containerRightPadding = getComputedStyle(ref.current)?.paddingRight;
      const containerWidth = ref.current ? ref.current.offsetWidth - parseFloat(containerRightPadding) : 0;

      // cut off children that wrap to rows beyond maxRows
      // add EllipsisComponent to the end of the last row
      // and make sure last row has room
      for (let i = 0; i < items.length; i++) {
        item = items[i];

        // if item is in a new row
        if (item.offsetTop > baseOffsetTop) {
          baseOffsetTop = item.offsetTop;
          row += 1;
        }

        // if in last row and current child + ellipsis is too wide
        // then don't include current child
        // otherwise just cut off all rows after maxRows
        const itemRightPadding = getComputedStyle(item)?.paddingRight;
        let rowWidth = ellipsisWidth + item.offsetLeft + item.offsetWidth + parseFloat(itemRightPadding);

        if ((row == maxRows && rowWidth > containerWidth) || row > maxRows) {
          setState({
            lastChild: i,
            showEllipsis: true,
          });
          break;
        }
      }
    }
  }, [maxRows, ellipsisWidth, ref.current]);

  return (
    <Box ref={ref} css={styles} className={cs('truncated-flex', className)}>
      {children.slice(0, state.lastChild)}
      {state.showEllipsis && EllipsisComponent}
    </Box>
  );
};

export default TruncatedFlex;

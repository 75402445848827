import React from 'react';
import cs from 'classnames';
import { Link } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import { faArrowAltCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box, Text } from 'shared/components/display';

import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

interface Props {
  to?: string;
  text?: string;
  children?: React.ReactNode;
  state?: any;
  onClick?: () => void;
  replace?: boolean;
  dataCy?: string;
  className?: string;
}

const BackButton: React.FC<Props> = ({
  to = '',
  text,
  children,
  state = {},
  onClick,
  replace = false,
  dataCy,
  className,
}) => {
  // if search query is provided in to, split it into path and search
  const [pathname, search] = to.split('?');
  return (
    <Box className={cs('back-button', className)} css={styles} data-cy={dataCy}>
      {to ? (
        <Link to={{ pathname, search }} state={state} onClick={onClick} replace={replace}>
          <Text variant={TEXT_VARIANTS.SUBHEADING} className="back-button__text">
            <FontAwesomeIcon icon={faArrowAltCircleLeft} size="xs" />
            &nbsp;
            {text || children}
          </Text>
        </Link>
      ) : (
        <Button variant={BUTTON_VARIANTS.TEXT_ONLY} className="back-button__btn" onClick={onClick}>
          <Text variant={TEXT_VARIANTS.SUBHEADING} className="back-button__text">
            <FontAwesomeIcon icon={faArrowAltCircleLeft} size="xs" />
            &nbsp;
            {text || children}
          </Text>
        </Button>
      )}
    </Box>
  );
};

export default BackButton;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import classNames from 'classnames';
import config from 'config';
import { Field, Form, Formik, FormikProps } from 'formik';

import { FACEBOOK_FIELDNAMES } from 'features/organizations/components/organisms/OrgOnboardingStepFields';
import { Box, Flex, Text } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import { SelectInput, SelectValue } from 'shared/components/molecules/Select';

import { apiAction } from 'shared/actions/api';
import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_FACEBOOK_ROUTES } from 'shared/config/routes/api/apiFacebookRoutes';
import { TRACKING } from 'shared/config/tracking';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useQuery } from 'shared/hooks/useQuery';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import {
  ABTestingParams,
  getABParams,
  getUTMParams,
  sha256Hash,
  trackEvent,
  trackPageView,
  UTMParams,
} from 'shared/utilities/trackingUtility';

import AdHealthSignIn from '../AdHealthSignIn/AdHealthSignIn';

import styles from './styles';
import { validationSchema } from './validations';

interface FormValues {
  fbAdAccount: SelectValue | null;
  fbBusiness: SelectValue | null;
}

const initialValues: FormValues = {
  fbAdAccount: null,
  fbBusiness: null,
};

const initialState = {
  adAccounts: [],
};

export function openFbLoginDialog(redirectURI: string, utmParams: UTMParams, abParams: ABTestingParams): void {
  const state = JSON.stringify({ redirectURI: encodeURIComponent(redirectURI), ...utmParams, ...abParams });
  const url = `https://www.facebook.com/${config.FB_APP_VERSION}/dialog/oauth?client_id=${config.FB_CLIENT_ID}&redirect_uri=${config.API_URL}${config.FB_REDIRECT_URI}&scope=${config.FB_CONNECTION_SCOPE}&state=${state}`;
  window.location.assign(url);
}

const AccountHealthOnboarding = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getQuery } = useQuery();
  const [emailHashed, setEmailHashed] = useState('');
  const queryParams = getQuery();
  const { fbBusinesses, token: fbAccessToken, email, firstName, lastName } = queryParams;
  const parsedBusinesses = useMemo(() => (fbBusinesses ? JSON.parse(fbBusinesses) : []), []);
  const { loading } = useComponentLoading(RESOURCES.FACEBOOK_BUSINESS_ASSET, false);

  const [shouldCreateAdAccount] = useState(false);
  const [state, setState] = useState(initialState);
  const { adAccounts } = state;

  const params = useParams<any>();

  const accountId = +params.accountId;
  const organizationId = +params.organizationId;
  const { formatMessage } = useIntl();

  useEffect(() => {
    const trackPage = async () => {
      const id = await sha256Hash(email);
      setEmailHashed(id);
      trackPageView(pathname + search, { email, type: UserType.ORG, id });
    };
    if (email) trackPage().catch(console.error);
  }, [pathname, search, email]);

  const onChange = useCallback(
    (business: SingleValue<SelectValue>, formikProps: FormikProps<FormValues>) => {
      dispatch(
        apiAction({
          path: {
            route: `${API_FACEBOOK_ROUTES.BUSINESS_ASSETS}/${business?.value}`,
          },
          params: {
            token: fbAccessToken,
          },
          entity: RESOURCES.FACEBOOK_BUSINESS_ASSET,
          onSuccess: ({ data }) => {
            if (data.adAccounts.length < 1) {
              formikProps.setFieldValue(FACEBOOK_FIELDNAMES.SHOULD_CREATE_AD_ACCOUNT, true);
            } else {
              formikProps.setFieldValue(FACEBOOK_FIELDNAMES.SHOULD_CREATE_AD_ACCOUNT, false);
            }
            setState((prev) => ({
              ...prev,
              pages: data.pages,
              pixels: data.pixels,
              productCatalogs: data.productCatalogs,
              adAccounts: data.adAccounts,
            }));
          },
        }),
      );
    },
    [dispatch, fbAccessToken],
  );

  const showAdAccounts = adAccounts.length >= 1 && !loading && !shouldCreateAdAccount;
  const showAdAccountWarnings = adAccounts.length < 1 && !loading;

  const onSubmit = (adAccountId) => {
    trackEvent(TRACKING.ADSCORE_CALCULATE, { email, type: UserType.ORG, userId: emailHashed });
    navigate(
      LinkCreator.createLink({
        userType: UserType.ORG,
        routeKey: 'EXTERNAL_AD_HEALTH',
        query: {
          token: fbAccessToken,
          adAccountId: adAccountId.value,
          // @ts-ignore
          adAccountName: adAccountId.label,
          isOnboarding: true,
          email,
          firstName,
          lastName,
          ...getABParams(queryParams),
          ...getUTMParams(queryParams),
        },
      }),
    );
  };

  const redirectUri = LinkCreator.createLink({
    routeKey: 'AD_HEALTH_CONNECT',
    variables: {
      organizationId,
      accountId,
    },
    query: {
      platform: 'facebook',
      token: fbAccessToken,
      isOnboarding: true,
      email,
      firstName,
      lastName,
      ...getABParams(queryParams),
    },
  });

  const onSignIn = () => {
    trackEvent(TRACKING.ADSCORE_FACEBOOK, { email, type: UserType.ORG, userId: emailHashed });
    openFbLoginDialog(redirectUri, getUTMParams(queryParams), getABParams(queryParams));
  };

  return parsedBusinesses?.length === 0 ? (
    <AdHealthSignIn onSubmit={onSignIn} />
  ) : (
    <Box css={styles} className="account-health-onboarding">
      <Formik
        validateOnBlur={false}
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          onSubmit(values[FACEBOOK_FIELDNAMES.FB_AD_ACCOUNT]);
          actions.setSubmitting(false);
        }}
      >
        {(formikProps) => (
          <Form className="account-health-onboarding__form">
            <Flex className="account-health-onboarding__form-content">
              <Text variant={TEXT_VARIANTS.H2} className="account-health-onboarding__title">
                {ACCOUNT_PLATFORM_COPY.HEADING_AD_HEALTH_CONNECT}
              </Text>
              {parsedBusinesses?.length >= 1 && (
                <>
                  <Flex className={'account-health-onboarding__input-loader'}>
                    <Field
                      width="275px"
                      name={'fbBusiness'}
                      component={SelectInput}
                      onChange={(event) => {
                        onChange(event, formikProps);
                        formikProps.setFieldValue(FACEBOOK_FIELDNAMES.FB_AD_ACCOUNT, null);
                        setState(initialState);
                      }}
                      isSearchable
                      filterByLabel
                      placeholder="Business Manager"
                      options={parsedBusinesses.map(({ id, name }) => ({ value: id, label: name }))}
                    />
                    <TailSpin
                      color={COLORS.PRIMARY200}
                      height="25"
                      width="25"
                      visible={loading}
                      wrapperClass="account-health-onboarding__loader"
                    />
                  </Flex>
                </>
              )}

              {parsedBusinesses?.length >= 1 && (
                <>
                  <Box
                    className={classNames('account-health-onboarding__ad-account-input', {
                      'account-health-onboarding__ad-account-input--show': showAdAccounts,
                    })}
                  >
                    <Field
                      width="275px"
                      name={FACEBOOK_FIELDNAMES.FB_AD_ACCOUNT}
                      component={SelectInput}
                      isSearchable
                      filterByLabel
                      placeholder="Ad Account"
                      options={adAccounts.map(({ account_id, name }) => ({ value: account_id, label: name }))}
                    />
                  </Box>
                </>
              )}
              {showAdAccountWarnings && formikProps.values.fbBusiness && (
                <Text
                  variant={TEXT_VARIANTS.CAPTION}
                  color={COLORS.ORANGE700}
                  className={'account-health-onboarding__input'}
                >
                  {formatMessage(
                    {
                      id: 'NO_RESULTS_HEALTH_ONBOARDING',
                    },
                    { businessName: formikProps.values.fbBusiness?.label || 'the business' },
                  )}
                </Text>
              )}
              <Flex className={'account-health-onboarding__submit-button'}>
                <FormButton
                  type="submit"
                  disabled={!formikProps.isValid || !formikProps.dirty}
                  loading={formikProps.isSubmitting}
                >
                  Calculate Ad Health Score
                </FormButton>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AccountHealthOnboarding;

import React from 'react';
import { Box, Flex } from 'shared/components/display';
import { Submission } from 'shared/typings/submissions';
import { SIZING } from 'shared/config/formatting';
import Modal from 'shared/components/molecules/Modal';
import CreatorSubmissionTabs from 'features/submissions/components/organisms/CreatorSubmissionTabs';

import styles from './styles';
import { Brief } from 'shared/typings/briefs';

interface Props {
  brief?: Brief;
  onRequestClose: () => void;
  isOpen: boolean;
}

interface HeaderProps {
  CloseButton?: React.ReactElement;
}

const CreatorSubmissionModal: React.FC<Props> = ({ brief, onRequestClose, isOpen }) => {
  return (
    <Modal
      modalSize={SIZING.FULLSCREEN}
      Header={<SubmissionModalHeader />}
      component={
        <Box css={styles} className="creator-submission-modal">
          <CreatorSubmissionTabs brief={brief} />
        </Box>
      }
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      title=""
    />
  );
};
export default CreatorSubmissionModal;

const SubmissionModalHeader: React.FC<HeaderProps> = ({ CloseButton }) => {
  return (
    <Flex css={styles} className="creator-submission-modal__header">
      {CloseButton}
    </Flex>
  );
};

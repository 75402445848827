import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import { fontSizes, fontWeights } from 'shared/styles/text';

export default css({
  width: '100%',
  minHeight: '100%',
  backgroundColor: COLORS.WHITE,
  '.creator-account-settings': {
    '&__side-nav': {
      flexDirection: 'column',
      width: '100%',
      minWidth: '200px',
      borderRight: `3px solid ${COLORS.NEUTRAL100}`,
      padding: '20px',
      [mediaquery.md]: {
        width: '25%',
        minWidth: '218px',
        borderRight: `3px solid ${COLORS.NEUTRAL100}`,
        padding: '20px',
      },
    },
    '&__side-nav-header': {
      padding: 0,
      textAlign: 'left',
      [mediaquery.md]: {
        padding: '8px',
        textAlign: 'left',
      },
    },
    '&__side-nav-subheading-wrapper': {
      flexDirection: 'column',
      [mediaquery.md]: {
        display: 'none',
      },
    },
    '&__side-nav-text': {
      lineHeight: 1.5,
      marginBottom: 0,
      fontSize: fontSizes[2],
    },
    '&__side-nav-description': {
      lineHeight: 1.5,
      marginBottom: '25px',
    },
    '&__btn': {
      marginBottom: '15px',
      color: COLORS.BLACK,
      padding: '8px',
      backgroundColor: COLORS.NEUTRAL50,
      border: 'none',
      borderRadius: 10,
      cursor: 'pointer',
      width: '100%',
      fontSize: fontSizes[2],
      fontWeight: fontWeights.bold,
      letterSpacing: 0,
      lineHeight: 1.8,
      px: [5],
      py: [1],
      textAlign: 'left',
      userSelect: 'none',
      [mediaquery.md]: {
        backgroundColor: COLORS.TRANSPARENT,
      },
    },
    '&__btn--active': {
      [mediaquery.md]: {
        backgroundColor: COLORS.NEUTRAL50,
        color: COLORS.BLACK,
      },
    },
    '&__btn:hover': {
      boxShadow: 'none',
      backgroundColor: COLORS.NEUTRAL50,
      borderRadius: '6px',
    },
    '&__btn-content': {
      alignItems: 'center',
      position: 'relative',
      minWidth: '175px',
    },
    '&__btn-name': {
      paddingLeft: '15px',
      [mediaquery.md]: {
        paddingLeft: '5px',
      },
    },
    '&__right-icon': {
      position: 'absolute',
      right: '0',
    },
    '&__form-container': {
      width: '100%',
      padding: '12px 18px',
      [mediaquery.md]: {
        padding: '20px',
      },
    },
    '&__form-header-wrapper': {
      position: 'relative',
      alignItems: 'center',
      marginBottom: '20px',
      justifyContent: 'center',
    },
    '&__form-header': {
      margin: '0',
      padding: '8px',
      display: 'none',
      [mediaquery.md]: {
        display: 'block',
      },
    },
    '&__mobile-form-header': {
      margin: '0',
    },
    '&__back-btn': {
      position: 'absolute',
      left: '0',
      padding: '8px',
      borderRadius: '5px',
      backgroundColor: COLORS.NEUTRAL100,
      cursor: 'pointer',
      [mediaquery.md]: {
        borderRadius: '0',
      },
    },
  },
});

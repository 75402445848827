import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '.account-logo-skeleton': {
    borderRadius: '50%',
  },
  '&.account-logo': {
    borderRadius: '50%',
    objectFit: 'cover',
  },
  '&.account-logo-placeholder': {
    color: COLORS.NEUTRAL300,
    backgroundColor: COLORS.NEUTRAL100,
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '&.account-logo--xs, &.account-logo-placeholder--xs, .account-logo-skeleton--xs': {
    width: '42px',
    height: '42px',
  },
  '&.account-logo--sm, &.account-logo-placeholder--sm, .account-logo-skeleton--sm': {
    width: '112px',
    height: '112px',
  },
  '&.account-logo--md, &.account-logo-placeholder--md, .account-logo-skeleton--md': {
    width: '160px',
    height: '160px',
  },
  '&.account-logo--lg, &.account-logo-placeholder--lg, .account-logo-skeleton--lg': {
    width: '240px',
    height: '240px',
  },
  '.account-logo-placeholder__icon': {
    width: '50%',
    height: '50%',
  },
});

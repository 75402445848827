import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.brkfst-pagination': {
    paddingBottom: theme.space[3],
    maxWidth: '100%',
    '& .mobile-table': {
      margin: 0,
      width: '100%',
    },
    '& thead': {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: zIndex.zStickyElements,
    },
    '& .mobile-table__cell-content:has(* .elliptical-menu), & .mobile-table__table:has(* .elliptical-menu)': {
      overflow: 'unset',
    },
    '& .mobile-table__row:hover .elliptical-menu': {
      visibility: 'visible',
    },
    '& .elliptical-menu': {
      visibility: 'hidden',
    },
  },
  '.brkfst-pagination': {
    '&__footer': {
      marginTop: theme.space[4],
      width: '100%',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    '&__controls': {
      display: 'block',
      [mediaquery.md]: {
        display: 'flex',
      },
    },
  },
});

import React from 'react';
import { Box } from 'shared/components/display';
import notificationBadgeStyles from 'shared/components/atoms/NotificationBadge/notificationBadgeStyles';

interface Props {
  top: string;
  right: string;
  children: any;
}

const NotificationBadge: React.FC<Props> = ({ children, top, right }) => {
  return (
    <Box as="span" className="notification-badge" css={notificationBadgeStyles(top, right)}>
      {children}
    </Box>
  );
};

export default NotificationBadge;

import { css } from '@emotion/react';

export default css({
  '&.update-banner-logo': {
    flexDirection: 'column',
    alignItems: 'start',
    display: 'block',
  },
  '.update-banner-logo': {
    '&__delete-btn': {
      padding: 0,
      margin: '16px 0',
    },
  },
});

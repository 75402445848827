import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@rollbar/react';

import LandingTemplate from 'features/home/LandingTemplate/LandingTemplate';
import { ErrorPage } from 'features/ui/pages/ErrorPages';

interface Props {
  children?: any;
}

const PublicRouteWrapper: React.FC<Props> = ({ children = <></> }) => {
  const location = useLocation<any>();
  return (
    <LandingTemplate>
      {/* @ts-ignore */}
      <ErrorBoundary key={location.pathname} fallbackUI={ErrorPage}>
        {children}
        <Outlet />
      </ErrorBoundary>
    </LandingTemplate>
  );
};

export default PublicRouteWrapper;

import React from 'react';
import { components, InputProps } from 'react-select';
import cs from 'classnames';

import { GeoLocationSuggestion } from 'shared/hooks/useGeoLocationHook';

const Input: React.FC<InputProps<GeoLocationSuggestion, false> & { active: boolean; dataCy?: string }> = ({
  active,
  dataCy,
  ...props
}) => {
  // TODO: Enable autocomplete as part of the refactor
  return (
    <components.Input
      {...props}
      data-lpignore={true}
      className={cs('address-search__input', {
        'address-search__input--active': active,
        'address-search__input--inactive': !active,
      })}
      autoComplete="none"
      data-cy={dataCy}
    />
  );
};

export default Input;

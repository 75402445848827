import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  flexDirection: 'column',
  '.facebook-permissions': {
    '&__checklist-item': {
      marginLeft: '0.1rem',
      marginRight: '0.4rem',
    },
    '&__checklist': {
      margin: '16px 0',
      flexWrap: 'wrap',
      paddingLeft: '3rem',
      paddingRight: '3rem',
    },
    '&__additional-section': {
      width: '100%',
    },
    '&__permission': {
      alignItems: 'center',
      margin: '8px 16px 8px 0',
    },
    '&__text': {
      marginTop: theme.space[6],
    },
    '&__badge': {
      alignItems: 'center',
      backgroundColor: COLORS.NEUTRAL50,
      borderRadius: '24px',
      width: 'fit-content',
      padding: '2px 12px 2px 4px',
    },
    '&__additional-section-name': {
      marginBottom: 0,
    },
    '&__icon': {
      marginRight: '6px',
      backgroundColor: 'black',
      borderRadius: '16px',
      color: 'white',
      width: '24px',
      height: '24px',
      textAlign: 'center',
    },
  },
  '.facebook-connect': {
    marginTop: '16px',
  },
});

import React, { useEffect, useMemo, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import { Field, Form, Formik, FormikHelpers } from 'formik';

import {
  facebookBusinessAssetAdded,
  facebookBusinessAssetRemoved,
} from 'features/accountPlatforms/accountPlatforms.slice';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';
import { Box, Flex } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import { SelectInput } from 'shared/components/molecules/Select';

import { apiAction } from 'shared/actions/api';
import { ACCOUNT_PLATFORM_COPY } from 'shared/config/copy';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_FACEBOOK_ROUTES } from 'shared/config/routes/api/apiFacebookRoutes';

import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import { breakpoints } from 'shared/styles/styleFunctions';
import { Type } from 'shared/typings/facebookBusinessAsset/enums';

import styles from './styles';
import { FormValues, IgAccount, Page, RouteParams } from './types';
import { validationSchema } from './validation';

const ENTITY = 'Instagram';

const PLACE_HOLDERS = Object.freeze({
  IG_ACCOUNT: 'Instagram Account',
});

const FIELD_NAMES = Object.freeze({
  IG_ACCOUNT: 'igAccount',
});

const DEFAULT_VALUES = Object.freeze({
  [FIELD_NAMES.IG_ACCOUNT]: null,
});

export const ConnectInstagram: React.FC<{}> = ({}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();
  const { accountPlatform } = useAccountPlatforms({
    accountPlatformId: +params.accountPlatformId,
  });
  const { loading } = useComponentLoading(ENTITY);
  const { loading: apLoading } = useComponentLoading(RESOURCES.ACCOUNT_PLATFORM);
  const [igAccounts, setIgAccounts] = useState<IgAccount[]>([]);
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const page = useMemo<Page | undefined>(
    () => accountPlatform.facebookBusinessAssets?.find(({ type }) => type === Type.PAGE),
    [accountPlatform.facebookBusinessAssets],
  );

  const igAccount = useMemo(
    () => accountPlatform.facebookBusinessAssets?.find(({ type }) => type === Type.INSTAGRAM_ACCOUNT),
    [accountPlatform.facebookBusinessAssets],
  );

  const initialValues = useMemo<FormValues>(() => {
    if (igAccount && page) return { igAccount: { label: igAccount.name, value: igAccount.facebookId } };
    return DEFAULT_VALUES;
  }, [igAccount, page]);

  useEffect(() => {
    if (page)
      dispatch(
        apiAction({
          path: {
            route: API_FACEBOOK_ROUTES.INSTAGRAM_ACCOUNTS,
            variables: {
              facebookId: page.facebookId,
            },
          },
          entity: ENTITY,
          onSuccess: ({ data: instagramAccounts }) => {
            setIgAccounts(instagramAccounts.map(({ id, username }) => ({ value: id, label: username })));
          },
        }),
      );
    else if (!apLoading) navigate(-1);
  }, [page]);

  const onSubmit = (formData: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    const { igAccount: igAccountFormData } = formData;
    if (page && igAccountFormData)
      dispatch(
        apiAction({
          path: {
            route: API_FACEBOOK_ROUTES.BUSINESS_ASSETS,
          },
          data: {
            accountPlatformId: accountPlatform.id,
            name: igAccountFormData.label,
            facebookId: igAccountFormData.value,
            type: Type.INSTAGRAM_ACCOUNT,
          },
          method: 'POST',
          entity: ENTITY,
          successAction: facebookBusinessAssetAdded,
          onSuccess: () => {
            setSubmitting(false);
          },
          successToast: {
            message: ACCOUNT_PLATFORM_COPY.ASSIGN_INSTAGRAM_TOAST,
          },
        }),
      );
  };

  const onDisconnect = ({ setFieldValue }: FormikHelpers<FormValues>) => {
    dispatch(
      apiAction({
        path: {
          route: API_FACEBOOK_ROUTES.BUSINESS_ASSET,
          variables: {
            id: igAccount?.id,
          },
        },
        method: 'DELETE',
        entity: ENTITY,
        successAction: facebookBusinessAssetRemoved,
        onSuccess: () => {
          setFieldValue(FIELD_NAMES.IG_ACCOUNT, DEFAULT_VALUES[FIELD_NAMES.IG_ACCOUNT]);
        },
        successToast: {
          message: 'Instagram Account Disconnected.',
        },
      }),
    );
  };

  return (
    <Box css={styles}>
      <Box>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <Form>
              <Flex className="connect-instagram__select-wrapper">
                <Field
                  name={FIELD_NAMES.IG_ACCOUNT}
                  component={SelectInput}
                  width={isMobile ? '200px' : '250px'}
                  options={igAccounts}
                  placeholder={PLACE_HOLDERS.IG_ACCOUNT}
                />
                <TailSpin
                  wrapperClass="connect-instagram__loader"
                  color={COLORS.PRIMARY200}
                  height="25"
                  width="25"
                  visible={loading}
                />
              </Flex>
              <Flex className="connect-instagram__buttons">
                <FormButton className="connect-instagram__button">Submit</FormButton>
                <FormButton
                  variant={BUTTON_VARIANTS.OUTLINE}
                  disabled={!igAccount || loading}
                  type="button"
                  onClick={onDisconnect}
                  className="connect-instagram__button"
                >
                  Disconnect
                </FormButton>
              </Flex>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

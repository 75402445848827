import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import styles from './styles';

interface Props {
  parentStateSetter: (arg: any) => void;
  arrayToSearch: any[];
  propertyToSearch: string | string[];
  className?: string;
  onSearchChanged?: (search: string, arrayToSearch: any[]) => void;
  [key: string]: any;
  placeholder?: string;
  width?: string;
}

const SearchBar: React.FC<Props> = ({
  parentStateSetter,
  arrayToSearch,
  propertyToSearch,
  className,
  onSearchChanged = () => {},
  placeholder = 'Search...',
  width = '100%',
}) => {
  const [search, setSearch] = useState('');

  const filterFunction = (objArray, property) => {
    return objArray.filter((elem) => {
      return property.some((propertyString) => {
        const toSearch = Array.isArray(elem[propertyString])
          ? elem[propertyString].map((val) => val.toLowerCase()).join(', ')
          : elem[propertyString].toLowerCase();
        const sensitive = search.toLowerCase();
        return toSearch.includes(sensitive);
      });
    });
  };

  const onSearchHandler = ({ target: { value: search } }) => {
    onSearchChanged(search, arrayToSearch);
    setSearch(search);
  };

  useEffect(() => {
    const arrPropertyToSearch = typeof propertyToSearch === 'string' ? [propertyToSearch] : propertyToSearch;
    const filtered = filterFunction(arrayToSearch, arrPropertyToSearch);
    parentStateSetter(filtered);
  }, [arrayToSearch, search, propertyToSearch]);

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={cs(className, 'search-bar')}
      css={styles(width)}
    >
      <input
        id="search"
        className="search-bar__input"
        value={search}
        onChange={onSearchHandler}
        type="text"
        placeholder={placeholder}
      />
    </Box>
  );
};

export default SearchBar;

import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'rebass/styled-components';
import PromptButton from 'shared/components/molecules/PromptButton';
import EditFileForm from 'features/media/components/molecules/EditFileForm';
import FileInformationModal from 'features/media/components/molecules/FileInformationModal';
import MoveFilesToCollectionForm from 'features/media/components/organisms/MoveFilesToCollectionForm';
import { useFiles } from 'features/media/useFiles';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import EllipticalMenu from 'shared/components/EllipticalMenu/EllipticalMenu';
import Modal from 'shared/components/molecules/Modal';
import { Box } from 'shared/components/display';

import { COLLECTION_COPY, FILE_COPY } from 'shared/config/copy';
import { SIZING } from 'shared/config/formatting';
import COLORS from 'shared/styles/colors';
import { BrkfstFile } from 'shared/typings/file';
import { Placement } from '@floating-ui/react';
import { BUTTON_VARIANTS } from 'shared/styles/button';

const { NEUTRAL300 } = COLORS;

interface Props {
  mediaObject: BrkfstFile;
  placement?: Placement;
  color?: string;
  collectionId: number;
  isBriefCollection?: boolean;
  showInfoOption?: boolean;
}

const CollectionFileElliptical: React.FC<Props> = ({
  mediaObject,
  placement = 'top-end',
  color = NEUTRAL300,
  collectionId,
  isBriefCollection = false,
  showInfoOption = false,
}) => {
  const { id, approvedInAnySubmission, pushedToPlatform, submitted, ...mediaObjectProps } = mediaObject;
  const [showEditFileModal, setShowEditFileModal] = useState(false);
  const [showAddToCollectionModal, setShowAddToCollectionModal] = useState(false);

  const { updateFile, deleteFile, removeFromCollection, fileUpdated, fileUpdating } = useFiles();

  const onRequestDelete = () => {
    // if a file is in multiple collections, then remove from collection
    if ((mediaObject.collectionIds?.length || 0) > 1) {
      removeFromCollection({
        fileId: id,
        collectionId,
      });
    }
    // if it's only in one, then delete
    else {
      deleteFile({ fileId: id, collectionId });
    }
  };

  const onRequestEdit = async (assetFormData) => {
    const {
      name,
      tags,
      mediaPreview: { offset, updateThumbnail },
    } = assetFormData;
    const fileObject = {
      ...mediaObjectProps,
      id,
      tagNames: tags,
      name,
      ...(updateThumbnail
        ? {
            thumbnailOffset: offset,
          }
        : {}),
    };

    updateFile(collectionId, fileObject, updateThumbnail);
  };

  const onMoveFileToCollection = () => setShowAddToCollectionModal(false);

  useEffect(() => {
    if (fileUpdated) {
      setShowEditFileModal(false);
    }
  }, [fileUpdated]);

  useEffect(() => {
    if (fileUpdated) {
      setShowEditFileModal(false);
    }
  }, [fileUpdated]);

  const canEdit = !approvedInAnySubmission;
  const canDelete = useMemo(() => {
    // marketers cannot delete the reference to approved submission asset within the brief collection or an asset published in an ad
    const assetApproved = mediaObject.approved || approvedInAnySubmission;
    return !((isBriefCollection && assetApproved) || pushedToPlatform || submitted);
  }, [isBriefCollection]);

  return (
    <>
      <EllipticalMenu placement={placement} color={color} width="120px" className="collection-file-elliptical">
        <Button disabled={!canEdit} onClick={() => setShowEditFileModal(true)}>
          {!canEdit ? (
            <Tooltip content={FILE_COPY.TOOLTIP_EDIT_DISABLED_APPROVED}>
              <Box as="span">{FILE_COPY.BUTTON_EDIT_FILE}</Box>
            </Tooltip>
          ) : (
            FILE_COPY.BUTTON_EDIT_FILE
          )}
        </Button>
        {canDelete ? (
          <PromptButton text={FILE_COPY.MODAL_DELETE_FILE} onConfirm={onRequestDelete}>
            {FILE_COPY.BUTTON_DELETE_FILE}
          </PromptButton>
        ) : (
          <Tooltip
            content={
              pushedToPlatform
                ? FILE_COPY.TOOLTIP_FILE_DELETE_DISABLED_PUBLISHED
                : FILE_COPY.TOOLTIP_FILE_DELETE_APPROVED_SUBMISSION
            }
          >
            <PromptButton
              variant={BUTTON_VARIANTS.OPTION}
              text={FILE_COPY.MODAL_DELETE_FILE}
              onConfirm={onRequestDelete}
              disabled
            >
              {FILE_COPY.BUTTON_DELETE_FILE}
            </PromptButton>
          </Tooltip>
        )}
        <Button onClick={() => setShowAddToCollectionModal(true)}>{COLLECTION_COPY.BUTTON_MOVE_FILE}</Button>
        {showInfoOption && <FileInformationModal file={mediaObject} textDisplay />}
      </EllipticalMenu>
      <Modal
        title="Edit File"
        onRequestClose={() => setShowEditFileModal(false)}
        isOpen={showEditFileModal}
        modalSize={SIZING.MEDIUM_WIDE}
        component={<EditFileForm onSubmit={onRequestEdit} mediaObject={mediaObject} loading={fileUpdating} />}
      />
      <Modal
        title={COLLECTION_COPY.DRAWER_ADD_FILES}
        onRequestClose={() => setShowAddToCollectionModal(false)}
        isOpen={showAddToCollectionModal}
        modalSize={SIZING.MEDIUM}
        component={
          <MoveFilesToCollectionForm
            selectedAssetIds={[id]}
            collectionId={collectionId}
            onSubmit={onMoveFileToCollection}
          />
        }
      />
    </>
  );
};

export default CollectionFileElliptical;

import * as yup from 'yup';
import { buttonGroupSchema } from 'shared/components/molecules/ButtonGroupInput/validation';
import { ageRangeLimits, heightRangeLimits } from 'features/creators/components/molecules/CreatorSearch/config';
import { rangeValidationSchema } from 'shared/components/molecules/RangeInput';

const schema = yup.object({
  search: yup.string(),
  recentlyActive: buttonGroupSchema,
  experience: buttonGroupSchema,
  gender: buttonGroupSchema,
  industry: buttonGroupSchema,
  niche: buttonGroupSchema,
  location: buttonGroupSchema,
  model: buttonGroupSchema,
  ethnicity: buttonGroupSchema,
  language: buttonGroupSchema,
  ageTags: buttonGroupSchema,
  ageRange: rangeValidationSchema(ageRangeLimits.min, ageRangeLimits.max),
  height: rangeValidationSchema(heightRangeLimits.min, heightRangeLimits.max),
});

export default schema;

import React from 'react';

import { BriefPermissions } from 'features/briefs/BriefHeader/HeaderBrandActions/types';
import DeleteDraftButton from 'features/briefs/components/atoms/DeleteDraftButton';
import DuplicateBriefButton from 'features/briefs/components/atoms/DuplicateBriefButton';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import SplitButton from 'shared/components/molecules/SplitButton';

import { BRIEF_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { Brief } from 'shared/typings/briefs';

interface Props {
  currentBrief: Brief;
  draftBriefPermissions: Pick<BriefPermissions, 'createEditDraft' | 'deleteDraft'>;
}

const DraftBriefActionButtons: React.FC<Props> = ({ currentBrief, draftBriefPermissions }) => {
  const { editBrief } = useBriefs();
  const { createEditDraft, deleteDraft } = draftBriefPermissions;
  const onEdit = () => {
    editBrief(currentBrief);
  };
  {
    if (createEditDraft) {
      return (
        <SplitButton
          primaryText={BRIEF_COPY.BUTTON_EDIT_DRAFT}
          primaryOnClick={onEdit}
          width="190px"
          className="draft-brief-action-button"
        >
          <DuplicateBriefButton brief={currentBrief} variant={BUTTON_VARIANTS.FLAT_OPTION} isDraft />
          {deleteDraft && <DeleteDraftButton briefId={currentBrief.id} variant={BUTTON_VARIANTS.FLAT_OPTION} />}
        </SplitButton>
      );
    } else {
      return <DeleteDraftButton briefId={currentBrief.id} variant={BUTTON_VARIANTS.PRIMARY} />;
    }
  }
};

export default DraftBriefActionButtons;

import React from 'react';
import { Link } from 'rebass/styled-components';
import styled from 'styled-components';

const PRIVACY_POLICY_LINK = 'https://www.brkfst.io/privacy-policy-2/';
const TERMS_OF_USE_LINK = 'https://www.brkfst.io/legal/';
const MSA_CREATORS_LINK = 'https://www.brkfst.io/creator-master-services-agreement/';
const MSA_MARKETERS_LINK = 'https://www.brkfst.io/brand-master-services-agreement/';

const PrivacyFooter = () => {
  return (
    <Private>
      <li>
        <Link href={PRIVACY_POLICY_LINK} target="_blank">
          Privacy Policy
        </Link>
      </li>
      <li>
        <Link href={TERMS_OF_USE_LINK} target="_blank">
          Terms of Use
        </Link>
      </li>
      <li>
        <Link href={MSA_CREATORS_LINK} target="_blank">
          Master Service Agreement (Creators)
        </Link>
      </li>
      <li>
        <Link href={MSA_MARKETERS_LINK} target="_blank">
          Master Service Agreement (Marketers)
        </Link>
      </li>
    </Private>
  );
};

const Private = styled.ul`
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 1rem 0;

  li:not(:last-child) {
    margin-right: 1rem;
  }

  li {
    font-size: 12px;
  }
`;

export default PrivacyFooter;

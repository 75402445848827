import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Text } from 'shared/components/display';
import CreatorInfo from 'features/creators/components/organisms/CreatorInfo/CreatorInfo';
import {
  creatorsTableNoDataStyles,
  getCreatorsTableStyles,
} from 'features/creators/components/organisms/CreatorsTable/styles';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';

import Skeleton from 'shared/components/atoms/Skeleton';
import { CARD_WIDTH, CARD_HEIGHT } from 'features/creators/components/organisms/CreatorInfo/creatorInfoStyles';

export type Props = {
  data: BrkfstUser[];
  loading: boolean;
};

const CreatorsTable: React.FC<Props> = ({ data = [], loading }) => {
  const { formatMessage } = useIntl();

  if (loading) {
    return (
      <Box css={getCreatorsTableStyles(CARD_WIDTH, CARD_HEIGHT, 4)} className="creators-table">
        <Skeleton height={CARD_HEIGHT} width={CARD_WIDTH} />
        <Skeleton height={CARD_HEIGHT} width={CARD_WIDTH} />
        <Skeleton height={CARD_HEIGHT} width={CARD_WIDTH} />
        <Skeleton height={CARD_HEIGHT} width={CARD_WIDTH} />
      </Box>
    );
  }

  if (!data.length) {
    return (
      <Box css={creatorsTableNoDataStyles} className="creators-table">
        <Text className="creators-table__no-creator" variant={TEXT_VARIANTS.H6}>
          {formatMessage({ id: 'NO_CREATOR_RESULTS' })}
        </Text>
      </Box>
    );
  }

  return (
    <Box css={getCreatorsTableStyles(CARD_WIDTH, CARD_HEIGHT, data.length)} className="creators-table">
      {data.map((creator) => (
        <CreatorInfo key={creator.id} creator={creator} />
      ))}
    </Box>
  );
};

export default CreatorsTable;

import React from 'react';
import Skeleton from 'shared/components/atoms/Skeleton';
import { Box } from 'shared/components/display';
import { CLASSNAMES } from 'shared/styles/containers';

const LoadingCollectionSkeleton: React.FC = () => {
  return (
    <Box>
      <Box>
        <Box style={{ marginBottom: '15px' }}>
          <Skeleton height={20} width={200} />
        </Box>
      </Box>
      <Box className={CLASSNAMES.PADDING_CONTAINER}>
        <Box style={{ marginBottom: '15px' }}>
          <Skeleton height={50} />
        </Box>
        <Box style={{ marginBottom: '15px' }}>
          <Skeleton height={110} />
        </Box>
        <Box style={{ marginBottom: '15px' }}>
          <Skeleton height={30} />
        </Box>
      </Box>
    </Box>
  );
};

export default LoadingCollectionSkeleton;

import React from 'react';
import styled from 'styled-components';
import theme from 'shared/styles/themes';
/**
 * ModalFormHr is a styled component that is a horizontal line that is used in modals.
 * It spans the whole width of the modal without being affected by the padding of the modal.
 * A bit of a bandaid component to make it easier to implement the new form modal design without having to mess with the modal padding styling across the whole app
 */

const ModalFormHr = styled.hr`
  margin: 0 -${theme.space[5]};
  width: calc(100% + (${theme.space[5]} * 2));
`;

export default ModalFormHr;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { zIndex } from 'shared/config';
import { STYLES } from 'shared/styles/styles';

export default css({
  '&.modal-step-wrapper': {
    '& .multi-step-form__btn-wrapper': {
      display: 'flex',
      justifyContent: 'end',
      flexDirection: 'row-reverse',
      margin: '20px 0 10px 0',
      padding: '10px 10px 0 0',
    },
    '&--show-button-divider .multi-step-form__btn-wrapper': {
      borderTop: `1px solid ${COLORS.NEUTRAL200}`,
    },
    '& .multi-step-form__continue-btn': {
      marginLeft: theme.space[3],
    },
  },
  '.modal-step-wrapper': {
    '&__header': {
      backgroundColor: COLORS.WHITE,
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      height: '3rem',
      borderBottom: STYLES.BORDER_NEUTRAL,
      marginBottom: theme.space[3],
    },
    '&__header-btn': {
      position: 'absolute',
      right: 0,
      margin: 0,
      color: COLORS.NEUTRAL300,
    },
    '&__header-text': {
      alignSelf: 'center',
      margin: 0,
    },
    '&__content': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      flexDirection: 'column',
      backgroundColor: COLORS.WHITE,
      boxShadow: theme.shadows.SHADOW12,
      zIndex: zIndex.zModal,
      width: '400px',
      borderRadius: '4px',
      height: 'auto',
    },
    '&__content--wide': {
      width: '550px',
    },
    '&__steps-modal-text': {
      textAlign: 'center',
      fontWeight: '500',
      marginBottom: theme.space[3],
    },
  },
});

export const modalStyles = {
  overlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: zIndex.zOverlay,
    background: 'rgba(0, 0, 0, 0.5)',
    top: 0,
    left: 0,
  },
  content: {
    overflow: 'visible',
  },
};

import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import OrgSettingsSidebar from 'features/organizations/components/molecules/OrgSettingsSidebar';
import { Box, Flex } from 'shared/components/display';
import RightDrawer from 'shared/components/layout/RightDrawer/RightDrawer';
import LazySpinner from 'shared/components/molecules/LazySpinner';

import styles from './styles';

interface Props {
  organizationId: number;
}
const OrgSettingsLayout: React.FC<Props> = ({ organizationId }) => {
  return (
    <Flex css={styles} className="org-settings-layout">
      <OrgSettingsSidebar organizationId={organizationId} />
      <Suspense fallback={<LazySpinner />}>
        <Box className="org-settings-layout__content">
          <Outlet />
        </Box>
      </Suspense>
    </Flex>
  );
};

export default OrgSettingsLayout;

import { pick, groupBy, isObject } from 'lodash';
import {
    PERFORMANCE_DATA_TYPES,
    NON_NUMERIC_COLUMNS,
} from 'shared/config/performance';
import DashboardColumn from 'shared/models/DashboardColumnModel';

const { TOP_BOTTOM_ANALYSIS, COMPARISON } = PERFORMANCE_DATA_TYPES;

export default class ExportUtility {
    static CSV_ENCODING = 'data:text/csv;charset=utf-8';

    static CSV_LINE_END = '\r\n';

    #data;

    #columns;

    #summary;

    #breakdowns = null;

    #dateRanges = null;

    constructor(data, columns, optionals) {
        this.#data = data.metrics;
        this.#summary = data.summary;
        this.#columns = columns;
        if (optionals.breakdowns) {
            this.#breakdowns = optionals.breakdowns.split(',');
        }
        if (optionals.dateRanges) {
            this.#dateRanges = JSON.parse(optionals.dateRanges);
        }
    }

    static createExport(type, data, columns, optionals = {}) {
        return new ExportUtility(data, columns, optionals)._createExport(type);
    }

    _createExport(type) {
        if (this.#breakdowns) {
            this._appendBreakdownsToColumns();
        }

        if (type === TOP_BOTTOM_ANALYSIS) {
            return this._createTopBottomExport();
        }
        if (type === COMPARISON) {
            return ExportUtility.formatExportString(
                this._createDateComparisonCSVString(this.#data),
                this._addDateComparisonTotalsRow(),
            );
        }
        return ExportUtility.formatExportString(
            this._createCSVString(this.#data),
            this._addTotalsRow(),
        );
    }

    _createTopBottomExport() {
        const { top: topData, bottom: bottomData } = groupBy(
            this.#data,
            (row) => (row.top ? 'top' : 'bottom'),
        );
        const { top: topSummary, bottom: bottomSummary } = this.#summary;
        let topCsv;
        let bottomCsv;
        if (this.#dateRanges) {
            topCsv =
                this._createDateComparisonCSVString(topData) +
                this._addDateComparisonTotalsRow(topSummary);

            bottomCsv =
                this._createDateComparisonCSVString(bottomData) +
                this._addDateComparisonTotalsRow(bottomSummary);
        } else {
            topCsv =
                this._createCSVString(topData) + this._addTotalsRow(topSummary);

            bottomCsv =
                this._createCSVString(bottomData) +
                this._addTotalsRow(bottomSummary);
        }

        return ExportUtility.formatTopBottomExportString(topCsv, bottomCsv);
    }

    _appendBreakdownsToColumns() {
        this.#columns.unshift(...this.#breakdowns);
    }

    _addTotalsRow(summary = null) {
        const summaryRow = summary || this.#summary;
        const totalRow = this._createCSVString([summaryRow], false);
        return ExportUtility._formatTotalRow(totalRow);
    }

    _createCSVString(data, addHeaders = true) {
        const csvString = addHeaders ? [this.#columns.join(',')] : [];
        data.forEach((row) => {
            const paddedRow = this._addPaddingForEmptyCells(row);
            const filteredRow = pick(paddedRow, ...this.#columns);
            const formattedRow = Object.values(filteredRow).join(',');
            csvString.push(formattedRow);
        });
        return csvString.join(ExportUtility.CSV_LINE_END);
    }

    _addDateComparisonTotalsRow(summary = null) {
        const summaryRow = summary || this.#summary;
        const totalRow = this._createDateComparisonCSVString(
            [summaryRow],
            false,
        );
        return ExportUtility._formatTotalRow(totalRow);
    }

    _createDateComparisonCSVString(data, addHeaders = true) {
        const csvString = addHeaders
            ? this._createDateComparisonHeadersRow()
            : [];
        data.forEach((row) => {
            const paddedRow = this._addPaddingForEmptyCells(row);
            const filteredRow = pick(paddedRow, ...this.#columns);
            const comparisonRow = Object.keys(filteredRow).map((fieldName) => {
                if (isObject(filteredRow[fieldName])) {
                    return Object.values(filteredRow[fieldName]);
                }
                return filteredRow[fieldName];
            });
            const formattedRow = comparisonRow.join(',');
            csvString.push(formattedRow);
        });
        return csvString.join(ExportUtility.CSV_LINE_END);
    }

    _createDateComparisonHeadersRow() {
        const dateRangeColumns = [];
        this.#columns.forEach((col) => {
            if (NON_NUMERIC_COLUMNS.includes(col)) {
                dateRangeColumns.push(col);
            } else {
                this.#dateRanges.forEach(({ startDate, endDate }) => {
                    dateRangeColumns.push(
                        `${col}: (${startDate} - ${endDate})`,
                    );
                });
                dateRangeColumns.push(...DashboardColumn.CHANGE_FIELDS);
            }
        });

        return [dateRangeColumns.join(',')];
    }

    _addPaddingForEmptyCells(row) {
        return this.#columns.reduce(
            (result, column) =>
                !result[column] ? { [column]: '', ...result } : result,
            { ...row },
        );
    }

    static _formatTotalRow(totalRow) {
        return `${ExportUtility.CSV_LINE_END}Totals${totalRow}`;
    }

    static formatTopBottomExportString(topCsv, bottomCsv) {
        return `${ExportUtility.CSV_ENCODING},Top${ExportUtility.CSV_LINE_END}${topCsv}${ExportUtility.CSV_LINE_END}Bottom${ExportUtility.CSV_LINE_END}${bottomCsv}`;
    }

    static formatExportString(csv, totals) {
        return `${ExportUtility.CSV_ENCODING},${csv}${totals}`;
    }
}

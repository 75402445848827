import config from 'config';
import Rollbar from 'rollbar';

import { ROLLBAR_ERROR_NAME } from './RollbarError';

const rollbarConfig: Rollbar.Configuration = {
  accessToken: config.ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: config.SERVER_NAME,
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
        code_version: '0.1.0',
      },
    },
  },
  checkIgnore: function (isUncaught, args: any[]) {
    const error = args[1];
    // ignore custom 'RollbarError' errors and all 401 errors
    if ((error.name === ROLLBAR_ERROR_NAME && error?.logging === false) || error?.request?.status === 401) {
      return true;
    }
    return false;
  },
};

export const rollbar: Rollbar = new Rollbar(rollbarConfig);

export const setLoggingUser = (user) => {
  rollbar?.configure({ payload: { person: user } });
};

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  '&.video-player': {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  '.video-player': {
    '&__placeholder-text': {
      maxHeight: ['100vh', 'calc(100 * var(--vh))'],
      maxWidth: '100%',
      padding: '50px 10px',
      backgroundColor: COLORS.NEUTRAL700,
      color: COLORS.WHITE,
    },
  },
});

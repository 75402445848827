import React from 'react';
import { useDispatch } from 'react-redux';
import { faCopy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import AdsetSelect from 'features/accountPlatforms/facebook/AdsetSelect';
import DropdownWrapper from 'shared/components/atoms/DropdownWrapper';
import { Flex } from 'shared/components/display';
import FormButton from 'shared/components/FormButton/FormButton';

import { apiAction } from 'shared/actions/api';
import { useContainer } from 'shared/hooks/useContainer';
import { usePerformance } from 'shared/hooks/usePerformance';

import styles from './styles';

const DRAWER_LABEL = 'ad-copy-button';
const DUPLICATE_INITIATE_SUCESS = 'Ad Duplication initiated successfully';

const validationSchema = Yup.object({
  adsets: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    )
    .nullable(),
});
interface Props {
  accountPlatformId: number;
}

const DuplicateDropdown: React.FC<Props> = ({ accountPlatformId }) => {
  const dispatch = useDispatch();
  const { adCopyIds } = usePerformance();
  const { closeContainer } = useContainer(DRAWER_LABEL);

  return (
    <DropdownWrapper
      label={DRAWER_LABEL}
      clickComponent={
        <>
          <FontAwesomeIcon icon={faCopy} />
          <span>Duplicate</span>
        </>
      }
    >
      <Flex css={styles}>
        <Formik
          initialValues={{
            adsets: [],
          }}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            const adsetIds = values.adsets.map(({ value }) => value);
            dispatch(
              apiAction({
                path: {
                  route: '/facebook/ad/ad-copy',
                },
                method: 'POST',
                data: {
                  adsetIds,
                  adIds: adCopyIds,
                  accountPlatformId,
                },
                entity: 'Ad',
                successToast: {
                  message: DUPLICATE_INITIATE_SUCESS,
                },
                onSuccess: () => {
                  actions.setSubmitting(false);
                  closeContainer();
                },
              }),
            );
          }}
        >
          <Form>
            <AdsetSelect name="adsets" placeholder="Adsets" accountPlatformId={accountPlatformId} />
            <FormButton className="ad-copy__submit">Duplicate</FormButton>
          </Form>
        </Formik>
      </Flex>
    </DropdownWrapper>
  );
};

export default DuplicateDropdown;

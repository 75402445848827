import { Flex, Box } from 'shared/components/display';
import TextInput from 'shared/components/TextInput';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { Field } from 'formik';
import React, { useState } from 'react';
import { Text } from 'shared/components/display';
import styles from './styles';

const InputGroup: React.FC<{
  name: string;
  label: string;
  maxLength?: number;
  softMaxLength?: number;
  labelMaxLength?: number;
  component?: any;
}> = ({ component = TextInput, name, label, maxLength, softMaxLength, labelMaxLength }) => {
  const [softValidation, setSoftValidation] = useState<string | null>(null);

  const updateSoftValidation = (length: number, max: number) => {
    if (length > max)
      setSoftValidation(`Text over ${softMaxLength} characters may be truncated and is not recommended`);
    else if (length <= max && softValidation) setSoftValidation(null);

    if (maxLength && length == maxLength) setSoftValidation(`Cannot exceed ${maxLength} characters`);
  };

  const onInput = (event) => {
    const inputLength = event.target.value.length;
    if (softMaxLength) {
      updateSoftValidation(inputLength, softMaxLength);
    }
  };

  return (
    <Box css={styles}>
      <Text className="name-text-pairs__header" variant={TEXT_VARIANTS.H5}>{label}</Text>
      <Flex className="name-text-pairs">
        <Field
          maxLength={maxLength}
          softMaxLength={softMaxLength}
          className="name-text-pairs__item"
          name={name}
          component={component}
          label={label}
          placeholder={label}
          showCharactersCounter={!Boolean(softValidation)}
          onInput={onInput}
          softValidationMessage={softValidation}
          allowVerticalResize
        />
        <Field
          maxLength={labelMaxLength}
          className="name-text-pairs__item"
          name={`${name}Name`}
          component={TextInput}
          label={`${label} Name`}
          placeholder={`${label} Name`}
          showCharactersCounter={!Boolean(softValidation)}
          softValidationMessage={softValidation}
        />
      </Flex>
    </Box>
  );
};

export default InputGroup;

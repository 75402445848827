import { css } from '@emotion/react';

import { zIndex } from 'shared/config';

export default css({
  display: 'inline',
  position: 'relative',
  '.rich-text-link-with-menu': {
    '&__menu': {
      position: 'absolute',
      left: '0',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'white',
      padding: '6px 10px',
      gap: '10px',
      borderRadius: '6px',
      border: '1px solid lightgray',
      zIndex: zIndex.zTooltip,
    },
    '&__tooltip': {
      display: 'inline',
      width: 'auto',
    },
    '&__close-btn': {
      position: 'absolute',
      top: '-16px',
      right: '-21px',
    },
  },
});

import { css } from '@emotion/react';

export default css({
  '&.sidebar-nav-bar': {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    overflow: 'hidden',
  },
});

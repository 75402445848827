import { passwordConfirmationSchema, passwordValidationSchema } from 'shared/components/inputs/PasswordInput/config';
import * as yup from 'yup';

export interface ResetPasswordFormValues {
  password: {
    password: string;
    score: number;
    message: string;
  };
  confirmPassword: string;
}

export const RESET_PASSWORD_FIELDNAMES = {
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
};

export const resetPasswordSchema = yup.object({
  password: passwordValidationSchema,
  confirmPassword: passwordConfirmationSchema,
});

export const resetPasswordInitialValues = {
  password: {
    password: '',
    score: 0,
    message: '',
  },
  confirmPassword: '',
};

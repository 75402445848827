import { capitalize, unionBy } from 'lodash';

import { CreatorUser } from 'shared/typings/user';
import { formatDateWithSlashes } from 'shared/utilities/dateUtility';

import {
  AGE_FIELDNAMES,
  ageInitialValues,
  ETHNICITY_FIELDNAMES,
  ethnicityOtherOption,
  GENDER_FIELDNAMES,
  genderInitialValues,
  genderOtherOption,
} from '../../CreatorOnboardingStepsFields';
import { getHeightOption } from '../../CreatorOnboardingStepsFields/HeightFormFields';
import { LANGUAGE_FIELDNAMES, languageOtherOption } from '../../CreatorOnboardingStepsFields/LanguageFormFields';

export const FIELDNAMES = {
  BIRTHDATE: 'dateOfBirth',
  GENDER: 'gender',
  HEIGHT: 'height',
  ETHNICITY: 'ethnicity',
  LANGUAGE: 'language',
};

export type ProfileInfoSections = 'Date of Birth' | 'gender' | 'height' | 'ethnicity' | 'language' | 'Note:';

export const SECTIONS: {
  name: ProfileInfoSections;
  isTag: boolean;
}[] = [
  {
    name: 'Date of Birth',
    isTag: false,
  },
  {
    name: 'gender',
    isTag: false,
  },
  {
    name: 'height',
    isTag: false,
  },
  {
    name: 'ethnicity',
    isTag: true,
  },
  {
    name: 'language',
    isTag: true,
  },
  { name: 'Note:', isTag: false },
];

export const getInitialValues = (
  creatorUser: CreatorUser,
  tags: { [category: string]: { label: string; value: string }[] },
) => {
  const isStandardGender = genderInitialValues[GENDER_FIELDNAMES.GENDER].find(
    ({ value }) => value === creatorUser.gender,
  );

  const otherEthnicity = creatorUser.creatorTags?.find((tags) => tags.category === 'ethnicity' && tags.isOther)?.value;
  const ethnicity = unionBy(
    creatorUser.creatorTags
      ?.filter((tags) => tags.category === 'ethnicity' && !tags.isOther)
      .map((tag) => ({ label: capitalize(tag.value), value: tag.value, selected: true })),
    [...tags['ethnicity'], { ...ethnicityOtherOption, selected: otherEthnicity }],
    'value',
  );
  const otherLanguage = creatorUser.creatorTags?.find((tags) => tags.category === 'language' && tags.isOther)?.value;
  const language = unionBy(
    creatorUser.creatorTags
      ?.filter((tags) => tags.category === 'language' && !tags.isOther)
      .map((tag) => ({ label: capitalize(tag.value), value: tag.value, selected: true })),
    [...tags['language'], { ...languageOtherOption, selected: otherLanguage }],
    'value',
  );
  const dob = creatorUser.birthdate
    ? formatDateWithSlashes(creatorUser.birthdate)
    : ageInitialValues[AGE_FIELDNAMES.DOB];

  return {
    [AGE_FIELDNAMES.DOB]: dob,
    [GENDER_FIELDNAMES.GENDER]: isStandardGender
      ? genderInitialValues[GENDER_FIELDNAMES.GENDER].map((tag) => ({
          ...tag,
          selected: tag.value === creatorUser.gender,
        }))
      : genderInitialValues[GENDER_FIELDNAMES.GENDER].map((tag) => ({
          ...tag,
          selected: Boolean(tag.value === genderOtherOption.value && creatorUser.gender),
        })),
    [GENDER_FIELDNAMES.GENDER_OTHER]: !isStandardGender
      ? creatorUser.gender || genderInitialValues[GENDER_FIELDNAMES.GENDER_OTHER]
      : '',
    [FIELDNAMES.HEIGHT]: getHeightOption(creatorUser.height) || '',
    [ETHNICITY_FIELDNAMES.ETHNICITY]: ethnicity?.length > 0 ? ethnicity : [...tags['ethnicity'], ethnicityOtherOption],
    [ETHNICITY_FIELDNAMES.ETHNICITY_OTHER]: otherEthnicity || '',
    [LANGUAGE_FIELDNAMES.LANGUAGE]: language?.length > 0 ? language : [...tags['language'], languageOtherOption],
    [LANGUAGE_FIELDNAMES.LANGUAGE_OTHER]: otherLanguage || '',
  };
};

export const getFieldsToReset = (activeForm: ProfileInfoSections | null, initialValues) => {
  switch (activeForm) {
    case 'Date of Birth':
      return {
        [AGE_FIELDNAMES.DOB]: initialValues[AGE_FIELDNAMES.DOB],
      };

    case 'gender':
      return {
        [GENDER_FIELDNAMES.GENDER]: initialValues[GENDER_FIELDNAMES.GENDER],
        [GENDER_FIELDNAMES.GENDER_OTHER]: initialValues[GENDER_FIELDNAMES.GENDER_OTHER],
      };

    case 'height':
      return {
        [FIELDNAMES.HEIGHT]: initialValues[FIELDNAMES.HEIGHT],
      };

    case 'ethnicity':
      return {
        [ETHNICITY_FIELDNAMES.ETHNICITY]: initialValues[ETHNICITY_FIELDNAMES.ETHNICITY],
        [ETHNICITY_FIELDNAMES.ETHNICITY_OTHER]: initialValues[ETHNICITY_FIELDNAMES.ETHNICITY_OTHER],
      };
    case 'language':
      return {
        [LANGUAGE_FIELDNAMES.LANGUAGE]: initialValues[LANGUAGE_FIELDNAMES.LANGUAGE],
      };
    default:
      return {};
  }
};

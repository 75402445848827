import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { zIndex } from 'shared/config';
import theme from 'shared/styles/themes';

export default css({
  '&.toggle-switch': {
    alignItems: 'center',
    justifyContent: 'center',
    width: 'max-content',
  },
  '&.right-align': {
    justifyContent: 'space-between',
    width: '100%',
  },
  '.toggle-switch': {
    '&__label': {
      marginBottom: 0,
    },
    '&__label--mini': {
      lineHeight: '1rem',
    },
    '&__wrapper': {
      position: 'relative',
      width: '2.75rem',
      height: '1.4rem',
      margin: 0,
      marginLeft: '0.75rem',
    },
    '&__wrapper--mini': {
      width: '2rem',
      height: '1rem',
    },
    '&__input': {
      opacity: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: zIndex.zButton,
      cursor: 'pointer',
    },
    '&__input:checked + .toggle-switch__slider': {
      backgroundColor: COLORS.GREEN500,
    },
    '&__input:checked + .toggle-switch__slider::before': {
      transform: 'translateX(1.35rem)',
    },
    '&__input--mini:checked + .toggle-switch__slider::before': {
      transform: 'translateX(1rem)',
    },
    '&__input:checked:focus + &__slider, &__input:checked:active + &__slider': {
      backgroundColor: COLORS.GREEN700,
    },
    '&__input:not(:checked):focus + &__slider, &__input:not(:checked):active + &__slider': {
      backgroundColor: COLORS.NEUTRAL300,
    },
    '&__slider': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: COLORS.NEUTRAL100,
      transition: 'background-color 0.2s ease-in-out',
      borderRadius: '1rem',
    },
    '&__slider:before': {
      borderRadius: '50%',
      position: 'absolute',
      content: '""',
      backgroundColor: 'white',
      transition: 'all 0.2s ease-in-out',
      boxShadow: theme.shadows.SHADOW02,
      width: '1.1rem',
      height: '1.1rem',
      left: '0.15rem',
      bottom: '0.15rem',
    },
    '&__slider--mini:before': {
      width: '0.75rem',
      height: '0.75rem',
      left: '0.1rem',
      bottom: '0.1rem',
    },
  },
});

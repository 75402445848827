import React, { useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import TextInput from 'shared/components/TextInput';
import { makeLabel } from 'shared/lib/formik';
import { Box, Flex, Text } from 'shared/components/display';
import { TEXT_VARIANTS } from 'shared/styles/text';
import FormButton from 'shared/components/FormButton';
import { SelectInput } from 'shared/components/molecules/Select';
import { REPORT_COPY } from 'shared/config/copy';
import NumberInput from 'shared/components/molecules/NumberInput';
import { reportSchema, ReportValues } from 'features/accounts/components/molecules/ReportForm/config';
import reportFormStyles from 'features/accounts/components/molecules/ReportForm/reportFormStyles';

const NAME = 'name';
const INTERVAL_VALUE = 'intervalValue';
const INTERVAL = 'interval';

const intervalOptions = [
  {
    label: 'minutes',
    value: 'minutes',
  },
  {
    label: 'hours',
    value: 'hours',
  },
  {
    label: 'days',
    value: 'days',
  },
  {
    label: 'weeks',
    value: 'weeks',
  },
];

interface Props {
  onSubmit: (values: ReportValues) => void;
  initialValues?: ReportValues;
}

const ReportForm: React.FC<Props> = ({ onSubmit, initialValues }) => {
  const defaultValues = useMemo(() => {
    return {
      name: initialValues?.name || '',
      intervalValue: initialValues?.intervalValue || '1',
      interval: initialValues?.interval || {
        label: 'hours',
        value: 'hours',
      },
    };
  }, [initialValues]);

  return (
    <Formik<ReportValues> onSubmit={onSubmit} initialValues={defaultValues} validationSchema={reportSchema}>
      <Form>
        <Box css={reportFormStyles} className="report-form">
          <Field name={NAME} component={TextInput} placeholder={makeLabel(NAME, true)} label={makeLabel(NAME, true)} />
          <Text className="report-form__label" variant={TEXT_VARIANTS.BODY}>
            Interval
          </Text>
          <Flex className="report-form__interval-wrapper">
            <Field component={NumberInput} name={INTERVAL_VALUE} />
            <Field
              className="report-form__interval"
              component={SelectInput}
              name={INTERVAL}
              options={intervalOptions}
              width="120px"
              hidePlaceholder
            />
          </Flex>
          <FormButton className="report-form__submit-btn">{REPORT_COPY.BUTTON_SCHEDULE}</FormButton>
        </Box>
      </Form>
    </Formik>
  );
};

export default ReportForm;

import React, { useEffect, useMemo, useState } from 'react';
import cs from 'classnames';
import { Flex } from 'shared/components/display';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'rebass/styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import styles from './styles';
import { breakpoints } from 'shared/styles/styleFunctions';
import { SEPARATOR_TEXT, setPages } from 'shared/components/molecules/PageIndex/util';

interface Props {
  pageNumber: number;
  total: number;
  perPage: number;
  onPageChange: (page: number) => void;
}
const PageIndex: React.FC<Props> = ({ pageNumber, total, perPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(pageNumber);

  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const { pages, totalPages } = useMemo(() => {
    const totalPages = Math.ceil(total / perPage);
    return {
      pages: setPages(totalPages, currentPage),
      totalPages,
    };
  }, [currentPage, total, perPage]);

  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber]);

  const setPage = (page) => {
    setCurrentPage(page);
    if (onPageChange) onPageChange(page);
  };

  return (
    <Flex css={styles} className={cs('page-index')}>
      {currentPage > 1 && (
        <Button
          className="page-index__page-nav-btn"
          variant={isMobile ? BUTTON_VARIANTS.PLAIN : BUTTON_VARIANTS.TEXT}
          onClick={() => setPage(currentPage - 1)}
        >
          <FontAwesomeIcon icon={faAngleLeft} size="sm" role="img" aria-hidden />
          previous
        </Button>
      )}
      {!isMobile &&
        pages.map((page) => {
          const isCurrentPage = currentPage === page;
          const notPage = typeof page !== 'number';
          return (
            <Button
              variant={isCurrentPage ? BUTTON_VARIANTS.PLAIN : BUTTON_VARIANTS.TEXT}
              className={cs('page-index__page-btn', {
                'page-index__page-btn--current': isCurrentPage,
              })}
              onClick={!notPage ? () => setPage(page) : undefined}
              disabled={notPage}
              key={!notPage ? page : `${page.separator}-${SEPARATOR_TEXT}`}
            >
              {!notPage ? page : SEPARATOR_TEXT}
            </Button>
          );
        })}

      {currentPage < totalPages && (
        <Button
          className="page-index__page-nav-btn"
          variant={isMobile ? BUTTON_VARIANTS.PLAIN : BUTTON_VARIANTS.TEXT}
          onClick={() => setPage(currentPage + 1)}
        >
          next
          <FontAwesomeIcon icon={faAngleRight} size="sm" role="img" aria-hidden />
        </Button>
      )}
    </Flex>
  );
};

export default PageIndex;

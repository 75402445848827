import React from 'react';
import { components, OptionProps } from 'react-select';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Box } from 'shared/components/display';
import styles from './styles';
import { SelectValue } from 'shared/components/molecules/Select';

export const SelectOption: React.FC<OptionProps<SelectValue>> = ({ innerRef, ...props }) => {
  // @ts-ignore
  const { menuPortalTarget, showOptionTooltip } = props.selectProps;
  return (
    <components.Option innerRef={innerRef} {...props}>
      <Tooltip content={props.data.label} portalTarget={menuPortalTarget} disabled={!showOptionTooltip}>
        <Box css={styles} className="select-option__content" data-cy={`select-option__content--${props.data.label}`}>
          {props.data.label}
        </Box>
      </Tooltip>
    </components.Option>
  );
};

import React from 'react';

import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Box, Flex, Text } from 'shared/components/display';

import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles, { CLASSNAMES, MAIN_CLASS, tooltipStyles } from './styles';

interface Props {
  briefName: string;
  brandName: string;
}
const BriefAndBrandCellWrapper = ({ briefName, brandName }: Props) => (
  <Box css={styles} className={MAIN_CLASS}>
    <Tooltip
      placement="left-end"
      content={
        <Flex css={tooltipStyles} className={CLASSNAMES.tooltip}>
          <Text>{brandName}</Text>
          <Text>{briefName}</Text>
        </Flex>
      }
    >
      <Flex flexDirection="column" width="100%">
        <Text variant={TEXT_VARIANTS.BODY_SM} color={COLORS.NEUTRAL600} className={CLASSNAMES.text}>
          {brandName}
        </Text>
        <Text variant={TEXT_VARIANTS.BODY} className={CLASSNAMES.text}>
          {briefName}
        </Text>
      </Flex>
    </Tooltip>
  </Box>
);

export default BriefAndBrandCellWrapper;

const BASE = '/org/:organizationId/account/:accountId';

export default {
  ACCOUNT_ROUTES: BASE,

  ACCOUNT_BUDGET_CHECKOUT: BASE + '/checkout',

  ACCOUNT_CREATORS: BASE + '/creator',

  ASSETS_LIBRARY: BASE + '/library',

  COLLECTION: BASE + '/library/:collectionId',

  VIDEO_COMPOSITION: 'compose-videos',

  BIDDING_TEST: BASE + '/configure/bidding-test',

  EDIT_ACCOUNT_DETAILS: BASE + '/edit/details',

  ONBOARD_COMPLETE: BASE + '/onboard-complete',

  PLATFORMS: BASE + '/platform',

  PRICING: BASE + '/pricing',

  ACCOUNT_HEALTH: BASE + '/account-health',

  TRENDS_LIBRARY: BASE + '/trends',
};

import React from 'react';
import cs from 'classnames';
import { Editor } from 'slate';
import { RenderElementProps, RenderLeafProps, useSlateStatic } from 'slate-react';

import Tooltip from 'shared/components/atoms/Tooltip';
import { RichTextLink, RichTextLinkWithMenu } from 'shared/components/Richtext/RichTextLink';
import RichtextPicture from 'shared/components/Richtext/RichtextPicture/RichtextPicture';
import { RichTextFormats } from 'shared/components/Richtext/shared/types';
import { isBlockActive, isMarkActive, toggleBlock, toggleMark } from 'shared/components/Richtext/shared/utils';

type ElementProps = RenderElementProps & {
  readOnly: boolean;
  richTextElem?: React.ReactElement;
};

export const Element: React.FC<ElementProps> = (props) => {
  const { attributes, children, element, readOnly } = props;
  switch (element.type) {
    case RichTextFormats.UNORDERED_LIST:
      return <ul {...attributes}>{children}</ul>;
    case RichTextFormats.HEADING_ONE:
      return <h1 {...attributes}>{children}</h1>;
    case RichTextFormats.HEADING_TWO:
      return <h2 {...attributes}>{children}</h2>;
    case RichTextFormats.HEADING_THREE:
      return <h3 {...attributes}>{children}</h3>;
    case RichTextFormats.HEADING_FOUR:
      return <h4 {...attributes}>{children}</h4>;
    case RichTextFormats.HEADING_FIVE:
      return <h5 {...attributes}>{children}</h5>;
    case RichTextFormats.HEADING_SIX:
      return <h6 {...attributes}>{children}</h6>;
    case RichTextFormats.LIST_ITEM:
      return <li {...attributes}>{children}</li>;
    case RichTextFormats.LIST_ITEM_TEXT:
      return <div {...attributes}>{children}</div>;
    case RichTextFormats.ORDERED_LIST:
      return <ol {...attributes}>{children}</ol>;
    case RichTextFormats.PARAGRAPH:
      return <p {...attributes}>{children}</p>;
    case RichTextFormats.LINK:
      return readOnly ? <RichTextLink className="rich-text__link" {...props} /> : <RichTextLinkWithMenu {...props} />;
    case RichTextFormats.IMAGE:
      return (
        <RichtextPicture readOnly={readOnly} attributes={attributes} element={element}>
          {children}
        </RichtextPicture>
      );
    case RichTextFormats.INDENT:
      const indentStyle = {
        textIndent: '25px',
      };
      return <div style={indentStyle}>{children}</div>;
    default:
      return <span {...attributes}> {children} </span>;
  }
};

export const Leaf: React.FC<RenderLeafProps> = ({ attributes, children, leaf }) => {
  if (leaf[RichTextFormats.BOLD]) {
    children = <strong>{children}</strong>;
  }

  if (leaf[RichTextFormats.CODE]) {
    children = <code>{children}</code>;
  }

  if (leaf[RichTextFormats.ITALIC]) {
    children = <em>{children}</em>;
  }

  if (leaf[RichTextFormats.UNDERLINED]) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

type BaseToolbarButtonProps = {
  children: JSX.Element;
  tooltip: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isActive: boolean;
};

const BaseToolbarButton: React.FC<BaseToolbarButtonProps> = ({ children, tooltip, onClick, isActive }) => (
  <button
    key={tooltip}
    type="button"
    onClick={onClick}
    className={cs('rich-text__icon-button', {
      'rich-text__icon-button--active': isActive,
    })}
    aria-label={tooltip}
    tabIndex={0}
  >
    {tooltip ? <Tooltip content={tooltip}>{children}</Tooltip> : children}
  </button>
);

type ToolbarButton = {
  children: JSX.Element;
  format: RichTextFormats;
  tooltip: string;
  onClick?: (editor: Editor) => void;
};

export const MarkButton: React.FC<ToolbarButton> = ({ onClick = null, format, ...props }) => {
  const editor = useSlateStatic();
  return (
    <BaseToolbarButton
      {...props}
      onClick={onClick ? () => onClick(editor) : () => toggleMark(editor, format)}
      isActive={isMarkActive(editor, format)}
    />
  );
};

export const BlockButton: React.FC<ToolbarButton> = ({ onClick = null, format, ...props }) => {
  const editor = useSlateStatic();
  return (
    <BaseToolbarButton
      {...props}
      onClick={onClick ? () => onClick(editor) : () => toggleBlock(editor, format)}
      isActive={isBlockActive(editor, format)}
    />
  );
};

export const USER_ACTION_TYPES = {
    USER_CLEARED: 'USER_CLEARED',
    USER_CONFIGURATION_CHANGED: 'USER_CONFIGURATION_CHANGED',
    USER_REFRESHED: 'USER_REFRESHED',
    USER_SIGNED_IN: 'USER_SIGNED_IN',
};

export function userRefreshed(payload) {
    return {
        type: USER_ACTION_TYPES.USER_REFRESHED,
        payload,
    };
}

export function userSignedIn(payload) {
    return {
        type: USER_ACTION_TYPES.USER_SIGNED_IN,
        payload,
    };
}

export function userCleared(payload) {
    return {
        type: USER_ACTION_TYPES.USER_CLEARED,
        payload,
    };
}

export function userConfigurationChanged(payload) {
    return {
        type: USER_ACTION_TYPES.USER_CONFIGURATION_CHANGED,
        payload,
    };
}
import React from 'react';
import cs from 'classnames';
import { Box, Flex, Text } from 'shared/components/display';

import { OnSubmit } from 'shared/lib/formik';
import Modal from 'shared/components/molecules/Modal';
import { GENERIC_COPY, SUBMISSION_COPY } from 'shared/config/copy';
import { Field, Form, Formik } from 'formik';
import TextInput from 'shared/components/TextInput';
import FormButton from 'shared/components/FormButton';
import { useTrends } from 'features/trends/hooks/useTrends';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import ModalFormHr from 'shared/components/atoms/ModalFormHr';
import { SIZING } from 'shared/config/formatting';

import styles from './styles';
import { initialValues, Values, getValidationSchema } from './config';

interface Props {
  isOpen: boolean;
  onRequestClose?: () => void;
  fileId?: number;
}

const AssetToTrendFormModal: React.FC<Props> = ({ isOpen, onRequestClose, fileId }) => {
  const { createTrend } = useTrends();

  const onSubmit: OnSubmit<Values> = (values, formikHelper) => {
    // TODO: need to add unique name validation
    if (fileId)
      createTrend(
        {
          name: values.trendName,
          fileId,
          isBrkfstAsset: true,
        },
        onRequestClose,
        () => {
          formikHelper.setSubmitting(false);
        },
      );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      modalSize={SIZING.MEDIUM_WIDE}
      title={SUBMISSION_COPY.MODAL_TITLE_ADD_TO_TREND}
      component={
        <Box css={styles} className={cs('asset-to-trend-form-modal')}>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={getValidationSchema()}>
            <Form>
              <Text className="asset-to-trend-form-modal__text">{SUBMISSION_COPY.MODAL_FORM_DESCRIPTION_TREND}</Text>
              <Field
                name="trendName"
                placeholder="Trend Name"
                label="Trend Name"
                component={TextInput}
                className="asset-to-trend-form-modal__name-input"
                dataCy="asset-to-trend-form-modal__name-input"
              />
              <ModalFormHr />
              <Flex className={'asset-to-trend-form-modal__btn-wrapper'}>
                <FormButton type="button" onClick={onRequestClose} variant={BUTTON_VARIANTS.SECONDARY}>
                  {GENERIC_COPY.BUTTON_CANCEL}
                </FormButton>
                <FormButton type="submit" dataCy="asset-to-trend-form-modal__trend-btn">
                  {SUBMISSION_COPY.BUTTON_ADD_TO_TREND}
                </FormButton>
              </Flex>
            </Form>
          </Formik>
        </Box>
      }
    />
  );
};

export default AssetToTrendFormModal;

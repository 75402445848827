import React from 'react';
import { useParams } from 'react-router-dom';

import TiktokAccountCreationForm from 'features/accountPlatforms/components/molecules/TiktokAccountCreationForm/TiktokAccountCreationForm';
import TiktokConnect from 'features/accountPlatforms/components/molecules/TiktokConnect/TiktokConnect';
import FacebookAccountCreationForm from 'features/accountPlatforms/facebook/components/AccountCreationForm';
import FacebookConnect from 'features/accountPlatforms/facebook/FacebookConnect/FacebookConnect';
import { Flex } from 'shared/components/display';

import { LinkCreator } from 'shared/utilities/linkUtility';

type RouteParams = {
  organizationId: string;
  accountId: string;
};

const AccountPlatformCreation = ({ facebookConnected = false }) => {
  const { accountId, organizationId } = useParams<RouteParams>();

  return (
    <Flex m={2}>
      <Flex mr={4}>
        <FacebookConnect
          facebookConnected={facebookConnected}
          redirectURI={LinkCreator.createLink({
            routeKey: 'PLATFORMS',
            variables: {
              organizationId,
              accountId,
            },
            query: {
              modalOpen: true,
              platform: 'facebook',
            },
          })}
        />
      </Flex>

      <TiktokConnect
        redirectURI={LinkCreator.createLink({
          routeKey: 'PLATFORMS',
          variables: {
            organizationId,
            accountId,
          },
          query: {
            modalOpen: true,
            platform: 'tiktok',
          },
        })}
      />
      <TiktokAccountCreationForm />
      <FacebookAccountCreationForm />
    </Flex>
  );
};

export default AccountPlatformCreation;

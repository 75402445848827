import React from 'react';
import cs from 'classnames';
import { Flex, Text } from 'shared/components/display';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/pro-light-svg-icons';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

import styles from './styles';

interface Props {
  theme: 'warning' | 'info';
  message: string;
  className?: string;
  extraStyles?: Record<string, string>;
}
const NotificationBanner: React.FC<Props> = ({ theme = 'warning', message, className, extraStyles }) => {
  return (
    <Flex css={styles(extraStyles)} className={cs('notification-banner', `notification-banner--${theme}`, className)}>
      <Flex className="notification-banner__icon">
        {theme == 'info' && (
          <FontAwesomeIcon className="notification-banner__info-icon" icon={faLightbulb} size="2xs" fixedWidth />
        )}
        {theme == 'warning' && (
          <FontAwesomeIcon
            className="notification-banner__warning-icon"
            icon={faCircleExclamation}
            size="lg"
            fixedWidth
          />
        )}
      </Flex>
      <Text className="notification-banner__text">{message}</Text>
    </Flex>
  );
};

export default NotificationBanner;

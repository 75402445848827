import React from 'react';
import { Field } from 'formik';
import * as yup from 'yup';

import { Box } from 'shared/components/display';
import { StepFieldProps } from 'shared/components/forms/MultiStepForm/interfaces';
import { SelectInput } from 'shared/components/molecules/Select';
import { emptySelectOption, getSelectInputSchema } from 'shared/components/molecules/Select/SelectInput';

import styles from './styles';

const options = [
  { label: 'under 5\'0"', value: 'under 5\'0"' },
  { label: '5\'0"', value: '5\'0"' },
  { label: '5\'1"', value: '5\'1"' },
  { label: '5\'2"', value: '5\'2"' },
  { label: '5\'3"', value: '5\'3"' },
  { label: '5\'4"', value: '5\'4"' },
  { label: '5\'5"', value: '5\'5"' },
  { label: '5\'6"', value: '5\'6"' },
  { label: '5\'7"', value: '5\'7"' },
  { label: '5\'8"', value: '5\'8"' },
  { label: '5\'9"', value: '5\'9"' },
  { label: '5\'10"', value: '5\'10"' },
  { label: '5\'11"', value: '5\'11"' },
  { label: '6\'0"', value: '6\'0"' },
  { label: '6\'1"', value: '6\'1"' },
  { label: '6\'2"', value: '6\'2"' },
  { label: '6\'3"', value: '6\'3"' },
  { label: '6\'4"', value: '6\'4"' },
  { label: '6\'5"', value: '6\'5"' },
  { label: 'above 6\'5"', value: 'above 6\'5"' },
];

export const HeightFormFields: React.FC<StepFieldProps> = ({ formikProps }) => {
  return (
    <Box css={styles}>
      <Field
        className="height__select"
        name={HEIGHT_FIELDNAME}
        component={SelectInput}
        label="Height"
        placeholder="Select your height"
        options={options}
        isSearchable
        dataCy="height-select"
      />
    </Box>
  );
};

export const HEIGHT_FIELDNAME = 'height';

export const heightInitialValues = { height: emptySelectOption };

export const heightSchema = yup.object({
  height: getSelectInputSchema(false, yup.string()),
});

export type HeightValues = { height: { value: string; label: string } };

export const getHeightOption = (value: string) => {
  return { ...options.find((option) => option.value === value) };
};

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { BrkfstUser } from 'shared/typings/user';
import { trackPageView } from 'shared/utilities/trackingUtility';

const usePageTracking = (user: BrkfstUser) => {
  const { pathname, search } = useLocation<any>();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      trackPageView(pathname + search, user);
    }
  }, [initialized, pathname, search, user]);
};

export default usePageTracking;

import React from 'react';
import { Text } from 'shared/components/display';
import { isString } from 'lodash';
import { formatDurationSeconds } from 'shared/utilities/stringUtility';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';

interface Props {
  time: string | number;
}
const Duration: React.FC<Props> = ({ time }) => {
  const displayTime = isString(time) ? formatDurationSeconds(+time) : formatDurationSeconds(time);
  return (
    <Text
      color={COLORS.WHITE}
      variant={TEXT_VARIANTS.BODY_SM}
      className="duration"
      style={{
        backgroundColor: `${COLORS.BLACK}75`,
        borderRadius: '2px',
        userSelect: 'none',
        padding: '0 4px',
        margin: 0,
      }}
    >
      {displayTime}
    </Text>
  );
};

export default Duration;

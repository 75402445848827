import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

import { briefAdded } from 'features/briefs/briefs.slice';
import { fileRemoved, filesAdded, filesAddedToCollection, filesChanged } from 'features/media/files.slice';

import { Collection } from 'shared/typings/collections';

export interface CollectionState {
  data: Collection[];
  total: number;
  selectedCollectionId?: number | null;
}

interface CollectionPayload<T = Collection> {
  data: T;
}

const initialState: CollectionState = {
  data: [],
  total: 0,
  selectedCollectionId: null,
};

const collectionsSlice = createSlice<CollectionState, SliceCaseReducers<CollectionState>>({
  name: 'collections',
  initialState,
  reducers: {
    collectionsLoaded: (collections, action: PayloadAction<CollectionPayload<CollectionState>>) => {
      const { data, total } = action.payload.data;
      const collectionArray: Collection[] = [];
      data.forEach((collectionObj) => {
        const col = Object.assign({}, collectionObj);
        col.settings = {
          backgroundImage: collectionObj.thumbnailUrl,
        };
        collectionArray.push(col);
      });
      collections.data = collectionArray;
      collections.total = total;
    },
    collectionLoaded: (collections, action: PayloadAction<CollectionPayload>) => {
      const { data } = action.payload;
      const col = {
        ...data,
        settings: {
          backgroundImage: data.thumbnailUrl,
        },
      };
      collections.data = [col];
      collections.total = 1;
    },
    collectionAdded: (collections, action: PayloadAction<CollectionPayload>) => {
      collections.data.unshift(action.payload.data);
      collections.total += 1;
    },
    collectionChanged: (collections, action: PayloadAction<CollectionPayload>) => {
      const collection = collections.data.find((col) => col.id === action.payload.data.id);
      if (collection) {
        const index = collections.data.indexOf(collection);
        collections.data.splice(index, 1, action.payload.data);
      }
    },
    collectionRemoved: (collections, action: PayloadAction<CollectionPayload<Pick<Collection, 'id'>>>) => {
      collections.data = collections.data.filter((col) => col.id !== action.payload.data.id);
      collections.total -= 1;
      if (action.payload.data.id === collections.selectedCollectionId) {
        collections.selectedCollectionId = null;
      }
    },
    selectedCollectionChanged: (collections, action: PayloadAction<number>) => {
      collections.selectedCollectionId = action.payload;
    },
    collectionsCleared: (collections) => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(briefAdded, (collections, action) => {
        const brief = action.payload.data;
        const { collection } = brief;
        if (collection) {
          collections.data.push(collection);
          collections.total += 1;
        }
      })
      .addCase(filesAdded, (collections, action) => {
        const {
          data: files,
          params: { updateCollection, collectionId },
        } = action.payload;
        if (updateCollection) {
          const filesArray = Array.isArray(files) ? files : [files];

          const numFiles = filesArray.length;
          collections.data = collections.data.map((col) => {
            if (col.id === +collectionId) {
              return {
                ...col,
                numFiles: col.numFiles + numFiles,
              };
            }
            return col;
          });
        }
      })
      .addCase(fileRemoved, (collections, action) => {
        if (action.payload.params?.collectionId) {
          const { collectionId } = action.payload.params;
          collections.data = collections.data.map((col) => {
            if (col.id === +collectionId) {
              return {
                ...col,
                numFiles: col.numFiles - 1,
              };
            }
            return col;
          });
        }
      })
      // update the thumbnail of the collection to the latest file changed
      // this will be then reset by the server when all collection files are fetched again.
      .addCase(filesChanged, (collections, action) => {
        const { collectionId, thumbnailUrl } = action.payload.data[0];
        if (collectionId) {
          const collection = collections.data.find((col) => col.id === collectionId);
          if (collection) {
            const index = collections.data.indexOf(collection);
            collection.settings = {
              backgroundImage: thumbnailUrl,
            };
            collections.data.splice(index, 1, collection);
          }
        }
      })
      .addCase(filesAddedToCollection, (collections, action) => {
        const { fileIds, collectionId } = action.payload.data;
        collections.data = collections.data.map((collection) => {
          if (collection.id === collectionId) collection.numFiles += fileIds.length;
          return collection;
        });
      }),
});

export const {
  collectionsLoaded,
  collectionChanged,
  collectionAdded,
  collectionRemoved,
  selectedCollectionChanged,
  collectionLoaded,
  collectionsCleared,
} = collectionsSlice.actions;

export default collectionsSlice.reducer;

interface State {
  collections: CollectionState;
}

export const getCollection = (id: number) => (state: State) => {
  const collection = state.collections.data.find((element) => element.id === id);
  return collection || {};
};

export const getCollections = (state: State) => state.collections.data;
export const getCollectionCount = (state: State) => state.collections.total;
export const getDefaultCollection = (state: State) =>
  state.collections.data.find((collection) => collection.isDefault) || {};

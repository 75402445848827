import React, { useRef, useState } from 'react';
import { Button } from 'rebass/styled-components';

import { ProfilePictureSkeleton } from 'features/users/components/atoms/ProfilePictureSkeleton';
import { ProfilePicture } from 'features/users/components/molecules/ProfilePicture';
import { useUser } from 'features/users/hooks/useUser';
import HiddenFileInput from 'shared/components/atoms/HiddenFileInput';
import { Box, Flex, Text } from 'shared/components/display';
import Dropdown from 'shared/components/molecules/Dropdown';
import MediaUploadButton from 'shared/components/molecules/MediaUploadButton';

import { USER_COPY } from 'shared/config/copy';
import { BRKFST_SUPPORTED_IMAGE_FORMATS } from 'shared/config/fileFormats';
import { useFileNotification } from 'shared/hooks/useFileNotification';
import { Input } from 'shared/lib/formik';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { STYLES } from 'shared/styles/styles';
import { TEXT_VARIANTS } from 'shared/styles/text';

interface Props {
  userId: number;
}

interface Value {
  name: string;
  url: string;
  removePicture?: boolean;
  file: File;
}

const ProfilePictureWithDropdown: Input<Value, Props> = ({ userId, field: { name, value, onChange } }) => {
  const { user, loadingProfilePicture: loading } = useUser(userId);
  const [error, setError] = useState('');
  const hiddenFileInputRef = useRef(null);
  const size = 'md';

  useFileNotification();

  const onFileUpload = (files) => {
    if (files.length > 0) {
      const [file] = files;
      const mediaObjectURL = URL.createObjectURL(file);
      setError('');
      onChange({
        target: {
          value: { name: file.name, url: mediaObjectURL, file },
          name,
        },
      });
    }
  };

  const onRemovePic = () => {
    onChange({
      target: {
        value: { name: '', url: '', removePicture: true },
        name,
      },
    });
  };

  return (
    <>
      <Flex justifyContent={STYLES.CENTER}>
        <Dropdown
          ClickComponent={
            loading ? (
              <ProfilePictureSkeleton size={size} />
            ) : (
              <Box>
                <ProfilePicture
                  user={{
                    firstName: user.firstName,
                    lastName: user.lastName,
                    profilePic: value,
                  }}
                  size={size}
                />
                <Text variant={TEXT_VARIANTS.ERROR}>{error}</Text>
              </Box>
            )
          }
          placement="bottom"
          width="192px"
        >
          <MediaUploadButton
            buttonContent={USER_COPY.BUTTON_EDIT_PROFILE_PICTURE}
            buttonVariant={BUTTON_VARIANTS.OPTION}
            // @ts-ignore
            hiddenFileInputRef={hiddenFileInputRef}
            acceptedMedia={BRKFST_SUPPORTED_IMAGE_FORMATS}
          />
          <Button onClick={onRemovePic} disabled={!value || !value.url}>
            {USER_COPY.BUTTON_DELETE_PROFILE_PICTURE}
          </Button>
        </Dropdown>
      </Flex>

      <HiddenFileInput
        name="profilePic"
        onChange={onFileUpload}
        acceptedMedia={BRKFST_SUPPORTED_IMAGE_FORMATS}
        ref={hiddenFileInputRef}
        onError={setError}
        validateFiles
      />
    </>
  );
};

export default ProfilePictureWithDropdown;

import React, { ReactNode } from 'react';
import cs from 'classnames';

import { Box } from 'shared/components/display';

import styles from './oauthButtonStyles';

interface Props {
  children: ReactNode | Array<ReactNode>;
  clickHandler: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

const OAuthButton: React.FC<Props> = ({ children, clickHandler, className }) => {
  return (
    <Box as="button" css={styles} className={cs(className, 'oauth-button')} type="button" onClick={clickHandler}>
      <Box className="oauth-button__wrapper">{children}</Box>
    </Box>
  );
};

export default OAuthButton;

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import config from 'config';

import { redirectURISet } from 'features/auth/auth.slice';
import { getErrors, getSuccessMessage, loadingToggled, setSuccessMessage } from 'features/ui/ui.slice';

import { apiAction } from 'shared/actions/api';
import { userCleared, userRefreshed, userSignedIn } from 'shared/actions/currentUser';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_AUTH_ROUTES } from 'shared/config/routes/api/apiAuthRoutes';
import { LOCAL_STORAGE } from 'shared/config/storage';
import { AUTH_TOASTS } from 'shared/config/toasts/authToasts';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useQuery } from 'shared/hooks/useQuery';
import { UserType } from 'shared/typings/user/enums';
import { encryptString } from 'shared/utilities/encryptionUtility';
import { LinkCreator, LinkUtilityConfiguration } from 'shared/utilities/linkUtility';
import { pushGtmSignUpEvent } from 'shared/utilities/trackingUtility';

const AUTH_ACTIVATE = 'AUTH_ACTIVATE';
const RESET_PASSWORD = 'RESET_PASSWORD';
const LOG_IN = 'LOG_IN';
const CONFIRMATION_LINK = 'CONFIRMATION_LINK';

export const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stringifyQuery, parseQuery } = useQuery();

  const activationErrors = useSelector(getErrors(AUTH_ACTIVATE));
  const resetPasswordErrors = useSelector(getErrors(RESET_PASSWORD));
  const confirmationLinkErrors = useSelector(getErrors(CONFIRMATION_LINK));
  const confirmationLinkSuccessMessage = useSelector(getSuccessMessage(CONFIRMATION_LINK));

  const { loading } = useComponentLoading(LOG_IN, false);

  const getUserByToken = (token, redirectTo = '') => {
    dispatch(loadingToggled(true));
    dispatch(redirectURISet(redirectTo ?? ''));

    dispatch(
      apiAction({
        path: { route: API_AUTH_ROUTES.GET_USER_BY_TOKEN },
        method: 'POST',
        successAction: userRefreshed,
        entity: [RESOURCES.PROFILE_PICTURE],
        navigate,
        config: { addAuthHeader: false },
        headers: { Authorization: `Bearer ${token}` },
      }),
    );
  };

  const register = (user, onError) => {
    dispatch(
      apiAction({
        path: {
          route: API_AUTH_ROUTES.REGISTER,
        },
        method: 'POST',
        data: user,
        entity: LOG_IN,
        navigate,
        pushFunction: () => {
          return {
            pathname: LinkCreator.createLink({
              routeKey: 'SIGN_UP_CONFIRMATION',
            }),
            state: { email: user.email },
          };
        },
        onSuccess: () => pushGtmSignUpEvent(user.email),
        onError,
      }),
    );
  };

  const logIn = (user, redirectTo = '/', onError) => {
    dispatch(redirectURISet(redirectTo ?? ''));
    dispatch(
      apiAction({
        path: {
          route: API_AUTH_ROUTES.LOG_IN,
        },
        data: user,
        method: 'POST',
        entity: LOG_IN,
        successAction: userSignedIn,
        navigate,
        pushFunction: (data) => {
          return {
            pathname: LinkCreator.createLink({
              routeKey: data.type === UserType.CREATOR ? 'BRIEF_INDEX' : 'HOME_PAGE',
              userType: data.type,
            }),
          };
        },
        onError,
      }),
    );
  };

  const signOut = () => {
    const signInLink = LinkCreator.createLink({
      routeKey: 'SIGN_IN',
      query: { logout: true },
    });
    dispatch(
      apiAction({
        path: {
          route: API_AUTH_ROUTES.SIGN_OUT,
        },
        method: 'DELETE',
        successAction: userCleared,
        data: { id: null },
        navigate,
        onError: () => {
          navigate(signInLink);
        },
        pushFunction: () => signInLink,
      }),
    );
  };

  const activateUser = (token) => {
    dispatch(
      apiAction({
        path: {
          route: API_AUTH_ROUTES.ACTIVATE_USER,
        },
        params: { token },
        successAction: userRefreshed,
        entity: AUTH_ACTIVATE,
        navigate,
        pushFunction: () => '/',
      }),
    );
  };

  const passwordReset = (data, onError) => {
    dispatch(
      apiAction({
        path: {
          route: API_AUTH_ROUTES.RESET_PASSWORD,
        },
        method: 'POST',
        data,
        params: { token: data.token },
        entity: RESET_PASSWORD,
        navigate,
        pushFunction: () =>
          LinkCreator.createLink({
            routeKey: 'RESET_PASSWORD_CONFIRMATION',
          }),
        successAction: userRefreshed,
        successToast: {
          message: AUTH_TOASTS.PASSWORD_RESET,
        },
        onError,
      }),
    );
  };

  const verifyResetPassword = (token) => {
    dispatch(
      apiAction({
        path: {
          route: API_AUTH_ROUTES.RESET_PASSWORD,
        },
        params: { token },
        navigate,
        pushFunction: () =>
          LinkCreator.createLink({
            routeKey: 'RESET_PASSWORD',
            query: {
              token,
            },
          }),
        entity: RESET_PASSWORD,
      }),
    );
  };

  const requestReset = (user) => {
    user = { ...user, email: user.email.trim() };
    dispatch(
      apiAction({
        path: {
          route: API_AUTH_ROUTES.REQUEST_RESET_PASSWORD,
        },
        method: 'POST',
        data: user,
        navigate,
        pushFunction: () => {
          return {
            pathname: LinkCreator.createLink({
              routeKey: 'FORGOT_PASSWORD_CONFIRMATION',
            }),
            state: { email: user.email },
          };
        },
      }),
    );
  };

  const sendLinkAgain = (email, type) => {
    const path =
      type === 'reset-password'
        ? LinkCreator.createLink({
            routeKey: 'FORGOT_PASSWORD_CONFIRMATION',
          })
        : LinkCreator.createLink({
            routeKey: 'SIGN_UP_CONFIRMATION',
          });
    dispatch(
      apiAction({
        path: {
          route: API_AUTH_ROUTES.RESEND_LINK,
        },
        params: { type, email },
        navigate,
        pushFunction: () => {
          return {
            pathname: path,
            state: { email },
          };
        },
        entity: CONFIRMATION_LINK,
      }),
    );
  };

  const clearConfirmationLinkMessages = () => {
    dispatch(
      setSuccessMessage({
        entity: CONFIRMATION_LINK,
        message: '',
      }),
    );
  };

  const signUpInvitedUser = (token, user, onError) => {
    const linkConfig: LinkUtilityConfiguration = {
      routeKey: 'SIGN_IN',
    };
    if (user.type === UserType.CREATOR) {
      const redirectURI = LinkCreator.createLink({
        userType: UserType.CREATOR,
        routeKey: 'CREATOR_ONBOARDING',
      });
      linkConfig.query = { redirectURI };
    }

    dispatch(
      apiAction({
        path: {
          route: API_AUTH_ROUTES.INVITE_SIGN_UP,
        },
        method: 'POST',
        params: {
          inviteToken: token,
          type: user.type,
        },
        entity: LOG_IN,
        data: user,
        navigate,
        pushFunction: () => LinkCreator.createLink(linkConfig),
        onSuccess: () => pushGtmSignUpEvent(user.email),
        successToast: {
          message: AUTH_TOASTS.SIGN_UP_WITH_INVITE_COMPLETE,
        },
        onError,
      }),
    );
  };

  const googleOauthRedirect = ({ redirectURI, search, token, type = null, email }) => {
    const baseUrl = `${config.API_URL}/${LinkCreator.createApiLink({
      route: API_AUTH_ROUTES.GOOGLE_OAUTH_REDIRECT,
    })}`;

    const queryObj: any = {
      redirectURI: encodeURIComponent(encryptString(redirectURI + search)),
      email,
    };

    // if the user has been invited, add the inviteToken
    if (token) {
      localStorage.setItem(LOCAL_STORAGE.INVITE_TOKEN, token);
      queryObj.inviteToken = token;
    }
    if (type) {
      queryObj.type = type;
    }
    const query = stringifyQuery(queryObj);
    window.location.assign(baseUrl + query);
  };

  interface FBOauthState {
    redirectURI: string;
    inviteToken?: string;
    type?: string;
    email: string;
  }
  const facebookOauthRedirect = ({ redirectURI = '', search = '', token, type = null, email }) => {
    const baseUrl = `${config.API_URL}/${LinkCreator.createApiLink({
      route: API_AUTH_ROUTES.FACEBOOK_OAUTH_REDIRECT,
    })}`;
    const encryptedURI = encryptString(redirectURI + search);

    // decryption happens when users return to frontend (i.e FacebookOauthLogin page)
    const queryObj: FBOauthState = {
      redirectURI: encodeURIComponent(encryptedURI),
      email,
    };

    // if the user has been invited, add the inviteToken
    if (token) {
      localStorage.setItem(LOCAL_STORAGE.INVITE_TOKEN, token);
      queryObj.inviteToken = token;
    }
    if (type) {
      queryObj.type = type;
    }

    const state = JSON.stringify({ ...queryObj });
    // redirect_uri is our backend where we want to return to after user logs in with FB dialog
    // the redirectURI inside of state is where want to return to after our backend endpoint is finished logging the user in
    const url = `https://www.facebook.com/${config.FB_APP_VERSION}/dialog/oauth?client_id=${config.FB_CLIENT_ID}&redirect_uri=${baseUrl}&scope=${config.FB_OAUTH_SCOPES}&state=${state}`;

    window.location.assign(url);
  };

  return {
    activateUser,
    activationErrors,
    confirmationLinkErrors,
    confirmationLinkSuccessMessage,
    clearConfirmationLinkMessages,
    getUserByToken,
    loading,
    logIn,
    passwordReset,
    register,
    requestReset,
    resetPasswordErrors,
    sendLinkAgain,
    signOut,
    signUpInvitedUser,
    verifyResetPassword,
    googleOauthRedirect,
    facebookOauthRedirect,
  };
};

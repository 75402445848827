import { BrkfstUser } from 'shared/typings/user';

export enum RevisionStatus {
  DOING = 0,
  DONE = 1,
}

export const RevisionStatusDisplay = {
  [RevisionStatus.DOING]: 'TO DO',
  [RevisionStatus.DONE]: 'DONE',
};

export interface Revision {
  id: number;
  category: string;
  comment: string;
  status: RevisionStatus;
  submissionModuleId: number;
  dateCreated: string;
  author: Partial<BrkfstUser>;
}

import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export const classes = {
  moduleReview: 'module-review',
  reviewButtons: 'module-review__buttons',
};

export const moduleReviewStyles = ({
  margin = theme.space[4],
  alignItems = 'center',
}: {
  margin: string;
  alignItems: string;
}) => css`
  &.${classes.moduleReview} {
    margin: ${margin};
  }
  .${classes.reviewButtons} {
    display: flex;
    align-items: ${alignItems};
  }
  .${classes.reviewButtons} .action-button {
    padding: 0.2rem ${theme.space[5]};
  }
`;

export const moduleReviewContentStyles = css`
  .lightbox__content {
    &__item {
      justify-content: center;
    }
  }
`;

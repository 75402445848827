import { css } from '@emotion/react';

import space from 'shared/styles/spacing';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

const {
  colors: { PRIMARY100, PRIMARY500, WHITE },
  shadows: { SHADOW04, SHADOW06, SHADOW16 },
} = theme;

export default css({
  flexDirection: 'column',
  alignItems: 'center',
  overflowY: 'auto',
  '.selection-item': {
    backgroundColor: 'white',
    border: '2px solid white',
    boxShadow: SHADOW06,
    borderRadius: '8px',
    maxWidth: '20rem',
    minWidth: '20rem',
    alignItems: 'center',
    userSelect: 'none',
    textAlign: 'left',
    transition: 'all ease-in-out 0.1s',
    marginBottom: space[3],
    padding: space[4],
    [mediaquery.md]: {
      marginBottom: space[5],
      padding: space[6],
      minWidth: '30rem',
      maxWidth: '30rem',
      height: '8rem',
    },
    '&:hover': {
      backgroundColor: PRIMARY100,
      border: `2px solid ${PRIMARY100}`,
      color: 'black',
      boxShadow: SHADOW16,
    },
    '&:active': {
      transform: 'scale(0.98)',
      boxShadow: SHADOW04,
    },
  },
  '.selection-item--selected': {
    backgroundColor: PRIMARY100,
    border: `2px solid ${PRIMARY500}`,
  },
  '.item-content': {
    flexDirection: 'column',
    flex: 1,
  },
  '.icon-container': {
    fontSize: theme.fontSizes[7],
    border: `1px solid ${PRIMARY500}`,
    borderRadius: '4px',
    transition: 'background-color 0.2s ease-in-out',
    marginRight: space[5],
    padding: space[3],
    [mediaquery.md]: {
      padding: space[5],
    },
  },
  '.icon-container--selected': {
    backgroundColor: PRIMARY500,
    color: WHITE,
  },
  '.icon': {
    color: PRIMARY500,
  },
  '.icon--selected': {
    color: WHITE,
  },

  '.selection-button--selected': {
    '&:not(:disabled):hover svg': {
      color: WHITE,
    },
  },

  '.brief-selection': {
    '&__submit-button': {
      [mediaquery.md]: {
        marginTop: '1.25rem',
        top: '90%',
        position: 'absolute',
      },
    },
  },
  '&.brief-selection': {
    height: '90%',
  },
});

/* eslint-disable no-param-reassign */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { loggedOut } from 'features/auth/auth.slice';
import { fileRemoved, filesAdded } from 'features/media/files.slice';

import { noOp } from 'shared/defaults';
import { FileUploadStatus } from 'shared/typings/file/enums';

export type UploadFile = {
  name: string;
  id: string | number;
  status: FileUploadStatus;
  abortController?: AbortController;
  errors?: string[];
  progress?: number;
};

type UiState = {
  filesUploading: UploadFile[];
  initialRefresh: boolean;
  loading: boolean;
  noAccess: boolean;
  newFilesLoading: number;
  showUploadDrawer: boolean;
  filesUploadComplete: boolean;
  hamburgerMenuIsOpen: boolean;
  galleryListView: string;
  toast: { message?: string; variant?: string; heading?: string };
  componentLoading: Record<string, boolean>;
  errors: Record<string, string[] | null>;
  successMessage: Record<string, string>;
  activeChannel: null | any;
  notificationsCount: Record<string, number>;
  activeLightbox: null | any;
  rightDrawer: {
    isOpen: boolean;
    Content: null | any;
    Header: null | any;
    headerText: string;
    onRequestClose: Function;
    key: null | any;
    mountDrawer: boolean;
  };
  accountScoreProgress: {
    progress: number;
    accountPlatformId: number | null;
  };
  showAdHealthSurvey: boolean;
};

const initialState: UiState = {
  initialRefresh: true,
  loading: false,
  noAccess: false,
  toast: {},
  componentLoading: {},
  errors: {},
  successMessage: {},
  activeChannel: null,
  notificationsCount: {},
  newFilesLoading: 0,
  filesUploading: [],
  showUploadDrawer: false,
  filesUploadComplete: false,
  activeLightbox: null,
  rightDrawer: {
    isOpen: false,
    Content: null,
    Header: null,
    headerText: '',
    onRequestClose: noOp,
    key: null,
    mountDrawer: false,
  },
  hamburgerMenuIsOpen: false,
  galleryListView: '',
  accountScoreProgress: {
    progress: 0,
    accountPlatformId: null,
  },
  showAdHealthSurvey: false,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    accessDenied: (ui, action) => {
      ui.noAccess = action.payload;
    },
    newFilesLoadingToggled: (
      ui,
      action: PayloadAction<{ numNewFiles?: number; filesUploading: Array<UploadFile> }>,
    ) => {
      const { numNewFiles, filesUploading } = action.payload;
      ui.newFilesLoading = numNewFiles || ui.newFilesLoading;
      ui.filesUploading = [...filesUploading, ...ui.filesUploading];
      ui.showUploadDrawer = true;
      ui.filesUploadComplete = false;
    },
    updateFileUploadStatus: (ui, action) => {
      const {
        payload: { fileUploadStatus, errors },
      } = action;
      const fileIndex = current(ui.filesUploading).findIndex((file) => {
        return file.id === action.payload.id;
      });
      if (ui.filesUploading?.length > 0 && ui.filesUploading[fileIndex]) {
        if (
          (fileUploadStatus === FileUploadStatus.UPLOAD_CANCELLED ||
            fileUploadStatus === FileUploadStatus.UPLOAD_FAILED) &&
          ui.newFilesLoading > 0
        ) {
          ui.newFilesLoading = ui.newFilesLoading - 1;
        }

        ui.filesUploading[fileIndex].status = fileUploadStatus;

        if (errors) ui.filesUploading[fileIndex].errors = errors;
      }
    },
    updateFileUploadProgress: (ui, action) => {
      const {
        payload: { progress },
      } = action;
      const fileIndex = ui.filesUploading.findIndex((file) => {
        return file.id === action.payload.id;
      });
      if (ui.filesUploading?.length > 0 && ui.filesUploading[fileIndex]) {
        ui.filesUploading[fileIndex].progress = progress;
      }
    },
    newFilesUploadComplete: (ui) => {
      ui.filesUploadComplete = true;
    },
    uploadDrawerClosed: (ui) => {
      ui.showUploadDrawer = false;
    },
    uploadDrawerCleared: (ui) => {
      const filesUploading = ui.filesUploading.filter((fileUpload) => {
        return (
          fileUpload.status === FileUploadStatus.UPLOADING ||
          fileUpload.status === FileUploadStatus.UPLOAD_ATTEMPTING_CANCEL
        );
      });
      ui.filesUploading = filesUploading;
    },
    componentLoadingToggled: (ui, action) => {
      const { component, loading } = action.payload;
      if (Array.isArray(component)) {
        for (let i = 0; i < component.length; i++) {
          const element = component[i];
          ui.componentLoading[element] = loading;
        }
      } else {
        ui.componentLoading[component] = loading;
      }
    },
    errorTriggered: (ui, action) => {
      const { error, entity = 'GENERAL' } = action.payload;
      if (ui.errors[entity]) {
        ui.errors[entity].push(error);
      } else {
        ui.errors[entity] = [error];
      }
    },
    errorCleared: (ui, action) => {
      const { entity = 'GENERAL' } = action.payload;
      ui.errors[entity] = null;
    },
    setSuccessMessage: (ui, action) => {
      const { message, entity = 'GENERAL' } = action.payload;
      ui.successMessage[entity] = message;
    },
    initialRefreshCompleted: (ui) => {
      ui.initialRefresh = false;
    },
    loadingToggled: (ui, action) => {
      ui.loading = action.payload;
    },
    toastPopped: (ui, action) => {
      ui.toast = action.payload;
    },
    channelOpened: (ui, action) => {
      ui.activeChannel = action.payload.data;
    },
    setNotificationCount: (ui, action) => {
      const { entity, count } = action.payload;
      ui.notificationsCount[entity] = count;
    },
    updateActiveLightbox: (ui, action) => {
      ui.activeLightbox = action.payload;
    },
    rightDrawerToggled: (ui, action) => {
      ui.rightDrawer.isOpen = action.payload === undefined ? !ui.rightDrawer.isOpen : action.payload;
    },
    rightDrawerDataSet: (ui, action) => {
      ui.rightDrawer = {
        ...ui.rightDrawer,
        ...action.payload,
      };
    },
    toggleHamburgerMenu: (ui) => {
      ui.hamburgerMenuIsOpen = !ui.hamburgerMenuIsOpen;
    },
    closeHamburgerMenu: (ui) => {
      ui.hamburgerMenuIsOpen = false;
    },
    toggleGalleryListView: (ui, action) => {
      ui.galleryListView = action.payload;
    },
    accountProgressUpdated: (ui, action) => {
      ui.accountScoreProgress.progress = action.payload.progress;
      ui.accountScoreProgress.accountPlatformId = action.payload.accountPlatformId;
      if (action.payload.progress === 100) {
        ui.showAdHealthSurvey = true;
      }
    },
    adScoreProgressReset: (ui) => {
      ui.accountScoreProgress = { ...ui.accountScoreProgress, progress: 0 };
      ui.showAdHealthSurvey = false;
    },
    adHealthSurveyClosed: (ui) => {
      ui.showAdHealthSurvey = false;
    },
    adHealthSurveyOpened: (ui) => {
      ui.showAdHealthSurvey = true;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(loggedOut, () => initialState)
      .addCase(filesAdded, (ui, action) => {
        const { data } = action.payload;
        const files = Array.isArray(data) ? data : [data];
        ui.newFilesLoading = ui?.newFilesLoading > files.length ? ui.newFilesLoading - files.length : 0;
      })
      .addCase(fileRemoved, (ui, action) => {
        const { name } = action.payload.data;
        return {
          ...ui,
          filesUploading: ui.filesUploading.filter((file) => file.name !== name || file.progress === 100),
        };
      }),
});

export const {
  accessDenied,
  channelOpened,
  closeHamburgerMenu,
  componentLoadingToggled,
  errorCleared,
  errorTriggered,
  initialRefreshCompleted,
  loadingToggled,
  newFilesLoadingToggled,
  newFilesUploadComplete,
  uploadDrawerClosed,
  rightDrawerDataSet,
  rightDrawerToggled,
  updateFileUploadStatus,
  uploadDrawerCleared,
  updateFileUploadProgress,
  setNotificationCount,
  setSuccessMessage,
  toastPopped,
  toggleHamburgerMenu,
  updateActiveLightbox,
  toggleGalleryListView,
  accountProgressUpdated,
  adScoreProgressReset,
  adHealthSurveyClosed,
  adHealthSurveyOpened,
} = uiSlice.actions;

export const loading = (state) => state.ui.loading;
export const toast = (state) => state.ui.toast;
export const initialRefresh = (state) => state.ui.initialRefresh;
export const getComponentLoading =
  (entity, startingState = true) =>
  (state) => {
    const loadingState = state.ui.componentLoading[entity];
    if (loadingState === undefined) return startingState;
    return loadingState;
  };
export const getLoadingNewFiles = (state) => state.ui.newFilesLoading;

export const getMultipleLoading = (entities) => (state) => {
  return entities
    .map((entity) => {
      const loadingState = state.ui.componentLoading[entity];
      if (loadingState === undefined) return true;
      return loadingState;
    })
    .some((a) => a);
};

export const getActiveChannel = (state) => state.ui.activeChannel;

export const getNotifications = (state) => state.ui.notificationsCount;

export const getErrors =
  (entity = 'GENERAL') =>
  (state) =>
    state.ui.errors[entity] || [];

export const getSuccessMessage =
  (entity = 'GENERAL') =>
  (state) =>
    state.ui.successMessage[entity];

export const getActiveLightbox = (state) => {
  return state.ui.activeLightbox;
};

export const getRightDrawerData = (state) => {
  return state.ui.rightDrawer;
};

export const hamburgerMenuIsOpen = (state) => state.ui.hamburgerMenuIsOpen;

export const getFilesUploading = (state) => state.ui.filesUploading as UploadFile[];

export const getShowUploadDrawer = (state) => state.ui.showUploadDrawer;

export const getFilesUploadComplete = (state) => state.ui.filesUploadComplete;

export const getGalleryListView = (state) => state.ui.galleryListView;

export const getAccountScoreProgress =
  (accountPlatformId: number) =>
  (state): number =>
    state.ui.accountScoreProgress.accountPlatformId === accountPlatformId || Number.isNaN(accountPlatformId)
      ? state.ui.accountScoreProgress.progress
      : 0;

export const getShowAdHealthSurvey = (state) => state.ui.showAdHealthSurvey;

import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import cs from 'classnames';

import { CreativeSafeZone } from 'shared/components/atoms/CreativeSafeZone';
import { Box, Flex } from 'shared/components/display';

import { SafeZoneButtons } from 'shared/components/molecules/SafeZoneButtons';
import VideoPlayer from 'shared/components/molecules/VideoPlayer/VideoPlayer';

import { MEDIA } from 'shared/config/media';
import { is9by16AspectRatio } from 'shared/utilities/validator/utils';
import { CarouselItemProps } from './types';
import { carouselItemStyles } from './styles';

/* If an onClick is passed to CarouselItem the control of bubbling and propagation of events is handled by the passed 
onClick event otherwise if no function is passed the default behavior of the VideoPlayer is to prevent any propagation 
of events */

const CarouselItem: React.FC<CarouselItemProps> = ({
  thumbnailUrl,
  name,
  mimeType,
  url = '',
  metadata = {},
  extension,
  showThumbnail = false,
  Footer,
  className,
  setMediaWidth,
  onClick,
  Header,
  isSelected = false,
  useIframe = false,
}) => {
  const [showFbSafezone, setShowFbSafezone] = useState(false);
  const [showTtSafezone, setShowTtSafezone] = useState(false);
  const [is9By16, setIs9By16] = useState(false);
  const isImage = mimeType.includes(MEDIA.TYPES.IMAGE);
  const source = showThumbnail ? thumbnailUrl : url;
  const carouselRef = useRef<HTMLDivElement>(null);

  //Only use the observer if the parent wants to know the media width
  const observer =
    setMediaWidth !== undefined
      ? new ResizeObserver((entries) => {
          setMediaWidth(entries[0].contentRect.width);
        })
      : null;

  useEffect(() => {
    if (observer) {
      if (carouselRef.current) {
        observer.observe(carouselRef.current);
      }
      return () => {
        if (carouselRef.current) {
          observer.unobserve(carouselRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    setIs9By16(is9by16AspectRatio(metadata));
  }, [metadata]);

  const onFbClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setShowFbSafezone((prev) => !prev);
  };

  const onTtClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setShowTtSafezone((prev) => !prev);
  };

  return (
    <Box css={carouselItemStyles} className={cs('carousel-item', className)}>
      {Header && Header}
      {useIframe ? (
        // @TODO BRKFST-178 update styles
        <iframe className="carousel-item__iframe" title={name} src={url} allowFullScreen />
      ) : (
        <Flex className="carousel-item__container" ref={carouselRef} onClick={onClick}>
          {showThumbnail || isImage ? (
            <div className="carousel-item__image-container">
              <img alt={name} src={source} title={name} className="carousel-item__img" />
              {is9By16 && <CreativeSafeZone showFbSafezone={showFbSafezone} showTtSafezone={showTtSafezone} />}
              {is9By16 && (
                <SafeZoneButtons
                  showFbSafezone={showFbSafezone}
                  showTtSafezone={showTtSafezone}
                  onFbClick={onFbClick}
                  onTtClick={onTtClick}
                />
              )}
            </div>
          ) : (
            <VideoPlayer
              url={url}
              mimeType={mimeType}
              metadata={metadata}
              extension={extension}
              className="carousel-item__video-wrapper"
              stopPropagation={onClick == undefined ? true : false}
              thumbnailUrl={thumbnailUrl}
              isActive={isSelected}
              showFbSafezone={showFbSafezone}
              showTtSafezone={showTtSafezone}
              onFbClick={onFbClick}
              onTtClick={onTtClick}
              is9By16={is9By16}
            />
          )}
        </Flex>
      )}
      {Footer && Footer}
    </Box>
  );
};

export default CarouselItem;

import * as yup from 'yup';

import { MESSAGES } from 'shared/config/validations';
import { intl } from 'shared/lib/intl';
import { maxDurationLimit, minDurationLimit } from 'shared/lib/validationTests';
import { dimensionLimit, fileNameLength } from 'shared/lib/validationTests';
import { hasValidAspectRatio } from 'shared/utilities/validator/utils';

import { SubmissionAssetFields, SubmissionFormFields, SubmissionModuleFields } from './types';

// Min/Max assets per module
const MAX_ASSETS = 2;
const MIN_ASSETS = 1;

export const submissionSchema = yup.object({
  [SubmissionFormFields.DESCRIPTION]: yup.array(),
  [SubmissionFormFields.MODULE_ASSETS]: yup.array(
    yup.object({
      [SubmissionModuleFields.FILES]: yup.array(
        yup.object({
          [SubmissionAssetFields.METADATA]: yup
            .mixed()
            .default({})
            .test({
              name: 'dimension-limit',
              test: dimensionLimit,
            })
            .test({
              name: 'aspect-ratio-limit',
              message: MESSAGES.ASPECT_RATIO,
              test: hasValidAspectRatio,
            })
            .test({
              name: 'video-duration-min-limit',
              test: minDurationLimit,
            })
            .test({
              name: 'video-duration-max-limit',
              test: maxDurationLimit,
            }),
          [SubmissionAssetFields.NAME]: yup.string().required('Asset name is a required field.').filename().test({
            name: 'file-name-length',
            message: MESSAGES.FILE_NAME_LENGTH,
            test: fileNameLength,
          }),
        }),
      ),
      [SubmissionModuleFields.MODULE_ASSET_COUNT]: yup
        .number()
        .required()
        .min(MIN_ASSETS, intl.formatMessage({ id: 'SUBMISSION_FORM_ASSET_MIN' }, { count: MIN_ASSETS }))
        .max(MAX_ASSETS, intl.formatMessage({ id: 'SUBMISSION_FORM_ASSET_MAX' }, { count: MAX_ASSETS })),
    }),
  ),
});

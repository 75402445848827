import React from 'react';
import cs from 'classnames';
import { Flex, Text } from 'shared/components/display';
import styles from './styles';
import Checkbox from 'shared/components/atoms/Checkbox';
import { useQuery } from 'shared/hooks/useQuery';
import { BRIEF_COPY } from 'shared/config/copy';

interface Props {
  className?: string;
}
const BrandApprovedCheckbox: React.FC<Props> = ({ className }) => {
  const { getQuery, setQuery } = useQuery();
  const { brandApprovedBadge } = getQuery<{ brandApprovedBadge?: boolean }>();
  const onChange = () => {
    setQuery({ brandApprovedBadge: !brandApprovedBadge });
  };

  return (
    <Flex css={styles} className={cs('brand-approved-checkbox', className)}>
      <Checkbox
        name="brandApprovedBadge"
        className="brand-approved-checkbox__input"
        checked={brandApprovedBadge}
        onChange={onChange}
      />
      <Text as="label" htmlFor="brandApprovedBadge" className="brand-approved-checkbox__label">
        {BRIEF_COPY.LABEL_BRAND_BADGE}
      </Text>
    </Flex>
  );
};

export default BrandApprovedCheckbox;

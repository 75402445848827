// * Lib
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import cs from 'classnames';

//* Display/UI
import { Box } from 'shared/components/display';

import { CREATOR_COPY } from 'shared/config/copy';
import { breakpoints } from 'shared/styles/styleFunctions';
// * Util
import { CreativePayout } from 'shared/typings/creatorPerformance';
import { formatCurrency } from 'shared/utilities/stringUtility';

// * Styles
import styles from './styles';

interface Props {
  data: Array<CreativePayout>;
  updateBriefIndex: (index: number) => void;
}
const CreativePerformanceTable: React.FC<Props> = ({ data, updateBriefIndex }) => {
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  const renderFileColBody = (creative) =>
    isMobile ? (
      <>
        <td className="creative-performance__head-col">
          <img src={creative.thumbnailUrl} alt="" className="creative-performance__creative-thumbnail" />
        </td>
        <td className="creative-performance__table-cell">
          <span>{creative.fileName}</span>
        </td>
      </>
    ) : (
      <td className="creative-performance__head-col">
        <img src={creative.thumbnailUrl} alt="" className="creative-performance__creative-thumbnail" />
        {creative.fileName}
      </td>
    );

  const renderTableData = () =>
    data?.length === 0 ? (
      <tr className="creative-performance__table-row">
        <td className="creative-performance__table-cell--no-results" colSpan={5}>
          <p className="creative-performance__no-results-text">{CREATOR_COPY.PLACEHOLDER_CREATOR_PERFORMANCE}</p>
        </td>
      </tr>
    ) : (
      data.map((creative: CreativePayout) => (
        <tr key={creative.id} className="creative-performance__table-row">
          {renderFileColBody(creative)}

          <td className="creative-performance__table-cell">
            <span>{formatCurrency(+creative.totalAmount / 100)}</span>
          </td>

          <td className="creative-performance__table-cell">
            <span>{creative.accountName}</span>
          </td>

          <td className="creative-performance__table-cell">
            <span>
              {creative.briefNames.map((name) => (
                <BriefName briefName={name} key={name} />
              ))}
            </span>
          </td>
        </tr>
      ))
    );

  return (
    <Box css={styles} className="creative-performance">
      <table
        className={cs({
          'creative-performance__table': true,
          'creative-performance__table--no-results': data?.length === 0,
        })}
      >
        <thead>
          <tr className="creative-performance__table-row">
            <th scope="col" className="creative-performance__head-col-header">
              {CREATOR_COPY.COLUMN_CREATIVE_FILE}
            </th>
            {isMobile ? <th scope="col" className="creative-performance__table-header"></th> : <></>}
            <th scope="col" className="creative-performance__table-header">
              {CREATOR_COPY.COLUMN_CREATIVE_PAYOUT}
            </th>
            <th scope="col" className="creative-performance__table-header">
              {CREATOR_COPY.COLUMN_CREATIVE_BRAND}
            </th>
            <th scope="col" className="creative-performance__table-header">
              {CREATOR_COPY.COLUMN_CREATIVE_BRIEF}
            </th>
          </tr>
        </thead>

        <tbody className="creative-performance__table-body">{renderTableData()}</tbody>
      </table>
    </Box>
  );
};

const BriefName = ({ briefName }: { briefName: string }) => <span>{briefName}</span>;

export default CreativePerformanceTable;

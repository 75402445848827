// ========================================================================================
/*                                                                                      *
 * ATOM: TEXT                                                                            *
 *                                                                                      */
// ========================================================================================
// * Lib
// * Styles
import styled from '@emotion/styled';
import theme from 'shared/styles/themes';
import React, { ReactNode } from 'react';
import { variant } from 'styled-system';
import { BUTTON_VARIANTS } from 'shared/styles/button';
// * Display/UI
import Base from './Base.display';

interface Props extends React.HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  className?: string;
  variant?: any;
  id?: string;
  css?: any;
  type?: string;
  disabled?: boolean;
  dataCy?: string;
}

const StyledButton = styled(Base)(
  variant({
    variants: theme.buttons,
  }),
);

const Text = React.forwardRef<any, Props>(({ variant = BUTTON_VARIANTS.PRIMARY, dataCy, ...props }, ref) => (
  <StyledButton
    as="button"
    data-display="Button"
    variant={variant}
    {...props}
    {...(dataCy ? { ['data-cy']: dataCy } : {})}
    ref={ref}
  />
));

export default Text;

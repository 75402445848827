import React from 'react';
import { useMediaQuery } from 'react-responsive';
import cs from 'classnames';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import ToggleSwitch from 'shared/components/atoms/ToggleSwitch';
import { Box, Text } from 'shared/components/display';
import Divider from 'shared/components/molecules/Divider';

import { ACCOUNT_COPY } from 'shared/config/copy';
import { CLASSNAMES } from 'shared/styles/containers';
import { breakpoints } from 'shared/styles/styleFunctions';
import { TEXT_VARIANTS } from 'shared/styles/text';

interface Props {
  accountId: string;
}
const SuperadminAccountSettings: React.FC<Props> = ({ accountId }) => {
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });
  const { toggleModuleComments, toggleEmailNotifications, toggleCreditCardCharges, toggleAccountHealth, account } =
    useAccounts({
      accountId,
    });

  const onToggleModuleComments = () => {
    toggleModuleComments(accountId, !account.enableModuleComments);
  };

  const onToggleEmailNotifications = () => {
    toggleEmailNotifications(accountId, !account.enableEmailNotifications);
  };

  const onToggleCreditCardCharges = () => {
    toggleCreditCardCharges(accountId, !account.enableCreditCardCharges);
  };

  const onToggleAccountHealth = () => {
    toggleAccountHealth(accountId, !account.enableAccountHealth);
  };

  return (
    <Box className={cs('superadmin-account-settings', CLASSNAMES.CENTERED_CONTAINER)}>
      <Text variant={TEXT_VARIANTS.H4} as="h4">
        {ACCOUNT_COPY.HEADING_SUPERADMIN_SETTINGS}
      </Text>
      <Divider heading="Feature Toggles" variant={TEXT_VARIANTS.H6} />
      <ToggleSwitch
        label="Module Comments"
        toggledOn={account.enableModuleComments}
        fieldName="module-comments"
        onClick={onToggleModuleComments}
        width={isMobile ? '100%' : '200px'}
        rightAlign
      />
      <ToggleSwitch
        label="Email Notifications"
        toggledOn={account.enableEmailNotifications}
        onClick={onToggleEmailNotifications}
        width={isMobile ? '100%' : '200px'}
        rightAlign
      />
      <ToggleSwitch
        label="Credit Card Charges"
        toggledOn={account.enableCreditCardCharges}
        onClick={onToggleCreditCardCharges}
        width={isMobile ? '100%' : '200px'}
        rightAlign
      />
      <ToggleSwitch
        label="Ad Health Score"
        toggledOn={account.enableAccountHealth}
        onClick={onToggleAccountHealth}
        width={isMobile ? '100%' : '200px'}
        rightAlign
      />
    </Box>
  );
};

export default SuperadminAccountSettings;

import React, { useMemo, useRef } from 'react';
import cs from 'classnames';
import { isEqual, pick } from 'lodash';
import { useFormikContext } from 'formik';
import { Box, Button, Flex, Text } from 'shared/components/display';
import Popover from 'shared/components/atoms/Popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { BUTTON_VARIANTS } from 'shared/styles/button';

import { dropdownFieldsStyles } from './styles';

export interface DropdownFieldProps {
  children: (React.ReactElement | false)[] | React.ReactElement;
  filterName: string;
  hideClearButton?: boolean;
  fieldNames: string[];
  maxHeight?: string;
  LeftFooter?: React.ReactElement;
  width?: string;
  dropdownWidth?: string;
}

const offset = { mainAxis: 5, crossAxis: 0 };

const DropdownField = <Values extends {}>({
  filterName,
  children,
  hideClearButton = false,
  fieldNames,
  maxHeight,
  LeftFooter = <span />,
  width = '200px',
  dropdownWidth = '200px',
}: DropdownFieldProps) => {
  const formikProps = useFormikContext<Values>();
  // save initialValues because formik updates them when resetForm is called
  const initialValues = useRef(pick(formikProps.initialValues, fieldNames));
  const modalId = `filter-modal-${filterName}`;

  const focusOnFirstElement = (parentElementSelector: string) => () => {
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const parentElement = document.querySelector(parentElementSelector);

    if (parentElement) {
      const focusableContent = parentElement.querySelectorAll(focusableElements);

      const firstElement = focusableContent[0] as HTMLElement;

      firstElement.focus();
    }
  };

  const onOpenChange = (open: boolean) => {
    // submit the form when the dropdown is closed
    if (!open) {
      formikProps.submitForm();
    } else {
      // focus the first input when the dropdown is opened so that inputs in the dropdown are in the correct tab order
      // do it with timeout to give modal time to mount
      setTimeout(focusOnFirstElement('#' + modalId), 100);
    }
  };

  // for memoizing the values
  const values = useMemo(() => {
    return JSON.stringify(pick(formikProps.values, fieldNames));
  }, [formikProps.values]);

  const { active } = useMemo(() => {
    return {
      // active if any fields are not the initial value
      active: fieldNames.some((fieldName) => {
        return !isEqual(formikProps.values[fieldName], initialValues.current[fieldName]);
      }),
    };
  }, [initialValues, values, fieldNames]);

  return (
    <Popover
      allRounded
      offset={offset}
      onOpen={onOpenChange}
      maxHeight={maxHeight}
      placement="bottom-start"
      ClickComponent={
        <Button
          css={dropdownFieldsStyles(width)}
          type="button"
          variant={BUTTON_VARIANTS.TEXT_ONLY}
          data-cy={`dropdown-field-${filterName}`}
          className={cs('dropdown-field__click-component', {
            'dropdown-field__click-component--active': active,
          })}
        >
          <Text className="dropdown-field__filter-name">{filterName}</Text>
          <FontAwesomeIcon className="dropdown-field__click-icon" icon={faCaretDown} size="xs" />
        </Button>
      }
    >
      <Box css={dropdownFieldsStyles(dropdownWidth)} id={modalId} className="dropdown-field">
        {children}
        {!hideClearButton && (
          <Flex className="dropdown-field__btn-wrapper">
            {LeftFooter}
            <Button
              onClick={() => {
                formikProps.resetForm({ values: { ...formikProps.values, ...initialValues.current } });
              }}
              variant={BUTTON_VARIANTS.TEXT_ONLY}
              data-cy={`dropdown-clear-btn-${filterName}`}
              type="button"
              className="dropdown-field__clear-btn"
            >
              Clear
            </Button>
          </Flex>
        )}
      </Box>
    </Popover>
  );
};

export default DropdownField;

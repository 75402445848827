import React from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import styles from './styles';
import { Button } from 'rebass/styled-components';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faX } from '@fortawesome/pro-light-svg-icons';

interface Props {
  onClick: () => void;
  className?: string;
  editing?: boolean;
  variant?: string;
  display?: 'vertical' | 'horizontal';
}
const EditButton: React.FC<Props> = ({
  className,
  onClick,
  editing,
  variant = BUTTON_VARIANTS.TEXT_ONLY,
  display = 'vertical',
}) => {
  return (
    <Box as="span" css={styles} className={cs('edit-button', className, `edit-button--${display}`)}>
      <Button variant={variant} onClick={onClick} className="edit-button__btn">
        <FontAwesomeIcon icon={editing ? faX : faPencil} className="edit-button__btn-icon" />
        {editing ? 'Cancel' : 'Edit'}
      </Button>
    </Box>
  );
};

export default EditButton;

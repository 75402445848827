const defaultPages = [1, 2, 3];

export const getVisiblePages = (pages: number, page: number) => {
  if (pages === 0) return [1];
  if (pages < 4) {
    // if less than 4 pages filter out pages less than amount
    return defaultPages.filter((v) => v <= pages);
  }
  if (page > 1 && page < pages - 1) {
    // if on any page greater than the second output and less than the last page, the previous and next page.
    return [page, page + 1, page + 2];
  }
  if (page === pages - 1) {
    // if on the last page, show it as the last visible page
    return [pages - 2, pages - 1, pages];
  }
  return defaultPages;
};

export const getSafePage = (newPage: number, page: number, pages: number) => {
  if (Number.isNaN(newPage)) {
    return Math.min(page, pages - 1);
  }
  return Math.min(newPage, pages - 1);
};

import React from 'react';
import { Text } from 'rebass/styled-components';

import SubmissionDetails from 'features/submissions/components/molecules/SubmissionDetails/SubmissionDetails';
import styles from 'features/submissions/components/SubmissionView/styles';
import { ProfilePicture } from 'features/users/components/molecules/ProfilePicture';
import { Flex } from 'shared/components/display';
import Box from 'shared/components/display/Box.display';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { Submission } from 'shared/typings/submissions';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { Link } from 'react-router-dom';

interface Props {
  submission: Submission;
}

const SubmissionView: React.FC<Props> = ({ submission }) => {
  const { isCreator } = useCurrentUser();

  const profileLink = LinkCreator.createLink({
    userType: UserType.ORG,
    routeKey: 'USER_PROFILE',
    variables: {
      userId: submission.creator.id,
    },
  });

  return (
    <Box css={styles} className="submission-view">
      {!isCreator && (
        <Flex className="submission-view__creator-info">
          <ProfilePicture customProfileLink={profileLink} openNewTab user={submission.creator} size="xs" />
          <Link target={'_blank'} to={profileLink} data-cy="submission-view__creator-name">
            <Text className="submission-view__creator-name">{submission.creator.fullName}</Text>
          </Link>
        </Flex>
      )}
      <SubmissionDetails submission={submission} />
    </Box>
  );
};

export default SubmissionView;

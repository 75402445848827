import { SearchResultItemProps } from "stream-chat-react";
import React from 'react';
import { Channel } from "stream-chat";
import { StreamChatTypes } from "shared/messaging/types";
import { getUserImage, parseChannelDisplayTitle } from "features/messages/util";
import { useCurrentUser } from "shared/hooks/useCurrentUser";
import ChannelListItem from "./ChannelListItem";

const SearchCustomResultItem: React.FC<SearchResultItemProps<StreamChatTypes>> = (props) => {
    const { result, selectResult } = props;

    // Even though result can be either a Channel or UserResponse we only query the results in MessageCenter as a Channel
    // if the query result is not for channels return null 
    const channel = result as Channel<StreamChatTypes>;
    if(channel.cid === null){
        return null;
    }
    const { isCreator } = useCurrentUser();
    const displayImage = getUserImage(channel, isCreator);

    let displayTitle = '';
    if(channel.data && channel.data.name){
        displayTitle = channel.data.name;
        if(isCreator){
            displayTitle = parseChannelDisplayTitle(displayTitle);
        }
    }

    const previewRef =  React.createRef<HTMLButtonElement>();
    return (
        <ChannelListItem 
            channel={channel} 
            isCreator={isCreator}
            displayImage={displayImage}
            displayTitle={displayTitle}
            onClick={() => selectResult(result)}
            previewRef={previewRef}
        />
    );
}

  export default SearchCustomResultItem;
export const REPORT_COPY = {
  // Buttons
  BUTTON_SCHEDULE: 'Schedule',

  BUTTON_CREATE_REPORT: `${String.fromCharCode(
    65291, // This is the html entity of a '+' sign
  )}  Create New Report`,

  // Placeholder
  PLACEHOLDER_SELECT: 'Select Report',

  // Tooltips
  TOOLTIP_ACCORDION_DESCRIPTION: 'Used to select and create reports on the Account Platform resource',

  // Labels
  LABEL_ACCORDION: 'Reports',

  MODAL_DELETE_REPORT: 'Are you sure you want to delete this report?',

  // Warnings
  WARNING_DELAY: `Reports may take longer than expected depending on the
    amount of data you're requesting.`,

  // form
  FORM_HEADER_NEW_REPORT: 'New Report',
  FORM_HEADER_UPDATE_REPORT: 'Update Report',
};

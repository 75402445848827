import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import { createColumnHelper } from '@tanstack/react-table';
import { sortBy } from 'lodash';

import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { ACCOUNT_SNAPSHOT, ORG_LAYOUTS } from 'features/organizations/components/organisms/OrgWidgets/config';
import { useOrganizations } from 'features/organizations/useOrganizations';
import Skeleton from 'shared/components/atoms/Skeleton';
import WidgetWrapper from 'shared/components/atoms/WidgetWrapper';
import { Box, Text } from 'shared/components/display';
import WidgetPanel from 'shared/components/molecules/WidgetPanel';
import BrkfstTable from 'shared/components/organisms/BrkfstTable';

import { ACCOUNT_COPY } from 'shared/config/copy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';

import { usePermissions } from 'shared/hooks/usePermissions';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { formatCurrency } from 'shared/utilities/stringUtility';

import styles from './styles';

const columnHelper = createColumnHelper<any>();
const buttonClassname = "widget-button";

const OrgWidgets = ({ organizationId }) => {
  const navigate = useNavigate();
  const { organization } = useOrganizations(organizationId);
  const { accountsWithSnippets, multipleSnippetsLoading } = useAccounts();
  const { displayComponent } = usePermissions();

  const sortedAccounts = useMemo(() => {
    return sortBy(accountsWithSnippets, 'name');
  }, [accountsWithSnippets]);

  const otherColumns = [
    columnHelper.accessor('name', {
      header: ACCOUNT_COPY.COLUMN_ACCOUNT_NAME,
      cell: (info) => info.renderValue(),
      meta: {
        addTooltip: true,
      },
    }),
    columnHelper.accessor('metricTotal', {
      header: ACCOUNT_COPY.COLUMN_SEVEN_DAY_SPEND,
      cell: (info) => {
        const metricTotal = info.getValue();
        return multipleSnippetsLoading ? <Skeleton width="50px" /> : formatCurrency(metricTotal) || 'None';
      },
      meta: {
        width: '170px',
      },
    }),
  ];

  const onClick = (item) => {
    const { id } = item;
    const link = LinkCreator.createLink({
      routeKey: 'ACCOUNT',
      variables: {
        organizationId,
        accountId: id,
      },
    });

    navigate(link);
  };
  return (
    <Box css={styles} className="org-widgets">
      <Text className="org-widgets__name" variant={TEXT_VARIANTS.H2}>
        {organization.name}
      </Text>
      <WidgetWrapper 
        initialLayouts={ORG_LAYOUTS} 
        entity="ORGANIZATIONS" 
        entityId={organizationId} 
      >
        <WidgetPanel  
          title="Accounts"
          key={ACCOUNT_SNAPSHOT}
          HeaderButton={displayComponent(
            <Button
              className={buttonClassname}
              variant={BUTTON_VARIANTS.MICRO}
              onClick={() =>
                navigate(
                  LinkCreator.createLink({
                    routeKey: 'CREATE_ACCOUNT',
                    variables: {
                      organizationId,
                    },
                  }),
                )
              }
            >
              {ACCOUNT_COPY.BUTTON_CREATE_ACCOUNT}
            </Button>,
            organizationId,
            PERMISSIONS_MAP.ADD_ACCOUNT.API,
          )}
        >
          <BrkfstTable data={sortedAccounts} onRowClick={onClick} columns={otherColumns} />
        </WidgetPanel>
      </WidgetWrapper>
    </Box>
  );
};

export default OrgWidgets;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { zIndex } from 'shared/config';

export default css({
  '.collection-preview': {
    '&__link:hover': {
      color: COLORS.BLACK,
    },
    '&__overlay': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      textAlign: 'center',
      cursor: 'pointer',
      borderRadius: '10px',
      display: 'none',
      /* last two digits are the opacity */
      backgroundColor: `${COLORS.PRIMARY300}60`,
      zIndex: zIndex.zOverlay,
    },
    '&__overlay > *': {
      backgroundColor: COLORS.PRIMARY100,
      filter: 'opacity(0.8)',
      padding: '0.5rem',
      boxShadow: COLORS.SHADOW04,
      width: 'max-content',
      margin: '0 auto',
    },
    '&__gallery-wrapper': {
      position: 'relative',
      padding: '1rem',
      borderRadius: '10px',
      cursor: 'pointer',
    },
    '&__gallery-wrapper:hover': {
      backgroundColor: COLORS.PRIMARY100,
    },
    '&__gallery-wrapper:hover > .collection-preview__overlay': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
});

import React, { useMemo } from 'react';
import { NON_NUMERIC_COLUMNS } from 'shared/config/performance';
import { formatStringPercent, humanize } from 'shared/utilities/stringUtility';
import styled from 'styled-components';
import DashboardColumn from 'shared/models/DashboardColumnModel';
import { formatSubComponentValue } from 'shared/utilities/Performance/dashboardUtility';
import COLORS from 'shared/styles/colors';
import { STYLES } from 'shared/styles/styles';
import { PerformanceLevels } from 'features/performance/enums';
import { PerformanceDateRange } from 'shared/contexts/performance/types';

const { GREEN300, RED200 } = COLORS;
const { BORDER_NEUTRAL, UNSET } = STYLES;
const { FIXED_FIELDS, CHANGE_FIELDS, CHANGE_VALUE, CHANGE_PERCENT } = DashboardColumn;

const DEFAULT_COLUMN_WIDTH = 100;
const DEFAULT_NAME_COLUMN_WIDTH = 150;

interface ResizedColumn {
  id: string;
  value: string;
}

interface Props {
  level: PerformanceLevels;
  dateRanges: PerformanceDateRange;
  columns: string[];
  resizedColumns: ResizedColumn[];
  original: {
    breakdowns: any;
  };
}

const BreakdownSubComponent: React.FC<Props> = ({
  level,
  dateRanges,
  columns = [],
  resizedColumns = [],
  original: { breakdowns: breakdownMetrics = {} },
}) => {
  // TODO: Prevent name columns from being removed in column selector for the level selected.
  // e.g: Leve; => Campaign => Prevent removing campaign_name column.
  const fixedLastColumnName = useMemo(
    () => [...columns].reverse().find((column) => FIXED_FIELDS.includes(column)),
    [columns],
  ); // Reverse the array and find the first field that's in the FIXED_FIELDS constant.

  const comparisonColumns = useMemo(
    () => dateRanges && dateRanges.map(({ startDate, endDate }) => `${startDate},${endDate}`).concat(CHANGE_FIELDS),
    [dateRanges],
  );

  /**
   * React-table resized data structure transformation into object literal(map):
   * @example
   * [{ id: 'Spend', value: 203.49 }]
   * // becomes
   * { 'Spend': 203.49 }
   */
  const resizedMap = useMemo(
    () =>
      resizedColumns.length
        ? resizedColumns.reduce((map, { id, value }) => {
            map[id] = value;
            return map;
          }, {})
        : {},
    [resizedColumns],
  );

  return (
    <>
      {Object.keys(breakdownMetrics).map((breakdown) => (
        <BreakdownRow
          level={level}
          resizedMap={resizedMap}
          key={breakdown}
          metrics={breakdownMetrics[breakdown]}
          breakdown={breakdown}
          columns={columns}
          fixedLastColumnName={fixedLastColumnName}
          comparisonColumns={comparisonColumns}
        />
      ))}
    </>
  );
};

const BreakdownRow = ({ level, metrics, fixedLastColumnName, columns, breakdown, comparisonColumns, resizedMap }) => {
  return (
    <Row className="rt-tr">
      {columns.map((column) => {
        const isNonNumeric = NON_NUMERIC_COLUMNS.includes(column);
        const isFixed = FIXED_FIELDS.includes(column);
        const isFixedLast = column === fixedLastColumnName;
        const isNameCell = column.includes('name');

        let width = DEFAULT_COLUMN_WIDTH;
        let maxWidth;
        if (isNameCell) {
          width = DEFAULT_NAME_COLUMN_WIDTH;
        }
        if (column in resizedMap) {
          width = resizedMap[column];
          maxWidth = width;
        }
        const align = isNonNumeric ? 'leftAlign' : 'rightAlign';
        const fixed = isFixed && 'rthfc-td-fixed rthfc-td-fixed-left';

        const displayValue = isFixedLast ? humanize(breakdown) : '';
        const metric = metrics[column];

        if (comparisonColumns && !isNonNumeric) {
          return (
            <BreakdownComparisonCellGroup
              resizedMap={resizedMap}
              key={`Label-List${column}`}
              metric={metric}
              comparisonColumns={comparisonColumns}
              column={column}
              className={`${align} ${fixed}`}
            />
          );
        }
        return (
          <Cell
            // TODO: Fix Types
            // @ts-ignore
            column={column}
            cellWidth={width}
            maxCellWidth={maxWidth}
            isFixedLast={isFixedLast && level !== PerformanceLevels.ACCOUNT}
            className={`rt-td ${align} ${fixed} ${isFixedLast && 'rthfc-td-fixed-left-last'}`}
            key={`Label-List${column}`}
          >
            {isFixed ? displayValue : formatSubComponentValue(column, metric)}
          </Cell>
        );
      })}
    </Row>
  );
};

const BreakdownComparisonCellGroup = ({ resizedMap, metric, comparisonColumns, column, className }) => {
  return comparisonColumns.map((field) => {
    const key = `${column}[${field}]`;
    const width = resizedMap[key] ? resizedMap[key] : DEFAULT_COLUMN_WIDTH;

    let value;
    let color;
    if (metric) {
      const comparisonMetric = metric[field] || 0;
      const isChangeField = CHANGE_FIELDS.includes(field);
      if (isChangeField) {
        color = +metric[CHANGE_VALUE] > 0 ? GREEN300 : RED200;
      }
      if (field === 'changePercent') {
        value = formatStringPercent(comparisonMetric);
      } else {
        value = formatSubComponentValue(column, comparisonMetric);
      }
    }
    return (
      <Cell
        // TODO: Fix Types
        // @ts-ignore
        column={field}
        cellWidth={width}
        maxCellWidth={width}
        className={`rt-td ${className}`}
        key={`Label-List${column}${field}`}
        color={color}
      >
        {value}
      </Cell>
    );
  });
};

const Cell = styled.div`
    flex: ${(
      props, // TODO: Fix Types
    ) =>
      // @ts-ignore
      props.cellWidth} 0 auto !important;
    width: ${(
      props, // TODO: Fix Types
    ) =>
      // @ts-ignore
      props.cellWidth}px !important;
    max-width: ${(
      props, // TODO: Fix Types
    ) =>
      // @ts-ignore
      props.maxCellWidth ? `${props.maxCellWidth}px !important` : UNSET}
    left: ${(
      props, // TODO: Fix Types
    ) =>
      // @ts-ignore
      props.isFixedLast && `${DEFAULT_COLUMN_WIDTH}px !important`};
    color: ${(
      props, // TODO: Fix Types
    ) =>
      // @ts-ignore
      props.color || UNSET};
    border-right: ${(
      props, // TODO: Fix Types
    ) =>
      // @ts-ignore
      props.column.includes(CHANGE_PERCENT) ? `${BORDER_NEUTRAL} !important` : UNSET}
`;

const Row = styled.div`
  height: 3rem;
`;

export default BreakdownSubComponent;

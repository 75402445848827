import React from 'react';
import cs from 'classnames';
import PromptButton from 'shared/components/molecules/PromptButton';
import { BRIEF_COPY } from 'shared/config/copy';
import { Brief } from 'shared/typings/briefs';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import { Box } from 'shared/components/display';
import styles from './styles';

interface Props {
  brief: Brief;
  variant?: string;
  className?: string;
  onClick?: () => void;
}
const ReopenBriefButton: React.FC<Props> = ({ brief, variant, className, onClick }) => {
  const { reopenBrief } = useBriefs();

  const onReopen = () => {
    reopenBrief(brief.id);
  };

  return (
    <Box css={styles}>
      <PromptButton
        text={BRIEF_COPY.MODAL_REOPEN}
        onConfirm={onReopen}
        className={cs('reopen-brief-button', className)}
        variant={variant}
        onClick={onClick}
      >
        {BRIEF_COPY.BUTTON_REOPEN}
      </PromptButton>
    </Box>
  );
};

export default ReopenBriefButton;

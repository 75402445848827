import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import * as yup from 'yup';

import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import StripeConnect from 'shared/components/molecules/StripeConnect';

import { BrkfstUser } from 'shared/typings/user';
import { UserType } from 'shared/typings/user/enums';
import { LinkCreator } from 'shared/utilities/linkUtility';

interface Props {
  user: Pick<BrkfstUser, 'id' | 'email' | 'stripeAccountId'>;
  redirectURI: string;
}

export const StripeFormFields: React.FC<Props> = ({ user, redirectURI }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue(STRIPE_FIELDNAMES.CONNECTED, user.stripeAccountId);
  }, [user.stripeAccountId]);

  return (
    <>
      <Field
        name={STRIPE_FIELDNAMES.CONNECTED}
        component={StripeConnect}
        user={user}
        redirectURI={redirectURI}
        className="creator-onboarding__stripe"
      />
      <FieldErrorMessage name={STRIPE_FIELDNAMES.CONNECTED} />
    </>
  );
};

export const STRIPE_FIELDNAMES = {
  CONNECTED: 'connected',
};

export const stripeSchema = yup.object({
  [STRIPE_FIELDNAMES.CONNECTED]: yup.string().nullable().required('Please connect Stripe to continue.'),
});

export const stripeInitialValues = {
  [STRIPE_FIELDNAMES.CONNECTED]: '',
};

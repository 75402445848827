import React from 'react';
import ErrorPage from 'features/ui/pages/ErrorPages/ErrorPage';
import { GENERIC_COPY } from 'shared/config/copy/genericCopy';
import { intl } from 'shared/lib/intl';
import config from 'config';

const InvalidInviteTokenPage = () => {
  const errorMessage = intl.formatMessage(
    { id: 'ERROR_PAGE_INVALID_TOKEN_MESSAGE' },
    {
      email: (
        <a className="error-page__email" href={`mailto:${config.CREATOR_CONTACT_EMAIL}`} target="_blank">
          {config.CREATOR_CONTACT_EMAIL}
        </a>
      ),
    },
  );

  return <ErrorPage errorType={GENERIC_COPY.ERROR_PAGE_INVALID_TOKEN_TYPE} errorMessage={errorMessage}></ErrorPage>;
};

export default InvalidInviteTokenPage;

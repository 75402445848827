import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.org-home': {
    '&--no-accounts': {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  '.org-home': {
    '&__create-btn': {
      margin: `${theme.space[4]} 0`,
    },
  },
});

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.brand-approved-checkbox': {
    alignItems: 'center',
  },
  '.brand-approved-checkbox': {
    '&__input': {
      marginRight: theme.space[2],
    },
    '&__label': {
      margin: 0,
    },
  },
});

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { FormikErrors } from 'formik';

import { SelectControl } from 'shared/components/atoms/SelectControl';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import { emptySelectOption } from 'shared/components/molecules/Select/SelectInput';

import { noOp } from 'shared/defaults';
import { GeoLocationSuggestion, GeoLocationValue, useGeoLocation } from 'shared/hooks/useGeoLocationHook';
import { Input as InputType } from 'shared/lib/formik';
import { Country } from 'shared/typings/address';

import ClearIndicator from './ClearIndicator';
import Input from './Input';
import NoOptionsMessage from './NoOptionsMessage';
import ValueContainer from './ValueContainer';
import Option from './Option';
import { reactSelectStyles } from './styles';

interface Props {
  innerRef: React.RefObject<any>;
  label: string;
  placeholder: string;
  width?: string;
  onAddressChange?: (address: GeoLocationValue) => void;
  setCountryDisabled: (arg: boolean) => void;
  countryOnly?: boolean;
  disabled?: boolean;
  countries: Country[];
}

const initialState: GeoLocationSuggestion = {
  value: {
    addressLine1: '',
    city: '',
    country: '',
    postalCode: '',
    state: '',
    countryCode: '',
  },
  label: '',
};

const StaticComponents = {
  IndicatorSeparator: () => null,
  Placeholder: () => null,
  SingleValue: () => null,
  ClearIndicator,
  NoOptionsMessage,
  Control: SelectControl,
  Option,
};

// Using this because setState is async and it does not
// update the state immediately in time for onMenuClose
// to get the correct value
let optionSet = false;

const AddressSearch: InputType<string, Props> = ({
  placeholder,
  width,
  setCountryDisabled,
  field: { name, value, onChange },
  form: { errors, touched },
  innerRef,
  countryOnly = false,
  disabled = false,
  onAddressChange = noOp,
  countries,
}) => {
  const [selectedOption, setSelectedOption] = useState<SingleValue<GeoLocationSuggestion>>(initialState);
  const [active, setActive] = useState(true);
  const { onQueryChange } = useGeoLocation();

  const onFocus = () => {
    setActive(true);
  };
  const onBlur = () => {
    setActive(false);
  };

  const setAddressInputs = (option: SingleValue<GeoLocationSuggestion>) => {
    // When a suggestion is selected the address fields are set to the selected values.
    if (option?.value) {
      Object.entries(option.value).forEach(async ([name, value]) => {
        if (name === 'country') {
          const country = countries.find((country) => country.code3 === option.value.countryCode);
          const optionValue = country ? { value: country, label: country?.name } : emptySelectOption;
          onChange({ target: { name, value: optionValue } });
        } else if (name === 'state') {
          // State is asynchronously fetched and assigned using the country chosen.
          onChange({ target: { name, value: emptySelectOption } });
        } else onChange({ target: { name, value } });
      });
      setCountryDisabled && setCountryDisabled(true);
      // Means the clear method was triggered
      onAddressChange(option.value);
    } else {
      Object.entries(initialState.value).forEach(([name, value]) => {
        onChange({ target: { name, value } });
      });
      setCountryDisabled && setCountryDisabled(false);
    }
  };

  const onOptionSelect = (
    suggestion: SingleValue<GeoLocationSuggestion>,
    triggeredAction: ActionMeta<GeoLocationSuggestion>,
  ) => {
    setSelectedOption(suggestion);
    setAddressInputs(suggestion);
    optionSet = true;

    if (triggeredAction.action === 'clear') {
      setActive(true);
    }
  };

  const hasErrors = useMemo(() => {
    const error = errors[name] as FormikErrors<any> | undefined;
    const errorIn = Array.isArray(error) ? error[0] : error;
    return Boolean(errorIn && touched[name]);
  }, [errors, touched, name]);

  const handleKeyDown = (e) => {
    // When user hits 'Enter' and there is no focused option, we want to save the text they inputted as the address
    // If there is a focused option, we want to select that option
    const focusedOption = innerRef.current?.state?.focusedOption; // focused option takes precedence over manual input when hitting 'Enter'
    const inputValue = innerRef.current?.inputRef?.value;
    if (e.key === 'Enter') {
      if (!focusedOption && inputValue) {
        e.preventDefault(); // Prevent form submission when manual input is entered and no suggestions are available
        const manualOption = {
          value: {
            addressLine1: inputValue,
            city: '',
            country: '',
            postalCode: '',
            state: '',
            countryCode: '',
          },
          label: inputValue,
        };
        setSelectedOption(manualOption);
        optionSet = true;
        setAddressInputs(manualOption);
        setCountryDisabled(false);

        innerRef.current.blur();
      }
    }
  };

  const onMenuOpen = () => {
    optionSet = false;
  };

  const onMenuClose = () => {
    // When user clicks outside of the input, we want to save the text they inputted as the address
    const inputValue = innerRef.current?.inputRef?.value;

    if (inputValue && !optionSet) {
      const manualOption = {
        value: {
          addressLine1: inputValue,
          city: '',
          country: '',
          postalCode: '',
          state: '',
          countryCode: '',
        },
        label: inputValue,
      };
      setSelectedOption(manualOption);
      optionSet = true;
      setAddressInputs(manualOption);
      setCountryDisabled(false);
    }
  };

  return (
    <>
      <AsyncSelect
        ref={innerRef}
        className={`address-search-${name}`}
        components={{
          ValueContainer: (valueContainerProps) => {
            return (
              <ValueContainer
                {...valueContainerProps}
                active={active}
                formikValue={value}
                ourPlaceholder={placeholder}
                dataCy="address-search__value-container"
              />
            );
          },
          Input: (inputProps) => {
            return (
              <Input
                {...inputProps}
                active={active}
                placeholder={value}
                autoFocus={active}
                dataCy="address-search__input"
              />
            );
          },
          ...StaticComponents,
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        loadOptions={(query, callback) => onQueryChange(query, callback, countryOnly)}
        name="addressSearch"
        // @ts-ignore
        onChange={(suggestion, triggerAction) => onOptionSelect(suggestion, triggerAction)}
        onMenuOpen={onMenuOpen}
        onMenuClose={onMenuClose}
        onKeyDown={handleKeyDown}
        styles={reactSelectStyles(width)}
        value={selectedOption}
        isDisabled={disabled}
        // @ts-ignore
        hasErrors={hasErrors}
        blurInputOnSelect
        defaultOptions
        isClearable
        isSearchable
      />
      <FieldErrorMessage className="address-search__error" dataCy="address-search__error" name={name} />
    </>
  );
};

export default AddressSearch;

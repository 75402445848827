import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.org-widgets': {
    '&__name': {
      margin: `${theme.space[5]} ${theme.space[7]} 0 ${theme.space[7]}`,
    },
  },
});

import { css } from '@emotion/react';

export default css({
  '.group-input-selected-count': {
    '&__dl, &__dd': {
      marginBottom: 0,
    },
    '&__dd': {
      lineHeight: '1',
    },
  },
});

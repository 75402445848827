import AccountLogo from 'features/accounts/AccountLogo/AccountLogo';
import AccountLogoForm from 'features/accounts/components/organisms/AccountLogoForm/AccountLogoForm';
import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { Flex } from 'shared/components/display';
import { ToggleEditInformation } from 'shared/components/molecules/ToggleEditInformation';
import { ACCOUNT_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import React from 'react';
import { Button } from 'rebass/styled-components';
import styles from './styles';

interface Props {
  accountId: string;
  authorizedToEdit: boolean;
}
const UpdateAccountLogo: React.FC<Props> = ({ accountId, authorizedToEdit }) => {
  const { account, loadingAccountLogo, addAccountLogo, removeAccountLogo } = useAccounts({ accountId });

  const accountLogoFormProps = {
    onSubmit: (accountData) => {
      addAccountLogo(accountData.brandLogo);
    },
    formDisabled: !authorizedToEdit,
  };

  return (
    <ToggleEditInformation
      editButtonText={ACCOUNT_COPY.BUTTON_UPLOAD_LOGO}
      heading={ACCOUNT_COPY.HEADING_ACCOUNT_LOGO}
      variant={TEXT_VARIANTS.H6}
      EditingComponent={AccountLogoForm}
      EditingComponentProps={accountLogoFormProps}
    >
      <Flex css={styles} className="update-account-logo">
        <AccountLogo size="sm" account={account} loading={loadingAccountLogo} />
        {!loadingAccountLogo && account.logo?.url && (
          <Button
            variant={BUTTON_VARIANTS.TEXT_ONLY}
            className="update-account-logo__delete-logo-btn"
            onClick={() => removeAccountLogo(account.logoId)}
          >
            {ACCOUNT_COPY.BUTTON_DELETE_LOGO}
          </Button>
        )}
      </Flex>
    </ToggleEditInformation>
  );
};

export default UpdateAccountLogo;

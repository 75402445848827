import { css } from '@emotion/react';
import text, { fontSizes } from 'shared/styles/text';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { zIndex } from 'shared/config';
import { mediaquery } from 'shared/styles/styleFunctions';

const HEIGHT = '25px';

export default (progress: number = 0) =>
  css({
    '&.account-platform-health-progress': {
      textAlign: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: '400px',
    },
    '.account-platform-health-progress': {
      '&__progress-circle': {
        width: '40vw',
        transform: 'rotate(180deg)',
        minWidth: '150px',
        margin: '4% 0',
        [mediaquery.md]: {
          width: '10vw',
        },
        '& .CircularProgressbar-text ': {
          fill: COLORS.BLACK,
          transformOrigin: '50px 50px',
          transform: 'rotate(180deg)',
          ...text.h1,
          fontSize: fontSizes[6],
        },
      },
      '&__title': {
        fontWeight: 300,
      },
      '&__subtitle': {
        fontWeight: 300,
        fontSize: theme.fontSizes[4],
        maxWidth: '775px',
      },
      '&__img': {
        width: '100%',
        maxWidth: '400px',
        margin: `${theme.space[7]} 0 `,
      },
      '&__progress-wrapper': {
        width: '200px',
      },
      '&__container': {
        flexDirection: 'column',
        alignItems: 'center',
      },
      '&__progress-bar-wrapper': {
        position: 'relative',
        width: '100%',
        height: HEIGHT,
        borderRadius: '50px',
        backgroundColor: COLORS.NEUTRAL100,
      },
      '&__progress-bar': {
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        transition: 'width 0.5s',
        backgroundColor: progress === 0 ? 'transparent' : COLORS.PRIMARY500,
        width: progress === 0 ? HEIGHT : `${progress}%`,
        borderRadius: '50px',
      },
      '&__logo': {
        width: HEIGHT,
        position: 'absolute',
        left: progress === 0 ? '0' : `calc(${progress}% - ${HEIGHT})`,
        zIndex: zIndex.zForeground,
        transition: 'left 0.5s',
      },
    },
  });

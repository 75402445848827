import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.facebook-step': {
    marginTop: theme.space[4],
  },
  '.facebook-step': {
    '&__checkbox-wrapper': {
      margin: `0 0 ${theme.space[2]} ${theme.space[2]}`,
    },
    '&__input-wrapper': {
      margin: `${theme.space[1]} ${theme.space[3]} ${theme.space[1]} ${theme.space[1]}`,
    },
    '&__loading-wrapper': {
      alignItems: 'flex-start',
    },
    '&__loader': {
      marginLeft: theme.space[3],
      marginTop: theme.space[4],
      padding: `${theme.space[2]} 0`,
    },
    '&__error': {
      marginTop: theme.space[3],
    },
  },
});

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import { faCircleDollar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import Tooltip from 'shared/components/atoms/Tooltip';
import { Flex, Text } from 'shared/components/display';

import { SUBMISSION_COPY } from 'shared/config/copy';
import { intl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { breakpoints } from 'shared/styles/styleFunctions';
import { Brief } from 'shared/typings/briefs';
import { Submission } from 'shared/typings/submissions';
import { LinkCreator } from 'shared/utilities/linkUtility';

import styles from './styles';

interface Props {
  brief: Brief;
  submission: Submission;
}
const SubmissionsPayout: React.FC<Props> = ({ brief, submission }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md - 1 });
  const {
    creator: { stripeAccountId, isElite },
  } = submission;
  const { creatorPayout, eliteCreatorPayout } = brief;
  const payoutAmount = isElite ? eliteCreatorPayout : creatorPayout;

  return (
    <Flex css={styles} className="submissions-payout">
      <Tooltip
        content={
          stripeAccountId
            ? intl.formatMessage({ id: 'TOOLTIP_SUBMISSION_PAYOUT' }, { creatorPayout: payoutAmount })
            : SUBMISSION_COPY.TOOLTIP_NO_STRIPE_ACCOUNT
        }
        as="span"
        arrow
        placement={isMobile ? 'bottom-end' : 'right'}
        offset={isMobile ? [10, 10] : [5, 20]}
      >
        <Button
          disabled={submission.paid || payoutAmount === 0 || !stripeAccountId}
          onClick={(e) => {
            e.stopPropagation();
            navigate(
              {
                pathname: LinkCreator.createLink({
                  routeKey: 'SUBMISSION_CHECKOUT',
                  variables: {
                    briefId: brief.id,
                    submissionId: submission.id,
                    accountId: brief.accountId,
                    organizationId: brief.organizationId,
                  },
                }),
              },
              {
                state: {
                  submission: submission,
                  brief: brief,
                },
              },
            );
          }}
          variant={BUTTON_VARIANTS.MICRO}
        >
          <Flex
            className={cs('submissions-payout__text-wrapper', {
              'submissions-payout__text-wrapper--paid': submission.paid,
              'submissions-payout__text-wrapper--unpaid': !submission.paid,
            })}
          >
            <FontAwesomeIcon icon={faCircleDollar} />

            <Text className="submissions-payout__text">
              {submission.paid ? SUBMISSION_COPY.SUBMISSION_PAID : SUBMISSION_COPY.SUBMISSION_UNPAID}
            </Text>
          </Flex>
        </Button>
      </Tooltip>
    </Flex>
  );
};

export default SubmissionsPayout;

import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Text } from 'rebass/styled-components';

import { useUser } from 'features/users/hooks/useUser';
import { Box } from 'shared/components/display';
import { validatePhone } from 'shared/components/inputs/PhoneInput/util';
import UnderlineInput from 'shared/components/inputs/UnderlineInput/UnderlineInput';
import AddressForm from 'shared/components/organisms/AddressForm';
import { formatAddressQuery } from 'shared/components/organisms/AddressFormFields';

import { CREATOR_COPY, USER_COPY } from 'shared/config/copy';
import { PhoneStatus } from 'shared/config/status/phone';
import { MESSAGES } from 'shared/config/validations';
import { useGeoLocation } from 'shared/hooks/useGeoLocationHook';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

const { H6 } = TEXT_VARIANTS;

interface Props {
  userId: number;
  email?: string;
}

const ContactInfoForm: React.FC<Props> = ({ userId }) => {
  const {
    user: { mailingAddress, phone, email },
    updateUserAddress,
    createOrUpdatePhone,
  } = useUser(userId);

  const { validateAddress } = useGeoLocation();

  const submitHandler = async (addressData, setAddressError, phoneChanged, addressChanged) => {
    const {
      city,
      postalCode,
      phoneNumber,
      countryCode,
      country: { value: country },
      state: { value: state },
    } = addressData;
    const address = formatAddressQuery({
      city,
      postalCode,
      country: country.name,
      state: state?.name,
    });

    const isValidAddress = await validateAddress(address);
    const { validity: isValidPhone, formattedNumber } = await validatePhone(phoneNumber, countryCode);

    const errorMessages: string[] = [];

    if (isValidAddress && isValidPhone) {
      if (phoneChanged) {
        createOrUpdatePhone({
          notifications: phone?.status === PhoneStatus.ACTIVE || false,
          number: formattedNumber,
          countryCode,
        });
      }
      if (addressChanged) {
        const stateId = state?.id || null; // Null if no state to remove relation if one already exists
        updateUserAddress({ mailingAddress: { ...addressData, countryId: country.id, stateId } });
      }
    } else {
      if (!isValidAddress) {
        errorMessages.push(MESSAGES.INVALID_ADDRESS);
      }
      if (!isValidPhone) {
        errorMessages.push(MESSAGES.INVALID_PHONE);
      }
      setAddressError(errorMessages);
    }
  };

  return (
    <Box className="contact-info-form" css={styles}>
      <Text className="contact-info-form__label" variant={H6}>
        {CREATOR_COPY.LABEL_CREATOR_EMAIL_READONLY}
      </Text>
      <UnderlineInput type="email" value={email} placeholder={email} onChange={() => {}} disabled />
      <Text className="contact-info-form__email-change-text" variant={TEXT_VARIANTS.BODY} color={COLORS.NEUTRAL500}>
        {ReactHtmlParser(USER_COPY.SUBHEADING_EMAIL_CHANGE)}
      </Text>
      <Text className="contact-info-form__label" variant={H6}>
        {CREATOR_COPY.HEADER_CREATOR_ADDRESS}
      </Text>
      <AddressForm
        address={mailingAddress}
        phone={phone}
        onSubmit={submitHandler}
        allowReset
        confirmButtonText="Save Changes"
      />
    </Box>
  );
};

export default ContactInfoForm;

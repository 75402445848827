import React, { useMemo, useState } from 'react';
import cs from 'classnames';
import * as yup from 'yup';
import { Box, Button, Flex, Text } from 'shared/components/display';
import { Field, Form, Formik } from 'formik';
import FormButton from 'shared/components/FormButton';
import TextareaInput from 'shared/components/TextareaInput/TextareaInput';
import { makeLabel } from 'shared/lib/formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { CREATOR_COPY } from 'shared/config/copy';

import styles from './styles';

const SUMMARY = 'summary';

const validationSchema: yup.SchemaOf<Values> = yup.object({
  [SUMMARY]: yup.string().nullable(),
});

interface Values {
  summary?: string;
}

interface Props {
  summary?: string;
  className?: string;
  onSubmit: (values: Values) => void;
}
const EditableCreatorSummary: React.FC<Props> = ({ summary = '', className, onSubmit }) => {
  const [editMode, setEditMode] = useState(false);
  const initialValues: Values = useMemo(() => {
    return {
      [SUMMARY]: summary,
    };
  }, [summary]);

  const handleSubmit = (values: Values) => {
    onSubmit(values);
    setEditMode(false);
  };

  return (
    <Box css={styles} className={cs('editable-creator-summary', className)}>
      {editMode ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <Form>
            <Field
              name={SUMMARY}
              maxLength={160}
              component={TextareaInput}
              placeholder={CREATOR_COPY.PLACEHOLDER_SUMMARY}
              label={makeLabel(SUMMARY)}
              className="editable-creator-summary__summary-field"
            />
            <Flex className="editable-creator-summary__btn-wrapper">
              <FormButton
                type="button"
                className="editable-creator-summary__btn"
                variant={BUTTON_VARIANTS.TEXT}
                onClick={() => {
                  setEditMode(false);
                }}
              >
                Cancel
              </FormButton>
              <FormButton type="submit" className="editable-creator-summary__btn">
                Save
              </FormButton>
            </Flex>
          </Form>
        </Formik>
      ) : (
        <Flex className="editable-creator-summary__text-icon-wrapper">
          <Text>{summary || CREATOR_COPY.PLACEHOLDER_SUMMARY}</Text>

          <Button
            variant={BUTTON_VARIANTS.TEXT_ONLY}
            onClick={() => {
              setEditMode(true);
            }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default EditableCreatorSummary;

import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from 'shared/components/display';
import FormButton from 'shared/components/FormButton';
import { inlineButtonsStyle } from 'shared/components/InlineInputs/inlineInputStyles';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import cs from 'classnames';
import { FormikContextType } from 'formik';
import React from 'react';

interface Props {
  onSubmit: (formik: FormikContextType<unknown>) => void;
  onCancel: (formik: FormikContextType<unknown>) => void;
  isTextarea?: boolean;
}
const InlineButtons: React.FC<Props> = ({ onSubmit, onCancel, isTextarea }) => {
  return (
    <Box
      css={inlineButtonsStyle}
      className={cs('inline-buttons', {
        ['inline-buttons--textarea']: isTextarea,
      })}
    >
      <FormButton className={'inline-buttons__btn'} tabIndex={-1} variant={BUTTON_VARIANTS.MICRO} onClick={onSubmit}>
        <FontAwesomeIcon icon={faCheck} fixedWidth />
      </FormButton>
      <FormButton
        type="button"
        className={'inline-buttons__btn'}
        tabIndex={-1}
        variant={BUTTON_VARIANTS.MICRO}
        onClick={onCancel}
      >
        <FontAwesomeIcon icon={faTimes} fixedWidth />
      </FormButton>
    </Box>
  );
};

export default InlineButtons;

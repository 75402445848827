import React from 'react';
import cs from 'classnames';
import { Flex } from 'shared/components/display';
import styles from './styles';

interface Props {
  children: React.ReactNode;
  className?: string;
  showShadow?: boolean;
  rightAlignItems?: boolean;
}
const NavBar: React.FC<Props> = ({ children, className, showShadow = false, rightAlignItems = false }) => {
  return (
    <Flex
      css={styles}
      className={cs('nav-bar', className, {
        'nav-bar--shadow': showShadow,
        'nav-bar--right': rightAlignItems,
      })}
    >
      {children}
    </Flex>
  );
};

export default NavBar;

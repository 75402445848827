import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import * as mimeTypes from 'mime';

import { ASPECT_RATIO_TOLERANCE, MEDIA } from 'shared/config/media';
import { RotationMetadata } from 'shared/typings/file';
import { withinPercentTolerance } from 'shared/utilities/mathUtility';

/**
 * returns the blob object of a file from its local url
 * @param url - browser specific url for a file
 */
export async function getBlobFromUrl(url) {
  const res = await fetch(url);
  return res.blob();
}

export async function downloadFile(fileObject) {
  return getBlobFromUrl(fileObject.url).then((blob) => {
    saveAs(blob, fileObject.name);
  });
}

export function createZip(files, folderName) {
  /**
   * Create a zip from an array of files and download it
   *
   * @param {array} files - array of file object, must have the properties: name, url
   *
   * @param {string} folderName - what the name of the zip file and the directory it exands to should be called
   *
   * */
  const zip = new JSZip().folder(folderName);
  if (zip) {
    const fileBlobs: Promise<Blob>[] = [];
    files.forEach((file) => {
      const fileBlob = getBlobFromUrl(file.url);
      fileBlobs.push(fileBlob);
    });
    Promise.all(fileBlobs).then((resolvedBlobs) => {
      resolvedBlobs.forEach((fileBlob, index) => {
        zip.file(files[index].name, fileBlob);
      });
      // @ts-ignore
      return zip.generateAsync({ type: MEDIA.FILE_TYPES.BLOB }).then((blob) => {
        saveAs(blob, `${folderName}.zip`);
      });
    });
  }
}

export function removeExtension(fileName) {
  return fileName.replace(/\.[^/.]+$/, '');
}

export const readUploadedFileAsDataURL = (inputFile) => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new Error('Problem parsing input file.'));
    };

    temporaryFileReader.onload = (event) => {
      resolve(event.target?.result);
    };
    temporaryFileReader.readAsDataURL(inputFile);
  });
};

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const _ia = new Uint8Array(arrayBuffer);
  for (let i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i);
  }

  const dataView = new DataView(arrayBuffer);
  return new Blob([dataView], { type: mimeString });
};

/**
 * Get mime type based on the file extension
 *
 * @param {string} path file name, path, or extension
 *
 * @returns {string} the mimetype that corresponds to the file extension
 *
 * */
export const getMimeType = (path) => {
  return mimeTypes.getType(path) || '';
};

export const getImageDimension = (gif): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.naturalWidth, height: img.naturalHeight });
    };
    img.onerror = () => {
      reject('Problem parsing image');
    };
    img.src = URL.createObjectURL(gif);
  });
};

export const formatAspectRatio = ({
  width = 0,
  height = 0,
  rotation = 0,
}: {
  width?: number;
  height?: number;
  rotation?: RotationMetadata;
}): string => {
  let aspectRatio = width / height;

  if (isRotatedSideways(rotation)) aspectRatio = height / width;

  if (withinPercentTolerance(aspectRatio, 9 / 16, ASPECT_RATIO_TOLERANCE)) {
    return '9:16';
  } else if (withinPercentTolerance(aspectRatio, 4 / 5, ASPECT_RATIO_TOLERANCE)) {
    return '4:5';
  } else if (withinPercentTolerance(aspectRatio, 1, ASPECT_RATIO_TOLERANCE)) {
    return '1:1';
  } else if (withinPercentTolerance(aspectRatio, 2 / 3, ASPECT_RATIO_TOLERANCE)) {
    return '2:3';
  } else if (withinPercentTolerance(aspectRatio, 16 / 9, ASPECT_RATIO_TOLERANCE)) {
    return '16:9';
  }
  return 'Other';
};

// used to determine if we need to invert aspect ratio calculations
// see https://dev.azure.com/agencywithin/AWPL/_wiki/wikis/Wiki/445/Height-and-Width-Inversions for more info
export const isRotatedSideways = (rotation: RotationMetadata): boolean =>
  Boolean(rotation) && ![180, -180].includes(rotation);

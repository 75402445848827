import { BREAKPOINTS, COLUMNS, ROW_HEIGHT } from 'shared/components/atoms/WidgetWrapper/config';
import widgetWrapperStyles from 'shared/components/atoms/WidgetWrapper/widgetWrapperStyles';
import { Box } from 'shared/components/display';
import { LOCAL_STORAGE } from 'shared/config/storage';
import { useLocalStorage } from 'shared/hooks/useLocalStorage';
import React, { useEffect, useMemo } from 'react';
import { Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';

const ReactGridLayout = WidthProvider(Responsive);
const DRAGGABLE_SELECTORS = '.widget-panel__title button, .mobile-table__body, .widget-panel__title--with-link, .earnings-summary a';

interface Props {
  initialLayouts: Layouts;
  entity: string;
  entityId: number;
  children: JSX.Element | Array<JSX.Element>;
}

// to do: only allow editing when editing mode is toggled

const WidgetWrapper: React.FC<Props> = ({ initialLayouts, entity, entityId, children }) => {
  const { value: layouts, setVal: setLayouts } = useLocalStorage<Layouts>(
    `${LOCAL_STORAGE.WIDGET_LAYOUT}_${entity}_${entityId}`,
  );

  useEffect(() => {
    if (!layouts) {
      setLayouts(initialLayouts);
    }
  }, [initialLayouts]);

  const onLayoutChange = (currentLayout, allLayouts) => {
    setLayouts(allLayouts);
  };

  return (
    <Box css={widgetWrapperStyles} className="widget-wrapper">
      <ReactGridLayout
        className="layout"
        layouts={layouts || initialLayouts}
        breakpoints={BREAKPOINTS}
        cols={COLUMNS}
        rowHeight={ROW_HEIGHT}
        onLayoutChange={onLayoutChange}
        autoSize={false}
        allowOverlap={false}
        draggableCancel={DRAGGABLE_SELECTORS}
      >
        {children}
      </ReactGridLayout>
    </Box>
  );
};

export default WidgetWrapper;

import * as yup from 'yup';
import { MESSAGES } from 'shared/config/validations';

export interface CreditCardValue {
  complete?: boolean; // boolean to signify if complete
  error?: string;
  card: {
    organizationId?: number | null;
    sourceId: string;
    expiration: string;
    default: boolean;
    name: string;
  };
}

export const creditCardInitialValue: CreditCardValue = {
  complete: false,
  error: '',
  card: {
    organizationId: null,
    sourceId: '',
    expiration: '',
    default: false,
    name: '',
  },
};

export const getCreditCardSchema = (required?: boolean) => {
  const baseSchema = yup
    .object()
    .shape({
      complete: yup.boolean(),
      error: yup.string().nullable(),
      card: yup.object({
        organizationId: yup.number().nullable(),
        sourceId: yup.string(),
        expiration: yup.string(),
        default: yup.boolean(),
        name: yup.string(),
      }),
    })
    .test({
      name: 'stripe error',
      test(value) {
        if (value.error) {
          return this.createError({
            message: value?.error,
          });
        }
        return true;
      },
    });
  if (required) {
    // @ts-ignore
    return baseSchema.test({
      name: 'required',
      message: MESSAGES.REQUIRED_FIELD,
      test(value) {
        if (value.card.sourceId) return true;
        // true means it is complete, false means it's incomplete
        return value.complete;
      },
    });
  }
  return baseSchema;
};

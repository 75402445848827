import { findKey } from 'lodash';
import { ALL_ROUTES, GENERAL_ROUTES } from 'shared/config/routes/generalRoutes';
import { UserType } from 'shared/typings/user/enums';
import { isNumeric } from 'shared/utilities/stringUtility';

export const isExistingRoute = (userType, path: string) => {
  const { BRANDS, CREATORS } = ALL_ROUTES;
  let routesToSearch = GENERAL_ROUTES;
  if (userType === UserType.ORG) {
    routesToSearch = BRANDS;
  } else if (userType === UserType.CREATOR) {
    routesToSearch = CREATORS;
  }

  const pathSearchIndex = path.indexOf('?');
  const pathRedirectURI = pathSearchIndex !== -1 ? path.slice(0, pathSearchIndex) : path;
  const pathArray = pathRedirectURI.split('/').slice(1); // paths start with "/"

  const key = findKey(routesToSearch, (value) => {
    const valueArray = value.split('/').slice(1); // paths start with "/"
    // If the length of the value and the pathArray are different, it's not a match
    if (valueArray.length !== pathArray.length) {
      return false;
    }
    // check the segments of the path
    for (let i = 0; i < valueArray.length; i++) {
      // if it starts with :, check for a number
      if (valueArray[i][0] === ':' && !isNumeric(pathArray[i])) {
        return false;
      }
      if (valueArray[i][0] !== ':' && valueArray[i] !== pathArray[i]) {
        return false;
      }
    }

    return true;
  });

  return !!key;
};

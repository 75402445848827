import { SIZING } from 'shared/config/formatting';
import buttons from 'shared/styles/button';
import COLORS from 'shared/styles/colors';
import variants from 'shared/styles/containers';
import SHADOWS from 'shared/styles/shadow';
import space from 'shared/styles/spacing';
import text, { fonts, fontSizes, fontWeights } from 'shared/styles/text';

const breakpoints = ['360px', '768px', '1200px'];

//@ts-ignore
breakpoints.sm = breakpoints[0];
//@ts-ignore
breakpoints.md = breakpoints[1];
//@ts-ignore
breakpoints.lg = breakpoints[2];

const theme = {
  breakpoints,
  fontSizes,
  colors: COLORS,
  space,
  fonts,
  fontWeights,
  shadows: SHADOWS,
  variants,
  text: { ...text },
  buttons,

  // modal Size
  modalSize: {
    [SIZING.SMALL]: {
      width: '25%',
      height: 'auto',
    },
    [SIZING.MEDIUM]: {
      width: '30%',
      height: 'auto',
    },
    [SIZING.MEDIUM_WIDE]: {
      width: '40%',
      height: 'auto',
    },
    [SIZING.LARGE]: {
      width: '60%',
      height: 'auto',
      maxHeight: '90%',
    },
    [SIZING.FULLSCREEN]: {
      width: '100vw',
      height: '100%',
    },
  },

  sideDrawerSize: {
    small: 'width: 30%; height: 45%',
    mediumSmall: 'width: 40%; height: 55%',
    medium: 'width: 50%; height: 70%;',
    default: 'width: 60%; height: 100%;',
    // add sizes as you see fit.
  },
};

export default theme;

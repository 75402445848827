export const API_SUBMISSION_ROUTES = Object.freeze({
  CREATOR_SUBMISSIONS: '/submission/creator/:creatorId',
  SUBMISSION: '/submission/:submissionId',
  SUBMISSIONS: '/submission',
  CREATOR_PAYOUT: '/submission/:submissionId/creator/:creatorId/payout',
  CREATOR_SYSTEM_SUBMISSION: '/submission/creator/:creatorId/system',
  MODULE_COMMENTS: '/submission/module/:submissionModuleId/comments',
  MODULE_COMMENT: '/submission/module/:submissionModuleId/comments/:commentId',
  PUBLISH_SUBMISSION: '/submission/:submissionId/publish',
});

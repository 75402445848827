import { css } from '@emotion/react';

import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.organization-onboarding-confirmation': {
    marginTop: theme.space[3],
    flexDirection: 'column',
    [mediaquery.md]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  '.organization-onboarding-confirmation': {
    '&__checkout': {
      margin: '16px 20px 16px 0',
    },
    '&__details': {
      width: '100%',
      [mediaquery.md]:{
        width: '25%',
      },
    },
  },
});

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.tagify': {
    border: 'none',
    width: '100%',
    '&__tag': {
      backgroundColor: COLORS.PRIMARY50,
      color: COLORS.PRIMARY800,
      padding: 0,
      borderRadius: '5px',
      height: 'fit-content',
    },
    '&__tag__removeBtn': {
      marginLeft: theme.space[3],
      color: COLORS.NEUTRAL700,
    },
    '&__tag--invalid': {
      backgroundColor: COLORS.RED200,
    },
    '&__tag-text, &__tag-category': {
      padding: `${theme.space[2]} ${theme.space[3]}`,
    },
    '&__tag-category': {
      backgroundColor: COLORS.PRIMARY200,
      borderRadius: '5px 0 0 5px',
      fontWeight: 600,
    },
    '--tag-hide-transition': '0s',
    // Dropdown styles are included in GlobalStyles since we don't have a way to apply styles to components in the dropdown modal otherwise
  },
  '.tagify__tag': {
    '&__avatar, &__avatarPlaceholder': {
      minWidth: '18px',
      width: '18px',
      height: '18px',
      background: COLORS.NEUTRAL200,
      borderRadius: '50%',
      marginLeft: '4px',
    },
  },
  '&.tags--readonly .tagify__input': {
    display: 'none',
  },
});

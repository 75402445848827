export const COLLECTION_COPY = {
  // Buttons
  BUTTON_MOVE_FILES: 'Move {numFiles, plural, one {# file} other {# files}}',
  BUTTON_MOVE_FILE: 'Move File',
  BUTTON_MOVE_FILES_TO_COLLECTION: 'Move Files to Collection',
  BUTTON_SELECT_ALL: 'Select All',
  BUTTON_CREATE_COLLECTION: 'Create Collection',
  BUTTON_SEE_CREATOR: 'See creator profile',
  BUTTON_UPLOAD_COLLECTION_ASSET: 'Upload {media, select, image {Image} video {Video} gif {GIF} other {File}}',
  BUTTON_UPLOAD_FILES: 'Upload Files',
  BUTTON_PICK_ASSET: `Submit {media, select, image {Image} video {Video} gif {GIF} other {File}}{numFiles, plural, =0 {} one { (#)} other {s (#)}}`,
  BUTTON_SHARE: 'Share Collection',
  BUTTON_BACK_TO_LIBRARY: 'Back to Library',
  BUTTON_BACK_TO_CREATOR_PROFILE: 'Back to Creator Profile',
  BUTTON_CREATE_NEW_COLLECTION: 'Create New Collection',
  BUTTON_DELETE_COLLECTION: 'Delete Collection',
  BUTTON_CLEAR_FILE_SELECTION: 'Clear all',
  BUTTON_COMPOSE_FILES: 'Compose {numFiles} files',
  BUTTON_DOWNLOAD_FILES: 'Download {numFiles, plural, one {# file} other {# files}}',
  BUTTON_DOWNLOAD_FILES_WATERMARK: 'With watermark',

  // Radio option
  RADIO_ALL_ASSETS: 'All Assets',
  RADIO_VIDEOS: 'Videos',
  RADIO_IMAGES: 'Images',

  // Labels
  LABEL_FB_LIBRARY: 'FB Library',
  LABEL_TIKTOK_LIBRARY: 'TikTok Library',
  LABEL_ADD_TO_TIKTOK_LIBRARY: 'Add to TikTok Library',
  LABEL_ADD_TO_FACEBOOK_LIBRARY: 'Add to Facebook Library',
  LABEL_FILTER: 'Filter:',
  LABEL_CREATE_COMPOSITION: 'Create Composition',
  LABEL_CREATE_CUSTOM_COMPOSITION: 'Create Custom Composition',
  LABEL_CREATE_SINGLE_AD: 'Create Facebook Ad',
  LABEL_CREATE_CAROUSEL_AD: 'Create Facebook Carousel Ad',
  LABEL_COLLECTION_TOGGLE_PRIVACY: 'Make Collection Public',
  LABEL_SUBMITTED_ASSET: 'Submitted',
  LABEL_APPROVED_ASSET: 'Approved',
  LABEL_TAGS: 'Tags',
  LABEL_DESTINATION_COLLECTION: 'Destination Collection',

  MODAL_DELETE_COLLECTION: 'Are you sure you want to delete this collection?',
  MODAL_DOWNLOAD_CREATIVE_FILES: 'Download Creative Only Files',
  MODAL_DOWNLOAD_FILES: 'Download Files',
  MODAL_CONFIRM_DOWNLOAD_CREATIVE_FILES: `The following files were not submitted to a creative only brief:{invalidFiles}\n\nWould you like to schedule a download for the remaining files? {validFiles}\n`,
  MODAL_BUTTON_SCHEDULE_DOWNLOAD: 'Schedule Download',

  // Placeholders
  PLACEHOLDER_SEARCH_ASSETS: 'Search...',
  PLACEHOLDER_ASSETS: 'No Assets',
  NO_COLLECTIONS_RESULTS: 'No collections matching that search',
  NO_FILES_RESULTS: 'No files matching that search',

  // Drawer headers
  DRAWER_IMAGE_UPLOAD: 'Upload New Image',
  DRAWER_VIDEO_UPLOAD: 'Upload New Video',
  DRAWER_ADD_FILES: 'Move Selected Files',
  DRAWER_SHARE: 'Share the collection',

  // Warnings
  WARNING_COMPOSITION_FILES:
    'A composition must contain all the videos in the set. Please add a mutable space, or create a new set with just the videos you need.',

  // Headings
  HEADING_SELECT_ASSET: 'Choose {media, select, image {Image} video {Video} gif {GIF} other {File}}s',

  // Tooltips
  TOOLTIP_PRIVATE: 'This collection is private',
  TOOLTIP_PUBLIC: 'This collection is public',
  TOOLTIP_DOWNLOAD_FILES: 'Only assets generated through creative only briefs can be downloaded',
  TOOLTIP_DOWNLOAD_SELECTED_FILES: 'Download Selected Files',

  LINK_SET_UP_AD_PLATFORM: `Complete ad platform 
    \nrequirements to launch new ads`,
  LINK_HOVER_SET_UP_AD_PLATFORM: 'View ad platform requirements',

  FORM_HEADER_NEW_COLLECTION: 'New Collection',
  FORM_HEADER_EDIT_COLLECTION: 'Edit Collection',
};

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import styled from 'styled-components';
import { zIndex } from 'shared/config';
import SHADOWS from 'shared/styles/shadow';

export default (optionWidth: number) =>
  css({
    '&.segmented-button': {
      height: 'fit-content',
    },
    '.segmented-button': {
      '&__control': {
        display: 'grid',
        gridAutoFlow: 'column',
        gridAutoColumns: `${optionWidth}rem`,
        userSelect: 'none',
        backgroundColor: COLORS.PRIMARY100,
        padding: '4px',
        borderRadius: '4px',
      },
      '&__option': {
        position: 'relative',
      },
      '&__option:first-of-type': {
        gridColumn: 1,
        gridRow: 1,
      },
      '&__option:not(:first-of-type) label::before': {
        content: '""',
        left: 0,
        width: '1px',
        backgroundColor: COLORS.NEUTRAL500,
        position: 'absolute',
        top: '14%',
        bottom: '14%',
        borderRadius: '10px',
      },
      '&__label': {
        width: '100%',
        margin: 0,
        cursor: 'pointer',
        textAlign: 'center',
      },
      '&__label span': {
        color: COLORS.NEUTRAL500,
        transition: 'color 0.2s ease-in-out',
        zIndex: zIndex.zButton,
        position: 'relative',
      },
      '&__input': {
        display: 'none',
      },
      '&__input:checked + span': {
        color: COLORS.PRIMARY500,
      },
      '&__input:not(:checked):focus + span, &__input:not(:checked): active + span': {
        color: COLORS.PRIMARY500,
      },
    },
  });

export const Slider = styled.span`
  border-radius: 4px;
  background-color: white;
  box-shadow: ${SHADOWS.SHADOW08};
  grid-column: 1;
  grid-row: 1;
  z-index: ${zIndex.zButton};
  width: calc(100% + 2px);
  transition: transform 0.2s ease-in-out;
  transform: ${({
    // TODO: Fix Types
    // @ts-ignore
    optionWidth,
    // TODO: Fix Types
    // @ts-ignore
    index,
  }) => `translateX(${optionWidth * index}rem)`};
`;

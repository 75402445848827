import { css } from '@emotion/react';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.create-update-submission': {
    padding: '16px',
    flexDirection: 'column',
    height: 'calc(100vh - 66px)',
    [mediaquery.md]: {
      height: '100%',
    },
  },
  '.create-update-submission': {
    '&__wrapper': {
      padding: theme.space[4],
      [mediaquery.md]: {
        padding: theme.space[5],
      },
    },
    '&__heading-submission': {
      display: 'none',
      [mediaquery.md]: {
        display: 'block',
      },
    },
  },
  '.split-panel-scroll': {
    padding: 0,
    flex: 1,
    overflow: 'auto',

    '&__grid': {
      gridTemplateColumns: '[left] 0 [right] 1fr [end]',
      [mediaquery.md]: {
        gridTemplateColumns: '[left] minmax(300px,1fr) [right] 2fr [end];',
      },
    },
    '&__left-header': {
      display: 'none',
      [mediaquery.md]: {
        display: 'block',
      },
    },
    '&__left-panel': {
      display: 'none',
      [mediaquery.md]: {
        display: 'block',
      },
    },
  },
});

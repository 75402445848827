import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.creator-submissions-actions': {
    height: '42px',
    alignItems: 'center',
    flex: 'none',
  },
  '.creator-submissions-actions': {
    '&__label': {
      marginBottom: 0,
    },
    '&__submission-btn': {
      marginLeft: theme.space[3],
    },
  },
});

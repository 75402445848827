import React from 'react';
import cs from 'classnames';

import Tags, { TagifySettings } from 'shared/components/atoms/Tags';
import Tag from 'shared/components/atoms/Tags/templates/Tag';
import { Box, Flex, Text } from 'shared/components/display';

import styles from './styles';

type CategoryTagTheme = 'top-category' | 'top-category-plain' | 'left-category' | 'definition-list-category';
type CategorySubComponent = React.FC<{
  category: string;
  disabled?: boolean;
  name?: string;
  active?: boolean;
  uppercaseCategory?: boolean;
}>;
type ValuesSubComponent = React.FC<{
  children: React.ReactElement<any> | React.ReactElement<any>[];
}>;
type WrapperSubComponent = React.FC<{
  children: React.ReactElement<any> | React.ReactElement<any>[];
  theme: CategoryTagTheme;
  className?: string;
  onClick?: () => void;
  dataCy?: string;
}>;
interface Props {
  category: string;
  values: { value: string }[];
  className?: string;
  theme?: CategoryTagTheme;
  onClick?: () => void;
  duplicates?: boolean;
  active?: boolean;
  uppercaseCategory?: boolean;
}

const settings: TagifySettings = {
  templates: {
    tag: Tag,
  },
};

const CategoryTags: React.FC<Props> & {
  Category: CategorySubComponent;
  Values: ValuesSubComponent;
  Wrapper: WrapperSubComponent;
} = ({
  category,
  values,
  className,
  theme = 'left-category',
  onClick,
  duplicates = false,
  uppercaseCategory = false,
}) => {
  return (
    <Wrapper dataCy={`category-tags__category--${category}`} theme={theme} className={className} onClick={onClick}>
      <Category category={category} uppercaseCategory={uppercaseCategory} />
      <Values>
        <Tags value={values} settings={{ ...settings, duplicates }} readOnly />
      </Values>
    </Wrapper>
  );
};

const Category: CategorySubComponent = ({ category, active = false, uppercaseCategory = false }) => {
  return (
    <Text
      className={cs('category-tags__category', {
        'category-tags__category--active': active,
        'category-tags__category--uppercase': uppercaseCategory,
      })}
    >
      {category}
    </Text>
  );
};

const Values: ValuesSubComponent = ({ children }) => {
  return <Box className="category-tags__values">{children}</Box>;
};

const Wrapper: WrapperSubComponent = ({ children, theme, className, onClick, dataCy }) => {
  return (
    <Flex
      css={styles}
      className={cs('category-tags', `category-tags--${theme}`, className)}
      dataCy={dataCy}
      onClick={onClick}
    >
      {children}
    </Flex>
  );
};

CategoryTags.Category = Category;
CategoryTags.Values = Values;
CategoryTags.Wrapper = Wrapper;

export default CategoryTags;

import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';

import { FacebookAdCreation } from 'features/accountPlatforms/facebook';
import { ConnectInstagram } from 'features/accountPlatforms/facebook/components/ConnectInstagramForm';
import FacebookRequirements from 'features/accountPlatforms/facebook/Requirements/Requirements';
import AccountPlatformHome from 'features/accountPlatforms/pages/AccountPlatformHome';
import { FacebookCheckout } from 'features/accountPlatforms/pages/FacebookCheckout';
import { useAccountPlatforms } from 'features/accountPlatforms/useAccountPlatforms';

import { AccountPlatformRouteParams } from 'shared/config/routes/brands/matchTypes';
import lazyWithRetry from 'shared/lib/lazyWithRetry';

const CheckoutComplete = lazyWithRetry(() => import('shared/components/molecules/CheckoutComplete'));

interface Props {}

const PlatformRoutes: React.FC<Props> = () => {
  const params = useParams<AccountPlatformRouteParams>();
  const accountPlatformId = +params.accountPlatformId;

  const { accountPlatform } = useAccountPlatforms({ accountPlatformId });
  const { hasRequiredFacebookAssets: hasFBAssets } = accountPlatform;
  const isFacebook = accountPlatform?.platform?.shortenedName === 'facebook';
  const hidePlatformhome = isFacebook && !hasFBAssets;

  return (
    <Routes>
      <Route index element={hidePlatformhome ? <FacebookRequirements /> : <AccountPlatformHome />} />
      <Route path="report">
        <Route index element={<AccountPlatformHome />} />
        <Route path=":reportId" element={<AccountPlatformHome />} />
      </Route>

      <Route path="create-ads" element={<FacebookAdCreation />} />
      <Route path="connect-instagram" element={<ConnectInstagram />} />
      <Route path="launch-ad">
        <Route index element={<FacebookCheckout />} />
        <Route path="confirmation" element={<CheckoutComplete />} />
      </Route>
      <Route path="requirements" element={<FacebookRequirements />} />
      <Route path="*" element={<Navigate to="/not-found" />} />
    </Routes>
  );
};

export default PlatformRoutes;

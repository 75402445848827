import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import { fontSizes, fontWeights } from 'shared/styles/text';

export default css({
  position: 'relative',
  '.work-info-form': {
    '&__form--active': {
      [mediaquery.md]: {
        paddingLeft: '8px',
      },
    },
    '&__subheader': {
      color: COLORS.NEUTRAL500,
      textTransform: 'none',
      marginTop: '-5px',
    },
    '&__label-optional-wrapper': {
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    '&__optional': {
      color: COLORS.NEUTRAL500,
      marginBottom: 0,
      fontSize: '1rem',
    },
    '&__btn-wrapper': {
      justifyContent: 'flex-end',
      marginTop: '20px',
      gap: '10px',
    },
    '&__cancel-btn': {
      width: '100px',
      cursor: 'pointer',
      backgroundColor: COLORS.NEUTRAL200,
      color: COLORS.NEUTRAL700,
      '&:hover': {
        opacity: 0.8,
      },
    },
    '&__label': {
      margin: 0,
    },
    '&__btn': {
      marginBottom: '15px',
      color: COLORS.BLACK,
      padding: '8px 0',
      backgroundColor: COLORS.TRANSPARENT,
      border: 'none',
      borderRadius: 0,
      cursor: 'pointer',
      width: '100%',
      fontSize: fontSizes[2],
      fontWeight: fontWeights.bold,
      letterSpacing: 0,
      lineHeight: 1.8,
      px: [5],
      py: [1],
      textAlign: 'left',
      userSelect: 'none',
      [mediaquery.md]: {
        padding: '8px',
      },
    },
    '&__btn--active': {
      backgroundColor: COLORS.NEUTRAL50,
      color: COLORS.BLACK,
    },
    '&__btn:hover': {
      boxShadow: 'none',
      backgroundColor: COLORS.NEUTRAL50,
      borderRadius: '6px',
    },
    '&__btn-content': {
      alignItems: 'center',
      position: 'relative',
    },
    '&__btn-text-wrapper': {
      flexDirection: 'column',
    },
    '&__btn-name': {
      margin: 0,
      textTransform: 'uppercase',
    },
    '&__btn-value': { margin: 0, fontWeight: 500 },
    '&__right-icon': {
      position: 'absolute',
      right: '0',
    },
    '&__form-header-wrapper': {
      position: 'relative',
      alignItems: 'center',
      marginBottom: '20px',
      justifyContent: 'center',
    },
    '&__form-header': {
      margin: '0',
    },
    '&__back-btn': {
      position: 'absolute',
      left: '0',
      padding: '8px',
      backgroundColor: COLORS.NEUTRAL100,
      cursor: 'pointer',
      borderRadius: '5px',
    },
  },
  '.date-input__input': {
    width: '250px',
    input: {
      width: '100%',
    },
    '& .date-input__day-picker ': {
      right: '0',
      left: 'unset!important', //override DateInput styles
    },
    '& .btn-input__option': {
      textTransform: 'capitalize',
    },
  },
});

import React from 'react';
import cs from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAwardSimple } from '@fortawesome/pro-solid-svg-icons';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

import COLORS from 'shared/styles/colors';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Flex } from 'shared/components/display';
import styles from './styles';

interface Props {
  className?: string;
  size?: SizeProp;
  showTooltip?: boolean;
  showHoverBox?: boolean;
}
const EliteCreatorBadge: React.FC<Props> = ({ className, size = 'lg', showTooltip = false, showHoverBox = false }) => {
  return (
    <Tooltip content="Elite Creator" disabled={!showTooltip}>
      <Flex
        css={styles}
        className={cs('elite-creator-badge', {
          'elite-creator-badge--hover': showHoverBox,
        })}
      >
        <FontAwesomeIcon icon={faAwardSimple} size={size} className={className} color={COLORS.SECONDARY500} />
      </Flex>
    </Tooltip>
  );
};

export default EliteCreatorBadge;

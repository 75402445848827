import React from 'react';
import cs from 'classnames';

import CreatorSubmissionActions from 'features/submissions/components/atoms/CreatorSubmissionActions';
import creatorSubmissionHeaderStyles from 'features/submissions/components/CreatorSubmissionHeader/creatorSubmissionHeaderStyles';
import { Box, Flex } from 'shared/components/display';

import { BRIEF_COPY } from 'shared/config/copy';
import { Brief } from 'shared/typings/briefs';

interface Props {
  brief?: Pick<Brief, 'id' | 'type' | 'status' | 'briefCreatorStatus' | 'name'> | null;
  submissionId?: number;
  isDraft?: boolean;
}

const CreatorSubmissionHeader: React.FC<Props> = ({ submissionId, isDraft, brief }) => {
  return (
    <Flex css={creatorSubmissionHeaderStyles} className="submission-header">
      <Box as="h4" className={cs('submission-header__name', 'endWithEllipsis')}>
        {brief?.name || BRIEF_COPY.PLACEHOLDER_NONE_SELECTED_BRIEF}
      </Box>
      {brief ? <CreatorSubmissionActions brief={brief} submissionId={submissionId} isDraft={isDraft} /> : <></>}
    </Flex>
  );
};
export default CreatorSubmissionHeader;

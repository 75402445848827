import React from 'react';
import { useMediaQuery } from 'react-responsive';
import LazyTooltip from 'shared/components/interactive/LazyTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { breakpoints } from 'shared/styles/styleFunctions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'rebass/styled-components';
import { BUTTON_VARIANTS } from 'shared/styles/button';

interface Props {
  children: JSX.Element | JSX.Element[];
  icon: IconProp;
  iconAlias: string;
  showDropdownIndicator?: boolean;
}
const ToolbarPopOutMenu: React.FC<Props> = ({ children, icon, iconAlias, showDropdownIndicator = false }) => {
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });

  return isMobile ? (
    <LazyTooltip
      content={children}
      allowHTML
      theme="light-border"
      trigger="click"
      interactive
      placement="bottom-start"
      offset={[-20, 10]}
    >
      <Button variant={BUTTON_VARIANTS.TEXT_ONLY} type="button" className="richtext-tool-bar__subgroup">
        <FontAwesomeIcon aria-label={iconAlias} icon={icon} className="richtext-tool-bar__icon" />
        {showDropdownIndicator && <FontAwesomeIcon icon={faCaretDown} className="richtext-tool-bar__subgroup-caret" />}
      </Button>
    </LazyTooltip>
  ) : (
    <>{children}</>
  );
};

export default ToolbarPopOutMenu;

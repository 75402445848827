import React from 'react';
import cs from 'classnames';

import organizationOnboardingConfirmationStyles from 'features/organizations/components/organisms/OrganizationOnboardingConfirmation/organizationOnboardingConfirmationStyles';
import { BRAND_ADVERTISING_FIELD_NAMES } from 'features/organizations/components/organisms/OrgOnboardingStepFields';
import { ORGANIZATION_ONBOARDING_STEPS } from 'features/organizations/components/organisms/OrgOnboardingStepFields/types';
import { Flex } from 'shared/components/display';
import { MultiStepConfirmationProps } from 'shared/components/forms/MultiStepForm/interfaces';
import { DefinitionList, ListDetail } from 'shared/components/molecules/DefinitionList';
import StreetAddress from 'shared/components/molecules/StreetAddress';
import { ADDRESS_FIELDNAMES } from 'shared/components/organisms/AddressFormFields/constants';
import CheckoutSummary from 'shared/components/organisms/CheckoutSummary';

import { ORGANIZATION_COPY } from 'shared/config/copy';
import { Address } from 'shared/typings/address';

const OrgOnboardingConfirmation: React.FC<MultiStepConfirmationProps> = ({ values = {}, onConfirm, loading }) => {
  const orgDetails = values[ORGANIZATION_ONBOARDING_STEPS.DETAILS];
  const paymentMethod = values[ORGANIZATION_ONBOARDING_STEPS.BILLING];
  const billingAddress = values[ORGANIZATION_ONBOARDING_STEPS.ADDRESS];
  const brandDetails = values[ORGANIZATION_ONBOARDING_STEPS.BRAND_ADVERTISING];
  const hideSlogan = !brandDetails[BRAND_ADVERTISING_FIELD_NAMES.BRAND_SLOGAN];

  return (
    <Flex className={cs('organization-onboarding-confirmation')} css={organizationOnboardingConfirmationStyles}>
      <DefinitionList className="organization-onboarding-confirmation__details">
        <ListDetail text={ORGANIZATION_COPY.FORM_SUMMARY_ORGANIZATION_NAME}>{orgDetails.name}</ListDetail>
        <ListDetail text={ORGANIZATION_COPY.FORM_SUMMARY_ORGANIZATION_WEBSITE}>{orgDetails.domain}</ListDetail>
        <ListDetail text={ORGANIZATION_COPY.FORM_SUMMARY_BILLING_INFO}>{paymentMethod.creditCard.card.name}</ListDetail>
        <ListDetail text={ORGANIZATION_COPY.FORM_SUMMARY_BILLING_ADDRESS}>
          <StreetAddress address={billingAddress as Address} />
        </ListDetail>
        <ListDetail
          text={ORGANIZATION_COPY.FORM_SUMMARY_COMPANY_NAME}
          hide={!billingAddress[ADDRESS_FIELDNAMES.COMPANY_NAME]}
        >
          {billingAddress[ADDRESS_FIELDNAMES.COMPANY_NAME]}
        </ListDetail>
        <ListDetail text={ORGANIZATION_COPY.FORM_SUMMARY_BRAND_NAME}>
          {brandDetails[BRAND_ADVERTISING_FIELD_NAMES.BRAND_NAME]}
        </ListDetail>
        <ListDetail text={ORGANIZATION_COPY.FORM_SUMMARY_BRAND_WEBSITE}>
          {brandDetails[BRAND_ADVERTISING_FIELD_NAMES.BRAND_WEBSITE]}
        </ListDetail>
        <ListDetail text={ORGANIZATION_COPY.FORM_SUMMARY_BRAND_SLOGAN} hide={hideSlogan}>
          {brandDetails[BRAND_ADVERTISING_FIELD_NAMES.BRAND_SLOGAN]}
        </ListDetail>
      </DefinitionList>
      <CheckoutSummary
        heading="Payment Summary"
        // @ts-ignore
        onSubmit={onConfirm}
        total={0}
        onSubmitText={ORGANIZATION_COPY.BUTTON_ONBOARD_CHECKOUT}
        className="organization-onboarding-confirmation__checkout"
        submitInProcess={loading}
      />
    </Flex>
  );
};

export default OrgOnboardingConfirmation;

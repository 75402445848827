import { css } from '@emotion/react';

export default css({
  '.address-form-fields': {
    '&__split-fields': {
      display: 'flex',
      gap: '16px',
    },
    '&__city-input': {
      flex: 1,
    },
    '&__state-select': {
      flex: 1,
    },
    '&__postal-code-input': {
      flex: 1,
    },
    '&__country-select': {
      flex: 1,
    },
  },
});

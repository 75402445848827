import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '.invite-creators': {
    '&__auto-approve': {
      marginBottom: theme.space[3],
    },
    '&__btn-wrapper': {
      gap: theme.space[4],
      padding: theme.space[3],
      borderTop: `1px solid ${COLORS.NEUTRAL200}`,
      alignItems: 'center',
      justifyContent: 'flex-end',
      margin: `0 -${theme.space[5]}`,
    },
  },
});

import { DUPLICATE, STATUS } from 'shared/config/performance';

type Level = 'account' | 'campaign' | 'adset' | 'ad';

class ColumnFormatter {
  private static ADSET_BLOCKLIST = Object.freeze(['ad_id', 'ad_name', DUPLICATE]);
  private static CAMPAIGN_BLOCKLIST = Object.freeze(['adset_id', 'adset_name', ...this.ADSET_BLOCKLIST]);
  private static ACCOUNT_BLOCKLIST = Object.freeze(['campaign_id', 'campaign_name', ...this.CAMPAIGN_BLOCKLIST]);

  private statusIndex = 0;
  constructor(private columns: string[]) {
    this.columns = [...columns];
    this.statusIndex = columns.findIndex((col) => col === STATUS);
  }

  formatColumns(level: Level) {
    this.removeLowLevelFields(level);
    if (level !== 'account') this.insertLevelSpecificColumns(level);
    return this.columns;
  }

  removeLowLevelFields(level: Level) {
    const lowLevelFields = this.getLowLevelFields(level);
    this.columns = this.columns.filter((column) => !lowLevelFields.includes(column));
  }

  private getLowLevelFields(level: Level): readonly string[] {
    switch (level) {
      case 'account':
        return ColumnFormatter.ACCOUNT_BLOCKLIST;
      case 'campaign':
        return ColumnFormatter.CAMPAIGN_BLOCKLIST;
      case 'adset':
        return ColumnFormatter.ADSET_BLOCKLIST;
      default:
        return [];
    }
  }

  insertLevelSpecificColumns(level) {
    const columns = [`${level}_name`, ...this.getHighLevelFields(level)];
    const newColumns = columns.filter((column) => !this.columns.includes(column));
    if (newColumns.length) this.columns.splice(this.statusIndex + 1, 0, ...newColumns);
    if(level === 'ad') this.columns.unshift(DUPLICATE)
  }

  private static ADSET_ALLOWLIST = Object.freeze(['campaign_name']);
  private static AD_ALLOWLIST = Object.freeze(['adset_name', ...this.ADSET_ALLOWLIST]);
  private getHighLevelFields(level: Level): readonly string[] {
    switch (level) {
      case 'adset':
        return ColumnFormatter.ADSET_ALLOWLIST;
      case 'ad':
        return ColumnFormatter.AD_ALLOWLIST;
      default:
        return [];
    }
  }
}

export default class LevelUtility {
  static formatColumns({ columns, level }) {
    const formatter = new ColumnFormatter(columns);
    return formatter.formatColumns(level);
  }
}

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default (backgroundImage: string, width?: string, height?: string) =>
  css({
    '.single-image': {
      '&__img': {
        backgroundColor: COLORS.NEUTRAL200,
        backgroundImage: `url("${backgroundImage}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width,
        height,
      },
    },
  });

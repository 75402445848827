import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.trend-copy-link': {
    borderRadius: '3px',
    border: `1px solid ${COLORS.NEUTRAL300}`,
    width: '30px',
    height: '30px',
    alignItems: 'center',
    marginLeft: theme.space[3],

    '& .link-button:focus::after': {
      animation: 'none',
    },
    '& .link-button': {
      marginLeft: '0.35rem',

      '& svg': {
        color: COLORS.NEUTRAL300,
      },
    },
  },
});

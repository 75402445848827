import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';
import COLORS from 'shared/styles/colors';

import { RIGHT_DRAWER_FULL_WIDTH } from 'shared/config/formatting';
import { zIndex } from 'shared/config';

export default css({
  '.right-drawer': {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: zIndex.zModal,
    width: '100%',
    display: 'flex',
    backgroundColor: 'white',
    flexDirection: 'column',
    overflow: 'hidden',
    [mediaquery.md]: {
      width: 0,
      top: 'initial',
      left: 'initial',
      transform: 'initial',
      height: 'initial',
      position: 'relative',
      boxShadow: `-1px 0 5px 0px ${COLORS.NEUTRAL100}`,
      transition: 'width 350ms ease',
    },
    '&__wrapper': {
      width: 0,
      transition: 'width 350ms ease',
      '&--open': {
        [mediaquery.md]: {
          width: RIGHT_DRAWER_FULL_WIDTH,
        },
      },
    },
    '&--open': {
      borderRadius: '4px',
      maxHeight: '90%',
      maxWidth: '90%',
      height: 'auto',
      [mediaquery.md]: {
        borderRadius: 'initial',
        maxHeight: 'initial',
        maxWidth: 'initial',
        width: RIGHT_DRAWER_FULL_WIDTH,
      },
    },
    '&--closed': {
      height: 0,
    },
    '&__header': {
      justifyContent: 'space-between',
      paddingLeft: '12px',
      backgroundColor: 'white',
    },
    '&__close-btn': {
      marginLeft: 'auto',
    },
    '&__content': {
      padding: '8px 12px',
      wordWrap: 'break-word',
      overflowY: 'auto',
    },
    '&__header--open, &__content--open': {
      visibility: 'visible',
      [mediaquery.md]: {
        transitionDelay: '350ms',
      },
    },
    '&__header--closed, &__content--closed': {
      visibility: 'hidden',
      transitionDelay: 0,
    },
    '&__header-text': {
      userSelect: 'none',
      flex: 1,
      paddingTop: '4px',
      overflowWrap: 'break-word',
    },
    '&__overlay': {
      position: 'fixed',
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.5)',
      top: 0,
      left: 0,
      zIndex: zIndex.zOverlay,
      [mediaquery.md]: {
        display: 'none',
      },
    },
  },
});

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import cs from 'classnames';
import { isEqual } from 'lodash';

import ReportPanel from 'features/accounts/components/molecules/ReportPanel';
import { useReport } from 'features/accounts/reports/useReport';
import AttributionWindowDropdown from 'features/performance/AttributionWindowDropdown';
import BreakdownsSelector from 'features/performance/BreakdownsSelector';
import ColumnsSelectorDropdown from 'features/performance/ColumnsSelectorDropdown';
import PerformanceTable from 'features/performance/components/organisms/PerformanceTable';
import DateRangeSetter from 'features/performance/DateRangeSetter';
import FilterFormDropdown from 'features/performance/FilterFormDropdown';
import LevelSelector from 'features/performance/LevelSelector/LevelSelector';
import { performanceFieldsLoaded } from 'features/performance/performance.slice';
import TopBottomFormDropdown from 'features/performance/TopBottomFormDropdown';
import { Box, Flex } from 'shared/components/display';

import { apiAction } from 'shared/actions/api';
import { DEFAULT_FACEBOOK_COLUMNS_CHOSEN, DEFAULT_PARAMS } from 'shared/config/performance';
import { API_PERFORMANCE_ROUTES } from 'shared/config/routes/api/apiPerformanceRoutes';
import { PerformanceParams } from 'shared/contexts/performance/types';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { usePerformance } from 'shared/hooks/usePerformance';
import { AccountPlatform } from 'shared/typings/accountPlatform';
import { PlatformShortNames } from 'shared/typings/accountPlatform/enums';
import { formatRequestParams } from 'shared/utilities/Performance/dashboardUtility';

import { DuplicateDropdown } from '../DuplicateDropdown';

import performanceDashboardStyles from './performanceDashboardStyles';

interface Props {
  reportId: number;
  accountId: number;
  accountPlatform: AccountPlatform;
}

const PerformanceDashboard: React.FC<Props> = ({
  reportId,
  accountId,
  accountPlatform: { accessId: adAccountId, id: accountPlatformId, platform, status },
}) => {
  const {
    performanceState: { level, params, committedParams, columnsChosen },
    commitAndFetchPerformanceData,
    setDashboardFieldsFromReport,
    updateColumnsChosen,
    resetPerformanceData,
    updatePage,
    updatePageSize,
    adCopyIds,
  } = usePerformance();
  const { isSuperadmin } = useCurrentUser();
  const { report } = useReport({ reportId });
  const dispatch = useDispatch();

  const [isChanged, setIsChanged] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (accountPlatformId) {
      dispatch(
        apiAction({
          path: {
            route: API_PERFORMANCE_ROUTES.FIELDS,
          },
          method: 'GET',
          params: {
            level,
            accountPlatformId,
            platformName: platform.shortenedName,
          },
          successAction: performanceFieldsLoaded,
        }),
      );
    }
    return () => {
      resetPerformanceData();
    };
  }, [accountPlatformId, platform, level]);

  // Requests Platform Data on Initial Load and Ad Account change ONLY!
  useEffect(() => {
    if (platform.shortenedName) {
      const requestParams: PerformanceParams = {
        ...DEFAULT_PARAMS,
        accountPlatformId,
        platforms: [platform.shortenedName],
        client: adAccountId,
        includeEmptyEntities: true,
      };
      requestParams.fields = DEFAULT_FACEBOOK_COLUMNS_CHOSEN.toString();
      updateColumnsChosen(DEFAULT_FACEBOOK_COLUMNS_CHOSEN);
      commitAndFetchPerformanceData(requestParams);
      setIsChanged(false);
      updatePage();
      updatePageSize();
    }
  }, [accountPlatformId, adAccountId, accountPlatformId, platform.shortenedName]);

  useEffect(() => {
    if (report) {
      setDashboardFieldsFromReport(report);
      commitAndFetchPerformanceData({
        ...formatRequestParams(report.params),
        accountPlatformId,
        platforms: [platform.shortenedName],
        client: adAccountId,
        includeEmptyEntities: true,
      });
    }
  }, [report, platform.shortenedName, accountPlatformId, adAccountId]);

  useEffect(() => {
    if (!isEqual(params, committedParams) && !initialLoad) {
      setIsChanged(true);
    }
    if (initialLoad) setInitialLoad(false);
  }, [params, committedParams]);

  const submitDataRequest = ({ limit }) => {
    const requestParams: PerformanceParams = {
      accountPlatformId,
      platforms: platform.shortenedName ? [platform.shortenedName] : undefined,
      client: adAccountId,
      ...params,
    };
    if (limit) requestParams.limit = limit;
    commitAndFetchPerformanceData(requestParams);
    setIsChanged(false);
  };

  return (
    <Box className="performance-dashboard" css={performanceDashboardStyles}>
      <Flex className={cs('performance-dashboard__row', 'performance-dashboard__first-row')}>
        <LevelSelector platformName={platform.shortenedName || ''} showAdLevel={isSuperadmin} />
        <DateRangeSetter />
      </Flex>
      <Flex
        className={cs(
          'performance-dashboard__row',
          { 'performance-dashboard__second-row-duplicate': Boolean(adCopyIds.length) },
          { 'performance-dashboard__second-row': !Boolean(adCopyIds.length) },
        )}
      >
        {adCopyIds.length && platform.shortenedName === PlatformShortNames.FACEBOOK ? (
          <DuplicateDropdown accountPlatformId={accountPlatformId} />
        ) : (
          ''
        )}
        <ReportPanel accountId={accountId} reportId={reportId} accountPlatformId={accountPlatformId} />
        <TopBottomFormDropdown />
        {platform.shortenedName !== PlatformShortNames.TIKTOK && <AttributionWindowDropdown />}
        <ColumnsSelectorDropdown />
        {platform.shortenedName !== PlatformShortNames.TIKTOK && <BreakdownsSelector />}
      </Flex>
      <Flex className={cs('performance-dashboard__row', 'performance-dashboard__third-row')}>
        <FilterFormDropdown name={platform.shortenedName} />
      </Flex>
      <PerformanceTable
        // @ts-ignore
        submitDataRequest={submitDataRequest}
        hasConfigChanged={isChanged}
        activeAccountPlatform={!!status}
      />
    </Box>
  );
};

export default PerformanceDashboard;

export const ORGANIZATION_USER_COPY = {
  // Buttons
  BUTTON_INVITE: 'Invite User',
  BUTTON_REASSIGN_ROLES: 'Reassign Roles',
  BUTTON_MANAGE_ACCESS: 'Manage Account Access',
  BUTTON_RESEND_INVITATION: 'Resend Invitation',

  // Columns
  COLUMN_USER: 'Name',
  COLUMN_EMAIL: 'Email',
  COLUMN_ROLE: 'Role Name',
  COLUMN_ROLE_STATUS: 'Status',

  // Headings
  HEADING_ORG_USERS: `Users`,

  // Placeholders
  PLACEHOLDER_ROLE: 'Role',
  // Warnings
  WARNING_ALREADY_INVITED: '{email} has already been invited to the organization',
  WARNING_CREATOR_EMAIL: '{email} is already associated with a creator account',

  FORM_HEADING_INVITE_USER: 'Invite a User',

  // Validations
  VALIDATION_SELECT_ONE_BRAND: 'Please select at least one brand',
  VALIDATION_ORG_USER_EMAIL: 'Please remove invalid email addresses',
  VALIDATION_INVALID_INVITEES: `Cannot invite existing users or invitees{emailCount, plural, 
    =0 {.}
    other {: {emails}}
  }`,

  PROMPT_CONFIRM_RESEND_INVITE: 'Would you like to resend the invitation?',
};

import React from 'react';
import { components, NoticeProps } from 'react-select';
import { GeoLocationSuggestion } from 'shared/hooks/useGeoLocationHook';

const NoOptionsMessage: React.FC<NoticeProps<GeoLocationSuggestion, false>> = (props) => {
  return (
    <components.NoOptionsMessage
      {...props}
      innerProps={{
        ...props.innerProps,
        // @ts-ignore
        'data-cy': 'address-search__no-options-message',
      }}
    />
  );
};

export default NoOptionsMessage;

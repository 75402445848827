import React, { useMemo } from 'react';
import { faSearch, faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { debounce } from 'lodash';

import { Flex, Text } from 'shared/components/display';
import TextInput from 'shared/components/TextInput';

import { COLLECTION_COPY } from 'shared/config/copy';

import styles from './styles';

export interface CollectionSearchState {
  search: string;
}

interface Props {
  onSubmit: (values: CollectionSearchState) => void;
  onSearchChange?: (value: string) => void;
  loading?: boolean;
  state: CollectionSearchState;
}
const CollectionSearch: React.FC<Props> = ({ onSubmit, onSearchChange, loading = false, state }) => {
  const initialValues: CollectionSearchState = useMemo(() => {
    return {
      search: state.search || '',
    };
  }, [state.search]);

  const handleSubmit = (values, helpers: FormikHelpers<CollectionSearchState>) => {
    onSubmit(values);
    helpers.setSubmitting(false);
  };

  const onChangeText = debounce(
    (event: React.ChangeEvent<HTMLInputElement>, submitForm: (() => Promise<void>) & (() => Promise<any>)) => {
      if (onSearchChange) onSearchChange(event.target.value);
      submitForm();
    },
    500,
  );

  return (
    <Formik<CollectionSearchState> initialValues={initialValues} onSubmit={handleSubmit} validateOnBlur={false}>
      {({ submitForm }) => (
        <Flex css={styles} className="collection-search">
          <Form className="collection-search__form">
            <Flex className="collection-search__search-bar">
              <Text className="collection-search__icon">
                <FontAwesomeIcon icon={faSearch} />
              </Text>
              <Field
                name={'search'}
                className="collection-search__search-input"
                component={TextInput}
                placeholder={COLLECTION_COPY.PLACEHOLDER_SEARCH_ASSETS}
                onChange={(e) => onChangeText(e, submitForm)}
              />
              {loading && <FontAwesomeIcon icon={faSpinner} className={cs('collection-search__spinner', 'fa-spin')} />}
            </Flex>
            {/* So that enter key will submit form */}
            <input
              type="submit"
              style={{
                display: 'none',
              }}
            />
          </Form>
        </Flex>
      )}
    </Formik>
  );
};

export default CollectionSearch;

// TO DO STYLES: change back to fontawesome
import React from 'react';
import { Box } from 'shared/components/display';
import getStyles from './styles';
import icons from './icons.svg';
import cs from 'classnames';

const iconLibrary = {
  'graph-chart': { width: '1em', height: '1em' },
  camera: { width: '1em', height: '1em' },
  popper: { width: '36px', height: '36px' },
};

interface Props {
  icon: keyof typeof iconLibrary;
}

/**
 *
 * @description For displaying svg icons that FontAwesome doesn't provide
 *
 */
const Icon: React.FC<Props> = ({ icon }) => {
  const { width, height } = iconLibrary[icon];

  return (
    <Box as="svg" css={getStyles(width, height)} className={cs(`icon__${icon}`, 'icon')}>
      <use xlinkHref={`${icons}#icon-${icon}`} />
    </Box>
  );
};

export default Icon;

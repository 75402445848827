import React from 'react';

import {
  creatorFilterOptions,
  creatorSortOptions,
  marketerFilterOptions,
  marketerSortOptions,
} from 'features/briefs/BriefIndexHeader/config';
import NewBriefButton from 'features/briefs/NewBriefButton/NewBriefButton';
import { Flex } from 'shared/components/display';
import PaginationControl from 'shared/components/organisms/PaginationControl';

import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { usePermissions } from 'shared/hooks/usePermissions';

import styles from './styles';

interface Props {
  organizationId: number;
  accountId: number;
  searchParams: any;
  isMobile?: boolean;
}

const BriefIndexHeader: React.FC<Props> = ({ organizationId, accountId, searchParams, isMobile }) => {
  const isMarketer = Boolean(organizationId);
  const { isAuthorized } = usePermissions();

  const authorizedToCreate = isAuthorized(organizationId, PERMISSIONS_MAP.CREATE_EDIT_BRIEF_DRAFT.API);

  return (
    <Flex css={styles} className={isMarketer ? 'brief-index-header' : 'brief-index-header__creator'}>
      {authorizedToCreate && <NewBriefButton organizationId={organizationId} accountId={accountId} />}
      <PaginationControl<string, number | string> // string values are used for creators' filtering options
        sortOptions={isMarketer ? marketerSortOptions : creatorSortOptions}
        sortWidth={isMobile ? '100%' : '200px'}
        filterOptions={isMarketer ? marketerFilterOptions : creatorFilterOptions}
        searchParams={searchParams}
        filterParamsName={isMarketer ? 'status' : 'briefCreatorStatus'}
        filterWidth={isMobile ? '100%' : '200px'}
      />
    </Flex>
  );
};

export default BriefIndexHeader;

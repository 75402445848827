import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'rebass/styled-components';

import CreatorFileOverlayIcons from 'features/media/components/molecules/CreatorFileOverlayIcons';
import { useCollections } from 'features/media/useCollections';
import { useFiles } from 'features/media/useFiles';
import { useSubmissionFileNotification } from 'features/submissions/hooks/useSubmissionFileNotification';
import { useSubmissions } from 'features/submissions/useSubmissions';
import { Box, Flex } from 'shared/components/display';
import CollectionExplorer from 'shared/components/molecules/CollectionExplorer';
import DragAndDropUpload from 'shared/components/molecules/DragAndDropUpload/DragAndDropUpload';
import FileExplorer from 'shared/components/molecules/FileExplorer';
import MediaUploadButton from 'shared/components/molecules/MediaUploadButton';
import Modal from 'shared/components/molecules/Modal';

import { FILE_COPY, GENERIC_COPY, SUBMISSION_COPY } from 'shared/config/copy';
import { SIZING } from 'shared/config/formatting';
import { SUBMISSION_MAX_FILES_PER_MODULE } from 'shared/config/validations';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useDeepEffect } from 'shared/hooks/useDeepEffect';
import { useQuery } from 'shared/hooks/useQuery';
import { useSearchParams } from 'shared/hooks/useSearchParams';
import { useToasts } from 'shared/hooks/useToasts';
import { intl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { ModuleFileType } from 'shared/typings/briefs';
import { BrkfstFile } from 'shared/typings/file';
import { formatAspectRatio } from 'shared/utilities/fileUtility';
import {
  CollectionValidator,
  MultiValidator,
  PlatformValidator,
  SubmissionValidator,
} from 'shared/utilities/validator';
import { getPlatformFormatsByFileType } from 'shared/utilities/validator/utils';

import { footerStyles, styles } from './styles';
import { copyFiles, formatUploadOperations, validateCopiedFiles, validateSelectedFiles } from './utils';

const { TEXT_ONLY } = BUTTON_VARIANTS;

interface Props {
  getSelectedFiles: (files: BrkfstFile[]) => void;
  getDirectlyUploadedFiles: (files: BrkfstFile[]) => void;
  fileType: ModuleFileType;
  isSubmission: boolean;
  minAssetDuration: number;
  maxAssetDuration: number;
  disabled: boolean;
  moduleNum: number;
  removedFileIds: number[];
  allSubmittedFiles: Pick<BrkfstFile, 'id' | 'name' | 'metadata' | 'submissionValidatorFields'>[];
  moduleFiles: Pick<BrkfstFile, 'id' | 'name' | 'metadata' | 'submissionValidatorFields'>[];
}

const SubmissionAssetUpload: React.FC<Props> = ({
  getSelectedFiles,
  getDirectlyUploadedFiles,
  fileType,
  minAssetDuration,
  maxAssetDuration,
  disabled,
  moduleNum,
  allSubmittedFiles,
  removedFileIds,
  moduleFiles,
}) => {
  const { getQuery } = useQuery();
  const query = getQuery();
  const { submissionId } = useParams<{ submissionId: string }>();
  const { submission } = useSubmissions({ submissionId: +submissionId });
  const { currentUser } = useCurrentUser();
  const { setErrorToast } = useToasts();

  const { collections, defaultCollection, getFilteredCollections } = useCollections();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    collectionFiles,
    selectedFiles,
    changeSelectedCollection,
    initiateMultipleFilesUpload,
    selectedCollectionId,
    selectFiles,
    filesUploading,
  } = useFiles();

  const collectionParams = useSearchParams(query, {
    collectionPage: 1,
    collectionSize: 10,
    orderBy: 'dateCreated',
    orderAsc: true,
    type: '',
    userCreated: currentUser.id,
  });

  const numFilesInModule = moduleFiles.length;
  const numSelectedFiles = selectedFiles.length;
  const maxNumAssetsReached = numFilesInModule >= SUBMISSION_MAX_FILES_PER_MODULE;
  const submittedNotRemovedFiles = allSubmittedFiles.filter(({ id }) => !removedFileIds.includes(id));

  const toggleModal = () => {
    selectFiles([]);
    setModalOpen((prev) => !prev);
  };

  const clearFileState = () => {
    selectFiles([]);
  };

  const onModalClose = () => {
    clearFileState();
    toggleModal();
  };

  const emitAspectRatioError = (file: BrkfstFile) => {
    setErrorToast({ message: `File with aspect ratio ${formatAspectRatio(file.metadata)} already exists` });
  };

  // Submits files selected from collection
  const submitSelected = async () => {
    const { validFiles: validSelected, invalidFiles: invalidSelected } = validateSelectedFiles(selectedFiles);
    if (invalidSelected.length) emitAspectRatioError(invalidSelected[0]);
    if (validSelected.length) {
      const filesCopied = await copyFiles({ files: selectedFiles, baseFileName, fileType });
      const { validFiles, invalidFiles } = validateCopiedFiles({
        filesUploading,
        filesSubmitted: submittedNotRemovedFiles,
        filesCopied,
      });
      if (invalidFiles.length) emitAspectRatioError(invalidFiles[0]);
      if (numFilesInModule + validFiles.length > SUBMISSION_MAX_FILES_PER_MODULE) {
        setErrorToast({
          message: SUBMISSION_COPY.VALIDATION_MODULE_MAX_FILES,
        });
      } else {
        // Add moduleNum to register into redux state
        getSelectedFiles(Object.values(validFiles.map((file) => ({ ...file, moduleNum: moduleNum.toString() }))));
      }
    }

    // close modal
    setModalOpen(false);

    clearFileState();
  };

  /**
   * Formats the prefix of the submission file name
   * @example <CreatorInitials><CreatorId>_B<briefId>_M<ModuleNumber>
   **/
  const baseFileName = useMemo(() => {
    const creatorInitials = `${currentUser.firstName[0]}${currentUser.lastName[0]}`;
    return `B${submission?.briefId}_${creatorInitials}${currentUser.id}_M${moduleNum}`;
  }, [currentUser.id, submission?.briefId, moduleNum, currentUser.firstName, currentUser.lastName]);

  const uploadFilesToCollection = useCallback(
    async (files) => {
      if (files.length) {
        const collectionId = selectedCollectionId || defaultCollection.id;
        if (!selectedCollectionId) changeSelectedCollection(collectionId);
        const assets = await formatUploadOperations({
          files,
          collectionId,
          validatorFields: { minAssetDuration, maxAssetDuration, moduleNum, fileType },
        });
        const collection = collections.find(({ id }) => id === collectionId) || defaultCollection;
        await initiateMultipleFilesUpload(
          assets,
          new MultiValidator(
            new CollectionValidator(collection.id, collectionFiles, collection.numFiles),
            new SubmissionValidator(minAssetDuration, maxAssetDuration, moduleNum, fileType),
            new PlatformValidator(fileType),
          ),
        );
      }
    },
    [
      selectedCollectionId,
      defaultCollection.id,
      collectionFiles,
      minAssetDuration,
      maxAssetDuration,
      collections,
      moduleNum,
      fileType,
      baseFileName,
      defaultCollection,
    ],
  );

  const uploadFilesToSubmission = useCallback(
    async (files) => {
      const totalFilesAfterUpload = numFilesInModule + (files.length || 1);
      if (files.length && totalFilesAfterUpload <= SUBMISSION_MAX_FILES_PER_MODULE) {
        const assets = await formatUploadOperations({
          files,
          fileName: baseFileName,
          submissionId: +submissionId,
          validatorFields: { minAssetDuration, maxAssetDuration, moduleNum, fileType },
        });
        await initiateMultipleFilesUpload(
          assets,
          new MultiValidator(
            new SubmissionValidator(minAssetDuration, maxAssetDuration, moduleNum, fileType, submittedNotRemovedFiles),
            new PlatformValidator(fileType),
          ),
        );
      } else if (totalFilesAfterUpload > SUBMISSION_MAX_FILES_PER_MODULE) {
        setErrorToast({
          message: SUBMISSION_COPY.VALIDATION_MODULE_MAX_FILES,
        });
      }
    },
    [
      submittedNotRemovedFiles,
      moduleNum,
      submissionId,
      minAssetDuration,
      maxAssetDuration,
      fileType,
      initiateMultipleFilesUpload,
      baseFileName,
    ],
  );

  const acceptedMedia = getPlatformFormatsByFileType(fileType);

  const onDirectUpload = (file: BrkfstFile) => {
    // Only for files uploaded to the specific module and are not already in a collection
    if (file.submissionValidatorFields?.moduleNum === moduleNum && (file.collectionIds?.length || 0) < 1) {
      getDirectlyUploadedFiles([file]);
    }
  };

  useSubmissionFileNotification({
    onFileUploaded: onDirectUpload,
  });

  useDeepEffect(() => {
    const { collectionSize, collectionPage, ...filter } = collectionParams;
    getFilteredCollections({
      ...filter,
      page: collectionPage,
      size: collectionSize,
    });
  }, [collectionParams]);

  const existingAspectRatio = useMemo(() => {
    const selectedFileAspectRatios = selectedFiles.map((file) => formatAspectRatio(file.metadata));
    const submittedFileAspectRatios = moduleFiles.map((file) => formatAspectRatio(file.metadata));
    return new Set([...selectedFileAspectRatios, ...submittedFileAspectRatios]);
  }, [selectedFiles, moduleFiles]);

  const formatGalleryItems = (asset) => {
    // submitted to this submission and not removed during the editing process before saving
    const submitted = Boolean(submittedNotRemovedFiles.find(({ id }) => id === asset.id));
    let selectDisabled = (asset.submitted || submitted) && !removedFileIds.includes(asset.id);
    let selectTooltip = submitted
      ? FILE_COPY.TOOLTIP_FILE_ALREADY_SUBMITTED
      : FILE_COPY.TOOLTIP_FILE_ALREADY_IN_ANOTHER_SUBMISSION;
    if (!selectDisabled && existingAspectRatio.has(formatAspectRatio(asset.metadata))) {
      const isSelected = selectedFiles.find((file) => file.id === asset.id);
      selectDisabled = isSelected ? false : true;
      selectTooltip = FILE_COPY.TOOLTIP_FILE_ASPECT_RATIO_ALREADY_USED;
    }
    return {
      itemObject: asset,
      OverlayLeft: <CreatorFileOverlayIcons file={asset} showTags showSubmitted={submitted} />,
      captionData: asset,
      selectDisabled,
      selectTooltip: selectDisabled ? selectTooltip : '',
    };
  };

  return (
    <Box className="submission-asset-upload" data-cy={`submission-asset-upload--module-${moduleNum}`}>
      <Box onClick={disabled || maxNumAssetsReached ? undefined : toggleModal}>
        <DragAndDropUpload
          onChange={uploadFilesToSubmission}
          supportedMedia={fileType}
          acceptedMedia={acceptedMedia}
          disabled={disabled || maxNumAssetsReached}
          text={maxNumAssetsReached ? SUBMISSION_COPY.SUBMISSION_ASSET_DISABLED_COPY : undefined}
          noClick
          multiple
        />
      </Box>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onRequestClose={onModalClose}
          title={intl.formatMessage(
            {
              id: 'HEADING_SELECT_ASSET',
            },
            {
              media: fileType,
            },
          )}
          component={
            <>
              {!selectedCollectionId ? (
                <CollectionExplorer
                  header={
                    <Flex css={styles} className="submission-asset-upload__upload-btn">
                      <MediaUploadButton
                        acceptedMedia={acceptedMedia}
                        buttonContent={'Select files from your device'}
                        multipleFiles
                        onFileUpload={(files) => {
                          toggleModal();
                          uploadFilesToSubmission(files);
                        }}
                      />
                    </Flex>
                  }
                  collectionParams={collectionParams}
                  isCreatorCollection
                />
              ) : (
                <FileExplorer
                  collectionId={selectedCollectionId}
                  uploadNewFiles={uploadFilesToCollection}
                  fileType={fileType}
                  acceptedMedia={acceptedMedia}
                  clearFileState={clearFileState}
                  formatGalleryItems={formatGalleryItems}
                />
              )}
            </>
          }
          footer={
            <Flex css={footerStyles} className="submission-asset-upload">
              <Button variant={TEXT_ONLY} onClick={onModalClose} className="submission-asset-upload__cancel-btn">
                {GENERIC_COPY.BUTTON_CANCEL}
              </Button>
              <Button
                data-cy="file-explorer__submit"
                onClick={submitSelected}
                className="submission-asset-upload__submit-btn"
                disabled={disabled}
              >
                {intl.formatMessage(
                  {
                    id: 'BUTTON_PICK_ASSET',
                  },
                  {
                    numFiles: numSelectedFiles,
                    media: fileType,
                  },
                )}
              </Button>
            </Flex>
          }
          modalSize={SIZING.LARGE}
        />
      )}
    </Box>
  );
};

export default SubmissionAssetUpload;

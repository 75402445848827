import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export const promptStyles = css({
  '&.audience-form-prompt': {
    padding: '0 1rem 1rem 1rem',
    flexDirection: 'column',
  },
  '.audience-form-prompt': {
    '&__modal-button': {
      marginTop: theme.space[3],
      width: '30%',
      alignSelf: 'flex-end',
    },
  },
});

export const audienceFormStyles = css({
  '&.audience-form': {
    width: '100%',
    [mediaquery.md]: {
      width: '30%',
    },
  },
  '.audience-form':{
    '&__submit-button': {
      marginTop: '.5rem',
    }
  },
});

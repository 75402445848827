// * Lib
import React from 'react';
import cs from 'classnames';

import Skeleton from 'shared/components/atoms/Skeleton';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
//* Display/UI
import { Box, Flex, Image, Text } from 'shared/components/display';

import { BrkfstAccount } from 'shared/typings/account';

// * Styles
import styles from './styles';

interface Props {
  loading?: boolean;
  account: BrkfstAccount;
}

const BannerLogo: React.FC<Props> = ({ loading, account }) => {
  const { name } = account;
  const url = account.banner?.url;

  if (loading) {
    return (
      <Box css={styles} className={'banner-logo'}>
        <Skeleton className={`banner-logo-skeleton`} />
      </Box>
    );
  }

  return url ? (
    <Flex css={styles} className={`banner-logo-placeholder`}>
      <Image
        src={url}
        alt={`${name} banner`}
        title={`${name} banner`}
        css={styles}
        className={`banner-logo-foreground`}
      />
    </Flex>
  ) : (
    <Flex css={styles} className={`banner-logo-placeholder`}>
      <Tooltip content={name}>
        <Text variant={'h4'} className={cs('endWithEllipsis', 'banner-logo-placeholder__text')}>
          {name}
        </Text>
      </Tooltip>
    </Flex>
  );
};

export default BannerLogo;

import * as yup from 'yup';

import { MESSAGES } from 'shared/config/validations';

export const FIELDNAMES = {
  ADDRESS_LINE_1: 'addressLine1',
  ADDRESS_LINE_2: 'addressLine2',
  COMPANY_NAME: 'companyName',
  CITY: 'city',
  STATE: 'state',
  POSTAL_CODE: 'postalCode',
  COUNTRY: 'country',
  PHONE_NUMBER: 'phoneNumber',
};

const addressSchema = {
  addressLine1: yup.string().customRequiredString().required(MESSAGES.REQUIRED_FIELD),
  addressLine2: yup.string().trim(),
  companyName: yup.string().trim(),
  city: yup.string().customRequiredString().required(MESSAGES.REQUIRED_FIELD),
  state: yup.object().when('country', {
    is: (country) => {
      return !Boolean(country?.value?.code) || ['US', 'CA'].includes(country?.value?.code);
    },
    then: yup
      .object({
        label: yup.string().required(MESSAGES.REQUIRED_FIELD),
        value: yup.object({
          id: yup.number().required(MESSAGES.REQUIRED_FIELD),
          name: yup.string().required(MESSAGES.REQUIRED_FIELD),
          code: yup.string().required(MESSAGES.REQUIRED_FIELD),
          countryId: yup.number().required(MESSAGES.REQUIRED_FIELD),
        }),
      })
      .required(MESSAGES.REQUIRED_FIELD),
    otherwise: yup.object({
      label: yup.string(),
      value: yup.object({
        id: yup.number(),
        name: yup.string(),
        code: yup.string(),
        countryId: yup.number(),
      }),
    }),
  }),
  postalCode: yup.string().customRequiredString().required(MESSAGES.REQUIRED_FIELD),
  country: yup.object({
    label: yup.string(),
    value: yup.object({
      id: yup.number().required(MESSAGES.REQUIRED_FIELD),
      name: yup.string(),
      code: yup.string(),
      code3: yup.string(),
    }),
  }),
  phoneNumber: yup.string().nullable(),
  countryCode: yup.string().nullable(),
  countryDialCode: yup.string().nullable(),
};

export const addressSchemaWithPhone = yup.object({
  ...addressSchema,
  phoneNumber: yup.string().customRequiredString().required(MESSAGES.REQUIRED_FIELD),
});

export const addressSchemaNoPhone = yup.object(addressSchema);

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

const getStyles = (withShowMore) =>
  css({
    '&.gallery': {
      '& .gallery-tile:hover .collection-file-elliptical': {
        visibility: 'visible',
      },
    },
    '.gallery': {
      '&__placeholder': {
        textAlign: 'center',
        color: COLORS.NEUTRAL600,
      },
      '&__no-items': {
        textAlign: 'center',
        marginBottom: '.5rem',
      },
      '&__view-all-btn': {
        background: COLORS.PRIMARY500,
        margin: '2px',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        border: `1px solid ${COLORS.PRIMARY400}`,
        borderRadius: '4px',
        '&:hover': {
          opacity: 0.9,
        },
        minWidth: '180px',
        [mediaquery.md]: {
          minWidth: 'auto',
        },
      },
      '&__view-all-text': {
        width: '30vw',
        color: COLORS.WHITE,
        lineHeight: '1.15',
        [mediaquery.md]: {
          width: '16vw',
          lineHeight: '1.75',
        },
      },
      '&__view-all-icon': {
        width: '1.8vw',
        height: '1.8vw',
        padding: '5px',
        marginBottom: '1.3vw',
        border: `1px solid ${COLORS.ORANGE300}`,
        color: COLORS.WHITE,
        borderRadius: '100%',
      },
    },
    '.react-photo-album': {
      overflowX: withShowMore ? 'scroll' : 'visible',
      paddingBottom: '10px',
    },
  });

export default getStyles;

import * as yup from 'yup';

export const reportSchema = yup.object({
  name: yup.string().customRequiredString(),
});

export interface ReportValues {
  name: string;
  interval: {
    label: string;
    value: string;
  };
  intervalValue: string;
}

import React from 'react';
import { useParams } from 'react-router-dom';

import { TRENDS_LIBRARY_COPY } from 'features/trends/pages/TrendsLibrary/copy';
import CopyLink from 'shared/components/atoms/CopyLink/CopyLink';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import { Flex } from 'shared/components/display';

import styles from './styles';

const { COPY_TEXT, COPIED_TEXT } = TRENDS_LIBRARY_COPY;

interface Props {
  trendId?: number;
  portalTarget?: string | HTMLElement | null;
}
const TrendCopyLink: React.FC<Props> = ({ trendId, portalTarget = 'body' }) => {
  const { organizationId, accountId } = useParams<{ organizationId: string; accountId: string }>();
  const [tooltip, setTooltip] = React.useState(COPY_TEXT);
  const searchParams = new URLSearchParams({
    orgId: organizationId,
    accountId: accountId,
    ...(trendId && { trendId: trendId.toString() }),
  });

  const onClick = () => setTooltip(COPIED_TEXT);
  const onMouseLeave = () => setTooltip(COPY_TEXT);

  return (
    <Tooltip portalTarget={portalTarget} content={tooltip} placement="top-start" onClick={onClick}>
      <Flex css={styles} className="trend-copy-link" onMouseLeave={onMouseLeave}>
        <CopyLink text="" link={`${window.location.origin}/trends?${searchParams.toString()}`} />
      </Flex>
    </Tooltip>
  );
};

export default TrendCopyLink;

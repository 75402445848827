import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.tagify-input': {
    marginTop: theme.space[4],
    padding: theme.space[1],
    border: `1px solid ${COLORS.NEUTRAL200}`,
    overflowX: 'hidden',
    '&--invalid': {
      border: 'solid 1px red',
    },
    '& .tagify__input::before, & .tagify__input[placeholder]': {
      // the placeholder
      display: 'inline-block',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      wordBreak: 'break-word',
      height: '100%',
      color: COLORS.NEUTRAL700,
    },
    '&--has-value .tagify__input::before': {
      // the placeholder
      display: 'none',
    },
    '&--editing-disabled .tagify__input': {
      display: 'none',
    },
  },
});

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.account-health-onboarding': {
    width: '98%',
    margin: 'auto',
    height: '100%',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
    display: 'flex',
    [mediaquery.md]: {
      padding: `${theme.space[4]} 0`,
    },
  },
  '.account-health-onboarding': {
    '&__title': {
      textAlign: 'center',
    },
    '&__logo': {
      width: '200px',
      position: 'absolute',
      top: '10px',
    },
    '&__form': {
      height: '50%',
      backgroundColor: ' white',
      width: '100%',
      [mediaquery.md]: {
        height: '50%',
      },
    },
    '&__form-content': {
      position: 'relative',
      flexDirection: 'column',
      height: '100%',
      alignItems: 'center',
      padding: '1rem',
      textAlign: 'center',
    },
    '&__message': {
      marginLeft: theme.space[2],
      marginTop: theme.space[3],
      color: COLORS.RED700,
    },
    '&__input': {
      flexDirection: 'column',
      marginLeft: theme.space[2],
      paddingTop: '1vw',
    },
    '&__ad-account-input': {
      flexDirection: 'column',
      marginLeft: theme.space[2],
      transform: 'scaleY(0)',
      transition: 'transform 0.5s cubic-bezier(0.5, 1.6, 0.4, 0.7)',
      margin: 0,
      display: 'none',
    },
    '&__ad-account-input--show': {
      transform: 'scaleY(1)',
      display: 'block',
      textAlign: 'left',
    },
    '&__input-loader': {
      position: 'relative',
      textAlign: 'left',
    },
    '&__submit-button': {
      height: 'auto',
      marginBottom: theme.space[3],
      marginTop: theme.space[3],
      alignItems: 'end',
      justifyContent: 'center',
      padding: '10px 0',
    },
    '&__loader': {
      position: 'absolute',
      right: '-35px',
      top: '21px',
    },
  },
});

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';

export default css({
  '&.soft-curtain': {
    justifyContent: 'center',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    '& > *:not(:last-child)': {
      paddingRight: '2rem',
    },
    '&::after': {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      content: '""',
      display: 'block',
      backgroundImage: 'linear-gradient(to left, white, white, #ffffff00)',
      width: '56px',
    },
  },
  '.soft-curtain': {
    '&__right-content': {
      position: 'absolute',
      right: 0,
      top: '2px',
    },
  },
});

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '.basic-account-settings': {
    '&__delete-btn': {
      padding: 0,
      marginTop: '42px',
      marginRight: 'auto',
      color: COLORS.RED700,
    },
  },
});

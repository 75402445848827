import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import jwtDecode from 'jwt-decode';

import AuthPageTemplate from 'features/auth/AuthPageTemplate/AuthPageTemplate';
import SignUpForm from 'features/auth/components/organisms/SignUpForm';

import { GLOBAL_COPY_VARIABLES } from 'shared/config/copy/allCopy';
import { AUTH_COPY } from 'shared/config/copy/authCopy';
import { useQuery } from 'shared/hooks/useQuery';
import { useQueryToast } from 'shared/hooks/useQueryToast';

const SignUpPage = () => {
  const [invitationEmail, setInvitationEmail] = useState('');

  const { formatMessage } = useIntl();
  const { getQuery } = useQuery();
  const urlParams = getQuery();
  const isInvited = Boolean(invitationEmail);
  const { queryToast } = useQueryToast();

  useEffect(() => {
    if (!invitationEmail && urlParams.token) {
      const { email } = jwtDecode<{ email: string }>(urlParams.token);
      setInvitationEmail(email);
    } else if (!invitationEmail && urlParams.email) {
      setInvitationEmail(urlParams.email);
    }
    queryToast(urlParams.error);
  }, [urlParams, invitationEmail]);

  return (
    <AuthPageTemplate
      welcomeHeading={isInvited ? AUTH_COPY.HEADING_INVITED_SIGN_UP_WELCOME : AUTH_COPY.HEADING_SIGN_UP_WELCOME}
      welcomeSubheading={
        isInvited
          ? AUTH_COPY.SUBHEADING_INVITED_SIGN_UP_WELCOME
          : formatMessage(
              {
                id: 'SUBHEADING_SIGN_UP_WELCOME',
              },
              { appName: GLOBAL_COPY_VARIABLES.APP_NAME },
            )
      }
      googleText={AUTH_COPY.BUTTON_GOOGLE_SIGN_UP}
      facebookText={AUTH_COPY.BUTTON_FACEBOOK_SIGN_UP}
      urlParams={urlParams}
    >
      <SignUpForm invitationEmail={invitationEmail} token={urlParams.token} userType={urlParams.type} />
    </AuthPageTemplate>
  );
};

export default SignUpPage;

import dataStudioReportStyles from 'features/accounts/components/atoms/DataStudioReport/dataStudioReportStyles';
import { Flex } from 'shared/components/display';
import React from 'react';

interface Props {
  dataStudioReportId: string;
}

const getReportUrl = (id: string) => `https://datastudio.google.com/embed/reporting/${id}`;

const DataStudioReport: React.FC<Props> = ({ dataStudioReportId }) => {
  return (
    <Flex css={dataStudioReportStyles} className="ds-report">
      <iframe
        className="ds-report__frame"
        width="100%"
        frameBorder="0"
        allowFullScreen
        src={getReportUrl(dataStudioReportId)}
      />
    </Flex>
  );
};

export default DataStudioReport;

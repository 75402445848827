export enum REVISION_CATEGORY {
  GUIDELINES = 'guidelines',
  END_CARD = 'endCard',
  VALUE_PROP = 'valueProp',
  LEGAL = 'legal',
  GRAMMAR = 'grammar',
  IMAGE = 'image',
  PRODUCT = 'product',
  SOUND_QUALITY = 'soundQuality',
}

export const REVISION_CATEGORY_DISPLAY = {
  [REVISION_CATEGORY.GUIDELINES]: 'Brand Guidelines',
  [REVISION_CATEGORY.END_CARD]: 'Wrong End Card',
  [REVISION_CATEGORY.VALUE_PROP]: 'Incorrect Value Prop',
  [REVISION_CATEGORY.LEGAL]: 'Legal Issue',
  [REVISION_CATEGORY.GRAMMAR]: 'Grammar Issue',
  [REVISION_CATEGORY.IMAGE]: 'Warped Image',
  [REVISION_CATEGORY.PRODUCT]: 'Obstructed Product',
  [REVISION_CATEGORY.SOUND_QUALITY]: 'Sound Quality',
};

import { CardElement } from '@stripe/react-stripe-js';
import {
  cardAdded,
  cardAddedAndSelected,
  cardChanged,
  cardRemoved,
  cardsLoaded,
  getCards,
  getSelectedCard,
  selectedChanged,
} from 'features/organizations/cards.slice';
import { componentLoadingToggled } from 'features/ui/ui.slice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiAction } from 'shared/actions/api';
import { RESOURCES } from 'shared/config/resourceNames';
import { API_ORG_ROUTES } from 'shared/config/routes/api/apiOrgRoutes';
import { CARD_TOASTS } from 'shared/config/toasts/cardToasts';
import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useToasts } from 'shared/hooks/useToasts';

const { CARD } = RESOURCES;

interface Config {
  organizationId?: number;
  shouldFetch?: boolean;
}

export const useCards = ({ organizationId, shouldFetch = false }: Config = {}) => {
  const cards = useSelector(getCards);
  const selectedCard = useSelector(getSelectedCard);
  const { loading } = useComponentLoading(CARD);
  const { setErrorToast } = useToasts();

  const dispatch = useDispatch();

  const fetchCards = () => {
    dispatch(
      apiAction({
        path: {
          route: API_ORG_ROUTES.CARDS,
          variables: {
            organizationId,
          },
        },
        successAction: cardsLoaded,
        entity: CARD,
      }),
    );
  };

  useEffect(() => {
    if (shouldFetch && organizationId) {
      fetchCards();
    }
  }, [organizationId, shouldFetch]);

  const setCardAsDefault = (id) => {
    dispatch(
      apiAction({
        path: {
          route: API_ORG_ROUTES.CARD,
          variables: {
            organizationId,
            cardId: id,
          },
        },
        method: 'PATCH',
        data: { id, default: true },
        successAction: cardChanged,
        successToast: {
          message: CARD_TOASTS.SET_AS_DEFAULT,
        },
      }),
    );
  };

  const deleteCard = (id) => {
    dispatch(
      apiAction({
        path: {
          route: API_ORG_ROUTES.CARD,
          variables: {
            organizationId,
            cardId: id,
          },
        },
        method: 'DELETE',
        successAction: cardRemoved,
        successToast: {
          message: CARD_TOASTS.CARD_DELETED,
        },
      }),
    );
  };

  const addCard = (creditCardData, stripe, elements, onSuccess) => {
    dispatch(componentLoadingToggled({ component: CARD, loading: true }));

    const cardElement = elements.getElement(CardElement);

    return stripe
      .createSource(cardElement, {
        type: 'card',
        owner: {
          name: creditCardData.name,
        },
      })
      .then((res) => {
        if (res.source) {
          const card = {
            organizationId,
            sourceId: res.source.id,
            expiration: `${res.source.card.exp_month}/${res.source.card.exp_year}`,
            default: creditCardData.default,
            name: `${res.source.card.brand} ending in ${res.source.card.last4}`,
          };

          dispatch(
            apiAction({
              path: {
                route: API_ORG_ROUTES.CARDS,
                variables: {
                  organizationId,
                },
              },
              method: 'POST',
              data: card,
              successAction: cardAdded,
              onSuccess,
              successToast: { message: CARD_TOASTS.CARD_ADDED },
            }),
          );
          dispatch(
            componentLoadingToggled({
              component: CARD,
              loading: false,
            }),
          );
        }
      })
      .catch((err) => {
        dispatch(
          componentLoadingToggled({
            component: CARD,
            loading: false,
          }),
        );
        setErrorToast({
          heading: CARD_TOASTS.ADD_ERROR_OCCURRED,
          message: err.message,
        });
      });
  };

  const selectCard = (card) => {
    dispatch(selectedChanged(card.id));
  };

  return {
    cards,
    loading,
    deleteCard,
    setCardAsDefault,
    addCard,
    selectCard,
    selectedCard,
  };
};

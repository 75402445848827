import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  flexDirection: 'column',
  width: '93.52%',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.WHITE,
  margin: 'auto',
  padding: '10px 12px 0 16px',
  boxShadow: theme.shadows.SHADOW03,
  borderRadius: '5px',
  '.brief-card': {
    '&__body-wrapper': {
      width: '100%',
      marginBottom: '12px',
    },
    '&__picture-wrapper': {
      marginRight: '14px',
      flex: 'none',
    },
    '&__texts-wrapper': {
      maxWidth: '60%',
      flex: 1,
      overflow: 'auto',
    },
    '&__brand-name': {
      color: theme.colors.NEUTRAL500,
      margin: 0,
    },
    '&__title': {
      margin: 0,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      marginTop: '-6px',
    },
    '&__status': {
      marginLeft: 'auto',
      justifyContent: 'center',
      flexBasis: '100px',
    },
    '&__tags-wrapper': {
      width: '100%',
      alignSelf: 'flex-start',
      marginBottom: '10px',
    },
    '&__tag': {
      position: 'relative',
      display: 'inline-flex',
      padding: '0 8px',
      height: '27px',
      borderRadius: '8px',
    },
  },
});

import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '.profile-notes': {
    '&__label': {
      margin: `${theme.space[3]} 0 0 0`,
    },

    '&__submit': {
      margin: `${theme.space[4]} 0 ${theme.space[4]} ${theme.space[4]}`,
    },
    '&__btn-wrapper': {
      justifyContent: 'end',
      alignItems: 'center',
    },
  },
});

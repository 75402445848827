import React from 'react';

import ContactInfoForm from 'features/users/components/organisms/ContactInfoForm';
import PaymentInfoForm from 'features/users/components/organisms/PaymentInfoForm/PaymentInfoForm';
import PortfolioForm from 'features/users/components/organisms/PortfolioForm/PortfolioForm';
import ProfileInfoForm from 'features/users/components/organisms/ProfileInfoForm/ProfileInfoForm';
import TextNotificationsForm from 'features/users/components/organisms/TextNotificationsForm/TextNotificationsForm';
import WorkInfoForm from 'features/users/components/organisms/WorkInfoForm/WorkInfoForm';

import { BrkfstUser } from 'shared/typings/user';

interface Props {
  activeForm: string;
  user: BrkfstUser;
  userId: number;
  onSubmit: (values: any) => void;
  tags: { [category: string]: { label: string; value: string }[] };
  createOrUpdatePhone: (values: any) => void;
  onNavigateBack?: () => void;
}

const FormContainer: React.FC<Props> = ({
  activeForm,
  user,
  userId,
  onSubmit,
  tags,
  createOrUpdatePhone,
  onNavigateBack,
}) => {
  const renderForm = (formName) => {
    switch (formName) {
      case 'Portfolio':
        return <PortfolioForm user={user} onSubmit={onSubmit} />;
      case 'Contact Info':
        return <ContactInfoForm userId={userId} />;
      case 'Profile Info':
        return <ProfileInfoForm user={user} tags={tags} onSubmit={onSubmit} onNavigateBack={onNavigateBack} />;
      case 'Work Info':
        return <WorkInfoForm user={user} tags={tags} onSubmit={onSubmit} onNavigateBack={onNavigateBack} />;
      case 'Payment':
        return <PaymentInfoForm user={user} onSubmit={onSubmit} />;
      case 'Text Notifications':
        return <TextNotificationsForm user={user} onSubmit={createOrUpdatePhone} />;
      default:
        return null;
    }
  };

  return renderForm(activeForm);
};

export default FormContainer;

import React, { useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@rollbar/react';
import ScrollToTop from 'app/ScrollToTop';
import RightDrawer from 'shared/components/layout/RightDrawer/RightDrawer';
import BrandNavBar from 'features/home/components/organisms/BrandNavBar';
import { ErrorPage } from 'features/ui/pages/ErrorPages';
import { Flex } from 'shared/components/display';

import RoutesWrapper from 'shared/router/RoutesWrapper';

import styles from './styles';

const BrandRoutesWrapper: React.FC = () => {
  const contentRef = useRef(null);
  const location = useLocation<{}>();

  return (
    <Flex css={styles} className="brand-routes-wrapper">
      <BrandNavBar />
      <ScrollToTop contentRef={contentRef} />
      {/* @ts-ignore */}
      <ErrorBoundary key={location.pathname} fallbackUI={ErrorPage}>
        <RoutesWrapper>
          <RoutesWrapper.ContentWrapper>
            <Outlet />
          </RoutesWrapper.ContentWrapper>
          <RightDrawer />
        </RoutesWrapper>
      </ErrorBoundary>
    </Flex>
  );
};

export default BrandRoutesWrapper;

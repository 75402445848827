import React, { useEffect } from 'react';
import { Button, Text } from 'rebass/styled-components';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from 'config';

import { FacebookConnectProps } from 'features/accountPlatforms/facebook/FacebookConnect/types';
import { Flex } from 'shared/components/display';

import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useQuery } from 'shared/hooks/useQuery';
import { useToasts } from 'shared/hooks/useToasts';
import { TEXT_VARIANTS } from 'shared/styles/text';

import styles from './styles';

export function openFbLoginDialog(redirectURI: string, userId: number, accountPlatformId?: number): void {
  const state = JSON.stringify({ redirectURI: encodeURIComponent(redirectURI), userId, accountPlatformId });
  const url = `https://www.facebook.com/${config.FB_APP_VERSION}/dialog/oauth?client_id=${config.FB_CLIENT_ID}&redirect_uri=${config.API_URL}${config.FB_REDIRECT_URI}&scope=${config.FB_CONNECTION_SCOPE}&state=${state}`;
  window.location.assign(url);
}

const FacebookConnect: React.FC<FacebookConnectProps> = ({
  facebookConnected,
  redirectURI,
  accountPlatformId,
  text = 'Connect with Facebook',
}) => {
  const { getQuery } = useQuery();
  const { fbError, businessId } = getQuery();
  const { currentUser } = useCurrentUser();
  const { setErrorToast } = useToasts();

  useEffect(() => {
    if (fbError) setErrorToast({ heading: 'Facebook', message: fbError });
  }, [fbError]);

  return (
    <Flex className="facebook-connect" css={styles}>
      <Button
        onClick={() => openFbLoginDialog(redirectURI, currentUser.id, accountPlatformId)}
        disabled={facebookConnected || businessId}
        className="facebook-connect__button"
        type="button"
      >
        <FontAwesomeIcon icon={faFacebook} className="facebook-connect__fb-icon" fixedWidth />
        {text}
      </Button>
      {facebookConnected && (
        <Text as="h3" variant={TEXT_VARIANTS.SUBHEADING} className="facebook-connect__connected">
          Connected
        </Text>
      )}
    </Flex>
  );
};

export default FacebookConnect;

import config from 'config';
import { TestContext, TestFunction } from 'yup';

import {
  MESSAGES,
  SUBMISSION_IMAGE_MIN_DIMENSION,
  SUBMISSION_VIDEO_MIN_DIMENSION,
  DURATION_BUFFER,
  VIDEO_SIZE_LIMIT,
} from 'shared/config/validations';
import { CurriedTestFunction } from 'shared/hooks/useSchema';
import { removeExtensionFromFilename } from 'shared/utilities/stringUtility';
import { isGif, isImage, isVideo } from 'shared/utilities/validator/utils';

export const fileNameLength: TestFunction<any> = (value, context) => {
  const filename = removeExtensionFromFilename(value);
  if (filename.length > config.FILE_NAME_LENGTH) {
    return context.createError({
      message: MESSAGES.FILE_NAME_LENGTH,
    });
  }
  return true;
};

export const videoSizeLimit: TestFunction<any> = (size) => {
  return size < VIDEO_SIZE_LIMIT;
};

export const dimensionLimit: TestFunction<any> = ({ width = 0, height = 0 }, context: TestContext) => {
  const { mimeType } = context.parent;
  if (isImage(mimeType) && width >= SUBMISSION_IMAGE_MIN_DIMENSION && height >= SUBMISSION_IMAGE_MIN_DIMENSION)
    return true;
  else if (
    (isGif(mimeType) || isVideo(mimeType)) &&
    width >= SUBMISSION_VIDEO_MIN_DIMENSION &&
    height >= SUBMISSION_VIDEO_MIN_DIMENSION
  )
    return true;
  return context.createError({
    message: isImage(mimeType) ? MESSAGES.IMAGE_DIMENSIONS_ERROR : MESSAGES.VIDEO_DIMENSIONS_ERROR,
  });
};

export const calculateBufferedDurationMin = (min: number) => {
  // Add buffer but not when between 0 and 10s
  if (min < 10) return min;
  return min - DURATION_BUFFER;
};

export const calculateBufferedDurationMax = (max: number) => {
  // Add buffer but not when between 0 and 10s
  if (max < 10) return max;
  return max + DURATION_BUFFER;
};

export const minDurationLimit: TestFunction<any> = ({ duration = 0 }, context: TestContext) => {
  const roundedDuration = Math.round(duration);
  const { mimeType, minDuration } = context.parent;
  const isDurationDefined = roundedDuration !== 0 && roundedDuration != null;
  const hasMinDuration = isDurationDefined && roundedDuration >= calculateBufferedDurationMin(minDuration);
  if (isImage(mimeType) || hasMinDuration) return true;
  return context.createError({
    message: `Please upload a video longer than ${context.parent.minDuration} second(s)`,
  });
};

export const maxDurationLimit: TestFunction<any> = ({ duration = 0 }, context: TestContext) => {
  const roundedDuration = Math.round(duration);
  const { mimeType, maxDuration } = context.parent;
  const isDurationDefined = roundedDuration !== 0 && roundedDuration != null;
  const underMaxDuration = isDurationDefined && roundedDuration <= calculateBufferedDurationMax(maxDuration);
  if (isImage(mimeType) || underMaxDuration) return true;
  return context.createError({
    message: `Please upload a video shorter than ${context.parent.maxDuration} second(s)`,
  });
};

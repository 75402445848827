import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'rebass/styled-components';
import { faThumbtack } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from 'classnames';

import { useMessaging } from 'features/messages/useMessaging';
import OrgDisplay from 'features/organizations/components/molecules/OrgDisplay';
import switchOrganizationsStyles from 'features/organizations/components/organisms/SwitchOrganizations/switchOrganizationsStyles';
import { useOrganizations } from 'features/organizations/useOrganizations';
import NotificationBadge from 'shared/components/atoms/NotificationBadge';
import { Box, Flex, Text } from 'shared/components/display';
import Modal from 'shared/components/molecules/Modal';
import { SegmentedButton } from 'shared/components/molecules/SegmentedButton';

import { ORGANIZATION_COPY } from 'shared/config/copy';
import { SIZING } from 'shared/config/formatting';
import { NOTIFICATIONS } from 'shared/config/notifications';
import { RESOURCES } from 'shared/config/resourceNames';

import { useComponentLoading } from 'shared/hooks/useComponentLoading';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { useNotifications } from 'shared/hooks/useNotifications';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LinkCreator } from 'shared/utilities/linkUtility';

import { FilterType, OPTIONS } from './constants';

interface Props {
  isOpen: boolean;
  organizationId: number;
  onRequestClose: () => void;
}

const SwitchOrganizations: React.FC<Props> = ({ isOpen, onRequestClose, organizationId }) => {
  const { organization, organizations, pinOrg, unpinOrg } = useOrganizations(organizationId);
  const { loading } = useComponentLoading(RESOURCES.PIN_ORGANIZATION, false);
  const navigate = useNavigate();
  const { setUnreadChannels, getUnreadChannels } = useMessaging();
  const { getFormattedOrgNotificationCount } = useNotifications();
  const { isSuperadmin } = useCurrentUser();
  const [hover, setHover] = useState({});
  const [filter, setFilter] = useState(FilterType.PINNED);

  const numUnreadChannels = getUnreadChannels().length;
  useEffect(() => {
    setUnreadChannels();
  }, [numUnreadChannels]);

  const hasPinned = useMemo(() => organizations.some(({ pinned }) => pinned), [organizations]);

  const filteredOrgs = useMemo(() => {
    if (filter === FilterType.PINNED) return organizations.filter(({ pinned }) => pinned);
    return organizations;
  }, [filter, organizations]);

  useEffect(() => {
    if (filteredOrgs.length < 1 && filter === FilterType.PINNED) setFilter(FilterType.ALL);
  }, [filteredOrgs, filter]);

  const onOrgClick = (id) => () => {
    const orgLink = LinkCreator.createLink({
      routeKey: 'ORGANIZATION',
      variables: {
        organizationId: id,
      },
    });
    onRequestClose();
    navigate(orgLink);
  };

  const createOrg = () => {
    navigate(
      LinkCreator.createLink({
        routeKey: 'CREATE_ORGANIZATION',
      }),
    );
    onRequestClose();
  };

  const onHover = (orgId: number) => {
    if (isSuperadmin)
      setHover((state) => ({
        ...state,
        [orgId]: true,
      }));
  };

  const onLeave = (orgId: number) => {
    if (isSuperadmin)
      setHover((state) => ({
        ...state,
        [orgId]: false,
      }));
  };

  const onChangeFilter = (value) => setFilter(value.value);

  return isOpen ? (
    <Modal
      modalSize={SIZING.FULLSCREEN}
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      title={ORGANIZATION_COPY.HEADING_SWITCH_ORGANIZATIONS}
      component={
        <Flex className="switch-organizations" css={switchOrganizationsStyles}>
          {isSuperadmin && hasPinned && (
            <SegmentedButton<FilterType>
              value={filter}
              // @ts-ignore
              options={OPTIONS}
              onChanged={onChangeFilter}
            />
          )}
          <Flex className="switch-organizations__content-wrapper">
            {filteredOrgs.map((org) => {
              const isCurrentOrg = organization.id === org.id;
              const badgeCount = getFormattedOrgNotificationCount(NOTIFICATIONS.UNREAD_MESSAGES, org.id);
              return (
                <Flex
                  key={org.id}
                  className={cs('switch-organizations__org-wrapper', {
                    'switch-organizations__org-wrapper--current': isCurrentOrg,
                  })}
                  onClick={onOrgClick(org.id)}
                  onMouseEnter={() => onHover(org.id)}
                  onMouseLeave={() => onLeave(org.id)}
                >
                  <Box as="span" className="switch-organizations__badge-wrapper">
                    {!org.pinned && hover[org.id] && (
                      <FontAwesomeIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!loading) pinOrg(org.id);
                        }}
                        icon={faThumbtack}
                        size="sm"
                        color="gray"
                        className={'switch-organizations__org-pin'}
                      />
                    )}
                    {org.pinned && (
                      <FontAwesomeIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!loading) unpinOrg(org.id);
                        }}
                        icon={faThumbtack}
                        size="sm"
                        className={'switch-organizations__org-pin'}
                      />
                    )}
                    <OrgDisplay organization={org} />
                    {badgeCount ? (
                      <NotificationBadge top="1rem" right="0.5rem">
                        {badgeCount}
                      </NotificationBadge>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Text
                    variant={TEXT_VARIANTS.H6}
                    className={cs('endWithEllipsis', 'endMultipleRows', 'switch-organizations__org-name')}
                  >
                    {org.name}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
          <Flex className="switch-organizations__button-wrapper">
            <Button onClick={createOrg} className="switch-organizations__btn">
              {ORGANIZATION_COPY.BUTTON_CREATE_ORGANIZATION}
            </Button>
          </Flex>
        </Flex>
      }
    />
  ) : (
    <></>
  );
};

export default SwitchOrganizations;

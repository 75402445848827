import { css } from '@emotion/react';
import theme from 'shared/styles/themes';

export default css({
  '&.edit-button--horizontal': {
    '.edit-button__btn-icon': {
      marginRight: theme.space[2],
    },
  },
  '&.edit-button--vertical': {
    '.edit-button__btn': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
  '.edit-button': {
    '&__btn': {
      padding: 0,
    },
  },
});

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.performance-pagination': {
    boxShadow: 'none',
    width: '100%',
    padding: `${theme.space[6]} ${theme.space[5]}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .page-size-select': {
      margin: `0 ${theme.space[2]}`,
    },
  },
  '.performance-pagination': {
    '&__navigation': {
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    '&__page-size': {
      justifyContent: 'center',
      paddingTop: theme.space[3],
      alignItems: 'center',
    },
    '&__item-totals': {
      justifyContent: 'flex-end',
      paddingTop: theme.space[3],
      alignItems: 'center',
    },
    '&__page-button--current': {
      color: COLORS.PRIMARY500,
    },
  },
});

import { BreakdownFields, Breakdowns, PerformanceLevels } from 'features/performance/enums';

import { formatISODate } from 'shared/utilities/dateUtility';

export const STATUS = 'status';
export const DUPLICATE = 'duplicate';

export const PERFORMANCE_DATA_TYPES = {
  DEFAULT: 'Default',
  COMPARISON: 'Compare',
  TOP_BOTTOM_ANALYSIS: 'Top Bottom Analysis',
};

export const DEFAULT_COLUMNS_CHOSEN = [STATUS, 'campaign_name', 'Spend', 'Impressions', 'Clicks'];

export const DEFAULT_FACEBOOK_COLUMNS_CHOSEN = [
  STATUS,
  'campaign_name',
  'bid_strategy',
  'Spend',
  'Impressions',
  'Clicks',
  'Purchases',
  'Purchases Conversion Value',
];

export const DEFAULT_PARAMS = {
  level: PerformanceLevels.CAMPAIGN,
  attributionWindow: '[]',
  breakdowns: '',
  fields: DEFAULT_COLUMNS_CHOSEN.toString(),
  startDate: formatISODate(new Date()),
  endDate: formatISODate(new Date()),
  datePreset: 'Today',
  filtering: '[]',
};

// temporary until DB storage
export const DEFAULT_FIELDS = [
  'campaign_name',
  'campaign_id',
  'adset_name',
  'adset_id',
  'ad_name',
  'ad_id',
  'Spend',
  'Impressions',
  'Clicks',
  'labels',
];

export const BREAKDOWN_MAP = {
  [Breakdowns.AGE_GENDER]: [BreakdownFields.AGE, BreakdownFields.GENDER],
  [Breakdowns.PLACEMENT]: [
    BreakdownFields.PUBLISHER_PLATFORM,
    BreakdownFields.PLATFORM_POSITION,
    BreakdownFields.DEVICE_PLATFORM,
  ],
};

export const NON_NUMERIC_COLUMNS = [
  'campaign_name',
  'campaign_id',
  'adset_name',
  'adset_id',
  'ad_name',
  'ad_id',
  'keyword_text',
  'keyword_match_type',
  'keyword_id',
  STATUS,
  'age',
  'gender',
  'publisher_platform',
  'platform_position',
  'device_platform',
  'bid_strategy',
  'date_start',
  'date_stop',
];

import { Box } from 'shared/components/display';
import { CarouselItemProps } from 'shared/components/molecules/CarouselItem';
import { carouselStyles } from 'shared/components/organisms/Carousel/carouselStyles';
import React from 'react';
import { Carousel as RRCarousel, CarouselProps } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

type Props = {
  children: React.ReactElement<CarouselItemProps>[];
} & Partial<Pick<CarouselProps, 'showIndicators' | 'onClickItem' | 'showStatus' | 'showThumbs'>>;

const Carousel: React.FC<Props> = ({
  children,
  showIndicators = true,
  showStatus = false,
  showThumbs = false,
  onClickItem,
}) => {
  return (
    <Box css={carouselStyles} className="carousel" onClick={(e) => e.stopPropagation()}>
      <RRCarousel
        onClickItem={onClickItem}
        showStatus={showStatus}
        showThumbs={showThumbs}
        showIndicators={showIndicators}
      >
        {React.Children.map<React.ReactElement, React.ReactElement>(children, (Child) => {
          if (Child && Child.type !== React.Fragment) {
            return React.cloneElement(Child, {
              showThumbnail: true,
            });
          }
          return Child;
        })}
      </RRCarousel>
    </Box>
  );
};

export default Carousel;

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';

export default css({
  '&.level-selector': {
    flexDirection: 'column',
  },
  '.level-selector': {
    '&__label': {
      color: COLORS.NEUTRAL600,
      marginBottom: theme.space[1],
    },
  },
});

import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@rollbar/react';
import NewUserNavBar from 'features/home/components/organisms/NewUserNavBar';
import RoutesWrapper from 'shared/router/RoutesWrapper';
import { ErrorPage } from 'features/ui/pages/ErrorPages';

const NewUserWrapper: React.FC = () => {
  const location = useLocation<any>();
  return (
    <>
      <NewUserNavBar />
      {/* @ts-ignore */}
      <ErrorBoundary key={location.pathname} fallbackUI={ErrorPage}>
        <RoutesWrapper>
          <Outlet />
        </RoutesWrapper>
      </ErrorBoundary>
    </>
  );
};

export default NewUserWrapper;

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  '.tt-videos-panel': {
    '&__link-wrapper': {
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    '&__link-text': {
      color: COLORS.NEUTRAL600,
      width: 'max-content',
    },
    '&__username': {
      alignItems: 'center',
    },
    '&__username > svg': {
      marginRight: '0.5rem',
    },
    '&__header': {
      justifyContent: 'space-between',
      gridColumn: '1 / span 2',
    },
  },
});

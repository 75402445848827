import React from 'react';
import { Box, Text, Image } from 'shared/components/display';
import LoadingAnimation from 'shared/images/loading/loading-001.gif';

import { ORGANIZATION_COPY } from 'shared/config/copy';
import { TEXT_VARIANTS } from 'shared/styles/text';
import styles from './styles';

const OrganizationOnboardingLoading = () => {
  return (
    <Box css={styles} className="organization-onboarding-loading">
      <Text variant={TEXT_VARIANTS.H6} className="organization-onboarding-loading__heading">
        {ORGANIZATION_COPY.HEADING_ORG_ONBOARD_LOADING}
      </Text>
      <Image
        src={LoadingAnimation}
        alt={ORGANIZATION_COPY.ALT_LOADING}
        title={ORGANIZATION_COPY.ALT_LOADING}
        className="organization-onboarding-loading__img"
      />
    </Box>
  );
};

export default OrganizationOnboardingLoading;

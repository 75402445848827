import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  flexDirection: 'column',
  marginBottom: '32px',
  '.stripe-receipt': {
    '&__receipt-number': {
      color: COLORS.NEUTRAL500,
    },
    '&__receipt-link': {
      marginLeft: '8px',
    },
    '&__receipt-link-icon': {
      color: COLORS.PRIMARY500,
    },
    '&__divider': {
      color: COLORS.NEUTRAL500,
      marginTop: '32px',
    },
    '&__summary-grid': {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      columnGap: '16px',
    },
    '&__summary-label': {
      color: COLORS.NEUTRAL500,
    },
    '&__line-items': {
      flexDirection: 'column',
    },
    '&__item-description': {
      marginRight: 'auto',
    },
  },
});

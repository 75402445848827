import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '&.contact-info-form': {
    [mediaquery.md]: { paddingLeft: '8px' },
  },
  '.contact-info-form': {
    '&__label': {
      marginBottom: '-7px',
    },
    '&__email-change-text': {
      lineHeight: 'normal',
    },
  },
});

import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import cs from 'classnames';
import { useFormikContext } from 'formik';

import { AgeValues } from 'features/users/components/CreatorOnboardingStepsFields';
import { Box } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';

import { Input as InputType } from 'shared/lib/formik';
import InputLabel from 'shared/styles/InputLabel/InputLabel';
import { formatDateWithSlashes } from 'shared/utilities/dateUtility';

import DayPickerModal from '../DayPickerModal';

import styles from './styles';

type Props = {
  innerRef: React.RefObject<HTMLInputElement>;
  className?: string;
  disabled?: boolean;
  label: string;
  maxLength?: number;
  softMaxLength?: number;
  placeholder?: string;
  showCharactersCounter?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onInput?: React.FormEventHandler<HTMLInputElement>;
  softValidationMessage: string;
  autoComplete?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  dataCy?: string;
  id?: string;
  autoFocus?: boolean;
  mask?: string;
  showDayPicker?: boolean;
};

const DateInput: InputType<string, Props> = ({
  innerRef,
  field: { name, value = '', onChange: formikOnChange },
  form: { touched, errors },
  label,
  className = '',
  autoComplete,
  onChange,
  dataCy,
  id,
  autoFocus = false,
  mask = '99/99/9999',
  placeholder = 'MM/DD/YYYY',
  showDayPicker = true,
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext<AgeValues>();
  const [active, setActive] = useState(false);

  const handleFocus = (e) => {
    setActive(true);
  };

  const handleChange = (e) => {
    e.persist();
    if (onChange) onChange(e);
    formikOnChange(e);
    setFieldTouched(name);
  };

  return (
    <Box className={cs(className, 'date-input')} css={styles}>
      <Box className="date-input__input">
        <InputLabel
          htmlFor={name}
          active={active}
          hasValue={Boolean(value)}
          isInvalid={touched[name] && !!errors[name]}
        >
          {label}
        </InputLabel>
        <InputMask
          mask={mask}
          ref={innerRef}
          placeholder={placeholder}
          value={value}
          type="text"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={(e) => {
            e.persist();
            setActive(false);
          }}
          name={name}
          data-lpignore={true}
          autoComplete={autoComplete}
          data-cy={dataCy}
          id={id}
          autoFocus={autoFocus}
          className={cs('input-mask', { 'input-mask--invalid': touched[name] && !!errors[name] })}
        />
        {showDayPicker && (
          <Box className="date-input__day-picker">
            <DayPickerModal
              title={'Date of Birth'}
              allowFutureDates={false}
              showDate={false}
              initialDate={value === '' || value.includes('_') ? new Date() : new Date(value)}
              onSelect={(d) => {
                setFieldValue(name, formatDateWithSlashes(d.toISOString()));
                setFieldTouched(name);
              }}
              showDropdown
            />
          </Box>
        )}
      </Box>
      {Object.keys(errors)?.length > 0 ? (
        <FieldErrorMessage className="date-input__error-message" dataCy={`${dataCy}__error-message`} name={name} />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default DateInput;

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { fontSizes } from 'shared/styles/text';

export default css({
  '.confirmation': {
    '&__subheading': {
      marginTop: '1rem',
      marginBottom: 0,
    },
    '&__caption': {
      color: COLORS.NEUTRAL500,
      lineHeight: 1.5,
    },
    '&__name': {
      backgroundColor: COLORS.PRIMARY25,
      borderRadius: '4px',
      border: `1px solid ${COLORS.PRIMARY25}`,
      padding: '.25rem 1rem .25rem 1rem',
      fontSize: fontSizes[4],
    },
    '&__section-title': {
      backgroundColor: COLORS.PRIMARY25,
      borderRadius: '4px',
      border: `1px solid ${COLORS.PRIMARY25}`,
      padding: '.25rem 1rem .25rem 1rem',
      fontSize: fontSizes[2],
      marginTop: '1rem',
    },
    '&__section': {
      display: 'flex',
      gap: '32px',
      margin: '0 20px',
    },
    '&__icon': {
      marginRight: '1rem',
      minWidth: '24px',
    },
    '&__social-media': {
      color: COLORS.PRIMARY500,
    },
    '&__phone': {
      whiteSpace: 'nowrap',
    },
    '&__stripe': {
      color: COLORS.PRIMARY500,
    },
    '&__notifications': {
      display: 'flex',
      gap: '20px',
      margin: '0 20px 20px',
      '&__status': {
        margin: 0,
      },
    },
    '&__confirm-btn': {
      display: 'flex',
      flexDirection: 'row-reverse',
    },
    '&__ethnicity-tags': {
      display: 'inline-block',
    },
    '&__tags-section': {
      display: 'flex',
    },
    '&__tags': {
      fontSize: fontSizes[1],
      margin: '0 16px',
      '.category-tags__category': {
        backgroundColor: COLORS.NEUTRAL100,
        color: COLORS.NEUTRAL500,
        fontSize: fontSizes[1],
        textTransform: 'lowercase',
        padding: '0 .25rem',
      },
      '.tagify__tag': {
        backgroundColor: COLORS.PRIMARY50,
        color: COLORS.PRIMARY800,
      },
    },
  },
});

import { BRIEF_COPY } from 'shared/config/copy';
import HeaderBrandActions from 'features/briefs/BriefHeader/HeaderBrandActions';
import HeaderCreatorActions from 'features/briefs/BriefHeader/HeaderCreatorActions';
import styles from 'features/briefs/BriefHeader/styles';
import { useBriefs } from 'features/briefs/hooks/useBriefs';
import { Box, Flex } from 'shared/components/display';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { Brief } from 'shared/typings/briefs';
import React from 'react';
import Skeleton from 'shared/components/atoms/Skeleton';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'shared/styles/styleFunctions';
import Tooltip from 'shared/components/atoms/Tooltip/NewTooltip';
import cs from 'classnames';
import { Link } from 'react-router-dom';
import ResponsiveDisplay from 'shared/components/atoms/ResponsiveDisplay';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { UserType } from 'shared/typings/user/enums';

interface Props {
  currentBrief: Brief;
  titleIsClickable?: boolean;
}

const BriefHeader: React.FC<Props> = ({ currentBrief, titleIsClickable = false }) => {
  const { currentUser, isCreator } = useCurrentUser();
  const { loading } = useBriefs();

  const showHeaderCreatorActions = currentBrief && isCreator;
  const showHeaderBrandActions = currentBrief && !isCreator;

  const isMobile = useMediaQuery({ maxWidth: breakpoints.md - 1 });
  const title = currentBrief
    ? currentBrief.name || BRIEF_COPY.DEFAULT_BRIEF_NAME
    : BRIEF_COPY.PLACEHOLDER_NONE_SELECTED_BRIEF;

  const briefLink = LinkCreator.createLink({
    routeKey: 'VIEW_INDIVIDUAL_BRIEF',
    userType: isCreator ? UserType.CREATOR : UserType.ORG,
    variables: {
      briefId: currentBrief?.id,
      organizationId: currentBrief?.organizationId,
      accountId: currentBrief?.accountId,
    },
  });

  return (
    <Flex css={styles} className="brief-header">
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <Tooltip content={title}>
            <Box as="h4" className={cs({ endWithEllipsis: !isMobile }, 'brief-header__name')}>
              {titleIsClickable ? <Link to={briefLink}>{title}</Link> : title}
            </Box>
          </Tooltip>
          <ResponsiveDisplay display="desktop">
            {showHeaderCreatorActions && <HeaderCreatorActions currentUser={currentUser} currentBrief={currentBrief} />}
            {showHeaderBrandActions && <HeaderBrandActions currentBrief={currentBrief} />}
          </ResponsiveDisplay>
        </>
      )}
    </Flex>
  );
};

export default BriefHeader;

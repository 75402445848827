import config from 'config';
import { Editor, Node, Path, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import { v4 as uuidv4 } from 'uuid';

import { EMPTY_ELEMENT } from 'shared/components/Richtext/config';
import { RichTextFormats } from 'shared/components/Richtext/shared/types';

import { uploadFile } from 'shared/blobstorage';
import { ImageElement } from 'shared/typings/slate';

const makeImageElement = (entityId: string, url: string = ''): ImageElement => ({
  ...EMPTY_ELEMENT,
  url,
  id: entityId,
});

export const insertImage = (editor: ReactEditor, key: string) => {
  const image = makeImageElement(key);
  const { selection } = editor;
  ReactEditor.focus(editor);

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(editor, selection.focus?.path);

    if (editor.isVoid(parentNode)) {
      // Insert image node after the void node
      Transforms.insertNodes(editor, image, {
        at: Path.next(parentPath),
        select: true,
      });
    } else if (!Node.string(parentNode).length) {
      // empty line
      Transforms.insertNodes(editor, image, {
        at: selection.focus?.path,
        select: true,
      });
    } else {
      // If the node is text, split and insert inside
      Transforms.splitNodes(editor);
      if (selection.focus?.offset === 1) Transforms.insertFragment(editor, [image]);
      else Transforms.insertFragment(editor, [image], { at: selection });
    }
  } else {
    // Insert image node at the bottom of the Editor when nothing is selected
    Transforms.insertNodes(editor, image, { select: true });
  }
};

export const updateImage = (editor: ReactEditor, { key, url }: { key: string; url: string }) => {
  Transforms.setNodes(
    editor,
    { url },
    {
      at: [],
      match: (node) => {
        return node.type === RichTextFormats.IMAGE && node.id === key;
      },
    },
  );
};

export const removeImage = (editor: ReactEditor, key: string) => {
  Transforms.removeNodes(editor, {
    at: [],
    match: (node) => {
      return node.type === RichTextFormats.IMAGE && node.id === key;
    },
  });
};

const abortController = new AbortController();

export const uploadRichtextImage = (editor: ReactEditor, sasToken: string, files) => {
  if (sasToken) {
    for (const file of files) {
      const key = uuidv4();
      uploadFile(sasToken, file, {
        metadata: { key },
        abortSignal: abortController.signal,
        blobDirectory: config.RICHTEXT_FILE_DIRECTORY,
      });
      insertImage(editor, key);
    }
  }
};

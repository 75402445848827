import React from 'react';
import { Button } from 'rebass/styled-components';

import { BUTTON_VARIANTS } from 'shared/styles/button';

const { PLAIN } = BUTTON_VARIANTS;

/**
 * This component shows that a link was sent to the given email.
 * Contains a 'Resend Link' text that will call the linkHandler
 * Used in Signup and Resetting Passwords
 */
const LinkSentMessage = (props) => {
    const { email, body, linkText, linkHandler } = props;
    return (
        <div>
            <p>{email}</p>
            <p>{body}</p>
            <Button variant={PLAIN} onClick={linkHandler}>
                {linkText}
            </Button>
        </div>
    );
};

export default LinkSentMessage;

/* eslint-disable no-param-reassign */
import { useMemo } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { assign } from 'lodash';
import qs, { ParsedQs } from 'qs';

// A custom hook that uses useLocation to parse
// the query string into an object
const prevQueries = {};

const decoder = (str: string, defaultDecoder: qs.defaultDecoder, charset: string, type: 'key' | 'value'): any => {
  // In order to parse numbers and boolean values
  if (type === 'key') return str;
  if (['true', 'false'].includes(str)) return str === 'true';
  const numberPattern = /^[0-9]+$/;
  if (numberPattern.test(str)) {
    return parseInt(str, 10);
  }

  return defaultDecoder(str, defaultDecoder, charset);
};

const parseQuery = (queryString: string): ParsedQs | { [key: string]: unknown } => {
  return qs.parse(queryString, {
    ignoreQueryPrefix: true,
    decoder,
  });
};

const stringifyQuery = (queryObj: any): string => {
  const queryString = qs.stringify(queryObj, {});
  // if there's a leading '&' replace it with '?'
  // otherwise just add '?'
  return queryString.replace(/(^&)*/, '?');
};

const getQuery = <T = any>(location: any): T => {
  // return an object of the query string values, where the values are strings

  // if the query already exists just return it
  let queries = prevQueries[location.search];
  if (queries) return queries;

  queries = parseQuery(location.search);

  // save this location.search result
  prevQueries[location.search] = queries;

  return queries;
};

const setQuery = ({
  location,
  navigate,
  queryObject = {},
  replaceQuery = false,
  replaceHistory = false,
}: {
  location: any;
  navigate: NavigateFunction;
  queryObject: any;
  replaceQuery?: boolean;
  replaceHistory?: boolean;
}) => {
  /**
   * takes an object and creates a search query and sets in the url
   * if replace is false it adds on to the current search query
   *
   * if a key is included in the queryObject that already exists in the
   * current query, it will be replace regardless
   */

  const currentQuery = parseQuery(location.search);

  // depending on the value of replace, either add to or replace pervious query
  const newQueryObject = assign(replaceQuery ? {} : currentQuery, queryObject);

  const queryString = stringifyQuery(newQueryObject);

  if (replaceHistory) {
    navigate(
      {
        search: queryString,
      },
      { replace: true },
    );
  } else {
    navigate({
      search: queryString,
    });
  }

  return queryString;
};

const setQueryWithoutRefresh = ({ queryObject }) => {
  const queryString = stringifyQuery(queryObject);
  // @ts-ignore
  const url = new URL(location);
  url.search = queryString;
  window.history.pushState({}, '', `${url}`);
};

export const useQuery = (refresh = true) => {
  const location = refresh ? useLocation<any>() : window.location;
  const navigate = useNavigate();
  return useMemo(
    () => ({
      getQuery: <T = any>() => getQuery<T>(location),
      setQuery: (queryObject: any, replaceQuery?: boolean, replaceHistory?: boolean) =>
        refresh
          ? setQuery({ queryObject, replaceQuery, replaceHistory, location, navigate })
          : setQueryWithoutRefresh({ queryObject }),
      parseQuery,
      stringifyQuery,
    }),
    [location],
  );
};

import * as yup from 'yup';

export const YUP_FIELDS = Object.freeze({
  DOMAIN_REQUIRED: yup.string().domain().customRequiredString(),
  PORTFOLIO_LINK_OPTIONAL: yup
    .string()
    .domain({ checkSubdomain: false, checkSlash: false })
    .nullable()
    .allowedPortfolioDomain(),
  LANDING_PAGE: yup.string().displayUrl().customRequiredString(),
});

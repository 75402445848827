import { css } from '@emotion/react';
import theme from 'shared/styles/themes';
import COLORS from 'shared/styles/colors';

export default css({
  '&.nav-item': {
    margin: `${theme.space[3]} 0`,
    '& .active': {
      color: COLORS.PRIMARY500,
    },
    '&--hide': {
      display: 'none',
    },
    '& svg': {
      marginRight: theme.space[2],
    },
  },
  '.nav-item': {
    '&__text': {
      alignContent: 'center',
      margin: `0 ${theme.space[2]} 0 0`,
      userSelect: 'none',
    },
    '&__text--clickable': {
      cursor: 'pointer',
    },
    '&__text[data-badge]::after': {
      content: 'attr(data-badge)',
      fontSize: '12px',
      color: 'white',
      backgroundColor: COLORS.RED500,
      fontWeight: 600,
      textAlign: 'center',
      padding: '1px 4px',
      marginLeft: '5px',
      lineHeight: '12px',
      minWidth: '16px',
      borderRadius: '16px',
    },
    '&__text-wrapper': {
      alignItems: 'center',
    },
  },
});

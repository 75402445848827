import { getComponentLoading, getMultipleLoading } from 'features/ui/ui.slice';
import { useSelector } from 'react-redux';

// action is dispatched in api/middleware

export const useComponentLoading = (component, startingState = true) => {
  const isLoading: boolean = useSelector(getComponentLoading(component, startingState));
  // depending on what you need
  return { loading: isLoading, loaded: !isLoading };
};

export const useMultipleLoading = (arrayOfComponents) => {
  const anyLoading: boolean = useSelector(getMultipleLoading(arrayOfComponents));
  return { loading: anyLoading };
};

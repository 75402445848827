// middleware to normalize current user object
// if api response changes, make updates here
import { currentUserChanged, loggedIn, loggedOut } from 'features/auth/auth.slice';
import { organizationsLoaded } from 'features/organizations/organizations.slice';
import {
  componentLoadingToggled,
  initialRefreshCompleted,
  loadingToggled,
  setNotificationCount,
} from 'features/ui/ui.slice';
import { userAccessRejected as userReset } from 'features/users/hooks/user.slice';

import { USER_ACTION_TYPES } from 'shared/actions/currentUser';
import { NOTIFICATIONS } from 'shared/config/notifications';
import { RESOURCES } from 'shared/config/resourceNames';
import { setLoggingUser } from 'shared/errors/Logger';
import Messaging from 'shared/messaging/Messaging';
import { deleteAllTokens, storeToken } from 'shared/utilities/tokenUtility';

export const currentUserMiddleware = () => (next) => (action) => {
  next(action);
  if (action.type === USER_ACTION_TYPES.USER_REFRESHED || action.type === USER_ACTION_TYPES.USER_SIGNED_IN) {
    const { organizations, ...user } = action.payload.data;
    storeToken(user.token);
    setLoggingUser({ id: user.id });
    Messaging.setUser(user).then(() => {
      const chatClient = Messaging.getClient();
      if (chatClient.user.unread_channels) {
        Messaging.getNotificationCounts(user).then((count) => {
          next(
            setNotificationCount({
              entity: NOTIFICATIONS.UNREAD_MESSAGES,
              count,
            }),
          );
        });
      }
    });
    next(loggedIn(user));
    next(organizationsLoaded({ data: organizations }));
    next(
      componentLoadingToggled({
        component: RESOURCES.ORGANIZATION,
        loading: false,
      }),
    );
    next(loadingToggled(false));
  } else if (action.type === USER_ACTION_TYPES.USER_CLEARED) {
    deleteAllTokens();
    Messaging.off();
    next(loggedOut());
    next(userReset());
    next(initialRefreshCompleted());
    next(loadingToggled(false));
  } else if (action.type === USER_ACTION_TYPES.USER_CONFIGURATION_CHANGED) {
    const newCurrentUser = {
      userConfiguration: {
        ...action.payload.data,
      },
    };
    next(currentUserChanged(newCurrentUser));
  }
};

export const API_ACCOUNT_ROUTES = Object.freeze({
  ACCOUNT_LOGO_FILE: '/account/:accountId/brand-logo/:fileId',
  ACCOUNT_PLATFORM: '/account/:accountId/platform/:accountPlatformId',
  ACCOUNT_PLATFORMS: '/account/:accountId/platform',
  ACCOUNT_HEALTH_CALCULATION: '/account-health',
  ACCOUNT_OPEN_BRIEFS: '/account/:accountId/open-briefs/:userId',
  ACCOUNT_PLATFORM_HEALTH: '/account-health/:accountPlatformId',
  ACCOUNT_PLATFORM_HEALTH_SURVEY: '/account-health/survey',
  ACCOUNT: '/account/:accountId',
  ACCOUNTS: '/account',
  AD_LAUNCH: '/account/:accountId/ad-launch',
  AD_PROCESSING: '/account/:accountId/ad-processing',
  ASSET_UPLOAD: '/account/:accountId/platform/:accountPlatformId/asset-upload',
  DELETE_BRAND_ASSET: '/account/:id/brand-assets/:fileId',
  GET_ACCOUNT_CHANNELS: '/channel',
  GET_BRAND_ASSETS: '/account/:accountId/brand-assets',
  PERFORMANCE_INDICATOR: '/account/:accountId/performance-indicator',
  PROMOTION_CODE: '/account/:accountId/promotion-code',
  UPDATE_AUDIENCE: '/account/:accountId/:accountPlatformId/update-audience',
  ACCOUNT_PRICING: '/account/:accountId/account-pricing',
  TOGGLE_MODULE_COMMENTS: 'account/:accountId/module-comments',
  TOGGLE_EMAIL_NOTIFICATIONS: 'account/:accountId/email-notifications',
  TOGGLE_CREDIT_CARD_CHARGES: 'account/:accountId/credit-card-charges',
  TOGGLE_ACCOUNT_HEALTH: 'account/:accountId/account-health',
  TOGGLE_AD_HEALTH_MACHINE_LEARNING: 'account/:accountId/account-health-machine-learning',
  TIKTOK_AD_ACCOUNTS: '/account/tiktok-ad-accounts',
});

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  padding: '10px 0',
  [mediaquery.md]: {
    padding: '10px 0 10px 8px',
  },
  '.text-notifications-form': {
    '&__field-title': {
      marginBottom: '-14px',
    },
    '&__submit-btn': {
      justifyContent: 'flex-end',
      marginTop: '20px',
      gap: '10px',
    },
    '&__cancel-btn': {
      width: '100px',
      cursor: 'pointer',
      backgroundColor: COLORS.NEUTRAL200,
      color: COLORS.NEUTRAL700,
      '&:hover': {
        opacity: 0.8,
      },
    },
  },
  '& .notifications__caption': {
    lineHeight: '1.5',
  },
});

import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import theme from 'shared/styles/themes';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
  '.account-platform-form': {
    '&__submit-btn': {
      margin: `${theme.space[4]} 0`,
    },
  },
});

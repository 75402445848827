import { css } from '@emotion/react';
import { mediaquery } from 'shared/styles/styleFunctions';

export default css({
    height: '100%',
    padding: '34px',
    textAlign: 'center',
    '.no-channels-placeholder': {
        '&__heading': {
            [mediaquery.md]: {
                display: 'none',
            },
        },
        '&__button': {
            marginTop: '16px',
        },
    },
});

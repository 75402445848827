import React, { useMemo, useState } from 'react';
import { Button } from 'rebass/styled-components';
import { Field, Form, Formik } from 'formik';

import internalNoteStyles from 'features/users/components/InternalNotesForm/internalNoteStyles';
import {
  INTERNAL_NOTE_FIELDS,
  internalNoteSchema,
  InternalNoteValues,
} from 'features/users/components/InternalNotesForm/internalNoteValidation';
import TagsInput, { Value } from 'features/users/components/organisms/TagsInput';
import { useUser } from 'features/users/hooks/useUser';
import { Box, Flex, Text } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import FormButton from 'shared/components/FormButton';
import Modal from 'shared/components/molecules/Modal';
import TextareaInput from 'shared/components/TextareaInput/TextareaInput';

import { GENERIC_COPY, USER_COPY } from 'shared/config/copy';
import { SIZING } from 'shared/config/formatting';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { BrkfstUser } from 'shared/typings/user';

interface Props {
  user: BrkfstUser;
  onClick?: () => void;
}
const InternalNotesForm: React.FC<Props> = ({ user, onClick }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const handleClick = () => {
    onClick && onClick();
    toggleModal();
  };

  const { updateInternalCreatorData } = useUser(user.id);

  const onSubmit = (data) => {
    //Submit the original note to validate if it has changed before making an update to the note field
    const trimmedData = {
      tags: data[INTERNAL_NOTE_FIELDS.TAGS].reduce((acc, { category, values }) => {
        values.forEach((tag) => {
          if (!(tag.includes('child (') || tag.includes('teenager ('))) acc.push({ value: tag, category });
        });
        return acc;
      }, []),
      originalTags: data[INTERNAL_NOTE_FIELDS.ORIGINAL_TAGS],
      note: data[INTERNAL_NOTE_FIELDS.NOTE].trim(''),
      originalNote: data[INTERNAL_NOTE_FIELDS.ORIGINAL_NOTE].trim(''),
    };

    updateInternalCreatorData(user.id, trimmedData);
    toggleModal();
  };

  const initialValues: InternalNoteValues = useMemo(() => {
    const creatorTags = user.creatorTags || [];
    const formattedTags: Value[] = Object.values(
      creatorTags.reduce((acc, tag) => {
        if (tag.isStandard) {
          const category = tag.category || 'other';
          if (!acc[category]) {
            acc[category] = {
              values: [tag.value],
              category,
            };
          } else {
            acc[category].values.push(tag.value);
          }
        }
        return acc;
      }, {}),
    );
    return {
      tags: formattedTags,
      originalTags: creatorTags,
      note: user.note || '',
      originalNote: user.note || '',
    };
  }, [user]);

  return (
    <Box className="profile-notes">
      <Button
        variant={BUTTON_VARIANTS.OPTION}
        onClick={handleClick}
        className="profile-notes__btn"
        data-cy="profile-notes__btn"
      >
        {USER_COPY.BUTTON_EDIT_INTERNAL_NOTES}
      </Button>
      <Modal
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        modalSize={SIZING.LARGE}
        title={USER_COPY.MODAL_HEADING_NOTES}
        component={
          <Formik<InternalNoteValues>
            onSubmit={onSubmit}
            validationSchema={internalNoteSchema}
            initialValues={initialValues}
          >
            {() => {
              return (
                <Box css={internalNoteStyles} className="profile-notes">
                  <Form>
                    <Text
                      as="label"
                      variant={TEXT_VARIANTS.SUBHEADING}
                      htmlFor={INTERNAL_NOTE_FIELDS.TAGS}
                      className="profile-notes__label"
                    >
                      {USER_COPY.FORM_LABEL_TAGS}
                    </Text>

                    <TagsInput dataCy="profile-notes__tags" name={INTERNAL_NOTE_FIELDS.TAGS} />
                    <FieldErrorMessage name={INTERNAL_NOTE_FIELDS.TAGS} />
                    <Text
                      as="label"
                      variant={TEXT_VARIANTS.SUBHEADING}
                      htmlFor={INTERNAL_NOTE_FIELDS.NOTE}
                      className="profile-notes__label"
                    >
                      {USER_COPY.FORM_LABEL_NOTE}
                    </Text>
                    <Field
                      component={TextareaInput}
                      dataCy="profile-notes__note"
                      name={INTERNAL_NOTE_FIELDS.NOTE}
                      placeholder=""
                    />
                    <Flex className="profile-notes__btn-wrapper">
                      <FormButton
                        variant={BUTTON_VARIANTS.TEXT}
                        onClick={toggleModal}
                        type="button"
                        dataCy="profile-notes__cancel"
                        className="profile-notes__cancel"
                      >
                        {GENERIC_COPY.BUTTON_CANCEL}
                      </FormButton>
                      <FormButton dataCy="profile-notes__submit" className="profile-notes__submit">
                        {USER_COPY.BUTTON_SAVE_CHANGES}
                      </FormButton>
                    </Flex>
                  </Form>
                </Box>
              );
            }}
          </Formik>
        }
      />
    </Box>
  );
};

export default InternalNotesForm;

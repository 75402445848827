import { Box, Flex, Text } from 'shared/components/display';
import StripeConnect, { StripeConnectVariant } from 'shared/components/molecules/StripeConnect/StripeConnect';
import { EARNING_COPY } from 'shared/config/copy';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { TEXT_VARIANTS } from 'shared/styles/text';
import { LinkCreator } from 'shared/utilities/linkUtility';
import React from 'react';
import styles, { CLASSNAMES, MAIN_CLASS } from './styles';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from 'shared/styles/styleFunctions';
import { UserType } from 'shared/typings/user/enums';
interface Props {
  isTotal?: boolean;
  total?: string;
}

const TotalEarningsHeader = ({ isTotal = true, total = '$0.00' }: Props) => {
  const { currentUser } = useCurrentUser();
  const isMobile = useMediaQuery({
    maxWidth: breakpoints.md - 1,
  });
  return (
    <Box css={styles} className={MAIN_CLASS}>
      <header>
        <Flex className={CLASSNAMES.mainWrapper}>
          <Text
            as="h2"
            variant={isMobile ? TEXT_VARIANTS.BODY_SM : TEXT_VARIANTS.BODY}
            className={CLASSNAMES.totalAmountLabel}
          >
            {isTotal ? EARNING_COPY.GRAND_TOTAL : EARNING_COPY.SUBTOTAL}
          </Text>
          <Text
            as="strong"
            variant={isMobile ? TEXT_VARIANTS.SUBHEADING_SM : TEXT_VARIANTS.SUBHEADING}
            className={CLASSNAMES.total}
          >
            {total}
          </Text>
          <Text as="small" className={CLASSNAMES.taxLegend}>
            (Excluding Tax)
          </Text>
          <Box className={CLASSNAMES.stripeWrapper}>
            <StripeConnect
              user={currentUser}
              redirectURI={LinkCreator.createLink({
                routeKey: 'EARNINGS',
                userType: UserType.CREATOR,
              })}
              variant={StripeConnectVariant.SECONDARY}
            />
          </Box>
        </Flex>
      </header>
    </Box>
  );
};

export default TotalEarningsHeader;

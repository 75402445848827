import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'rebass/styled-components';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { makeInitials } from 'features/organizations/components/molecules/OrgDisplay/util';
import { Box } from 'shared/components/display';
import orgDisplayStyles from 'features/organizations/components/molecules/OrgDisplay/orgDisplayStyles';

const MAX_NUM_ORG_INITIALS = 3;

const OrgDisplay = ({ organization }) => {
  const { id, imgUrl, name } = organization;

  const orgLink = LinkCreator.createLink({
    routeKey: 'ORGANIZATION',
    variables: {
      organizationId: id,
    },
  });

  return (
    <Box className="org-display" css={orgDisplayStyles}>
      <Link to={orgLink} className="org-display__link">
        {imgUrl ? (
          <Image src={imgUrl} />
        ) : (
          <Box className="org-display__initials">{makeInitials(name, MAX_NUM_ORG_INITIALS)}</Box>
        )}
      </Link>
    </Box>
  );
};

export default OrgDisplay;

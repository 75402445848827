import { css } from '@emotion/react';
import theme from 'shared/styles/themes';
import { zIndex } from 'shared/config';

export default css({
  '&.creator-submission-modal': {
    height: '100%',
    '&__header': {
      height: '40px',
      position: 'absolute',
      top: '5px',
      right: '5px',
      zIndex: zIndex.zModalContent,
    },
  },
  '.creator-submission-modal': {
    '&__header-link': {
      paddingBottom: 0,
      paddingLeft: theme.space[4],
      fontSize: theme.fontSizes[1],
      fontWeight: 600,
      letterSpacing: '0.05rem',
      textTransform: 'uppercase',
    },
  },
});

import { css } from '@emotion/react';

import COLORS from 'shared/styles/colors';

export default css({
  '&.counter': {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${COLORS.NEUTRAL200}`,
    borderRadius: '6px',
    width: 'fit-content',
    margin: '8px 0',
  },
  '.counter': {
    '&__button': {
      border: 'none',
      fontSize: '24px',
      cursor: 'pointer',
      margin: '0 16px',
      padding: 0,
      minWidth: '30px',
    },
  },
});

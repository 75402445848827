import React from 'react';
import { BRIEF_COPY } from 'shared/config/copy';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { Flex } from 'shared/components/display';
import { FormBrief } from '../types';
import FormPromptButton from 'shared/components/molecules/FormPromptButton/FormPromptButton';
import FormButton from 'shared/components/FormButton';
import { SIZING } from 'shared/config/formatting';
import COLORS from 'shared/styles/colors';

interface Props {
  isPublished: boolean;
  showPublishButton: boolean;
  onPublish: (values: FormBrief) => void;
  onSaveDraft: (values: FormBrief) => void;
  onCancel: () => void;
  loadingSaveDraft?: boolean;
  loadingPublish?: boolean;
}

const FormButtons: React.FC<Props> = ({
  isPublished,
  showPublishButton,
  onPublish,
  onCancel,
  onSaveDraft,
  loadingSaveDraft = false,
  loadingPublish = false,
}) => {
  if (isPublished)
    return (
      <Flex className="brief-form__button-wrapper--open">
        <FormPromptButton<FormBrief>
          onConfirm={(values) => {
            onPublish(values);
          }}
          text={BRIEF_COPY.MODAL_EDIT_BRIEF}
          buttonText="Edit"
          modalSize={SIZING.MEDIUM}
          validate
        />
        <FormButton type="button" onClick={onCancel} variant={BUTTON_VARIANTS.TEXT}>
          {BRIEF_COPY.BUTTON_CANCEL_EDIT_BRIEF}
        </FormButton>
      </Flex>
    );
  return (
    <Flex className="brief-form__button-wrapper">
      <FormButton<FormBrief>
        type="button"
        variant={BUTTON_VARIANTS.OUTLINE}
        onClick={({ values }) => onSaveDraft(values)}
        className="brief-form__draft-btn"
        loading={loadingSaveDraft}
        loaderColor={COLORS.PRIMARY600}
        disabled={loadingPublish}
      >
        {BRIEF_COPY.BUTTON_SAVE_BRIEF_DRAFT}
      </FormButton>

      {showPublishButton && (
        <FormButton<FormBrief>
          className="brief-form__publish-btn"
          loading={loadingPublish}
          disabled={loadingSaveDraft}
          dataCy="brief-form__publish-btn"
        >
          {BRIEF_COPY.BUTTON_PUBLISH}
        </FormButton>
      )}
    </Flex>
  );
};

export default FormButtons;

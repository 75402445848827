import { BlobServiceClient } from '@azure/storage-blob';
import config from 'config';
import { v4 as uuid } from 'uuid';

import { Constants, FileUploadOperation, Options } from 'shared/blobstorage';
import { getFileMimeType } from 'shared/utilities/validator/utils';

export async function uploadFile(sasToken: string, file: File, options: Options = {}) {
  const { metadata = {}, onProgress = () => {}, fileName, abortSignal } = options;
  const blobService = new BlobServiceClient(`${Constants.CONNECTION_URL}?${sasToken}`);
  const containerClient = blobService.getContainerClient(Constants.CONTAINER_NAME);
  const blobDir = options.blobDirectory || config.BLOB_FILE_DIRECTORY; // optionally override blob directory file is uploaded to.
  const blobClient = containerClient.getBlockBlobClient(`${blobDir}/${uuid()}_${fileName ? fileName : file.name}`);
  // upload file
  const fileType = getFileMimeType(file);
  return Promise.allSettled([
    blobClient.uploadData(file, {
      blobHTTPHeaders: { blobContentType: fileType },
      metadata,
      onProgress,
      abortSignal,
    }),
  ]);
}

export async function uploadFiles(sasToken: string, operations: FileUploadOperation[]) {
  return Promise.allSettled(
    operations.map(({ file, options = {} }) => {
      return uploadFile(sasToken, file, options);
    }),
  );
}

import { YUP_FIELDS } from 'shared/utilities/yupFieldUtility';
import * as Yup from 'yup';

const REQUIRED_FIELD = 'This field is required.';

const baseSchema = {
  adName: Yup.string().customRequiredString(),
  urlTags: Yup.string().customRequiredString(),
  primaryText: Yup.string().customRequiredString(),
  primaryTextName: Yup.string().customRequiredString(),
  callToAction: Yup.object()
    .shape({
      value: Yup.string().customRequiredString().nullable(),
    })
    .required(REQUIRED_FIELD),
  adsets: Yup.array()
    .min(1)
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    )
    .nullable(),
};

export const adCreationSchema: Yup.ObjectSchema<any> = Yup.object({
  ...baseSchema,
  headline: Yup.string().customRequiredString(),
  headlineName: Yup.string().customRequiredString(),
  description: Yup.string(),
  descriptionName: Yup.string().customRequiredString(),
  landingPage: YUP_FIELDS.LANDING_PAGE,
  landingPageName: Yup.string().customRequiredString(),
});

export const collectionAdCreationSchema: Yup.ObjectSchema<any> = Yup.object({
  ...baseSchema,
  headline: Yup.string().customRequiredString(),
  headlineName: Yup.string().customRequiredString(),
  description: Yup.string(),
  descriptionName: Yup.string().customRequiredString(),
  landingPage: YUP_FIELDS.LANDING_PAGE,
  landingPageName: Yup.string().customRequiredString(),
  productSet: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .required(REQUIRED_FIELD)
    .nullable(),
});

export const carouselAdCreationSchema: Yup.ObjectSchema<any> = Yup.object({
  ...baseSchema,
  carouselAssets: Yup.array(
    Yup.object().shape({
      headline: Yup.string().customRequiredString(),
      headlineName: Yup.string().customRequiredString(),
      description: Yup.string(),
      descriptionName: Yup.string().customRequiredString(),
      landingPage: YUP_FIELDS.LANDING_PAGE,
      landingPageName: Yup.string().customRequiredString(),
    }),
  ),
  seeMoreUrl: YUP_FIELDS.DOMAIN_REQUIRED,
});

import * as yup from 'yup';

export interface Values {
  trendName: string;
}

export const initialValues: Values = {
  trendName: '',
};

export const getValidationSchema = () =>
  yup.object({
    trendName: yup.string().customRequiredString(),
  });

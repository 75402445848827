import React, { useMemo, useState } from 'react';

import CreatorFileOverlayIcons from 'features/media/components/molecules/CreatorFileOverlayIcons';
import MarketerFileOverlayIcons from 'features/media/components/molecules/MarketerFileOverlayIcons';
import { GalleryItem } from 'features/media/interfaces/gallery';
import ModuleAssetsEllipsis from 'features/submissions/components/molecules/ModuleAssetsEllipsis';
import { Text } from 'shared/components/display';
import MediaGalleryAndListDisplay from 'shared/components/organisms/MediaGalleryAndListDisplay';

import { SUBMISSION_COPY } from 'shared/config/copy/submissionCopy';
import { PERMISSIONS_MAP } from 'shared/config/permissions';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { usePermissions } from 'shared/hooks/usePermissions';
import { BrkfstFile } from 'shared/typings/file';
import { ModuleSubmissionStatus } from 'shared/typings/submissions';
import IndividualModuleReview from 'shared/components/organisms/IndividualModuleReview';
import FileLightboxFooter from 'features/media/components/molecules/FileLightboxFooter';
import AssetToTrendLightboxFooter from 'features/submissions/components/molecules/AssetToTrendLightboxFooter';
import AssetToTrendFormModal from 'features/submissions/components/molecules/AssetToTrendFormModal';

interface Props {
  assets: BrkfstFile[];
  status: ModuleSubmissionStatus;
  organizationId: number;
  moduleNum: number;
  setSelectedFiles?: React.Dispatch<React.SetStateAction<BrkfstFile[]>>;
  selectedFiles?: BrkfstFile[];
  moduleId: number;
  accountId: number;
  briefName: string;
  brandName: string;
}

interface ReviewModuleState {
  isOpen: boolean;
  assetId: number | null;
}

const initialReviewModuleState: ReviewModuleState = {
  isOpen: false,
  assetId: null,
};

const ModuleAssets: React.FC<Props> = ({
  assets,
  moduleNum,
  status,
  organizationId,
  selectedFiles,
  setSelectedFiles,
  moduleId,
  accountId,
  briefName,
  brandName,
}) => {
  const [reviewModule, setReviewModule] = useState<ReviewModuleState>(initialReviewModuleState);
  const [trendFormId, setTrendFormId] = useState<number | undefined>(undefined);
  const { isCreator, isSuperadmin = false } = useCurrentUser();
  const { isAuthorized } = usePermissions();
  const selectedFileIds = useMemo(() => selectedFiles?.map((file) => file.id), [selectedFiles]);

  const showAssetToTrendLightbox = status === ModuleSubmissionStatus.APPROVED && isSuperadmin;

  const toggleReviewModule = (index: number) => {
    const asset = assets[index];
    setReviewModule((state) => ({
      isOpen: !state.isOpen,
      assetId: asset?.id || null,
    }));
  };

  const closeReviewModule = () => {
    setReviewModule(initialReviewModuleState);
  };

  const closeAssetToTrendModal = () => {
    setTrendFormId(undefined);
  };

  const onAddAssetToTrend = (fileId?: number) => {
    setTrendFormId(fileId);
    closeReviewModule();
  };

  const updateSelectedItems = (fileIds: number[]) => {
    // Create Set for quick access
    const fileIdsSet = new Set(fileIds);
    // Grab file objects that were selected from Ids
    const moduleAssetsChosen = assets.filter((file) => fileIdsSet.has(file.id));
    setSelectedFiles?.((existingFiles) => {
      // Get all module files that have already been chosen
      const moduleFiles = existingFiles.filter(({ moduleSubmission }) => moduleSubmission.moduleNum === moduleNum);
      const moduleFileIds = moduleFiles.map((file) => file.id);
      // Get all module files that have been removed
      const fileIdsRemoved = moduleFileIds.filter((id) => !fileIdsSet.has(id));
      // Remove files from callback argument array that have been removed
      const remainingFiles = existingFiles.filter((file) => !fileIdsRemoved.includes(file.id));

      // Create Set for quick access
      const existingFileIds = new Set(existingFiles.map((file) => file.id));
      // Find all files that are new
      const newFiles = moduleAssetsChosen.filter((file) => !existingFileIds.has(file.id));
      // Return all files that are still selected
      return [...remainingFiles, ...newFiles];
    });
  };

  const hideModuleInSubmittedState = useMemo(() => {
    const hasPermissionToViewSubmittedState =
      isCreator || isAuthorized(organizationId, PERMISSIONS_MAP.VIEW_SUBMITTED_SUBMISSION_MODULES.API);
    return !hasPermissionToViewSubmittedState && status == ModuleSubmissionStatus.SUBMITTED;
  }, [organizationId, isCreator, status]);

  const formatGalleryItems = (file: BrkfstFile): GalleryItem => {
    const { submitted, ...marketerFile } = file;

    return {
      options: <ModuleAssetsEllipsis file={isCreator ? file : marketerFile} />,
      itemObject: isCreator ? file : marketerFile,
      captionData: isCreator ? file : marketerFile,
      OverlayLeft: isCreator ? (
        <CreatorFileOverlayIcons file={file} showTags />
      ) : (
        <MarketerFileOverlayIcons file={marketerFile} showTags />
      ),
    };
  };

  return hideModuleInSubmittedState ? (
    <Text>{SUBMISSION_COPY.PLACEHOLDER_SUBMITTED_MODULES}</Text>
  ) : (
    <>
      <MediaGalleryAndListDisplay
        data={assets}
        isSelectable={isSuperadmin}
        formatGalleryItems={formatGalleryItems}
        updateSelectedItems={updateSelectedItems}
        selectedFileIds={selectedFileIds}
        renderLightbox={status !== ModuleSubmissionStatus.REVIEW}
        onItemClick={status === ModuleSubmissionStatus.REVIEW ? toggleReviewModule : undefined}
        LightboxCaption={
          showAssetToTrendLightbox ? <AssetToTrendLightboxFooter onClick={onAddAssetToTrend} /> : <FileLightboxFooter />
        }
      />
      {status === ModuleSubmissionStatus.REVIEW && (
        <IndividualModuleReview
          open={reviewModule.isOpen}
          displayAssetId={reviewModule.assetId}
          toggleLightbox={closeReviewModule}
          moduleId={moduleId}
          accountId={accountId}
          brandName={brandName}
          briefName={briefName}
        />
      )}
      <AssetToTrendFormModal
        isOpen={trendFormId !== undefined}
        fileId={trendFormId}
        onRequestClose={closeAssetToTrendModal}
      />
    </>
  );
};

export default ModuleAssets;

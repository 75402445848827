import 'react-day-picker/dist/style.css';

import React from 'react';
import { DayPicker, Matcher, SelectSingleEventHandler } from 'react-day-picker';
import cs from 'classnames';
import { isAfter, isSameDay } from 'date-fns';
import { isBefore } from 'date-fns/esm';

import { Box } from 'shared/components/display';

import calendarDateSelectorStyles, { modifiersStyles } from './calendarDaySelectorStyles';

const today = new Date();

interface Props {
  onSelect: SelectSingleEventHandler;
  selectedDay?: Date;
  required?: boolean;
  className?: string;
  onlyAllowFutureDateSelection?: boolean;
  allowFutureDates?: boolean;
  showDropdown?: boolean;
}

const CalendarDaySelector: React.FC<Props> = ({
  onSelect,
  selectedDay,
  required,
  className,
  onlyAllowFutureDateSelection = false,
  allowFutureDates = false,
  showDropdown = false,
}) => {
  const disabledModifier: Matcher = (day) => {
    // This returns true for days that should be disabled and get the disabled class name that we can then use in styles to modify
    if (onlyAllowFutureDateSelection) {
      return isBefore(day, today) || isSameDay(day, today);
    } else if (!allowFutureDates) return isAfter(day, today);
    else return false;
  };

  return (
    <Box className={cs('calendar-day-selector', className)} css={calendarDateSelectorStyles}>
      <DayPicker
        mode="single"
        numberOfMonths={1}
        defaultMonth={selectedDay}
        selected={selectedDay}
        onSelect={onSelect}
        required={required}
        modifiersStyles={modifiersStyles}
        modifiers={{
          disabled: disabledModifier,
        }}
        showOutsideDays
        captionLayout={showDropdown ? 'dropdown' : 'buttons'}
        fromYear={showDropdown ? new Date().getFullYear() - 100 : undefined} // don't allow dates older than 100 years
        toYear={showDropdown ? new Date().getFullYear() : undefined}
      />
    </Box>
  );
};

export default CalendarDaySelector;

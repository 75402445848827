import { createSlice } from '@reduxjs/toolkit';

import { EARNING_COPY } from 'shared/config/copy/earningCopy';
import { prettyDate, splitISODate } from 'shared/utilities/dateUtility';
import { formatCurrency } from 'shared/utilities/stringUtility';

const { PLACEHOLDER_NO_UPCOMING_EARNING } = EARNING_COPY;

const earningsSlice = createSlice({
  name: 'earnings',
  initialState: {
    data: [],
    currentPayouts: [],
    total: 0,
  },
  reducers: {
    earningsLoaded: (earnings, action) => {
      earnings.data = action.payload.data.data.map((data, ndx) => {
        data.id = ndx + 1;
        return data;
      });
      earnings.total = action.payload.data.total;
    },
    earningDetailsLoaded: (earnings, action) => {
      earnings.currentPayouts = Array.isArray(action.payload.data.data)
        ? action.payload.data.data.map((data, ndx) => {
            data.id = ndx + 1;
            return data;
          })
        : [action.payload.data];
      earnings.currentPayoutsTotal = action.payload.data.total;
    },
  },
});

export const { earningsLoaded, earningDetailsLoaded } = earningsSlice.actions;

export const getAllEarnings = ({ earnings: { data } }) => data;
export const getEarningsTotal = ({ earnings: { total } }) => total;

export const getEarningsPaidAt = ({ earnings: { data } }) =>
  data[0] ? prettyDate(splitISODate(data[0].stripePayoutDate)) : PLACEHOLDER_NO_UPCOMING_EARNING;

export const getEarningsPayPeriod = ({ earnings: { data } }) => {
  return data.length > 0
    ? `${prettyDate(data[0].billingStartDate.split('T')[0])} - ${prettyDate(data[0].billingEndDate.split('T')[0])}`
    : PLACEHOLDER_NO_UPCOMING_EARNING;
};
export const getEarningsTotalAmount = ({ earnings: { data } }) =>
  formatCurrency(data.reduce((acc, { totalAmount }) => acc + +totalAmount, 0) / 100);

export const getCurrentPayouts = ({ earnings: { currentPayouts } }) => currentPayouts;
export const getCurrentPayoutsTotal = ({ earnings: { currentPayoutsTotal } }) => currentPayoutsTotal;

export default earningsSlice.reducer;

import React, { useEffect, useState } from 'react';
import { intl } from 'shared/lib/intl';
import { Box, Flex, Text } from 'shared/components/display';
import { CREATOR_COPY, GENERIC_COPY } from 'shared/config/copy';
import { Field, Form, Formik, FormikProps } from 'formik';
import { useOrganizations } from 'features/organizations/useOrganizations';
import { SelectInput } from 'shared/components/molecules/Select';
import axiosClient from 'shared/axiosClient';
import { API_ACCOUNT_ROUTES } from 'shared/config/routes/api/apiAccountRoutes';
import { useAccounts } from 'features/accounts/hooks/useAccounts';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import NotificationBanner from 'shared/components/atoms/NotificationBanner';
import FormButton from 'shared/components/FormButton';
import { makeLabel } from 'shared/lib/formik';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { Brief } from 'shared/typings/briefs';

import styles from './styles';
import { FIELDNAMES, initialValues, InviteCreatorValues, validationSchema } from './config';
import { API_ORG_ROUTES } from 'shared/config/routes/api/apiOrgRoutes';
import ModalFormHr from 'shared/components/atoms/ModalFormHr';

interface Props {
  onSubmit: (values: InviteCreatorValues) => void;
  onCancel: () => void;
  creatorId: number;
  submitting?: boolean;
}

const initialBriefState = {
  data: [],
  loading: false,
};

const CreatorInviteForm: React.FC<Props> = ({ onSubmit, creatorId, onCancel, submitting }) => {
  const [briefs, setBriefs] = useState<{
    data: { label: string; value: number }[];
    loading: boolean;
  }>(initialBriefState);
  const [accounts, setAccounts] = useState<{
    data: { label: string; value: number }[];
    error: string;
    loading: boolean;
  }>({
    data: [],
    error: '',
    loading: false,
  });

  const { organizations } = useOrganizations();

  const orgOptions = organizations.map((org) => ({ value: org.id, label: org.name }));

  const hasMultipleOrgs = organizations.length > 1;
  const hasMultipleAccounts = accounts.data.length > 1 && !accounts.loading;
  const loadAccountsImmediately = !hasMultipleOrgs;

  useEffect(() => {
    if (loadAccountsImmediately) {
      loadAccounts(organizations[0]?.id).then((accs) => {
        if (accs.length === 1) loadBriefs(accs[0]?.value);
      });
    }
  }, [loadAccountsImmediately]);

  const loadAccounts = (organizationId: number): Promise<{ value: number; label: string }[]> => {
    if (!accounts.loading) {
      setAccounts({ data: [], error: '', loading: true });
    }
    return axiosClient
      .httpRequest<{ id: number; name: string }[]>({
        url: LinkCreator.createApiLink({
          route: API_ORG_ROUTES.ACCOUNTS,
          variables: {
            organizationId,
          },
        }),
        method: 'GET',
      })
      .then(({ data }) => {
        const options = data.map((account) => ({ label: account.name, value: account.id }));
        setAccounts({
          data: options,
          error: data.length === 0 ? 'No accounts found for this organization' : '',
          loading: false,
        });
        return options;
      })
      .catch((error) => {
        setAccounts({ data: [], error: error.message, loading: false });
        return [];
      });
  };

  const loadBriefs = (accountId: number) => {
    if (!briefs.loading) {
      setBriefs({
        data: [],
        loading: true,
      });
    }
    axiosClient
      .httpRequest<{ id: number; name: string }[]>({
        url: LinkCreator.createApiLink({
          route: API_ACCOUNT_ROUTES.ACCOUNT_OPEN_BRIEFS,
          variables: {
            accountId,
            userId: creatorId,
          },
        }),
        method: 'GET',
      })
      .then(({ data }) => {
        setBriefs({
          data: data.map((brief) => ({ label: brief.name, value: brief.id })),
          loading: false,
        });
      })
      .catch(() => {
        setBriefs(initialBriefState);
      });
  };

  const onAccountSelect =
    (formikProps: FormikProps<InviteCreatorValues>) =>
    ({ value: accountId }) => {
      formikProps.setFieldValue(FIELDNAMES.BRIEF, initialValues[FIELDNAMES.BRIEF]);
      loadBriefs(accountId);
    };

  const onOrgSelect = (formikProps: FormikProps<InviteCreatorValues>) => async (orgValue) => {
    const { value: organizationId } = orgValue;
    const accounts = await loadAccounts(organizationId);

    if (accounts.length > 1) {
      formikProps.resetForm({ values: { ...initialValues, [FIELDNAMES.ORGANIZATION]: orgValue } });
    } else if (accounts.length === 1) {
      loadBriefs(accounts[0]?.value);
      formikProps.resetForm({
        values: {
          ...initialValues,
          [FIELDNAMES.ORGANIZATION]: orgValue,
          [FIELDNAMES.ACCOUNT]: accounts[0],
        },
      });
    } else {
      setBriefs(initialBriefState);
      formikProps.resetForm({ values: { ...initialValues, [FIELDNAMES.ORGANIZATION]: orgValue } });
    }
  };

  const handleSubmit = (values: InviteCreatorValues) => {
    onSubmit({
      ...values,
      [FIELDNAMES.ACCOUNT]: hasMultipleAccounts
        ? values[FIELDNAMES.ACCOUNT]
        : { value: accounts.data[0]?.value, label: accounts.data[0]?.label },
    });
  };

  return (
    <Box css={styles} className="creator-invite-form">
      <Formik<InviteCreatorValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        validateOnBlur={false}
      >
        {(formikProps) => {
          const selectedOrgId = hasMultipleOrgs
            ? formikProps.values[FIELDNAMES.ORGANIZATION]?.value
            : organizations[0]?.id;
          const selectedAccountId = hasMultipleAccounts
            ? formikProps.values[FIELDNAMES.ACCOUNT]?.value
            : accounts.data[0]?.value;

          const showBriefSelect = Boolean(selectedAccountId) && !Boolean(accounts.error);

          return (
            <Form className="creator-invite-form__form">
              {(hasMultipleAccounts || hasMultipleOrgs) && (
                <Text className="creator-invite-form__description">
                  {intl.formatMessage(
                    {
                      id: 'MODAL_DESCRIPTION_INVITE_CREATORS',
                    },
                    { selection: hasMultipleOrgs ? 'org' : 'account' },
                  )}
                </Text>
              )}
              {hasMultipleOrgs && (
                <Field
                  name={FIELDNAMES.ORGANIZATION}
                  component={SelectInput}
                  options={orgOptions}
                  placeholder={makeLabel(FIELDNAMES.ORGANIZATION, true)}
                  onChange={onOrgSelect(formikProps)}
                  dataCy="creator-invite-form__org-select"
                  loading={accounts.loading}
                  isSearchable
                />
              )}
              {hasMultipleAccounts && (
                <Field
                  name={FIELDNAMES.ACCOUNT}
                  component={SelectInput}
                  options={accounts.data}
                  disabled={accounts.loading || !selectedOrgId}
                  onChange={onAccountSelect(formikProps)}
                  placeholder={makeLabel(FIELDNAMES.ACCOUNT, true)}
                  loading={accounts.loading || briefs.loading}
                  dataCy="creator-invite-form__account-select"
                  isSearchable
                />
              )}
              {accounts.error && <Text className="creator-invite-form__error">{accounts.error}</Text>}

              {showBriefSelect && (
                <>
                  <Text className="creator-invite-form__description">
                    {CREATOR_COPY.MODAL_BRIEF_DESCRIPTION_INVITE_CREATORS}
                  </Text>
                  <Field
                    name={FIELDNAMES.BRIEF}
                    component={SelectInput}
                    options={briefs.data}
                    disabled={briefs.loading}
                    placeholder={makeLabel(FIELDNAMES.BRIEF, true)}
                    loading={briefs.loading}
                    dataCy="creator-invite-form__brief-select"
                    isSearchable
                  />
                </>
              )}
              <NotificationBanner
                className="creator-invite-form__info-message"
                theme="info"
                message={CREATOR_COPY.MODAL_INFO_INVITE_CREATORS}
              />
              <ModalFormHr />
              <Flex className="creator-invite-form__button-wrapper">
                <FormButton
                  type="button"
                  variant={BUTTON_VARIANTS.TEXT}
                  className="creator-invite-form__cancel-btn"
                  onClick={onCancel}
                >
                  {GENERIC_COPY.BUTTON_CANCEL}
                </FormButton>
                <FormButton
                  type="submit"
                  variant={BUTTON_VARIANTS.PRIMARY}
                  className="creator-invite-form__submit-btn"
                  dataCy="creator-invite-form__submit-btn"
                  loading={submitting}
                >
                  {CREATOR_COPY.BUTTON_SUBMIT_INVITE}
                </FormButton>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CreatorInviteForm;

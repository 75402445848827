export const TAG_COPY = {
  // Buttons
  BUTTON_DONE: 'Done',
  // Placeholders
  PLACEHOLDER_ADD: 'Add tags',

  // errors
  ERROR_MAX_TAGS: 'You can only select up to {maxTags} tags',

  // instructions
  INSTRUCTION_ADD_TAG: `Press the <Enter> key to add a tag`,

  // Form validation
  VALIDATION_MISSING_TAG_CATEGORY: 'You must enter a category',
  VALIDATION_MISSING_TAG_VALUE: 'You must enter a value',
};

import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import SHADOWS from 'shared/styles/shadow';

export default css({
  '.review-overlay-modal': {
    '&__header': {
      position: 'relative',
      width: '100%',
      height: '20px',
    },
    '&__close-btn': {
      opacity: '1.0',
      color: COLORS.BLACK,
      height: '44px',
      width: '44px',
      fontWeight: 1000,
      position: 'absolute',
      right: '0px',
      top: '-5px',
    },
  },
});

export const reviewOverlayModalCss = {
  overlay: {
    position: 'absolute',
    height: 'fit-content',
    maxHeight: '98vh',
    maxWidth: '550px',
    background: 'rgba(255, 255, 255, 1.0)',
    zIndex: zIndex.zOverlay,
    borderRadius: '10px',
    border: `solid 1px ${COLORS.NEUTRAL200}`,
    boxShadow: SHADOWS.SHADOW03,
    margin: 'auto',
  },
  content: {
    overflow: 'visible',
  },
};

import React from 'react';
import { faAngleRight, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { Box, Flex, Text } from 'shared/components/display';

import { USER_COPY } from 'shared/config/copy';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';

const SideNav = ({ sections, activeForm, onTabClick, isMobile = false }) => (
  <Flex className="creator-account-settings__side-nav">
    <Text variant={TEXT_VARIANTS.H4} className="creator-account-settings__side-nav-header">
      Account Details
    </Text>
    <Flex className="creator-account-settings__side-nav-subheading-wrapper">
      <Text variant={TEXT_VARIANTS.SUBHEADING_SM} className="creator-account-settings__side-nav-text">
        {USER_COPY.HEADING_CREATOR_ACCOUNT_SETTINGS}
      </Text>
      <Text
        variant={TEXT_VARIANTS.BODY}
        color={COLORS.NEUTRAL500}
        className="creator-account-settings__side-nav-description"
      >
        {USER_COPY.DESCRIPTION_ACCOUNT_SETTINGS}
      </Text>
    </Flex>

    {sections.map(({ name, icon }) => (
      <Box
        key={name}
        onClick={() => onTabClick(name)}
        className={classNames('creator-account-settings__btn', {
          'creator-account-settings__btn--active': activeForm === name,
        })}
      >
        <Flex className="creator-account-settings__btn-content">
          <FontAwesomeIcon icon={icon} className="creator-account-settings__left-icon" fixedWidth />
          <span className="creator-account-settings__btn-name">{name}</span>
          <FontAwesomeIcon
            icon={isMobile ? faAngleUp : faAngleRight}
            className="creator-account-settings__right-icon"
            fixedWidth
          />
        </Flex>
      </Box>
    ))}
  </Flex>
);

export default SideNav;

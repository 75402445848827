import React from 'react';
import cs from 'classnames';
import { Box, Text } from 'shared/components/display';
import styles from './styles';
import { formatTimeAgo, prettyStringDate } from 'shared/utilities/dateUtility';
import Tooltip from 'shared/components/atoms/Tooltip';
import { intl } from 'shared/lib/intl';

interface Props {
  name?: string;
  dateCreated: string;
  dateUpdated?: string;
  children?: React.ReactNode;
}

const TOOLTIP_DELAY = 500;

const AuthorTimeDisplay: React.FC<Props> = ({ name, dateCreated, dateUpdated, children }) => {
  const updated = dateUpdated ? dateCreated !== dateUpdated : false;

  return (
    <Box css={styles} className={cs('author-time-display')}>
      <Text as="span" className="author-time-display__author-name">
        {name}
      </Text>
      <Text
        as="span"
        className={cs('author-time-display__time-ago', {
          'author-time-display__time-ago--has-sibling': children !== undefined,
        })}
      >
        <Tooltip as="span" delay={TOOLTIP_DELAY} content={prettyStringDate(dateCreated)}>
          {intl.formatMessage(
            {
              id: 'TIME_AGO',
            },
            {
              date: formatTimeAgo(dateCreated),
            },
          )}
        </Tooltip>
        {updated && dateUpdated && (
          <Tooltip
            as="span"
            delay={TOOLTIP_DELAY}
            content={intl.formatMessage(
              {
                id: 'TIME_AGO',
              },
              {
                date: formatTimeAgo(dateUpdated),
              },
            )}
          >
            {' '}
            (edited)
          </Tooltip>
        )}
      </Text>
      {children}
    </Box>
  );
};

export default AuthorTimeDisplay;

import { css } from '@emotion/react';
import { DROPBOX_CLASS } from 'shared/components/DropBox/DropBox';
import theme from 'shared/styles/themes';

export default css({
  '&.creator-collection': {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: `${theme.space[3]} ${theme.space[5]}`,
  },
  '.creator-collection': {
    '&__toggle': {
      alignSelf: 'flex-end',
    },
  },
  [`.${DROPBOX_CLASS}`]: {
    marginBottom: `${theme.space[5]}`,
    flexGrow: 1,
    width: `calc(100% - ${theme.space[5]}*2)`,
  },
});

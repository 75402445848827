import React from 'react';
import { NavLink } from 'react-router-dom';
import { faCommentAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import NotificationBadge from 'shared/components/atoms/NotificationBadge';

import { NOTIFICATIONS } from 'shared/config/notifications';
import { useNotifications } from 'shared/hooks/useNotifications';
import COLORS from 'shared/styles/colors';
import { LinkCreator } from 'shared/utilities/linkUtility';

const MessageNavItem = ({ organizationId }) => {
  const { getFormattedOrgNotificationCount } = useNotifications();

  const badgeCount = getFormattedOrgNotificationCount(NOTIFICATIONS.UNREAD_MESSAGES, organizationId);

  return (
    <MessageNav
      to={LinkCreator.createLink({
        routeKey: 'MESSAGES',
        variables: {
          organizationId,
        },
      })}
      className={(navData) => (navData.isActive ? 'active' : 'none')}
      onClick={(e) => e.currentTarget.blur()}
    >
      <BadgeWrapper>
        {badgeCount ? (
          <NotificationBadge top="-0.5rem" right="0.5rem">
            {badgeCount}
          </NotificationBadge>
        ) : (
          <></>
        )}
        <FontAwesomeIcon icon={faCommentAlt} fixedWidth />
      </BadgeWrapper>
    </MessageNav>
  );
};

export default MessageNavItem;

const MessageNav = styled(NavLink)`
  position: relative;
  padding-right: 1rem;
  margin-right: 1rem;
  border-right: 1px solid ${COLORS.NEUTRAL100};
`;

const BadgeWrapper = styled.span`
  & > svg {
    height: 100%;
  }
`;

import React, { useCallback } from 'react';
import { merge } from 'lodash';
import CenteredSpinner from 'features/ui/CenteredSpinner';
import { CONFIGURATION_COPY } from 'shared/config/copy/configurationCopy';
import { useConfigurations } from 'features/accountPlatforms/facebook/useConfigurations';
import { noOp } from 'shared/defaults';
import { Formik, Form, Field } from 'formik';
import TextInput from 'shared/components/TextInput';
import FormButton from 'shared/components/FormButton';
import * as yup from 'yup';
import { MESSAGES } from 'shared/config/validations';
import { Box } from 'rebass';
import { makeLabel } from 'shared/lib/formik';

// fieldnames
const NAME = 'name';
const DESCRIPTION = 'description';

const initialValues = {
  name: '',
  description: '',
};

const validationSchema = yup.object({
  name: yup.string().customRequiredString(),
  description: yup.string(),
});

interface Values {
  name: string;
  description?: string;
}

interface Props {
  type: string;
  entityType: string;
  entityId: number;
  onRequestClose: Function;
  formData: { [field: string]: any };
}

const SaveConfigurationForm: React.FC<Props> = ({ type, entityType, entityId, onRequestClose = noOp, formData }) => {
  const { loading, createConfiguration } = useConfigurations();

  const saveCallback = useCallback(
    (nameData) => {
      let configObj = {
        type,
        entityType,
        entityId,
        value: formData,
      };
      configObj = merge(configObj, nameData);
      createConfiguration(configObj);
      onRequestClose();
    },
    [formData],
  );

  return (
    <>
      <Formik<Values> onSubmit={saveCallback} initialValues={initialValues} validationSchema={validationSchema}>
        <Form>
          <Field component={TextInput} name={NAME} label={makeLabel(NAME, true)} placeholder={makeLabel(NAME, true)} />
          <Field
            component={TextInput}
            name={DESCRIPTION}
            label={makeLabel(DESCRIPTION, false)}
            placeholder={makeLabel(DESCRIPTION, false)}
          />
          <Box py={2}>
            <FormButton>{CONFIGURATION_COPY.BUTTON_SAVE_CONFIGURATION}</FormButton>
          </Box>
        </Form>
      </Formik>
      {loading && <CenteredSpinner />}
    </>
  );
};
export default SaveConfigurationForm;

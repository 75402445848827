import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.approval-buttons': {
    justifyContent: 'space-evenly',
  },
  '.approval-buttons': {
    '&__icon-wrapper': {
      justifyContent: 'space-evenly',
      alignItems: 'center',
      color: COLORS.WHITE,
    },
    '&__approve-btn, &__reject-btn': {
      color: COLORS.WHITE,
      cursor: 'pointer',
      fontWeight: 600,
      height: 'min-content',
      padding: `${theme.space[3]} ${theme.space[5]}`,
      minWidth: '7rem',
      [mediaquery.md]: {
        minWidth: '9rem',
      },

      '&:not(:disabled) svg': {
        color: COLORS.WHITE,
      },
      '&:disabled': {
        cursor: 'default',
        opacity: 0.3,
      },
    },
    '&__approve-btn': {
      backgroundColor: COLORS.GREEN500,
      marginLeft: theme.space[4],
      '&:not(:disabled):hover': {
        backgroundColor: COLORS.GREEN700,
      },
    },
    '&__reject-btn': {
      backgroundColor: COLORS.RED500,
      '&:not(:disabled):hover': {
        backgroundColor: COLORS.RED700,
      },
    },
  },
});

import { SelectValue } from 'shared/components/molecules/Select';

export const DELIMINATOR = ': ';

export enum PaginationSortType {
  ALPHABET,
  TIME,
  PRICE,
  RELEVANCE,
}
export type SortOptions<V = string> = (SelectValue<V> & { sortType: PaginationSortType })[];
export type SingleOptions<V = string> = (SelectValue & { orderAsc: boolean })[];
export const ORDER_MAP: Record<PaginationSortType, { ascending: string; descending: string }> = {
  [PaginationSortType.ALPHABET]: {
    ascending: 'A-Z',
    descending: 'Z-A',
  },
  [PaginationSortType.TIME]: {
    ascending: 'Earliest',
    descending: 'Latest',
  },
  [PaginationSortType.PRICE]: {
    ascending: 'Lowest',
    descending: 'Highest',
  },
  [PaginationSortType.RELEVANCE]: {
    ascending: 'Asc',
    descending: 'Desc',
  },
};

import { css } from '@emotion/react';

import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';

export const MAIN_CLASS = 'performance-table';
export const CLASSNAMES = {
  table: `${MAIN_CLASS}__table`,
  tableLong: `${MAIN_CLASS}__table--long`,
  overlay: `${MAIN_CLASS}__overlay`,
  overlayDisplay: `${MAIN_CLASS}__overlay--display`,
  overlayContentBtn: `${MAIN_CLASS}__overlay-content-btn`,
};
// @ts-ignore
export default css({
  [`&.${MAIN_CLASS}`]: {
    position: 'relative',
    backgroundColor: COLORS.WHITE,
    flexDirection: 'column',
  },
  [`.${MAIN_CLASS}`]: {
    '&__overlay': {
      transition: 'all 0.3s ease',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: 'rgba(255, 255, 255, 0.8)',
      zIndex: -1,
      opacity: 0,
    },
    '&__overlay--display': {
      zIndex: 3,
      opacity: 1,
    },
    '&__overlay-content-btn': {
      position: 'absolute',
      display: 'block',
      textAlign: 'center',
      width: '100%',
      top: '20rem',
      left: 0,
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    '&__table': {
      '&.ReactTable .rightAlign': {
        textAlign: 'right',
      },

      '&.ReactTable .leftAlign ': {
        textAlign: 'left',
      },

      '&.ReactTable .centerAlign ': {
        textAlign: 'center',
      },

      '&.ReactTable .rt-td ': {
        backgroundColor: 'unset',
      },

      '&.ReactTable .rt-thead.-header,&.ReactTable .rthfc-td-fixed,&.ReactTable .rt-tbody .rt-tr,&.ReactTable .rt-tfoot,&.ReactTable .rt-tfoot rt-tr rt-td,&.ReactTable .-headerGroups .rt-th,&.ReactTable .-pagination .-btn[disabled] ':
        {
          backgroundColor: COLORS.WHITE,
        },

      '&.ReactTable .rt-thead .rt-tr .rt-th,&.ReactTable .rt-tbody .rt-tr .rt-td,&.ReactTable .rt-tfoot .rt-tr .rt-td ':
        {
          borderRight: 'unset',
        },

      '&.ReactTable .rt-tbody .rt-tr .rt-td,&.ReactTable .rt-tfoot .rt-tr .rt-td ': {
        paddingTop: '1rem',
        paddingBottom: '1rem',
      },

      '&.ReactTable .rt-thead.-header ': {
        top: '0px !important',
        boxShadow: 'none',
        borderBottom: `solid 2px ${COLORS.NEUTRAL100}`,
        fontSize: '0.875rem',
        fontWeight: '600',
        letterSpacing: '0.05rem',
        textTransform: 'uppercase',
      },
      '&.ReactTable .rt-thead .rt-tr .rt-th ': {
        '& .rt-resizable-header-content ': {
          height: '100%',
          display: 'inline-flex',
          alignItems: 'center',
        },

        '&:hover ': {
          backgroundColor: COLORS.NEUTRAL100,
        },

        '&.-sort-asc ': {
          boxShadow: `inset 0 3px 0 0 ${COLORS.PRIMARY300}`,
        },
        '&.-sort-desc ': {
          boxShadow: `inset 0 -3px 0 0 ${COLORS.PRIMARY300}`,
        },
      },
      '&.ReactTable .rt-tbody .rt-tr ': {
        '&:hover ': {
          backgroundColor: COLORS.NEUTRAL100,
        },
        '& .rt-td ': {
          fontSize: '0.875rem',
          borderBottom: `solid 1px ${COLORS.NEUTRAL50}`,
        },
      },
      '&.ReactTable .rt-tfoot ': {
        boxShadow: 'none',

        '& .rt-tr .rt-td ': {
          borderTop: `solid 2px ${COLORS.NEUTRAL100}`,
        },
      },
      '&.ReactTable .performance-pagination': {
        boxShadow: 'none',
      },

      '&.ReactTable .rt-th,&.ReactTable .-headerGroups .rt-th ': {
        whiteSpace: 'normal',
      },

      '&.ReactTable .rt-thead.-headerGroups .rt-tr .rt-th ': {
        '&:first-of-type ': {
          position: 'sticky',
          zIndex: `${zIndex.zForeground}`,
          left: 0,
        },
      },

      '.rthfc-th-fixed-left-last, .rthfc-td-fixed-left-last ': {
        borderRight: `solid 2px ${COLORS.NEUTRAL100} !important`,
      },

      '&.ReactTable .rt-th .rt-resizable-header-content:after ': {
        color: COLORS.BLACK,
        fontSize: '0.75rem',
      },

      '&.ReactTable .rt-th.-sort-asc .rt-resizable-header-content:after ': {
        content: '"▲"',
      },

      '&.ReactTable .rt-th.-sort-desc .rt-resizable-header-content:after ': {
        content: '"▼"',
      },

      '&.ReactTable .-loading > div ': {
        top: '50%',
      },
    },
    '&__table--long': {
      '&.ReactTable .-loading > div ': {
        top: '20rem',
      },
    },
  },
});

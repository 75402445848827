import { createSlice, createSelector } from '@reduxjs/toolkit';
import { prettyDate } from 'shared/utilities/dateUtility';
import { formatCurrency } from 'shared/utilities/stringUtility';
import { INVOICE_COPY } from 'shared/config/copy/invoiceCopy';

const { PLACEHOLDER_NO_UPCOMING } = INVOICE_COPY;

const invoicesSlice = createSlice({
    name: 'invoices',
    initialState: {
        data: [],
        checkoutInvoice: {},
        hasMore: false,
    },
    reducers: {
        invoicesLoaded: (invoices, action) => {
            invoices.data = action.payload.data.data;
            invoices.hasMore = action.payload.data.hasMore;
        },
        checkoutInvoiceAdded: (invoices, action) => {
            invoices.checkoutInvoice = action.payload.data;
        },
        checkoutInvoiceReset: (invoices) => {
            invoices.checkoutInvoice = {};
        },
    },
});

export const {
    invoicesLoaded,
    checkoutInvoiceAdded,
    checkoutInvoiceReset,
} = invoicesSlice.actions;

export const getUpcomingInvoice = (state) => state.invoices.data[0];

export const getUpcomingInvoiceAmountDue = createSelector(
    getUpcomingInvoice,
    (invoice) => invoice && formatCurrency(invoice.amountDue / 100 || 0),
);
export const getUpcomingInvoicePaymentDate = createSelector(
    getUpcomingInvoice,
    (invoice) =>
        invoice && invoice.nextPaymentAttempt
            ? prettyDate(invoice.nextPaymentAttempt)
            : PLACEHOLDER_NO_UPCOMING,
);
export const getUpcomingInvoicePayPeriod = createSelector(
    getUpcomingInvoice,
    (invoice) => {
        if (invoice && invoice.periodStart) {
            return `${prettyDate(invoice.periodStart)} - ${prettyDate(
                invoice.periodEnd,
            )}`;
        }
        return PLACEHOLDER_NO_UPCOMING;
    },
);

export const getPastInvoices = (state) => state.invoices.data;

export const getInvoiceHasMore = (state) => state.invoices.hasMore;

export const getFirstInvoiceId = createSelector(getPastInvoices, (invoices) =>
    invoices[0] ? invoices[0].id : null,
);

export const getLastInvoiceId = createSelector(getPastInvoices, (invoices) =>
    invoices[invoices.length - 1] ? invoices[invoices.length - 1].id : null,
);

export const getCheckoutInvoice = (state) => state.invoices.checkoutInvoice;

export default invoicesSlice.reducer;

// * Lib
import 'react-circular-progressbar/dist/styles.css';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';
import cs from 'classnames';

// * Util
import { useFiles } from 'features/media/useFiles';
import { newFilesUploadComplete, uploadDrawerCleared } from 'features/ui/ui.slice';
// * Display/UI
import { Flex } from 'shared/components/display';
import { FileUploadStatus } from 'shared/typings/file/enums';

// * Styles
import styles from './styles';
import UploadStatusDrawerHeader from './UploadDrawerHeader';
import UploadDrawerItem from './UploadDrawerItem';
import RefreshPrompt from 'shared/components/atoms/RefreshPrompt';

const UploadStatusDrawer: React.FC<any> = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const [stillUploading, setStillUploading] = useState(true);
  const { showUploadDrawer, updateFileUpload, filesUploadComplete, newFilesLoading, filesUploading } = useFiles();
  const dispatch = useDispatch();

  ReactModal.setAppElement('#root');

  useEffect(() => {
    const filesStillUploading = filesUploading.some((file) => {
      return (
        (file.status === FileUploadStatus.UPLOADING && file.errors?.length === 0) ||
        file.status === FileUploadStatus.UPLOAD_ATTEMPTING_CANCEL
      );
    });

    setStillUploading(filesStillUploading);

    if (!filesStillUploading) {
      dispatch(newFilesUploadComplete());
    }
  }, [filesUploading]);

  const onClear = () => {
    dispatch(uploadDrawerCleared());
  };

  const onDrawerArrowClick = () => setCollapsed((prev) => !prev);

  return (
    <ReactModal
      isOpen={showUploadDrawer && filesUploading.length > 0}
      overlayClassName="upload-status-drawer__click-overlay"
      className="upload-status-drawer-modal"
      id="drawer-root"
    >
      <RefreshPrompt disabled={!stillUploading} />
      <Flex className="upload-status-drawer" css={styles}>
        <UploadStatusDrawerHeader
          isCollapsed={isCollapsed}
          onDrawerArrowClick={onDrawerArrowClick}
          onClear={onClear}
          filesUploading={filesUploading}
          filesUploadComplete={filesUploadComplete}
        />
        <ul
          className={cs('upload-status-drawer__list', {
            'upload-status-drawer__list--open': !isCollapsed,
          })}
        >
          {filesUploading?.map((file, index) => (
            <UploadDrawerItem
              key={`${file.name}-${index}`}
              file={file}
              newFilesLoading={newFilesLoading}
              filesUploading={filesUploading}
              updateFileUpload={updateFileUpload}
              dataCy={`upload-drawer-item__${file.name}`}
            />
          ))}
        </ul>
      </Flex>
    </ReactModal>
  );
};

export default UploadStatusDrawer;

import '@yaireo/tagify/dist/tagify.css'; // Tagify CSS

import React from 'react';
import Tagify from '@yaireo/tagify/dist/react.tagify'; // React-wrapper file
import cs from 'classnames';

import tagsStyles from 'shared/components/atoms/Tags/tagsStyles';
import { BackspaceSetting, TagifySettings } from 'shared/components/atoms/Tags/types';
import { Box } from 'shared/components/display';

interface Props {
  className?: string;
  name?: string;
  onBlur?: () => void;
  onChange?: (e: { detail: { value: string } }) => void;
  placeholder?: string;
  readOnly?: boolean;
  settings?: TagifySettings;
  value: any[];
  whitelist?: any[];
  backspace?: BackspaceSetting;
  dataCy?: string;
}

const Tags = React.forwardRef<typeof Tagify, Props>(
  (
    {
      className,
      name,
      onBlur,
      onChange,
      placeholder,
      readOnly = false,
      value,
      whitelist,
      settings,
      backspace = false,
      dataCy,
    },
    ref,
  ) => {
    return (
      <Box
        className={cs(
          'tags',
          {
            'tags--readonly': readOnly,
          },
          className,
        )}
        css={tagsStyles}
        dataCy={dataCy}
      >
        <Tagify
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={readOnly}
          settings={settings}
          tagifyRef={ref}
          value={value}
          whitelist={whitelist}
          backspace={backspace}
        />
      </Box>
    );
  },
);

export default Tags;

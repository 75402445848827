import { addHttp } from 'shared/utilities/stringUtility';
import React from 'react';

interface Props {
  href: string;
  text?: string | React.ReactNode;
  className?: string;
}
const ExternalLink: React.FC<Props> = ({ href, text, className }) => {
  const noPropagation = (event) => event.stopPropagation();
  return (
    <a href={addHttp(href)} target="_blank" rel="noopener noreferrer" onClick={noPropagation} className={className}>
      {text || href}
    </a>
  );
};

export default ExternalLink;

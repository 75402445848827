import { css } from '@emotion/react';
import COLORS from 'shared/styles/colors';

export default css({
  '&.file-list-item': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${COLORS.NEUTRAL200}`,
    borderRadius: '4px',
    marginBottom: '8px',
    backgroundColor: COLORS.WHITE,
  },
  '.file-list-item': {
    '&__name': {
      flex: 1,
      userSelect: 'none',
      margin: '8px 0',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&__icon': {
      margin: '0 8px',
    },
    '&__name-icon': {
      alignItems: 'center',
      overflow: 'hidden',
    },
    '&__name-icon--clickable': {
      cursor: 'pointer',
    },
  },
});

import React from 'react';
import { faArrowAltCircleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

import { ContributorAssessment, ContributorImpact } from 'features/accountPlatforms/types';
import Chip from 'shared/components/atoms/Chip/Chip';
import { Flex, Text } from 'shared/components/display';

import { intl } from 'shared/lib/intl';
import COLORS from 'shared/styles/colors';
import { TEXT_VARIANTS } from 'shared/styles/text';

import getStyles from './accountHealthContributorStyles';
interface Props {
  title: string;
  score: string;
  description: string;
  cta: string;
  impact: ContributorImpact;
  isExpanded?: boolean;
  assessment: ContributorAssessment;
  onClick?: () => void;
  isRatio: boolean;
}

const AccountHealthContributor = ({
  title,
  score,
  description,
  cta,
  impact,
  assessment,
  isExpanded = false,
  onClick,
  isRatio,
}: Props) => {
  const formatMessage = intl.formatMessage;
  const getImpactColor = () => {
    switch (assessment) {
      case ContributorAssessment.Weak:
        return COLORS.RED500;
      case ContributorAssessment.Moderate:
        return COLORS.ORANGE500;
      default:
        return COLORS.GREEN500;
    }
  };

  const getLabelColor = () => {
    switch (assessment) {
      case ContributorAssessment.Weak:
        return COLORS.RED700;
      case ContributorAssessment.Moderate:
        return COLORS.ORANGE700;
      default:
        return COLORS.GREEN700;
    }
  };

  const getLabelBackgroundColor = () => {
    switch (assessment) {
      case ContributorAssessment.Weak:
        return COLORS.RED100;
      case ContributorAssessment.Moderate:
        return COLORS.ORANGE100;
      default:
        return COLORS.GREEN100;
    }
  };

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Flex
      css={getStyles({
        color: getImpactColor(),
      })}
      className={classnames('account-health-contributor', {
        'account-health-contributor--expanded': isExpanded,
      })}
      onClick={handleClick}
    >
      <Flex className="account-health-contributor__top-section">
        <Text variant={TEXT_VARIANTS.SUBHEADING} className="account-health-contributor__title">
          {title}
        </Text>
        <Chip
          label={ContributorAssessment[assessment]}
          color={getLabelColor()}
          bgColor={getLabelBackgroundColor()}
          square
        />
      </Flex>

      <Flex
        className={classnames('account-health-contributor__bottom-section', {
          'account-health-contributor__bottom-section--expanded': isExpanded,
        })}
      >
        {isExpanded && (
          <Text variant={TEXT_VARIANTS.BODY} className="account-health-contributor__description">
            {description}
            &nbsp;
            {formatMessage({ id: 'LABEL_CONTRIBUTOR_IMPACT' }, { impact: ContributorImpact[impact].toLowerCase() })}
          </Text>
        )}

        {isExpanded && (
          <Text variant={TEXT_VARIANTS.SUBHEADING} className="account-health-contributor__impact">
            <FontAwesomeIcon icon={faArrowAltCircleRight} size="xs" color={COLORS.PRIMARY500} />
            &nbsp;
            {cta}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default AccountHealthContributor;

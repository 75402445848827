import 'tippy.js/themes/light-border.css';

import React, { useCallback, useState } from 'react';
import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseSelection } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { Instance } from 'tippy.js';

import LazyTooltip from 'shared/components/interactive/LazyTooltip';
import { RichTextLinkMenu } from 'shared/components/Richtext/RichTextLink';

type Props = {
  onHide: () => void;
  onCreate: (instance: Instance) => void;
};

const RichTextLinkIcon: React.FC<Props> = ({ onCreate, onHide }) => {
  const editor = useSlateStatic();
  const [selection, setSelection] = useState<BaseSelection | null>(null);

  const onHidden = useCallback(() => {
    ReactEditor.focus(editor);
    ReactEditor.blur(editor);
  }, [editor]);

  return (
    <LazyTooltip
      onCreate={onCreate}
      onShow={() => {
        setSelection(editor.selection);
      }}
      content={<RichTextLinkMenu isEditMode editor={editor} selection={selection} onHide={onHide} />}
      allowHTML
      theme="light-border"
      trigger="manual"
      interactive
      placement="bottom-start"
      offset={[-20, 10]}
      onHidden={onHidden}
    >
      <FontAwesomeIcon icon={faLink} className=" richtext-tool-bar__icon" />
    </LazyTooltip>
  );
};

export default RichTextLinkIcon;

import React from 'react';
import { FormikErrors } from 'formik';
import { GroupBase } from 'react-select';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import { Input } from 'shared/lib/formik';
import axiosClient from 'shared/axiosClient';
import { MODAL_PORTAL_TARGET_SELECTOR } from 'shared/components/molecules/Modal';
import { isEmpty } from 'lodash';
import { defaultOption, components, styles } from './config';
import { CollectionSelectData, Value } from './types';
import { LinkCreator } from 'shared/utilities/linkUtility';
import { API_COLLECTION_ROUTES } from 'shared/config/routes/api/apiCollectionRoutes';
import { Collection } from 'shared/typings/collections';
import { Box } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';

interface Props {
  placeholder?: string;
  width?: string;
  extraParams: { accountId?: number; organizationId?: number; creatorId?: number };
  className?: string;
  currentCollectionId?: number;
}

const defaultOptions = [defaultOption];

const AsyncCollectionSelect: Input<Value, Props> = ({
  field: { value, name, onChange, onBlur },
  form: { errors, touched },
  placeholder,
  width,
  extraParams,
  className,
  currentCollectionId,
}) => {
  const error = errors[name] as FormikErrors<any> | undefined;
  const hasErrors = error && Boolean(touched[name]);

  const loadOptions: LoadOptions<CollectionSelectData, GroupBase<CollectionSelectData>, { page: number }> = async (
    search,
    loadedOptions,
    additional,
  ) => {
    const page = additional?.page || 1;

    const { data } = await axiosClient.httpRequest<{ data: Collection[]; total: number }>({
      url: LinkCreator.createApiLink({
        route: API_COLLECTION_ROUTES.COLLECTIONS,
      }),
      params: {
        ...extraParams,
        name: search,
        page,
        size: 9,
        orderBy: 'name',
      },
      method: 'GET',
    });
    const newOptions = data.data.map(
      (collection): CollectionSelectData => ({
        value: collection,
        label: collection.name,
        isDisabled: collection.id === currentCollectionId,
      }),
    );

    return {
      options: newOptions,
      hasMore: loadedOptions.length + newOptions.length < data.total,
      additional: {
        page: page + 1,
      },
    };
  };

  const setInputValue = (value: Value) => {
    const event = {
      target: { name, value },
    };
    onChange(event);
    onBlur(event);
  };

  return (
    <Box>
      <AsyncPaginate
        closeMenuOnSelect
        className={className}
        components={components}
        debounceTimeout={300}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        menuPlacement="auto"
        // Whether the select menu should use a portal, and where it should attach.
        // @ts-ignore
        menuPortalTarget={document.querySelector(MODAL_PORTAL_TARGET_SELECTOR)}
        name="collection-select"
        onChange={setInputValue}
        placeholder={placeholder}
        value={value}
        // @ts-ignore
        width={width}
        hasErrors={hasErrors}
        hasValue={!isEmpty(value)}
        styles={styles}
      />
      <FieldErrorMessage name={name} />
    </Box>
  );
};

export default AsyncCollectionSelect;

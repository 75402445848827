import { CONSTRAINTS, MESSAGES } from 'shared/config/validations';
import * as yup from 'yup';

export const marketerInfoSchema = yup.object({
  firstName: yup
    .string()
    .matches(CONSTRAINTS.NO_SPECIAL_CHARACTERS_CONSTRAINT, {
      message: MESSAGES.NO_SPECIAL_CHARACTERS,
    })
    .customRequiredString(),
  lastName: yup
    .string()
    .matches(CONSTRAINTS.NO_SPECIAL_CHARACTERS_CONSTRAINT, {
      message: MESSAGES.NO_SPECIAL_CHARACTERS,
    })
    .customRequiredString(),
  email: yup.string().email(MESSAGES.EMAIL).customRequiredString(),
  secondaryEmail: yup.string().email(MESSAGES.EMAIL),
});

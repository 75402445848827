import React from 'react';
import cs from 'classnames';
import { Box } from 'shared/components/display';
import styles from './styles';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import PromptButton from 'shared/components/molecules/PromptButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';

interface Props {
  onClick: () => void;
  deleteConfirmationText: string;
  className?: string;
  variant?: string;
  display?: 'vertical' | 'horizontal';
}
const DeleteButton: React.FC<Props> = ({
  onClick,
  deleteConfirmationText,
  className,
  variant = BUTTON_VARIANTS.TEXT_ONLY,
  display = 'vertical',
}) => {
  return (
    <Box as="span" css={styles} className={cs('delete-button', className, `delete-button--${display}`)}>
      <PromptButton variant={variant} onConfirm={onClick} className="delete-button__btn" text={deleteConfirmationText}>
        <FontAwesomeIcon icon={faTrash} className="delete-button__btn-icon" />
        Delete
      </PromptButton>
    </Box>
  );
};

export default DeleteButton;

const BASE = '/org/:organizationId/';

export default {
  ORGANIZATION: BASE,

  ACCOUNT: BASE + 'account/:accountId',

  CREATE_ACCOUNT: BASE + 'new-account',

  CARD_SETTINGS: BASE + 'settings/cards',

  EDIT_ORG_DETAILS: BASE + 'settings/edit',

  EDIT_ROLE_SETTINGS: BASE + 'settings/roles/edit',

  EDIT_USER_SETTINGS: BASE + 'settings/users/edit',

  MESSAGES: BASE + 'messaging',

  REASSIGN_ROLES: BASE + 'settings/roles/reassign',

  ROLE_SETTINGS: BASE + 'settings/roles',

  ORG_SETTINGS_USERS: BASE + 'settings/users',

  ORG_SETTINGS: BASE + 'settings',

  EDIT_BRIEF_TEMPLATES: BASE + 'templates/edit',

  EDIT_BRIEF_TEMPLATE: BASE + 'templates/edit/:briefTemplateId',
};

import React from 'react';
import Skeleton from 'shared/components/atoms/Skeleton';
import { Box } from 'rebass/styled-components';

interface Props {
  layout: {
    index: number;
    width: number;
    height: number;
  };
  layoutOptions: {
    spacing: number;
  };
  maxHeight: number;
  ariaLabelledBy?: string;
}
const LoadingSkeleton: React.FC<Props> = ({
  layout,
  layoutOptions: { spacing: margin },
  maxHeight,
  ariaLabelledBy,
}) => {
  const { index, width, height } = layout;

  return (
    <Box key={index} width={width} px={`${margin}px`} aria-labelledby={ariaLabelledBy}>
      <Box mb={2}>
        <Skeleton height={height} />
      </Box>
      <Skeleton />
    </Box>
  );
};

export default LoadingSkeleton;

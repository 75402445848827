import React, { useMemo, useState } from 'react';
import { Button } from 'rebass/styled-components';
import { faDownload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { partition } from 'lodash';

import { useFiles } from 'features/media/useFiles';
import Tooltip from 'shared/components/atoms/Tooltip';
import { Box } from 'shared/components/display';
import Modal from 'shared/components/molecules/Modal';
import Prompt from 'shared/components/molecules/Prompt';
import SplitButton from 'shared/components/molecules/SplitButton';

import { COLLECTION_COPY } from 'shared/config/copy';
import { SIZING } from 'shared/config/formatting';
import { COLLECTION_TOASTS } from 'shared/config/toasts/collectionToasts';
import { useToasts } from 'shared/hooks/useToasts';
import { intl } from 'shared/lib/intl';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { BrkfstFile } from 'shared/typings/file';

import styles from './styles';

interface Props {
  selectedFiles: BrkfstFile[];
  downloadInfo: {
    brandName: string;
    orgName: string;
    briefName?: string;
    collectionName: string;
  };
  tooltip?: string;
  promptTitle?: string;
  splitButtonVariant?: 'mini' | 'default' | 'borderless';
}
const DownloadFilesButton: React.FC<Props> = ({
  selectedFiles,
  downloadInfo,
  tooltip = COLLECTION_COPY.TOOLTIP_DOWNLOAD_FILES,
  promptTitle = COLLECTION_COPY.MODAL_DOWNLOAD_CREATIVE_FILES,
  splitButtonVariant = 'mini',
}) => {
  const { downloadCreatives } = useFiles();
  const { setErrorToast, setSuccessToast } = useToasts();
  const [modalOpen, setModalOpen] = useState(false);
  const [validFileIds, setValidFileIds] = useState<number[]>([]);

  const setToast = (requestedFileIds, validFileIds) => {
    if (validFileIds.length === 0) setErrorToast({ message: COLLECTION_TOASTS.ERROR_DOWNLOAD_FILES });
    else if (requestedFileIds.length !== validFileIds.length) {
      setModalOpen(true);
      setValidFileIds(validFileIds);
    } else if (requestedFileIds.length === validFileIds.length)
      setSuccessToast({
        message: COLLECTION_TOASTS.SUCCESS_DOWNLOAD_FILES,
      });
  };

  const downloadFiles = (requestedFileIds, withWatermark = false) => {
    downloadCreatives({
      fileIds: requestedFileIds,
      withWatermark,
      downloadInfo,
      onSuccess: (response) => setToast(requestedFileIds, response.data.downloadableFileIds),
    });
  };

  const selectedFileIds = selectedFiles.map(({ id }) => id);
  const downloadSelectedFiles = () => downloadFiles(selectedFileIds);
  const downloadSelectedFilesWithWatermark = () => downloadFiles(selectedFileIds, true);

  const toggleModal = () => setModalOpen((prev) => !prev);

  const [validFiles, invalidFiles] = useMemo(() => {
    return partition(selectedFiles, (file) => validFileIds.includes(file.id));
  }, [selectedFiles, validFileIds]);

  return (
    <Box css={styles} className="download-files-button">
      <Modal
        modalSize={SIZING.MEDIUM}
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        title={promptTitle}
        component={
          <Prompt
            onRequestClose={toggleModal}
            confirmCallback={() => downloadFiles(validFileIds)}
            confirmButtonText={COLLECTION_COPY.MODAL_BUTTON_SCHEDULE_DOWNLOAD}
            text={intl.formatMessage(
              { id: 'MODAL_CONFIRM_DOWNLOAD_CREATIVE_FILES' },
              {
                invalidFiles: invalidFiles.map((file) => `\n- ${file.name}`).join(''),
                validFiles: validFiles.map((file) => `\n- ${file.name}`).join(''),
              },
            )}
          />
        }
      />
      <Tooltip content={tooltip}>
        <SplitButton
          variant={splitButtonVariant}
          primaryOnClick={downloadSelectedFiles}
          className="download-files-button__btn"
          primaryText={
            <div>
              <FontAwesomeIcon icon={faDownload} />
              {intl.formatMessage(
                { id: 'BUTTON_DOWNLOAD_FILES' },
                {
                  numFiles: selectedFiles.length,
                },
              )}
            </div>
          }
        >
          <Button
            key="WatermarkOption"
            variant={BUTTON_VARIANTS.FLAT_OPTION}
            onClick={downloadSelectedFilesWithWatermark}
          >
            {intl.formatMessage({ id: 'BUTTON_DOWNLOAD_FILES_WATERMARK' })}
          </Button>
        </SplitButton>
      </Tooltip>
    </Box>
  );
};

export default DownloadFilesButton;

const TagManager = {
  gtm(args) {
    const iframe = `
        <iframe src="https://www.googletagmanager.com/ns.html?id=${args.id}&gtm_cookies_win=x"
          height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;
    const gtmScript = `  (function(w,d,s,l,i){
              const gtmNonce = d.querySelector('[data-gtm-nonce]').dataset.gtmNonce;
              w[l]=w[l]||[];
              w[l].push({'nonce': gtmNonce});
              w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
              const f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              j.setAttribute('nonce', gtmNonce);
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${args.id}');`;
    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = iframe;
      return noscript;
    };
    const script = () => {
      const scriptTag = document.createElement('script');
      scriptTag.innerHTML = gtmScript;
      scriptTag.id = 'gtmScript';
      scriptTag.setAttribute('nonce', '2726c7f26c');
      scriptTag.setAttribute('data-gtm-nonce', '2726c7f26c');
      return scriptTag;
    };
    return {
      noScript,
      script,
    };
  },
  initialize({
    gtmId,
    dataLayer,
    dataLayerName = 'dataLayer',
    auth = '',
    preview = '',
  }: {
    gtmId: string,
    dataLayer?: any,
    dataLayerName?: string,
    auth?: string,
    preview?: string,
  }) {
    const gtm = this.gtm({
      id: gtmId,
      dataLayer: dataLayer || undefined,
      dataLayerName,
      auth,
      preview,
    });
    document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
};
export default TagManager;

import { Box, Text } from 'shared/components/display';
import FieldErrorMessage from 'shared/components/FieldErrorMessage';
import InlineButtons from 'shared/components/InlineInputs/InlineButtons';
import inlineInputStyles from 'shared/components/InlineInputs/inlineInputStyles';
import { Input as InputType } from 'shared/lib/formik';
import Input from 'shared/styles/Input/Input';
import { TEXT_VARIANTS } from 'shared/styles/text';
import cs from 'classnames';
import React, { useRef, useState } from 'react';

interface Props {
  className?: string;
  maxLength?: number;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
}
const InlineInput: InputType<string, Props> = ({
  className,
  field: { name, value = '', onChange },
  form: { errors, submitForm, setSubmitting, isSubmitting },
  placeholder,
  disabled,
  maxLength = Infinity,
  label,
}) => {
  const [active, setActive] = useState(false);
  const [storedValue, setStoredValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  const error = errors[name];

  const setInputValue = (value) => onChange({ target: { name, value } });

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (e) => {
    // Don't blur when clicking on submit button
    const submitButtonClicked = e.currentTarget.contains(e.relatedTarget as Node);

    if (!submitButtonClicked && !isSubmitting) {
      setActive(false);
      setInputValue(storedValue);
    }
  };

  const onSubmit = async () => {
    await submitForm();
    if (error) {
      inputRef.current?.focus();
    } else {
      setStoredValue(value);
      setActive(false);
      inputRef.current?.blur();
    }
    setSubmitting(false);
  };

  const onCancel = () => {
    setInputValue(storedValue);
    setActive(false);
  };

  return (
    <Box css={inlineInputStyles} className={cs('inline-input', className)} onBlur={handleBlur}>
      <Text variant={TEXT_VARIANTS.SUBHEADING} as="label" htmlFor={name} className="inline-input__label">
        {label}
      </Text>
      <Input
        className="inline-input__input"
        disabled={disabled}
        id={name}
        maxLength={maxLength}
        name={name}
        onChange={({ target }) => {
          setInputValue(target.value);
        }}
        onFocus={() => setActive(true)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onSubmit();
          }
        }}
        placeholder={placeholder}
        ref={inputRef}
        type="text"
        value={value}
        noBorder
        noMargin
      />
      {active && <InlineButtons onCancel={onCancel} onSubmit={onSubmit} />}
      <FieldErrorMessage name={name} />
    </Box>
  );
};

export default InlineInput;

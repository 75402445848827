import { css } from '@emotion/react';

import theme from 'shared/styles/themes';

export default css({
  '.submission-details': {
    '&__details-wrapper': {
      padding: theme.space[5],
      marginBottom: 0,
      marginTop: 0,
      paddingBottom: 0,
    },
  },
  '.rich-text--read-only .rich-text__editor': {
    padding: 0,
  },
});

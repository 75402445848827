import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Button } from 'rebass/styled-components';
import cs from 'classnames';

import HiddenFileInput from 'shared/components/atoms/HiddenFileInput';
import { Box, Flex } from 'shared/components/display';

import { FILE_COPY } from 'shared/config/copy';
import { MEDIA } from 'shared/config/media';
import { BUTTON_VARIANTS } from 'shared/styles/button';
import { FileFormats } from 'shared/typings/file/enums';

import styles from './styles';

interface SingleFileUploadNameAndButtonProps {
  name: string;
  filename: string;
  placeholder?: string;
  fileFormat?: FileFormats;
  onChange: (file: File | null) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  accept?: readonly string[];
  disabled?: boolean;
}

export const SingleFileUploadNameAndButton: React.FC<SingleFileUploadNameAndButtonProps> = ({
  onChange,
  onBlur,
  name,
  fileFormat = '',
  placeholder = '',
  filename = '',
  accept = [],
  disabled = false,
}) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);

  const changeHandler = (files: FileList | null) => {
    const file = files ? files[0] : null;
    if (file) {
      // file.size is the filesize in number of bytes
      onChange(file);
    }
  };

  const browse = () => {
    if (inputRef.current !== null) inputRef.current.click();
  };

  const clearFile = () => {
    if (inputRef.current !== null) {
      inputRef.current.value = '';
      onChange(null);
    }
  };

  return (
    <Flex css={styles} className="file-input">
      <Box
        onClick={browse}
        className={cs('file-input__filename', {
          'file-input__filename--disabled': disabled,
        })}
      >
        {filename || (
          <span
            className={cs('file-input__placeholder', {
              'file-input__placeholder--capitalized': placeholder === '',
            })}
          >
            {placeholder ||
              formatMessage(
                { id: 'BODY_UPLOAD_MEDIA' },
                {
                  supportedMedia: fileFormat || MEDIA.TYPES.FILE,
                },
              )}
          </span>
        )}
      </Box>
      <Button
        type="button"
        onClick={filename ? clearFile : browse}
        variant={BUTTON_VARIANTS.MICRO}
        className="file-input__btn"
        disabled={disabled}
      >
        {filename ? FILE_COPY.BUTTON_REMOVE : FILE_COPY.BUTTON_BROWSE}
      </Button>
      <HiddenFileInput
        name={name}
        onChange={changeHandler}
        onBlur={onBlur}
        ref={inputRef}
        acceptedMedia={accept}
        disabled={disabled}
        // error validation handled by form
      />
    </Flex>
  );
};

export default SingleFileUploadNameAndButton;

import { css } from '@emotion/react';
import { zIndex } from 'shared/config';
import COLORS from 'shared/styles/colors';
import { mediaquery } from 'shared/styles/styleFunctions';
import theme from 'shared/styles/themes';

export default css({
  '&.profile-picture': {
    position: 'relative',
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    flex: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      '.profile-picture__delete-button': {
        // display button when hovering over profile picture
        display: 'block',
      },
    },
  },
  '.profile-picture': {
    '&__placeholder': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors.NEUTRAL200,
      color: theme.colors.NEUTRAL900,
      textTransform: 'uppercase',
      userSelect: 'none',
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      [mediaquery.md]: {
        cursor: 'pointer',
      },
    },
    '&__placeholder--xs': {
      width: '2rem',
      height: '2rem',
    },
    '&__placeholder--sm': {
      width: '4rem',
      height: '4rem',
    },
    '&__placeholder--md': {
      width: '11rem',
      height: '11rem',
    },
    '&__placeholder--lg': {
      width: '250px',
      height: '250px',
    },
    '&__img': {
      objectFit: 'cover',
      borderRadius: '50%',
    },
    '&__img--xs': {
      width: '2rem',
      height: '2rem',
    },
    '&__img--sm': {
      width: '4rem',
      height: '4rem',
    },
    '&__img--md': {
      width: '11rem',
      height: '11rem',
    },
    '&__img--lg': {
      width: '250px',
      height: '250px',
    },
    '&__img--editable:hover': {
      // when hovering over an editable profile picture, change opacity
      filter: 'opacity(.5)',
    },
    '&__delete-button': {
      backgroundColor: COLORS.NEUTRAL700,
      border: 'none',
      borderRadius: '50%',
      cursor: 'pointer',
      display: 'none',
      filter: 'opacity(0.75)',
      fontSize: '2rem',
      height: '4rem',
      position: 'absolute',
      width: '4rem',
      zIndex: zIndex.zButton,
    },
    '&__delete-button:hover + .profile-picture__img--editable': {
      // image should stay at 0.5 opacity when cursor is hovering over button
      display: 'block',
      filter: 'opacity(0.5)',
      cursor: 'pointer',
    },

    '&__delete-button:not(:disabled):hover, &__delete-button:not(:disabled):hover .profile-picture__delete-button-svg':
      {
        // change color of button when hovering over clickable button
        backgroundColor: COLORS.NEUTRAL900,
        color: COLORS.WHITE,
      },
  },
  'a:has(&.profile-picture--navigation-disabled)': {
    cursor: 'default',
  },
});

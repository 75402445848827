import { css } from '@emotion/react';

export default css({
    justifyContent: 'left',
    marginRight: '1rem',
    '.min-max-input': {
        width: '6rem',
        marginRight: '1rem',
        marginTop: 'unset',
    },
});

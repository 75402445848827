import React, { useEffect, useState } from 'react';
import cs from 'classnames';

import Gallery from 'features/media/components/organisms/Gallery';
import { GalleryItem } from 'features/media/interfaces/gallery';
import ExternalLink from 'shared/components/atoms/ExternalLink/ExternalLink';
import { Box } from 'shared/components/display';

import { CREATOR_COPY } from 'shared/config/copy';
import { MEDIA } from 'shared/config/media';
import { useCurrentUser } from 'shared/hooks/useCurrentUser';
import { TiktokVideo } from 'shared/typings/user';

import tiktokVideosStyles from './styles';
interface CaptionProps {
  captionData?: { tiktokUrl: string };
  disabled?: boolean;
}

export const Caption: React.FC<CaptionProps> = ({ captionData = { tiktokUrl: '' }, disabled }) => {
  const { tiktokUrl } = captionData;
  return (
    <Box css={tiktokVideosStyles} className="tt-videos__caption">
      <ExternalLink
        href={tiktokUrl}
        text={CREATOR_COPY.OPEN_IN_TIKTOK}
        className={cs('tt-videos__open-btn', {
          ['tt-videos__open-btn--disabled']: disabled,
        })}
      />
    </Box>
  );
};

interface Props {
  creatorId: number;
  tiktokVideos: TiktokVideo[];
  placeholderText: string;
}

const TTVideos: React.FC<Props> = ({ creatorId, tiktokVideos, placeholderText }) => {
  const [galleryItems, setGalleryItems] = useState<GalleryItem[]>([]);
  const [active, setActive] = useState(true);

  useEffect(() => {
    setActive(true);
    const videos = tiktokVideos.slice(0, 5).map((video): GalleryItem => {
      const metadata: Record<string, any> = {
        width: video.width || 500,
        height: video.height || 500,
        duration: video.duration || 0,
      };
      return {
        itemObject: {
          id: +video.id,
          thumbnailUrl: video.cover_image_url,
          url: video.embed_link,
          creatorId,
          dateCreated: new Date(),
          name: video.id,
          metadata,
          mimeType: MEDIA.TYPES.VIDEO,
          renderInIframe: true,
        },
        captionData: { tiktokUrl: video.share_url },
      };
    });
    if (!active) return;
    setGalleryItems(videos);
    return () => {
      setActive(false);
    };
  }, [tiktokVideos, creatorId, active]);

  const { isCreator } = useCurrentUser();
  return (
    <Gallery
      items={galleryItems}
      numberOfSkeletons={tiktokVideos.length}
      targetHeight={0}
      LightboxCaption={<Caption />}
      placeholderText={placeholderText}
      onlyImage
      infoModalDisabled={isCreator}
      hideAspectRatio
    />
  );
};

export default TTVideos;
